import _ from 'lodash';
import {
  FETCH_JMBS,
  PUSH_JMBS,
  REMOVE_JMBS,
  UPDATE_JMBS,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID
} from '../actions/actions-jmbs';

const INITIAL_STATE = {
  jmbs: [],
  mode: 'index',
  activeId: '',
  activeJmbs: {}
}


export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_JMBS:
      return {
        ...state,
        jmbs: action.payload,
      }
    case PUSH_JMBS:
      return {
        ...state,
        jmbs: [action.payload, ...state.jmbs],
      }
    case REMOVE_JMBS:
      return {
        ...state,
        jmbs: [...state.jmbs.filter((item) => item._id !== action.payload._id)]
      }
    case UPDATE_JMBS:
      let index = _.findIndex(state.jmbs, { '_id': action.payload._id })
      state.jmbs.splice(index, 1, action.payload)
      return {
        ...state,
        jmbs: state.jmbs
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode: action.payload,
        activeId: ''
      }
    case UPDATE_ACTIVE_ID:
      return {
        ...state,
        mode: action.mode,
        activeId: action.id,
        activeJmbs: _.pick(_.find(state.jmbs, { '_id': action.id }), ['propertyId', 'status', 'name', 'registrationNo', 'contactNo', 'faxNo', 'email', 'headerFile', 'headerLine1', 'headerLine2', 'headerLine3', 'headerLine4', 'bankName', 'bankAccountHolder', 'bankAccountNo', '_id', 'managementName'])
      }
    default:
      return state

  }
}
