import React, { Component } from 'react';
import { Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Card, Spin, Tooltip, Popover, Row, Col, Select } from 'antd';
import { Route, Link } from 'react-router-dom';

import client from '../../feathers';
import requireAuth from "../../components/requireAuth";
import Layout from "../../components/layout";
import UploadsForm from "../../components/uploads/form";
import { CSVLink, CSVDownload } from "react-csv";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchUploads,
  pushUploads,
  removeUploads,
  updateMode,
  updateActiveUploads,
  updateUploads
} from '../../actions/actions-uploads';
import moment from "moment";
const FormItem = Form.Item;
const Option = Select.Option;
const text = 'Are you sure to delete this item?';
const text2 = 'Are you sure to process this item?';
const _ = require('lodash');
class UploadsIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      loading: false,
      uploads: [],
      loading: false,
      propertyunitsDropdowns: [],
      searchPropertyUnit: '',
      queryString: '',
      excelHeader: [
        { label: "Unit", key: "referenceId" },
        { label: "Name", key: "owner.name" },
        { label: "openingDate", key: "" },
        { label: "debitAmount", key: "" },
        { label: "creditAmount", key: "" },
      ],
    }

    this.handleDelete = this.handleDelete.bind(this);
    this.handleProcess = this.handleProcess.bind(this);
  }

  componentDidUpdate() {
    client.service('uploads').on('patched', (rec) => {
      this.componentWillMount();
    })

    if (this.props.uploads.mode === 'refresh') {
      this.componentWillMount()
    }
  }

  componentWillMount() {
    this.props.updateMode('');

    this.setState({ loading: true });
    let companyId = this.props.commons.selectedCompany._id

    client.authenticate()
      .then(() => {
        return client.service('uploads').find({
          query: {
            companyId: companyId,
            $populate: 'propertyunitId',
            $sort: {
              createdAt: -1
              // readDate: -1,
              // propertyunitId:1,
            }
          }
        })
      })
      .then((res) => {
        this.setState({ loading: false });
        this.props.fetchUploads(res.data)
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      })
  }

  _getData(skip) {

    let queryString = ''
    let companyId = this.props.commons.selectedCompany._id
    client.authenticate()
      .then((res) => {
        return client.service('uploads').find({
          query: {
            companyId: companyId,
            ...queryString,
            $populate: 'propertyunitId',
            $sort: {
              readDate: -1,
            }
          }
        })
      })
      .then((res) => {
        this.setState({ loading: false });
        this.props.fetchUploads(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleDelete(_id) {
    client.service('uploads').remove(_id)
      .then((res) => {
        this.props.updateMode('refresh')
        this.setState({
          mode: 'refresh',
        });
      })
      .catch((err) => {
        alert(err);
      })
  }

  handleProcess(_id) {

    client.service('uploads').patch(_id, { processStatus: 'Processing' })
      .then((res) => {
        //  console.log({res});
        this.props.updateMode('refresh')
        this.setState({ mode: 'refresh' });
        // client.service('uploads').patch(_id,{processStatus:'Processed'}).then((res)=>{
        //   this.props.updateMode('refresh')
        //   this.setState({
        //    mode:'refresh',
        //    });
        // }).catch((err)=>{
        //    alert(err)
        // });

      })
      .catch((err) => {
        console.log({ err });
        message.error(err.message || err)
        // alert(err);
      })
  }

  handleEdit(_id) {
    this.props.updateActiveUploads('edit', _id)
    this.setState({
      mode: 'edit',
    });

  }

  handleManage(_id) {
    this.props.updateActiveUploads('', _id)
    this.setState({
      mode: '',
    });
  }

  showModal = () => {
    this.props.updateActiveUploads('',)
    this.props.updateMode('new')
    this.setState({
      mode: 'new',
    });
  }

  renderConfirmDeleteBtn(_id) {
    return (
      <Popconfirm placement="topLeft" title={text} onConfirm={() => this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
        <Button type="danger">Delete</Button>
      </Popconfirm>
    )
  }

  renderProcessBtn(record) {
    return (
      <Popconfirm placement="topLeft" title={text2} onConfirm={() => this.handleProcess(record._id)} okText="Confirm" cancelText="Cancel">
        <Button disabled={record.processStatus === 'Uploaded' ? false : true}>Process</Button>
      </Popconfirm>
    )
  }


  render() {
    const { getFieldDecorator } = this.props.form;
    var csvFileName = 'New Property Template.csv';
    const columns = [
      {
        title: 'Function',
        dataIndex: 'function',
        key: 'function',
        width: '10%',
        render: (text, record) => (
          <span>{text ? text : '-'}</span>
        ),
      },

      {
        title: 'Process Status',
        dataIndex: 'processStatus',
        key: 'processStatus',
        width: '10%',
        render: (text, record) => (
          <span>{text ? text : '-'}</span>
        ),
      },
      {
        title: 'Upload Date',
        dataIndex: 'uploadDate',
        key: 'uploadDate',
        width: '10%',
        render: (text, record) => (
          <span>{text ? moment(text).format('DD-MM-YYYY') : '-'}</span>
        ),
      },
      {
        title: 'Process Date',
        dataIndex: 'processDate',
        key: 'processDate',
        width: '10%',
        render: (text, record) => (

          <span>{text ? moment(text).format('DD-MM-YYYY') : '-'}</span>

        ),
      },

      {
        title: 'Remark',
        dataIndex: 'remark',
        key: 'remark',
        width: '10%',
        render: (text, record) => (
          <span>
            {text ? <Popover content={text}><Icon type="message" /></Popover> : ''}
          </span>
        ),
      },

      {
        title: 'Action',
        key: 'action',
        width: '20%',
        render: (text, record) => (
          <span>
            {this.renderProcessBtn(record)}
            <Divider type="vertical" />
            {this.renderConfirmDeleteBtn(record._id)}

          </span>
        ),
      }];

    return (
      <Layout>
        <Spin key='spinning' spinning={this.state.loading} >
          <Card
            title="Uploads"
            extra={
              <React.Fragment>
                {/*<CSVLink
                  filename={csvFileName}
                  data={this.state.excelData}
                  headers={this.state.excelHeader}>
                <Button>Download Templates(Debtor)</Button>
                </CSVLink>*/}
                <Button type="primary" onClick={this.showModal}>New</Button>
              </React.Fragment>
            }
          >
            <Table dataSource={this.props.uploads.uploads} columns={columns} rowKey="_id" />
            <UploadsForm />
          </Card>
        </Spin>
      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    uploads: state.uploads,
    commons: state.commons
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchUploads: fetchUploads,
    pushUploads: pushUploads,
    removeUploads: removeUploads,
    updateActiveUploads: updateActiveUploads,
    updateUploads: updateUploads,
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(UploadsIndex)));
