import React, { Component, useEffect } from 'react';
import { Modal, Upload, Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Select, Card, Row, Col, Spin, InputNumber, Radio, DatePicker } from 'antd'
import client from '../../feathers';
import requireAuth from "../requireAuth";
import Layout from "../layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateMode } from '../../actions/actions-invoices';
import Info from "../common/info";
import moment from 'moment';


const _ = require('lodash');
const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },

};
const formItemStyle = {
  style: { width: '300px' }
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};

const text = 'Are you sure to delete this item?';

class InvoicesForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',
      confirmDirty: false,
      autoCompleteResult: [],
      chargesDropdowns: [],
      charges: [],
      dataSource: [],
      mode: 'new',
      propertyunits: [],
      loading: false,
      count: 0
    }

  }

  componentWillMount() {

    setTimeout(() => {    //set default date

      this.props.form.setFieldsValue({ invoiceDate: moment() });
    }, 1000)

    //generate charges dropdown
    client.authenticate()
      .then((res) => {
        return client.service('charges').find({
          query: {
            propertyId: this.props.commons.selectedCompany._id,
            $sort: {
              createdAt: -1
            },
            code: { $in: ['DEPOSIT', 'RENO DEPO'] }
          }
        })
      })
      .then((res) => {
        this.setState({ chargesDropdowns: res.data });
        return client.service('propertyunits').find({
          query: {
            propertyId: this.props.commons.selectedCompany._id,
            $sort: {
              referenceId: 1
            }
          }
        })
      })
      .then((res) => {

        this.setState({
          propertyunits: res.data
        });
      })
      .catch((err) => {
        console.log(err);
      });

  }

  componentDidUpdate() {

    if (this.props.invoices.activeId !== this.state._id) {
      this.setState({
        _id: this.props.invoices.activeId
      })

      if (this.props.invoices.activeInvoices) {
        this.props.form.setFieldsValue(
          this.props.invoices.activeInvoices
        )
      }
    }
  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }

  handleChange = (e) => {

    this.setState({
      loading: true
    });


    client.authenticate()
      .then((res) => {
        return client.service('charges').get(e)
      })
      .then((res) => {
        this.setState({
          charges: res,
          loading: false
        });
        this.props.form.setFieldsValue(
          res
        );
      })
      .catch((err) => {
        this.setState({
          loading: false
        });
        console.log(err);
      });
  }

  handleDeleteItem = (key) => {

    const dataSource = [...this.state.dataSource];
    this.setState({ dataSource: dataSource.filter(item => item.key !== key) });
  }

  handleClick = () => {

    if (!this.state.charges._id) {
      return message.error('Please select charges.')
    }

    const newData = {
      key: this.state.count,
      chargeId: this.state.charges._id,
      chargeCode: this.state.charges.code,
      description: this.state.charges.description,
      amount: this.state.charges.amount,
      effectiveDate: new Date(this.props.form.getFieldValue('invoiceDate'))
    };

    this.setState({
      dataSource: [...this.state.dataSource, newData],
      count: this.state.count + 1,
    });
  }

  handleChangeAmount = (e) => {
    this.setState({
      charges: {
        _id: this.state.charges._id,
        code: this.state.charges.code,
        description: this.state.charges.description,
        amount: e
      }
    })
  }

  handleChangePropertyunit = (e) => {

    var selectedProperty = _.find(this.state.propertyunits, function (o) { return o._id == e; });

    if (selectedProperty.owner) {

      let ownerName = selectedProperty.owner.name ? selectedProperty.owner.name : ''
      let ownerContact = selectedProperty.owner.contactNumber ? selectedProperty.owner.contactNumber : ''
      this.props.form.setFieldsValue(
        {
          ownerName: ownerName,
          ownerContact: ownerContact
        }
      )
    } else {
      this.props.form.setFieldsValue(
        {
          ownerName: '',
          ownerContact: ''
        }
      )
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.state.dataSource.length === 0) {
      return message.error('No item is selected');
    }

    this.props.form.validateFields((err, values) => {
      if (!err) {

        this.setState({ loading: true })

        values.invoiceDate = new Date(values.invoiceDate)

        client.service('invoices').create({
          invoiceDate: values.invoiceDate,
          invoiceType: 'OTHERS',
          propertyId: this.props.commons.selectedCompany._id,
          customerId: values.customerId,
          propertyunitId: values.propertyunitId,
          customerType: 'PROPERTYUNIT',//values.customerType,
          reference: '',
          status: 'ISSUED',
          subtotal: 0,
          taxPercentage: 0,
          taxAmt: 0,
          totalAmt: 0,
          remark: values.remark,
          invoiceType: 'DEPOSIT',
        },
          {
            query: {
              $client: {
                invoiceItems: this.state.dataSource
              }
            }
          }
        )
          .then((res) => {
            this.setState({ loading: false })
          })
        this.props.history.push('/deposit/');

        message.success('Deposit Invoice creation in progress...');

      }
    });
  }

  render() {
    const { visible, onCancel } = this.props;
    const { getFieldDecorator } = this.props.form;

    const columns = [{
      title: 'Fee Code',
      dataIndex: 'chargeCode',
      key: 'chargeCode',
    }, {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    }, {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Action',
      key: 'action',

      render: (text, record) => (
        this.state.dataSource.length >= 1
          ? (
            <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDeleteItem(record.key)}>
              <a href="javascript:;">Delete</a>
            </Popconfirm>
          ) : null
      ),
    }
    ];

    return (
      <Layout>
        <Spin spinning={this.state.loading}>
          <Card
            title='Deposit'
            extra={<Button onClick={this.handleSubmit}>Create</Button>}
          >


            <Form className="submit-form">

              <Row>
                <Col span={6}>

                  <FormItem key='invoiceDate'
                    {...formItemLayout}
                    label="Date"
                  >
                    {getFieldDecorator('invoiceDate', {
                      rules: [{
                        required: true, message: 'Please Select date!',
                      }],
                    })(
                      <DatePicker />
                    )}
                  </FormItem>

                  <FormItem key='propertyunitId'
                    {...formItemLayout}
                    label="Property Unit"
                  >
                    {getFieldDecorator('propertyunitId', {
                      rules: [{
                        required: true, message: 'Please Input Property Unit!',
                      }],
                    })(
                      <Select
                        {...formItemStyle}
                        onChange={this.handleChangePropertyunit}
                        showSearch
                        placeholder="Select a unit"
                        optionFilterProp="children"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      >
                        {this.state.propertyunits.map(dropdown => (
                          <Option key={dropdown._id} value={dropdown._id}>
                            {dropdown.referenceId}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>

                  <FormItem key='ownerName'
                    {...formItemLayout}
                    label="Owner Name"
                  >
                    {getFieldDecorator('ownerName', {
                      rules: [{
                        required: false, message: 'Please Input ownerName!',
                      }],
                    })(
                      <Input disabled />
                    )}
                  </FormItem>

                  <FormItem key='ownerContact'
                    {...formItemLayout}
                    label="Owner Contact"
                  >
                    {getFieldDecorator('ownerContact', {
                      rules: [{
                        required: false, message: 'Please Input ownerContact!',
                      }],
                    })(
                      <Input disabled />
                    )}
                  </FormItem>

                  <FormItem key='remark'
                    {...formItemLayout}
                    label="Remark"
                  >
                    {getFieldDecorator('remark', {
                      rules: [{
                        required: false, message: 'Please Input Remark!',
                      }],
                    })(
                      <TextArea rows={3} />
                    )}
                  </FormItem>

                </Col>
                <Col span={6} offset={12}>

                  <FormItem key='chargeId'
                    {...formItemLayout}
                    label="Charges"
                  >
                    {getFieldDecorator('chargeId', {
                      rules: [{
                        required: true, message: 'Please Select Charges!',
                      }],
                    })(
                      <Select placeholder="Select Type" style={{ 'width': '200px' }} onChange={this.handleChange}>
                        {this.state.chargesDropdowns.map(dropdown => (
                          <Option key={dropdown._id} value={dropdown._id}>
                            {dropdown.description}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>

                  <FormItem key='code'
                    {...formItemLayout}
                    label="Charge Code"
                  >
                    {getFieldDecorator('code', {
                      rules: [{
                        required: true, message: 'Please select charges!',
                      }],
                    })(
                      <Input disabled />
                    )}
                  </FormItem>

                  <FormItem key='amount'
                    {...formItemLayout}
                    label="Amount"
                  >
                    {getFieldDecorator('amount', {
                      rules: [{
                        required: true, message: 'Please Input amount!',
                      }],
                    })(
                      <InputNumber onChange={this.handleChangeAmount} />
                    )}
                  </FormItem>
                  <Button type="primary" onClick={this.handleClick} disabled={this.state.mode === 'edit' ? true : false} >Add</Button>

                </Col>
              </Row>

            </Form>



            <Table dataSource={this.state.dataSource} columns={columns} rowKey='code' />


          </Card>
        </Spin>
      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    invoices: state.invoices,
    commons: state.commons
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(InvoicesForm)));
