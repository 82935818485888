import React, { Component } from 'react';
import { Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Card, Spin, Row, Col, DatePicker } from 'antd';
import { Route, Link } from 'react-router-dom';

import client from '../../feathers';
import requireAuth from "../../components/requireAuth";
import Layout from "../../components/layout";
import CreditorAgingPrint from "../../components/creditorAging/print";
import ReactToPrint from "react-to-print";
import moment from 'moment';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchCreditorAgingReports } from '../../actions/actions-creditorAgingReports';


const { MonthPicker, RangePicker, WeekPicker } = DatePicker;

const FormItem = Form.Item;

const text = 'Are you sure to delete this item?';

class CreditorAgingView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      fromDate: moment().startOf('year').format('YYYY-MM-DD'),
      toDate: moment().format('YYYYMMDD'),
    }

  }

  fromDateOnChange = (e) => {

    this.setState({
      toDate: moment(new Date(e)).format("YYYYMMDD")
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Layout>
        <div className='wrap-reactToPrint'>

          <Row gutter={24}>
            <Col span={6} offset={16}>
              <DatePicker onChange={this.fromDateOnChange} />
            </Col>
            {/*
          <Col span={3}>
          To Date : <DatePicker  defaultValue={moment().endOf('year')} onChange={this.toDateOnChange}/>
          </Col>
          */}
            <Col span={2}>
              {/* <ReactToPrint
                trigger={() => <Button type="primary">Print</Button>}
                content={() => this.componentRef}
              /> */}
            </Col>
          </Row>
        </div>

        <CreditorAgingPrint invoiceId={this.props.match.params.id} ref={el => (this.componentRef = el)} fromDate={this.state.fromDate} toDate={this.state.toDate} />

      </Layout>
    )
  }
}


function mapStateToProps(state) {

  return {
    creditorAgingReports: state.creditorAgingReports,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchCreditorAgingReports: fetchCreditorAgingReports
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(CreditorAgingView)));
