export const FETCH_GLOBALPARAMETERS = 'FETCH_GLOBALPARAMETERS';
export const PUSH_GLOBALPARAMETERS = 'PUSH_GLOBALPARAMETERS';
export const REMOVE_GLOBALPARAMETERS = 'REMOVE_GLOBALPARAMETERS';
export const UPDATE_GLOBALPARAMETERS = 'UPDATE_GLOBALPARAMETERS';
export const UPDATE_MODE = 'UPDATE_MODE';
export const UPDATE_ACTIVE_ID = 'UPDATE_ACTIVE_ID';

export function fetchGlobalParameters(data){
  return (dispatch) => {
    dispatch({
      type: FETCH_GLOBALPARAMETERS,
      payload:data
    });
  }
}

export function pushGlobalParameters(data){
  return (dispatch) => {
    dispatch({
      type: PUSH_GLOBALPARAMETERS,
      payload:data
    });
  }
}

export function removeGlobalParameters(data){
  return (dispatch) => {
    dispatch({
      type: REMOVE_GLOBALPARAMETERS,
      payload:data
    });
  }
}

export function updateGlobalParameters(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_GLOBALPARAMETERS,
      payload:data
    });
  }
}

export function updateMode(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE,
      payload:data
    });
  }
}

export function updateActiveGlobalParameters(mode, id){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_ID,
      mode,
      id
    });
  }
}
