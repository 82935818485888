import _ from 'lodash';
import {
  FETCH_UPLOADS,
  PUSH_UPLOADS,
  REMOVE_UPLOADS,
  UPDATE_UPLOADS,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID } from '../actions/actions-uploads';

const INITIAL_STATE = {
  uploads: [],
  mode: 'index',
  activeId: '',
  activeUploads:{}
}


export default function(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_UPLOADS:
      return {
        ...state,
        uploads:action.payload,
      }
    case PUSH_UPLOADS:
      return {
        ...state,
        uploads:[action.payload, ...state.uploads],
      }
    case REMOVE_UPLOADS:
      return {
        ...state,
        uploads : [...state.uploads.filter( (item) => item._id !== action.payload._id)]
      }
    case UPDATE_UPLOADS:
      let index =  _.findIndex(state.uploads, { '_id': action.payload._id })
      state.uploads.splice(index, 1, action.payload)
      return {
        ...state,
        uploads : state.uploads
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode : action.payload,
        activeId:''
      }
    case UPDATE_ACTIVE_ID:
      return {
        ...state,
        mode : action.mode,
        activeId:action.id,
        activeUploads: _.pick(_.find(state.uploads, { '_id': action.id }), ['companyId'	,'function','documentPath','processStatus','uploadDate','processDate','_id'])
      }
    default:
      return state

  }
}
