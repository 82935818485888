import React, { Component } from 'react';
import {
  DatePicker,
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  InputNumber,
  Select,
  Col,
  Row,
  Card,
  message,
  Spin,
  Tabs,
  Icon,
  notification,
  Modal,

} from 'antd';
import { Route, Link } from 'react-router-dom';

import client from '../../feathers';
import requireAuth from "../requireAuth";
import Layout from "../layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PaymentFormItems from "./form-payment-items";
import {
  fetchInvoiceitems,
  pushInvoiceitems,
  removeInvoiceitems,
  updateMode,
  updateActiveInvoiceitems,
  updateInvoiceitems
} from '../../actions/actions-invoiceitems';

import '../../App.css';
import moment from 'moment';
import axios from 'axios';

const { TextArea } = Input;
const { TabPane } = Tabs;
const _ = require('lodash');
const text = 'Are you sure to delete this item?';
const FormItem = Form.Item;
const EditableContext = React.createContext();
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
const d = new Date();
const monthdesc = monthNames[d.getMonth()];
const formItemLayout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 16
  }
};
const formItemLayout2 = {
  labelCol: {
    span: 5
  },
  wrapperCol: {
    span: 14
  }
};

const EditableRow = ({
  form,
  index,
  ...props
}) => (<EditableContext.Provider value={form}>
  <tr {...props} />
</EditableContext.Provider>);
const EditableFormRow = Form.create()(EditableRow);
const Option = Select.Option;
//--------------------------------------------------------------------------------------------------------------------------------

class EditableCell extends React.Component {
  state = {
    editing: false,
    charges: []
  }

  componentWillMount() { }

  componentDidUpdate() {
    if (this.props.charges !== this.state.charges) {
      this.setState({ charges: this.props.charges });
    }
  }

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({
      editing
    }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  }

  save = () => {
    const { record, handleSave } = this.props;
    let newRecord = {};
    this.form.validateFields((error, values) => {


      if (values.chargeId) {

        const newData = [...this.props.recurringItems];
        const index = newData.findIndex(item => values.chargeId === item.chargeId._id);
        const item = newData[index];
        newRecord = {
          ...record,
          actualAmount: item.amount
        };
      } else {
        newRecord = record;
      }

      if (error) {
        return;
      }
      this.toggleEdit();
      handleSave({
        ...newRecord,
        ...values
      });
    });
  }

  getInput = () => {
    if (this.props.inputType === 'number') {
      return <InputNumber min={0.01} step={0.01} ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} />;
    } else if (this.props.inputType === 'month') { //monthNames:['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December','All']
      return <Select ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} placeholder="Please Select Month" style={{
        width: '100%'
      }}>
        <Option value="January">January</Option>
        <Option value="February">February</Option>
        <Option value="March">March</Option>
        <Option value="April">April</Option>
        <Option value="May">May</Option>
        <Option value="June">June</Option>
        <Option value="July">July</Option>
        <Option value="August">August</Option>
        <Option value="September">September</Option>
        <Option value="October">October</Option>
        <Option value="November">November</Option>
        <Option value="December">December</Option>
      </Select>
    } else if (this.props.inputType === 'chargeId') {
      return <Select ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} placeholder="Select Fee" style={{
        width: '100%'
      }}>
        {
          this.props.recurringItems.map(dropdown => (<Option value={dropdown.chargeId._id}>
            {dropdown.chargeId.code}
          </Option>))
        }
      </Select>
    } else {
      return <Input ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} />;
    }
  };
  //--------------------------------------------------------------------------------------------------------------------------------
  render() {
    const { editing } = this.state;
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      ...restProps
    } = this.props;
    return (<td ref={node => (this.cell = node)} {...restProps}>
      {
        editable
          ? (<EditableContext.Consumer>
            {
              (form) => {
                this.form = form;
                return (
                  editing
                    ? (<FormItem style={{
                      margin: 0
                    }}>
                      {
                        form.getFieldDecorator(dataIndex, {
                          rules: [
                            {
                              required: true,
                              message: `${title} is required.`
                            }
                          ],
                          initialValue: record[dataIndex]
                        })(this.getInput())
                      }
                    </FormItem>)
                    : (<div className="editable-cell-value-wrap" style={{
                      paddingRight: 24,
                      border: '1px solid darkgray',
                      backgroundColor: 'white'
                    }} onClick={this.toggleEdit}>
                      {restProps.children}
                    </div>));
              }
            }
          </EditableContext.Consumer>)
          : restProps.children
      }
    </td>);
  }
}
//--------------------------------------------------------------------------------------------------------------------------------
class IndexKnockOffByPropertyunit extends Component {
  constructor(props) {
    super(props);

    this.columnsPrepayment = [
      {
        title: ' ',
        dataIndex: 'operation',
        width: '5%',
        key: '',
        render: (text, record) => (
          this.state.dataSourcePrepayment.length >= 1
            ? (
              <Icon type="close" onClick={() => this.handleDelete(record.key)} />
            )
            : null)
      }, {
        title: 'Charges',
        dataIndex: 'chargeId',
        key: '',
        width: '25%',
        editable: true,
        render: (text, record) => (<Select value={record.chargeId} style={{
          width: '100%'
        }}>
          {
            this.state.recurringItems.map(dropdown => (<Option value={dropdown.chargeId._id}>
              {dropdown.chargeId.code}
            </Option>))
          }
        </Select>)
      }, {
        title: 'Year',
        dataIndex: 'year',
        key: '',
        width: '20%',
        editable: true
      }, {
        title: 'Next Process Month',
        dataIndex: 'month',
        key: '',
        width: '20%',
        editable: true
      }, {
        title: 'Charge Amount',
        dataIndex: 'actualAmount',
        key: '',
        width: '15%',
        editable: false,
        render: (text, record) => (<span>
          {this.formatAmt(record.actualAmount)}
        </span>)
      }, {
        title: 'Amount',
        dataIndex: 'amount',
        key: '',
        width: '15%',
        editable: true
      }
    ];

    this.state = {
      prepaymentTotalAmount: 0.00,
      prepaymentTotalAmountDisplay: 0.00,
      prepaymentSelectedAmount: 0.00,
      selectedAmount: 0.00,
      totalAmount: 0.00,
      payAmount: 0.00,
      propertyUnit: {},
      loading: false,
      dataSource: [],
      dataSourcePrepayment: [],
      selectedRowKeys: [],
      count: 0,
      charges: [],
      recurringItems: [],
      selectedRows: [],
      paymentDate: moment(new Date()).format("YYYY-MM-DD"),
      prepaymentUA: 0.00,
      visible: false,
      references: '',
      remark: '',
    }

  }

  componentDidUpdate() {

    if (this.state.selectedRows.length > 0) {

      let tempAmt = 0;
      for (let i = 0; i < this.state.selectedRows.length; i++) {
        tempAmt += parseFloat(this.state.selectedRows[i].knockOffAmount);
      }

      if (tempAmt !== this.state.totalAmount) {

        this.setState({ totalAmount: tempAmt });
      }

    }

  }

  componentDidMount() { }

  componentWillMount() {

    this.setState({ loading: true });
    client.authenticate().then((res) => {
      //
      return client.service('invoice-items').find({
        query: {
          $populate: 'propertyId invoiceId',
          propertyId: this.props.commons.selectedCompany._id,
          propertyunitId: this.props.match.params.id,
          status: {
            $in: ['ISSUED', 'PARTIAL']
          },
          $sort: {
            docId: 1
          }
        }
      })
    }).then((res) => {
      //

      this.setState({ dataSource: res.data, loading: false });

    }).catch((err) => {
      console.log(err);
      this.setState({ loading: false });
    });

    this.getCharges();
    this.getRecurringItem();
  }

  getCharges() {

    client.authenticate().then((res) => {

      return client.service('charges').find({
        query: {
          propertyId: this.props.commons.selectedCompany._id
        }
      })
    }).then((res) => {
      this.setState({ charges: res.data });
    }).catch((err) => {
      console.log(err);
    });

  }

  getRecurringItem() {

    client.authenticate().then((res) => {

      return client.service('recurring-items').find({
        query: {
          propertyId: this.props.commons.selectedCompany._id,
          propertyunitId: this.props.match.params.id,
          $populate: 'chargeId'
        }
      })
    }).then((res) => {

      this.setState({ recurringItems: res.data });
    }).catch((err) => {
      console.log(err);
    });

  }

  formatAmt(amt) {
    let integer = parseFloat(amt);
    return integer > 0
      ? integer.toFixed(2)
      : 0.00;
  }

  handleDelete = (key) => {
    const dataSourcePrepayment = [...this.state.dataSourcePrepayment];

    this.setState({
      dataSourcePrepayment: dataSourcePrepayment.filter(item => item.key !== key)
    });

    setTimeout(() => { //recalculate
      let tempTotal = 0;
      for (let i = 0; i < this.state.dataSourcePrepayment.length; i++) {
        tempTotal += this.state.dataSourcePrepayment[i].amount;
      }
      this.setState({ prepaymentSelectedAmount: tempTotal });

    }, 1000)

  }

  handleAdd = () => {
    const { count, dataSourcePrepayment, recurringItems } = this.state;
    let amount = 0.00;
    let finalAmount = 0.00;

    const newData = {
      key: count,
      chargeId: recurringItems[0].chargeId._id,
      year: new Date().getFullYear(),
      actualAmount: recurringItems[0].amount,
      month: monthdesc,
      amount: 0
    };

    this.setState({
      dataSourcePrepayment: [
        ...dataSourcePrepayment,
        newData
      ],
      count: count + 1
    });

    //recalculate total amount
    if (this.state.prepaymentTotalAmount > 0) {

      setTimeout(() => {
        let tempSelectedTotal = 0;

        for (let i = 0; i < this.state.dataSourcePrepayment.length; i++) {
          tempSelectedTotal += this.state.dataSourcePrepayment[i].actualAmount;
        }
        this.setState({
          prepaymentSelectedAmount: tempSelectedTotal,
          prepaymentTotalAmount: this.state.payAmount - this.state.selectedAmount - tempSelectedTotal
        });

      }, 1000);

    } else {

      setTimeout(() => {
        let tempTotal = 0;
        let tempSelectedTotal = 0;

        for (let i = 0; i < this.state.dataSourcePrepayment.length; i++) {
          tempTotal += this.state.dataSourcePrepayment[i].amount;
          tempSelectedTotal += this.state.dataSourcePrepayment[i].actualAmount;
        }

        this.setState({ prepaymentTotalAmount: tempTotal, prepaymentSelectedAmount: tempSelectedTotal });

      }, 1000);

    }

  }

  handleSave = (row) => {

    if (parseFloat(row.knockOffAmount) > parseFloat(row.appliedAmount - row.knockOffBalance).toFixed(2)) {
      message.error('Amount can\'t greater than remaining charges.');
      return;
    }

    //dataSource
    const newData = [...this.state.dataSource];
    const index = newData.findIndex(item => row._id === item._id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row
    });

    //dataSelectedrow
    const newData2 = [...this.state.selectedRows];
    const index2 = newData2.findIndex(item => row._id === item._id);
    const item2 = newData2[index2];
    newData2.splice(index2, 1, {
      ...item2,
      ...row
    });

    let amt = 0;
    for (let i = 0; i < newData2.length; i++) {
      amt += parseFloat(newData2[i].knockOffAmount).toFixed(2);
    }

    this.setState({ dataSource: newData, selectedRows: newData2, totalAmount: amt });

  }

  handleSavePrepayment = (row) => {

    if (row.amount > row.actualAmount) {
      message.error('Amount can\'t greater than charge Amount.');
      return;
    }

    const newData = [...this.state.dataSourcePrepayment];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row
    });

    let tempTotal = 0;
    let tempSelectedTotal = 0;
    for (let i = 0; i < newData.length; i++) {
      tempTotal += newData[i].amount;
      tempSelectedTotal += newData[i].actualAmount;
    }

    this.setState({ dataSourcePrepayment: newData, prepaymentTotalAmountDisplay: tempTotal, prepaymentTotalAmount: tempTotal, prepaymentSelectedAmount: tempSelectedTotal });

  }

  getInputStyle = (dataType) => {
    if (dataType === 'amount') {
      return 'number';
    } else if (dataType === 'month') {
      return 'month';
    } else if (dataType === 'chargeId') {
      return 'chargeId';
    } else {
      return 'text';
    }
  }

  renderFooter() {
    return ([<Row key='footer1'>
      <Col offset={11} span={9} style={{
        'textAlign': 'right',
      }}>

        <span className='customBold2'>Total KnockOff :</span>
      </Col>
      <Col span={1}>
        <span></span>
      </Col>
      <Col span={3}>
        <Input key='totalKnockOff' style={{
          color: 'green',
          'textAlign': 'right'
        }} value={this.formatAmt(this.state.totalAmount)} disabled={true} />
      </Col>

    </Row>
    ])
  }

  renderFooterPrepayment() {
    return ([<Row key='prepaymentfooter'>

      <Col offset={11} span={9} style={{
        'textAlign': 'right',
      }}>
        <span className='customBold2'>Total Amount :
        </span>
      </Col>
      <Col span={1} style={{}}>
        <span></span>
      </Col>
      <Col span={3}>
        <Input style={{
          color: 'green',
          'float': 'right'
        }} value={this.formatAmt(this.state.prepaymentTotalAmountDisplay)} disabled={true} />
      </Col>
    </Row>
    ])
  }

  renderFooterPrepayment2() {
    return ([
      <Row key='prepaymentfooter2' className='backgrouGrey'>
        <Col offset={11} span={9} style={{
          'text-align': 'right',
          'margin-right': '10px',
          'margin-top': '10px',
          'margin-bottom': '10px',
        }}>
          <span className='customBold2'>Total Amount : </span>
        </Col>
        <Col span={3}>
          <Input style={{
            color: 'green',
            marginTop: '10px',
            marginRight: '5px',
            marginBottom: '20px',
            marginLeft: '20px',
            textAlign: 'right',
          }} value={this.formatAmt(this.state.prepaymentUA)}
            disabled={true} />
        </Col>
      </Row>
    ])
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  _paymentDateChange = (e) => {

    this.setState({
      paymentDate: moment(new Date(e)).format("YYYY-MM-DD")
    });
  }

  unAllocatedPrepaymentAmount = (e) => {

    if (!e) {

      this.setState({ prepaymentUA: 0 });

    }
    else {

      this.setState({ prepaymentUA: e });

    }
  }

  handleCancel = (e) => {

    this.setState({ visible: false });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {

      this.state.totalAmount = this.state.totalAmount ? this.state.totalAmount : 0;
      this.state.prepaymentTotalAmount = this.state.prepaymentTotalAmount ? this.state.prepaymentTotalAmount : 0;
      this.state.payAmount = this.state.payAmount ? this.state.payAmount : 0;
      this.state.prepaymentUA = this.state.prepaymentUA ? this.state.prepaymentUA : 0;
      console.log(
        '1' + '-----' + this.props.commons.selectedCompany._id,
        '2' + '-----' + this.props.match.params.id,
        '3' + '-----' + this.state.dataSourcePrepayment,
        '4' + '-----' + this.state.references,
        '5' + '-----' + this.state.remark)

      if (this.state.dataSourcePrepayment.length === 0) {
        axios.get(`${client.io.io.uri}firstTimePrepaymentByAmount`, {
          params: {
            propertyId: this.props.commons.selectedCompany._id,
            propertyunitId: this.props.match.params.id,
            prepayment: this.state.dataSourcePrepayment,
            reference: this.state.references,
            amount: this.state.prepaymentUA,
            remark: this.state.remark,
          },
          headers: { 'Authorization': client.settings.storage.storage['feathers-jwt'] }
        })
          .then((res) => {
            this.props.updateMode('')
            if (res.data.status) {
              message.success(res.data.status)
            }
          })
      }

      return

      if (!err) {
        let custInfo = {
          propertyunitId: this.props.match.params.id,
          customerType: 'PROPERTYUNIT',
        };

        axios.get(`${client.io.io.uri}firstTimePrepayment`, {
          params: {
            propertyId: sessionStorage.getItem('companyId'),
            propertyunitId: this.props.match.params.id,
            prepayment: this.state.dataSourcePrepayment,
            reference: this.state.references,
            remark: this.state.remark,
          },
          headers: {
            'Authorization': client.settings.storage.storage['feathers-jwt']
          }
        })
          .then((res) => {
            this.props.updateMode('')
            if (res.data.status) {
              message.success(res.data.status)
            }
          })

        this.setState({ visible: false, });
        this.props.history.push('/firstTime/invoice/');
        notification.open({
          message: 'Successfully submitted!',
          description:
            'Prepayment record creation in progress...',
          duration: 5,
        });
      }
    });
  }

  showModal = () => {

    this.props.form.validateFields((err, values) => {
      if (!err) {
        let totalAmount = _.sumBy(this.state.dataSourcePrepayment, 'amount');
        if (((this.state.dataSourcePrepayment.length <= 0) && ((this.state.prepaymentUA <= 0) || (!this.state.prepaymentUA))) || (totalAmount <= 0 && this.state.prepaymentUA <= 0)) {
          if (totalAmount <= 0 && this.state.prepaymentUA <= 0) {
            message.error('Please Prepayment Amount must greater than 0.')
          }

          if ((this.state.dataSourcePrepayment.length <= 0) && ((this.state.prepaymentUA <= 0) || (!this.state.prepaymentUA))) {
            message.error('Please Add Prepayment Record.')
          }
        } else {
          this.setState({ visible: true });
        }
      }
    })
  }

  setReferenceValue = (e) => {
    this.setState({ references: e.target.value });
  }

  setRemarkValue = (e) => {
    this.setState({ remark: e.target.value });
  }

  render() {
    const { dataSource, dataSourcePrepayment, selectedRowKeys, loading, selectedRows } = this.state;
    const { getFieldDecorator } = this.props.form;
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell
      }
    };

    // rowSelection object indicates the need for row selection
    const rowSelection = {
      selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {

        let tempAmt = 0;
        let newData = [...this.state.dataSource];

        for (let i = 0; i < selectedRows.length; i++) {
          tempAmt += selectedRows[i].appliedAmount - selectedRows[i].knockOffBalance;
        }

        if (selectedRows.length > 0) {
          //start handle latest selected row
          selectedRows[selectedRows.length - 1].knockOffAmount = selectedRows[selectedRows.length - 1].appliedAmount - selectedRows[selectedRows.length - 1].knockOffBalance;
          const index = newData.findIndex(item => selectedRows[selectedRows.length - 1]._id === item._id);
          const item = newData[index];
          newData.splice(index, 1, {
            ...item,
            ...selectedRows[selectedRows.length - 1]
          });
          //end handle latest selected row
        }

        if (dataSourcePrepayment.length === 0) { }

        this.setState({ selectedRows: selectedRows, selectedRowKeys: selectedRowKeys, selectedAmount: tempAmt, dataSource: newData, totalAmount: tempAmt });
      }
    };

    const hasSelected = selectedRows.length > 0;

    const prepaymentItemsColumns = [
      {
        title: 'Charges',
        dataIndex: 'chargeId',
        key: 'chargeId',
        render: (text, record) => (<Select value={record.chargeId} style={{
          width: '100%'
        }} disabled={true}>
          {
            this.state.charges.map(dropdown => (<Option value={dropdown._id}>
              {dropdown.code}
            </Option>))
          }
        </Select>)
      }, {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        render: text => <span style={{ 'float': 'right' }}>{parseFloat(text).toFixed(2)}</span>
      }, {
        title: 'Year',
        dataIndex: 'year',
        key: 'year'
      }, {
        title: 'Month',
        key: 'month',
        dataIndex: 'month'
      }
    ];

    const columnsPrepayment = this.columnsPrepayment.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: this.getInputStyle(col.dataIndex), //col.dataIndex === 'amount' ? 'number' : 'text',
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSavePrepayment,
          recurringItems: this.state.recurringItems
        })
      };
    });

    const dateFormat = 'YYYY/MM/DD';

    const config = {
      initialValue: moment(),
      rules: [
        {
          type: 'object',
          required: true,
          message: 'Please select date!'
        }
      ]
    };

    return (
      <Layout>
        <Spin key='loadingPage' spinning={this.state.loading}>
          <Card title={'Prepayment (Not affecting GL)'}>
            <Row>
              <Col span='15'>
                <Tabs defaultActiveKey="1">
                  <TabPane tab="Prepayment by Allocation" key="1">
                    <Row>
                      <Col align='right'>
                        <Button onClick={this.handleAdd} style={{
                          marginTop: 5,
                          marginBottom: 10
                        }}>
                          Add a Prepayment
                        </Button>
                      </Col>
                    </Row>

                    <Row>
                      <Table components={components} rowClassName={() => 'editable-row'} bordered dataSource={dataSourcePrepayment} columns={columnsPrepayment} pagination={false} size='small' footer={() => this.renderFooterPrepayment()} />
                    </Row>
                  </TabPane>

                  <TabPane tab="Prepayment by Amount" key="2">
                    <Form.Item {...formItemLayout2} label="Prepayment Amount">
                      {getFieldDecorator('prepaymentAmountUA')(
                        <InputNumber min={0.01} step={0.01} key='prepaymentAmountUA' value={this.state.prepaymentAmountUA} onChange={this.unAllocatedPrepaymentAmount} />
                      )}
                    </Form.Item>

                    {this.renderFooterPrepayment2()}
                  </TabPane>
                </Tabs>
                <br />
                <Row key='totaling' className='backgrouGrey'>
                  <Col offset={11} span={9} style={{
                    'textAlign': 'right',
                    'marginRight': '10px',
                    'marginTop': '10px',
                    'marginBottom': '10px',
                  }}>
                    <span className='customBold2'>Total Prepayment : </span>
                  </Col>
                  <Col span={3}>
                    <Input
                      style={{
                        color: 'green',
                        marginTop: '10px',
                        marginRight: '5px',
                        marginBottom: '20px',
                        marginLeft: '20px',
                        textAlign: 'right',
                      }}
                      value={
                        parseFloat(
                          (this.state.totalAmount ? this.state.totalAmount : 0) +
                          (this.state.prepaymentTotalAmountDisplay ? this.state.prepaymentTotalAmountDisplay : 0) +
                          (this.state.prepaymentUA ? this.state.prepaymentUA : 0)
                        ).toFixed(2)
                      }
                      disabled={true} />
                  </Col>
                </Row>
                <br />
                <br />
                <Form className="submit-form">
                  <FormItem label="References" >
                    {getFieldDecorator('reference', {
                      rules: [{
                        required: true, message: 'Please input Reference!',
                      }],
                    })(
                      <Input onChange={this.setReferenceValue} key='reference' />
                    )}
                  </FormItem>

                  <FormItem label="Remark" >
                    {getFieldDecorator('remark', {
                      rules: [{
                        required: false, message: 'Please input Remark!',
                      }],
                    })(
                      <TextArea onChange={this.setRemarkValue} key='remark' rows={4} />
                    )}
                  </FormItem>
                </Form>

                <Button key='cancellation' type="primary" onClick={this.showModal} htmlType="submit" style={{ float: 'right' }}>Submit</Button>

                <Modal title="Confirmation" visible={this.state.visible}
                  onCancel={this.handleCancel}
                  key='mainModal'
                  footer={[
                    <Button key='cancellation' onClick={this.handleCancel}>Cancel</Button>,
                    <Popconfirm key='confirmation' title="Confirm to Proceed?" onConfirm={this.handleSubmit}>
                      <Button key='submitbutton' type='primary'>Submit</Button>
                    </Popconfirm>
                  ]}>
                  {
                    this.state.dataSourcePrepayment.length > 0 ? <Table columns={prepaymentItemsColumns} dataSource={this.state.dataSourcePrepayment} title={() => <span>Prepayment Item by Allocation</span>} pagination={false} rowKey="_id" /> : ''
                  }
                  <br />
                  {
                    this.state.prepaymentUA > 0 ?
                      <div>
                        <Row gutter={10}>
                          <Col span={10}><span>Prepayment Item by Amount</span></Col>
                          <Col span={6}><span><Input style={{ 'textAlign': 'right' }} value={parseFloat(this.state.prepaymentUA).toFixed(2)} disabled /></span></Col>
                        </Row>
                      </div>
                      : ''
                  }

                </Modal>
              </Col>
            </Row>
          </Card>
        </Spin>
      </Layout>
    )
  }
}

function mapStateToProps(state) {
  return {
    invoiceitems: state.invoiceitems,
    commons: state.commons
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchInvoiceitems: fetchInvoiceitems,
    pushInvoiceitems: pushInvoiceitems,
    removeInvoiceitems: removeInvoiceitems,
    updateActiveInvoiceitems: updateActiveInvoiceitems,
    updateInvoiceitems: updateInvoiceitems,
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(IndexKnockOffByPropertyunit)));
