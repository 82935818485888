import React from "react";

const SvgWater1 = props => (
  <svg height={512} viewBox="-31 0 479 479.874" width={512} {...props}>
    <path
      d="M120.438 335.875c77.382 0 120-45.457 120-128 0-77.281-109.391-200.152-114.047-205.336a8.251 8.251 0 00-11.906 0C109.827 7.723.437 130.594.437 207.875c0 82.543 42.617 128 120 128zm0-315.793c22.902 26.879 104 126.36 104 187.793 0 51.094-18.047 112-104 112s-104-60.906-104-112c0-61.434 81.093-160.914 104-187.793zm0 0M344.438 351.875c45.757 0 72-30.535 72-83.793 0-50.008-63.45-118.75-66.145-121.656a8.24 8.24 0 00-11.711 0c-2.695 2.906-66.145 71.648-66.145 121.656 0 53.258 26.239 83.793 72 83.793zm0-187.922c15.43 17.953 56 68.547 56 104.129 0 44.984-18.84 67.793-56 67.793s-56-22.809-56-67.793c0-35.582 40.566-86.176 56-104.129zm0 0M226.438 322.578c-2.04 2.313-50 57.09-50 93.297 0 40.672 20.414 64 56 64 35.582 0 56-23.328 56-64 0-36.207-47.961-90.984-50-93.297a8.265 8.265 0 00-12 0zm6 141.297c-26.536 0-40-16.152-40-48 0-21.746 25.03-57.145 40-75.586 14.976 18.402 40 53.809 40 75.586 0 31.848-13.465 48-40 48zm0 0"
      data-original="#000000"
      className="water_(1)_svg__active-path"
      data-old_color="#000000"
      fill="#8D99AE"
    />
    <path
      d="M103.637 303.836a8.005 8.005 0 008.793-7.563 8 8 0 00-7.192-8.359 58.61 58.61 0 01-42.746-21.824c-21.277-27.742-14.23-72.465-14.168-72.91a8.002 8.002 0 00-6.613-8.97 8.002 8.002 0 00-9.16 6.345c-.356 2.113-8.29 51.886 17.191 85.199a73.932 73.932 0 0053.895 28.082zm0 0M336.438 319.875a55.696 55.696 0 0039.128-16.855 47.815 47.815 0 008.758-40.465 7.998 7.998 0 00-15.773 2.64 32.819 32.819 0 01-5.137 27.414 39.825 39.825 0 01-26.976 11.266 8 8 0 000 16zm0 0M228.012 432.715a9.181 9.181 0 01-5.274-5.613 9.184 9.184 0 01.86-7.653c1.972-3.953.37-8.761-3.586-10.734-3.953-1.977-8.758-.371-10.735 3.582a25.185 25.185 0 00-1.718 19.89 25.197 25.197 0 0013.3 14.887 8.003 8.003 0 0010.739-3.586 8.003 8.003 0 00-3.586-10.734zm0 0"
      data-original="#000000"
      className="water_(1)_svg__active-path"
      data-old_color="#000000"
      fill="#8D99AE"
    />
  </svg>
);

export default SvgWater1;
