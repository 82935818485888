import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Table,
  Tabs,
} from "antd";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateMode } from "../../actions/actions-accounts";
import client from "../../feathers";
import { getBulkData } from "../functionContent";
import Layout from "../layout";
import requireAuth from "../requireAuth";

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
const dateFormat = "DD/MM/YYYY";
const { RangePicker } = DatePicker;
const TabPane = Tabs.TabPane;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};
const formatCurr = function format1(n = 0, currency = null) {
  let oriAmt = n;
  n = n < 0 ? Math.abs(n) : n;
  let amt = currency
    ? currency
    : "" +
      n.toFixed(2).replace(/./g, function(c, i, a) {
        return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
      });

  return oriAmt < 0 ? "(" + amt + ")" : amt;
};
const text = "Are you sure to delete this item?";

class LedgerIndexTransactions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ledgerAccount: [],
      dataSource: [],
      totalPagination: 1,
      CurrentPagination: 1,
      cbalance: 0,
      dbalance: 0,
      dateFilter: [moment().startOf("year"), moment().endOf("year")],
      loading: false,
      reportData: "",
      totalRecord: "",
      excelHeader: [
        {
          label: "Date",
          key: "trxDate",
        },
        {
          label: "Description",
          key: "description",
        },
        {
          label: "GL Reference",
          key: "referenceId",
        },
        {
          label: "Ref.2",
          key: "name",
        },
        {
          label: "Debit (RM)",
          key: "debitAmount",
          render: (v, record) => {
            if (record.description.toLowerCase().indexOf("b/f") >= 0) {
              return 0.0;
            }
            return v;
          },
        },
        {
          label: "Credit (RM)",
          key: "creditAmount",
        },
        {
          label: "Balance (RM)",
          key: "balance",
          render: (v, record) => {
            if (record.description.toLowerCase().indexOf("b/f") >= 0) {
              return 0.0;
            }
            return v;
          },
        },
      ],
    };
  }

  componentDidMount() {
    if (!_.isEmpty(this.props.selectedDate)) {
      this.setState({
        dateFilter: [
          moment(new Date("2020-05-27T14:26:42.195+08:00")).format(
            "YYYY-MM-DD"
          ),
          this.props.selectedDate,
        ],
      });
    }
  }

  async componentWillMount() {
    let companyId = this.props.commons.selectedCompany._id;
    this.setState({ loading: true });
    //get ledger account info
    client
      .authenticate()
      .then(async (res) => {
        let accountList = [this.props.match.params.id];

        this.processCSV();

        return axios.get(`${client.io.io.uri}ledgerTrxSummarybyAccounts`, {
          params: {
            companyId: companyId,
            accountList,
          },
          headers: {
            Authorization: client.settings.storage.storage["feathers-jwt"],
          },
        });
      })
      .then((res) => {
        this.setState({
          ledgerAccount: res,
          dbalance: _.get(res.data[0], "debitAmount"),
          cbalance: _.get(res.data[0], "creditAmount"),
          loading: false,
        });
      })
      .catch((err) => {
        alert(err);
        this.setState({ loading: false });
      });

    //this.getAccountBalance();
  }

  handleSubmit = (e) => {
    e.preventDefault();
  };

  async processCSV() {
    this.setState({ loading: true });
    let fromDate = "",
      toDate = "";

    if (_.isEmpty(this.state.dateFilter)) {
      fromDate = moment()
        .startOf("year")
        .format("YYYY-MM-DD");
      toDate = moment()
        .endOf("year")
        .format("YYYY-MM-DD");
    } else {
      fromDate = moment(new Date(this.state.dateFilter[0])).format(
        "YYYY-MM-DD"
      );
      toDate = moment(new Date(this.state.dateFilter[1])).format("YYYY-MM-DD");
    }

    let queryString = { IsDebtor: true };
    if (
      this.props.match.params.id === "5ff5aad0f85e2375d524f235" ||
      this.props.match.params.id === "6051d0cdd4c161377c809f71"
    ) {
      queryString = { isPrepayment: true };
    }
    let balanceBF = await axios.get(`${client.io.io.uri}ledgerTrxBalanceSOA`, {
      params: {
        startDate: fromDate,
        endDate: toDate,
        ledgerAccountId: this.props.match.params.id,
        ...queryString,
      },
      headers: {
        Authorization: client.settings.storage.storage["feathers-jwt"],
      },
    });

    let concatList = [];
    if (balanceBF.data.length > 0) {
      let concatObj = {};
      if (_.get(balanceBF.data, [0, "_id"]) === undefined) {
        concatObj.description = balanceBF.data[0]
          ? balanceBF.data[0].description
          : "";
        concatObj.debitAmount = balanceBF.data[0]
          ? balanceBF.data[0].debitAmount
          : "";
        concatObj.creditAmount = balanceBF.data[0]
          ? balanceBF.data[0].creditAmount
          : "";
        concatObj.trxDate = balanceBF.data[0] ? balanceBF.data[0].trxDate : "";
        concatList.push(concatObj);
      }
    }

    let dateRange = {};
    if (!_.isEmpty(this.state.dateFilter)) {
      dateRange = {
        trxDate: {
          $gte: new Date(
            moment(new Date(this.state.dateFilter[0])).startOf("day")
          ),
          $lte: new Date(
            moment(new Date(this.state.dateFilter[1])).endOf("day")
          ),
        },
      };
    }

    let queryReport = {
      ledgerAccountId: this.props.match.params.id,
      companyId: this.props.commons.selectedCompany._id,
      ...dateRange,
      eventCode: { $nin: ["PREPAYMENT", "PREPAYMENTUA", "MONTHLYCLOSING"] },
      remark: { $ne: "PREPAYMENT" },
      $sort: {
        trxDate: 1,
        referenceId: -1,
      },
      $populate: [
        {
          path: "invoiceId",
          ref: "invoices",
          populate: [{ path: "propertyunitId", ref: "propertyunits" }],
        },
        {
          path: "transactionId",
          ref: "transaction",
          populate: [{ path: "propertyunitId", ref: "propertyunits" }],
        },
        {
          path: "paymentsVoucherMasterId",
          ref: "paymentsvouchermasters",
        },
        {
          path: "refundId",
          ref: "refunds",
          populate: [{ path: "propertyunitId", ref: "propertyunits" }],
        },
      ],
    };

    if (
      this.props.match.params.id === "5ff5aad0f85e2375d524f235" ||
      this.props.match.params.id === "6051d0cdd4c161377c809f71"
    ) {
      queryReport = {
        ledgerAccountId: this.props.match.params.id,
        companyId: this.props.commons.selectedCompany._id,
        ...dateRange,
        $sort: {
          trxDate: 1,
          referenceId: -1,
        },
        $populate: [
          {
            path: "invoiceId",
            ref: "invoices",
            populate: [{ path: "propertyunitId", ref: "propertyunits" }],
          },
          {
            path: "transactionId",
            ref: "transaction",
            populate: [{ path: "propertyunitId", ref: "propertyunits" }],
          },
          {
            path: "paymentsVoucherMasterId",
            ref: "paymentsvouchermasters",
          },
        ],
      };
    }

    let reportLedgerAccount = await getBulkData(
      "ledger-transactions",
      queryReport,
      50
    );
    reportLedgerAccount = concatList.concat(
      reportLedgerAccount ? reportLedgerAccount : []
    );

    // console.log({reportLedgerAccount});
    reportLedgerAccount = _.sortBy(reportLedgerAccount, [
      "invoiceId.invoiceDate",
      "asc",
    ]);

    let totalDebit = 0;
    let totalCredit = 0;
    let totalTotalDebit = 0;
    let totalTotalCredit = 0;
    let totalRecord = {},
      balanceCF = {},
      totalDebitAmount = {},
      totalCreditAmount = {};

    let reportData = _.map(reportLedgerAccount, function(value, i) {
      if (i > 0) {
        value.balance = parseFloat(
          reportLedgerAccount[i - 1].balance +
            (value.creditAmount
              ? value.creditAmount
                ? value.creditAmount * -1
                : 0
              : value.debitAmount
              ? value.debitAmount
              : 0)
        );
      } else {
        value.balance = parseFloat(
          value.creditAmount
            ? value.creditAmount
              ? value.creditAmount * -1
              : 0
            : value.debitAmount
            ? value.debitAmount
            : 0
        );
      }

      if (!(value.description.toLowerCase().indexOf("b/f") >= 0)) {
        if (value.debitAmount) {
          totalTotalDebit += value.debitAmount ? value.debitAmount : 0;
        } else {
          totalTotalCredit += value.creditAmount ? value.creditAmount : 0;
        }
      }

      if (value.debitAmount) {
        totalDebit += value.debitAmount ? value.debitAmount : 0;
      } else {
        totalCredit += value.creditAmount ? value.creditAmount : 0;
      }

      const unitNoOwner =
        _.get(value, ["invoiceId", "propertyunitId", "owner", "name"]) ||
        _.get(value, ["transactionId", "propertyunitId", "owner", "name"]);
      const unitNoProperty =
        _.get(value, ["invoiceId", "propertyunitId", "unitNo"]) ||
        _.get(value, ["transactionId", "propertyunitId", "unitNo"]);

      const unitDescription = value && value.description.split("-");
      let correctDescription = "";
      if (unitDescription[0] === "OR") {
        correctDescription = "";
      } else {
        correctDescription = value.description;
      }

      value.name =
        _.get(value, ["invoiceId", "propertyunitId", "owner", "name"]) ||
        _.get(value, ["refundId", "propertyunitId", "owner", "name"]);
      _.get(value, ["transactionId", "reference"]);

      value.description = `${unitNoProperty ? unitNoProperty + " " : ""}${
        unitNoOwner ? unitNoOwner + " " : ""
      }${correctDescription}`;

      if (_.get(value, ["paymentsVoucherMasterId", "referenceNo"])) {
        value.referenceId = _.get(value, [
          "paymentsVoucherMasterId",
          "referenceNo",
        ]);
      }
      if (_.get(value, ["refundId", "propertyunitId", "unitNo"])) {
        value.propertyunitNo = `${_.get(value, [
          "refundId",
          "propertyunitId",
          "unitNo",
        ]) +" - "+ _.get(value, ["name"])}`;
      }

      if (_.get(value, ["debitAmount"]) === undefined) {
        value.debitAmount = 0;
      }
      if (_.get(value, ["creditAmount"]) === undefined) {
        value.creditAmount = 0;
      }
      value.trxDate = moment(value.trxDate).format("DD/MM/YYYY");

      return value;
    });

    concatList.concat(reportData);

    totalRecord.debitAmount = totalTotalDebit ? totalTotalDebit : 0;
    totalRecord.creditAmount = totalTotalCredit ? totalTotalCredit : 0;
    totalRecord.description = "Total :";

    if (totalDebit > totalCredit) {
      balanceCF.debitAmount = totalDebit - totalCredit;
    } else if (totalCredit > totalDebit) {
      balanceCF.debitAmount = totalCredit - totalDebit;
    }

    balanceCF.description = "Balance c/f :";

    totalDebitAmount.debitAmount = totalTotalDebit ? totalTotalDebit : 0;
    totalDebitAmount.description = "Total Debits :";

    totalCreditAmount.creditAmount = totalTotalCredit ? totalTotalCredit : 0;
    totalCreditAmount.description = "Total Credits :";

    let tempArr = [];
    tempArr.push(totalRecord);
    tempArr.push(balanceCF);
    tempArr.push(totalDebitAmount);
    tempArr.push(totalCreditAmount);

    let newReportData = _.cloneDeep(reportData);
    _.forEach(tempArr, function(a) {
      newReportData.push(a);
    });

    const isDeposit = this.props.match.params.id === "6051d0cdd4c161377c809fb7";

    if (isDeposit) {
      const newHead = [
        {
          label: "Date",
          key: "trxDate",
        },
        {
          label: "Description",
          key: "description",
        },

        {
          label: "GL Reference",
          key: "referenceId",
        },
        {
          label: "Property Unit",
          key: "propertyunitNo",
        },
        {
          label: "Ref.2",
          key: "name",
        },
        {
          label: "Debit (RM)",
          key: "debitAmount",
          render: (v, record) => {
            if (record.description.toLowerCase().indexOf("b/f") >= 0) {
              return 0.0;
            }
            return v;
          },
        },
        {
          label: "Credit (RM)",
          key: "creditAmount",
        },
        {
          label: "Balance (RM)",
          key: "balance",
          render: (v, record) => {
            if (record.description.toLowerCase().indexOf("b/f") >= 0) {
              return 0.0;
            }
            return v;
          },
        },
      ];
      this.setState({ excelHeader: newHead });
    }

    this.setState(
      {
        reportData: newReportData,
        dataSource: reportData,
        totalRecord: tempArr,
        loading: false,
      },
      () => {
        this.setState({ totalPagination: reportData.length });
      }
    );
  }

  convertDate(date) {
    return moment(date).format("DD/MM/YYYY");
  }

  formatAmt(amt) {
    let tempAmt = amt;
    return tempAmt > 0 ? tempAmt.toFixed(2) : 0.0;
  }
  onChangeCalendar = (value) => {
    if (_.get(value, 1)) {
      this.setState({ dateFilter: value }, () => {
        this.processCSV();
      });
    }
  };

  onChange = (value) => {
    if (_.isEmpty(value)) {
      ///check when is clear
      this.setState({ dateFilter: [] }, () => {
        this.processCSV();
      });
    }
  };

  render() {
    const { visible, onCancel } = this.props;
    const { getFieldDecorator } = this.props.form;
    const dataSource = this.state.dataSource;
    const totalRecord = this.state.totalRecord;
    const totalPagination = this.state.totalPagination;
    const dbalance = this.state.dbalance;
    const cbalance = this.state.cbalance;
    const isDeposit = this.props.match.params.id === "6051d0cdd4c161377c809fb7";

    let columns = [
      {
        title: "Date",
        dataIndex: "trxDate",
        key: "trxDate",
        width: "8%",
        render: (v, r) => <span>{v}</span>,
      },
      {
        title: "Description",
        width: "15%",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "GL Reference",
        dataIndex: "referenceId",
        key: "reference",
        width: "8%",
        render: (text, record) => {
          if (_.get(record, ["paymentsVoucherMasterId", "referenceNo"])) {
            return (
              <span>
                {_.get(record, ["paymentsVoucherMasterId", "referenceNo"])}
              </span>
            );
          } else {
            return text;
          }
        },
      },
      {
        title: "Ref.2",
        width: "10%",
        key: "name",
        render: (record) => {
          if (_.get(record, ["transactionId", "reference"])) {
            return <span>{_.get(record, ["transactionId", "reference"])}</span>;
          } else {
            return (
              <span>
                {_.get(record, [
                  "invoiceId",
                  "propertyunitId",
                  "owner",
                  "name",
                ])}
              </span>
            );
          }
        },
      },
      {
        title: "Debit (RM)",
        width: "7%",
        dataIndex: "debitAmount",
        key: "debitAmount",
        align: "right",
        render: (text, record) => {
          if (record.description.toLowerCase().indexOf("b/f") >= 0) {
            return <span style={{ float: "right" }}>{formatCurr(0.0)}</span>;
          }
          return <span style={{ float: "right" }}>{formatCurr(text)}</span>;
        },
      },
      {
        title: "Credit (RM)",
        width: "7%",
        dataIndex: "creditAmount",
        key: "creditAmount",
        align: "right",
        render: (text, record) => {
          if (record.description.toLowerCase().indexOf("b/f") >= 0) {
            return <span style={{ float: "right" }}>{formatCurr(0.0)}</span>;
          }
          return <span style={{ float: "right" }}>{formatCurr(text)}</span>;
        },
      },

      {
        title: "Balance (RM)",
        width: "8%",
        dataIndex: "balance",
        key: "balance",
        align: "right",
        render: (text, record) => {
          return <span style={{ float: "right" }}>{formatCurr(text)}</span>;
        },
      },
    ];

    if (isDeposit) {
      columns = [
        {
          title: "Date",
          dataIndex: "trxDate",
          key: "trxDate",
          width: "8%",
          render: (v, r) => <span>{v}</span>,
        },
        {
          title: "Description",
          width: "15%",
          dataIndex: "description",
          key: "description",
        },
        {
          title: "GL Reference",
          dataIndex: "referenceId",
          key: "reference",
          width: "8%",
          render: (text, record) => {
            if (_.get(record, ["paymentsVoucherMasterId", "referenceNo"])) {
              return (
                <span>
                  {_.get(record, ["paymentsVoucherMasterId", "referenceNo"])}
                </span>
              );
            } else {
              return text;
            }
          },
        },
        {
          title: "Property Unit",
          width: "7%",
          render: (record) => {
            if (_.get(record, ["refundId", "propertyunitId", "unitNo"])) {
              return (
                <span>
                  {_.get(record, ["refundId", "propertyunitId", "unitNo"])}
                </span>
              );
            } else {
              return <span>-</span>;
            }
          },
        },
        {
          title: "Ref.2",
          width: "10%",
          render: (record) => {
            if (_.get(record, ["transactionId", "reference"])) {
              return (
                <span>{_.get(record, ["transactionId", "reference"])}</span>
              );
            } else if (
              _.get(record, ["refundId", "propertyunitId", "owner", "name"])
            ) {
              return (
                <span>
                  {_.get(record, [
                    "refundId",
                    "propertyunitId",
                    "owner",
                    "name",
                  ])}
                </span>
              );
            } else {
              return (
                <span>
                  {_.get(record, [
                    "invoiceId",
                    "propertyunitId",
                    "owner",
                    "name",
                  ])}
                </span>
              );
            }
          },
        },
        {
          title: "Debit (RM)",
          width: "7%",
          dataIndex: "debitAmount",
          key: "debitAmount",
          align: "right",
          render: (text, record) => {
            if (record.description.toLowerCase().indexOf("b/f") >= 0) {
              return <span style={{ float: "right" }}>{formatCurr(0.0)}</span>;
            }
            return <span style={{ float: "right" }}>{formatCurr(text)}</span>;
          },
        },
        {
          title: "Credit (RM)",
          width: "7%",
          dataIndex: "creditAmount",
          key: "creditAmount",
          align: "right",
          render: (text, record) => {
            if (record.description.toLowerCase().indexOf("b/f") >= 0) {
              return <span style={{ float: "right" }}>{formatCurr(0.0)}</span>;
            }
            return <span style={{ float: "right" }}>{formatCurr(text)}</span>;
          },
        },

        {
          title: "Balance (RM)",
          width: "8%",
          dataIndex: "balance",
          key: "balance",
          align: "right",
          render: (text, record) => {
            return <span style={{ float: "right" }}>{formatCurr(text)}</span>;
          },
        },
      ];
    }

    const newColumns = [
      {
        width: "8%",
      },
      {
        width: "8%",
      },
      {
        width: "7%",
      },
      {
        width: "10%",
      },
      {
        dataIndex: "description",
        width: "21%",
        key: "description",
        align: "right",
        render: (text, record) => {
          if (text === "Total :" || text === "Balance c/f :") {
            return <b>{text}</b>;
          } else {
            return text;
          }
        },
      },
      {
        dataIndex: "debitAmount",
        key: "debitAmount",
        width: "8%",
        align: "right",
        render: (text, record) => {
          if (typeof text === "number") {
            return <span style={{ float: "right" }}>{formatCurr(text)}</span>;
          } else {
            return null;
          }
        },
      },
      {
        dataIndex: "creditAmount",
        key: "creditAmount",
        width: "8%",
        align: "right",
        render: (text, record) => {
          if (typeof text === "number") {
            return <span style={{ float: "right" }}>{formatCurr(text)}</span>;
          } else {
            return null;
          }
        },
      },
      {
        dataIndex: "balance",
        key: "balance",
        width: "8%",
      },
    ];

    let accountInfo = this.state.ledgerAccount.data
      ? this.state.ledgerAccount.data.length > 0
        ? this.state.ledgerAccount.data[0].accountInfo
        : ""
      : "";
    let propertyName = this.props.commons.selectedCompany.name
      ? this.props.commons.selectedCompany.name
      : "";
    let date = this.state.dateFilter,
      dateObj = {};
    if (_.isEmpty(date)) {
      dateObj = moment()
        .startOf("year")
        .format("DD/MM/YYYY");
    }

    return (
      <Layout>
        <Spin key="spinning" spinning={this.state.loading}>
          <div style={{ marginLeft: "80%", marginBottom: "10px" }}>
            <RangePicker
              on
              format={dateFormat}
              onCalendarChange={this.onChangeCalendar}
              onChange={this.onChange}
            />
          </div>

          <Card
            title={accountInfo ? accountInfo.accountName + " Transactions" : ""}
            extra={[
              !_.isEmpty(this.state.reportData) ? (
                <CSVLink
                  filename={`${propertyName}_${accountInfo.accountName}_General ledgers_${this.state.dateFilter}
                .csv`}
                  data={this.state.reportData}
                  headers={this.state.excelHeader}
                >
                  <Button type="primary">Export to CSV</Button>
                </CSVLink>
              ) : null,
            ]}
          >
            <Row>
              <Col span={24}>
                <Table
                  dataSource={dataSource}
                  bordered={true}
                  columns={columns}
                  footer={() => {
                    return (
                      <Table
                        dataSource={this.state.totalRecord}
                        columns={newColumns}
                        showHeader={false}
                        bordered={false}
                        pagination={false}
                      />
                    );
                  }}
                  pagination={{
                    total: totalPagination,
                    defaultCurrent: 1,
                    pageSize: 20,
                  }}
                  rowKey="_id"
                />
              </Col>
            </Row>
          </Card>
        </Spin>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    accounts: state.accounts,
    commons: state.commons,
    selectedDate: state.ledgeraccounts.selectedDate,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateMode: updateMode,
    },
    dispatch
  );
}

export default requireAuth(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Form.create()(LedgerIndexTransactions))
);
