export const UPDATE_VALUEIDN = 'UPDATE_VALUEIDN';
export const UPDATE_VALUEREMINDER = 'UPDATE_VALUEREMINDER';
export const UPDATE_VALUEPROPERTYUNITID = 'UPDATE_VALUEPROPERTYUNITID';
export const REFRESH_PAGE = 'REFRESH_PAGE';
export const SELECTED_COMPANY = 'SELECTED_COMPANY';
export const SELECTED_ROLE = 'SELECTED_ROLE';
//InterestDebitNode
export function updateValueIDN(mode, status, debtorAcc, propertyunit) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_VALUEIDN,
      mode,
      status,
      debtorAcc,
      propertyunit,
    });
  }
}

export function updateValueReminder(mode, filterAmount) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_VALUEREMINDER,
      mode,
      filterAmount,

    });
  }
}

export function updateValuePropertyUnitId(mode, propertyUnitId) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_VALUEPROPERTYUNITID,
      mode,
      propertyUnitId,

    });
  }
}

export function refreshPage(mode) {
  return (dispatch) => {
    dispatch({
      type: REFRESH_PAGE,
      mode,
    });
  }
}

export function selectedCompany(record) {
  return (dispatch) => {
    dispatch({ type: SELECTED_COMPANY, selectedCompany: record });
  }
}

export function selectedRole(record) {
  return (dispatch) => {
    dispatch({ type: SELECTED_ROLE, selectedRole: record });
  }
}
