import React, { Component, useEffect } from 'react';
import { Modal, Upload, Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Select, TimePicker, Row, Col, Spin, Card, Tabs } from 'antd';
import client from '../../feathers';
import requireAuth from "../requireAuth";
import Layout from "../layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateMode, updateActiveRequests } from '../../actions/actions-requests';
import moment from 'moment';
import Info from "../common/info";
import ImageZoom from 'react-medium-image-zoom';
import _ from 'lodash';

const TabPane = Tabs.TabPane;
const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
const format = 'HH:mm';

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};

const text = 'Are you sure to delete this item?';

class RequestsFormMng extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',
      user: {},
      propertyunit: {},
      property: {},
      fetching: false,
      loading: false,
      propertyunitsDropdowns: [],
      userType: [],
      numberPrimary: '',
      numberSecondary: '',
      owner: {},
      ownerPropertyunit: {}
    }
  }

  componentDidUpdate() {



    if (this.props.requests.activeId !== this.state._id) {

      this.setState({
        _id: this.props.requests.activeId,
        user: this.props.requests.activeRequests.userId,
        propertyunit: this.props.requests.activeRequests.propertyunitId,
        property: this.props.requests.activeRequests.propertyId,
      });
      this.searchPropertyunit();
    }

  }

  handleSubmit = (e, type, isChange) => {

    let { numberPrimary, numberSecondary } = this.state
    let isChangeQuery = ''

    if (isChange) {
      isChangeQuery = { isChange: true }

    }


    if (numberPrimary >= 1 && type === 'primary') {
      return message.error('This property unit already have primary user.')

    } else if (numberSecondary >= 5 && type === 'secondary') {
      return message.error('This property unit already have 5 secondary user.')
    }

    this.setState({ loading: true });
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let requestId = this.props.requests.activeRequests._id
        client.service('requests').patch(requestId,
          {
            status: 'APPROVED',
            type: type,
            ...isChangeQuery
          }
        )
          .then((res) => {

            message.success(`Approved`);
            this.setState({
              visible: false,
              loading: true
            });
            this.props.updateMode('refresh')
          })
          .catch((err) => {
            alert(err);
            console.log(err);
          })
        return;
      }
    });
  }

  handleReject = (e) => {

    this.setState({ loading: true });
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let requestId = this.props.requests.activeRequests._id
        client.service('requests').patch(requestId,
          {
            status: 'REJECTED'
          }
        )
          .then((res) => {

            message.success(`Rejected`);
            this.setState({
              visible: false,
              loading: true
            });
            this.props.updateMode('refresh')
          })
          .catch((err) => {
            alert(err);
            console.log(err);
          })
        return;
      }
    });
  }

  componentWillMount() {

    //generate property unit dropdown
    client.authenticate()
      .then((res) => {
        return client.service('propertyunits').find({
          query: {
            propertyId: this.props.user.info.propertyId._id,
            $sort: {
              createdAt: -1
            },
            $limit: 10,
          }
        })
      })
      .then((res) => {
        this.setState({ propertyunitsDropdowns: res.data });
        this.props.updateMode('refresh')
      })
      .catch((err) => {
        console.log(err);
      });
  }

  searchPropertyunit() {
    client.authenticate()
      .then((res) => {
        return client.service('propertyunits').find({
          query: {
            _id: this.state.propertyunit._id
          }
        })
      })
      .then((res) => {

        let numberA = 0, numberB = 0
        _.forEach(res.data[0].users, function (a) {
          if (a.userType === 'primary') {
            numberA += 1
          }
          else if (a.userType === 'secondary') {
            numberB += 1
          }
        })

        this.setState({
          owner: res.data[0].owner ? res.data[0].owner : [],
          numberPrimary: numberA,
          numberSecondary: numberB,
          ownerPropertyunit: res.data[0]
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  }

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  }

  handleCancel = (e) => {
    this.props.updateMode('refresh')
  }

  _submittingFooter = () => {
    let data = this.props.requests.activeRequests
    // console.log(data)


    if (data.status === 'APPROVED') {
      let currentUserId = data.userId._id
      let changeType = ''
      let propertyUsers = data.propertyunitId.users
      propertyUsers = _.find(propertyUsers, function (a) {

        return a.userId === currentUserId
      })
      // console.log(propertyUsers.userId, currentUserId, propertyUsers.userType)
      if (propertyUsers.userType === 'primary') {

        changeType = 'secondary'
      }
      else if (propertyUsers.userType === 'secondary') {
        changeType = 'primary'

      }
      let showChangeType = changeType.charAt(0).toUpperCase() + changeType.slice(1)



      return (<div>
        <Button type="primary" onClick={(e) => { this.handleSubmit(e, changeType, true) }}>{`Set As (${showChangeType})`}</Button>
        <Button type="danger" onClick={this.handleReject} >Reject</Button>
        <Button onClick={this.handleCancel} >Cancel</Button>
      </div>
      );
    } else {
      return ([
        <Button type="primary" onClick={(e) => { this.handleSubmit(e, 'primary') }} >Approve (Primary)</Button>,
        <Button onClick={(e) => { this.handleSubmit(e, 'secondary') }} >Approve (Secondary)</Button>,
        data.status === 'APPROVED' ? <Button type="danger" onClick={this.handleReject} >Reject</Button> : null,
        <Button type="primary" onClick={this.handleCancel} >Cancel</Button>
      ]);
    }
  }

  render() {
    const { visible, onCancel } = this.props;
    const { getFieldDecorator } = this.props.form;
    const requestDataSource = this.props.requests.activeRequests


    return (

      <Modal
        visible={
          this.props.requests.mode === 'view' ? true : false
        }

        title="Approval"
        onCancel={this.handleCancel}
        width='1300px'
        footer={this._submittingFooter()}
      >
        <Row>
          <Col span={12} >
            <Card title={'System Info'} >
              {/* {this.renderSearching()} */}
              <Info label="Unit Number" labelSpan={6} value={this.state.ownerPropertyunit.referenceId} valueSpan={18} />
              <Info label="Owner Name" labelSpan={6} value={this.state.owner.name} valueSpan={18} />
              <Info label="Contact Number" labelSpan={6} value={this.state.owner.contactNumber} valueSpan={18} />
              <Info label="Mailing Address" labelSpan={6} value={this.state.owner.mailingAddress} valueSpan={18} />
              <Info label="E-mail" labelSpan={6} value={this.state.owner.email} valueSpan={18} />
              <Info label="Remark" labelSpan={6} value={this.state.owner.remark} valueSpan={18} />
            </Card>
            {/*   <img src={ this.state.owner.file } style={{width:'50%',backgroundColor: 'aliceblue'}} /> */}

            <Card title={'Request from Apps'} >
              <Info label="Property Name" labelSpan={6} value={this.state.property.name} valueSpan={18} />
              <Info label="Property Unit" labelSpan={6} value={this.state.propertyunit.referenceId} valueSpan={18} />
              {/*<Info label="First Name" labelSpan={6}  value={this.state.user.firstName?this.state.user.firstName:''} valueSpan={18} />
                <Info label="Last Name" labelSpan={6}  value={this.state.user.lastName?this.state.user.lastName:''} valueSpan={18} />*/}
              <Info label="Status" labelSpan={6} value={requestDataSource.status} valueSpan={18} />
            </Card>
          </Col>

          <Col span={11} offset={1}>
            <Tabs defaultActiveKey="1">
              <TabPane tab="System Info Image" key="1">
                <img src={this.state.owner.file} style={{ width: '60%', backgroundColor: 'aliceblue' }} />
              </TabPane>
              <TabPane tab="Request from Apps Image" key="2">
                <img src={requestDataSource.file} style={{ width: '60%', backgroundColor: 'aliceblue' }} />
              </TabPane>
            </Tabs>



          </Col>


        </Row>
      </Modal>
    )
  }
}


function mapStateToProps(state) {
  return {
    requests: state.requests
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode,
    updateActiveRequests: updateActiveRequests
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(RequestsFormMng)));
