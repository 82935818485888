import React from "react";

const SvgFoodAndRestaurant = props => (
  <svg height={512} viewBox="0 0 64 64" width={512} {...props}>
    <path
      fill="#8D99AE"
      d="M57 37.09V34a7.008 7.008 0 00-7-7H14a7.008 7.008 0 00-7 7v3.09a5.993 5.993 0 000 11.82V50a5.006 5.006 0 005 5h40a5.006 5.006 0 005-5v-1.09a5.993 5.993 0 000-11.82zM9 34a5.006 5.006 0 015-5h36a5.006 5.006 0 015 5v3H9zm46 16a3 3 0 01-3 3H12a3 3 0 01-3-3v-1h46zm1-3H8a4 4 0 010-8h48a4 4 0 010 8z"
      data-original="#000000"
      className="food-and-restaurant_svg__active-path"
      data-old_color="#000000"
    />
    <path
      fill="#8D99AE"
      d="M49.33 43a7.943 7.943 0 01-3.917-.908A9.953 9.953 0 0040.659 41a9.943 9.943 0 00-4.752 1.092 7.931 7.931 0 01-3.913.908 7.94 7.94 0 01-3.915-.908A9.946 9.946 0 0023.327 41a9.939 9.939 0 00-4.751 1.092 7.936 7.936 0 01-3.914.908 7.927 7.927 0 01-3.912-.908A9.937 9.937 0 006 41v2a7.927 7.927 0 013.912.908A9.94 9.94 0 0014.662 45a9.946 9.946 0 004.752-1.092A7.928 7.928 0 0123.327 43a7.933 7.933 0 013.914.908A9.948 9.948 0 0031.994 45a9.939 9.939 0 004.751-1.092A7.936 7.936 0 0140.659 43a7.941 7.941 0 013.916.908 10.894 10.894 0 009.508 0A7.949 7.949 0 0158 43v-2a9.953 9.953 0 00-4.754 1.092A7.947 7.947 0 0149.33 43zM13 31h2v2h-2zM19 33h2v2h-2zM25 31h2v2h-2zM37 31h2v2h-2zM43 33h2v2h-2zM49 31h2v2h-2zM31 33h2v2h-2zM34 11h-2c0 .562-.217.8-.708 1.294a3.484 3.484 0 000 5.416c.491.49.708.731.708 1.294s-.217.8-.707 1.291A3.563 3.563 0 0030 23h2c0-.561.217-.8.707-1.291a3.48 3.48 0 000-5.413c-.49-.49-.707-.732-.707-1.296s.217-.8.708-1.294A3.568 3.568 0 0034 11zM28 13h-2c0 .562-.217.8-.708 1.294a3.484 3.484 0 000 5.416c.491.49.708.731.708 1.294s-.217.8-.707 1.291A3.563 3.563 0 0024 25h2c0-.561.217-.8.707-1.291a3.48 3.48 0 000-5.413c-.49-.49-.707-.732-.707-1.296s.217-.8.708-1.294A3.568 3.568 0 0028 13zM40 13h-2c0 .562-.217.8-.708 1.294a3.484 3.484 0 000 5.416c.491.49.708.731.708 1.294s-.217.8-.707 1.291A3.563 3.563 0 0036 25h2c0-.561.217-.8.707-1.291a3.48 3.48 0 000-5.413c-.49-.49-.707-.732-.707-1.296s.217-.8.708-1.294A3.568 3.568 0 0040 13z"
      data-original="#000000"
      className="food-and-restaurant_svg__active-path"
      data-old_color="#000000"
    />
  </svg>
);

export default SvgFoodAndRestaurant;
