import React, { Component } from 'react';
import { Modal, message, Form, Input, Select, Checkbox } from 'antd';
import client from '../../feathers';
import requireAuth from "../../components/requireAuth";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateMode } from '../../actions/actions-ledgerAccounts';

const FormItem = Form.Item;
const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const Option = Select.Option;


class ledgerAccountsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',
      ledgeraccounts: {},

    }
  }
  componentDidUpdate() {
    if (this.props.ledgeraccounts.activeId !== this.state._id) {
      this.setState({
        _id: this.props.ledgeraccounts.activeId
      })

      if (this.props.ledgeraccounts.activeLedgerAccounts) {
        this.props.form.setFieldsValue(
          this.props.ledgeraccounts.activeLedgerAccounts
        )
      }
    }
  }

  componentWillMount() {

  }


  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (values.specialCode) {
          values.specialCode = 'RENODEPOSIT'
        }
        else {
          values.specialCode = ''
        }
        client.service('ledger-accounts').patch(this.props.ledgeraccounts.activeId,
          values
        )
          .then((res) => {

            message.success(`Record Updated`);
            this.props.updateMode('refresh')
          })
          .catch((err) => {
            console.log(err);

            this.props.updateMode('refresh')
          })
        return;
      }

    });

  }

  render() {

    const { getFieldDecorator } = this.props.form;

    return (
      <Modal
        visible={this.props.ledgeraccounts.mode === 'edit' ? true : false}
        title={this.props.ledgeraccounts.mode === 'edit' ? 'Update ledgerAccounts' : 'Create ledgerAccounts'}
        okText={this.props.ledgeraccounts.mode === 'edit' ? 'Update' : 'Create'}
        onCancel={() => { this.props.updateMode(''); this.props.form.resetFields() }}
        onOk={this.handleSubmit}
      >

        <Form className="submit-form">

          <FormItem
            {...formItemLayout}
            label="Categories"
            hasFeedback
          >
            {getFieldDecorator('categories', {
              rules: [
                { required: true, message: 'Select Categories!' },
              ],
            })(
              <Input disabled style={{ width: 200 }} placeholder="ASSETS" />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Account Type"
            hasFeedback

          >
            {getFieldDecorator('accountType', {
              rules: [
                { required: true, message: 'Select Type!' },
              ],
            })(
              <Input disabled />

            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Account Name"
          >
            {getFieldDecorator('accountName', {
              rules: [{
                required: true, message: 'Please input Accounts Description!',
              }],
            })(
              <Input placeholder="Lim Motors" />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Account No."
          >
            {getFieldDecorator('accountNo', {
              rules: [{
                required: true, message: 'Please input Account Number!',
              }],
            })(
              <Input disabled />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Sub Account No "
          >
            {getFieldDecorator('subAccountNo', {
              rules: [{
                required: true, message: 'Please input Account Number!',
              }],
            })(
              <Input placeholder="000" />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Description"
          >
            {getFieldDecorator('description', {
              rules: [{
                required: true, message: 'Please input description!',
              }],
            })(
              <Input placeholder="" />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Special Code"
          >
            {getFieldDecorator('specialCode', {
              rules: [{
                required: false, message: 'Please input Special Code!',
              }],
            })(
              <Checkbox>Reno Deposit</Checkbox>
            )}
          </FormItem>

        </Form>
      </Modal>

    )
  }
}


function mapStateToProps(state) {
  return {
    ledgeraccounts: state.ledgeraccounts
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(ledgerAccountsForm)));
