import React, { Component } from 'react';
import { Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Card, Spin, Tooltip, Popover, Row, Col, Select } from 'antd';
import { Route, Link } from 'react-router-dom';

import client from '../../feathers';
import requireAuth from "../../components/requireAuth";
import Layout from "../../components/layout";
import UploadsForm from "../../components/uploads/form-firstOpeningBalance";
import { CSVLink, CSVDownload } from "react-csv";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from 'axios';
import {
  fetchUploads,
  pushUploads,
  removeUploads,
  updateMode,
  updateActiveUploads,
  updateUploads
} from '../../actions/actions-uploads';
import moment from "moment";


//---------------------------------------
const FormItem = Form.Item;
const Option = Select.Option;
const text = 'Are you sure to delete this item?';
const text2 = 'Are you sure to process this item?';
const informationText =
  (
    <div>
      <Row><span> <Icon type="bulb" theme="twoTone" /> Please download templates and input accordingly.</span></Row>
      <Row><span>Value in <span style={{ color: "red" }}>Red</span> are Required to input</span></Row>
      <Row><span>-----------------</span></Row>
      <Row><span>1.<span style={{ color: "red" }}>Opening Date</span> </span></Row>
      <Row><span>2.<span style={{ color: "red" }}>Debit Amount</span> </span></Row>
      <Row><span>3.<span style={{ color: "red" }}>Credit Amount</span> </span></Row>
      <Row><span>* Debit amount and Credit amount   </span></Row>
    </div>
  )

const _ = require('lodash');
class UploadsIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      loading: false,
      uploads: [],
      loading: false,
      propertyunitsDropdowns: [],
      searchPropertyUnit: '',
      queryString: '',
      excelHeader: [
        { label: "Unit", key: "referenceId" },
        { label: "Name", key: "owner.name" },
        { label: "openingDate", key: "" },
        { label: "debitAmount", key: "" },
        { label: "creditAmount", key: "" },
      ],
      excelHeader2: [
        { label: "Category", key: "categories" },
        { label: "Acc", key: "fullAccountNo" },
        { label: "Acc Name", key: "description" },
        { label: "openingDate", key: "" },
        { label: "debitAmount", key: "" },
        { label: "creditAmount", key: "" },
      ],
      excelData: [],
      excelData2: [],
    }



    this.handleDelete = this.handleDelete.bind(this);
    this.handleProcess = this.handleProcess.bind(this);
  }

  componentDidUpdate() {
    if (this.props.uploads.mode === 'refresh') {
      this.componentWillMount()
    }


  }

  componentDidMount() { }

  componentWillMount() {
    this.props.updateMode('');

    this.setState({ loading: true });

    client.authenticate()
      .then(() => {
        return client.service('uploads').find({
          query: {
            companyId: this.props.commons.selectedCompany._id,
            $populate: 'propertyunitId',
            $sort: {
              readDate: -1,
              propertyunitId: 1,
            },
            function: { $in: ['NonDebtorOpenBal', 'debtorOpenBal'] },
          }
        })
      })
      .then((res) => {

        this.setState({ loading: false });
        this.props.fetchUploads(res.data)

        return client.service('propertyunits').find({
          query: {
            propertyId: this.props.commons.selectedCompany._id,
            $sort: {
              referenceId: 1,
            }
          }
        })

      })
      .then((res1) => {

        this.setState({
          excelData: res1.data,
        });

        return client.service('ledger-accounts').find({
          query: {
            companyId: this.props.commons.selectedCompany._id,
            accountNo: { $ne: '3000' },
            $sort: {
              fullAccountNo: 1
            },
          }
        })

      })
      .then((res2) => {
        this.setState({
          excelData2: res2.data,
        });

      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      })
  }

  _getData(skip) {

    let queryString = ''

    client.authenticate()
      .then((res) => {
        return client.service('uploads').find({
          query: {
            companyId: this.props.commons.selectedCompany._id,
            ...queryString,
            $populate: 'propertyunitId',
            $sort: {
              readDate: -1,
            },
            function: { $in: ['NonDebtorOpenBal', 'debtorOpenBal'] },
          }
        })
      })
      .then((res) => {
        this.setState({ loading: false });
        this.props.fetchUploads(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleDelete(_id) {
    client.service('uploads').remove(_id)
      .then((res) => {
        this.props.updateMode('refresh')
        this.setState({
          mode: 'refresh',
        });
      })
      .catch((err) => {
        alert(err);
      })
  }

  handleProcess(_id) {

    client.service('uploads').patch(_id, { processStatus: 'Processed' })
      // .then((res)=>{
      //   this.props.updateMode('refresh')
      //   this.setState({ mode:'refresh' });
      //   return client.service('uploads').patch(_id,{processStatus:'Processed'})
      // })
      .then((res) => {
        this.props.updateMode('refresh')
        this.setState({
          mode: 'refresh',
        });
      })
      .catch((err) => {
        console.log({ err });
        message.error(err.Message)
        // alert(err);
      })
  }

  handleEdit(_id) {
    this.props.updateActiveUploads('edit', _id)
    this.setState({
      mode: 'edit',
    });

  }

  handleManage(_id) {
    this.props.updateActiveUploads('', _id)
    this.setState({
      mode: '',
    });
  }

  showModal = () => {
    this.props.updateActiveUploads('',)
    this.props.updateMode('new')
    this.setState({
      mode: 'new',
    });
  }

  renderConfirmDeleteBtn(_id) {
    return (
      <Popconfirm placement="topLeft" title={text} onConfirm={() => this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
        <Button type="danger">Delete</Button>
      </Popconfirm>
    )
  }

  renderProcessBtn(record) {
    if (record.processStatus === 'Processed') {
      return (
        <Button disabled>Process</Button>
      )
    }
    else {

      return (
        <Popconfirm placement="topLeft" title={text2} onConfirm={() => this.handleProcess(record._id)} okText="Confirm" cancelText="Cancel">
          <Button >Process</Button>
        </Popconfirm>
      )
    }

  }


  render() {
    const { getFieldDecorator } = this.props.form;
    var csvFileName = 'DebtorOpeningBalance_' + moment(Date.now()).format('DD_MM_YYYY') + ')' + '.csv';
    var csvFileName2 = 'NonDebtorOpeningBalance_' + moment(Date.now()).format('DD_MM_YYYY') + ')' + '.csv';

    const columns = [
      {
        title: 'Function',
        dataIndex: 'function',
        key: 'function',
        width: '10%',
        render: (text, record) => (
          <span>{text ? text : '-'}</span>
        ),
      },

      {
        title: 'Process Status',
        dataIndex: 'processStatus',
        key: 'processStatus',
        width: '10%',
        render: (text, record) => (
          <span>{text ? text : '-'}</span>
        ),
      },
      {
        title: 'Upload Date',
        dataIndex: 'uploadDate',
        key: 'uploadDate',
        width: '10%',
        render: (text, record) => (
          <span>{text ? moment(text).format('DD-MM-YYYY') : '-'}</span>
        ),
      },
      {
        title: 'Process Date',
        dataIndex: 'processDate',
        key: 'processDate',
        width: '10%',
        render: (text, record) => (

          <span>{text ? moment(text).format('DD-MM-YYYY') : '-'}</span>

        ),
      },

      {
        title: 'Remark',
        dataIndex: 'remark',
        key: 'remark',
        width: '10%',
        render: (text, record) => (
          <span>
            {text ? <Popover content={text}><Icon type="message" /></Popover> : ''}
          </span>
        ),
      },

      {
        title: 'Action',
        key: 'action',
        width: '20%',
        render: (text, record) => (
          <span>
            {this.renderProcessBtn(record)}
            <Divider type="vertical" />
            {this.renderConfirmDeleteBtn(record._id)}

          </span>
        ),
      }];

    return (
      <Layout>
        <Spin key='spinning' spinning={this.state.loading} >
          <Card
            title={

              <div > <Row>
                <Col>
                  <span>Upload Opening Balance </span>
                  <span style={{
                    marginLeft: "5px",
                    marginBottom: "10px"
                  }}>
                    <Popover content={informationText}><Icon type="info-circle" theme="twoTone" /></Popover>

                  </span>
                </Col>
              </Row>
              </div>}
            extra={
              <div>
                <CSVLink
                  filename={csvFileName2}
                  data={this.state.excelData2}
                  headers={this.state.excelHeader2}>
                  <Button>Download Templates(Non Debtor)</Button>
                </CSVLink>
                <Divider type="vertical" />
                <CSVLink
                  filename={csvFileName}
                  data={this.state.excelData}
                  headers={this.state.excelHeader}>
                  <Button>Download Templates(Debtor)</Button>
                </CSVLink>
                <Divider type="vertical" />
                <Button type="primary" onClick={this.showModal}>New</Button>
              </div>

            }
          >
            <Table dataSource={this.props.uploads.uploads} columns={columns} rowKey="_id" />

            <UploadsForm />
          </Card>
        </Spin>
      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    uploads: state.uploads,
    commons: state.commons
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchUploads: fetchUploads,
    pushUploads: pushUploads,
    removeUploads: removeUploads,
    updateActiveUploads: updateActiveUploads,
    updateUploads: updateUploads,
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(UploadsIndex)));
