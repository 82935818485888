export const FETCH_LEDGERS = 'FETCH_LEDGERS';
export const PUSH_LEDGERS = 'PUSH_LEDGERS';
export const REMOVE_LEDGERS = 'REMOVE_LEDGERS';
export const UPDATE_LEDGERS = 'UPDATE_LEDGERS';
export const UPDATE_MODE = 'UPDATE_MODE';
export const UPDATE_ACTIVE_ID = 'UPDATE_ACTIVE_ID';

export function fetchLedgers(data){
  return (dispatch) => {
    dispatch({
      type: FETCH_LEDGERS,
      payload:data
    });
  }
}

export function pushLedgers(data){
  return (dispatch) => {
    dispatch({
      type: PUSH_LEDGERS,
      payload:data
    });
  }
}

export function removeLedgers(data){
  return (dispatch) => {
    dispatch({
      type: REMOVE_LEDGERS,
      payload:data
    });
  }
}

export function updateLedgers(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_LEDGERS,
      payload:data
    });
  }
}

export function updateMode(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE,
      payload:data
    });
  }
}

export function updateActiveLedgers(mode, id){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_ID,
      mode,
      id
    });
  }
}
