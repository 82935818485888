import _ from 'lodash';
import {
  FETCH_ACCOUNTMAINS,
  PUSH_ACCOUNTMAINS,
  REMOVE_ACCOUNTMAINS,
  UPDATE_ACCOUNTMAINS,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID } from '../actions/actions-accountmains';

const INITIAL_STATE = {
  accountmains: [],
  mode: 'index',
  activeId: '',
  activeAccountmains:{}
}


export default function(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_ACCOUNTMAINS:
      return {
        ...state,
        accountmains:action.payload,
      }
    case PUSH_ACCOUNTMAINS:
      return {
        ...state,
        accountmains:[action.payload, ...state.accountmains],
      }
    case REMOVE_ACCOUNTMAINS:
      return {
        ...state,
        accountmains : [...state.accountmains.filter( (item) => item._id !== action.payload._id)]
      }
    case UPDATE_ACCOUNTMAINS:
      let index =  _.findIndex(state.accountmains, { '_id': action.payload._id })
      state.accountmains.splice(index, 1, action.payload)
      return {
        ...state,
        accountmains : state.accountmains
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode : action.payload,
        activeId:''
      }
    case UPDATE_ACTIVE_ID:
      return {
        ...state,
        mode : action.mode,
        activeId:action.id,
        activeAccountmains: _.pick(_.find(state.accountmains, { '_id': action.id }), ['description', '_id'])
      }
    default:
      return state

  }
}