import React, { Component, useEffect } from 'react';
import { Modal, Upload, Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Select, Card, Row, Col, Spin, InputNumber, Radio, DatePicker } from 'antd'
import client from '../../feathers';
import requireAuth from "../requireAuth";
import Layout from "../layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateMode } from '../../actions/actions-invoices';
import Info from "../common/info";
import moment from 'moment';
// import  ObjectId from 'mongodb';
// var objectid = require('objectid')

const _ = require('lodash');
const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },

};
const formItemStyle = {
  style: { width: '300px' }
};
const formTailLayout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 4, offset: 20 },
};

const text = 'Are you sure to delete this item?';

class InvoicesForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',
      confirmDirty: false,
      autoCompleteResult: [],
      chargesDropdowns: [],
      charges: [],
      invoiceItems: [],
      mode: 'new',
      propertyunits: [],
      loading: false,
      count: 0
    }

  }

  componentWillMount() {
    this.setState({ loading: true });
    let companyId = this.props.commons.selectedCompany._id

    setTimeout(() => {
      let Tod8 = moment();
      this.props.form.setFieldsValue({ invoiceDate: Tod8 });
    }, 1000)

    //generate charges dropdown
    client.authenticate()
      .then((res) => {
        return Promise.all([
          client.service('charges').find({
            query: {
              $populate: 'ledgerAccountId',
              propertyId: companyId,
              code: { $nin: 'DEPOSIT' },
              $sort: {
                description: 1
              }
            }
          }),
          // client.service('payment-type-params').find({
          //   query: {
          //     $populate: "ledgerAccountId",
          //     propertyId: this.props.commons.selectedCompany._id,
          //     category: {
          //       $in: ['DEBITNOTES']
          //     },
          //   }
          // })
        ])
      })
      .then(([res1, res2]) => {
        // this.setState({ chargesDropdowns: [...res1.data, ...res2.data] });
        this.setState({ chargesDropdowns: [...res1.data] });

        return client.service('propertyunits').find({
          query: {
            propertyId: companyId,
            $sort: {
              referenceId: 1
            }
          }
        })
      })
      .then((res) => {

        this.setState({
          loading: false,
          propertyunits: res.data
        });

        //if this.props.copiedInvoice has value, get invoiceItems
        if (this.props.copiedInvoice) {
          return client.service('invoice-items').find({
            query: {
              invoiceId: this.props.copiedInvoice._id,
              $populate: ['chargeId'],
              $sort: {
                referenceId: 1
              }
            }
          })
        }return null
      })
      .then((res) => {
        if(!res){ return  }
        //assign copied invoice items
        res.data = res.data.map((i, index) => {
          return {
            key: index,
            chargeId: i.chargeId,
            chargeCode: i.description,
            description: i.description,
            amount: i.oriAmount,
            effectiveDate: moment()
          }
        })
        this.setState({
          invoiceItems: res.data,
          count: this.state.count + 1,
        });

      })
      .catch((err) => {
        console.log(err);
      });
    //get all propertyunits

  }

  componentDidUpdate() {

    if (this.props.invoices.activeId !== this.state._id) {
      this.setState({
        _id: this.props.invoices.activeId
      })

      if (this.props.invoices.activeInvoices) {
        this.props.form.setFieldsValue(
          this.props.invoices.activeInvoices
        )
      }
    }
  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }

  handleChangeCharges = (e) => {

    this.setState({
      loading: true
    });


    client.authenticate()
      .then((res) => {
        return client.service('charges').get(e)
      })
      .then((res) => {
        this.setState({
          charges: res,
          loading: false
        });
        this.props.form.setFieldsValue(
          res
        );
      })
      .catch((err) => {
        this.setState({
          loading: false
        });
        console.log(err);
      });
  }

  handleDeleteItem = (key) => {

    const dataSource = [...this.state.invoiceItems];
    this.setState({ invoiceItems: dataSource.filter(item => item.key !== key) });
  }

  handleClick = () => {

    const newData = {
      key: this.state.count,
      chargeId: this.state.charges._id,
      chargeCode: this.state.charges.code,
      description: this.state.charges.description,
      amount: this.state.charges.amount,
      effectiveDate: new Date(this.props.form.getFieldValue('invoiceDate'))
    };

    let validateItem = true
    Object.keys(newData).forEach(i => {
      if ( i!=='key' && !newData[i] ) {
        validateItem = false
      }
    })

    if (!validateItem) {
      return message.error('Invalid Info');
    }

    this.setState({
      invoiceItems: [...this.state.invoiceItems, newData],
      count: this.state.count + 1,
    });
  }

  handleChangeAmount = (e) => {
    this.setState({
      charges: {
        _id: this.state.charges._id,
        code: this.state.charges.code,
        description: this.state.charges.description,
        amount: e
      }
    })
  }

  handleChangePropertyunit = (e) => {
    var selectedProperty = _.find(this.state.propertyunits, { '_id': e });
    let name = selectedProperty.owner ? selectedProperty.owner.name : ''
    let contact = selectedProperty.owner ? selectedProperty.owner.contactNumber : ''
    this.props.form.setFieldsValue({
      ownerName: name,
      ownerContact: contact
    })

  }

  handleSubmit = (e) => {
    e.preventDefault();


    if (this.state.invoiceItems.length === 0) {
      return message.error('No item is selected');
    }

    this.props.form.validateFields((err, values) => {
      let companyId = this.props.commons.selectedCompany._id
      if (!err) {
        client.service('invoices')
          .create({
            isDebitNote: true,
            invoiceDate: values.invoiceDate,
            invoiceType: 'OTHERS',
            propertyId: companyId,
            customerId: values.customerId,
            propertyunitId: values.propertyunitId,
            customerType: 'PROPERTYUNIT',//values.customerType,
            reference: '',
            status: 'ISSUED',
            subtotal: 0,
            taxPercentage: 0,
            taxAmt: 0,
            totalAmt: 0,
            remark: values.remark,
            type: 'single'
          },
            {
              query: {
                $client: {
                  invoiceItems: this.state.invoiceItems
                }
              }
            }
          )
          .then((res) => {

            // this.props.history.push('/invoices/view/'+res._id);
            // message.success('Invoice Created');
          })
        message.success('Invoice Creation in process...');
        this.props.history.push('/debitnotes/');

      }
    });
  }

  render() {
    const { visible, onCancel } = this.props;
    const { getFieldDecorator } = this.props.form;

    const columns = [{
      title: 'Fee Code',
      dataIndex: 'chargeCode',
      key: 'chargeCode',
    }, {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    }, {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'itemAmount',
    },
    {
      title: 'Action',
      key: 'action',
      // render: (text, record) => (
      //   <Button onClick={()=>this.handleDeleteItem(record._id)} >X</Button>
      // ),
      render: (text, record) => (
        this.state.invoiceItems.length >= 1
          ? (
            <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDeleteItem(record.key)}>
              <a href="javascript:;">Delete</a>
            </Popconfirm>
          ) : null
      ),
    }
    ];

    return (
      <Layout>
        <Spin spinning={this.state.loading}>
          <Card
            title='Debit Note'
            extra={<Button type="danger" onClick={this.handleSubmit}>Create</Button>}
          >
            <Form className="submit-form">

              <Row>
                <Col span={6}>

                  <FormItem key='invoiceDate'
                    {...formItemLayout}
                    label="Date"
                  >
                    {getFieldDecorator('invoiceDate', {
                      rules: [{
                        required: true, message: 'Please Select date!',
                      }],
                    })(
                      <DatePicker />
                    )}
                  </FormItem>

                  <FormItem key='propertyunitId'
                    {...formItemLayout}
                    label="Property Unit"
                  >
                    {getFieldDecorator('propertyunitId', {
                      rules: [{
                        required: true, message: 'Please Input Property Unit!',
                      }],
                    })(
                      <Select
                        {...formItemStyle}
                        onChange={this.handleChangePropertyunit}
                        showSearch
                        placeholder="Select a unit"
                        optionFilterProp="children"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      >
                        {this.state.propertyunits.map(dropdown => (
                          <Option key={dropdown._id} value={dropdown._id}    >
                            {dropdown.referenceId}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>

                  <FormItem key='ownerName'
                    {...formItemLayout}
                    label="Owner Name"
                  >
                    {getFieldDecorator('ownerName', {
                      rules: [{
                        required: false, message: 'Please Input ownerName!',
                      }],
                    })(
                      <Input disabled />
                    )}
                  </FormItem>

                  <FormItem key='ownerContact'
                    {...formItemLayout}
                    label="Owner Contact"
                  >
                    {getFieldDecorator('ownerContact', {
                      rules: [{
                        required: false, message: 'Please Input ownerContact!',
                      }],
                    })(
                      <Input disabled />
                    )}
                  </FormItem>

                  <FormItem key='remark'
                    {...formItemLayout}
                    label="Remark"
                  >
                    {getFieldDecorator('remark', {
                      rules: [{
                        required: false, message: 'Please Input Remark!',
                      }],
                    })(
                      <TextArea rows={3} />
                    )}
                  </FormItem>

                </Col>

                <Col span={10} offset={6}>
                  <FormItem key='chargeId'
                    {...formItemLayout}
                    label="Charges"
                  >
                    {getFieldDecorator('chargeId', {
                      rules: [{
                        required: true, message: 'Please Select Charges!',
                      }],
                    })(
                      <Select placeholder="Select Type" style={{ 'width': '100%' }} onChange={this.handleChangeCharges}>
                        {this.state.chargesDropdowns.map(i => {
                          return (
                            <Option key={i._id} value={i._id}>
                              {i.description} -- {i.ledgerAccountId?i.ledgerAccountId.fullAccountNo:''}
                            </Option>
                          )
                        }
                        )}
                      </Select>
                    )}
                  </FormItem>

                  <FormItem key='code'
                    {...formItemLayout}
                    label="Charge Code"
                  >
                    {getFieldDecorator('code', {
                      rules: [{
                        required: true, message: 'Please select charges!',
                      }],
                    })(
                      <Input disabled />
                    )}
                  </FormItem>

                  <FormItem key='amount'
                    {...formItemLayout}
                    label="Amount"
                  >
                    {getFieldDecorator('amount', {
                      rules: [{
                        required: true, message: 'Please Input amount!',
                      }],
                    })(
                      <InputNumber onChange={this.handleChangeAmount} />
                    )}
                  </FormItem>

                  <FormItem key='addbtn'
                    {...formTailLayout}
                    label=""
                    style={{ textAlign: 'right' }}
                  >
                    <Button type="primary" onClick={this.handleClick} disabled={this.state.mode === 'edit' ? true : false} >Add</Button>
                  </FormItem>

                </Col>
              </Row>

            </Form>

            <Table dataSource={this.state.invoiceItems} columns={columns} rowKey='code' />

          </Card>

        </Spin>
      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    invoices: state.invoices,
    copiedInvoice: state.invoices.copiedInvoice,
    commons: state.commons
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(InvoicesForm)));
