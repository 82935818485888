import React, { Component } from 'react';
import { Avatar, Row, Col, Card, Popconfirm, message, Divider,  Table, Form, Icon, Input, Button } from 'antd';
import client from '../../feathers';
import requireAuth  from "../../components/requireAuth";
import Layout  from "../../components/layout";
import Info  from "../../components/common/info";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchUsers,
  pushUsers,
  removeUsers,
  updateMode,
  updateActiveUser,
  updateUsers } from '../../actions/users-actions';

const FormItem = Form.Item;

class UsersView extends Component {
  constructor(props) {
      super(props);

      this.state = {
        user: [],
        propertyunits:[],
      }

  }

  componentWillMount(){
    client.authenticate()
    .then(()=>{
      return client.service('users').find({
        query: {
            _id:this.props.match.params.id,
         }
      })
    })
    .then((res)=>{
      this.setState({
        user:res.data[0]
      })

    })
    .catch((err)=>{
      console.log(err);
    });

    client.authenticate()
    .then(()=>{
      return client.service('propertyunits').find({
        query:{
          $populate:'propertyId',
          users:{
            //userId:{$in: [this.props.match.params.id]},
            $elemMatch: { userId: this.props.match.params.id },
          }
        }
      })
    })
    .then((res)=>{

      this.setState({propertyunits:res.data});
      
    })
    .catch((err)=>{
      console.log(err);
      alert(err);
    })

  }

  componentDidMount(){
      client.service('users').on('updated', (properties) => {
        this.props.updateUsers(properties)
      })
  }

  render() {
    let initial = this.state.user.email?this.state.user.email.toString().split("@"):'';
    const columns = [
      {
        title: 'Property',
        dataIndex: 'propertyId.name',
        key: 'propertyId.name',
      },
      {
        title: 'Unit Number',
        render: (text, record) => (
          <span>
            {record.block+'-'+record.floor+'-'+record.unitNo}
          </span>
        ),
      },
      {
        title: 'User ID',
        dataIndex: '_id',
        key: '_id',
      }
    ];

    return(
      <Layout>
        <Row gutter={8}>
          <Col span={8}>
            <Card title="User Info">
              <Avatar style={{ margin:'0 35% 10% 35% ', backgroundColor: '#87cefa', verticalAlign: 'middle' }} size={86}>
                {initial[0]}
              </Avatar>
              <Info label="Email" labelSpan={6}  value={this.state.user.email} valueSpan={18} />
              <Info label="Role" labelSpan={6}  value={this.state.user.role} valueSpan={18} />

              <Divider dashed >Detail</Divider>

            </Card>
          </Col>

          <Col span={16}>
            <Row gutter={8}>
              <Card title="Property">
                <Table dataSource={this.state.propertyunits} columns={columns} rowKey="_id" />
              </Card>
            </Row>
          </Col>
        </Row>
      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    user: state.user
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchUsers: fetchUsers,
    pushUsers: pushUsers,
    removeUsers: removeUsers,
    updateActiveUser: updateActiveUser,
    updateUsers: updateUsers,
    updateMode: updateMode
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersView);
