import React, { Component } from 'react'
import { Modal, message, Form, Icon, Input, Select } from 'antd'
import client from '../../feathers'
import requireAuth  from "../../components/requireAuth"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateMode } from '../../actions/actions-mngproperties'

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};


class MngpropertiesForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id:'',
      confirmDirty: false,
      autoCompleteResult: [],
    }
  }
  componentDidUpdate(){
      if(this.props.mngproperties.activeId !== this.state._id){
        this.setState({
          _id:this.props.mngproperties.activeId
        })

        if(this.props.mngproperties.activeMngproperties){
          this.props.form.setFieldsValue(
            this.props.mngproperties.activeMngproperties
          )
        }
      }
      
  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }


  handleSubmit = (e) => {
     e.preventDefault();
     if(this.props.mngproperties.mode === 'new'){

       this.props.form.validateFields((err, values) => {
         if (!err) {
           client.service('mngproperties').create(values)
           .then((res)=>{
             this.props.updateMode('')
             message.success('New Assignment Added');
           })
           .catch((err)=>{
             this.props.updateMode('')
             alert(err);
           })
         }
       });
     }else{
       this.props.form.validateFields((err, values) => {
         if (!err) {
           client.service('mngproperties').update(this.props.mngproperties.activeId,
             values
           )
           .then((res)=>{
             message.success(`Assignment Updated`);
             this.props.updateMode('')
           })
           .catch((err)=>{
             
             this.props.updateMode('')
           })
           return;
         }
         
       });
     }
  }



compareToFirstPassword = (rule, value, callback) => {
  const form = this.props.form;
  if (value && value !== form.getFieldValue('password')) {
    callback('Two passwords that you enter is inconsistent!');
  } else {
    callback();
  }
}

validateToNextPassword = (rule, value, callback) => {
  const form = this.props.form;
  if (value && this.state.confirmDirty) {
    form.validateFields(['confirm'], { force: true });
  }
  callback();
}

  render() {

    const { getFieldDecorator } = this.props.form;


    return(
      <Modal
         visible={
           this.props.mngproperties.mode==='new'||this.props.mngproperties.mode==='edit'?true:false
         }
         title={ this.props.mngproperties.mode === 'edit' ? 'Update Mngproperties':'Create Mngproperties' }
         okText={ this.props.mngproperties.mode === 'edit' ? 'Update':'Create' }
         onCancel={()=>{this.props.updateMode(''); this.props.form.resetFields()} }
         onOk={this.handleSubmit}

      >
        <Form className="submit-form">


            <FormItem
              {...formItemLayout}
              label="User"
            >
              {getFieldDecorator('userId', {
                rules: [{
                  required: true, message: 'Please input Mngproperties Name!',
                }],
              })(
                <Input prefix={<Icon type="idcard" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="SUPERHERO" />
              )}
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Management"
            >
              {getFieldDecorator('managementId', {
                rules: [{
                  required: false, message: 'Please input Management!',
                }],
              })(
                <Input prefix={<Icon type="idcard" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="SUPERHERO" />
              )}
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Property"
            >
              {getFieldDecorator('propertyId', {
                rules: [{
                  required: true, message: 'Please input Property!',
                }],
              })(
                <Input prefix={<Icon type="idcard" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Tropica" />
              )}
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Status"
              hasFeedback
            >
              {getFieldDecorator('status', {
                rules: [
                  { required: true, message: 'Please select your categories!' },
                ],
              })(
                <Select placeholder="Please select a categories">
                  <Option value="active">Active</Option>
                  <Option value="inactive">Inactive</Option>
                </Select>
              )}
            </FormItem>


        </Form>
      </Modal>

    )
  }
}


function mapStateToProps(state) {
  return {
    mngproperties: state.mngproperties
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
    }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(MngpropertiesForm)));
