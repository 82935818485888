import React, { Component } from 'react'
import { Popconfirm, message, Divider,  Table, Form, Icon, Input, Button,Tabs } from 'antd'
import { Route, Link } from 'react-router-dom';

import client from '../../feathers';
import requireAuth  from "../../components/requireAuth";
import Layout  from "../../components/layout";
import LedgerIndex  from "../../components/ledgers/index";

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  fetchAccounts,
  pushAccounts,
  removeAccounts,
  updateMode,
  updateActiveAccounts,
  updateAccounts } from '../../actions/actions-accounts'

const FormItem = Form.Item;
const TabPane = Tabs.TabPane;

const text = 'Are you sure to delete this item?';

class AccountsIndex extends Component {
  constructor(props) {
    super(props);

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidUpdate(){
  }

  componentDidMount(){
      client.service('accounts').on('created', (accounts) => {
        this.props.pushAccounts(accounts)
      })

      client.service('accounts').on('removed', (accounts) => {
        this.props.removeAccounts(accounts)
      })

      client.service('accounts').on('updated', (accounts) => {
        this.props.updateAccounts(accounts)
      })
  }

  componentWillMount(){

      client.authenticate()
      .then((res)=>{

          return client.service('accounts').find({
            query: {
              $limit:99,
              accountmainId:sessionStorage.getItem('accountmainId'),
               $sort: {
                 createdAt: -1
               }
             }
          })
      })
      .then((res)=>{

        this.props.fetchAccounts(res.data);
      })
      .catch((err)=>{
          console.log(err);
      })
  }

  handleDelete(_id) {
     client.service('accounts').remove(_id)
     .then((res)=>{
     })
     .catch((err)=>{
       alert(err);
     })
  }

  handleEdit(_id) {
    this.props.updateActiveAccounts('edit',_id)
  }

  handleManage(_id) {
    this.props.updateActiveAccounts('',_id)
  }

  showModal = () => {
    this.props.updateMode('new')
  }

  renderConfirmDeleteBtn(_id){
    return (
      <Popconfirm placement="topLeft" title={text} onConfirm={()=>this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
        <Button type="danger">Delete</Button>
      </Popconfirm>
    )
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        render: (text, record) => ([
          <span>
            {record.description}
          </span>
          ,<LedgerIndex accountId={record._id} />
        ]),
      },
    ];

    return(
      <Layout>
        <h1>Accounting</h1>
        <Table dataSource={this.props.accounts.accounts} columns={columns} rowKey="_id" />
      </Layout>
    )
  }
}

function callback(key) {

}


function mapStateToProps(state) {
  return {
    accounts: state.accounts,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchAccounts: fetchAccounts,
    pushAccounts: pushAccounts,
    removeAccounts: removeAccounts,
    updateActiveAccounts: updateActiveAccounts,
    updateAccounts: updateAccounts,
    updateMode: updateMode
    }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(AccountsIndex)));
