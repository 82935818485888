import { LOGIN_SUCCESSFUL, LOGOUT_SUCCESSFUL } from '../actions/user-actions';

const INITIAL_STATE = {
  info: {},
  authenticated:false,
  loading: false,
  errors: {},
}


export default function(state = INITIAL_STATE, action){
  switch(action.type){
    case LOGIN_SUCCESSFUL:
      // console.log(action.payload);
      return {
        ...state,
        authenticated:true,
        info:action.payload
      }
    case LOGOUT_SUCCESSFUL:
      return {
        ...state,
        authenticated:false,
        info:{}
      }
    default:
      return state

  }
}
