import React, { Component } from 'react';
import { Modal, message, Popconfirm, Card, Divider, Table, Form, InputNumber, Input, Button, Select, Row, Col, Spin, DatePicker } from 'antd';
import { Route, Link } from 'react-router-dom';

import client from '../../feathers';
import requireAuth from "../../components/requireAuth";
import Layout from "../../components/layout";
import ReceiptsForm from "../../components/receipts/form";
import ReceiptsView from "../../components/receipts/view";
// import LedgerIndexTransactionsByReference  from "../../components/ledgers/index-transactions-by-reference";
import axios from 'axios';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchReceipts,
  pushReceipts,
  removeReceipts,
  updateMode,
  updateActiveReceipts,
  updateReceipts
} from '../../actions/actions-receipts';

const _ = require('lodash');
const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const formItemStyle = {
  style: { width: '300px' }
};
const text = 'Are you sure to delete this item?';

class ReceiptsIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      img: '',
      mode: 'new',

      _id: '',
      confirmDirty: false,
      autoCompleteResult: [],
      accountAssetsDropdowns: [],
      accountExpensesDropdowns: [],
      suppliers: [],
      dataSource: [],
      mode: 'new',
      propertyunits: [],
      loading: false,
      count: 0,

      description: '',
      date: '',
      supplierId: '',
      supplierCode: '',

      receiptNo: '',
      subTotalAmount: '',
      taxAmount: '',

      //category
      category: '',
      debitledgerAccountId: '',

      imageUrl: '',
    }

    this.handleDelete = this.handleDelete.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentWillMount() {

    client.authenticate()
      .then((res) => {

        return client.service('receipts').find({
          query: {
            propertyId: sessionStorage.getItem('companyId'),
            $populate: 'supplierId',
            $sort: {
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {
        this.props.fetchReceipts(res.data)
      })
      .catch((err) => {
        console.log(err);
      });


    //generate dropdown
    client.authenticate()
      .then((res) => {

        return client.service('ledger-accounts').find({
          query: {
            companyId: sessionStorage.getItem('companyId'),
            categories: 'ASSETS',
            $or: [
              { accountNo: { $in: ['3130', '3120', '3110'] } },
              { bankAccountFlag: true }
            ],
            $sort: {
              description: 1
            }
          }
        })
      })
      .then((res) => {

        this.setState({
          accountAssetsDropdowns: res.data,
        })
      })
      .catch((err) => {
        this.setState({
          accountAssetsDropdowns: []
        })
      })
    //generate dropdown category
    client.authenticate()
      .then((res) => {

        return client.service('ledger-accounts').find({
          query: {
            companyId: sessionStorage.getItem('companyId'),
            //categories:{$in:['EXPENSES','ASSETS']},
            accountNo: { $nin: ['3000'] },
            $sort: {
              description: 1
            }
          }
        })
      })
      .then((res) => {
        this.setState({
          accountExpensesDropdowns: res.data,
        })
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          accountExpensesDropdowns: []
        })
      })

    this.handleGetSupplierDropdown();

  }

  //-------------------------------------------------------------------
  handleGetSupplierDropdown = () => {
    //generate suppliers dropdown
    client.authenticate()
      .then((res) => {

        return client.service('suppliers').find({
          query: {
            propertyId: sessionStorage.getItem('companyId'),
            $populate: 'ledgerAccountId',
            $sort: {
              supplierCode: 1
            }
          }
        })
      })
      .then((res) => {
        this.setState({
          suppliers: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      })
  }
  //-------------------------------------------------------------------
  handleSaveMerchant = () => {

    client.authenticate()
      .then((res) => {

        return client.service('suppliers').create({
          name: this.state.newMerchant,
          propertyId: sessionStorage.getItem('companyId'),
        })
      })
      .then((res) => {
        this.setState({
          invoice: res,
          loading: false
        });
        message.success('New Merchant Added');
        this.handleGetSupplierDropdown();

        this.props.form.setFieldsValue({
          merchantName: res._id
        });
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      })
  }
  //-------------------------------------------------------------------
  handleChange = (value) => {

    var result = this.state.suppliers.filter(obj => {
      return obj._id === value
    })
    this.setState({
      creditledgerAccountId: result[0].ledgerAccountId._id,
      creditledgerAccountName: result[0].ledgerAccountId.accountName,
      supplierCode: result[0].supplierCode,
      supplierId: value,
    });

    this.props.form.setFieldsValue({ creditledgerAccountId: result[0].ledgerAccountId.accountName });

  }

  handleChangeCategory = (e) => {

    var result = this.state.accountExpensesDropdowns.filter(obj => {
      return obj._id === e
    })
    this.setState({
      category: result[0].accountName,
      debitledgerAccountId: e,
    })
  }

  //-------------------------------------------------------------------
  handleSearch = (value) => {
    this.setState({
      newMerchant: value
    });
  }
  //-------------------------------------------------------------------
  handleChangeSubTotalAmt = (e) => {
    this.setState({
      subTotalAmount: e.target.value
    });
    typingTimeout: setTimeout(() => {
      this.handleChangeTotalAmt()
    }, 500)
  }
  //-------------------------------------------------------------------
  handleChangeTaxAmount = (e) => {
    this.setState({
      taxAmount: e.target.value
    });
    typingTimeout: setTimeout(() => {
      this.handleChangeTotalAmt()
    }, 500)
  }

  handleChangeTotalAmt = () => {

    this.setState({
      totalAmount: parseFloat(this.state.subTotalAmount) + parseFloat(this.state.taxAmount)
    });
    this.props.form.setFieldsValue({ totalAmount: this.state.totalAmount });

  }
  //----------------------------------------------------------

  handleDelete(_id) {
    client.service('receipts').remove(_id)
      .then((res) => {
        message.success('Deleted');
      })
      .catch((err) => {
        alert(err);
      })
  }

  handleEdit(_id) {
    this.props.updateActiveReceipts('edit', _id)
  }

  handleView(_id) {
    this.props.updateActiveReceipts('view', _id)
  }

  handleManage(_id) {
    this.props.updateActiveReceipts('', _id)
  }

  showModal = () => {
    this.props.updateMode('new')
  }

  handleImage(img = null) {
    this.setState({
      visible: true,
      img: img
    });
  }

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  }

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  }

  handleFileUpload = (event) => {

    this.setState({ loading: true });

    let formData = new FormData();

    formData.append('image', event.target.files[0]);

    axios.post(`${client.io.io.uri}upload-receipts`,
      formData
      , {
        headers: {
          'Authorization': client.settings.storage.storage['feathers-jwt'],
          'Content-Type': 'multipart/form-data',
        }
      }
    ).then((res) => {

      this.props.form.setFieldsValue({
        file: res.data.imageUrl
      });
      this.setState({
        loading: false,
        imageUrl: res.data.imageUrl,
      });
      message.success('Upload Successful');
    })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
        message.error(err);
      })
  }

  //..............................................................................
  handleDeleteItem = (key) => {

    const dataSource = [...this.state.dataSource];
    this.setState({ dataSource: dataSource.filter(item => item.key !== key) });
  }

  handleClick = () => {

    const newData = {
      key: this.state.count,
      imageUrl: this.state.imageUrl,
      date: this.state.date,
      supplierId: this.state.supplierId,

      //supplier
      creditledgerAccountId: this.state.creditledgerAccountId,
      creditledgerAccountName: this.state.creditledgerAccountName,
      supplierCode: this.state.supplierCode,

      receiptNo: this.state.receiptNo,
      subTotalAmount: this.state.subTotalAmount,
      taxAmount: this.state.taxAmount,
      totalAmount: this.state.totalAmount,
      //category
      category: this.state.category,
      debitledgerAccountId: this.state.debitledgerAccountId,
      description: this.state.description,
      // effectiveDate   : new Date(this.props.form.getFieldValue('invoiceDate'))
    };

    this.setState({
      dataSource: [...this.state.dataSource, newData],
      count: this.state.count + 1,
    });
    const dataSource = [...this.state.dataSource];

  }
  //..............................................................................
  handleSubmit = (e) => {
    e.preventDefault();


    if (this.state.dataSource.length === 0) {
      return message.error('No item is selected');
    }

    this.props.form.validateFields((err, values) => {
      if (!err) {


        axios.post(`${client.io.io.uri}supplier-Multiple-Invoice`,
          {
            invoiceItems: this.state.dataSource,
            propertyId: sessionStorage.getItem('companyId'),
          },
          {
            headers: { 'Authorization': client.settings.storage.storage['feathers-jwt'] },
          }
        )
          .then((res) => {
            // this.props.history.push('/invoices/view/'+res._id);
            message.success('Invoice Created');
          })
          .catch((err) => {
            console.log(err);
            alert(err);
          })

      }
    });
  }
  //..............................................................................

  handleChangeDate = (e) => {

    this.setState({
      date: e.target.value,
    })
  }

  handleChangeRemark = (e) => {

    this.setState({
      description: e.target.value,
    })
  }

  handleChangereceiptNo = (e) => {

    this.setState({
      receiptNo: e.target.value,
    })
  }

  //total amount function
  handleChangeTotalAmount = (e) => {

    this.setState({
      totalAmount: e.target.value,
    })
  }

  render() {
    const { visible, onCancel } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { accountExpensesDropdowns } = this.state;

    const columns = [
      //   {
      //   title: 'File',
      //   dataIndex: 'imageUrl',
      //   key: 'imageUrl',
      // },
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
      }, {
        title: 'Supplier',
        dataIndex: 'supplierCode',
        key: 'supplierCode',
      }, {
        title: 'Supplier Account',
        dataIndex: 'creditledgerAccountName',
        key: 'creditledgerAccountName',
      }, {
        title: 'Receipt',
        dataIndex: 'receiptNo',
        key: 'receiptNo',
      }, {
        title: 'Sub Total Amount',
        dataIndex: 'subTotalAmount',
        key: 'subTotalAmount',
      },
      // {
      //   title: 'Tax Amount',
      //   dataIndex: 'taxAmount',
      //   key: 'taxAmount',
      // },{
      //   title: 'Total Amount',
      //   dataIndex: 'totalAmount',
      //   key: 'totalAmount',
      // },
      {
        title: 'Category',
        dataIndex: 'category',
        key: 'category',
      }, {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: 'Action',
        key: 'action',
        // render: (text, record) => (
        //   <Button onClick={()=>this.handleDeleteItem(record._id)} >X</Button>
        // ),
        render: (text, record) => (
          this.state.dataSource.length >= 1
            ? (
              <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDeleteItem(record.key)}>
                <a href="javascript:;">Delete</a>
              </Popconfirm>
            ) : null
        ),
      }
    ];

    return (
      <Layout>
        <Spin spinning={this.state.loading}>
          <Card
            title='Supplier Invoices'
            extra={<Button type="danger" onClick={this.handleSubmit}>Create</Button>}
          >
            <Form className="submit-form">

              <Row>
                <Col span={12}>
                  <FormItem
                    {...formItemLayout}
                    label="Upload"
                  >
                    <Input label='upload file' type='file' onChange={this.handleFileUpload} />
                  </FormItem>

                  <FormItem
                    {...formItemLayout}
                    label="File Name"
                    style={{ 'display': 'none' }}
                  >
                    {getFieldDecorator('imageUrl', {
                      rules: [{
                        required: false, message: 'Please Upload File!',
                      }],
                    })(
                      <Input style={{ 'display': 'none' }} disabled={true} placeholder="File Name" />
                    )}
                  </FormItem>

                  <FormItem
                    {...formItemLayout}
                    label="Date"
                  >
                    {getFieldDecorator('date', {
                      rules: [{
                        required: true, message: 'Please input date!',
                      }],
                    })(

                      <DatePicker onChange={this.handleChangeDate} />
                    )}
                  </FormItem>

                  <FormItem
                    {...formItemLayout}
                    label="Supplier"
                  >
                    {getFieldDecorator('supplierCode', {
                      rules: [{
                        required: true, message: 'Please select Supplier!',
                      }],
                    })(
                      <Select
                        showSearch
                        placeholder="Select a Supplier"
                        defaultActiveFirstOption={false}
                        //notFoundContent={[<Button onClick={this.handleSaveMerchant} type="danger">+</Button>,' Create a New Merchant']}
                        onChange={this.handleChange}
                        onSearch={this.handleSearch}
                        //optionFilterProp="children"
                        //filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        showArrow={false}
                      >
                        {this.state.suppliers.map(dropdown => (
                          <Option key={dropdown._id} value={dropdown._id}>
                            {dropdown.supplierCode + '-' + dropdown.name}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>

                  <FormItem
                    {...formItemLayout}
                    label="Supplier Account"
                  >
                    {getFieldDecorator('creditledgerAccountId', {
                      rules: [{
                        required: true, message: 'Please input Account!',
                      }],
                    })(
                      <Input value={this.state.creditledgerAccountId} disabled />
                    )}
                  </FormItem>

                  <FormItem
                    {...formItemLayout}
                    label="Receipt No."
                  >
                    {getFieldDecorator('receiptNo', {
                      rules: [{
                        required: true, message: 'Please input Invoice No.!',
                      }],
                    })(
                      <Input placeholder="INV0001" onChange={this.handleChangereceiptNo} />
                    )}
                  </FormItem>

                </Col>
                <Col span={12}>

                  <FormItem
                    {...formItemLayout}
                    label="Sub Total Amount"
                  >
                    {getFieldDecorator('subTotalAmount', {
                      rules: [{
                        required: true, message: 'Please input SubTotal!',
                      }],
                    })(
                      <Input placeholder="9.00" value={this.state.SubTotalAmount} onChange={this.handleChangeSubTotalAmt} />
                    )}
                  </FormItem>

                  <FormItem
                    {...formItemLayout}
                    label="Tax Amount"
                  >
                    {getFieldDecorator('taxAmount', {
                      rules: [{
                        required: true, message: 'Please input Tax Amount!',
                      }],
                    })(
                      <Input placeholder="1.00" value={this.state.TaxAmount} onChange={this.handleChangeTaxAmount} />
                    )}
                  </FormItem>

                  <FormItem key='totalAmount'
                    {...formItemLayout}
                    label="Total Amount"
                  >
                    {getFieldDecorator('totalAmount', {
                      rules: [{
                        required: true, message: 'Please input Amount!',
                      }],
                    })(
                      <Input disabled onChange={this.handleChangeTotalAmount} />
                    )}
                  </FormItem>

                  <FormItem
                    {...formItemLayout}
                    label="Category"
                  >
                    {getFieldDecorator('debitledgerAccountId', {
                      rules: [{
                        required: true, message: 'Please input category!',
                      }],
                    })(
                      <Select placeholder="Please select category" onChange={this.handleChangeCategory}>
                        {accountExpensesDropdowns.map(dropdown => (
                          <Option key={dropdown._id} value={dropdown._id}>
                            {dropdown.description}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>

                  <FormItem
                    {...formItemLayout}
                    label="Remark"
                  >
                    {getFieldDecorator('description', {
                      rules: [{
                        required: false, message: 'Please input remark!',
                      }],
                    })(
                      <TextArea rows={4} onChange={this.handleChangeRemark} />
                    )}
                  </FormItem>

                  <Button type="primary" style={{ marginBottom: '5px', float: 'right' }} onClick={this.handleClick} disabled={this.state.mode === 'edit' ? true : false} >Add</Button>

                </Col>
              </Row>

            </Form>

            <Table dataSource={this.state.dataSource} columns={columns} />

          </Card>
        </Spin>
      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    receipts: state.receipts,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchReceipts: fetchReceipts,
    pushReceipts: pushReceipts,
    removeReceipts: removeReceipts,
    updateActiveReceipts: updateActiveReceipts,
    updateReceipts: updateReceipts,
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(ReceiptsIndex)));
