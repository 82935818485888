import _ from 'lodash';
import {
  FETCH_INVOICEITEMS,
  PUSH_INVOICEITEMS,
  REMOVE_INVOICEITEMS,
  UPDATE_INVOICEITEMS,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID } from '../actions/actions-invoiceitems';

const INITIAL_STATE = {
  invoiceitems: [],
  mode: 'index',
  activeId: '',
  activeInvoiceitems:{}
}


export default function(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_INVOICEITEMS:
      return {
        ...state,
        invoiceitems:action.payload,
      }
    case PUSH_INVOICEITEMS:
      return {
        ...state,
        invoiceitems:[action.payload, ...state.invoiceitems],
      }
    case REMOVE_INVOICEITEMS:
      return {
        ...state,
        invoiceitems : [...state.invoiceitems.filter( (item) => item._id !== action.payload._id)]
      }
    case UPDATE_INVOICEITEMS:
      let index =  _.findIndex(state.invoiceitems, { '_id': action.payload._id })
      state.invoiceitems.splice(index, 1, action.payload)
      return {
        ...state,
        invoiceitems : state.invoiceitems
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode : action.payload,
        activeId:''
      }
    case UPDATE_ACTIVE_ID:
      return {
        ...state,
        mode : action.mode,
        activeId:action.id,
        activeInvoiceitems: _.pick(_.find(state.invoiceitems, { '_id': action.id }), ['charges', 'description','amount', '_id'])
      }
    default:
      return state

  }
}
