import _ from 'lodash';
import {
  FETCH_FACILITYBOOKINGS,
  PUSH_FACILITYBOOKINGS,
  REMOVE_FACILITYBOOKINGS,
  UPDATE_FACILITYBOOKINGS,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID
} from '../actions/actions-facilitybookings';

const INITIAL_STATE = {
  facilitybookings: [],
  mode: 'index',
  activeId: '',
  activeFacilitybookings: {}
}


export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_FACILITYBOOKINGS:
      return {
        ...state,
        facilitybookings: action.payload,
      }
    case PUSH_FACILITYBOOKINGS:
      return {
        ...state,
        facilitybookings: [action.payload, ...state.facilitybookings],
      }
    case REMOVE_FACILITYBOOKINGS:
      return {
        ...state,
        facilitybookings: [...state.facilitybookings.filter((item) => item._id !== action.payload._id)]
      }
    case UPDATE_FACILITYBOOKINGS:
      let index = _.findIndex(state.facilitybookings, { '_id': action.payload._id })
      state.facilitybookings.splice(index, 1, action.payload)
      return {
        ...state,
        facilitybookings: state.facilitybookings
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode: action.payload,
        activeId: ''
      }
    case UPDATE_ACTIVE_ID:
      return {
        ...state,
        mode: action.mode,
        activeId: action.id,
        date: action.date
        ,

        activeFacilitybookings: _.pick(_.find(state.facilitybookings, { '_id': action.id }), ['name', 'description', 'unit', 'categories', '_id'])
      }
    default:
      return state

  }
}
