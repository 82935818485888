import _ from 'lodash';
import {
  FETCH_MANAGEMENT,
  PUSH_MANAGEMENT,
  REMOVE_MANAGEMENT,
  UPDATE_MANAGEMENT,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID } from '../actions/managements-actions';

const INITIAL_STATE = {
  managements: [],
  mode: 'index',
  activeId: '',
  activeManagement:{}
}


export default function(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_MANAGEMENT:
      return {
        ...state,
        managements:action.payload,
      }
    case PUSH_MANAGEMENT:
      return {
        ...state,
        managements:[action.payload, ...state.managements],
      }
    case REMOVE_MANAGEMENT:
      return {
        ...state,
        managements : [...state.managements.filter( (item) => item._id !== action.payload._id)]
      }
    case UPDATE_MANAGEMENT:
      let index =  _.findIndex(state.managements, { '_id': action.payload._id })
      state.managements.splice(index, 1, action.payload)
      return {
        ...state,
        managements : state.managements
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode : action.payload,
        activeId:''
      }
    case UPDATE_ACTIVE_ID:
      return {
        ...state,
        mode : action.mode,
        activeId:action.id,
        activeManagement: _.pick(_.find(state.managements, { '_id': action.id }), ['name', 'address1', 'address2', 'state', 'postCode', 'country', 'mobile', 'fax', 'email', 'ssm','propertyId', '_id'])
      }
    default:
      return state

  }
}
