export const FETCH_EFORMS = 'FETCH_EFORMS';
export const PUSH_EFORMS = 'PUSH_EFORMS';
export const REMOVE_EFORMS = 'REMOVE_EFORMS';
export const UPDATE_EFORMS = 'UPDATE_EFORMS';
export const UPDATE_MODE = 'UPDATE_MODE';
export const UPDATE_ACTIVE_ID = 'UPDATE_ACTIVE_ID';

export function fetchEforms(data){
  return (dispatch) => {
    dispatch({
      type: FETCH_EFORMS,
      payload:data
    });
  }
}

export function pushEforms(data){
  return (dispatch) => {
    dispatch({
      type: PUSH_EFORMS,
      payload:data
    });
  }
}

export function removeEforms(data){
  return (dispatch) => {
    dispatch({
      type: REMOVE_EFORMS,
      payload:data
    });
  }
}

export function updateEforms(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_EFORMS,
      payload:data
    });
  }
}

export function updateMode(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE,
      payload:data
    });
  }
}

export function updateActiveEforms(mode, id){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_ID,
      mode,
      id
    });
  }
}
