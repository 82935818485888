import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
} from "antd";
import _ from "lodash";
import moment from "moment";

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  updateActiveBankRecons,
  updateMonthBank,
} from "../../actions/actions-bankrecons";
import Layout from "../../components/layout";
import requireAuth from "../../components/requireAuth";
import client from "../../feathers";

const { MonthPicker } = DatePicker;
const FormItem = Form.Item;
const Option = Select.Option;
const formItemLayout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 6,
  },
};
const { TextArea } = Input;

class BankReconsIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bankledgerAccountList: [],
    };
    // this.goNextPage = this.goNextPage.bind(this)
  }

  componentDidUpdate() {}

  componentDidMount() {
    if (
      this.props.bankrecons.mode === "edit" ||
      this.props.bankrecons.mode === "view"
    ) {
      let activeBankRecons = _.cloneDeep(
        this.props.bankrecons.activeBankRecons
      );

      this.props.form.setFieldsValue({
        remark: activeBankRecons.remark,
        BankAccountId: activeBankRecons.ledgerAccountId._id,
        month: moment(activeBankRecons.reconMonth),
        closingBalance: activeBankRecons.statementBalance,
      });
    }
  }

  componentWillMount() {
    client
      .authenticate()
      .then((res) => {
        return client.service("ledger-accounts").find({
          query: {
            companyId: this.props.commons.selectedCompany._id,

            categories: "ASSETS",
            $or: [
              {
                bankAccountFlag: true,
              },
              // {
              //   fullAccountNo: "3120/000"
              // }
            ],
            $sort: {
              description: 1,
            },
          },
        });
      })
      .then((res) => {
        this.setState({ bankledgerAccountList: res.data });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ bankledgerAccountList: [] });
      });
  }

  goNextPage() {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // let thismonth = moment(values.month).format('YYYYMM')
        let thismonthFrom = moment(values.month).startOf("month");
        let thismonthTo = moment(values.month).endOf("month");

        client.authenticate().then((res) => {
          client
            .service("bankrecons")
            .find({
              query: {
                ledgerAccountId: values.BankAccountId,
                reconMonth: {
                  $gte: thismonthFrom,
                  $lte: thismonthTo,
                },
              },
            })
            .then((res) => {
              if (res.data.length > 0) {
                let patchId = res.data[0]._id;
                client
                  .service("bankrecons")
                  .patch(patchId, {
                    companyId: this.props.commons.selectedCompany._id,
                    statementBalance: values.closingBalance,
                    remark: values.remark,
                  })
                  .then((res) => {
                    if (this.props.bankrecons.mode === "view") {
                      this.props.updateActiveBankRecons("view", res._id);
                    } else {
                      this.props.updateActiveBankRecons("bankrecon", res._id);
                    }
                  });
              } else {
                client
                  .service("bankrecons")
                  .create({
                    companyId: this.props.commons.selectedCompany._id,
                    reconMonth: thismonthFrom,
                    statementBalance: values.closingBalance,
                    ledgerAccountId: values.BankAccountId,
                    remark: values.remark,
                  })
                  .then((res) => {
                    this.props.updateActiveBankRecons("bankrecon", res._id);
                  });
              }
            });
        });

        this.props.updateMonthBank(
          thismonthFrom,
          values.BankAccountId,
          values.closingBalance
        );
        // this.props.history.push('/bankrecons/index-master/');
        this.props.history.push(
          "/bankrecons/index-master/index-daily/" + values.BankAccountId
        );
      } else {
        message.error("Field required input.");
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    // const { loading } = this.state;
    // const { bankledgerAccountList } = this.state;
    const isView = this.props.bankrecons.mode === "view";

    return (
      <Layout>
        <>
          <Card title="Bank Reconciliation">
            <Form className="submit-form">
              <Row gutter={8}>
                <FormItem {...formItemLayout} label="Month">
                  {getFieldDecorator("month", {
                    rules: [
                      {
                        required: true,
                        message: "Please select Month!",
                      },
                    ],
                    initailValue: moment().startOf("year"),
                  })(
                    <MonthPicker
                      onChange={this.fromMonthOnChange}
                      disabled={isView}
                    />
                  )}
                </FormItem>

                <FormItem {...formItemLayout} label="Bank">
                  {getFieldDecorator("BankAccountId", {
                    rules: [
                      {
                        required: true,
                        message: "Please select Bank Account!",
                      },
                    ],
                  })(
                    <Select disabled={isView}>
                      {this.state.bankledgerAccountList.map((dropdown) => (
                        <Option
                          key={dropdown._id}
                          value={dropdown._id}
                          description={dropdown.description}
                        >
                          {dropdown.description}
                        </Option>
                      ))}
                    </Select>
                  )}
                </FormItem>

                <FormItem {...formItemLayout} label="Closing Balance">
                  {getFieldDecorator("closingBalance", {
                    rules: [{ required: true, message: "Field Required." }],
                  })(<InputNumber min={0} disabled={isView} />)}
                </FormItem>

                <FormItem {...formItemLayout} label="Remark">
                  {getFieldDecorator("remark", {
                    rules: [{ required: true, message: "Field Required." }],
                  })(<TextArea rows={4} max={3000} disabled={isView} />)}
                </FormItem>

                <Col span={4} offset={3}>
                  <Button onClick={() => this.goNextPage()} type="submit">
                    Next
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    monthlystatements: state.monthlystatements,
    bankrecons: state.bankrecons,
    commons: state.commons,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateMonthBank: updateMonthBank,
      updateActiveBankRecons: updateActiveBankRecons,
    },
    dispatch
  );
}

export default requireAuth(
  connect(mapStateToProps, mapDispatchToProps)(Form.create()(BankReconsIndex))
);
