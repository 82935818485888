import _ from 'lodash';
import {
  FETCH_RECURRINGITEMS,
  PUSH_RECURRINGITEMS,
  REMOVE_RECURRINGITEMS,
  UPDATE_RECURRINGITEMS,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID } from '../actions/actions-recurringitems';

const INITIAL_STATE = {
  recurringitems: [],
  mode: 'index',
  activeId: '',
  activeRecurringitems:{}
}


export default function(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_RECURRINGITEMS:
      return {
        ...state,
        recurringitems:action.payload,
      }
    case PUSH_RECURRINGITEMS:
      return {
        ...state,
        recurringitems:[action.payload, ...state.recurringitems],
      }
    case REMOVE_RECURRINGITEMS:
      return {
        ...state,
        recurringitems : [...state.recurringitems.filter( (item) => item._id !== action.payload._id)]
      }
    case UPDATE_RECURRINGITEMS:
      let index =  _.findIndex(state.recurringitems, { '_id': action.payload._id })
      state.recurringitems.splice(index, 1, action.payload)
      return {
        ...state,
        recurringitems : state.recurringitems
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode : action.payload,
        activeId:''
      }
    case UPDATE_ACTIVE_ID:
      return {
        ...state,
        mode : action.mode,
        activeId:action.id,
        activeRecurringitems: _.pick(_.find(state.recurringitems, { '_id': action.id }), ['propertyId', 'propertyunitId','chargeId','description','amount','status', '_id'])
      }
    default:
      return state

  }
}
