import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Table,
} from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  fetchPropertyunits,
  pushPropertyunits,
  removePropertyunits,
  updateActivePropertyunits,
  updateMode,
  updatePropertyunits,
} from "../../actions/actions-propertyunits";
import Layout from "../../components/layout";
import requireAuth from "../../components/requireAuth";
import client from "../../feathers";
import axios from "axios";

const _ = require("lodash");

const PAGESIZE = 20;

class PropertyunitsIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchUnitNumber: "",
      totalPagination: 0,
      data: [],
      loading: true,
      allAmt: 0,
    };
  }

  componentWillMount() {
    this.props.updateMode("");
    this._getData();
  }

  formatAmt(amt) {
    let integer = parseFloat(amt);
    return integer > 0 ? integer.toFixed(2) : 0.0;
  }

  handlePageChange = (page) => {
    let companyId = this.props.commons.selectedCompany._id;
    this.setState({
      CurrentPagination: page,
    });
    this.props.form.validateFields((err, values) => {
      client
        .authenticate()
        .then((res) => {
          return client.service("propertyunits").find({
            query: {
              propertyId: companyId,
              $limit: 20,
              $skip: (page - 1) * 20,
              $populate: "ledgerAccountId",
              $sort: {
                referenceId: 1,
                //createdAt: -1
              },
            },
          });
        })
        .then((res) => {
          //
          this.setState({ totalPagination: res.total });
          this.props.fetchPropertyunits(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  _getData() {
    this.setState({
      loading: true,
    });

    client
      .authenticate()
      .then(() => {
        return axios.get(`${client.io.io.uri}prepaymentIndex`, {
          params: {
            propertyId: this.props.user.info.propertyId._id,
          },
          headers: {
            Authorization: client.settings.storage.storage["feathers-jwt"],
          },
        });
      })
      .then((res) => {
        var allTotal = _.sumBy(res.data.result, "total");
        this.setState({
          data: res.data.result || [],
          loading: false,
          allAmt: allTotal,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    const columns = [
      {
        title: (
          <Row gutter={8}>
            <Row>
              <Col span={24}>Unit Number</Col>
            </Row>
            {/* <Row><Col span={24}><Input value={this.state.searchUnitNumber}/></Col></Row> */}
          </Row>
        ),
        dataIndex: "unit",
        key: "unit",
        width: "15%",
      },
      {
        title: "Total",
        dataIndex: "total",
        key: "total",
        align: "right",
        width: "20%",
        render: (v) => v.toFixed(2),
      },
      {
        title: "Balance",
        dataIndex: "balance",
        key: "balance",
        align: "right",
        width: "20%",
        render: (v) => v.toFixed(2),
      },
      {
        title: "Action",
        key: "action",
        dataIndex: "unitId",
        width: "55%",
        render: (v) => (
          <span>
            <Divider type="vertical" />
            <Link
              to={{
                pathname: `/prepayments/${v}`,
              }}
            >
              <Button>Prepayment List</Button>
            </Link>
          </span>
        ),
      },
    ];

    return (
      <Layout>
        <Spin spinning={this.state.loading}>
          <Card title="Prepayment Info">
            <Table
              dataSource={this.state.data}
              columns={columns}
              rowKey="_id"
              pagination={{
                // defaultCurrent: 1,
                // total: this.state.totalPagination,
                // onChange: this.handlePageChange,
                pageSize: PAGESIZE,
              }}
              footer={() => {
                return (
                  <span style={{ fontWeight: "bold" }}>
                    Total Amount : {this.formatAmt(this.state.allAmt)}
                  </span>
                );
              }}
            />
          </Card>
        </Spin>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    propertyunits: state.propertyunits,
    commons: state.commons,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchPropertyunits: fetchPropertyunits,
      pushPropertyunits: pushPropertyunits,
      removePropertyunits: removePropertyunits,
      updateActivePropertyunits: updateActivePropertyunits,
      updatePropertyunits: updatePropertyunits,
      updateMode: updateMode,
    },
    dispatch
  );
}

export default requireAuth(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Form.create()(PropertyunitsIndex))
);
