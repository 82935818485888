export const FETCH_MNGPROPERTIES = 'FETCH_MNGPROPERTIES';
export const PUSH_MNGPROPERTIES = 'PUSH_MNGPROPERTIES';
export const REMOVE_MNGPROPERTIES = 'REMOVE_MNGPROPERTIES';
export const UPDATE_MNGPROPERTIES = 'UPDATE_MNGPROPERTIES';
export const UPDATE_MODE = 'UPDATE_MODE';
export const UPDATE_ACTIVE_ID = 'UPDATE_ACTIVE_ID';

export function fetchMngproperties(data){
  return (dispatch) => {
    dispatch({
      type: FETCH_MNGPROPERTIES,
      payload:data
    });
  }
}

export function pushMngproperties(data){
  return (dispatch) => {
    dispatch({
      type: PUSH_MNGPROPERTIES,
      payload:data
    });
  }
}

export function removeMngproperties(data){
  return (dispatch) => {
    dispatch({
      type: REMOVE_MNGPROPERTIES,
      payload:data
    });
  }
}

export function updateMngproperties(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MNGPROPERTIES,
      payload:data
    });
  }
}

export function updateMode(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE,
      payload:data
    });
  }
}

export function updateActiveMngproperties(mode, id){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_ID,
      mode,
      id
    });
  }
}
