import React, { Component } from 'react';
import { Row, Col, Form, Button, Spin } from 'antd';
import client from '../../feathers';
import Layout from "../../components/layout";
import InvoicePrint from "../../components/invoices/print";
import ReactToPrint from "react-to-print";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchInvoices,
  pushInvoices,
  removeInvoices,
  updateMode,
  updateActiveInvoices,
  updateInvoices
} from '../../actions/actions-invoices';


class InvoicesView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoices: {},
      loading: false
    }
  }

  componentWillMount() {

    this.setState({ loading: true });
    client.authenticate()
      .then(() => {
        return client.service('invoices').get(this.props.match.params.id)
      })
      .then((res) => {
        //
        this.setState({
          invoices: res,
          loading: false
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      })
  }

  componentDidMount() {

    client.service('invoices').on('patched', (invoice) => {
      if (invoice._id === this.state.invoices._id) {
        this.setState({ invoices: invoice });
      }
    })

  }

  handleSubmit = (e) => {
    e.preventDefault();
  }

  getDocumentTitle = (v) => {
    return document.title = v.invoiceNo;
  };

  render() {

    return (
      <Layout>
        <Spin spinning={this.state.loading}>
          {/* {this.state.invoices.status==='PAID'?'':<PaymentForm invoice={this.state.invoices} totalAmount={this.state.invoices.totalAmt} />} */}
          <ReactToPrint
            onBeforePrint={() => this.getDocumentTitle(this.state.invoices)}
            trigger={() => <Row><Col style={{ float: 'right', marginBottom: '5px' }}><Button type="primary">Print</Button></Col></Row>}
            content={() => this.componentRef}
          />
          <InvoicePrint invoiceId={this.props.match.params.id} ref={el => (this.componentRef = el)} />
        </Spin>
      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    invoice: state.invoice
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchInvoices: fetchInvoices,
    pushInvoices: pushInvoices,
    removeInvoices: removeInvoices,
    updateActiveInvoices: updateActiveInvoices,
    updateInvoices: updateInvoices,
    updateMode: updateMode
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(InvoicesView));
