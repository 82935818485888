import React, { Component } from 'react'
import { Select, Popover, Divider, Popconfirm, Table, Form, Input, Button,Card, Row, Col, message, Tag, Spin } from 'antd'
import client from '../../feathers'
import requireAuth  from "../../components/requireAuth"
import Layout  from "../../components/layout"
import _ from 'lodash'

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
// import {
//   fetchProducts,
//   pushProducts,
//   removeProducts,
//   updateMode,
//   updateActiveProduct,
//   updateProduct } from '../../actions/products-actions';

// const FormItem = Form.Item;
const Option = Select.Option;
const PAGESIZE = 10;

class LedgerTransactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:[],
      searchName:'',
      searchReferenceId:'',
      searchObj:{},
      total:0,
      skip:0,
      pageNo:1,
    }

    this._searchNameChange = this._searchNameChange.bind(this);
  }

  componentWillMount(){
    this._getData(0)
  }

  _getData(skip){
    // console.log({skip});
    this.setState({loading: true});

    let query = {
      query:{
         orRegex:this.state.searchObj,
         $populate:['ledgerAccountId'],
         $sort: {
           trxDate: -1,
           // productName:1,
         },
         $skip:skip,
         $limit:PAGESIZE,
       }
    }


    // console.log({query});
    client.authenticate()
    .then(()=>{
      return client.service('ledger-transactions').find(query)
    })
    .then((res)=>{
      // console.log({res});
      this.setState({
        data:res.data,
        total:res.total,
        loading: false,
      })
    })
    .catch((err)=>{
      console.log(err);
    })
  }

  _handlePageChange(pagination, filters, sorter){
    this.setState({
      pageNo: pagination.current
    })
    this._getData(((pagination.current) * PAGESIZE)-PAGESIZE)
  }

  _searchNameChange(e){
    this.setState({
        searchName:e.target.value,
        searchObj:_.merge(this.state.searchObj,e.target.value?{name:e.target.value}:{name:'.'})
      },
    ()=>{
      this._getData(this.state.skip)
    })
  }

  _searchReferenceIdChange(e){
    this.setState({
        searchReferenceId:e.target.value,
        searchObj:_.merge(this.state.searchObj,e.target.value?{referenceId:e.target.value}:{referenceId:'.'})
      },
    ()=>{
      this._getData(this.state.skip)
    })
  }

  render() {

    const columns = [
      {
        title: (
          <Row gutter={8}>
            <Row><Col span={24}>Name</Col></Row>
            {/*<Row><Col span={24}><Input value={this.state.searchName} onChange={(e)=>this._searchNameChange(e)}/></Col></Row>*/}
          </Row>
        ),
        dataIndex: 'companyType',
        key: '1',
      },
      {
        title: 'Amount',
        dataIndex: 'creditAmount',
        key: '2',
        align:'right',
        render: (v, r) => (<span>{(r.creditAmount||r.debitAmount).toFixed(2)}</span>)
      },
      {
        title: 'Credit Debit',
        dataIndex: 'creditDebit',
        key: '3',
      },
      {
        title: (
          <Row gutter={8}>
            <Row><Col span={24}>Reference Id</Col></Row>
            <Row><Col span={24}><Input value={this.state.searchReferenceId} onChange={(e)=>this._searchReferenceIdChange(e)}/></Col></Row>
          </Row>
        ),
        dataIndex: 'referenceId',
        key: '4',
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: '5',
      },
      {
        title: 'Account No',
        dataIndex: 'ledgerAccountId.accountNo',
        key: '6',
      },
      {
        title: 'Sub Account No',
        dataIndex: 'ledgerAccountId.subAccountNo',
        key: '7',
      },
      {
        title: 'Account Desc',
        dataIndex: 'ledgerAccountId.description',
        key: '8',
      },
      {
        title: 'Categories',
        dataIndex: 'ledgerAccountId.categories',
        key: '9',
      },
      {
        title: 'Trx Date',
        dataIndex: 'ledgerAccountId.trxDate',
        key: '10',
        render:(v,r)=>(<span>{moment(v).format('DD-MM-YYYY')}</span>)
      },
      {
        title: 'System Date',
        dataIndex: 'ledgerAccountId.createdAt',
        key: '11',
        render:(v,r)=>(<span>{moment(v).format('DD-MM-YYYY H:mm:ss')}</span>)
      },
      // {
      //   title: 'Action',
      //   key: 'action',
      //   render: (text, record) => (
      //     <span>
      //       {/*}<Link to={{ pathname: `/product/view/${record._id}` }} ><Button>Manage</Button></Link>
      //       <Divider type="vertical" />*/}
      //       <Button onClick={()=>this.handleEdit(record._id)}>Edit</Button>
      //       <Divider type="vertical" />
      //       {this.renderConfirmDeleteBtn(record._id, record.status)}
      //     </span>
      //   ),
      // }
  ];
      return(
        <Layout>
          <Spin spinning={this.state.loading}>
            <Card title="Ledger Transactions" >
              <Table
                  bordered
                  size='small'
                  scroll={{ x: 1500 }}
                  dataSource={this.state.data}
                  columns={columns}
                  rowKey="_id"
                  pagination={{
                    total:this.state.total,
                    position:'both'
                  }}
                  onChange={(e)=>this._handlePageChange(e)}
                />
            </Card>
          </Spin>
        </Layout>
      )
  }
}


function mapStateToProps(state) {
  return {
    user: state.user,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(LedgerTransactions)));
