import _ from 'lodash';
import {
  FETCH_USERPROPERTIES,
  PUSH_USERPROPERTIES,
  REMOVE_USERPROPERTIES,
  UPDATE_USERPROPERTIES,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID } from '../actions/actions-userproperties';

const INITIAL_STATE = {
  userproperties: [],
  mode: 'index',
  activeId: '',
  activeUserproperties:{}
}


export default function(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_USERPROPERTIES:
      return {
        ...state,
        userproperties:action.payload,
      }
    case PUSH_USERPROPERTIES:
      return {
        ...state,
        userproperties:[action.payload, ...state.userproperties],
      }
    case REMOVE_USERPROPERTIES:
      return {
        ...state,
        userproperties : [...state.userproperties.filter( (item) => item._id !== action.payload._id)]
      }
    case UPDATE_USERPROPERTIES:
      let index =  _.findIndex(state.userproperties, { '_id': action.payload._id })
      state.userproperties.splice(index, 1, action.payload)
      return {
        ...state,
        userproperties : state.userproperties
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode : action.payload,
        activeId:''
      }
    case UPDATE_ACTIVE_ID:
      return {
        ...state,
        mode : action.mode,
        activeId:action.id,
        activeUserproperties: _.pick(_.find(state.userproperties, { '_id': action.id }), ['userId', 'email', 'referenceId', 'type', '_id'])
      }
    default:
      return state

  }
}
