import React, { Component } from 'react';
import { Modal, Avatar, Row, Col, Card, Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Select, Radio, Spin } from 'antd';
import client from '../../feathers';
import requireAuth from "../../components/requireAuth";
import Layout from "../../components/layout";
import Info from "../../components/common/info";
import PaymentVouchersPrint from "../../components/paymentvoucher/paymentvoucher-print";
import ReactToPrint from "react-to-print";


import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchPaymentVouchers,
  pushPaymentVouchers,
  removePaymentVouchers,
  updateMode,
  updateActivePaymentVouchers,
  updatePaymentVouchers
} from '../../actions/actions-paymentvouchers';

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

class PaymentVouchersView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentvouchers: [],
      loading: false
    }
  }

  componentWillMount() {

    // this.setState({loading:true});
    // client.authenticate()
    // .then(()=>{
    //   return client.service('paymentvouchers').get(this.props.match.params.id)
    // })
    // .then((res)=>{
    //   //
    //   this.setState({
    //     paymentvouchers:res,
    //     loading:false
    //   });
    // })
    // .catch((err)=>{
    //   this.setState({loading:false});
    //   console.log(err);
    // })
  }

  componentDidMount() {

  }

  handleSubmit = (e) => {
    e.preventDefault();
  }


  render() {

    return (
      <Layout>
        <Spin spinning={this.state.loading}>
          <Row>
            <Col style={{ marginBottom: 10, float: 'right' }}>
              <ReactToPrint
                trigger={() => <Button type="primary">Print</Button>}
                content={() => this.componentRef}
              />
            </Col>
          </Row>

          <PaymentVouchersPrint paymentsvouchermastersId={this.props.match.params.id} ref={el => (this.componentRef = el)} />

        </Spin>
      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    jbms: state.jbms,
    paymentvouchers: state.paymentvouchers
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchPaymentVouchers: fetchPaymentVouchers,
    pushPaymentVouchers: pushPaymentVouchers,
    removePaymentVouchers: removePaymentVouchers,
    updateActivePaymentVouchers: updateActivePaymentVouchers,
    updatePaymentVouchers: updatePaymentVouchers,
    updateMode: updateMode
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(PaymentVouchersView));
