import _ from 'lodash';
import {
  FETCH_NOTICES,
  PUSH_NOTICES,
  REMOVE_NOTICES,
  UPDATE_NOTICES,
  UPDATE_NOTICES_MODE,
  UPDATE_NOTICES_ACTIVE_ID } from '../actions/actions-notices';

const INITIAL_STATE = {
  notices: [],
  mode: 'index',
  activeId: '',
  activeNotices:{}
}


export default function(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_NOTICES:
      return {
        ...state,
        notices:action.payload,
      }
    case PUSH_NOTICES:
      return {
        ...state,
        notices:[action.payload, ...state.notices],
      }
    case REMOVE_NOTICES:
      return {
        ...state,
        notices : [...state.notices.filter( (item) => item._id !== action.payload._id)]
      }
    case UPDATE_NOTICES:
      let index =  _.findIndex(state.notices, { '_id': action.payload._id })
      state.notices.splice(index, 1, action.payload)
      return {
        ...state,
        notices : state.notices
      }
    case UPDATE_NOTICES_MODE:
      return {
        ...state,
        mode : action.payload,
        activeId:''
      }
    case UPDATE_NOTICES_ACTIVE_ID:
      return {
        ...state,
        mode : action.mode,
        activeId:action.id,
        activeNotices: _.pick(_.find(state.notices, { '_id': action.id }), ['subject', 'description', 'priority', 'target', 'status','date', 'recurring', '_id'])
      }
    default:
      return state

  }
}
