import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Login from './components/loginForm';
import PropertyIndex from './components/property/property-index';
import PropertyView from './components/property/property-view';
import PropertyunitsIndexMng from './components/propertyunits/index-mng';
import PropertyunitsIndex from './components/propertyunits/index';
import PropertyunitsView from './components/propertyunits/view';
import PropertyunitsUser from './components/propertyunits-user/index';
import UserIndex from './components/users/index';
import UserView from './components/users/view';
import UserMngIndex from './components/users-mng/index';
import MngUserIndex from './components/users-mng/index-mng';
import ManagementIndex from './components/management/index';
import ManagementView from './components/management/view';
import AccounttrxsIndex from './components/accounttrxs/index';
import AccountmainsIndex from './components/accountmains/index';
import AccountsIndex from './components/accounts/index';
import AccountsIndexAssest from './components/accounts/index-assest';
import AccountsIndexLiabilities from './components/accounts/index-liabilities';
import AccountsIndexIncome from './components/accounts/index-income';
import AccountsIndexExpense from './components/accounts/index-expense';
import AccountsIndexEquity from './components/accounts/index-equity';
import AccountsBalanceSheet from './components/accounts/balance-sheet';
import AccountsProfitAndLoss from './components/accounts/profit-and-loss';

import AdsIndex from './components/ads/index';
import DashboardIndex from './components/dashboard/index';
import FacilitiesIndex from './components/facilities/index';
import FacilityBookingsIndex from './components/facilitybookings/index';
import MngpropertiesIndex from './components/mngproperties/index';
import UserpropertiesIndex from './components/userproperties/index';
import ComplaintsIndex from './components/complaints/index';
import InvoicesIndex from './components/invoices/index';
import InvoicesNew from './components/invoices/form-new';
import InvoicesView from './components/invoices/view';
import InvoicesPropertyunitIndex from './components/invoices/index-propertyunit';
import VendorsIndex from './components/vendors/index';
import PassesIndex from './components/passes/index';
import ProfomasIndex from './components/profomas/index';
import ChargesIndex from './components/charges/index';
import NoticesIndex from './components/notices/index';
import SettingsIndex from './components/settings/index';
import SettingsFormInvoiceTnc from './components/settings/form-invoice-tnc';

import SuppliersIndex from './components/suppliers/index';
import ReceiptsIndex from './components/receipts/index';
import LedgersIndex from './components/ledgers/index';
import TransactionsIndex from './components/transactions/index';
import TransactionsView from './components/transactions/view';
import FilesIndex from './components/files/index';
import PropertyunitApproval from './components/propertyunits/approval';
import RequestsIndex from './components/requests/index';
import EformsIndex from './components/eforms/index';
import EformsForm from './components/eforms/form';
import EformsCard from './components/eforms/form-card';
import EformsCommonArea from './components/eforms/form-move';
import EformsRenovation from './components/eforms/form-renovation';

import LedgerIndexDebtors from './components/ledgers/index-debtors';
import LedgerIndexAccounts from './components/ledgers/index-accounts';
import LedgerIndexMainTransactions from './components/ledgers/index-main-transactions';
import LedgerIndexJournalEntries from './components/ledgers/index-journalentries';
import LedgerIndexJournalEntriesPrint from './components/ledgers/journalEntries-print';
import LedgerIndexCashBankEntriesPrint from './components/ledgers/cashBank-print';

import LedgerIndexTransactions from './components/ledgers/index-transactions';
// import  LedgerFormOpen  from './components/ledgers/form-open';
import LedgerFormClose from './components/ledgers/form-close';

import ChartIndexOrganizational from './components/charts/index-organizational';
import JmbsIndex from './components/jmbs/index';
import JmbsMngIndex from './components/jmbs/index-mng';

import RecurringItemIndex from './components/recurringitems/index';
import InvoiceIndex from './components/invoices/index-invoice';
import LateChargeIndex from './components/late-charge/index';

import InvoicesIndexKnockoffByInvoice from './components/invoices/index-knockoff-by-invoice';
import InvoicesIndexByInvoiceDetails from './components/invoices/index-by-invoice-details';
import InvoicesIndexKnockoffByPropertyunit from './components/invoices/index-knockoff-by-propertyunit';
import RequestsIndexMng from './components/requests/index-mng';
import AccountingPeriodsIndex from './components/accounting-periods/index';

import ReportsIndexProfitAndLoss from './components/reports/index-profit-and-loss';
import ReportsIndexProfitAndLossYearly from './components/reports/index-profit-and-loss-yearly';
import ReportsIndexBalanceSheet from './components/reports/index-balance-sheet';
import ReportsIndexTrialBalance from './components/reports/index-trial-balance';
import ReportsIndexStatementOfAccount from './components/reports/index-statement-of-account';
import ReportsIndexReceiptAndPayment from './components/reports/index-receipt-and-payment';


import ApplogsIndex from './components/applogs/index';
import ApplogsForm from './components/applogs/form';

import PrepaymentsIndex from './components/prepayments/index';
import VendorTypeIndex from './components/settings/index-vendortype';
import RefundIndex from './components/refund/index-refund';

import WaiverIndex from './components/waiver/index-waiver';

//import  ReceiptsView  from './components/receipts/receipts-view';

// import BankReconsIndex from './components/bankrecons/index';
// import BankReconsMaster from './components/bankrecons/index-master';
// import BankReconsDailyIndex from './components/bankrecons/index-daily';
// import BankReconsStatement from './components/bankrecons/bankrecons-statement';

import BankReconsStatement from './components/bankrecons/bankrecons-statement';
import BankReconsIndex from './components/bankrecons/index';
import BankReconsDailyIndex from './components/bankrecons/index-daily';
import BankReconsMainIndex from './components/bankrecons/index-main';
import BankReconsMaster from './components/bankrecons/index-master';

import StatementofAccount from './components/statementofaccount/index';

import PaymentVoucherIndex from './components/paymentvoucher/index';
import PaymentVoucherView from './components/paymentvoucher/paymentvoucher-view';
import PaymentVoucherViewDetail from './components/paymentvoucher/index-detail';
import PaymentVoucherPay from './components/paymentvoucher/index-paymentvoucher-pay';
import PaymentVoucherMultiPay from './components/paymentvoucher/index-paymentvoucher-multipay';

import TrxParameterIndex from './components/trxparameter/index';

import FpxParameterIndex from './components/fpxparameter/index';
import DocumentIndex from './components/document/index';
import ReceiptNewSingle from './components/receipts/form-new-single';
import ReceiptNew from './components/receipts/form-new';
import ReceiptForm from './components/receipts/form'
// import ReceiptCN from './components/receipts/form-CN'
import ReceiptFormCn from './components/receipts/form-cn'
import ReceiptFormDn from './components/receipts/form-dn'

import ChartOfAccountsIndex from './components/chartOfAccounts/index';
import ChartOfAccountsView from './components/chartOfAccounts/view';
import DebtorAgingView from './components/debtorAging/view';
import CreditorAgingView from './components/creditorAging/view';

import soaPrintAll from './components/statementofaccount/formsoa';
import soaGLPrintAll from './components/statementofaccount/formsoaGL';

import interestDebitNote from './components/interestDebitNote/index';
import interestDebitNotePrint from './components/interestDebitNote/print';

import reminderLetter from './components/reminderLetter/index';
import reminderLetterPrint from './components/reminderLetter/print';
import reminderLetterPrintAll from './components/reminderLetter/printall';

import NODFORM11LetterPrint from './components/reminderLetter/NODForm11print';
import NODFORM11LetterPrintAll from './components/reminderLetter/NODForm11printAll';
import NODFORM20LetterPrint from './components/reminderLetter/NODForm20print';
import NODFORM20LetterPrintAll from './components/reminderLetter/NODForm20printAll';

import paymentTypeParamsIndex from './components/paymentTypeParams/index';
import meterReadingsIndex from './components/meterReadings/index';
import accountListIndex from './components/ledgers/index-accountList';

import depositIndex from './components/deposit/index';
import depositNew from './components/deposit/form-new';
import depositIndexKnockoffByInvoice from './components/deposit/index-knockoff-by-invoice';
import RefundDepositIndex from './components/refund/index-deposit';
import RefundListingIndex from './components/refund/index-listing';

import WaterBillIndex from './components/waterbills/index';
import debitNotesIndex from './components/debitnotes/index';
import debitNotesNew from './components/debitnotes/form-new';
import debitNotesView from './components/debitnotes/view';
import debitNotesPrint from './components/debitnotes/print';
import creditNotesIndex from './components/creditnotes/index';
import creditNotesPrint from './components/creditnotes/print';

import debitNotesSupplierIndex from './components/debitnotes-supplier/index';
import debitNotesSupplierPrint from './components/debitnotes-supplier/print';
import creditNotesSupplierIndex from './components/creditnotes-supplier/index';
import creditNotesSupplierPrint from './components/creditnotes-supplier/print';

import PrepaymentbyPropertyIndex from './components/prepayments/index-mng';
import WaiverPropertyIndex from './components/waiver/index-mng';

import moneyInTransit from './components/moneyInTransit/index';
import moneyInTransitStatement from './components/moneyInTransit/statement';
import refundStatementPrint from './components/refund/refund-view';

import uploadsIndex from './components/uploads/index-waterbill';
import uploadAdminIndex from './components/uploads/index';

import LedgerFormOpen from './components/firstTime/ledger-open';
import InvoiceFormOpenIndex from './components/firstTime/invoice-index';
import InvoiceFormOpenForm from './components/firstTime/invoice-open';
import PrepaymentFormOpenForm from './components/firstTime/prepayment-form';

import uploadsFirstOPIndex from './components/uploads/index-firstOpeningBalance';
import manageGuardIndex from "./components/users/form-security";


//for bryan
import LedgerTransactions from './components/reports/index-ledger-transactions';
import PinboardIndex from './components/pinboard/index'
import HELPIndex from './components/HELP/index'
import CashAndbanktransactionsIndex from './components/banktrxs/index';
import supplierAccountStatement from './components/statementofaccount/index-supplier'
// import ReceiptNewSingle from './components/receipts/form-new-single';
import RefundNew from './components/refund/form-refund';
import PaymentLog from './components/paymentLog/index'
// import PaymenVoucherFormNew from './components/paymentvoucher/form-paymentvoucher-new'
import './App.css';
import paymentLog from './components/paymentLog/index';

class App extends Component {

  render() {
    return (
      <BrowserRouter>
        <div>
          <Route path="/invoices/index-knockoff-by-invoice/:id" exact component={InvoicesIndexKnockoffByInvoice} />
          <Route path="/invoices/index-knockoff-by-propertyunit/:id" exact component={InvoicesIndexKnockoffByPropertyunit} />

          <Route path="/" exact component={Login} />
          <Route path="/property" exact component={PropertyIndex} />
          <Route path="/property/view/:id" component={PropertyView} />
          <Route path="/propertyunitsmng" exact component={PropertyunitsIndexMng} />
          <Route path="/propertyunits" exact component={PropertyunitsIndex} />
          <Route path="/propertyunits/view/:id" component={PropertyunitsView} />
          <Route path="/propertyunits/user/:id" component={PropertyunitsUser} />
          <Route path="/users" exact component={UserIndex} />
          <Route path="/users/view/:id" component={UserView} />
          <Route path="/users-mng" exact component={UserMngIndex} />
          <Route path="/mng-users" exact component={MngUserIndex} />

          <Route path="/management" exact component={ManagementIndex} />
          <Route path="/management/view/:id" component={ManagementView} />
          <Route path="/accountmains" exact component={AccountmainsIndex} />
          <Route path="/accounts" exact component={AccountsIndex} />
          <Route path="/accounttrxs" exact component={AccounttrxsIndex} />
          <Route path="/accounts-assest" exact component={AccountsIndexAssest} />
          <Route path="/accounts-liabilities" exact component={AccountsIndexLiabilities} />
          <Route path="/accounts-income" exact component={AccountsIndexIncome} />
          <Route path="/accounts-expense" exact component={AccountsIndexExpense} />
          <Route path="/accounts-equity" exact component={AccountsIndexEquity} />
          <Route path="/balance-sheet" exact component={AccountsBalanceSheet} />
          <Route path="/profit-and-loss" exact component={AccountsProfitAndLoss} />

          <Route path="/ads" exact component={AdsIndex} />
          <Route path="/dashboard" exact component={DashboardIndex} />
          <Route path="/facilities" exact component={FacilitiesIndex} />
          <Route path="/facilitybookings" exact component={FacilityBookingsIndex} />
          <Route path="/mngproperties" exact component={MngpropertiesIndex} />
          <Route path="/userproperties" exact component={UserpropertiesIndex} />
          <Route path="/userproperties/:id" exact component={UserpropertiesIndex} />
          <Route path="/complaints" exact component={ComplaintsIndex} />
          <Route path="/invoices" exact component={InvoicesIndex} />
          <Route path="/invoices/new" exact component={InvoicesNew} />
          <Route path="/invoices/view/:id" exact component={InvoicesView} />
          <Route path="/invoices/propertyunit/:id" exact component={InvoicesPropertyunitIndex} />

          <Route path="/vendors" exact component={VendorsIndex} />
          <Route path="/passes" exact component={PassesIndex} />
          <Route path="/profomas/:id" exact component={ProfomasIndex} />
          <Route path="/charges" exact component={ChargesIndex} />
          <Route path="/notices" exact component={NoticesIndex} />
          <Route path="/settings" exact component={SettingsIndex} />
          <Route path="/settings/invoice-tnc" exact component={SettingsFormInvoiceTnc} />

          <Route path="/suppliers" exact component={SuppliersIndex} />
          <Route path="/receipts" exact component={ReceiptsIndex} />
          {/*<Route path="/ledgers" exact component={LedgersIndex}/>
          <Route path="/ledgers/:id" exact component={LedgersIndex}/>*/}
          <Route path="/transactions" exact component={TransactionsIndex} />
          <Route path="/transactions/:id" exact component={TransactionsView} />
          <Route path="/files" exact component={FilesIndex} />
          <Route path="/propertyunits/approval/:id" exact component={PropertyunitApproval} />
          <Route path="/requests" exact component={RequestsIndex} />
          <Route path="/eforms" exact component={EformsIndex} />
          <Route path="/eforms/card/:id" exact component={EformsCard} />
          <Route path="/eforms/commonArea/:id" exact component={EformsCommonArea} />
          <Route path="/eforms/renovation/:id" exact component={EformsRenovation} />
          <Route path="/eforms/form" exact component={EformsForm} />

          <Route path="/ledgers/debtors" exact component={LedgerIndexDebtors} />
          <Route path="/ledgers/accounts" exact component={LedgerIndexAccounts} />
          <Route path="/ledgers/main-transactions" exact component={LedgerIndexMainTransactions} />
          <Route path="/ledgers/journalentries" exact component={LedgerIndexJournalEntries} />
          <Route path="/ledgers/journalentriesPrint/:referenceId" exact component={LedgerIndexJournalEntriesPrint} />
          <Route path="/ledgers/cashBankPrint/:referenceId" exact component={LedgerIndexCashBankEntriesPrint} />

          <Route path="/ledgers/transactions/:id" exact component={LedgerIndexTransactions} />
          {/*
          <Route path="/ledgers/open" exact component={LedgerFormOpen}/>
          */}
          <Route path="/ledgers/close" exact component={LedgerFormClose} />

          <Route path="/charts/organizational" exact component={ChartIndexOrganizational} />
          <Route path="/jmbs/:id" exact component={JmbsIndex} />
          <Route path="/jmbs" exact component={JmbsMngIndex} />

          <Route path="/recurring-items" exact component={RecurringItemIndex} />
          <Route path="/invoices/invoice/:id" exact component={InvoiceIndex} />
          <Route path="/invoices/invoice/details/:id" exact component={InvoicesIndexByInvoiceDetails} />

          <Route path="/late-charge" exact component={LateChargeIndex} />
          <Route path="/requests/index-mng" exact component={RequestsIndexMng} />
          <Route path="/accounting-periods" exact component={AccountingPeriodsIndex} />

          <Route path="/reports/profit-and-loss" exact component={ReportsIndexProfitAndLoss} />
          <Route path="/reports/profit-and-loss-yearly" exact component={ReportsIndexProfitAndLossYearly} />
          <Route path="/reports/balance-sheet" exact component={ReportsIndexBalanceSheet} />
          <Route path="/reports/trial-balance" exact component={ReportsIndexTrialBalance} />
          <Route path="/reports/receipt-and-payment" exact component={ReportsIndexReceiptAndPayment} />

          <Route path="/refunds/index-refund/:id" exact component={RefundIndex} />

          <Route path="/settings/index-vendortype" exact component={VendorTypeIndex} />

          <Route path="/reports/statement-of-account/:id" exact component={ReportsIndexStatementOfAccount} />

          <Route path="/applogs" exact component={ApplogsIndex} />
          <Route path="/applogs/form/:id" exact component={ApplogsForm} />

          <Route path="/prepayments/:id" exact component={PrepaymentsIndex} />

          <Route path="/waiver/:id" exact component={WaiverIndex} />

          {/*  <Route path="/receipts/receipts-view/:id" exact component={ReceiptsView}/> */}

          {/* <Route path="/bankrecons/index/" exact component={BankReconsIndex} />
          <Route path="/bankrecons/index-master/" exact component={BankReconsMaster} />
          <Route path="/bankrecons/index-master/index-daily/:id" exact component={BankReconsDailyIndex} />
          <Route path="/bankrecons-statement/:id" exact component={BankReconsStatement} /> */}

          <Route path="/bankrecons-statement/:id" exact component={BankReconsStatement} />
          <Route path="/bankrecons/index-master/" exact component={BankReconsMaster} />
          <Route path="/bankrecons/index-master/index-daily/:id" exact component={BankReconsDailyIndex} />
          <Route path="/bankrecons/index/" exact component={BankReconsIndex} />
          <Route path="/bankrecons/main/" exact component={BankReconsMainIndex} />

          <Route path="/statementofaccount" exact component={StatementofAccount} />

          <Route path="/paymentvoucher" exact component={PaymentVoucherIndex} />
          <Route path="/paymentvoucher/paymentvoucher-view/:id" exact component={PaymentVoucherView} />
          <Route path="/paymentvoucher/paymentvoucher-view-detail/:id" exact component={PaymentVoucherViewDetail} />
          <Route path="/paymentvoucher/paymentvoucher-pay/:id" exact component={PaymentVoucherPay} />
          <Route path="/paymentvoucher/paymentvoucher-multipay/:id" exact component={PaymentVoucherMultiPay} />
          {/* <Route path="/paymentvoucher/pay-supplier/:id" exact component={PaymenVoucherFormNew} /> */}

          <Route path="/trxparameter" exact component={TrxParameterIndex} />

          <Route path="/fpxparameter" exact component={FpxParameterIndex} />
          <Route path="/document" exact component={DocumentIndex} />
          <Route path="/receipts/new-single" exact component={ReceiptNewSingle} />
          <Route path="/receipts/new" exact component={ReceiptNew} />
          <Route path="/chartOfAccounts" exact component={ChartOfAccountsIndex} />
          <Route path="/chartOfAccounts/view" exact component={ChartOfAccountsView} />
          <Route path="/debtorAging/view" exact component={DebtorAgingView} />
          <Route path="/creditorAging/view" exact component={CreditorAgingView} />
          <Route path="/soaPrintAll/:id" exact component={soaPrintAll} />
          <Route path="/soaGLPrintAll/:id" exact component={soaGLPrintAll} />
          <Route path="/interestDebitNote/:id" exact component={interestDebitNote} />
          <Route path="/interestDebitNotePrint/" exact component={interestDebitNotePrint} />
          <Route path="/reminderLetter/" exact component={reminderLetter} />
          <Route path="/reminderLetter/print/:id" exact component={reminderLetterPrint} />
          <Route path="/reminderLetter/printAll/:id" exact component={reminderLetterPrintAll} />
          <Route path="/NODFORM11Letter/print/:id" exact component={NODFORM11LetterPrint} />
          <Route path="/NODFORM11Letter/printAll/:id" exact component={NODFORM11LetterPrintAll} />
          <Route path="/NODFORM20Letter/print/:id" exact component={NODFORM20LetterPrint} />
          <Route path="/NODFORM20Letter/printAll/:id" exact component={NODFORM20LetterPrintAll} />
          <Route path="/paymentTypeParams/" exact component={paymentTypeParamsIndex} />
          <Route path="/meterReadings/" exact component={meterReadingsIndex} />

          <Route path="/ledgers/accountList/:id" exact component={accountListIndex} />
          <Route path="/deposit/" exact component={depositIndex} />
          <Route path="/deposit/new" exact component={depositNew} />
          <Route path="/deposit/index-knockoff-by-invoice/:id" exact component={depositIndexKnockoffByInvoice} />
          <Route path="/refunds/index-deposit/:id" exact component={RefundDepositIndex} />
          <Route path="/refunds/index-listing/" exact component={RefundListingIndex} />
          <Route path="/refunds/new/" exact component={RefundNew} />
          <Route path="/waterbills/" exact component={WaterBillIndex} />
          <Route path="/debitnotes/" exact component={debitNotesIndex} />
          <Route path="/debitnotes/new" exact component={debitNotesNew} />
          <Route path="/debitnotes/view/:id" exact component={debitNotesView} />
          <Route path="/debitnotes/print/:id" exact component={debitNotesPrint} />
          <Route path="/creditnotes/" exact component={creditNotesIndex} />
          <Route path="/creditnotes/print/:id" exact component={creditNotesPrint} />

          <Route path="/debitnotesSupplier/" exact component={debitNotesSupplierIndex} />
          <Route path="/debitnotesSupplier/print/:id" exact component={debitNotesSupplierPrint} />
          <Route path="/creditnotesSupplier/" exact component={creditNotesSupplierIndex} />
          <Route path="/creditnotesSupplier/print/:id" exact component={creditNotesSupplierPrint} />
          <Route path="/prepaymentbyProps" exact component={PrepaymentbyPropertyIndex} />
          <Route path="/waiverbyProps" exact component={WaiverPropertyIndex} />
          <Route path="/moneyInTransit" exact component={moneyInTransit} />
          <Route path="/moneyInTransitStatement" exact component={moneyInTransitStatement} />
          <Route path="/refundStatement/:id" exact component={refundStatementPrint} />
          <Route path="/uploads/waterBill" exact component={uploadsIndex} />
          <Route path="/adminUploads/" exact component={uploadAdminIndex} />
          <Route path="/firstTime/ledger" exact component={LedgerFormOpen} />
          <Route path="/firstTime/invoice" exact component={InvoiceFormOpenIndex} />
          <Route path="/firstTime/invoice/open/:id" exact component={InvoiceFormOpenForm} />
          <Route path="/firstTime/prepayment/open/:id" exact component={PrepaymentFormOpenForm} />
          <Route path="/uploads/firstOpeningBalance" exact component={uploadsFirstOPIndex} />

          <Route path="/reports/ledger-transactions" exact component={LedgerTransactions} />

          <Route path="/manageGuard" exact component={manageGuardIndex} />
          <Route path="/pinboard" exact component={PinboardIndex} />
          <Route path="/help" exact component={HELPIndex} />


          <Route path="/cashAndbanktransactions" exact component={CashAndbanktransactionsIndex} />
          <Route path="/supplierStatementAccount" exact component={supplierAccountStatement} />
          <Route path="/receipts-form" exact component={ReceiptForm} />
          {/* <Route path="/receipts-CN" exact component={ReceiptCN} /> */}
          <Route path="/receipts-cn" exact component={ReceiptFormCn} />
          <Route path="/receipts-dn" exact component={ReceiptFormDn} />
          <Route path='/paymentlog' exact component={paymentLog} />
          {/* <Route path="/receipts/new-single" exact component={ReceiptNewSingle} /> */}


        </div>
      </BrowserRouter>
    );
  }
}

export default App;
