import React, { Component, useEffect } from 'react'
import { AutoComplete, Modal, Upload, Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Select, InputNumber, Spin, DatePicker } from 'antd'
import client from '../../feathers'
import requireAuth from "../../components/requireAuth"
import Layout from "../../components/layout"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateMode } from '../../actions/actions-creditnotes'
import axios from 'axios';
import params from '../../params/system'
import moment from 'moment';
import _ from "lodash"


const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

//----------------------------------------------------------------------------
class CreditNotesForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',
      confirmDirty: false,
      autoCompleteResult: [],
      referenceformat: '',
      ledgerAccountList: [],
      formloading: false,
      dtAccountList: [],
      ctAccountList: [],
      invoices: [],
      receiptId: null,
      receiptPaidAmount: null
    }

  }
  //----------------------------------------------------------------------------
  componentDidUpdate() {

    if (this.props.creditnotes.activeId !== this.state._id) {
      this.setState({
        _id: this.props.creditnotes.activeId
      })

      if (this.props.creditnotes.activeCreditNotes) {
        this.props.form.setFieldsValue({
          ctledgerAccountId: this.props.creditnotes.activeCreditNotes.ctledgerAccountId._id,
          dtledgerAccountId: this.props.creditnotes.activeCreditNotes.dtledgerAccountId._id,
          creditAmount: this.props.creditnotes.activeCreditNotes.creditAmount,
          invoiceNo: this.props.creditnotes.activeCreditNotes.invoiceNo,
          invoiceDate: moment(this.props.creditnotes.activeCreditNotes.invoiceDate),
          trxDate: moment(this.props.creditnotes.activeCreditNotes.trxDate),
          remark: this.props.creditnotes.activeCreditNotes.remark,
        })
      }
    }

  }

  //----------------------------------------------------------------------------
  handleSubmit = (e) => {

    this.setState({ formloading: true })
    if (this.props.creditnotes.mode === 'new') {

      this.props.form.validateFields((err, values) => {

        if (!err) {

          values.trxDate = new Date(values.trxDate)
          values.invoiceDate = new Date(values.invoiceDate)

          client.authenticate()
            .then((res) => {
              return client.service('creditnotes').create({
                companyId: this.props.commons.selectedCompany._id,
                ...values,
                divisionType: 'SUPPLIER',
                trancode: '1600',
                receiptId: this.state.receiptId,
                receiptPaidAmount: this.state.receiptPaidAmount
              })
            })
            .then((res) => {
              this.props.updateMode('refresh')
              this.props.form.resetFields()
              this.setState({
                formloading: false,
              });
            })
            .catch(err => { console.log(err) })
        }

      });


    }
    else {
      this.props.form.validateFields((err, values) => {

        if (!err) {

          this.setState({
            formloading: true,
          });

          values.trxDate = new Date(values.trxDate)
          values.invoiceDate = new Date(values.invoiceDate)

          client.authenticate()
            .then((res) => {
              return client.service('creditnotes').patch(this.props.creditnotes.activeId, {
                companyId: this.props.commons.selectedCompany._id,
                ...values,
              })
            })
            .then((res) => {

              this.props.updateMode('refresh')
              this.props.form.resetFields()
              this.setState({
                formloading: false,
              });
            })
            .catch(err => { console.log(err) })
        }

      });
    }
  }
  //----------------------------------------------------------------------------
  //----------------------------------------------------------------------------
  componentWillMount() {
    client.authenticate()
      .then((res) => {
        return client.service('ledger-accounts').find({
          query: {
            companyId: this.props.commons.selectedCompany._id,
            $sort: { accountName: 1 },
            accountNo: '4000',
          }
        })
      })
      .then((res) => {
        this.setState({ dtAccountList: res.data });

        return client.service('payment-type-params').find({
          query: {
            $populate: 'ledgerAccountId',
            propertyId: this.props.commons.selectedCompany._id,
            category: {
              $in: ['SUPPLIERCREDITNOTES']
            },
          }
        })
      })
      .then((res) => {

        this.setState({
          ctAccountList: res.data
        });

        // this.getExpenses()
      })
      .catch((err) => {
        console.log(err);
      });

  }

  resetForm() {
    this.props.form.setFieldsValue({
      ctledgerAccountId: null,
      dtledgerAccountId: null,
      creditAmount: null,
      invoiceNo: null,
      invoiceDate: null,
      trxDate: null,
      remark: null,
    })
  }


  getSupplierInvoices(accountId) {
    return client.service('suppliers').find({
      query: { ledgerAccountId: accountId }
    })
      .then((res) => {
        if (res.total > 0) {
          return client.service('receipts').find({
            query: { supplierId: res.data[0]._id }
          })
        }
        return { "data": [] }
      })
      .then((res) => {
        this.setState({ invoices: res.data })
      })
  }

  updateAmount(e) {
    let result = _.find(this.state.invoices, { receiptNo: e })
    if (result) {
      // this.getExpenses(result.debitledgerAccountId)
      this.setState({ receiptId: result._id, receiptPaidAmount: result.totalPay })
      this.props.form.setFieldsValue({ creditAmount: result.totalPay, invoiceDate: moment(result.date) })
    } else {
      // this.getExpenses()
      this.setState({ receiptId: null, receiptPaidAmount: null })
      this.props.form.setFieldsValue({ creditAmount: null, invoiceDate: null })
    }
  }

  //----------------------------------------------------------------------------
  render() {
    const { visible, onCancel } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { referenceformat } = this.state;

    return (
      <Spin spinning={this.state.formloading}>
        <Modal
          centered
          visible={this.props.creditnotes.mode === 'new' || this.props.creditnotes.mode === 'edit' ? true : false}
          title={this.props.creditnotes.mode === 'new' ? 'Add New Credit Notes' : 'Edit New Credit Notes'}
          okText={this.props.creditnotes.mode === 'new' ? 'Add' : 'Edit'}
          onCancel={() => { this.props.updateMode(''); this.props.form.resetFields() }}
          onOk={() => { this.handleSubmit() }}

        >
          <Form className="submit-form">

            <FormItem {...formItemLayout} label="Transaction Date">
              {getFieldDecorator('trxDate', {
                rules: [{
                  required: true, message: 'Please input Transaction Date!',
                }],
                initialValue: moment(),
              })(
                <DatePicker />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="Supplier">
              {getFieldDecorator('dtledgerAccountId', {
                rules: [{
                  required: true, message: 'Please select Account Date!',
                }],
              })(
                <Select onChange={(e) => {
                  this.getSupplierInvoices(e)
                  this.resetForm()
                }}>
                  {this.state.dtAccountList.map(dropdown => (
                    <Option key={dropdown._id} value={dropdown._id} description={dropdown.description} >
                      {dropdown.description}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="Credit by">
              {getFieldDecorator('ctledgerAccountId', {
                rules: [{
                  required: true, message: 'Please select Credit by!',
                }],
              })(
                <Select>
                  {this.state.ctAccountList.map(dropdown => (
                    <Option key={dropdown.ledgerAccountId._id}  >
                      {dropdown.description} ({dropdown.ledgerAccountId.fullAccountNo})
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="Invoice No">
              {getFieldDecorator('invoiceNo', {
                rules: [{
                  required: false, message: 'Please select Invoice No!',
                }],
              })(
                <AutoComplete onBlur={(e) => this.updateAmount(e)} onSelect={(e) => this.updateAmount(e)} onChange={(e) => this.updateAmount(e)} style={{ width: 200 }} onSearch={this.handleSearch} placeholder="">
                  {this.state.invoices.map(d => (
                    <AutoComplete.Option key={d._id} value={d.receiptNo} description={d.receiptNo} >
                      {d.receiptNo}
                    </AutoComplete.Option>
                  ))}
                </AutoComplete>
              )}
            </FormItem>




            <FormItem {...formItemLayout} label="Invoice Date">
              {getFieldDecorator('invoiceDate', {
                rules: [{
                  required: false, message: 'Please input Invoice Date!',
                }],
                initialValue: moment(),
              })(
                <DatePicker />
              )}
            </FormItem>


            <FormItem {...formItemLayout} label="Credit Amount">
              {getFieldDecorator('creditAmount', {
                rules: [{
                  required: true, message: 'Please select Credit Amount!',
                }],
              })(
                <InputNumber style={{ width: '100%' }} />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="Remark">
              {getFieldDecorator('remark', {
                rules: [{
                  required: false, message: 'Please select Remark!',
                }],
              })(
                <TextArea rows={4} />
              )}
            </FormItem>

          </Form>
        </Modal>
      </Spin>
    )
  }
}

//----------------------------------------------------------------------------
function mapStateToProps(state) {
  return {
    creditnotes: state.creditnotes,
    commons: state.commons
  }
}
//----------------------------------------------------------------------------
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
  }, dispatch);
}

//----------------------------------------------------------------------------

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(CreditNotesForm)));