import React, { Component } from 'react';
import { Modal, message, Form, Input, Button, Select, Radio, Row, Col, Spin } from 'antd';
import client from '../../feathers';
import requireAuth from "../../components/requireAuth";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateMode } from '../../actions/actions-jmbs';
import axios from 'axios';

const FormItem = Form.Item;



const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};


class JmbsView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',
      confirmDirty: false,
      autoCompleteResult: [],
      loading: false
    }
  }

  componentWillMount() {

  }

  componentDidUpdate() {

    if (this.props.jmbs.activeId !== this.state._id) {
      this.setState({
        _id: this.props.jmbs.activeId
      })

      if (this.props.jmbs.activeJmbs) {

        this.props.form.setFieldsValue(
          this.props.jmbs.activeJmbs
        )

        // this.props.form.setFieldsValue({
        //   bankAccountHolder: this.props.jmbs.activeJmbs.bankAccountHolder,
        //   bankAccountNo: this.props.jmbs.activeJmbs.bankAccountNo,
        // });
      }
    }

  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }


  handleSubmit = (e) => {

    // this.props.updateMode('');
    e.preventDefault();

    if (this.props.jmbs.mode === 'new') {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          client.service('jmbs').create({
            ...values,
            propertyId: this.props.propertyId,
          })
            .then((res) => {

              this.props.updateMode('');
              message.success('Record Created');
            })
            .catch((err) => {
              console.log(err);
              //this.props.updateMode('');
              alert(err);
            })
        }
      });
    } else {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          client.service('jmbs').patch(this.props.jmbs.activeId,
            values
          )
            .then((res) => {

              message.success(`Jmb Updated`);
              this.props.updateMode('');
            })
            .catch((err) => {
              console.log(err);

              this.props.updateMode('');
            })
          return;
        }

      });
    }
  }

  onChange = (time) => {

    //this.setState({ value: time });
  }

  handleFileUpload = (event) => {

    this.setState({ loading: true });
    let formData = new FormData();

    formData.append('image', event.target.files[0]);

    axios.post(`${client.io.io.uri}upload-jmb-header`,
      formData
      , {
        headers: {
          'Authorization': client.settings.storage.storage['feathers-jwt'],
          'Content-Type': 'multipart/form-data',
        }
      }
    ).then((res) => {

      this.props.form.setFieldsValue({
        headerFile: res.data.imageUrl
      });
      this.setState({ loading: false });
      message.success('Upload Successful');
    })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
        message.error(err);
      })
  }

  render() {

    const { getFieldDecorator } = this.props.form;


    return (
      <Modal
        visible={
          this.props.jmbs.mode === 'new' || this.props.jmbs.mode === 'edit' ? true : false
        }
        width={1000}
        title={'Details'}
        okText={'Update'}
        onCancel={this.handleSubmit}
        footer={[
          <Button key="submit" type="primary" onClick={this.handleSubmit}>
            Update
          </Button>,
        ]}
        width="900px"
      >
        <Spin spinning={this.state.loading}>
          <Form className="submit-form">
            <Row>
              <Col span={12}>
                <FormItem
                  {...formItemLayout}
                  label="Name"
                >
                  {getFieldDecorator('name', {
                    rules: [{
                      required: true, message: 'Please input JMB Name!',
                    }],
                  })(
                    <Input />
                  )}
                </FormItem>

                <FormItem
                  {...formItemLayout}
                  label="Management Name"
                >
                  {getFieldDecorator('managementName', {
                    rules: [{
                      required: true, message: 'Please input JMB Name!',
                    }],
                  })(
                    <Input />
                  )}
                </FormItem>

                <FormItem
                  {...formItemLayout}
                  label="Status"
                >
                  {getFieldDecorator('status', {
                    rules: [{
                      required: true, message: 'Please Select Status!',
                    }],
                  })(
                    <Radio.Group  >
                      <Radio.Button value="ACTIVE">ACTIVE</Radio.Button>
                      <Radio.Button value="INACTIVE">INACTIVE</Radio.Button>
                    </Radio.Group>
                  )}
                </FormItem>

                <FormItem
                  {...formItemLayout}
                  label="Registration No."
                >
                  {getFieldDecorator('registrationNo', {
                    rules: [{
                      required: true, message: 'Please input Registration Number!',
                    }],
                  })(
                    <Input />
                  )}
                </FormItem>

                <FormItem
                  {...formItemLayout}
                  label="Contact"
                >
                  {getFieldDecorator('contactNo', {
                    rules: [{
                      required: true, message: 'Please input Contact!',
                    }],
                  })(
                    <Input />
                  )}
                </FormItem>

                <FormItem
                  {...formItemLayout}
                  label="Fax Number"
                >
                  {getFieldDecorator('faxNo', {
                    rules: [{
                      required: false, message: 'Please input Fax!',
                    }],
                  })(
                    <Input />
                  )}
                </FormItem>

                <FormItem
                  {...formItemLayout}
                  label="email"
                >
                  {getFieldDecorator('email', {
                    rules: [{
                      type: 'email', message: 'The input is not valid E-mail!',
                    }, {
                      required: true, message: 'Please input E-mail!',
                    }],
                  })(
                    <Input />
                  )}
                </FormItem>

              </Col>
              <Col span={12}>

                <FormItem
                  {...formItemLayout}
                  label="Header Line 1"
                >
                  {getFieldDecorator('headerLine1', {
                    rules: [
                      { required: true, message: 'Please input Header Line 1!' },
                    ],
                  })(
                    <Input />
                  )}
                </FormItem>

                <FormItem
                  {...formItemLayout}
                  label="Header Line 2"
                >
                  {getFieldDecorator('headerLine2', {
                    rules: [
                      { required: true, message: 'Please input Header Line 2!' },
                    ],
                  })(
                    <Input />
                  )}
                </FormItem>

                <FormItem
                  {...formItemLayout}
                  label="Header Line 3"
                >
                  {getFieldDecorator('headerLine3', {
                    rules: [
                      { required: false, message: 'Please input Header Line 3!' },
                    ],
                  })(
                    <Input />
                  )}
                </FormItem>

                <FormItem
                  {...formItemLayout}
                  label="Header Line 4"
                >
                  {getFieldDecorator('headerLine4', {
                    rules: [
                      { required: false, message: 'Please input Header Line 4!' },
                    ],
                  })(
                    <Input />
                  )}
                </FormItem>

                <FormItem
                  {...formItemLayout}
                  label="Bank Name"
                >
                  {getFieldDecorator('bankName', {
                    rules: [
                      { required: false, message: 'Please Input Bank Name!' },
                    ],
                  })(
                    <Input />
                  )}
                </FormItem>

                <FormItem
                  {...formItemLayout}
                  label="Bank Acct Holder"
                >
                  {getFieldDecorator('bankAccountHolder', {
                    rules: [
                      { required: false, message: 'Please Input Bank Acct Holder!' },
                    ],
                  })(
                    <Input />
                  )}
                </FormItem>

                <FormItem
                  {...formItemLayout}
                  label="Bank Acct No."
                >
                  {getFieldDecorator('bankAccountNo', {
                    rules: [
                      { required: false, message: 'Please input Bank Acct No.!' },
                    ],
                  })(
                    <Input />
                  )}
                </FormItem>

              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>

    )
  }
}


function mapStateToProps(state) {
  return {
    jmbs: state.jmbs
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(JmbsView)));
