export const FETCH_JMBS = 'FETCH_JMBS';
export const PUSH_JMBS = 'PUSH_JMBS';
export const REMOVE_JMBS = 'REMOVE_JMBS';
export const UPDATE_JMBS = 'UPDATE_JMBS';
export const UPDATE_MODE = 'UPDATE_MODE';
export const UPDATE_ACTIVE_ID = 'UPDATE_ACTIVE_ID';

export function fetchJmbs(data){
  return (dispatch) => {
    dispatch({
      type: FETCH_JMBS,
      payload:data
    });
  }
}

export function pushJmbs(data){
  return (dispatch) => {
    dispatch({
      type: PUSH_JMBS,
      payload:data
    });
  }
}

export function removeJmbs(data){
  return (dispatch) => {
    dispatch({
      type: REMOVE_JMBS,
      payload:data
    });
  }
}

export function updateJmbs(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_JMBS,
      payload:data
    });
  }
}

export function updateMode(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE,
      payload:data
    });
  }
}

export function updateActiveJmbs(mode, id){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_ID,
      mode,
      id
    });
  }
}
