import React, { Component } from 'react';
import { Modal, message, Form, Input, Select, InputNumber, Radio, Row, Col, Spin } from 'antd';
import client from '../../feathers';
import requireAuth from "../../components/requireAuth";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateMode } from '../../actions/actions-globalparameters'

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

class VendorTypeForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',
      confirmDirty: false,
      autoCompleteResult: [],
      ledgerAccountDropdowns: [],
      description: '',
      amtDescription: 'Amount',
      loading: false,
      monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December', 'All']
    }
  }
  componentDidUpdate() {
    if (this.props.globalparameters.activeId !== this.state._id) {
      this.setState({
        _id: this.props.globalparameters.activeId
      })

      // if(this.props.charges.activeCharges){
      //   this.props.form.setFieldsValue(
      //     this.props.charges.activeCharges
      //   )
      // }
    }
  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }


  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    if (this.props.globalparameters.mode === 'new') {

      this.props.form.validateFields((err, values) => {
        if (!err) {
          client.service('globalparameters').create({
            ...values,
            groupCode: 'NONE',
            propertyId: this.props.commons.selectedCompany._id,
          })
            .then((res) => {

              this.props.updateMode('');
              message.success('New globalparameters Added');
              this.setState({ loading: false });
            })
            .catch((err) => {
              console.log(err);
              this.props.updateMode('');
              this.setState({ loading: false });
              alert(err);
            })
        }
      });
    } else {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          client.service('globalparameters').patch(this.props.globalparameters.activeId,
            values
          )
            .then((res) => {

              message.success(`Globalparameters Updated`);
              this.props.updateMode('');
              this.setState({ loading: false });
            })
            .catch((err) => {
              console.log(err);

              this.props.updateMode('');
              this.setState({ loading: false });
            })
          return;
        }

      });
    }
  }

  componentWillMount() {
    //generate mng dropdown
    client.authenticate()
      .then(() => {
        return client.service('ledger-accounts').find({
          query: {
            companyId: this.props.commons.selectedCompany._id,
            $sort: {
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {

        this.setState({
          ledgerAccountDropdowns: res.data
        })
      })
      .catch((err) => {
        console.log(err);
      });

  }

  handleChange = (e) => {

    if (e.target.value === 'incomes') {
      this.setState({ description: 'This Fee will be park under Accounting INCOMES' });
    } else if (e.target.value === 'current liabilities') {
      this.setState({ description: 'This Fee will be park under Accounting LIABILITIES' });
    } else {
      this.setState({ description: '' });
    }
  }


  handleBlur = (e) => {
    let code = e.target.value;
    //code.toUpperCase();
    this.props.form.setFieldsValue({
      code: code.toUpperCase(),
    })
  }

  renderTypeSelection() {


    const { getFieldDecorator } = this.props.form;

    if (this.props.form.getFieldValue('chargeType') === 'AMOUNT') {

      return (
        <FormItem
          {...formItemLayout}
          label={this.state.amtDescription}
        >
          {getFieldDecorator('amount', {
            rules: [{
              required: true, message: 'Please input Amount!',
            }],
          })(
            <Input />
          )}
        </FormItem>
      )

    } else if (this.props.form.getFieldValue('chargeType') === 'PERCENTAGE') {

      return (
        <FormItem
          {...formItemLayout}
          label='Percentage'
        >
          {getFieldDecorator('percentage', {
            rules: [{
              required: true, message: 'Please input Percentage!',
            }],
          })(
            <InputNumber placeholder={'1 = 100%, 0.1 = 10%'} />
          )}
        </FormItem>
      )

    } else {
      return (<span></span>)
    }

  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Spin spinning={this.state.loading} >
        <Modal
          visible={
            this.props.globalparameters.mode === 'new' || this.props.globalparameters.mode === 'edit' ? true : false
          }
          title={this.props.globalparameters.mode === 'edit' ? 'Update Fee' : 'Create Fee'}
          okText={this.props.globalparameters.mode === 'edit' ? 'Update' : 'Create'}
          onCancel={() => { this.props.updateMode(''); this.props.form.resetFields() }}
          onOk={this.handleSubmit}

        >
          <Form className="submit-form">

            <Row>
              <Col offset={8}>
                <small style={{ color: 'red' }} >{this.state.description}</small>
              </Col>
            </Row>


            <FormItem
              {...formItemLayout}
              label="Parameter Name"
            >
              {getFieldDecorator('parametername', {
                initialValue: 'VENDORTYPE',
                rules: [{
                  required: true, message: 'Please input Parameter Name!',
                }],
              })(
                <Input onBlur={this.handleBlur} disabled={'true'} />
              )}
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Variable"
            >
              {getFieldDecorator('variable', {
                rules: [{
                  required: true, message: 'Please input Variable!',
                }],
              })(
                <Input onBlur={this.handleBlur} disabled={this.props.globalparameters.mode === 'edit' ? 'true' : ''} placeholder="Please input Value" />
              )}
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Value"
            >
              {getFieldDecorator('variableValue1', {
                rules: [{
                  required: true, message: 'Please input Value!',
                }],
              })(
                <Input onBlur={this.handleBlur} disabled={this.props.globalparameters.mode === 'edit' ? 'true' : ''} placeholder="Please input Value" />
              )}
            </FormItem>



          </Form>
        </Modal>
      </Spin>

    )
  }
}


function mapStateToProps(state) {
  return {
    globalparameters: state.globalparameters,
    commons: state.commons
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(VendorTypeForm)));
