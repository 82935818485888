import React, { Component, useEffect } from 'react'
import { Modal, Upload, Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Select, InputNumber } from 'antd'
import client from '../../feathers'
import requireAuth from "../../components/requireAuth"
import Layout from "../../components/layout"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateMode } from '../../actions/actions-trxparameters'
import axios from 'axios';
import params from '../../params/system'
var FormData = require('form-data');

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};
const text = 'Are you sure to delete this item?';
//----------------------------------------------------------------------------
class TrxParametersForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',
      confirmDirty: false,
      autoCompleteResult: [],
      referenceformat: '',
    }

  }
  //----------------------------------------------------------------------------
  componentDidUpdate() {
    if (this.props.trxparameters.activeId !== this.state._id) {
      this.setState({
        _id: this.props.trxparameters.activeId
      })


      if (this.props.trxparameters.activeTrxParameters) {
        this.props.form.setFieldsValue(
          this.props.trxparameters.activeTrxParameters
        )
      }
    }

  }
  //----------------------------------------------------------------------------
  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }
  //----------------------------------------------------------------------------
  handleSubmit = (e) => {
    e.preventDefault();

    if (this.props.trxparameters.mode === 'new') {

      this.props.form.validateFields((err, values) => {
        if (!err) {

          client.service('trxparameters').create({
            ...values,
            companyId: this.props.commons.selectedCompany._id,
          })
            .then((res) => {
              message.success('New Record added');
              this.props.updateMode('refresh')
            })
            .catch((err) => {
              this.props.updateMode('refresh')
              alert(err);
            })
        }
      });
    } else {
      this.props.form.validateFields((err, values) => {
        if (!err) {

          client.service('trxparameters').patch(this.props.trxparameters.activeId,
            values,

          )
            .then((res) => {
              this.props.updateMode('refresh')
              message.success(`Updated`);
            })
            .catch((err) => {
              this.props.updateMode('refresh')
            })
          return;
        }

      });
    }
  }
  //----------------------------------------------------------------------------

  currentFormat = (e) => {


    let referenceformat = '';
    let number = '1';
    this.props.form.validateFields((err, values) => {
      if (!err) {

        referenceformat =
          (values.referenceformat ? values.referenceformat : '') +
          (values.referenceseperator ? values.referenceseperator : '') +
          (values.referencedateformat ? values.referencedateformat : '') +
          (values.referenceseperator2 ? values.referenceseperator2 : '') +
          (values.referenceSeqlength ? number.padStart(values.referenceSeqlength, 0) : 1);

      }
    });

    this.setState({
      referenceformat: referenceformat
    })

  }
  //----------------------------------------------------------------------------

  trancode() {

    const { getFieldDecorator } = this.props.form;

    if (this.props.trxparameters.mode === 'new') {
      return (
        <FormItem
          {...formItemLayout}
          label="Tran code">
          {getFieldDecorator('trancode', {
            rules: [{
              required: true, message: 'Please input Tran Code!',
            }],
          })(
            <Input placeholder="1000" />
          )}
        </FormItem>
      )
    }
    //else
    return (
      <FormItem
        {...formItemLayout}
        label="Tran code" >
        {getFieldDecorator('trancode', {
          rules: [{
            required: true, message: 'Please input Tran Code!',
          }],
        })(
          <Input disabled placeholder="1000" />
        )}
      </FormItem>
    )
  }
  //----------------------------------------------------------------------------
  componentWillMount() {
  }

  //----------------------------------------------------------------------------
  render() {
    const { visible, onCancel } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { referenceformat } = this.state;

    return (
      <Modal
        visible={
          this.props.trxparameters.mode === 'new' || this.props.trxparameters.mode === 'edit' ? true : false
        }
        title={this.props.trxparameters.mode === 'edit' ? 'Update Parameter' : 'Create Parameter'}
        okText={this.props.trxparameters.mode === 'edit' ? 'Update' : 'Create'}
        onCancel={() => { this.props.updateMode(''); this.props.form.resetFields() }}
        onOk={this.handleSubmit}
      >
        <Form className="submit-form">

          {this.trancode()}

          <FormItem
            {...formItemLayout}
            label="Decription"
          >
            {getFieldDecorator('tranDesc', {
              rules: [{
                required: true, message: 'Please input description!',
              }],
            })(
              <TextArea rows={4} />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Format"
          >
            {getFieldDecorator('referenceformat', {
              rules: [{
                required: true, message: 'Please input Format !',
              }],
            })(
              <Input
                onChange={this.currentFormat}
                placeholder="PV,IV,IN" />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Seperator"
          >
            {getFieldDecorator('referenceseperator', {
              rules: [{
                required: false, message: 'Please input Seperator!',
              }],
            })(
              <Input
                onChange={this.currentFormat}
                placeholder="-,*,/,@," />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Date format"
          >
            {getFieldDecorator('referencedateformat', {
              rules: [{
                required: false, message: 'Please input Date Format!',
              }],
            })(


              <Select
                id='referencedateformat'
                onChange={this.currentFormat}
                placeholder="Select Type">
                <Option value="YY">YY</Option>
                <Option value="YYYY">YYYY</Option>
                <Option value="YYYYMM">YYYYMM</Option>
                <Option value="YYYYMMDD">YYYYMMDD</Option>
              </Select>
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Seperator2"
          >
            {getFieldDecorator('referenceseperator2', {
              rules: [{
                required: false, message: 'Please input Seperator!',
              }],
            })(
              <Input
                onChange={this.currentFormat}
                placeholder="-,*,/,@," />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Seq No. Length"
          >
            {getFieldDecorator('referenceSeqlength', {
              rules: [{
                required: false, message: 'Please input Seperator!',
              }],
            })(
              <InputNumber
                min={1}
                onClick={this.currentFormat}
                onChange={this.currentFormat}
                placeholder="00000" />
            )}
          </FormItem>



        </Form>
        <span style={{ 'align': 'right' }}>{this.state.referenceformat}</span>
      </Modal>

    )
  }
}

//----------------------------------------------------------------------------
function mapStateToProps(state) {
  return {
    trxparameters: state.trxparameters,
    commons: state.commons
  }
}
//----------------------------------------------------------------------------
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
  }, dispatch);
}

//----------------------------------------------------------------------------

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(TrxParametersForm)));
