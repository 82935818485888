import _ from 'lodash';
import {
  FETCH_MONTHLYSTATEMENTS,
  PUSH_MONTHLYSTATEMENTS,
  REMOVE_MONTHLYSTATEMENTS,
  UPDATE_MONTHLYSTATEMENTS,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID,
  UPDATE_MONTHBANK,
 } from '../actions/actions-monthlystatements';

const INITIAL_STATE = {
  monthlystatements: [],
  mode: 'index',
  activeId: '',
  activeMonthlystatements:{},
  selectedMonth:'',
  selectedBank:''

}


export default function(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_MONTHLYSTATEMENTS:
      return {
        ...state,
        monthlystatements:action.payload,
      }
    case PUSH_MONTHLYSTATEMENTS:
      return {
        ...state,
        monthlystatements:[action.payload, ...state.monthlystatements],
      }
    case REMOVE_MONTHLYSTATEMENTS:
      return {
        ...state,
        monthlystatements : [...state.monthlystatements.filter( (item) => item._id !== action.payload._id)]
      }
    case UPDATE_MONTHLYSTATEMENTS:
      let index =  _.findIndex(state.monthlystatements, { '_id': action.payload._id })
      state.monthlystatements.splice(index, 1, action.payload)
      return {
        ...state,
        monthlystatements : state.monthlystatements
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode : action.payload,
        activeId:''
      }
    case UPDATE_ACTIVE_ID:
      return {
        ...state,
        mode : action.mode,
        activeId:action.id,
        activeMonthlystatements: _.pick(_.find(state.monthlystatements, { '_id': action.id }), ['companyId', 'ledgerAccountId', 'ledgerAccountCat', 'ledgerAccountType','ledgerAccountMainAcc','ledgerAccountSubAcc','ledgerAccountDescription','balance','currency','yearmonth', '_id'])
      }
      case UPDATE_MONTHBANK:
        return {
          selectedMonth:action.month,
          selectedBank:action.bank
        }
    default:
      return state

  }
}
