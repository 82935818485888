import _ from 'lodash';
import {
  FETCH_WATERBILLS,
  PUSH_WATERBILLS,
  REMOVE_WATERBILLS,
  UPDATE_WATERBILLS,
  UPDATE_WATERBILLS_MODE,
  UPDATE_WATERBILLS_ACTIVE_ID } from '../actions/actions-waterbills';

const INITIAL_STATE = {
  waterbills: [],
  mode: 'index',
  activeId: '',
  activeWaterBills:{}
}


export default function(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_WATERBILLS:
      return {
        ...state,
        waterbills:action.payload,
      }
    case PUSH_WATERBILLS:
      return {
        ...state,
        waterbills:[action.payload, ...state.waterbills],
      }
    case REMOVE_WATERBILLS:
      return {
        ...state,
        waterbills : [...state.waterbills.filter( (item) => item._id !== action.payload._id)]
      }
    case UPDATE_WATERBILLS:
      let index =  _.findIndex(state.waterbills, { '_id': action.payload._id })
      state.waterbills.splice(index, 1, action.payload)
      return {
        ...state,
        waterbills : state.waterbills
      }
    case UPDATE_WATERBILLS_MODE:
      return {
        ...state,
        mode : action.payload,
        activeId:''
      }
    case UPDATE_WATERBILLS_ACTIVE_ID:
      return {
        ...state,
        mode : action.mode,
        activeId:action.id,
        activeWaterBills: _.pick(_.find(state.waterbills, { '_id': action.id }), ['companyId'	,'propertyunitId'	,'chargeId',	'readDate'	,'meterName'	,'previousNum'	,'currentNum'	,'usageNum','status','notes'	,'createdBy',	'updatedBy'	,'invoiceId'	,'invoiceItemId', '_id'])
      }
    default:
      return state

  }
}
