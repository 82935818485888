import React, { Component } from 'react';
import { Modal, message, Form, Icon, Input, Select } from 'antd';
import client from '../../feathers';
import requireAuth from "../../components/requireAuth";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateMode } from '../../actions/actions-facilities';
import Bbq1 from '../../assets/facilitiesIcon/Bbq1';
import Billiard from '../../assets/facilitiesIcon/Billiard';
import ChampagneGlass from '../../assets/facilitiesIcon/ChampagneGlass';
import Console from '../../assets/facilitiesIcon/Console';
import FoodAndRestaurant from '../../assets/facilitiesIcon/FoodAndRestaurant';
import LoungeChair from '../../assets/facilitiesIcon/LoungeChair';
import PingPong from '../../assets/facilitiesIcon/PingPong';
import Speech from '../../assets/facilitiesIcon/Speech';
import Basketball from '../../assets/facilitiesIcon/Basketball';
import Bbq from '../../assets/facilitiesIcon/Bbq';
import Cameras from '../../assets/facilitiesIcon/Cameras';
import Dumbbell from '../../assets/facilitiesIcon/Dumbbell';
import Football from '../../assets/facilitiesIcon/Football';
import Lounge from '../../assets/facilitiesIcon/Lounge';
import Park from '../../assets/facilitiesIcon/Park';
import Pool from '../../assets/facilitiesIcon/Pool';
import Stones from '../../assets/facilitiesIcon/Stones';

import Bathrobe from '../../assets/interimIcons/Bathrobe';
import Cocktail1 from '../../assets/interimIcons/Cocktail1';
import FlowerBouquet from '../../assets/interimIcons/FlowerBouquet';
import Lightbulb from '../../assets/interimIcons/Lightbulb';
import Planet from '../../assets/interimIcons/Planet';
import Water1 from '../../assets/interimIcons/Water1';
import _ from 'lodash';
import { MDBIcon } from "mdbreact";
import axios from 'axios';
import {
  updateActiveFacilities,
} from '../../actions/actions-facilities';

var FormData = require('form-data');



const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

class FacilitiesForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',
      confirmDirty: false,
      autoCompleteResult: [],
      previewSrc: [],
      imageError: null
    }
  }
  componentDidUpdate() {
    if (this.props.facilities.activeId !== this.state._id) {
      this.setState({
        _id: this.props.facilities.activeId
      })

      if (this.props.facilities.activeFacilities) {
        this.props.form.setFieldsValue(
          this.props.facilities.activeFacilities
        )
      }
    }
  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }

  sendFacilitiesImages = (facilityId) => {
    const fileList = this.fileInput.input.files;
    let formData = new FormData();
    formData.set("facilityId", facilityId);

    for (let i = 0; i < fileList.length; i++) {
      formData.append('images', fileList[i]);
    }

    return axios.post(`${client.io.io.uri}upload-facilities-images`,
      formData
      , {
        headers: {
          'Authorization': client.settings.storage.storage['feathers-jwt'],
          'Content-Type': 'multipart/form-data',
        }
      }
    );
  }

  clearImageData = () => {
    this.setState({
      previewSrc: [],
      imageError: null
    })
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    if (this.state.imageError) {
      return;
    }

    if (this.props.facilities.mode === 'new') {

      this.props.form.validateFields((err, values) => {
        if (!err) {

          client.service('facilities').create({
            ...values,
            propertyId: this.props.user.info.propertyId._id,
          }).then(res => {

            if (this.state.previewSrc.length > 0) {
              return this.sendFacilitiesImages(res._id);
            }
          })
            .then((res) => {
              this.props.updateMode('refresh')
              message.success('New Facility Added');
              this.props.updateActiveFacilities('refresh', '')
              this.props.form.resetFields();
              this.clearImageData();
            })
            .catch((err) => {
              console.log(err);
              this.props.updateMode('refresh')
              this.clearImageData();
              alert(err);
            })
        }
      });
    } else {
      this.props.form.validateFields((err, values) => {
        if (!err) {

          Promise.all([
            client.service('facilities').patch(this.props.facilities.activeId,
              values
            ),
            ...((this.state.previewSrc.length > 0) ? [this.sendFacilitiesImages(this.props.facilities.activeId)] : [])
          ])
            .then((res) => {
              message.success(`Facilities Updated`);
              this.props.updateActiveFacilities('refresh', '', '')
              // this.props.updateMode('')
              this.props.form.resetFields();
              this.clearImageData();
            })
            .catch((err) => {
              console.log(err);
              this.props.updateMode('')
              this.clearImageData();
            })

        }
      });
    }
  }

  onChange = (time) => {

    //this.setState({ value: time });
  }
  makePreviewSrc = (fileList) => {
    const src = []
    for (let i = 0; i < fileList.length; i++) {
      src[i] = URL.createObjectURL(fileList[i])
    }
    return src;
  }
  handleImagePreview = (e) => {



    const fileList = e.target.files;

    this.setState({
      imageError: fileList.length > 5,
      previewSrc: (fileList.length > 5) ? [] : this.makePreviewSrc(fileList)
    });

    // if(fileList.length > 5){
    //   this.setState({
    //     imageError: true,
    //     previewFileList:
    //   });
    // }
    // else {
    //   this.setState({
    //
    //   })

    //}
    // this.setState({
    //   previewFileList:
    // });

    // const files = Array.from(e.target.files);
    // if(_.isEmpty(e.target.files)){

    //   this.setState({
    //     previewSrc: files.map(file => URL.createObjectURL(file))
    //   })
    //
    // }

  }
  makePreview = () => {
    const filelist = this.state.previewFileList;

    const src = []
    for (let i = 0; i < filelist.length; i++) {
      src[i] = URL.createObjectURL(filelist[i])
    }
    return src.map((src, index) => (
      <img src={src} style={{ 'width': '200px', height: '100px' }} alt={`preview${index}`} />
    ))
    console.log(src);
  }

  render() {


    const { getFieldDecorator } = this.props.form;


    return (
      <Modal
        visible={
          this.props.facilities.mode === 'new' || this.props.facilities.mode === 'edit' ? true : false
        }
        title={this.props.facilities.mode === 'edit' ? 'Update Facilities' : 'Create Facilities'}
        okText={this.props.facilities.mode === 'edit' ? 'Update' : 'Create'}
        onCancel={() => {

          this.clearImageData();
          this.props.form.resetFields();
          this.props.updateActiveFacilities('', '')
        }}
        onOk={this.handleSubmit}

      >
        <Form className="submit-form">



          <FormItem
            {...formItemLayout}
            label="Icon"
          >
            {getFieldDecorator('icon', {
              rules: [{
                required: true, message: 'Please choose an icon!',
              }],
            })(
              <Select placeholder="Please Select an Icon">

                <Option value="Bathrobe">
                  <Bathrobe width={100} height={30} />
                </Option>
                <Option value="Cocktail1">
                  <Cocktail1 width={100} height={30} />
                </Option>
                <Option value="FlowerBouquet">
                  <FlowerBouquet width={100} height={30} />
                </Option>
                <Option value="Lightbulb">
                  <Lightbulb width={100} height={30} />
                </Option>
                <Option value="Planet">
                  <Planet width={100} height={30} />
                </Option>
                <Option value="Water1">
                  <Water1 width={100} height={30} />
                </Option>

                <Option value="Bbq1">
                  <Bbq1 width={100} height={30} />
                </Option>

                <Option value="Billiard">
                  <Billiard width={100} height={30} />
                </Option>

                <Option value="ChampagneGlass">
                  <ChampagneGlass width={100} height={30} />
                </Option>

                <Option value="Console">
                  <Console width={100} height={30} />
                </Option>

                <Option value="FoodAndRestaurant">
                  <FoodAndRestaurant width={100} height={30} />
                </Option>

                <Option value="LoungeChair">
                  <LoungeChair width={100} height={30} />
                </Option>

                <Option value="PingPong">
                  <PingPong width={100} height={30} />
                </Option>

                <Option value="Speech">
                  <Speech width={100} height={30} />
                </Option>

                <Option value="Basketball">
                  <Basketball width={100} height={30} />
                </Option>

                <Option value="Bbq">
                  <Bbq width={100} height={30} />
                </Option>

                <Option value="Cameras">
                  <Cameras width={100} height={30} />
                </Option>


                <Option value="Dumbbell">
                  <Dumbbell width={100} height={30} />
                </Option>

                <Option value="Football">
                  <Football width={100} height={30} />
                </Option>

                <Option value="Lounge">
                  <Lounge width={100} height={30} />
                </Option>

                <Option value="Park">
                  <Park width={100} height={30} />
                </Option>

                <Option value="Stones">
                  <Stones width={100} height={30} />
                </Option>

              </Select>
            )}
          </FormItem>



          <FormItem
            {...formItemLayout}
            label="Name"
          >
            {getFieldDecorator('name', {
              rules: [{
                required: true, message: 'Please input Facilities Name!',
              }],
            })(
              <Input prefix={<Icon type="idcard" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="SUPERHERO" />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Description"
          >
            {getFieldDecorator('description', {
              rules: [{
                required: false, message: 'Please input DEscription!',
              }],
            })(
              <TextArea rows={4} />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Unit"
          >
            {getFieldDecorator('unit', {
              rules: [{
                required: true, message: 'Please input Unit!',
              }],
            })(
              <Input prefix={<Icon type="tag" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="1" />
            )}
          </FormItem>

          {/*<FormItem
             {...formItemLayout}
             label="Charges Amount"
           >
             {getFieldDecorator('amount', {
               rules: [{
                 required: false, message: 'Please input amount!',
               }],
             })(
               <Input placeholder="10.00" />
             )}
             </FormItem>*/}

          <FormItem
            {...formItemLayout}
            label="Start Time"
          >
            {getFieldDecorator('startTime', {
              rules: [{
                required: true, message: 'Please input Start Time!',
              }],
            })(
              <Select placeholder="Please Select Time">
                <Option value="00:00">00:00</Option>
                <Option value="00:30">00:30</Option>
                <Option value="01:00">01:00</Option>
                <Option value="01:30">01:30</Option>
                <Option value="02:00">02:00</Option>
                <Option value="02:30">02:30</Option>
                <Option value="03:00">03:00</Option>
                <Option value="03:30">03:30</Option>
                <Option value="04:00">04:00</Option>
                <Option value="04:30">04:30</Option>
                <Option value="05:00">05:00</Option>
                <Option value="05:30">05:30</Option>
                <Option value="06:00">06:00</Option>
                <Option value="06:30">06:30</Option>
                <Option value="07:00">07:00</Option>
                <Option value="07:30">07:30</Option>
                <Option value="08:00">08:00</Option>
                <Option value="08:30">08:30</Option>
                <Option value="09:00">09:00</Option>
                <Option value="09:30">09:30</Option>
                <Option value="10:00">10:00</Option>
                <Option value="10:30">10:30</Option>
                <Option value="11:00">11:00</Option>
                <Option value="11:30">11:30</Option>
                <Option value="12:00">12:00</Option>
                <Option value="12:30">12:30</Option>
                <Option value="13:00">13:00</Option>
                <Option value="13:30">13:30</Option>
                <Option value="14:00">14:00</Option>
                <Option value="14:30">14:30</Option>
                <Option value="15:00">15:00</Option>
                <Option value="15:30">15:30</Option>
                <Option value="16:00">16:00</Option>
                <Option value="16:30">16:30</Option>
                <Option value="17:00">17:00</Option>
                <Option value="17:30">17:30</Option>
                <Option value="18:00">18:00</Option>
                <Option value="18:30">18:30</Option>
                <Option value="19:00">19:00</Option>
                <Option value="19:30">19:30</Option>
                <Option value="20:00">20:00</Option>
                <Option value="20:30">20:30</Option>
                <Option value="21:00">21:00</Option>
                <Option value="21:30">21:30</Option>
                <Option value="22:00">22:00</Option>
                <Option value="22:30">22:30</Option>
                <Option value="23:00">23:00</Option>
                <Option value="23:30">23:30</Option>
              </Select>
            )}
          </FormItem>


          <FormItem
            {...formItemLayout}
            label="Max Session Per Day"
          >
            {getFieldDecorator('maxSessionPerday', {
              rules: [{
                required: true, message: 'Please input no of session!',
              }],
            })(
              <Input placeholder="0" />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Minute Per Session"
          >
            {getFieldDecorator('minutePerSession', {
              rules: [{
                required: true, message: 'Please input minute!',
              }],
            })(
              <Input placeholder="60" />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Select Categories"
            hasFeedback
          >
            {getFieldDecorator('categories', {
              rules: [
                { required: true, message: 'Please select your categories!' },
              ],
            })(
              <Select placeholder="Please select a categories">
                <Option value="GYM">GYM</Option>
                <Option value="OTHERS">Others</Option>
              </Select>
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Images"
          >
            <span style={{ display: 'flex', flexDirection: 'row', }} onClick={() => this.fileInput && this.fileInput.input.click()}>
              <MDBIcon far icon="image" size="2x" style={{ alignSelf: 'center' }} />
              <div style={{ color: "cecece", marginLeft: 10 }}>Upload image(s)</div>
            </span >


            <Input style={{ display: 'none' }} ref={(x) => this.fileInput = x} label='Upload image' type='file' onChange={this.handleImagePreview} multiple accept="image/jpeg, image/png" />


            {(!!this.state.previewSrc.length) &&
              (
                <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                  {
                    this.state.previewSrc.map((src, index) => (
                      <img key={src} src={src} style={{ 'width': '20%', height: 'auto' }} alt={`preview${index}`} />
                    ))
                  }
                </div>
              )
            }

            {
              (!!this.state.imageError) && (
                <div style={{ color: 'red' }}>
                  More than 5 images are not allowed
                </div>
              )
            }
          </FormItem>


        </Form>
      </Modal>

    )
  }
}


function mapStateToProps(state) {
  return {
    facilities: state.facilities
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode,
    updateActiveFacilities: updateActiveFacilities,
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(FacilitiesForm)));
