import _ from 'lodash';
import {
  FETCH_METERREADINGS,
  PUSH_METERREADINGS,
  REMOVE_METERREADINGS,
  UPDATE_METERREADINGS,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID } from '../actions/actions-meterreadings';

const INITIAL_STATE = {
  meterreadings: [],
  mode: 'index',
  activeId: '',
  activeMeterReadings:{}
}


export default function(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_METERREADINGS:
      return {
        ...state,
        meterreadings:action.payload,
      }
    case PUSH_METERREADINGS:
      return {
        ...state,
        meterreadings:[action.payload, ...state.meterreadings],
      }
    case REMOVE_METERREADINGS:
      return {
        ...state,
        meterreadings : [...state.meterreadings.filter( (item) => item._id !== action.payload._id)]
      }
    case UPDATE_METERREADINGS:
      let index =  _.findIndex(state.meterreadings, { '_id': action.payload._id })
      state.meterreadings.splice(index, 1, action.payload)
      return {
        ...state,
        meterreadings : state.meterreadings
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode : action.payload,
        activeId:''
      }
    case UPDATE_ACTIVE_ID:
      return {
        ...state,
        mode : action.mode,
        activeId:action.id,
        activeMeterReadings: _.pick(_.find(state.meterreadings, { '_id': action.id }), ['companyId','readDate', 'meterName','meterType','uom','previousNum','currentNum','usageNum','notes', '_id'])
      }
    default:
      return state

  }
}
