import { Button, Card, Col, Form, Popconfirm, Row, Spin, Table } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchBankRecons,
  pushBankRecons,
  removeBankRecons,
  updateActiveBankRecons,
  updateBankRecons,
  updateModeBankRecons,
  updateMonthBank,
} from "../../actions/actions-bankrecons";
import Layout from "../../components/layout";
import requireAuth from "../../components/requireAuth";
import client from "../../feathers";

// const FormItem = Form.Item;
const _ = require("lodash");
const PAGESIZE = 20;
const text = "Are you sure to delete this item?";
const textLock = "Are you sure to lock this item?";
const textUnLock = "Are you sure to unlock this item?";

class BankReconsIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      totalPagination: 1,
      CurrentPagination: 1,
      _id: "",
    };

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidUpdate() {}

  componentDidMount() {}

  componentWillMount() {
    this.props.updateModeBankRecons("");
    this.setState({ loading: true });

    client
      .authenticate()
      .then((res) => {
        return client.service("bankrecons").find({
          query: {
            companyId: this.props.commons.selectedCompany._id,
            $populate: "ledgerAccountId",
            $limit: 20,
            $sort: {
              createdAt: -1,
            },
          },
        });
      })
      .then((res) => {
        this.props.fetchBankRecons(res.data);
        this.setState({ totalPagination: res.total, loading: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
      });

    this.getAccountList();
  }

  handleDelete(_id) {
    client
      .service("bankrecons")
      .remove(_id)
      .then((res) => {
        this.componentWillMount();
      });
  }

  handleEdit(record) {
    // this.props.updateModeBankRecons('edit')
    this.props.updateActiveBankRecons("edit", record._id);
    this.props.history.push("/bankrecons/index/");
    // let thismonthFrom = moment(record.reconMonth).startOf('month')
    // this.props.updateMonthBank(thismonthFrom, record.ledgerAccountId, record.statementBalance,);
    // this.props.history.push('/bankrecons/index-master/index-daily/' + record.ledgerAccountId);
  }

  handleView(record) {
    this.props.updateActiveBankRecons("view", record._id);
    this.props.history.push("/bankrecons/index/");
  }

  handleManage(_id) {
    this.props.updateActiveBankRecons("", _id);
  }

  handleLock(_id) {
    client
      .service("bankrecons")
      .patch(_id, { lockBankRecon: true })
      .then((res) => {
        this.componentWillMount();
      });
  }

  handleUnLock(_id) {
    client
      .service("bankrecons")
      .patch(_id, { lockBankRecon: false })
      .then((res) => {
        this.componentWillMount();
      });
  }

  showModal = () => {
    this.props.updateModeBankRecons("new");
    this.props.history.push("/bankrecons/index/");
  };

  renderConfirmDeleteBtn(item) {
    return (
      <Popconfirm
        placement="topLeft"
        title={text}
        onConfirm={() => this.handleDelete(item._id)}
        okText="Confirm"
        cancelText="Cancel"
        disabled={item.lockBankRecon}
      >
        <Button
          style={{ marginRight: "6px" }}
          size="small"
          type="danger"
          disabled={item.lockBankRecon}
        >
          Delete
        </Button>
      </Popconfirm>
    );
  }

  renderLockBtn(item) {
    const isLocked = item.lockBankRecon;
    const isManagement = this.props.commons.selectedRole === "management";
    return (
      <Popconfirm
        placement="topLeft"
        title={isLocked ? textUnLock : textLock}
        disabled={isLocked ? !isManagement : isLocked}
        onConfirm={() =>
          isLocked && isManagement
            ? this.handleUnLock(item._id)
            : this.handleLock(item._id)
        }
        okText="Confirm"
        cancelText="Cancel"
      >
        <Button
          style={{ marginRight: "6px" }}
          size="small"
          type="primary"
          disabled={isLocked ? !isManagement : isLocked}
        >
          {isLocked ? "Unlock" : "Lock"}
        </Button>
      </Popconfirm>
    );
  }

  getData(skip) {
    this.setState({
      loading: true,
    });
    client
      .authenticate()
      .then(() => {
        let searchObj = this.state.searchObj;
        let queryString = "";

        if (this.state.searchDate !== "") {
          queryString = {
            trxDate: {
              $gte: new Date(
                moment(new Date(this.state.searchDate)).startOf("day")
              ),
              $lte: new Date(
                moment(new Date(this.state.searchDate)).endOf("day")
              ),
            },
          };
        }

        let query = {
          query: {
            companyId: this.props.commons.selectedCompany._id,
            $populate: "  dtledgerAccountId ctledgerAccountId ledgerAccountId",
            // $limit: 20,
            $sort: {
              createdAt: -1,
            },
            $limit: PAGESIZE,
            ...queryString,
            divisionType: "DEBTOR",
            orRegex: this.state.searchObj,
          },
        };
        _.assign(query.query, searchObj);

        return client.service("bankrecons").find(query);
      })
      .then((res) => {
        this.setState({
          loading: false,
        });

        this.props.fetchBankRecons(res.data);
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        console.log(err);
      });
  }

  handlePageChange = (page) => {
    this.setState({ CurrentPagination: page, loading: true });
    this.props.form.validateFields((err, values) => {
      client
        .authenticate()
        .then((res) => {
          //
          return client.service("bankrecons").find({
            query: {
              ...values,
              companyId: this.props.commons.selectedCompany._id,
              $limit: 20,
              $skip: (page - 1) * 20,
              $populate: "dtledgerAccountId ctledgerAccountId",
              divisionType: "DEBTOR",
              $sort: {
                createdAt: -1,
              },
            },
          });
        })
        .then((res) => {
          //
          this.setState({ totalPagination: res.total, loading: false });
          this.props.fetchBankRecons(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  handleSubmit() {
    if (this.props.bankrecons.mode === "new") {
      this.setState({
        loading: true,
      });

      this.props.form.validateFields((err, values) => {
        if (!err) {
          values.trxDate = new Date(values.trxDate);

          client
            .authenticate()
            .then((res) => {
              return client.service("bankrecons").create({
                companyId: this.props.commons.selectedCompany._id,
                ...values,
              });
            })
            .then((res) => {
              this.props.updateModeBankRecons("refresh");
              this.props.form.resetFields();
              this.setState({
                loading: false,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    }

    if (this.props.bankrecons.mode === "edit") {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          this.setState({
            loading: true,
          });

          values.trxDate = new Date(values.trxDate);

          client
            .authenticate()
            .then((res) => {
              return client
                .service("bankrecons")
                .patch(this.props.bankrecons.activeId, {
                  companyId: this.props.commons.selectedCompany._id,
                  ...values,
                });
            })
            .then((res) => {
              this.props.updateModeBankRecons("refresh");
              this.props.form.resetFields();
              this.setState({
                loading: false,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    }
  }

  getAccountList() {
    client
      .authenticate()
      .then((res) => {
        return client.service("ledger-accounts").find({
          query: {
            companyId: this.props.commons.selectedCompany._id,
            $sort: { accountName: 1 },
          },
        });
      })
      .then((res) => {
        this.setState({
          dtAccountList: res.data,
        });

        return client.service("ledger-accounts").find({
          query: {
            companyId: this.props.commons.selectedCompany._id,
            $sort: { accountName: 1 },
          },
        });
      })
      .then((res) => {
        this.setState({
          ctAccountList: res.data,
        });
      })

      .catch((err) => {
        console.log(err);
      });
  }

  //---------------------------------------------------------

  render() {
    // const {getFieldDecorator} = this.props.form;

    const columns = [
      {
        title: "Month",
        dataIndex: "reconMonth",
        key: "reconMonth",
        width: "10%",
        render: (text, record) => (
          <div>{text ? moment(text).format("MM-YYYY") : ""}</div>
        ),
      },
      {
        title: "Account",
        dataIndex: "ledgerAccountId",
        key: "ledgerAccountId",
        width: "20%",
        render: (text, record) => (
          <div>{text ? (text.accountName ? text.accountName : "") : ""}</div>
        ),
      },
      {
        // title: (<Row><Col style={{'textAlign':'center'}}>Remark</Col></Row>),
        title: "Remark",
        dataIndex: "remark",
        key: "remark",
        width: "50%",
        // render: (text, record) => (
        //   <div style={{textAlign: 'center'}}>
        //   {text?<Popover content={text}><Icon type="message" /></Popover>:''}
        //   </div>
        // ),
      },
      {
        title: "Action",
        key: "action",
        width: "20%",
        render: (text, record) => (
          <div>
            {/* <Link to={{
            pathname: `/bankrecons/print/${record._id}`
          }}>
          <Button style={{marginRight:'6px'}} size='small'  type="primary">Print</Button>
        </Link> */}
            <Button
              style={{ marginRight: "6px" }}
              size="small"
              onClick={() => {
                record.lockBankRecon
                  ? this.handleView(record)
                  : this.handleEdit(record);
              }}
            >
              {record.lockBankRecon ? "View" : "Edit"}
            </Button>
            {this.renderConfirmDeleteBtn(record)}
            {this.props.commons.selectedCompany._id ===
              "6051d0ccd4c161377c809f36" && this.renderLockBtn(record)}
          </div>
        ),
      },
    ];

    return (
      <Layout>
        <Spin key="spinning" spinning={this.state.loading}>
          <Card
            key="mainCard"
            title={
              <div>
                {" "}
                <Row>
                  <Col>
                    <span>Bank Recon</span>
                    <span
                      style={{
                        marginLeft: "5px",
                        marginBottom: "10px",
                      }}
                    ></span>
                  </Col>
                </Row>
              </div>
            }
            extra={[
              <Button
                key="btnNew"
                type="primary"
                onClick={() => this.showModal()}
                style={{ right: 10 }}
              >
                New
              </Button>,
            ]}
          >
            <>
              <Table
                key="mainTable"
                dataSource={this.props.bankrecons.bankrecons}
                columns={columns}
                rowKey="_id"
                pagination={{
                  defaultCurrent: 1,
                  total: this.state.totalPagination,
                  onChange: this.handlePageChange,
                  pageSize: 20,
                }}
                size={"small"}
              />
            </>
          </Card>
        </Spin>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    bankrecons: state.bankrecons,
    commons: state.commons,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchBankRecons: fetchBankRecons,
      pushBankRecons: pushBankRecons,
      removeBankRecons: removeBankRecons,
      updateActiveBankRecons: updateActiveBankRecons,
      updateBankRecons: updateBankRecons,
      updateModeBankRecons: updateModeBankRecons,
      updateMonthBank: updateMonthBank,
    },
    dispatch
  );
}

export default requireAuth(
  connect(mapStateToProps, mapDispatchToProps)(Form.create()(BankReconsIndex))
);
