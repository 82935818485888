import {
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Spin,
  Table,
} from "antd";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchInvoiceitems,
  pushInvoiceitems,
  removeInvoiceitems,
  updateActiveInvoiceitems,
  updateInvoiceitems,
  updateMode,
} from "../../actions/actions-invoiceitems";
import "../../App.css";
import client from "../../feathers";
import Layout from "../layout";
import requireAuth from "../requireAuth";
import RefundFormItems from "./form-refund";

const _ = require("lodash");
const text = "Are you sure to delete this item?";
const FormItem = Form.Item;
const EditableContext = React.createContext();
const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);
const EditableFormRow = Form.create()(EditableRow);
const Option = Select.Option;

class EditableCell extends React.Component {
  state = {
    editing: false,
    refund: [],
    loading: true,
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = () => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  getInput = () => {
    if (this.props.inputType === "number") {
      return (
        <InputNumber
          ref={(node) => (this.input = node)}
          onPressEnter={this.save}
          onBlur={this.save}
        />
      );
    } else if (this.props.inputType === "month") {
      //monthNames:['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December','All']
      return (
        <Select
          ref={(node) => (this.input = node)}
          onPressEnter={this.save}
          onBlur={this.save}
          placeholder="Please Month"
          style={{ width: "100%" }}
        >
          <Option value="1">1</Option>
          <Option value="2">2</Option>
          <Option value="3">3</Option>
          <Option value="4">4</Option>
          <Option value="5">5</Option>
          <Option value="6">6</Option>
          <Option value="7">7</Option>
          <Option value="8">8</Option>
          <Option value="9">9</Option>
          <Option value="10">10</Option>
          <Option value="11">11</Option>
          <Option value="12">12</Option>
        </Select>
      );
    } else if (this.props.inputType === "chargeId") {
      return (
        <Select
          ref={(node) => (this.input = node)}
          onPressEnter={this.save}
          onBlur={this.save}
          placeholder="Select Fee"
          style={{ width: "100%" }}
        >
          {this.state.refund.map((dropdown) => (
            <Option value={dropdown.code}>{dropdown.code}</Option>
          ))}
        </Select>
      );
    } else {
      return (
        <Input
          ref={(node) => (this.input = node)}
          onPressEnter={this.save}
          onBlur={this.save}
        />
      );
    }
  };

  render() {
    const { editing } = this.state;
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      ...restProps
    } = this.props;
    return (
      <td ref={(node) => (this.cell = node)} {...restProps}>
        {editable ? (
          <EditableContext.Consumer>
            {(form) => {
              this.form = form;
              return editing ? (
                <FormItem style={{ margin: 0 }}>
                  {form.getFieldDecorator(dataIndex, {
                    rules: [
                      {
                        required: true,
                        message: `${title} is required.`,
                      },
                    ],
                    initialValue: record[dataIndex],
                  })(this.getInput())}
                </FormItem>
              ) : (
                <div
                  className="editable-cell-value-wrap"
                  style={{ paddingRight: 24 }}
                  onClick={this.toggleEdit}
                >
                  {restProps.children}
                </div>
              );
            }}
          </EditableContext.Consumer>
        ) : (
          restProps.children
        )}
      </td>
    );
  }
}

class IndexKnockOffByPropertyunit extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        title: "Doc Id",
        dataIndex: "docId",
        key: "docId",
        width: "25%",
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        width: "25%",
      },
      {
        title: "Payment Date",
        dataIndex: "knockOffDate",
        key: "knockOffDate",
        width: "25%",
        render: (text, record) => (
          <span>
            {record.knockOffDate
              ? moment(record.knockOffDate).format("DD-MM-YYYY")
              : ""}
          </span>
        ),
      },
      {
        title: "Payment Amount",
        dataIndex: "knockOffBalance",
        key: "knockOffBalance",
        width: "25%",
        render: (text, record) => (
          <span>{this.formatAmt(record.knockOffBalance)}</span>
        ),
      },
    ];

    this.columnsRefund = [
      {
        title: "Refund Date",
        dataIndex: "refundDate",
        key: "refundDate",
        width: "10%",
        render: (text, record) => (
          <span>{moment(record.refundDate).format("DD-MM-YYYY")}</span>
        ),
      },
      {
        title: "Refund Item",
        dataIndex: "invoiceItemId.description",
        key: "invoiceItemId.description",
        width: "20%",
      },
      {
        title: (
          <Row>
            <Col style={{ float: "right" }}>Refund Amount</Col>
          </Row>
        ),
        dataIndex: "refundAmt",
        key: "refundAmt",
        width: "10%",
        editable: false,
        render: (text, record) => (
          <span
            style={{
              float: "right",
            }}
          >
            {parseFloat(text).toFixed(2)}
          </span>
        ),
      },

      {
        title: "Doc Id",
        dataIndex: "invoiceItemId.docId",
        key: "docId",
        width: "20%",
        editable: false,
      },
      {
        title: "Remark",
        dataIndex: "remark",
        key: "remark",
        width: "20%",
        editable: false,
      },
    ];

    this.state = {
      itemTotalAmount: 0,
      selectedAmount: 0,
      totalAmount: 0,
      propertyUnit: {},
      loading: false,
      dataSource: [],
      dataSourceRefund: [],
      selectedRowKeys: [],
      count: 0,
      refund: [],
      selectedRows: [],
    };
  }

  componentDidUpdate() {}

  componentDidMount() {
    // client.service('refunds').on('created', (refunds) => {
    //   if (sessionStorage.getItem('companyId') === refunds.propertyId && this.props.match.params.id === refunds.propertyunitId) {
    //     this.componentWillMount();
    //   }
    // }
    // )
    // client.service('refunds').on('removed', (refunds) => {
    //   if (sessionStorage.getItem('companyId') === refunds.propertyId && this.props.match.params.id === refunds.propertyunitId) {
    //     this.componentWillMount();
    //   }
    // })
    // client.service('refunds').on('updated', (refunds) => {
    //   if (sessionStorage.getItem('companyId') === refunds.propertyId && this.props.match.params.id === refunds.propertyunitId) {
    //     this.componentWillMount();
    //   }
    // })
    // client.service('refunds').on('patched', (refunds) => {
    //   if (sessionStorage.getItem('companyId') === refunds.propertyId && this.props.match.params.id === refunds.propertyunitId) {
    //     this.componentWillMount();
    //   }
    // })
    // client.service('invoice-items').on('patched', (invoiceItems) => {
    //   if (sessionStorage.getItem('companyId') === invoiceItems.propertyId && this.props.match.params.id === invoiceItems.propertyunitId) {
    //     this.componentWillMount();
    //   }
    // })
  }

  componentWillMount() {
    this.setState({ loading: true });
    client
      .authenticate()
      .then((res) => {
        return client.service("invoice-items").find({
          query: {
            $populate: "propertyId invoiceId",
            propertyId: this.props.propertyunitId,
            invoiceId: this.props.match.params.id,
            description: { $in: ["DEPOSIT", "RENOVATION DEPOSIT"] },
            status: { $in: ["PAID"] },
            $sort: {
              docId: 1,
            },
          },
        });
      })
      .then((res) => {
        this.setState({
          dataSource: res.data,
          loading: false,
        });
        this.checkedAllCheckedBox();
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });

    this._getRefund();
  }

  _getRefund() {
    client
      .authenticate()
      .then((res) => {
        return client.service("refunds").find({
          query: {
            propertyunitId: this.props.match.params.id,
            $populate: "invoiceItemId",
          },
        });
      })
      .then((res) => {
        this.setState({
          dataSourceRefund: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  checkedAllCheckedBox() {
    let selectedRowKeys = [];
    let selectedRows = [];
    let newData = [];
    let totalSelectedAmt = 0;
    let totalKnockOffAmt = 0;
    for (let i = 0; i < this.state.dataSource.length; i++) {
      this.state.dataSource[i].knockOffAmount =
        this.state.dataSource[i].appliedAmount -
        this.state.dataSource[i].knockOffBalance;
      newData.push(this.state.dataSource[i]);
      selectedRows.push(this.state.dataSource[i]);
      selectedRowKeys.push(this.state.dataSource[i]._id);
      totalSelectedAmt += this.state.dataSource[i].knockOffAmount;
      totalKnockOffAmt += this.state.dataSource[i].knockOffBalance;
    }
    setTimeout(() => {
      this.setState({
        selectedRowKeys: selectedRowKeys,
        selectedRows: selectedRows,
        dataSource: newData,
        selectedAmount: totalSelectedAmt,
        itemTotalAmount: totalSelectedAmt,
        totalAmount: totalKnockOffAmt,
      });
    }, 1000);
  }

  formatAmt(amt) {
    let integer = parseFloat(amt);
    return integer > 0 ? integer.toFixed(2) : 0.0;
  }

  getInputStyle = (dataType) => {
    if (dataType === "amount") {
      return "number";
    } else if (dataType === "month") {
      return "month";
    } else if (dataType === "chargeId") {
      return "chargeId";
    } else {
      return "text";
    }
  };

  handleChangeTotalAmt = (e) => {
    this.setState({ totalAmount: e });

    let tempValue = 0;
    let tempAddOn = 0;
    let oriAmt = e;
    let dataSource = this.state.dataSource;
    let newData = [];
    let stopper = false;

    for (let i = 0; i < dataSource.length; i++) {
      let isChecked = _.includes(this.state.selectedRowKeys, dataSource[i]._id);
      if (isChecked) {
        if (stopper === true) {
          dataSource[i].knockOffAmount = 0.0;
        } else if (
          tempValue +
            (dataSource[i].appliedAmount - dataSource[i].knockOffBalance) <=
          e
        ) {
          tempValue =
            dataSource[i].appliedAmount - dataSource[i].knockOffBalance;
          tempAddOn +=
            dataSource[i].appliedAmount - dataSource[i].knockOffBalance;

          if (tempAddOn >= e) {
            dataSource[i].knockOffAmount = parseFloat(oriAmt);
            stopper = true;
          } else {
            dataSource[i].knockOffAmount = tempValue;
            oriAmt -=
              dataSource[i].appliedAmount - dataSource[i].knockOffBalance;
          }
        } else {
          dataSource[i].knockOffAmount = e - tempValue;
          stopper = true;
        }

        newData.push(dataSource[i]);
      } else {
        dataSource[i].knockOffAmount = 0.0;
        newData.push(dataSource[i]);
      }
    }

    this.setState({ dataSource: newData });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  render() {
    const {
      dataSource,
      dataSourceRefund,
      selectedRowKeys,
      loading,
      selectedRows,
    } = this.state;
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };

    //when check or uncheck record, will run this function
    const rowSelection = {
      selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        let tempAmt = 0;
        let newData = [...this.state.dataSource];
        for (let i = 0; i < selectedRows.length; i++) {
          tempAmt += selectedRows[i].knockOffBalance;
        }

        if (selectedRows.length > 0) {
          //start handle latest selected row
          selectedRows[selectedRows.length - 1].knockOffAmount =
            selectedRows[selectedRows.length - 1].appliedAmount -
            selectedRows[selectedRows.length - 1].knockOffBalance;
          const index = newData.findIndex(
            (item) => selectedRows[selectedRows.length - 1]._id === item._id
          );
          const item = newData[index];
          newData.splice(index, 1, {
            ...item,
            ...selectedRows[selectedRows.length - 1],
          });
          //end handle latest selected row
        }

        this.setState({
          selectedRows: selectedRows,
          selectedRowKeys: selectedRowKeys,
          selectedAmount: tempAmt,
          dataSource: newData,
          totalAmount: tempAmt,
        });
      },
    };

    const hasSelected = selectedRows.length > 0;
    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });

    const columnsRefund = this.columnsRefund.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: this.getInputStyle(col.dataIndex), //col.dataIndex === 'amount' ? 'number' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
        }),
      };
    });

    return (
      <Layout>
        <Spin spinning={this.state.loading}>
          <Card title={"Refund"}>
            <Row>
              <Col span="16">
                {hasSelected ? `Selected ${selectedRows.length} items` : ""}
                <Table
                  rowSelection={rowSelection}
                  components={components}
                  rowClassName={() => "editable-row"}
                  bordered
                  dataSource={dataSource}
                  columns={columns}
                  pagination={false}
                  scroll={{ y: 340 }}
                  rowKey="_id"
                />
                <br />
              </Col>
              <Col span="8">
                <RefundFormItems
                  // selectedRowKeys={this.state.selectedRowKeys}
                  invoiceItems={this.state.selectedRows}
                  totalAmount={this.state.totalAmount}
                  propertyunitId={this.props.commons.propertyUnitId._id}
                  key="_id"
                />
              </Col>
            </Row>
          </Card>
        </Spin>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    invoiceitems: state.invoiceitems,
    commons: state.commons,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchInvoiceitems: fetchInvoiceitems,
      pushInvoiceitems: pushInvoiceitems,
      removeInvoiceitems: removeInvoiceitems,
      updateActiveInvoiceitems: updateActiveInvoiceitems,
      updateInvoiceitems: updateInvoiceitems,
      updateMode: updateMode,
    },
    dispatch
  );
}

export default requireAuth(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Form.create()(IndexKnockOffByPropertyunit))
);
