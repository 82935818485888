import React, { Component } from 'react';
import { Popconfirm, message, Divider,  Table, Form, Icon, Input, Button,Card } from 'antd';
import { Route, Link } from 'react-router-dom';

import client from '../../feathers';
import requireAuth  from "../../components/requireAuth";
import Layout  from "../../components/layout";
import VendorsForm  from "../../components/vendors/form";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchVendors,
  pushVendors,
  removeVendors,
  updateMode,
  updateActiveVendors,
  updateVendors } from '../../actions/actions-vendors';

const FormItem = Form.Item;

const text = 'Are you sure to delete this item?';

class VendorsIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      loading:false
    }

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidUpdate(){
  }

  componentDidMount(){
      client.service('vendors').on('created', (vendors) => {
        this.componentWillMount();
        //this.props.pushVendors(vendors)
      })

      client.service('vendors').on('removed', (vendors) => {
        //this.props.removeVendors(vendors)
        this.componentWillMount();
      })

      client.service('vendors').on('updated', (vendors) => {
        //this.props.updateVendors(vendors)
        this.componentWillMount();
      })
  }

  componentWillMount(){
      client.authenticate()
      .then(()=>{
          return client.service('vendors').find({
            query: {
               $sort: {
                 createdAt: -1
               }
             }
          })
      })
      .then((res)=>{
        this.props.fetchVendors(res.data)
      })
      .catch((err)=>{
          console.log(err);
      })
  }

  handleDelete(_id) {
     client.service('vendors').remove(_id)
     .then((res)=>{
     })
     .catch((err)=>{
       alert(err);
     })
  }

  handleEdit(_id) {
    this.props.updateActiveVendors('edit',_id)
  }

  handleManage(_id) {
    this.props.updateActiveVendors('',_id)
  }

  showModal = () => {
    this.props.updateMode('new')
  }

  renderConfirmDeleteBtn(_id){
    return (
      <Popconfirm placement="topLeft" title={text} onConfirm={()=>this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
        <Button type="danger">Delete</Button>
      </Popconfirm>
    )
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const columns = [{
        title: 'File ID',
        key: 'file',
        width: '10%',
        render: (text, record) => (
          <img style={{ width: '100px' }} src={record.file} />
        ),
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: '10%',
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        width: '20%',
      },
      {
        title: 'Contact',
        dataIndex: 'mobile',
        key: 'mobile',
        width: '10%',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: '10%',
      },
      {
      title: 'Action',
      key: 'action',
      width: '20%',
      render: (text, record) => (
        <span>
          <Divider type="vertical" />
          <Button onClick={()=>this.handleEdit(record._id)}>Edit</Button>
          <Divider type="vertical" />
          {this.renderConfirmDeleteBtn(record._id)}
        </span>
      ),
    }];

    return(
      <Layout>
          <Card
            title="Services"
            extra={<Button type="primary" onClick={this.showModal}>New Services</Button>}
          >
            <Table dataSource={this.props.vendors.vendors} columns={columns} rowKey="_id" />
            <VendorsForm />
          </Card>

      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    vendors: state.vendors,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchVendors: fetchVendors,
    pushVendors: pushVendors,
    removeVendors: removeVendors,
    updateActiveVendors: updateActiveVendors,
    updateVendors: updateVendors,
    updateMode: updateMode
    }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(VendorsIndex)));
