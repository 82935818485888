import React, { Component } from 'react'
import { Popconfirm, message, Divider, Table, Form, Icon, Input, Button, DatePickerBadge, Select } from 'antd'
import { Route, Link } from 'react-router-dom';

import client from '../../feathers'
import requireAuth from "../../components/requireAuth"
import Layout from "../../components/layout"
import FacilitybookingsForm from "../../components/facilitybookings/form"
import moment from "moment";

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  fetchFacilitybookings,
  pushFacilitybookings,
  removeFacilitybookings,
  updateMode,
  updateActiveFacilitybookings,
  updateFacilitybookings
} from '../../actions/actions-facilitybookings'

const _ = require('lodash')
const FormItem = Form.Item;
const Option = Select.Option;

const text = 'Are you sure to delete this item?';

class FacilitybookingsIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      facilityList: [],
      dateMoment: []
    }

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidUpdate() {

    if (this.props.facilitybookings.mode === 'dateSearch') {
      this.setState({ dateMoment: this.props.facilitybookings.date }, () => {

        this.getData(0)
      })
      this.props.updateActiveFacilitybookings('', '', '');
    }
    if (this.props.facilitybookings.mode === 'deleteSearch') {
      this.setState({ dateMoment: '' }, () => {

        this.getData(0)
      })
      this.props.updateActiveFacilitybookings('', '', '');
    }

  }

  componentDidMount() {
    // client.service('facilitybookings').on('created', (facilitybookings) => {
    //   this.props.pushFacilitybookings(facilitybookings)
    // })

    // client.service('facilitybookings').on('removed', (facilitybookings) => {
    //   this.props.removeFacilitybookings(facilitybookings)
    // })

    // client.service('facilitybookings').on('updated', (facilitybookings) => {
    //   this.props.updateFacilitybookings(facilitybookings)
    // })
  }

  componentWillMount() {
    //perform reset
    this.props.fetchFacilitybookings([]);
    this.props.updateMode('')




    client.authenticate().then((res) => {
      return client.service('facilities').find({
        query: {
          propertyId: this.props.commons.selectedCompany._id,
        }

      })
    }).then((res) => {

      this.setState({ facilityList: res.data })
    })


    this.getData(0)



  }

  getData(skip) {

    let facilityId, dateRange = ''

    if (_.isEmpty(this.state.selectedFacilitiesId) === false) {
      facilityId = { facilityId: this.state.selectedFacilitiesId }
    }

    if (!_.isEmpty(this.state.dateMoment)) {
      dateRange = {
        startDateTime: {
          $gte: new Date(moment(new Date(this.state.dateMoment[0])).startOf('day')),
          $lte: new Date(moment(new Date(this.state.dateMoment[1])).endOf('day')),

        }
      }
    }
    client.authenticate()
      .then((res) => {
        return client.service('facilitybookings').find({
          query: {
            ...facilityId,
            ...dateRange,
            propertyId: res.propertyId._id,
            $populate: 'facilityId userId propertyunitId',
            $sort: {
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {
        // console.log({res});
        this.props.fetchFacilitybookings(res.data)

      })
      .catch((err) => {
        console.log(err);
      })
  }

  handleDelete(_id) {
    client.service('facilitybookings').remove(_id)
      .then((res) => {
      })
      .catch((err) => {
        alert(err);
      })
  }

  handleEdit(_id) {
    this.props.updateActiveFacilitybookings('edit', _id)
  }

  handleManage(_id) {
    this.props.updateActiveFacilitybookings('', _id)
  }

  showModal = () => {
    this.props.updateMode('new')
  }

  handleSearch = (e) => {
    client.authenticate()
      .then((res) => {
        return client.service('facilitybookings').find({
          query: {
            startDate: e.format('YYYY-MM-DD'),
            propertyId: res.propertyId,
            $sort: {
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {

        this.setState({
          facilitybookings: res.data,
        });

        this.props.fetchFacilitybookings(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
  }

  renderConfirmDeleteBtn(_id) {
    return (
      <Popconfirm placement="topLeft" title={text} onConfirm={() => this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
        <Button type="danger">Delete</Button>
      </Popconfirm>
    )
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    let { facilityList } = this.state

    const columns = [
      {
        title:
          <Select style={{ width: '100%' }} defaultValue=''

            onChange={(e) => {
              clearTimeout(this.state.typingTimeout)
              this.setState({
                selectedFacilitiesId: e,
                typingTimeout: setTimeout(() => {
                  this.getData(0)
                }, 300)
              })

            }}
          >
            <Option key='' value=''>Facilities (All)</Option>

            {facilityList.map((v) =>
              < Option key={v._id} values={v._id} > {v.name}</Option>
            )
            }


          </Select>,
        dataIndex: 'facilityId.name',
        key: 'facilityId',
      },
      {
        title: 'User',
        dataIndex: 'userId',
        key: 'userId',
        render: (item, record) => {
          if (item) {
            let { firstName, lastName } = item
            firstName = firstName ? firstName : ''
            lastName = lastName ? lastName : ''
            return (<span> {firstName + ' ' + lastName}</span>)
          }
        }
      },
      {
        title: 'Unit No.',
        dataIndex: 'propertyunitId.referenceId',
        key: 'propertyunitId',
      },
      {
        title: 'No. Pax',
        dataIndex: 'pax',
        key: 'pax',
      },
      {
        title: 'Start Date/Time',
        dataIndex: 'startDateTime',
        key: 'startDateTime',
        render: (text, record) => (
          moment(text).format("YYYY-MM-DD HH:mm A")
        )
      },
      {
        title: 'End Date/Time',
        dataIndex: 'endDateTime',
        key: 'endDateTime',
        render: (text, record) => (
          moment(new Date(text)).format("YYYY-MM-DD HH:mm A")
        )
      },


    ];

    return (
      <>
        {/*<Button type="primary" onClick={this.showModal}>New Facilitybookings</Button>*/}
        {/* <DatePicker onChange={this.handleSearch} style={{'width':'200px'}}/> */}
        {/* <br /> */}
        {/* <Table dataSource={this.props.facilitybookings.facilitybookings} columns={columns} rowKey="_id" /> */}
        <Table dataSource={this.props.facilitybookings.facilitybookings} columns={columns} rowKey="_id" />
        <FacilitybookingsForm />

      </>
    )
  }
}


function mapStateToProps(state) {
  return {
    facilitybookings: state.facilitybookings,
    commons: state.commons
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchFacilitybookings,
    pushFacilitybookings,
    removeFacilitybookings,
    updateActiveFacilitybookings,
    updateFacilitybookings,
    updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(FacilitybookingsIndex)));
