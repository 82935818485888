import { Form, Input, message, Modal } from 'antd'
import React, { Component } from 'react'
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateMode } from '../../actions/actions-monthlystatements'
import requireAuth from "../../components/requireAuth"
import client from '../../feathers'

const FormItem = Form.Item;


const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};



class BankReconsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',

    }

  }
  componentDidUpdate() {
    if (this.props.monthlystatements.activeId !== this.state._id) {
      this.setState({
        _id: this.props.monthlystatements.activeId
      })

    }

  }


  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        client.service('monthlystatements').patch(this.props.monthlystatements.activeId,
          values
        )
          .then((res) => {
            message.success(`Record Updated`);
            this.props.updateMode('refresh')
          })
          .catch((err) => {
            console.log(err);
            this.props.updateMode('')
          })
        return;
      }

    });
  }

  componentWillMount() {

  }

  //-----------------------------------------------------


  //-----------------------------------------------------
  render() {
    const { getFieldDecorator } = this.props.form;


    return (
      <Modal
        visible={
          this.props.monthlystatements.mode === 'new' || this.props.monthlystatements.mode === 'edit' ? true : false
        }
        title={this.props.monthlystatements.mode === 'edit' ? 'Edit Bank Balance' : 'Edit Bank Balance'}
        okText={this.props.monthlystatements.mode === 'edit' ? 'Edit Bank Balance' : 'Edit Bank Balance'}
        onCancel={() => { this.props.updateMode(''); this.props.form.resetFields() }}
        onOk={this.handleSubmit}
      >
        <Form className="submit-form">

          <FormItem
            {...formItemLayout}
            label="Bank Balance"
          >
            {getFieldDecorator('bankStatementBalance', {
              rules: [{
                required: true, message: 'Please input Balance!',
              }],
            })(
              <Input />
            )}
          </FormItem>

        </Form>
      </Modal>

    )
  }
}


function mapStateToProps(state) {
  return {
    monthlystatements: state.monthlystatements
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(BankReconsForm)));