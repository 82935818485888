import React, { Component } from 'react'
import { Modal, message, Form, Input, Select } from 'antd'
import client from '../../feathers'
import requireAuth  from "../../components/requireAuth"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateMode } from '../../actions/actions-transactions'

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

class TransactionsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id:'',
      confirmDirty: false,
      autoCompleteResult: [],
      cc:true,
      invoicesDropdowns : []
    }
  }

  componentWillMount(){
    //generate invoice dropdown
    client.authenticate()
    .then((res)=>{
        return client.service('invoices').find({
          query: {
            propertyId: res.propertyId,
            status:{$in:['CLOSED'] },
             $sort: {
               createdAt: -1
             }
           }
        })
    })
    .then((res)=>{
      this.setState({
        invoicesDropdowns:res.data
      })
    })
    .catch((err)=>{
        console.log(err);
    });
  }
  componentDidUpdate(){
      if(this.props.transactions.activeId !== this.state._id){
        this.setState({
          _id:this.props.transactions.activeId
        })

        if(this.props.transactions.activeTransactions){
          this.props.form.setFieldsValue(
            this.props.transactions.activeTransactions
          )
        }
      }
  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }

  handleChange = (value) => {

    this.setState({ cc: value==='CASH'?true:false});
    this.props.form.resetFields();

  }

  handleGetInvoicePrice = (value) => {

    client.authenticate()
    .then((res)=>{
        return client.service('invoices').find({
          query: {
            _id: value,
             $sort: {
               createdAt: -1
             }
           }
        })
    })
    .then((res)=>{

      this.props.form.setFieldsValue({
        amount: res.data[0].total
      });
    })
    .catch((err)=>{
        console.log(err);
    });
  }

  handleSubmit = (e) => {
     e.preventDefault();
     if(this.props.transactions.mode === 'new'){

       this.props.form.validateFields((err, fieldValues) => {
         if (!err) {

          const values = {...fieldValues,channel:'WALKIN'};
           client.service('transactions').create(values)
           .then((res)=>{

             this.props.updateMode('')
             message.success('New Transactions Added');
           })
           .catch((err)=>{
            console.log(err);
             this.props.updateMode('')
             alert(err);
           })
         }
       });
     }else{
       this.props.form.validateFields((err, values) => {
         if (!err) {
           client.service('transactions').update(this.props.transactions.activeId,
             values
           )
           .then((res)=>{

             message.success(`Transactions Updated`);
             this.props.updateMode('')
           })
           .catch((err)=>{
            console.log(err);
             
             this.props.updateMode('')
           })
           return;
         }
         
       });
     }
  }

  render() {

    const { getFieldDecorator } = this.props.form;


    return(
      <Modal
         visible={
           this.props.transactions.mode==='new'||this.props.transactions.mode==='edit'?true:false
         }
         title={ this.props.transactions.mode === 'edit' ? 'Update Transactions':'Create Transactions' }
         okText={ this.props.transactions.mode === 'edit' ? 'Update':'Create' }
         onCancel={()=>{this.props.updateMode(''); this.props.form.resetFields()} }
         onOk={this.handleSubmit}

      >
        <Form className="submit-form">

          <FormItem
             {...formItemLayout}
             label="Payment Type"
          >
             {getFieldDecorator('paymentType', {
               rules: [{
                 required: true, message: 'Please select Payment Type!',
               }],
             })(
              <Select onChange={this.handleChange} placeholder="Please select Payment Type">
                <Option value="CASH">CASH</Option>
                <Option value="CREDIT CARD">CREDIT CARD</Option>
              </Select>
             )}
          </FormItem>

          <FormItem
             {...formItemLayout}
             label="Invoice"
          >
             {getFieldDecorator('invoiceId', {
               rules: [{
                 required: true, message: 'Please select invoice No!',
               }],
             })(
              <Select  onChange={this.handleGetInvoicePrice} placeholder="Select Invoice">
              {this.state.invoicesDropdowns.map(dropdown => (
                <Option value={dropdown._id}>
                  {dropdown.invoiceNo}
                </Option>
              ))}
            </Select>
             )}
          </FormItem>

          <FormItem
             {...formItemLayout}
             label="Currency"
           >
             {getFieldDecorator('currency', {
               rules: [{
                 required: true, message: 'Please Select Currency!',
               }],
             })(
              <Select placeholder="Please select Currency">
                <Option value="MYR">MALAYSIA RINGGIT</Option>
              </Select>
             )}
          </FormItem>

          <FormItem
             {...formItemLayout}
             label="Amount"
           >
             {getFieldDecorator('amount', {
               rules: [{
                 required: true, message: 'Please input Amount!',
               }],
             })(
                <Input disabled='true' placeholder="100.00" />
             )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="VISA/MASTER"
            hasFeedback
          >
            {getFieldDecorator('ccType', {
              rules: [
                { required: false, message: 'Please select VISA/MASTER!' },
              ],
            })(
              <Select disabled={this.state.cc} placeholder="Please Select VISA/MASTER">
                <Option value="VISA">VISA</Option>
                <Option value="MASTER">MASTER</Option>
                <Option value="AMERICAN EXPRESS">American Express</Option>
              </Select>
            )}
          </FormItem>

          <FormItem
             {...formItemLayout}
             label="Credit Card Amount"
           >
             {getFieldDecorator('ccNumber', {
               rules: [{
                 required: false, message: 'Please input Credit Card Number!',
               }],
             })(
               <Input disabled={this.state.cc} placeholder="4111 1111 1111 1111" />
             )}
          </FormItem>

        </Form>
      </Modal>

    )
  }
}


function mapStateToProps(state) {
  return {
    transactions: state.transactions
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
    }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(TransactionsForm)));
