import {
  Button,
  Card,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Menu,
  message,
  Popconfirm,
  Row,
  Select,
  Spin,
  Table,
  Input,
} from "antd";
import _ from "lodash";
import moment from "moment";

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchBankTrxs,
  pushBankTrxs,
  removeBankTrxs,
  updateActiveBankTrxs,
  updateBankTrxs,
  updateBankTrxsType,
  updateModeBankTrxs,
} from "../../actions/actions-banktrxs";
import BankTrxForm from "./form";
import Layout from "../../components/layout";
import requireAuth from "../../components/requireAuth";
import client from "../../feathers";
import { getBulkData } from "../functionContent/index";
import { Route, Link } from "react-router-dom";

const text = "Are you sure to delete this item?";

const { RangePicker } = DatePicker;
class BankTrxsIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      img: "",
      loading: false,
      accountList: [],
      accountList2: [],
      searchReferences: "",
    };

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.banktrxs.mode !== this.props.banktrxs.mode) {
      if (this.props.banktrxs.mode === "refresh") {
        this.componentWillMount();
      }
    }
  }

  componentDidMount() {}

  componentWillMount() {
    this.setState({ loading: true });
    this.props.updateModeBankTrxs("");
    client
      .authenticate()
      .then((res) => {
        this.getData();
        this.getAccountList();
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  }

  async getAccountList() {
    let companyId = this.props.user.info.propertyId._id;
    let pageSize = 50;
    let query = { companyId: companyId, $sort: { accountName: 1 } };
    let accountList = await getBulkData("ledger-accounts", query, pageSize);

    this.setState({ accountList: accountList, accountList2: accountList });
  }

  getData(skip) {
    this.setState({ loading: true });
    let selectedAccountStr = "",
      selectedAccountStr2 = "",
      dateQryStr = "",
      searchReferencesStr = "";

    let {
      selectedAccount,
      selectedAccount2,
      fromDate,
      toDate,
      searchReferences,
    } = this.state;
    if (_.isEmpty(selectedAccount) === false) {
      selectedAccountStr = { bankAccountId: selectedAccount };
    }
    if (_.isEmpty(selectedAccount2) === false) {
      selectedAccountStr2 = { otherAccountId: selectedAccount2 };
    }

    if (_.isEmpty(fromDate) === false && _.isEmpty(toDate) === false) {
      dateQryStr = { trxDate: { $gte: fromDate, $lte: toDate } };
    }

    if (_.isEmpty(searchReferences) === false) {
      searchReferencesStr = { referenceId: searchReferences };
    }

    client
      .service("banktrxs")
      .find({
        query: {
          ...selectedAccountStr,
          ...selectedAccountStr2,
          ...dateQryStr,
          ...searchReferencesStr,
          // companyId: this.props.commons.companyId,
          propertyId: this.props.user.info.propertyId._id,
          $populate: "otherAccountId bankAccountId",
          $sort: {
            createdAt: -1,
          },
        },
      })
      .then((res) => {
        this.props.fetchBankTrxs(res.data);
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  }

  handleDelete(_id) {
    client
      .service("banktrxs")
      .remove(_id)
      .then((res) => {
        this.props.updateModeBankTrxs("refresh");
      })
      .catch((err) => {
        message.error(err.message);
      });
  }

  handleEdit(record) {
    this.props.updateBankTrxsType(record.bankTrxType);
    this.props.updateActiveBankTrxs("edit", record._id);
  }

  handleCopy(record) {
    this.props.updateBankTrxsType(record.bankTrxType);
    this.props.updateActiveBankTrxs("copy", record._id);
  }

  handleView(_id) {
    this.props.updateActiveBankTrxs("view", _id);
  }

  handleManage(_id) {
    this.props.updateActiveBankTrxs("", _id);
  }

  showModal = (e) => {
    this.props.updateModeBankTrxs("new");
    this.props.updateBankTrxsType(e.key);
  };

  renderConfirmPrintBtn(record) {
    return (
      <span>
        <Link
          to={{
            pathname: `/ledgers/cashBankPrint/${record._id}`,
          }}
        >
          <Button
            key="navigateJV"
            style={{ marginTop: 5, marginBottom: 5 }}
            size="small"
            onClick={() => this.props.updateActiveBankTrxs("edit", record._id)}
          >
            Print
          </Button>
        </Link>
      </span>
    );
  }

  renderConfirmDeleteBtn(_id) {
    return (
      <Popconfirm
        placement="topLeft"
        title={text}
        onConfirm={() => this.handleDelete(_id)}
        okText="Confirm"
        cancelText="Cancel"
      >
        <Button size="small" style={{ marginRight: "6px" }} type="danger">
          Delete
        </Button>
      </Popconfirm>
    );
  }

  renderAccountName(record, type) {
    if (type === "bank") {
      if (!record) {
      }
      if (!record.bankAccountId) {
        return;
      }
      return (
        <Row>
          <Col span={24}>
            <span>
              {record.bankAccountId.accountName +
                "/" +
                record.bankAccountId.description}
            </span>
          </Col>
          <Col span={24}>
            <span>
              {record.bankAccountId.fullAccountNo +
                " - " +
                record.bankAccountId.categories}
            </span>
          </Col>
        </Row>
      );
    }

    if (type === "other") {
      if (!record) {
      }
      if (!record.otherAccountId) {
        return;
      }
      return (
        <Row>
          <Col span={24}>
            <span>
              {record.otherAccountId.accountName +
                "/" +
                record.otherAccountId.description}
            </span>
          </Col>
          <Col span={24}>
            <span>
              {record.otherAccountId.fullAccountNo +
                " - " +
                record.otherAccountId.categories}
            </span>
          </Col>
        </Row>
      );
    }
  }

  fromDateOnChange = (e) => {
    this.setState(
      {
        fromDate: moment(new Date(e[0])).startOf("day"),
        toDate: moment(new Date(e[1])).endOf("day"),
      },
      () => {
        this.getData();
      }
    );
  };

  changeData = () => {
    const fieldValues = {
      userId: "",
      customerType: "PROPERTYUNIT",
      currency: "MYR",
      appliedAmount: 0,
      payAmount: 0,
      // companyId: this.props.commons.companyId,
      propertyId: this.props.user.info.propertyId._id,
    };
    client
      .service("banktrxs")
      .create(fieldValues)
      .then((res) => {});
  };
  // ------------------------------------------------------------------------------
  render() {
    let { accountList } = this.state;

    let accountTitle = (
      <Row gutter={8}>
        <Col span={24}>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              Bank
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Select
                style={{ width: "100%" }}
                showSearch
                dropdownMatchSelectWidth={false}
                optionFilterProp="children"
                allowClear={true}
                showArrow={false}
                onChange={(e) => {
                  this.setState({
                    selectedAccount: e,
                    typingTimeout: setTimeout(() => {
                      this.getData();
                    }, 1000),
                  });
                }}
                filterOption={(input, option) =>
                  option.props.children.props.children[0].props.children.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0 ||
                  option.props.children.props.children[1].props.children.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {accountList.map((v) => (
                  <Select.Option key={v._id} values={v._id}>
                    <Row>
                      <Col className="accountColSelect">
                        <span>{v.accountName + "/" + v.description}</span>
                      </Col>
                      <Col style={{ lineHeight: "16px" }}>
                        <span>{v.fullAccountNo + "-" + v.categories}</span>
                      </Col>
                    </Row>
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
        </Col>
      </Row>
    );

    let accountTitle2 = (
      <Row gutter={8}>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            Pay/Receive/Transfer
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Select
              style={{ width: "100%" }}
              showSearch
              dropdownMatchSelectWidth={false}
              optionFilterProp="children"
              allowClear={true}
              showArrow={false}
              onChange={(e) => {
                this.setState({
                  selectedAccount2: e,
                  typingTimeout: setTimeout(() => {
                    this.getData();
                  }, 1000),
                });
              }}
              filterOption={(input, option) =>
                option.props.children.props.children[0].props.children.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0 ||
                option.props.children.props.children[1].props.children.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {accountList.map((v) => (
                <Select.Option key={v._id} values={v._id}>
                  <Row>
                    <Col className="accountColSelect">
                      <span>{v.accountName + "/" + v.description}</span>
                    </Col>
                    <Col style={{ lineHeight: "16px" }}>
                      <span>{v.fullAccountNo + "-" + v.categories}</span>
                    </Col>
                  </Row>
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
      </Row>
    );

    const columns = [
      {
        title: accountTitle,
        dataIndex: "bankAccountId.accountName",
        key: "bankAccountId.name",
        width: "15%",
        className: "fixedColumnWidth",
        // ellipsis:true,
        render: (text, record) => (
          <React.Fragment>
            {this.renderAccountName(record, "bank")}
          </React.Fragment>
        ),
      },
      {
        title: accountTitle2,
        dataIndex: "otherAccountId.accountName",
        key: "otherAccountId.name",
        className: "fixedColumnWidth",
        width: "15%",
        render: (text, record) => (
          <React.Fragment>
            {this.renderAccountName(record, "other")}
          </React.Fragment>
        ),
      },
      {
        title: (
          <Row gutter={8}>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                References
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Input
                  value={this.state.searchReferences}
                  onChange={(e) => {
                    clearTimeout(this.state.typingTimeout);
                    this.setState({ searchReferences: e.target.value });
                    this.setState({
                      typingTimeout: setTimeout(() => {
                        this.getData();
                      }, 500),
                    });
                  }}
                />
              </Col>
            </Row>
          </Row>
        ),
        dataIndex: "referenceId",
        key: "referenceId",
        width: "10%",
      },
      {
        title: "Type",
        dataIndex: "bankTrxType",
        width: "10%",
        render: (text, record) => <span>{text ? text.toUpperCase() : ""}</span>,
      },
      {
        title: (
          <>
            <Row>
              <Col span={24}>Date</Col>
            </Row>
            <Row>
              <Col span={24}>
                <RangePicker
                  placeholder={["From", "To"]}
                  onChange={this.fromDateOnChange}
                />
              </Col>
            </Row>
          </>
        ),
        dataIndex: "trxDate",
        key: "date",
        width: "10%",
        render: (text, record) => moment(text).format("DD-MM-YYYY"),
      },
      {
        title: <div style={{ textAlign: "right" }}>Amount</div>,
        dataIndex: "trxAmount",
        key: "trxAmount",
        width: "10%",
        render: (text, record) => (
          <span style={{ float: "right" }}>{text ? text : "0.00"}</span>
        ),
      },

      {
        title: "Description",
        dataIndex: "description",
        key: "remark",
        width: "20%",
      },

      {
        title: "Action",
        key: "action",
        width: "10%",
        render: (text, record) => (
          <React.Fragment>
            <Button
              size="small"
              style={{ marginRight: "6px" }}
              onClick={() => this.handleEdit(record)}
            >
              Edit
            </Button>
            <Button
              size="small"
              style={{ marginRight: "6px", marginTop: 5}}
              onClick={() => this.handleCopy(record)}
            >
              Copy
            </Button>
            {this.renderConfirmPrintBtn(record)}

            {this.renderConfirmDeleteBtn(record._id)}
          </React.Fragment>
        ),
      },
    ];

    const menuNew = (
      <Menu onClick={this.showModal}>
        <Menu.Item key="spend">Spend</Menu.Item>
        <Menu.Item key="receive">Receive</Menu.Item>
        <Menu.Item key="transfer">Transfer</Menu.Item>
      </Menu>
    );

    return (
      <Layout>
        <Card
          title="Cash & Bank Transactions"
          extra={
            <Dropdown overlay={menuNew} placement="bottomCenter">
              <Button>New</Button>
            </Dropdown>
          }
        >

          <Spin key="spinning" spinning={this.state.loading}>
            <Table
              dataSource={this.props.banktrxs.banktrxs}
              columns={columns}
              rowKey="_id"
            />
          </Spin>

          <BankTrxForm />
        </Card>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    banktrxs: state.banktrxs,
    commons: state.commons,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchBankTrxs: fetchBankTrxs,
      pushBankTrxs: pushBankTrxs,
      removeBankTrxs: removeBankTrxs,
      updateActiveBankTrxs: updateActiveBankTrxs,
      updateBankTrxs: updateBankTrxs,
      updateModeBankTrxs: updateModeBankTrxs,
      updateBankTrxsType: updateBankTrxsType,
    },
    dispatch
  );
}

export default requireAuth(
  connect(mapStateToProps, mapDispatchToProps)(Form.create()(BankTrxsIndex))
);
