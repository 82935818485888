import React, { Component, useEffect } from 'react'
import { Modal, Upload, Popconfirm, message, Divider,  Table, Form, Icon, Input, Button, Select,InputNumber } from 'antd'
import client from '../../feathers'
import requireAuth  from "../../components/requireAuth"
import Layout  from "../../components/layout"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateMode } from '../../actions/actions-fpxparameters'
import axios from 'axios';
import params from '../../params/system'
var FormData = require('form-data');

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset:4 },
};
const text = 'Are you sure to delete this item?';
  //----------------------------------------------------------------------------
class FpxParametersForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id:'',
      confirmDirty: false,
      autoCompleteResult: [],
      referenceformat:'',
      ledgerAccountList:[],
    }

  }
  //----------------------------------------------------------------------------
  componentDidUpdate(){
      if(this.props.fpxparameters.activeId !== this.state._id){
        this.setState({
          _id:this.props.fpxparameters.activeId
        })


        if(this.props.fpxparameters.activeFpxParameters){
          this.props.form.setFieldsValue(
            this.props.fpxparameters.activeFpxParameters
          )
        }
      }
  }
  //----------------------------------------------------------------------------
  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }
  //----------------------------------------------------------------------------
  handleSubmit = (e) => {
     e.preventDefault();
     if(this.props.fpxparameters.mode === 'new'){

       this.props.form.validateFields((err, values) => {
         if (!err) {

           client.service('fpxparameters').create({
            ...values,
            companyId:sessionStorage.getItem('companyId'),
          })
           .then((res)=>{
             this.props.updateMode('')
             message.success('New Record added');
           })
           .catch((err)=>{
             this.props.updateMode('')
             alert(err);
           })
         }
       });
     }else{
       this.props.form.validateFields((err, values) => {
         if (!err) {
           client.service('fpxparameters').patch(this.props.fpxparameters.activeId,
             values,

           )
           .then((res)=>{
             message.success(`Updated`);
             this.props.updateMode('')
           })
           .catch((err)=>{

             this.props.updateMode('')
           })
           return;
         }

       });
     }
  }

    //----------------------------------------------------------------------------
  componentWillMount(){

    client.authenticate()
    .then((res)=>{

        return client.service('ledger-accounts').find({
          query: {
            //orRegex:{description:'BANK'},
             $sort: {description: 1}
           }
        })
    })
    .then((res)=>{
      this.setState({
        ledgerAccountList:res.data
      })
    })
    .catch((err)=>{
        this.setState({
          ledgerAccountList:[]
        })
    })

  }

  //----------------------------------------------------------------------------
  render() {
    const { visible, onCancel } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { ledgerAccountList } = this.state;

    return(
      <Modal
         visible={
           this.props.fpxparameters.mode==='new'||this.props.fpxparameters.mode==='edit'?true:false
         }
         title={ this.props.fpxparameters.mode === 'edit' ? 'Update Parameter':'Create Parameter' }
         okText={ this.props.fpxparameters.mode === 'edit' ? 'Update':'Create' }
         onCancel={()=>{this.props.updateMode(''); this.props.form.resetFields()} }
         onOk={this.handleSubmit}
      >
        <Form className="submit-form">

          <FormItem
             {...formItemLayout}
             label="Type"
           >
             {getFieldDecorator('type', {
               rules: [{
                 required: true, message: 'Please input Type!',
               }],
             })(
               <Input  placeholder="1000" />
             )}
          </FormItem>

          <FormItem
             {...formItemLayout}
             label="Decription"
           >
             {getFieldDecorator('des', {
               rules: [{
                 required: true, message: 'Please input description!',
               }],
             })(
               <TextArea rows={4} />
             )}
          </FormItem>

          <FormItem
             {...formItemLayout}
             label="Account Name"
           >
             {getFieldDecorator('ledgerAccountId._id', {
               rules: [{
                 required: false, message: 'Please input Account Name!',
               }],
             })(

               <Select>
               {this.state.ledgerAccountList.map(dropdown => (
                 <Option key={dropdown._id} value={dropdown._id} description={dropdown.accountName} >
                   {dropdown.accountName}
                 </Option>
               ))}
               </Select>

             )}
          </FormItem>

        </Form>
        <span style={{'align':'right'}}>{this.state.referenceformat}</span>
      </Modal>

    )
  }
}

  //----------------------------------------------------------------------------
function mapStateToProps(state) {
  return {
    fpxparameters: state.fpxparameters
  }
}
  //----------------------------------------------------------------------------
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
    }, dispatch);
}

  //----------------------------------------------------------------------------

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(FpxParametersForm)));
