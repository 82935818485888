import React, { Component } from 'react';
import { Modal, message, Form, Input, Select } from 'antd';
import client from '../../feathers';
import requireAuth  from "../../components/requireAuth";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateMode } from '../../actions/properties-actions';
import axios from "axios";
const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

class PropertyForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id:'',
      malaysiaState:[],
      countries:[],
    }
  }

  // componentDidMount(){
  //   this.props.form.setFieldsValue({
  //     name:'abc'
  //   })
  // }

  componentDidUpdate(){
      if(this.props.properties.activeId !== this.state._id){
        this.setState({
          _id:this.props.properties.activeId
        })

        if(this.props.properties.activeProperty){
          this.props.form.setFieldsValue(
            this.props.properties.activeProperty
          )
        }
      }
  }

  handleSubmit = (e) => {
     e.preventDefault();
     if(this.props.properties.mode === 'new'){

       this.props.form.validateFields((err, values) => {
         if (!err) {


           // client.service('properties').create(values)
           // .then((res)=>{
           //
           //   this.props.updateMode('')
           //   message.success('New Property added');
           // })
           // .catch((err)=>{
           //  console.log('err');
           //   console.log(err);
           //   this.props.updateMode('')
           //   alert(err);
           // })

           axios.get(`${client.io.io.uri}createNewProperty`,{
             params:{
               values:values,
             },
             headers: {
               'Authorization': client.settings.storage.storage['feathers-jwt']
             }
           })
           .then((res)=>{

             this.props.updateMode('')
             if (res.data.status){
               message.success(res.data.status)
             }
           })



         }
       });



     }else{
       this.props.form.validateFields((err, values) => {
         if (!err) {
           client.service('properties').patch(this.props.properties.activeId,
             values
           )
           .then((res)=>{
             this.props.updateMode('')
             message.success('Property updated');
           })
           .catch((err)=>{
             console.log(err);
             this.props.updateMode('')
           })
           return;
         }
         console.log(err);
       });
     }
  }

  componentWillMount(){
    this.getStateParams();
    this.getCountriesParams();
  }

  getStateParams(){
    client.authenticate()
    .then(()=>{
        return client.service('parameters').find({
          query: {
            table: 'MALAYSIA-STATE',
             $sort: {
               VALUE: 1
             }
           }
        })
    })
    .then((res)=>{

      this.setState({
        malaysiaState:res.data[0].items
      })
    })
    .catch((err)=>{
        console.log(err);
    });
  }

  getCountriesParams(){
    client.authenticate()
    .then(()=>{
        return client.service('parameters').find({
          query: {
            table: 'COUNTRIES',
             $sort: {
               VALUE: 1
             }
           }
        })
    })
    .then((res)=>{

      this.setState({
        countries:res.data[0].items
      })
    })
    .catch((err)=>{
        console.log(err);
    });
  }

  handleBlur = (e) => {
    let code = e.target.value;
    //code.toUpperCase();
    this.props.form.setFieldsValue({
      code:code.toUpperCase(),
    })
  }

  render() {

    const { getFieldDecorator } = this.props.form;

    return(
      <Modal
         visible={
           this.props.properties.mode==='new'||this.props.properties.mode==='edit'?true:false
         }
         title="Create a new property"
         okText={ this.props.properties.mode === 'edit' ? 'Update':'Create' }
         onCancel={()=>{this.props.updateMode(''); this.props.form.resetFields()} }
         onOk={this.handleSubmit}

      >
        <Form className="submit-form">
          <FormItem {...formItemLayout} label="Property Name">
            {getFieldDecorator('name', {
              rules: [{ required: true, message: 'Please input property name!' }],
            })(
              <Input placeholder="PROPERTY" />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Code">
            {getFieldDecorator('code', {
              rules: [{ required: true, message: 'Please input Code!' }],
            })(
              <Input maxlength='6' placeholder="TRP" onBlur={this.handleBlur}/>
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Property Type"
            hasFeedback
          >
            {getFieldDecorator('type', {
              rules: [
                { required: true, message: 'Please select Type!' },
              ],
            })(
              <Select placeholder="Please select Type">
                <Option value="CONDOMINIUM">Condominium</Option>
                <Option value="APARTMENT">Apartment</Option>
                <Option value="FLAT">Flat</Option>
                <Option value="SERVICE RESIDENCE">SERVICE RESIDENCE</Option>
                <Option value="LANDED">LANDED</Option>
              </Select>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Address line 1">
            {getFieldDecorator('addressLineA', {
              rules: [{ required: false, message: 'Please input Address!' }],
            })(
              <Input />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Address line 2">
            {getFieldDecorator('addressLineB', {
              rules: [{ required: false, message: 'Please input Address!' }],
            })(
              <Input />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Address line 3">
            {getFieldDecorator('addressLineC', {
              rules: [{ required: false, message: 'Please input Address!' }],
            })(
              <Input />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Post Code">
            {getFieldDecorator('postCode', {
              rules: [{ required: false, message: 'Please input Post Code!' }],
            })(
              <Input />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="State">
            {getFieldDecorator('state', {
              rules: [{ required: false, message: 'Please input State!' }],
            })(
              <Select placeholder="Select state">
                {this.state.malaysiaState.map(dropdown => (
                  <Option value={dropdown.value}>
                    {dropdown.description}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Country">
            {getFieldDecorator('country', {
              rules: [{ required: false, message: 'Please select Country!' }],
            })(
              <Select placeholder="Select country">
                {this.state.countries.map(dropdown => (
                  <Option value={dropdown.value}>
                    {dropdown.description}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>

        </Form>
      </Modal>

    )
  }
}


function mapStateToProps(state) {
  return {
    properties: state.properties
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
    }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(PropertyForm)));
