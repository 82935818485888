import {
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Spin,
} from "antd";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  updateActiveBankTrxs,
  updateModeBankTrxs,
} from "../../actions/actions-banktrxs";
import requireAuth from "../../components/requireAuth";
import client from "../../feathers";
import { getBulkData } from "../functionContent/index";
// ------------------------------------------------------------
const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
const formItemLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

// ------------------------------------------------------------
class BankTrxForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: "",
      confirmDirty: false,
      autoCompleteResult: [],
      accountAssetsDropdowns: [],
      accountExpensesDropdowns: [],
      newMerchant: "",
      loading: false,
      subTotalAmount: 0,
      taxAmount: 0,
      totalAmount: 0,
      creditledgerAccountId: "",
      creditledgerAccountName: "",
      totalInvoiceAmount: "",
      productList: [],
      productId: "",
      count: 0,
      dataSource: [],
      boxInfo: "",
      invoiceMethod: "items",
      modelList: [],
      carBrandList: [],
      yearList: [],
      carSelection: [],
      selectedCar: {},
      modelDisabled: true,
      yearDisabled: true,
      carSelectionDisabled: true,
      paymentTypeParams: "",
    };
  }
  // ------------------------------------------------------------
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.banktrxs.activeId !== this.props.banktrxs.activeId) {
      let activeBankTrxs = this.props.banktrxs.activeBankTrxs;
      this.props.form.setFieldsValue({
        trxDate: moment(activeBankTrxs.trxDate),
        bankAccountId: activeBankTrxs.bankAccountId._id,
        otherAccountId: activeBankTrxs.otherAccountId._id,
        trxAmount: activeBankTrxs.trxAmount,
        referenceId: activeBankTrxs.referenceId,
        remark: activeBankTrxs.remark,
        description: activeBankTrxs.description,
      });
    }

    if (prevProps.banktrxs.mode !== this.props.banktrxs.mode) {
      if (this.props.banktrxs.mode === "new") {
        this.props.form.resetFields();
      }
    }
  }
  // ------------------------------------------------------------

  // ------------------------------------------------------------
  handleSubmit = (e) => {
    this.setState({ loading: true });
    e.preventDefault();
    // let currentnow = new Date()

    if (
      this.props.banktrxs.mode === "new" ||
      this.props.banktrxs.mode === "copy"
    ) {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          const fieldValues = {
            ...values,
            propertyId: this.props.user.info.propertyId._id,
            bankTrxType: this.props.banktrxs.bankTrxsType,
          };

          client
            .service("banktrxs")
            .create(fieldValues)
            .then((res) => {
              this.props.updateModeBankTrxs("refresh");
              message.success("New Record Added");
              this.props.form.resetFields();
              this.resetAllFields();
              this.setState({ loading: false });
            })
            .catch((err) => {
              this.setState({ loading: false });
              console.log(err);
              this.props.updateModeBankTrxs("");
              alert(err);
            });
        } else {
          this.setState({ loading: false });
        }
      });
    } else {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          const fieldValues = {
            ...values,
            invoiceItems: this.state.dataSource,
            bankTrxType: this.props.banktrxs.bankTrxType,
          };

          client
            .service("banktrxs")
            .patch(this.props.banktrxs.activeId, fieldValues)
            .then((res) => {
              message.success(`BankTrxs Updated`);
              this.props.updateModeBankTrxs("refresh");
              this.setState({
                loading: false,
                invoiceMethod: false,
                selectedProduct: [],
              });
              this.resetAllFields();
            })
            .catch((err) => {
              message.error(err.message);
              this.props.updateModeBankTrxs("");
              this.setState({ loading: false, invoiceMethod: false });
            });
          return;
        } else {
          this.setState({ loading: false, invoiceMethod: "items" });
        }
      });
    }
  };
  // ------------------------------------------------------------
  // handleFileUpload = (event) => {

  //   this.setState({ loading: true });

  //   let formData = new FormData();

  //   formData.append('image', event.target.files[0]);

  //   axios.post(`${client.io.io.uri}upload-banktrxs`, formData, {
  //     headers: {
  //       'Authorization': client.settings.accessToken,
  //       'Content-Type': 'multipart/form-data'
  //     }
  //   }).then((res) => {

  //     this.props.form.setFieldsValue({ file: res.data.imageUrl });
  //     this.setState({ loading: false });
  //     message.success('Upload Successful');
  //   }).catch((err) => {
  //     this.setState({ loading: false });
  //     console.log(err);
  //     message.error(err);
  //   })
  // }

  resetAllFields() {
    this.setState({
      loading: false,
    });
  }
  // ------------------------------------------------------------
  componentWillMount() {
    this.props.updateActiveBankTrxs("", "");
    this.props.form.resetFields();
    //generate dropdown
    client.authenticate().then((res) => {
      this.getAllLedgerAccount();
      // client.service('ledger-accounts').find({
      //   query: {
      //     companyId: this.props.user.info.propertyId._id,
      //     $limit: 0
      //   }
      // }).then((res) => {

      //   client.service('ledger-accounts').find({
      //     query: {
      //       companyId: this.props.user.info.propertyId._id,
      //       $sort: {
      //         accountName: 1,
      //         description: 1
      //       },
      //       $limit: res
      //     }
      //   }).then((res2) => {
      //     console.log(res2.total);
      //     if (res2.data.length > 0) {
      //       let bankAccountList = _.filter(res2.data, v => v.bankAccountFlag === true || v.cashAccountFlag === true);
      //       let otherAccountList = _.filter(res2.data, v => v.bankAccountFlag !== true);
      //       this.setState({ bankAccountList: bankAccountList, otherAccountList: otherAccountList })
      //     }
      //     else {
      //       this.setState({ bankAccountList: [], otherAccountList: [] })
      //     }

      //   })
      // })
    });
  }

  getPaymentTypeParams() {
    let companyId = this.props.user.info.propertyId._id;

    client
      .authenticate()

      .then((res) => {
        return client.service("payment-type-params").find({
          query: {
            propertyId: companyId,
            category: {
              $in: ["INVOICE"],
            },
            description: "ONLINE BANKING",
          },
        });
      })
      .then((res) => {
        console.log("adjsk", res);
        this.setState({
          paymentTypeParams: res.data[0],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidMount() {
    this.getPaymentTypeParams();
  }
  async getAllLedgerAccount() {
    let query = {
      companyId: this.props.user.info.propertyId._id,
      $sort: {
        accountName: 1,
        description: 1,
      },
    };
    let ledgerAccounts = await getBulkData("ledger-accounts", query, 50);

    if (ledgerAccounts.length > 0) {
      let bankAccountList = _.filter(
        ledgerAccounts,
        (v) => v.bankAccountFlag === true || v.cashAccountFlag === true
      );
      let otherAccountList = _.filter(
        ledgerAccounts,
        (v) => v.bankAccountFlag !== true
      );
      this.setState({
        bankAccountList: bankAccountList,
        otherAccountList: otherAccountList,
      });
    } else {
      this.setState({ bankAccountList: [], otherAccountList: [] });
    }
  }

  // ----------------------------------------------------------
  render() {
    const { getFieldDecorator } = this.props.form;
    const { bankAccountList, otherAccountList } = this.state;

    let mode = "";
    try {
      mode = this.props.banktrxs.mode;
    } catch (err) {
      mode = "";
    }
    return (
      <Modal
        width="1200px"
        visible={
          mode === "new" || mode === "edit" || mode === "copy" ? true : false
        }
        title={
          this.props.banktrxs.mode === "edit"
            ? "Update Bank Transactions - " +
              this.props.banktrxs.bankTrxsType.toUpperCase()
            : "New Bank Transactions - " +
              this.props.banktrxs.bankTrxsType.toUpperCase()
        }
        okText={this.props.banktrxs.mode === "edit" ? "Update" : "Create"}
        onCancel={() => {
          this.setState({ serviceTotalAmount: 0, selectedRecord: [] });
          this.props.updateModeBankTrxs("", "");
          this.props.form.resetFields();
        }}
        onOk={this.handleSubmit}
        centered
        confirmLoading={this.state.loading}
      >
        <Spin spinning={this.state.loading}>
          <Form className="submit-form">
            <Row>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Date">
                  {getFieldDecorator("trxDate", {
                    initialValue: moment(),
                    rules: [{ required: true, message: "Please input date!" }],
                  })(<DatePicker />)}
                </FormItem>
                {this.props.banktrxs.bankTrxsType !== "transfer" ? (
                  <React.Fragment>
                    <FormItem {...formItemLayout} label="Bank">
                      {getFieldDecorator("bankAccountId", {
                        rules: [
                          { required: true, message: "Please input category!" },
                        ],
                      })(
                        <Select
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children.props.children[0].props.children.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0 ||
                            option.props.children.props.children[1].props.children.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {bankAccountList
                            ? bankAccountList.map((dropdown) => (
                                <Option key={dropdown._id} value={dropdown._id}>
                                  <Row>
                                    <Col
                                      style={{
                                        lineHeight: "16px",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      <span>
                                        {dropdown.accountName +
                                          "/" +
                                          dropdown.description}
                                      </span>
                                    </Col>
                                    <Col style={{ lineHeight: "16px" }}>
                                      <span>
                                        {dropdown.fullAccountNo +
                                          "-" +
                                          dropdown.categories}
                                      </span>
                                    </Col>
                                  </Row>
                                </Option>
                              ))
                            : ""}
                        </Select>
                      )}
                    </FormItem>

                    <FormItem {...formItemLayout} label="Account">
                      {getFieldDecorator("otherAccountId", {
                        rules: [
                          { required: true, message: "Please input category!" },
                        ],
                      })(
                        <Select
                          dropdownMatchSelectWidth={false}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children.props.children[0].props.children.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0 ||
                            option.props.children.props.children[1].props.children.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {otherAccountList
                            ? otherAccountList.map((dropdown) => (
                                <Option key={dropdown._id} value={dropdown._id}>
                                  <Row>
                                    <Col
                                      style={{
                                        lineHeight: "16px",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      <span>
                                        {dropdown.accountName +
                                          "/" +
                                          dropdown.description}
                                      </span>
                                    </Col>
                                    <Col style={{ lineHeight: "16px" }}>
                                      <span>
                                        {dropdown.fullAccountNo +
                                          "-" +
                                          dropdown.categories}
                                      </span>
                                    </Col>
                                  </Row>
                                </Option>
                              ))
                            : ""}
                        </Select>
                      )}
                    </FormItem>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <FormItem {...formItemLayout} label="From">
                      {getFieldDecorator("bankAccountId", {
                        rules: [
                          { required: true, message: "Please input category!" },
                        ],
                      })(
                        <Select
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children.props.children[0].props.children.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0 ||
                            option.props.children.props.children[1].props.children.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {bankAccountList
                            ? bankAccountList.map((dropdown) => (
                                <Option key={dropdown._id} value={dropdown._id}>
                                  <Row>
                                    <Col
                                      style={{
                                        lineHeight: "16px",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      <span>
                                        {dropdown.accountName +
                                          "/" +
                                          dropdown.description}
                                      </span>
                                    </Col>
                                    <Col style={{ lineHeight: "16px" }}>
                                      <span>
                                        {dropdown.fullAccountNo +
                                          "-" +
                                          dropdown.categories}
                                      </span>
                                    </Col>
                                  </Row>
                                </Option>
                              ))
                            : ""}
                        </Select>
                      )}
                    </FormItem>

                    <FormItem {...formItemLayout} label="To">
                      {getFieldDecorator("otherAccountId", {
                        rules: [
                          { required: true, message: "Please input category!" },
                        ],
                      })(
                        <Select
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children.props.children[0].props.children.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0 ||
                            option.props.children.props.children[1].props.children.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {bankAccountList
                            ? bankAccountList.map((dropdown) => (
                                <Option key={dropdown._id} value={dropdown._id}>
                                  <Row>
                                    <Col
                                      style={{
                                        lineHeight: "16px",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      <span>
                                        {dropdown.accountName +
                                          "/" +
                                          dropdown.description}
                                      </span>
                                    </Col>
                                    <Col style={{ lineHeight: "16px" }}>
                                      <span>
                                        {dropdown.fullAccountNo +
                                          "-" +
                                          dropdown.categories}
                                      </span>
                                    </Col>
                                  </Row>
                                </Option>
                              ))
                            : ""}
                        </Select>
                      )}
                    </FormItem>
                  </React.Fragment>
                )}

                <FormItem key="invAmt" {...formItemLayout} label="Amount">
                  {getFieldDecorator("trxAmount", {
                    rules: [
                      { required: true, message: "Field Required Input." },
                    ],
                  })(
                    <InputNumber
                      style={{ width: "-webkit-fill-available" }}
                      min={0}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      precision={2}
                      steps={0.01}
                    />
                  )}
                </FormItem>
              </Col>

              <Col span={12}>
                <FormItem {...formItemLayout} label="Reference No.">
                  {getFieldDecorator("referenceId", {
                    rules: [
                      {
                        required: true,
                        message: "Please input Reference No.!",
                      },
                    ],
                  })(<Input placeholder="RECEIPT0000001" />)}
                </FormItem>

                <FormItem {...formItemLayout} label="Description">
                  {getFieldDecorator("description", {
                    rules: [
                      { required: true, message: "Please input Description!" },
                    ],
                  })(<Input maxLength={200} />)}
                </FormItem>

                <FormItem {...formItemLayout} label="Remark">
                  {getFieldDecorator("remark", {
                    rules: [
                      { required: false, message: "Please input Remark!" },
                    ],
                  })(<TextArea rows={4} maxLength={3000} />)}
                </FormItem>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return { user: state.user, banktrxs: state.banktrxs, commons: state.commons };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateModeBankTrxs: updateModeBankTrxs,
      updateActiveBankTrxs: updateActiveBankTrxs,
    },
    dispatch
  );
}

export default requireAuth(
  connect(mapStateToProps, mapDispatchToProps)(Form.create()(BankTrxForm))
);
