import React, { Component } from 'react';
import { Avatar, Row, Col, Card, Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Spin, Select, DatePicker, Radio } from 'antd';
import client from '../../feathers';
import requireAuth from "../requireAuth";
import Layout from "../layout";
import Info from "../common/info";
import moment, { isDate } from 'moment';
import ReactToPrint from "react-to-print";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  fetchEforms,
  pushEforms,
  removeEforms,
  updateMode,
  updateActiveEforms,
  updateEforms
} from '../../actions/actions-eforms';

const { TextArea } = Input;
const FormItem = Form.Item;
const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
};
const Option = Select.Option;
const EditableContext = React.createContext();
const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
  state = {
    editing: false,
  }

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  }

  save = (e) => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  }

  getInput = () => {
    if (this.props.inputType === 'date') {
      return <Input
        type='date'
        ref={node => (this.input = node)}
        onPressEnter={this.save}
        onBlur={this.save}
      />;
    } else {
      return <Input
        ref={node => (this.input = node)}
        onPressEnter={this.save}
        onBlur={this.save}
      />;
    }
  };

  render() {
    const { editing } = this.state;
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>
            {(form) => {
              this.form = form;
              return (
                editing ? (
                  <FormItem style={{ margin: 0 }}>
                    {form.getFieldDecorator(dataIndex, {
                      rules: [{
                        required: true,
                        message: `${title} is required.`,
                      }],
                      initialValue: record[dataIndex],
                    })(
                      this.getInput()
                      // <Input
                      //   ref={node => (this.input = node)}
                      //   onPressEnter={this.save}
                      //   onBlur={this.save}
                      // />
                    )}
                  </FormItem>
                ) : (
                  <div
                    className="editable-cell-value-wrap"
                    style={{ paddingRight: 24 }}
                    onClick={this.toggleEdit}
                  >
                    {restProps.children}
                  </div>
                )
              );
            }}
          </EditableContext.Consumer>
        ) : restProps.children}
      </td>
    );
  }
}

class ComponentToPrint extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      count: 0,
      setting: {},
      jmb: {},
      mode: 'New',
      activeId: '',
      cards: [],
      propertyunitsDropdowns: [],
      fetching: false,
      loading: false,
      formType: 'Common Area',
      createdAt: ''
    }

    this.columns = [{
      title: 'Card Type',
      dataIndex: 'cardType',
      width: '15%',
      editable: true,
    }, {
      title: 'Card No.',
      dataIndex: 'cardNo',
      width: '25%',
      editable: true,
    }, {
      title: 'Date Issued',
      dataIndex: 'dateIssue',
      width: '15%',
      editable: true,
    }, {
      title: 'Activation Date',
      dataIndex: 'activateDate',
      width: '15%',
      editable: true,
    }, {
      title: 'Expiry Date',
      dataIndex: 'expiryDate',
      width: '15%',
      editable: true,
    }, {
      title: 'Status',
      dataIndex: 'status',
      width: '15%',
      editable: true,
    }];
    // }, {
    //   title: 'operation',
    //   dataIndex: 'operation',
    //   render: (text, record) => (
    //     this.state.dataSource.length >= 1
    //       ? (
    //         <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record.key)}>
    //           <a href="javascript:;">Delete</a>
    //         </Popconfirm>
    //       ) : null
    //   ),
    // }];

  }

  componentDidUpdate() {

    if (this.props.mode !== this.state.mode) {
      this.setState({
        mode: this.props.mode
      });
    }

  }

  componentDidMount() {

    for (let i = 0; i < 10; i++) {

      setTimeout(() => {

        const { count, dataSource } = this.state;
        const newData = {
          key: count,
          cardType: ``,
          cardNo: '',
          dateIssue: ``,
          activateDate: ``,
          expiryDate: ``,
          status: ``,
        };
        this.setState({
          dataSource: [...dataSource, newData],
          count: count + 1,
        });

      }, 1000);

    }
  }

  _getPropertyUnit(id) {
    client.authenticate()
      .then(() => {
        return client.service('propertyunits').get(id)
      })
      .then(res => {
        this.setState({ propertyunitsDropdowns: [res] });
      })
  }

  componentWillMount() {

    if (this.props.id) {
      this.setState({ loading: true });
      //get data
      client.authenticate()
        .then(() => {
          return client.service('eforms').find({
            query: {
              _id: this.props.id,
              $populate: ['propertyunitId', 'userId'],
            }
          })
        })
        .then((res) => {
          res = res.data[0]
          this.setState({
            loading: false,
            formType: res.formType,
            createdAt: ' ' + moment(res.createdAt).format('DD/MM/YYYY')
          });


          this._getPropertyUnit(res.propertyunitId._id)
          this.props.form.setFieldsValue({
            mphType: res.mphType,
            session: res.session,
            reason: res.reason,
            applyDate: moment(res.applyDate),
            idNo: res.idNo,
            idName: res.idName,
            status: res.status,
            propertyunitId: res.propertyunitId._id,
            contactNo: res.contactNo,
            pax: res.pax,
            remark: res.remark,
          });

          //get footer info

        })

    }

    client.authenticate()
      //get footer info
      .then(() => {
        return client.service('settings').find({
          query: {
            propertyId: this.props.companyId
          }
        })
      })
      .then((res) => {
        this.setState({ setting: res.data[0].tnc })
        //get header info
        return client.service('jmbs').find({
          query: {
            propertyId: this.props.companyId,
            status: 'ACTIVE',
            $limit: 1,
            $sort: {
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {
        this.setState({ jmb: res.data[0] });
        // if(this.state.mode )
        //generate property unit dropdown
        return client.service('propertyunits').find({
          query: {
            propertyId: this.props.companyId,
            $sort: {
              referenceId: -1
            },
            $limit: 10,
          }
        })
      })
      .then((res) => {
        this.setState({ propertyunitsDropdowns: [...res.data, ...this.state.propertyunitsDropdowns] });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleSearchPropertyunit = (value) => {
    //generate charges dropdown
    this.setState({ fetching: true });
    client.authenticate()
      .then((res) => {
        return client.service('propertyunits').find({
          query: {
            propertyId: this.props.companyId,
            orRegex: {
              referenceId: value
            },
          }
        })
      })
      .then((res) => {

        this.setState({
          propertyunitsDropdowns: [...res.data, this.state.propertyunitsDropdowns],
          fetching: false,
        });
      })
      .catch((err) => {
        this.setState({ fetching: false });
        console.log(err);
      });
  }


  convertDate(date = null) {
    let tempDate = new Date(date);
    let d = tempDate.getDate();
    let m = tempDate.getMonth() + 1;
    let y = tempDate.getFullYear();
    return date ? y + '-' + m + '-' + d : '';
  }

  handleAdd = () => {
    const { count, dataSource } = this.state;
    const newData = {
      key: count,
      cardType: `VEHICLE,MOTORCYCLE,GYM,WALKWAY,CLUBHOUSE`,
      cardNo: '123123213',
      dateIssue: this.convertDate(new Date()),
      activateDate: this.convertDate(new Date()),
      expiryDate: this.convertDate(new Date()),
      status: `PENDING`,
    };
    this.setState({
      dataSource: [...dataSource, newData],
      count: count + 1,
    });
  }

  handleSave = (row) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ dataSource: newData });
  }

  handleDelete = (key) => {
    const dataSource = [...this.state.dataSource];
    this.setState({ dataSource: dataSource.filter(item => item.key !== key) });
  }

  handleChangePropertyunit = (e) => {

    const { propertyunitsDropdowns } = this.state;

    let row = propertyunitsDropdowns.filter(item => item._id === e)[0];
    if (row) {
      console.log(row)
      // this.props.form.setFieldsValue({
      //   contactNo: row.owner ? row.owner.contactNumber : '',
      //   ownerName: row.owner ? row.owner.name : ''
      // });
    }
  }

  getInputStyle = (dataType) => {
    if (dataType === 'expiryDate' || dataType === 'activateDate' || dataType === 'dateIssue') {
      return 'date';
    } else {
      return 'text';
    }
  }

  handleDateOnchange(date, dateString) {
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        return;
      }

      const mode = this.state.mode;

      if (mode === 'New') {
        client.service('eforms').create({
          ...fieldsValue,
          propertyId: this.props.companyId,
          formType: 'MULTIPURPOSE HALL'
        })
          .then((res) => {
            message.success('Record Created');

          })
          .catch((err) => {
            console.log(err);
          });

      } else if (mode === 'Edit') {
        // console.log(fieldsValue)
        client.service('eforms').patch(this.props.id, {
          ...fieldsValue,
        })
          .then((res) => {

            message.success('Record Updated');
          })
          .catch((err) => {
            console.log(err);
          });
      }


    });
  }
  renderSession() {
    const { getFieldDecorator } = this.props.form

    let mphValue = this.props.form.getFieldValue('mphType')


    return (<FormItem
      label="Session require"
    >
      {getFieldDecorator('session', {
        rules: [{
          required: true, message: 'Please input!',
        }],
      })(
        mphValue !== 'BBQ' ?
          <Select style={{ width: '75%' }}>
            <Option value='morning'>First Half ( 9am - 3pm )</Option>
            <Option value='night'>Second Half ( 5pm - 11pm )</Option>
            <Option value='all'>Entire Day ( 9am - 11pm )</Option>
          </Select> :
          <Select style={{ width: '75%' }}>
            <Option value='all'>One Day ( 6pm - 11pm )</Option>
          </Select>
      )}
    </FormItem>)





  }

  render() {
    const setting = this.state.setting;
    const loading = this.state.loading;
    const jmb = this.state.jmb;
    const dataSource = this.state.dataSource;
    const mode = this.props.mode
    let isDisable
    if (mode === 'Edit') {
      isDisable = true
    }
    else {
      isDisable = false
    }
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };

    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: this.getInputStyle(col.dataIndex), //col.dataIndex === 'amount' ? 'number' : 'text',
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });

    const { getFieldDecorator } = this.props.form;

    // company: {
    //   name            : { type: String},
    //   addressA        : { type: String},
    //   addressB        : { type: String},
    //   addressC        : { type: String},
    //   postCode        : { type: String},
    //   country         : { type: String},
    //   pic             : { type: String},
    //   contactNo       : { type: String},
    //   vehicleNo       : { type: String},
    //   numberOfWorkers : { type: String},
    //   fromDate        : { type: String},
    //   toDate          : { type: String},
    // },

    return (
      <Card
        loading={loading}
      >
        <Row>
          <Col span={4}>
            <img width='100%' src={jmb ? jmb.headerFile : ''} />
          </Col>
          <Col span={16} style={{ textAlign: 'center' }} >
            <p style={{ fontSize: 'large' }}><span>Application Form</span></p>
            <h3 style={{}}><span>COMMON AREA</span></h3>
          </Col>
          <Col span={4}>
            <p><span>{jmb ? jmb.headerLine1 : ''}</span></p>
            <p><span>{jmb ? jmb.headerLine2 : ''}</span></p>
            <p><span>{jmb ? jmb.headerLine3 : ''}</span></p>
            <p><span>{jmb ? jmb.headerLine4 : ''}</span></p>
          </Col>
        </Row>
        <Form className="submit-form" onSubmit={this.handleSubmit}>
          <Divider dashed >Residential Detail</Divider>
          <Row>
            <Col span={12}>
              <FormItem
                label="Name"
              >
                {getFieldDecorator('idName', {
                  rules: [{
                    required: true, message: 'Please input!',
                  }],
                })(
                  <Input style={{ width: '75%' }} />
                )}
              </FormItem>

              <FormItem
                label="IC No."
              >
                {getFieldDecorator('idNo', {
                  rules: [{
                    required: true, message: 'Please input!',
                  }],
                })(
                  <Input style={{ width: '75%' }} />
                )}
              </FormItem>

              <FormItem
                label="Type"
              >
                {getFieldDecorator('mphType', {
                  rules: [{
                    required: true, message: 'Please input!',
                  }],
                })(
                  <Select style={{ width: '75%' }}>
                    <Option key='Multi Purpose Hall'>Multipurpose Hall</Option>
                    <Option key='BBQ'>BBQ</Option>
                  </Select>
                )}
              </FormItem>

              <FormItem
                label="Pax"
              >
                {getFieldDecorator('pax', {
                  rules: [{
                    required: true, message: 'Please input!',
                  }],
                })(
                  <Input style={{ width: '75%' }} />
                )}
              </FormItem>

              <FormItem
                label="Status"
              >
                {getFieldDecorator('status', {
                  rules: [{
                    required: true, message: 'Please select Status!',
                  }],
                })(
                  <Radio.Group>
                    <Radio.Button value="In progress">In progress</Radio.Button>
                    <Radio.Button value="Approved">Approved</Radio.Button>
                    <Radio.Button value="Rejected">Rejected</Radio.Button>
                  </Radio.Group>
                )}
              </FormItem>
            </Col>
            <Col span={12} >

              <FormItem
                label="Unit"
              >
                {getFieldDecorator('propertyunitId', {
                  rules: [{
                    required: true, message: 'Please input!',
                  }],
                })(
                  <Select style={{ width: '75%' }}
                    disabled={isDisable}
                    showSearch
                    // value={value}
                    notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
                    filterOption={false}
                    onSearch={this.handleSearchPropertyunit}
                    onChange={this.handleChangePropertyunit}
                  >
                    {this.state.propertyunitsDropdowns.map(d => <Option key={d._id}>{d.referenceId}</Option>)}
                  </Select>
                )}
              </FormItem>

              <FormItem
                label="Mobile Number"
              >
                {getFieldDecorator('contactNo', {
                  rules: [{
                    required: true, message: 'Please input!',
                  }],
                })(
                  <Input style={{ width: '75%' }} />
                )}
              </FormItem>

              {this.renderSession()}

              <FormItem
                label="Booking Date"
              >
                {getFieldDecorator('applyDate', {
                  rules: [{
                    required: true, message: 'Please input!',
                  }],
                })(
                  <DatePicker />
                )}
              </FormItem>
              <FormItem
                label="Reason"
              >
                {getFieldDecorator('reason', {
                  rules: [{
                    required: true, message: 'Please input!',
                  }],
                })(
                  <Input style={{ width: '75%' }} />
                )}
              </FormItem>

              <span><b>Created Date:</b></span>
              <span>{this.state.createdAt}</span>




            </Col>
            {/* <Col span={7} offset={1}>
              <Form.Item
                label='Unit No.'
              >
                {getFieldDecorator(`propertyunitId`, {
                  rules: [{
                    required: true,
                    message: 'Unit Number!',
                  }],
                })(
                  <Select
                    showSearch
                    // value={value}
                    notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
                    filterOption={false}
                    onSearch={this.handleSearchPropertyunit}
                    onChange={this.handleChangePropertyunit}
                  >
                    {this.state.propertyunitsDropdowns.map(d => <Option key={d._id}>{d.referenceId}</Option>)}
                  </Select>
                )}
              </Form.Item>

              <FormItem
                label="Contact No"
              >
                {getFieldDecorator('contactNo', {
                  rules: [{
                    required: false, message: 'Please input contact No.!',
                  }],
                })(
                  //<Input disabled={true} />
                  <Input />
                )}
              </FormItem>

              <FormItem
                label="Owner Name"
              >
                {getFieldDecorator('ownerName', {
                  rules: [{
                    required: false, message: 'Please input Owner Name!',
                  }],
                })(
                  //<Input disabled={true} />
                  <Input />
                )}
              </FormItem>
            </Col> */}
          </Row>




          <Row>
            <Col span={7} >
              <span>Signature:</span>
              <br /><br />
              <br /><br />
              <Divider dashed />
              <span>Name:</span>
              <br /><br />
              <span>Date:</span>
            </Col>
            <Col span={15} offset={1}>
              <FormItem
                label="Remark"
              >
                {getFieldDecorator('remark', {
                  rules: [{
                    required: false, message: 'Please input!',
                  }],
                })(
                  <TextArea rows={4} />
                )}
              </FormItem>
            </Col>
          </Row>


          <Button type="primary" htmlType="submit" style={{ 'float': 'right' }} className='print-hide' >Submit</Button>
        </Form>
        <div className="invoiceFooter">
          <div className="ql-display-container">
            <div className="ql-display-editor" dangerouslySetInnerHTML={{ __html: setting ? setting.applicationFormTnc : '' }} />
          </div>

          <div style={{ 'textAlign': 'right' }}>© 2021 Mr Tech System Holdings Sdn Bhd (1370083-M)</div>
        </div>
      </Card>
    );
  }
}

class FormEformCommonArea extends React.Component {
  render() {
    if (this.props.mode === 'New') {

      return (
        <div>
          <Row>
            <Col style={{ marginBottom: 10, float: 'right' }}>
              <ReactToPrint
                trigger={() => <Button type='primary'>PRINT</Button>}
                content={() => this.componentRef}
              />
            </Col>
          </Row>
          <ComponentToPrint ref={el => (this.componentRef = el)} form={this.props.form} companyId={this.props.commons.selectedCompany._id} mode='New' />
        </div>
      );

    } else {

      return (
        <Layout>
          <Row>
            <Col style={{ marginBottom: 10, float: 'right' }}>
              <ReactToPrint
                trigger={() => <Button type='primary'>PRINT</Button>}
                content={() => this.componentRef}
              />
            </Col>
          </Row>
          <ComponentToPrint ref={el => (this.componentRef = el)} form={this.props.form} id={this.props.match.params.id} companyId={this.props.commons.selectedCompany._id} mode='Edit' />
        </Layout>
      );

    }
  }
}

function mapStateToProps(state) {
  return {
    eform: state.eform,
    commons: state.commons
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchEforms: fetchEforms,
    pushEforms: pushEforms,
    removeEforms: removeEforms,
    updateActiveEforms: updateActiveEforms,
    updateEforms: updateEforms,
    updateMode: updateMode
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(FormEformCommonArea));
