import React, { Component } from 'react';
import { Popconfirm, message, Divider,  Table, Form, Icon, Input, Button,Card } from 'antd';
import { Route, Link } from 'react-router-dom';

import client from '../../feathers';
import requireAuth  from "../../components/requireAuth";
import Layout  from "../../components/layout";
import AccounttrxsForm  from "../../components/accounttrxs/form";
import AccounttrxsFormJournal  from "../../components/accounttrxs/form-journal";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchAccounttrxs,
  pushAccounttrxs,
  removeAccounttrxs,
  updateMode,
  updateActiveAccounttrxs,
  updateAccounttrxs } from '../../actions/actions-accounttrxs';

const FormItem = Form.Item;

const text = 'Are you sure to delete this item?';

class AccounttrxsIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      balance:'0.00',
    }

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidUpdate(){
  }

  componentDidMount(){
 
  }



  componentWillMount(){

      client.authenticate()
      .then((res)=>{

          return client.service('accounttrxs').find({
            query: {
              //$populate:'accountId',
              accountmainId:sessionStorage.getItem('accountmainId'),
               $sort: {
                 //createdAt: -1,
                 trxDate: -1
               }
             }
          })
      })
      .then((res)=>{

        this.props.fetchAccounttrxs(res.data);
        this.setState({
          balance:res.balance,
        })

      })
      .catch((err)=>{
          console.log(err);
      });
  }

  handleDelete(_id) {
     client.service('accounttrxs').remove(_id)
     .then((res)=>{
     })
     .catch((err)=>{
       alert(err);
     })
  }

  handleEdit(_id) {
    this.props.updateActiveAccounttrxs('edit',_id)
  }

  handleEditJournal(_id) {
    this.props.updateActiveAccounttrxs('edit-journal',_id)
  }

  handleManage(_id) {
    this.props.updateActiveAccounttrxs('',_id)
  }

  showModal = () => {
    this.props.updateMode('new')
  }

  showModalJournal = () => {
    this.props.updateMode('new-journal')
  }

  renderConfirmDeleteBtn(_id){
    return (
      <Popconfirm placement="topLeft" title={text} onConfirm={()=>this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
        <Button type="danger">Delete</Button>
      </Popconfirm>
    )
  }

  getAccounts(id){
    var accounts = client.service('accounts').get(id);

    return accounts.description;

  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title: 'Last Update At',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
      },
      {
        title: 'Trx Date',
        dataIndex: 'trxDate',
        key: 'trxDate',
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
      },
      {
        title: 'Type',
        dataIndex: 'trxType',
        key: 'trxType',
        render: (text, record) => (
          <span>{record.trxType}</span>
        ),
      },
      {
        title: 'Credit/Dedit',
        dataIndex: 'creditDebit',
        key: 'creditDebit',
      },
      {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
          <Divider type="vertical" />
          {record.trxType==='JOURNAL'?<Button onClick={()=>this.handleEditJournal(record._id)}>Edit</Button>:<Button onClick={()=>this.handleEdit(record._id)}>Edit</Button>}
          <Divider type="vertical" />
          {/*this.renderConfirmDeleteBtn(record._id)*/}
        </span>
      ),
    }];

    return(
      <Layout>
        <Card
          title={<span style={{color:this.state.balance<0?'red':'green'}}>Transaction Balance : {this.state.balance}</span>}
          extra={[<Button type="primary" onClick={this.showModal}>New Transaction</Button>,<Button type="info" onClick={this.showModalJournal}>New Journal</Button>]}
        >
          <Table dataSource={this.props.accounttrxs.accounttrxs} columns={columns} rowKey="_id" />
          <AccounttrxsForm />
          <AccounttrxsFormJournal />
        </Card>

      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    accounttrxs: state.accounttrxs,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchAccounttrxs: fetchAccounttrxs,
    pushAccounttrxs: pushAccounttrxs,
    removeAccounttrxs: removeAccounttrxs,
    updateActiveAccounttrxs: updateActiveAccounttrxs,
    updateAccounttrxs: updateAccounttrxs,
    updateMode: updateMode
    }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(AccounttrxsIndex)));
