import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Icon,
  message,
  Modal,
  Popconfirm,
  Popover,
  Row,
  Spin,
  Table,
  Select,
  Input,
  DatePicker,
} from 'antd';
import axios from 'axios';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  fetchReceipts,
  pushReceipts,
  removeReceipts,
  updateActiveReceipts,
  updateMode,
  updateReceipts,
} from '../../actions/actions-receipts';
import Layout from '../../components/layout';
import requireAuth from '../../components/requireAuth';
import client from '../../feathers';
import moment from 'moment';
import { updateActivePage } from '../../actions/app-actions';

const _ = require('lodash');
const text = 'Are you sure to delete this item?';
const Option = Select.Option;

class ReceiptsIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      img: '',
      loading: false,
      selectedSupplier: '',
      supplierList: [],
      receiptNoFilter: '',
      dateFilter: '',
    };

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidUpdate() {
    if (this.props.receipts.mode === 'refresh') {
      this.props.updateActiveReceipts('', '');
      this.componentWillMount();
    }
  }

  componentDidMount() {
    client.service('receipts').on('created', (receipts) => {
      if (receipts.propertyId === sessionStorage.getItem('companyId')) {
        this.componentWillMount();
      }
    });

    client.service('receipts').on('removed', (receipts) => {
      if (receipts.propertyId === sessionStorage.getItem('companyId')) {
        this.componentWillMount();
      }
    });

    client.service('receipts').on('updated', (receipts) => {
      if (receipts.propertyId === sessionStorage.getItem('companyId')) {
        this.componentWillMount();
      }
    });

    client.service('receipts').on('patched', (receipts) => {
      if (receipts.propertyId === sessionStorage.getItem('companyId')) {
        this.componentWillMount();
      }
    });
  }

  async componentWillMount() {
    client.authenticate().then((res) => {
      client
        .service('suppliers')
        .find({
          query: {
            propertyId: this.props.commons.selectedCompany._id,
          },
        })
        .then((res) => {
          this.setState({ supplierList: res.data }, () => {
            this.getData(0);
          });
        });
    });
  }

  getData(skip) {
    this.setState({ loading: true });
    let { selectedSupplier, receiptNoFilter, dateFilter } = this.state;

    let queryStringDate,
      querySupplier = {},
      orRegexFilter = [];

    if (!_.isEmpty(selectedSupplier)) {
      querySupplier = { supplierId: selectedSupplier };
    }

    if (!_.isEmpty(receiptNoFilter)) {
      orRegexFilter.push({ receiptNo: receiptNoFilter });
    }
    if (_.isEmpty(dateFilter) === false) {
      queryStringDate = {
        date: {
          $gte: new Date(moment(dateFilter[0]).startOf('day')),
          $lte: new Date(moment(dateFilter[1]).endOf('day')),
        },
      };
    }

    if (!_.isEmpty(orRegexFilter)) {
      orRegexFilter = { orRegexOr: orRegexFilter };
    }

    client
      .service('receipts')
      .find({
        query: {
          propertyId: this.props.commons.selectedCompany._id,
          receiptType: { $nin: ['1', '2'] },
          ...querySupplier,
          ...orRegexFilter,
          ...queryStringDate,
          $populate: 'supplierId debitledgerAccountId',
          $sort: {
            createdAt: -1,
          },
        },
      })
      .then((res) => {
        this.props.fetchReceipts(res.data);
        this.setState({ loading: false });
      });
  }

  handleDelete(_id) {
    axios
      .post(
        `${client.io.io.uri}removeTrx`,
        {
          _id: _id,
          companyId: this.props.commons.selectedCompany._id,
          trxType: 'SI',
        },
        {
          headers: {
            Authorization: client.settings.storage.storage['feathers-jwt'],
          },
        }
      )
      .then((res) => {
        this.props.updateActiveReceipts('refresh', '');
        message.success('Deleted');
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleEdit(_id) {
    this.props.history.push('/receipts-form');
    this.props.updateActiveReceipts('edit', _id);
  }

  handleCopy(_id) {
    this.props.history.push('/receipts-form');
    this.props.updateActiveReceipts('copy', _id);
  }

  handleCN(_id) {
    this.props.history.push('/receipts-cn');
    this.props.updateActiveReceipts('cn', _id);
  }

  handleDN(_id) {
    this.props.history.push('/receipts-dn');
    this.props.updateActiveReceipts('dn', _id);
  }

  handleView(_id) {
    this.props.history.push('/receipts-form');
    this.props.updateActiveReceipts('view', _id);
  }

  handleManage(_id) {
    this.props.updateActiveReceipts('', _id);
  }

  showModal = () => {
    this.props.updateMode('new');
  };

  handleImage(img = null) {
    this.setState({
      visible: true,
      img: img,
    });
  }

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  renderConfirmDeleteBtn(_id) {
    return (
      <Popconfirm
        placement='topLeft'
        title={text}
        onConfirm={() => this.handleDelete(_id)}
        okText='Confirm'
        cancelText='Cancel'
      >
        <Button type='danger' style={{ marginTop: 5 }}>
          Delete
        </Button>
      </Popconfirm>
    );
  }

  renderEditButton(record) {
    let value = _.get(record, ['totalPay'], 0);
    if (value === 0) {
      return (
        <Button type='primary' onClick={() => this.handleEdit(record._id)}>
          Edit
        </Button>
      );
    } else if (value > 0) {
      return (
        <Button type='primary' onClick={() => this.handleView(record._id)}>
          View
        </Button>
      );
    }
  }

  renderCopyButton(record) {
    return (
      <Button type='primary' onClick={() => this.handleCopy(record._id)}>
        Copy
      </Button>
    );
  }

  renderPayButton(record) {
    let value = _.get(record, ['totalPay'], 0);

    if (record.totalAmount > value) {
      return (
        <Link
          to={{ pathname: `/paymentvoucher/paymentvoucher-pay/${record._id}` }}
        >
          <Button type='primary'>Pay</Button>
        </Link>
      );
    } else {
      return (
        <Button disabled type='primary'>
          Pay
        </Button>
      );
    }
  }

  renderCNBtn(r) {
    if (r.receiptType !== '1') {
      return (
        <>
          <Divider type='vertical' />
          <Button type='primary' onClick={() => this.handleCN(r._id)}>
            CN
          </Button>
        </>
      );
    }
  }

  renderDNBtn(r) {
    if (r.receiptType !== '2') {
      return (
        <>
          <Divider type='vertical' />
          <Button type='primary' onClick={() => this.handleDN(r._id)}>
            DN
          </Button>
        </>
      );
    }
  }

  _searchReceiptChange = (e) => {
    this.setState({ receiptNoFilter: e.target.value }, () => {
      this.getData(0);
    });
  };

  _searchDateChange = (e) => {
    this.setState({ dateFilter: e }, () => {
      this.getData(0);
    });
  };

  handlePageChange = (page) => {
    this.props.updateActivePage({
      supplierInvoicePage: page,
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title: (
          <>
            <Row>
              <Col span={24}></Col>Supplier
            </Row>
            <Row>
              <Col span={24}>
                <Select
                  style={{ width: '150px' }}
                  defaultValue=''
                  onChange={(e) => {
                    this.setState({ selectedSupplier: e }, () => {
                      this.getData(0);
                    });
                  }}
                  showSearch
                  allowClear
                  dropdownMatchSelectWidth={false}
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option key='' value=''>
                    Supplier
                  </Option>
                  {this.state.supplierList.map((v) => (
                    <Option key={v._id}>{v.name}</Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </>
        ),
        dataIndex: 'supplierId.name',
        key: 'supplierId.name',
        width: '15%',
      },
      {
        title: (
          <div>
            <Row>
              <Col span={24}>Bill / Invoice No</Col>
            </Row>
            <Row>
              <Col span={24}>
                <Input onChange={this._searchReceiptChange} />
              </Col>
            </Row>
          </div>
        ),
        dataIndex: 'receiptNo',
        key: 'receiptNo',
        width: '15%',
      },
      {
        title: (
          <div>
            <Row>
              <Col span={24}>Date</Col>
            </Row>
            <Row>
              <Col span={24}>
                <DatePicker.RangePicker
                  allowEmpty
                  type='Date'
                  onChange={this._searchDateChange}
                />
              </Col>
            </Row>
          </div>
        ),
        dataIndex: 'date',
        key: 'date',
        width: '8%',
        render: (text, record) => (
          <span style={{ float: 'right' }}>
            {moment(text).format('YYYY-MM-DD')}
          </span>
        ),
      },
      {
        title: <div style={{ textAlign: 'right' }}>Total Amount</div>,
        dataIndex: 'totalAmount',
        key: 'totalAmount',
        width: '10%',
        align: 'right',
        render: (text, record) => (
          <span style={{ float: 'right' }}>
            {record.totalAmount && record.totalAmount.toFixed(2)}
          </span>
        ),
      },
      {
        title: <div style={{ textAlign: 'right' }}>Total Pay</div>,
        dataIndex: 'totalPay',
        key: 'totalPay',
        width: '10%',
        align: 'right',
        render: (text, record) => (
          <span style={{ float: 'right' }}>
            {record.totalPay ? record.totalPay.toFixed(2) : '0.00'}
          </span>
        ),
      },

      {
        title: 'Remark',
        dataIndex: 'remark',
        key: 'remark',
        width: '5%',
        render: (text, record) => (
          <div style={{ textAlign: 'center' }}>
            {text ? (
              <Popover content={text}>
                <Icon type='message' />
              </Popover>
            ) : (
              ''
            )}
          </div>
        ),
      },
      {
        title: 'Action',
        key: 'action',
        width: '37%',
        render: (text, record) => (
          <div>
            {this.renderEditButton(record)}
            {/* {record.receiptId ? null : (<>{this.renderCNBtn(record)}{this.renderDNBtn(record)}</>)} */}
            <Divider type='vertical' />
            {this.renderCopyButton(record)}

            <Divider type='vertical' />
            {this.renderPayButton(record)}

            <Divider type='vertical' />

            {/* {this.renderCNButton(record)} */}

            <Divider type='vertical' />

            {record.file ? (
              <span>
                <Button
                  type='primary'
                  onClick={() => this.handleImage(record.file)}
                >
                  Image
                </Button>
                <Divider type='vertical' />
              </span>
            ) : (
              ''
            )}
            {/*record.status==='PENDING'?this.renderConfirmDeleteBtn(record._id):''*/}

            {this.renderConfirmDeleteBtn(record._id)}
          </div>
        ),
      },
    ];

    return (
      <Layout>
        <Card
          title='Supplier Invoices'
          extra={
            <Row gutter={24}>
              <Col span={12}>
                <Link to={{ pathname: `receipts-form` }}>
                  <Button type='primary' onClick={this.showModal}>
                    New Invoices
                  </Button>
                </Link>
              </Col>
              <Col span={12}>
                {/* <Link to={{ pathname: `/receipts/new` }} ><Button type="primary">Multiple Invoices</Button></Link> */}
              </Col>
            </Row>
          }
        >
          <Spin key='spinning' spinning={this.state.loading}>
            <Table
              dataSource={this.props.receipts.receipts}
              columns={columns}
              rowKey='_id'
              pagination={{
                defaultCurrent: this.props.activePage.supplierInvoicePage,
                onChange: this.handlePageChange,
              }}
            />
          </Spin>
          {/* <ReceiptsForm /> */}
          {/* <ReceiptsView /> */}
        </Card>

        <Modal
          title='Image'
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key='back' onClick={this.handleCancel}>
              Cancel
            </Button>,
          ]}
        >
          <img style={{ width: '100%' }} src={this.state.img} />
        </Modal>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    receipts: state.receipts,
    commons: state.commons,
    activePage: state.app.activePage,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchReceipts: fetchReceipts,
      pushReceipts: pushReceipts,
      removeReceipts: removeReceipts,
      updateActiveReceipts: updateActiveReceipts,
      updateReceipts: updateReceipts,
      updateMode: updateMode,
      updateActivePage,
    },
    dispatch
  );
}

export default requireAuth(
  connect(mapStateToProps, mapDispatchToProps)(Form.create()(ReceiptsIndex))
);
