import React from "react";

const SvgBbq = props => (
  <svg height={512} viewBox="0 0 64 64" width={512} {...props}>
    <g data-name="BBQ">
      <path
        fill="#8D99AE"
        d="M23 40a1 1 0 00-1 1v6a1 1 0 002 0v-6a1 1 0 00-1-1zM31 48a1 1 0 001-1v-6a1 1 0 00-2 0v6a1 1 0 001 1z"
        data-original="#000000"
        className="bbq_svg__active-path"
        data-old_color="#000000"
      />
      <path
        d="M62 22h-7a1 1 0 00-1 1v3a1 1 0 001 1v3h-2v-1a1 1 0 00-1-1H2a1 1 0 00-1 1v4a1 1 0 001 1h2v19.762A4.5 4.5 0 1010.238 55h33.524A4.5 4.5 0 1050 53.762V34h2a1 1 0 001-1v-1h3a1 1 0 001-1v-4h5a1 1 0 001-1v-3a1 1 0 00-1-1zM9 57.5A2.5 2.5 0 116.5 55 2.5 2.5 0 019 57.5zM6 53V34h20v19zm41.5 7a2.5 2.5 0 112.5-2.5 2.5 2.5 0 01-2.5 2.5zm.5-7H28V34h20zm3-21H3v-2h48zm10-7h-5v-1h5z"
        data-original="#000000"
        className="bbq_svg__active-path"
        data-old_color="#000000"
        fill="#8D99AE"
      />
      <path
        d="M27 27a8.009 8.009 0 008-8c0-4.061-6.442-13.5-7.177-14.568a1.038 1.038 0 00-1.646 0C25.442 5.5 19 14.939 19 19a8.009 8.009 0 008 8zm0-20.208c2.212 3.361 6 9.651 6 12.208a6 6 0 01-12 0c0-2.556 3.788-8.846 6-12.208z"
        data-original="#000000"
        className="bbq_svg__active-path"
        data-old_color="#000000"
        fill="#8D99AE"
      />
      <path
        d="M27 24.2a3.8 3.8 0 003.8-3.837c-.114-1.469-1.886-4.756-2.932-6.585a1 1 0 00-1.736 0c-1.044 1.823-2.814 5.1-2.931 6.622A3.8 3.8 0 0027 24.2zm0-7.862a17.821 17.821 0 011.8 4.1 1.8 1.8 0 01-3.605.04A16.775 16.775 0 0127 16.338z"
        data-original="#000000"
        className="bbq_svg__active-path"
        data-old_color="#000000"
        fill="#8D99AE"
      />
    </g>
  </svg>
);

export default SvgBbq;
