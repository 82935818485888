import React, { Component } from 'react'
import { Modal, message, Form, Input, Select } from 'antd'
import client from '../../feathers'
import requireAuth  from "../../components/requireAuth"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateMode } from '../../actions/actions-accounttrxs'

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

class AccounttrxsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id:'',
      confirmDirty: false,
      autoCompleteResult: [],
      accountAssestsDropdowns :[],
      accountExpensesDropdowns :[],
    }
  }

  componentWillMount(){

    client.authenticate()
    .then((res)=>{

        return client.service('accounts').find({
          query: {
            $limit: 99,
            accountmainId:sessionStorage.getItem('accountmainId'),
            categories:'assests',
             $sort: {
               createdAt: -1
             }
           }
        })
    })
    .then((res)=>{
      this.setState({
        accountAssestsDropdowns:res.data,
      })
    })
    .catch((err)=>{
        console.log(err);
        this.setState({
          accountAssestsDropdowns:[]
        })
    })

    client.authenticate()
    .then((res)=>{

        return client.service('accounts').find({
          query: {
            $limit: 99,
            accountmainId:sessionStorage.getItem('accountmainId'),
            //categories:'expenses',
             $sort: {
               createdAt: -1
             }
           }
        })
    })
    .then((res)=>{
      this.setState({
        accountExpensesDropdowns:res.data,
      })
    })
    .catch((err)=>{
        console.log(err);
        this.setState({
          accountExpensesDropdowns:[]
        })
    })

  }

  componentDidUpdate(){
      if(this.props.accounttrxs.activeId !== this.state._id){
        this.setState({
          _id:this.props.accounttrxs.activeId
        })

        if(this.props.accounttrxs.activeAccounttrxs){
          this.props.form.setFieldsValue(
            this.props.accounttrxs.activeAccounttrxs
          )
        }
      }
  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }


  handleSubmit = (e) => {
     e.preventDefault();
     if(this.props.accounttrxs.mode === 'new'){

       this.props.form.validateFields((err, fieldsValue) => {
         if (!err) {

          const values = {
            ...fieldsValue,
            'accountmainId': sessionStorage.getItem('accountmainId'),
          };

           client.service('accounttrxs').create(values)
           .then((res)=>{

             this.props.updateMode('')
             message.success('New Account Added');
           })
           .catch((err)=>{
            console.log(err);
             this.props.updateMode('')
             alert(err);
           })
         }
       });
     }else{
       this.props.form.validateFields((err, fieldsValue) => {

        const values = {
          ...fieldsValue,
          'accountmainId': sessionStorage.getItem('accountmainId'),
        };

         if (!err) {
           client.service('accounttrxs').patch(this.props.accounttrxs.activeId,
             values
           )
           .then((res)=>{

             message.success(`Account Updated`);
             this.props.updateMode('')
           })
           .catch((err)=>{
            console.log(err);
             
             this.props.updateMode('')
           })
           return;
         }
         
       });
     }
  }

  render() {
    const { getFieldDecorator } = this.props.form;


    return(
      <Modal
         visible={
           this.props.accounttrxs.mode==='new'||this.props.accounttrxs.mode==='edit'?true:false
         }
         title={ this.props.accounttrxs.mode === 'edit' ? 'Update Transaction':'Create Transaction' }
         okText={ this.props.accounttrxs.mode === 'edit' ? 'Update':'Create' }
         onCancel={()=>{this.props.updateMode(''); this.props.form.resetFields()} }
         onOk={this.handleSubmit}

      >
        <Form className="submit-form">

          <FormItem
             {...formItemLayout}
             label="Description"
           >
             {getFieldDecorator('description', {
               rules: [{
                 required: true, message: 'Please input Description!',
               }],
             })(
              <TextArea rows={4} />
             )}
          </FormItem>

          <FormItem
             {...formItemLayout}
             label="Amount"
           >
             {getFieldDecorator('amount', {
               rules: [{
                 required: true, message: 'Please input Amount!',
               }],
             })(
                <Input placeholder="100.00" />
             )}
          </FormItem>

          <FormItem
             {...formItemLayout}
             label="Date"
           >
             {getFieldDecorator('trxDate', {
               rules: [{
                 required: true, message: 'Please Select date!',
               }],
             })(
                <Input type='date' />
             )}
          </FormItem>

          <FormItem
             {...formItemLayout}
             label="Credit / Debit"
           >
             {getFieldDecorator('creditDebit', {
               rules: [{
                 required: true, message: 'Please Select credit or debit!',
               }],
             })(
              <Select placeholder="Please select credit or debit">
                <Option value="CREDIT">CREDIT</Option>
                <Option value="DEBIT">DEBIT</Option>
              </Select>
             )}
          </FormItem>

          <FormItem
             {...formItemLayout}
             label="Account"
           >
             {getFieldDecorator('accountId', {
               rules: [{
                 required: true, message: 'Please Select Account!',
               }],
             })(
              <Select placeholder="Please select Account">
                {this.state.accountAssestsDropdowns.map(dropdown => (
                  <Option value={dropdown._id}>
                    {dropdown.description}
                  </Option>
                ))}
              </Select>
             )}
          </FormItem>

          <FormItem
             {...formItemLayout}
             label="Remark"
           >
             {getFieldDecorator('remark', {
               rules: [{
                 required: false, message: 'Please Input Remark!',
               }],
             })(
                <TextArea rows={4} />
             )}
          </FormItem>

          <FormItem
             {...formItemLayout}
             label="Category"
           >
             {getFieldDecorator('category', {
               rules: [{
                 required: true, message: 'Please Input Category!',
               }],
             })(
              <Select placeholder="Please select Category">
                {this.state.accountExpensesDropdowns.map(dropdown => (
                  <Option value={dropdown._id}>
                    {dropdown.description}
                  </Option>
                ))}
              </Select>
             )}
          </FormItem>

        </Form>
      </Modal>

    )
  }
}


function mapStateToProps(state) {
  return {
    accounttrxs: state.accounttrxs
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
    }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(AccounttrxsForm)));
