import React, { Component } from 'react';
import {
  Popconfirm,
  message,
  Divider,
  Table,
  Form,
  Icon,
  Input,
  Button,
  Select,
  Radio,
  InputNumber,
  Spin,
  DatePicker,
} from 'antd';
import client from '../../feathers';
import requireAuth from '../requireAuth';
import Layout from '../layout';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateMode } from '../../actions/actions-refunds';
import axios from 'axios';
import { Link, withRouter, Route } from 'react-router-dom';
import moment from 'moment';

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
const _ = require('lodash');

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;
const text = 'Are you sure to delete this item?';

class WaiverFormItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',
      confirmDirty: false,
      autoCompleteResult: [],
      mode: this.props.mode,
      visible: false,
      invoice: [],
      loading: false,
      paymentTypeParams: [],
      paymentType: '', //CASH, CREDIT CARD, ONLINE BANKING, CHEQUE
      selectedRowKeys: [],
      totalAmount: 0,
      loading: false,
      waiveDate: moment(new Date()).format('YYYY-MM-DD'),
    };
  }

  componentDidUpdate() {
    if (this.props.totalAmount !== this.state.totalAmount) {
      this.setState({ totalAmount: this.props.totalAmount });
      this.props.form.setFieldsValue({ totalAmount: this.props.totalAmount });
    }

    if (this.props.invoiceItems !== this.state.invoiceItems) {
      this.setState({ invoiceItems: this.props.invoiceItems });
    }

    if (this.props.selectedRowKeys !== this.state.selectedRowKeys) {
      this.setState({ selectedRowKeys: this.props.selectedRowKeys });
    }
  }

  componentDidMount() {}

  componentWillMount() {}

  handleConfirmBlur = (e) => {};

  handleChange = (value) => {
    let change = value - this.props.form.getFieldValue('totalAmount');

    if (change < 0) {
      this.props.form.setFieldsValue({
        change: change,
      });
    } else {
      this.props.form.setFieldsValue({
        change: this.formatAmt(change),
      });
    }
  };

  handleChangePaymentType = (e) => {
    this.setState({ paymentType: e.target.description });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ loading: true });

        values.waiveDate = moment(new Date(values.waiveDate)).format(
          'YYYY-MM-DD'
        );

        for (let i = 0; i < this.state.invoiceItems.length; i++) {
          client
            .service('waives')
            .create({
              ...values,
              propertyId: this.props.commons.selectedCompany._id,
              propertyunitId: this.props.propertyunitId,
              invoiceItemId: this.state.invoiceItems[i]._id,
              waiveAmt: this.state.invoiceItems[i].appliedAmount,
              waiveDate: values.waiveDate,
            })
            .then((res) => {
              this.setState({ visible: false });
              this.setState({ loading: false });
              this.props.onRefresh();
            })
            .catch((err) => {
              alert(err);
              this.toggle();
            });
        }

        //----------- Log to refund
      }
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  formatAmt(amt) {
    let tempAmt = amt;

    return tempAmt > 0 ? tempAmt.toFixed(2) : 0.0;
  }

  handleFileUpload = (event) => {
    this.setState({ loading: true });
    let formData = new FormData();

    formData.append('image', event.target.files[0]);

    axios
      .post(`${client.io.io.uri}upload-transactions`, formData, {
        headers: {
          Authorization: client.settings.storage.storage['feathers-jwt'],
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        this.props.form.setFieldsValue({
          file: res.data.imageUrl,
        });
        this.setState({ loading: false });
        message.success('Upload Successful');
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
        message.error(err);
      });
  };

  //loading after submit
  toggle = (value) => {
    this.setState({ loading: false });
  };

  render() {
    const { visible, onCancel } = this.props;
    const { getFieldDecorator } = this.props.form;

    return [
      <Spin key='pageloading' spinning={this.state.loading}>
        <Form className='submit-form' onSubmit={this.handleSubmit}>
          <FormItem key='waiveDate' {...formItemLayout} label='Waive Date'>
            {getFieldDecorator('waiveDate', {
              rules: [
                {
                  required: true,
                  message: 'Please input Waive Date!',
                },
              ],
            })(<DatePicker />)}
          </FormItem>

          <FormItem key='totalAmount' {...formItemLayout} label='Total Amt'>
            {getFieldDecorator('totalAmount', {
              rules: [
                {
                  required: true,
                  message: 'Please input Amount!',
                },
              ],
            })(<Input disabled />)}
          </FormItem>

          <FormItem key='remark' {...formItemLayout} label='Remark'>
            {getFieldDecorator('remark', {
              rules: [
                {
                  required: false,
                  message: 'Please input remark!',
                },
              ],
            })(<TextArea rows={4} />)}
          </FormItem>
          <Button
            type='primary'
            htmlType='submit'
            style={{ float: 'right' }}
            onClick={this.toggle}
          >
            Waive
          </Button>
        </Form>
      </Spin>,
    ];
  }
}

function mapStateToProps(state) {
  return {
    invoices: state.invoices,
    commons: state.commons,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateMode: updateMode,
    },
    dispatch
  );
}

export default requireAuth(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(Form.create()(WaiverFormItems)))
);
