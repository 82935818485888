const _ = require('lodash')
export const FETCH_INVOICES = 'FETCH_INVOICES';
export const PUSH_INVOICES = 'PUSH_INVOICES';
export const REMOVE_INVOICES = 'REMOVE_INVOICES';
export const UPDATE_INVOICES = 'UPDATE_INVOICES';
export const UPDATE_MODE = 'UPDATE_MODE';
export const UPDATE_ACTIVE_ID = 'UPDATE_ACTIVE_ID';
export const UPDATE_INVOICE_COPY = 'UPDATE_INVOICE_COPY';

export function fetchInvoices(data){
  return (dispatch) => {
    dispatch({
      type: FETCH_INVOICES,
      payload:data
    });
  }
}

export function pushInvoices(data){
  return (dispatch) => {
    dispatch({
      type: PUSH_INVOICES,
      payload:data
    });
  }
}

export function removeInvoices(data){
  return (dispatch) => {
    dispatch({
      type: REMOVE_INVOICES,
      payload:data
    });
  }
}

export function updateInvoices(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_INVOICES,
      payload:data
    });
  }
}

export function updateMode(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE,
      payload:data
    });
  }
}

export function updateActiveInvoices(mode, id){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_ID,
      mode,
      id
    });
  }
}

export function updateCopyInvoice(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_INVOICE_COPY,
      payload:data,
    });
  }
}
