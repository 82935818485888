export const FETCH_PROFOMAS = 'FETCH_PROFOMAS';
export const PUSH_PROFOMAS = 'PUSH_PROFOMAS';
export const REMOVE_PROFOMAS = 'REMOVE_PROFOMAS';
export const UPDATE_PROFOMAS = 'UPDATE_PROFOMAS';
export const UPDATE_MODE = 'UPDATE_MODE';
export const UPDATE_ACTIVE_ID = 'UPDATE_ACTIVE_ID';

export function fetchProfomas(data){
  return (dispatch) => {
    dispatch({
      type: FETCH_PROFOMAS,
      payload:data
    });
  }
}

export function pushProfomas(data){
  return (dispatch) => {
    dispatch({
      type: PUSH_PROFOMAS,
      payload:data
    });
  }
}

export function removeProfomas(data){
  return (dispatch) => {
    dispatch({
      type: REMOVE_PROFOMAS,
      payload:data
    });
  }
}

export function updateProfomas(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_PROFOMAS,
      payload:data
    });
  }
}

export function updateMode(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE,
      payload:data
    });
  }
}

export function updateActiveProfomas(mode, id){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_ID,
      mode,
      id
    });
  }
}
