import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
  Select,
  Spin,
  Table,
} from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import { CSVLink } from 'react-csv';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { updateValuePropertyUnitId } from '../../actions/actions-commons';
import {
  fetchInvoices,
  pushInvoices,
  removeInvoices,
  updateActiveInvoices,
  updateInvoices,
  updateMode,
} from '../../actions/actions-invoices';
import { updateActivePage } from '../../actions/app-actions';
import '../../App.css';
import InvoicesForm from '../../components/invoices/form';
import Layout from '../../components/layout';
import requireAuth from '../../components/requireAuth';
import client from '../../feathers';

// ----------------------------------------------------
// Declare Varaible
// ---------------------------------------------------
const FormItem = Form.Item;
const Option = Select.Option;
const _ = require('lodash');
const PAGESIZE = 20;
const text = 'Are you sure to delete this item?';
// ----------------------------------------------------
// main
// ---------------------------------------------------
class InvoicesIndex extends Component {
  constructor(props) {
    super(props);

    // ----------------------------------------------------
    // Declare this.state
    // ---------------------------------------------------
    this.state = {
      typingTimeout: 0,
      loading: false,
      totalPagination: 1,
      searchInvoiceNo: '',
      searchStatus: '',
      searchDate: '',
      searchRefundDate: '',
      searchUnit: '',
      allData: [],
      searchObj: {},
      excelHeader: [
        {
          label: 'Invoice No',
          key: 'invoiceNo',
        },
        {
          label: 'Unit No - Customer Name',
          key: 'unitNoss',
        },
        {
          label: 'Status',
          key: 'status',
        },
        {
          label: 'Date',
          key: 'date',
        },
        {
          label: 'Refund Date',
          key: 'rDate',
        },
        {
          label: 'Amount',
          key: 'totalAmt',
        },
      ],
    };

    this.handleDelete = this.handleDelete.bind(this);
    this._searchInvoiceNoChange = this._searchInvoiceNoChange.bind(this);
    this._searchStatusChange = this._searchStatusChange.bind(this);
    this._searchDateChange = this._searchDateChange.bind(this);
    this._searchRefundDateChange = this._searchRefundDateChange.bind(this);
  }

  componentDidUpdate() {}

  componentDidMount() {
    client.service('invoices').on('created', (invoices) => {
      //this.props.pushInvoices(invoices)
      if (this.props.commons.selectedCompany._id === invoices.propertyId) {
        this.componentWillMount();
      }
    });

    client.service('invoices').on('removed', (invoices) => {
      if (this.props.commons.selectedCompany._id === invoices.propertyId) {
        this.componentWillMount();
      }
    });

    client.service('invoices').on('updated', (invoices) => {
      if (this.props.commons.selectedCompany._id === invoices.propertyId) {
        this.componentWillMount();
      }
    });

    client.service('invoices').on('patched', (invoices) => {
      if (this.props.commons.selectedCompany._id === invoices.propertyId) {
        this.componentWillMount();
      }
    });
  }

  componentWillMount() {
    this.props.updateMode('');
    this.setState({ loading: true });
    const activePage = this.props.activePage.depositPage;

    if (activePage > 1) {
      this.handlePageChange(activePage);
    } else {
      client
        .authenticate()
        .then((res) => {
          return client.service('invoices').find({
            query: {
              $populate: 'propertyunitId',
              propertyId: this.props.commons.selectedCompany._id,
              $limit: 20,
              invoiceType: 'DEPOSIT',
              $sort: {
                invoiceNo: -1,
                createdAt: -1,
              },
            },
          });
        })
        .then((res) => {
          // const newData = newData.concat(res.data);
          this.props.fetchInvoices(res.data);
          this.setState({
            totalPagination: res.total,
            loading: false,
          });
          this._getAllData();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  handleDelete(_id) {
    axios
      .post(
        `${client.io.io.uri}removeTrx`,
        {
          _id: _id,
          companyId: this.props.commons.selectedCompany._id,
          trxType: 'IV',
        },
        {
          headers: {
            Authorization: client.settings.storage.storage['feathers-jwt'],
          },
        }
      )
      .then((res) => {
        message.success('Deleted');
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleEdit(_id) {
    this.props.updateActiveInvoices('edit', _id);
  }

  handleManage(_id) {
    this.props.updateActiveInvoices('', _id);
  }

  handlePageChange = (page) => {
    this.props.updateActivePage({
      depositPage: page,
    });
    this.props.form.validateFields((err, values) => {
      this._getData((page - 1) * 20);
    });
  };

  confirm = () => {
    this.setState({ loading: true });

    client
      .authenticate()
      .then((res) => {
        //
        return client.service('applogs').create({
          remark: 'Recurring Invoice',
          status: 'START',
          propertyId: this.props.commons.selectedCompany._id,
        });
      })
      .then((res) => {
        //
        this.setState({ loading: false });
        message.success('Recurring Invoices Created');
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
        alert(err);
      });
  };

  cancel(e) {
    //message.error('Click on No');
  }

  showModal = () => {
    this.props.updateMode('new');
  };

  renderConfirmDeleteBtn(record) {
    if (record.status === 'PAID-REFUND') {
      return (
        <Button type='danger' disabled>
          Delete
        </Button>
      );
    } else {
      return (
        <Popconfirm
          placement='topLeft'
          title={text}
          onConfirm={() => this.handleDelete(record._id)}
          okText='Confirm'
          cancelText='Cancel'
        >
          <Button type='danger'>Delete</Button>
        </Popconfirm>
      );
    }
  }

  formatAmt(amt) {
    let tempAmt = amt;

    return tempAmt > 0 ? tempAmt.toFixed(2) : 0.0;
  }

  _searchInvoiceNoChange(e) {
    this.setState({
      searchInvoiceNo: e.target.value,
      searchObj: _.merge(
        this.state.searchObj,
        e.target.value
          ? {
              invoiceNo: e.target.value,
            }
          : {
              invoiceNo: '.',
            }
      ),
      typingTimeout: setTimeout(() => {
        this._getData(this.state.skip);
      }, 500),
    });
  }
  _searchUnit = (e) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    let referenceId = e.target.value;
    this.setState({
      searchUnit: referenceId,
      typingTimeout: setTimeout(() => {
        if (referenceId) {
          client
            .authenticate()
            .then(() => {
              return client.service('propertyunits').find({
                query: {
                  orRegex: { referenceId },
                },
              });
            })
            .then((res) => {
              let propertyunitIdList = res.data.map((i) => {
                return i._id.toString();
              });

              this.setState(
                {
                  searchUnit: referenceId,
                  searchPropertyUnitList: {
                    propertyunitId: { $in: propertyunitIdList },
                  },
                },
                () => {
                  this._getData(0);
                }
              );
            })
            .catch((err) => {
              console.log({ err });
            });
        } else {
          this.setState(
            {
              searchUnit: '',
              searchPropertyUnitList: {},
            },
            () => {
              this._getData(0);
            }
          );
        }
      }, 500),
    });
  };

  _searchStatusChange(e) {
    if (e !== 'SHOWALL') {
      this.setState({
        searchStatus: e,
        searchObj: _.merge(
          this.state.searchObj,
          e
            ? {
                status: e,
              }
            : {
                status: '.',
              }
        ),
        typingTimeout: setTimeout(() => {
          this._getData(this.state.skip);
        }, 500),
      });
    } else {
      this.setState({
        searchStatus: '',
        searchObj: _.remove(this.state.searchObj, 'status'),
        typingTimeout: setTimeout(() => {
          this._getData(this.state.skip);
        }, 500),
      });
    }
  }

  _searchDateChange(e) {
    if (e) {
      e = new Date(e);

      this.setState({
        searchDate: e,
        typingTimeout: setTimeout(() => {
          this._getData(this.state.skip);
        }, 500),
      });
    } else {
      this.setState({
        searchDate: '',
        typingTimeout: setTimeout(() => {
          this._getData(this.state.skip);
        }, 500),
      });
    }
  }

  _searchRefundDateChange(e) {
    if (e) {
      e = new Date(e);

      this.setState({
        searchRefundDate: e,
        typingTimeout: setTimeout(() => {
          this._getData(this.state.skip);
        }, 500),
      });
    } else {
      this.setState({
        searchRefundDate: '',
        typingTimeout: setTimeout(() => {
          this._getData(this.state.skip);
        }, 500),
      });
    }
  }

  renderButton(record) {
    if (record.status === 'PAID' || record.status === 'PARTIAL') {
      return (
        <Link
          to={{
            pathname: `/refunds/index-deposit/${record._id}`,
          }}
        >
          <Button
            onClick={() =>
              this.props.updateValuePropertyUnitId(
                'refund',
                record.propertyunitId
              )
            }
            type='primary'
          >
            Refund
          </Button>
        </Link>
      );
    } else if (record.status === 'ISSUED') {
      return (
        <Link
          to={{
            pathname: `/deposit/index-knockoff-by-invoice/${record._id}`,
          }}
        >
          <Button type='primary'>Pay</Button>
        </Link>
      );
    }
  }

  _getAllData() {
    client
      .authenticate()
      .then(() => {
        let searchObj = this.state.searchObj;

        let invoiceDate = '';
        let refundDate = '';

        if (this.state.searchDate) {
          invoiceDate = {
            invoiceDate: {
              $gte: moment(this.state.searchDate).startOf('day'),
              $lte: moment(this.state.searchDate).endOf('day'),
            },
          };
        }

        if (this.state.searchRefundDate) {
          refundDate = {
            refundDate: {
              $gte: moment(this.state.searchRefundDate).startOf('day'),
              $lte: moment(this.state.searchRefundDate).endOf('day'),
            },
          };
        }
        let query = {
          query: {
            propertyId: this.props.commons.selectedCompany._id,
            $populate: 'propertyunitId',
            $sort: {
              invoiceNo: -1,
              createdAt: -1,
            },
            orRegex: this.state.searchObj,
            invoiceType: 'DEPOSIT',
            ...invoiceDate,
            ...refundDate,
          },
        };

        _.assign(query.query, searchObj);

        return client.service('invoices').find(query);
      })
      .then((res) => {
        if (this.state.searchObj && this.state.searchObj.status === 'PAID') {
          let data = res.data.filter((i) => i.status === 'PAID');
          this.setState({
            allData: data,
          });
        } else {
          this.setState({
            allData: res.data,
          });
        }
      });
  }

  _getData(skip) {
    this.setState({ loading: true });
    let { searchPropertyUnitList } = this.state;
    client
      .authenticate()
      .then(() => {
        let searchObj = this.state.searchObj;

        let invoiceDate = '';
        let refundDate = '';

        if (this.state.searchDate) {
          invoiceDate = {
            invoiceDate: {
              $gte: moment(this.state.searchDate).startOf('day'),
              $lte: moment(this.state.searchDate).endOf('day'),
            },
          };
        }

        if (this.state.searchRefundDate) {
          refundDate = {
            refundDate: {
              $gte: moment(this.state.searchRefundDate).startOf('day'),
              $lte: moment(this.state.searchRefundDate).endOf('day'),
            },
          };
        }
        let query = {
          query: {
            propertyId: this.props.commons.selectedCompany._id,
            $populate: 'propertyunitId',
            $sort: {
              invoiceNo: -1,
              createdAt: -1,
            },
            $limit: PAGESIZE,
            $skip: skip,
            orRegex: this.state.searchObj,
            ...searchPropertyUnitList,
            invoiceType: 'DEPOSIT',
            ...invoiceDate,
            ...refundDate,
          },
        };
        if (this.state.searchObj.status === 'PAID' && this.state.searchStatus) {
          query = {
            query: {
              propertyId: this.props.commons.selectedCompany._id,
              $populate: 'propertyunitId',
              $sort: {
                invoiceNo: -1,
                createdAt: -1,
              },
              orRegex: this.state.searchObj,
              ...searchPropertyUnitList,
              invoiceType: 'DEPOSIT',
              ...invoiceDate,
              ...refundDate,
            },
          };
        }

        _.assign(query.query, searchObj);

        return client.service('invoices').find(query);
      })
      .then((res) => {
        if (this.state.searchObj.status === 'PAID') {
          let data = res.data.filter((i) => i.status === 'PAID');
          this.props.fetchInvoices(data);
          this.setState({
            totalPagination: data.length,
          });
        } else {
          this.props.fetchInvoices(res.data);
          this.setState({
            totalPagination: res.total,
          });
        }
        this.setState({ loading: false });
        this._getAllData();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //----------Render Part ---------------------//

  render() {
    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title: (
          <Row gutter={8}>
            <Row>
              <Col span={24}>Deposit No</Col>
            </Row>
            <Row>
              <Col span={24}>
                <Input
                  value={this.state.searchInvoiceNo}
                  onChange={this._searchInvoiceNoChange}
                />
              </Col>
            </Row>
          </Row>
        ),
        dataIndex: 'invoiceNo',
        key: 'invoiceNo',
        width: '10%',
      },
      {
        title: 'Customer Name',
        dataIndex: 'customer',
        key: 'customer',
        width: '10%',
        render: (text, record) => (
          <div>
            {record.propertyunitId
              ? record.propertyunitId.owner
                ? record.propertyunitId.owner.name
                : record.customerId
              : ''}
          </div>
        ),
      },
      {
        title: (
          <Row gutter={8}>
            <Row>
              <Col span={24}>Property Unit</Col>
            </Row>
            <Row>
              <Col span={24}>
                <Input
                  value={this.state.searchUnit}
                  onChange={this._searchUnit}
                />
              </Col>
            </Row>
          </Row>
        ),
        dataIndex: 'propertyunitId.referenceId',
        key: 'referenceId',
        width: '20%',
      },
      {
        title: (
          <Row gutter={8}>
            <Row>
              <Col span={24}>Status</Col>
            </Row>
            <Row>
              <Col span={24}>
                <Select
                  style={{ width: '120px' }}
                  onChange={this._searchStatusChange}
                >
                  <Option key='SHOWALL' value='SHOWALL'>
                    ALL
                  </Option>
                  <Option key='ISSUED' value='ISSUED'>
                    ISSUED
                  </Option>
                  <Option key='PARTIAL' value='PARTIAL'>
                    PARTIAL
                  </Option>
                  <Option key='PAID' value='PAID'>
                    PAID
                  </Option>
                  <Option key='PAID-REFUND' value='PAID-REFUND'>
                    PAID-REFUND
                  </Option>
                </Select>
              </Col>
            </Row>
          </Row>
        ),
        dataIndex: 'status',
        key: 'status',
        width: '10%',
        render: (text, record) => (
          <div>
            {record.status === 'PAID' ? (
              <span
                style={{
                  color: 'green',
                }}
              >
                {record.status}
              </span>
            ) : (
              <span
                style={{
                  color: 'red',
                }}
              >
                {record.status}
              </span>
            )}
          </div>
        ),
      },
      {
        title: (
          <Row gutter={8}>
            <Row>
              <Col span={24}>Date</Col>
            </Row>
            <Row>
              <Col span={24}>
                <DatePicker type='Date' onChange={this._searchDateChange} />
              </Col>
            </Row>
          </Row>
        ),
        dataIndex: 'invoiceDate',
        key: 'invoiceDate',
        width: '10%',
        render: (text, record) => (
          <div>{moment(record.invoiceDate).format('DD-MM-YYYY')}</div>
        ),
      },
      {
        title: (
          <Row gutter={8}>
            <Row>
              <Col span={24}>Refund Date</Col>
            </Row>
            <Row>
              <Col span={24}>
                <DatePicker
                  type='Date'
                  onChange={this._searchRefundDateChange}
                />
              </Col>
            </Row>
          </Row>
        ),
        dataIndex: 'refundDate',
        key: 'refundDate',
        width: '10%',
        render: (text, record) => (
          <span>
            {text ? moment(record.refundDate).format('DD-MM-YYYY') : ''}
          </span>
        ),
      },
      {
        title: (
          <Row>
            <Col style={{ float: 'right' }}>Total (MYR)</Col>
          </Row>
        ),
        dataIndex: 'totalAmt',
        key: 'totalAmt',
        width: '10%',
        render: (text, record) => (
          <div
            style={{
              float: 'right',
            }}
          >
            {this.formatAmt(record.totalAmt)}
          </div>
        ),
      },
      {
        title: 'Action',
        key: 'action',
        width: '40%',
        render: (text, record) => (
          <div>
            <Divider type='vertical' />
            <Link
              to={{
                pathname: `/invoices/view/${record._id}`,
              }}
            >
              <Button type='primary'>Print</Button>
            </Link>
            <Divider type='vertical' />
            {this.renderButton(record)}
            <Divider type='vertical' /> {this.renderConfirmDeleteBtn(record)}
          </div>
        ),
      },
    ];

    var depositList = _.map(this.state.allData, function(item) {
      const unit = _.get(item, 'propertyunitId.unitNo');
      const ownerName = _.get(item, 'propertyunitId.owner.name');
      item.invoiceNo = _.get(item, 'invoiceNo');
      // item.customerName = ownerName
      item.unitNo = unit + ' - ' + ownerName;
      item.status = _.get(item, 'status');
      item.date = item && moment(item.invoiceDate).format('DD-MM-YYYY');
      if (item && !_.isEmpty(item.refundDate)) {
        item.rDate = moment(item.refundDate).format('DD-MM-YYYY');
      } else {
        item.rDate = '-';
      }
      return item;
    });

    return (
      <Spin spinning={this.state.loading}>
        <Layout>
          <Card
            title='Deposit'
            key='cardaction'
            extra={[
              <Link
                key='linktoRefund'
                to={{
                  pathname: `/refunds/index-listing`,
                }}
              >
                <Button
                  type='primary'
                  style={{
                    right: 10,
                  }}
                >
                  View Refund Listing
                </Button>
              </Link>,
              <Divider type='vertical' />,
              <Link
                key='linktoNew'
                to={{
                  pathname: `/deposit/new`,
                }}
              >
                <Button
                  type='primary'
                  style={{
                    right: 10,
                  }}
                >
                  New
                </Button>
              </Link>,
              !_.isEmpty(depositList) ? (
                <CSVLink
                  filename={`${this.props.commons.selectedCompany.name}_Deposit_List.csv`}
                  data={depositList}
                  headers={this.state.excelHeader}
                >
                  <Button type='primary'>Export to CSV</Button>
                </CSVLink>
              ) : null,
            ]}
          >
            <Table
              dataSource={this.props.invoices.invoices}
              columns={columns}
              rowKey='_id'
              pagination={{
                defaultCurrent: this.props.activePage.depositPage,
                total: this.state.totalPagination,
                onChange: this.handlePageChange,
                pageSize: 20,
              }}
              size={'small'}
            />

            <InvoicesForm />
          </Card>
        </Layout>
      </Spin>
    );
  }
}

function mapStateToProps(state) {
  return {
    invoices: state.invoices,
    commons: state.commons,
    activePage: state.app.activePage,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchInvoices: fetchInvoices,
      pushInvoices: pushInvoices,
      removeInvoices: removeInvoices,
      updateActiveInvoices: updateActiveInvoices,
      updateInvoices: updateInvoices,
      updateMode: updateMode,
      updateValuePropertyUnitId: updateValuePropertyUnitId,
      updateActivePage: updateActivePage,
    },
    dispatch
  );
}

export default requireAuth(
  connect(mapStateToProps, mapDispatchToProps)(Form.create()(InvoicesIndex))
);
