import React, { Component, useEffect } from 'react'
import { Modal, Upload,Popconfirm, message, Divider,  Table, Form, Icon, Input, Button, Select } from 'antd'
import client from '../../feathers'
import requireAuth  from "../../components/requireAuth"
import Layout  from "../../components/layout"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateMode } from '../../actions/actions-facilitybookings'

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset:4 },
};

const text = 'Are you sure to delete this item?';

class FacilitybookingsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id:'',
      confirmDirty: false,
      autoCompleteResult: [],
    }
  }
  componentDidUpdate(){
      if(this.props.facilitybookings.activeId !== this.state._id){
        this.setState({
          _id:this.props.facilitybookings.activeId
        })

        if(this.props.facilitybookings.activeFacilitybookings){
          this.props.form.setFieldsValue(
            this.props.facilitybookings.activeFacilitybookings
          )
        }
      }
  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }


  handleSubmit = (e) => {
     e.preventDefault();
     if(this.props.facilitybookings.mode === 'new'){

       this.props.form.validateFields((err, values) => {
         if (!err) {
           client.service('facilitybookings').create({
            'file':values.file.fileList[0].thumbUrl,
            'name':values.name,
            'description':values.description,
            'unit':values.unit,
            'categories':values.categories,
           })
           .then((res)=>{

             this.props.updateMode('')
             message.success('New Facilitybookings Added');
           })
           .catch((err)=>{
            console.log(err);
             this.props.updateMode('')
             alert(err);
           })
         }
       });
     }else{
       this.props.form.validateFields((err, values) => {
         if (!err) {
           client.service('facilitybookings').update(this.props.facilitybookings.activeId,
             values
           )
           .then((res)=>{

             message.success(`Facilitybookings Updated`);
             this.props.updateMode('')
           })
           .catch((err)=>{
            console.log(err);
             
             this.props.updateMode('')
           })
           return;
         }
         
       });
     }
  }



compareToFirstPassword = (rule, value, callback) => {
  const form = this.props.form;
  if (value && value !== form.getFieldValue('password')) {
    callback('Two passwords that you enter is inconsistent!');
  } else {
    callback();
  }
}

validateToNextPassword = (rule, value, callback) => {
  const form = this.props.form;
  if (value && this.state.confirmDirty) {
    form.validateFields(['confirm'], { force: true });
  }
  callback();
}

  render() {
    const { visible=false, onCancel } = this.props;

    const { getFieldDecorator } = this.props.form;


    return(
      <Modal
         visible={
           this.props.facilitybookings.mode==='new'||this.props.facilitybookings.mode==='edit'?true:false
         }
         title={ this.props.facilitybookings.mode === 'edit' ? 'Update Facilitybookings':'Create Facilitybookings' }
         okText={ this.props.facilitybookings.mode === 'edit' ? 'Update':'Create' }
         onCancel={()=>{this.props.updateMode(''); this.props.form.resetFields()} }
         onOk={this.handleSubmit}

      >
        <Form className="submit-form">

          <FormItem
            {...formItemLayout}
            label="Upload"
          >
            {getFieldDecorator('file', {
              getValueFromEvent: this.normFile,
            })(
              <Upload name="logo" action="/upload.do" listType="picture">
                <Button>
                  <Icon type="upload" /> Click to upload
                </Button>
              </Upload>
            )}
          </FormItem>

          <FormItem
             {...formItemLayout}
             label="Name"
           >
             {getFieldDecorator('name', {
               rules: [{
                 required: true, message: 'Please input Facilitybookings Name!',
               }],
             })(
               <Input prefix={<Icon type="idcard" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="SUPERHERO" />
             )}
          </FormItem>

          <FormItem
             {...formItemLayout}
             label="Description"
           >
             {getFieldDecorator('description', {
               rules: [{
                 required: false, message: 'Please input Description!',
               }],
             })(
              <TextArea rows={4} />
             )}
          </FormItem>

          <FormItem
             {...formItemLayout}
             label="Unit"
           >
             {getFieldDecorator('unit', {
               rules: [{
                 required: true, message: 'Please input Unit!',
               }],
             })(
               <Input prefix={<Icon type="tag" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="1" />
             )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Select Categories"
            hasFeedback
          >
            {getFieldDecorator('categories', {
              rules: [
                { required: true, message: 'Please select your categories!' },
              ],
            })(
              <Select placeholder="Please select a categories">
                <Option value="GYM">GYM</Option>
                <Option value="OTHERS">Others</Option>
              </Select>
            )}
          </FormItem>

        </Form>
      </Modal>

    )
  }
}


function mapStateToProps(state) {
  return {
    facilitybookings: state.facilitybookings
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
    }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(FacilitybookingsForm)));
