import React, { Component } from 'react';
import Layout from "../../components/layout";

//import Charts from 'ant-design-pro/lib/Charts';
import { Card, Row, Col, Calendar, Badge, Icon, Avatar, Tabs, Modal, List, message, Spin, Button, Form, Input, } from 'antd';
import { Pie, yuan } from 'ant-design-pro/lib/Charts';
import NumberFormat from 'react-number-format';
//import { Chart, Geom, Axis, Legend, Coord,Tooltip} from 'bizcharts';
import { Route, Link } from 'react-router-dom';
import ComplaintsIndex from "../../components/complaints/index";
import FacilitybookingsIndex from "../../components/facilitybookings/index";
import moment from 'moment';
import client from '../../feathers';
import axios from 'axios';
import Info from "../../components/common/info"
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import requireAuth from "../../components/requireAuth";
import { UserOutlined } from '@ant-design/icons';


//import reqwest from 'reqwest';
//import InfiniteScroll from 'react-infinite-scroller';
const _ = require('lodash');
const { TextArea } = Input;
const { Meta } = Card;
const TabPane = Tabs.TabPane;
const limit = 15;
const ComplaintTypes = {
  "1": 'PUBLIC',
  "2": 'PRIVATE',
  "3": 'SECURITY',
}

class dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      content: '',
      value: moment('2017-01-25'),
      selectedValue: moment('2017-01-25'),
      pendingComplaints: 0,
      processingComplaints: 0,
      solvedComplaints: 0,
      todayVisitor: 0,
      chats: [],
      chatsContent: [],
      addComment: '',
      propertyId: '',
      occupiedUnit: 0,
      vacantUnit: 0,
      activeChat: '',
      totalChatMessageRecord: '',
      previousCount: 0,
      currentPosition: 0,
      userId: '',
      loading: false,
      totalPayment: 0,
      totalKnockOff: 0,
      totalOutstanding: 0,
      totalAccOutstanding: 0,
      totalOPPayment: 0,
      totalOPOutstanding: 0,
      monthlySOI: 0,
      monthlySKO: 0,
      monthlyOutsanding: 0,
      dailySOI: 0,
      dailySKO: 0,
      dailyOutstanding: 0,
      tabPaneTitle: `Daily Summary of ${moment().format('DD/MM/YYYY')}`
    };
  }

  componentDidMount() {

    client.service('chat-messages').on('created', (messages) => {
      if (messages.chatId === this.state.activeChat) {


        this.state.chatsContent.push(messages);

        setTimeout(function () {
          let te = document.getElementById("chatcontent").scrollHeight + 106;
          document.getElementById("chatcontent").scrollTop = te;
        }, 1000);

      }
    })

    client.service('chats').on('patched', (chats) => {


      this.handleChats();

    });

    /*window.onscroll = function() {
      if(window.pageYOffset === 0) {
        alert('I AM AT THE TOP');
      }
    };*/

  }
  getTheNumberOfUnreadMessages = (id, userId, createAt) => {
    let managementId = this.props.user.info.user._id


    return this.getLastActiveDate(id, managementId, createAt).then(lastActiveDate => {
      let createdAtQuery = ''
      if (lastActiveDate) {
        createdAtQuery = { createdAt: { $gt: lastActiveDate } }
      }
      // console.log(createdAtQuery)


      return client.service("chat-messages").find({
        query: {
          $limit: 0,
          chatId: id,
          userId: userId,
          ...createdAtQuery
        }
      }).then(x => {
        return { Id: id, numberOfUnreadMessages: x.total, dateUsedToCalculateUnread: lastActiveDate }
      })
    })

  }
  getLastActiveDate = (id, user, createAt) => {
    // console.log(id, user)


    return client.service('chat-messages').find({
      query: {
        userId: user,
        chatId: id,
        $sort: { createdAt: -1 }
      }
    }).then(res => {
      // console.log(res.data)

      return _.get(res.data, "0.updatedAt", false);
    }).catch(err => { console.log(err) })
  }

  handleScroll = () => {
    if (document.getElementById("chatcontent").scrollTop === 0) {
      //alert('I AM AT THE TOP');

      this.setState({
        currentPosition: document.getElementById("chatcontent").scrollHeight,
        loading: true
      });

      client.authenticate()
        .then((res) => {
          //
          //return client.service('chats').get(_id)
          return client.service('chat-messages').find({
            query: {
              $limit: limit,
              $skip: this.state.totalChatMessageRecord - (limit * this.state.previousCount),
              chatId: this.state.activeChat,
              $populate: ['userId', 'complaintId'],
              /*$sort: {
                createdAt: -1
              }*/
            }

          })
        })
        .then((res) => {
          // console.log(res.data)
          this.setState({
            chatsContent: res.data.concat(this.state.chatsContent),
            previousCount: this.state.previousCount + 1,
            loading: false
          });

          document.getElementById("chatcontent").scrollTop = document.getElementById("chatcontent").scrollHeight - this.state.currentPosition;
        })
        .catch((err) => {
          console.log(err);
        })

    }
  }

  componentWillMount() {


    //count total of vacant unit and occupied unit
    client.authenticate()
      .then((res) => {
        return axios.get(`${client.io.io.uri}count-propertyunits`, {
          params: {
            propertyId: this.props.user.info.propertyId._id,
          },
          headers: {
            'Authorization': client.settings.storage.storage['feathers-jwt']
          }
        })
      })
      .then((res) => {
        this.setState({
          occupiedUnit: res.data.occupied.length,
          vacantUnit: res.data.vacant.length,
        });
      })
      .catch((err) => {
        console.log(err);
      });



    //count total of pending
    client.authenticate()
      .then((res) => {
        //
        return client.service('complaints').find({
          query: {
            propertyId: this.props.user.info.propertyId._id,
            status: 'PENDING',
          }
        })
      })
      .then((res) => {
        this.setState({
          pendingComplaints: res.total
        })
      })
      .catch((err) => {
        console.log(err);
      })
    //count total of processing
    client.authenticate()
      .then((res) => {
        return client.service('complaints').find({
          query: {
            propertyId: this.props.user.info.propertyId._id,
            status: 'PROCESSING',

          }
        })
      })
      .then((res) => {
        //
        this.setState({
          processingComplaints: res.total
        })
      })
      .catch((err) => {
        console.log(err);
      })
    //count total of solved
    client.authenticate()
      .then((res) => {
        return client.service('complaints').find({
          query: {
            propertyId: this.props.user.info.propertyId._id,
            $limit: 1,
            status: 'SOLVED',
            $sort: {
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {
        //
        this.setState({
          solvedComplaints: res.total
        })
      })
      .catch((err) => {
        console.log(err);
      })
    // calculate total of visitor
    var today = new Date();
    var d = today.getDate();
    var m = today.getMonth() + 1;
    var y = today.getFullYear();
    var tod8 = y + '-' + m + '-' + d;
    client.authenticate()
      .then((res) => {
        return client.service('passes').find({
          query: {
            propertyId: res.propertyId,
            visitDate: tod8,
            $sort: {
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {
        //
        this.setState({
          todayVisitor: res.total
        })
      })
      .catch((err) => {
        console.log(err);
      })
    this.handleChats();
    this.getSummaryOfTotalPayment();
    this.getSummaryOfCurrentMonth();
    this.getSummaryofDaily();
  }

  getSummaryOfTotalPayment() {
    client.authenticate()
      .then(async (res) => {
        let sumTotalInvoiceItems = await axios.get(`${client.io.io.uri}sumtotalinvoiceitems`, { //Total Payment
          params: {
            propertyId: this.props.user.info.propertyId._id,
          },
          headers: {
            'Authorization': client.settings.storage.storage['feathers-jwt']
          }
        })

        let sumTotalKnockoffItems = await axios.get(`${client.io.io.uri}sumtotalknockoffitems`, { //Total Received
          params: {
            propertyId: this.props.user.info.propertyId._id,
          },
          headers: {
            'Authorization': client.settings.storage.storage['feathers-jwt']
          }
        })

        let tOutstanding = await axios.get(`${client.io.io.uri}outstanding`, { //Total Outstanding
          params: {
            propertyId: this.props.user.info.propertyId._id,
          },
          headers: {
            'Authorization': client.settings.storage.storage['feathers-jwt']
          }
        })
        let accumulatedOutstanding = await axios.get(`${client.io.io.uri}accumulatedoutstanding`, {//Accumulated Outstanding
          params: {
            propertyId: this.props.user.info.propertyId._id,
          },
          headers: {
            'Authorization': client.settings.storage.storage['feathers-jwt']
          }
        })

        let sumTotalBringFoward = await axios.get(`${client.io.io.uri}sumtotalbringforward`, {
          params: {
            propertyId: this.props.user.info.propertyId._id,
          },
          headers: {
            'Authorization': client.settings.storage.storage['feathers-jwt']
          }
        })
          .then((res) => {// Bring Forward
            const { data } = res
            Object.keys(data).forEach(key => {
              let e = data[key]
              if (e.totalDebit > 0) {
                this.setState({ totalOPOutstanding: e.totalDebit }) //Total Outstanding
              } else {
                this.setState({ totalOPPayment: e.totalCredit }) // Total Prepayment
              }
            });
          })

        this.setState({
          totalPayment: sumTotalInvoiceItems.data.data[0] ? this.formatAmt(sumTotalInvoiceItems.data.data[0].totalPayment) : this.formatAmt(0),
          totalKnockOff: sumTotalKnockoffItems.data.data[0] ? this.formatAmt(sumTotalKnockoffItems.data.data[0].totalKnockOffAmt) : this.formatAmt(0),
          totalOutstanding: tOutstanding.data.data[0] ? this.formatAmt(tOutstanding.data.data[0].outstanding) : this.formatAmt(0),
          totalAccOutstanding: accumulatedOutstanding.data.data[0] ? this.formatAmt(accumulatedOutstanding.data.data[0].accumulatedOutstanding) : this.formatAmt(0)

        })

      })

  }



  getSummaryOfCurrentMonth() {

    client.authenticate()
      .then(async (res) => {

        let monthlySOI = await axios.get(`${client.io.io.uri}accumulatedoutstanding`, { //Total Payment
          params: {
            propertyId: this.props.user.info.propertyId._id,
            currentMonth: true
          },
          headers: {
            'Authorization': client.settings.storage.storage['feathers-jwt']
          }
        })

        let monthlySKO = await axios.get(`${client.io.io.uri}sumtotalknockoffitems`, { //Total Received
          params: {
            propertyId: this.props.user.info.propertyId._id,
            currentMonth: true
          },
          headers: {
            'Authorization': client.settings.storage.storage['feathers-jwt']
          }
        })

        let monthlyOutsanding = await axios.get(`${client.io.io.uri}outstanding`, { //Total Outstanding
          params: {
            propertyId: this.props.user.info.propertyId._id,
            currentMonth: true
          },
          headers: {
            'Authorization': client.settings.storage.storage['feathers-jwt']
          }
        })

        this.setState({
          monthlySOI: monthlySOI.data.data[0] ? this.formatAmt(monthlySOI.data.data[0].accumulatedOutstanding) : this.formatAmt(0),
          monthlySKO: monthlySKO.data.data[0] ? this.formatAmt(monthlySKO.data.data[0].totalKnockOffAmt) : this.formatAmt(0),
          monthlyOutsanding: monthlyOutsanding.data.data[0] ? this.formatAmt(monthlyOutsanding.data.data[0].outstanding) : this.formatAmt(0),
        })

      })


  }

  getSummaryofDaily() {
    client.authenticate()
      .then(async (res) => {

        let dailySOI = await axios.get(`${client.io.io.uri}accumulatedoutstanding`, { //Total Payment
          params: {
            propertyId: this.props.user.info.propertyId._id,
            daily: true
          },
          headers: {
            'Authorization': client.settings.storage.storage['feathers-jwt']
          }
        })

        let dailySKO = await axios.get(`${client.io.io.uri}sumtotalknockoffitems`, { //Total Received
          params: {
            propertyId: this.props.user.info.propertyId._id,
            daily: true
          },
          headers: {
            'Authorization': client.settings.storage.storage['feathers-jwt']
          }
        })

        let dailyOutsanding = await axios.get(`${client.io.io.uri}outstanding`, { //Total Outstanding
          params: {
            propertyId: this.props.user.info.propertyId._id,
            daily: true
          },
          headers: {
            'Authorization': client.settings.storage.storage['feathers-jwt']
          }
        })

        this.setState({
          dailySOI: dailySOI.data.data[0] ? this.formatAmt(dailySOI.data.data[0].accumulatedOutstanding) : this.formatAmt(0),
          dailySKO: dailySKO.data.data[0] ? this.formatAmt(dailySKO.data.data[0].totalKnockOffAmt) : this.formatAmt(0),
          dailyOutsanding: dailyOutsanding.data.data[0] ? this.formatAmt(dailyOutsanding.data.data[0].outstanding) : this.formatAmt(0),
        })

      })

  }

  formatAmt(amt) {
    let tempAmt = amt > 0 ? amt : 0;
    return tempAmt.toFixed(2);
  }

  handleChats = () => {
    // chatting channel
    client.authenticate()
      .then((res) => {
        this.setState({
          userId: res.user._id
        });
        return client.service('chats').find({
          query: {
            propertyId: res.propertyId,
            channelType: { $nin: ['1'] },

            /*users:{
              //userId:{$in: [this.props.match.params.id]},
              $elemMatch: { userId: this.state.userId },
            },*/
            $populate: 'lastMessage.chatMessageId lastMessage.userId propertyUnitId',
            $sort: {
              updatedAt: -1
            }
          }
        })
      })
      .then((res) => {
        // console.log(res.data)
        this.setState({ chats: res.data })

        Promise.all(res.data.map(x => this.getTheNumberOfUnreadMessages(x._id, x.userId, x.createdAt))).then((result) => {
          let newChat = _.map(res.data, function (a, i) {
            if (a._id === result[i].Id) {
              a.numberOfUnreadMessages = result[i].numberOfUnreadMessages
            }
            return a
          })

          this.setState({ chats: newChat })


        })

        //this.props.fetchFacilities(res.data)
      })
      .catch((err) => {
        console.log(err);
      })
  }

  showModal = (value) => {
    this.setState({
      visible: true,
      value,
      selectedValue: value,
    });

  }

  handleOk = (e) => {
    //
    this.setState({
      visible: false,
    });
  }

  handleCancel = (e) => {
    //
    this.setState({
      visible: false,
    });
  }
  patchLastSeenMessage(_id) {

    client.service('chat-messages').find({
      query: {
        $limit: 1,
        userId: this.props.user.info.user._id,
        chatId: _id,
        $sort: { createdAt: -1 }

      }

    }).then((res) => {
      if (!_.isEmpty(res.data)) {
        client.service('chat-messages').patch(res.data[0]._id, { updatedAt: new Date() })

      }
      return;



    })


  }

  handleChatMessage(_id) {
    this.patchLastSeenMessage(_id)
    this.handleChats();

    this.setState({
      activeChat: _id
    });

    // get Details
    client.authenticate()
      .then((res) => {
        //
        //return client.service('chats').get(_id)
        return client.service('chat-messages').find({
          query: {
            chatId: _id,
            $populate: ['userId', 'complaintId'],
            $sort: {
              createdAt: -1
            },
            $limit: limit,
          }

        })
      })
      .then((res) => {

        this.setState({
          chatsContent: res.data.reverse(),
          totalChatMessageRecord: res.total,
          previousCount: 2,
        });

        document.getElementById("chatcontent").scrollTop = document.getElementById("chatcontent").scrollHeight;
      })
      .catch((err) => {
        console.log(err);
      })

  }

  onSubmit = (e) => {
    e.preventDefault();
    if (this.state.activeChat === '') {
      return message.error('No Channel has been select');
    }

    client.authenticate()
      .then((res) => {
        return client.service('chat-messages').create({
          chatId: this.state.activeChat,
          userId: this.props.user.info.user._id,
          type: 'TEXT',    //IMAGE,TEXT,VIDEO,SOUND
          message: this.state.addComment,
          createdBy: this.props.user.info.user._id,
        });
      })
      .then((res) => {
        this.setState({ addComment: '' })
        //   message.success('Comment Added');
      })
      .catch((err) => {
        console.log(err);
        this.setState({ addComment: '' })
        alert(err);
      })

  }

  handleChange = (e) => {
    this.setState({
      addComment: e.target.value,
    });
  }

  _renderDesc(item) {
    // {[item.lastMessage.userId.firstName+':'+item.lastMessage.chatMessageId.message.slice(0, 20)+'...']}
    if (item.lastMessage) {
      let message = ''
      let name = ''
      if (item.lastMessage.userId) {
        name = item.lastMessage.userId.firstName || item.lastMessage.userId.lastName
      }
      return (
        <p>{[name + ':' + item.lastMessage.chatMessage + '...']}</p>
      )
    }
    return (<p>No Message</p>)
  }

  _renderChatAvatar(item) {
    if (item.userId) {
      if (item.userId.avatar) {
        return (
          <Badge count={item.numberOfUnreadMessages}>
            <Avatar size={'large'} src={item.userId.avatar} />
          </Badge>
        )
      }
    }
    return (
      <Badge count={item.numberOfUnreadMessages}>
        <Avatar size={'large'}><Icon type="user" /></Avatar>
      </Badge>
    )
  }

  _renderTitle(item) {

    let unitInfo = ''
    let output = ''
    //if not public
    if (!_.isEmpty(item.propertyId.block)) {
      output = item.propertyId.block + '-'
    }
    if (item.propertyUnitId) {

      unitInfo = output + item.propertyUnitId.floor + '-' + item.propertyUnitId.unitNo
    }
    return (
      <Row gutter={20}>

        <Col span={12}>

          <p>{ComplaintTypes[item.channelType]}</p>
          <p>{unitInfo}</p>
        </Col>
        <Col span={6}>
          <Button type={this.state.activeChat === item._id ? 'primary' : 'dashed'} onClick={() => this.handleChatMessage(item._id)} >{<Icon type="rollback" />}</Button>

        </Col>
      </Row>
    )
  }
  renderType(data) {

    if (data.type === 'COMPLAINT') {
      console.log(data, 'asds')
      let outputData = ''
      try {
        outputData = data.complaintId.description


        return (
          <div>
            <Row>
              <b>Feedbacks</b>
            </Row>
            <Row>
              Subject: {data.complaintId.subject}
            </Row>
            <Row>
              Desc: {data.complaintId.description}
            </Row>
          </div>
        )

      }
      catch (err) {
        console.log(err)
      }

    }
    else {
      return data.message
    }

  }
  onChangeTabPaneMonth = (e) => {
    if (e === 'daily') {
      this.setState({ tabPaneTitle: `Daily Summary of ${moment().format('DD/MM/YYYY')}` })
    }
    else if (e === 'month') {
      this.setState({
        tabPaneTitle: `Month Summary of ${moment().format("MMM'YYYY")}`
      })
    }

  }

  render() {

    const { occupiedUnit, vacantUnit, tabPaneTitle } = this.state
    const propertyPieData = [
      {
        x: 'Occupied Unit',
        y: occupiedUnit,
      },
      {
        x: 'Vacant Unit',
        y: vacantUnit,
      },
    ];

    return (
      <Layout>
        <Row gutter={16}>
          <Col className="gutter-row" span={8}>
            <Card actions={[<Link to={{ pathname: `/invoices` }} >More</Link>]} >
              <Meta title="Total Payment" />
              <br />
              <Row style={{ height: 90 }}>
                <Col className="gutter-row" span={24}>
                  <Tabs defaultActiveKey="1" onChange={() => { }}>
                    <TabPane tab="Current" key="1">
                      <Info label="Invoice Generated" labelSpan={16} value={<span style={{ float: 'right' }} ><NumberFormat thousandSeparator={true} displayType="text" fixedDecimalScale={true} decimalScale={2} value={this.state.totalAccOutstanding} /></span>} valueSpan={8} />
                      <Info label="Payment Collected" labelSpan={16} value={<span style={{ float: 'right' }} ><NumberFormat thousandSeparator={true} displayType="text" fixedDecimalScale={true} decimalScale={2} value={this.state.totalPayment} /></span>} valueSpan={8} />
                      <br />

                      <Info label="Payment Knock-Off" labelSpan={16} value={<span style={{ float: 'right' }} ><NumberFormat thousandSeparator={true} displayType="text" fixedDecimalScale={true} decimalScale={2} value={this.state.totalKnockOff} /></span>} valueSpan={8} />
                      <Info label="UN-Knock-Off" labelSpan={16} value={<span style={{ float: 'right' }} ><NumberFormat thousandSeparator={true} displayType="text" fixedDecimalScale={true} decimalScale={2} value={this.state.totalPayment - this.state.totalKnockOff} /></span>} valueSpan={8} />
                      <br />
                      <Info label="Outstanding" labelSpan={16} value={<span style={{ float: 'right' }} ><NumberFormat thousandSeparator={true} displayType="text" fixedDecimalScale={true} decimalScale={2} value={this.state.totalOutstanding} /></span>} valueSpan={8} />
                    </TabPane>
                    <TabPane tab="Bring Forward" key="2">
                      <Info label="Total Prepayment" labelSpan={16} value={<span style={{ float: 'right' }} ><NumberFormat thousandSeparator={true} displayType="text" fixedDecimalScale={true} decimalScale={2} value={this.state.totalOPPayment} /></span>} valueSpan={8} />
                      <Info label="Total Outstanding" labelSpan={16} value={<span style={{ float: 'right' }} ><NumberFormat thousandSeparator={true} displayType="text" fixedDecimalScale={true} decimalScale={2} value={this.state.totalOPOutstanding} /></span>} valueSpan={8} />
                    </TabPane>
                  </Tabs>

                </Col>
              </Row>
            </Card>
          </Col>

          <Col className="gutter-row" span={8}>
            <Card actions={[<Link to={{ pathname: `/invoices` }} >More</Link>]} >
              <Meta title={tabPaneTitle} />
              <br />
              <Row style={{ height: 90 }}>
                <Col className="gutter-row" span={24}>
                  <Tabs defaultActiveKey="1" onChange={this.onChangeTabPaneMonth}>
                    <TabPane tab="Daily" key='daily'>
                      <Info label="Invoice generated" labelSpan={16} value={<span style={{ float: 'right' }} ><NumberFormat thousandSeparator={true} displayType="text" fixedDecimalScale={true} decimalScale={2} value={this.state.dailySOI} /></span>} valueSpan={8} />
                      <Info label="Payment Collected" labelSpan={16} value={<span style={{ float: 'right' }} ><NumberFormat thousandSeparator={true} displayType="text" fixedDecimalScale={true} decimalScale={2} value={this.state.dailySKO} /></span>} valueSpan={8} />
                      <Info label="Outstanding" labelSpan={16} value={<span style={{ float: 'right' }} ><NumberFormat thousandSeparator={true} displayType="text" fixedDecimalScale={true} decimalScale={2} value={this.state.dailyOutstanding} /></span>} valueSpan={8} />
                      {/* <Info label="Accumulated Outstanding" labelSpan={16} value={<span style={{ float: 'right' }} ><NumberFormat thousandSeparator={true} displayType="text" fixedDecimalScale={true} decimalScale={2} value={this.state.totalAccOutstanding} /></span>} valueSpan={8} /> */}
                    </TabPane>
                    <TabPane tab="Month" key="month">
                      <Info label="Invoice generated" labelSpan={16} value={<span style={{ float: 'right' }} ><NumberFormat thousandSeparator={true} displayType="text" fixedDecimalScale={true} decimalScale={2} value={this.state.monthlySOI} /></span>} valueSpan={8} />
                      <Info label="Payment Collected" labelSpan={16} value={<span style={{ float: 'right' }} ><NumberFormat thousandSeparator={true} displayType="text" fixedDecimalScale={true} decimalScale={2} value={this.state.monthlySKO} /></span>} valueSpan={8} />
                      <Info label="Outstanding" labelSpan={16} value={<span style={{ float: 'right' }} ><NumberFormat thousandSeparator={true} displayType="text" fixedDecimalScale={true} decimalScale={2} value={this.state.monthlyOutsanding} /></span>} valueSpan={8} />
                      {/* <Info label="Accumulated Outstanding" labelSpan={16} value={<span style={{ float: 'right' }} ><NumberFormat thousandSeparator={true} displayType="text" fixedDecimalScale={true} decimalScale={2} value={this.state.totalAccOutstanding} /></span>} valueSpan={8} /> */}
                    </TabPane>
                  </Tabs>

                </Col>
              </Row>
            </Card>
          </Col>
          <Col className="gutter-row" span={6}>
            <Card actions={[<Link to={{ pathname: `/propertyunitsmng` }} >More</Link>]}>
              <Meta title="Property" />
              <br />
              <Row style={{ height: 90 }}>
                <Col className="gutter-row" span={24}>
                  <Info label="Occupied Unit" labelSpan={16} value={<span style={{ float: 'right' }} >{this.state.occupiedUnit}</span>} valueSpan={8} />
                  <Info label="Vacant Unit" labelSpan={16} value={<span style={{ float: 'right' }} >{this.state.vacantUnit}</span>} valueSpan={8} />
                </Col>
              </Row>
            </Card>
          </Col>
          {/* <Col className="gutter-row" span={5}>
            <Card actions={[<Link to={{ pathname: `/passes` }} >More</Link>]} >
              <Meta title="Visitor" />
              <br />
              <Row style={{ height: 90 }}>
                <Col className="gutter-row" span={24}>
                  <Info label="Today Visitor" labelSpan={16} value={<span style={{ float: 'right' }} >{this.state.todayVisitor}</span>} valueSpan={8} />
                </Col>
              </Row>
            </Card>
          </Col> */}
          {/* <Col className="gutter-row" span={5}>
            <Card actions={[<Link to={{ pathname: `/complaints` }} >More</Link>]} >
              <Meta title="Complaints" />
              <br />
              <Row style={{ height: 90 }}>
                <Col className="gutter-row" span={24}>
                  <Info label="Pending" labelSpan={16} value={<span style={{ float: 'right' }} >{this.state.pendingComplaints}</span>} valueSpan={8} />
                  <Info label="Processing" labelSpan={16} value={<span style={{ float: 'right' }} >{this.state.processingComplaints}</span>} valueSpan={8} />
                  <Info label="Solved" labelSpan={16} value={<span style={{ float: 'right' }} >{this.state.solvedComplaints}</span>} valueSpan={8} />
                </Col>
              </Row>

            </Card>
          </Col> */}
        </Row>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Chat Room" key="1">
            <Row>
              <Col span={5} style={{ 'height': 450, 'overflow': 'scroll' }}>
                <List
                  itemLayout="horizontal"
                  dataSource={this.state.chats}
                  renderItem={item => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={this._renderChatAvatar(item)}
                        title={this._renderTitle(item)}
                        description={this._renderDesc(item)}
                      />
                    </List.Item>
                  )}
                />
              </Col>
              <Col span={19} style={{ 'backgroundColor': 'aliceblue' }}>
                <Spin spinning={this.state.loading}>
                  <List
                    itemLayout="horizontal"
                    dataSource={this.state.chatsContent}
                    style={{ 'overflowY': 'scroll', 'height': '300px' }}
                    id='chatcontent'
                    onScroll={this.handleScroll}
                    renderItem={item => (

                      <List.Item style={{ 'margin': '10px' }}>
                        <List.Item.Meta

                          title={<font color={this.state.userId === item.userId._id ? 'green' : 'black'} >{item.userId.firstName}<small>{' (' + moment(new Date(item.createdAt)).format("DD/MMM/YYYY HH:mm") + ')'}</small></font>}
                          style={{ 'textAlign': this.state.userId === item.userId._id ? 'right' : '', 'wordBreak': 'break-word' }}
                          description={this.renderType(item)}
                        />
                      </List.Item>
                    )}
                  />
                </Spin>
                <Form.Item style={{ 'margin': '10px' }}>
                  <TextArea rows={4} onChange={this.handleChange} value={this.state.addComment} placeholder="Text Message here" />
                </Form.Item>
                <Form.Item style={{ 'margin': '10px' }}>
                  <Button htmlType="submit" onClick={this.onSubmit} type="primary" > Add Comment </Button>
                </Form.Item>
              </Col>
            </Row>

          </TabPane>

          <TabPane tab="Bookings" key="2">
            <FacilitybookingsIndex />

          </TabPane>
        </Tabs>
      </Layout>

    )
  }

}


function mapStateToProps(state) {
  return {
    user: state.user
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    // updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)((dashboard)));
