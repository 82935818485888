import React, { Component } from 'react';
import { Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Card, Row, Col, Select, Spin, InputNumber } from 'antd';
import { Route, Link } from 'react-router-dom';

import client from '../../feathers';
import requireAuth from "../requireAuth";
import Layout from "../layout";
import InvoiceitemsForm from "./form";
import PaymentForm from "./form-payment";
import PaymentFormItems from "./form-payment-items";
import axios from 'axios';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchInvoiceitems,
  pushInvoiceitems,
  removeInvoiceitems,
  updateMode,
  updateActiveInvoiceitems,
  updateInvoiceitems
} from '../../actions/actions-invoiceitems';

import '../../App.css';
const _ = require('lodash');
const FormItem = Form.Item;
const Option = Select.Option;
const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);
const EditableFormRow = Form.create()(EditableRow);

const text = 'Are you sure to delete this item?';

class EditableCell extends React.Component {
  state = {
    editing: false,
  }

  componentDidMount() {
    if (this.props.editable) {
      document.addEventListener('click', this.handleClickOutside, true);
    }
  }

  componentWillUnmount() {
    if (this.props.editable) {
      document.removeEventListener('click', this.handleClickOutside, true);
    }
  }

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  }

  handleClickOutside = (e) => {
    const { editing } = this.state;
    if (editing && this.cell !== e.target && !this.cell.contains(e.target)) {
      this.save();
    }
  }

  save = () => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  }

  render() {
    const { editing } = this.state;
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      ...restProps
    } = this.props;
    return (
      <td ref={node => (this.cell = node)} {...restProps}>
        {editable ? (
          <EditableContext.Consumer>
            {(form) => {
              this.form = form;
              return (
                editing ? (
                  <FormItem style={{ margin: 0 }}>
                    {form.getFieldDecorator(dataIndex, {
                      rules: [{
                        required: true,
                        message: `${title} is required.`,
                      }],
                      initialValue: record[dataIndex],
                    })(
                      <Input
                        ref={node => (this.input = node)}
                        onPressEnter={this.save}
                      />
                    )}
                  </FormItem>
                ) : (
                  <div
                    className="editable-cell-value-wrap"
                    style={{ paddingRight: 24 }}
                    onClick={this.toggleEdit}
                  >
                    {restProps.children}
                  </div>
                )
              );
            }}
          </EditableContext.Consumer>
        ) : restProps.children}
      </td>
    );
  }
}

class InvoiceitemsIndexPropertyunit extends Component {
  constructor(props) {
    super(props);

    this.columns = [{
      title: 'Doc Id',
      dataIndex: 'docId',
      width: '15%',
    }, {
      title: 'Description',
      dataIndex: 'description',
      width: '15%',
    }, {
      title: 'Status',
      dataIndex: 'status',
      width: '10%',
    }, {
      title: 'Due Date',
      dataIndex: 'dueDate',
      width: '15%',
      render: (text, record) => (
        <span>
          {record.dueDate ? this.convertDate(record.dueDate) : ''}
        </span>
      ),
    }, {
      title: 'Charge Amount',
      dataIndex: 'appliedAmount',
      width: '15%',
      render: (text, record) => (
        <span>
          {this.formatAmt(record.appliedAmount)}
        </span>
      ),
    }, {
      title: 'Amount Paid',
      dataIndex: 'knockOffBalance',
      width: '15%',
      //editable: true,
      render: (text, record) => (
        <span>
          {this.formatAmt(record.knockOffBalance)}
        </span>
      ),
    }, {
      title: 'KnockOff Amount',
      dataIndex: 'knockOffAmount',
      width: '15%',
      editable: true,
      render: (text, record) => (
        <span>
          {this.formatAmt(record.knockOffAmount)}
        </span>
      ),
    }];

    this.state = {
      invoiceItems: [],
      totalAmount: 0,
      propertyUnit: {},
      loading: false,
      sumOfInvoiceItemsAmount: 0,
      dataSource: [],
      oriDataSource: [],
      selectedRowKeys: [],
      count: 0
    }
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidUpdate() {

  }

  componentDidMount() {
    client.service('invoiceitems').on('created', (invoiceitems) => {
      //this.props.pushInvoiceitems(invoiceitems)
      if (sessionStorage.getItem('companyId') === invoiceitems.propertyId) {
        this.componentWillMount();
      }

    })

    client.service('invoice-items').on('removed', (invoiceitems) => {
      if (sessionStorage.getItem('companyId') === invoiceitems.propertyId) {
        this.componentWillMount();
      }
    })

    client.service('invoice-items').on('updated', (invoiceitems) => {
      if (sessionStorage.getItem('companyId') === invoiceitems.propertyId) {
        this.componentWillMount();
      }
    })

    client.service('invoice-items').on('patched', (invoiceitems) => {
      if (sessionStorage.getItem('companyId') === invoiceitems.propertyId) {
        this.componentWillMount();
      }
    })
  }

  componentWillMount() {
    let companyId = this.props.commons.selectedCompany._id

    this.setState({ loading: true });
    client.authenticate()
      .then((res) => {

        return client.service('invoice-items').find({
          query: {
            $populate: 'propertyId invoiceId',
            propertyId: companyId,
            propertyunitId: this.props.match.params.id,
            status: { $in: ['ISSUED', 'PARTIAL'] },
            $sort: {
              docId: 1
            }
          }
        })
      })
      .then((res) => {
        this.setState({
          dataSource: res.data,
          loading: false
        });
        this.checkedAllCheckedBox();
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });

    //get property unit info
    client.authenticate()
      .then((res) => {

        return client.service('propertyunits').get(this.props.match.params.id)
      })
      .then((res) => {

        this.setState({ propertyUnit: res });
      })
      .catch((err) => {
        console.log(err);
      });

  }

  checkedAllCheckedBox() {
    let data = [];
    for (let i = 0; i < this.state.dataSource.length; i++) {
      data.push(this.state.dataSource[i]._id);
    }

    setTimeout(() => {
      this.setState({
        selectedRowKeys: data,
      });
      this.countTotalSelectedItem();
      this.checkedAndAssignValue();
    }, 1000);

  }

  checkedAndAssignValue() {

    let dataSource = this.state.dataSource;
    let newData = [];
    for (let i = 0; i < dataSource.length; i++) {
      newData.push({
        ...dataSource[i],
        knockOffAmount: dataSource[i].appliedAmount - dataSource[i].knockOffBalance
      });
    }

    this.setState({ dataSource: newData });
    this.countSelectedKnockOffAmt();
  }

  countSelectedKnockOffAmt() {
    let tempDataSource = this.state.dataSource;
    let tempSelectedKey = this.state.selectedRowKeys;
    let amt = 0;
    for (let i = 0; i < tempSelectedKey.length; i++) {
      let temp = _.find(tempDataSource, { _id: tempSelectedKey[i] });
      amt += parseFloat(temp.knockOffAmount);
    }
    this.setState({ totalAmount: amt.toFixed(2) });
  }

  countTotalSelectedItem() {
    let tempDataSource = this.state.dataSource;
    let tempSelectedKey = this.state.selectedRowKeys;
    let amt = 0;
    for (let i = 0; i < tempSelectedKey.length; i++) {
      let temp = _.find(tempDataSource, { _id: tempSelectedKey[i] });
      amt += temp.appliedAmount - temp.knockOffBalance;
    }

    this.setState({
      sumOfInvoiceItemsAmount: amt,
      totalAmount: amt
    });
  }

  handleDelete(_id) {
    client.service('invoiceitems').remove(_id)
      .then((res) => {
      })
      .catch((err) => {
        message.seccess('Deleted');
        alert(err);
      })
  }

  handleEdit(_id) {
    this.props.updateActiveInvoiceitems('edit', _id)
  }

  handleManage(_id) {
    this.props.updateActiveInvoiceitems('', _id)
  }

  confirm = () => {
    let companyId = this.props.commons.selectedCompany._id

    client.authenticate()
      .then((res) => {

        return client.service('applogs').create({
          remark: 'Recurring Invoice',
          status: 'START',
          propertyId: companyId,
        })
      })
      .then((res) => {

        message.success('Recurring Invoiceitems Created');
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      })

  }

  cancel(e) {

    //message.error('Click on No');
  }


  showModal = () => {
    this.props.updateMode('new')
  }

  renderConfirmDeleteBtn(_id) {
    return (
      <Popconfirm placement="topLeft" title={text} onConfirm={() => this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
        <Button type="danger">Delete</Button>
      </Popconfirm>
    )
  }

  renderSearching() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form
        className="ant-advanced-search-form"
        onSubmit={this.handleSearch}
      >
        <Row gutter={24} style={{ display: 'block' }}>
          <Col span={8}>
            <Form.Item label='Invoice No.'>
              {getFieldDecorator(`invoiceNo`, {
                rules: [{
                  required: false,
                  message: 'Invoice No.!',
                }],
              })(
                <Input placeholder="INV0001" />
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='Date'>
              {getFieldDecorator(`invoiceDate`, {
                rules: [{
                  required: false,
                  message: 'Input Date!',
                }],
              })(
                <Input type='Date' />
              )}
            </Form.Item>
          </Col>
          <Button type="primary" htmlType="submit" style={{ float: 'right' }} >Search</Button>
        </Row>

      </Form>
    )
  }

  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      let companyId = this.props.commons.selectedCompany._id


      client.authenticate()
        .then((res) => {

          return client.service('invoice-items').find({
            query: {
              ...values,
              propertyId: companyId,
              invoiceType: 'RECURRING',
              $sort: {
                invoiceDate: 1
              }
            }
          })
        })
        .then((res) => {

          this.setState({ dataSource: res.data });
          //this.props.fetchInvoiceitems(res.data)
        })
        .catch((err) => {
          console.log(err);
        });

    });
  }

  formatAmt(amt) {
    let integer = parseFloat(amt);
    return integer > 0 ? integer.toFixed(2) : 0.00;
  }

  convertDate(date) {
    let tempDate = new Date(date);
    let d = tempDate.getDate();
    let m = tempDate.getMonth() + 1;
    let y = tempDate.getFullYear();
    return d + '/' + m + '/' + y;
  }


  handleChangeTotalAmt = (e) => {

    this.setState({ totalAmount: e });

    let tempValue = 0;
    let tempAddOn = 0;
    let oriAmt = e;
    let dataSource = this.state.dataSource;
    let newData = [];
    let stopper = false;


    for (let i = 0; i < dataSource.length; i++) {

      let isChecked = _.includes(this.state.selectedRowKeys, dataSource[i]._id);
      if (isChecked) {

        if (stopper === true) {
          dataSource[i].knockOffAmount = 0.00;
        } else if (tempValue + (dataSource[i].appliedAmount - dataSource[i].knockOffBalance) <= e) {
          tempValue = dataSource[i].appliedAmount - dataSource[i].knockOffBalance;
          tempAddOn += dataSource[i].appliedAmount - dataSource[i].knockOffBalance;

          if (tempAddOn >= e) {
            dataSource[i].knockOffAmount = parseFloat(oriAmt);
            stopper = true;
          } else {
            dataSource[i].knockOffAmount = tempValue;
            oriAmt -= dataSource[i].appliedAmount - dataSource[i].knockOffBalance;
          }

        } else {
          dataSource[i].knockOffAmount = e - tempValue;
          stopper = true;
        }

        newData.push(dataSource[i]);

      } else {
        dataSource[i].knockOffAmount = 0.00;
        newData.push(dataSource[i]);
      }

    }

    this.setState({ dataSource: newData });
  }

  renderFooter() {
    return ([
      <Row >
        <Col offset={18} span={3}>
          Total Amount
          <Input style={{ color: 'red' }} value={this.formatAmt(this.state.sumOfInvoiceItemsAmount)} disabled={true} />
        </Col>
        <Col span={3}>
          Total KnockOff
          <InputNumber style={{ color: 'green' }} onChange={this.handleChangeTotalAmt} value={this.state.totalAmount} />
        </Col>
      </Row>
    ])
  }

  handleSave = (row) => {

    const newData = [...this.state.dataSource];
    const index = newData.findIndex(item => row._id === item._id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });

    this.setState({ dataSource: newData });
    this.countSelectedKnockOffAmt();
  }

  onSelectChange = (selectedRowKeys) => {
    let dataSource = this.state.dataSource;
    let newData = [];


    this.setState({ selectedRowKeys });

    for (let i = 0; i < dataSource.length; i++) {

      let isChecked = _.includes(selectedRowKeys, dataSource[i]._id);
      if (isChecked) {
        dataSource[i].knockOffAmount = dataSource[i].appliedAmount - dataSource[i].knockOffBalance;
        newData.push(dataSource[i]);
      } else {
        dataSource[i].knockOffAmount = 0.00;
        newData.push(dataSource[i]);
      }

    }
    this.setState({ dataSource: newData });

    setTimeout(() => {
      this.countTotalSelectedItem();
    }, 500);
  }

  handleAdd = () => {
    const { count, dataSource } = this.state;
    const newData = {
      key: count,
      prepayment: true,
      description: `MNTFEE ${count}`,
      appliedAmount: 0,
      knockOffBalance: 0,
      status: 'ACTIVE',
    };
    this.setState({
      dataSource: [...dataSource, newData],
      count: count + 1,
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { selectedRowKeys, dataSource } = this.state;

    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };
    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;
    return (
      <Layout>
        <Spin spinning={this.state.loading} >
          <Card
            title={this.state.propertyUnit.referenceId}
          >
            {/*this.renderSearching()*/}
            <Button onClick={this.handleAdd} type="info" style={{ marginBottom: 16 }}>
              Add a row
                </Button>
            <span style={{ marginLeft: 8 }}>
              {hasSelected ? `Selected ${this.state.selectedRowKeys.length} items` : ''}
            </span>
            <Row>
              <Col span={16}>
                <Table
                  rowSelection={rowSelection}
                  components={components}
                  rowClassName={() => 'editable-row'}
                  scroll={{ y: 440 }}
                  bordered
                  //dataSource={this.props.invoiceitems.invoiceitems}
                  dataSource={dataSource}
                  rowKey="_id"
                  columns={columns}
                  pagination={false}
                  footer={() => this.renderFooter()}
                  size='middle'
                />
              </Col>
              <Col span={7} offset={1}>
                <PaymentFormItems selectedRowKeys={this.state.selectedRowKeys} invoiceItems={this.state.dataSource} totalAmount={this.state.totalAmount} propertyunitId={this.props.match.params.id} />
              </Col>
            </Row>

            {/* <InvoiceitemsForm /> */}
          </Card>
        </Spin>
      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    invoiceitems: state.invoiceitems,
    commons: state.commons
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchInvoiceitems: fetchInvoiceitems,
    pushInvoiceitems: pushInvoiceitems,
    removeInvoiceitems: removeInvoiceitems,
    updateActiveInvoiceitems: updateActiveInvoiceitems,
    updateInvoiceitems: updateInvoiceitems,
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(InvoiceitemsIndexPropertyunit)));
