import React, { Component } from 'react'
import { Modal, message, Form, Input, Select } from 'antd'
import client from '../../feathers'
import requireAuth from "../../components/requireAuth"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateMode } from '../../actions/managements-actions'

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};


class ManagementsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',
      confirmDirty: false,
      autoCompleteResult: [],
      propertyDropdowns: [],
      malaysiaState: [],
      countries: [],
    }
  }

  getStateParams() {
    client.authenticate()
      .then(() => {
        return client.service('parameters').find({
          query: {
            table: 'MALAYSIA-STATE',
            $sort: {
              VALUE: 1
            }
          }
        })
      })
      .then((res) => {

        this.setState({
          malaysiaState: res.data[0].items
        })
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getCountriesParams() {
    client.authenticate()
      .then(() => {
        return client.service('parameters').find({
          query: {
            table: 'COUNTRIES',
            $sort: {
              VALUE: 1
            }
          }
        })
      })
      .then((res) => {

        this.setState({
          countries: res.data[0].items
        })
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getPropertiesDropdown() {
    client.authenticate()
      .then((res) => {

        return client.service('properties').find({
          query: {
            $sort: {
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {

        this.setState({
          propertyDropdowns: res.data,
        })
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          propertyDropdowns: []
        })
      });
  }

  componentDidUpdate() {
    if (this.props.managements.activeId !== this.state._id) {
      this.setState({
        _id: this.props.managements.activeId
      })

      if (this.props.managements.activeManagement) {
        this.props.form.setFieldsValue(
          this.props.managements.activeManagement
        )
      }
    }

  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }


  handleSubmit = (e) => {
    e.preventDefault();
    if (this.props.managements.mode === 'new') {

      this.props.form.validateFields((err, values) => {
        if (!err) {
          client.service('managements').create(values)
            .then((res) => {
              this.props.updateMode('')
              message.success('New Management added');
            })
            .catch((err) => {
              this.props.updateMode('')
              alert(err);
            })
        }
      });
    } else {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          client.service('managements').patch(this.props.managements.activeId,
            values
          )
            .then((res) => {
              message.success(`Management updated`);
              this.props.updateMode('')
            })
            .catch((err) => {

              this.props.updateMode('')
            })
          return;
        }

      });
    }
  }

  componentWillMount() {

    this.getPropertiesDropdown();
    this.getStateParams();
    this.getCountriesParams();

  }

  render() {

    const { getFieldDecorator } = this.props.form;


    return (
      <Modal
        visible={
          this.props.managements.mode === 'new' || this.props.managements.mode === 'edit' ? true : false
        }
        title={this.props.managements.mode === 'edit' ? 'Update Management' : 'Create Management'}
        okText={this.props.managements.mode === 'edit' ? 'Update' : 'Create'}
        onCancel={() => { this.props.updateMode(''); this.props.form.resetFields() }}
        onOk={this.handleSubmit}

      >
        <Form className="submit-form">

          <FormItem
            {...formItemLayout}
            label="Company Name"
          >
            {getFieldDecorator('name', {
              rules: [{
                required: true, message: 'Please input Management Name!',
              }],
            })(
              <Input placeholder="ABC Management" />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Select Property"
            hasFeedback
          >
            {getFieldDecorator('propertyId', {
              rules: [
                { required: true, message: 'Please Assign Property!' },
              ],
            })(
              <Select placeholder="Please select property">
                {this.state.propertyDropdowns.map(dropdown => (
                  <Option value={dropdown._id}>
                    {dropdown.name}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Address line 1"
          >
            {getFieldDecorator('address1', {
              rules: [{
                required: true, message: 'Please input Address line 1!',
              }],
            })(
              <Input placeholder="Sakura-08-08, Jln Spiderman" />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Address line 2"
          >
            {getFieldDecorator('address2', {
              rules: [{
                required: false, message: 'Please input Address line 2!',
              }],
            })(
              <Input placeholder="Tmn X-man, Ipoh Utara" />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Post Code"
          >
            {getFieldDecorator('postCode', {
              rules: [{
                required: true, message: 'Please input Post Code!',
              }],
            })(
              <Input placeholder="52200" />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="State"
          >
            {getFieldDecorator('state', {
              rules: [{
                required: true, message: 'Please input State!',
              }],
            })(
              <Select placeholder="Select state">
                {this.state.malaysiaState.map(dropdown => (
                  <Option value={dropdown.value}>
                    {dropdown.description}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Select Country"
            hasFeedback
          >
            {getFieldDecorator('country', {
              rules: [
                { required: true, message: 'Please select your country!' },
              ],
            })(
              <Select placeholder="Select country">
                {this.state.countries.map(dropdown => (
                  <Option value={dropdown.value}>
                    {dropdown.description}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Contact Number"
          >
            {getFieldDecorator('mobile', {
              rules: [{
                required: true, message: 'Please input Contact Number!',
              }],
            })(
              <Input placeholder="012-345678" />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Fax Number"
          >
            {getFieldDecorator('fax', {
              rules: [{
                required: false, message: 'Please input Fax Number!',
              }],
            })(
              <Input placeholder="03-12345678" />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="E-mail"
          >
            {getFieldDecorator('email', {
              rules: [{
                type: 'email', message: 'The input is not valid E-mail!',
              }, {
                required: true, message: 'Please input your E-mail!',
              }],
            })(
              <Input placeholder="Email" />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Registration Number"
          >
            {getFieldDecorator('ssm', {
              rules: [{
                required: true, message: 'Please input Registration Number No.!',
              }],
            })(
              <Input placeholder="105015-K" />
            )}
          </FormItem>

        </Form>
      </Modal>

    )
  }
}


function mapStateToProps(state) {
  return {
    managements: state.managements
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(ManagementsForm)));
