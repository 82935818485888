import { Form, Input, message, Select, InputNumber, DatePicker } from 'antd';
import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateMode, updateActiveReceipts } from '../../actions/actions-receipts';
import requireAuth from "../../components/requireAuth";
import client from '../../feathers';
import moment from 'moment';

const FormItem = Form.Item;
const _ = require('lodash');
const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

class FormCN extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',
      confirmDirty: false,
      autoCompleteResult: [],
      accountAssetsDropdowns: [],
      accountExpensesDropdowns: [],
      suppliers: [],
      newMerchant: '',
      loading: false,
      subTotalAmount: 0,
      taxAmount: 0,
      totalAmount: 0,
      creditledgerAccountId: '',
      creditledgerAccountName: '',
      receiptItems: [],
      creditAmount: 0
    }
  }

  componentDidUpdate() {

    if (this.props.activeItem._id !== this.state._id) {
      this.setState({
        _id: this.props.activeItem._id,
        activeItem: this.props.activeItem,
        supplier: this.props.currentSupplier,
        receiptItems: this.props.receiptItems

      })

      if (!_.isEmpty(this.props.activeItem)) {
        this.props.form.setFieldsValue({
          date: this.props.activeItem.date,
          amount: this.props.activeItem.amount,
          debitAccountName: this.props.activeItem.debitAccountName,
          totalPay: this.props.activeItem.totalPay,
        })
      }

      if (!_.isEmpty(this.props.currentSupplier)) {
        this.props.form.setFieldsValue({
          supplier: this.props.currentSupplier.name
        })
      }
    }
    if (this.props.receipts.mode === 'createcn') {
      this.handleSubmit()
      this.props.updateActiveReceipts('edit', this.props.parentReceipt._id)
    }

  }
  componentDidMount() {
    if (!_.isEmpty(this.props.activeItem)) {
      this.props.form.setFieldsValue({
        date: this.props.activeItem.date,
        amount: this.props.activeItem.amount,
        debitAccountName: this.props.activeItem.debitAccountName,
        totalPay: 0,
      })
    }

    if (!_.isEmpty(this.props.currentSupplier)) {
      this.props.form.setFieldsValue({
        supplier: this.props.currentSupplier.name
      })
    }
  }

  handleSubmit() {
    // e.preventDefault();
    let { activeItem, receiptItems } = this.state
    this.props.form.validateFields((err, values) => {
      let newTotal = 0
      if (this.state.creditAmount > values.amount) {
        return message.error('Credit Amount cannot greater than then original amount')
      }

      values.date = moment(values.date1).format('YYYY-MM-DD')
      values.totalAmount = values.creditAmount
      values.amount = values.creditAmount

      for (let i = 0; i < receiptItems.length; i++) {

        if (receiptItems[i].receiptType === "1") {
          receiptItems[i].amount = -(receiptItems[i].amount)
        }

        newTotal += parseFloat(receiptItems[i].amount)
      }

      if (!err) {
        const fieldValues = {
          ...values,
          receiptId: this.props.parentReceipt._id,
          receiptItemId: this.props.activeItem._id,
          receiptType: "1", status: "CLOSED", propertyId: this.props.commons.selectedCompany._id,
          supplierId: this.props.currentSupplier._id,
          debitledgerAccountId: this.props.activeItem.debitledgerAccountId,
          creditledgerAccountId: this.props.currentSupplier.ledgerAccountId,
          receiptNo: this.props.parentReceipt.receiptNo,
          sortOrder: this.props.receiptItems.length,
          knockOffAmount: 0,
          totalPay: 0,
        }

        client.service('receipts').patch(this.props.parentReceipt._id, {
          totalAmount: newTotal - parseFloat(values.creditAmount)
        })
        client.service('receipt-items').create(fieldValues)
          .then(() => {
            message.success(`Credit Notes Created`);
            this.props.updateActiveReceipts('refresh', this.props.parentReceipt._id)
          })
          .catch((err) => {
            console.log(err);

            this.props.updateMode('')
          })
        return;
      }
    });
  }

  handleAmtChange = (v) => {
    let { activeItem } = this.state
    let tempArr = []

    if (_.isArray(activeItem)) {
      tempArr = activeItem
    } else {
      tempArr.push(activeItem)
    }
    tempArr[0]['updatedAmount'] = parseFloat(v)

    this.setState({ activeItem: tempArr, creditAmount: parseFloat(v) })
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form className="submit-form">
        <FormItem
          {...formItemLayout}
          label="Supplier"
        >
          {getFieldDecorator('supplier')(<Input disabled />)}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Invoice Date"
        >
          {getFieldDecorator('date')(<Input disabled />)}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Expenses Category"
        >
          {getFieldDecorator('debitAccountName')(<Input disabled />)}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Amount (RM)"
        >
          {getFieldDecorator('amount')(<Input disabled />)}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Amount Paid (RM)"
        >
          {getFieldDecorator('totalPay')(<Input disabled />)}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Credit Amount (RM)"
        >
          {getFieldDecorator('creditAmount', {
            rules: [{
              required: true, message: 'Please credit amount!',
            }],
          })(
            <InputNumber onChange={(e) => { this.handleAmtChange(e) }} />
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Remark"
        >
          {getFieldDecorator('remark', {
            rules: [{
              required: true, message: 'Please fill in remark!',
            }],
          })(
            <Input />
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Transaction Date"
        >
          {getFieldDecorator('date1', {
            rules: [{
              required: true, message: 'Please input date!',
            }],
          })(
            <DatePicker />
          )}
        </FormItem>
      </Form>
    )
  }
}


function mapStateToProps(state) {
  return {
    receipts: state.receipts,
    commons: state.commons
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode,
    updateActiveReceipts: updateActiveReceipts
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(FormCN)));