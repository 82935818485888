import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Spin,
  Table,
} from 'antd';
import axios from 'axios';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  fetchPropertyunits,
  pushPropertyunits,
  removePropertyunits,
  updateActivePropertyunits,
  updateMode,
  updatePropertyunits,
} from '../../actions/actions-propertyunits';
import Layout from '../../components/layout';
import requireAuth from '../../components/requireAuth';
import client from '../../feathers';

const _ = require('lodash');
const FormItem = Form.Item;
const Option = Select.Option;
const formatter = new Intl.NumberFormat('en-US', {
  //style: 'currency',
  //currency: 'MYR',
  minimumFractionDigits: 2,
});

const PAGESIZE = 20;
const text = 'Are you sure to delete this item?';

class PropertyunitsIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchUnitNumber: '',
      totalPagination: 0,
      loading: true,
      totalAmt: 0,
    };

    this.handleDelete = this.handleDelete.bind(this);
    this._searchUnitNumberChange = this._searchUnitNumberChange.bind(this);
  }

  componentDidUpdate() {
    if (this.props.propertyunits.mode === 'refresh') {
      this.componentWillMount();
    }
  }

  componentDidMount() {}

  _getTotalAmt() {
    client
      .authenticate()
      .then((res) => {
        return client.service('waives').find({
          query: {
            propertyId: this.props.commons.selectedCompany._id,
          },
        });
      })
      .then((res) => {
        console.log('lala', res.data);
        let amt = res.data.map((r) => {
          return r.waiveAmt;
        });
        amt = _.sum(amt);
        this.setState({ totalAmt: amt });
      });
  }

  _getWaiverList() {
    this.setState({ loading: true });
    axios
      .get(`${client.io.io.uri}waiverList`, {
        params: {
          propertyId: this.props.commons.selectedCompany._id,
        },
        headers: {
          Authorization: client.settings.storage.storage['feathers-jwt'],
        },
      })
      .then((res) => {
        const { data } = res;
        if (data) {
          this.setState({ loading: false });
          this.props.fetchPropertyunits(data.data);
        }
      });
  }

  componentWillMount() {
    this.props.updateMode('');
    this.setState({ loading: true });
    this._getTotalAmt();
    this._getWaiverList();
    // client.authenticate()
    //   .then((res) => {
    //     return client.service('propertyunits').find({
    //       query: {
    //         propertyId: this.props.commons.selectedCompany._id,
    //         //$limit: 20,
    //         //$populate:'propertyId',
    //         //propertyId:{_id:res.propertyId},
    //         //propertyId:res.propertyId,
    //         $limit: 20,
    //         $populate: ['ledgerAccountId'],
    //         $sort: {
    //           referenceId: 1
    //           //createdAt: -1
    //         }
    //       }
    //     })
    //   })
    //   .then((res) => {
    //     this.setState({ totalPagination: res.total, loading: false });
    //     // this.props.fetchPropertyunits(res.data)

    //   })
    //   .catch((err) => {
    //     this.setState({ loading: false });
    //     console.log(err);
    //   })
  }

  handleDelete(_id) {
    client
      .service('propertyunits')
      .remove(_id)
      .then((res) => {})
      .catch((err) => {
        alert(err);
      });
  }

  handleEdit(_id) {
    this.props.updateActivePropertyunits('edit', _id);
  }

  handleManage(_id) {
    this.props.updateActivePropertyunits('', _id);
  }

  showModal = () => {
    this.props.updateMode('new');
  };

  formatAmt(amt) {
    let integer = parseFloat(amt);
    return integer > 0 ? integer.toFixed(2) : 0.0;
  }

  renderConfirmDeleteBtn(_id) {
    return (
      <Popconfirm
        placement='topLeft'
        title={text}
        onConfirm={() => this.handleDelete(_id)}
        okText='Confirm'
        cancelText='Cancel'
      >
        <Button type='danger'>Delete</Button>
      </Popconfirm>
    );
  }

  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      client
        .authenticate()
        .then((res) => {
          //
          return client.service('propertyunits').find({
            query: {
              ...values,
              propertyId: this.props.commons.selectedCompany._id,
              $populate: 'ledgerAccountId',
              $sort: {
                referenceId: 1,
                //createdAt: -1
              },
            },
          });
        })
        .then((res) => {
          this.props.fetchPropertyunits(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  handlePageChange = (page) => {
    this.setState({
      CurrentPagination: page,
    });
    // this.props.form.validateFields((err, values) => {
    //   client.authenticate()
    //     .then((res) => {
    //       //
    //       return client.service('propertyunits').find({
    //         query: {
    //           propertyId: this.props.commons.selectedCompany._id,
    //           $limit: 20,
    //           $skip: (page - 1) * 20,
    //           $populate: 'ledgerAccountId',
    //           $sort: {
    //             referenceId: 1
    //             //createdAt: -1
    //           }
    //         }
    //       })
    //     })
    //     .then((res) => {
    //       //
    //       this.setState({ totalPagination: res.total });
    //       this.props.fetchPropertyunits(res.data)
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     })
    // });
    this._getWaiverList();
  };

  renderSearching() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form className='ant-advanced-search-form' onSubmit={this.handleSearch}>
        <Row gutter={24} style={{ display: 'block' }}>
          <Col span={8}>
            <Form.Item label='Unit Number'>
              {getFieldDecorator(`unitNo`, {
                rules: [
                  {
                    required: false,
                    message: 'Unit Number!',
                  },
                ],
              })(<Input placeholder='B-1-2' />)}
            </Form.Item>
          </Col>

          <Button type='primary' htmlType='submit' style={{ float: 'right' }}>
            Search
          </Button>
        </Row>
      </Form>
    );
  }

  _Field_TotalBillAmount(record) {
    try {
      if (
        record.ledgerAccountId.balance < 0 ||
        typeof record.ledgerAccountId.balance == 'undefined'
      ) {
        return (
          <span style={{ color: 'red', float: 'right' }}>
            {formatter.format(
              record.ledgerAccountId.balance
                ? record.ledgerAccountId.balance
                : 0
            )}
          </span>
        );
      } else {
        return (
          <span style={{ float: 'right' }}>
            {record.ledgerAccountId.balance
              ? formatter.format(record.ledgerAccountId.balance)
              : formatter.format(0.0)}{' '}
          </span>
        );
      }
    } catch (e) {
      return <span style={{ float: 'right' }}>{formatter.format(0.0)} </span>;
    }
  }

  _searchUnitNumberChange(e) {
    this.setState({
      searchUnitNumber: e.target.value,
      searchObj: _.merge(
        this.state.searchObj,
        e.target.value ? { referenceId: e.target.value } : { referenceId: '.' }
      ),
      typingTimeout: setTimeout(() => {
        this._getData(this.state.skip);
      }, 500),
    });
  }

  _getData(skip) {
    client
      .authenticate()
      .then(() => {
        let searchObj = this.state.searchObj;
        let query = {
          query: {
            propertyId: this.props.commons.selectedCompany._id,
            $populate: 'ledgerAccountId',
            $sort: {
              referenceId: 1,
            },
            orRegex: this.state.searchObj,
          },
        };
        _.assign(query.query, searchObj);
        return client.service('propertyunits').find(query);
      })

      .then((res) => {
        let allUnit = [];
        allUnit = res.data.map((i) => {
          return i._id;
        });
        axios
          .get(`${client.io.io.uri}waiverList`, {
            params: {
              propertyId: this.props.commons.selectedCompany._id,
              propertyunitId: allUnit,
            },
            headers: {
              Authorization: client.settings.storage.storage['feathers-jwt'],
            },
          })
          .then((x) => {
            const { data } = x;
            if (data) {
              this.setState({ loading: false });
              this.props.fetchPropertyunits(data.data);
            }
          });
        // this.props.fetchPropertyunits(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //---------------Render Part-----------------------------//
  render() {
    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title: (
          <Row gutter={8}>
            <Row>
              <Col span={24}>Unit Number</Col>
            </Row>
            <Row>
              <Col span={24}>
                <Input
                  value={this.state.searchUnitNumber}
                  onChange={this._searchUnitNumberChange}
                />
              </Col>
            </Row>
          </Row>
        ),

        dataIndex: 'propertyUnitNo',
        key: 'propertyUnitNo',
        width: '25%',
      },
      {
        title: 'Waived Amount',
        dataIndex: 'waivedAmount',
        key: 'waivedAmount',
        width: '25%',
        render: (text, record) => {
          return <span>{this.formatAmt(record.waivedAmount)}</span>;
        },
      },
      {
        title: 'Balance',
        dataIndex: 'totalBalance',
        key: 'totalBalance',
        width: '25%',
        render: (text, record) => (
          <span>{this.formatAmt(record.totalBalance)}</span>
        ),
      },
      {
        title: 'Action',
        key: 'action',
        width: '25%',
        render: (text, record) => (
          <span>
            <Divider type='vertical' />
            <Link
              to={{
                pathname: `/waiver/${record.propertyunitId}`,
              }}
            >
              <Button>Waiver</Button>
            </Link>
          </span>
        ),
      },
    ];

    return (
      <Layout>
        <Spin spinning={this.state.loading}>
          <Card title='Property Unit Info'>
            {/*<Button type="primary" onClick={this.showModal}>New Units</Button>*/}
            {/* {this.renderSearching()} */}

            <Table
              dataSource={this.props.propertyunits.propertyunits}
              columns={columns}
              rowKey='_id'
              pagination={{
                defaultCurrent: 1,
                total: this.state.totalPagination,
                // onChange: this.handlePageChange,
                pageSize: 20,
                size: 'small',
              }}
              footer={() => {
                return (
                  <span style={{ fontWeight: 'bold' }}>
                    Total Amount : {this.formatAmt(this.state.totalAmt)}
                  </span>
                );
              }}
            />
          </Card>
        </Spin>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    propertyunits: state.propertyunits,
    commons: state.commons,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchPropertyunits: fetchPropertyunits,
      pushPropertyunits: pushPropertyunits,
      removePropertyunits: removePropertyunits,
      updateActivePropertyunits: updateActivePropertyunits,
      updatePropertyunits: updatePropertyunits,
      updateMode: updateMode,
    },
    dispatch
  );
}

export default requireAuth(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Form.create()(PropertyunitsIndex))
);
