import _ from 'lodash';
import {
  FETCH_CREDITNOTES,
  PUSH_CREDITNOTES,
  REMOVE_CREDITNOTES,
  UPDATE_CREDITNOTES,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID } from '../actions/actions-creditnotes';

const INITIAL_STATE = {
  creditnotes: [],
  mode: 'index',
  activeId: '',
  activeCreditNotes:{}
}


export default function(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_CREDITNOTES:
      return {
        ...state,
        creditnotes:action.payload,
      }
    case PUSH_CREDITNOTES:
      return {
        ...state,
        creditnotes:[action.payload, ...state.creditnotes],
      }
    case REMOVE_CREDITNOTES:
      return {
        ...state,
        creditnotes : [...state.creditnotes.filter( (item) => item._id !== action.payload._id)]
      }
    case UPDATE_CREDITNOTES:
      let index =  _.findIndex(state.creditnotes, { '_id': action.payload._id })
      state.creditnotes.splice(index, 1, action.payload)
      return {
        ...state,
        creditnotes : state.creditnotes
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode : action.payload,
        activeId:''
      }
    case UPDATE_ACTIVE_ID:
      return {
        ...state,
        mode : action.mode,
        activeId:action.id,
        activeCreditNotes: _.pick(_.find(state.creditnotes, { '_id': action.id }), ['propertyId','propertyunitId','invoiceNo','invoiceDate','dtledgerAccountId','ctledgerAccountId','creditAmount','trxDate','remark','referenceId','updatedBy','createdBy','trandcode','_id'])
      }
    default:
      return state

  }
}
