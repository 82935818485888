import {
  Button,
  Card, Col, Divider, Form,
  Icon,
  Input, message, Popconfirm, Popover, Row, Select,
  Spin, Table
} from 'antd';
import axios from 'axios';
import moment from 'moment';
import 'rc-table/assets/index.css';
import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { bindActionCreators } from "redux";
import {
  fetchInvoices,
  pushInvoices,
  removeInvoices, updateActiveInvoices, updateCopyInvoice, updateInvoices, updateMode
} from '../../actions/actions-invoices';
// import 'rc-table/assets/animation.css';
import '../../App.css';
import InvoicesForm from "../../components/invoices/form";
import Layout from "../../components/layout";
import requireAuth from "../../components/requireAuth";
import client from '../../feathers';


const FormItem = Form.Item;
const Option = Select.Option;
const _ = require('lodash');
const PAGESIZE = 10;
const text = 'Are you sure to delete this item?';

class InvoicesIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      typingTimeout: 0,
      loading: false,
      totalPagination: 0,
      searchInvoiceNo: '',
      searchStatus: '',
      searchDate: '',
      searchPropertyUnit: '',
      searchCustomer: '',
      searchPropertyUnitList: {},
      loading: false
    }

    this.handleDelete = this.handleDelete.bind(this);
    this._searchInvoiceNoChange = this._searchInvoiceNoChange.bind(this);
    this._searchStatusChange = this._searchStatusChange.bind(this);
    this._searchDateChange = this._searchDateChange.bind(this);
    this._searchPropertyUnitChange = this._searchPropertyUnitChange.bind(this);
    this._searchCustomerChange = this._searchCustomerChange.bind(this);
  }

  componentWillMount() {
    this.props.updateCopyInvoice()
    this.props.updateMode('')
    this.props.updateMode('')
    this._getData(0)
  }

  _getSingleRec(data) {
    client.authenticate()
      .then((res) => {
        return client.service('invoices').find({
          query: {
            isDebitNote: true,
            _id: data._id,
            $populate: 'propertyunitId',
            $limit: 1,
          }
        })
      })
      .then((res) => {
        this.props.pushInvoices(res.data[0])
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      })
  }

  _getData(skip) {

    let companyId = this.props.commons.selectedCompany._id

    this.setState({ loading: true });
    let { searchPropertyUnitList } = this.state
    client.authenticate()
      .then((res) => {
        return client.service('invoices').find({
          query: {
            isDebitNote: true,
            $populate: 'propertyunitId',
            propertyId: companyId,
            $limit: PAGESIZE,
            $skip: skip,
            invoiceType: { $nin: ['DEPOSIT'] },
            $sort: {
              invoiceDate: -1,
              invoiceNo: 1,
              // propertyunitId: 1,
            },
            orRegex: this.state.searchObj,
            ...searchPropertyUnitList,
          }
        })
      })
      .then((res) => {
        // console.log({res});
        this.props.fetchInvoices(res.data);
        this.setState({
          totalPagination: res.total,
          loading: false
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        // console.log(err);
      })
  }

  handleDelete(_id) {
    this.setState({ loading: true });
    axios.post(`${client.io.io.uri}removeTrx`, {
      _id: _id,
      companyId: this.props.commons.selectedCompany._id,
      trxType: 'IV'
    }, {
      headers: {
        'Authorization': client.settings.storage.storage['feathers-jwt']
      }
    })
      .then((res) => {
        this.setState({ loading: false });
        message.success('Deleted');
        this.props.removeInvoices({ _id })
      }).catch((err) => {
        this.setState({ loading: false });
        console.log({ err });
      })
  }

  handleEdit(_id) {
    this.props.updateActiveInvoices('edit', _id)
  }

  handleManage(_id) {
    this.props.updateActiveInvoices('', _id)
  }

  _handlePageChange(pagination) {
    this._getData((pagination.current * PAGESIZE) - PAGESIZE)
  }

  showModal = () => {
    this.props.updateMode('new')
  }

  renderConfirmDeleteBtn(rc) {
    let userRole = this.props.commons.selectedRole
    if (userRole === 'authoriser') {
      if (rc.status !== 'PAID' && rc.status !== 'PARTIAL') {
        return (
          <React.Fragment>
            <Divider type="vertical" />
            <Popconfirm placement="topLeft" title={text} onConfirm={() => this.handleDelete(rc._id)} okText="Confirm" cancelText="Cancel">
              <Button type="danger">Delete</Button>
            </Popconfirm>
          </React.Fragment>
        )
      }

    }

  }

  renderPayBtn(rc) {
    if (rc.status !== 'PAID') {
      return (
        <React.Fragment>
          <Divider type="vertical" />
          <Link to={{ pathname: `/invoices/index-knockoff-by-invoice/${rc._id}` }}>
            <Button style={{ marginTop: 5, marginBottom: 5 }} type="primary">Pay</Button>
          </Link>
        </React.Fragment>
      )
    }
  }

  renderEditBtn(rc) {
    let userRole = this.props.commons.selectedRole
    if (userRole === 'authoriser') {
      if (rc.status !== 'PAID') {
        return (
          <React.Fragment>
            <Divider type="vertical" />
            <Link to={{ pathname: `/invoices/invoice/details/${rc._id}` }}>
              <Button type="primary">Edit</Button>
            </Link>
          </React.Fragment>
        )
      }
    }
  }

  renderCopyBtn(rc) {
    // if(rc.status !== 'COPY'){
    return (
      <React.Fragment>
        <Divider type="vertical" />
        {/* <Link to={{ pathname: `/invoices/new` }}> */}
        <Button type="primary" onClick={() => {
          this.props.updateCopyInvoice(rc)
          this.props.history.push('/invoices/new');
        }}>Copy</Button>
        {/* </Link> */}
      </React.Fragment>
    )
    // }
  }

  formatAmt(amt) {
    let tempAmt = amt;

    return tempAmt > 0
      ? tempAmt.toFixed(2)
      : 0.00;
  }

  _searchInvoiceNoChange(e) {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    this.setState({
      searchInvoiceNo: e.target.value,
      searchObj: _.merge(this.state.searchObj, e.target.value ? { invoiceNo: e.target.value } : { invoiceNo: '.' }),
      typingTimeout: setTimeout(() => {
        this._getData(this.state.skip)
      }, 500)
    })
  }

  _searchPropertyUnitChange(e) {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    let referenceId = e.target.value
    this.setState({
      searchPropertyUnit: referenceId,
      typingTimeout: setTimeout(() => {
        if (referenceId) {
          client.authenticate()
            .then(() => {
              return client.service('propertyunits').find({
                query: {
                  orRegex: { referenceId }
                }
              })
            })
            .then((res) => {
              let propertyunitIdList = res.data.map((i) => {
                return i._id.toString()
              })
              this.setState({
                searchPropertyUnitList: {
                  propertyunitId: { $in: propertyunitIdList }
                }
              }, () => {
                this._getData(0)
              })
            })
            .catch((err) => {
              console.log({ err })
            });
        } else {
          this.setState({
            searchPropertyUnitList: {}
          }, () => {
            this._getData(0)
          })
        }
      }, 500)
    })
  }

  _searchCustomerChange(e) {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    let name = e.target.value
    this.setState({
      searchCustomer: name,
      typingTimeout: setTimeout(() => {

        if (name) {
          client.authenticate()
            .then(() => {
              return client.service('propertyunits').find({
                query: {
                  orRegex: {
                    'owner.name': name
                  }
                }
              })
            })
            .then((res) => {
              let propertyunitIdList = res.data.map((i) => {
                return i._id.toString()
              })
              this.setState({
                searchPropertyUnitList: {
                  propertyunitId: { $in: propertyunitIdList }
                }
              }, () => {
                this._getData(0)
              })
            })
            .catch((err) => {
              console.log({ err })
            });
        } else {
          this.setState({
            searchPropertyUnitList: {}
          }, () => {
            this._getData(0)
          })
        }
      }, 500)
    })
  }

  _searchStatusChange(e) {
    console.log({ e })
    this.setState({
      searchStatus: e,
      searchObj: _.merge(this.state.searchObj, e ? { status: e } : { status: '.' }),
    }, () => {
      this._getData(this.state.skip)
    })
  }

  _searchDateChange(e) {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    this.setState({
      searchDate: e.target.value,
      searchObj: _.merge(this.state.searchObj, e.target.value ? { status: e.target.value } : { status: '.' }),
      typingTimeout: setTimeout(() => {
        this._getData(this.state.skip)
      }, 500)
    })
  }
  renderAuthoriserFuntion() {
    let userRole = this.props.commons.selectedRole
    if (userRole === 'authoriser' || userRole === 'account') {
      return ([
      // <Link key='linkToAppslogs' to={{
      //   pathname: `/applogs/`
      // }}>
      //   <Button type="primary" style={{
      //     right: 20
      //   }}>Print All Invoices</Button>
      // </Link>,
      <Link key='linktoNew' to={{
        pathname: `/debitnotes/new`
      }}>
        <Button type="primary" style={{ right: 10 }}>New</Button>
      </Link>,
        // <Popconfirm key='confirmation' title="Are you sure to Create invoice for all?" onConfirm={this.confirmRecurring} onCancel={this.cancel} okText="Yes" cancelText="No">
        //   <Button type="primary">Create Recurring</Button>
        // </Popconfirm>
      ])

    }
    else {
      return (<Link key='linkToAppslogs' to={{
        pathname: `/applogs/`
      }}>
        <Button type="primary" style={{
          right: 20
        }}>Print All Invoices</Button>
      </Link>)
    }


  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title: (<Row gutter={8}>
          <Row>
            <Col span={24}>Invoice No</Col>
          </Row>
          <Row>
            <Col span={24}><Input value={this.state.searchInvoiceNo} onChange={this._searchInvoiceNoChange} /></Col>
          </Row>
        </Row>),
        dataIndex: 'invoiceNo',
        key: 'invoiceNo',
        width: '10%'
      },
      {
        title: (<Row gutter={8}>
          <Row>
            <Col span={24}>Customer Name</Col>
          </Row>
          <Row>
            <Col span={24}><Input value={this.state.searchCustomer} onChange={this._searchCustomerChange} /></Col>
          </Row>
        </Row>),
        dataIndex: 'customer',
        key: 'customer',
        width: '10%',
        render: (text, record) => (<div>
          {
            record.propertyunitId
              ?
              (record.propertyunitId.owner
                ? record.propertyunitId.owner.name
                : record.customerId) : ''
          }
        </div>)
      },
      {
        title: (<Row gutter={8}>
          <Row>
            <Col span={24}>Property Unit</Col>
          </Row>
          <Row>
            <Col span={24}><Input value={this.state.searchPropertyUnit} onChange={this._searchPropertyUnitChange} /></Col>
          </Row>
        </Row>),
        dataIndex: 'propertyunitId.referenceId',
        key: 'referenceId',
        width: '20%'
      },
      {
        title: (<Row gutter={8}>
          <Row>
            <Col span={24}>Status</Col>
          </Row>
          <Row>
            <Col span={24}>
              <Select style={{ width: 100 }} allowClear={true} value={this.state.searchStatus} onChange={this._searchStatusChange} placeholder="Status">
                <Option value="paid">PAID</Option>
                <Option value="partial">PARTIAL</Option>
                <Option value="issued">ISSUED</Option>
              </Select>
            </Col>
          </Row>
        </Row>),
        dataIndex: 'status',
        key: 'status',
        width: '10%',
        render: (text, record) => (<div>
          {
            record.status === 'PAID'
              ? <span style={{
                color: 'green'
              }}>{record.status}</span>
              : <span style={{
                color: 'red'
              }}>{record.status}</span>
          }
        </div>)
      },
      {
        title: (<Row gutter={8}>
          <Row>
            <Col span={24}>Date</Col>
          </Row>
          <Row>
            <Col span={24}><Input type='Date' value={this.state.searchDate} onChange={this._searchDateChange} /></Col>
          </Row>
        </Row>),
        dataIndex: 'invoiceDate',
        key: 'invoiceDate',
        width: '10%',
        render: (v) => (<div>{moment(v).format('DD/MM/YYYY')}</div>)
      },
      {
        title: 'Total (MYR)',
        dataIndex: 'totalAmt',
        key: 'totalAmt',
        width: '10%',
        render: (text, record) => (<div style={{
          float: 'right'
        }}>
          {this.formatAmt(record.totalAmt)}
        </div>)
      },
      {
        title: '',
        dataIndex: 'remark',
        key: 'remark',
        width: '5%',
        render: (text, record) => (
          <div style={{ textAlign: 'center' }}>
            {text ? <Popover content={text}><Icon type="message" /></Popover> : ''}
          </div>
        ),
      },
      {
        title: 'Action',
        key: 'action',
        width: '35%',
        render: (text, record) => (<div>
          {/*
          <LedgerIndexTransactionsByReference invoiceId={record._id}/>
          <Divider type="vertical"/>*/}
          <Link to={{
            pathname: `/debitnotes/view/${record._id}`
          }}>
            <Button style={{ marginTop: 5, marginBottom: 5 }} type="primary">View</Button>
          </Link>
          {this.renderEditBtn(record)}
          {this.renderPayBtn(record)}
          {/* {this.renderCopyBtn(record)} */}
          {this.renderConfirmDeleteBtn(record)}
        </div>)
      }
    ];

    return (<Spin spinning={this.state.loading}>
      <Layout>
        <Card title='Debit Notes' key='cardaction' extra={this.renderAuthoriserFuntion()}>

          <Table
            dataSource={this.props.invoices.invoices}
            columns={columns}
            rowKey="_id"

            pagination={{ total: this.state.totalPagination, pageSize: PAGESIZE }}
            onChange={(e) => this._handlePageChange(e)}
            size={'small'}
          />

          <InvoicesForm />
        </Card>

      </Layout>
    </Spin>)
  }
}

function mapStateToProps(state) {
  return {
    invoices: state.invoices,
    commons: state.commons

  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchInvoices,
    pushInvoices,
    removeInvoices,
    updateActiveInvoices,
    updateInvoices,
    updateMode,
    updateCopyInvoice
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(InvoicesIndex)));
