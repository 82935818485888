import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import promise from "redux-promise-middleware";
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from "./reducers";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'root',
  storage,
}

const middleware = composeWithDevTools(applyMiddleware(promise(), thunk));

const persistedReducer = persistReducer(persistConfig, rootReducer)

// export default createStore(rootReducer, middleware);

const store = createStore(persistedReducer, middleware)
const persistor = persistStore(store)
// export default () => {
//   return { store, persistor }
// }


export { store, persistor }