import _ from 'lodash';
import {
  FETCH_TRANSACTIONS,
  PUSH_TRANSACTIONS,
  REMOVE_TRANSACTIONS,
  UPDATE_TRANSACTIONS,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID } from '../actions/actions-transactions';

const INITIAL_STATE = {
  transactions: [],
  mode: 'index',
  activeId: '',
  activeTransactions:{}
}


export default function(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_TRANSACTIONS:
      return {
        ...state,
        transactions:action.payload,
      }
    case PUSH_TRANSACTIONS:
      return {
        ...state,
        transactions:[action.payload, ...state.transactions],
      }
    case REMOVE_TRANSACTIONS:
      return {
        ...state,
        transactions : [...state.transactions.filter( (item) => item._id !== action.payload._id)]
      }
    case UPDATE_TRANSACTIONS:
      let index =  _.findIndex(state.transactions, { '_id': action.payload._id })
      state.transactions.splice(index, 1, action.payload)
      return {
        ...state,
        transactions : state.transactions
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode : action.payload,
        activeId:''
      }
    case UPDATE_ACTIVE_ID:
      return {
        ...state,
        mode : action.mode,
        activeId:action.id,
        activeTransactions: _.pick(_.find(state.transactions, { '_id': action.id }), ['name', 'description', 'unit', 'categories','amount','minutePerSession','startTime','maxSessionPerday', '_id'])
      }
    default:
      return state

  }
}
