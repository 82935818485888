import React, { Component, useEffect, useRef } from 'react';
import { Modal, Upload, Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Select, Card, Radio, Spin, Row, Col } from 'antd';
import client from '../../feathers';
import requireAuth from "../requireAuth";
import Layout from "../layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Route, Link } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import axios from 'axios';
import Info from "../common/info";

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};

const tablebody = {
  padding: "4px 4px",
};

const formatter = new Intl.NumberFormat('en-US', {
  // style: 'currency',
  // currency: 'MYR',
  minimumFractionDigits: 2
})

const formatCurr = function format1(n = 0, currency = null) {
  let oriAmt = n;
  n = n < 0 ? Math.abs(n) : n;
  let amt = currency ? currency : '' + n.toFixed(2).replace(/./g, function (c, i, a) {
    return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
  });

  return oriAmt < 0 ? '(' + amt + ')' : amt;
}
const todaydate = new Date().getDate() + '/' + (new Date().getMonth() + 1) + '/' + new Date().getFullYear()

const text = 'Are you sure to delete this item?';
var balanceAmount = 0
var ledgerId = ''
var StatementTotal = [];

//-------------------------------------------------------------------------------
class ComponentToPrint extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      jmb: {},
      loading: false,
      invoiceAndItems: [],
      propertyunit: [],
      setting: [],
      CurrentPagination: 1,
      totalRecord: 0,
      balanceAmount: 0,
    }
  }

  componentWillMount() {

    this.setState({ loading: true });

    //get JMB INFO
    client.authenticate()
      .then((res) => {
        return client.service('jmbs').find({
          query: {
            $populate: 'propertyId',
            propertyId: this.props.id,
            status: 'ACTIVE',
            $sort: {
              createdAt: -1
            },
            $limit: 1
          }
        })
      })
      .then((res) => {
        this.setState({ jmb: res.data[0] });

        client.service('settings').find({
          query: {
            propertyId: this.props.id
          }
        })
          .then((res) => {
            this.setState({
              setting: res.data[0].tnc,
            });
          })

      })
      .then((res) => {
        return axios.get(`${client.io.io.uri}SOA-printAll`, {
          params: {
            companyId: this.props.id,
            fromDate: this.props.fromDate,
            toDate: this.props.toDate,

          },
          headers: {
            'Authorization': client.settings.storage.storage['feathers-jwt']
          }
        })
      })
      .then((res) => {

        this.setState({
          dataSource: res.data,
          loading: false
        });
      })
      .catch((err) => {
        console.log(err);
      });




  }

  //----------------------------------------------------------------------
  convertDate(date = null) {
    let tempDate = new Date(date);
    let d = tempDate.getDate();
    let m = tempDate.getMonth() + 1
    let y = tempDate.getFullYear();
    return date ? d + '/' + m + '/' + y : '';
  }
  //----------------------------------------------------------------------
  componentDidUpdate() {

  }

  formBalance(record) {

    if (ledgerId != record.ledgerAccountId) {
      balanceAmount = 0
      ledgerId = record.ledgerAccountId
    }

    let totaling = ''
    let totalingamt = record.creditDebit === 'CREDIT' ? record.creditAmount * -1 : record.debitAmount
    totaling = { ledgerAccountId: ledgerId, footerBalance: totalingamt }
    StatementTotal.push(totaling)

    let balance = record.creditDebit === 'CREDIT' ? balanceAmount -= record.creditAmount : balanceAmount += record.debitAmount

    return (
      formatCurr(balance)
    )

  }

  footerBalance(record) {

  }

  //----------------------------------------------------------------------
  _renderTable = () => {

    const dataSource = this.state.dataSource;
    const loading = this.state.loading;
    const jmb = this.state.jmb;
    let table = [];
    var balanceAmount = 0;
    var ledgerId = '';

    const columns = [
      {
        // title: 'Transaction Date',
        title: 'Date',
        dataIndex: 'trxDate',
        width: '12%',
        render: (text, record) => (
          <span style={{ fontSize: '.8em' }}>
            {this.convertDate(text)}
          </span>)
      },
      {
        title: 'Doc No.',
        dataIndex: 'description',
        width: '23%',
        render: (text, record) => (
          <span style={{ fontSize: '.8em' }}>
            {text}
          </span>)
      },
      {
        title: 'Description',
        dataIndex: 'description2',
        width: '29%',
        render: (text, record) => (
          <span style={{ fontSize: '.8em' }}>
            {text}
          </span>)
      },

      {
        title: (<span style={{
          float: 'right'
        }}>
          Debit
        </span>),
        width: '12%',
        render: (text, record) => (
          <span style={{ fontSize: '.8em', float: 'right' }}>
            {formatCurr(record.debitAmount)}
          </span>)
      }, {
        title: (<span style={{
          float: 'right'
        }}>
          Credit
        </span>),
        width: '12%',
        render: (text, record) => (
          <span style={{ fontSize: '.8em', float: 'right' }}>
            {formatCurr(record.creditAmount)}
          </span>)
      }, {
        title: (<span style={{ float: 'right' }}>Balance</span>),
        width: '12%',
        render: (text, record) => (
          <span style={{ fontSize: '.8em', float: 'right' }}>
            {
              // formatCurr(record.creditDebit === 'CREDIT'? balanceAmount -= record.creditAmount: balanceAmount += record.debitAmount)
              this.formBalance(record)
            }
          </span>),
        footer: (data) => {
          return <div>123</div>
        },
      }

    ];

    // Outer loop to create parent
    let recordCount = dataSource.result ? dataSource.result.length : 0

    if (recordCount > 0) {
      for (let i = 0; i < dataSource.result.length; i++) {
        // for (let i = 0; i < 2; i++) {
        {
          var count = dataSource.result[i].trxList.length
          var flag = 3;
          if (count > 27) {
            let page = count / 27;
            if (page >= 2) {
              let exLPge = ((page - 1) * 27);
              let lpge = count - exLPge;
              flag = lpge > 27 ? 1 : 0;
            }
            else {
              flag = 1;
            }

          }
        }

        { balanceAmount = 0; }


        table.push([
          <Card title={'INVOICE cum STATEMENT of ACCOUNT'}
            loading={loading}>
            <Row>
              <Col span={5}><img width='100%' src={this.state.jmb ? this.state.jmb.headerFile : ''} /></Col>
              <Col span={19}>
                <p style={{ margin: '5px' }}>
                  <span >{this.state.jmb ? this.state.jmb.headerLine1 : ''}</span> ({this.state.jmb ? this.state.jmb.registrationNo : ''})</p>
                <p style={{ margin: '5px' }}>
                  <span>{this.state.jmb ? this.state.jmb.headerLine2 : ''}</span>
                </p>
                <p style={{ margin: '5px' }}>
                  <span>{this.state.jmb ? this.state.jmb.headerLine3 : ''}</span>
                </p>
                <p style={{ margin: '5px' }}>
                  <span>{this.state.jmb ? this.state.jmb.headerLine4 : ''}</span>
                </p>
              </Col>
            </Row>

            <br />

            <Row gutter={24}>
              <Col span={4}><span>Generated on</span></Col>
              <Col span={1}><span>:</span></Col>
              <Col span={18}><span>{todaydate}</span></Col>
            </Row>

            <Row gutter={24}>
              <Col span={4}><span>Unit</span></Col>
              <Col span={1}><span>:</span></Col>
              <Col span={18}><span>{dataSource.result[i].referenceId}</span></Col>
            </Row>

            <Row gutter={24}>
              <Col span={4}><span>Name</span></Col>
              <Col span={1}><span>:</span></Col>
              <Col span={18}><span>{dataSource.result[i].owner ? dataSource.result[i].owner.name : ''}</span></Col>
            </Row>

            {/*  <Row gutter={24}>
                    <Col span={4}><span>Contact Number</span></Col>
                    <Col span={1}><span>:</span></Col>
                    <Col span={18}><span>{dataSource.result[i].owner?dataSource.result[i].owner.contactNumber:''}</span></Col>
                    </Row>  */}

            <Row gutter={24}>
              <Col span={4}><span>Mailing Address</span></Col>
              <Col span={1}><span>:</span></Col>
              <Col span={18}><span><TextArea readOnly style={{ fontWeight: 650, fontSize: '1em', resize: 'none', padding: 0, width: '300px', height: '10 0px', border: 'none' }} value={dataSource.result[i].owner ? dataSource.result[i].owner.mailingAddress : ''} rows={4} /></span></Col>
            </Row>


            <br />


            <Row>
              <Table
                bodystyle={{ tablebody }}
                dataSource={dataSource.result[i].trxList}
                rowKey='_id'
                columns={columns}
                pagination={false}
                // title={() => 'Statement Of Account'}

                size={'small'} />
              {flag == 1 ? <div className='pageBreak' /> : ''}
              <div className="soaFooter">
                <div className="ql-display-container2">
                  <div className="ql-display-editor2" dangerouslySetInnerHTML={{ __html: this.state.setting ? this.state.setting.soaTnc : '' }} />
                  <div style={{ 'textAlign': 'right' }} >© 2021 Mr Tech System Holdings Sdn Bhd (1370083-M)</div>
                </div>
              </div>
            </Row>

          </Card>
          ,
          <div className='pageBreak' />
        ]);
        // } //for loop total page
      }
      return table;
    }
  }

  render() {
    const loading = this.state.loading;
    return (
      <Spin spinning={loading}>
        {this._renderTable()}
      </Spin>
    );
  }
}

class IndexTrialBalance extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Layout>
        <ReactToPrint
          trigger={() =>
            <Row><Col style={{ float: 'right', marginBottom: '5px' }}>
              <Button type="primary">Print</Button></Col></Row>}
          content={() => this.componentRef}
        />
        <ComponentToPrint ref={el => (this.componentRef = el)}
          id={this.props.match.params.id}
          fromDate={this.props.location.state.fromdate}
          toDate={this.props.location.state.todate}
        />
      </Layout>
    );
  }
}


function mapStateToProps(state) {
  return {
    //accounts: state.accounts
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    //updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(IndexTrialBalance)));
