import React, { Component } from 'react';
import {
  Popconfirm,
  message,
  Divider,
  Table,
  Form,
  Icon,
  Input,
  Button,
  Card,
  Row,
  Col,
  Spin,
  DatePicker,
  Popover,
} from 'antd';
import { Link } from 'react-router-dom';
import client from '../../feathers';
import requireAuth from "../../components/requireAuth";
import Layout from "../../components/layout";
import CreditNotesForm from "../../components/creditnotes/form";
import moment from 'moment';
import axios from 'axios';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchCreditNotes,
  pushCreditNotes,
  removeCreditNotes,
  updateMode,
  updateActiveCreditNotes,
  updateCreditNotes
} from '../../actions/actions-creditnotes';

const _ = require('lodash');
const PAGESIZE = 20;
const text = 'Are you sure to delete this item?';
const informationText =
  (
    <div>
      <Row><span> <Icon type="bulb" theme="twoTone" /> Please download templates and input accordingly.</span></Row>
      <Row><span>----------------------------------------------------------</span></Row>
      <Row><span><Icon type="bulb" theme="filled" style={{ color: 'gold', fontSize: 18 }} />After <b>Add</b> Credit Notes.</span></Row>
      <Row><span> 1. Please go to menu, <b>Settings</b> {'-->'} <b>No Affect GL</b></span></Row>
      <Row><span> 2. Please <b>Add</b> Prepayment</span></Row>
      <Row><span><Icon type="bulb" theme="filled" style={{ color: 'gold', fontSize: 18 }} />After <b>Edit/Delete</b> Credit Notes.</span></Row>
      <Row><span> 1.Please <b>Delete</b> Prepayment (if by prepayment) </span></Row>
      <Row><span> 2. Please go to menu , <b>Settings</b> {'-->'} <b>No Affect GL</b></span></Row>
      <Row><span> 3. Please Add Prepayment (if still by prepayment)</span></Row>

    </div>
  )

class CreditNotesIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      totalPagination: 1,
      CurrentPagination: 1,
      searchReferenceNo: '',
      searchStatus: '',
      searchDate: '',
      dtAccountList: [],
      ctAccountList: [],
      _id: '',
    }

    this.handleDelete = this.handleDelete.bind(this);
    this._searchReferenceNoChange = this._searchReferenceNoChange.bind(this);
    this._searchDateChange = this._searchDateChange.bind(this);
  }

  componentDidUpdate() {

    if (this.props.creditnotes.mode === 'refresh') {
      this.componentWillMount()
    }
  }

  componentDidMount() { }

  componentWillMount() {

    this.props.updateMode('')
    this.setState({ loading: true });

    let companyId = this.props.commons.selectedCompany._id

    client.authenticate().then((res) => {
      //
      return client.service('creditnotes').find({
        query: {
          $populate: 'propertyunitId dtledgerAccountId ctledgerAccountId',
          companyId: companyId,
          $limit: PAGESIZE,
          divisionType: 'DEBTOR',
          $sort: {
            createdAt: -1
          }
        }
      })
    }).then((res) => {

      this.props.fetchCreditNotes(res.data);
      this.setState({ totalPagination: res.total, loading: false });
    }).catch((err) => {
      console.log(err);
      this.setState({
        loading: false,
      });
    })

    this.getAccountList()
  }

  handleDelete(_id) {
    axios.post(`${client.io.io.uri}removeTrx`, {
      _id: _id,
      companyId: this.props.commons.selectedCompany._id,
      trxType: 'CN'
    }, {
      headers: {
        'Authorization': client.settings.storage.storage['feathers-jwt']
      }
    }).then((res) => {
      message.success('Deleted');
      this.props.updateMode('refresh')
    }).catch((err) => {
      console.log(err);
      this.props.updateMode('refresh')
    })
  }

  handleEdit(_id) {
    this.props.updateActiveCreditNotes('edit', _id)

  }

  handleManage(_id) {
    this.props.updateActiveCreditNotes('', _id)
  }

  showModal = () => {
    this.props.updateMode('new')

  }

  renderConfirmDeleteBtn(_id) {
    return (<Popconfirm placement="topLeft" title={text} onConfirm={() => this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
      <Button type="danger">Delete</Button>
    </Popconfirm>)
  }

  _searchReferenceNoChange(e) {
    this.setState({
      searchReferenceNo: e.target.value,
      searchObj: _.merge(
        this.state.searchObj, e.target.value
        ? {
          referenceId: e.target.value
        }
        : {
          referenceId: '.'
        }),
      typingTimeout: setTimeout(() => {
        this._getData(this.state.skip)
      }, 500)
    })
  }

  _searchDateChange(e) {
    let trxDate = new Date(e)

    this.setState({
      searchDate: moment(trxDate),

      typingTimeout: setTimeout(() => {
        this._getData(this.state.skip)
      }, 500)
    })
  }

  _getData(skip) {
    this.setState({
      loading: true,
    });
    client.authenticate().then(() => {
      let searchObj = this.state.searchObj
      let queryString = ''

      if (this.state.searchDate !== '') {
        queryString = {
          trxDate: {
            $gte: new Date(moment(new Date(this.state.searchDate)).startOf('day')),
            $lte: new Date(moment(new Date(this.state.searchDate)).endOf('day')),
          }
        }
      }

      let query = {
        query: {
          companyId: this.props.commons.selectedCompany._id,
          $populate: 'propertyunitId dtledgerAccountId ctledgerAccountId',
          $limit: PAGESIZE,
          $sort: {

            createdAt: -1
          },
          $limit: PAGESIZE,
          ...queryString,
          divisionType: 'DEBTOR',
          orRegex: this.state.searchObj
        }
      }
      _.assign(query.query, searchObj)

      return client.service('creditnotes').find(query)
    }).then((res) => {

      this.setState({
        loading: false,
      });

      this.props.fetchCreditNotes(res.data)
    }).catch((err) => {
      this.setState({
        loading: false,
      });
      console.log(err);
    });
  }

  handlePageChange = (page) => {
    let companyId = this.props.commons.selectedCompany._id

    this.setState({ CurrentPagination: page, loading: true });
    this.props.form.validateFields((err, values) => {

      client.authenticate().then((res) => {
        //
        return client.service('creditnotes').find({
          query: {
            ...values,
            companyId: companyId,
            $limit: PAGESIZE,
            $skip: (page - 1) * PAGESIZE,
            $populate: 'propertyunitId dtledgerAccountId ctledgerAccountId',
            divisionType: 'DEBTOR',
            $sort: {

              createdAt: -1
            }
          }
        })
      }).then((res) => {
        //
        this.setState({ totalPagination: res.total, loading: false });
        this.props.fetchCreditNotes(res.data)
      }).catch((err) => {
        console.log(err);
      })
    });
  }

  handleSubmit() {

    if (this.props.creditnotes.mode === 'new') {
      this.setState({
        loading: true,
      });

      this.props.form.validateFields((err, values) => {

        if (!err) {

          values.trxDate = new Date(values.trxDate)
          console.log(values)
          client.authenticate()
            .then((res) => {
              return client.service('creditnotes').create({
                companyId: this.props.commons.selectedCompany._id,
                ...values,
              })
            })
            .then((res) => {

              this.props.updateMode('refresh')
              this.props.form.resetFields()
              this.setState({
                loading: false,
              });
            })
            .catch(err => { console.log(err) })
        }

      });

    }

    if (this.props.creditnotes.mode === 'edit') {


      this.props.form.validateFields((err, values) => {

        if (!err) {

          this.setState({
            loading: true,
          });

          values.trxDate = new Date(values.trxDate)

          client.authenticate()
            .then((res) => {
              return client.service('creditnotes').patch(this.props.creditnotes.activeId, {
                companyId: this.props.commons.selectedCompany._id,
                ...values,
              })
            })
            .then((res) => {

              this.props.updateMode('refresh')
              this.props.form.resetFields()
              this.setState({
                loading: false,
              });
            })
            .catch(err => { console.log(err) })
        }

      });

    }
  }

  getAccountList() {

    client.authenticate()
      .then((res) => {
        return client.service('ledger-accounts').find({
          query: {
            companyId: this.props.commons.selectedCompany._id,
            $sort: { accountName: 1 },
          }
        })
      })
      .then((res) => {

        this.setState({
          dtAccountList: res.data
        });

        return client.service('ledger-accounts').find({
          query: {
            companyId: this.props.commons.selectedCompany._id,
            $sort: { accountName: 1 },
          }
        })

      })
      .then((res) => {
        this.setState({
          ctAccountList: res.data
        });
      })

      .catch((err) => {
        console.log(err);
      });

  }

  render() {
    const columns = [
      {
        title: (<Row gutter={8}>
          <Row>
            <Col span={24}>Reference No.</Col>
          </Row>
          <Row>
            <Col span={24}><Input value={this.state.searchReferenceNo} onChange={this._searchReferenceNoChange} /></Col>
          </Row>
        </Row>),
        dataIndex: 'referenceId',
        key: 'referenceId',
        width: '10%'
      },
      {
        title: 'Credit Account',
        dataIndex: 'ctledgerAccountId.description',
        key: 'dtledgerAccountId.description',
        width: '10%'
      },
      {
        title: 'Debit Account',
        dataIndex: 'dtledgerAccountId.description',
        key: 'ctledgerAccountId.description',
        width: '10%'
      }
      , {
        title: (<Row gutter={8}>
          <Row>
            <Col span={24}>Date</Col>
          </Row>
          <Row>
            <Col span={24}><DatePicker value={this.state.searchDate} onChange={this._searchDateChange} /></Col>
          </Row>
        </Row>),
        dataIndex: 'trxDate',
        key: 'trxDate',
        width: '10%',
        render: (text, record) => (<div>{moment(record.trxDate).format('DD-MM-YYYY')}</div>)
      },
      {
        title: 'Invoice No',
        dataIndex: 'invoiceNo',
        key: 'invoiceNo',
        width: '10%',
      },
      {
        title: 'Invoice Date',
        dataIndex: 'invoiceDate',
        key: 'invoiceDate',
        width: '10%',
        render: (text, record) => (<div>{moment(record.invoiceDate).format('DD-MM-YYYY')}</div>)
      },
      {
        title: (<Row><Col style={{ 'float': 'right' }}>Amount</Col></Row>),
        dataIndex: 'creditAmount',
        key: 'creditAmount',
        width: '10%',
        render: (text, record) => (<div style={{
          float: 'right'
        }}>
          {parseFloat(record.creditAmount).toFixed(2)}
        </div>)
      },
      {
        title: 'Remark',
        dataIndex: 'remark',
        key: 'remark',
        width: '10%',
        render: (text, record) => (
          <div style={{ textAlign: 'center' }}>
            {text ? <Popover content={text}><Icon type="message" /></Popover> : ''}
          </div>
        ),
      },
      {
        title: 'Action',
        key: 'action',
        width: '40%',
        render: (text, record) => (<div>

          <Link to={{
            pathname: `/creditnotes/print/${record._id}`
          }}>
            <Button type="primary">Print</Button>
          </Link>

          <Divider type="vertical" />

          <Button onClick={() => { this.handleEdit(record._id); }} type="primary">Edit</Button>

          <Divider type="vertical" /> {this.renderConfirmDeleteBtn(record._id)}
        </div>)
      }
    ];

    return (<Layout>
      <Spin key='spinning' spinning={this.state.loading}>
        <Card title={

          <div > <Row>
            <Col>
              <span>Credit Notes</span>
              <span style={{
                marginLeft: "5px",
                marginBottom: "10px"
              }}>
                {/* <Popover content={informationText}><Icon type="info-circle" theme="twoTone" /></Popover> */}

              </span>
            </Col>
          </Row>
          </div>} key='cardaction' extra={[
            <Button type="primary" onClick={() => this.showModal()} style={{ right: 10 }}>New</Button>
          ]}>



          <Table dataSource={this.props.creditnotes.creditnotes} columns={columns} rowKey="_id" pagination={{
            defaultCurrent: 1,
            total: this.state.totalPagination,
            onChange: this.handlePageChange,
            pageSize: 20
          }} size={'small'} />

          <CreditNotesForm />
        </Card>
      </Spin>
    </Layout>)
  }
}

function mapStateToProps(state) {
  return {
    creditnotes: state.creditnotes,
    commons: state.commons

  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchCreditNotes: fetchCreditNotes,
    pushCreditNotes: pushCreditNotes,
    removeCreditNotes: removeCreditNotes,
    updateActiveCreditNotes: updateActiveCreditNotes,
    updateCreditNotes: updateCreditNotes,
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(CreditNotesIndex)));
