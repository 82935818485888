import _ from 'lodash';
import {
  FETCH_PAYMENTSVOUCHERMASTERS,
  PUSH_PAYMENTSVOUCHERMASTERS,
  REMOVE_PAYMENTSVOUCHERMASTERS,
  UPDATE_PAYMENTSVOUCHERMASTERS,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID
} from '../actions/actions-paymentsvouchermasters';


const INITIAL_STATE = {
  paymentsvouchersmasters: [],
  mode: 'index',
  activeId: '',
  activePaymentsVoucherMasters: {}
}


export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_PAYMENTSVOUCHERMASTERS:
      return {
        ...state,
        paymentsvouchersmasters: action.payload,
      }
    case PUSH_PAYMENTSVOUCHERMASTERS:
      return {
        ...state,
        paymentsvouchersmasters: [action.payload, ...state.paymentsvouchersmasters],
      }
    case REMOVE_PAYMENTSVOUCHERMASTERS:
      return {
        ...state,
        paymentsvouchersmasters: [...state.paymentsvouchersmasters.filter((item) => item._id !== action.payload._id)]
      }
    case UPDATE_PAYMENTSVOUCHERMASTERS:
      let index = _.findIndex(state.paymentsvouchersmasters, { '_id': action.payload._id })
      state.paymentsvouchersmasters.splice(index, 1, action.payload)
      return {
        ...state,
        paymentsvouchersmasters: state.paymentsvouchersmasters
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode: action.payload,
        activeId: ''
      }
    case UPDATE_ACTIVE_ID:
      return {
        ...state,
        mode: action.mode,
        activeId: action.id,
        activePaymentsVoucherMasters: _.pick(_.find(state.paymentsvouchersmasters, { '_id': action.id }), ['propertyId', 'date', 'dateInt', 'supplierId', 'paymentamount', 'paymentDate', 'referenceId', 'chequeNo', 'remark', '_id', 'referenceNo'])
      }
    default:
      return state

  }
}
