import React, { Component } from 'react';
import { Popconfirm, message, Divider,Row, Col,Table, Form, Icon, Input, Button,Card, Spin,Select,Modal,DatePicker,Alert} from 'antd';
import { Route, Link } from 'react-router-dom';

import client from '../../feathers';
import requireAuth  from "../requireAuth";
import Layout  from "../layout";
import axios from 'axios';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import {
  fetchInvoiceitems,
  pushInvoiceitems,
  removeInvoiceitems,
  updateMode,
  updateActiveInvoiceitems,
  updateInvoiceitems } from '../../actions/actions-invoiceitems';


const FormItem = Form.Item;
const Option = Select.Option;

const text = 'Are you sure to delete this item?';

const formItemLayout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 16
  }
};

class LateChargeIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource:[],
      totalPagination:1,
      CurrentPagination:1,
      latecharge:0,
      fetching:false,
      loading:false,
      loading2:false,
      propertyunitsDropdowns:[],
      settings:'',
      startDate:'',
      visible:false,
      visible2:false,
      InitialAccrueLPIdate:'',
      firstLPI:'',
      error:false,
      refresh:false,
    }
  }

  componentDidUpdate(){

    if(this.state.refresh === true){
      this.componentWillMount()
    }

  }


  componentWillMount(){

       this.setState({loading:true,refresh:false});

      client.authenticate()
      .then((res)=>{

        return client.service('settings').find({
          query: {
            propertyId:sessionStorage.getItem('companyId'),
             $limit:1
           }
        })
    })
    .then((res2)=>{
      this.setState({
        settings:res2.data[0],
        firstLPI:res2.data[0].firstLPI
      });

          return client.service('lateinterests').find({
            query: {
              companyId:sessionStorage.getItem('companyId'),
              $populate:'propertyunitId invoiceItemId',
               $sort: {
                 createdAt: 1
               },
               $limit:20,
             }
          })
      })
      .then((res)=>{

        this.setState({
          dataSource:res.data,
          totalPagination:res.total,
          loading:false,
        });
      })
      .catch((err)=>{
          console.log(err);
          this.setState({
            loading:false,
          });
      });

      this.getPropertyUnits()

  }
getPropertyUnits () {

  client.authenticate()
  .then((res)=>{
      return client.service('propertyunits').find({
        query: {
          propertyId: sessionStorage.getItem('companyId'),
           $sort: {
             referenceId: 1
           },
           $limit:10,
         }
      })
  })
  .then((res)=>{
    this.setState({propertyunitsDropdowns:res.data});
  })
  }

  showModal = () => {
    this.props.updateMode('new')
  }

  handlePageChange = (page) => {

    this.setState({
      CurrentPagination: page,
      loading:true,
    });
    this.props.form.validateFields((err, values) => {

      client.authenticate()
      .then((res)=>{

          return client.service('lateinterests').find({
            query: {
              companyId: sessionStorage.getItem('companyId'),
              $populate:'propertyunitId invoiceItemId',
              $limit:20,
              $skip:(page-1)*20,
            }
          })
      })
      .then((res)=>{

        this.setState({
          dataSource:res.data,
          totalPagination:res.total,
          loading:false,
        });
        this.props.fetchTransactions(res.data);
      })
      .catch((err)=>{
        this.setState({
          loading:false,
        });
          console.log(err);
      })
    });
  }

  handleSearch = (e) => {

    try{
    e.preventDefault();
    }
    catch(err){};

    this.props.form.validateFields((err, values) => {
      client.authenticate()

      .then((res)=>{
        let filerPropertyunitId       = e===''?'':{propertyunitId:e};
        var collection              = {...filerPropertyunitId};

          return client.service('lateinterests').find({
            query: {
              companyId:sessionStorage.getItem('companyId'),

              $populate:'propertyunitId invoiceItemId',
              ...collection,
               $sort: {
                 createdAt:1,
                 //propertyunitId: -1
               },

             }
          })
      })
      .then((res)=>{
        this.setState({
          dataSource:res.data,
          totalPagination:res.total,
        });

      })
      .catch((err)=>{
          console.log(err);
      });

    });
  }


  handleCancel = (e) => {
    this.setState({visible: false,visible2: false});
  }

  handleSearchPropertyunit = (value) => {
    //generate charges dropdown
    this.setState({fetching:true});
    client.authenticate()
    .then((res)=>{
        return client.service('propertyunits').find({
          query: {
            propertyId:sessionStorage.getItem('companyId'),
            $sort: {
              referenceId: 1
            },
             orRegex:{
              referenceId:value
            },
           }
        })
    })
    .then((res)=>{

      this.setState({
        propertyunitsDropdowns:res.data,
        fetching:false,
      });
    })
    .catch((err)=>{
      this.setState({fetching:false});
      console.log(err);
    });
  }

  renderConfirmDeleteBtn(_id){
    return (
      <Popconfirm placement="topLeft" title={text} onConfirm={()=>this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
        <Button type="danger">Delete</Button>
      </Popconfirm>
    )
  }


  createInitialAccrueLPI = () => {

    client.authenticate()
    .then((res)=>{
        return client.service('cronjobs').create({
            propertyId:sessionStorage.getItem('companyId'),
            initialAccrueDate: moment(this.state.InitialAccrueLPIdate).startOf('day'),
            createInitialAccrueLPI :true,

        })
    })

          this.setState({visible:false,firstLPI:true});
          client.service('settings').patch(null,
              {
                firstLPI:true
              },
              {
              query: {
                  propertyId:sessionStorage.getItem('companyId'),
                  }
                }

          )

          this.componentWillMount();

  }

  showFormInitialAccrueLPI = () => {

        this.setState({visible:true});

  }

  renderSearching(){
    const { getFieldDecorator } = this.props.form;
    return (
      <Form
        className="ant-advanced-search-form"
        onSubmit={this.handleSearch}
      >
        <Row gutter={24} style={{ display: 'block' }}>
          <Col span={8}>
            <Form.Item label='Unit Number'>
              {getFieldDecorator(`propertyunitId`, {
                rules: [{
                  required: false,
                  message: 'Unit Number!',
                }],
              })(
                <Select
                  showSearch
                  placeholder="Select users"
                  notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
                  filterOption={false}
                  onSearch={this.handleSearchPropertyunit}
                  // onChange={this.handleSearchPropertyunit}
                  style={{ width: '100%' }}
                >
                  {this.state.propertyunitsDropdowns.map(d => <Option key={d._id}>{d.referenceId}</Option>)}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Button type="primary" htmlType="submit" style={{float:'right'}} >Search</Button>
        </Row>
      </Form>
    )
  }

  showCreateLateCharge=()=> {
       this.setState({visible2:true});
  }

  createLateCharge=()=> {

     this.props.form.validateFields((err, values) => {

        this.setState({loading2:true});

       axios.get(`${client.io.io.uri}singleChargePropertyUnitLPI`,{
         params:{
           companyId:sessionStorage.getItem('companyId'),
           propertyunitId: values.propertyunitId,
         },
         headers: {
           'Authorization': client.settings.storage.storage['feathers-jwt']
         }
       }).then((res)=>{

          if (res.data.success){message.success(res.data.success);}
          if (res.data.failure){message.error(res.data.failure);}
           this.setState({visible2:false,loading2:false,refresh:true});
        })
        .catch((err)=>{
          console.log(err)
        });

    })

  }

  cancel(e) {

  }

  _InitialAccrueLPIDateChange=(e)=>{

    this.setState({
      InitialAccrueLPIdate: new Date(e)
    });
  }

  formatAmt(amt) {
    let integer = parseFloat(amt);
    return integer>0?integer.toFixed(2):0.00;
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const {dataSource,error}          = this.state;

    const columns = [
      {
        title:
                (          <Row gutter={8}>
                 <Row><Col span={24}>Property Unit</Col></Row>
                 <Row><Col span={24}>
                    <Select
                      showSearch
                      placeholder="Select Units"
                      notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
                      filterOption={false}
                      showArrow={false}
                      onSearch={this.handleSearchPropertyunit}
                      onChange={this.handleSearch}
                      style={{ width: '100%' }}
                      notFoundContent={null}
                      >
                  <Option key='ShowAll'>Show All Record</Option>
                   {this.state.propertyunitsDropdowns.map(d => <Option key={d._id}>{d.referenceId}</Option>)}
                 </Select>
                 </Col></Row>
                 </Row>
                ),
        dataIndex: 'propertyunitId.referenceId',
        key: 'propertyunitId.referenceId',
      },
      {
        title: 'Doc Id',
        dataIndex: 'invoiceItemId.docId',
        key: 'invoiceItemId.docId',
      },
      {
        title: 'Description',
        dataIndex: 'invoiceItemId.description',
        key: 'invoiceItemId.description',
      },
      {
        title: (<span style={{
            float: 'right'
          }}>Invoice Amount
        </span>),
        dataIndex: 'invoiceAmt',
        key: 'invoiceAmt',
        render: (text, record) => (
          <span style={{float:'right'}}>{this.formatAmt(record.invoiceAmt)}</span>
        ),
      },

      {
        title: 'Due Date',
        dataIndex: 'invoiceItemId.dueDate',
        key: 'invoiceItemId.dueDate',
        render: (text, record) => (
          <span>{moment(text).format('DD-MM-YYYY')}</span>
        ),
      },

      {
        title: 'Accrue Start Date',
        dataIndex: 'accrueIntStartDate',
        key: 'accrueIntStartDate',
        render: (text, record) => (
          <span>{moment(text).format('DD-MM-YYYY')}</span>
        ),
      },

      {
        title: 'Days Over Due',
        dataIndex: 'dueDateDays',
      },
      {
        title: (<span style={{
            float: 'right'
          }}>Accumulated LPI
        </span>),
        dataIndex: 'runninglateInterest',
        render: (text, record) => (
          <span style={{float:'right'}}>{this.formatAmt(record.runninglateInterest)}</span>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'status',
      },
      {
        title: 'Date Charged',
        dataIndex: 'chargeDate',
        render: (text, record) => (
          <span>{text?moment(text).format('DD-MM-YYYY'):''}</span>
        ),
      },
    ];

    return(
      <Layout>
        <Spin spinning={this.state.loading} >
        {/*this.state.error?(<Alert message="Success Text" type="success" />):null*/}
          <Card
            title='Late Charge Items'
            extra={[

                    this.state.settings.firstLPI==='true'?'':
                    <Popconfirm title="Trigger Initial Accrue LPI ?" onConfirm={this.showFormInitialAccrueLPI} onCancel={this.cancel} okText="Yes" cancelText="No">
                      <Button type="danger">Calculate Initial LPI Accrue</Button>
                    </Popconfirm>
                    ,
                    <Divider type="vertical"/>
                    ,
                    <Popconfirm title="Create LPI Invoice on Unit ?" onConfirm={this.showCreateLateCharge} onCancel={this.cancel} okText="Yes" cancelText="No">
                      <Button type="danger">Create late charge Invoice</Button>
                    </Popconfirm>

                  ]}
          >
          {/*{this.renderSearching()} */}

          

            <Table
              dataSource={dataSource}
              columns={columns}
              pagination={{total:this.state.totalPagination,defaultCurrent:1,onChange:this.handlePageChange,  pageSize:20,}}
              rowKey="_id"
            />
            

            <Modal title="Trigger Initial Accrue LPI"
            visible={this.state.visible}
            onOk={this.createInitialAccrueLPI}
            onCancel={this.handleCancel}
            >

              <Form className="submit-form">

                <Row>
                  <Col span={24}>

                  <Form.Item key='InitialAccrueLPIdate' {...formItemLayout} label="Initial Accrue Interest Date">
                      {getFieldDecorator('InitialAccrueLPIdate')
                      (
                        <DatePicker onChange={this._InitialAccrueLPIDateChange}/>
                      )}
                    </Form.Item>

                  </Col>
                </Row>
              </Form>
            </Modal>



            <Modal title="LPI on Property Unit"
            visible={this.state.visible2}
            onOk={this.createLateCharge}
            onCancel={this.handleCancel}
            >
        <Spin spinning={this.state.loading2} >
              <Form className="submit-form">

                <Row>
                  <Col span={24}>

                  <Form.Item key='propertyunitId' {...formItemLayout} label="Property Unit">
                      {getFieldDecorator('propertyunitId')
                      (
                        <Select
                          showSearch
                          // value={value}
                          notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
                          filterOption={false}
                          onSearch={this.handleSearchPropertyunit}
                          //onChange={this.handleChangePropertyunit}
                        >
                          {this.state.propertyunitsDropdowns.map(d => <Option key={d._id}>{d.referenceId}</Option>)}
                        </Select>
                      )}
                    </Form.Item>

                  </Col>
                </Row>
              </Form>
          </Spin>
            </Modal>



          </Card>
        </Spin>
      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    invoiceitems: state.invoiceitems,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchInvoiceitems: fetchInvoiceitems,
    pushInvoiceitems: pushInvoiceitems,
    removeInvoiceitems: removeInvoiceitems,
    updateActiveInvoiceitems: updateActiveInvoiceitems,
    updateInvoiceitems: updateInvoiceitems,
    updateMode: updateMode
    }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(LateChargeIndex)));
