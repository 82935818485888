export const FETCH_NOTICES = 'FETCH_NOTICES';
export const PUSH_NOTICES = 'PUSH_NOTICES';
export const REMOVE_NOTICES = 'REMOVE_NOTICES';
export const UPDATE_NOTICES = 'UPDATE_NOTICES';
export const UPDATE_NOTICES_MODE = 'UPDATE_NOTICES_MODE';
export const UPDATE_NOTICES_ACTIVE_ID = 'UPDATE_NOTICES_ACTIVE_ID';

export function fetchNotices(data){
  return (dispatch) => {
    dispatch({
      type: FETCH_NOTICES,
      payload:data
    });
  }
}

export function pushNotices(data){
  return (dispatch) => {
    dispatch({
      type: PUSH_NOTICES,
      payload:data
    });
  }
}

export function removeNotices(data){
  return (dispatch) => {
    dispatch({
      type: REMOVE_NOTICES,
      payload:data
    });
  }
}

export function updateNotices(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_NOTICES,
      payload:data
    });
  }
}

export function updateMode(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_NOTICES_MODE,
      payload:data
    });
  }
}

export function updateActiveNotices(mode, id){
  return (dispatch) => {
    dispatch({
      type: UPDATE_NOTICES_ACTIVE_ID,
      mode,
      id
    });
  }
}
