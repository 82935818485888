import React, { Component } from 'react';
import { Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Select, Radio, InputNumber, Spin, DatePicker } from 'antd';
import client from '../../feathers';
import requireAuth from "../requireAuth";
import Layout from "../layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateMode } from '../../actions/actions-refunds';
import axios from 'axios';
import { Link, withRouter, Route } from 'react-router-dom';
import moment from 'moment';
import {
  updateValuePropertyUnitId
} from '../../actions/actions-commons';

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
const _ = require('lodash');

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;
const text = 'Are you sure to delete this item?';

class RefundFormItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',
      confirmDirty: false,
      autoCompleteResult: [],
      mode: this.props.mode,
      visible: false,
      invoice: [],
      loading: false,
      paymentTypeParams: [],
      paymentType: '',    //CASH, CREDIT CARD, ONLINE BANKING, CHEQUE
      selectedRowKeys: [],
      totalAmount: 0,
      loading: false,
      refundDate: moment(new Date()).format("YYYY-MM-DD"),

    }
  }

  componentDidUpdate() {

    if (this.props.totalAmount !== this.state.totalAmount) {
      this.setState({ totalAmount: this.props.totalAmount });
      this.props.form.setFieldsValue({ totalAmount: this.props.totalAmount });

    }

    if (this.props.invoiceItems !== this.state.invoiceItems) {
      this.setState({ invoiceItems: this.props.invoiceItems });
    }

    if (this.props.selectedRowKeys !== this.state.selectedRowKeys) {
      this.setState({ selectedRowKeys: this.props.selectedRowKeys });

    }


  }

  componentDidMount() {
  }

  componentWillMount() {

    this.getPaymentTypeParams();

  }

  getPaymentTypeParams() {
    client.authenticate()
      .then((res) => {
        return client.service('payment-type-params').find({
          query: {
            propertyId: this.props.commons.selectedCompany._id,
            category: {
              $in: ['REFUND']
            },
          }
        })
      })
      .then((res) => {
        this.setState({
          paymentTypeParams: res.data
        });
      })
      .catch((err) => {
        console.log(err);
      });

  }

  handleConfirmBlur = (e) => {

  }

  handleChange = (value) => {

    let change = value - this.props.form.getFieldValue('totalAmount');

    if (change < 0) {
      this.props.form.setFieldsValue({
        change: change,
      });
    } else {
      this.props.form.setFieldsValue({
        change: this.formatAmt(change),
      });
    }

  }

  handleChangePaymentType = (e) => {
    this.setState({ paymentType: e.target.description });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {

      if (!err) {

        values.refundDate = moment(new Date(values.refundDate)).format("YYYY-MM-DD")
        for (let i = 0; i < this.state.invoiceItems.length; i++) {

          client.service('refunds').create({

            ...values,
            refundDate: new Date(),
            propertyId: this.props.commons.selectedCompany._id,
            invoiceItemId: this.state.invoiceItems[i]._id,
            knockOffBalance: this.state.invoiceItems[i].knockOffBalance,
            trxDate: this.state.invoiceItems[i].knockOffDate,
            refundAmt: this.state.invoiceItems[i].knockOffBalance,
            refundDate: values.refundDate,
            propertyunitId: this.props.propertyunitId,
            description: this.state.invoiceItems[i].description,

          })
            .then((res) => {
              message.success('Refund Record Added');
              this.setState({ visible: false });
              this.setState({ loading: false });

              // if(this.props.commons.propertyUnitId)
              //     {      this.props.history.push('/deposit')}
              // else{      this.props.history.push('/propertyunitsmng')}



            })

          if (this.props.commons.propertyUnitId) { this.props.history.push('/deposit') }
          else { this.props.history.push('/propertyunitsmng') }


        }

        //----------- Log to refund

      }
      this.setState({ loading: false });
    });
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  }

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  }

  formatAmt(amt) {
    let tempAmt = amt;

    return tempAmt > 0 ? tempAmt.toFixed(2) : 0.00;
  }

  //loading after submit
  toggle = (value) => {
    this.setState({ loading: false });
  }

  renderPaymentField() {
    const { getFieldDecorator } = this.props.form;

    if (this.state.paymentType === 'CASH') {

      return ([

      ]);

    }

  }

  render() {
    const { visible, onCancel } = this.props;
    const { getFieldDecorator } = this.props.form;

    return ([
      <Spin key='spinning' spinning={this.state.loading} >

        <Form className="submit-form" onSubmit={this.handleSubmit} >

          <FormItem key='refundDate'
            {...formItemLayout}
            label="Refund Date"
          >
            {getFieldDecorator('refundDate', {
              rules: [{
                required: true, message: 'Please select Refund Date!',
              }],
            })(
              <DatePicker />
            )}
          </FormItem>

          <FormItem key='paymentTypeParamId'
            {...formItemLayout}
            label="Refund Type"
          >
            {getFieldDecorator('paymentTypeParamId', {
              rules: [{
                required: true, message: 'Please select Payment Type!',
              }],
            })(
              <Radio.Group onChange={this.handleChangePaymentType} >
                {this.state.paymentTypeParams.map(dropdown => (
                  <Radio.Button key={dropdown._id} value={dropdown._id} description={dropdown.description} >
                    {dropdown.description}
                  </Radio.Button>
                ))}
              </Radio.Group>
            )}
          </FormItem>

          <FormItem key='chequeNo'
            {...formItemLayout}
            label="Cheque No."
          >
            {getFieldDecorator('chequeNo', {
              rules: [{
                required: false, message: 'Please input Cheque No!',
              }],
            })(
              <Input />
            )}
          </FormItem>

          <FormItem key='totalAmount'
            {...formItemLayout}
            label="Total Amt"
          >
            {getFieldDecorator('totalAmount', {
              rules: [{
                required: true, message: 'Please input Amount!',
              }],
            })(
              <Input disabled />
            )}
          </FormItem>



          {this.renderPaymentField()}

          <FormItem key='remark'
            {...formItemLayout}
            label="Remark"
          >
            {getFieldDecorator('remark', {
              rules: [{
                required: false, message: 'Please input remark!',
              }],
            })(
              <TextArea rows={4} />
            )}
          </FormItem>
          <Button key='submittion' type="primary" htmlType="submit" style={{ float: 'right' }} onClick={this.toggle} >Refund</Button>
        </Form>
      </Spin>
    ])
  }
}


function mapStateToProps(state) {
  return {
    invoices: state.invoices,
    commons: state.commons,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode,
    updateValuePropertyUnitId: updateValuePropertyUnitId
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(withRouter(Form.create()(RefundFormItems))));
