import React, { Component } from 'react'
import { Popconfirm, message, Divider,  Table, Form, Icon, Input, Button,Card } from 'antd'
import { Route, Link } from 'react-router-dom';

import client from '../../feathers'
import requireAuth  from "../../components/requireAuth"
import Layout  from "../../components/layout"
import VendorTypeForm  from "../../components/settings/form-vendortype"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  fetchGlobalParameters,
  pushGlobalParameters,
  removeGlobalParameters,
  updateMode,
  updateActiveGlobalParameters,
  updateGlobalParameters } from '../../actions/actions-globalparameters'



const FormItem = Form.Item;

const text = 'Are you sure to delete this item?';

class VendorTypeIndex extends Component {
  constructor(props) {
    super(props);

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidUpdate(){
  }

  componentDidMount(){
      client.service('globalparameters').on('created', (globalparameters) => {
        this.componentWillMount();
      })

      client.service('globalparameters').on('removed', (globalparameters) => {
        this.componentWillMount();
      })

      client.service('globalparameters').on('updated', (globalparameters) => {
        this.componentWillMount();
      })

      client.service('globalparameters').on('patched', (globalparameters) => {
        this.componentWillMount();
      })
  }



  componentWillMount(){

      client.authenticate()
      .then((res)=>{
          return client.service('globalparameters').find({
            query: {
              parametername:'VENDORTYPE',
                $sort: {
                  createdAt: -1
                }
             }
          })
      })
      .then((res)=>{
        this.props.fetchGlobalParameters(res.data);
      })
      .catch((err)=>{
          console.log(err);
      })
  }

  handleDelete(_id) {
     client.service('globalparameters').patch(_id)
     .then((res)=>{
       message.success('Deleted');
     })
     .catch((err)=>{
       alert(err);
     })
  }

  handleEdit(_id) {
    this.props.updateActiveGLOBALPARAMETERS('edit',_id)
  }

  handleManage(_id) {
    this.props.updateActiveGLOBALPARAMETERS('',_id)
  }

  showModal = () => {
    this.props.updateMode('new');
  }

  renderConfirmDeleteBtn(_id){
    return (
      <Popconfirm placement="topLeft" title={text} onConfirm={()=>this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
        <Button type="danger">Delete</Button>
      </Popconfirm>
    )
  }


  render() {
    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title: 'Parameter Name',
        dataIndex: 'parametername',
        key: 'parametername',
      },
      {
        title: 'Variable',
        dataIndex: 'variable',
        key: 'variable',
      },
      {
        title: 'Value',
        dataIndex: 'variableValue1',
        key: 'variableValue1',
      },
      {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
          <Divider type="vertical " />
          <Button onClick={()=>this.handleEdit(record._id)}>Edit</Button>
          <Divider type="vertical" />

          {this.renderConfirmDeleteBtn(record._id)}

        </span>
      ),
    }];

    return(
      <Layout>
        <Card
          title="Vendor Type"
          extra={<Button type="primary" onClick={this.showModal}>New Vendor Type</Button>}
        >
          <Table dataSource={this.props.globalparameters.GlobalParameters} columns={columns} rowKey="_id" />
          <VendorTypeForm />
        </Card>

      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    globalparameters: state.globalparameters,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchGlobalParameters: fetchGlobalParameters,
    pushGlobalParameters: pushGlobalParameters,
    removeGlobalParameters: removeGlobalParameters,
    updateActiveGlobalParameters: updateActiveGlobalParameters,
    updateGlobalParameters: updateGlobalParameters,
    updateMode: updateMode
    }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(VendorTypeIndex)));
