import _ from 'lodash';
import {
  FETCH_USERS,
  PUSH_USERS,
  REMOVE_USERS,
  UPDATE_USERS,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID
} from '../actions/users-actions';

const INITIAL_STATE = {
  users: [],
  mode: 'index',
  activeId: '',
  activeUser: {}
}


export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_USERS:
      return {
        ...state,
        users: action.payload,
      }
    case PUSH_USERS:
      return {
        ...state,
        users: [action.payload, ...state.users],
      }
    case REMOVE_USERS:
      return {
        ...state,
        properties: [...state.users.filter((item) => item._id !== action.payload._id)]
      }
    case UPDATE_USERS:
      let index = _.findIndex(state.users, { '_id': action.payload._id })
      state.users.splice(index, 1, action.payload)
      return {
        ...state,
        properties: state.users
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode: action.payload,
        activeId: '',
        activeUser: {}
      }
    case UPDATE_ACTIVE_ID:
      return {
        ...state,
        mode: action.mode,
        activeId: action.id,
        activeUser: _.pick(_.find(state.users, { '_id': action.id }), ['email', 'role', 'managementId', 'propertyId', 'accountmainId', '_id', 'firstName', 'lastName'])
      }
    default:
      return state

  }
}
