import React, { Component, useEffect, useRef } from "react";
import {
  Modal,
  Upload,
  Popconfirm,
  message,
  Divider,
  Table,
  Form,
  Icon,
  Input,
  Button,
  Select,
  Card,
  Radio,
  Spin,
  Row,
  Col,
  DatePicker,
  Tabs,
} from "antd";
import client from "../../feathers";
import requireAuth from "../requireAuth";
import Layout from "../layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Route, Link } from "react-router-dom";
import ReactToPrint from "react-to-print";
import axios from "axios";
import moment from 'moment';
import RNPYearly from "../../components/reports/index-receipt-and-payment-yearly";
import XLSX from 'sheetjs-style'


//--------------------------------------------------------------------
const _ = require("lodash");
const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
const TabPane = Tabs.TabPane;
const formItemLayout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 16
  }
};
const formTailLayout = {
  labelCol: {
    span: 4
  },
  wrapperCol: {
    span: 8,
    offset: 4
  }
};

const AmountfontSize = {
  float: 'right',
  fontSize: 11
};
const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "MYR",
  minimumFractionDigits: 2
});

const formatCurr = function format1(n = 0.0, currency = null) {
  let oriAmt = n;
  n = n < 0 ? Math.abs(n) : n;
  let amt = currency ? currency : "" + n.toFixed(2).replace(/./g, function (c, i, a) {
    return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
  });

  return oriAmt < 0 ? "(" + amt + ")" : amt;
};

class ReceiptComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      jmb: this.props.jmbs,
      fromDate: moment().startOf('year').format('YYYY-MM-DD'),
      toDate: moment().endOf('year').format('YYYY-MM-DD'),
      transactionsList: [],
    };
  }
  // -----------------------------------------------------------------
  componentWillMount() {
    this.setState({ loading: true });
    let companyId = this.props.companyId

    client.authenticate()
      .then((res) => {
        return axios.get(`${client.io.io.uri}receiptLedgerTrxDetail`, {
          params: {
            companyId: companyId,
            toDate: this.state.toDate,
            fromDate: this.state.fromDate
          },
          headers: {
            'Authorization': client.settings.storage.storage['feathers-jwt']
          }
        })
      })
      .then((res) => {
        this.setState({ transactionsList: res.data, loading: false })
      })

  }
  // -------------------------------
  componentDidUpdate() {
    if (this.props.fromDate !== this.state.fromDate) {
      this.setState({ fromDate: this.props.fromDate }, () => { this.componentWillMount() })
    }
    if (this.props.toDate !== this.state.toDate) {
      this.setState({ toDate: this.props.toDate }, () => { this.componentWillMount() })
    }
  }

  _renderAmount(r) {
    return (
      <span style={{ float: 'right' }}>{formatCurr(r.invoiceItems.knockOffAmount)}</span>
    )
  }

  render() {
    const { jmb, loading, defaultValue, transactionsList } = this.state;
    var { fromDate, toDate } = this.state;

    fromDate = moment(new Date(this.state.fromDate)).format("Do MMM YYYY")
    toDate = moment(new Date(this.state.toDate)).format("Do MMM YYYY")

    const columnsR = [
      {
        title: "Payment Date",
        dataIndex: "paymentDate",
        width: "10%",
        render: (text, record) => (
          <span>{moment(text).format('DD-MM-YYYY')}</span>)
      },
      {
        title: "Category",
        dataIndex: "chargeLedgerAccount.accountName",
        width: "20%",
        render: (v) => (
          <span>{v}</span>)
      },
      {
        title: "Reference",
        dataIndex: "",
        width: "20%"
      },
      {
        title: "Unit No.",
        dataIndex: "propertyUnit.referenceId",
        width: "30%",
      },
      {
        title: (<span style={{ float: 'right' }}>Amount</span>),
        dataIndex: "invoiceItems.knockOffAmount",
        width: "10%",
        render: (text, record) => this._renderAmount(record)
      },
    ]
    let totalAmount = _.sumBy(transactionsList, 'invoiceItems.knockOffAmount')

    return (<div>
      <Spin spinning={loading}>
        <Card>
          <span style={{ fontSize: 20 }}>{jmb.name}</span><br />
          <span>({jmb.registrationNo})</span><br />
          <span style={{ fontSize: 20 }}>Receipt And Payment </span>
          <span className="alignRight" >As at selected date : {fromDate} ~ {toDate}</span>

          <Row>
            <Col span={5}>
              <img width="25%" src={this.state.jmb.headerFile} />
            </Col>
            <Col span={19}>
              <p>
                <span>{jmb.headerLine1}</span>
              </p>
              <p>
                <span>{jmb.headerLine2}</span>
              </p>
              <p>
                <span>{jmb.headerLine3}</span>
              </p>
              <p>
                <span>{jmb.headerLine4}</span>
              </p>
            </Col>
          </Row>

          <Table
            title={() => (<span>Receipt</span>)}
            dataSource={transactionsList}
            columns={columnsR}
            pagination={false}
            size={"small"}
            footer={() => (
              <Row ><span>Total:</span><span style={{ float: "right", borderTop: 'double', borderBottom: 'double' }}>{formatCurr(totalAmount)}</span></Row>
            )}
          />

        </Card>
        <br />


      </Spin>
    </div>);
  }
}
class PrintExcell extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      jbms: this.props.jbms,
      companyId: this.props.companyId._id,
      fromDate: moment().startOf('year').format('YYYY-MM-DD'),
      toDate: moment().endOf('year').format('YYYY-MM-DD'),
      receiptDetailData: [],
      paymentDetailData: [],
      doneLoadingReceipt: false,
      doneLoadingPayment: false

    }
  }

  componentWillMount() {

    client.authenticate()
      .then((res) => {

        axios.get(`${client.io.io.uri}receiptLedgerTrxDetail`, {
          params: {
            companyId: this.state.companyId,
            toDate: this.state.toDate,
            fromDate: this.state.fromDate
          },
          headers: {
            'Authorization': client.settings.storage.storage['feathers-jwt']
          }
        }).then((res) => {
          this.setState({ receiptDetailData: res.data, doneLoadingReceipt: true })

        })


        axios.get(`${client.io.io.uri}paymentLedgerTrxDetail`, {
          params: {
            companyId: this.state.companyId,
            toDate: this.state.toDate,
            fromDate: this.state.fromDate
          },
          headers: {
            'Authorization': client.settings.storage.storage['feathers-jwt']
          }
        }).then((res2) => {
          this.setState({ paymentDetailData: res2.data, doneLoadingPayment: true })
        })

      })



  }
  handlePrintExcell = (e) => {

    const { fromDate, toDate, receiptDetailData, paymentDetailData } = this.state

    var wb = XLSX.utils.book_new();

    let ws = XLSX.utils.aoa_to_sheet([]);
    let cell = XLSX.utils.sheet_add_aoa

    let ws2 = XLSX.utils.aoa_to_sheet([]);
    let cell2 = XLSX.utils.sheet_add_aoa

    cell(ws, [['Receipt Details ' + fromDate + ' to ' + toDate]], { origin: "A1" });
    cell(ws, [['Payment Date', 'Category', 'Reference', 'Unit No', 'Amount']], { origin: "A2" });

    cell2(ws2, [['Payment Details']], { origin: "A1" })
    cell(ws2, [['Payment Date', 'Payment No.', 'Cheque No.', 'Supplier Name', 'Remark', 'Amount']], { origin: "A2" });

    ws["A1"].s = {									// set the style for target cell
      font: {
        name: '宋体',
        sz: 24,
        bold: true,
        color: { rgb: "FFFFAA00" }
      },
    };


    receiptDetailData.map((v) => {
      XLSX.utils.sheet_add_aoa(ws, [[moment(v.paymentDate).format('DD-MM-YYYY'), v.chargeLedgerAccount.accountName, v.invoiceItemId.docId, v.propertyUnit.referenceId, v.invoiceItems.knockOffAmount]], { origin: -1 });
    })

    paymentDetailData.map((v) => {
      XLSX.utils.sheet_add_aoa(ws2, [[moment(v.date).format('DD-MM-YYYY'), v.referenceId, _.get(v.master, ['chequeNo']), v.debtorAccount.accountName, v.remark, v.paymentamount]], { origin: -1 });
    })


    //finalize Data
    XLSX.utils.book_append_sheet(wb, ws, "Receipt Details");
    XLSX.utils.book_append_sheet(wb, ws2, "Payment Details")

    XLSX.writeFile(wb, `${this.props.companyId.name}_R&P_${moment(this.state.fromDate).format('YYYYMMDD')}-${moment(this.state.toDate).format('YYYYMMDD')}.xlsx`, { compression: true });



  }


  render() {
    let { doneLoadingReceipt, doneLoadingPayment } = this.state
    let keepLoading = true
    if (!doneLoadingReceipt && !doneLoadingPayment) {
      keepLoading = true

    }
    else if (doneLoadingReceipt && doneLoadingPayment) {
      keepLoading = false

    }

    return (
      <Button type='primary' loading={keepLoading} onClick={this.handlePrintExcell}>
        Export
      </Button>
    )

  }
}

class PaymentComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      jmb: this.props.jmbs,
      fromDate: moment().startOf('year').format('YYYY-MM-DD'),
      toDate: moment().endOf('year').format('YYYY-MM-DD'),
      transactionsList: []
    };
  }


  componentWillMount() {

    this.setState({ loading: true });
    let companyId = this.props.companyId

    client.authenticate()
      .then((res) => {
        return axios.get(`${client.io.io.uri}paymentLedgerTrxDetail`, {
          params: {
            companyId: companyId,
            toDate: this.state.toDate,
            fromDate: this.state.fromDate
          },
          headers: {
            'Authorization': client.settings.storage.storage['feathers-jwt']
          }
        })
      })
      .then((result) => {
        let transactionsList = _.map(result.data, (v, i) => {
          v.key = i
          return v
        })
        this.setState({ transactionsList: result.data, loading: false })
        return result
      })
      .catch((err) => {
        console.log({ err });
      })

  }

  componentDidUpdate() {
    if (this.props.fromDate !== this.state.fromDate) {
      this.setState({ fromDate: this.props.fromDate }, () => { this.componentWillMount() })
    }
    if (this.props.toDate !== this.state.toDate) {
      this.setState({ toDate: this.props.toDate }, () => { this.componentWillMount() })
    }
  }

  _renderPaymentDate(r) {


    return (
      <span>{moment(r.date).format('DD-MM-YYYY')}</span>
    )
  }

  _renderCheque(v, r) {
    if (r.chequeNo) {
      return r.chequeNo
    }
    return v
  }


  render() {
    const { jmb, loading, defaultValue, transactionsList } = this.state;
    var { fromDate, toDate } = this.state;

    fromDate = moment(new Date(this.state.fromDate)).format("Do MMM YYYY")
    toDate = moment(new Date(this.state.toDate)).format("Do MMM YYYY")

    const columnsRnP = [
      {
        title: "Payment Date",
        dataIndex: "date",
        width: "10%",
        render: (text, record) => this._renderPaymentDate(record),
        // sorter: {
        //   compare: (a, b) => a.date - b.date,
        //   multiple: 1,
        // },
      },
      {
        title: "Payment No.",
        dataIndex: "referenceId",
        width: "10%",
        // sorter: {
        //   compare: (a, b) => a.referenceId - b.referenceId,
        //   multiple: 2,
        // },
      },
      {
        title: "Cheque No",
        dataIndex: "master.chequeNo",
        width: "10%",
        render: (v, r) => this._renderCheque(v, r)
        // sorter: {
        //   compare: (a, b) => 'a.master.chequeNo' - 'b.master.chequeNo',
        //   multiple: 3,
        // },
      },
      {
        title: "Supplier Name",
        dataIndex: "debtorAccount.accountName",
        width: "15%",
        // sorter: {
        //   compare: (a, b) => 'a.debtorAccount.accountName' - 'b.debtorAccount.accountName',
        //   multiple: 3,
        // },
      },
      {
        title: "Remark",
        dataIndex: "remark",
        width: "40%",
        // sorter: {
        //   compare: (a, b) => a.remark - b.remark,
        //   multiple: 4,
        // },

      },
      {
        title: (<span style={{ float: 'right' }}>Amount</span>),
        dataIndex: "paymentamount",
        width: "10%",
        render: (text, record) => (
          <span style={{ float: 'right' }}>{formatCurr(text)}</span>)
      },
    ]

    var totalAmount = _.sumBy(transactionsList, 'paymentamount');

    return (<div>
      <Spin spinning={loading}>
        <Card>
          <span style={{ fontSize: 20 }}>{jmb.name}</span><br />
          <span>({jmb.registrationNo})</span><br />
          <span style={{ fontSize: 20 }}>Receipt And Payment </span>
          <span className="alignRight" >As at selected date : {fromDate} ~ {toDate}</span>

          <Row>
            <Col span={5}>
              <img width="25%" src={this.state.jmb.headerFile} />
            </Col>
            <Col span={19}>
              <p><span>{jmb.headerLine1}</span></p>
              <p><span>{jmb.headerLine2}</span></p>
              <p><span>{jmb.headerLine3}</span></p>
              <p><span>{jmb.headerLine4}</span></p>
            </Col>
          </Row>

          <Table
            title={() => (<span>Payment</span>)}
            dataSource={transactionsList}
            columns={columnsRnP}
            pagination={false}
            size={"small"}
            footer={() => (
              <Row ><span>Total:</span><span style={{ float: "right", borderTop: 'double', borderBottom: 'double' }}>{formatCurr(totalAmount)}</span></Row>
            )} />
        </Card>
        <br />
      </Spin>
    </div>);
  }
}

class IndexProfitAndLoss extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fromDate: moment().startOf('year').format('YYYY-MM-DD'),
      toDate: moment().endOf('year').format('YYYY-MM-DD'),
    }
  }

  fromDateOnChange = (e) => {
    this.setState({ fromDate: moment(new Date(e)).format("YYYY-MM-DD") });
  }

  toDateOnChange = (e) => {
    this.setState({ toDate: moment(new Date(e)).format("YYYY-MM-DD") });
  }

  render() {
    return (<Layout>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Detail" key="1">
          <RNPYearly jmbs={this.props.jmbs.jmbs[0]} />
        </TabPane>

        <TabPane tab="Receipt" key="2">
          <Row gutter={24}>
            <Col span={3} offset={16}>
              From Date : <DatePicker defaultValue={moment().startOf('year')} onChange={this.fromDateOnChange} />
            </Col>
            <Col span={3}>
              To Date : <DatePicker defaultValue={moment().endOf('year')} onChange={this.toDateOnChange} />
            </Col>
            <Col span={1}>
              <br />
              <PrintExcell jmbs={this.props.jmbs.jmbs[0]} companyId={this.props.commons.selectedCompany} ref={el => (this.componentRef = el)} fromDate={this.state.fromDate} toDate={this.state.toDate} />
              {/* <ReactToPrint trigger={() => <Button type="primary">PRINT</Button>} content={() => this.componentRef} /> */}
            </Col>
          </Row>
          <br />

          <ReceiptComponent jmbs={this.props.jmbs.jmbs[0]} companyId={this.props.commons.selectedCompany._id} ref={el => (this.componentRef = el)} fromDate={this.state.fromDate} toDate={this.state.toDate} />
        </TabPane>

        <TabPane tab="Payment" key="3">
          <Row gutter={24}>
            <Col span={3} offset={16}>
              From Date : <DatePicker defaultValue={moment().startOf('year')} onChange={this.fromDateOnChange} />
            </Col>
            <Col span={3}>
              To Date : <DatePicker defaultValue={moment().endOf('year')} onChange={this.toDateOnChange} />
            </Col>
            <Col span={1}>
              <br />
              <PrintExcell jmbs={this.props.jmbs.jmbs[0]} companyId={this.props.commons.selectedCompany} ref={el => (this.componentRef = el)} fromDate={this.state.fromDate} toDate={this.state.toDate} />
              {/* <ReactToPrint trigger={() => <Button type="primary">PRINT</Button>} content={() => this.componentRef} /> */}
            </Col>
          </Row>
          <br />
          <PaymentComponent jmbs={this.props.jmbs.jmbs[0]} companyId={this.props.commons.selectedCompany._id} ref={el => (this.componentRef = el)} fromDate={this.state.fromDate} toDate={this.state.toDate} />
        </TabPane>

      </Tabs>
    </Layout>);
  }
}

function mapStateToProps(state) {
  return {
    jmbs: state.jmbs,
    commons: state.commons
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    //updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(IndexProfitAndLoss)));
