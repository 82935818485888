import React, { Component } from 'react';
import { Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Card, Modal, Spin } from 'antd';
import { Route, Link } from 'react-router-dom';
import client from '../../feathers';
import requireAuth from "../../components/requireAuth";
import Layout from "../../components/layout";
import SettingsForm from "../../components/settings/form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchSettings,
  pushSettings,
  removeSettings,
  updateMode,
  updateActiveSettings,
  updateSettings
} from '../../actions/actions-settings';


const FormItem = Form.Item;

const text = 'Are you sure to delete this item?';

class SettingsIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      password: '',
      loading: false
    }

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidUpdate() {
  }

  componentDidMount() {
    client.service('settings').on('created', (settings) => {
      if (settings.propertyId === sessionStorage.getItem('companyId')) {
        this.componentWillMount();
      }
    })

    client.service('settings').on('removed', (settings) => {
      if (settings.propertyId === sessionStorage.getItem('companyId')) {
        this.componentWillMount();
      }
    })

    client.service('settings').on('updated', (settings) => {
      if (settings.propertyId === sessionStorage.getItem('companyId')) {
        this.componentWillMount();
      }
    })

    client.service('settings').on('patched', (settings) => {
      if (settings.propertyId === sessionStorage.getItem('companyId')) {
        this.componentWillMount();
      }
    })

  }



  componentWillMount() {

    this.setState({
      loading: true,
    });

    client.authenticate()
      .then((res) => {

        return client.service('settings').find({
          query: {
            propertyId: this.props.commons.selectedCompany._id,
            $sort: {
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {
        this.props.fetchSettings(res.data)
        this.setState({
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      })
  }

  handleDelete(_id) {
    client.service('settings').remove(_id)
      .then((res) => {
      })
      .catch((err) => {
        alert(err);
      })
  }

  handleEdit(_id) {
    this.props.updateActiveSettings('edit', _id)
  }

  handleManage(_id) {
    this.props.updateActiveSettings('', _id)
  }

  showModal = () => {
    this.props.updateMode('new');
  }

  showModalRecurring = () => {
    this.setState({
      visible: true,
    });
  }

  confirm = () => {

    this.setState({ loading: true });

    client.authenticate()
      .then((res) => {

        return client.service('applogs').create({
          remark: 'Recurring Invoice',
          status: 'START',
          propertyId: this.props.commons.selectedCompany._id,
        })
      })
      .then((res) => {

        this.setState({ loading: false });
        message.success('Recurring Invoices Created');
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
        alert(err);
      })

  }

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  }

  handleChange = (e) => {
    this.setState({
      password: e,
    });

  }

  renderConfirmDeleteBtn(_id) {
    return (
      <Popconfirm placement="topLeft" title={text} onConfirm={() => this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
        <Button type="danger">Delete</Button>
      </Popconfirm>
    )
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        align: 'right',
        title: 'Due Date Period',
        dataIndex: 'dueDatePeriod',
        key: 'dueDatePeriod',
      },
      {
        align: 'right',
        title: 'Recurring Period',
        dataIndex: 'recurringPeriod',
        key: 'recurringPeriod',
      },
      {
        align: 'right',
        title: 'Recurring Date',
        dataIndex: 'recurringDate',
        key: 'recurringDate',
      },
      {
        title: 'Current/Next Month',
        dataIndex: 'invoiceGenMonth',
        key: 'invoiceGenMonth',
      },
      {
        align: 'right',
        title: 'Recurring Gen Date',
        dataIndex: 'recurringGenDate',
        key: 'recurringGenDate',
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <Divider type="vertical" />
            <Button type='primary' onClick={() => this.handleEdit(record._id)}>Edit</Button>
            {/*<Divider type="vertical" />
          this.renderConfirmDeleteBtn(record._id)*/}
          </span>
        ),
      }];

    return (

      <Layout>
        <Spin key='spinning' spinning={this.state.loading}>
          <Card
            title="Settings"
          // extra={<Button type="primary" onClick={this.showModalRecurring}>
          //         Recurring Invoice
          //       </Button>}
          //extra={<Button type="primary" onClick={this.showModal}>New</Button>}
          >

            <Table dataSource={this.props.settings.settings} columns={columns} rowKey="_id" />

            <SettingsForm />
          </Card>
        </Spin>
        <Modal
          title="Create Recurring"
          visible={this.state.visible}
          onOk={this.confirm}
          onCancel={this.handleCancel}
        >
          Password:<Input onChange={this.handleChange} />
        </Modal>

      </Layout>

    )

  }
}


function mapStateToProps(state) {
  return {
    settings: state.settings,
    commons: state.commons
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchSettings: fetchSettings,
    pushSettings: pushSettings,
    removeSettings: removeSettings,
    updateActiveSettings: updateActiveSettings,
    updateSettings: updateSettings,
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(SettingsIndex)));
