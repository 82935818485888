
import React, { Component } from 'react';
import { Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Card, Spin } from 'antd';
import { Route, Link } from 'react-router-dom';

import client from '../../feathers';
import requireAuth from "../../components/requireAuth";
import Layout from "../../components/layout";
import FilesForm from "../../components/files/form";


import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchFiles,
  pushFiles,
  removeFiles,
  updateMode,
  updateActiveFiles,
  updateFiles
} from '../../actions/actions-files';

const FormItem = Form.Item;

const text = 'Are you sure to delete this item?';

class FilesIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {

      loading: true,

    }

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidUpdate() {

    if (this.props.files.mode === 'refresh') {
      this.componentWillMount()
    }


  }

  componentDidMount() {

  }

  componentWillMount() {
    this.props.updateMode('')
    client.authenticate()
      .then((res) => {

        return client.service('files').find({
          query: {
            propertyId: this.props.user.info.propertyId._id,
            $sort: {
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {

        this.props.fetchFiles(res.data)
        this.setState({ loading: false })
      })
      .catch((err) => {
        console.log(err);
      })
  }

  handleDelete(_id) {
    client.service('files').remove(_id)
      .then((res) => {
        this.props.updateMode('refresh')
      })
      .catch((err) => {
        alert(err);
      })
  }

  handleEdit(_id) {
    this.props.updateActiveFiles('edit', _id)
  }

  handleUploadEdit(_id) {
    this.props.updateActiveFiles('upload', _id)
  }

  handleManage(_id) {
    this.props.updateActiveFiles('', _id)
  }

  showModal = () => {
    this.props.updateMode('new')
  }

  renderConfirmDeleteBtn(_id) {
    return (
      <Popconfirm placement="topLeft" title={text} onConfirm={() => this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
        <Button type="danger">Delete</Button>
      </Popconfirm>
    )
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title: 'Remark',
        dataIndex: 'remark',
        key: 'remark',
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <Button onClick={() => this.handleEdit(record._id)}>Edit</Button>
            <Divider type="vertical" />
            <Button onClick={() => this.handleUploadEdit(record._id)}>Update Image</Button>
            <Divider type="vertical" />
            {this.renderConfirmDeleteBtn(record._id)}
          </span>
        ),
      }];

    return (
      <Layout>
        <Spin spinning={this.state.loading}>
          <Card
            title="Files"
            extra={<Button type="primary" onClick={this.showModal}>New File</Button>}
          >


            <Table dataSource={this.props.files.files} columns={columns} rowKey="_id" />


            <FilesForm />
          </Card>
        </Spin>
      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    files: state.files,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchFiles: fetchFiles,
    pushFiles: pushFiles,
    removeFiles: removeFiles,
    updateActiveFiles: updateActiveFiles,
    updateFiles: updateFiles,
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(FilesIndex)));
