import React, { Component } from 'react';
import { Popconfirm, message, Divider,  Table, Form, Icon, Input, Button,Tabs,Card,Select,DatePicker,Row,Col } from 'antd';
import { Route, Link } from 'react-router-dom';

import client from '../../feathers';
import requireAuth  from "../requireAuth";
import Layout  from "../layout";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchAccounts,
  pushAccounts,
  removeAccounts,
  updateMode,
  updateActiveAccounts,
  updateAccounts } from '../../actions/actions-accounts';

const FormItem = Form.Item;
const TabPane = Tabs.TabPane;
const { Option, OptGroup } = Select;
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const text = 'Are you sure to delete this item?';

class ProfitAndLossIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fromDate:'2019-01-01',
      toDate:'2019-12-31',
      incomes : [],
      expenses : [],
      totalIncomes : 0,
      totalExpenses : 0,
    }

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidUpdate(){
  }

  componentDidMount(){
 
  }

  componentWillMount(){
    this.handleClick();
  }

  handleClick = () => {

    this.setState({
      netProfit:0,
    });

      //incomes
      client.authenticate()
      .then((res)=>{

          return client.service('accounts').find({
            query: {
              $limit:99,
              accountmainId:sessionStorage.getItem('accountmainId'),
              categories:'incomes',
               $sort: {
                 accountId: 1,
                 subAccountId: 1
               },
               $client:{
                fromDate:this.state.fromDate,
                toDate:this.state.toDate,
               }
            },
          })
      })
      .then((res)=>{

        this.setState({
          incomes:res.data,
          totalIncomes : res.totalCreditBalance-res.totalDebitBalance,
        });
      })
      .catch((err)=>{
          console.log(err);
      })
      //expenses
      client.authenticate()
      .then((res)=>{

          return client.service('accounts').find({
            query: {
              $limit:99,
              accountmainId:sessionStorage.getItem('accountmainId'),
              categories:'expenses',
               $sort: {
                 accountId: 1,
                 subAccountId: 1
               },
               $client:{
                fromDate:this.state.fromDate,
                toDate:this.state.toDate,
               }
            },
          })
      })
      .then((res)=>{

        this.setState({
          expenses:res.data,
          totalExpenses : res.totalDebitBalance-res.totalCreditBalance,
        });
      })
      .catch((err)=>{
          console.log(err);
      })
  }

  fromDateOnChange = (e) => {
    this.setState({
      fromDate:e.target.value
    });
  }

  toDateOnChange = (e) => {
    this.setState({
      toDate:e.target.value
    });
  }

  handleDelete(_id) {
     client.service('accounts').remove(_id)
     .then((res)=>{
     })
     .catch((err)=>{
       alert(err);
     })
  }

  handleEdit(_id) {
    this.props.updateActiveAccounts('edit',_id)
  }

  handleManage(_id) {
    this.props.updateActiveAccounts('',_id)
  }

  showModal = () => {
    this.props.updateMode('new')
  }

  renderConfirmDeleteBtn(_id){
    return (
      <Popconfirm placement="topLeft" title={text} onConfirm={()=>this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
        <Button type="danger">Delete</Button>
      </Popconfirm>
    )
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        width: '50%',
      },
      {
        title: 'Balance',
        dataIndex: 'balance',
        key: 'balance',
      },
    ];

    return(
      <Layout>
        <Card
          title={'Profit and Loss'}
          extra={[
            <input onChange={this.fromDateOnChange} type='date' defaultValue={this.state.fromDate}/>,
            <input onChange={this.toDateOnChange} type='date' defaultValue={this.state.toDate} />,
            <Button onClick={this.handleClick} type="primary">Search</Button>
          ]}
        >
          <Card
            title={'Income : '+this.state.totalIncomes}
          >
            <Table dataSource={this.state.incomes} columns={columns} rowKey="_id" />
          </Card>
          <Card
            title={'EXPENSES : '+this.state.totalExpenses}
          >
            <Table dataSource={this.state.expenses} columns={columns} rowKey="_id" />
          </Card>
          {/*<Table dataSource={this.props.accounts.accounts} columns={columns} rowKey="_id" />*/}

          <Card title='NET Profit' >
              <Row>
                <Col span={12}>
                </Col>
                <Col span={12}>
                  {this.state.totalIncomes-this.state.totalExpenses}
                </Col>
              </Row>
          </Card>

        </Card>
      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    accounts: state.accounts,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchAccounts: fetchAccounts,
    pushAccounts: pushAccounts,
    removeAccounts: removeAccounts,
    updateActiveAccounts: updateActiveAccounts,
    updateAccounts: updateAccounts,
    updateMode: updateMode
    }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(ProfitAndLossIndex)));
