export const FETCH_ACCOUTINGPERIODS = 'FETCH_ACCOUTINGPERIODS';
export const PUSH_ACCOUTINGPERIODS = 'PUSH_ACCOUTINGPERIODS';
export const REMOVE_ACCOUTINGPERIODS = 'REMOVE_ACCOUTINGPERIODS';
export const UPDATE_ACCOUTINGPERIODS = 'UPDATE_ACCOUTINGPERIODS';
export const UPDATE_MODE = 'UPDATE_MODE';
export const UPDATE_ACTIVE_ID = 'UPDATE_ACTIVE_ID';

export function fetchAccountingperiods(data){
  return (dispatch) => {
    dispatch({
      type: FETCH_ACCOUTINGPERIODS,
      payload:data
    });
  }
}

export function pushAccountingperiods(data){
  return (dispatch) => {
    dispatch({
      type: PUSH_ACCOUTINGPERIODS,
      payload:data
    });
  }
}

export function removeAccountingperiods(data){
  return (dispatch) => {
    dispatch({
      type: REMOVE_ACCOUTINGPERIODS,
      payload:data
    });
  }
}

export function updateAccountingperiods(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACCOUTINGPERIODS,
      payload:data
    });
  }
}

export function updateMode(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE,
      payload:data
    });
  }
}

export function updateActiveAccountingperiods(mode, id){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_ID,
      mode,
      id
    });
  }
}
