import React, { Component } from "react";
import {
  Modal,
  message,
  Divider,
  Table,
  Form,
  Input,
  Button,
  Select,
  Card,
  Radio,
  Row,
  Col,
  Spin,
  DatePicker,
} from "antd";
import client from "../../feathers";
import requireAuth from "../requireAuth";
import Layout from "../layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import AccountForm from "../ledgers/account-form";
import {
  updateActiveLedgers,
  fetchLedgers,
} from "../../actions/actions-ledgers";
import moment from "moment";
import axios from "axios";

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const PAGESIZE = 25;
const _ = require("lodash");

const formatCurr = function format1(n = 0.0, currency = null) {
  let oriAmt = n;
  n = n < 0 ? Math.abs(n) : n;

  try {
    let amt = currency
      ? currency
      : "" +
        n.toFixed(2).replace(/./g, function(c, i, a) {
          return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
        });
    return oriAmt < 0 ? "(" + amt + ")" : amt;
  } catch (e) {
    return "0.00";
  }
};

class LedgerIndexLedgerAccounts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ledgerAccounts: [],
      filerAccountName: "",
      //filerCategories:'ASSETS',
      visible: false,
      accountTypeDropdowns: [],
      searchCategories: "",
      accountTypeDropdownsValue: "",
      totalPagination: 1,
      CurrentPagination: 1,
      loading: false,
      CurrentPage: 1,
      ledgers: [],
      visiblePrintAll: false,
      allAmt: "",
    };
  }

  componentDidMount() {}

  componentWillMount() {
    this.setState({ loading: true });
    let companyId = this.props.commons.selectedCompany._id;
    client.authenticate().then(async (res) => {
      let { data } = await client.service("ledger-accounts").find({
        query: {
          accountNo: { $eq: this.props.match.params.id },
          companyId: this.props.commons.selectedCompany._id,
          eventCode: { $nin: ["PREPAYMENT", "PREPAYMENTUA", "MONTHLYCLOSING"] },
          remark: { $ne: "PREPAYMENT" },
        },
      });
      let allAccList = [];
      if (!_.isEmpty(data)) {
        allAccList = data.map((i) => i._id);
      }

      let ledgerSummary = await axios.get(
        `${client.io.io.uri}ledgerTrxSummarybyAccounts`,
        {
          params: {
            companyId: companyId,
            accountList: allAccList,
            toDate: !_.isEmpty(this.props.selectedDate)
              ? this.props.selectedDate
              : "",
          },
          headers: {
            Authorization: client.settings.storage.storage["feathers-jwt"],
          },
        }
      );

      client
        .service("ledger-accounts")
        .find({
          query: {
            companyId: this.props.commons.selectedCompany._id,
            accountNo: { $eq: this.props.match.params.id },
            // $limit: PAGESIZE,
            // $sort: {
            //     accountNo: 1,
            //     subAccountNo: 1,
            //     categories:1,
            // }
          },
        })
        .then((res) => {
          var ledgerBalance = _.map(res.data, function(item) {
            item = _.merge(
              item,
              _.find(ledgerSummary.data, function(a) {
                return a.accountInfo._id === item._id;
              })
            );
            item._id = _.get(item._id, ["accountId"], item._id);
            return item;
          });

          var getAllAmt = _.sumBy(ledgerBalance, "balance");

          this.props.fetchLedgers(ledgerBalance);

          this.setState({
            totalPagination: res.total,
            allAmt: getAllAmt,
            loading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });
    });

    //  this.handleDropdown();
  }
  //---------------------------------------------------------------
  handlePageChange = (page) => {
    this.setState({ loading: true });
    let companyId = this.props.commons.selectedCompany._id;

    client.authenticate().then(async (res) => {
      let { data } = await client.service("ledger-accounts").find({
        query: {
          accountNo: { $eq: this.props.match.params.id },
          companyId: this.props.commons.selectedCompany._id,
          eventCode: { $nin: ["PREPAYMENT", "PREPAYMENTUA", "MONTHLYCLOSING"] },
          remark: { $ne: "PREPAYMENT" },
        },
      });
      let allAccList = [];
      if (!_.isEmpty(data)) {
        allAccList = data.map((i) => i._id);
      }

      let ledgerSummary = await axios.get(
        `${client.io.io.uri}ledgerTrxSummarybyAccounts`,
        {
          params: {
            companyId: companyId,
            accountList: allAccList,
            toDate: !_.isEmpty(this.props.selectedDate)
              ? this.props.selectedDate
              : "",
          },
          headers: {
            Authorization: client.settings.storage.storage["feathers-jwt"],
          },
        }
      );
      return client
        .service("ledger-accounts")
        .find({
          query: {
            companyId: this.props.commons.selectedCompany._id,
            accountNo: { $eq: this.props.match.params.id },

            $limit: PAGESIZE,
            $skip: (page - 1) * PAGESIZE,
            $sort: {
              accountNo: 1,
              subAccountNo: 1,
              categories: 1,
            },
          },
        })
        .then((res) => {
          var ledgerBalance = _.map(res.data, function(item) {
            item = _.merge(
              item,
              _.find(ledgerSummary.data, function(a) {
                return a.accountInfo._id === item._id;
              })
            );
            item._id = _.get(item._id, ["accountId"], item._id);
            return item;
          });
          this.props.fetchLedgers(ledgerBalance);
          this.setState({
            totalPagination: res.total,
            // ledgerAccounts:res.data,
            loading: false,
            CurrentPage: page,
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });
    });

    //  this.handleDropdown();
  };
  //---------------------------------------------------------------
  handleDropdown = (e) => {
    this.props.form.resetFields();

    this.state.filerCategories = e;
    this.state.accountTypeDropdowns = [];
    this.state.accountTypeDropdownsValue = "";

    //     this.props.form.resetFields();
    //
    //     this.state.filerCategories =e ;
    //     this.state.accountTypeDropdowns = [];
    //     this.state.accountTypeDropdownsValue = '';
    //
    //dropdown account type
    this.setState({ accountTypeDropdownsValue: [] }, () => {
      client
        .authenticate()
        .then(() => {
          return client.service("parameters").find({
            query: {
              table: this.state.filerCategories,
              $sort: {
                createdAt: -1,
              },
            },
          });
        })
        .then((res) => {
          this.setState({
            accountTypeDropdowns: res.data[0].items,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  componentDidUpdate() {}
  //---------------------------------------------------------------
  handleConfirmBlur = (e) => {};
  //---------------------------------------------------------------
  handleFilter = () => {
    this.setState({ loading: true });
    let filerAccountName =
      this.state.filerAccountName === ""
        ? ""
        : { accountName: this.state.filerAccountName };
    let filerCategories =
      this.state.filerCategories === ""
        ? ""
        : { categories: this.state.filerCategories };
    let collection = { ...filerAccountName, ...filerCategories };

    client
      .authenticate()
      .then((res) => {
        //  this.handleDropdown();
        return client.service("ledger-accounts").find({
          query: {
            companyId: this.props.commons.selectedCompany._id,
            accountNo: { $eq: this.props.match.params.id },
            ...collection,
            $limit: PAGESIZE,
            $sort: {
              accountNo: 1,
              subAccountNo: 1,
              categories: 1,
            },
          },
        });
      })
      .then((res) => {
        this.props.fetchLedgers(res.data);
        this.setState({
          totalPagination: res.total,
          ledgerAccounts: res.data,
          loading: false,
          CurrentPage: 1,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
        alert(err);
      });
  };
  //---------------------------------------------------------------
  handleFilterAccountName = (e) => {
    this.setState({
      filerAccountName: e.target.value,
    });
  };
  //---------------------------------------------------------------
  handleFilterCategories = (e) => {
    this.setState(
      {
        filerCategories: e,
      },
      () => {
        this.handleFilter();
      }
    );

    //  this.state.searchCategories =e;
    //this.state.filerCategories =e;
  };
  //---------------------------------------------------------------
  handleSubmit = (e) => {
    try {
      e.preventDefault();
    } catch (err) {}
    this.props.form.validateFields((err, values) => {
      if (!err) {
        client
          .service("ledger-accounts")
          .create({
            companyId: this.props.commons.selectedCompany._id,
            companyType: "PROPERTY",
            categories: this.state.filerCategories,
            accountType: values.accountType,
            accountName: values.accountName,
            accountNo: values.accountNo,
            subAccountNo: values.subAccountNo,
            accountCurrency: "MYR",
            description: values.description,
            bankAccountFlag: values.bankAccountFlag,
          })
          .then((res) => {
            this.setState({
              visible: false,
            });
            this.componentWillMount();
            message.success("New Accounts Added");
          })
          .catch((err) => {
            console.log(err);
            this.setState({
              visible: false,
            });
            alert(err);
          });
      }
    });
  };
  //---------------------------------------------------------------

  handleSubmitPrintAll = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (err && this.state.visiblePrintAll === true) {
        let fromdate = moment(new Date(values.fromDate)).format("YYYYMMDD");
        let todate = moment(new Date(values.toDate)).format("YYYYMMDD");

        this.props.history.push();

        this.props.history.push({
          pathname: "/soaGLPrintAll/" + this.props.commons.selectedCompany._id,
          state: { fromdate: fromdate, todate: todate },
        });
      }
    });
  };
  //---------------------------------------------------------------
  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  //---------------------------------------------------------------
  showModalPrintAll = () => {
    this.setState({
      visiblePrintAll: true,
    });
  };
  //---------------------------------------------------------------
  handleEdit(_id) {
    this.props.updateActiveLedgers("edit", _id);
  }
  //---------------------------------------------------------------
  handleCancel = (e) => {
    this.setState({
      visible: false,
      visiblePrintAll: false,
    });
  };

  //---------------------------------------------------------------
  render() {
    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title: (
          <Row gutter={8}>
            <Row>
              <Col span={24}>Account No.</Col>
            </Row>
            <Row>
              <Col span={24}>
                <br />
              </Col>
            </Row>
          </Row>
        ),
        dataIndex: "accountNo",
        key: "accountNo",
        width: "10%",
        render: (text, record) => (
          <span>{record.accountNo + "/" + record.subAccountNo}</span>
        ),
      },
      {
        title: (
          <Row gutter={8}>
            <Row>
              <Col span={24}>Account Name</Col>
            </Row>
            <Row>
              <Col span={24}>
                <br />
              </Col>
            </Row>
          </Row>
        ),
        dataIndex: "accountName",
        key: "accountName",
        width: "20%",
      },
      {
        title: (
          <Row gutter={8}>
            <Row>
              <Col span={24}>Description</Col>
            </Row>
            <Row>
              <Col span={24}>
                <br />
              </Col>
            </Row>
          </Row>
        ),
        dataIndex: "description",
        key: "description",
        width: "20%",
      },
      {
        title: (
          <Row gutter={8}>
            <Row>
              <Col span={24}>Categories</Col>
            </Row>
          </Row>
        ),
        dataIndex: "categories",
        key: "categories",
        width: "10%",
      },
      {
        title: (
          <Row gutter={8}>
            <Row>
              <Col span={24}>Account Type</Col>
            </Row>
            <Row>
              <Col span={24}>
                <br />
              </Col>
            </Row>
          </Row>
        ),
        dataIndex: "accountType",
        key: "accountType",
        width: "10%",
      },

      {
        title: (
          <Row gutter={8}>
            <Row>
              <Col span={24}>Balance</Col>
            </Row>
            <Row>
              <Col span={24}>
                <br />
              </Col>
            </Row>
          </Row>
        ),
        dataIndex: "balance",
        key: "balance",
        width: "8%",
        render: (text, record) => {
          return <span>{formatCurr(text)}</span>;
        },
      },
      {
        title: (
          <Row gutter={8}>
            <Row>
              <Col span={24}>Action</Col>
            </Row>
            <Row>
              <Col span={24}>
                <br />
              </Col>
            </Row>
          </Row>
        ),
        key: "action",
        render: (text, record) => (
          <span>
            <Link
              to={{
                pathname: `/ledgers/transactions/${record._id}`,
              }}
            >
              <Button>Transactions</Button>
            </Link>
            <Divider type="vertical" />
            <Button onClick={() => this.handleEdit(record._id)}>Edit</Button>
          </span>
        ),
      },
    ];

    return (
      <Layout>
        <Spin spinning={this.state.loading} key="spinning">
          <Card>
            <Table
              dataSource={this.props.ledgers.ledgers}
              columns={columns}
              rowKey="_id"
              pagination={{
                total: this.state.totalPagination,
                current: this.state.CurrentPage,
                onChange: this.handlePageChange,
                pageSize: 25,
              }}
              footer={() => {
                return (
                  <span style={{ fontWeight: "bold" }}>
                    Total Balance amount : {formatCurr(this.state.allAmt)}
                  </span>
                );
              }}
            />
            <AccountForm />
          </Card>
          <Modal
            title="Details"
            visible={this.state.visible}
            onOk={this.handleSubmit}
            okText={"Submit"}
            onCancel={this.handleCancel}
          >
            <Form className="submit-form">
              <FormItem {...formItemLayout} label="Categories" hasFeedback>
                {getFieldDecorator("categories", {
                  rules: [{ required: true, message: "Select Categories!" }],
                })(
                  <Select
                    style={{ width: 200 }}
                    placeholder="Please select Account Categories"
                    onChange={this.handleDropdown}
                  >
                    <Option value="ASSETS">ASSETS</Option>
                    <Option value="LIABILITIES">LIABILITIES</Option>
                    <Option value="INCOMES">INCOMES</Option>
                    <Option value="EXPENSES">EXPENSES</Option>
                    <Option value="EQUITY">EQUITY</Option>
                  </Select>
                )}
              </FormItem>

              <FormItem {...formItemLayout} label="Account Type" hasFeedback>
                {getFieldDecorator("accountType", {
                  rules: [{ required: true, message: "Select Type!" }],
                })(
                  <Select
                    placeholder="Select Type"
                    value={this.state.accountTypeDropdownsValue}
                  >
                    {this.state.accountTypeDropdowns.map((dropdown) => (
                      <Option value={dropdown.value}>
                        {dropdown.description}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>

              <FormItem {...formItemLayout} label="Account Name">
                {getFieldDecorator("accountName", {
                  rules: [
                    {
                      required: true,
                      message: "Please input Accounts Description!",
                    },
                  ],
                })(<Input placeholder="Lim Motors" />)}
              </FormItem>

              <FormItem {...formItemLayout} label="Account No.">
                {getFieldDecorator("accountNo", {
                  rules: [
                    {
                      required: true,
                      message: "Please input Account Number!",
                    },
                  ],
                })(<Input placeholder="1000" />)}
              </FormItem>

              <FormItem {...formItemLayout} label="Sub Account No">
                {getFieldDecorator("subAccountNo", {
                  rules: [
                    {
                      required: true,
                      message: "Please input Account Number!",
                    },
                  ],
                })(<Input placeholder="000" />)}
              </FormItem>

              {/*
                <FormItem
                {...formItemLayout}
                label="Currency"
              >
                {getFieldDecorator('accountCurrency', {
                  rules: [{
                    required: true, message: 'Please select Currency!',
                  }],
                })(
                  <Select placeholder="Please Select currency">
                    <Option value="MYR">MYR - Malaysia Ringgit</Option>
                  </Select>
                )}
              </FormItem>
              */}

              <FormItem {...formItemLayout} label="Description">
                {getFieldDecorator("description", {
                  rules: [
                    {
                      required: true,
                      message: "Please input description!",
                    },
                  ],
                })(<Input placeholder="" />)}
              </FormItem>

              <FormItem {...formItemLayout} label="Bank Account?">
                {getFieldDecorator("bankAccountFlag", {
                  rules: [
                    {
                      required: true,
                      message: "Please select!",
                    },
                  ],
                })(
                  <Radio.Group>
                    <Radio.Button value="true">true</Radio.Button>
                    <Radio.Button value="false">false</Radio.Button>
                  </Radio.Group>
                )}
              </FormItem>
            </Form>
          </Modal>

          <Modal
            title="Print Statement"
            visible={this.state.visiblePrintAll}
            onOk={this.handleSubmitPrintAll}
            okText={"Submit"}
            onCancel={this.handleCancel}
          >
            <Form className="submit-form">
              <FormItem {...formItemLayout} label="Print Start Date">
                {getFieldDecorator("fromDate", {
                  rules: [
                    {
                      required: true,
                      message: "Please input Print Start Date!",
                    },
                  ],
                  initialValue: moment().startOf("year"),
                })(<DatePicker />)}
              </FormItem>

              <FormItem {...formItemLayout} label="Print End Date">
                {getFieldDecorator("toDate", {
                  rules: [
                    {
                      required: true,
                      message: "Please input print End Date!",
                    },
                  ],
                  initialValue: moment().endOf("year"),
                })(<DatePicker />)}
              </FormItem>
            </Form>
          </Modal>
        </Spin>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    ledgers: state.ledgers,
    commons: state.commons,
    selectedDate: state.ledgeraccounts.selectedDate,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      // updateMode: updateMode
      updateActiveLedgers: updateActiveLedgers,
      fetchLedgers: fetchLedgers,
    },
    dispatch
  );
}

export default requireAuth(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Form.create()(LedgerIndexLedgerAccounts))
);
