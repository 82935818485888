import React, { Component } from 'react';
import { Modal, Popconfirm, Popover, message, Divider, Table, Form, Icon, Input, Button, Select, Radio, Card, Row, Col, Spin, Tree } from 'antd';
import client from '../../feathers';
import requireAuth from "../requireAuth";
import Layout from "../layout";
import Info from "../common/info";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchledgeraccounts
} from '../../actions/actions-chartOfAccounts';

const { TreeNode } = Tree;
const _ = require("lodash");

class ChartOfAccountsPrint extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      ledgeraccounts: [],
      ledgeraccountsChild: [],
      visible: false,
      loading: false,
      companyName: '',
      companyRegistrationNo: '',
    }
  }

  parentsLedger(type, ac_type) {

    let a = _.map(this.state.ledgeraccounts, function (o) {

      if (o.accountType === type && o.accountNo != '2000') {
        return o;
      }
    });
    a = _.without(a, undefined)
    a = _.orderBy(a, ['accountNo', 'subAccountNo'], ['asc', 'asc'])

    if (a.length > 0) {
      return (
        a.map(dropdown => (
          <TreeNode title=
            {<div className='wrap-span-tree'>
              <span>{dropdown.accountNo} / {dropdown.subAccountNo}</span>
              <span className='spanMiddleType'>{ac_type}</span>
              <Popover content={dropdown.accountName} trigger="hover">
                <span className='spanMiddleName'>{dropdown.accountName}</span>
              </Popover>
            </div>}
            key={dropdown._id}>
            {this.childLedger(dropdown.accountNo, ac_type)}
          </TreeNode>
        ))
      )
    }
  }

  childLedger(e, ac_type) {

    let a = _.map(this.state.ledgeraccountsChild, function (o) {

      if (o.accountNo === e) {
        return o;
      }
    });
    a = _.without(a, undefined)
    a = _.orderBy(a, ['accountNo', 'subAccountNo'], ['asc', 'asc'])

    if (a.length > 0) {
      return (
        a.map(dropdown => (
          <TreeNode title=
            {<div>{dropdown.accountNo} / {dropdown.subAccountNo}
              <span className='spanMiddleType'>{ac_type}</span>
              <Popover content={dropdown.accountName} trigger="hover">
                <span className='spanMiddleName'>{dropdown.accountName}</span>
              </Popover>
            </div>}
            key={dropdown._id} />
          // <TreeNode title={<td>{dropdown.accountNo +'/'+ dropdown.subAccountNo +
          //               '  '+dropdown.accountName +' '+ ac_type} key={dropdown._id}}</td>}/>

          //   <TreeNode title="leaf" key="0-0-0-0" />
          //   <TreeNode title="leaf" key="0-0-0-1" />
          //   <TreeNode title="leaf" key="0-0-0-2" />
          // </TreeNode>
        ))
      )
    }
  }

  componentWillMount() {

    client.authenticate()
      .then(() => {
        return client.service('ledger-accounts').find({
          query: {
            $sort: {
              accountName: 1,
            },
            companyId: this.props.commons.selectedCompany._id,
            subAccountNo: "000",
          }
        })
      })
      .then((res) => {
        this.props.fetchledgeraccounts(res.data);

        this.setState({
          ledgeraccounts: res.data,
        });
      })
      .catch((err) => {

      });

    //child
    client.authenticate()
      .then(() => {
        return client.service('ledger-accounts').find({
          query: {
            // $sort: {
            //   accountName : 1,
            // },
            companyId: this.props.commons.selectedCompany._id,
            subAccountNo: { $ne: "000" },
          }
        })
      })
      .then((res) => {

        this.props.fetchledgeraccounts(res.data);

        this.setState({
          ledgeraccountsChild: res.data,
          loading: false,
        });
      })
      .catch((err) => {

      })
    //.............................................................................
    client.authenticate()
      .then(() => {
        return client.service('jmbs').find({
          query: {
            propertyId: this.props.commons.selectedCompany._id,
          }
        })
      })
      .then((res) => {
        this.setState({
          companyName: res.data[0].name,
          companyRegistrationNo: res.data[0].registrationNo,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidMount() {
  }

  render() {
    const loading = this.state.loading;
    const { companyName, companyRegistrationNo } = this.state;

    return (
      <Card
        title='Chart of Accounts'>
        <Spin spinning={loading}>
          {/*<h2 align='middle'>Chart of Accounts</h2>*/}
          <h4 align='middle' style={{ marginBottom: 20 }}>{companyName} <span className="registrationNo">({companyRegistrationNo})</span></h4>
          <table className="chartTable">
            <tbody>
              <tr>
                <th className='chartTableHead0'></th>
                <th className='chartTableHead00'>Acc Code</th>
                <th>Description</th>
                <th className='chartTableHead01'>A/C Type</th>
              </tr>
            </tbody>
          </table>
          <Tree showLine onSelect={this.onSelect}>
            <TreeNode title="CAPITAL" key="0">
              {this.parentsLedger('CAPITAL', 'CP')}
            </TreeNode>
            <TreeNode title="CURRENT LIABILITIES" key="1">
              {this.parentsLedger('CURRENT LIABILITIES', 'CL')}
            </TreeNode>
            <TreeNode title="FIXED ASSETS" key="2">
              {this.parentsLedger('FIXED ASSETS', 'FA')}
            </TreeNode>
            <TreeNode title="CURRENT ASSETS" key="3">
              {this.parentsLedger('CURRENT ASSETS', 'CA')}
            </TreeNode>
            <TreeNode title="SALES" key="4">
              {this.parentsLedger('SALES', 'SL')}
            </TreeNode>
            <TreeNode title="FIXED EXPENSES" key="5">
              {this.parentsLedger('FIXED EXPENSES', 'FE')}
            </TreeNode>
            <TreeNode title="VARIABLE EXPENSES" key="6">
              {this.parentsLedger('VARIABLE EXPENSES', 'VE')}
            </TreeNode>
          </Tree>
        </Spin>
      </Card>
    );
  }
}



function mapStateToProps(state) {
  return {
    chartOfAccounts: state.chartOfAccounts,
    commons: state.commons
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchledgeraccounts: fetchledgeraccounts,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(ChartOfAccountsPrint));
