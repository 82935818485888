import React, { Component } from 'react'
import { Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Card, Spin } from 'antd'
import { Route, Link } from 'react-router-dom';

import client from '../../feathers'
import requireAuth from "../../components/requireAuth"
import Layout from "../../components/layout"
import NoticesForm from "../../components/notices/form"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  fetchNotices,
  pushNotices,
  removeNotices,
  updateMode,
  updateActiveNotices,
  updateNotices
} from '../../actions/actions-notices'


const FormItem = Form.Item;

const text = 'Are you sure to delete this item?';

class NoticesIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    }
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidUpdate() {

    if (this.props.notices.mode === 'refresh') {
      this.componentWillMount()
    }
  }

  componentDidMount() {

  }

  componentWillMount() {

    this.props.updateMode('')

    this.setState({ loading: true });

    client.authenticate()
      .then((res) => {

        return client.service('notices').find({
          query: {
            propertyId: this.props.user.info.propertyId._id,
            $sort: {
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {
        this.props.fetchNotices(res.data)
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err);
      })
  }

  handleDelete(_id) {
    this.setState({
      loading: true,
    });
    client.service('notices').remove(_id)
      .then((res) => {
        message.success('Record Deleted');
        this.props.updateMode('refresh')
        this.setState({ loading: false });
      })
      .catch((err) => {
        alert(err);
      })
  }

  handleEdit(_id) {
    this.props.updateActiveNotices('edit', _id)
  }

  handleManage(_id) {
    this.props.updateActiveNotices('', _id)
  }

  showModal = () => {
    this.props.updateMode('new')
  }

  renderConfirmDeleteBtn(_id) {
    return (
      <Popconfirm placement="topLeft" title={text} onConfirm={() => this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
        <Button type="danger">Delete</Button>
      </Popconfirm>
    )
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title: 'Subject',
        dataIndex: 'subject',
        key: 'subject',
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: 'Priority',
        dataIndex: 'priority',
        key: 'priority',
      },
      {
        title: 'Target',
        dataIndex: 'target',
        key: 'target',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <Divider type="vertical" />
            <Button onClick={() => this.handleEdit(record._id)}>Edit</Button>
            <Divider type="vertical" />
            {this.renderConfirmDeleteBtn(record._id)}
          </span>
        ),
      }];

    return (
      <Layout>
        <Spin spinning={this.state.loading}>
          <Card
            title="Notices"
            extra={<Button type="primary" onClick={this.showModal}>New Notices</Button>}
          >

            <Table dataSource={this.props.notices.notices} columns={columns} rowKey="_id" />

            <NoticesForm />
          </Card>
        </Spin>
      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    notices: state.notices,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchNotices,
    pushNotices,
    removeNotices,
    updateActiveNotices,
    updateNotices,
    updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(NoticesIndex)));
