import React from "react";
import { Divider, Table, Form, Button, Card, Spin, Row, Col } from "antd";
import client from "../../feathers";
import requireAuth from "../requireAuth";
import Layout from "../layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactToPrint from "react-to-print";
import axios from "axios";
import Info from "../common/info";

const formatCurr = function format1(n = 0, currency = null) {
  let oriAmt = n;
  n = n < 0 ? Math.abs(n) : n;
  n = n ? n : 0;
  let amt = currency
    ? currency
    : "" +
      n.toFixed(2).replace(/./g, function(c, i, a) {
        return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
      });

  return oriAmt < 0 ? "(" + amt + ")" : amt;
};

const formatter = new Intl.NumberFormat("en-US", {
  // style: 'currency',
  // currency: 'MYR',
  minimumFractionDigits: 2,
});

class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      jmb: {},
      loading: false,
      invoiceAndItems: [],
      propertyunit: [],
      setting: [],
      CurrentPagination: 1,
      totalRecord: 0,
    };
  }

  componentWillMount() {
    let companyId = this.props.companyId;

    this.setState({ loading: true });
    client
      .authenticate()
      //get JMB INFO
      .then((res) => {
        return client.service("jmbs").find({
          query: {
            $populate: "propertyId",
            propertyId: companyId,
            status: "ACTIVE",
            $sort: {
              createdAt: -1,
            },
            $limit: 1,
          },
        });
      })
      .then((res) => {
        this.setState({ jmb: res.data[0] });
      })
      .then(() => {
        return client.service("settings").find({
          query: { propertyId: companyId },
        });
      })
      .then((res) => {
        return this.setState({ setting: res.data[0].tnc });
      })
      .then((res) => {
        return axios.get(`${client.io.io.uri}applogs-invoices`, {
          params: {
            id: this.props.id,
            //skip:0,
          },
          headers: {
            Authorization: client.settings.storage.storage["feathers-jwt"],
          },
        });
      })
      .then((res) => {
        this.setState({
          dataSource: res.data.invoiceAndItems,
          loading: false,
          totalRecord: res.data.total,
        });

        // setTimeout(() => {
        //   if (this.state.dataSource.length < this.state.totalRecord) {
        //     this.handleLoadMore();
        //   }
        // }, 1000);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleLoadMore = () => {
    return;
  };

  convertDate(date = null) {
    let tempDate = new Date(date);
    let d = tempDate.getDate();
    let m = tempDate.getMonth() + 1;
    let y = tempDate.getFullYear();
    return date ? d + "/" + m + "/" + y : "";
  }

  componentDidUpdate() {}

  _Field_OutStanding(data) {
    return [
      <Info
        type="amount"
        label="Previous Outstanding"
        labelSpan={14}
        value={formatCurr(data.invoice.previousOutstanding)}
        valueSpan={10}
      />,
    ];
  }

  _Field_TotalBillAmount(invoice) {
    if (invoice.invoiceType !== "OTHERS") {
      return [
        <Info
          type="amount"
          label="Outstanding"
          labelSpan={14}
          value={formatCurr(invoice.previousOutstanding)}
          valueSpan={10}
        />,
      ];
    }
  }

  _Field_NetAmount(invoice) {
    return [
      <Info
        type="amount"
        bold
        label="Net Amount"
        labelSpan={14}
        value={formatCurr(invoice.totalAmt + invoice.previousOutstanding)}
        valueSpan={10}
      />,
    ];
  }

  _renderDescription(v) {
    let data = v.split("<br />");
    // data.map(i=>{
    //   console.log({i});
    // })
    // return v
    return data.map((i) => (
      <span style={{}}>
        {i} <br />
      </span>
    ));
  }

  _renderTable = () => {
    const dataSource = this.state.dataSource;
    const loading = this.state.loading;
    const jmb = this.state.jmb;
    let table = [];

    const columns = [
      {
        title: "Doc ID",
        dataIndex: "docId",
        key: "docId",
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        render: (v) => this._renderDescription(v),
      },
      {
        title: "Effective Date",
        dataIndex: "effectiveDate",
        key: "effectiveDate",
        render: (text, record) => <span>{this.convertDate(text)}</span>,
      },
      {
        title: "Due Date",
        dataIndex: "dueDate",
        key: "dueDate",
        render: (text, record) => <span>{this.convertDate(text)}</span>,
      },
      {
        title: <span style={{ float: "right" }}>Amount</span>,
        dataIndex: "appliedAmount",
        key: "appliedAmount",
        render: (text, record) => (
          <span style={{ float: "right" }}>
            {formatter.format(record.appliedAmount)}
          </span>
        ),
      },
    ];

    // Outer loop to create parent
    for (let i = 0; i < dataSource.length; i++) {
      let remain = dataSource[i].invoiceItem.length % 10;
      let totalPage =
        parseInt(dataSource[i].invoiceItem.length / 10) + (remain > 0 ? 1 : 0);
      for (let a = 0; a < totalPage; a++) {
        table.push([
          <Card>
            <Row>
              <Col span={3}>
                <img width="100%" src={jmb.headerFile} />
              </Col>
              <Col span={14} offset={1}>
                <p style={{ fontWeight: "bolder", color: "#000" }}>
                  {jmb.managementName} ({jmb ? jmb.registrationNo : ""})
                </p>
                <p style={{ marginTop: -15 }}>
                  <span>{jmb ? jmb.headerLine1 : ""} </span>
                </p>
                <p style={{ marginTop: -15 }}>
                  <span>{jmb ? jmb.headerLine2 : ""}</span>
                </p>
                <p style={{ marginTop: -15 }}>
                  <span>{jmb ? jmb.headerLine3 : ""}</span>
                </p>
                <p style={{ marginTop: -15 }}>
                  <span>{jmb ? jmb.headerLine4 : ""}</span>
                </p>
                <p style={{ marginTop: -15 }}>
                  <span>
                    {jmb ? "Mangement Contact: " + jmb.contactNo : ""}
                  </span>
                </p>
                <p style={{ marginTop: -15 }}>
                  <span>{jmb ? "EMAIL: " + jmb.email : ""}</span>
                </p>
              </Col>
              <Col span={6}>
                <h4>TAX INVOICE/STATEMENT</h4>
                <Info
                  label="Invoice No."
                  labelSpan={10}
                  value={dataSource[i].invoice.invoiceNo}
                  valueSpan={14}
                />
                <Info
                  label="Date"
                  labelSpan={10}
                  value={this.convertDate(dataSource[i].invoice.invoiceDate)}
                  valueSpan={14}
                />
                <Info
                  label="Status"
                  labelSpan={10}
                  value={
                    dataSource[i].invoice.status === "PAID" ? (
                      <span style={{ color: "green" }}>
                        {dataSource[i].invoice.status}
                      </span>
                    ) : (
                      <span style={{ color: "red" }}>
                        {dataSource[i].invoice.status}
                      </span>
                    )
                  }
                  valueSpan={14}
                />

                {this._Field_OutStanding(dataSource[i])}
              </Col>
            </Row>

            <Row>
              <Col span={18}>
                <Info
                  label="Issued To"
                  labelSpan={4}
                  bold
                  value={dataSource[i].invoice.propertyunitId.referenceId}
                  valueSpan={20}
                />
                <Info
                  label="Attn"
                  labelSpan={4}
                  bold
                  value={
                    dataSource[i].invoice.propertyunitId.owner
                      ? dataSource[i].invoice.propertyunitId.owner.name
                      : ""
                  }
                  valueSpan={20}
                />
                <Info
                  label="Mailing Address"
                  bold
                  labelSpan={4}
                  value={
                    dataSource[i].invoice.propertyunitId.owner
                      ? dataSource[i].invoice.propertyunitId.owner
                          .mailingAddress
                      : ""
                  }
                  valueSpan={20}
                />
              </Col>
              <Col span={6}>
                <p>
                  *OUTSTANDING B/F AMOUNT MIGHT BE VARY AS PENDING ADD VALUE
                  MANAGEMENT SERVICES FINAL CONFIRMATION
                </p>
              </Col>
            </Row>

            <Divider dashed>Detail</Divider>
            <div style={{ height: "auto", marginBottom: "20px" }}>
              <Table
                pagination={{ current: a + 1, pageSize: 10, simple: true }}
                dataSource={dataSource[i].invoiceItem}
                columns={columns}
                size={"small"}
                rowKey="dataSource[i].invoice._id"
              />
            </div>
            <Row className="noBorder">
              <Col span={6}>
                <Info
                  label="Remark"
                  labelSpan={6}
                  value={dataSource[i].invoice.remark}
                  valueSpan={18}
                />
              </Col>
              <Col span={8} offset={10}>
                <Info
                  type="amount"
                  label="Current Bill Amount"
                  labelSpan={14}
                  value={formatter.format(
                    dataSource[i].invoice.totalAmt
                      ? dataSource[i].invoice.totalAmt
                      : 0
                  )}
                  valueSpan={10}
                />
                <Info
                  type="amount"
                  label="Total Prepayment Amount"
                  labelSpan={14}
                  value={formatCurr(
                    dataSource[i].invoice.previousOutstanding < 0
                      ? dataSource[i].invoice.previousOutstanding
                      : 0
                  )}
                  valueSpan={10}
                />
                {this._Field_TotalBillAmount(dataSource[i].invoice)}
                {this._Field_NetAmount(dataSource[i].invoice)}
              </Col>

              <div className="invoiceFooter">
                <div className="ql-display-container">
                  <div
                    className="ql-display-editor"
                    dangerouslySetInnerHTML={{
                      __html: this.state.setting
                        ? this.state.setting.invoiceTnc
                        : "",
                    }}
                  />
                </div>
                {/* <div style={{ textAlign: 'left' }} >© 2021 Mr Tech System Holdings Sdn Bhd (1370083-M)</div> */}
              </div>
            </Row>
          </Card>,
          <div className="pageBreak" />,
        ]);
      }
    }
    return table;
  };

  render() {
    const loading = this.state.loading;
    return (
      <Spin spinning={loading}>
        <div>{this._renderTable()}</div>
      </Spin>
    );
  }
}

class Index extends React.Component {
  render() {
    return (
      <Layout>
        <ReactToPrint
          trigger={() => (
            <Row>
              <Col style={{ float: "right", marginBottom: "5px" }}>
                <Button type="primary">Print</Button>
              </Col>
            </Row>
          )}
          content={() => this.componentRef}
        />
        <ComponentToPrint
          ref={(el) => (this.componentRef = el)}
          companyId={this.props.commons.selectedCompany._id}
          id={this.props.match.params.id}
        />
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    //accounts: state.accounts
    commons: state.commons,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      //updateMode: updateMode
    },
    dispatch
  );
}

export default requireAuth(
  connect(mapStateToProps, mapDispatchToProps)(Form.create()(Index))
);
