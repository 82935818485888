import _ from 'lodash';
import {
  FETCH_JOURNALENTRIES,
  PUSH_JOURNALENTRIES,
  REMOVE_JOURNALENTRIES,
  UPDATE_JOURNALENTRIES,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID } from '../actions/actions-journalentries';

const INITIAL_STATE = {
  journalentries: [],
  mode: 'index',
  activeId: '',
  activeJournalEntries:{}
}


export default function(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_JOURNALENTRIES:

      return {
        ...state,
        journalentries:action.payload,
      }
    case PUSH_JOURNALENTRIES:
      return {
        ...state,
        journalentries:[action.payload, ...state.journalentries],
      }
    case REMOVE_JOURNALENTRIES:
      return {
        ...state,
        journalentries : [...state.journalentries.filter( (item) => item._id !== action.payload._id)]
      }
    case UPDATE_JOURNALENTRIES:
      let index =  _.findIndex(state.journalentries, { '_id': action.payload._id })
      state.journalentries.splice(index, 1, action.payload)
      return {
        ...state,
        journalentries : state.journalentries
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode : action.payload,
        activeId:''
      }
    case UPDATE_ACTIVE_ID:
 
      return {
        ...state,
        mode : action.mode,
        activeId:action.id,
        activeJournalEntries: _.pick(_.find(state.journalentries, { '_id': action.id }), ['_id','companyId', 'trxDate','amount','description','remark','referenceId'])
      }
    default:
      return state

  }
}
