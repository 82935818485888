import React, { Component } from 'react';
import {
  Popconfirm,
  message,
  Divider,
  Table,
  Form,
  Icon,
  Input,
  Button,
  Card,
  Row,
  Col,
  Spin,
} from 'antd';
import { Route, Link } from 'react-router-dom';

import client from '../../feathers';
import requireAuth from '../requireAuth';
import Layout from '../layout';
import UsersFormMng from '../users-mng/form-mng';
import UsersFormEditMng from '../users-mng/form-mng-edit';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  fetchUsers,
  pushUsers,
  removeUsers,
  updateMode,
  updateActiveUser,
  updateUsers,
} from '../../actions/users-actions';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const _ = require('lodash');
const FormItem = Form.Item;
const PAGESIZE = 10;

const text = 'Are you sure to disable this user?';
const enableText = 'Are you sure to enable this user?';

class UsersIndexMng extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchEmail: '',
      searchRole: '',
      searchObj: {},
      total: 0,
      skip: 0,
      typingTimeout: 0,
      loading: false,
    };

    this.handleDelete = this.handleDelete.bind(this);
    this._searchEmailChange = this._searchEmailChange.bind(this);
    this._searchRoleChange = this._searchRoleChange.bind(this);
  }

  componentDidUpdate() {}

  componentDidMount() {}

  componentWillMount() {
    this.setState({ loading: true });
    this._getData(0);
  }

  _getData(skip) {
    let { searchEmail } = this.state;
    let orRegexFilter = [];
    client
      .authenticate()
      .then(() => {
        if (!_.isEmpty(searchEmail)) {
          orRegexFilter.push({ email: searchEmail });
        }

        if (!_.isEmpty(orRegexFilter)) {
          orRegexFilter = { orRegexOr: orRegexFilter };
        }

        let searchObj = this.state.searchObj;
        let query = {
          query: {
            role: { $in: ['management', 'admin', 'account', 'executive'] },
            managementId: this.props.user.info.managementId._id,
            ...orRegexFilter,
            $sort: {
              createdAt: -1,
            },
            $skip: skip,
            $limit: PAGESIZE,
          },
        };
        _.assign(query.query, searchObj);
        // console.log(query)
        return client.service('users').find(query);
      })
      .then((res) => {
        // console.log(res)
        this.props.fetchUsers(res.data);
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  _searchEmailChange(e) {
    this.setState(
      {
        searchEmail: e.target.value,
      },
      () => {
        this._getData(0);
      }
    );
  }

  _searchRoleChange(e) {
    clearTimeout(this.state.typingTimeout);

    this.setState({
      searchRole: e.target.value,
      searchObj: _.merge(
        this.state.searchObj,
        e.target.value ? { role: e.target.value } : { role: '.' }
      ),
      typingTimeout: setTimeout(() => {
        this._getData(this.state.skip);
      }, 500),
    });
  }

  handleDelete(_id, status) {
    client
      .service('users')
      .patch(_id, { status: status })
      .then((res) => {
        this._getData(this.state.skip);

        let msg = '';
        if (status === 'INACTIVE') {
          msg = 'disabled';
        } else {
          msg = 'enabled';
        }
        message.success(`User ${msg}`);
      })
      .catch((err) => {
        alert(err);
      });
  }

  handleEdit(_id) {
    this.props.updateActiveUser('edit', _id);
  }

  handleManage(_id) {
    this.props.updateActiveUser('', _id);
  }

  showModal = () => {
    this.props.updateMode('new');
  };

  renderConfirmDeleteBtn(_id, record) {
    const status = _.get(record, ['status'], '');

    if (status === 'INACTIVE') {
      return (
        <Popconfirm
          placement='topLeft'
          title={enableText}
          onConfirm={() => this.handleDelete(_id, 'ACTIVE')}
          okText='Confirm'
          cancelText='Cancel'
        >
          <Button type='primary'>Enable</Button>
        </Popconfirm>
      );
    } else if (_.isEmpty(status) || status === 'ACTIVE') {
      return (
        <Popconfirm
          placement='topLeft'
          title={text}
          onConfirm={() => this.handleDelete(_id, 'INACTIVE')}
          okText='Confirm'
          cancelText='Cancel'
        >
          <Button type='danger'>Disable</Button>
        </Popconfirm>
      );
    }
  }

  _confirmAlert(_id) {
    confirmAlert({
      title: 'Confirm to Delete User?',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.handleDelete(_id),
        },
        {
          label: 'No',
        },
      ],
    });
  }

  //----------------------------------------------

  render() {
    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title: (
          <Row gutter={8}>
            <Row>
              <Col span={24}>Username</Col>
            </Row>
            <Row>
              <Col span={24}>
                <Input
                  value={this.state.searchEmail}
                  onChange={this._searchEmailChange}
                />
              </Col>
            </Row>
          </Row>
        ),
        dataIndex: 'email',
        // key: 'email',
        render: (result) => {
          return <span>{result.slice(0, result.indexOf('@'))}</span>;
        },
      },
      {
        title: (
          <Row gutter={8}>
            <Row>
              <Col span={24}>Role</Col>
            </Row>
            {/* <Row><Col span={24}><Input value={this.state.searchRole} onChange={this._searchRoleChange}/></Col></Row> */}
          </Row>
        ),
        dataIndex: 'role',
        key: 'role',
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <Divider type='vertical' />
            <Button onClick={() => this.handleEdit(record._id)}>Edit</Button>
            <Divider type='vertical' />

            {this.renderConfirmDeleteBtn(record._id, record)}
          </span>
        ),
      },
    ];

    return (
      <Layout>
        <Spin spinning={this.state.loading} key='Spinning'>
          <Card
            title='Management User'
            extra={
              <Button
                type='primary'
                onClick={() => {
                  this.props.updateMode('new');
                }}
              >
                New User
              </Button>
            }
          >
            <Table
              dataSource={this.props.users.users}
              columns={columns}
              rowKey='_id'
            />

            <UsersFormMng
              submitSuccess={() => {
                this._getData(this.state.skip);
              }}
            />
            <UsersFormEditMng
              submitSuccess={() => {
                this._getData(this.state.skip);
              }}
            />
          </Card>
        </Spin>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    users: state.users,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchUsers: fetchUsers,
      pushUsers: pushUsers,
      removeUsers: removeUsers,
      updateActiveUser: updateActiveUser,
      updateUsers: updateUsers,
      updateMode: updateMode,
    },
    dispatch
  );
}

export default requireAuth(
  connect(mapStateToProps, mapDispatchToProps)(Form.create()(UsersIndexMng))
);
