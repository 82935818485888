import _ from 'lodash';
import {
  FETCH_FILES,
  PUSH_FILES,
  REMOVE_FILES,
  UPDATE_FILES,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID } from '../actions/actions-files';

const INITIAL_STATE = {
  files: [],
  mode: 'index',
  activeId: '',
  activeFiles:{}
}


export default function(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_FILES:
      return {
        ...state,
        files:action.payload,
      }
    case PUSH_FILES:
      return {
        ...state,
        files:[action.payload, ...state.files],
      }
    case REMOVE_FILES:
      return {
        ...state,
        files : [...state.files.filter( (item) => item._id !== action.payload._id)]
      }
    case UPDATE_FILES:
      let index =  _.findIndex(state.files, { '_id': action.payload._id })
      state.files.splice(index, 1, action.payload)
      return {
        ...state,
        files : state.files
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode : action.payload,
        activeId:'',
        activeFiles:[]
      }
    case UPDATE_ACTIVE_ID:
      return {
        ...state,
        mode : action.mode,
        activeId:action.id,
        activeFiles: _.pick(_.find(state.files, { '_id': action.id }), ['file', 'remark', 'type', '_id'])
      }
    default:
      return state

  }
}
