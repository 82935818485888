import React, { Component } from 'react';
import {
  Popconfirm,
  Divider,
  Table,
  Form,
  Input,
  Button,
  Card,
  Row,
  Col,
} from 'antd';

import client from '../../feathers';
import requireAuth from '../../components/requireAuth';
import Layout from '../../components/layout';
import UsersForm from '../../components/users/form';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  fetchUsers,
  pushUsers,
  removeUsers,
  updateMode,
  updateActiveUser,
  updateUsers,
} from '../../actions/users-actions';
const _ = require('lodash');

const PAGESIZE = 10;

const text = 'Are you sure to delete this item?';

class UsersIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchEmail: '',
      searchRole: '',
      searchObj: {},
      total: 0,
      skip: 0,
      typingTimeout: 0,
    };

    this.handleDelete = this.handleDelete.bind(this);
    this._searchEmailChange = this._searchEmailChange.bind(this);
    this._searchRoleChange = this._searchRoleChange.bind(this);
  }

  componentDidUpdate() {}

  componentDidMount() {
    client.service('users').on('created', (users) => {
      //this.props.pushUsers(users)
      this.componentWillMount();
    });

    client.service('users').on('removed', (users) => {
      //this.props.removeUsers(users)
      this.componentWillMount();
    });

    client.service('users').on('updated', (users) => {
      //this.props.updateUsers(users)
      this.componentWillMount();
    });

    client.service('users').on('patched', (users) => {
      //this.props.updateUsers(users)
      this.componentWillMount();
    });
  }

  componentWillMount() {
    client
      .authenticate()
      .then(() => {
        return client.service('users').find({
          query: {
            role: 'management',
            $sort: {
              createdAt: -1,
            },
          },
        });
      })
      .then((res) => {
        this.props.fetchUsers(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  _getData(skip) {
    client
      .authenticate()
      .then(() => {
        let searchObj = this.state.searchObj;
        let query = {
          query: {
            $sort: {
              createdAt: -1,
            },
            $skip: skip,
            $limit: PAGESIZE,
            orRegex: this.state.searchObj,
          },
        };
        _.assign(query.query, searchObj);

        return client.service('users').find(query);
      })
      .then((res) => {
        this.props.fetchUsers(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  _searchEmailChange(e) {
    this.setState({
      searchEmail: e.target.value,
      searchObj: _.merge(
        this.state.searchObj,
        e.target.value ? { email: e.target.value } : { email: '.' }
      ),
      typingTimeout: setTimeout(() => {
        this._getData(this.state.skip);
      }, 500),
    });
  }

  _searchRoleChange(e) {
    clearTimeout(this.state.typingTimeout);

    this.setState({
      searchRole: e.target.value,
      searchObj: _.merge(
        this.state.searchObj,
        e.target.value ? { role: e.target.value } : { role: '.' }
      ),
      typingTimeout: setTimeout(() => {
        this._getData(this.state.skip);
      }, 500),
    });
  }

  handleDelete(_id) {
    client
      .service('users')
      .remove(_id)
      .then((res) => {})
      .catch((err) => {
        alert(err);
      });
  }

  handleEdit(_id) {
    this.props.updateActiveUser('edit', _id);
  }

  handleManage(_id) {
    this.props.updateActiveUser('', _id);
  }

  showModal = () => {
    this.props.updateMode('new');
  };

  renderConfirmDeleteBtn(_id) {
    return (
      <Popconfirm
        placement='topLeft'
        title={text}
        onConfirm={() => this.handleDelete(_id)}
        okText='Confirm'
        cancelText='Cancel'
      >
        <Button type='danger'>Delete</Button>
      </Popconfirm>
    );
  }

  render() {
    const columns = [
      {
        title: (
          <Row gutter={8}>
            <Row>
              <Col span={24}>Email</Col>
            </Row>
            <Row>
              <Col span={24}>
                <Input
                  value={this.state.searchEmail}
                  onChange={this._searchEmailChange}
                />
              </Col>
            </Row>
          </Row>
        ),
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: (
          <Row gutter={8}>
            <Row>
              <Col span={24}>Role</Col>
            </Row>
            <Row>
              <Col span={24}>
                <Input
                  value={this.state.searchRole}
                  onChange={this._searchRoleChange}
                />
              </Col>
            </Row>
          </Row>
        ),
        dataIndex: 'role',
        key: 'role',
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            {/*<Link
            to={{
              pathname: `/users/view/${record._id}`
            }}
          >
          <Button>Manage</Button>
          </Link>*/}
            <Divider type='vertical' />
            <Button onClick={() => this.handleEdit(record._id)}>Edit</Button>
            <Divider type='vertical' />
            {/*this.renderConfirmDeleteBtn(record._id)*/}
          </span>
        ),
      },
    ];

    return (
      <Layout>
        <Card
          title='Management User'
          extra={
            <Button type='primary' onClick={this.showModal}>
              New User
            </Button>
          }
        >
          <Table
            dataSource={this.props.users.users}
            columns={columns}
            rowKey='_id'
          />
          <UsersForm />
        </Card>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    users: state.users,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchUsers: fetchUsers,
      pushUsers: pushUsers,
      removeUsers: removeUsers,
      updateActiveUser: updateActiveUser,
      updateUsers: updateUsers,
      updateMode: updateMode,
    },
    dispatch
  );
}

export default requireAuth(
  connect(mapStateToProps, mapDispatchToProps)(Form.create()(UsersIndex))
);
