export const FETCH_PAYMENTVOUCHERS= 'FETCH_PAYMENTVOUCHERS';
export const PUSH_PAYMENTVOUCHERS= 'PUSH_PAYMENTVOUCHERS';
export const REMOVE_PAYMENTVOUCHERS= 'REMOVE_PAYMENTVOUCHERS';
export const UPDATE_PAYMENTVOUCHERS= 'UPDATE_PAYMENTVOUCHERS';
export const UPDATE_MODE = 'UPDATE_MODE';
export const UPDATE_ACTIVE_ID = 'UPDATE_ACTIVE_ID';

export function fetchPaymentVouchers(data){
  return (dispatch) => {
    dispatch({
      type: FETCH_PAYMENTVOUCHERS,
      payload:data
    });
  }
}

export function pushPaymentVouchers(data){
  return (dispatch) => {
    dispatch({
      type: PUSH_PAYMENTVOUCHERS,
      payload:data
    });
  }
}

export function removePaymentVouchers(data){
  return (dispatch) => {
    dispatch({
      type: REMOVE_PAYMENTVOUCHERS,
      payload:data
    });
  }
}

export function updatePaymentVouchers(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_PAYMENTVOUCHERS,
      payload:data
    });
  }
}

export function updateMode(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE,
      payload:data
    });
  }
}

export function updateActivePaymentVouchers(mode, id){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_ID,
      mode,
      id
    });
  }
}
