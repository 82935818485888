import React, { Component } from 'react';
import { Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Card, Row, Col, Select, Spin, InputNumber } from 'antd';
import { Route, Link } from 'react-router-dom';

import client from '../../feathers';
import requireAuth from "../requireAuth";
import Layout from "../layout";
import InvoiceitemsForm from "./form";
import PaymentForm from "./form-payment";
import PaymentFormItems from "./form-payment-items";
import axios from 'axios';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchInvoiceitems,
  pushInvoiceitems,
  removeInvoiceitems,
  updateMode,
  updateActiveInvoiceitems,
  updateInvoiceitems
} from '../../actions/actions-invoiceitems';

import '../../App.css';


const _ = require('lodash');
const FormItem = Form.Item;
const Option = Select.Option;
const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);
const EditableFormRow = Form.create()(EditableRow);

const text = 'Are you sure to delete this item?';

class EditableCell extends React.Component {
  state = {
    editing: false,
  }

  componentDidMount() {
    if (this.props.editable) {
      document.addEventListener('click', this.handleClickOutside, true);
    }
  }

  componentWillUnmount() {
    if (this.props.editable) {
      document.removeEventListener('click', this.handleClickOutside, true);
    }
  }

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  }

  handleClickOutside = (e) => {
    const { editing } = this.state;
    if (editing && this.cell !== e.target && !this.cell.contains(e.target)) {
      this.save();
    }
  }

  save = () => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  }

  render() {
    const { editing } = this.state;
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      ...restProps
    } = this.props;
    return (
      <td ref={node => (this.cell = node)} {...restProps}>
        {editable ? (
          <EditableContext.Consumer>
            {(form) => {
              this.form = form;
              return (
                editing ? (
                  <FormItem style={{ margin: 0 }}>
                    {form.getFieldDecorator(dataIndex, {
                      rules: [{
                        required: true,
                        message: `${title} is required.`,
                      }],
                      initialValue: record[dataIndex],
                    })(
                      <Input
                        ref={node => (this.input = node)}
                        onPressEnter={this.save}
                      />
                    )}
                  </FormItem>
                ) : (
                  <div
                    className="editable-cell-value-wrap"
                    style={{ paddingRight: 24 }}
                    onClick={this.toggleEdit}
                  >
                    {restProps.children}
                  </div>
                )
              );
            }}
          </EditableContext.Consumer>
        ) : restProps.children}
      </td>
    );
  }
}

class IndexInvoice extends Component {
  constructor(props) {
    super(props);

    this.columns = [{
      title: 'Doc Id',
      dataIndex: 'docId',
      width: '15%',
    }, {
      title: 'Description',
      dataIndex: 'description',
      width: '15%',
    }, {
      title: 'Status',
      dataIndex: 'status',
      width: '10%',
    }, {
      title: 'Due Date',
      dataIndex: 'dueDate',
      width: '15%',
      render: (text, record) => (
        <span>
          {record.dueDate ? this.convertDate(record.dueDate) : ''}
        </span>
      ),
    }, {
      title: 'Charge Amount',
      dataIndex: 'appliedAmount',
      width: '15%',
      render: (text, record) => (
        <span>
          {this.formatAmt(record.appliedAmount)}
        </span>
      ),
    }, {
      title: 'Paid Amount',
      dataIndex: 'knockOffBalance',
      width: '15%',
      //editable: true,
      render: (text, record) => (
        <span>
          {this.formatAmt(record.knockOffBalance)}
        </span>
      ),
    }, {
      title: 'KnockOff Amount',
      dataIndex: 'knockOffAmount',
      width: '15%',
      editable: true,
      render: (text, record) => (
        <span>
          {this.formatAmt(record.knockOffAmount)}
        </span>
      ),
    }];

    this.state = {
      invoiceItems: [],
      totalAmount: 0,
      propertyUnit: {},
      loading: false,
      selectedAmount: 0,
      dataSource: [],
      oriDataSource: [],
      selectedRowKeys: []
    }
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidUpdate() {

  }

  componentDidMount() {
    client.service('invoiceitems').on('created', (invoiceitems) => {
      //this.props.pushInvoiceitems(invoiceitems)
      if (sessionStorage.getItem('companyId') === invoiceitems.propertyId) {
        this.componentWillMount();
      }

    })

    client.service('invoice-items').on('removed', (invoiceitems) => {
      if (sessionStorage.getItem('companyId') === invoiceitems.propertyId) {
        this.componentWillMount();
      }
    })

    client.service('invoice-items').on('updated', (invoiceitems) => {
      if (sessionStorage.getItem('companyId') === invoiceitems.propertyId) {
        this.componentWillMount();
      }
    })

    client.service('invoice-items').on('patched', (invoiceitems) => {
      if (sessionStorage.getItem('companyId') === invoiceitems.propertyId) {
        this.componentWillMount();
      }
    })
  }

  componentWillMount() {
    let companyId = this.props.commons.selectedCompany._id

    this.setState({ loading: true });
    client.authenticate()
      .then((res) => {

        return client.service('invoice-items').find({
          query: {
            $populate: 'propertyId invoiceId',
            propertyId: companyId,
            invoiceId: this.props.match.params.id,
            status: { $in: ['ISSUED', 'PARTIAL'] },
            $sort: {
              docId: 1
            }
          }
        })
      })
      .then((res) => {
        this.setState({
          dataSource: res.data,
          loading: false
        });
        this.checkedAllCheckedBox();
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });

  }

  checkedAllCheckedBox() {

    let selectedRowKeys = [];
    let selectedRows = [];
    let newData = [];
    let totalSelectedAmt = 0;
    let totalKnockOffAmt = 0;
    for (let i = 0; i < this.state.dataSource.length; i++) {

      this.state.dataSource[i].knockOffAmount = this.state.dataSource[i].appliedAmount - this.state.dataSource[i].knockOffBalance;
      newData.push(this.state.dataSource[i]);
      selectedRows.push(this.state.dataSource[i]);
      selectedRowKeys.push(this.state.dataSource[i]._id);
      totalSelectedAmt += this.state.dataSource[i].knockOffAmount;
      totalKnockOffAmt += this.state.dataSource[i].knockOffAmount;
    }
    this.setState({
      selectedRowKeys: selectedRowKeys,
      selectedRows: selectedRows,
      dataSource: newData,
      selectedAmount: totalSelectedAmt,
      itemTotalAmount: totalSelectedAmt,
      totalAmount: totalSelectedAmt
    });

  }


  handleDelete(_id) {
    client.service('invoiceitems').remove(_id)
      .then((res) => {
      })
      .catch((err) => {
        message.seccess('Deleted');
        alert(err);
      })
  }

  handleEdit(_id) {
    this.props.updateActiveInvoiceitems('edit', _id)
  }

  handleManage(_id) {
    this.props.updateActiveInvoiceitems('', _id)
  }

  confirm = () => {

    client.authenticate()
      .then((res) => {
        let companyId = this.props.commons.selectedCompany._id

        return client.service('applogs').create({
          remark: 'Recurring Invoice',
          status: 'START',
          propertyId: companyId,
        })
      })
      .then((res) => {

        message.success('Recurring Invoiceitems Created');
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      })

  }

  cancel(e) {

    //message.error('Click on No');
  }


  showModal = () => {
    this.props.updateMode('new')
  }

  renderConfirmDeleteBtn(_id) {
    return (
      <Popconfirm placement="topLeft" title={text} onConfirm={() => this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
        <Button type="danger">Delete</Button>
      </Popconfirm>
    )
  }

  renderSearching() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form
        className="ant-advanced-search-form"
        onSubmit={this.handleSearch}
      >
        <Row gutter={24} style={{ display: 'block' }}>
          <Col span={8}>
            <Form.Item label='Invoice No.'>
              {getFieldDecorator(`invoiceNo`, {
                rules: [{
                  required: false,
                  message: 'Invoice No.!',
                }],
              })(
                <Input placeholder="INV0001" />
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='Date'>
              {getFieldDecorator(`invoiceDate`, {
                rules: [{
                  required: false,
                  message: 'Input Date!',
                }],
              })(
                <Input type='Date' />
              )}
            </Form.Item>
          </Col>
          <Button type="primary" htmlType="submit" style={{ float: 'right' }} >Search</Button>
        </Row>

      </Form>
    )
  }

  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {

      client.authenticate()
        .then((res) => {
          let companyId = this.props.commons.selectedCompany._id

          return client.service('invoice-items').find({
            query: {
              ...values,
              propertyId: companyId,
              invoiceType: 'RECURRING',
              $sort: {
                invoiceDate: 1
              }
            }
          })
        })
        .then((res) => {

          this.setState({ dataSource: res.data });
          //this.props.fetchInvoiceitems(res.data)
        })
        .catch((err) => {
          console.log(err);
        });

    });
  }

  formatAmt(amt) {
    let integer = parseFloat(amt);
    return integer > 0 ? integer.toFixed(2) : 0.00;
  }

  convertDate(date) {
    let tempDate = new Date(date);
    let d = tempDate.getDate();
    let m = tempDate.getMonth() + 1;
    let y = tempDate.getFullYear();
    return d + '/' + m + '/' + y;
  }

  renderFooter() {
    return ([
      <Row >
        <Col offset={18} span={3}>
          Total Amount
          <Input style={{ color: 'red' }} value={this.formatAmt(this.state.selectedAmount)} disabled={true} />
        </Col>
        <Col span={3}>
          Total KnockOff
          <InputNumber style={{ color: 'green' }} onChange={this.handleChangeTotalAmt} value={this.state.totalAmount} />
        </Col>
      </Row>
    ])
  }

  handleSave = (row) => {

    const newData = [...this.state.dataSource];
    const index = newData.findIndex(item => row._id === item._id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ dataSource: newData });

    setTimeout(() => {

      if (newData.length > 1) {
        this.setState({
          totalAmount: newData.reduce((previous, current) => parseFloat(previous.knockOffAmount) + parseFloat(current.knockOffAmount)),
        });
      } else {
        this.setState({
          totalAmount: newData[0].knockOffAmount,
        });
      }

    }, 1000);
  }

  onSelectChange = (selectedRowKeys) => {

    this.setState({ selectedRowKeys });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { selectedRowKeys, dataSource } = this.state;

    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };
    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });

    const rowSelection = {
      selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {


        let tempAmt = 0;
        let newData = [...this.state.dataSource];
        for (let i = 0; i < selectedRows.length; i++) {
          tempAmt += selectedRows[i].appliedAmount - selectedRows[i].knockOffBalance;
        }

        if (selectedRows.length > 0) {
          //start handle latest selected row
          selectedRows[selectedRows.length - 1].knockOffAmount = selectedRows[selectedRows.length - 1].appliedAmount - selectedRows[selectedRows.length - 1].knockOffBalance;
          const index = newData.findIndex(item => selectedRows[selectedRows.length - 1]._id === item._id);
          const item = newData[index];
          newData.splice(index, 1, {
            ...item,
            ...selectedRows[selectedRows.length - 1],
          });
          //end handle latest selected row
        }

        this.setState({
          selectedRows: selectedRows,
          selectedRowKeys: selectedRowKeys,
          selectedAmount: tempAmt,
          dataSource: newData,
          totalAmount: tempAmt
        });
      },
    };
    const hasSelected = selectedRowKeys.length > 0;
    return (
      <Layout>
        <Spin spinning={this.state.loading} >
          <Card
            title={this.state.propertyUnit.referenceId}
          >
            {/*this.renderSearching()*/}
            <span style={{ marginLeft: 8 }}>
              {hasSelected ? `Selected ${this.state.selectedRowKeys.length} items` : ''}
            </span>
            <Row>
              <Col span={19}>


                <Table
                  rowSelection={rowSelection}
                  components={components}
                  rowClassName={() => 'editable-row'}
                  bordered
                  dataSource={dataSource}
                  columns={columns}
                  pagination={false}
                  scroll={{ y: 440 }}
                  rowKey="_id"
                  footer={() => this.renderFooter()}
                />

              </Col>
              <Col span={5}>

                {/* <PaymentFormItems
                      selectedRowKeys={this.state.selectedRowKeys}
                      invoiceItems={this.state.dataSource}
                      totalAmount={this.state.totalAmount}
                      propertyunitId={this.state.invoice.propertyunitId}
                    /> */}

                <PaymentFormItems
                  invoiceItems={this.state.selectedRows}
                  totalAmount={this.state.totalAmount}
                  propertyunitId={this.props.match.params.id}
                />

              </Col>
            </Row>

            {/* <InvoiceitemsForm /> */}
          </Card>
        </Spin>
      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    invoiceitems: state.invoiceitems,
    commons: state.commons
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchInvoiceitems: fetchInvoiceitems,
    pushInvoiceitems: pushInvoiceitems,
    removeInvoiceitems: removeInvoiceitems,
    updateActiveInvoiceitems: updateActiveInvoiceitems,
    updateInvoiceitems: updateInvoiceitems,
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(IndexInvoice)));
