import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Icon,
  message,
  Popover,
  Row,
  Select,
  Spin,
  Table,
  Input,
  Popconfirm,
} from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  fetchTransactions,
  pushTransactions,
  removeTransactions,
  updateActiveTransactions,
  updateMode,
  updateTransactions,
} from '../../actions/actions-transactions';
import Layout from '../../components/layout';
import requireAuth from '../../components/requireAuth';
import client from '../../feathers';
import params from '../../params/system';
import { getBulkData } from '../functionContent';
import axios from 'axios';

//-----------------------------------------
const _ = require('lodash');
const FormItem = Form.Item;
const Option = Select.Option;
const text = '';
var showHide = false;
var showHide2 = false;
const formatCurr = function format1(n = 0, currency = null) {
  let oriAmt = n;
  n = n < 0 ? Math.abs(n) : n;
  let amt = currency
    ? currency
    : '' +
      n.toFixed(2).replace(/./g, function(c, i, a) {
        return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c;
      });

  return oriAmt < 0 ? '(' + amt + ')' : amt;
};

const formItemLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
};

const PAGESIZE = 20;

class TransactionsIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      monthlystatementsId: '',
      loading: false,
      totalPagination: 1,
      CurrentPagination: 1,
      showAllRecord: false,
      dropDownPaymentType: [],
      selectedPaymentMode: '',
      dateFilter: [],
      receiptFilter: '',
      referenceNo: '',
      allPropertyUnits: [],
      selectedUnit: '',
      showSelectedRecord: false,
      selectedCannel: '',
      allData: [],
    };

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidUpdate() {
    if (this.props.transactions.mode === 'refresh') {
      this.componentWillMount();
    }
  }
  componentDidMount() {
    this.getPropertyUnits();
    this.getAllData();
  }

  async getPropertyUnits() {
    let query = {
      propertyId: this.props.commons.selectedCompany._id,
      $select: ['_id', 'referenceId'],
    };
    let allPropertyUnits = await getBulkData('propertyunits', query, 50);
    this.setState({ allPropertyUnits: allPropertyUnits || [] });
  }

  async componentWillMount() {
    let companyId = this.props.commons.selectedCompany._id;

    this.props.updateMode('');
    this.setState({ loading: true });

    client.authenticate().then(async (res) => {
      let patchTransactions = await axios.post(
        `${client.io.io.uri}patchMoneyInTransit`,
        {
          companyId: companyId,
        }
      );

      client
        .service('payment-type-params')
        .find({
          query: {
            propertyId: companyId,
            category: {
              $in: ['INVOICE', 'DEPOSIT'],
            },
          },
        })
        .then((res) => {
          // console.log(res)
          this.setState({ dropDownPaymentType: res.data });
          this.getData(0);
        });
    });
  }

  getAllData() {
    let companyId = this.props.commons.selectedCompany._id;
    client
      .service('transactions')
      .find({
        query: {
          propertyId: companyId,
        },
      })
      .then((res) => {
        let selectedPrintData = res.data;
        _.forEach(selectedPrintData, function(a, index) {
          client.service('transactions').patch(a._id, { MITstatus: false });
        });
      });
  }

  getData(skip) {
    let companyId = this.props.commons.selectedCompany._id;

    client.authenticate().then(() => {
      this.setState({ loading: true });

      let queryString,
        queryPaymentType = '';
      if (this.state.showAllRecord === true) {
        queryString = { MITshow: true };
      }
      if (this.state.selectedChannel) {
        queryString = { channel: this.state.selectedChannel };
      }
      if (!_.isEmpty(this.state.selectedPaymentMode)) {
        queryPaymentType = {
          paymentTypeParamId: this.state.selectedPaymentMode,
        };
      }

      let { dateFilter, receiptFilter, referenceNo, selectedUnit } = this.state;
      let queryStringDate = '',
        orRegexFilter = [],
        orRegexQuery = '',
        unitFilter = '';

      if (_.isEmpty(dateFilter) === false) {
        queryStringDate = {
          paymentDate: {
            $gte: new Date(moment(dateFilter[0]).startOf('day')),
            $lte: new Date(moment(dateFilter[1]).endOf('day')),
          },
        };
      }

      if (_.isEmpty(receiptFilter) === false) {
        orRegexFilter.push({ trxNo: receiptFilter });
      }
      if (_.isEmpty(referenceNo) === false) {
        orRegexFilter.push({ reference: referenceNo });
      }

      if (_.isEmpty(orRegexFilter) === false) {
        orRegexFilter = { orRegexOr: orRegexFilter };
      }

      if (_.isEmpty(selectedUnit) === false) {
        unitFilter = { propertyunitId: selectedUnit };
      }

      return client
        .service('transactions')
        .find({
          query: {
            propertyId: companyId,
            $populate: 'propertyunitId paymentTypeParamId',
            $limit: PAGESIZE,
            $skip: skip,
            $sort: { paymentDate: -1, trxNo: -1 },
            ...queryString,
            ...queryPaymentType,
            ...queryStringDate,
            ...orRegexFilter,
            ...unitFilter,
          },
        })
        .then((res) => {
          this.setState({ totalPagination: res.total, loading: false });
          this.props.fetchTransactions(res.data);
        })
        .catch((err) => {
          this.setState({ loading: false });
          console.log(err);
        });
    });
  }
  // ---------------------------------------------------------------

  // ---------------------------------------------------------------
  handlePageChange = (page) => {
    this.getData(page * PAGESIZE - PAGESIZE);
  };

  handleSelected(e, _id) {
    this.setState({ loading: true });

    client
      .authenticate()
      .then((res) => {
        return client
          .service('transactions')
          .patch(_id, { MITstatus: e.target.checked });
      })
      .then((res) => {
        let transactions = _.map(this.props.transactions.transactions, function(
          o
        ) {
          if (o._id === res._id) {
            o.MITstatus = res.MITstatus;
            return o;
          }
        });

        // this.props.fetchBankRecons('')
        this.setState({ loading: false });

        //this.componentWillMount();
      })
      .catch((err) => {
        alert(err);
        this.setState({ loading: false });
      });
  }
  handleAllSelected(e) {
    let companyId = this.props.commons.selectedCompany._id;

    if (this.state.showSelectedRecord === true) {
      this.setState({ loading: true });

      let queryPaymentType = '';
      if (!_.isEmpty(this.state.selectedPaymentMode)) {
        queryPaymentType = {
          paymentTypeParamId: this.state.selectedPaymentMode,
        };
      }

      let { dateFilter, receiptFilter, referenceNo, selectedUnit } = this.state;
      let queryString;
      let queryStringDate = '',
        orRegexFilter = [],
        orRegexQuery = '',
        unitFilter = '';

      if (_.isEmpty(dateFilter) === false) {
        queryStringDate = {
          paymentDate: {
            $gte: new Date(moment(dateFilter[0]).startOf('day')),
            $lte: new Date(moment(dateFilter[1]).endOf('day')),
          },
        };
      }

      if (this.state.selectedChannel) {
        queryString = { channel: this.state.selectedChannel };
      }
      if (_.isEmpty(receiptFilter) === false) {
        orRegexFilter.push({ trxNo: receiptFilter });
      }
      if (_.isEmpty(referenceNo) === false) {
        orRegexFilter.push({ reference: referenceNo });
      }

      if (_.isEmpty(orRegexFilter) === false) {
        orRegexFilter = { orRegexOr: orRegexFilter };
      }

      if (_.isEmpty(selectedUnit) === false) {
        unitFilter = { propertyunitId: selectedUnit };
      }
      client
        .service('transactions')
        .find({
          query: {
            propertyId: companyId,
            $populate: 'propertyunitId paymentTypeParamId',
            // $limit: PAGESIZE,
            $sort: { trxNo: 1 },
            ...queryString,
            ...queryPaymentType,
            ...queryStringDate,
            ...orRegexFilter,
            ...unitFilter,
          },
        })
        .then((res) => {
          let selectedPrintData = res.data;

          _.forEach(selectedPrintData, function(a, index) {
            client.service('transactions').patch(a._id, { MITstatus: true });
          });
          this.setState({ loading: false });
        });
    } else {
      this.setState({ loading: true });

      axios
        .post(`${client.io.io.uri}patchMoneyInTransit`, {
          companyId: companyId,
        })
        .then((res) => {
          this.setState({ loading: false });
        });
    }
  }

  handlePrinted(e, _id) {
    this.setState({ loading: true });

    client
      .authenticate()
      .then((res) => {
        return client
          .service('transactions')
          .patch(_id, { MITshow: e.target.checked });
      })
      .then((res) => {
        let transactions = _.map(this.props.transactions.transactions, function(
          o
        ) {
          if (o._id === res._id) {
            o.MITshow = res.MITshow;
            return o;
          }
        });

        transactions = _.reject(this.props.transactions.transactions, function(
          item
        ) {
          return item._id === res._id;
        });

        this.props.fetchTransactions(transactions);

        this.setState({ loading: false });
        // this.componentWillMount();
      })
      .catch((err) => {
        alert(err);
        this.setState({ loading: false });
      });
  }
  // ---------------------------------------------------------------

  // ---------------------------------------------------------------
  handleDelete(_id) {
    client
      .service('transactions')
      .remove(_id)
      .then((res) => {
        this.props.updateMode('refresh');
      })
      .catch((err) => {
        alert(err);
      });
  }

  handleEdit(_id) {
    this.props.updateActivetransactions('edit', _id);
  }

  handleManage(_id) {
    this.props.updateActivetransactions('', _id);
  }

  showModal = () => {
    this.props.updateMode('');
  };

  showRecord = () => {
    if (this.state.showAllRecord === true) {
      this.setState({
        showAllRecord: false,
        typingTimeout: setTimeout(() => {
          this.componentWillMount();
        }, 500),
      });
    } else {
      this.setState({
        showAllRecord: true,
        typingTimeout: setTimeout(() => {
          this.componentWillMount();
        }, 500),
      });
    }
  };

  RetrieveFromParameter(record) {
    var myFunc = function(thisObj, property) {
      return params.uncleartransactionsCategory[property];
    };
    return myFunc(params.uncleartransactionsCategory, record.category);
  }

  _searchDateChange = (e) => {
    this.setState({ dateFilter: e }, () => {
      this.getData(0);
      this.handleAllSelected();
    });
  };

  _searchReceiptChange = (e) => {
    this.setState(
      { receiptFilter: e.target.value, showSelectedRecord: false },
      () => {
        this.getData(0);
        this.handleAllSelected();
      }
    );
  };

  _searchRefNoChange = (e) => {
    this.setState(
      { referenceNo: e.target.value, showSelectedRecord: false },
      () => {
        this.handleAllSelected();
        this.getData(0);
      }
    );
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { loading, allPropertyUnits } = this.state;
    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    };
    let { paymentType } = this.state;

    const columns = [
      {
        title: (
          <div>
            <Row>
              <Col span={24}>Payment Date</Col>
            </Row>
            <Row>
              <Col span={24}>
                <DatePicker.RangePicker
                  allowEmpty
                  type='Date'
                  onChange={this._searchDateChange}
                />
              </Col>
            </Row>
          </div>
        ),
        dataIndex: 'paymentDate',
        key: 'paymentDate',
        width: '10%',
        render: (text, record) => (
          <span>{text ? moment(text).format('DD-MM-YYYY') : ''}</span>
        ),
      },
      {
        title: (
          <div>
            <Row>
              <Col span={24}>Receipt No.</Col>
            </Row>
            <Row>
              <Col span={24}>
                <Input onChange={this._searchReceiptChange} />
              </Col>
            </Row>
          </div>
        ),
        dataIndex: 'trxNo',
        key: 'trxNo',
        width: '10%',
      },
      {
        title: (
          <>
            <Row>
              <Col span={24}>Unit No</Col>
            </Row>
            <Row>
              <Col span={24}></Col>
            </Row>
            <Select
              style={{ minWidth: '100px' }}
              placeholder='Unit No'
              showSearch
              dropdownMatchSelectWidth={false}
              allowClear
              optionFilterProp='children'
              onChange={(e) => {
                this.setState({
                  selectedUnit: e,
                  showSelectedRecord: false,
                  typingTimeout: setTimeout(() => {
                    this.getData(0);
                    this.handleAllSelected();
                  }, 1000),
                });
              }}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {allPropertyUnits.map((dropdown) => (
                <Option key={dropdown._id} value={dropdown._id}>
                  {dropdown.referenceId}
                </Option>
              ))}
            </Select>
          </>
        ),
        dataIndex: 'propertyunitId.referenceId',
        key: 'propertyunitId.referenceId',
        width: '10%',
      },
      {
        title: (
          <>
            <Row>
              <Col span={24}>Payment Mode</Col>
            </Row>
            <Row>
              <Col span={24}>
                <Select
                  style={{ width: '200px' }}
                  defaultValue=''
                  onChange={(e) => {
                    this.setState(
                      { selectedPaymentMode: e, showSelectedRecord: false },
                      () => {
                        this.getData(0);
                        this.handleAllSelected();
                      }
                    );
                  }}
                  allowClear
                >
                  <Option key='' value=''></Option>
                  {this.state.dropDownPaymentType.map((v) => (
                    <Option key={v._id}>
                      {v.description + ' (' + v.category + ')'}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </>
        ),
        dataIndex: 'paymentTypeParamId.description',
      },
      {
        title: (
          <>
            <Row>
              <Col span={24}>CHANNEL</Col>
            </Row>
            <Row>
              <Col span={24}>
                <Select
                  style={{ width: '80px' }}
                  defaultValue=''
                  onChange={(e) => {
                    this.setState({ selectedChannel: e }, () => {
                      this.getData(0);
                    });
                  }}
                  allowClear
                >
                  <Option key='WEB' value='WEB'>
                    {' '}
                    WEB{' '}
                  </Option>
                  <Option key='APPS' value='APPS'>
                    {' '}
                    APPS{' '}
                  </Option>
                </Select>
              </Col>
            </Row>
          </>
        ),
        dataIndex: 'channel',
      },

      {
        title: (
          <div>
            <Row>
              <Col span={24}>Reference No</Col>
            </Row>
            <Row>
              <Col span={24}>
                <Input onChange={this._searchRefNoChange} />
              </Col>
            </Row>
          </div>
        ),
        dataIndex: 'reference',
        key: 'reference',
        width: '10%',
      },

      {
        title: (
          <span
            style={{
              float: 'right',
            }}
          >
            Amount
          </span>
        ),
        dataIndex: 'payAmount',
        key: 'payAmount',
        width: '10%',
        align: 'right',
        render: (text, record) => (
          <span
            style={{
              float: 'right',
            }}
          >
            {text ? parseFloat(text).toFixed(2) : '0.00'}
          </span>
        ),
      },
      {
        title: 'Remark',
        dataIndex: 'remark',
        key: 'remark',
        width: '5%',
        render: (text, record) => (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {text ? (
              <Popover content={text}>
                <Icon type='message' />
              </Popover>
            ) : (
              ''
            )}
          </div>
        ),
      },
      {
        title: (
          <Checkbox
            checked={this.state.showSelectedRecord}
            onChange={(e) => {
              this.setState({ showSelectedRecord: e.target.checked }, () => {
                this.handleAllSelected();
              });
            }}
          >
            Select current records
          </Checkbox>
        ),
        key: 'action',
        render: (text, record) => (
          <span>
            {record.MITstatus === true ? (
              <Checkbox
                checked={record.MITstatus}
                onChange={(e) => this.handleSelected(e, record._id)}
              >
                Unselect from Print
              </Checkbox>
            ) : (
              <Checkbox
                checked={record.MITstatus}
                onChange={(e) => this.handleSelected(e, record._id)}
              >
                Select to Print
              </Checkbox>
            )}

            <Divider type='vertical' />

            {record.MITshow === true ? (
              <Checkbox
                checked={record.MITshow}
                onChange={(e) => this.handlePrinted(e, record._id)}
              >
                Show Record
              </Checkbox>
            ) : (
              <Checkbox
                checked={record.MITshow}
                onChange={(e) => this.handlePrinted(e, record._id)}
              >
                Hide Record
              </Checkbox>
            )}
          </span>
        ),
      },
    ];

    return (
      <Layout>
        <Spin key='spinning' spinning={this.state.loading}>
          <Card
            title='Money in Transit Print'
            extra={
              <span>
                {this.state.showAllRecord === false ? (
                  <Button
                    type='primary'
                    style={{ marginRight: 16 }}
                    onClick={this.showRecord}
                  >
                    Show Hide Record
                  </Button>
                ) : (
                  <Button
                    type='primary'
                    style={{ marginRight: 16 }}
                    onClick={this.showRecord}
                  >
                    Show All Record
                  </Button>
                )}

                {this.state.showSelectedRecord === false ? (
                  <Popconfirm
                    title="Please ensure 'Select current records' is clicked"
                    onConfirm={() => {
                      this.props.history.push('/moneyInTransitStatement');
                    }}
                  >
                    <Button type='primary' style={{ marginRight: 16 }}>
                      Money in Transit
                    </Button>
                  </Popconfirm>
                ) : (
                  <Button
                    type='primary'
                    onClick={() => {
                      this.props.history.push('/moneyInTransitStatement');
                    }}
                    style={{ marginRight: 16 }}
                  >
                    Money in Transit
                  </Button>
                )}
              </span>
            }
          >
            <Table
              dataSource={this.props.transactions.transactions}
              columns={columns}
              rowKey='_id'
              pagination={{
                total: this.state.totalPagination,
                defaultCurrent: 1,
                onChange: this.handlePageChange,
                pageSize: 20,
              }}
            />
          </Card>
        </Spin>
      </Layout>
    );
  }
}
function mapStateToProps(state) {
  return {
    transactions: state.transactions,
    commons: state.commons,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchTransactions: fetchTransactions,
      pushTransactions: pushTransactions,
      removeTransactions: removeTransactions,
      updateActiveTransactions: updateActiveTransactions,
      updateTransactions: updateTransactions,
      updateMode: updateMode,
    },
    dispatch
  );
}

export default requireAuth(
  connect(mapStateToProps, mapDispatchToProps)(Form.create()(TransactionsIndex))
);
