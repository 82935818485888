import _ from 'lodash';
import {
  FETCH_AD,
  PUSH_AD,
  REMOVE_AD,
  UPDATE_AD,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID
} from '../actions/ads-actions';

const INITIAL_STATE = {
  ads: [],
  mode: 'index',
  activeId: '',
  activeAd: {}
}


export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_AD:
      return {
        ...state,
        ads: action.payload,
      }
    case PUSH_AD:
      return {
        ...state,
        ads: [action.payload, ...state.ads],
      }
    case REMOVE_AD:
      return {
        ...state,
        ads: [...state.ads.filter((item) => item._id !== action.payload._id)]
      }
    case UPDATE_AD:
      let index = _.findIndex(state.ads, { '_id': action.payload._id })
      state.ads.splice(index, 1, action.payload)
      return {
        ...state,
        ads: state.ads
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode: action.payload,
        activeId: ''
      }
    case UPDATE_ACTIVE_ID:
      return {
        ...state,
        mode: action.mode,
        activeId: action.id,
        activeAd: _.pick(_.find(state.ads, { '_id': action.id }), ['title', 'file', 'content', '_id', 'type', 'location'])
      }
    default:
      return state

  }
}
