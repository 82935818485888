export const FETCH_BANKRECONS = 'FETCH_BANKRECONS';
export const FETCH_BANKRECONS2 = 'FETCH_BANKRECONS2';
export const PUSH_BANKRECONS = 'PUSH_BANKRECONS';
export const REMOVE_BANKRECONS = 'REMOVE_BANKRECONS';
export const UPDATE_BANKRECONS = 'UPDATE_BANKRECONS';
export const UPDATE_MODE_BANKRECONS = 'UPDATE_MODE_BANKRECONS';
export const UPDATE_ACTIVE_ID_BANKRECONS = 'UPDATE_ACTIVE_ID_BANKRECONS';
export const UPDATE_MONTHBANK = 'UPDATE_MONTHBANK';

export function fetchBankRecons(data){
  return (dispatch) => {
    dispatch({
      type: FETCH_BANKRECONS,
      payload:data
    });
  }
}

export function fetchBankRecons2(data){
  return (dispatch) => {
    dispatch({
      type: FETCH_BANKRECONS2,
      payload:data
    });
  }
}

export function pushBankRecons(data){
  return (dispatch) => {
    dispatch({
      type: PUSH_BANKRECONS,
      payload:data
    });
  }
}

export function removeBankRecons(data){
  return (dispatch) => {
    dispatch({
      type: REMOVE_BANKRECONS,
      payload:data
    });
  }
}

export function updateBankRecons(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_BANKRECONS,
      payload:data
    });
  }
}

export function updateModeBankRecons(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE_BANKRECONS,
      payload:data
    });
  }
}

export function updateActiveBankRecons(mode, id){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_ID_BANKRECONS,
      mode,
      id
    });
  }
}

export function updateMonthBank(month,bank,closingBalance){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MONTHBANK,
      month,
      bank,
      closingBalance
    });
  }
}
