import React, { Component } from 'react';
import {
  Popconfirm,
  Row,
  Col,
  message,
  Divider,
  Table,
  Form,
  Icon,
  Input,
  Button,
  Card,
  Modal,
  Spin,
  DatePicker,
  Select,
} from 'antd';
import { Route, Link } from 'react-router-dom';

import client from '../../feathers';
import requireAuth from '../../components/requireAuth';
import Layout from '../../components/layout';
import LedgerIndexTransactionsByReference from '../../components/ledgers/index-transactions-by-reference';
import moment from 'moment';
import axios from 'axios';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  fetchPaymentsVoucherMasters,
  pushPaymentsVoucherMasters,
  removePaymentsVoucherMasters,
  updateMode,
  updateActivePaymentsVoucherMasters,
  updatePaymentsVoucherMasters,
} from '../../actions/actions-paymentsvouchermasters';
import { updateActivePage } from '../../actions/app-actions';

const _ = require('lodash');
const Option = Select.Option;
const FormItem = Form.Item;
const formItemLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const text = 'Are you sure to delete this item?';

class PaymentsVoucherMastersIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      visible2: false,
      img: '',
      datasource: [],
      fromPaymentVoucher: 'YES',
      loading: false,
      paymentsvouchermasters: '',
      dateFilter: '',
      referenceNoFilter: '',
      chequeNoFilter: '',
      selectedSupplier: '',
      supplierList: [],
    };
  }

  componentDidUpdate() {
    if (this.props.paymentsvouchermasters.activeId !== this.state._id) {
      this.setState({ _id: this.props.paymentsvouchermasters.activeId });

      if (this.props.paymentsvouchermasters.activePaymentsVoucherMasters) {
        this.props.form.setFieldsValue(
          this.props.paymentsvouchermasters.activePaymentsVoucherMasters
        );
      }
    }
  }

  componentDidMount() {}

  componentWillMount() {
    client.authenticate().then((res) => {
      client
        .service('suppliers')
        .find({
          query: {
            propertyId: this.props.commons.selectedCompany._id,
          },
        })
        .then((res) => {
          this.setState({ supplierList: res.data }, () => {
            this.getData(0);
          });
        });
    });

    this.getData(0);
  }
  getData(skip) {
    this.setState({ loading: true });
    let {
      selectedSupplier,
      chequeNoFilter,
      referenceNoFilter,
      dateFilter,
    } = this.state;

    client
      .authenticate()
      .then(() => {
        let queryStringDate,
          querySupplier = {},
          orRegexFilter = [];

        if (_.isEmpty(dateFilter) === false) {
          queryStringDate = {
            paymentDate: {
              $gte: new Date(moment(dateFilter[0]).startOf('day')),
              $lte: new Date(moment(dateFilter[1]).endOf('day')),
            },
          };
        }

        if (!_.isEmpty(selectedSupplier)) {
          querySupplier = { supplierId: selectedSupplier };
        }

        if (!_.isEmpty(chequeNoFilter)) {
          orRegexFilter.push({ chequeNo: chequeNoFilter });
        }

        if (!_.isEmpty(referenceNoFilter)) {
          orRegexFilter.push({ referenceNo: referenceNoFilter });
        }

        if (!_.isEmpty(orRegexFilter)) {
          orRegexFilter = { orRegexOr: orRegexFilter };
        }

        client
          .service('paymentsvouchermasters')
          .find({
            query: {
              propertyId: this.props.commons.selectedCompany._id,
              ...querySupplier,
              ...queryStringDate,
              ...orRegexFilter,
              $populate: 'supplierId',
              $sort: {
                createdAt: -1,
              },
            },
          })
          .then((res) => {
            this.setState({ datasource: res.data, loading: false });
            this.props.fetchPaymentsVoucherMasters(res.data);
          });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  }

  handleEdit(_id) {
    this.props.updateActivePaymentsVoucherMasters('edit', _id);

    this.setState({ visible2: true });
  }

  handleView(_id) {
    this.props.updateActivePaymentsVoucherMasters('view', _id);
  }

  handleManage(_id) {
    this.props.updateActivePaymentsVoucherMasters('', _id);
  }

  handleDelete(_id, reference) {
    axios
      .post(
        `${client.io.io.uri}removeTrx`,
        {
          _id: _id,
          companyId: this.props.commons.selectedCompany._id,
          trxType: 'PV',
        },
        {
          headers: {
            Authorization: client.settings.storage.storage['feathers-jwt'],
          },
        }
      )
      .then((res) => {
        message.success('Deleted');
      })
      .catch((err) => {
        console.log(err);
      });
  }

  showModal = () => {
    this.props.updateMode('new');
  };

  handleImage(img = null) {
    this.setState({ visible: true, img: img });
  }

  handleOk = (e) => {
    this.setState({ visible: false });
  };
  handleOk2 = (e) => {
    this.props.form.validateFields((err, values) => {
      client
        .service('paymentsvouchermasters')
        .patch(this.props.paymentsvouchermasters.activeId, {
          checkDuplicate: true,
          ...values,
          propertyId: this.props.commons.selectedCompany._id,
        })
        .then((res) => {
          message.success('Record Updated.');
        });
    });
    this.setState({ visible2: false });
  };

  handleCancel = (e) => {
    this.setState({ visible: false });
  };

  handleCancel2 = (e) => {
    this.setState({ visible2: false });
  };

  renderConfirmDeleteBtn(_id, reference) {
    return (
      <Popconfirm
        placement='topLeft'
        title={text}
        onConfirm={() => this.handleDelete(_id, reference)}
        okText='Confirm'
        cancelText='Cancel'
      >
        <Button type='danger'>Delete</Button>
      </Popconfirm>
    );
  }

  renderPayButton(record) {
    if (record.totalAmount !== record.totalPay) {
      return (
        <Link
          to={{
            pathname: `/paymentvoucher/paymentvoucher-pay/${record._id}`,
          }}
        >
          <Button>Pay</Button>
        </Link>
      );
    }
  }
  _searchDateChange = (e) => {
    this.setState({ dateFilter: e }, () => {
      this.getData(0);
    });
  };
  _searchReferenceChange = (e) => {
    this.setState({ referenceNoFilter: e.target.value }, () => {
      this.getData(0);
    });
  };
  _searchChequeChange = (e) => {
    this.setState({ chequeNoFilter: e.target.value }, () => {
      this.getData(0);
    });
  };

  handlePageChange = (page) => {
    this.props.updateActivePage({
      paymentVoucherPage: page,
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { datasource, fromPaymentVoucher } = this.state;

    const columns = [
      {
        title: (
          <div>
            <Row>
              <Col span={24}>Date</Col>
            </Row>
            <Row>
              <Col span={24}>
                <DatePicker.RangePicker
                  allowEmpty
                  type='Date'
                  onChange={this._searchDateChange}
                />
              </Col>
            </Row>
          </div>
        ),
        dataIndex: 'paymentDate',
        key: 'date',
        width: '10%',
        render: (text, record) => moment(text).format('YYYY-MM-DD'),
      },
      {
        title: (
          <>
            <Row>
              <Col span={24}></Col>Supplier
            </Row>
            <Row>
              <Col span={24}>
                <Select
                  style={{ width: '150px' }}
                  defaultValue=''
                  onChange={(e) => {
                    this.setState({ selectedSupplier: e }, () => {
                      this.getData(0);
                    });
                  }}
                  showSearch
                  allowClear
                  dropdownMatchSelectWidth={false}
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option key='' value=''>
                    Supplier
                  </Option>
                  {this.state.supplierList.map((v) => (
                    <Option key={v._id}>{v.name}</Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </>
        ),
        dataIndex: 'supplierId.name',
        key: 'supplierId.name',
        width: '10%',
      },
      {
        title: (
          <div>
            <Row>
              <Col span={24}>Reference</Col>
            </Row>
            <Row>
              <Col span={24}>
                <Input onChange={this._searchReferenceChange} />
              </Col>
            </Row>
          </div>
        ),
        dataIndex: 'referenceNo',
        key: 'referenceId',
        width: '10%',
      },
      {
        title: (
          <div>
            <Row>
              <Col span={24}>Cheque No</Col>
            </Row>
            <Row>
              <Col span={24}>
                <Input onChange={this._searchChequeChange} />
              </Col>
            </Row>
          </div>
        ),
        dataIndex: 'chequeNo',
        key: 'chequeNo',
        width: '10%',
      },
      {
        title: 'Remark',
        dataIndex: 'remark',
        key: 'remark',
        width: '10%',
      },
      {
        title: (
          <span
            style={{
              float: 'right',
            }}
          >
            Total Amount
          </span>
        ),
        dataIndex: 'paymentamount',
        key: 'totalAmount',
        width: '10%',
        align: 'right',
        render: (text, record) => (
          <span
            style={{
              float: 'right',
            }}
          >
            {record.paymentamount.toFixed(2)}
          </span>
        ),
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <Divider type='vertical' />
            <Link
              to={{
                pathname: `/paymentvoucher/paymentvoucher-view/${record._id}`,
              }}
            >
              <Button key='navigatePV'>Print</Button>
            </Link>
            <Divider type='vertical' />
            <Button
              key='editPVMast'
              onClick={() => this.handleEdit(record._id)}
            >
              Edit
            </Button>
            {/* <Divider type="vertical" />
          <Link to={{
            pathname: `/paymentvoucher/paymentvoucher-view-detail/${record._id}`
          }}>
            <Button key='navigatePVDetails'>Edit Details</Button>
          </Link> */}
            <Divider type='vertical' />{' '}
            {this.renderConfirmDeleteBtn(record._id, record.referenceId)}
            <Divider type='vertical' /> {this.renderPayButton(record)}
            <Divider type='vertical' />{' '}
            {record.file ? (
              <Button onClick={() => this.handleImage(record.file)}>
                Image
              </Button>
            ) : (
              ''
            )}
          </span>
        ),
      },
    ];

    return (
      <Layout>
        <Spin spinning={this.state.loading} key='spinning'>
          <Card title='Payment Voucher'>
            <Table
              dataSource={datasource}
              columns={columns}
              rowKey='_id'
              pagination={{
                defaultCurrent: this.props.activePage.paymentVoucherPage,
                onChange: this.handlePageChange,
              }}
            />
          </Card>

          <Modal
            title='Image'
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={[
              <Button key='back' onClick={this.handleCancel}>
                Cancel
              </Button>,
            ]}
          >
            <img
              style={{
                width: '100%',
              }}
              src={this.state.img}
            />
          </Modal>

          <Modal
            title='Edit Payment Voucher'
            visible={this.state.visible2}
            onOk={this.handleOk2}
            onCancel={this.handleCancel2}
          >
            <FormItem {...formItemLayout} label='Reference No'>
              {getFieldDecorator('referenceNo', {
                rules: [
                  {
                    required: true,
                    message: 'Please input Reference No!',
                  },
                ],
              })(<Input />)}
            </FormItem>

            <FormItem {...formItemLayout} label='Cheque No'>
              {getFieldDecorator('chequeNo', {
                rules: [
                  {
                    required: false,
                    message: 'Please input Cheque No!',
                  },
                ],
              })(<Input />)}
            </FormItem>

            <FormItem {...formItemLayout} label='Remark'>
              {getFieldDecorator('remark', {
                rules: [
                  {
                    required: false,
                    message: 'Please input Remark!',
                  },
                ],
              })(<Input />)}
            </FormItem>
          </Modal>
        </Spin>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    paymentsvouchermasters: state.paymentsvouchermasters,
    commons: state.commons,
    activePage: state.app.activePage,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchPaymentsVoucherMasters: fetchPaymentsVoucherMasters,
      pushPaymentsVoucherMasters: pushPaymentsVoucherMasters,
      removePaymentsVoucherMasters: removePaymentsVoucherMasters,
      updateActivePaymentsVoucherMasters: updateActivePaymentsVoucherMasters,
      updatePaymentsVoucherMasters: updatePaymentsVoucherMasters,
      updateMode: updateMode,
      updateActivePage,
    },
    dispatch
  );
}

export default requireAuth(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Form.create()(PaymentsVoucherMastersIndex))
);
