import _ from 'lodash';
import {
  FETCH_LEDGERACCOUNTS,
  PUSH_LEDGERACCOUNTS,
  REMOVE_LEDGERACCOUNTS,
  UPDATE_LEDGERACCOUNTS,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID ,UPDATE_DATE} from '../actions/actions-ledgerAccounts';

const INITIAL_STATE = {
  ledgeraccounts: [],
  mode: 'index',
  activeId: '',
  activeLedgerAccounts:{},
  selectedDate:''
}


export default function(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_LEDGERACCOUNTS:
      return {
        ...state,
        ledgeraccounts:action.payload,
      }
    case PUSH_LEDGERACCOUNTS:
      return {
        ...state,
        ledgeraccounts:[action.payload, ...state.ledgeraccounts],
      }
    case REMOVE_LEDGERACCOUNTS:
      return {
        ...state,
        ledgeraccounts : [...state.ledgeraccounts.filter( (item) => item._id !== action.payload._id)]
      }
    case UPDATE_LEDGERACCOUNTS:
      let index =  _.findIndex(state.ledgeraccounts, { '_id': action.payload._id })
      state.ledgeraccounts.splice(index, 1, action.payload)
      return {
        ...state,
        ledgeraccounts : state.ledgeraccounts
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode : action.payload,
        activeId:''
      }
    case UPDATE_ACTIVE_ID:
      return {
        ...state,
        mode : action.mode,
        activeId:action.id,
        activeLedgerAccounts: _.pick(_.find(state.ledgeraccounts, { '_id': action.id }), ['companyId','categories','accountType','accountName','fullAccountNo','accountNo','subAccountNo','description','balance','referenceId','referenceType','specialCode','prepaymentUA','prepayment', '_id'])
      }
      case UPDATE_DATE:
        return {
          ...state,
          selectedDate : action.payload,
        }
    default:
      return state

  }
}
