import React, { Component } from 'react'
import { Popconfirm, message, Divider,  Table, Form, Icon, Input, Button,Card,Row,Col } from 'antd'
import { Route, Link } from 'react-router-dom';

import client from '../../feathers'
import requireAuth  from "../../components/requireAuth"
import Layout  from "../../components/layout"
import LedgersForm  from "../../components/ledgers/form"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  fetchLedgers,
  pushLedgers,
  removeLedgers,
  updateMode,
  updateActiveLedgers,
  updateLedgers } from '../../actions/actions-ledgers'

const FormItem = Form.Item;

const text = 'Are you sure to delete this item?';

class LedgersIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accountName: '',
      accountId: '',
    }

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidUpdate(){
  }

  componentDidMount(){
      client.service('ledgers').on('created', (ledgers) => {
        this.props.pushLedgers(ledgers)
      })

      client.service('ledgers').on('removed', (ledgers) => {
        this.props.removeLedgers(ledgers)
      })

      client.service('ledgers').on('updated', (ledgers) => {
        this.props.updateLedgers(ledgers)
      })

      client.service('ledgers').on('patched', (ledgers) => {
        this.props.updateLedgers(ledgers)
      })
  }



  componentWillMount(){

      client.authenticate()
      .then((res)=>{

          return client.service('ledgers').find({
            query: {
              //propertyId:res.propertyId,
              accountId:this.props.match.params.id,
               $sort: {
                 createdAt: -1
               }
             }
          })
      })
      .then((res)=>{
        this.props.fetchLedgers(res.data)
      })
      .catch((err)=>{
          console.log(err);
      })
      //
      client.authenticate()
      .then((res)=>{
          return client.service('accounts').find({
            query: {
              //propertyId:res.propertyId,
              _id:this.props.match.params.id,
               $sort: {
                 createdAt: -1
               }
             }
          })
      })
      .then((res)=>{
        this.setState({
          accountName:res.data[0].accountName,
          accountId:res.data[0].accountId,
        })
      })
      .catch((err)=>{
          console.log(err);
      })
  }

  handleDelete(_id) {
     client.service('ledgers').remove(_id)
     .then((res)=>{
     })
     .catch((err)=>{
       alert(err);
     })
  }

  handleEdit(_id) {
    this.props.updateActiveLedgers('edit',_id)
  }

  handleManage(_id) {
    this.props.updateActiveLedgers('',_id)
  }

  showModal = () => {
    this.props.updateMode('new')
  }

  renderConfirmDeleteBtn(_id){
    return (
      <Popconfirm placement="topLeft" title={text} onConfirm={()=>this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
        <Button type="danger">Delete</Button>
      </Popconfirm>
    )
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: 'Debit',
        render: (text, record) => (
          record.creditDebit==='DEBIT'?record.amount:''
        ),
      },
      {
        title: 'Credit',
        render: (text, record) => (
          record.creditDebit==='CREDIT'?record.amount:''
        ),
      },
      {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
          <Divider type="vertical" />
          {/*<Button onClick={()=>this.handleEdit(record._id)}>Edit</Button>*/}
          <Divider type="vertical" />
          {/*this.renderConfirmDeleteBtn(record._id)*/}
        </span>
      ),
    }];

    return(
      <Layout>
        {/*<Button type="primary" onClick={this.showModal}>New Ledgers</Button>*/}
        <Row>
          <Col span={6}>
            <Card title="Account">
              {this.state.accountId}
              <br/>
              {this.state.accountName}
            </Card>
          </Col>
          <Col span={18}>
            <Card title="Ledgers">
              <Table dataSource={this.props.ledgers.ledgers} columns={columns} rowKey="_id" />
              <LedgersForm />
            </Card>
          </Col>
        </Row>
      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    ledgers: state.ledgers,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchLedgers: fetchLedgers,
    pushLedgers: pushLedgers,
    removeLedgers: removeLedgers,
    updateActiveLedgers: updateActiveLedgers,
    updateLedgers: updateLedgers,
    updateMode: updateMode
    }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(LedgersIndex)));
