import _ from 'lodash';
import {
  FETCH_LEDGERS,
  PUSH_LEDGERS,
  REMOVE_LEDGERS,
  UPDATE_LEDGERS,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID } from '../actions/actions-ledgers';

const INITIAL_STATE = {
  ledgers: [],
  mode: 'index',
  activeId: '',
  activeLedgers:{}
}


export default function(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_LEDGERS:
      return {
        ...state,
        ledgers:action.payload,
      }
    case PUSH_LEDGERS:
      return {
        ...state,
        ledgers:[action.payload, ...state.ledgers],
      }
    case REMOVE_LEDGERS:
      return {
        ...state,
        ledgers : [...state.ledgers.filter( (item) => item._id !== action.payload._id)]
      }
    case UPDATE_LEDGERS:
      let index =  _.findIndex(state.ledgers, { '_id': action.payload._id })
      state.ledgers.splice(index, 1, action.payload)
      return {
        ...state,
        ledgers : state.ledgers
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode : action.payload,
        activeId:''
      }
    case UPDATE_ACTIVE_ID:
      return {
        ...state,
        mode : action.mode,
        activeId:action.id,
        activeLedgers: _.pick(_.find(state.ledgers, { '_id': action.id }), ['name', 'description', 'unit', 'categories','amount', 'accountName', 'accountType', 'accountNo', 'subAccountNo', 'bankAccountFlag', '_id'])
      }
    default:
      return state

  }
}
