import React, { Component } from 'react';
import { Popconfirm, Table, Form, Input, Button, Card, Modal, Select, Row, Col, Tabs, Badge } from 'antd';
import client from '../../feathers';
import requireAuth from "../requireAuth";
import Layout from "../layout";
import RequestsFormMng from "./form-mng";
import moment from "moment";


import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchRequests,
  pushRequests,
  removeRequests,
  updateMode,
  updateActiveRequests,
  updateRequests
} from '../../actions/actions-requests';
import axios from 'axios';
import _ from 'lodash';



const TabPane = Tabs.TabPane;
const text = 'Are you sure to delete this item?';

class RequestsIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      img: '',
      status: '',
      totalPagination: 1,
      totalPagination2: 1,
      CurrentPagination: 1,
      CurrentPagination2: 1,
      searchName: '',
      searchUnitNo: '',
      searchUnitNo2: '',
      searchStatus: '',
      searchObj: {},
      request2: {},
      loading: false,
      totalPending: 0,
      totalApproved: 0,
      totalRejected: 0,
      statusType: ''
    }

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidUpdate() {

    if (this.props.requests.mode === 'refresh') {
      this.getData(0)
    }
  }

  componentDidMount() {

  }

  componentWillMount() {

    this.props.updateMode('')

    this.setState({
      loading: false,
      statusType: 'PENDING'

    }, () => {
      this.getData(0)
    });

  }

  getData() {
    this.props.updateMode('')
    const { statusType } = this.state
    client.authenticate()
      .then((res) => {

        let unitNoQuery = ''
        if (!_.isEmpty(this.state.searchUnitNo)) {
          unitNoQuery = { orRegex: { propertyUnitName: this.state.searchUnitNo } }
        }


        axios.get(`${client.io.io.uri}getTotalStatusRequest`, {
          params: {
            propertyId: this.props.user.info.propertyId._id,
          },
          headers: {
            'Authorization': client.settings.storage.storage['feathers-jwt']
          }
        }).then((res) => {
          let data = []
          try { data = res.data.data } catch (err) { data = [] }

          for (let i = 0; i < data.length; i++) {
            let result = data[i]

            if (result._id === 'PENDING') { this.setState({ totalPending: result.totalCount }) }
            if (result._id === 'APPROVED') { this.setState({ totalApproved: result.totalCount }) }
            if (result._id === 'REJECTED') { this.setState({ totalRejected: result.totalCount }) }
          }
        })

        //return client.service('requests').find({
        return client.service('requests').find({
          query: {
            ...unitNoQuery,
            propertyId: this.props.user.info.propertyId._id,
            $populate: 'userId propertyId propertyunitId',
            status: statusType,
            $sort: {
              createdAt: -1
            },
            $limit: 20,
          }
        })
      })
      .then((res) => {

        this.props.fetchRequests(res.data);
        this.setState({
          totalPagination: res.total
        });
      })

      .catch((err) => {
        console.log(err);
      });
  }



  handleDelete(_id) {
    client.service('requests').remove(_id)
      .then((res) => {
      })
      .catch((err) => {
        alert(err);
      })
  }

  handleEdit(_id) {
    this.props.updateActiveRequests('edit', _id)
  }

  handleManage(_id) {
    this.props.updateActiveRequests('', _id)
  }

  showModal = () => {
    this.props.updateMode('new')
  }

  _searchNameChange = (e) => {

    this.setState({ searchName: e.target.value });
    setTimeout(() => {
      this.handleSearch();
    }, 1000);
  }

  _searchUnitNumberChange = (e) => {
    this.setState({ searchUnitNo: e.target.value, searchStatus: 'PENDING' });
    setTimeout(() => {
      this.getData();
    }, 1000);
  }

  _searchUnitNumberChange2 = (e) => {
    this.setState({ searchUnitNo2: e.target.value, searchStatus: 'APPROVED' });
    setTimeout(() => {
      this.handleSearch2();
    }, 1000);
  }

  _searchStatusChange = (e) => {
    this.setState({ searchStatus: e.target.value });
    setTimeout(() => {
      this.handleSearch();
    }, 1000);

  }

  handleImage(img = null) {
    this.setState({
      visible: true,
      img: img
    });
  }

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  }

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });

  }



  handleSearch2() {


    let searchObj = this.state.searchUnitNo2 !== '' ? { propertyUnitName: this.state.searchUnitNo2 } : {};
    searchObj = this.state.searchStatus !== '' ? { ...searchObj, status: this.state.searchStatus } : { ...searchObj };

    client.authenticate()
      .then((res) => {
        return client.service('requests').find({
          query: {
            orRegex: searchObj,
            propertyId: this.props.user.info.propertyId._id,
            $populate: 'userId propertyId propertyunitId',
            $sort: {
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {
        this.setState({ totalPagination: res.total, request2: res.data });
      })
      .catch((err) => {
        console.log(err);
      })

  }

  renderConfirmDeleteBtn(_id) {
    return (
      <Popconfirm placement="topLeft" title={text} onConfirm={() => this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
        <Button type="danger">Delete</Button>
      </Popconfirm>
    )
  }
  handleOnChangeTab = (e) => {

    this.setState({
      statusType: e
    }, () => {
      this.getData(0)
    })

  }

  renderUserRecord(record) {
    if (record.status === 'APPROVED') {
      let currentUserId = record.userId._id
      let propertyUsers = record.propertyunitId.users


      propertyUsers = _.find(propertyUsers, function (b) {
        return b.userId === currentUserId
      })


      let userType = _.get(propertyUsers, ['userType'], '-')

      return (userType.charAt(0).toUpperCase() + userType.slice(1))

    }
    else {
      return

    }

  }

  render() {
    const loading = this.state.loading;
    const { statusType } = this.state

    const columns = [
      {
        title: 'Apps User Name',
        dataIndex: 'userId.firstName',
        key: 'userId.firstName',
      },
      // {
      //   title: 'Property',
      //   dataIndex: 'propertyName',
      //   key: 'propertyName',
      // },
      {
        title: (
          <Row gutter={8}>
            <Row><Col span={24}>Unit No</Col></Row>
            <Row><Col span={24}><Input style={{ width: '50%' }} value={this.state.searchUnitNo} onChange={this._searchUnitNumberChange} /></Col></Row>
          </Row>
        ),
        // title: 'Property Unit',
        dataIndex: 'propertyUnitName',
        key: 'propertyUnitName',

      },
      {
        title: 'User Type',
        key: 'userType',
        render: (text, record) => {
          return (this.renderUserRecord(record))
        }
      },

      {
        title: 'Created Time',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text, record) => (
          moment(text).format("hh:mm DD/MM/YYYY ")
        ),
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            {/* <RequestsFormMng dataSource={record} /> */}
            <Button type='primary' onClick={() => {
              this.props.updateActiveRequests('view', record._id)
            }}>View</Button>
          </span>
        ),
      }];
    //------------Column------------------------------------------------------------------------



    return (
      <Layout>
        <Card
          title="Apps Request"
        >




          <Tabs defaultActiveKey="PENDING" onChange={this.handleOnChangeTab}>
            <TabPane tab={(
              <div>
                <span className='marginRight8px' >Pending</span>
                <Badge overflowCount={999999} style={{ marginLeft: '8px', backgroundColor: '#1DA57A' }}
                  count={this.state.totalPending} />
              </div>
            )} key="PENDING">
            </TabPane>

            <TabPane tab={(
              <div>
                <span className='marginRight8px' >Approved</span>
                <Badge overflowCount={9999} style={{ marginLeft: '8px', backgroundColor: '#1DA57A' }}
                  count={this.state.totalApproved} />
              </div>
            )} key="APPROVED">
            </TabPane>

            <TabPane tab={(
              <div>
                <span className='marginRight8px' >Rejected</span>
                <Badge overflowCount={9999} style={{ marginLeft: '8px', backgroundColor: '#1DA57A' }}
                  count={this.state.totalRejected} />
              </div>
            )} key="REJECTED">
            </TabPane>


          </Tabs>

          <Table
            dataSource={this.props.requests.requests}
            columns={statusType === 'APPROVED' ? columns : columns.filter(ev => ev.key !== 'userType')}
            rowKey="_id"
            size={'small'}
            loading={loading}
          />



          <Modal
            title="Image"
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            okText="Done"
          >
            <img alt='requestImg' style={{ 'width': '100%' }} src={this.state.img} />
          </Modal>

          <RequestsFormMng />
        </Card>



      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    requests: state.requests,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchRequests: fetchRequests,
    pushRequests: pushRequests,
    removeRequests: removeRequests,
    updateActiveRequests: updateActiveRequests,
    updateRequests: updateRequests,
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(RequestsIndex)));
