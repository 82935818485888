import { Col, DatePicker, Form, Row } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchDebtorAgingReports } from '../../actions/actions-debtorAgingReports';
import DebtorAgingPrint from "../../components/debtorAging/print";
import Layout from "../../components/layout";
import requireAuth from "../../components/requireAuth";



const { MonthPicker, RangePicker, WeekPicker } = DatePicker;
const FormItem = Form.Item;

const text = 'Are you sure to delete this item?';

class DebtorAgingIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      supplierReport: [],
      toDate: moment().endOf('year').format('YYYYMMDD'),
    }

  }

  toDateOnChange = (e) => {
    this.setState({
      toDate:    moment(new Date(e)).format("YYYY-MM-DD")
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Layout>
        <div className='wrap-reactToPrint'>
          <Row gutter={24}>
            <Col span={6} offset={16}>
              <DatePicker onChange={this.toDateOnChange} />
            </Col>
            <Col span={2}>
              {/* <ReactToPrint
                trigger={() => <Button type="primary">Print</Button>}
                content={() => this.componentRef}
              /> */}
            </Col>
          </Row>
        </div>
        <DebtorAgingPrint key={this.state.toDate} toDate={this.state.toDate}/>
      </Layout>
    )
  }
}


function mapStateToProps(state) {

  return {
    debtorAgingReports: state.debtorAgingReports,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchDebtorAgingReports: fetchDebtorAgingReports
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(DebtorAgingIndex)));
