import _ from 'lodash';
import {
  FETCH_COMPLAINTS,
  PUSH_COMPLAINTS,
  REMOVE_COMPLAINTS,
  UPDATE_COMPLAINTS,
  UPDATE_MODE,
  UPDATE_COMPLAINT_ACTIVE_ID } from '../actions/actions-complaints';

const INITIAL_STATE = {
  complaints: [],
  mode: 'index',
  activeId: '',
  activeComplaints:{}
}


export default function(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_COMPLAINTS:
      return {
        ...state,
        complaints:action.payload,
      }
    case PUSH_COMPLAINTS:
      return {
        ...state,
        complaints:[action.payload, ...state.complaints],
      }
    case REMOVE_COMPLAINTS:
      return {
        ...state,
        complaints : [...state.complaints.filter( (item) => item._id !== action.payload._id)]
      }
    case UPDATE_COMPLAINTS:
      let index =  _.findIndex(state.complaints, { '_id': action.payload._id })
      state.complaints.splice(index, 1, action.payload)
      return {
        ...state,
        complaints : state.complaints
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode : action.payload,
        activeId:''
      }
    case UPDATE_COMPLAINT_ACTIVE_ID:
      return {
        ...state,
        mode : action.mode,
        activeId:action.id,
        activeComplaints: _.pick(_.find(state.complaints, { '_id': action.id}), ['propertyId', 'propertyunitId','subject', 'description', 'status', 'remark', 'categories', 'email', 'complaintsType', 'files', 'createdBy', '_id'])
      }
    default:
      return state

  }
}
