import React, { Component } from 'react';
import { Modal, message, Form, Icon, Input, Select } from 'antd';
import client from '../../feathers'
import requireAuth  from "../../components/requireAuth";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchProfomas,
  pushProfomas,
  removeProfomas,
  updateMode,
  updateActiveProfomas,
  updateProfomas } from '../../actions/actions-profomas';

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

class ProfomasForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id:'',
      confirmDirty: false,
      autoCompleteResult: [],
      chargesDropdowns : [],
    }
  }

  componentWillMount(){
    //generate charges dropdown
    client.authenticate()
    .then((res)=>{
        return client.service('charges').find({
          query: {
            propertyId: res.propertyId,
             $sort: {
               createdAt: -1
             }
           }
        })
    })
    .then((res)=>{
      this.setState({
        chargesDropdowns:res.data
      })
    })
    .catch((err)=>{
        console.log(err);
    });
  }

  componentDidUpdate(){
      if(this.props.profomas.activeId !== this.state._id){
        this.setState({
          _id:this.props.profomas.activeId
        })

        if(this.props.profomas.activeProfomas){
          this.props.form.setFieldsValue(
            this.props.profomas.activeProfomas
          )
        }
      }

  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }

  handleSubmit = (e) => {
     e.preventDefault();
     if(this.props.profomas.mode === 'new'){

       this.props.form.validateFields((err, values) => {
         if (!err) {
           client.service('propertyunits').patch(this.props.propertyunitId,{
            '$push':{'recurringInvoice':{
              'chargesId':values.chargesId,
              'description':values.description,
              'amount': values.amount,
              'status': values.status,
              'createdAt': new Date(),
            } }
           })
           .then((res)=>{

             this.props.updateMode('');
             this.props.fetchProfomas(res.recurringInvoice);
             message.success('New Item Added');
           })
           .catch((err)=>{
            console.log(err);
             this.props.updateMode('')
             alert(err);
           })
         }
       });
     }else{
       this.props.form.validateFields((err, values) => {
         if (!err) {
           client.service('profomas').update(this.props.profomas.activeId,
             values
           )
           .then((res)=>{

             message.success(`Profomas Updated`);
             this.props.updateMode('')
           })
           .catch((err)=>{
            console.log(err);
             
             this.props.updateMode('')
           })
           return;
         }
         
       });
     }
  }

  render() {

    const { getFieldDecorator } = this.props.form;


    return(
      <Modal
         visible={
           this.props.profomas.mode==='new'||this.props.profomas.mode==='edit'?true:false
         }
         title={ this.props.profomas.mode === 'edit' ? 'Update Item':'Create Item' }
         okText={ this.props.profomas.mode === 'edit' ? 'Update':'Create' }
         onCancel={()=>{this.props.updateMode(''); this.props.form.resetFields()} }
         onOk={this.handleSubmit}

      >
        <Form className="submit-form">

          <FormItem
            {...formItemLayout}
            label="Select Charges"
            hasFeedback
          >
            {getFieldDecorator('chargesId', {
              rules: [
                { required: true, message: 'Please select charges!' },
              ],
            })(
              <Select placeholder="Select Type">
                {this.state.chargesDropdowns.map(dropdown => (
                  <Option value={dropdown.code}>
                    {dropdown.description}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>

          <FormItem
             {...formItemLayout}
             label="Description"
           >
             {getFieldDecorator('description', {
               rules: [{
                 required: false, message: 'Please input description!',
               }],
             })(
               <Input prefix={<Icon type="idcard" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="SUPERHERO" />
             )}
          </FormItem>

          <FormItem
             {...formItemLayout}
             label="Amount"
           >
             {getFieldDecorator('amount', {
               rules: [{
                 required: false, message: 'Please input Amount!',
               }],
             })(
              <Input placeholder="100.00" />
             )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Select Status"
            hasFeedback
          >
            {getFieldDecorator('status', {
              rules: [
                { required: true, message: 'Please select Status!' },
              ],
            })(
              <Select placeholder="Please select Status">
                <Option value="active">Active</Option>
                <Option value="inactive">Inactive</Option>
              </Select>
            )}
          </FormItem>

        </Form>
      </Modal>

    )
  }
}


function mapStateToProps(state) {
  return {
    profomas: state.profomas
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchProfomas: fetchProfomas,
    pushProfomas: pushProfomas,
    removeProfomas: removeProfomas,
    updateActiveProfomas: updateActiveProfomas,
    updateProfomas: updateProfomas,
    updateMode: updateMode
    }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(ProfomasForm)));
