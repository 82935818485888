import React, { Component } from 'react';
import { Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Tabs, Card, Select, DatePicker, Row, Col } from 'antd';
import { Route, Link } from 'react-router-dom';

import client from '../../feathers';
import requireAuth from "../requireAuth";
import Layout from "../layout";
import axios from 'axios';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchAccounts,
  pushAccounts,
  removeAccounts,
  updateMode,
  updateActiveAccounts,
  updateAccounts
} from '../../actions/actions-accounts';

const FormItem = Form.Item;
const TabPane = Tabs.TabPane;
const { Option, OptGroup } = Select;
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const text = 'Are you sure to delete this item?';

class AccountsIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: 'assests',
      fromDate: '2019-01-01',
      toDate: '2019-12-31',
      fixedAssests: [],
      currentAssests: [],
      fixedLibilities: [],
      currentLibilities: [],
      equity: [],
      totalFixedAssests: 0,
      totalCurrentAssests: 0,
      totalCurrentLiabilities: 0,
      totalEquity: 0,
      netCurrentAssests: 0,
      totalIncomes: 0,
      totalExpenses: 0,
    }

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidUpdate() {
  }

  componentDidMount() {

  }

  componentWillMount() {
    console.log(this.props)
    client.authenticate()
      .then((res) => {
        return axios.get(`${client.io.io.uri}balancesheet`, {
          params: {
            companyId: this.props.user.info.propertyId._id,
            companyType: 'PROPERTY'
          },
          headers: {
            'Authorization': client.settings.storage.storage['feathers-jwt']
          }
        })
      })
      .then((res) => {


        //this.props.fetchAccounts(res.data);
      })
      .catch((err) => {
        console.log('err');
        console.log(err);
      })

    this.handleClick();
  }

  handleClick = () => {

    this.setState({
      netCurrentAssests: 0,
    });

    //current assest
    client.authenticate()
      .then((res) => {

        return client.service('accounts').find({
          query: {
            $limit: 99,
            accountmainId: sessionStorage.getItem('accountmainId'),
            //categories:'assests',
            accountType: 'current assests',
            //subAccountId: '000',
            $sort: {
              accountId: 1,
              subAccountId: 1
            },
            $client: {
              fromDate: this.state.fromDate,
              toDate: this.state.toDate,
            }
          },
        })
      })
      .then((res) => {

        this.setState({
          currentAssests: res.data,
          totalCurrentAssests: res.totalDebitBalance - res.totalCreditBalance,
          netCurrentAssests: this.state.netCurrentAssests + (res.totalDebitBalance - res.totalCreditBalance),
        });
      })
      .catch((err) => {
        console.log(err);
      })
    //fixed assests
    client.authenticate()
      .then((res) => {

        return client.service('accounts').find({
          query: {
            $limit: 99,
            accountmainId: sessionStorage.getItem('accountmainId'),
            //categories:'liabilities',
            accountType: 'fixed assests',
            $sort: {
              accountId: 1,
              subAccountId: 1
            },
            $client: {
              fromDate: this.state.fromDate,
              toDate: this.state.toDate,
            }
          },
        })
      })
      .then((res) => {

        this.setState({
          fixedAssests: res.data,
          fixedCurrentAssests: res.totalDebitBalance - res.totalCreditBalance,
          netCurrentAssests: this.state.netCurrentAssests + (res.totalDebitBalance - res.totalCreditBalance),
        });
      })
      .catch((err) => {
        console.log(err);
      })
    //Current Liabilities
    client.authenticate()
      .then((res) => {

        return client.service('accounts').find({
          query: {
            $limit: 99,
            accountmainId: sessionStorage.getItem('accountmainId'),
            //categories:'equity',
            accountType: 'current liabilites',
            $sort: {
              accountId: 1,
              subAccountId: 1
            },
            $client: {
              fromDate: this.state.fromDate,
              toDate: this.state.toDate,
            }
          },
        })
      })
      .then((res) => {

        this.setState({
          currentLiabilities: res.data,
          totalCurrentLiabilities: res.totalDebitBalance - res.totalCreditBalance,
          netCurrentAssests: this.state.netCurrentAssests - (res.totalDebitBalance - res.totalCreditBalance),
        });
      })
      .catch((err) => {
        console.log(err);
      })
    //Equity
    client.authenticate()
      .then((res) => {

        return client.service('accounts').find({
          query: {
            $limit: 99,
            accountmainId: sessionStorage.getItem('accountmainId'),
            categories: 'equity',
            $sort: {
              accountId: 1,
              subAccountId: 1
            },
            $client: {
              fromDate: this.state.fromDate,
              toDate: this.state.toDate,
            }
          },
        })
      })
      .then((res) => {

        this.setState({
          equity: res.data,
          totalEquity: res.totalDebitBalance - res.totalCreditBalance,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    //incomes
    client.authenticate()
      .then((res) => {

        return client.service('accounts').find({
          query: {
            $limit: 99,
            accountmainId: sessionStorage.getItem('accountmainId'),
            categories: 'incomes',
            $sort: {
              accountId: 1,
              subAccountId: 1
            },
            $client: {
              fromDate: this.state.fromDate,
              toDate: this.state.toDate,
            }
          },
        })
      })
      .then((res) => {

        this.setState({
          totalIncomes: res.totalCreditBalance - res.totalDebitBalance,
          //totalEquity : this.state.totalEquity+(res.totalDebitBalance-res.totalCreditBalance),
        });
      })
      .catch((err) => {
        console.log(err);
      })
    //expenses
    client.authenticate()
      .then((res) => {

        return client.service('accounts').find({
          query: {
            $limit: 99,
            accountmainId: sessionStorage.getItem('accountmainId'),
            categories: 'expenses',
            $sort: {
              accountId: 1,
              subAccountId: 1
            },
            $client: {
              fromDate: this.state.fromDate,
              toDate: this.state.toDate,
            }
          },
        })
      })
      .then((res) => {


        this.setState({
          totalExpenses: res.totalDebitBalance - res.totalCreditBalance,
          //totalEquity : this.state.totalEquity+(res.totalDebitBalance-res.totalCreditBalance),
        });
      })
      .catch((err) => {
        console.log(err);
      })
  }


  categoriesOnChange = (e) => {

    this.setState({
      categories: e
    });
  }

  fromDateOnChange = (e) => {
    this.setState({
      fromDate: e.target.value
    });
  }

  toDateOnChange = (e) => {
    this.setState({
      toDate: e.target.value
    });
  }

  handleDelete(_id) {
    client.service('accounts').remove(_id)
      .then((res) => {
      })
      .catch((err) => {
        alert(err);
      })
  }

  handleEdit(_id) {
    this.props.updateActiveAccounts('edit', _id)
  }

  handleManage(_id) {
    this.props.updateActiveAccounts('', _id)
  }

  showModal = () => {
    this.props.updateMode('new')
  }

  renderConfirmDeleteBtn(_id) {
    return (
      <Popconfirm placement="topLeft" title={text} onConfirm={() => this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
        <Button type="danger">Delete</Button>
      </Popconfirm>
    )
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        width: '50%',
      },
      {
        title: 'Balance',
        dataIndex: 'balance',
        key: 'balance',
        render: (text, record) => (
          <span>
            {record.description === 'PROFIT AND LOSS ACCOUNT' ? this.state.totalIncomes - this.state.totalExpenses : record.balance}
          </span>
        ),
      },
    ];

    return (
      <Layout>
        <Card
          title={'Balance Sheet'}
          extra={[
            <input onChange={this.fromDateOnChange} type='date' defaultValue={this.state.fromDate} />,
            <input onChange={this.toDateOnChange} type='date' defaultValue={this.state.toDate} />,
            <Button onClick={this.handleClick} type="primary">Search</Button>
          ]}
        >
          <Card
            title={'Current Assests : ' + this.state.totalCurrentAssests}
          >
            <Table dataSource={this.state.currentAssests} columns={columns} rowKey="_id" />
          </Card>
          <Card
            title={'Fixed Assests : ' + this.state.totalFixedAssests}
          >
            <Table dataSource={this.state.fixedAssests} columns={columns} rowKey="_id" />
          </Card>
          <Card
            title={'Current Liabilities : ' + this.state.totalCurrentLiabilities}
          >
            <Table dataSource={this.state.currentLibilities} columns={columns} rowKey="_id" />
          </Card>
          <Card
            title='NET CURRENT ASSESTS'
          >
            <Row>
              <Col span={12}>
              </Col>
              <Col span={12}>
                {this.state.netCurrentAssests}
              </Col>
            </Row>
          </Card>
          <Card
            title={'Equity : ' + this.state.totalEquity}
          >
            <Table dataSource={this.state.equity} columns={columns} rowKey="_id" />
          </Card>
          {/*<Table dataSource={this.props.accounts.accounts} columns={columns} rowKey="_id" />*/}
        </Card>
      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    accounts: state.accounts,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchAccounts: fetchAccounts,
    pushAccounts: pushAccounts,
    removeAccounts: removeAccounts,
    updateActiveAccounts: updateActiveAccounts,
    updateAccounts: updateAccounts,
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(AccountsIndex)));
