import TableToExcel from '@linways/table-to-excel';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Row,
  Spin,
  Table,
  Tabs,
} from 'antd';
import axios from 'axios';
import moment from 'moment';
import React from 'react';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import ReactToPrint from 'react-to-print';
import { bindActionCreators } from 'redux';
import PNLYearly from '../../components/reports/index-profit-and-loss-yearly';
import client from '../../feathers';
import Layout from '../layout';
import requireAuth from '../requireAuth';

const _ = require('lodash');
const TabPane = Tabs.TabPane;

class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      loading: false,
      accountingPeriods: [],
      jmb: this.props.jmbs,
      defaultValue: '',
      fromDate: moment()
        .startOf('year')
        .format('YYYY-MM-DD'),
      toDate: moment()
        .endOf('year')
        .format('YYYY-MM-DD'),
      tableColumn: [],
    };
  }

  componentWillMount() {
    this._getData();
    this.setState({
      tableColumn: [
        {
          title: 'Account No.',
          dataIndex: 'ledgerAccountNo',
          width: 250,
        },
        {
          title: 'Account Name',
          dataIndex: 'ledgerAccountName',
          width: 250,
        },
        {
          title: 'Amount',
          width: 250,
          dataIndex: 'balance',
          render: (v) => this._renderAmount(v),
        },
      ],
    });
  }

  _getData() {
    document.title = `${this.props.companyId.name}_P&L Summary_ ${moment(
      this.state.fromDate
    ).format('YYYYMMDD')}-${moment(this.state.toDate).format('YYYYMMDD')}`;
    this.setState({ loading: true });

    let companyId = this.props.companyId._id;

    client
      .authenticate()
      .then(() => {
        return client.service('jmbs').find({
          query: {
            propertyId: companyId,
          },
        });
      })
      .then((res) => {
        this.setState({
          jmbsName: res.data[0].name,
          jmbsNo: res.data[0].registrationNo,
        });
      })
      .then((res) => {
        return axios.get(`${client.io.io.uri}ledgerTrxSummary`, {
          params: {
            companyId: companyId,
            toDate: this.state.toDate,
            fromDate: this.state.fromDate,
            profitAndLossFilter: true,
          },
          headers: {
            Authorization: client.settings.storage.storage['feathers-jwt'],
          },
        });
      })
      .then((res) => {
        this.setState({
          dataSource: res.data[0],
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidUpdate() {
    if (this.props.fromDate !== this.state.fromDate) {
      this.setState({ fromDate: this.props.fromDate }, () => {
        this.componentWillMount();
      });
    }
    if (this.props.toDate !== this.state.toDate) {
      this.setState({ toDate: this.props.toDate }, () => {
        this.componentWillMount();
      });
    }
  }

  handleChangeDate = (e) => {
    this.setState({
      loading: true,
      defaultValue: e,
    });
    this._getData();
  };

  _renderAmount(v) {
    v = parseFloat(v.toFixed(2));
    return (
      <span style={{ float: 'right' }}>
        <NumberFormat
          prefix={v < 0 ? '(' : ''}
          suffix={v < 0 ? ')' : ''}
          thousandSeparator={true}
          displayType='text'
          fixedDecimalScale={true}
          decimalScale={2}
          value={v < 0 ? v * -1 : v}
        />
      </span>
    );
  }

  _renderExcelRows() {
    const { dataSource, tableColumn } = this.state;
    let dataSourceIncomes = [];
    let dataSourceExpenses = [];
    let titleMain = '';
    let totalIncomes = 0.0;
    let totalExpenses = 0.0;

    function checkVal(k, i, n, underline, key) {
      let a = { 'data-b-b-s': false };
      if (i[k.dataIndex]) {
        if (i.titleMain) {
          a['data-f-sz'] = '18';
          a['data-f-bold'] = true;
        }
        if (i.titleRow) {
          a['data-f-sz'] = '13';
          a['data-f-bold'] = true;
        }
        if (n) {
          a['data-t'] = 'n';
          a['data-num-fmt'] = '#,##0.00;[RED](#,##0.00)';
        }
        return (
          <td {...a} key={key}>
            {i[k.dataIndex]}
          </td>
        );
      }
      return <td {...a} key={key}></td>;
    }

    dataSource.forEach(function(item, index) {
      if (item.ledgerAccountCat === 'INCOMES') {
        item.ledgerAccountNo =
          item.ledgerAccountNo + '/' + item.ledgerAccountSubAcc;
        dataSourceIncomes.push(item);
        totalIncomes = parseFloat(
          parseFloat(totalIncomes) + parseFloat(item.balance)
        ).toFixed(2);
      } else if (item.ledgerAccountCat === 'EXPENSES') {
        item.ledgerAccountNo =
          item.ledgerAccountNo + '/' + item.ledgerAccountSubAcc;
        dataSourceExpenses.push(item);
        totalExpenses = parseFloat(
          parseFloat(totalExpenses) + parseFloat(item.balance)
        ).toFixed(2);
      }
    });

    function _renderTableInfo(catTitle, data, total) {
      return (
        <>
          <tr key={'td-header-1'}>
            <td data-f-sz='18' data-f-bold='true'>
              {catTitle}
            </td>
          </tr>
          <tr>
            {tableColumn.map((i, index) => {
              let title = i.title;
              return (
                <td
                  key={'tr-title-td-' + index}
                  data-f-sz='16'
                  data-f-bold='true'
                  data-a-h='center'
                  data-b-b-s='medium'
                  data-b-t-s='medium'
                >
                  {title}
                </td>
              );
            })}
          </tr>
          {//rows
          data.map((i, indexi) => {
            return (
              <tr>
                {tableColumn.map((j, index) => {
                  return (
                    <>
                      {checkVal(
                        j,
                        i,
                        index > 1,
                        i.titleMain,
                        'td-amt-' + indexi + '-td-' + index
                      )}
                    </>
                  );
                })}
              </tr>
            );
          })}
          <tr key={'td-header-2'}>
            <td
              data-f-sz='13'
              colspan='3'
              align='right'
              data-a-rtl='true'
              data-f-bold='true'
            >
              Total: {total}
            </td>
          </tr>
        </>
      );
    }

    function renderTotalAmount(netIncome) {
      return (
        <tr key={'td-header-2'}>
          <td data-f-sz='13' data-f-bold='true'>
            NET INCOME: {parseFloat(netIncome).toFixed(2)}
          </td>
        </tr>
      );
    }

    let netIncome = totalIncomes - totalExpenses;

    return (
      <>
        {_renderTableInfo('INCOMES', dataSourceIncomes, totalIncomes)}
        <tr />
        {_renderTableInfo('EXPENSES', dataSourceExpenses, totalExpenses)}
        <tr />
        {renderTotalAmount(netIncome)}
      </>
    );
  }

  render() {
    const {
      dataSource,
      jmb,
      loading,
      accountingPeriods,
      defaultValue,
    } = this.state;
    var { fromDateD, toDateD } = this.state;

    fromDateD = moment(new Date(this.state.fromDate)).format('Do MMM YYYY');
    toDateD = moment(new Date(this.state.toDate)).format('Do MMM YYYY');

    let dataSourceIncomes = [];
    let dataSourceExpenses = [];
    let totalIncomes = 0.0;
    let totalExpenses = 0.0;

    dataSource.forEach(function(item, index) {
      if (item.ledgerAccountCat === 'INCOMES') {
        dataSourceIncomes.push(item);
        totalIncomes = parseFloat(
          parseFloat(totalIncomes) + parseFloat(item.balance)
        ).toFixed(2);
      } else if (item.ledgerAccountCat === 'EXPENSES') {
        dataSourceExpenses.push(item);
        totalExpenses = parseFloat(
          parseFloat(totalExpenses) + parseFloat(item.balance)
        ).toFixed(2);
      }
    });
    let netIncome = totalIncomes - totalExpenses;

    const columnsIncomes = [
      {
        title: 'Account No.',
        dataIndex: 'accountInfo.fullAccountNo',
        width: '30%',
      },
      {
        title: 'Account Name',
        dataIndex: 'accountInfo.accountName',
        width: '55%',
      },
      {
        title: 'Amount',
        width: '15%',
        align: 'right',
        dataIndex: 'balance',
        render: (v) => (
          <NumberFormat
            prefix={v < 0 ? '(' : ''}
            suffix={v < 0 ? ')' : ''}
            thousandSeparator={true}
            displayType='text'
            fixedDecimalScale={true}
            decimalScale={2}
            value={v < 0 ? v * -1 : v}
          />
        ),
      },
    ];

    const columnsExpenses = [
      {
        title: 'Account No.',
        dataIndex: 'accountInfo.fullAccountNo',
        width: '30%',
      },
      {
        title: 'Account Name',
        dataIndex: 'accountInfo.accountName',
        width: '55%',
      },
      {
        title: <span style={{ float: 'right' }}>Amount</span>,
        width: '15%',
        dataIndex: 'balance',
        render: (v) => this._renderAmount(v),
      },
    ];

    return (
      <div>
        <Spin spinning={loading}>
          <Card
            title={
              <div>
                <span style={{ fontSize: 20 }}>{jmb ? jmb.name : ''}</span>
                <br />
                <span>({jmb ? jmb.registrationNo : ''})</span>
                <br />
                <span style={{ fontSize: 20 }}>PROFIT & LOSS STATEMENT</span>
                <span className='alignRight'>
                  As at selected date {fromDateD} - {toDateD}
                </span>
              </div>
            }
          >
            <Table
              dataSource={dataSourceIncomes}
              columns={columnsIncomes}
              pagination={false}
              title={() => 'INCOMES'}
              footer={() => (
                <div style={{ height: '20px' }}>
                  <span style={{ float: 'right' }}>
                    TOTAL:
                    <NumberFormat
                      prefix={totalIncomes < 0 ? '(' : ''}
                      suffix={totalIncomes < 0 ? ')' : ''}
                      thousandSeparator={true}
                      displayType='text'
                      fixedDecimalScale={true}
                      decimalScale={2}
                      value={
                        totalIncomes < 0 ? totalIncomes * -1 : totalIncomes
                      }
                    />
                  </span>
                </div>
              )}
              size={'small'}
            />
            <br />
            <Table
              dataSource={dataSourceExpenses}
              columns={columnsExpenses}
              pagination={false}
              title={() => 'EXPENSES'}
              footer={() => (
                <div style={{ height: '20px' }}>
                  <span style={{ float: 'right' }}>
                    TOTAL :
                    <NumberFormat
                      prefix={totalExpenses < 0 ? '(' : ''}
                      suffix={totalExpenses < 0 ? ')' : ''}
                      thousandSeparator={true}
                      displayType='text'
                      fixedDecimalScale={true}
                      decimalScale={2}
                      value={
                        totalExpenses < 0 ? totalExpenses * -1 : totalExpenses
                      }
                    />
                  </span>
                </div>
              )}
              size={'small'}
            />
            <table
              style={{ display: 'none' }}
              key={'printExcel-table'}
              data-cols-width={
                '40' +
                ',12'.repeat(
                  this.state.tableColumn.length > 0
                    ? this.state.tableColumn.length - 1
                    : 0
                )
              }
              className='printExcel'
            >
              <tbody>{this._renderExcelRows()}</tbody>
            </table>
            <br />
            <Card>
              <h6>
                NET INCOME :
                <span style={{ float: 'right', fontWeight: 'bolder' }}>
                  <NumberFormat
                    prefix={netIncome < 0 ? '(' : ''}
                    suffix={netIncome < 0 ? ')' : ''}
                    thousandSeparator={true}
                    displayType='text'
                    fixedDecimalScale={true}
                    decimalScale={2}
                    value={netIncome < 0 ? netIncome * -1 : netIncome}
                  />
                </span>
              </h6>
            </Card>
          </Card>
        </Spin>
      </div>
    );
  }
}

class IndexProfitAndLoss extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fromDate: moment()
        .startOf('year')
        .format('YYYY-MM-DD'),
      toDate: moment()
        .endOf('year')
        .format('YYYY-MM-DD'),
    };
  }

  fromDateOnChange = (e) => {
    this.setState({ fromDate: moment(new Date(e)).format('YYYY-MM-DD') });
  };

  toDateOnChange = (e) => {
    this.setState({ toDate: moment(new Date(e)).format('YYYY-MM-DD') });
  };

  render() {
    const exportToCSV = () => {
      TableToExcel.convert(document.querySelector('table.printExcel'), {
        name: `${this.props.jmbs.jmbs[0].name}_PROFIT & LOSS STATEMENT_${this.state.fromDate} to ${this.state.toDate}.xlsx`,
        sheet: {
          name: 'Sheet 1',
        },
      });
    };

    return (
      <Layout>
        <Tabs defaultActiveKey='1'>
          <TabPane tab='Summary' key='1'>
            <Row gutter={24}>
              <Col span={3} offset={14}>
                From Date :{' '}
                <DatePicker
                  defaultValue={moment().startOf('year')}
                  onChange={this.fromDateOnChange}
                />
              </Col>
              <Col span={3}>
                To Date :{' '}
                <DatePicker
                  defaultValue={moment().endOf('year')}
                  onChange={this.toDateOnChange}
                />
              </Col>
              <Col span={2}>
                <br />
                <ReactToPrint
                  trigger={() => <Button type='primary'>PRINT</Button>}
                  content={() => this.componentRef}
                />
              </Col>
              <Col span={2}>
                <br />
                <Button onClick={(e) => exportToCSV()} type='primary'>
                  Export
                </Button>
              </Col>
            </Row>
            <br />
            <ComponentToPrint
              jmbs={this.props.jmbs.jmbs[0]}
              companyId={this.props.commons.selectedCompany}
              ref={(el) => (this.componentRef = el)}
              fromDate={this.state.fromDate}
              toDate={this.state.toDate}
            />
          </TabPane>
          <TabPane tab='Detail' key='2'>
            <PNLYearly />
          </TabPane>
        </Tabs>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    jmbs: state.jmbs,
    commons: state.commons,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      //updateMode: updateMode
    },
    dispatch
  );
}

export default requireAuth(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Form.create()(IndexProfitAndLoss))
);
