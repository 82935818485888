import React, { Component } from 'react';
import { Modal, message, Form, Input, Select ,Row,Col, Spin} from 'antd';
import client from '../../feathers';
import requireAuth  from "../../components/requireAuth";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateMode } from '../../actions/actions-receipts';
import axios from 'axios';
import moment from 'moment';


const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

class ReceiptsView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id:'',
      confirmDirty: false,
      autoCompleteResult: [],
      accountAssetsDropdowns :[],
      accountExpensesDropdowns :[],
      suppliers:[],
      loading:false
    }
  }
  componentDidUpdate(){
      if(this.props.receipts.activeId !== this.state._id){

        this.setState({
          _id:this.props.receipts.activeId
        })

        if(this.props.receipts.activeReceipts){
          this.props.form.setFieldsValue(
            this.props.receipts.activeReceipts
          )
        }

      }

  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }


  handleSubmit = (e) => {
    e.preventDefault();
    if(this.props.receipts.mode === 'view'){

      this.props.form.validateFields((err, values) => {
        if (!err) {
          client.service('receipts').patch(this.props.receipts.activeId,
            {

              file:values.file,
              date: values.date,
              creditledgerAccountId:values.creditledgerAccountId,
              debitledgerAccountId:values.debitledgerAccountId,
              receiptNo : values.receiptNo,
              subTotalAmount : values.subTotalAmount,
              taxAmount : values.taxAmount,
              totalAmount : values.totalAmount,
              remark:values.remark,
            }
          )
          .then((res)=>{
            message.success(`Receipts Updated`);
            this.props.updateMode('');
          })
          .catch((err)=>{
            console.log(err);
            this.props.updateMode('');
          })
          return;
        }
      });
    }
 }

  handleFileUpload = (event) => {

    this.setState({loading:true});

    let formData = new FormData();

    formData.append('image', event.target.files[0]);

    axios.post(`${client.io.io.uri}upload-receipts`,
    formData
    ,{
        headers: {
          'Authorization': client.settings.storage.storage['feathers-jwt'],
          'Content-Type': 'multipart/form-data',
        }
      }
    ).then((res)=>{

      this.props.form.setFieldsValue({
        file:res.data.imageUrl
      });
      this.setState({loading:false});
      message.success('Upload Successful');
    })
    .catch((err)=>{
      this.setState({loading:false});
      console.log(err);
      message.error(err);
    })
  }
//-----------------------------------------------------------
  componentWillMount(){
    //generate dropdown
    client.authenticate()
    .then((res)=>{

        return client.service('ledger-accounts').find({
          query: {
            companyId:sessionStorage.getItem('companyId'),
            categories:'ASSETS',
             $sort: {
               //createdAt: -1
               description: 1
             }
           }
        })
    })
    .then((res)=>{

      this.setState({
        accountAssetsDropdowns:res.data,
      })
    })
    .catch((err)=>{
        console.log(err);
        this.setState({
          accountAssetsDropdowns:[]
        })
    })
//-----------------------------------------------------------
    //generate dropdown category
    client.authenticate()
    .then((res)=>{

        return client.service('ledger-accounts').find({
          query: {
            companyId:sessionStorage.getItem('companyId'),
            categories:'EXPENSES',
             $sort: {
               //createdAt: -1
               description: 1
             }
           }
        })
    })
    .then((res)=>{

      this.setState({
        accountExpensesDropdowns:res.data,
      })
    })
    .catch((err)=>{
        console.log(err);
        this.setState({
          accountExpensesDropdowns:[]
        })
    })
//-----------------------------------------------------------
    //generate supplier dropdown
    client.authenticate()
    .then((res)=>{

        return client.service('suppliers').find({
          query: {
            propertyId:sessionStorage.getItem('companyId'),
             $sort: {
               createdAt: -1
             }
           }
        })
    })
    .then((res)=>{
      this.setState({
        suppliers:res.data
      });
    })
    .catch((err)=>{
      console.log(err);
    })

  }
//-----------------------------------------------------------
renderCategory = () => {

        const { getFieldDecorator } = this.props.form;

        // <FormItem
        //   {...formItemLayout}
        //   label="Category"
        // >
        //   {getFieldDecorator('debitledgerAccountId', {
        //
        //     rules: [{
        //       required: true, message: 'Please input category!',
        //     }],
        //   })(
        //       <Select placeholder="Please select category" disabled={true} >
        //       {this.state.accountExpensesDropdowns.map(dropdown => (
        //         <Option value={dropdown._id}>
        //           {dropdown.description}
        //         </Option>
        //       ))}
        //       </Select>
        //   )}
        // </FormItem>


}
//-----------------------------------------------------------
  render() {

    const { getFieldDecorator } = this.props.form;
    const imageUrl = this.props.form.getFieldValue('file');

    return(
      <Modal
         visible={
           this.props.receipts.mode==='view'?true:false
         }
         title='Supplier Invoice'
         width="900px"
         okText={ 'Save' }
         onCancel={()=>{this.props.updateMode(''); this.props.form.resetFields()} }
         onOk={this.handleSubmit}
      >
        <Spin spinning={this.state.loading}>
          <Form className="submit-form">

            <Row>
              <Col span={12}>

                <FormItem
                  {...formItemLayout}
                  label="Upload"
                >
                  <Input label='upload file' type='file' onChange={this.handleFileUpload} />
                </FormItem>

                <FormItem
                  {...formItemLayout}
                  label="File Name"
                  style={{'display':'none'}}
                >
                  {getFieldDecorator('file', {
                    rules: [{
                      required: false, message: 'Please Upload File!',
                    }],
                  })(
                    <Input style={{'display':'none'}} />
                  )}
                </FormItem>

                <FormItem
                  {...formItemLayout}
                  label="File Name"
                  style={{'display':'none'}}
                >
                  {getFieldDecorator('file', {
                    rules: [{
                      required: false, message: 'Please Upload File!',
                    }],
                  })(
                    <Input style={{'display':'none'}} disabled={true} placeholder="File Name" />
                  )}
                </FormItem>

                <FormItem
                  {...formItemLayout}
                  label="Date"
                >
                  {getFieldDecorator('date', {
                    initialValue: moment().format("YYYY-MM-DD"),
                    rules: [{
                      required: true, message: 'Please input date!',
                    }],
                  })(
                    <Input type="date" disabled={true} />
                  )}
                </FormItem>

                <FormItem
                  {...formItemLayout}
                  label="Supplier"
                >
                  {getFieldDecorator('supplierId._id', {
                    rules: [{
                      required: true, message: 'Please input Supplier!',
                    }],
                  })(
                    <Select disabled={true}>
                    {this.state.suppliers.map(dropdown => (
                      <Option key={dropdown._id} value={dropdown._id}>
                        {dropdown.name}
                      </Option>
                    ))}
                  </Select>
                  )}
                </FormItem>

                <FormItem
                  {...formItemLayout}
                  label="Receipt No."
                >
                  {getFieldDecorator('receiptNo', {
                    rules: [{
                      required: true, message: 'Please input Receipt No.!',
                    }],
                  })(
                      <Input disabled={true} />
                  )}
                </FormItem>

              </Col>
              <Col span={12}>

                <FormItem
                  {...formItemLayout}
                  label="Tax Amount"
                >
                  {getFieldDecorator('taxAmount', {
                    rules: [{
                      required: true, message: 'Please input Tax Amount!',
                    }],
                  })(
                    <Input disabled={true} />
                  )}
                </FormItem>

                <FormItem
                  {...formItemLayout}
                  label="Sub Total Amount"
                >
                  {getFieldDecorator('subTotalAmount', {
                    rules: [{
                      required: true, message: 'Please input SubTotal!',
                    }],
                  })(
                    <Input disabled={true} />
                  )}
                </FormItem>

                <FormItem
                  {...formItemLayout}
                  label="Total Amount"
                >
                  {getFieldDecorator('totalAmount', {
                    rules: [{
                      required: true, message: 'Please input Amount!',
                    }],
                  })(
                    <Input disabled={true} />
                  )}
                </FormItem>


{
  this.renderCategory()
}
                <FormItem
                  {...formItemLayout}
                  label="Category"
                >
                  {getFieldDecorator('debitledgerAccountId', {
                    rules: [{
                      required: true, message: 'Please input category!',
                    }],
                  })(
                      <Select placeholder="Please select category" disabled={true} >
                      {this.state.accountExpensesDropdowns.map(dropdown => (
                        <Option key={dropdown._id} value={dropdown._id}>
                          {dropdown.description}
                        </Option>
                      ))}
                      </Select>
                  )}
                </FormItem>

                {/*
                   <FormItem
                  {...formItemLayout}
                  label="Payment Account"
                >
                  {getFieldDecorator('creditledgerAccountId', {
                    rules: [{
                      required: true, message: 'Please input Account!',
                    }],
                  })(
                    <Select placeholder="Please select Account" disabled={true} >
                      {this.state.accountAssetsDropdowns.map(dropdown => (
                        <Option value={dropdown._id}>
                          {dropdown.description}
                        </Option>
                      ))}
                    </Select>
                  )}
                </FormItem>
                  */}
                <FormItem
                  {...formItemLayout}
                  label="Remark"
                >
                  {getFieldDecorator('remark', {
                    rules: [{
                      required: false, message: 'Please input remark!',
                    }],
                  })(
                //    <TextArea rows={4} disabled={true} />
                      <TextArea rows={4} disabled={false} />
                  )}
                </FormItem>

              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>

    )
  }
}


function mapStateToProps(state) {
  return {
    receipts: state.receipts
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
    }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(ReceiptsView)));
