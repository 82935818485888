import React, { Component } from 'react';
import { Row, Col, Card, Popconfirm, message, Divider,  Table, Form, Icon, Input, Button, Spin } from 'antd';
import client from '../../feathers';
import requireAuth  from "../../components/requireAuth";
import Layout  from "../../components/layout";
import Info  from "../../components/common/info";
import PropertyUnitForm  from "../../components/propertyunits/form";
import PropertyUnits  from "../../components/propertyunits/index";
//import Test  from "../../components/property-units/test"
import { updateMode } from '../../actions/actions-propertyunits';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchProperties,
  pushProperties,
  removeProperties,
  updateActiveProperty,
  updateProperties } from '../../actions/properties-actions';

const FormItem = Form.Item;

class PropertyView extends Component {
  constructor(props) {
      super(props);

      this.state = {
        property  : [],
        loading   :false
      }

      this.handlePropertyUnitAdd = this.handlePropertyUnitAdd.bind(this);
  }


  componentWillMount(){

      client.authenticate()
      .then((res)=>{
          return client.service('properties').get(this.props.match.params.id)
      })
      .then((res)=>{

        this.setState({
          property:res
        });
      })
      .catch((err)=>{
        alert(err);
      })
  }

  componentDidMount(){
      client.service('properties').on('updated', (properties) => {
        this.props.updateProperties(properties)
      })
  }

  handlePropertyUnitAdd(){
    this.props.updateMode('new')
  }


  render() {
    return(
      <Spin spinning={this.state.loading}>
        <Layout>
          {this.state.property?<PropertyUnitForm propertyId={this.props.match.params.id} propertyType={this.state.property.type} />:''}

          <Row gutter={8}>
            <Col span={8}>
              <Card title="Property Info">
                <Info label="Name" labelSpan={6}  value={this.state.property.name} valueSpan={18} />
                <Info label="Type" labelSpan={6}  value={this.state.property.type} valueSpan={18} />
                <Info label="Code" labelSpan={6}  value={this.state.property.code} valueSpan={18} />
                <Info label="Address Line 1" labelSpan={6}  value={this.state.property.addressLineA} valueSpan={18} />
                <Info label="Address Line 2" labelSpan={6}  value={this.state.property.addressLineB} valueSpan={18} />
                <Info label="Address Line 3" labelSpan={6}  value={this.state.property.addressLineC} valueSpan={18} />
                <Info label="Post Code" labelSpan={6}  value={this.state.property.postCode} valueSpan={18} />
                <Info label="Country" labelSpan={6}  value={this.state.property.country} valueSpan={18} />
              </Card>
            </Col>

            <Col span={16}>
              <Row gutter={8}>
                <Card title="Property Unit"
                  extra={<a onClick={()=>this.handlePropertyUnitAdd()} ><Icon type="plus-circle" /> </a>}
                >
                <PropertyUnits propertyId={this.props.match.params.id} />
                </Card>
              </Row>
            </Col>
          </Row>
        </Layout>
      </Spin>
    )
  }
}


function mapStateToProps(state) {
  return {
    user: state.user,
    properties: state.properties
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchProperties: fetchProperties,
    pushProperties: pushProperties,
    removeProperties: removeProperties,
    updateActiveProperty: updateActiveProperty,
    updateProperties: updateProperties,

    updateMode:updateMode
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyView);
