import React, { Component, useEffect } from 'react';
import { Spin, Modal, Upload, Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Select, TimePicker } from 'antd';
import client from '../../feathers';
import requireAuth from "../../components/requireAuth";
import Layout from "../../components/layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateMode } from '../../actions/actions-files';
import moment from 'moment';
import axios from 'axios';
var FormData = require('form-data');

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
const format = 'HH:mm';

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};

const text = 'Are you sure to delete this item?';

class FilesForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',
      confirmDirty: false,
      autoCompleteResult: [],
      showUploadDone: false,
      file: {},
      submitting: false
    }
  }

  componentDidUpdate() {

    if (this.props.files.activeId !== this.state._id) {
      this.setState({
        _id: this.props.files.activeId
      })

      if (this.props.files.activeFiles) {
        this.props.form.setFieldsValue(
          this.props.files.activeFiles
        )
      }
    }
  }
  componentWillMount() {
    this.props.updateMode('')
  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }

  handleSubmit = (e) => {
    this.setState({ submitting: true })
    e.preventDefault();
    if (this.props.files.mode === 'new') {

      this.props.form.validateFields((err, values) => {
        if (!err) {
          client.service('files').create({
            ...values,
            propertyId: this.props.user.info.propertyId._id,
          })
            .then((res) => {
              //
              this.props.updateMode('refresh');
              message.success('New File Added');
              this.setState({ submitting: false })
            })
            .catch((err) => {
              console.log(err);
              this.props.updateMode('')
              alert(err);
              this.setState({ submitting: false })
            })
        }
      });
    } else if (this.props.files.mode === 'edit') {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          client.service('files').patch(this.props.files.activeId,
            values
          )
            .then((res) => {
              //
              message.success(`Files Updated`);
              this.props.updateMode('')
              this.setState({ submitting: false })
            })
            .catch((err) => {

              this.props.updateMode('')
              this.setState({ submitting: false })
            })
          return;
        }
      });
    } else if (this.props.files.mode === 'upload') {
      this._handleConfirmUpload()
    }
  }

  _handleConfirmUpload() {
    let formData = new FormData();

    formData.append('image', this.state.file);

    axios.post(`${client.io.io.uri}upload-files`,
      formData
      , {
        headers: {
          'Authorization': client.settings.storage.storage['feathers-jwt'],
          'Content-Type': 'multipart/form-data',
        }
      }
    ).then((res) => {
      this.props.form.setFieldsValue({
        file: res.data.imageUrl
      })

      return client.service('files').patch(this.props.files.activeId, { file: res.data.imageUrl })
    })
      .then((res) => {
        message.success('Upload Successful');
        this.props.updateMode('')
        this.setState({ submitting: false })
      })
      .catch((err) => {
        this.setState({
          showUploadDone: false,
          submitting: false
        })
        console.log(err);
        message.error(err);
      })

  }

  handleFileUpload = (event) => {
    this.setState({
      showUploadDone: true,
      file: event.target.files[0]
    })

    this.props.form.setFieldsValue({
      file: URL.createObjectURL(event.target.files[0] ? event.target.files[0] : '')
    })
  }

  _renderUploadFormItem() {
    const { getFieldDecorator } = this.props.form;
    const imageUrl = this.props.form.getFieldValue('file');

    if (this.props.files.mode !== 'edit' && this.props.files.mode !== '')
      return (
        <div>
          <FormItem
            {...formItemLayout}
            label="Upload"
          >
            <Input label='upload file' type='file' onChange={this.handleFileUpload} />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="File Name"
            style={{ 'display': 'none' }}
          >
            {getFieldDecorator('file', {
              rules: [{
                required: true, message: 'Please Upload File!',
              }],
            })(
              <Input disabled placeholder="File Name" />
            )}
          </FormItem>


          {imageUrl ? <img src={imageUrl} style={{ 'width': '200px', 'marginLeft': '35%', 'marginBottom': '5%' }} alt="image" /> : ''}
        </div>
      )
  }

  _renderEditFormItem() {
    const { getFieldDecorator } = this.props.form;
    if (this.props.files.mode !== 'upload')
      return (
        <div>
          <FormItem
            {...formItemLayout}
            label="Remark"
          >
            {getFieldDecorator('remark', {
              rules: [{
                required: false, message: 'Please Input Remark!',
              }],
            })(
              <TextArea rows={4} />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Select Type"
            hasFeedback
          >
            {getFieldDecorator('type', {
              rules: [
                { required: true, message: 'Please select type!' },
              ],
            })(
              <Select placeholder="Please select a type">
                <Option value="FLOORPLAN">FLOORPLAN</Option>
                <Option value="OTHERS">OTHERS</Option>
              </Select>
            )}
          </FormItem>
        </div>
      )
  }

  _submittingFooter() {
    if (this.state.submitting) {
      return (
        <Spin>
          {this._renderFooter()}
        </Spin>
      )
    } else {
      return (

        this._renderFooter()

      )
    }
  }
  _renderFooter() {
    if (this.state.showUploadDone || this.props.files.mode === 'edit') {
      return (
        [<Button key="back" onClick={() => { this.props.updateMode(''); this.props.form.resetFields() }}>Cancel</Button>,
        <Button key="submit" type="primary" onClick={this.handleSubmit}>
          {this.props.files.mode === 'edit' ? 'Update' : (this.props.files.mode === 'upload' ? 'Done' : 'Create')}
        </Button>]
      )
    } else {
      return (
        [<Button key="back" onClick={() => { this.props.updateMode(''); this.props.form.resetFields() }}>Cancel</Button>]
      )
    }
  }

  _renderForm() {

  }

  render() {
    const { visible, onCancel } = this.props;

    const { getFieldDecorator } = this.props.form;

    return (
      <Modal
        visible={
          ['edit', 'new', 'upload'].includes(this.props.files.mode) ? true : false
        }
        title={this.props.files.mode === 'edit' ? 'Update Files' : (this.props.files.mode === 'upload' ? 'Upload' : 'Create Files')}
        // okText={ this.props.files.mode === 'edit' ? 'Update':(this.props.files.mode === 'upload'?'Done':'Create') }
        onCancel={() => { this.props.updateMode(''); this.props.form.resetFields() }}
        // onOk={this.handleSubmit}
        footer={this._submittingFooter()}

      >
        <Form className="submit-form">

          {this._renderUploadFormItem()}
          {this._renderEditFormItem()}

        </Form>
      </Modal>

    )
  }
}


function mapStateToProps(state) {
  return {
    files: state.files
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(FilesForm)));
