import React, { Component, useEffect } from 'react';
import { Modal, Upload, Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Select, Spin } from 'antd';
import client from '../../feathers';
import requireAuth from "../requireAuth";
import Layout from "../layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateMode } from '../../actions/actions-propertyunits';
import axios from 'axios';

var FormData = require('form-data');
const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};

const text = 'Are you sure to delete this item?';
const children = [];

class PropertyunitsFormMng extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',
      confirmDirty: false,
      autoCompleteResult: [],
      loading: false
    }
  }

  componentDidUpdate() {
    if (this.props.propertyunits.activeId !== this.state._id) {
      this.setState({
        _id: this.props.propertyunits.activeId
      })

      // if(this.props.propertyunits.activePropertyunits){
      //   this.props.form.setFieldsValue(
      //     this.props.propertyunits.activePropertyunits
      //   )
      // }

      if (this.props.propertyunits.activePropertyunits) {
        this.props.form.setFieldsValue({
          size: this.props.propertyunits.activePropertyunits.size,
          extraSize: this.props.propertyunits.activePropertyunits.extraSize ? this.props.propertyunits.activePropertyunits.extraSize : 0,
          referenceId: this.props.propertyunits.activePropertyunits.referenceId,

        })
      }

    }



  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }

  componentWillMount() {
  }


  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        client.service('propertyunits').patch(this.props.propertyunits.activeId,
          {
            ...values
          }
        )
          .then((res) => {
            message.success(`Updated`);
            this.props.updateMode('refresh');
            this.setState({ loading: false });
          })
          .catch((err) => {

            this.props.updateMode('');
          })
        return;
      }

    });

  }

  renderFields(type) {

    const { getFieldDecorator } = this.props.form;

    if (type !== 'LANDED') {
      return ([
        <FormItem key="referenceId"
          {...formItemLayout}
          label="Unit Number"
        >
          {getFieldDecorator('referenceId', {
            rules: [{
              required: true, message: 'Please input !',
            }],
          })(
            <Input disabled={true} />
          )}
        </FormItem>
        ,
        <FormItem key="size"
          {...formItemLayout}
          label="Shared Unit"
        >
          {getFieldDecorator('size', {
            rules: [{
              required: true, message: 'Please input Shared Unit!',
            }],
          })(
            <Input />
          )}
        </FormItem>
        ,
        <FormItem key="extraSize"
          {...formItemLayout}
          label="Extra Size"
        >
          {getFieldDecorator('extraSize', {
            rules: [{
              required: true, message: 'Please input Extra Size!',
            }],
          })(
            <Input />
          )}
        </FormItem>
      ])
    } else {
      return ([
        <FormItem
          {...formItemLayout}
          label="Unit Number"
        >
          {getFieldDecorator('referenceId', {
            rules: [{
              required: true, message: 'Please input !',
            }],
          })(
            <Input disabled={true} />
          )}
        </FormItem>
        ,
        <FormItem
          {...formItemLayout}
          label="Size"
        >
          {getFieldDecorator('size', {
            rules: [{
              required: true, message: 'Please input Size!',
            }],
          })(
            <Input />
          )}
        </FormItem>
      ])
    }
  }


  render() {
    const { visible, onCancel } = this.props;
    const { getFieldDecorator } = this.props.form;


    return (
      <Spin spinning={this.state.loading}>
        <Modal
          visible={
            this.props.propertyunits.mode === 'new' || this.props.propertyunits.mode === 'edit' ? true : false
          }
          title={this.props.propertyunits.mode === 'edit' ? 'Update' : 'Create Unit'}
          okText={this.props.propertyunits.mode === 'edit' ? 'Update' : 'Create'}
          onCancel={() => { this.props.updateMode(''); this.props.form.resetFields() }}
          onOk={this.handleSubmit}
        >
          <Form className="submit-form">

            {this.renderFields(this.props.propertyType)}

          </Form>
        </Modal>
      </Spin>

    )
  }
}


function mapStateToProps(state) {
  return {
    propertyunits: state.propertyunits,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(PropertyunitsFormMng)));
