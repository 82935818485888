import _ from 'lodash';
import {
  FETCH_CHARGES,
  PUSH_CHARGES,
  REMOVE_CHARGES,
  UPDATE_CHARGES,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID } from '../actions/actions-charges';

const INITIAL_STATE = {
  charges: [],
  mode: 'index',
  activeId: '',
  activeCharges:{}
}


export default function(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_CHARGES:
      return {
        ...state,
        charges:action.payload,
      }
    case PUSH_CHARGES:
      return {
        ...state,
        charges:[action.payload, ...state.charges],
      }
    case REMOVE_CHARGES:
      return {
        ...state,
        charges : [...state.charges.filter( (item) => item._id !== action.payload._id)]
      }
    case UPDATE_CHARGES:
      let index =  _.findIndex(state.charges, { '_id': action.payload._id })
      state.charges.splice(index, 1, action.payload)
      return {
        ...state,
        charges : state.charges
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode : action.payload,
        activeId:''
      }
    case UPDATE_ACTIVE_ID:
      return {
        ...state,
        mode : action.mode,
        activeId:action.id,
        activeCharges: _.pick(_.find(state.charges, { '_id': action.id }), ['accountNo','code', 'description', 'amount', 'status','ledgerAccountId','ledgerAccountType','lateCharge','chargeType','percentage','applyOnMonth', '_id'])
      }
    default:
      return state

  }
}
