import React, { Component } from "react";
import {
  Modal,
  Avatar,
  Row,
  Col,
  Card,
  Popconfirm,
  message,
  Divider,
  Table,
  Form,
  Icon,
  Input,
  Button,
  Select,
  Radio,
  Switch,
  Spin,
} from "antd";
import client from "../../feathers";
import requireAuth from "../requireAuth";
import Layout from "../layout";
import Info from "../common/info";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactToPrint from "react-to-print";
import { fetchJournalEntries } from "../../actions/actions-journalentries";

const toWords = require("to-words");
const { TextArea } = Input;

const formatCurr = function format1(n = 0, currency = null) {
  let oriAmt = n;
  n = n < 0 ? Math.abs(n) : n;
  n = n ? n : 0;
  let amt = currency
    ? currency
    : " " +
      n.toFixed(2).replace(/./g, function(c, i, a) {
        return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
      });

  return oriAmt < 0 ? "(" + amt + ")" : amt;
};
const thisStyle = {
  padding: "0px",
};

class CashBankPrint extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      visible: false,
      setting: [],
      jmb: [],
      loading: false,
    };
  }

  componentWillMount() {
    document.title = `${this.props.companyId.name}_Cash / Bank_${this.props.props.banktrxs.activeBankTrxs.referenceId}`;

    this.setState({ loading: true });

    client
      .authenticate()
      .then((res) => {
        return client.service("ledger-transactions").find({
          query: {
            $populate: "ledgerAccountId",
            banktrxsId: this.props.props.match.params.referenceId,
            $sort: { ledgerAccountId: 1 },
          },
        });
      })
      .then((res) => {
        this.setState({
          dataSource: res.data,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    this.getJmbInfo();
    this.getTnc();
  }

  getJmbInfo() {
    client
      .authenticate()
      .then((res) => {
        return client.service("jmbs").find({
          query: {
            propertyId: this.props.companyId._id,
            status: "ACTIVE",
            $limit: 1,
            $sort: {
              createdAt: -1,
            },
          },
        });
      })
      .then((res) => {
        this.setState({
          jmb: res.data[0],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getTnc() {
    client
      .authenticate()
      .then(() => {
        return client.service("settings").find({
          query: {
            propertyId: this.props.companyId._id,
          },
        });
      })
      .then((res) => {
        //
        this.setState({
          setting: res.data[0].tnc,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidMount() {}

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  _renderTable = () => {
    const { visible, onCancel } = this.props;
    const { loading, selectedRowKeys, jmb, dataSource } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    let table = [];
    const activeBankTrxs = this.props.props.banktrxs.activeBankTrxs;

    const columns = [
      {
        title: "Receipt No",
        dataIndex: "ledgerAccountId.fullAccountNo",
        key: "ledgerAccountId.fullAccountNo",
        width: "20%",
        render: (text, record) => (
          <span style={{ fontSize: 11, padding: "0px" }}>{text}</span>
        ),
      },
      {
        title: "Receipt No",
        dataIndex: "ledgerAccountId.accountName",
        key: "ledgerAccountId.accountName",
        width: "20%",
        render: (text, record) => (
          <span style={{ fontSize: 11, padding: "0px" }}>{text}</span>
        ),
      },
      {
        title: <span style={{ float: "right" }}>Debit Amount</span>,
        align: "right",
        dataIndex: "debitAmount",
        key: "debitAmount",
        width: "20%",
        render: (text, record) => (
          <span style={{ fontSize: 11, padding: "0px", float: "right" }}>
            {formatCurr(record.debitAmount)}
          </span>
        ),
      },

      {
        title: <span style={{ float: "right" }}>Credit Amount</span>,
        dataIndex: "creditAmount",
        align: "right",
        key: "creditAmount",
        width: "20%",
        render: (text, record) => (
          <span style={{ fontSize: 11, padding: "0px", float: "right" }}>
            {formatCurr(record.creditAmount)}
          </span>
        ),
      },
    ];

    table.push([
      <div key="main">
        <Card>
          <Row>
            <Col span={3}>
              <img width="100%" src={jmb ? jmb.headerFile : ""} />
            </Col>
            <Col span={18} offset={1}>
              <p style={{ fontWeight: "bolder", color: "#000" }}>
                {jmb.managementName} ({jmb ? jmb.registrationNo : ""})
              </p>
              <p style={{ marginTop: -15 }}>
                {jmb ? jmb.headerLine1 : ""} ,{jmb ? jmb.headerLine2 : ""}{" "}
              </p>
              <p style={{ marginTop: -15 }}>
                {jmb ? jmb.headerLine3 : ""}
                {jmb ? jmb.headerLine4 : ""}
              </p>
              <p>
                <span>
                  {jmb ? "Tel: " + jmb.contactNo : ""} |{" "}
                  {jmb ? "Email: " + jmb.email : ""}
                </span>
              </p>
              <br />
            </Col>

            <Col span={6}></Col>
          </Row>

          <Row gutter={8}>
            <Col span={24} align="middle">
              <h3 style={{ color: "rgb(169,169,169)", letterSpacing: "10px" }}>
                Cash/Bank
              </h3>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={18}>
              <br />
              <Row>
                <span>Description : {activeBankTrxs.description} </span>
                <br />
                <br />
              </Row>
            </Col>
            <Col span={6}>
              <Row>
                <Col span={10}>
                  <span>Date </span>
                </Col>
                <Col span={14}>
                  <span>
                    : {moment(activeBankTrxs.trxDate).format("YYYY-MM-DD")}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col span={10}>
                  <span>References </span>
                </Col>
                <Col span={14}>
                  <span>: {activeBankTrxs.referenceId}</span>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* <Row><span>Description : {this.props.props.journalentries.activeJournalEntries.description}  </span><br /><br /></Row> */}

          <Divider dashed>Detail</Divider>

          <Table
            pagination={false}
            dataSource={dataSource}
            columns={columns}
            rowKey="_id"
            footer={() => (
              <Row>
                <Col style={{ float: "right" }}>
                  <span style={{ textAlign: "center" }}>
                    {" "}
                    Total:
                    {formatCurr(activeBankTrxs.trxAmount)}
                  </span>
                </Col>
              </Row>
            )}
          />

          <div
            key="moneysum"
            style={{ fontSize: 15, fontWeight: "bold", marginTop: "50px" }}
          >
            <Row>
              <Col align="left">
                <span>
                  {"The Sum of Amount : **" +
                    toWords(activeBankTrxs.trxAmount) +
                    " Ringgit Only**"}
                </span>
              </Col>
            </Row>
            <br />
          </div>

          <Row className="noBorder">
            <div key="footerpv" className="paymentvoucherFooter">
              <div key="displaycontain" className="ql-display-container">
                <Row gutter={8}>
                  <Col span={8}>
                    <Card
                      headStyle={{ padding: "0 6", fontSize: "12px" }}
                      style={{ minHeight: "145px", width: "250px" }}
                      bordered={true}
                      title={<span style={{ padding: "0 6" }}>Title</span>}
                    >
                      <p style={{ lineHeight: 0.2, fontSize: "10px" }}>
                        {this.state.preparebyName}
                      </p>
                    </Card>
                  </Col>
                  <Col span={8}>
                    <Card
                      headStyle={{ padding: "0 6", fontSize: "12px" }}
                      style={{ minHeight: "145px", width: "250px" }}
                      bordered={true}
                      title="VERIFIED BY"
                    >
                      <div
                        key="verifiedby"
                        style={{ lineHeight: 0.2, fontSize: "10px" }}
                      >
                        <p>Sign: </p>
                        <p>Name: </p>
                      </div>
                    </Card>
                  </Col>
                  <Col span={8}>
                    <Card
                      headStyle={{ padding: "0 6", fontSize: "12px" }}
                      style={{ minHeight: "145px", width: "250px" }}
                      bordered={true}
                      title="RECEIVED BY"
                    >
                      <div
                        key="receiveby"
                        style={{ lineHeight: 0.2, fontSize: "10px" }}
                      >
                        <p>Sign: </p>
                        <p>Name: </p>
                        <p>IC No: </p>
                        <p>Date: </p>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </Row>

          {/* <Row className="noBorder">

            <div key='footerpv' className="">
              <div key='displaycontain' className="">
                <Row gutter={8} >
                  <Col span={8}>

                  </Col>
                  <Col span={8} >

                  </Col>
                  <Col span={8} >
                    <br />
                    <br />
                    <br />
                    <br />
                    <span>Approved By: _______________________</span>
                  </Col>
                </Row>
              </div>
              <br />
              <div key='footer' align="right" >© 2021 Mr Tech System Holdings Sdn Bhd (1370083-M)</div>
            </div>
          </Row> */}
        </Card>
      </div>,
      <div key="pagebreak" className="pageBreak" />,
    ]);

    return table;
  };

  render() {
    const loading = this.state.loading;
    return (
      <div>
        <Spin key="spinning" spinning={loading}>
          {this._renderTable()}
        </Spin>
      </div>
    );
  }
}

class PrintCashBank extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Layout>
        <Row>
          <Col style={{ marginBottom: 10, float: "right" }}>
            <ReactToPrint
              trigger={() => <Button type="primary">PRINT</Button>}
              content={() => this.componentRef}
            />
          </Col>
        </Row>
        <CashBankPrint
          ref={(el) => (this.componentRef = el)}
          props={this.props}
          companyId={this.props.commons.selectedCompany}
        />
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    banktrxs: state.banktrxs,
    journalentries: state.journalentries,
    commons: state.commons,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchJournalEntries: fetchJournalEntries,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(PrintCashBank));
