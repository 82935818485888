import _ from 'lodash';
import {
  FETCH_REQUESTS,
  PUSH_REQUESTS,
  REMOVE_REQUESTS,
  UPDATE_REQUESTS,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID
} from '../actions/actions-requests';

const INITIAL_STATE = {
  requests: [],
  mode: 'index',
  activeId: '',
  activeRequests: {}
}


export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_REQUESTS:
      return {
        ...state,
        requests: action.payload,
      }
    case PUSH_REQUESTS:
      return {
        ...state,
        requests: [action.payload, ...state.requests],
      }
    case REMOVE_REQUESTS:
      return {
        ...state,
        requests: [...state.requests.filter((item) => item._id !== action.payload._id)]
      }
    case UPDATE_REQUESTS:
      let index = _.findIndex(state.requests, { '_id': action.payload._id })
      state.requests.splice(index, 1, action.payload)
      return {
        ...state,
        requests: state.requests
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode: action.payload,
        activeId: ''
      }
    case UPDATE_ACTIVE_ID:
      return {
        ...state,
        mode: action.mode,
        activeId: action.id,
        activeRequests: _.pick(_.find(state.requests, { '_id': action.id }), ['file',
          'status',
          'remark',
          'beneficialName',
          'propertyUnitName',
          'propertyName',
          'contactNo',
          'userId',
          'propertyunitId',
          'propertyId',
          '_id'])
      }
    default:
      return state

  }
}
