import React, { Component } from 'react';
import { Modal, message, Form, Input, Select } from 'antd';
import client from '../../feathers';
import requireAuth from "../../components/requireAuth";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  updateMode,
  updateActiveSuppliers,
} from '../../actions/actions-suppliers';

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};


class SuppliersForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',
      confirmDirty: false,
      autoCompleteResult: [],
      malaysiaState: [],
    }
  }
  componentDidUpdate() {
    if (this.props.suppliers.activeId !== this.state._id) {
      this.setState({
        _id: this.props.suppliers.activeId
      })

      if (this.props.suppliers.activeSuppliers) {
        this.props.form.setFieldsValue(
          this.props.suppliers.activeSuppliers
        )
      }
    }
  }

  componentWillMount() {
    this.getStateParams();
  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }


  handleSubmit = (e) => {
    e.preventDefault();
    if (this.props.suppliers.mode === 'new') {

      this.props.form.validateFields((err, values) => {
        values.accountName = values.name

        if (!err) {
          client.service('suppliers').create({
            ...values,
            country: 'MALAYSIA',
            propertyId: this.props.commons.selectedCompany._id,
          })
            .then((res) => {

              this.props.updateActiveSuppliers('', '');
              message.success('New Suppliers Added');
            })
            .catch((err) => {
              console.log(err);
              this.props.updateActiveSuppliers('', '');
              alert(err);
            })
        }
      });
    } else if (this.props.suppliers.mode === 'edit') {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          client.service('suppliers').patch(this.props.suppliers.activeId,
            values
          )
            .then((res) => {

              message.success(`Suppliers Updated`);
              this.props.updateActiveSuppliers('', '')
            })
            .catch((err) => {
              console.log(err);

              this.props.updateActiveSuppliers('', '')
            })
          return;
        }

      });
    }
  }

  getStateParams() {
    client.authenticate()
      .then(() => {
        return client.service('parameters').find({
          query: {
            table: 'MALAYSIA-STATE',
            $sort: {
              VALUE: 1
            }
          }
        })
      })
      .then((res) => {

        this.setState({
          malaysiaState: res.data[0].items
        })
      })
      .catch((err) => {
        console.log(err);
      });
  }

  renderFormItem() {

    const { getFieldDecorator } = this.props.form;

    if (this.props.suppliers.mode === 'new') {
      return ([

        // <Form.Item key='headerText'>
        //   <span className="ant-form-text">GL Account</span>
        // </Form.Item>,
        // <FormItem key='accountName'
        //   {...formItemLayout}
        //   label="Account Name"
        // >
        //   {getFieldDecorator('accountName', {
        //     rules: [{
        //       required: true, message: 'Please input Account Name!',
        //     }],
        //   })(
        //     <Input placeholder="" />
        //   )}
        // </FormItem>,
        <FormItem key='subAccountNo'
          {...formItemLayout}
          label="Account No"
        >
          {getFieldDecorator('subAccountNo', {
            rules: [{
              required: true, message: 'Please input Sub Account No!',
            }],
          })(
            <Input placeholder="000" />
          )}
        </FormItem>,
        <FormItem key='description'
          {...formItemLayout}
          label="Account description"
        >
          {getFieldDecorator('description', {
            rules: [{
              required: true, message: 'Please input Account description!',
            }],
          })(
            <Input placeholder="" />
          )}
        </FormItem>
      ]);
    }
  }

  render() {

    const { getFieldDecorator } = this.props.form;


    return (
      <Modal
        visible={
          this.props.suppliers.mode === 'new' || this.props.suppliers.mode === 'edit' ? true : false
        }
        title={this.props.suppliers.mode === 'edit' ? 'Update Suppliers' : 'Create Suppliers'}
        okText={this.props.suppliers.mode === 'edit' ? 'Update' : 'Create'}
        onCancel={() => { this.props.updateActiveSuppliers('', ''); this.props.form.resetFields() }}
        onOk={this.handleSubmit}

      >
        <Form className="submit-form">

          <FormItem key='name'
            {...formItemLayout}
            label="Supplier Name"
          >
            {getFieldDecorator('name', {
              rules: [{
                required: true, message: 'Please input Suppliers Name!',
              }],
            })(
              <Input placeholder="" />
            )}
          </FormItem>
          {
            this.renderFormItem()
          }

          <FormItem key='email'
            {...formItemLayout}
            label="Email"
          >
            {getFieldDecorator('email', {
              rules: [{
                type: 'email', required: false, message: 'Please input Email!',
              }],
            })(
              <Input placeholder="a@a.my" />
            )}
          </FormItem>


          <FormItem key='name'
            {...formItemLayout}
            label="Registration No."
          >
            {getFieldDecorator('ssmNo', {
              rules: [{
                required: false, message: 'Please input Registration Number!',
              }],
            })(
              <Input placeholder="" />
            )}
          </FormItem>

          <FormItem key='contactNo'
            {...formItemLayout}
            label="Contact No."
          >
            {getFieldDecorator('contactNo', {
              rules: [{
                required: false, message: 'Please input Email!',
              }],
            })(
              <Input placeholder="012-3456789" />
            )}
          </FormItem>

          <FormItem key='addressA'
            {...formItemLayout}
            label="Address line 1"
          >
            {getFieldDecorator('addressA', {
              rules: [{
                required: false, message: 'Please input Address!',
              }],
            })(
              <TextArea rows={4} />
            )}
          </FormItem>

          <FormItem key='addressB'
            {...formItemLayout}
            label="Address line 2"
          >
            {getFieldDecorator('addressB', {
              rules: [{
                required: false, message: 'Please input Address!',
              }],
            })(
              <TextArea rows={4} />
            )}
          </FormItem>

          <FormItem key='state' {...formItemLayout} label="State">
            {getFieldDecorator('state', {
              rules: [{ required: false, message: 'Please input State!' }],
            })(
              <Select placeholder="Select state">
                {this.state.malaysiaState.map(dropdown => (
                  <Option key={dropdown.value} value={dropdown.value}>
                    {dropdown.description}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>

          <FormItem key='postCode'
            {...formItemLayout}
            label="Post Code"
          >
            {getFieldDecorator('postCode', {
              rules: [{
                required: false, message: 'Please input post code!',
              }],
            })(
              <Input placeholder="52100" />
            )}
          </FormItem>

          <FormItem key='remark'
            {...formItemLayout}
            label="Remark"
          >
            {getFieldDecorator('remark', {
              rules: [{
                required: false, message: 'Please input Remark!',
              }],
            })(
              <TextArea rows={4} />
            )}
          </FormItem>


        </Form>
      </Modal>

    )
  }
}


function mapStateToProps(state) {
  return {
    suppliers: state.suppliers,
    commons: state.commons
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode,
    updateActiveSuppliers: updateActiveSuppliers
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(SuppliersForm)));
