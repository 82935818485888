import React, { Component } from 'react';
import { Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Card, Spin, Tooltip, Popover, Row, Col, Select } from 'antd';
import { Route, Link } from 'react-router-dom';

import client from '../../feathers';
import requireAuth from "../../components/requireAuth";
import Layout from "../../components/layout";
import WaterBillsForm from "../../components/waterbills/form";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchWaterBills,
  pushWaterBills,
  removeWaterBills,
  updateMode,
  updateActiveWaterBills,
  updateWaterBills
} from '../../actions/actions-waterbills';
import moment from "moment";


const FormItem = Form.Item;
const Option = Select.Option;
const text = 'Are you sure to delete this item?';
const _ = require('lodash');
class WaterBillsIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      loading: false,
      waterbills: [],
      loading: false,
      propertyunitsDropdowns: [],
      searchPropertyUnit: '',
      queryString: '',
    }

    this.handleDelete = this.handleDelete.bind(this);
    this._searchUnitNumberChange = this._searchUnitNumberChange.bind(this);
    this.handleSearchAccountonChange = this.handleSearchAccountonChange.bind(this);

  }

  componentDidUpdate() {
    if (this.props.waterbills.mode === 'refresh') {
      this.componentWillMount()
    }


  }

  componentDidMount() { }

  componentWillMount() {
    this.props.updateMode('');
    this.getPropertyUnitList();
    this.setState({ loading: true });

    let companyId = this.props.commons.selectedCompany._id

    client.authenticate()
      .then(() => {
        return client.service('waterbills').find({
          query: {
            companyId: companyId,
            $populate: 'propertyunitId',
            $sort: {
              readDate: -1,
              propertyunitId: 1,
            }
          }
        })
      })
      .then((res) => {
        this.setState({ loading: false });
        this.props.fetchWaterBills(res.data)
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      })
  }

  _getData(skip) {

    let queryString = ''

    if (this.state.searchPropertyUnit !== '') {
      queryString = { propertyunitId: this.state.searchPropertyUnit }
    }
    let companyId = this.props.commons.selectedCompany._id

    client.authenticate()
      .then((res) => {
        return client.service('waterbills').find({
          query: {
            companyId: companyId,
            ...queryString,
            $populate: 'propertyunitId',
            $sort: {
              readDate: -1,
            }
          }
        })
      })
      .then((res) => {
        this.setState({ loading: false });
        this.props.fetchWaterBills(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleDelete(_id) {
    client.service('waterbills').remove(_id)
      .then((res) => {
        this.props.updateMode('refresh')
        this.setState({
          mode: 'refresh',
        });
      })
      .catch((err) => {
        alert(err);
      })
  }

  handleEdit(_id) {
    this.props.updateActiveWaterBills('edit', _id)
    this.setState({ mode: 'edit' });

  }

  handleManage(_id) {
    this.props.updateActiveWaterBills('', _id)
    this.setState({
      mode: '',
    });
  }

  showModal = () => {
    this.props.updateActiveWaterBills('',)
    this.props.updateMode('new')
    this.setState({
      mode: 'new',
    });
  }

  renderConfirmDeleteBtn(_id) {
    return (
      <Popconfirm placement="topLeft" title={text} onConfirm={() => this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
        <Button type="danger">Delete</Button>
      </Popconfirm>
    )
  }

  _searchUnitNumberChange(e) {
    this.setState({
      searchUnitNumber: e.target.value,
      searchObj: _.merge(this.state.searchObj, e.target.value ? { referenceId: e.target.value } : { referenceId: '.' }),
      typingTimeout: setTimeout(() => {
        this.componentWillMount()
      }, 500)
    })
  }

  getPropertyUnitList() {
    let companyId = this.props.commons.selectedCompany._id

    client.authenticate()
      .then((res) => {

        return client.service('propertyunits').find({
          query: {
            propertyId: companyId,
            $sort: {
              referenceId: 1,
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {

        this.setState({
          propertyunitsDropdowns: res.data,
        })
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          propertyunitsDropdowns: []
        })
      })
  }

  handleSearchAccount = (value) => {

    this.setState({ fetching: true });
    let companyId = this.props.commons.selectedCompany._id


    this.setState({
      typingTimeout: setTimeout(() => {

        client.authenticate()
          .then((res) => {
            return client.service('propertyunits').find({
              query: {
                propertyId: companyId,
                $sort: {
                  referenceId: 1
                },
                orRegex: { referenceId: value },
              }
            })
          })
          .then((res) => {

            this.setState({
              propertyunitsDropdowns: res.data,
              fetching: false,
            });
          })
      }, 500)
    })

  }

  handleSearchAccountonChange = (e) => {
    // console.log(e)
    this.setState({
      searchPropertyUnit: e,
      typingTimeout: setTimeout(() => {
        this._getData(this.state.skip)
      }, 500)
    })

  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const columns = [

      {
        title: 'Read Date',
        dataIndex: 'readDate',
        key: 'readDate',
        width: '10%',
        render: (text, record) => (

          <span>{moment(text).format('DD-MM-YYYY')}</span>

        ),
      },
      {
        title: (
          <Row gutter={8}>
            <Row><Col span={24}>Unit Number</Col></Row>
            <Row><Col span={24}>
              <Select
                showSearch
                placeholder="Select Property"
                notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
                filterOption={false}
                onSearch={this.handleSearchAccount}
                onChange={this.handleSearchAccountonChange}
                style={{ width: '100%' }}
              >
                {this.state.propertyunitsDropdowns.map(d => <Option key={d._id}>{d.referenceId}</Option>)}
              </Select>
            </Col></Row>
          </Row>
        ),
        dataIndex: 'propertyunitId.referenceId',
        key: 'propertyunitId.referenceId',
        width: '10%',
      },

      {
        title: 'Previous',
        dataIndex: 'previousNum',
        key: 'previousNum',
        width: '10%',
      },
      {
        title: 'Current',
        dataIndex: 'currentNum',
        key: 'currentNum',
        width: '10%',
      },
      {
        title: 'Usage',
        dataIndex: 'usageNum',
        key: 'usageNum',
        width: '10%',
      },
      {
        title: (<Row ><Col style={{ 'textAlign': 'right' }}>Bill Amount</Col></Row>),
        dataIndex: 'billAmount',
        key: 'billAmount  ',
        width: '10%',
        render: (text, record) => (

          <span style={{ 'float': 'right' }}>{text ? parseFloat(text).toFixed(2) : ''}</span>
        ),
      },
      {
        title: 'Charge Date',
        dataIndex: 'chargeDate',
        key: 'chargeDate',
        width: '10%',
        render: (text, record) => (
          <span>{text ? moment(text).format('DD-MM-YYYY') : ''}</span>
        ),
      },
      {
        title: 'Notes',
        dataIndex: 'notes',
        key: 'notes',
        width: '10%',
        render: (text, record) => (
          <span>
            {text ? <Popover content={text}><Icon type="message" /></Popover> : ''}
          </span>
        ),
      },

      {
        title: 'Action',
        key: 'action',
        width: '20%',
        render: (text, record) => (
          <span>
            <Button onClick={() => this.handleEdit(record._id)}>Edit</Button>
            <Divider type="vertical" />
            {this.renderConfirmDeleteBtn(record._id)}
          </span>
        ),
      }];

    return (
      <Layout>
        <Spin key='spinning' spinning={this.state.loading} >
          <Card title="Water Bills" extra={<Button type="primary" onClick={this.showModal}>New</Button>}>
            <Table dataSource={this.props.waterbills.waterbills} columns={columns} rowKey="_id" />
            <WaterBillsForm />
          </Card>
        </Spin>
      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    waterbills: state.waterbills,
    commons: state.commons
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchWaterBills: fetchWaterBills,
    pushWaterBills: pushWaterBills,
    removeWaterBills: removeWaterBills,
    updateActiveWaterBills: updateActiveWaterBills,
    updateWaterBills: updateWaterBills,
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(WaterBillsIndex)));
