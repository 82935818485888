import React, { Component, useEffect } from 'react';
import {
  Alert,
  Modal,
  Upload,
  Popconfirm,
  message,
  Divider,
  Table,
  Form,
  Icon,
  Input,
  Button,
  Select,
  Card,
  Row,
  Col,
  Pagination,
  Drawer,
  Spin,
  DatePicker,
} from 'antd';
import client from '../../feathers';
import requireAuth from '../requireAuth';
import Layout from '../layout';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateMode } from '../../actions/actions-accounts';
import {
  fetchJournalEntries,
  updateActiveJournalEntries,
} from '../../actions/actions-journalentries';
import { Route, Link } from 'react-router-dom';
import FormSingle from '../../components/ledgers/form-single';
import axios from 'axios';
import moment from 'moment';
import ReactToPrint from 'react-to-print';

import NumberFormat from 'react-number-format';
import { updateActivePage } from '../../actions/app-actions';

// ---------------------------------------
const FormItem = Form.Item;
const Option = Select.Option;
const _ = require('lodash');
const text = 'Are you sure to delete this item?';
const PAGESIZE = 20;

class LedgerIndexJournalEntries extends Component {
  constructor(props) {
    super(props);

    this.state = {
      journalentries: [],
      visibleSingle: false,
      totalPagination: 1,
      CurrentPagination: 1,
      descriptionDropdown: [],
      searchGLReference: '',
      searchDescription: '',
      searchRemark: '',
      searchAccount: '',
      searchTrxDate: '',
      searchTrxDate2: '',
      loading: false,
      skip: 0,
    };
    this._searchDescription = this._searchDescription.bind(this);
  }

  componentDidMount() {
    client.service('journalentries').on('created', (data) => {
      if (data.companyId === sessionStorage.getItem('companyId')) {
        this._getData(0);
      }
    });

    client.service('journalentries').on('removed', (data) => {
      this._getData(0);
    });

    client.service('journalentries').on('updated', (data) => {
      if (data.companyId === sessionStorage.getItem('companyId')) {
        this._getData(0);
      }
    });

    client.service('journalentries').on('patched', (data) => {
      if (data.companyId === sessionStorage.getItem('companyId')) {
        this._getData(0);
      }
    });
  }

  componentWillMount() {
    const activePage = this.props.activePage.journalentriesPage;
    if (activePage > 1) {
      this.handlePageChange(activePage);
    } else {
      this._getData(0);
    }
  }

  formatAmt(amt) {
    let integer = parseFloat(amt);
    return integer > 0 ? integer.toFixed(2) : 0.0;
  }

  //Search Description
  _searchDescription(e) {
    this.setState(
      {
        searchName: e.target.value,
        searchObj: _.merge(
          this.state.searchObj,
          e.target.value
            ? {
                description: e.target.value,
              }
            : {
                description: '.',
              }
        ),
      },
      () => {
        typingTimeout: setTimeout(() => {
          this._getData(this.state.skip);
        }, 500);
      }
    );
  }

  componentDidUpdate() {}

  _getData(skip) {
    this.setState({ loading: true });
    client
      .authenticate()
      .then((res) => {
        return client.service('journalentries').find({
          query: {
            companyId: this.props.commons.selectedCompany._id,
            // companyType: {
            //   $in: ['PROPERTY']
            // },
            // trancode: '2500',
            // $populate: 'ledgerAccountId',
            $sort: {
              // referenceId: -1,
              trxDate: -1,
            },
            $skip: skip,
            $limit: PAGESIZE,
          },
        });
      })
      .then((res) => {
        // console.log({res}
        this.props.fetchJournalEntries(res.data);
        console.log(this.props);
        this.setState({
          totalPagination: res.total,
          journalentries: res.data,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handlePageChange = (pagination, filters, sorter) => {
    const page =
      typeof pagination === 'number' ? pagination : pagination.current;
    this.props.updateActivePage({
      journalentriesPage: page,
    });
    this._getData(page * PAGESIZE - PAGESIZE);
  };

  handleConfirmBlur = (e) => {};

  handleSubmit = (e) => {
    e.preventDefault();
  };

  showSingle = () => {
    this.setState({ visibleSingle: true });
  };

  //Drawer function
  showDrawer = () => {
    this.setState({ visible: true });
  };

  onClose = () => {
    this.setState({ visible: false });
  };

  //Handle Search
  handleSearch = (e) => {
    this.props.form.validateFields((err, values) => {
      // const { searchGLReference, searchAccount, searchDescription, searchRemark, searchTrxDate } = this.state

      let searchGLReference =
        this.state.searchGLReference === ''
          ? { referenceId: '.' }
          : { referenceId: this.state.searchGLReference };
      let searchAccount =
        this.state.searchAccount === ''
          ? '.'
          : { ledgerAccountId: this.state.searchAccount };
      let searchDescription =
        this.state.searchDescription === ''
          ? { description: '.' }
          : { description: this.state.searchDescription };
      let searchRemark =
        this.state.searchRemark === ''
          ? { remark: '.' }
          : { remark: this.state.searchRemark };
      let searchTrxDate;

      // console.log({searchGLReference});

      if (this.state.searchTrxDate !== '') {
        let date = parseInt(this.state.searchTrxDate.replace(/-/g, ''));
        searchTrxDate = {
          trxDateInt: {
            $eq: date,
          },
        };
      }

      let collection = {
        ...searchGLReference,
        ...searchAccount,
        ...searchTrxDate,
      };
      this.setState({ loading: true });
      client
        .authenticate()
        .then((res) => {
          return client.service('journalentries').find({
            query: {
              // ...collection,
              companyId: this.props.commons.selectedCompany._id,
              orRegex: {
                ...searchGLReference,
                ...searchDescription,
                ...searchRemark,
              },
              $sort: {
                referenceId: -1,
                trxDate: -1,
              },
              $limit: 20,
            },
          });
        })
        .then((res) => {
          this.setState({
            journalentries: res.data,
            totalPagination: res.total,
            loading: false,
          });
          this.props.fetchJournalEntries(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  //handleSearchAccount

  //handle auto search Description
  handleSearchDescription = (value) => {
    this.setState({ fetching: true });
    client
      .authenticate()
      .then((res) => {
        return client.service('journalentries').find({
          query: {
            companyId: this.props.commons.selectedCompany._id,
            orRegex: {
              description: value,
            },
            trancode: '2500',
            $sort: {
              referenceId: -1,
              trxDate: -1,
            },
          },
        });
      })
      .then((res) => {
        this.setState({ descriptionDropdown: res.data, fetching: false });
      })
      .catch((err) => {
        this.setState({ fetching: false });
        console.log(err);
      });
  };

  // Filtering
  _searchGLReferenceChange = (e) => {
    this.setState({ searchGLReference: e.target.value });
    this.handleSearch();
  };

  _searchDescriptionChange = (e) => {
    this.setState({ searchDescription: e.target.value });
    this.handleSearch();
  };

  _searchRemarkChange = (e) => {
    this.setState({ searchRemark: e.target.value });
    this.handleSearch();
  };

  _searchTrxDateChange = (e) => {
    this.state.searchTrxDate = moment(new Date(e)).format('YYYY-MM-DD');
    this.state.searchTrxDate2 = e;
    this.handleSearch();
  };

  _searchTrxDateChange2 = (e) => {
    this.state.searchTrxDate2 = e.target.value;
    this.handleSearch();
  };
  //RenderSearching
  handleDelete(_id) {
    axios
      .post(
        `${client.io.io.uri}removeTrx`,
        {
          referenceId: _id,
          companyId: this.props.commons.selectedCompany._id,
          trxType: 'JE',
        },
        {
          headers: {
            Authorization: client.settings.storage.storage['feathers-jwt'],
          },
        }
      )
      .then((res) => {
        message.success('Deleted');
      })
      .catch((err) => {
        console.log(err);
      });
  }

  renderConfirmDeleteBtn(_id) {
    return (
      <Popconfirm
        placement='topLeft'
        title={text}
        onConfirm={() => this.handleDelete(_id)}
        okText='Confirm'
        cancelText='Cancel'
      >
        <Button type='danger'>Delete</Button>
      </Popconfirm>
    );
  }

  renderConfirmPrintBtn(record) {
    return (
      <span>
        <Link
          to={{
            pathname: `/ledgers/journalEntriesPrint/${record._id}`,
          }}
        >
          <Button
            key='navigateJV'
            onClick={() => this.updateActiveJournalEntries(record._id)}
          >
            View
          </Button>
        </Link>
      </span>
    );
  }

  renderViewBtn(_id) {
    return (
      <div>
        <Button>View</Button>
      </div>
    );
  }

  updateActiveJournalEntries(_id) {
    this.props.updateActiveJournalEntries('edit', _id);
  }

  render() {
    const { visible, onCancel } = this.props;
    const { getFieldDecorator } = this.props.form;
    const columns = [
      {
        title: (
          <Row gutter={8}>
            <Row>
              <Col span={24}>Transaction Date</Col>
            </Row>
            <Row>
              <Col span={24}>
                {/*<DatePicker className='no-print' value={this.state.searchTrxDate2} onChange={this._searchTrxDateChange}/>*/}
              </Col>
            </Row>
          </Row>
        ),
        dataIndex: 'trxDate',
        key: 'trxDate',
        width: '11%',
        render: (text, record) => (
          <span>{moment(record.trxDate).format('DD/MM/YYYY')}</span>
        ),
      },
      {
        title: (
          <Row gutter={8}>
            <Row>
              <Col span={24}>GL Reference</Col>
            </Row>
            <Row>
              <Col span={24}>
                <Input
                  className='no-print'
                  value={this.state.searchGLReference}
                  onChange={this._searchGLReferenceChange}
                />
              </Col>
            </Row>
          </Row>
        ),
        dataIndex: 'referenceId',
        key: 'referenceId',
        width: '15%',
      },
      {
        title: (
          <Row gutter={8}>
            <Row>
              <Col span={24}>Description</Col>
            </Row>
            <Row>
              <Col span={24}>
                <Input
                  className='no-print'
                  value={this.state.searchDescription}
                  onChange={this._searchDescriptionChange}
                />
              </Col>
            </Row>
          </Row>
        ),
        dataIndex: 'description',
        key: 'description',
        width: '15%',
      },
      {
        title: (
          <Row gutter={8}>
            <Row>
              <Col span={24}>Remark</Col>
            </Row>
            <Row>
              <Col span={24}>
                <Input
                  className='no-print'
                  value={this.state.searchRemark}
                  onChange={this._searchRemarkChange}
                />
              </Col>
            </Row>
          </Row>
        ),
        dataIndex: 'remark',
        key: 'remark',
        width: '10%',
      },
      {
        title: (
          <Row gutter={8}>
            <Row>
              <Col span={24}>Amount</Col>
            </Row>
          </Row>
        ),
        align: 'right',
        dataIndex: 'amount',
        key: 'amount',
        width: '9%',
        render: (text, record) => (
          <span>
            {
              <NumberFormat
                thousandSeparator={true}
                displayType='text'
                fixedDecimalScale={true}
                decimalScale={2}
                value={record.amount || 0}
              />
            }
          </span>
        ),
      },
      {
        title: <div className='no-print'>Action</div>,
        key: 'action',
        width: '10%',
        render: (text, record) => (
          <Row>
            <span className='no-print'>
              {this.renderConfirmPrintBtn(record)}
              <Divider type='vertical' />
              {this.renderConfirmDeleteBtn(record._id)}
            </span>
          </Row>
        ),
      },
    ];

    return (
      <Layout>
        <Spin key='spinning' spinning={this.state.loading}>
          <Card
            key='journalentry'
            title='Journal Entry'
            extra={[
              <Button
                className='no-print'
                key='newTrx'
                type='primary'
                onClick={this.showDrawer}
              >
                New
              </Button>,
            ]}
          >
            <React.Fragment>
              <Drawer
                title='Single / Multiple GL Entry'
                width={640}
                onClose={this.onClose}
                visible={this.state.visible}
                style={{
                  overflow: 'auto',
                  // height: 'calc(100% - 108px)',
                  paddingBottom: '108px',
                }}
              >
                <FormSingle />
              </Drawer>
            </React.Fragment>

            <Row>
              <Col span={24}>
                <Table
                  dataSource={this.state.journalentries}
                  columns={columns}
                  rowKey='_id'
                  onChange={(e) => this.handlePageChange(e)}
                  pagination={{
                    defaultCurrent: this.props.activePage.journalentriesPage,
                    total: this.state.totalPagination,
                    pageSize: 20,
                    size: 'small',
                  }}
                />
              </Col>
            </Row>
          </Card>
        </Spin>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    journalentries: state.journalentries,
    commons: state.commons,
    activePage: state.app.activePage,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchJournalEntries: fetchJournalEntries,
      updateMode: updateMode,
      updateActiveJournalEntries: updateActiveJournalEntries,
      updateActivePage,
    },
    dispatch
  );
}

export default requireAuth(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Form.create()(LedgerIndexJournalEntries))
);
