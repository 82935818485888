import React, { Component } from 'react';
import { Popconfirm, message, Divider,  Table, Form, Icon, Input, Button,Card } from 'antd';
import { Route, Link } from 'react-router-dom';

import client from '../../feathers';
import requireAuth  from "../../components/requireAuth";
import Layout  from "../../components/layout";
import ManagementsForm  from "../../components/management/form";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchManagements,
  pushManagements,
  removeManagements,
  updateMode,
  updateActiveManagement,
  updateManagement } from '../../actions/managements-actions';

const FormItem = Form.Item;

const text = 'Are you sure to delete this item?';

class ManagementsIndex extends Component {
  constructor(props) {
    super(props);

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidUpdate(){
  }

  componentDidMount(){
      client.service('managements').on('created', (managements) => {
        //this.props.pushManagements(managements)
        this.componentWillMount();
      })

      client.service('managements').on('removed', (managements) => {
        //this.props.removeManagements(managements)
        this.componentWillMount();
      })

      client.service('managements').on('updated', (managements) => {
        //this.props.updateManagement(managements);
        this.componentWillMount();
      })

      client.service('managements').on('patched', (managements) => {
        //this.props.updateManagement(managements)
        this.componentWillMount();
      })
  }

  componentWillMount(){
      client.authenticate()
      .then(()=>{
          return client.service('managements').find({
            query: {
               $sort: {
                 createdAt: -1
               }
             }
          })
      })
      .then((res)=>{
        this.props.fetchManagements(res.data)
      })
      .catch((err)=>{
          console.log(err);
      })
  }

  handleDelete(_id) {
     client.service('managements').remove(_id)
     .then((res)=>{
     })
     .catch((err)=>{
       alert(err);
     })
  }

  handleEdit(_id) {
    this.props.updateActiveManagement('edit',_id)
  }

  handleManage(_id) {
    this.props.updateActiveManagement('',_id)
  }

  showModal = () => {
    this.props.updateMode('new')
  }

  renderConfirmDeleteBtn(_id){
    return (
      <Popconfirm placement="topLeft" title={text} onConfirm={()=>this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
        <Button type="danger">Delete</Button>
      </Popconfirm>
    )
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
      },
      {
        title: 'Mobile',
        dataIndex: 'mobile',
        key: 'mobile',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
          <Divider type="vertical" />
          <Button onClick={()=>this.handleEdit(record._id)}>Edit</Button>
          <Divider type="vertical" />
          {/*this.renderConfirmDeleteBtn(record._id)*/}
        </span>
      ),
    }];

    return(
      <Layout>
          <Card
            title="Managements"
            extra={<Button type="primary" onClick={this.showModal}>New Management</Button>}
          >

            <Table dataSource={this.props.managements.managements} columns={columns} rowKey="_id" />
            <ManagementsForm />
        </Card>

      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    managements: state.managements
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchManagements: fetchManagements,
    pushManagements: pushManagements,
    removeManagements: removeManagements,
    updateActiveManagement: updateActiveManagement,
    updateManagement: updateManagement,
    updateMode: updateMode
    }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(ManagementsIndex)));
