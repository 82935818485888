import React, { Component } from 'react';
import { Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Card, Spin } from 'antd';
import { Route, Link } from 'react-router-dom';

import client from '../../feathers';
import requireAuth from "../../components/requireAuth";
import Layout from "../../components/layout";
import TrxParametersForm from "../../components/trxparameter/form";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchTrxParameters,
  pushTrxParameters,
  removeTrxParameters,
  updateMode,
  updateActiveTrxParameters,
  updateTrxParameters
} from '../../actions/actions-trxparameters';


const FormItem = Form.Item;

const text = 'Are you sure to delete this item?';

class TrxParametersIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      loading: false,
      trxparameters: [],
      loading: false,
    }

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidUpdate() {
    if (this.props.trxparameters.mode === 'refresh') {

      this.componentWillMount()
    }
  }

  componentDidMount() {

  }

  componentWillMount() {

    this.props.updateMode('')
    this.setState({ loading: true });
    let companyId = this.props.commons.selectedCompany._id

    client.authenticate()
      .then(() => {
        return client.service('trxparameters').find({
          query: {
            companyId: companyId,
            $sort: {

              trancode: 1
            }
          }
        })
      })
      .then((res) => {
        this.setState({ loading: false });
        this.props.fetchTrxParameters(res.data)
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      })
  }

  handleDelete(_id) {
    client.service('trxparameters').remove(_id)
      .then((res) => {
        this.props.updateMode('refresh')
      })
      .catch((err) => {
        alert(err);
      })
  }

  handleEdit(_id) {
    this.props.updateActiveTrxParameters('edit', _id)

  }

  handleManage(_id) {
    this.props.updateActiveTrxParameters('', _id)
  }

  showModal = () => {
    this.props.updateMode('new')
  }

  renderConfirmDeleteBtn(_id) {
    return (
      <Popconfirm placement="topLeft" title={text} onConfirm={() => this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
        <Button type="danger">Delete</Button>
      </Popconfirm>
    )
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const columns = [

      {
        title: 'Tran Code',
        dataIndex: 'trancode',
        key: 'trancode',
        width: '10%',
      },
      {
        title: 'Description',
        dataIndex: 'tranDesc',
        key: 'tranDesc',
        width: '20%',
      },
      {
        title: 'Action',
        key: 'action',
        width: '20%',
        render: (text, record) => (
          <span>
            <Divider type="vertical" />
            <Button onClick={() => this.handleEdit(record._id)}>Edit</Button>
            <Divider type="vertical" />
            {this.renderConfirmDeleteBtn(record._id)}
          </span>
        ),
      }];

    return (
      <Layout>
        <Spin key='spinning' spinning={this.state.loading} >
          <Card
            title="Transasction Parameter"
            extra={<Button type="primary" onClick={this.showModal}>New</Button>}
          >



            <Table dataSource={this.props.trxparameters.trxparameters} columns={columns} rowKey="_id" />


            <TrxParametersForm />
          </Card>
        </Spin>
      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    trxparameters: state.trxparameters,
    commons: state.commons
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchTrxParameters: fetchTrxParameters,
    pushTrxParameters: pushTrxParameters,
    removeTrxParameters: removeTrxParameters,
    updateActiveTrxParameters: updateActiveTrxParameters,
    updateTrxParameters: updateTrxParameters,
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(TrxParametersIndex)));
