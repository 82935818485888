export const FETCH_MANAGEMENT = 'FETCH_MANAGEMENT';
export const PUSH_MANAGEMENT = 'PUSH_MANAGEMENT';
export const REMOVE_MANAGEMENT = 'REMOVE_MANAGEMENT';
export const UPDATE_MANAGEMENT = 'UPDATE_MANAGEMENT';
export const UPDATE_MODE = 'UPDATE_MODE';
export const UPDATE_ACTIVE_ID = 'UPDATE_ACTIVE_ID';

export function fetchManagements(data){
  return (dispatch) => {
    dispatch({
      type: FETCH_MANAGEMENT,
      payload:data
    });
  }
}

export function pushManagements(data){
  return (dispatch) => {
    dispatch({
      type: PUSH_MANAGEMENT,
      payload:data
    });
  }
}

export function removeManagements(data){
  return (dispatch) => {
    dispatch({
      type: REMOVE_MANAGEMENT,
      payload:data
    });
  }
}

export function updateManagement(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MANAGEMENT,
      payload:data
    });
  }
}

export function updateMode(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE,
      payload:data
    });
  }
}

export function updateActiveManagement(mode, id){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_ID,
      mode,
      id
    });
  }
}
