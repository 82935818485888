export const FETCH_FACILITIES = 'FETCH_FACILITIES';
export const PUSH_FACILITIES = 'PUSH_FACILITIES';
export const REMOVE_FACILITIES = 'REMOVE_FACILITIES';
export const UPDATE_FACILITIES = 'UPDATE_FACILITIES';
export const UPDATE_MODE = 'UPDATE_MODE';
export const UPDATE_ACTIVE_ID = 'UPDATE_ACTIVE_ID';

export function fetchFacilities(data){
  return (dispatch) => {
    dispatch({
      type: FETCH_FACILITIES,
      payload:data
    });
  }
}

export function pushFacilities(data){
  return (dispatch) => {
    dispatch({
      type: PUSH_FACILITIES,
      payload:data
    });
  }
}

export function removeFacilities(data){
  return (dispatch) => {
    dispatch({
      type: REMOVE_FACILITIES,
      payload:data
    });
  }
}

export function updateFacilities(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_FACILITIES,
      payload:data
    });
  }
}

export function updateMode(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE,
      payload:data
    });
  }
}

export function updateActiveFacilities(mode, id){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_ID,
      mode,
      id
    });
  }
}
