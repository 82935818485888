export const FETCH_PROPERTYUNITUSERS = 'FETCH_PROPERTYUNITUSERS';
export const PUSH_PROPERTYUNITUSERS = 'PUSH_PROPERTYUNITUSERS';
export const REMOVE_PROPERTYUNITUSERS = 'REMOVE_PROPERTYUNITUSERS';
export const UPDATE_PROPERTYUNITUSERS = 'UPDATE_PROPERTYUNITUSERS';
export const UPDATE_MODE = 'UPDATE_MODE';
export const UPDATE_ACTIVE_ID = 'UPDATE_ACTIVE_ID';

export function fetchPropertyunitusers(data){
  return (dispatch) => {
    dispatch({
      type: FETCH_PROPERTYUNITUSERS,
      payload:data
    });
  }
}

export function pushPropertyunitusers(data){
  return (dispatch) => {
    dispatch({
      type: PUSH_PROPERTYUNITUSERS,
      payload:data
    });
  }
}

export function removePropertyunitusers(data){
  return (dispatch) => {
    dispatch({
      type: REMOVE_PROPERTYUNITUSERS,
      payload:data
    });
  }
}

export function updatePropertyunitusers(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_PROPERTYUNITUSERS,
      payload:data
    });
  }
}

export function updateMode(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE,
      payload:data
    });
  }
}

export function updateActivePropertyunitusers(mode, id){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_ID,
      mode,
      id
    });
  }
}
