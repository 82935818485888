import React, { Component } from "react";
import {
  Popconfirm,
  message,
  Divider,
  Table,
  Form,
  Icon,
  Input,
  Button,
  Card,
  Modal,
  Spin,
  Col,
  Row,
  Select,
  DatePicker,
} from "antd";
import { Route, Link } from "react-router-dom";

import client from "../../feathers";
import requireAuth from "../../components/requireAuth";
import Layout from "../../components/layout";
import LedgerIndexTransactionsByReference from "../../components/ledgers/index-transactions-by-reference";
import moment from "moment";
import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  fetchInvoiceitems,
  pushInvoiceitems,
  removeInvoiceitems,
  updateActiveInvoiceitems,
  updateMode,
  updateInvoiceitems,
} from "../../actions/actions-invoiceitems";

const FormItem = Form.Item;
const Option = Select.Option;
const text = "Are you sure to delete this item?";

const formItemLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

class InvoicesDetailIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      img: "",
      datasource: [],
      fromPaymentVoucher: "YES",
      loading: false,
      bankledgerAccountList: [],
      invoiceitems: "",
    };
  }

  componentDidUpdate() {
    if (this.props.invoiceitems.mode === "refresh") {
      this.componentWillMount();
    }
  }

  componentDidMount() {
    // client.service('invoice-items').on('patched', (receipts) => {
    //   if(receipts.propertyId===sessionStorage.getItem('companyId')){
    //     this.componentWillMount();
    //   }
    // })
  }

  _getData(skip) {}

  componentWillMount() {
    this.props.updateMode("");
    let companyId = this.props.commons.selectedCompany._id;

    this.setState({ loading: true });
    client
      .authenticate()
      .then((res) => {
        return client.service("invoice-items").find({
          query: {
            propertyId: companyId,
            invoiceId: this.props.match.params.id,
            $populate: "chargeId",
            status: {
              $in: ["ISSUED", "PARTIAL"],
            },
          },
        });
      })
      .then((res) => {
        this.setState({ datasource: res.data, loading: false });
        this.props.fetchInvoiceitems(res.data);

        return client
          .service("ledger-accounts")
          .find({
            query: {
              companyId: companyId,
              categories: "ASSETS",
              bankAccountFlag: true,
              //orRegex:{description:'BANK'},
              $sort: {
                description: 1,
              },
            },
          })
          .then((res) => {
            this.setState({ bankledgerAccountList: res.data });
          });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  }

  handleEdit(record) {
    this.props.updateActiveInvoiceitems("edit", record._id);
    this.setState({ visible: true });
  }

  handleDelete(_id, reference) {
    let companyId = this.props.commons.selectedCompany._id;

    axios
      .post(
        `${client.io.io.uri}removeTrx`,
        {
          _id: _id,
          companyId: companyId,
          trxType: "IVD",
        },
        {
          headers: {
            Authorization: client.settings.storage.storage["feathers-jwt"],
          },
        }
      )
      .then((res) => {
        message.success("Deleted");
        this.props.updateMode("refresh");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  showModal = () => {
    this.props.updateMode("new");
  };

  handleOk = (e) => {
    this.props.form.validateFields((err, values) => {
      let companyId = this.props.commons.selectedCompany._id;
      client
        .service("invoice-items")
        .patch(this.props.invoiceitems.activeId, {
          ...values,
          oriAmount: values.appliedAmount,
          editInvoice: true,
          propertyId: companyId,
          systemPatch: true,
        })
        .then((res) => {
          this.props.updateMode("refresh");

          axios.get(
            `${client.io.io.uri}updateFutureInvoicesBalance`,
            {
              params: { invoiceId: this.props.match.params.id },
            },
            {
              headers: {
                Authorization: client.settings.storage.storage["feathers-jwt"],
              },
            }
          );
        });
    });

    this.props.updateActiveInvoiceitems("", "");
    this.setState({ visible: false });
  };

  handleCancel = (e) => {
    this.props.updateActiveInvoiceitems("", "");

    this.setState({ visible: false });
  };

  renderConfirmDeleteBtn(_id, reference) {
    return (
      <Popconfirm
        placement="topLeft"
        title={text}
        onConfirm={() => this.handleDelete(_id, reference)}
        okText="Confirm"
        cancelText="Cancel"
      >
        <Button type="danger">Delete</Button>
      </Popconfirm>
    );
  }

  _paymentDateChange = (e) => {
    this.setState({
      date: moment(new Date(e)).format("YYYY-MM-DD"),
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { datasource, fromPaymentVoucher } = this.state;

    const columns = [
      {
        title: "Doc ID",
        dataIndex: "docId",
        key: "docId",
        width: "10%",
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        width: "10%",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: "10%",
      },
      {
        title: "Due Date",
        dataIndex: "dueDate",
        key: "dueDate",
        width: "12%",
        render: (text, record) => (
          <span>{text ? moment(text).format("DD-MM-YYYY") : ""}</span>
        ),
      },
      {
        title: "Charge Id",
        dataIndex: "chargeId.description",
        key: "chargeId",
        width: "10%",
      },
      {
        title: (
          <span
            style={{
              float: "right",
            }}
          >
            Charge Amount
          </span>
        ),
        dataIndex: "appliedAmount",
        key: "appliedAmount",
        width: "10%",
        render: (text, record) => (
          <span
            style={{
              float: "right",
            }}
          >
            {record.appliedAmount.toFixed(2)}
          </span>
        ),
      },
      {
        title: "Action",
        key: "action",
        width: "18%",
        render: (text, record) => (
          <span>
            <Button
              key="navigatePVDetails"
              onClick={() => this.handleEdit(record)}
            >
              Edit
            </Button>
            <Divider type="vertical" />{" "}
            {this.renderConfirmDeleteBtn(record._id, record.referenceId)}
          </span>
        ),
      },
    ];

    return (
      <Layout>
        <Spin spinning={this.state.loading} key="spinning">
          <Card title="Invoice Items">
            <Table dataSource={datasource} columns={columns} rowKey="_id" />
          </Card>

          <Modal
            title="Edit Invoice Item"
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
          >
            <Form className="submit-form">
              <Row>
                <Col span={24}>
                  <FormItem {...formItemLayout} label="Amount">
                    {getFieldDecorator("appliedAmount", {
                      rules: [
                        {
                          required: true,
                          message: "Please input Amount!",
                        },
                      ],
                    })(<Input />)}
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Modal>
        </Spin>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    invoiceitems: state.invoiceitems,

    commons: state.commons,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateMode: updateMode,
      fetchInvoiceitems: fetchInvoiceitems,
      pushInvoiceitems: pushInvoiceitems,
      removeInvoiceitems: removeInvoiceitems,
      updateActiveInvoiceitems: updateActiveInvoiceitems,
    },
    dispatch
  );
}

export default requireAuth(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Form.create()(InvoicesDetailIndex))
);
