import React, { Component, useEffect } from "react";
import {
  Modal,
  message,
  Form,
  Icon,
  Table,
  Input,
  Button,
  Select,
  Card,
  Spin,
  Tag,
  Divider,
  Popconfirm,
} from "antd";
import client from "../../feathers";
import requireAuth from "../requireAuth";
import Layout from "../layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateMode } from "../../actions/actions-accounts";
import { refreshPage } from "../../actions/actions-commons";
import { Chart } from "react-google-charts";
import OrgChart from "react-orgchart";
import ChartAction from "./ChartAction";
import "react-orgchart/index.css";

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
const { Meta } = Card;
const _ = require("lodash");

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};

const text = "Are you sure to delete this item?";

class ChartIndexOrganizational extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      visible: "",
      orgObj: {},
      visiblePop: false,
      commons: "",
      tableData: "",
      patchCurrentId: "",
      totalRecord: 0,
      propertyUnits: [],
      user: [],
      selectedUserId: "",
      roles: [
        "Chairman",
        "Treasure",
        "Secretary",
        "Committee",
        "Office",
        "Building manager",
        "Admin",
        "Account",
        "Technician",
      ],
    };
  }

  componentDidMount() {
    // client.service('organizational-charts').on('created', (notices) => {
    //   this.setState({
    //     loading: true
    //   },()=>{this._getData()})
    // })
    //
    // client.service('organizational-charts').on('removed', (notices) => {
    //   this.setState({
    //     loading: true
    //   },()=>{this._getData()})
    // })
    //
    // client.service('organizational-charts').on('updated', (notices) => {
    //   this.setState({
    //     loading: true
    //   },()=>{this._getData()})
    // })
    //
    // client.service('organizational-charts').on('patched', (notices) => {
    //   this.setState({
    //     loading: true
    //   },()=>{this._getData()})
    // })
  }

  _getAllUser() {
    client
      .authenticate()
      .then(() => {
        return client.service("users").find({
          query: {
            // _id:this.props.match.params.id,
          },
        });
      })
      .then((res) => {
        this.setState({
          user: res.data,
        });
      });
  }

  _getPropertyUnits() {
    client.authenticate().then(() => {
      return client
        .service("propertyunits")
        .find({
          query: {
            propertyId: this.props.user.info.propertyId._id,
            $sort: {
              referenceId: 1,
            },
          },
        })
        .then((res) => {
          if (res) {
            this.setState({
              propertyUnits: res.data,
            });
          }
        });
    });
  }

  _getNestedChildren(arr, parent) {
    var out = [];
    for (var i in arr) {
      if (arr[i].parent === parent) {
        var children = this._getNestedChildren(arr, arr[i].name);
        if (children.length) {
          arr[i].children = children;
        }
        out.push(arr[i]);
      }
    }
    return out;
  }

  componentWillMount() {
    this._getData();
    this._getPropertyUnits();
    this._getAllUser();
  }

  _getData() {
    // this.props.refreshPage('');
    client
      .authenticate()
      .then((res) => {
        return client.service("organizational-charts").find({
          query: {
            // propertyId: this.props.user.info.propertyId._id,
            propertyId: this.props.user.info.propertyId._id,
            $sort: { createdAt: 1 },
            $limit: 20,
          },
        });
      })
      .then((res) => {
        this.setState({ totalRecord: res.total });
        // console.log({ res })
        if (res.data.length > 0) {
          let arr = [];
          for (let i = 0; i < res.total; i++) {
            arr.push({
              name: res.data[i].name,
              contact: res.data[i].contact,
              _id: res.data[i]._id,
              children: this._getNestedChildren(res.data, res.data[i].name),
            });
          }
          // console.log(arr[0])

          this.setState({
            loading: false,
            orgObj: arr[0],
            tableData: res.data,
          });
        } else {
          message.info("No Record Found.");
          this.setState({
            loading: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidUpdate() {
    if (this.props.commons.mode === "refresh") {
      this._getData();
    }
  }

  handleChangePropertyunit = (e) => {
    var selectedProperty = _.find(this.state.propertyUnits, { _id: e });
    let user =
      selectedProperty.users &&
      selectedProperty.users.filter((i) => i.userType === "primary");
    let id = user.length > 0 && user[0].userId;
    let selectedUser =
      this.state.user && this.state.user.filter((i) => i._id === id);
    // let name = user ? selectedProperty.owner.name : "";
    // let contact = selectedProperty.owner
    //   ? selectedProperty.owner.contactNumber
    //   : "";
    this.props.form.setFieldsValue({
      name:
        selectedUser && selectedUser[0]
          ? selectedUser[0].lastName + " " + selectedUser[0].firstName
          : "",
      contact: selectedUser && selectedUser[0] ? selectedUser[0].phoneNo : "",
    });
    this.setState({
      selectedUserId:
        selectedUser && selectedUser[0] ? selectedUser[0]._id : "",
    });
  };

  handleChangeRole = (e) => {
    this.props.form.setFieldsValue({
      role: e,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (this.state.visible === "new") {
          if (this.state.totalRecord >= 12) {
            return message.error("Only 14 members can be created !");
          }
          client
            .authenticate()
            .then((res) => {
              return client.service("organizational-charts").create({
                ...values,
                // propertyId:this.props.user.info.propertyId._id,
                propertyId: this.props.user.info.propertyId._id,
                parent: "",
                userId: this.state.selectedUserId,
              });
            })
            .then((res) => {
              //

              this.setState({
                visible: false,
              });
              message.success("Added");
              this._getData();
              this.props.form.resetFields();
            })
            .catch((err) => {
              this.setState({
                visible: false,
              });
              this.props.form.resetFields();
              // alert(err);
            });
        } else {
          client
            .service("organizational-charts")
            .patch(this.state.patchCurrentId, { ...values })
            .then((res) => {
              this.setState({ visible: false });
              message.success("Successfully Added");
              this._getData();
              this.props.form.resetFields();
            });
        }
      }
    });
  };

  showModal(type) {
    this.setState({
      visible: type,
    });
  }

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
    this.props.form.resetFields();
  };

  _renderNewButton() {
    let userRole = this.props.commons.selectedRole;
    if (userRole === "authoriser") {
      return (
        <Button
          type="primary"
          onClick={() => {
            this.showModal("new");
          }}
        >
          New
        </Button>
      );
    } else {
      return null;
    }
  }
  handleDelete(_id) {
    client
      .service("organizational-charts")
      .remove(_id)
      .then((res) => {
        this._getData();
        message.success("Successfully Deleted");
      })
      .catch((err) => {
        alert(err);
      });
  }

  render() {
    let showModal = "";
    const { visible } = this.state;
    const { getFieldDecorator } = this.props.form;

    if (visible === "edit" || visible === "new") {
      showModal = true;
    } else {
      showModal = false;
    }

    let organCol = [
      {
        title: "Name",
        dataIndex: "name",
      },
      // {
      //   title: "Title",
      //   dataIndex: "title",
      // },
      {
        title: "Contact",
        dataIndex: "contact",
      },
      {
        title: "Role",
        dataIndex: "role",
      },
      {
        title: "Action",
        dataIndex: "action",
        render: (data, record) => {
          return (
            <div>
              <Button
                onClick={() => {
                  this.showModal("edit");
                  this.setState({ patchCurrentId: record._id });
                  this.props.form.setFieldsValue({
                    name: record.name,
                    title: record.title,
                    contact: record.contact,
                  });
                }}
                type="primary"
              >
                Edit
              </Button>
              <Divider type="vertical" />
              <Popconfirm
                onConfirm={() => this.handleDelete(record._id)}
                title={text}
              >
                <Button type="danger">Delete</Button>
              </Popconfirm>
            </div>
          );
        },
      },
    ];

    const ChartNode = ({ node }) => {
      return (
        <div className="initechNode">
          <Icon style={{ fontSize: "20px" }} type="user" />
          <ChartAction parent={node.name} info={node} />
          <p>
            <b>{node.name}</b>
          </p>
          <hr />
          <Tag color="blue">{node.title}</Tag>
          <p>
            <Icon type="phone" /> {node.contact}
          </p>
        </div>
      );
    };

    return (
      <Layout>
        <Card title="Organizational Chart" extra={this._renderNewButton()}>
          <Spin spinning={this.state.loading}>
            <div className="App" id="initechOrgChart">
              <Table
                columns={organCol}
                rowKey="name"
                dataSource={this.state.tableData}
                pagination={{ total: this.state.total, pageSize: 20 }}
              />
              {/* <OrgChart tree={this.state.orgObj} NodeComponent={ChartNode} /> */}
            </div>
          </Spin>
        </Card>

        <Modal
          title="Details"
          visible={showModal}
          onOk={this.handleSubmit}
          textOk="Submit"
          onCancel={this.handleCancel}
        >
          <Form className="submit-form">
            {/* <FormItem
              {...formItemLayout}
              label="Parent"
              hasFeedback
            >
              {getFieldDecorator('parent', {
                rules: [
                  { required: false, message: 'Please input Parent Name!' },
                ],
              })(
                <Input />
              )}
            </FormItem> */}
            {this.state.visible === "new" && (
              <FormItem
                {...formItemLayout}
                key="propertyunitId"
                label="Property Unit"
              >
                {getFieldDecorator("propertyunitId", {
                  rules: [
                    {
                      required: true,
                      message: "Please Input Property Unit!",
                    },
                  ],
                })(
                  <Select
                    onChange={this.handleChangePropertyunit}
                    showSearch
                    placeholder="Select a unit"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.state.propertyUnits.map((dropdown) => (
                      <Option key={dropdown._id} value={dropdown._id}>
                        {dropdown.referenceId}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            )}

            <FormItem {...formItemLayout} label="Name" hasFeedback>
              {getFieldDecorator("name", {
                rules: [{ required: true, message: "Please input Name!" }],
              })(<Input disabled />)}
            </FormItem>

            <FormItem {...formItemLayout} label="Contact" hasFeedback>
              {getFieldDecorator("contact", {
                rules: [{ required: true, message: "Please Input Contact!" }],
              })(<Input disabled />)}
            </FormItem>

            <FormItem {...formItemLayout} label="Role" hasFeedback>
              {getFieldDecorator("role", {
                rules: [{ required: true, message: "Please Input Role!" }],
              })(
                <Select
                  onChange={this.handleChangeRole}
                  showSearch
                  placeholder="Select a role"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.state.roles.map((dropdown) => (
                    <Option key={dropdown} value={dropdown}>
                      {dropdown}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Form>
        </Modal>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    commons: state.commons,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateMode: updateMode,
      refreshPage: refreshPage,
    },
    dispatch
  );
}

export default requireAuth(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Form.create()(ChartIndexOrganizational))
);
