export const FETCH_DOCUMENTS = 'FETCH_DOCUMENTS';
export const PUSH_DOCUMENTS = 'PUSH_DOCUMENTS';
export const REMOVE_DOCUMENTS = 'REMOVE_DOCUMENTS';
export const UPDATE_DOCUMENTS = 'UPDATE_DOCUMENTS';
export const UPDATE_MODE = 'UPDATE_MODE';
export const UPDATE_ACTIVE_ID = 'UPDATE_ACTIVE_ID';

export function fetchDocuments(data){
  return (dispatch) => {
    dispatch({
      type: FETCH_DOCUMENTS,
      payload:data
    });
  }
}

export function pushDocuments(data){
  return (dispatch) => {
    dispatch({
      type: PUSH_DOCUMENTS,
      payload:data
    });
  }
}

export function removeDocuments(data){
  return (dispatch) => {
    dispatch({
      type: REMOVE_DOCUMENTS,
      payload:data
    });
  }
}

export function updateDocuments(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_DOCUMENTS,
      payload:data
    });
  }
}

export function updateMode(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE,
      payload:data
    });
  }
}

export function updateActiveDocuments(mode, id){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_ID,
      mode,
      id
    });
  }
}
