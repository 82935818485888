import React, { Component } from 'react';
import { Popconfirm, message, Divider,  Table, Form, Icon, Input, Button,Card,Row, Col, } from 'antd';
import { Route, Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import client from '../../feathers';
import requireAuth  from "../../components/requireAuth";
import Layout  from "../../components/layout";
import PropertyForm  from "./property-form";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchProperties,
  pushProperties,
  removeProperties,
  updateMode,
  updateActiveProperty,
  updateProperties } from '../../actions/properties-actions';

const _ = require('lodash');
const FormItem = Form.Item;

const text = 'Are you sure to delete this item?';
const PAGESIZE = 10;

class PropertyIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchName:'',
      searchCode:'',
      searchType:'',
      searchObj:{},
      total:0,
      skip:0
    }

    this.handleDelete = this.handleDelete.bind(this);
    this._searchNameChange = this._searchNameChange.bind(this);
    this._searchCodeChange = this._searchCodeChange.bind(this);
    this._searchTypeChange = this._searchTypeChange.bind(this);
  }

  componentDidUpdate(){
  }

  componentDidMount(){
      window.onpopstate = ()=> {
        this.props.updateMode('')
      }

  }

  componentWillMount(){
    this._getData(0)
  }

  _getData(skip){
    client.authenticate()
    .then((res)=>{
      let searchObj = this.state.searchObj
      let query = {
        query:{
           $sort: {
             //type:1,name:1,
             name:1,
             //createdAt: -1
           },
           $skip:skip,
           $limit:PAGESIZE,
        orRegex:this.state.searchObj,
         }
      }
      // _.assign(query.query,searchObj)
      return client.service('properties').find(query)
    })
    .then((res)=>{

      this.setState({
        total:res.total
      })
      this.props.fetchProperties(res.data)
    })
    .catch((err)=>{
      console.log(err);
    })
  }

  _handlePageChange(pagination, filters, sorter){
    this._getData((pagination.current * PAGESIZE)-PAGESIZE)
  }

  _searchNameChange(e){
    this.setState({
        searchName:e.target.value,
        searchObj:_.merge(this.state.searchObj,e.target.value?{name:e.target.value}:{name:'.'})
      },
    ()=>{

      this._getData(this.state.skip)
    })
  }

  _searchCodeChange(e){
    this.setState({
        searchCode:e.target.value,
        searchObj:_.merge(this.state.searchObj,e.target.value?{code:e.target.value}:{code:'.'})
      },
    ()=>{

      this._getData(this.state.skip)
    })
  }

  _searchTypeChange(e){
    this.setState({
        searchType:e.target.value,
        searchObj:_.merge(this.state.searchObj,e.target.value?{type:e.target.value}:{type:'.'})
      },
    ()=>{

      this._getData(this.state.skip)
    })
  }

  handleDelete(_id) {
     client.service('properties').remove(_id)
     .then((res)=>{
     })
     .catch((err)=>{
       alert(err);
     })
  }

  handleEdit(_id) {
    this.props.updateActiveProperty('edit',_id)
  }

  handleManage(_id) {
    this.props.updateActiveProperty('',_id)
  }

  showModal = () => {
    this.props.updateMode('new')
  }

  renderConfirmDeleteBtn(_id){
    return (
      <Popconfirm placement="topLeft" title={text} onConfirm={()=>this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
        <Button type="danger">Delete</Button>
      </Popconfirm>
    )
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title: (
          <Row gutter={8}>
            <Row><Col span={24}>Name</Col></Row>
            <Row><Col span={24}><Input value={this.state.searchName} onChange={(e)=>this._searchNameChange(e)}/></Col></Row>
          </Row>
        ),
        dataIndex: 'name',
        key: 'name',
        filteredValue:['j'],
      },
      {
        title: (
          <Row gutter={8}>
            <Row><Col span={24}>Code</Col></Row>
            <Row><Col span={24}><Input value={this.state.searchCode} onChange={(e)=>this._searchCodeChange(e)}/></Col></Row>
          </Row>
        ),
        dataIndex: 'code',
        key: 'code',
      },
      {
        title: (
          <Row gutter={8}>
            <Row><Col span={24}>Type</Col></Row>
            <Row><Col span={24}><Input value={this.state.searchType} onChange={(e)=>this._searchTypeChange(e)}/></Col></Row>
          </Row>
        ),
        dataIndex: 'type',
        key: 'type',
      }, {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
            <Link
              to={{
                pathname: `/property/view/${record._id}`
              }}
            >
            <Button>Manage</Button>
          </Link>
          <Divider type="vertical" />
          <Button onClick={()=>this.handleEdit(record._id)}>Edit</Button>
          <Divider type="vertical" />
          <Link
            to={{
              pathname: `/jmbs/${record._id}`
            }}
          >
            <Button>JMB Info</Button>
          </Link>
          {/*this.renderConfirmDeleteBtn(record._id)*/}
        </span>
      ),
    }];

    if(this.props.properties.mode === 'manage'){
      return <Redirect push from='/property/' to={{
            pathname:'/property/view',
            search:'?id:' + this.props.properties.activeProperty._id
          }}
        />
    }else{
      return(
        <Layout>
          <Card
            title="Properties"
            extra={<Button  type="primary" onClick={this.showModal}>New Property</Button>}
          >
            <PropertyForm />

            <Table
              dataSource={this.props.properties.properties}
              columns={columns}
              rowKey="_id"
              pagination={{total:this.state.total}}
              onChange={(e)=>this._handlePageChange(e)}
              />
          </Card>
        </Layout>
      )
    }
  }
}


function mapStateToProps(state) {
  return {
    user: state.user,
    properties: state.properties
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchProperties: fetchProperties,
    pushProperties: pushProperties,
    removeProperties: removeProperties,
    updateActiveProperty: updateActiveProperty,
    updateProperties: updateProperties,
    updateMode: updateMode
    }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(PropertyIndex)));
