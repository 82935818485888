export const FETCH_USERPROPERTIES = 'FETCH_USERPROPERTIES';
export const PUSH_USERPROPERTIES = 'PUSH_USERPROPERTIES';
export const REMOVE_USERPROPERTIES = 'REMOVE_USERPROPERTIES';
export const UPDATE_USERPROPERTIES = 'UPDATE_USERPROPERTIES';
export const UPDATE_MODE = 'UPDATE_MODE';
export const UPDATE_ACTIVE_ID = 'UPDATE_ACTIVE_ID';

export function fetchUserproperties(data){
  return (dispatch) => {
    dispatch({
      type: FETCH_USERPROPERTIES,
      payload:data
    });
  }
}

export function pushUserproperties(data){
  return (dispatch) => {
    dispatch({
      type: PUSH_USERPROPERTIES,
      payload:data
    });
  }
}

export function removeUserproperties(data){
  return (dispatch) => {
    dispatch({
      type: REMOVE_USERPROPERTIES,
      payload:data
    });
  }
}

export function updateUserproperties(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_USERPROPERTIES,
      payload:data
    });
  }
}

export function updateMode(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE,
      payload:data
    });
  }
}

export function updateActiveUserproperties(mode, id){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_ID,
      mode,
      id
    });
  }
}
