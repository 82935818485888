import React, { Component, useEffect } from 'react'
import { Modal, Upload, Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Select, InputNumber, Spin } from 'antd'
import client from '../../feathers'
import requireAuth from "../../components/requireAuth"
import Layout from "../../components/layout"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateMode } from '../../actions/actions-paymenttypeparams'
import axios from 'axios';
import params from '../../params/system'
var FormData = require('form-data');

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};
const text = 'Are you sure to delete this item?';
//----------------------------------------------------------------------------
class PaymentTypeParamsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',
      confirmDirty: false,
      autoCompleteResult: [],
      referenceformat: '',
      ledgerAccountList: [],
      formloading: false
    }

  }
  //----------------------------------------------------------------------------
  componentDidUpdate() {

    if (this.props.paymenttypeparams.activeId !== this.state._id) {
      this.setState({
        _id: this.props.paymenttypeparams.activeId
      })
      //

      if (this.props.paymenttypeparams.activePaymentTypeParams) {
        this.props.form.setFieldsValue({
          description: this.props.paymenttypeparams.activePaymentTypeParams.description,
          ledgerAccountId: this.props.paymenttypeparams.activePaymentTypeParams.ledgerAccountId._id,
          category: this.props.paymenttypeparams.activePaymentTypeParams.category,
        })
      }
    }

  }

  //----------------------------------------------------------------------------
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ formloading: true })
    if (this.props.paymenttypeparams.mode === 'new') {

      this.props.form.validateFields((err, values) => {
        if (!err) {
          client.service('payment-type-params').create({
            ...values,
            propertyId: this.props.commons.selectedCompany._id,
          })
            .then((res) => {
              this.setState({ formloading: false })
              this.props.updateMode('refresh')
              message.success('New Record added');
            })
            .catch((err) => {
              this.props.updateMode('')
              this.setState({ formloading: false })
              alert(err);
            })
        }
      });
    } else {
      this.props.form.validateFields((err, values) => {
        if (!err) {

          client.service('payment-type-params').patch(this.props.paymenttypeparams.activeId,
            values,

          )
            .then((res) => {
              this.setState({ formloading: false })
              message.success(`Updated`);
              this.props.updateMode('refresh')
            })
            .catch((err) => {
              this.setState({ formloading: false })
              this.props.updateMode('')
            })
          return;
        }

      });
    }
  }
  //----------------------------------------------------------------------------
  //----------------------------------------------------------------------------
  componentWillMount() {


    client.authenticate()
      .then((res) => {

        return client.service('ledger-accounts').find({

          query: {
            companyId: this.props.commons.selectedCompany._id,
            // categories:'ASSETS',
            accountNo: { $nin: '3000' },
            $sort: { description: 1 }
          }
        })
      })
      .then((res) => {

        this.setState({
          ledgerAccountList: res.data
        })
      })
      .catch((err) => {
        this.setState({
          ledgerAccountList: []
        })
      })
  }

  //----------------------------------------------------------------------------
  render() {
    const { visible, onCancel } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { referenceformat } = this.state;

    return (
      <Spin spinning={this.state.formloading}>
        <Modal
          visible={
            this.props.paymenttypeparams.mode === 'new' || this.props.paymenttypeparams.mode === 'edit' ? true : false
          }
          title={this.props.paymenttypeparams.mode === 'edit' ? 'Update Parameter' : 'Create Parameter'}
          okText={this.props.paymenttypeparams.mode === 'edit' ? 'Update' : 'Create'}
          onCancel={() => { this.props.updateMode(''); this.props.form.resetFields() }}
          onOk={this.handleSubmit}
        >
          <Form className="submit-form">

            <FormItem
              {...formItemLayout}
              label="Decription"
            >
              {getFieldDecorator('description', {
                rules: [{
                  required: true, message: 'Please input description!',
                }],
              })(
                <Input />
              )}
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Account"
            >
              {getFieldDecorator('ledgerAccountId', {
                rules: [{
                  required: true, message: 'Please input Format !',
                }],
              })(
                <Select
                  showSearch
                  allowClear
                  optionFilterProp='children'
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}

                >
                  {this.state.ledgerAccountList.map(dropdown => (
                    <Option key={dropdown._id} value={dropdown._id} description={dropdown.accountName} >
                      {dropdown.accountName}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>


            <FormItem
              {...formItemLayout}
              label="Category"
            >
              {getFieldDecorator('category', {
                rules: [{
                  required: false, message: 'Please Select category!',
                }],
              })(


                <Select
                  id='category'
                  placeholder="Select Type">
                  <Option value="INVOICE">INVOICE</Option>
                  <Option value="SUPPLIERPAYMENT">SUPPLIER PAYMENT</Option>
                  <Option value="REFUND">REFUND</Option>
                  <Option value="DEPOSIT">DEPOSIT</Option>
                  <Option value="DEBITNOTES">DEBIT NOTES </Option>
                  <Option value="CREDITNOTES">CREDIT NOTES </Option>
                  <Option value="SUPPLIERDEBITNOTES">SUPPLIER DEBIT NOTES </Option>
                  <Option value="SUPPLIERCREDITNOTES">SUPPLIER CREDIT NOTES </Option>
                </Select>
              )}
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Category"
            >
              {getFieldDecorator('specialCode', {
                rules: [{
                  required: false, message: 'Please Select category!',
                }],
              })(


                <Select
                  id='category'
                  placeholder="Select Type">
                  <Option value="CASH">Cash</Option>
                  <Option value="INTERNETBANKING">Internet Banking</Option>
                  <Option value="BANK">Bank</Option>

                </Select>
              )}
            </FormItem>


          </Form>

        </Modal>
      </Spin>
    )
  }
}

//----------------------------------------------------------------------------
function mapStateToProps(state) {
  return {
    paymenttypeparams: state.paymenttypeparams,
    commons: state.commons
  }
}
//----------------------------------------------------------------------------
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
  }, dispatch);
}

//----------------------------------------------------------------------------

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(PaymentTypeParamsForm)));
