export const FETCH_PINBOARD = 'FETCH_PINBOARD';
export const PUSH_PINBOARD= 'PUSH_PINBOARD';
export const REMOVE_PINBOARD= 'REMOVE_PINBOARD';
export const UPDATE_PINBOARD = 'UPDATE_PINBOARD';
export const UPDATE_MODE = 'UPDATE_MODE';
export const UPDATE_ACTIVE_ID = 'UPDATE_ACTIVE_ID';

export function fetchPinboard(data){
  return (dispatch) => {
    dispatch({
      type: FETCH_PINBOARD,
      payload:data
    });
  }
}

export function pushPinboard(data){
  return (dispatch) => {
    dispatch({
      type: PUSH_PINBOARD,
      payload:data
    });
  }
}

export function removePinboard(data){
  return (dispatch) => {
    dispatch({
      type: REMOVE_PINBOARD,
      payload:data
    });
  }
}

export function updatePinboard(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_PINBOARD,
      payload:data
    });
  }
}

export function updatePinboardMode(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE,
      payload:data
    });
  }
}

export function updateActivePinboard(mode, id){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_ID,
      mode,
      id
    });
  }
}
