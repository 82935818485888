import React, {Component} from 'react';
import {
  Popconfirm,
  message,
  Divider,
  Table,
  Form,
  Icon,
  Input,
  Button,
  Card,
  Modal,
  Spin,
  Col,
  Row,
  Select,
  DatePicker
} from 'antd';
import {Route, Link} from 'react-router-dom';

import client from '../../feathers';
import requireAuth from "../../components/requireAuth";
import Layout from "../../components/layout";
import LedgerIndexTransactionsByReference from "../../components/ledgers/index-transactions-by-reference";
import moment from "moment";
import axios from 'axios';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import {
  fetchPaymentVouchers,
  pushPaymentVouchers,
  removePaymentVouchers,
  updateActivePaymentVouchers,
  updateMode,
  updatePaymentVouchers
} from '../../actions/actions-paymentvouchers';

const FormItem = Form.Item;
const Option = Select.Option;
const text = 'Are you sure to delete this item?';

const formItemLayout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 16
  }
};

const config = {
    // initialValue:moment('date'),
    // defaultValue:moment('date'),
    value:moment('2019-01-02'),
  rules: [
    {
      type: 'object',
      required: true,
      message: 'Please select date!'
    }
  ]
};

class ReceiptsIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      img: '',
      datasource: [],
      fromPaymentVoucher: 'YES',
      loading: false,
      bankledgerAccountList: []
    }

  }

  componentDidUpdate() {

        if (this.props.paymentvouchers.activeId === this.state._id) {
          this.props.paymentvouchers.activePaymentVouchers.date = moment(this.props.paymentvouchers.activePaymentVouchers.date)
        }

    if (this.props.paymentvouchers.activeId !== this.state._id) {
      this.setState({_id: this.props.paymentvouchers.activeId})

      if (this.props.paymentvouchers.activePaymentVouchers) {


        this.props.paymentvouchers.activePaymentVouchers.date = moment(this.props.paymentvouchers.activePaymentVouchers.date)

        this.props.form.setFieldsValue(this.props.paymentvouchers.activePaymentVouchers)
      }
    }
  }

  componentDidMount() {

    client.service('paymentvouchers').on('patched', (receipts) => {
      if(receipts.propertyId===sessionStorage.getItem('companyId')){
        this.componentWillMount();
      }
    })

  }

  componentWillMount() {


    this.setState({loading: true});
    client.authenticate().then((res) => {

      return client.service('paymentvouchers').find({
        query: {
          propertyId: sessionStorage.getItem('companyId'),
          $populate: [
            {
              path: 'supplierId'
            }, {
              path: 'creditledgerAccountId'
            }, {
              path: 'debitledgerAccountId'
            }, {
              path: 'receiptId',
              populate: [
                {
                  path: 'debitledgerAccountId creditledgerAccountId'
                }
              ]
            }
          ],
          paymentvouchermasterId: this.props.match.params.id,
          $sort: {
            createdAt: -1
          }
        }
      })
    }).then((res) => {



      this.setState({datasource: res.data, loading: false});
      this.props.fetchPaymentVouchers(res.data)

      return client.service('payment-type-params').find({
        query: {
          propertyId: sessionStorage.getItem('companyId'),
          category: 'SUPPLIERPAYMENT',
          $sort: {
            description: 1
          }
        }

      }).then((res) => {
        this.setState({bankledgerAccountList: res.data})

      })
    }).catch((err) => {
      console.log(err);
      this.setState({loading: false});
    })
  }

  handleEdit(record) {
    this.props.updateActivePaymentVouchers('edit', record._id)
    this.props.form.setFieldsValue(this.props.paymentvouchers.activePaymentVouchers)
    this.setState({visible: true});
  }

  handleDelete(record) {

    axios.post(`${client.io.io.uri}removeTrx`, {
      _id: record._id,
      companyId: sessionStorage.getItem('companyId'),
      trxType: 'PVD',
      receiptId:record.receiptId,
      paymentsVoucherMasterId:record.paymentsVoucherMasterId,
    }, {
      headers: {
        'Authorization': client.settings.storage.storage['feathers-jwt']
      }
    }).then((res) => {
      message.success('Deleted');
    }).catch((err) => {
      console.log(err);
    })

  }

  showModal = () => {
    this.props.updateMode('new')
  }

  handleOk = (e) => {

    this.props.form.validateFields((err, values) => {


      values.date = moment(values.date).format('YYYY-MM-DD')
      values.creditledgerAccountId = values.creditledgerAccountId._id

      client.service('paymentvouchers').patch(
        this.props.paymentvouchers.activeId,
        {
      ...values,
      // receiptId: this.props.paymentvouchers.activePaymentVouchers.receiptId._id,
      // paymentvouchermasterId:this.props.paymentvouchers.activePaymentVouchers.paymentvouchermasterId,
       propertyId:sessionStorage.getItem('companyId'),
     }).then(res => {message.success('Record Updated.')})

    })
    this.props.updateActivePaymentVouchers('', '')
    this.setState({visible: false});
  }

  handleCancel = (e) => {
    this.props.updateActivePaymentVouchers('', '')

    this.setState({visible: false});
  }

  renderConfirmDeleteBtn(record) {
    // return (<Popconfirm placement="topLeft" title={text} onConfirm={() => this.handleDelete(record)} okText="Confirm" cancelText="Cancel">
    //   <Button type="danger">Delete</Button>
    // </Popconfirm>)
  }

  _paymentDateChange=(e)=>{

    this.setState({
      date:moment(new Date(e)).format("YYYY-MM-DD")
    });
  }

  render() {
    const {getFieldDecorator} = this.props.form;
    const {datasource, fromPaymentVoucher} = this.state;

    const columns = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        width: '10%'
      }, {
        title: 'Receipt No',
        dataIndex: 'receiptId.receiptNo',
        key: 'receiptNo',
        width: '10%'
      }, {
        title: 'Debiting Account',
        dataIndex: 'debitledgerAccountId.accountName',
        key: 'debitledgerAccountId.accountName._id',
        width: '10%'
      }, {
        title: 'Crediting Account',
        dataIndex: 'creditledgerAccountId.accountName',
        key: 'creditledgerAccountId.accountName._id',
        width: '12%'
      }, {
        title: 'Reference',
        dataIndex: 'referenceId',
        key: 'referenceId',
        width: '10%'
      }, {
        title: 'Receipt Desc.',
        dataIndex: 'receiptId.remark',
        key: 'receiptId.remark',
        width: '10%'
      }, {
        title: 'PV Desc.',
        dataIndex: 'remark',
        key: 'remark',
        width: '10%'
      }, {
        title: (<span style={{
            float: 'right'
          }}>Amount
        </span>),
        dataIndex: 'paymentamount',
        key: 'totalAmount',
        width: '10%',
        render: (text, record) => (<span style={{
            float: 'right'
          }}>
          {record.paymentamount.toFixed(2)}
        </span>)
      }, {
        title: 'Action',
        key: 'action',
        width: '18%',
        render: (text, record) => (<span>
          <Button key='navigatePVDetails' onClick={() => this.handleEdit(record)}>Edit</Button>
          <Divider type="vertical"/> {this.renderConfirmDeleteBtn(record)}

        </span>)
      }
    ];

    return (<Layout>
      <Spin spinning={this.state.loading} key='spinning'>
        <Card title='Payment Voucher' extra={[<Button key='navigate' type='primary'>
            <Link to={{
                pathname: `/suppliers/`,
                state: 'PV'
              }}>New</Link>

          </Button>
            ]}>
          <Table dataSource={datasource} columns={columns} rowKey="_id"/>

        </Card>

        <Modal title="Edit Payment Voucher"
        visible={this.state.visible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        >

          <Form className="submit-form">

            <Row>
              <Col span={24}>

          {/*      <Form.Item key='date' {...formItemLayout} label="Payment Date">
                  {getFieldDecorator('date', config)
                  (
                    <DatePicker onChange={this._paymentDateChange}/>
                  )}
                </Form.Item>
          */}

                <FormItem key='creditledgerAccountId' {...formItemLayout} label="Pay by">
                  {
                    getFieldDecorator('creditledgerAccountId._id', {
                      rules: [
                        {
                          required: true,
                          message: 'Please select Payment Type!'
                        }
                      ]
                    })(<Select>
                      {
                        this.state.bankledgerAccountList.map(dropdown => (<Option key={dropdown.ledgerAccountId} value={dropdown.ledgerAccountId} description={dropdown.description}>
                          {dropdown.description}
                        </Option>))
                      }
                    </Select>)
                  }
                </FormItem>

                <FormItem {...formItemLayout} label="Amount">
                  {
                    getFieldDecorator('paymentamount', {
                      rules: [
                        {
                          required: true,
                          message: 'Please input Amount!'
                        }
                      ]
                    })(<Input/>)
                  }
                </FormItem>
{/*
                <FormItem {...formItemLayout} label="Description">
                  {
                    getFieldDecorator('remark', {
                      rules: [
                        {
                          required: true,
                          message: 'Please input Description!'
                        }
                      ]
                    })(<Input/>)
                  }
                </FormItem>

                */}

              </Col>
            </Row>
          </Form>
        </Modal>

      </Spin>
    </Layout>)
  }
}

function mapStateToProps(state) {
  return {paymentvouchers: state.paymentvouchers}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({

    updateMode: updateMode,
    fetchPaymentVouchers: fetchPaymentVouchers,
    pushPaymentVouchers: pushPaymentVouchers,
    removePaymentVouchers: removePaymentVouchers,
    updateActivePaymentVouchers: updateActivePaymentVouchers
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(ReceiptsIndex)));
