export const FETCH_ACCOUNTS = 'FETCH_ACCOUNTS';
export const PUSH_ACCOUNTS = 'PUSH_ACCOUNTS';
export const REMOVE_ACCOUNTS = 'REMOVE_ACCOUNTS';
export const UPDATE_ACCOUNTS = 'UPDATE_ACCOUNTS';
export const UPDATE_MODE = 'UPDATE_MODE';
export const UPDATE_ACTIVE_ID = 'UPDATE_ACTIVE_ID';

export function fetchAccounts(data){
  return (dispatch) => {
    dispatch({
      type: FETCH_ACCOUNTS,
      payload:data
    });
  }
}

export function pushAccounts(data){
  return (dispatch) => {
    dispatch({
      type: PUSH_ACCOUNTS,
      payload:data
    });
  }
}

export function removeAccounts(data){
  return (dispatch) => {
    dispatch({
      type: REMOVE_ACCOUNTS,
      payload:data
    });
  }
}

export function updateAccounts(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACCOUNTS,
      payload:data
    });
  }
}

export function updateMode(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE,
      payload:data
    });
  }
}

export function updateActiveAccounts(mode, id){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_ID,
      mode,
      id
    });
  }
}
