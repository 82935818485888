export const FETCH_REFUNDS = 'FETCH_REFUND';
export const PUSH_REFUNDS = 'PUSH_REFUND';
export const REMOVE_REFUNDS = 'REMOVE_REFUND';
export const UPDATE_REFUNDS = 'UPDATE_REFUND';
export const UPDATE_MODE = 'UPDATE_MODE';
export const UPDATE_ACTIVE_ID = 'UPDATE_ACTIVE_ID';

export function fetchRefunds(data){
  return (dispatch) => {
    dispatch({
      type: FETCH_REFUNDS,
      payload:data
    });
  }
}

export function pushRefunds(data){
  return (dispatch) => {
    dispatch({
      type: PUSH_REFUNDS,
      payload:data
    });
  }
}

export function removeRefunds(data){
  return (dispatch) => {
    dispatch({
      type: REMOVE_REFUNDS,
      payload:data
    });
  }
}

export function updateRefunds(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_REFUNDS,
      payload:data
    });
  }
}

export function updateMode(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE,
      payload:data
    });
  }
}

export function updateActiveRefunds(mode, id){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_ID,
      mode,
      id
    });
  }
}
