import React, { Component } from 'react'
import { Modal, message, Form, Icon, Input, Select, Alert, Row, Col, Spin, Card, Button, Table } from 'antd'
import client from '../../feathers'
import requireAuth from "../requireAuth"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateMode, fetchUsers, updateActiveUser } from '../../actions/users-actions'
import Layout from "../layout"
import { SkypeOutlined } from '@ant-design/icons'

const _ = require('lodash');
const FormItem = Form.Item;
const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const Option = Select.Option;


class SecurityUsersForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            searchEmail: '',
            searchobj: {},
            skip: 0,

        }
        this._searchEmailChange = this._searchEmailChange.bind(this);
    }

    componentWillMount() {
        this._getData(0)

    }
    componentDidUpdate() {
        if (this.props.users.activeId !== this.state._id) {
            this.setState({
                _id: this.props.users.activeId
            })

            if (this.props.users.activeUser) {

                this.props.form.setFieldsValue(
                    this.props.users.activeUser
                )
            }
        }
        if (this.props.users.mode === 'refresh') {
            // console.log('been here')
            this.componentWillMount()
            this.props.updateMode('')
        }

    }

    _getData(skip) {


        client.authenticate()
            .then((res) => {
                return client.service('users').find({
                    query: {
                        role: 'guard',
                        propertyId: this.props.commons.selectedCompany._id,
                        $sort: {
                            createdAt: -1
                        },
                        $skip: skip,
                        orRegex: this.state.searchObj
                    }
                })
            })
            .then((res) => {
                this.props.fetchUsers(res.data)
            })
            .catch((err) => {
                console.log(err);
            });

    }
    validateToNextPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    }

    compareToFirstPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('password')) {
            callback('Two passwords that you enter is inconsistent!');
        } else {
            callback();
        }
    }
    _searchEmailChange(e) {
        this.setState({
            searchEmail: e.target.value,
            searchObj: _.merge(this.state.searchObj, e.target.value ? { email: e.target.value } : { email: '.' }),
            typingTimeout: setTimeout(() => {
                this._getData(this.state.skip)
            }, 500)
        })
    }
    handleEdit(_id) {
        this.props.updateActiveUser('edit', _id)
        this.setState({ showModal: true })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.props.users.mode === 'new') {
            let queryProperty = ''

            this.props.form.validateFields((err, values) => {
                values.username = values.email
                try {
                    queryProperty = this.props.commons.selectedCompany._id
                }
                catch (err) {
                    console.log(err)
                }


                const fieldValues = { ...values, role: 'guard', propertyId: queryProperty };
                if (!err) {
                    client.service('users').create(fieldValues)
                        .then((res) => {
                            this.props.updateActiveUser('refresh', '')
                            message.success('New Guard added');
                            this.setState({ showModal: false })
                        })
                        .catch((err) => {
                            this.props.updateMode('refresh')
                            alert(err);
                        })
                }
            });
        } else {
            this.props.form.validateFields((err, values) => {
                values.username = values.email
                if (!err) {
                    client.service('users').patch(this.props.users.activeId,
                        values
                    )
                        .then((res) => {
                            message.success(`User updated`);
                            this.props.updateActiveUser('refresh', '')
                            this.setState({ showModal: false })
                        })
                        .catch((err) => {

                            this.props.updateMode('refresh')
                        })
                    return;
                }

            });
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { showModal } = this.state

        const columns = [{
            title: (
                <Row gutter={8}>
                    <Row><Col span={24}>Email</Col></Row>
                    <Row><Col span={24}><Input value={this.state.searchEmail} onChange={this._searchEmailChange} /></Col></Row>
                </Row>
            ),
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: (

                <Row><Col span={24}>Role</Col></Row>
            ),
            dataIndex: 'role',
            key: 'role',
            render: (data, record) => {
                // data = data.charAt(0).toUpperCase() + data.slice(1)
                data = _.map(data, function (a) {
                    a = a.charAt(0).toUpperCase() + a.slice(1);
                    return a;

                })
                return (
                    <span>{data}</span>
                )


            }

        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <span>
                    {/*<Link
                  to={{
                    pathname: `/users/view/${record._id}`
                  }}
                >
                <Button>Manage</Button>
                </Link>*/}
                    {/* <Divider type="vertical" /> */}
                    <Button onClick={() => this.handleEdit(record._id)}>Edit</Button>
                    {/* <Divider type="vertical" /> */}
                    {/*this.renderConfirmDeleteBtn(record._id)*/}
                </span>
            ),
        }];

        return (
            <Layout>

                {/* <Spin spinning={this.state.loading}> */}
                <Card title='Guard Management '
                    extra={<Button type="primary" onClick={() => { this.setState({ showModal: true }); this.props.updateMode('new') }}>New Security</Button>}>
                    {/*  {<Button type="primary" onClick={this.showModals}>New Complaints</Button>} */}
                    {/* {renderComplaintsForm} */}

                    <Table dataSource={this.props.users.users} columns={columns} rowKey="_id" />

                    {/* <ComplaintsForm /> */}
                </Card>
                {/* </Spin> */}

                <Modal
                    visible={showModal}
                    title={this.props.users.mode === 'edit' ? "Update User" : "Create User"}
                    okText={this.props.users.mode === 'edit' ? 'Update' : 'Create'}
                    onCancel={() => { this.setState({ showModal: false }); this.props.updateMode(''); this.props.form.resetFields() }}
                    onOk={this.handleSubmit}

                >
                    <Form className="submit-form">
                        <FormItem
                            {...formItemLayout}
                            label="Email"
                        >
                            {getFieldDecorator('email', {
                                rules: [{
                                    type: 'string', message: 'The input is not valid Username!',
                                }, {
                                    required: true, message: 'Please input your email!',
                                }],
                            })(
                                <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="E-mail" />
                            )}
                        </FormItem>

                        <FormItem
                            {...formItemLayout}
                            label="Password"
                        >
                            {getFieldDecorator('password', {
                                rules: [{
                                    required: true, message: 'Please input your password!',
                                }, {
                                    validator: this.validateToNextPassword,
                                }],
                            })(

                                <Row gutter={8}>
                                    <Row><Col span={24}><Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Password" type="password" /></Col></Row>
                                    <Row style={{ lineHeight: '10px', fontSize: 12, fontStyle: 'italic', color: '#a9b3ad' }} ><Col span={24}>Password length must more than 8.</Col></Row>
                                    <Row style={{ lineHeight: '10px', fontSize: 12, fontStyle: 'italic', color: '#a9b3ad' }}><Col span={24}>Password must contain Uppercase.</Col></Row>
                                    <Row style={{ lineHeight: '10px', fontSize: 12, fontStyle: 'italic', color: '#a9b3ad' }}><Col span={24}>Password must contain digits.</Col></Row>
                                </Row>
                            )}
                        </FormItem>

                        <FormItem
                            {...formItemLayout}
                            label="Confirm Password"
                        >
                            {getFieldDecorator('confirm', {
                                rules: [{
                                    required: true, message: 'Please confirm your password!',
                                }, {
                                    validator: this.compareToFirstPassword,
                                }],
                            })(
                                <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Confirm Password" type="password" onBlur={this.handleConfirmBlur} />
                            )}
                        </FormItem>

                        <FormItem
                            {...formItemLayout}
                            label="First Name"
                        >
                            {getFieldDecorator('firstName', {
                                rules: [{
                                    required: false, message: 'Please confirm your First Name!',
                                }],
                            })(
                                <Input />
                            )}
                        </FormItem>

                        <FormItem
                            {...formItemLayout}
                            label="Last Name"
                        >
                            {getFieldDecorator('lastName', {
                                rules: [{
                                    required: false, message: 'Please confirm your Last Name!',
                                }],
                            })(
                                <Input />
                            )}
                        </FormItem>

                        {/* <FormItem
                            {...formItemLayout}
                            label="Select Management"
                            hasFeedback
                        >
                            {getFieldDecorator('managementId', {
                                rules: [
                                    { required: false, message: 'Please select Management!' },
                                ],
                            })(
                                <Select placeholder="Please select Management" onChange={this.handleChange}>
                                    {this.state.managementdropdowns.map(managementdropdown => (
                                        <Option value={managementdropdown._id}>
                                            {managementdropdown.name}
                                        </Option>
                                    ))}
                                </Select>
                            )}
                        </FormItem> */}

                    </Form>
                </Modal>
            </Layout>
        )

    }








}
function mapStateToProps(state) {
    return {
        users: state.users,
        commons: state.commons
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateMode: updateMode,
        fetchUsers: fetchUsers,
        updateActiveUser: updateActiveUser
    }, dispatch);
}


export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(SecurityUsersForm)));