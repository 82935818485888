import React from "react";

const SvgLightbulb = props => (
  <svg height={512} viewBox="-104 0 480 480" width={512} {...props}>
    <path
      d="M168 376h-8a8 8 0 000 16h8a8 8 0 000-16zm0 0"
      data-original="#000000"
      className="lightbulb_svg__active-path"
      data-old_color="#000000"
      fill="#8D99AE"
    />
    <path
      d="M60.594 272.336c6.574 13.047 3.199 45.969.398 63.664H24a8 8 0 00-8 8v80a8 8 0 008 8h16.64c3.973 27.527 27.547 47.969 55.36 48h72c27.813-.031 51.387-20.473 55.36-48H248a8 8 0 008-8v-80a8 8 0 00-8-8h-37c-3.305-20.734-5.602-51.734.406-63.664 5.547-10.992 12.602-22.64 20.09-34.977C251.406 204.504 272 170.527 272 137.23 272 64.13 208.45 0 136 0S0 64.129 0 137.23c0 33.297 20.594 67.274 40.504 100.13 7.496 12.335 14.543 23.984 20.09 34.976zM168 464H96c-19-.02-35.375-13.387-39.2-32h150.4c-3.825 18.613-20.2 31.98-39.2 32zm72-48H32v-24h96a8 8 0 000-16H32v-24h208zM136 232c-22.09 0-40-17.91-40-40s17.91-40 40-40 40 17.91 40 40c-.027 22.082-17.918 39.973-40 40zm-8 15.36c5.3.855 10.7.855 16 0V336h-16zM136 16c63.93 0 120 56.648 120 121.23 0 28.801-19.414 60.868-38.191 91.84-7.641 12.61-14.864 24.516-20.688 36.067-8.848 17.55-4.945 53.383-2.305 70.863H160v-93.48c23.66-11.223 36.383-37.348 30.633-62.895-5.754-25.55-28.446-43.703-54.633-43.703s-48.879 18.152-54.633 43.703c-5.75 25.547 6.973 51.672 30.633 62.895V336H77.184c2.64-17.48 6.543-53.313-2.305-70.863-5.824-11.551-13.047-23.457-20.688-36.067C35.414 198.098 16 166.062 16 137.23 16 72.648 72.07 16 136 16zm0 0"
      data-original="#000000"
      className="lightbulb_svg__active-path"
      data-old_color="#000000"
      fill="#8D99AE"
    />
    <path
      d="M136 112a8 8 0 008-8V56a8 8 0 00-16 0v48a8 8 0 008 8zm0 0M178.977 119.113a7.946 7.946 0 004.8-1.601l34.399-25.778c3.535-2.648 4.25-7.664 1.601-11.199a8.002 8.002 0 00-11.203-1.597l-34.398 25.773a8 8 0 00-2.79 8.93 7.998 7.998 0 007.59 5.472zm0 0M87.375 116.8a8 8 0 0011.313-11.312L72.91 79.703a7.997 7.997 0 00-7.867-2.394 7.999 7.999 0 00-3.441 13.683zm0 0M197.152 148a7.997 7.997 0 0010.121 5.063l25.774-8.59a8.012 8.012 0 005.074-10.13c-1.398-4.195-5.934-6.468-10.129-5.07l-25.777 8.582A8.002 8.002 0 00197.152 148zm0 0M67.258 153.488a8 8 0 002.527-15.601L44 129.305c-4.2-1.399-8.73.875-10.129 5.07-1.394 4.2.875 8.734 5.074 10.129l25.774 8.594a8 8 0 002.539.39zm0 0"
      data-original="#000000"
      className="lightbulb_svg__active-path"
      data-old_color="#000000"
      fill="#8D99AE"
    />
  </svg>
);

export default SvgLightbulb;
