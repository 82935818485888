export const FETCH_COMPLAINTS = 'FETCH_COMPLAINTS';
export const PUSH_COMPLAINTS = 'PUSH_COMPLAINTS';
export const REMOVE_COMPLAINTS = 'REMOVE_COMPLAINTS';
export const UPDATE_COMPLAINTS = 'UPDATE_COMPLAINTS';
export const UPDATE_MODE = 'UPDATE_MODE';
export const UPDATE_COMPLAINT_ACTIVE_ID = 'UPDATE_COMPLAINT_ACTIVE_ID';

export function fetchComplaints(data){
  return (dispatch) => {
    dispatch({
      type: FETCH_COMPLAINTS,
      payload:data
    });
  }
}

export function pushComplaints(data){
  return (dispatch) => {
    dispatch({
      type: PUSH_COMPLAINTS,
      payload:data
    });
  }
}

export function removeComplaints(data){
  return (dispatch) => {
    dispatch({
      type: REMOVE_COMPLAINTS,
      payload:data
    });
  }
}

export function updateComplaints(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_COMPLAINTS,
      payload:data
    });
  }
}

export function updateMode(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE,
      payload:data
    });
  }
}

export function updateActiveComplaints(mode, id){
  return (dispatch) => {
    dispatch({
      type: UPDATE_COMPLAINT_ACTIVE_ID,
      mode,
      id
    });
  }
}
