import React, { Component } from 'react'
import { Avatar, Row, Col, Card, Popconfirm, message, Divider,  Table, Form, Icon, Input, Button } from 'antd'
import client from '../../feathers'
import requireAuth  from "../../components/requireAuth"
import Layout  from "../../components/layout"
import Info  from "../../components/common/info"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  fetchManagements,
  pushManagements,
  removeManagements,
  updateMode,
  updateActiveManagement,
  updateManagement } from '../../actions/managements-actions'

const FormItem = Form.Item;

class ManagementView extends Component {
  constructor(props) {
      super(props);

      this.state = {
        management: []
      }
  }

  componentWillMount(){
    client.authenticate()
    .then(()=>{
      return client.service('management').find({
        query: {
            _id:this.props.match.params.id,
         }
      })
    })
    .then((res)=>{
      this.setState({
        management:res.data[0]
      })

    })
    .catch((err)=>{
      console.log(err);
    })
  }

  componentDidMount(){
      client.service('management').on('updated', (managements) => {
        this.props.updateManagement(managements)
      })
  }

  render() {
    let initial = this.state.management.email?this.state.management.email.toString().split("@"):'';
    return(
      <Layout>
        <Row gutter={8}>
          <Col span={8}>
            <Card title="Management Info">
              <Avatar style={{ margin:'0 35% 10% 35% ', backgroundColor: '#87cefa', verticalAlign: 'middle' }} size={86}>
                {initial[0]}
              </Avatar>
              <Info label="Email" labelSpan={6}  value={this.state.management.email} valueSpan={18} />
              <Info label="Role" labelSpan={6}  value={this.state.management.role} valueSpan={18} />

              <Divider dashed >Detail</Divider>

            </Card>
          </Col>

          <Col span={16}>
            <Row gutter={8}>
              <Card title="Management">
              </Card>
            </Row>
            <Row gutter={8}>
              <Card title="Property">
              </Card>
            </Row>
          </Col>
        </Row>
      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    management: state.management
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchManagements: fetchManagements,
    pushManagements: pushManagements,
    removeManagements: removeManagements,
    updateActiveManagement: updateActiveManagement,
    updateManagement: updateManagement,
    updateMode: updateMode
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagementView);
