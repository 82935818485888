import _ from 'lodash';
import {
  FETCH_PASSES,
  PUSH_PASSES,
  REMOVE_PASSES,
  UPDATE_PASSES,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID } from '../actions/actions-passes';

const INITIAL_STATE = {
  passes: [],
  mode: 'index',
  activeId: '',
  activePasses:{}
}


export default function(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_PASSES:
      return {
        ...state,
        passes:action.payload,
      }
    case PUSH_PASSES:
      return {
        ...state,
        passes:[action.payload, ...state.passes],
      }
    case REMOVE_PASSES:
      return {
        ...state,
        passes : [...state.passes.filter( (item) => item._id !== action.payload._id)]
      }
    case UPDATE_PASSES:
      let index =  _.findIndex(state.passes, { '_id': action.payload._id })
      state.passes.splice(index, 1, action.payload)
      return {
        ...state,
        passes : state.passes
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode : action.payload,
        activeId:''
      }
    case UPDATE_ACTIVE_ID:
      return {
        ...state,
        mode : action.mode,
        activeId:action.id,
        activePasses: _.pick(_.find(state.passes, { '_id': action.id }), ['file', 'name', 'description', 'mobile', 'email', 'location', 'status', 'type', '_id'])
      }
    default:
      return state

  }
}
