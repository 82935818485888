import React, { Component } from 'react';
import {
  Popconfirm,
  message,
  Divider,
  Table,
  Form,
  Icon,
  Input,
  Button,
  Card,
  Row,
  Col,
  Spin,
  DatePicker,
  Popover,
} from 'antd';
import { Link } from 'react-router-dom';
import client from '../../feathers';
import requireAuth from "../../components/requireAuth";
import Layout from "../../components/layout";
import CreditNotesForm from "../../components/creditnotes-supplier/form";
import moment from 'moment';
import axios from 'axios';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {

  fetchCreditNotes,
  pushCreditNotes,
  removeCreditNotes,
  updateMode,
  updateActiveCreditNotes,
  updateCreditNotes
} from '../../actions/actions-creditnotes';
import FormCN from "../../components/receipts/form-modal-cn";

const _ = require('lodash');
const PAGESIZE = 20;
const text = 'Are you sure to delete this item?';

class CreditNotesIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      totalPagination: 1,
      CurrentPagination: 1,
      searchReferenceNo: '',
      searchStatus: '',
      searchDate: '',
      dtAccountList: [],
      ctAccountList: [],
      _id: '',
    }

    this.handleDelete = this.handleDelete.bind(this);
    this._searchReferenceNoChange = this._searchReferenceNoChange.bind(this);
    this._searchDateChange = this._searchDateChange.bind(this);
  }

  componentDidUpdate() {

    if (this.props.creditnotes.mode === 'refresh') {
      this.componentWillMount()
    }
  }

  componentDidMount() { }

  componentWillMount() {

    this.props.updateMode('')
    this.setState({ loading: true });

    client.authenticate().then((res) => {
      this._getData(0)

    })

    this.getAccountList()
  }

  handleDelete(_id) {
    client.service('receipt-items').remove(_id)
  }

  handleEdit(_id) {
    this.props.updateActiveCreditNotes('edit', _id)

  }

  handleManage(_id) {
    this.props.updateActiveCreditNotes('', _id)
  }

  showModal = () => {
    this.props.updateMode('new')

  }

  renderConfirmDeleteBtn(_id) {
    return (<Popconfirm placement="topLeft" title={text} onConfirm={() => this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
      <Button size='small' type="danger">Delete</Button>
    </Popconfirm>)
  }

  _searchReferenceNoChange(e) {
    this.setState({
      searchReferenceNo: e.target.value,
      searchObj: _.merge(
        this.state.searchObj, e.target.value
        ? {
          reference: e.target.value
        }
        : {
          referenceId: '.'
        }),
      typingTimeout: setTimeout(() => {
        this._getData(this.state.skip)
      }, 500)
    })
  }



  _searchDateChange(e) {
    let trxDate = new Date(e)

    this.setState({
      searchDate: moment(trxDate),

      typingTimeout: setTimeout(() => {
        this._getData(this.state.skip)
      }, 500)
    })
  }

  _getData(skip) {
    this.setState({
      loading: true,
    });
    client.authenticate().then(() => {
      let searchObj = this.state.searchObj
      let queryString = ''

      if (this.state.searchDate !== '') {
        queryString = {
          date: {
            $gte: new Date(moment(new Date(this.state.searchDate)).startOf('day')),
            $lte: new Date(moment(new Date(this.state.searchDate)).endOf('day')),
          }
        }
      }

      let query = {
        query: {
          propertyId: this.props.commons.selectedCompany._id,
          $populate: 'debitledgerAccountId creditledgerAccountId receiptId',
          receiptType: { $in: ['1'] },
          $sort: {
            createdAt: -1
          },
          $limit: PAGESIZE,
          ...queryString,
          orRegex: this.state.searchObj
        }
      }
      _.assign(query.query, searchObj)

      return client.service('receipt-items').find(query)
    }).then((res) => {
      console.log(res)

      this.setState({
        loading: false,
      });

      this.props.fetchCreditNotes(res.data)
    }).catch((err) => {
      this.setState({
        loading: false,
      });
      console.log(err);
    });
  }

  handlePageChange = (page) => {
    this._getData((page * PAGESIZE) - PAGESIZE)
  }

  handleSubmit() {

    if (this.props.creditnotes.mode === 'new') {
      this.setState({
        loading: true,
      });

      this.props.form.validateFields((err, values) => {

        if (!err) {

          values.trxDate = new Date(values.trxDate)

          client.authenticate()
            .then((res) => {
              return client.service('receipts').create({
                companyId: this.props.commons.selectedCompany._id,
                ...values,
              })
            })
            .then((res) => {

              this.props.updateMode('refresh')
              this.props.form.resetFields()
              this.setState({
                loading: false,
              });
            })
            .catch(err => { console.log(err) })
        }

      });

    }

    if (this.props.creditnotes.mode === 'edit') {


      this.props.form.validateFields((err, values) => {

        if (!err) {

          this.setState({
            loading: true,
          });

          values.trxDate = new Date(values.trxDate)

          client.authenticate()
            .then((res) => {
              return client.service('rec').patch(this.props.creditnotes.activeId, {
                companyId: this.props.commons.selectedCompany._id,
                ...values,
              })
            })
            .then((res) => {

              this.props.updateMode('refresh')
              this.props.form.resetFields()
              this.setState({
                loading: false,
              });
            })
            .catch(err => { console.log(err) })
        }

      });

    }
  }


  getAccountList() {

    client.authenticate()
      .then((res) => {
        return client.service('ledger-accounts').find({
          query: {
            companyId: this.props.commons.selectedCompany._id,
            $sort: { accountName: 1 },
          }
        })
      })
      .then((res) => {

        this.setState({
          dtAccountList: res.data
        });

        return client.service('ledger-accounts').find({
          query: {
            companyId: this.props.commons.selectedCompany._id,
            $sort: { accountName: 1 },
          }
        })

      })
      .then((res) => {
        this.setState({
          ctAccountList: res.data
        });
      })

      .catch((err) => {
        console.log(err);
      });

  }

  //---------------------------------------------------------

  render() {

    const columns = [
      {
        title: (<Row gutter={8}>
          <Row>
            <Col span={24}>Invoice No</Col>
          </Row>
          <Row>
            {/* <Col span={24}><Input value={this.state.searchReferenceNo} onChange={this._searchReferenceNoChange} /></Col> */}
          </Row>
        </Row>),
        dataIndex: 'receiptId.receiptNo',
        key: 'receiptId.receiptNo',
        width: '10%'
      },
      {
        title: 'Credit Account',
        dataIndex: 'creditledgerAccountId.description',
        key: 'creditledgerAccountId.description',
        width: '15%'
      },
      {
        title: 'Debit Account',
        dataIndex: 'debitledgerAccountId.description',
        key: 'debitledgerAccountId.description',
        width: '25%'
      }
      , {
        title: (<Row gutter={8}>
          <Row>
            <Col span={24}>Date</Col>
          </Row>
          <Row>
            <Col span={24}><DatePicker value={this.state.searchDate} onChange={this._searchDateChange} /></Col>
          </Row>
        </Row>),
        dataIndex: 'date',
        key: 'date',
        width: '10%',
        render: (text, record) => (<div>{moment(record.date).format('DD-MM-YYYY')}</div>)
      },

      {
        title: (<Row><Col style={{ 'float': 'right' }}>Amount</Col></Row>),
        dataIndex: 'amount',
        key: 'amount',
        width: '10%',
        align: 'right',
        render: (text, record) => (<div style={{
          float: 'right'
        }}>
          {parseFloat(record.amount).toFixed(2)}
        </div>)
      },
      {
        title: 'Remark',
        dataIndex: 'remark',
        key: 'remark',
        width: '10%',
        render: (text, record) => (
          <div style={{ textAlign: 'center' }}>
            {text ? <Popover content={text}><Icon type="message" /></Popover> : ''}
          </div>
        ),
      },
      {
        title: 'Action',
        key: 'action',
        width: '20%',
        render: (text, record) => (<div>

          <Link to={{
            pathname: `/creditnotesSupplier/print/${record._id}`
          }}>
            <Button size='small' type="primary">Print</Button>
          </Link>

          {/* <Divider type="vertical" /> */}

          {/* <Button size='small' onClick={() => { this.handleEdit(record._id); }} type="primary">Edit</Button> */}

          {/* <Divider type="vertical" /> {this.renderConfirmDeleteBtn(record._id)} */}
        </div>)
      }
    ];

    return (<Layout>
      <Spin key='spinning' spinning={this.state.loading}>
        <Card title='Supplier Credit Notes' key='cardaction'
        //  extra={[
        //   <Button type="primary" onClick={() => this.showModal()} style={{ right: 10 }}>New</Button>
        // ]}
        >


          <Table dataSource={this.props.creditnotes.creditnotes} columns={columns} rowKey="_id" pagination={{
            defaultCurrent: 1,
            total: this.state.totalPagination,
            onChange: this.handlePageChange,
            pageSize: 20
          }} size={'small'} />

          {/* <FormCN activeItem={ } currentSupplier={ } receiptItems={ } parentReceipt /> */}
          <CreditNotesForm />
        </Card>
      </Spin>
    </Layout>)
  }
}

function mapStateToProps(state) {
  return { creditnotes: state.creditnotes, commons: state.commons }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchCreditNotes: fetchCreditNotes,
    pushCreditNotes: pushCreditNotes,
    removeCreditNotes: removeCreditNotes,
    updateActiveCreditNotes: updateActiveCreditNotes,
    updateCreditNotes: updateCreditNotes,
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(CreditNotesIndex)));
