const complaintType = {
  'A':'PUBLIC',
  'B':'PRIVATE',
  'C':'3-WAY'
};

const unclearBankReconsCategory = {
  'UC'  :  'UNPRESENTED CHEQUES',
  'UR'  :  'UNIDENTIFIED RECEIPTS',
  'LNC' :  'LOGGEMENT NOT CLEAR',
  //UC - UNPRESENTED CHEQUES(+add balance),
//UR - UNIDENTIFIED RECEIPTS(+add balance),
//LNC - LOGGEMENT NOT CLEAR(-minus balance)
};
const trancode_dateformat = {
  'YY'  :  'YY',
  'YYYY'  :  'YYYY',
  'YYYYMM' :  'YYYYMM',
  'YYYYMMDD' :  'YYYYMMDD',

};

module.exports = {
  complaintType,
  unclearBankReconsCategory,
  trancode_dateformat,
}
