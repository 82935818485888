import {
  Button,
  Card,

  DatePicker, Divider,

  Form,

  Input,




  InputNumber, message, Modal,












  Radio, Spin, Table
} from 'antd';

import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { bindActionCreators } from "redux";
import {
  fetchMonthlyStatements,
  pushMonthlyStatements,
  removeMonthlyStatements,

  updateActiveMonthlyStatements, updateMode,

  updateMonthlyStatements
} from '../../actions/actions-monthlystatements';
import BankReconsForm from "../../components/bankrecons/form";
import Layout from "../../components/layout";
import requireAuth from "../../components/requireAuth";
import client from '../../feathers';
import params from '../../params/system';


const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 8
  }
};

const formatCurr = function format1(n = 0, currency = null) {
  let oriAmt = n;
  n = n < 0
    ? Math.abs(n)
    : n;
  let amt = currency
    ? currency
    : '' + n.toFixed(2).replace(/./g, function (c, i, a) {
      return i > 0 && c !== "." && (a.length - i) % 3 === 0
        ? "," + c
        : c;
    });

  return oriAmt < 0
    ? '(' + amt + ')'
    : amt;
}

const formatDate = function (date) {
  try {
    if (date.toString().length === 6) {
      let y = date.toString().substr(0, 4);
      let m = date.toString().substr(4, 2);
      date = m + '/' + y
    }
  } catch (e) { }
  return date
}

class BankReconsIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {

      loading: false,
      recordId: '',
      monthlystatements: [],
      dataSource: [],
      visibleBankRecon: false
    }

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidUpdate() {

    if (this.props.monthlystatements.mode === 'refresh') {
      this.componentWillMount()
    }

  }

  componentDidMount() {

  }

  componentWillMount() {

    this.setState({ loading: true })

    this.props.updateMode('');

    client.authenticate().then((res) => {


      return client.service('monthlystatements').find({
        query: {
          companyId: this.props.commons.selectedCompany._id,
          bankreconInq: true,
          $populate: 'ledgerAccountId',
          yearmonth: this.props.monthlystatements.selectedMonth,
          ledgerAccountId: this.props.monthlystatements.selectedBank,
          $sort: {
            ledgerAccountId: 1,
            yearmonth: -1
          }
        }
      })
    }).then((res) => {

      this.props.fetchMonthlyStatements(res.data)
      this.setState({ dataSource: res.data, loading: false });
    }).catch((err) => {
      console.log(err);
      this.setState({ loading: false })
    })

  }

  handleDelete(_id) {
    client.service('bankrecons').remove(_id).then((res) => { }).catch((err) => {
      alert(err);
    })
  }

  handleEdit(_id) {
    this.props.updateActiveBankRecons('edit', _id)
  }

  handleManage(_id) {
    this.props.updateActiveMonthlyStatements('', _id)
  }

  showModal(_id, record) {
    this.props.updateActiveMonthlyStatements('edit', _id)
  }

  showModalBankRecon() {
    this.setState({ visibleBankRecon: true })
  }

  handleCancelBankRecon() {
    this.setState({ visibleBankRecon: false })
  }

  handleSubmitBankRecon() {

    this.setState({ loadingBankRecon: true })

    this.props.form.validateFields((err, values) => {

      let ctAmt = 0,
        dtAmt = 0

      values.trxDate = new Date(values.trxDate)

      if (values.bankReconCategory === 'UR' || values.bankReconCategory === 'LNC') {
        ctAmt = values.amount
        values.creditDebit = 'CREDIT'
      }
      else {
        dtAmt = values.amount
        values.creditDebit = 'DEBIT'
      }

      client.service('bankrecons').create({
        companyId: this.props.commons.selectedCompany._id,

        trxDate: values.trxDate,
        reference: values.reference,
        reference2: values.reference2
          ? values.reference2
          : '',
        description: values.description
          ? values.description
          : '',
        debitAmount: dtAmt,
        creditAmount: ctAmt,
        ledgerAccountId: this.props.monthlystatements.selectedBank,
        showInBankRecon: false,
        creditDebit: values.creditDebit,
        bankReconCategory: values.bankReconCategory
      }).then((res) => {
        message.success('New Record Added');
        this.setState({ visibleBankRecon: false, loadingBankRecon: false });
      })

    });
  }

  RetrieveFromParameter(record) {
    var myFunc = function (thisObj, company) {
      return params.unclearBankReconsCategory[company]
    };
    return myFunc(params.unclearBankReconsCategory, record.category);
  }

  render() {

    const { getFieldDecorator } = this.props.form;
    const { loading } = this.state;

    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px'
    };

    const columns = [
      {
        title: 'Date',
        dataIndex: 'yearmonth',
        key: 'yearmonth',
        width: "10%",
        render: (text, record) => (<span>
          {formatDate(text)}
        </span>)

      }, {
        title: 'Account',
        dataIndex: 'ledgerAccountId.fullAccountNo',
        key: 'fullAccountNo',
        width: "15%"
      }, {
        title: 'Description',
        dataIndex: 'ledgerAccountId.description',
        key: 'ledgerAccountId',
        width: "10%"
      }, {
        title: (<span style={{
          float: 'right'
        }}>Sys Balance</span>),
        dataIndex: 'balance',
        key: 'balance',
        width: "8%",
        render: (text, record) => (<span style={{
          float: 'right'
        }}>{
            formatCurr(
              record.balance
                ? record.balance
                : 0)
          }</span>)
      }, {
        title: (<span style={{
          float: 'right'
        }}>Bank Balance</span>),
        dataIndex: 'bankStatementBalance',
        key: 'bankStatementBalance',
        width: "8%",
        render: (text, record) => (<span style={{
          float: 'right'
        }}>{
            formatCurr(
              record.bankStatementBalance
                ? record.bankStatementBalance
                : 0)
          }</span>)
      }, {
        title: (<span style={{
          float: 'right'
        }}>Differences</span>),
        dataIndex: 'workfield',
        key: 'workfield',
        width: "8%",
        render: (text, record) => (<span style={{
          float: 'right'
        }}>{
            formatCurr(record.balance - (
              record.bankStatementBalance
                ? record.bankStatementBalance
                : 0))
          }</span>)
      }, {
        title: 'Action',
        key: 'action',
        render: (text, record) => (<span>
          <Link to={{
            pathname: `./index-daily/${record._id}`
          }}>
            <Button>View</Button>
          </Link>
          <Divider type="vertical" />

          <Button onClick={() => this.showModal(record._id, record)}>
            Edit Bank Balance</Button>
        </span>)
      }
    ];

    return (<Layout>
      <Spin key='spinning' spinning={this.state.loading}>
        <Card loading={loading} title="Bank Reconciliation" extra={<span > <Button type="primary" onClick={() => this.showModalBankRecon()}>
          Add Record</Button>
        </span>}>


          <>
            <Table dataSource={this.state.dataSource} columns={columns} rowKey="_id" />
          </>

          <BankReconsForm />
        </Card>
      </Spin>,

      <Modal title='Add New Bank Recon Record' visible={this.state.visibleBankRecon} onOk={() => this.handleSubmitBankRecon()} onCancel={() => this.handleCancelBankRecon()} okText={'Submit'}>
        <Form className="submit-form">

          <FormItem {...formItemLayout} label="Date">
            {
              getFieldDecorator('trxDate', {
                rules: [
                  {
                    required: true,
                    message: 'Please input Transaction Date!'
                  }
                ]
              })(<DatePicker />)
            }
          </FormItem>

          <FormItem {...formItemLayout} label="Reference">
            {
              getFieldDecorator('reference', {
                rules: [
                  {
                    required: true,
                    message: 'Please input Bank Balance!'
                  }
                ]
              })(<Input />)
            }
          </FormItem>

          <FormItem {...formItemLayout} label="Reference 2">
            {
              getFieldDecorator('reference2', {
                rules: [
                  {
                    required: false,
                    message: 'Please input Reference 2!'
                  }
                ]
              })(<Input />)
            }
          </FormItem>

          <FormItem {...formItemLayout} label="Description">
            {
              getFieldDecorator('description', {
                rules: [
                  {
                    required: false,
                    message: 'Please input description!'
                  }
                ]
              })(<Input />)
            }
          </FormItem>

          {/*

        <FormItem {...formItemLayout} label="Debit/Credit">
          {
            getFieldDecorator('creditDebit', {
              rules: [
                {
                  required: true,
                  message: 'Please Select Debit/Credit!'
                }
              ]
            })(<Radio.Group key='debitCredit'>
              <Radio.Button key='DEBIT' value='DEBIT' description='DEBIT'>DEBIT</Radio.Button>
              <Radio.Button key='CREDIT' value='CREDIT' description='CREDIT'>CREDIT</Radio.Button>
            </Radio.Group>)
          }
        </FormItem>

      */}

          <FormItem {...formItemLayout} label="Category">
            {
              getFieldDecorator('bankReconCategory', {
                rules: [
                  {
                    required: true,
                    message: 'Please Select Category!'
                  }
                ]
              })(<Radio.Group key='bankReconCategory'>
                <Radio style={radioStyle} key='UC' value='UC' description='UC'>UNIDENTIFIED CHEQUE</Radio>
                <Radio style={radioStyle} key='UR' value='UR' description='UR'>UNIDENTIFIED RECEIPTS</Radio>
                <Radio style={radioStyle} key='LNC' value='LNC' description='LNC'>LODGEMENT NOT CLEARED</Radio>
              </Radio.Group>)
            }
          </FormItem>

          <FormItem {...formItemLayout} label="Amount">
            {
              getFieldDecorator('amount', {
                rules: [
                  {
                    required: true,
                    message: 'Please Select Amount!'
                  }
                ]
              })(<InputNumber step={0.01} />)
            }
          </FormItem>

        </Form>

      </Modal>

    </Layout>)
  }
}

function mapStateToProps(state) {
  return {
    monthlystatements: state.monthlystatements,
    commons: state.commons,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchMonthlyStatements: fetchMonthlyStatements,
    pushMonthlyStatements: pushMonthlyStatements,
    removeMonthlyStatements: removeMonthlyStatements,
    updateActiveMonthlyStatements: updateActiveMonthlyStatements,
    updateMonthlyStatements: updateMonthlyStatements,
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(BankReconsIndex)));