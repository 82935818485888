import _ from 'lodash';
import {
  FETCH_PROPERTYUNITUSERS,
  PUSH_PROPERTYUNITUSERS,
  REMOVE_PROPERTYUNITUSERS,
  UPDATE_PROPERTYUNITUSERS,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID } from '../actions/actions-propertyunitusers';

const INITIAL_STATE = {
  propertyunitusers: [],
  mode: 'index',
  activeId: '',
  activePropertyunitusers:{}
}


export default function(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_PROPERTYUNITUSERS:
      return {
        ...state,
        propertyunitusers:action.payload,
      }
    case PUSH_PROPERTYUNITUSERS:
      return {
        ...state,
        propertyunitusers:[action.payload, ...state.propertyunitusers],
      }
    case REMOVE_PROPERTYUNITUSERS:
      return {
        ...state,
        propertyunitusers : [...state.propertyunitusers.filter( (item) => item._id !== action.payload._id)]
      }
    case UPDATE_PROPERTYUNITUSERS:
      let index =  _.findIndex(state.propertyunitusers, { '_id': action.payload._id })
      state.propertyunitusers.splice(index, 1, action.payload)
      return {
        ...state,
        propertyunitusers : state.propertyunitusers
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode : action.payload,
        activeId:''
      }
    case UPDATE_ACTIVE_ID:
      return {
        ...state,
        mode : action.mode,
        activeId:action.id,
        activePropertyunitusers: _.pick(_.find(state.propertyunitusers, { '_id': action.id }), ['propertyId','block', 'floor','unitNo','size', '_id'])
      }
    default:
      return state

  }
}
