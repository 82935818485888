import _ from 'lodash';
import {
  FETCH_VENDORS,
  PUSH_VENDORS,
  REMOVE_VENDORS,
  UPDATE_VENDORS,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID } from '../actions/actions-vendors';

const INITIAL_STATE = {
  vendors: [],
  mode: 'index',
  activeId: '',
  activeVendors:{}
}


export default function(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_VENDORS:
      return {
        ...state,
        vendors:action.payload,
      }
    case PUSH_VENDORS:
      return {
        ...state,
        vendors:[action.payload, ...state.vendors],
      }
    case REMOVE_VENDORS:
      return {
        ...state,
        vendors : [...state.vendors.filter( (item) => item._id !== action.payload._id)]
      }
    case UPDATE_VENDORS:
      let index =  _.findIndex(state.vendors, { '_id': action.payload._id })
      state.vendors.splice(index, 1, action.payload)
      return {
        ...state,
        vendors : state.vendors
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode : action.payload,
        activeId:''
      }
    case UPDATE_ACTIVE_ID:
      return {
        ...state,
        mode : action.mode,
        activeId:action.id,
        activeVendors: _.pick(_.find(state.vendors, { '_id': action.id }), ['file','name', 'description','mobile','email','location','status','type', '_id'])
      }
    default:
      return state

  }
}
