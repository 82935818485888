export const LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL';
export const LOGOUT_SUCCESSFUL = 'LOGOUT_SUCCESSFUL';

export function loginSuccessful(userInfo){
  // console.log({userInfo});
  return (dispatch) => {
    dispatch({
      type: LOGIN_SUCCESSFUL,
      payload:userInfo
    });
  }
}

export function logoutSuccessful(){
  return (dispatch) => {
    dispatch({
      type: LOGOUT_SUCCESSFUL,
      payload:false
    });
  }
}
