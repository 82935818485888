import React, { Component } from 'react';
import { Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Card, Modal, Spin } from 'antd';
import { Route, Link } from 'react-router-dom';

import client from '../../feathers';
import requireAuth from "../../components/requireAuth";
import Layout from "../../components/layout";
import AccountingperiodsForm from "../../components/accounting-periods/form";
import moment from 'moment';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchAccountingperiods,
  pushAccountingperiods,
  removeAccountingperiods,
  updateMode,
  updateActiveAccountingperiods,
  updateAccountingperiods
} from '../../actions/actions-accountingperiods';


const FormItem = Form.Item;

const text = 'Are you sure to delete this item?';

class AccountingperiodsIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      password: '',
      loading: false
    }

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidUpdate() {
  }

  componentDidMount() {

  }

  componentWillMount() {

    this.setState({ loading: true });

    client.authenticate()
      .then((res) => {

        return client.service('accounting-periods').find({
          query: {
            companyId: this.props.commons.selectedCompany._id,
            companyType: 'PROPERTY',
            $sort: {
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {

        this.props.fetchAccountingperiods(res.data);
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      })
  }

  handleDelete(_id) {
    client.service('accountingperiods').remove(_id)
      .then((res) => {
      })
      .catch((err) => {
        alert(err);
      })
  }

  handleEdit(_id) {
    this.props.updateActiveAccountingperiods('edit', _id)
  }

  handleManage(_id) {
    this.props.updateActiveAccountingperiods('', _id)
  }

  showModal = () => {
    this.props.updateMode('new');
  }

  showModalRecurring = () => {
    this.setState({
      visible: true,
    });
  }

  confirm = () => {

    this.setState({ loading: true });

    client.authenticate()
      .then((res) => {

        return client.service('applogs').create({
          remark: 'Recurring Invoice',
          status: 'START',
          propertyId: this.props.commons.selectedCompany._id
        })
      })
      .then((res) => {

        this.setState({ loading: false });
        message.success('Recurring Invoices Created');
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
        alert(err);
      })

  }

  handleCancel = (e) => {

    this.setState({
      visible: false,
    });
  }

  renderConfirmDeleteBtn(_id) {
    return (
      <Popconfirm placement="topLeft" title={text} onConfirm={() => this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
        <Button type="danger">Delete</Button>
      </Popconfirm>
    )
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title: 'From Date',
        dataIndex: 'fromDate',
        width: "20%",
        render: (text, record) => (moment(text).format("DD-MM-YYYY"))
      },
      {
        title: '',
        key: 'undefined',
        width: "5%",
        render: (text, record) => ('~')
      },
      {
        title: 'To Date',
        dataIndex: 'toDate',
        width: "20%",
        render: (text, record) => (moment(text).format("DD-MM-YYYY"))
      },

      // {
      //   title: 'Status',
      //   dataIndex: 'status',
      //   key: 'status',
      // },
      //   {
      //   title: 'Action',
      //   key: 'action',
      //   render: (text, record) => (
      //     <span>
      //       <Divider type="vertical" />
      //         {/* <AccountingperiodsForm mode={'Edit'} dataSource={record} /> */}
      //       <Divider type="vertical" />
      //       {/*this.renderConfirmDeleteBtn(record._id)*/}
      //     </span>
      //   ),
      // }
    ];

    return (
      <Layout>
        <Spin spinning={this.state.loading} >
          <Card
            title="Accounting Periods"
            extra={<AccountingperiodsForm mode={'New'} />}
          >



            <Table
              dataSource={this.props.accountingperiods.accountingperiods}
              columns={columns}
              rowKey="_id"
              size={'small'}
            />


          </Card>
        </Spin>
      </Layout>
    )

  }
}


function mapStateToProps(state) {
  return {
    accountingperiods: state.accountingperiods,
    commons: state.commons
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchAccountingperiods: fetchAccountingperiods,
    pushAccountingperiods: pushAccountingperiods,
    removeAccountingperiods: removeAccountingperiods,
    updateActiveAccountingperiods: updateActiveAccountingperiods,
    updateAccountingperiods: updateAccountingperiods,
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(AccountingperiodsIndex)));
