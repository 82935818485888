import React, { Component } from 'react'
import { Modal, message, Form, Input, Select,InputNumber } from 'antd'
import client from '../../feathers'
import requireAuth  from "../../components/requireAuth"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateMode } from '../../actions/actions-settings'

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8},
};


class SettingsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id:'',
      confirmDirty: false,
      autoCompleteResult: [],
    }
  }
  componentDidUpdate(){
      if(this.props.settings.activeId !== this.state._id){
        this.setState({
          _id:this.props.settings.activeId
        })

        if(this.props.settings.activeSettings){
          this.props.form.setFieldsValue(
            this.props.settings.activeSettings
          )
        }
      }
  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }


  handleSubmit = (e) => {
     e.preventDefault();
     if(this.props.settings.mode === 'new'){

       this.props.form.validateFields((err, values) => {
         if (!err) {
           client.service('settings').create(values)
           .then((res)=>{

             this.props.updateMode('')
             message.success('New Settings Added');
           })
           .catch((err)=>{
            console.log(err);
             this.props.updateMode('')
             alert(err);
           })
         }
       });
     }else{
       this.props.form.validateFields((err, values) => {



         values.recurringDate = parseInt(this.props.form.getFieldValue('recurringDate'));
         //values.recurringGenDate = parseInt(this.props.form.getFieldValue('recurringGenDate'));


         if (!err) {
           client.service('settings').patch(this.props.settings.activeId,
             values
           )
           .then((res)=>{

             message.success(`Settings Updated`);
             this.props.updateMode('');
           })
           .catch((err)=>{
            console.log(err);

              message.error(`Failed to Update.`);
             this.props.updateMode('');
           })
           return;
         }

       });
     }
  }

  render() {

    const { getFieldDecorator } = this.props.form;


    return(
      <Modal
         visible={
           this.props.settings.mode==='new'||this.props.settings.mode==='edit'?true:false
         }
         title={ this.props.settings.mode === 'edit' ? 'Update Settings':'Create Settings' }
         okText={ this.props.settings.mode === 'edit' ? 'Update':'Create' }
         onCancel={()=>{this.props.updateMode(''); this.props.form.resetFields()} }
         onOk={this.handleSubmit}

      >
        <Form className="submit-form">

          <FormItem
            {...formItemLayout}
            label="Recurring Period"
          >
            {getFieldDecorator('recurringPeriod', {
              rules: [
                { required: true, message: 'Please input Recurring Period!' },
              ],
            })(
              <Select placeholder="Please Select">
                <Option value="1">1 MONTH</Option>
                <Option value="3">3 MONTH</Option>
                <Option value="4">4 MONTH</Option>
                <Option value="6">6 MONTH</Option>
              </Select>
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Current/Next Month"
          >
            {getFieldDecorator('invoiceGenMonth', {
              rules: [
                { required: true, message: 'Please input!' },
              ],
            })(
              <Select placeholder="Please Select">
                <Option value="CURRENT">Current Month</Option>
                <Option value="NEXT">Next Month</Option>
              </Select>
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Recurring Gen Date"
          >
            {getFieldDecorator('recurringGenDate', {
              rules: [
                { required: true, message: 'Recurring Gen Date!' },
              ],
            })(
              <Select placeholder="Please Select Date">
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="3">3</Option>
                <Option value="4">4</Option>
                <Option value="5">5</Option>
                <Option value="6">6</Option>
                <Option value="7">7</Option>
                <Option value="8">8</Option>
                <Option value="9">9</Option>
                <Option value="10">10</Option>
                <Option value="11">11</Option>
                <Option value="12">12</Option>
                <Option value="13">13</Option>
                <Option value="14">14</Option>
                <Option value="15">15</Option>
                <Option value="16">16</Option>
                <Option value="17">17</Option>
                <Option value="18">18</Option>
                <Option value="19">19</Option>
                <Option value="20">20</Option>
                <Option value="21">21</Option>
                <Option value="22">22</Option>
                <Option value="23">23</Option>
                <Option value="24">24</Option>
                <Option value="25">25</Option>
                <Option value="26">26</Option>
                <Option value="27">27</Option>
                <Option value="28">28</Option>
                <Option value="29">29</Option>
                <Option value="30">30</Option>
                <Option value="31">31</Option>
              </Select>
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Recurring Date"
          >
            {getFieldDecorator('recurringDate', {
              rules: [
                { required: true, message: 'Please select Recurring Date!' },
              ],
            })(
              <Select placeholder="Please Select Recurring Date">
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="3">3</Option>
                <Option value="4">4</Option>
                <Option value="5">5</Option>
                <Option value="6">6</Option>
                <Option value="7">7</Option>
                <Option value="8">8</Option>
                <Option value="9">9</Option>
                <Option value="10">10</Option>
                <Option value="11">11</Option>
                <Option value="12">12</Option>
                <Option value="13">13</Option>
                <Option value="14">14</Option>
                <Option value="15">15</Option>
                <Option value="16">16</Option>
                <Option value="17">17</Option>
                <Option value="18">18</Option>
                <Option value="19">19</Option>
                <Option value="20">20</Option>
                <Option value="21">21</Option>
                <Option value="22">22</Option>
                <Option value="23">23</Option>
                <Option value="24">24</Option>
                <Option value="25">25</Option>
                <Option value="26">26</Option>
                <Option value="27">27</Option>
                <Option value="28">28</Option>
                <Option value="29">29</Option>
                <Option value="30">30</Option>
                <Option value="31">31</Option>
              </Select>
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Due Date Period"
          >
            {getFieldDecorator('dueDatePeriod', {
              rules: [
                { required: true, message: 'Please input DUE DATE PERIOD!' },
              ],
            })(
              <InputNumber />
            )}
          </FormItem>

        </Form>
      </Modal>

    )
  }
}


function mapStateToProps(state) {
  return {
    settings: state.settings
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
    }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(SettingsForm)));
