import React, { Component, useEffect } from "react";
import {
  Modal,
  Upload,
  Popconfirm,
  message,
  Divider,
  Table,
  Form,
  Icon,
  Input,
  Button,
  Select,
  InputNumber,
  DatePicker,
  Radio,
  Row,
  Col,
} from "antd";
import client from "../../feathers";
import requireAuth from "../../components/requireAuth";
import Layout from "../../components/layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateMode } from "../../actions/actions-meterreadings";
import axios from "axios";
import params from "../../params/system";
import moment from "moment";

var FormData = require("form-data");
const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};
const text = "Are you sure to delete this item?";
//----------------------------------------------------------------------------
class TrxParametersForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: "",
      confirmDirty: false,
      autoCompleteResult: [],
      referenceformat: "",
      currentNum: 0,
      previousNum: 0,
      usageNum: 0,
    };
  }
  //----------------------------------------------------------------------------
  componentDidUpdate() {
    if (this.props.meterreadings.activeId !== this.state._id) {
      this.setState({
        _id: this.props.meterreadings.activeId,
      });

      if (this.props.meterreadings.activeMeterReadings) {
        this.props.meterreadings.activeMeterReadings.readDate = moment(
          new Date(this.props.meterreadings.activeMeterReadings.readDate)
        );
        this.props.form.resetFields();
        this.props.form.setFieldsValue(
          this.props.meterreadings.activeMeterReadings
        );
      }
    }

    if (this.props.form.getFieldValue("currentNum") !== this.state.currentNum) {
      let usageNum = parseFloat(
        this.props.form.getFieldValue("currentNum") - this.state.previousNum
      );
      this.setState({
        usageNum: usageNum,
        currentNum: this.props.form.getFieldValue("currentNum"),
      });
      this.props.form.setFieldsValue({ usageNum: usageNum });
    }

    if (
      this.props.form.getFieldValue("previousNum") !== this.state.previousNum
    ) {
      let usageNum = parseFloat(
        this.state.currentNum - this.props.form.getFieldValue("previousNum")
      );
      this.setState({
        usageNum: usageNum,
        previousNum: this.props.form.getFieldValue("previousNum"),
      });
      this.props.form.setFieldsValue({ usageNum: usageNum });
    }
  }
  //----------------------------------------------------------------------------
  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  handleTypeChange = (e) => {
    const value = e.target.value;

    if (value === "electricity") {
      this.props.form.setFieldsValue({ uom: "kWh" });
    } else {
      this.props.form.setFieldsValue({ uom: "m3" });
    }
  };
  //----------------------------------------------------------------------------
  handleSubmit = (e) => {
    e.preventDefault();

    if (this.props.meterreadings.mode === "new") {
      this.props.form.validateFields((err, values) => {
        if (values.previousNum) {
          values.usagesNum = parseFloat(values.currentNum - values.previousNum);
        }
        values.readDate = new Date(values.readDate);

        if (!err) {
          client
            .service("meterreadings")
            .create({
              ...values,
              companyId: this.props.commons.selectedCompany._id,
            })
            .then((res) => {
              this.props.updateMode("refresh");
              message.success("New Record added");
            })
            .catch((err) => {
              this.props.updateMode("");
              alert(err);
            });
        }
      });
    } else {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          client
            .service("meterreadings")
            .patch(this.props.meterreadings.activeId, values)
            .then((res) => {
              message.success(`Updated`);
              this.props.updateMode("refresh");
            })
            .catch((err) => {
              this.props.updateMode("");
            });
          return;
        }
      });
    }
  };
  //----------------------------------------------------------------------------

  componentWillMount() {}

  //----------------------------------------------------------------------------
  render() {
    const { visible, onCancel } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { referenceformat, usageNum } = this.state;

    return (
      <Modal
        visible={
          this.props.meterreadings.mode === "new" ||
          this.props.meterreadings.mode === "edit"
            ? true
            : false
        }
        title={
          this.props.meterreadings.mode === "edit"
            ? "Update Reading"
            : "Create Reading"
        }
        okText={this.props.meterreadings.mode === "edit" ? "Update" : "Create"}
        onCancel={() => {
          this.props.updateMode("");
          this.props.form.resetFields();
        }}
        onOk={this.handleSubmit}
      >
        <Form className="submit-form">
          <FormItem {...formItemLayout} label="Name">
            {getFieldDecorator("meterName", {
              rules: [{ required: true, message: "Please input Name!" }],
            })(<Input placeholder="WATERMETER" />)}
          </FormItem>

          <FormItem {...formItemLayout} label="Reading Date">
            {getFieldDecorator("readDate", {
              rules: [{ required: true, message: "Please input Date!" }],
            })(<DatePicker />)}
          </FormItem>

          <FormItem {...formItemLayout} label="Type">
            {getFieldDecorator("meterType", {
              rules: [{ required: true, message: "Please Select Type!" }],
            })(
              <Radio.Group onChange={this.handleTypeChange}>
                <Radio.Button value="electricity">ELECTRICITY</Radio.Button>
                <Radio.Button value="water">WATER</Radio.Button>
              </Radio.Group>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="UOM">
            {getFieldDecorator("uom", {
              rules: [{ required: false, message: "Please input UOM!" }],
            })(<Input disabled value={this.state.uom} />)}
          </FormItem>

          <FormItem {...formItemLayout} label="Current Reading">
            {getFieldDecorator("currentNum", {
              rules: [{ required: true, message: "Please input Current!" }],
              initialValue: 0,
            })(<InputNumber value={this.state.currentNum} />)}
          </FormItem>

          <Row gutter={8}>
            <Col offset={8}>
              <span style={{ "font-size": "0.75em", "font-style": "italic" }}>
                If below leave blank, system will auto derive from previous
                record
              </span>
            </Col>
          </Row>

          <FormItem {...formItemLayout} label="Previous Reading">
            {getFieldDecorator("previousNum", {
              rules: [{ required: false, message: "Please input Previous!" }],
              initialValue: 0,
            })(<InputNumber value={this.state.previousNum} />)}
          </FormItem>

          <FormItem {...formItemLayout} label="Usage">
            {getFieldDecorator("usageNum", {
              rules: [{ required: false, message: "Please input Usage!" }],
            })(<Input value={this.state.usageNum} disabled />)}
          </FormItem>

          <FormItem {...formItemLayout} label="Remark">
            {getFieldDecorator("notes", {
              rules: [{ required: false, message: "Please input Notes!" }],
            })(<TextArea rows={4} />)}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

//----------------------------------------------------------------------------
function mapStateToProps(state) {
  return {
    meterreadings: state.meterreadings,
    commons: state.commons,
  };
}
//----------------------------------------------------------------------------
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateMode: updateMode,
    },
    dispatch
  );
}

//----------------------------------------------------------------------------

export default requireAuth(
  connect(mapStateToProps, mapDispatchToProps)(Form.create()(TrxParametersForm))
);
