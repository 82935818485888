import React, { Component } from 'react';
import { Modal, message, Form, Input, Select} from 'antd';
import client from '../../feathers';
import requireAuth  from "../../components/requireAuth";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  fetchPropertyunits,
  pushPropertyunits,
  removePropertyunits,
  updateMode,
  updateActivePropertyunits,
  updatePropertyunits } from '../../actions/actions-propertyunits';

const FormItem = Form.Item;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

class PropertyunitsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id:'',
      confirmDirty: false,
      autoCompleteResult: [],
    }
  }
  componentDidUpdate(){
      if(this.props.propertyunits.activeId !== this.state._id){
        this.setState({
          _id:this.props.propertyunits.activeId
        })

        if(this.props.propertyunits.activePropertyunits){
          this.props.form.setFieldsValue(
            this.props.propertyunits.activePropertyunits
          )
        }
      }
      
  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if(this.props.propertyunits.mode === 'new'){

      this.props.form.validateFields((err, values) => {
        if (!err) {

         client.service('propertyunits').patch(this.props.propertyunitsId,{
           '$push':{'users':{
             'userId':values.userId,
             'email':values.email,
             'userType':values.userType,
           } }
         })
         .then((res)=>{


           message.success(`Item Added`);
           this.props.updateMode('');
           this.props.fetchPropertyunits(res.users);
         })
         .catch((err)=>{
          console.log(err);
           
           this.props.updateMode('');
         })
         return;
        }

      });
    }else{
      this.props.form.validateFields((err, values) => {
        if (!err) {
          client.service('invoices').update(this.props.invoiceitems.activeId,
            values
          )
          .then((res)=>{

            message.success(`Invoiceitems Updated`);
            this.props.updateMode('')
          })
          .catch((err)=>{
           console.log(err);
            
            this.props.updateMode('')
          })
          return;
        }
        
      });
    }
 }


handleBlurProperty = (e) => {
  client.service('propertyunits').find({
    query: {
      referenceId:e.target.value,
       $sort: {
         createdAt: -1
       }
     }
  })
  .then((res)=>{
    if(res.total=='1'){
      message.success('Record Found');
      this.props.form.setFieldsValue({
        propertyId:res.data[0].propertyId
      })
    }else{
      message.error('Not Found');
    }

  })
  .catch((err)=>{
    console.log(err);
    message.error(err);
  })
}


  render() {

    const { getFieldDecorator } = this.props.form;

    return(
      <Modal
         visible={
           this.props.propertyunits.mode==='new'||this.props.propertyunits.mode==='edit'?true:false
         }
         title={ this.props.propertyunits.mode === 'edit' ? 'Update':'Create' }
         okText={ this.props.propertyunits.mode === 'edit' ? 'Update':'Create' }
         onCancel={()=>{this.props.updateMode(''); this.props.form.resetFields()} }
         onOk={this.handleSubmit}

      >
        <Form className="submit-form">

            <FormItem
              {...formItemLayout}
              label="Owner Info"
            >
              {getFieldDecorator('remark', {
                rules: [{
                  required: true, message: 'Please input Remark!',
                }],
              })(
                <TextArea rows={6}/>
              )}
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="file"
            >
              {getFieldDecorator('file', {
                rules: [{
                  required: true, message: 'Please insert File!',
                }],
              })(
                <Input/>
              )}
            </FormItem>


        </Form>
      </Modal>

    )
  }
}


function mapStateToProps(state) {
  return {
    propertyunits: state.propertyunits
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchPropertyunits: fetchPropertyunits,
    pushPropertyunits: pushPropertyunits,
    removePropertyunits: removePropertyunits,
    updateActivePropertyunits: updateActivePropertyunits,
    updatePropertyunits: updatePropertyunits,
    updateMode: updateMode
    }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(PropertyunitsForm)));
