export const accounting         = require('./accounting.png');
export const advertisement      = require('./advertisement.png');
export const dashboard          = require('./dashboard.png');
export const eform              = require('./e-form.png');
export const managementuser     = require('./management-user.png');
export const management         = require('./management.png');
export const notices            = require('./notices.png');
export const propertyrequest    = require('./property-request.png');
export const property           = require('./property.png');
export const reporting          = require('./reporting.png');
export const services           = require('./services.png');
export const user               = require('./user.png');
export const facilities         = require('./facilities.png');
export const setting            = require('./setting.png');
export const propertyunits      = require('./property-units.png');
export const invoices           = require('./invoices.png');
export const payments           = require('./payments.png');
export const companyprofile     = require('./company-profile.png');