import React, { Component } from 'react'
import { Popconfirm, Select, message, Divider, Table, Form, Icon, Input, Button, Card, Tabs, Badge, Modal, Col, Row, DatePicker } from 'antd'
import { Route, Link } from 'react-router-dom';
import axios from 'axios';
import client from '../../feathers'
import requireAuth from "../../components/requireAuth"
import Layout from "../../components/layout"
import PassesForm from "../../components/passes/form"
import moment from 'moment';
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  fetchPasses,
  pushPasses,
  removePasses,
  updateMode,
  updateActivePasses,
  updatePasses
} from '../../actions/actions-passes'

const _ = require('lodash')
const FormItem = Form.Item;
const { TabPane } = Tabs;
const Option = Select.Option;
const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY'

const text = 'Are you sure to delete this item?';

class PassesIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passType: '',
      totalDriveIn: 0,
      totalWalkIn: 0,
      totalService: 0,
      totalOneTimePass: 0,
      viewData: false,
      isScanned: '',
      wholeRecord: '',
      dateMoment: '',

    }


    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidUpdate() {

  }

  componentDidMount() {

  }

  componentWillMount() {
    this.setState({
      passType: 'DRIVE'
    }, () => {
      this.getData(0)
    })

  }

  getData(skip) {
    const { passType, isScanned, dateMoment } = this.state
    let queryPassesType, queryIsScanned, queryDateRange = ''

    if (!_.isEmpty(passType)) {
      queryPassesType = { accessType: passType }

    }
    if (!_.isEmpty(isScanned)) {
      if (isScanned === 'yes') {
        queryIsScanned = { scannedBy: { $exists: true } }
      }
      else {
        queryIsScanned = { scannedBy: { $exists: false } }
      }

    }
    if (!_.isEmpty(dateMoment)) {

      queryDateRange = {
        'requestDate.dateObj': {
          $gte: new Date(moment(new Date(this.state.dateMoment[0])).startOf('day')),
          $lte: new Date(moment(new Date(this.state.dateMoment[1])).endOf('day')),

        }

      }
    }



    client.authenticate()
      .then((res) => {
        var d = new Date();
        let year = d.getFullYear();
        let month = d.getMonth() + 1;
        let date = d.getDate();
        let afterFormat = year + '-' + month + '-' + date;


        axios.get(`${client.io.io.uri}getTotalPassesNumber`, {
          params: {
            propertyId: this.props.user.info.propertyId._id,

          },
          headers: {
            'Authorization': client.settings.storage.storage['feathers-jwt']
          }
        }).then((res) => {

          let data = []

          try { data = res.data.data } catch (err) { data = [] }

          for (let i = 0; i < data.length; i++) {
            let result = data[i]

            if (result._id === 'DRIVE') { this.setState({ totalDriveIn: result.totalCount }) }
            if (result._id === 'MOVE') { this.setState({ totalOneTimePass: result.totalCount }) }
            if (result._id === 'WALK') { this.setState({ totalWalkIn: result.totalCount }) }
            if (result._id === 'CONTRACTOR') { this.setState({ totalService: result.totalCount }) }

          }
        }).catch((err) => {
          console.log(err)
        })

        return client.service('epasses').find({
          query: {
            ...queryPassesType,
            ...queryIsScanned,
            ...queryDateRange,
            $populate: ['propertyId', 'propertyunitId', 'scannedBy'],
            propertyId: [res.propertyId._id],
            // visitDate: afterFormat,
            $sort: {
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {
        // console.log({ res })
        this.props.fetchPasses(res.data)
      })
      .catch((err) => {
        console.log(err);
      })
  }

  handleDelete(_id) {
    client.service('epasses').remove(_id)
      .then((res) => {
      })
      .catch((err) => {
        alert(err);
      })
  }

  handleEdit(_id) {
    this.props.updateActivePasses('edit', _id)
  }

  handleManage(_id) {
    this.props.updateActivePasses('', _id)
  }

  showModal = () => {
    this.props.updateMode('new')
  }
  handleOnChangeSortBy = (e) => {

    this.setState({
      passType: e,
      // changeStatus: true
    },
      () => {

        this.getData(0)
        // this.setState({ changeStatus: false })
      })

  }

  renderConfirmDeleteBtn(_id) {
    return (
      <Popconfirm placement="topLeft" title={text} onConfirm={() => this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
        <Button type="danger">Delete</Button>
      </Popconfirm>
    )
  }
  onChangeCalendar = (value) => {

    if (value.length === 2) {
      this.setState({ dateMoment: value }, () => {
        this.getData(0)
      })

    }


  }

  onChange = (value) => {
    if (_.isEmpty(value)) {
      this.setState({ dateMoment: '' }, () => {
        this.getData(0)
      })
    }
  }


  render() {
    const { getFieldDecorator } = this.props.form;
    let { passType, wholeRecord } = this.state

    let columns = [
      {
        title: 'Unit No.',
        dataIndex: 'propertyunitId.referenceId',
        key: 'propertyunitId.referenceId',
      },
      {
        title: 'Visitor Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Visitor Mobile.No',
        dataIndex: 'mobileNo',
        key: 'mobileNo',
      },
      {
        title: 'Visitor IC',
        dataIndex: 'idNo',
        key: 'idNo',
      },
      {
        title: 'Vehicle No.',
        dataIndex: 'vehicleDetail',
        key: 'vehicleDetail',
      },
      {
        title: 'Visit Date',
        dataIndex: 'requestDate.dateStr',
        key: 'requestDate.dateStr',
        render: (values, record) => {
          let value = ''
          try {
            value = record.requestTime.timeStr

          } catch (err) { value = '' }

          if (value) {
            return (
              values + ' ' + value
            )
          }
          else {
            return (<span>-</span>)
          }

        }
      },
      // {
      //   title: 'Visit Time',
      //   dataIndex: 'requestTime.timeStr',
      //   key: 'requestTime.timeStr',
      // },
      // {
      //   title: 'View Info',
      //   dataIndex: 'viewInfo',
      //   key: 'viewInfo',
      //   render: (data, record) => {
      //     return <Button type='primary' onClick={() => { this.setState({ viewData: true }) }}>Details</Button>

      //   }

      // },

      {/*{
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <Divider type="vertical" />
            <Button onClick={()=>this.handleEdit(record._id)}>Edit</Button>
            <Divider type="vertical" />
            this.renderConfirmDeleteBtn(record._id)
          </span>
        ),
      }*/}
    ];

    let oneTimePassCol = [
      {
        title: 'Company Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Phone Number',
        dataIndex: 'mobileNo',
        key: 'mobileNo',
      },
      {
        title: 'PIC IC',
        dataIndex: 'idNo',
        key: 'idNo',
      },
      {
        title: 'Date',
        dataIndex: 'requestDate.dateObj',
        key: 'requestDate.dateObj',
        render: (values, record) => {
          return (
            moment(values).format('DD/MM/YYYY')
          )
        }
      },
      {
        title: 'Vehicle Plate ',
        dataIndex: 'vehicleDetail',
        key: 'vehicleDetail',
      },



    ]
    let contractorCol = [
      {
        title: 'Service Type',
        dataIndex: 'service',
        key: 'service',
        render: (data, record) => {
          if (record.service === 'Others' && typeof record.otherDetails === 'string') {


            return record.service + ' (' + record.otherDetails + ")"

          }
          else if (record.service === 'Others') {
            return record.service + " (-)"
          }
          else {
            return record.service
          }
        }
      },
      {
        title: 'Company Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Company Phone Number',
        dataIndex: 'mobileNo',
        key: 'mobileNo',
      },
      {
        title: 'PIC IC',
        dataIndex: 'idNo',
        key: 'idNo',
      },
      {
        title: 'Start Date',
        dataIndex: 'requestDate.dateStr',
        key: 'requestDate.dateStr',

      }, {
        title: 'End Date',
        dataIndex: 'requestedEndDate.dateStr',
        key: 'requestedEndDate.dateStr',

      },




    ]
    let actionCol = [{
      title: <Select style={{ width: '100%' }} defaultValue=''

        onChange={(e) => {

          this.setState({
            isScanned: e,

          }, () => { this.getData(0) })

        }}
      >
        <Option key='' value=''>Scanned By (All)</Option>
        <Option key='yes' value='yes'>Scanned</Option>
        <Option key='no' value='no'>Unscanned</Option>





      </Select>,
      dataIndex: 'viewInfo',
      key: 'viewInfo',
      render: (data, record) => {

        let isDisable = false
        if (!record.scannedBy) {

          isDisable = true
        }

        return <Button type='primary' disabled={isDisable} onClick={() => {
          this.setState({
            viewData: true,
            wholeRecord: record
          })
        }}>Details</Button>


      }

    },]


    let tableCol = []
    if (passType === 'MOVE') {
      tableCol = oneTimePassCol.concat(actionCol)

    }
    if (passType === 'DRIVE') {
      tableCol = columns.concat(actionCol)

    }
    if (passType === 'WALK') {
      columns = columns.concat(actionCol)
      tableCol = columns.filter(ev => ev.dataIndex !== 'vehicleDetail')
    }

    if (passType === 'CONTRACTOR') {
      tableCol = contractorCol.concat(actionCol)
    }

    let guardUserName, guardLastName, guardFirstName, guardPhone,
      requestTime, access, imageSrc, requestDate = ''
    try {
      guardUserName = wholeRecord.scannedBy.email
      guardLastName = wholeRecord.scannedBy.lastName
      guardFirstName = wholeRecord.scannedBy.firstName
      guardPhone = wholeRecord.scannedBy.phoneNo
      requestTime = wholeRecord.requestTime.timeObj
      requestDate = wholeRecord.requestDate.dateObj
      access = wholeRecord.accessStartTime.timeObj
      // accessDate = wholeRecord.accessStartTime.dateObj
      console.log(wholeRecord)

      imageSrc = wholeRecord.files[0]

    }
    catch (err) {
      requestTime = ''
      access = ''
      guardUserName = ''
      guardLastName = ''
      guardFirstName = ''
      guardPhone = ''
      imageSrc = ''
      requestDate = ''
    }


    let rowData = (
      <Col>
        <Row>
          <Col span={12}><span ><b>Scanned By: </b>
            {guardUserName ? guardUserName : '-'}
          </span></Col>
          <Col span={12}><span ><b>Phone No: </b>
            {guardPhone ? guardPhone : '-'}
          </span></Col>
          <Col span={12}><span ><b>First Name: </b>
            {guardFirstName ? guardFirstName : '-'}
          </span></Col>
          <Col span={12}><span ><b>Last Name: </b>
            {guardLastName ? guardLastName : '-'} </span></Col>

          <Col span={12}><span ><b>Request Date: </b>
            {requestTime ? moment(requestTime).format('DD/MM/YYYY') :
              requestDate ? moment(requestDate).format('DD/MM/YYYY') : '-'}
          </span></Col>
          <Col span={12}><span ><b>Request Time: </b>
            {requestTime ? moment(requestTime).format('hh:mm A') : '-'}
          </span></Col>
          <Col span={12}><span ><b>Access Date: </b>
            {access ? moment(access).format('DD/MM/YYYY') : 'No record'}
          </span></Col>
          <Col span={12}><span ><b>Access Time: </b>
            {access ? moment(access).format('hh:mm A') : 'No record'}
          </span></Col>
        </Row>

        <Divider  >Evidence Files</Divider>

        <Row gutter={8}>
          <Col>
            <div className='wrap-zoom-img'>
              <img alt='evidence' src={imageSrc} />
            </div>
          </Col>
        </Row>
      </Col>
    )

    return (
      <Layout>
        E-Visitor
        <Card title={<Tabs defaultActiveKey="DRIVE" onChange={this.handleOnChangeSortBy} style={{ flex: 1 }}

        >
          {/* <TabPane tab='All' key=''></TabPane> */}

          <TabPane tab={(<div><span className='marginRight8px' >Drive-In</span><Badge overflowCount={999999} style={{ marginLeft: '8px', backgroundColor: '#1DA57A' }} count={this.state.totalDriveIn} /></div>)} key="DRIVE">
          </TabPane>

          <TabPane tab={(<div><span className='marginRight8px' >Walk-In</span><Badge overflowCount={999999} style={{ marginLeft: '8px', backgroundColor: '#1DA57A' }} count={this.state.totalWalkIn} /></div>)} key="WALK">
          </TabPane>
          <TabPane tab={(<div><span className='marginRight8px' >Services</span><Badge overflowCount={999999} style={{ marginLeft: '8px', backgroundColor: '#1DA57A' }} count={this.state.totalService} /></div>)} key="CONTRACTOR">
          </TabPane>
          <TabPane tab={(<div><span className='marginRight8px' >One Time Pass</span><Badge overflowCount={999999} style={{ marginLeft: '8px', backgroundColor: '#1DA57A' }} count={this.state.totalOneTimePass} /></div>)} key="MOVE">
          </TabPane>

        </Tabs>}
          extra={
            <div>
              <RangePicker style={{ marginRight: '10px' }} on
                format={dateFormat}
                onCalendarChange={this.onChangeCalendar}
                onChange={this.onChange}
              />

            </div>}>
          {/*<Button type="primary" onClick={this.showModal}>New Passes</Button>*/}
          <Table dataSource={this.props.passes.passes} columns={tableCol} rowKey="_id" />

          <PassesForm />
        </Card>


        <Modal visible={this.state.viewData}
          onCancel={() => { this.setState({ viewData: false }) }}
          onOk={() => { this.setState({ viewData: false }) }}
        >
          {rowData}

        </Modal>

      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    passes: state.passes,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchPasses: fetchPasses,
    pushPasses: pushPasses,
    removePasses: removePasses,
    updateActivePasses: updateActivePasses,
    updatePasses: updatePasses,
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(PassesIndex)));
