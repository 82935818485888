export const FETCH_HELP = 'FETCH_HELP';
export const PUSH_HELP = 'PUSH_HELP';
export const REMOVE_HELP = 'REMOVE_HELP';
export const UPDATE_HELP = 'UPDATE_HELP';
export const UPDATE_MODE = 'UPDATE_MODE';
export const UPDATE_ACTIVE_ID = 'UPDATE_ACTIVE_ID';

export function fetchHelp(data) {
    return (dispatch) => {
        dispatch({
            type: FETCH_HELP,
            payload: data
        });
    }
}

export function pushHelp(data) {
    return (dispatch) => {
        dispatch({
            type: PUSH_HELP,
            payload: data
        });
    }
}

export function removeHelp(data) {
    return (dispatch) => {
        dispatch({
            type: REMOVE_HELP,
            payload: data
        });
    }
}

export function updateHelp(data) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_HELP,
            payload: data
        });
    }
}

export function updateMode(data) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_MODE,
            payload: data
        });
    }
}

export function updateActiveHelp(mode, id) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_ACTIVE_ID,
            mode,
            id
        });
    }
}
