import React, { Component } from 'react';
import { Modal,Avatar, Row, Col, Card, Popconfirm, message, Divider,  Table, Form, Icon, Input, Button,Select,Radio,Switch,Spin } from 'antd';
import client from '../../feathers';
import requireAuth  from "../requireAuth";
import Layout  from "../layout";
import Info  from "../common/info";
import moment from 'moment';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  updateValueIDN
} from '../../actions/actions-commons';
import ReactToPrint from 'react-to-print';


//--------------------------------------------------------------------
const toWords = require('to-words');
const { TextArea } = Input;
const _ = require("lodash");
//--------------------------------------------------------------------


class ComponentToPrint extends Component {
  constructor(props) {
      super(props);

      this.state = {
        dataSource: [],
        visible: false,
        setting:[],
        jmb:[],
        loading:false,
        totalAmount: 1,
        toWordText:''

      }
  }
  //-------------------------------------------------------
  componentWillMount(){

    client.authenticate()
    .then((res)=>{

      return client.service('lateinterests').find({
        query:{
            companyId: sessionStorage.getItem('companyId'),
            $populate:'invoiceItemId',
            status:this.props.props.commons.status,
            propertyunitId:this.props.props.commons.debtorAcc,
        }
      })
    })
    .then((res)=>{

      let seq = 0;
      res.data.forEach(function(item,index){
        item.seq= seq+1;
        seq++;

      })
      let totalAmount = _.sumBy(res.data, 'runninglateInterest');

      let toWordText =''

      try{
              toWordText= toWords(totalAmount)
      }
      catch(err)
      {
            toWordText= '-'
      }

      this.setState({
        dataSource  : res.data,
        totalAmount : totalAmount,
        toWordText  :toWordText
      });
    })


    this.getJmbInfo();
  }

  getJmbInfo(){
    client.authenticate()
    .then((res)=>{
        return client.service('jmbs').find({
          query:{
            propertyId:sessionStorage.getItem('companyId'),
            status:'ACTIVE',
            $limit:1,
            $sort: {
              createdAt: -1
            }
          }
        })
    })
    .then((res)=>{

      this.setState({
        jmb:res.data[0]
      });
    })
    .catch((err)=>{
        console.log(err);
    });

  }
  //-------------------------------------------------------
  componentDidMount(){}

  _renderTable = () => {

    const { visible, onCancel } = this.props;
    const { loading, selectedRowKeys,jmb,dataSource,totalAmount } = this.state;

    let table = [];

    const columns = [
      {
        title: 'No',
        dataIndex: 'seq',
        key: 'seq',
        width:'3%',
        align:'right',
        render: (text, record) => (
            <span style={{  fontSize: 12,padding:'0px'}} >
            {text}
          </span>
        ),
      },
      {
        title: 'Invoice No.',
        dataIndex: 'invoiceItemId.docId',
        key: 'invoiceItemId.docId',
        width:'10%',
        render: (text, record) => (
          <span style={{  fontSize: 12,padding:'0px'}} >
            {text}
          </span>
        ),
      },
      {
        title: 'Invoice Item',
        dataIndex: 'invoiceItemId.description',
        key: 'invoiceItemId.description',
        width:'18%',
        render: (text, record) => (
          <span style={{  fontSize: 12,padding:'0px'}} >
            {text}
          </span>
        ),
      },
      {
        title: 'Invoice Date',
        dataIndex: 'invoiceItemId.effectiveDate',
        key: 'invoiceItemId.effectiveDate',
        width:'10%',
        render: (text, record) => (
          <span style={{  fontSize: 12,padding:'0px'}} >
            {moment(text).format('DD-MM-YYYY')}
          </span>
        ),
      },

      {
        title: 'Invoice Amount',
        dataIndex: 'invoiceAmt',
        key: 'invoiceAmt',
        align:'right',
        width:'10%',
        render: (text, record) => (
          <span style={{  fontSize: 12,padding:'0px'}} >
              {text.toFixed(2)}
          </span>
        ),
      },
      {
        title: 'Accrue Interest Start Date',
        dataIndex: 'accrueIntStartDate',
        key: 'accrueIntStartDate',
        width:'10%',
        render: (text, record) => (
          <span style={{  fontSize: 12,padding:'0px'}} >
            {moment(text).format('DD-MM-YYYY')}
          </span>
        ),
      },
      {
        title: 'Due Days',
        dataIndex: 'dueDateDays',
        key: 'dueDateDays',
        width:'5%',
        align:'right',
        render: (text, record) => (
          <span style={{  fontSize: 12,padding:'0px'}} >
            {text}
          </span>
        ),
      },
      {
        title: 'Charge Date',
        dataIndex: 'chargeDate',
        key: 'chargeDate',
        width:'10%',
        render: (text, record) => (
          <span style={{  fontSize: 12,padding:'0px'}} >
            {moment(text).format('DD-MM-YYYY')}
          </span>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width:'10%',
        render: (text, record) => (
          <span style={{  fontSize: 12,padding:'0px'}} >
            {text}
          </span>
        ),
      },
        {
        title: (
          <span style={{float:'right'}}> Charge Amount </span>),
        dataIndex: 'runninglateInterest',
        key: 'runninglateInterest',
        width:'10%',
        align:'right',
        render: (text, record) => (
          <span style={{fontSize: 12,padding:'0px',float:'right'}} >
            {text.toFixed(2)}
          </span>
        ),
      }
    ];


    table.push([
      <div key='main' >
        <Card>
          <Row>
            <Col span={14} align="left">
              <p><span>{jmb?jmb.headerLine1:''}</span> ({jmb?jmb.registrationNo:''}) </p>
              <p><span>{jmb?jmb.headerLine2:''}</span></p>
              <p><span>{jmb?jmb.headerLine3:''}</span></p>
              <p><span>{jmb?jmb.headerLine4:''}</span></p>
              <br/>

            </Col>
          </Row>

          <Row gutter={8} >
            <Col span ={24} align="left">
              <h2 style={{'color': 'rgb(169,169,169)','letterSpacing': '2px'}}>Interest Debit Note</h2>
            </Col>
          </Row>


          <Row gutter={24}>
            <Col span={4}><span>Unit</span></Col>
            <Col span={1}><span>:</span></Col>
            <Col span={18}><span style={{'padding-left':'0px'}}>{this.props.props.commons.propertyunit.referenceId?this.props.props.commons.propertyunit.referenceId:''} </span></Col>
          </Row>

          <Row gutter={24}>
            <Col span={4}><span>Name</span></Col>
            <Col span={1}><span>:</span></Col>
            <Col span={18}><span>{this.props.props.commons.propertyunit.owner?this.props.props.commons.propertyunit.owner.name:''} </span></Col>
          </Row>

          <Row gutter={24}>
            <Col span={4}><span>Mailing Address</span></Col>
            <Col span={1}><span>:</span></Col>
            <Col span={18}>
              <TextArea readOnly style={{fontWeight: 650,fontSize: '1em',resize: 'none',padding: 0,width: '300px',height: '10 0px',border: 'none'}} value={this.props.props.commons.propertyunit.owner?this.props.props.commons.propertyunit.owner.mailingAddress:''} rows={4} />
            </Col>
          </Row>

          <br/>
          <br/>
          <Row gutter={24}>
            <Col>
              We note your payment is now overdue.Kindly remit the sum of any outstanding payment to avoid further late interest. Failure to do so will result in late payment charges calculate by daily basic.
            </Col>
          </Row>


          <Divider dashed >Detail</Divider>

          <Table
              pagination={false}
              dataSource={dataSource}
              columns={columns}
              rowKey="_id"
              footer={() => (
              <Row>
                <Col style={{float: 'right'}}>
                  <span style={{textAlign: 'center'}} > Total Interest Amount :{totalAmount.toFixed(2)}</span>
                </Col>
              </Row>
          )} />

        </Card>
        <Row gutter={10}>
          <Col span={24}>
              <span>{'The Sum of Amount : **' + this.state.toWordText+' Ringgit Only**'}</span>
          </Col>
        </Row>
      </div>,

      <div>
      <br/>
      <Row gutter={24}>
        <Col >
          Professional fee are due on presentation and payment is to be by crossed cheque made payable to <span>BADAN PENGURUSAN BERSAMA (JMB) TAMAN CASSA MAYA</span>.
          <br />Late interest will be levied on all late payments.
        </Col>
      </Row>
      </div>
      ,
      <div key='pagebreak' className='pageBreak' />
    ])

    return table;
  }

  render() {
    const loading  = this.state.loading;
    return (
        <Spin key='spinning' spinning={loading}>
            {this._renderTable()}
        </Spin>
    );
  }
}

class InterestDebitNote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    }

  }

  render() {
    return (<Layout>
      <Row>
        <Col align='right' style={{
            marginBottom: '5px'
          }}>
          <span>
            <ReactToPrint trigger={() => <Button type='primary'>PRINT</Button>} content={() => this.componentRef}/>
          </span>
        </Col>
      </Row>


      <ComponentToPrint props={this.props} ref={el => (this.componentRef = el)}/>

    </Layout>);
  }
}


function mapStateToProps(state) {
  return {
    commons: state.commons
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateValueIDN: updateValueIDN
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(InterestDebitNote));
