import React from "react";

const SvgBilliard = props => (
  <svg viewBox="0 0 511.992 511.992" width={512} height={512} {...props}>
    <path
      fill="#8D99AE"
      d="M503.691 464.092L287.317 255.424 507.37 27.245c6.178-6.243 6.158-16.348-.061-22.567-6.218-6.217-16.322-6.237-22.566-.06l-228.747 220.6L27.248 4.617C21.005-1.559 10.9-1.539 4.682 4.679s-6.238 16.322-.061 22.566l220.053 228.18L8.3 464.092l-.104.102c-10.917 10.917-10.917 28.681 0 39.598 5.289 5.289 12.32 8.201 19.799 8.201 7.479 0 14.511-2.913 19.799-8.201l.101-.104 208.1-215.785 208.1 215.785.102.104c5.289 5.289 12.32 8.201 19.799 8.201 7.479 0 14.511-2.913 19.799-8.201 10.917-10.917 10.917-28.681 0-39.598l-.104-.102zm-120.812-67.669l13.548-13.548 78.077 75.296-16.328 16.328-75.297-78.076zM495.892 16.094l.104-.102-.102.104-220.093 228.222-8.281-7.986L495.892 16.094zm-479.895-.102l.103.101 368.808 355.673-13.137 13.137L16.097 16.096a9.626 9.626 0 00-.1-.104zm37.82 458.507l-16.328-16.328 78.077-75.296 13.548 13.548-75.297 78.076zm-25.821 21.493a11.919 11.919 0 01-8.485-3.515c-4.664-4.665-4.679-12.244-.043-16.927l6.503-6.271 16.739 16.739-6.273 6.504a11.922 11.922 0 01-8.441 3.47zm112.225-111.088l-13.137-13.137 108.697-104.826 9.101 9.437-104.661 108.526zM492.48 492.477a11.917 11.917 0 01-8.485 3.515 11.916 11.916 0 01-8.44-3.47l-6.273-6.504 16.739-16.739 6.504 6.273c4.635 4.683 4.62 12.262-.045 16.925z"
      data-original="#000000"
      className="billiard_svg__active-path"
      data-old_color="#000000"
    />
  </svg>
);

export default SvgBilliard;
