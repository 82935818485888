import React, { Component } from 'react';
import { Popconfirm, message, Divider,  Table, Form, Icon, Input, Button,Card } from 'antd';
import { Route, Link } from 'react-router-dom';

import client from '../../feathers';
import requireAuth  from "../requireAuth";
import Layout  from "../layout";
import AccountsForm  from "./form";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchAccounts,
  pushAccounts,
  removeAccounts,
  updateMode,
  updateActiveAccounts,
  updateAccounts } from '../../actions/actions-accounts';

const FormItem = Form.Item;

const text = 'Are you sure to delete this item?';

class AccountsIndex extends Component {
  constructor(props) {
    super(props);

    this.handleDelete = this.handleDelete.bind(this);
    this.state = {
      categories:'expenses',
    }
  }

  componentDidUpdate(){
  }

  componentDidMount(){

  }



  componentWillMount(){

      client.authenticate()
      .then((res)=>{

          return client.service('accounts').find({
            query: {
              categories:this.state.categories,
              accountmainId:sessionStorage.getItem('accountmainId'),
               $sort: {
                  accountId: 1
               }
             }
          })
      })
      .then((res)=>{
        this.props.fetchAccounts(res.data)
      })
      .catch((err)=>{
          console.log(err);
      })
  }

  handleDelete(_id) {
     client.service('accounts').remove(_id)
     .then((res)=>{
     })
     .catch((err)=>{
       alert(err);
     })
  }

  handleEdit(_id) {
    this.props.updateActiveAccounts('edit',_id)
  }

  handleManage(_id) {
    this.props.updateActiveAccounts('',_id)
  }

  showModal = () => {
    this.props.updateMode('new')
  }

  renderConfirmDeleteBtn(_id){
    return (
      <Popconfirm placement="topLeft" title={text} onConfirm={()=>this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
        <Button type="danger">Delete</Button>
      </Popconfirm>
    )
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title: 'ACC.No.',
        dataIndex: 'accountId',
        key: 'accountId',
      },
      {
        title: 'A/C Description',
        dataIndex: 'accountName',
        key: 'accountName',
      },
      {
        title: 'Account Type',
        dataIndex: 'accountType',
        key: 'accountType',
      },
      {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
          <Link
            to={{
              pathname: `/ledgers/${record._id}`
            }}
          >
          <Button>Ledgers</Button>
          </Link>
          <Divider type="vertical" />
          <Button onClick={()=>this.handleEdit(record._id)}>Edit</Button>
          <Divider type="vertical" />
          {/*this.renderConfirmDeleteBtn(record._id)*/}
        </span>
      ),
    }];

    return(
      <Layout>
          <Card
            title="Expense"
            extra={<Button type="primary" onClick={this.showModal}>New Accounts</Button>}
          >
            <Link to={{pathname: `/accounts-assest`}} >Assest</Link> | <Link to={{pathname: `/accounts-liabilities`}} >Liabilities</Link> | <Link to={{pathname: `/accounts-equity`}} >Equity</Link> | <Link to={{pathname: `/accounts-income`}} >Income</Link> | <Link to={{pathname: `/accounts-expense`}} >Expenses</Link>

            <Table dataSource={this.props.accounts.accounts} columns={columns} rowKey="_id" />
            <AccountsForm categories={this.state.categories}/>
          </Card>
      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    accounts: state.accounts,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchAccounts: fetchAccounts,
    pushAccounts: pushAccounts,
    removeAccounts: removeAccounts,
    updateActiveAccounts: updateActiveAccounts,
    updateAccounts: updateAccounts,
    updateMode: updateMode
    }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(AccountsIndex)));
