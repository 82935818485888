import React, { Component, useEffect } from 'react'
import { Modal, Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Select, Row, Col, Card } from 'antd'
import client from '../../feathers'
import requireAuth from "../../components/requireAuth"
import Layout from "../../components/layout"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateMode } from '../../actions/actions-complaints'
import _ from 'lodash'
import LightBox from 'react-image-lightbox'
import ImageGallery from 'react-image-gallery';



const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};

const text = 'Are you sure to delete this item?';

class ComplaintsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',
      confirmDirty: false,
      autoCompleteResult: [],
      statusDropdowns: [],
      categoriesDropdowns: [],
      currentImg: 0,
      evidenceList: []
    }
  }

  componentDidUpdate() {
    if (this.props.complaints.activeId !== this.state._id) {
      console.log(this.props.complaints.activeId, 'hiii', this.state._id)
      this.setState({
        _id: this.props.complaints.activeId
      })

      if (this.props.complaints.activeComplaints) {
        // console.log('--33333--', this.props.complaints.activeComplaints.propertyunitId)
        this.props.form.setFieldsValue(
          {
            ...this.props.complaints.activeComplaints,
            email: this.props.complaints.activeComplaints.createdBy.email,
            createdByName: this.props.complaints.activeComplaints.createdBy.firstName + this.props.complaints.activeComplaints.createdBy.lastName,
            phoneNo: this.props.complaints.activeComplaints.createdBy.phoneNo,
            unit: this.props.complaints.activeComplaints.propertyunitId.referenceId
          },
        )
        this.setState({ evidenceList: this.props.complaints.activeComplaints.files })


      }
    }
  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }


  handleSubmit = (e) => {
    e.preventDefault();
    if (this.props.complaints.mode === 'new') {

      this.props.form.validateFields((err, values) => {
        if (!err) {
          client.service('complaints').create(values)
            .then((res) => {

              this.props.updateMode('')
              message.success('New Complaints Added');
            })
            .catch((err) => {
              console.log(err);
              this.props.updateMode('')
              alert(err);
            })
        }
      });
    } else {
      this.props.form.validateFields((err, values) => {

        if (!err) {
          client.service('complaints').patch(this.props.complaints.activeId,
            values
          )
            .then((res) => {

              message.success(`Complaints Updated`);
              this.props.updateMode('refresh')
            })
            .catch((err) => {
              console.log(err);

              this.props.updateMode('')
            })
        }

      });
    }
  }
  _renderImages() {

    let imgList = this.props.complaints.activeComplaints.files ?
      this.props.complaints.activeComplaints.files.map((v, i) => {
        return (
          <Row key={i}>
            <Col span={24}>
              <div className="dot-img" onClick={() => {
                this.setState({ currentImg: i });
                // this.onClickShow() 
              }}>
                {this.state.currentImg === i ?
                  <div className="dot-img-overlay">
                  </div> : null}
                <img alt="something" src={v} />
              </div>
            </Col>
          </Row>

        )

      }) : '';

    return (
      <div className>
        {imgList}
      </div>
    )

  }

  componentWillMount() {

    // client.authenticate()
    //   .then((res) => {

    //     return client.service('parameters').find({
    //       query: {
    //         table: "complaintStatus",
    //         $sort: {
    //           createdAt: -1
    //         }
    //       }
    //     })
    //   })
    //   .then((res) => {

    //     this.setState({
    //       statusDropdowns: res.data[0].items
    //     })
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     this.setState({
    //       statusDropdowns: []
    //     })
    //   })

    this.setState({
      statusDropdowns: ['Pending', 'In progress', 'Solved', 'Rejected'

      ]
    })
    //categories dropdown
    client.authenticate()
      .then((res) => {

        return client.service('parameters').find({
          query: {
            table: "complaintCategories",
            $sort: {
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {
        this.setState({
          categoriesDropdowns: res.data[0].items
        })
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          categoriesDropdowns: []
        })
      })
  }

  renderImages() {
    const { getFieldDecorator } = this.props.form;



    let { files } = this.props.complaints.activeComplaints
    let newImg = []

    _.forEach(files, function (img) {
      newImg.push({
        original: img, thumbnail: img,
        // originalClass: 'wrap-zoom-img' 
      })
    })


    return (
      <Row gutter={8}>


        <Col className="gutter-row" xs={14} sm={14} md={14} lg={14} xl={14}>
          {this.state.evidenceList.length > 0 ?
            <ImageGallery items={newImg} thumbnailPosition='left' showPlayButton={false} showBullets={true} showFullscreenButton={false} />
            : 'No Images found'}

        </Col>

        <Col xs={10} sm={10} md={10} lg={10} xl={10}>
          <Card>


            <Form className="submit-form">

              <FormItem
                {...formItemLayout}
                label="Unit"
              >
                {getFieldDecorator('unit', {
                  rules: [{
                    required: true, message: 'Please input Unit!',
                  }],
                })(
                  <Input disabled={true} placeholder="Unit" />
                )}
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="Categories"
              >
                {getFieldDecorator('categories', {
                  rules: [{
                    required: false, message: 'Please input Categories!',
                  }],
                })(
                  <Select disabled={true} placeholder="Please select Categories">
                    {this.state.categoriesDropdowns.map(dropdown => (
                      <Option key={dropdown.value} value={dropdown.value}>
                        {dropdown.description}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="Subject"
              >
                {getFieldDecorator('subject', {
                  rules: [{
                    required: true, message: 'Please input Subject!',
                  }],
                })(
                  <Input disabled={true} placeholder="Subject" />
                )}
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="Description"
              >
                {getFieldDecorator('description', {
                  rules: [{
                    required: true, message: 'Please input Description!',
                  }],
                })(
                  <TextArea disabled="true" rows={4} />
                )}
              </FormItem>



              <FormItem
                {...formItemLayout}
                label="Created By"
              >
                {getFieldDecorator('createdByName', {
                  rules: [{
                    required: false, message: 'Please input created by!',
                  }],
                })(
                  <Input disabled={true} />
                )}
              </FormItem>



              <FormItem
                {...formItemLayout}
                label="Type"
              >
                {getFieldDecorator('complaintsType', {
                  rules: [{
                    required: false, message: 'Please input Type!',
                  }],
                })(
                  <Select disabled="true" placeholder="Please select Type">
                    <Option value="1">Public</Option>
                    <Option value="2">Private</Option>
                  </Select>
                )}
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="Status"
              >
                {getFieldDecorator('status', {
                  rules: [{
                    required: false, message: 'Please input status!',
                  }],
                })(
                  <Select placeholder="Please select Status">
                    {this.state.statusDropdowns.map(dropdown => (


                      <Option key={dropdown} value={dropdown}>
                        {dropdown}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>

            </Form>


          </Card>
        </Col>


      </Row>
    )




    // if (this.props.complaints.activeComplaints.files) {
    //   if (this.props.complaints.activeComplaints.files.length > 0) {
    //     const { files } = this.props.complaints.activeComplaints
    //     return (
    //       <>
    //         <p style={{ marginLeft: '20px' }} class="ant-form-item-label  label" >Images:</p>
    //         <div style={{ width: '100%', overflow: 'scroll', textAlign: 'center' }}>
    //           <div style={{ width: (files.length * 300) + 'px', textAlign: 'center' }}>
    //             {
    //               files.map(v => {
    //                 return (<img alt='evidence' style={{ width: '300px', height: '200px' }} src={v} />)
    //               })
    //             }
    //           </div>
    //         </div>
    //       </>
    //     )
    //   }
    //   else {
    //     return (
    //       <>
    //         <p style={{ marginLeft: '20px' }} class="ant-form-item-label  label" >Images:</p>
    //         <span>
    //           No images found.
    //     </span>
    //       </>)
    //   }
    // }


  }


  render() {
    const { visible, onCancel } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { activeComplaints } = this.props.complaints

    return (
      <Modal
        visible={
          this.props.complaints.mode === 'new' || this.props.complaints.mode === 'edit' ? true : false
        }
        title={this.props.complaints.mode === 'edit' ? 'Update eFeedback' : 'Create eFeeback'}
        okText={this.props.complaints.mode === 'edit' ? 'Update' : 'Create'}
        onCancel={() => { this.props.updateMode(''); this.props.form.resetFields() }}
        onOk={this.handleSubmit}
        width={1080}
      >
        {this.renderImages()}
        <Form className="submit-form">

          {/* <FormItem
            {...formItemLayout}
            label="Unit"
          >
            {getFieldDecorator('unit', {
              rules: [{
                required: true, message: 'Please input Unit!',
              }],
            })(
              <Input disabled={true} placeholder="Unit" />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Categories"
          >
            {getFieldDecorator('categories', {
              rules: [{p
                required: false, message: 'Please input Categories!',
              }],
            })(
              <Select disabled={true} placeholder="Please select Categories">
                {this.state.categoriesDropdowns.map(dropdown => (
                  <Option key={dropdown.value} value={dropdown.value}>
                    {dropdown.description}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Subject"
          >
            {getFieldDecorator('subject', {
              rules: [{
                required: true, message: 'Please input Subject!',
              }],
            })(
              <Input disabled={true} placeholder="Subject" />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Description"
          >
            {getFieldDecorator('description', {
              rules: [{
                required: true, message: 'Please input Description!',
              }],
            })(
              <TextArea disabled="true" rows={4} />
            )}
          </FormItem>



          <FormItem
            {...formItemLayout}
            label="Created By"
          >
            {getFieldDecorator('createdByName', {
              rules: [{
                required: false, message: 'Please input created by!',
              }],
            })(
              <Input disabled={true} />
            )}
          </FormItem>



          <FormItem
            {...formItemLayout}
            label="Type"
          >
            {getFieldDecorator('complaintsType', {
              rules: [{
                required: false, message: 'Please input Type!',
              }],
            })(
              <Select disabled="true" placeholder="Please select Type">
                <Option value="1">Public</Option>
                <Option value="2">Private</Option>
              </Select>
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Status"
          >
            {getFieldDecorator('status', {
              rules: [{
                required: false, message: 'Please input status!',
              }],
            })(
              <Select placeholder="Please select Status">
                {this.state.statusDropdowns.map(dropdown => (


                  <Option key={dropdown} value={dropdown}>
                    {dropdown}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem> */}

          {/* <FormItem
            {...formItemLayout}
            label="Reply"
          >
            {getFieldDecorator('remark', {
              rules: [{
                required: false, message: 'Please input Remark!',
              }],
            })(
              <TextArea rows={4} />
            )}
          </FormItem> */}
        </Form>
      </Modal >

    )
  }
}


function mapStateToProps(state) {
  return {
    complaints: state.complaints
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(ComplaintsForm)));
