import React, { Component, useEffect } from 'react';
import {
  Modal,
  Upload,
  Popconfirm,
  message,
  Divider,
  Table,
  Form,
  Icon,
  Input,
  Button,
  Select,
  Radio,
  InputNumber,
  Spin,
  Dialog,
  Row,
  notification,
} from 'antd';
import client from '../../feathers';
import requireAuth from "../requireAuth";
import Layout from "../layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateMode } from '../../actions/actions-invoices';
import axios from 'axios';
import { Link, withRouter, Route } from 'react-router-dom';

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
const _ = require('lodash');
// const formatter = new Intl.NumberFormat('en-US', {
//   // style: 'currency',
//   // currency: 'MYR',
//   minimumFractionDigits: 2
// })

const formItemLayout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 16
  }
};
const formTailLayout = {
  labelCol: {
    span: 4
  },
  wrapperCol: {
    span: 8,
    offset: 4
  }
};

const text = 'Are you sure to delete this item?';

class PaymentFormItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',
      confirmDirty: false,
      autoCompleteResult: [],
      mode: this.props.mode,
      visible: false,
      invoice: [],
      loading: false,
      paymentTypeParams: [],
      paymentType: '', //CASH, CREDIT CARD, ONLINE BANKING, CHEQUE
      selectedRowKeys: [],
      invoiceItems: [],
      prepayment: [],
      payAmount: 0,
      prepaymentTotalAmount: 0,
      prepaymentSelectedAmount: 0,
      totalAmount: 0,
      charges: [],
      paymentDate: new Date(),
      prepaymentUA: 0
    }
  }

  componentDidUpdate() {

    if (this.props.payAmount !== this.state.payAmount) {
      this.setState({ payAmount: this.props.payAmount });
    }

    if (this.props.prepaymentUA !== this.state.prepaymentUA) {
      this.setState({ prepaymentUA: this.props.prepaymentUA });
    }

    if (this.props.invoiceItems !== this.state.invoiceItems) {
      this.setState({ invoiceItems: this.props.invoiceItems });
    }

    if (this.props.selectedRowKeys !== this.state.selectedRowKeys) {
      this.setState({ selectedRowKeys: this.props.selectedRowKeys });
    }

    if (this.props.prepayment !== this.state.prepayment) {
      this.setState({ prepayment: this.props.prepayment });
    }

    if (this.props.totalAmount !== this.state.totalAmount) {
      this.setState({ totalAmount: this.props.totalAmount });
    }
    if (this.props.prepaymentTotalAmount !== this.state.prepaymentTotalAmount) {
      this.setState({ prepaymentTotalAmount: this.props.prepaymentTotalAmount });
    }
    if (this.props.prepaymentSelectedAmount !== this.state.prepaymentSelectedAmount) {
      this.setState({ prepaymentSelectedAmount: this.props.prepaymentSelectedAmount });
    }
    if (this.props.paymentDate !== this.state.paymentDate) {
      this.setState({ paymentDate: this.props.paymentDate });
    }

  }

  componentDidMount() { }

  componentWillMount() {

    this.getPaymentTypeParams();
    this.getCharges();

  }

  getCharges() {
    client.authenticate().then((res) => {

      return client.service('charges').find({
        query: {
          propertyId: this.props.commons.selectedCompany._id,

        }
      })
    }).then((res) => {

      this.setState({ charges: res.data });
    }).catch((err) => {
      console.log(err);
    });
  }

  getPaymentTypeParams() {
    client.authenticate().then((res) => {
      return client.service('payment-type-params').find({
        query: {
          propertyId: this.props.commons.selectedCompany._id,
          category: {
            $in: ['INVOICE']
          }
        }
      })
    }).then((res) => {
      this.setState({ paymentTypeParams: res.data });
    }).catch((err) => {
      console.log(err);
    });

  }

  handleConfirmBlur = (e) => { }

  handleChange = (value) => {

    let change = value - this.state.payAmount; //this.props.form.getFieldValue('appliedAmount');

    if (change < 0) {
      this.props.form.setFieldsValue({ change: change });
    } else {
      this.props.form.setFieldsValue({ change: this.formatAmt(change) });
    }

  }

  handleChangePaymentType = (e) => {
    this.setState({ paymentType: e.target.description });

    let tempAmt = 0;
    if (e.target.description === 'CASH') {
      if (this.state.prepayment.length === 0) {
        tempAmt = this.state.prepaymentTotalAmount;
      } else {
        tempAmt = 0.00;
      }
      setTimeout(() => {
        this.props.form.setFieldsValue({ change: tempAmt });
      }, 1000);
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {

      this.state.totalAmount = this.state.totalAmount
        ? this.state.totalAmount
        : 0;
      this.state.prepaymentTotalAmount = this.state.prepaymentTotalAmount
        ? this.state.prepaymentTotalAmount
        : 0;
      this.state.payAmount = this.state.payAmount
        ? this.state.payAmount
        : 0;
      this.state.prepaymentUA = this.state.prepaymentUA
        ? this.state.prepaymentUA
        : 0;

      if (!err) {

        let dataToSend = [];
        for (let i = 0; i < this.state.invoiceItems.length; i++) {
          dataToSend.push({ invoiceItemId: this.state.invoiceItems[i]._id, knockOffAmount: this.state.invoiceItems[i].knockOffAmount });
        }

        let custInfo = {
          propertyunitId: this.props.propertyunitId,
          customerType: 'PROPERTYUNIT',
          invoiceItems: dataToSend
        };

        client.service('transactions').create({
          ...values,
          ...custInfo,
          appliedAmount: values.change
            ? parseFloat(this.state.payAmount) - parseFloat(values.change)
            : this.state.payAmount,
          payAmount: values.change
            ? parseFloat(this.state.payAmount) - parseFloat(values.change)
            : this.state.payAmount,
          prepayment: this.state.prepayment,
          prepaymentUA: this.state.prepaymentUA,
          change: values.change
            ? values.change
            : 0,
          currency: 'MYR',
          status: 'SUCCESS', //PENDING,SUCCESS, FAILED
          propertyId: this.props.commons.selectedCompany._id,
          channel: 'WEB', //APPS, WEB
          paymentDate: this.state.paymentDate
        })
        this.props.history.push('/propertyunitsmng/');
        notification.open({
          message: 'Successfully submitted!',
          description:
            'The payment is in progress...',
          duration: 5,
          // onClick: () => {
          // },
        });
        // }).then((res) => {
        //   this.props.history.push('/transactions/' + res._id);
        //   message.success('Paid');
        //   this.setState({visible: false});
        // }).catch((err) => {
        //   alert(err);
        //   console.log(err);
        // })
      }
    });
  }

  showModal = () => {
    this.setState({ visible: true });
  }

  handleCancel = (e) => {

    this.setState({ visible: false });
  }

  formatAmt(amt) {
    let tempAmt = amt;

    return tempAmt > 0
      ? tempAmt.toFixed(2)
      : 0.00;
  }

  handleFileUpload = (event) => {

    this.setState({ loading: true });
    let formData = new FormData();

    formData.append('image', event.target.files[0]);

    axios.post(`${client.io.io.uri}upload-transactions`, formData, {
      headers: {
        'Authorization': client.settings.storage.storage['feathers-jwt'],
        'Content-Type': 'multipart/form-data'
      }
    }).then((res) => {

      this.props.form.setFieldsValue({ file: res.data.imageUrl });
      this.setState({ loading: false });
      message.success('Upload Successful');
    }).catch((err) => {
      console.log(err);
      this.setState({ loading: false });
      message.error(err);
    })
  }

  renderPaymentField() {
    const { getFieldDecorator } = this.props.form;

    if (this.state.paymentType === 'CREDIT CARD') {
      return ([
        <FormItem key='ccType' {...formItemLayout} label="Select">
          {
            getFieldDecorator('ccType', {
              rules: [
                {
                  required: false,
                  message: 'Please select VISA/MASTER!'
                }
              ]
            })(<Radio.Group>
              <Radio.Button value="VISA">VISA</Radio.Button>
              <Radio.Button value="MASTER">MASTER</Radio.Button>
            </Radio.Group>)
          }
        </FormItem>,
        <FormItem key='ccNumber' {...formItemLayout} label="Credit Card No.">
          {
            getFieldDecorator('ccNumber', {
              rules: [
                {
                  required: false,
                  message: 'Please input Credit Card Number!'
                }
              ]
            })(<Input placeholder="542323893838" />)
          }
        </FormItem>
      ]);
    } else if (this.state.paymentType === 'CASH') {

      return ([<FormItem key='changes' {...formItemLayout} label="Change">
        {
          getFieldDecorator('change', {
            rules: [
              {
                required: false,
                message: 'Please input change!'
              }
            ]
          })(<Input disabled="disabled" />)
        }
      </FormItem>
      ]);

    } else if (this.state.paymentType === 'ONLINE BANKING') {
      return ([
        <FormItem key='Upload' {...formItemLayout} label="Upload">
          <Input label='upload file' type='file' onChange={this.handleFileUpload} />
        </FormItem>,

      ]);
    } else if (this.state.paymentType === 'CHEQUE') {
      return ([
        <FormItem key='Upload' {...formItemLayout} label="Upload">
          <Input label='upload file' type='file' onChange={this.handleFileUpload} />
        </FormItem>,

        <FormItem key='file' {...formItemLayout} label="File Name" style={{
          'display': 'none'
        }}>
          {
            getFieldDecorator('file', {
              rules: [
                {
                  required: false,
                  message: 'Please Upload File!'
                }
              ]
            })(<Input style={{
              'display': 'none'
            }} disabled="disabled" placeholder="File Name" />)
          }
        </FormItem>,

        <FormItem key='chequeNo' {...formItemLayout} label="Cheque No.">
          {
            getFieldDecorator('chequeNo', {
              rules: [
                {
                  required: true,
                  message: 'Please input Cheque No.!'
                }
              ]
            })(<Input />)
          }
        </FormItem>,

        <FormItem key='chequeDate' {...formItemLayout} label="Cheque Date.">
          {
            getFieldDecorator('chequeDate', {
              rules: [
                {
                  required: true,
                  message: 'Please input Cheque Date!'
                }
              ]
            })(<Input type='date' />)
          }
        </FormItem>
      ]);
    }

  }

  render() {
    const { visible, onCancel } = this.props;
    const { getFieldDecorator } = this.props.form;

    const knockOffItemsColumns = [
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description'
      }, {
        title: 'Knock Off Amount',
        dataIndex: 'knockOffAmount',
        key: 'knockOffAmount',
        render: text => <span style={{ 'float': 'right' }}>{text}</span>
      }
    ];
    const prepaymentItemsColumns = [
      {
        title: 'Charges',
        dataIndex: 'chargeId',
        key: 'chargeId',
        render: (text, record) => (<Select value={record.chargeId} style={{
          width: '100%'
        }} disabled={true}>
          {
            this.state.charges.map(dropdown => (<Option value={dropdown._id}>
              {dropdown.code}
            </Option>))
          }
        </Select>)
      }, {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        render: text => <span style={{ 'float': 'right' }}>{parseFloat(text).toFixed(2)}</span>
      }, {
        title: 'Year',
        dataIndex: 'year',
        key: 'year'
      }, {
        title: 'Month',
        key: 'month',
        dataIndex: 'month'
      }
    ];

    return ([

      <Spin key='spinning' spinning={this.state.loading}>
        <Form className="submit-form" onSubmit={this.handleSubmi}>

          <Button key='cancellation' type="primary" onClick={this.showModal} htmlType="submit" style={{
            float: 'right'
          }}>Pay</Button>
        </Form>
      </Spin>,
      <Modal title="Confirmation" visible={this.state.visible}
        onCancel={this.handleCancel} key='mainModal' footer={[
          <Button key='cancellation' onClick={this.handleCancel}>Cancel</Button>,
          <Popconfirm key='confirmation' title="Sure to Pay?" onConfirm={this.handleSubmit}>
            <Button key='submitbutton' type='primary'>Submit</Button>
          </Popconfirm>
        ]}>
        <Table columns={knockOffItemsColumns} dataSource={this.state.invoiceItems} title={() => <span>KnockOff Item</span>} pagination={false} rowKey="_id" />
        {this.state.prepayment.length > 0 ? <Table columns={prepaymentItemsColumns} dataSource={this.state.prepayment} title={() => <span>Prepayment Item by Allocation</span>} pagination={false} rowKey="_id" /> : ''}
        <br />

        {this.state.prepaymentUA > 0 ?
          <div>
            <Row><span>Prepayment Item by Amount</span></Row>
            <br />
            <Row><span>
              <Input style={{ 'textAlign': 'right' }} value={parseFloat(this.state.prepaymentUA).toFixed(2)} disabled />
            </span>
            </Row>
          </div>
          : ''}

      </Modal>

    ])
  }
}

function mapStateToProps(state) {
  return {
    invoices: state.invoices,
    commons: state.commons
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(withRouter(Form.create()(PaymentFormItems))));
