export const FETCH_PROPERTYUNITS = 'FETCH_PROPERTYUNITS';
export const PUSH_PROPERTYUNITS = 'PUSH_PROPERTYUNITS';
export const REMOVE_PROPERTYUNITS = 'REMOVE_PROPERTYUNITS';
export const UPDATE_PROPERTYUNITS = 'UPDATE_PROPERTYUNITS';
export const UPDATE_MODE = 'UPDATE_MODE';
export const UPDATE_ACTIVE_ID = 'UPDATE_ACTIVE_ID';

export function fetchPropertyunits(data){
  return (dispatch) => {
    dispatch({
      type: FETCH_PROPERTYUNITS,
      payload:data
    });
  }
}

export function pushPropertyunits(data){
  return (dispatch) => {
    dispatch({
      type: PUSH_PROPERTYUNITS,
      payload:data
    });
  }
}

export function removePropertyunits(data){
  return (dispatch) => {
    dispatch({
      type: REMOVE_PROPERTYUNITS,
      payload:data
    });
  }
}

export function updatePropertyunits(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_PROPERTYUNITS,
      payload:data
    });
  }
}

export function updateMode(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE,
      payload:data
    });
  }
}

export function updateActivePropertyunits(mode, id){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_ID,
      mode,
      id
    });
  }
}
