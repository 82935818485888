import {
  Modal,
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Icon,
  Input,
  message,
  Popconfirm,
  Row,
  Select,
  Spin,
  Table,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  updateActiveReceipts,
  updateMode,
} from "../../actions/actions-receipts";
import requireAuth from "../../components/requireAuth";
import FormCN from "../../components/receipts/form-modal-cn";
import client from "../../feathers";
import Layout from "../layout";
import { values } from "lodash";
//------------------------------------------------------------
const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
const _ = require("lodash");
const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

//------------------------------------------------------------
class ReceiptsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: "",
      confirmDirty: false,
      autoCompleteResult: [],
      accountAssetsDropdowns: [],
      accountExpensesDropdowns: [],
      suppliers: [],
      newMerchant: "",
      loading: false,
      amount: 0,
      totalAmount: 0,
      creditledgerAccountId: "",
      creditledgerAccountName: "",
      selectedCategory: "",
      count: 0,
      receiptItems: [],
      expenses: [],
      activeItem: {},
      showModalCN: false,
      currentSupplier: "",
      submitNow: false,
      checkingReceiptItem: [],
      selected: 0,
    };
  }
  //------------------------------------------------------------

  componentWillMount() {
    if (
      (!_.isEmpty(this.props.receipts.activeId) &&
        this.props.receipts.mode === "edit") ||
      (!_.isEmpty(this.props.receipts.activeId) &&
        this.props.receipts.mode === "view") ||
      (!_.isEmpty(this.props.receipts.activeId) &&
        this.props.receipts.mode === "copy")
    ) {
      this.getReceiptItems();
    }
    //generate dropdown
    client
      .authenticate()
      .then((res) => {
        return client.service("ledger-accounts").find({
          query: {
            companyId: this.props.commons.selectedCompany._id,
            categories: "ASSETS",
            $or: [
              { accountNo: { $in: ["3130", "3120", "3110"] } },
              { bankAccountFlag: true },
            ],
            $sort: {
              description: 1,
            },
          },
        });
      })
      .then((res) => {
        this.setState({
          accountAssetsDropdowns: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          accountAssetsDropdowns: [],
        });
      });
    //generate dropdown category
    client
      .authenticate()
      .then((res) => {
        return client.service("ledger-accounts").find({
          query: {
            companyId: this.props.commons.selectedCompany._id,
            //categories:{$in:['EXPENSES','ASSETS']},
            accountNo: { $nin: ["3000", "4000", "3110", "3150", "3130"] },
            $sort: {
              description: 1,
            },
          },
        });
      })
      .then((res) => {
        this.setState({
          accountExpensesDropdowns: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          accountExpensesDropdowns: [],
        });
      });

    this.handleGetSupplierDropdown();
  }

  async componentDidUpdate() {
    if (this.props.receipts.activeId !== this.state._id) {
      this.setState({
        _id: this.props.receipts.activeId,
      });

      if (!_.isEmpty(this.props.receipts.activeReceipts)) {
        this.setState({
          totalAmount: this.props.receipts.activeReceipts.totalAmount,
          currentSupplier: _.get(this.props.receipts.activeReceipts, [
            "supplierId",
          ]),
          expenses: {
            remark: this.props.receipts.activeReceipts.remark,
            // amount: this.props.receipts.activeReceipts.amount.toFixed(2),
            // name: this.props.receipts.activeReceipts.debitledgerAccountId.description,
          },
          creditledgerAccountId: this.props.receipts.activeReceipts
            .creditledgerAccountId,
        });
        this.props.form.setFieldsValue({
          date: moment(this.props.receipts.activeReceipts.date),
          receiptNo: this.props.receipts.activeReceipts.receiptNo,
          // amount: this.props.receipts.activeReceipts.amount.toFixed(2),
          totalAmount: this.props.receipts.activeReceipts.totalAmount,
          remark: this.props.receipts.activeReceipts.remark,
          supplierId: _.get(this.props.receipts.activeReceipts.supplierId, [
            "_id",
          ]),
          creditledgerAccountId: this.props.receipts.activeReceipts
            .creditledgerAccountId,
        });
      }
    }

    if (this.props.receipts.mode === "refresh") {
      this.getReceiptItems();
      this.props.updateActiveReceipts("edit", this.state._id);
    }
  }
  //------------------------------------------------------------
  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };
  //------------------------------------------------------------
  handleSubmit = (e) => {
    e.preventDefault();

    let newTotal = this.calculateTotalAmount();
    let creditledgerAccountId = this.state.creditledgerAccountId;

    if (this.state.receiptItems.length === 0) {
      return message.error("No item is selected");
    }

    if (this.props.receipts.mode !== "edit") {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          values.totalAmount = newTotal;
          values.creditledgerAccountId = creditledgerAccountId;

          values.date = moment(values.date).format("YYYY-MM-DD");

          const fieldValues = {
            ...values,
            status: "OPEN",
            propertyId: this.props.commons.selectedCompany._id,
          };
          client
            .service("receipts")
            .create(fieldValues, {
              query: {
                $client: {
                  receiptItems: this.state.receiptItems,
                },
              },
            })
            .then((res) => {
              this.props.history.push("/receipts");
              // this.props.updateActiveReceipts('refresh', '');
              message.success("New Receipts Added");
              this.props.form.resetFields();
            })
            .catch((err) => {
              message.error(err.message);
              if (this.props.receipts.mode !== "copy") {
                this.props.updateActiveReceipts("refresh", "");
              }
              // alert(err);
            });
        }
      });
    } else {
      this.props.form.validateFields((err, values) => {
        values.totalAmount = newTotal;
        if (!err) {
          values.date = moment(values.date);

          client
            .service("receipts")
            .patch(this.props.receipts.activeId, values)
            .then((res) => {
              const checkAdded = this.state.receiptItems.filter(
                (i) => !i.alreadyCreates
              );
              if (res) {
                for (let i = 0; i < this.state.receiptItems.length; i++) {
                  if (!this.state.receiptItems[i].alreadyCreates) {
                    const fieldValues = {
                      receiptId: res._id,
                      debitledgerAccountId: this.state.receiptItems[i]
                        .debitledgerAccountId,
                      creditledgerAccountId: res.creditledgerAccountId,
                      supplierId: res.supplierId,
                      remark: this.state.receiptItems[i].remark,
                      amount: this.state.receiptItems[i].amount,
                      date: values.date._d.toString(),
                      knockOffAmount: 0,
                      sortOrder: i,
                      totalPay: 0,
                      status: "OPEN",
                      propertyId: this.props.commons.selectedCompany._id,
                    };
                    console.log("get time", fieldValues);

                    client
                      .service("receipt-items")
                      .create(fieldValues)
                      .then(() => {
                        message.success(`Receipts Updated`);
                        // this.props.updateActiveReceipts('refresh', '')
                        this.props.history.push("/receipts");
                      })
                      .catch((err) => {
                        console.log(err);

                        this.props.updateMode("");
                      });
                  } else {
                    client
                      .service("receipt-items")
                      .patch(this.state.receiptItems[i]._id, {
                        date: values.date.toString(),
                      })
                      .then(() => {
                        message.success(`Receipts Updated`);
                        // this.props.updateActiveReceipts('refresh', '')
                        this.props.history.push("/receipts");
                      });
                  }
                }
              }
            })
            .catch((err) => {
              message.error(err.message);
              this.props.updateActiveReceipts("refresh", "");
            });
          return;
        }
      });
    }
  };
  //------------------------------------------------------------
  handleFileUpload = (event) => {
    this.setState({ loading: true });

    let formData = new FormData();

    formData.append("image", event.target.files[0]);

    axios
      .post(`${client.io.io.uri}upload-receipts`, formData, {
        headers: {
          Authorization: client.settings.storage.storage["feathers-jwt"],
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        this.props.form.setFieldsValue({
          file: res.data.imageUrl,
        });
        this.setState({ loading: false });
        message.success("Upload Successful");
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
        message.error(err);
      });
  };

  getSelectedItem(index) {
    if (
      (this.props.receipts.mode === "copy" ||
        this.props.receipts.mode === "edit") &&
      this.state.receiptItems
    ) {
      this.props.form.setFieldsValue({
        amount: this.state.receiptItems[index].amount,
        debitledgerAccountId: this.state.receiptItems[index]
          .debitledgerAccountId,
        remark: this.state.receiptItems[index].remark,
      });
      this.setState({
        selected: index,
        expenses: {
          debitledgerAccountId: this.state.receiptItems[index]
            .debitledgerAccountId,
          remark: this.state.receiptItems[index].remark,
          name: this.state.receiptItems[index].debitAccountName,
          amount: this.state.receiptItems[index].amount,
        },
      });
    }
  }
  getReceiptItems() {
    client.authenticate().then(() => {
      client
        .service("receipt-items")
        .find({
          query: {
            receiptId: this.props.receipts.activeId,
            $populate: "debitledgerAccountId",
            $sort: {
              sortOrder: -1,
            },
          },
        })
        .then((res) => {
          if (!_.isEmpty(res.data)) {
            let reciptDate = this.props.receipts.activeReceipts.date;
            let checkCopyMode = this.props.receipts.mode === "copy";

            let newStateArr = [];
            newStateArr = _.map(res.data, function(a, i) {
              a.key = i;
              a.date = reciptDate;
              a.amount = a.amount.toFixed(2);
              a.receiptType = _.get(a, ["receiptType"], "");
              a.debitAccountName = a.debitledgerAccountId.description;
              a.debitledgerAccountId = a.debitledgerAccountId._id;
              a._id = a._id;
              a.alreadyCreates = true;
              a.status = checkCopyMode ? "OPEN" : a.status;

              return a;
            });

            this.setState(
              {
                receiptItems: newStateArr,
                count: newStateArr.length,
              },
              () => {
                let newAmount = this.calculateTotalAmount();
                this.props.form.setFieldsValue({
                  totalAmount: newAmount,
                });
              }
            );

            this.getSelectedItem(0);
          }
        });
    });
  }
  //------------------------------------------------------------

  calculateTotalAmount() {
    let cloneDeepReceipt = _.cloneDeep(this.state.receiptItems);

    let newTotal = 0;

    for (let i = 0; i < cloneDeepReceipt.length; i++) {
      if (cloneDeepReceipt[i].receiptType === "1") {
        cloneDeepReceipt[i].amount = -cloneDeepReceipt[i].amount;
      }

      newTotal += parseFloat(cloneDeepReceipt[i].amount);
    }
    return newTotal;
  }
  renderPartialStatus(record) {
    const { receiptItems } = this.state;
    let creditNoteList = _.filter(receiptItems, function(o) {
      return o.receiptType === "1";
    });

    let status = record.status;

    _.forEach(creditNoteList, function(a) {
      if (a.receiptItemId === record._id && record.status === "OPEN") {
        status = "PARTIAL";
      }
    });

    return status;
  }

  handleGetSupplierDropdown = () => {
    //generate suppliers dropdown
    client
      .authenticate()
      .then((res) => {
        return client.service("suppliers").find({
          query: {
            propertyId: this.props.commons.selectedCompany._id,
            $populate: "ledgerAccountId",
            $sort: {
              supplierCode: 1,
            },
          },
        });
      })
      .then((res) => {
        // console.log({res});
        this.setState({
          suppliers: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //-------------------------------------------------------------------
  handleSaveMerchant = () => {
    client
      .authenticate()
      .then((res) => {
        return client.service("suppliers").create({
          name: this.state.newMerchant,
          propertyId: this.props.commons.selectedCompany._id,
        });
      })
      .then((res) => {
        message.success("New Merchant Added");
        this.handleGetSupplierDropdown();

        this.props.form.setFieldsValue({
          merchantName: res._id,
        });
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };
  //-------------------------------------------------------------------
  handleChange = (value) => {
    var result = this.state.suppliers.filter((obj) => {
      return obj._id === value;
    });

    this.setState({
      creditledgerAccountId: result[0].ledgerAccountId._id,
      creditledgerAccountName: result[0].ledgerAccountId.accountName,
      currentSupplier: result[0],
    });

    this.props.form.setFieldsValue({
      creditledgerAccountId: result[0].ledgerAccountId.accountName,
    });
  };
  //-------------------------------------------------------------------
  handleSearch = (value) => {
    this.setState({
      newMerchant: value,
    });
  };
  //-------------------------------------------------------------------
  handleChangeSubTotalAmt = (e) => {
    let tempId = this.props.form.getFieldValue("debitledgerAccountId");
    let tempName = _.find(this.state.accountExpensesDropdowns, function(a) {
      return a._id === tempId;
    });
    this.setState({
      expenses: {
        debitledgerAccountId: this.props.form.getFieldValue(
          "debitledgerAccountId"
        ),
        name: _.get(tempName, ["description"]),
        remark: this.props.form.getFieldValue("remark"),
        amount: e.target.value,
      },
      amount: e.target.value,
    });
    typingTimeout: setTimeout(() => {
      this.handleChangeTotalAmt();
    }, 500);
  };
  //-------------------------------------------------------------------
  // handleChangeTaxAmount = (e) => {
  //   this.setState({
  //     taxAmount: e.target.value
  //   });
  //   typingTimeout: setTimeout(() => {
  //     this.handleChangeTotalAmt()
  //   }, 500)
  // }

  handleChangeTotalAmt = () => {
    if (this.state.count === 0) {
      this.setState({
        totalAmount: parseFloat(this.state.amount),
      });
      this.props.form.setFieldsValue({ totalAmount: this.state.totalAmount });
    }
  };

  handleClick = () => {
    if (this.state.count !== 0) {
      this.setState(
        {
          totalAmount:
            this.state.totalAmount + parseFloat(this.state.expenses.amount),
        },
        () => {
          this.props.form.setFieldsValue({
            totalAmount: this.state.totalAmount,
          });
        }
      );
    }

    const newData = {
      key: this.state.count,
      debitledgerAccountId: this.state.expenses.debitledgerAccountId,
      supplierId: this.state.currentSupplier._id,
      debitAccountName: this.state.expenses.name,
      remark: this.state.expenses.remark,
      amount: this.state.expenses.amount,
      date: this.props.form.getFieldValue("date"),
    };
    this.setState({
      receiptItems: [...this.state.receiptItems, newData],
      count: this.state.count + 1,
    });
    this.props.form.setFieldsValue({
      amount: "",
      remark: "",
      debitledgerAccountId: "",
    });
  };
  handleDeleteItem = (record) => {
    const dataSource = [...this.state.receiptItems];
    this.setState({
      receiptItems: dataSource.filter((item) => item.key !== record.key),
    });
    this.setState(
      {
        totalAmount:
          parseFloat(this.state.totalAmount) - parseFloat(record.amount),
      },
      () => {
        this.props.form.setFieldsValue({ totalAmount: this.state.totalAmount });
      }
    );
  };

  handleDeleteReceiptItems = (record) => {
    const dataSource = [...this.state.receiptItems];

    if (this.props.receipts.mode !== "copy") {
      client.service("receipt-items").remove(record._id);
    }

    this.setState(
      { receiptItems: dataSource.filter((item) => item._id !== record._id) },
      () => {
        let newTotal = this.calculateTotalAmount();
        this.setState({ totalAmount: newTotal });
        this.props.form.setFieldsValue({ totalAmount: newTotal });
      }
    );
  };

  renderDeleteIcon(record) {
    if (this.props.receipts.mode === "edit") {
      return (
        <Popconfirm
          title="Sure to delete? It will delete the "
          onConfirm={() => {
            this.handleDeleteReceiptItems(record);
          }}
        >
          <Icon style={{ marginBottom: 20 }} type="delete" />
        </Popconfirm>
      );
    } else if (
      this.props.receipts.mode === "new" ||
      this.props.receipts.mode === "copy"
    ) {
      return (
        <Popconfirm
          title="Sure to Delete?"
          onConfirm={() => this.handleDeleteItem(record)}
        >
          <Icon style={{ marginBottom: 20 }} type="delete" />
        </Popconfirm>
      );
    }
  }

  //----------------------------------------------------------
  render() {
    const { getFieldDecorator } = this.props.form;
    const checkMode = this.props.receipts.mode;

    const { accountExpensesDropdowns } = this.state;
    const imageUrl = this.props.form.getFieldValue("file");

    const columns = [
      {
        title: "Description",
        dataIndex: "remark",
        key: "description",
        render: (text, record, index) => {
          return (
            <div style={{ alignItems: "center", flexDirection: "row" }}>
              {_.isEmpty(record.receiptType) && checkMode !== "new" ? (
                <Button
                  onClick={() => {
                    this.getSelectedItem(index);
                  }}
                  style={{
                    marginRight: 10,
                    paddingLeft: 5,
                    paddingRight: 5,
                    borderRadius: 25,
                    height: 20,
                  }}
                >
                  <div
                    className={
                      this.state.selected === index
                        ? "button-inner"
                        : "button-diselected"
                    }
                  />
                </Button>
              ) : (
                ""
              )}
              <span>{text}</span>
            </div>
          );
        },
      },

      {
        title: "Expenses Category",
        dataIndex: "debitAccountName",
        key: "chargeCode",
      },

      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        align: "right",
        render: (text, record) => {
          if (record.receiptType === "1") {
            return parseFloat(-text).toFixed(2);
          } else {
            return text;
          }
        },
      },

      {
        title: "Status",
        dataIndex: "status",
        render: (text, record) => {
          let checkStatus = this.renderPartialStatus(record);

          if (checkStatus === "CLOSED") {
            return "PAID";
          } else if (checkStatus === "PARTIAL") {
            return "PARTIAL";
          } else {
            return "UNPAID";
          }
        },
      },
      {
        title: "Action",
        key: "action",
        align: "center",
        // render: (text, record) => (
        //   <Button onClick={()=>this.handleDeleteItem(record._id)} >X</Button>
        // ),
        render: (text, record, index) => {
          // console.log({ record,text,index });
          return (
            <div style={{ alignItems: "center" }}>
              {_.isEmpty(record.receiptType) && checkMode === "edit" ? (
                <Button
                  onClick={() =>
                    this.setState({ activeItem: record, showModalCN: true })
                  }
                  style={{ marginRight: 10 }}
                  size="small"
                  type="primary"
                >
                  CN
                </Button>
              ) : (
                ""
              )}
              {this.renderDeleteIcon(record)}
            </div>
          );
        },
      },
    ];

    const {
      activeItem,
      showModalCN,
      currentSupplier,
      receiptItems,
    } = this.state;

    let disableMode = {};
    let isRequired = true;
    let addItemRequired = true;

    if (checkMode === "view") {
      disableMode = { disabled: true };
      isRequired = false;
    }
    if (receiptItems.length > 0) {
      addItemRequired = false;
    }

    return (
      <Layout>
        {/* CN Form */}
        <Modal
          title="Credit Notes Items"
          centered
          // visible={activeItem?true:false}
          visible={showModalCN}
          onOk={() => {
            this.setState({ showModalCN: false });
            this.props.updateMode("createcn");
          }}
          onCancel={() => this.setState({ showModalCN: false })}
        >
          <FormCN
            activeItem={activeItem}
            currentSupplier={currentSupplier}
            submitNow={this.state.submitNow}
            receiptItems={this.state.receiptItems}
            parentReceipt={this.props.receipts.activeReceipts}
          />
        </Modal>

        <Spin spinning={this.state.loading}>
          <Card
            title="Supplier Invoices"
            extra={
              this.props.receipts.mode !== "view" ? (
                <Button type="danger" onClick={this.handleSubmit}>
                  {this.props.receipts.mode === "new" ||
                  this.props.receipts.mode === "copy"
                    ? "Create"
                    : "Update"}
                </Button>
              ) : null
            }
          >
            <Form className="submit-form">
              <Row>
                <Col span={12}>
                  <FormItem {...formItemLayout} label="Upload">
                    <Input
                      {...disableMode}
                      label="upload file"
                      type="file"
                      onChange={this.handleFileUpload}
                    />
                  </FormItem>

                  <FormItem
                    {...formItemLayout}
                    label="File Name"
                    style={{ display: "none" }}
                  >
                    {getFieldDecorator("file", {
                      rules: [
                        {
                          required: false,
                          message: "Please Upload File!",
                        },
                      ],
                    })(
                      <Input
                        style={{ display: "none" }}
                        disabled={true}
                        placeholder="File Name"
                      />
                    )}
                  </FormItem>

                  <FormItem {...formItemLayout} label="Date">
                    {getFieldDecorator("date", {
                      rules: [
                        {
                          required: isRequired ? true : false,
                          message: "Please input date!",
                        },
                      ],
                    })(<DatePicker {...disableMode} />)}
                  </FormItem>

                  <FormItem {...formItemLayout} label="Supplier">
                    {getFieldDecorator("supplierId", {
                      rules: [
                        {
                          required: isRequired ? true : false,
                          message: "Please select Supplier!",
                        },
                      ],
                    })(
                      <Select
                        disabled={
                          this.props.receipts.mode === "new" ? false : true
                        }
                        showSearch
                        placeholder="Select a Supplier"
                        defaultActiveFirstOption={false}
                        //notFoundContent={[<Button onClick={this.handleSaveMerchant} type="danger">+</Button>,' Create a New Merchant']}
                        onChange={this.handleChange}
                        onSearch={this.handleSearch}
                        //optionFilterProp="children"
                        //filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        showArrow={false}
                      >
                        {this.state.suppliers.map((dropdown) => (
                          <Option key={dropdown._id} value={dropdown._id}>
                            {dropdown.supplierCode + "-" + dropdown.name}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>

                  <FormItem {...formItemLayout} label="Bill / Inovice No.">
                    {getFieldDecorator("receiptNo", {
                      rules: [
                        {
                          required: isRequired ? true : false,
                          message: "Please input Invoice No.!",
                        },
                      ],
                    })(<Input {...disableMode} placeholder="INV0001" />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem {...formItemLayout} label="Amount">
                    {getFieldDecorator("amount", {
                      rules: [
                        {
                          required: addItemRequired ? true : false,
                          message: "Please input SubTotal!",
                        },
                      ],
                    })(
                      <Input
                        {...disableMode}
                        placeholder="9.00"
                        onChange={this.handleChangeSubTotalAmt}
                      />
                    )}
                  </FormItem>

                  {/* <FormItem
                  {...formItemLayout}
                  label="Tax Amount"
                >
                  {getFieldDecorator('taxAmount', {
                    rules: [{
                      required: true, message: 'Please input Tax Amount!',
                    }],
                  })(
                    <Input placeholder="1.00" onChange={this.handleChangeTaxAmount} />
                  )}
                </FormItem> */}

                  <FormItem
                    key="totalAmount"
                    {...formItemLayout}
                    label="Invoice Amount"
                  >
                    {getFieldDecorator("totalAmount", {
                      rules: [
                        {
                          required:
                            this.props.receipts.mode !== "edit" ? true : false,
                          message: "Please input Amount!",
                        },
                      ],
                    })(<Input disabled />)}
                  </FormItem>

                  <FormItem {...formItemLayout} label="Category">
                    {getFieldDecorator("debitledgerAccountId", {
                      rules: [
                        {
                          required: addItemRequired
                            ? isRequired
                            : addItemRequired,
                          message: "Please input category!",
                        },
                      ],
                    })(
                      <Select
                        placeholder="Please select category"
                        showSearch
                        {...disableMode}
                        dropdownMatchSelectWidth={false}
                        allowClear
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(e) => {
                          let tempName = _.find(
                            this.state.accountExpensesDropdowns,
                            function(a) {
                              return a._id === e;
                            }
                          );

                          this.setState({
                            expenses: {
                              debitledgerAccountId: e,
                              remark: this.props.form.getFieldValue("remark"),
                              name: tempName && tempName.description,
                              amount: this.props.form.getFieldValue("amount"),
                            },
                          });
                        }}
                      >
                        {accountExpensesDropdowns.map((i) => (
                          <Option key={i._id} value={i._id}>
                            {`${i.description} - ${i.fullAccountNo}`}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>

                  <FormItem {...formItemLayout} label="Description">
                    {getFieldDecorator("remark", {
                      rules: [
                        {
                          required: addItemRequired
                            ? isRequired
                            : addItemRequired,
                          message: "Please input Description!",
                        },
                      ],
                    })(
                      <TextArea
                        {...disableMode}
                        ows={4}
                        onChange={(e) => {
                          let tempId = this.props.form.getFieldValue(
                            "debitledgerAccountId"
                          );
                          let tempName = _.find(
                            this.state.accountExpensesDropdowns,
                            function(a) {
                              return a._id === tempId;
                            }
                          );

                          this.setState({
                            expenses: {
                              debitledgerAccountId: this.props.form.getFieldValue(
                                "debitledgerAccountId"
                              ),
                              name: tempName && tempName.description,
                              remark: e.target.value,
                              amount: this.props.form.getFieldValue("amount"),
                            },
                          });
                        }}
                      />
                    )}
                  </FormItem>
                  <Row>
                    <Col span={20}></Col>
                    <Col span={4}>
                      {/* {this.props.receipts.mode !== "edit" ? ( */}
                      {this.props.receipts.mode !== "view" ? (
                        <Button type="primary" onClick={this.handleClick}>
                          Add
                        </Button>
                      ) : null}
                      {/* ) : (
                        ""
                      )} */}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
            <br />
            <Table dataSource={this.state.receiptItems} columns={columns} />
          </Card>
        </Spin>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    receipts: state.receipts,
    commons: state.commons,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateMode: updateMode,
      updateActiveReceipts: updateActiveReceipts,
    },
    dispatch
  );
}

export default requireAuth(
  connect(mapStateToProps, mapDispatchToProps)(Form.create()(ReceiptsForm))
);
