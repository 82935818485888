import React from "react";

const SvgFootball = props => (
  <svg viewBox="0 0 64 64" width={512} height={512} {...props}>
    <path
      fill="#8D99AE"
      d="M32 0C14.355 0 0 14.355 0 32s14.355 32 32 32 32-14.355 32-32S49.645 0 32 0zm29.624 36.731l-3.885-6.439 2.681-7.88a29.867 29.867 0 011.204 14.319zm-7.558 15.567a.994.994 0 00-.408-.02L43.98 53.83a.993.993 0 00-.123-.345l-5.502-9.17 8.896-13.7h8.428a.992.992 0 00.105.312l5.236 8.678a29.956 29.956 0 01-6.954 12.693zm-10.085 3.557l7.688-1.232a29.958 29.958 0 01-11.706 6.296l4.018-5.064zM12.65 9.1a29.858 29.858 0 0118.628-7.082.982.982 0 00.24.376l5.525 5.214-2.185 8.156-14.237 5.465c-.052-.042-.093-.094-.154-.126l-8.87-4.701L12.65 9.1zm25.736-2.976l-4.283-4.042a29.763 29.763 0 0110.989 2.931l-6.706 1.111zM21.93 38.737l-.816-15.554L35.655 17.6l9.803 12.106-8.483 13.063-15.045-4.032zm37.375-19.141c-.031.054-.072.098-.093.159l-3.015 8.86h-9.048L36.882 15.937l2.113-7.887 8.27-1.371a.979.979 0 00.453-.218 30.2 30.2 0 0111.587 13.135zm-48.994-8.289l-.802 5.561-5.349 3.975a30.035 30.035 0 016.151-9.536zm-7.255 12.82c.044-.023.09-.037.131-.068l7.737-5.751 8.158 4.323.888 16.936c.002.025.013.048.016.073l-7.71 7.629c-.066.065-.105.145-.149.222L4.734 44.32c-.028-.012-.057-.009-.085-.018A29.822 29.822 0 012 32c0-2.725.372-5.362 1.056-7.873zm3.022 22.945l5.415 2.322 4.141 7.729a30.222 30.222 0 01-9.556-10.051zm12.759 11.879c-.019-.064-.025-.131-.058-.192l-5.317-9.924c.076-.043.155-.08.22-.145l8.027-7.942 14.507 3.888 5.927 9.879c.05.083.11.154.178.217l-5.449 6.867c-1.587.26-3.213.401-4.872.401-4.72 0-9.186-1.099-13.163-3.049z"
      data-original="#000000"
      className="football_svg__active-path"
      data-old_color="#000000"
    />
  </svg>
);

export default SvgFootball;
