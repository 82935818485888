import _ from 'lodash';
import {
  FETCH_PAYMENTVOUCHERS,
  PUSH_PAYMENTVOUCHERS,
  REMOVE_PAYMENTVOUCHERS,
  UPDATE_PAYMENTVOUCHERS,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID } from '../actions/actions-paymentvouchers';

const INITIAL_STATE = {
  paymentvouchers: [],
  mode: 'index',
  activeId: '',
  activePaymentVouchers:{}
}


export default function(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_PAYMENTVOUCHERS:
      return {
        ...state,
        paymentvouchers:action.payload,
      }
    case PUSH_PAYMENTVOUCHERS:
      return {
        ...state,
        paymentvouchers:[action.payload, ...state.paymentvouchers],
      }
    case REMOVE_PAYMENTVOUCHERS:
      return {
        ...state,
        paymentvouchers : [...state.paymentvouchers.filter( (item) => item._id !== action.payload._id)]
      }
    case UPDATE_PAYMENTVOUCHERS:
      let index =  _.findIndex(state.paymentvouchers, { '_id': action.payload._id })
      state.paymentvouchers.splice(index, 1, action.payload)
      return {
        ...state,
        paymentvouchers : state.paymentvouchers
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode : action.payload,
        activeId:''
      }
    case UPDATE_ACTIVE_ID:
      return {
        ...state,
        mode : action.mode,
        activeId:action.id,
        activePaymentVouchers: _.pick(_.find(state.paymentvouchers, { '_id': action.id }), ['propertyId', 'date', 'remark', 'currency','paymentamount','creditledgerAccountId','debitledgerAccountId','paymentvouchermasterId','referenceId','receiptId','_id'])
      }
    default:
      return state

  }
}
