import React, { Component } from 'react';
import { Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Card } from 'antd';
import { Route, Link } from 'react-router-dom';

import client from '../../feathers';
import requireAuth from "../../components/requireAuth";
import Layout from "../../components/layout";
import AdsForm from "../../components/ads/form";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchAds,
  pushAds,
  removeAds,
  updateMode,
  updateActiveAd,
  updateAd
} from '../../actions/ads-actions';
import userproperties from '../userproperties';

const FormItem = Form.Item;

const text = 'Are you sure to delete this item?';

class AdsIndex extends Component {
  constructor(props) {
    super(props);

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidUpdate() {

    if (this.props.ads.mode === 'refresh') {
      this.props.updateMode('')
      this.componentWillMount();
    }
  }

  componentDidMount() {
    // client.service('ads').on('created', (ads) => {
    //   this.props.pushAds(ads)
    // })

    // client.service('ads').on('removed', (ads) => {
    //   this.props.removeAds(ads)
    // })

    // client.service('ads').on('updated', (ads) => {
    //   this.props.updateAd(ads)
    // })
  }

  componentWillMount() {



    client.authenticate()
      .then(() => {
        return client.service('ads').find({
          query: {
            propertyId: this.props.commons.selectedCompany._id,
            $sort: {
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {
        this.props.fetchAds(res.data)
      })
      .catch((err) => {
        console.log(err);
      })
  }

  handleDelete(_id) {
    client.service('ads').remove(_id)
      .then((res) => {
        this.props.updateMode('refresh')
      })
      .catch((err) => {
        this.props.updateMode('refresh')
        alert(err);
      })
  }

  handleEdit(_id) {
    this.props.updateActiveAd('edit', _id)
  }

  handleManage(_id) {
    this.props.updateActiveAd('', _id)
  }

  showModal = () => {
    this.props.updateMode('new')
  }

  renderConfirmDeleteBtn(_id) {
    return (
      <Popconfirm placement="topLeft" title={text} onConfirm={() => this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
        <Button type="danger">Delete</Button>
      </Popconfirm>
    )
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    let userRole = this.props.user.info.user.role[0]

    const columns = [{
      title: 'File ID',
      key: 'file',
      width: '20%',
      render: (text, record) => (

        <div className='wrap-zoom-image'>
          <img style={{ height: '150px', width: '200px' }} src={record.file} />
        </div>


      ),
    },
    {
      title: 'News Type',
      dataIndex: 'type',
      key: 'type',
      width: '20%',
      render: (text, record) => {
        let showText = ''
        if (text === 'n') {
          showText = 'Happenings'

        }
        else if (text === 'p') {
          showText = 'Promo'
        }
        else if (text === 'np') {
          showText = 'Property News'
        }

        return (showText)
      }
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: '20%',

    },


    {
      title: 'Content',
      dataIndex: 'content',
      key: 'content',
      width: '20%',
    },
    {
      title: 'Action',
      key: 'action',
      width: '20%',
      render: (text, record) => (
        <span>
          <Divider type="vertical" />
          <Button onClick={() => this.handleEdit(record._id)}>Edit</Button>
          <Divider type="vertical" />
          {this.renderConfirmDeleteBtn(record._id)}
        </span>
      ),
    }];


    return (
      <Layout>
        <Card
          title={userRole === 'management' ? 'Property News' : "News"}

          extra={<Button type="primary" onClick={this.showModal}>{userRole === 'management' ? 'Create News' : 'New Ads'}</Button>}
        >
          <Table dataSource={this.props.ads.ads} columns={columns} rowKey="_id" />
          <AdsForm />
        </Card>

      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    ad: state.ad,
    ads: state.ads,
    commons: state.commons
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchAds: fetchAds,
    pushAds: pushAds,
    removeAds: removeAds,
    updateActiveAd: updateActiveAd,
    updateAd: updateAd,
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(AdsIndex)));
