import React, { Component } from "react";
import {
  Modal,
  message,
  Divider,
  Table,
  Form,
  Input,
  Button,
  Select,
  Card,
  Radio,
  Row,
  Col,
  Spin,
  DatePicker,
} from "antd";
import client from "../../feathers";
import requireAuth from "../requireAuth";
import Layout from "../layout";
import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import AccountForm from "../ledgers/account-form";
import {
  updateActiveLedgers,
  fetchLedgers,
} from "../../actions/actions-ledgers";
import { updateDate } from "../../actions/actions-ledgerAccounts";
import moment from "moment";

const _ = require("lodash");
const FormItem = Form.Item;
const Option = Select.Option;

const formatCurr = function format1(n = 0.0, currency = null) {
  let oriAmt = n;
  n = n < 0 ? Math.abs(n) : n;

  try {
    let amt = currency
      ? currency
      : "" +
        n.toFixed(2).replace(/./g, function(c, i, a) {
          return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
        });
    return oriAmt < 0 ? "(" + amt + ")" : amt;
  } catch (e) {
    return "0.00";
  }
};

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const PAGESIZE = 25;

class LedgerIndexLedgerAccounts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ledgerAccounts: [],
      searchAccountNo: "",
      searchAccountName: "",
      searchAccountDesc: "",
      filerCategories: "",
      visible: false,
      accountTypeDropdowns: [],
      searchCategories: "",
      accountTypeDropdownsValue: "",
      totalPagination: 1,
      CurrentPagination: 1,
      loading: false,
      CurrentPage: 1,
      ledgers: [],
      visiblePrintAll: false,
    };
  }

  componentDidMount() {}

  async componentWillMount() {
    this.getData(0);
  }
  //---------------------------------------------------------------
  handlePageChange = (page) => {
    this.getData(page * PAGESIZE - PAGESIZE);
    this.setState({ CurrentPage: page });
  };
  //---------------------------------------------------------------
  handleDropdown = (e) => {
    this.props.form.resetFields();

    this.state.filerCategories = e;
    this.state.accountTypeDropdowns = [];
    this.state.accountTypeDropdownsValue = "";

    //dropdown account type
    this.setState({ accountTypeDropdownsValue: [] }, () => {
      client
        .authenticate()
        .then(() => {
          return client.service("parameters").find({
            query: {
              table: this.state.filerCategories,
              $sort: {
                createdAt: -1,
              },
            },
          });
        })
        .then((res) => {
          this.setState({
            accountTypeDropdowns: res.data[0].items,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  async getData(skip) {
    this.setState({ loading: true, visible: false });
    let companyId = this.props.commons.selectedCompany._id;

    client.authenticate().then(async (res) => {
      let ledgerSummary = await axios.get(
        `${client.io.io.uri}ledgerTrxSummarybyAccounts`,
        {
          params: {
            companyId: companyId,
            accountList: [],
          },
          headers: {
            Authorization: client.settings.storage.storage["feathers-jwt"],
          },
        }
      );
      let queryCategory = "";
      let orRegexFilter = [];
      let {
        filerCategories,
        searchAccountName,
        searchAccountNo,
        searchAccountDesc,
      } = this.state;

      if (!_.isEmpty(filerCategories)) {
        queryCategory = { categories: filerCategories };
      }
      if (!_.isEmpty(searchAccountNo)) {
        orRegexFilter.push({ accountNo: searchAccountNo });
      }
      if (!_.isEmpty(searchAccountName)) {
        orRegexFilter.push({ accountName: searchAccountName });
      }
      if (!_.isEmpty(searchAccountDesc)) {
        orRegexFilter.push({ description: searchAccountDesc });
      }

      if (!_.isEmpty(orRegexFilter)) {
        orRegexFilter = { orRegexOr: orRegexFilter };
      }

      client
        .service("ledger-accounts")
        .find({
          query: {
            companyId: companyId,
            ...queryCategory,
            ...orRegexFilter,
            accountNo: { $nin: ["3000"] },
            $limit: PAGESIZE,
            $skip: skip,
            $sort: {
              accountNo: 1,
              subAccountNo: 1,
              categories: 1,
            },
          },
        })
        .then((res) => {
          var ledgerBalance = _.map(res.data, function(item) {
            item = _.merge(
              item,
              _.find(ledgerSummary.data, function(a) {
                return a.accountInfo._id === item._id;
              })
            );
            item._id = _.get(item._id, ["accountId"], item._id);
            return item;
          });

          this.props.fetchLedgers(ledgerBalance);
          this.setState({
            totalPagination: res.total,
            loading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });
    });
  }

  componentDidUpdate() {}
  //---------------------------------------------------------------

  //---------------------------------------------------------------
  handleFilterAccountNo = (e) => {
    this.setState(
      {
        searchAccountNo: e.target.value,
      },
      () => {
        this.getData(0);
      }
    );
  };
  handleFilterAccountName = (e) => {
    this.setState(
      {
        searchAccountName: e.target.value,
      },
      () => {
        this.getData(0);
      }
    );
  };
  handleFilterAccountDesc = (e) => {
    this.setState(
      {
        searchAccountDesc: e.target.value,
      },
      () => {
        this.getData(0);
      }
    );
  };
  //---------------------------------------------------------------
  handleFilterCategories = (e) => {
    this.setState(
      {
        filerCategories: e,
      },
      () => {
        this.getData(0);
      }
    );
  };
  //---------------------------------------------------------------
  handleSubmit = (e) => {
    try {
      e.preventDefault();
    } catch (err) {}
    this.props.form.validateFields((err, values) => {
      let companyId = this.props.commons.selectedCompany._id;
      if (!err) {
        client
          .service("ledger-accounts")
          .create({
            companyId: companyId,
            companyType: "PROPERTY",
            categories: this.state.filerCategories,
            accountType: values.accountType,
            accountName: values.accountName,
            accountNo: values.accountNo,
            subAccountNo: values.subAccountNo,
            accountCurrency: "MYR",
            description: values.description,
            bankAccountFlag: values.bankAccountFlag,
          })
          .then((res) => {
            this.setState({
              visible: false,
            });
            this.componentWillMount();
            message.success("New Accounts Added");
          })
          .catch((err) => {
            console.log(err);
            this.setState({
              visible: false,
            });
            alert(err);
          });
      }
    });
  };
  //---------------------------------------------------------------

  handleSubmitPrintAll = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      let companyId = this.props.commons.selectedCompany._id;

      if (err && this.state.visiblePrintAll == true) {
        let fromdate = moment(new Date(values.fromDate)).format("YYYYMMDD");
        let todate = moment(new Date(values.toDate)).format("YYYYMMDD");

        this.props.history.push();
        let companyId = this.props.commons.selectedCompany._id;

        if (values.email) {
          axios.get(`${client.io.io.uri}SOA-GL-printAll`, {
            params: {
              companyId: companyId,
              fromDate: moment(new Date(values.fromDate)).format("YYYYMMDD"),
              toDate: moment(new Date(values.toDate)).format("YYYYMMDD"),
              email: values.email,
            },
            headers: {
              Authorization: client.settings.storage.storage["feathers-jwt"],
            },
          });
          this.setState({ visiblePrintAll: false });
          message.success("Email will be sent in a while");
        } else {
          this.props.history.push({
            pathname: "/soaGLPrintAll/" + companyId,
            state: { fromdate: fromdate, todate: todate },
          });
        }
      }
    });
  };
  //---------------------------------------------------------------
  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  //---------------------------------------------------------------
  showModalPrintAll = () => {
    this.setState({
      visiblePrintAll: true,
    });
  };
  //---------------------------------------------------------------
  handleEdit(_id) {
    this.props.updateActiveLedgers("edit", _id);
  }
  //---------------------------------------------------------------
  handleCancel = (e) => {
    this.setState({
      visible: false,
      visiblePrintAll: false,
    });
  };

  //---------------------------------------------------------------
  render() {
    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title: (
          <Row gutter={8}>
            <Row>
              <Col span={24}>Account No.</Col>
            </Row>
            <Row>
              <Col span={24}>
                <Input onChange={this.handleFilterAccountNo} />
              </Col>
            </Row>
          </Row>
        ),
        dataIndex: "accountNo",
        key: "accountNo",
        width: "10%",
        render: (text, record) => (
          <span>{record.accountNo + "/" + record.subAccountNo}</span>
        ),
      },
      {
        title: (
          <Row gutter={8}>
            <Row>
              <Col span={24}>Account Name</Col>
            </Row>
            <Row>
              <Col span={24}>
                <Input onChange={this.handleFilterAccountName} />
              </Col>
            </Row>
          </Row>
        ),
        dataIndex: "accountName",
        key: "accountName",
        width: "20%",
      },
      {
        title: (
          <Row gutter={8}>
            <Row>
              <Col span={24}>Description</Col>
            </Row>
            <Row>
              <Col span={24}>
                <Input onChange={this.handleFilterAccountDesc} />
              </Col>
            </Row>
          </Row>
        ),
        dataIndex: "description",
        key: "description",
        width: "20%",
      },
      {
        title: (
          <Row gutter={8}>
            <Row>
              <Col span={24}>Categories</Col>
            </Row>
            <Row>
              <Col span={24}>
                <Select
                  style={{ width: 100 }}
                  placeholder=""
                  onChange={this.handleFilterCategories}
                >
                  <Option value="ASSETS">ASSETS</Option>
                  <Option value="LIABILITIES">LIABILITIES</Option>
                  <Option value="INCOMES">INCOMES</Option>
                  <Option value="EXPENSES">EXPENSES</Option>
                  <Option value="EQUITY">EQUITY</Option>
                </Select>
              </Col>
            </Row>
          </Row>
        ),
        dataIndex: "categories",
        key: "categories",
        width: "5%",
      },
      {
        title: (
          <Row gutter={8}>
            <Row>
              <Col span={24}>Account Type</Col>
            </Row>
            <Row>
              <Col span={24}>
                <br />
              </Col>
            </Row>
          </Row>
        ),
        dataIndex: "accountType",
        key: "accountType",
        width: "10%",
      },
      {
        title: (
          <Row gutter={8}>
            <Row>
              <Col span={24}>Balance</Col>
            </Row>
            <Row>
              <Col span={24}>
                <br />
              </Col>
            </Row>
          </Row>
        ),
        dataIndex: "balance",
        key: "balance",
        width: "8%",
        render: (text, record) => {
          return <span>{formatCurr(text)}</span>;
        },
      },

      {
        title: (
          <Row gutter={8}>
            <Row>
              <Col span={24}>Action</Col>
            </Row>
            <Row>
              <Col span={24}>
                <br />
              </Col>
            </Row>
          </Row>
        ),
        key: "action",
        render: (text, record) => (
          <span>
            <Link
              target="blank"
              to={{
                pathname: `/ledgers/transactions/${record._id}`,
              }}
            >
              <Button
                onClick={() => {
                  this.props.updateDate("");
                }}
              >
                Transactions
              </Button>
            </Link>
            <Divider type="vertical" />
            <Button onClick={() => this.handleEdit(record._id)}>Edit</Button>
          </span>
        ),
      },
    ];

    return (
      <Layout>
        <Spin spinning={this.state.loading} key="spinning">
          <Card
            title={
              <Row>
                <span>General Ledger</span>
                <Col style={{ float: "right" }}>
                  <Button onClick={this.showModalPrintAll}>
                    Print All Statement
                  </Button>
                  <Divider type="vertical" />
                  <Button onClick={this.showModal}>New</Button>
                </Col>
              </Row>
            }
            // extra={[
            // <Select defaultValue='ASSETS' style={{ width: 200 }} placeholder="Please select Account Categories" onChange={this.handleFilterCategories}>
            // <Option value="ASSETS">ASSETS</Option>
            // <Option value="LIABILITIES">LIABILITIES</Option>
            // <Option value="INCOMES">INCOMES</Option>
            // <Option value="EXPENSES">EXPENSES</Option>
            // <Option value="EQUITY">EQUITY</Option>
            // </Select>,<Input onChange={this.handleFilterAccountName} placeholder="Account Name" style={{'width':'200px'}} />,
            // <Button type='primary' onClick={this.handleFilter} >Search</Button>]}
          >
            <Table
              dataSource={this.props.ledgers.ledgers}
              columns={columns}
              rowKey="_id"
              pagination={{
                total: this.state.totalPagination,
                current: this.state.CurrentPage,
                onChange: this.handlePageChange,
                pageSize: 25,
              }}
            />

            <AccountForm
              doneSubmit={(e) => {
                if (e === "done") {
                  this.getData(0)
                }
              }}
            />
          </Card>
          <Modal
            title="Details"
            visible={this.state.visible}
            onOk={this.handleSubmit}
            okText={"Submit"}
            onCancel={this.handleCancel}
          >
            <Form className="submit-form">
              <FormItem {...formItemLayout} label="Categories" hasFeedback>
                {getFieldDecorator("categories", {
                  rules: [{ required: true, message: "Select Categories!" }],
                })(
                  <Select
                    style={{ width: 200 }}
                    placeholder="Please select Account Categories"
                    onChange={this.handleDropdown}
                  >
                    <Option value="ASSETS">ASSETS</Option>
                    <Option value="LIABILITIES">LIABILITIES</Option>
                    <Option value="INCOMES">INCOMES</Option>
                    <Option value="EXPENSES">EXPENSES</Option>
                    <Option value="EQUITY">EQUITY</Option>
                  </Select>
                )}
              </FormItem>

              <FormItem {...formItemLayout} label="Account Type" hasFeedback>
                {getFieldDecorator("accountType", {
                  rules: [{ required: true, message: "Select Type!" }],
                })(
                  <Select
                    placeholder="Select Type"
                    value={this.state.accountTypeDropdownsValue}
                  >
                    {this.state.accountTypeDropdowns.map((dropdown) => (
                      <Option value={dropdown.value}>
                        {dropdown.description}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>

              <FormItem {...formItemLayout} label="Account Name">
                {getFieldDecorator("accountName", {
                  rules: [
                    {
                      required: true,
                      message: "Please input Accounts Description!",
                    },
                  ],
                })(<Input placeholder="Lim Motors" />)}
              </FormItem>

              <FormItem {...formItemLayout} label="Account No.">
                {getFieldDecorator("accountNo", {
                  rules: [
                    {
                      required: true,
                      message: "Please input Account Number!",
                    },
                  ],
                })(<Input placeholder="1000" />)}
              </FormItem>

              <FormItem {...formItemLayout} label="Sub Account No">
                {getFieldDecorator("subAccountNo", {
                  rules: [
                    {
                      required: true,
                      message: "Please input Account Number!",
                    },
                  ],
                })(<Input placeholder="000" />)}
              </FormItem>

              {/*
                <FormItem
                {...formItemLayout}
                label="Currency"
              >
                {getFieldDecorator('accountCurrency', {
                  rules: [{
                    required: true, message: 'Please select Currency!',
                  }],
                })(
                  <Select placeholder="Please Select currency">
                    <Option value="MYR">MYR - Malaysia Ringgit</Option>
                  </Select>
                )}
              </FormItem>
              */}

              <FormItem {...formItemLayout} label="Description">
                {getFieldDecorator("description", {
                  rules: [
                    {
                      required: true,
                      message: "Please input description!",
                    },
                  ],
                })(<Input placeholder="" />)}
              </FormItem>

              <FormItem {...formItemLayout} label="Bank Account?">
                {getFieldDecorator("bankAccountFlag", {
                  rules: [
                    {
                      required: true,
                      message: "Please select!",
                    },
                  ],
                })(
                  <Radio.Group>
                    <Radio.Button value="true">true</Radio.Button>
                    <Radio.Button value="false">false</Radio.Button>
                  </Radio.Group>
                )}
              </FormItem>
            </Form>
          </Modal>

          <Modal
            title="Print Statement"
            visible={this.state.visiblePrintAll}
            onOk={this.handleSubmitPrintAll}
            okText={"Submit"}
            onCancel={this.handleCancel}
          >
            <Form className="submit-form">
              <FormItem {...formItemLayout} label="Print Start Date">
                {getFieldDecorator("fromDate", {
                  rules: [
                    {
                      required: true,
                      message: "Please input Print Start Date!",
                    },
                  ],
                  initialValue: moment().startOf("year"),
                })(<DatePicker />)}
              </FormItem>

              <FormItem {...formItemLayout} label="Print End Date">
                {getFieldDecorator("toDate", {
                  rules: [
                    {
                      required: true,
                      message: "Please input print End Date!",
                    },
                  ],
                  initialValue: moment().endOf("year"),
                })(<DatePicker />)}
              </FormItem>

              <FormItem {...formItemLayout} label="Email">
                {getFieldDecorator("email", {
                  rules: [
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                  ],
                })(<Input placeholder="Email" />)}
              </FormItem>
            </Form>
          </Modal>
        </Spin>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    ledgers: state.ledgers,
    commons: state.commons,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateDate: updateDate,
      updateActiveLedgers: updateActiveLedgers,
      fetchLedgers: fetchLedgers,
    },
    dispatch
  );
}

export default requireAuth(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Form.create()(LedgerIndexLedgerAccounts))
);
