import _ from 'lodash';
import {
  FETCH_ACCOUNTTRXS,
  PUSH_ACCOUNTTRXS,
  REMOVE_ACCOUNTTRXS,
  UPDATE_ACCOUNTTRXS,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID } from '../actions/actions-accounttrxs';

const INITIAL_STATE = {
  accounttrxs: [],
  mode: 'index',
  activeId: '',
  activeAccounttrxs:{}
}


export default function(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_ACCOUNTTRXS:
      return {
        ...state,
        accounttrxs:action.payload,
      }
    case PUSH_ACCOUNTTRXS:
      return {
        ...state,
        accounttrxs:[action.payload, ...state.accounttrxs],
      }
    case REMOVE_ACCOUNTTRXS:
      return {
        ...state,
        accounttrxs : [...state.accounttrxs.filter( (item) => item._id !== action.payload._id)]
      }
    case UPDATE_ACCOUNTTRXS:
      let index =  _.findIndex(state.accounttrxs, { '_id': action.payload._id })
      state.accounttrxs.splice(index, 1, action.payload)
      return {
        ...state,
        accounttrxs : state.accounttrxs
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode : action.payload,
        activeId:''
      }
    case UPDATE_ACTIVE_ID:
      return {
        ...state,
        mode : action.mode,
        activeId:action.id,
        activeAccounttrxs: _.pick(_.find(state.accounttrxs, { '_id': action.id }), ['accountId', 'description', 'trxDate', 'amount','creditDebit','category','propertyunitId','remark', '_id'])
      }
    default:
      return state

  }
}