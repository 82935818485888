import _ from 'lodash';
import {
  FETCH_DEBITNOTES,
  PUSH_DEBITNOTES,
  REMOVE_DEBITNOTES,
  UPDATE_DEBITNOTES,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID } from '../actions/actions-debitnotes';

const INITIAL_STATE = {
  debitnotes: [],
  mode: 'index',
  activeId: '',
  activeDebitNotes:{}
}


export default function(state = INITIAL_STATE, action){

  switch(action.type){
    case FETCH_DEBITNOTES:
      return {
        ...state,
        debitnotes:action.payload,
      }
    case PUSH_DEBITNOTES:
      return {
        ...state,
        debitnotes:[action.payload, ...state.debitnotes],
      }
    case REMOVE_DEBITNOTES:
      return {
        ...state,
        debitnotes : [...state.debitnotes.filter( (item) => item._id !== action.payload._id)]
      }
    case UPDATE_DEBITNOTES:
      let index =  _.findIndex(state.debitnotes, { '_id': action.payload._id })
      state.debitnotes.splice(index, 1, action.payload)
      return {
        ...state,
        debitnotes : state.debitnotes
      }
    case UPDATE_MODE:

      return {
        ...state,
        mode : action.payload,
        activeId:''
      }
    case UPDATE_ACTIVE_ID:

      return {
        ...state,
        mode : action.mode,
        activeId:action.id,
        activeDebitNotes: _.pick(_.find(state.debitnotes, { '_id': action.id }), ['propertyId','propertyunitId','dtledgerAccountId','ctledgerAccountId','debitAmount','trxDate','remark','referenceId','updatedBy','createdBy','trandcode','_id'])
      }
    default:
      return state

  }
}
