import React, { Component } from 'react';
import { Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Card, Spin, Tabs, Badge, Select } from 'antd';
import { Route, Link } from 'react-router-dom';

import client from '../../feathers';
import requireAuth from "../../components/requireAuth";
import Layout from "../../components/layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    fetchHelp,
    // pushPinboard,
    // removePinboard,
    // updatePinboardMode,
    // updateActivePinboard,
    // updatePinboard
} from '../../actions/actions-help';
import userproperties from '../userproperties';
import moment from 'moment';
import _ from 'lodash';

const FormItem = Form.Item;
const { TabPane } = Tabs;
const Option = Select.Option;
const text = 'Are you sure to delete this item?';

class HELPIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            status: '',
            selectedPropertyUnit: '',
            dropDownPropertyUnit: []
        }

        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidUpdate() {
        // if (this.props.pinboard.mode === 'refresh') {
        //     this.props.updateActivePinboard('', '')
        //     this.componentWillMount();
        // }

    }

    componentDidMount() {
        // client.service('ads').on('created', (ads) => {
        //   this.props.pushAds(ads)
        // })

        // client.service('ads').on('removed', (ads) => {
        //   this.props.removeAds(ads)
        // })

        // client.service('ads').on('updated', (ads) => {
        //   this.props.updateAd(ads)
        // })
    }

    componentWillMount() {
        this.setState({ loading: true })

        client.service('propertyunits').find({
            query: {
                propertyId: this.props.user.info.propertyId._id
            }
        }).then((res) => {
            this.setState({ dropDownPropertyUnit: res.data })
        })

        this.setState({ status: 'Active' }, () => {
            this.getData(0)
        })


    }
    getData(skip) {
        let propertyValue = ''
        try {
            propertyValue = this.props.user.info.propertyId._id

        }
        catch (err) {
            console.log(err)
        }

        const { status, selectedPropertyUnit } = this.state
        let queryActive, queryUnitNo = ''
        if (status === 'Active') {
            queryActive = {
                status: {
                    $nin: ['Terminated']
                }
            }

        }
        if (status === 'History') {
            queryActive = {
                status: 'Terminated'
            }

        }
        if (!_.isEmpty(selectedPropertyUnit)) {
            queryUnitNo = { propertyunitId: selectedPropertyUnit }
        }

        client.authenticate()
            .then(() => {
                return client.service('help').find({
                    query: {
                        ...queryActive,
                        ...queryUnitNo,
                        propertyId: propertyValue,
                        $sort: {
                            createdAt: -1
                        },
                        $populate: 'propertyunitId createdBy guardId'
                    }
                })
            })
            .then((res) => {

                this.props.fetchHelp(res.data)
                this.setState({ loading: false })

            })
            .catch((err) => {
                console.log(err);
            })
    }

    handleDelete(_id) {
        client.service('pinboard').remove(_id)
            .then((res) => {
            })
            .catch((err) => {
                alert(err);
            })
    }

    handleEdit(_id) {
        // this.props.updateActivePinboard('edit', _id)
    }

    //   handleManage(_id) {
    //     this.props.updateActiveAd('', _id)
    //   }

    showModal = () => {
        // this.props.updateActivePinboard('new', '')
    }

    renderConfirmDeleteBtn(_id) {
        return (
            <Popconfirm placement="topLeft" title={text} onConfirm={() => this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
                <Button type="danger">Delete</Button>
            </Popconfirm>
        )
    }
    handleChangeStatus = (e) => {
        this.setState({
            status: e
        }, () => {
            this.getData(0)
        })
    }


    render() {
        const { getFieldDecorator } = this.props.form;
        const { status } = this.state
        let userRole = this.props.user.info.user.role[0]


        let columns = [
            {
                title: <Select defaultValue='' allowClear showSearch style={{ width: 90 }} optionFilterProp="children"
                    // filterOption={(input, option) =>
                    //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    // }
                    onChange={(e) => {
                        this.setState({
                            selectedPropertyUnit: e
                        }, () => { this.getData(0) })
                    }}
                >
                    <Option value=''>Unit</Option>
                    {this.state.dropDownPropertyUnit.map(v => { return <Option key={v._id}>{v.referenceId}</Option> })}


                </Select>,
                key: 'propertyunitId.unitNo',
                dataIndex: 'propertyunitId.unitNo',
                // width: '10%',


            },

            {
                title: 'User Name',
                key: 'createdBy.firstName',
                dataIndex: 'createdBy.firstName',
                // width: '10%',

            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (values, record) => {
                    // console.log(_.get(record, 'userCancelledTime'))
                    if (values === 'Active') {
                        return <span>Request</span>
                    }
                    else if (values === 'Pending') {
                        return <span>Guard Ongoing</span>
                    }
                    else if (values === 'Terminated' && _.get(record, 'guardSolvedTime')) {
                        return <span>Guard Solved</span>
                    }
                    else if (values === 'Terminated' && _.get(record, 'userCancelledTime')) {
                        return <span>Terminated</span>
                    }
                    else {
                        return <span>-</span>
                    }
                }

            },
            {
                title: 'Request Time',
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: (values) => {
                    return (moment(values).format('DD/MM/YYYY h:mm a'))
                }

            },
            {
                title: 'Guard Response Time',

                render: (record) => {

                    if (_.get(record, 'guardResponseTime')) {
                        return (moment(_.get(record, 'guardResponseTime')).format('DD/MM/YYYY h:mm a'))
                    }
                    else {
                        return <span>-</span>
                    }

                }


            },


        ];
        let extraColumns = [

            {
                title: 'Terminated Time',

                render: (record) => {

                    if (_.get(record, 'guardSolvedTime')) {
                        return (moment(_.get(record, 'guardSolvedTime')).format('DD/MM/YYYY h:mm a'))
                    }
                    else if (_.get(record, 'userCancelledTime')) {
                        return (moment(_.get(record, 'userCancelledTime')).format('DD/MM/YYYY h:mm a'))
                    }

                }


            },
            {
                title: 'Action By',
                render: (record) => {
                    if (record.status === 'Terminated' && _.get(record, 'guardSolvedTime')) {
                        return _.get(record, ['guardId', 'firstName']) + ' ' + _.get(record, ['guardId', 'lastName'])
                    }
                    else if (record.status === 'Terminated' && _.get(record, 'userCancelledTime')) {
                        return _.get(record, ['createdBy', 'firstName']) + ' ' + _.get(record, ['createdBy', 'lastName'])


                    }
                }


            },
        ]

        if (status === 'History') {
            columns = columns.concat(extraColumns)

        }


        return (
            <Layout>
                HELP List
                <Spin spinning={this.state.loading}>
                    <Card
                        title={
                            <Tabs defaultActiveKey='Pending' onChange={this.handleChangeStatus} style={{ flex: 1 }}>

                                <TabPane tab={(<div><span className='marginRight8px' >Active</span><Badge overflowCount={999999} style={{ marginLeft: '8px', backgroundColor: '#fda855' }} count={this.state.totalPending} /></div>)} key="Active">
                                </TabPane>
                                <TabPane tab={(<div><span className='marginRight8px' >Past Record</span><Badge overflowCount={999999} style={{ marginLeft: '8px', backgroundColor: '#9FD5B5' }} count={this.state.totalProgress} /></div>)} key="History">
                                </TabPane>



                            </Tabs>
                        }
                    >
                        <Table dataSource={this.props.help.help} columns={columns} rowKey="_id" />


                    </Card>
                </Spin>

            </Layout>
        )
    }
}


function mapStateToProps(state) {
    return {

        // pinboard: state.pinboard
        help: state.help
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchHelp: fetchHelp,
        // pushPinboard: pushPinboard,
        // removePinboard: removePinboard,
        // updateActivePinboard: updateActivePinboard,
        // updatePinboard: updatePinboard,
        // updatePinboardMode: updatePinboardMode
    }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(HELPIndex)));
