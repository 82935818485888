import _ from 'lodash';
import {
  FETCH_REFUNDS,
  PUSH_REFUNDS,
  REMOVE_REFUNDS,
  UPDATE_REFUNDS,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID
} from '../actions/actions-refunds';

const INITIAL_STATE = {
  refunds: [],
  mode: 'index',
  activeId: '',
  activerefunds: {}
}


export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_REFUNDS:
      return {
        ...state,
        refunds: action.payload,
      }
    case PUSH_REFUNDS:
      return {
        ...state,
        refunds: [action.payload, ...state.refunds],
      }
    case REMOVE_REFUNDS:
      return {
        ...state,
        refunds: [...state.refunds.filter((item) => item._id !== action.payload._id)]
      }
    case UPDATE_REFUNDS:
      let index = _.findIndex(state.refunds, { '_id': action.payload._id })
      state.refunds.splice(index, 1, action.payload)
      return {
        ...state,
        refunds: state.refunds
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode: action.payload,
        activeId: ''
      }
    case UPDATE_ACTIVE_ID:
      return {
        ...state,
        mode: action.mode,
        activeId: action.id,
        activerefunds: _.pick(_.find(state.refunds, { '_id': action.id }), ['propertyId', 'propertyunitId', 'paymentTypeParamId', 'invoiceItemId', 'refundAmt', 'refundDate', 'remark', 'referenceId', '_id', 'chequeNo'])
      }
    default:
      return state

  }
}
