import React, { Component } from 'react';
import { Popconfirm, message, Divider,  Table, Form, Icon, Input, Button,Card ,Spin, Modal} from 'antd';
import { Route, Link } from 'react-router-dom';

import client from '../../feathers';
import requireAuth  from "../../components/requireAuth";
import Layout  from "../../components/layout";
import JmbsForm  from "../../components/jmbs/form";

 
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchJmbs,
  pushJmbs,
  removeJmbs,
  updateMode,
  updateActiveJmbs,
  updateJmbs } from '../../actions/actions-jmbs';

const FormItem = Form.Item;

const text = 'Are you sure to delete this item?';

class JmbsIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      property:[],
      loading: false,
      visible: false,
      img:''
    }

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidUpdate(){
  }

  componentDidMount(){
      client.service('jmbs').on('created', (jmbs) => {
        this.componentWillMount();
      })

      client.service('jmbs').on('removed', (jmbs) => {
        this.componentWillMount();
      })

      client.service('jmbs').on('updated', (jmbs) => {
        this.componentWillMount();
      })

      client.service('jmbs').on('patched', (jmbs) => {
        this.componentWillMount();
      })
  }

  componentWillMount(){
    this.setState({loading:true});
      client.authenticate()
      .then((res)=>{

          return client.service('jmbs').find({
            query: {
                $populate:'propertyId',
                propertyId:this.props.match.params.id,    //unable to use session, due to  admin hav to add
                $sort: {
                  createdAt: -1
                }
             }
          })
      })
      .then((res)=>{

        this.setState({loading:false});
        this.props.fetchJmbs(res.data)
      })
      .catch((err)=>{
        this.setState({loading:false});
        console.log(err);
      });
      //get property info
      client.authenticate()
      .then((res)=>{

          return client.service('properties').get(this.props.match.params.id)
      })
      .then((res)=>{
        this.setState({property:res});

      })
      .catch((err)=>{
          //message.error(err);
          console.log(err);
      });
  }

  handleDelete(_id) {
     client.service('jmbs').remove(_id)
     .then((res)=>{
       this.componentWillMount();
     })
     .catch((err)=>{
       alert(err);
     })
  }

  handleEdit(_id) {
    this.props.updateActiveJmbs('edit',_id)
  }

  handleManage(_id) {
    this.props.updateActiveJmbs('',_id)
  }

  showModal = () => {
    this.props.updateMode('new')
  }

  renderConfirmDeleteBtn(_id){
    return (
      <Popconfirm placement="topLeft" title={text} onConfirm={()=>this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
        <Button type="danger">Delete</Button>
      </Popconfirm>
    )
  }

  handleImage (img = null) {
    this.setState({
      visible: true,
      img:img
    });
  }

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  }

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
      },
      {
        title: 'Contact',
        dataIndex: 'contactNo',
        key: 'contactNo',
      },
      {
        title: 'E-mail',
        dataIndex: 'email',
        key: 'email',
      },
      {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
          <Divider type="vertical" />
          <Button type='primary' onClick={()=>this.handleEdit(record._id)}>Edit</Button>
          <Divider type="vertical" />
          {record.headerFile?<Button onClick={()=>this.handleImage(record.headerFile)}>JMB Logo</Button>:''}
          {/*this.renderConfirmDeleteBtn(record._id)*/}
        </span>
      ),
    }];

    return(
      <Layout>
      
        <Spin spinning={this.state.loading}>
          <Card
            title={this.state.property.name+'-JMB'}
            extra={<Button type="primary" onClick={this.showModal}>New JMB</Button>}
          >
            <Table dataSource={this.props.jmbs.jmbs} columns={columns} rowKey="_id" />
            <JmbsForm propertyId={this.props.match.params.id} />
          </Card>

          <Modal
            title="JMB Logo"
            visible={this.state.visible}
            // onOk={this.handleOk}
             onCancel={this.handleCancel}
            // okText="Done"
            footer={[
              <Button key="submit" type="primary" onClick={this.handleOk}>
                Done
              </Button>,
            ]}
          >
            <img style={{'width':'100%'}} src={this.state.img} />
          </Modal>

        </Spin>
        
      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    jmbs: state.jmbs,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchJmbs: fetchJmbs,
    pushJmbs: pushJmbs,
    removeJmbs: removeJmbs,
    updateActiveJmbs: updateActiveJmbs,
    updateJmbs: updateJmbs,
    updateMode: updateMode
    }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(JmbsIndex)));
