import React, { Component } from 'react'
import { Modal, message, Form, Icon, Input, Select, DatePicker,Spin } from 'antd'
import client from '../../feathers'
import requireAuth from "../../components/requireAuth"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updatePinboardMode, updateActivePinboard } from '../../actions/actions-pinboard'
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import Resizer from 'react-image-file-resizer';
var FormData = require('form-data');

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const dateFormat = 'DD/MM/YYYY'

const resizeFile = (file) => new Promise(resolve => {
  Resizer.imageFileResizer(file, 2048, 1363, 'JPEG', 100, 0,
  uri => {
    resolve(uri);
  },
  'base64'
  );
});


const resizeFilePNG = (file) => new Promise(resolve => {
  Resizer.imageFileResizer(file, 2048, 1363, 'PNG', 100, 0,
  uri => {
    resolve(uri);
  },
  'base64'
  );
});


class PinboardForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',
      confirmDirty: false,
      autoCompleteResult: [],
      loading:false
    }
  }
  componentDidUpdate() {


    if (this.props.pinboard.activeId !== this.state._id) {
      this.setState({
        _id: this.props.pinboard.activeId
      })

      if (!_.isEmpty(this.props.pinboard.activePinboard)) {

        this.props.form.setFieldsValue(this.props.pinboard.activePinboard)
        //this.props.form.setFieldsValue({})
      }
      if (_.get(this.props.pinboard, 'activePinboard.startDate') !== undefined) {
        this.props.form.setFieldsValue({
          startDate: moment(this.props.pinboard.activePinboard.startDate),
          endDate: moment(this.props.pinboard.activePinboard.endDate)
        })

      }
    }

  }

  handleConfirmBlur = async (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }

  handleThumbnailUpload = async (event) => {
    this.setState({loading:true})
    let resizeImage=''
    if(event.target.files[0].type === 'image/png'){
     resizeImage= await resizeFilePNG(event.target.files[0])
    }
    else{
      resizeImage= await resizeFile(event.target.files[0])

    }

    // let formData = new FormData();

    // formData.append('image', event.target.files[0]);
    // console.log(resizeImage)
    axios.post(`${client.io.io.uri}compressImages`,
      {data:resizeImage}
      , {
        headers: {
          'Authorization': client.settings.storage.storage['feathers-jwt'],
        }
      }
    ).then((res) => {
      this.setState({loading:false})

      this.props.form.setFieldsValue({
        imagesSrc: res.data
      })
      message.success('Thumbnail upload Successful');
    })
      .catch((err) => {
        this.setState({loading:false})
        console.log(err);
        message.error(err);
      })
  }
  handleFileUpload = (event) => {
    this.setState({loading:true})

    if (event.target.files[0].type !== 'application/pdf') {
      return message.error('Only PDF file allowed!')
    }


    let formData = new FormData();

    formData.append('document', event.target.files[0]

    );

    axios.post(`${client.io.io.uri}upload-pinboard`,
      formData
      , {
        headers: {
          'Authorization': client.settings.storage.storage['feathers-jwt'],
          'Content-Type': 'multipart/form-data',
        }
      }
    ).then((res) => {
      this.setState({loading:false})


      this.props.form.setFieldsValue({
        filePath: res.data.pdfUrl
      })
      message.success('File upload Successful');
    })
      .catch((err) => {
        this.state({loading:false})
        console.log(err);
        message.error(err);
      })
  }


  handleSubmit = (e) => {
    this.setState({loading:true})
    let propertyValue = ''

    try {
      propertyValue = this.props.user.info.propertyId._id

    }
    catch (err) {
      propertyValue = ''
    }
    e.preventDefault();
    if (this.props.pinboard.mode === 'new') {

      this.props.form.validateFields((err, values) => {
        if (!_.isEmpty(propertyValue)) {
          values.propertyId = propertyValue
        }


        if (!err) {

          client.service('pinboard').create(values

          )
            .then((res) => {
              this.props.updateActivePinboard('refresh', '')
              this.setState({loading:false})
              message.success('New Pinboard added');
              this.props.form.resetFields();
            })
            .catch((err) => {
              this.setState({loading:false})
              this.props.updateActivePinboard('', '')
              alert(err);
            })
        }
      });
    } else {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          if (!_.isEmpty(propertyValue)) {
            values.propertyId = propertyValue
          }
          client.service('pinboard').patch(this.props.pinboard.activeId,
            values
          )
            .then((res) => {
              this.setState({loading:false})
              message.success(`Pinboard successfully updated`);
              this.props.updateActivePinboard('refresh', '')
              this.props.form.resetFields();
            })
            .catch((err) => {
              this.setState({loading:false})

              this.props.updateActivePinboard('', '')
            })
          return;
        }

      });
    }
  }


  render() {
    // const { visible, onCancel } = this.props;

    const { getFieldDecorator } = this.props.form;


    // const uploadButton = (
    //   <div>
    //     <Icon type={this.state.loading ? 'loading' : 'plus'} />
    //     <div className="ant-upload-text">Upload</div>
    //   </div>
    // );

    return (

    

      <Modal
        visible={
          this.props.pinboard.mode === 'new' || this.props.pinboard.mode === 'edit' ? true : false
        }
        title={this.props.pinboard.mode === 'edit' ? 'Update Pinboard' : 'Create Pinboard'}
        okText={this.props.pinboard.mode === 'edit' ? 'Update' : 'Create'}
        onCancel={() => { this.props.updateActivePinboard('', ''); this.props.form.resetFields() }}
        onOk={this.handleSubmit}
      >
      <Spin spinning={this.state.loading}>
        <Form className="submit-form">


          <FormItem
            {...formItemLayout}
            label="Upload Thumbnail"
          >
            <Input label='upload file' type='file' onChange={this.handleThumbnailUpload} />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Thumbnail"
          >
            {getFieldDecorator('imagesSrc', {
              rules: [{
                required: false, message: 'Please Upload File!',
              }],
            })(
              <Input disabled={true} placeholder="Images" />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Upload PDF"
          >
            <Input label='upload file' type='file' onChange={this.handleFileUpload} />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="File Url"
          >
            {getFieldDecorator('filePath', {
              rules: [{
                required: false, message: 'Please Upload File!',
              }],
            })(
              <Input disabled={true} placeholder="File" />
            )}
          </FormItem>




          <FormItem
            {...formItemLayout}
            label="Title"
          >
            {getFieldDecorator('title', {
              rules: [{
                required: true, message: 'Please input Title!',
              }],
            })(
              <Input placeholder="Basketball" />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Description"
          >
            {getFieldDecorator('description', {
              rules: [{
                required: true, message: 'Please input Description!',
              }],
            })(
              <TextArea rows={8} />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Start Date"
          >
            {getFieldDecorator('startDate', {
              rules: [{
                required: false, message: 'Please input endDate!',
              }],
            })(
              <DatePicker format={dateFormat} />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="End Date"
          >
            {getFieldDecorator('endDate', {
              rules: [{
                required: false, message: 'Please input endDate!',
              }],
            })(
              <DatePicker format={dateFormat} />
            )}
          </FormItem>

          {/* <FormItem
            {...formItemLayout}
            label="Select Type"
            hasFeedback
          >
            {getFieldDecorator('type', {
              rules: [
                { required: true, message: 'Please select type!' },
              ],
            })(
              <Select placeholder="Please select a Advertisment type">
                <Option value="n">Happenings</Option>
                <Option value="p">Promo</Option>
                <Option value='np'>Property News</Option>
              </Select>
            )}
          </FormItem> */}


        </Form>
        </Spin>

      </Modal>
    

    )
  }
}


function mapStateToProps(state) {
  return {
    pinboard: state.pinboard
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updatePinboardMode: updatePinboardMode,
    updateActivePinboard: updateActivePinboard
  }, dispatch);
}

// function getBase64(img, callback) {
//   const reader = new FileReader();
//   reader.addEventListener('load', () => callback(reader.result));
//   reader.readAsDataURL(img);
// }

// function beforeUpload(file) {
//   const isJPG = file.type === 'image/jpeg';
//   if (!isJPG) {
//     message.error('You can only upload JPG file!');
//   }
//   const isLt2M = file.size / 1024 / 1024 < 2;
//   if (!isLt2M) {
//     message.error('Image must smaller than 2MB!');
//   }
//   return isJPG && isLt2M;
// }



export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(PinboardForm)));
