import React, { Component } from 'react';
import { Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Card, Spin } from 'antd';
import { Route, Link } from 'react-router-dom';

import client from '../../feathers';
import requireAuth from "../../components/requireAuth";
import Layout from "../../components/layout";
import PaymentTypeParamsForm from "../../components/paymentTypeParams/form";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {

  fetchpaymentTypeParams,
  pushpaymentTypeParams,
  removepaymentTypeParams,
  updateMode,
  updateActivepaymentTypeParams,
  updatepaymentTypeParams
} from '../../actions/actions-paymenttypeparams';


const FormItem = Form.Item;

const text = 'Are you sure to delete this item?';

class paymentTypeParamsIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      loading: false,
      paymenttypeparams: [],
      loading: false,
    }

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidUpdate() {

    if (this.props.paymenttypeparams.mode === 'refresh') {
      this.componentWillMount()
    }
  }

  componentDidMount() {
  }

  componentWillMount() {

    this.props.updateMode('')
    this.setState({ loading: true });

    client.authenticate()
      .then(() => {
        return client.service('payment-type-params').find({
          query: {
            propertyId: this.props.commons.selectedCompany._id,
            $populate: 'ledgerAccountId',
            $sort: {
              description: 1
            }
          }
        })
      })
      .then((res) => {

        this.setState({ loading: false });
        this.props.fetchpaymentTypeParams(res.data)

      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      })
  }

  handleDelete(_id) {
    client.service('payment-type-params').remove(_id)
      .then((res) => {
        this.props.updateMode('refresh')
      })
      .catch((err) => {
        alert(err);
      })
  }

  handleEdit(_id) {
    this.props.updateActivepaymentTypeParams('edit', _id)

  }

  handleManage(_id) {
    this.props.updateActivepaymentTypeParams('', _id)
  }

  showModal = () => {
    this.props.updateMode('new')
  }

  renderConfirmDeleteBtn(_id) {
    return (
      <Popconfirm placement="topLeft" title={text} onConfirm={() => this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
        <Button type="danger">Delete</Button>
      </Popconfirm>
    )
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const columns = [

      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        width: '10%',
      },
      {
        title: 'Account',
        dataIndex: 'ledgerAccountId.accountName',
        key: 'ledgerAccountId.accountName',
        width: '10%',
      },
      {
        title: 'Account',
        dataIndex: 'ledgerAccountId.fullAccountNo',
        key: 'ledgerAccountId.fullAccountNo',
        width: '10%',
      },
      {
        title: 'Category',
        dataIndex: 'category',
        key: 'category',
        width: '10%',
      },
      {
        title: 'Special Code',
        dataIndex: 'specialCode',
        key: 'specialCode',
        width: '10%',
      },
      {
        title: 'Action',
        key: 'action',
        width: '10%',
        render: (text, record) => (
          <span>
            <Divider type="vertical" />
            <Button onClick={() => this.handleEdit(record._id)}>Edit</Button>
            <Divider type="vertical" />
            {this.renderConfirmDeleteBtn(record._id)}
          </span>
        ),
      }];

    return (
      <Layout>
        <Spin key='spinning' spinning={this.state.loading} >
          <Card
            title="Payment Type Parameter"
            extra={<Button type="primary" onClick={this.showModal}>New</Button>}
          >



            <Table dataSource={this.props.paymenttypeparams.paymenttypeparams} columns={columns} rowKey="_id" />


            <PaymentTypeParamsForm />
          </Card>
        </Spin>
      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    paymenttypeparams: state.paymenttypeparams,
    commons: state.commons
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchpaymentTypeParams: fetchpaymentTypeParams,
    pushpaymentTypeParams: pushpaymentTypeParams,
    removepaymentTypeParams: removepaymentTypeParams,
    updateActivepaymentTypeParams: updateActivepaymentTypeParams,
    updatepaymentTypeParams: updatepaymentTypeParams,
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(paymentTypeParamsIndex)));
