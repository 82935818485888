import React, { Component } from 'react'
import { Row, Col } from 'antd'

class Info extends Component{
  super(props){
  }

  

  render(){
    const styles = {
      amountStyle:{
        textAlign:'right',
        fontWeight:this.props.bold?'bold':'400'
      },
      textStyle:{
        textAlign:'left',
        fontWeight:this.props.bold?'bold':'400'
      }
    }

    return(
      <Row type="flex">
        <Col style={{'fontWeight':'bold'}} span={this.props.labelSpan} order={1}>{this.props.label}</Col>
        <Col  span={this.props.valueSpan} order={2}>
        <Row type="flex">
          <Col span={1}>:</Col>
          <Col span={22} style={this.props.type==='amount'?styles.amountStyle:styles.textStyle}>{this.props.value}</Col>
          </Row></Col>
      </Row>
    )
  }
}




export default Info;
