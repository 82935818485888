import React, { Component } from 'react';
import { Modal, message, Form, Input, Select, InputNumber, Radio, Row, Col, Spin } from 'antd';
import client from '../../feathers';
import requireAuth from "../../components/requireAuth";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateMode } from '../../actions/actions-charges';
import _ from 'lodash';

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

class ChargesForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',
      confirmDirty: false,
      autoCompleteResult: [],
      incomeAccounts: [],
      description: '',
      amtDescription: 'Amount',
      loading: false,
      monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December', 'All'],
      accountList: []
    }
  }
  componentDidUpdate() {

    if (this.props.charges.mode === 'refresh') {
      this.componentWillMount()
    }

    if (this.props.charges.activeId !== this.state._id) {
      this.setState({
        _id: this.props.charges.activeId
      })

      if (this.props.charges.activeCharges) {
        this.props.form.setFieldsValue(
          this.props.charges.activeCharges
        )
      }
      if (this.props.charges.accountNo) {
        this.props.form.setFieldsValue(
          this.props.charges.accountNo
        )
      }
    }
  }

  handleSubmit = (e) => {
    let propertyValue = ''
    try {
      propertyValue = this.props.user.info.propertyId._id
    }
    catch (err) {
      propertyValue = ''
    }

    e.preventDefault();
    this.setState({ loading: true });
    if (this.props.charges.mode === 'new') {

      this.props.form.validateFields((err, values) => {

        if (values.chargeType === 'PERCENTAGE') {
          values.amount = 0
        }

        if (values.chargeType === 'AMOUNT') {
          values.percentage = 0
        }
        if (!_.isEmpty(propertyValue)) {
          values.propertyId = propertyValue
        }
        else {
          return message.error('PropertyId not found')
        }


        if (!err) {

          client.service('charges').create({
            ...values,
            groupCode: 'NONE',
          })
            .then((res) => {
              this.props.updateMode('refresh');
              message.success('New Charges Added! Please go to Settings > Fee Recurring and recalculate the charges', 8);
              this.setState({ loading: false });
            })
            .catch((err) => {
              console.log(err);
              this.props.updateMode('');
              this.setState({ loading: false });
              alert(err);
            })
        }
      });
    } else {
      this.props.form.validateFields((err, values) => {

        if (values.chargeType === 'PERCENTAGE') {
          values.amount = 0
        }

        if (values.chargeType === 'AMOUNT') {
          values.percentage = 0
        }

        if (!err) {
          client.service('charges').patch(this.props.charges.activeId,
            values
          )
            .then((res) => {

              message.success(`Charges Updated ! Please go to Settings > Fee Recurring and recalculate the charges`, 8);
              this.props.updateMode('refresh');
              this.setState({ loading: false });

            })
            .catch((err) => {
              console.log(err);

              this.props.updateMode('');
              this.setState({ loading: false });
            })
          return;
        }

      });
    }
  }

  componentWillMount() {

    this.props.updateMode('');

    client.authenticate()
      .then(() => {
        return client.service('ledger-accounts').find({
          query: {
            companyId: this.props.user.info.propertyId._id,
            categories: 'INCOMES',
            subAccountNo: '000',
            $sort: { description: 1 }
          }
        })
      })
      .then((res) => {
        this.setState({
          incomeAccounts: res.data
        })
      })
      .catch((err) => {
        console.log(err);
      });

  }

  handleChange = (e) => {

    if (e.target.value === 'incomes') {
      this.setState({ description: 'This Fee will be park under Accounting INCOMES' });
    } else if (e.target.value === 'current liabilities') {
      this.setState({ description: 'This Fee will be park under Accounting LIABILITIES' });
    } else {
      this.setState({ description: '' });
    }
  }

  handleChangeCode = (e) => {

    if (e.target.value === 'MNTFEE') {
      this.setState({ amtDescription: 'Amount / SquareFeet' });
    }
  }

  handleBlur = (e) => {
    let code = e.target.value;
    this.props.form.setFieldsValue({
      code: code.toUpperCase(),
    })
  }

  renderTypeSelection() {
    const { getFieldDecorator } = this.props.form;

    if (this.props.form.getFieldValue('chargeType') === 'AMOUNT') {

      return (
        <FormItem
          {...formItemLayout}
          label={this.state.amtDescription}
        >
          {getFieldDecorator('amount', {
            rules: [{
              required: true, message: 'Please input Amount!',
            }],
          })(
            <Input placeholder={'Per Sq Ft. eg. 0.215'} />
          )}
        </FormItem>
      )

    } else if (this.props.form.getFieldValue('chargeType') === 'PERCENTAGE') {

      return (
        <FormItem
          {...formItemLayout}
          label='Percentage'
        >
          {getFieldDecorator('percentage', {
            rules: [{
              required: true, message: 'Please input Percentage!',
            }],
          })(
            <InputNumber placeholder={'1 = 100%, 0.1 = 10%'} />
          )}
        </FormItem>
      )

    } else {
      return (<span></span>)
    }

  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Spin spinning={this.state.loading} >
        <Modal
          visible={
            this.props.charges.mode === 'new' || this.props.charges.mode === 'edit' ? true : false
          }
          width={600}
          title={this.props.charges.mode === 'edit' ? 'Update Fee' : 'Create Fee'}
          okText={this.props.charges.mode === 'edit' ? 'Update' : 'Create'}
          onCancel={() => { this.props.updateMode(''); this.props.form.resetFields() }}
          onOk={this.handleSubmit}

        >
          <Form className="submit-form">

            <Row>
              <Col offset={8}>
                <small style={{ color: 'red' }} >{this.state.description}</small>
              </Col>
            </Row>

            <FormItem
              {...formItemLayout}
              label="Account"
            >
              {getFieldDecorator('accountNo', {
                rules: [{
                  required: true, message: 'Please select Accounting!',
                }],
              })(
                <Select placeholder="Account">
                  {this.state.incomeAccounts.map(i => {
                    return (<Option key={i.accountNo} value={i.accountNo}>{i.accountName} -- {i.fullAccountNo}</Option>)
                  })}

                </Select>
              )}
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Code"
            >
              {getFieldDecorator('code', {
                rules: [{
                  required: true, message: 'Please input Code!',
                }],
              })(
                <Input onBlur={this.handleBlur} onChange={this.handleChangeCode} disabled={this.props.charges.mode === 'edit' ? true : false} placeholder="MNTFEE" />
              )}
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Description"
            >
              {getFieldDecorator('description', {
                rules: [{
                  required: true, message: 'Please input description!',
                }],
              })(
                <TextArea disabled={this.props.charges.mode === 'edit' ? 'true' : ''} rows={4} placeholder='MANAGEMENT FEE, SINKING FUND, etc...' />
              )}
            </FormItem>

            <FormItem
              {...formItemLayout}
              label='Late Charge?'
            >
              {getFieldDecorator('lateCharge', {
                rules: [{
                  required: true, message: 'Please select!',
                }],
              })(
                <Radio.Group>
                  <Radio.Button value="YES">YES</Radio.Button>
                  <Radio.Button value="NO">NO</Radio.Button>
                </Radio.Group>
              )}
            </FormItem>

            <FormItem
              {...formItemLayout}
              label='Type'
            >
              {getFieldDecorator('chargeType', {
                rules: [{
                  required: true, message: 'Please Select Type!',
                }],
              })(
                <Radio.Group>
                  <Radio.Button value="AMOUNT">AMOUNT</Radio.Button>
                  <Radio.Button value="PERCENTAGE">PERCENTAGE</Radio.Button>
                </Radio.Group>
              )}
            </FormItem>

            {this.renderTypeSelection()}

            <FormItem
              {...formItemLayout}
              label="Recurring Status"
              hasFeedback
            >
              {getFieldDecorator('status', {
                rules: [
                  { required: true, message: 'Please select your status!' },
                ],
              })(
                <Select placeholder="Please select a status">
                  <Option value="ACTIVE">ACTIVE</Option>
                  <Option value="INACTIVE">INACTIVE</Option>
                </Select>
              )}
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Apply Month"
              hasFeedback
            >
              {getFieldDecorator('applyOnMonth', {
                rules: [
                  { required: true, message: 'Please select your Month!' },
                ],
              })(
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder="Please select"
                //onChange={handleChange}
                >
                  {this.state.monthNames.map(dropdown => (
                    <Option key={dropdown} value={dropdown}>
                      {dropdown}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Special Code"
            >
              {getFieldDecorator('specialCode', {
                rules: [
                  { required: false, message: 'Please input Special Code!' },
                ],
              })(
                <Input />
              )}
            </FormItem>

          </Form>
        </Modal>
      </Spin>
    )
  }
}

function mapStateToProps(state) {
  return {
    charges: state.charges
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(ChargesForm)));
