import React, { Component } from 'react';
import { Popconfirm, message, Divider,  Table, Form, Icon, Input, Button, Row, Col , Pagination, Spin, Select} from 'antd';
import { Route, Link } from 'react-router-dom';

import client from '../../feathers';
import requireAuth  from "../../components/requireAuth";
import Layout  from "../../components/layout";
import PropertyunitsForm  from "../../components/propertyunits/form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import {
  fetchPropertyunits,
  pushPropertyunits,
  removePropertyunits,
  updateMode,
  updateActivePropertyunits,
  updatePropertyunits } from '../../actions/actions-propertyunits';


const FormItem = Form.Item;
const Option = Select.Option;
const PAGESIZE = 10;

const text = 'Are you sure to delete this item?';

class PropertyunitsIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalPagination:1,
      CurrentPagination:1,
      propertyunitsDropdowns:[],
      fetching:false,
      searchObj:{},
      searchUnit:'',
      typingTimeout:0
    }

    this.handleDelete = this.handleDelete.bind(this);

  }

  _searchUnitChange(e){
    clearTimeout(this.state.typingTimeout);
    this.setState({
        searchUnit:e.target.value,
        searchObj:_.merge(this.state.searchObj,e.target.value?{referenceId:e.target.value}:{referenceId:'.'})
      },
    ()=>{
      this._getData(this.state.skip)
    })
  }

  componentDidUpdate(){
  }

  componentDidMount(){

  }

  componentWillMount(){

      this._getData(0)

      // this.getPropertyunitDropdown();
  }

  getPropertyunitDropdown(){
    //generate property unit dropdown
    client.authenticate()
    .then((res)=>{
        return client.service('propertyunits').find({
          query: {
            propertyId: this.props.propertyId,
             $sort: {
               referenceId: 1
             },
             $limit:10,
           }
        })
    })
    .then((res)=>{

      this.setState({propertyunitsDropdowns:res.data});
    })
    .catch((err)=>{
        console.log(err);
    });
  }

  handleDelete(_id) {
     client.service('propertyunits').remove(_id)
     .then((res)=>{
       message.success('Removed')
     })
     .catch((err)=>{
       alert(err);
     })
  }

  handleEdit(_id) {
    this.props.updateActivePropertyunits('edit',_id)
  }

  handleManage(_id) {
    this.props.updateActivePropertyunits('',_id)
  }

  showModal = () => {
    this.props.updateMode('new')
  }

  _getData(skip){
    client.authenticate()
    .then((res)=>{
        return client.service('propertyunits').find({
          query: {
            propertyId:this.props.propertyId,
            $limit:PAGESIZE,
            $skip:skip,
            $sort: {
              referenceId: -1
            },
            orRegex: this.state.searchObj
          }
        })
    })
    .then((res)=>{
      // console.log({res});
      this.setState({
        loading:false,
        totalPagination:res.total
      });
      this.props.fetchPropertyunits(res.data);
    })
    .catch((err)=>{
        console.log(err);
    })
  }

  handlePageChange = (page) => {
    console.log(page)
    this.setState({
      CurrentPagination: page,
    });
    this.props.form.validateFields((err, values) => {

      this._getData((page-1)*PAGESIZE)
      // client.authenticate()
      // .then((res)=>{
      //
      //     return client.service('propertyunits').find({
      //       query: {
      //         ...values,
      //         propertyId:this.props.propertyId,
      //         $limit:10,
      //         $skip:(page-1)*10,
      //         $sort: {
      //           referenceId: -1
      //         }
      //       }
      //     })
      // })
      // .then((res)=>{
      //
      //   this.setState({totalPagination:res.total});
      //   this.props.fetchPropertyunits(res.data);
      // })
      // .catch((err)=>{
      //     console.log(err);
      // })
    });
  }

  renderConfirmDeleteBtn(_id){
    return (
      <Popconfirm placement="topLeft" title={text} onConfirm={()=>this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
        <Button type="danger">Delete</Button>
      </Popconfirm>
    )
  }

  render() {

    const columns = [
      {
        title:(
          <Row gutter={8}>
             <Col span={24}>Unit Number</Col>
             <Col span={24}><Input value={this.state.searchUnit} onChange={(e)=>this._searchUnitChange(e)}/></Col>
          </Row>
      ),
        dataIndex: 'referenceId',
        key: 'referenceId',
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <Button onClick={()=>this.handleEdit(record._id)}>Edit</Button>
            <Divider type="vertical" />
            {this.renderConfirmDeleteBtn(record._id)}
          </span>
        ),
      }
    ];

    return(
      <div>
        <Table
          dataSource={this.props.propertyunits.propertyunits}
          columns={columns}
          pagination={{
            total:this.state.totalPagination,
            defaultCurrent:1,
            onChange:this.handlePageChange
          }}
          rowKey="_id"
        />
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    propertyunit: state.propertyunit,
    propertyunits: state.propertyunits
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchPropertyunits: fetchPropertyunits,
    pushPropertyunits: pushPropertyunits,
    removePropertyunits: removePropertyunits,
    updateActivePropertyunits: updateActivePropertyunits,
    updatePropertyunits: updatePropertyunits,
    updateMode: updateMode
    }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(PropertyunitsIndex)));
