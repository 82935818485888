import React, { Component } from 'react';
import { Modal, message, Form, Input, Button, Select, InputNumber, DatePicker } from 'antd';
import client from '../../feathers';
import requireAuth from "../../components/requireAuth";
import Layout from "../../components/layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateMode } from '../../actions/actions-accountingperiods'
import moment from 'moment';

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
const { MonthPicker, RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';
const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};



class AccountingPeriodsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: '',
      dataSource: {}
    }
  }
  componentDidUpdate() {
    if (this.props.mode !== this.state.mode) {
      this.setState({
        mode: this.props.mode
      });
    }

    if (this.props.dataSource !== this.state.dataSource) {
      this.setState({
        dataSource: this.props.dataSource
      });

      this.props.form.setFieldsValue(
        this.props.dataSource
      );
    }
  }


  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.mode === 'New') {

      this.props.form.validateFields((err, values) => {
        let data = {
          ...values,
          fromDate: values.dateSelection[0],
          toDate: values.dateSelection[1],
          companyId: this.props.user.info.propertyId._id,
          companyType: 'PROPERTY',
        }

        console.log({ data });

        if (!err) {
          client.service('accounting-periods').create(data)
            .then((res) => {
              this.setState({ visible: false });
              message.success('New Settings Added');
            })
            .catch((err) => {
              alert(err);
            })
        }
      });
    } else {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          client.service('accounting-periods').patch(this.state.dataSource._id,
            values
          )
            .then((res) => {
              message.success(`Settings Updated`);
              this.setState({ visible: false });
            })
            .catch((err) => {
              alert(err);
            })
          return;
        }

      });
    }

  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  }

  handleOk = (e) => {

    this.setState({
      visible: false,
    });
  }

  handleCancel = (e) => {

    this.setState({
      visible: false,
    });
  }

  render() {

    const { getFieldDecorator } = this.props.form;


    return ([
      <Button type="primary" onClick={this.showModal}>
        {this.state.mode}
      </Button>
      ,
      <Modal
        title={this.state.mode}
        visible={this.state.visible}
        onOk={this.handleSubmit}
        onCancel={this.handleCancel}
        okText={'Submit'}
      >
        <Form className="submit-form">

          <FormItem
            {...formItemLayout}
            label="Date Selection"
          >
            {getFieldDecorator('dateSelection', {
              rules: [{
                required: true, message: 'Please select Date!',
              }],
            })(
              <RangePicker
                defaultValue={[moment(), moment()]}
                format={dateFormat}
              />
            )}
          </FormItem>

        </Form>
      </Modal>
    ])
  }
}


function mapStateToProps(state) {
  return {
    accountingperiods: state.accountingperiods,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(AccountingPeriodsForm)));
