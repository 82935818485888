import React, { Component } from 'react';
import { Modal, message, Form, Icon, Input, Select } from 'antd';
import client from '../../feathers';
import requireAuth  from "../../components/requireAuth";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateMode } from '../../actions/actions-requests';


const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};


class RequestsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id:'',
      confirmDirty: false,
      autoCompleteResult: [],
    }
  }
  componentDidUpdate(){
      if(this.props.requests.activeId !== this.state._id){
        this.setState({
          _id:this.props.requests.activeId
        })

        if(this.props.requests.activeRequests){
          this.props.form.setFieldsValue(
            this.props.requests.activeRequests
          )
        }
      }

  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }


  handleSubmit = (e) => {
     e.preventDefault();
     if(this.props.requests.mode === 'new'){

       this.props.form.validateFields((err, values) => {
         if (!err) {
           client.service('requests').create(values)
           .then((res)=>{

             this.props.updateMode('')
             message.success('New Facility Added');
           })
           .catch((err)=>{
            console.log(err);
             this.props.updateMode('')
             alert(err);
           })
         }
       });
     }else{
       this.props.form.validateFields((err, values) => {
         if (!err) {
           client.service('requests').patch(this.props.requests.activeId,
             values
           )
           .then((res)=>{

             message.success(`Requests Updated`);
             this.props.updateMode('')
            this.props.updateMode('refresh')
           })
           .catch((err)=>{
            console.log(err);

             this.props.updateMode('')
           })
           return;
         }

       });
     }
  }

  onChange = (time) => {
    //this.setState({ value: time });
  }

  render() {

    const { getFieldDecorator } = this.props.form;


    return(
      <Modal
         visible={
           this.props.requests.mode==='new'||this.props.requests.mode==='edit'?true:false
         }
         title={ this.props.requests.mode === 'edit' ? 'Update Requests':'Create Requests' }
         okText={ this.props.requests.mode === 'edit' ? 'Update':'Create' }
         onCancel={()=>{this.props.updateMode(''); this.props.form.resetFields()} }
         onOk={this.handleSubmit}

      >
        <Form className="submit-form">

          <FormItem
             {...formItemLayout}
             label="Name"
           >
             {getFieldDecorator('name', {
               rules: [{
                 required: true, message: 'Please input Requests Name!',
               }],
             })(
               <Input prefix={<Icon type="idcard" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="SUPERHERO" />
             )}
          </FormItem>

          <FormItem
             {...formItemLayout}
             label="Description"
           >
             {getFieldDecorator('description', {
               rules: [{
                 required: false, message: 'Please input Description!',
               }],
             })(
              <TextArea rows={4} />
             )}
          </FormItem>

          <FormItem
             {...formItemLayout}
             label="Unit"
           >
             {getFieldDecorator('unit', {
               rules: [{
                 required: true, message: 'Please input Unit!',
               }],
             })(
               <Input prefix={<Icon type="tag" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="1" />
             )}
          </FormItem>

          <FormItem
             {...formItemLayout}
             label="Charges Amount"
           >
             {getFieldDecorator('amount', {
               rules: [{
                 required: false, message: 'Please input amount!',
               }],
             })(
               <Input placeholder="10.00" />
             )}
          </FormItem>

          <FormItem
              {...formItemLayout}
              label="Start Time"
            >
              {getFieldDecorator('startTime', {
                rules: [{
                  required: true, message: 'Please input Start Time!',
                }],
              })(
                <Select placeholder="Please Select Time">
                  <Option value="00:00">00:00</Option>
                  <Option value="00:30">00:30</Option>
                  <Option value="01:00">01:00</Option>
                  <Option value="01:30">01:30</Option>
                  <Option value="02:00">02:00</Option>
                  <Option value="02:30">02:30</Option>
                  <Option value="03:00">03:00</Option>
                  <Option value="03:30">03:30</Option>
                  <Option value="04:00">04:00</Option>
                  <Option value="04:30">04:30</Option>
                  <Option value="05:00">05:00</Option>
                  <Option value="05:30">05:30</Option>
                  <Option value="06:00">06:00</Option>
                  <Option value="06:30">06:30</Option>
                  <Option value="07:00">07:00</Option>
                  <Option value="07:30">07:30</Option>
                  <Option value="08:00">08:00</Option>
                  <Option value="08:30">08:30</Option>
                  <Option value="09:00">09:00</Option>
                  <Option value="09:30">09:30</Option>
                  <Option value="10:00">10:00</Option>
                  <Option value="10:30">10:30</Option>
                  <Option value="11:00">11:00</Option>
                  <Option value="11:30">11:30</Option>
                  <Option value="12:00">12:00</Option>
                  <Option value="12:30">12:30</Option>
                  <Option value="13:00">13:00</Option>
                  <Option value="13:30">13:30</Option>
                  <Option value="14:00">14:00</Option>
                  <Option value="14:30">14:30</Option>
                  <Option value="15:00">15:00</Option>
                  <Option value="15:30">15:30</Option>
                  <Option value="16:00">16:00</Option>
                  <Option value="16:30">16:30</Option>
                  <Option value="17:00">17:00</Option>
                  <Option value="17:30">17:30</Option>
                  <Option value="18:00">18:00</Option>
                  <Option value="18:30">18:30</Option>
                  <Option value="19:00">19:00</Option>
                  <Option value="19:30">19:30</Option>
                  <Option value="20:00">20:00</Option>
                  <Option value="20:30">20:30</Option>
                  <Option value="21:00">21:00</Option>
                  <Option value="21:30">21:30</Option>
                  <Option value="22:00">22:00</Option>
                  <Option value="22:30">22:30</Option>
                  <Option value="23:00">23:00</Option>
                  <Option value="23:30">23:30</Option>
              </Select>
              )}
          </FormItem>


            <FormItem
              {...formItemLayout}
              label="Max Session Per Day"
            >
              {getFieldDecorator('maxSessionPerday', {
                rules: [{
                  required: true, message: 'Please input no of session!',
                }],
              })(
                <Input placeholder="0" />
              )}
          </FormItem>

          <FormItem
             {...formItemLayout}
             label="Minute Per Session"
           >
             {getFieldDecorator('minutePerSession', {
               rules: [{
                 required: true, message: 'Please input minute!',
               }],
             })(
               <Input placeholder="60" />
             )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Select Categories"
            hasFeedback
          >
            {getFieldDecorator('categories', {
              rules: [
                { required: true, message: 'Please select your categories!' },
              ],
            })(
              <Select placeholder="Please select a categories">
                <Option value="GYM">GYM</Option>
                <Option value="OTHERS">Others</Option>
              </Select>
            )}
          </FormItem>

        </Form>
      </Modal>

    )
  }
}


function mapStateToProps(state) {
  return {
    requests: state.requests
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
    }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(RequestsForm)));
