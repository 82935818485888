export const FETCH_METERREADINGS = 'FETCH_METERREADINGS';
export const PUSH_METERREADINGS = 'PUSH_METERREADINGS';
export const REMOVE_METERREADINGS = 'REMOVE_METERREADINGS';
export const UPDATE_METERREADINGS = 'UPDATE_METERREADINGS';
export const UPDATE_MODE = 'UPDATE_MODE';
export const UPDATE_ACTIVE_ID = 'UPDATE_ACTIVE_ID';

export function fetchMeterReadings(data){
  return (dispatch) => {
    dispatch({
      type: FETCH_METERREADINGS,
      payload:data
    });
  }
}

export function pushMeterReadings(data){
  return (dispatch) => {
    dispatch({
      type: PUSH_METERREADINGS,
      payload:data
    });
  }
}

export function removeMeterReadings(data){
  return (dispatch) => {
    dispatch({
      type: REMOVE_METERREADINGS,
      payload:data
    });
  }
}

export function updateMeterReadings(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_METERREADINGS,
      payload:data
    });
  }
}

export function updateMode(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE,
      payload:data
    });
  }
}

export function updateActiveMeterReadings(mode, id){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_ID,
      mode,
      id
    });
  }
}
