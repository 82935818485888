export const FETCH_PAYMENTTYPEPARAMS = 'FETCH_PAYMENTTYPEPARAMS';
export const PUSH_PAYMENTTYPEPARAMS = 'PUSH_PAYMENTTYPEPARAMS';
export const REMOVE_PAYMENTTYPEPARAMS = 'REMOVE_PAYMENTTYPEPARAMS';
export const UPDATE_PAYMENTTYPEPARAMS = 'UPDATE_PAYMENTTYPEPARAMS';
export const UPDATE_MODE = 'UPDATE_MODE';
export const UPDATE_ACTIVE_ID = 'UPDATE_ACTIVE_ID';

export function fetchpaymentTypeParams(data){
  return (dispatch) => {
    dispatch({
      type: FETCH_PAYMENTTYPEPARAMS,
      payload:data
    });
  }
}

export function pushpaymentTypeParams(data){
  return (dispatch) => {
    dispatch({
      type: PUSH_PAYMENTTYPEPARAMS,
      payload:data
    });
  }
}

export function removepaymentTypeParams(data){
  return (dispatch) => {
    dispatch({
      type: REMOVE_PAYMENTTYPEPARAMS,
      payload:data
    });
  }
}

export function updatepaymentTypeParams(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_PAYMENTTYPEPARAMS,
      payload:data
    });
  }
}

export function updateMode(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE,
      payload:data
    });
  }
}

export function updateActivepaymentTypeParams(mode, id){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_ID,
      mode,
      id
    });
  }
}
