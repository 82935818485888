import React, { Component } from 'react';
import {
  Layout,
  Menu,
  Breadcrumb,
  notification,
  Icon,
  Avatar,
  Row,
  Col,
  Card,
  Tooltip,
  message,
  Badge,
  Button,
  List,
  Popover,
} from 'antd';
import { Link, withRouter, Route } from 'react-router-dom';
import client from '../feathers';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logoutSuccessful } from '../actions/user-actions';
import { updateActiveMenuKey, updateActivePage } from '../actions/app-actions';
import _, { times } from 'lodash';
import moment from 'moment';
import { initFirebaseToken, onMessageListener } from '../webPush';
import firebase from 'firebase/app';

import {
  accounting,
  advertisement,
  dashboard,
  eform,
  managementuser,
  management,
  notices,
  propertyrequest,
  property,
  reporting,
  services,
  facilities,
  user,
  setting,
  invoices,
  propertyunits,
  payments,
  companyprofile,
} from '../assets/menu-icons/';
import { kbmslogo } from '../assets/images/';
import axios from 'axios';

const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;

class layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      current: 0,
      incomingNotification: false,
      currentFcmtoken: '',
      notificationList: [],
    };
  }

  componentWillMount() {
    // console.log('user reducer', this.props.user);
    // axios.post(`${client.io.io.uri}sendTestNotification`, {
    //   // userId: this.props.user.info.user._id,
    //   // token: dataToken,
    //   // managementId: managementId
    // }).then((res) => {
    //   console.log('sucesss patch fcmtokennn,hehrralksjkl')
    // }).catch((err) => {
    //   console.log(err, 'his')
    // })
  }

  async getTokenBrowser() {
    const token = await initFirebaseToken();
    if (token) {
      this.subscribeTopics(token);
      this.setState({ currentFcmtoken: token });
    }
  }

  async getMessage() {
    let noti = await onMessageListener();
    if (!_.isEmpty(noti)) {
      console.log(noti);
      this.setState({ incomingNotification: true });
      notification.info({
        message: (
          <div>
            {noti.notification.title}{' '}
            <a href={noti.notification.click_action}>Click</a>
          </div>
        ),
        description: noti.notification.body,
        duration: 0,
      });
    }
  }

  subscribeTopics(dataToken) {
    axios
      .post(`${client.io.io.uri}getNotificationTokenFromUser`, {
        userId: this.props.user.info.user._id,
        token: dataToken,
        role: this.props.user.info.user.role,
      })
      .then((res) => {
        // console.log('sucesss patch fcmtokennn')
      })
      .catch((err) => {
        console.log(err, 'this');
      });
  }

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  handleLogout = () => {
    client.logout().then(() => {
      axios
        .post(`${client.io.io.uri}unsubscribeTopicToken`, {
          // isRemove: true,
          userId: this.props.user.info.user._id,
          token: this.state.currentFcmtoken,
        })
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    });
    this.props.updateActiveMenuKey('0');
    this.props.logoutSuccessful();
    localStorage.clear();
    sessionStorage.clear();
    this.props.history.push('/');
  };

  handleClick = (e) => {
    this.props.updateActiveMenuKey(e.key);
    this.props.updateActivePage({
      statementPage: 1,
      depositPage: 1,
      refundPage: 1,
      invoicePage: 1,
      officialReceiptPage: 1,
      supplierStatementPage: 1,
      supplierInvoicePage: 1,
      paymentVoucherPage: 1,
      journalentriesPage: 1,
      bankReconPage: 1,
    });
  };

  handleOpenChange = (e) => {
    // console.log({e});
    // this.setState({
    //   current: e.key,
    // });
    // this.props.updateActiveMenuKey(e.key)
  };

  handleChangeMenuStyle(index) {
    if (this.state.activeMenu === index) {
      return 'ant-menu-item-selected';
    }
  }

  componentDidMount() {
    this.getTokenBrowser();
    this.getMessage();
  }
  renderUserDetails() {
    let userDetails = _.get(this.props, ['user', 'info', 'user']);
    let userName = userDetails && _.get(userDetails, ['username']);
    let userRole = userDetails && _.get(userDetails, ['role', 0]);

    userName = userName && userName.slice(0, userName.indexOf('@'));
    userRole = userRole.charAt(0).toUpperCase() + userRole.slice(1);

    return (
      // <Popover content={content} trigger='click'>
      <Row>
        <Icon type='user' />: <b>{userName}</b>
        <Icon type='audit' style={{ marginLeft: 15 }} /> : <b>{userRole}</b>
      </Row>
      // </Popover>
    );
  }

  _renderIcon(type, iconName) {
    if (type === 'image') {
      return <img className='menu-image anticon' src={iconName} />;
    }
    return <Icon type={iconName} />;
  }

  render() {
    let menuItem = [];
    let { notificationList } = this.state;
    const authoriseRole = [
      'admin',
      'authoriser',
      'account',
      'management',
      'executive',
    ];

    let currentUserRole = _.get(this.props, [
      'user',
      'info',
      'user',
      'role',
      0,
    ]);
    let propertyName = _.get(this.props, [
      'user',
      'info',
      'propertyId',
      'name',
    ]);
    let showProperty = '';
    let managementId = _.get(this.props, [
      'user',
      'info',
      'managementId',
      '_id',
    ]);

    if (authoriseRole.includes(currentUserRole)) {
      showProperty = propertyName;
    } else {
      showProperty = 'SYSTEM ADMIN';
    }

    let menuSuperAdminItem = [
      {
        name: 'Dashboard',
        url: '/dashboard',
        icon: 'bar-chart',
      },
      {
        name: 'Property Units',
        url: '/propertyunitsmng',
        icon: 'home',
      },
      {
        name: 'Management User',
        url: '/users-mng',
        icon: 'user',
      },
      {
        name: 'User',
        url: '/users',
        icon: 'user',
      },
      {
        name: 'Property',
        url: '/property',
        icon: 'home',
      },
      {
        name: 'Management',
        url: '/management',
        icon: 'team',
      },
      {
        name: 'Invoices',
        url: '/invoices',
        icon: 'snippets',
      },
      {
        name: 'Payments',
        url: '/transactions',
        icon: 'barcode',
      },
      {
        name: 'Advertisement',
        url: '/ads',
        icon: 'shopping',
      },
      {
        name: 'Services',
        url: '/vendors',
        icon: 'appstore',
      },
      {
        //   'name': 'Notices',
        //   'url': '/notices',
        //   'icon': 'notification'
        // }, {
        name: 'Property Request',
        url: '/requests',
        icon: 'select',
      },
      {
        name: 'E-forms',
        url: '/eforms',
        icon: 'bars',
      },
    ];

    let menuAuthoriser = [
      {
        name: 'Dashboard',
        url: '/dashboard',
        icon: dashboard,
        iconType: 'image',
      },
      {
        name: 'Building Information',
        icon: companyprofile,
        iconType: 'image',
        subitem: [
          {
            name: 'JMB Info',
            url: '/jmbs',
          },
          {
            name: 'JMB Org Chart',
            url: '/charts/organizational',
          },
          {
            name: 'Floor Plan Upload',
            url: '/files',
          },
          {
            name: 'Property Units',
            url: '/propertyunitsmng',
          },
          {
            name: 'Apps Request',
            url: '/requests/index-mng',
            icon: 'user-add',
          },
          {
            name: 'Property News',
            url: '/ads',
          },
          {
            name: 'Pinboard',
            url: '/pinboard',
          },
        ],
      },
      {
        name: 'Management',
        icon: companyprofile,
        iconType: 'image',
        subitem: [
          {
            //   'name': 'Notices',
            //   'url': '/notices'
            // }, {
            name: 'eForms',
            url: '/eforms',
          },
          {
            name: 'eFacilities',
            url: '/facilities',
          },
          {
            //   'name': 'eFacilities Bookings',
            //   'url': '/facilityBookings'
            // }, {
            name: 'eFeedBack',
            url: '/complaints',
          },
          {
            name: 'eVisitor',
            url: '/passes',
          },
          {
            name: 'HELP',
            url: '/help',
          },

          {
            name: 'Document',
            url: '/document',
          },

          {
            name: 'Meter Reading',
            url: '/meterReadings',
          },
          {
            name: 'Guard Management',
            url: '/manageGuard',
          },
        ],
      },
      {
        name: 'Accounting',
        icon: accounting,
        iconType: 'image',
        subitem: [
          // {
          //   'name': 'Customer',
          //   'label': 'Y',
          // },
          {
            name: 'Maintenance',
            url: '/ledgers/debtors',
          },
          {
            name: 'Account Statement',
            url: '/statementofaccount',
          },
          {
            name: 'Deposit',
            url: '/deposit',
          },
          {
            name: 'Refund Listing',
            url: '/refunds/index-listing/',
          },
          {
            name: 'Invoices',
            url: '/invoices',
          },
          {
            name: 'Official Receipt',
            url: '/transactions',
          },
          {
            name: 'Prepayment',
            url: '/prepaymentbyProps',
          },
          {
            name: 'Waiver',
            url: '/waiverbyProps',
          },
          // {
          //    'name': 'Late Charge',
          //    'url': '/late-charge'
          //  },
          {
            name: 'Water Bills',
            url: '/waterbills',
          },
          {
            name: 'Debit Notes',
            url: '/debitnotes',
          },
          {
            name: 'Credit Notes',
            url: '/creditnotes',
          },
        ],
      },
      {
        name: 'Supplier',
        icon: accounting,
        iconType: 'image',
        subitem: [
          {
            name: 'Maintenance',
            url: '/suppliers',
          },
          {
            name: 'Account Statement',
            url: '/supplierStatementAccount',
          },
          {
            name: 'Invoices',
            url: '/receipts',
          },
          {
            name: 'Payment Voucher',
            url: '/paymentvoucher',
          },
          // {
          //   'name': 'Debit Notes',
          //   'url': '/debitnotesSupplier'
          // },
          {
            name: 'Credit Notes',
            url: '/creditnotesSupplier',
          },
        ],
      },
      {
        name: 'Cash/Bank',
        icon: accounting,
        iconType: 'image',
        subitem: [{ name: 'Transactions', url: '/cashAndbanktransactions' }],
      },
      {
        name: 'Reporting',
        icon: reporting,
        iconType: 'image',
        subitem: [
          {
            name: 'Journal Entry',
            url: '/ledgers/journalentries',
          },
          // {
          //   'name': 'Journal Entry',
          //   'url': '/ledgers/main-transactions'
          // },
          {
            name: 'General Ledger',
            url: '/ledgers/accounts',
          },
          {
            name: 'Bank Reconciliation',
            url: '/bankrecons/main',
          },
          {
            name: 'Receipt and Payment',
            url: '/reports/receipt-and-payment',
          },
          {
            name: 'Trial Balance',
            url: '/reports/trial-balance',
          },
          {
            name: 'Profit and Loss',
            url: '/reports/profit-and-loss',
          },
          {
            name: 'Balance Sheet',
            url: '/reports/balance-sheet',
          },
          {
            name: 'Chart Of Accounts',
            url: '/chartOfAccounts/view',
          },
          {
            name: 'Debtor Aging',
            url: '/debtorAging/view',
          },
          {
            name: 'Creditor Aging',
            url: '/creditorAging/view',
          },
          {
            name: 'Payment Log',
            url: '/paymentlog',
          },
          // , {
          //   'name': 'Interest Debit Note',
          //   'url': '/interestDebitNote/index'
          // }
          {
            name: 'Reminder/NOD Letter',
            url: '/reminderLetter/',
          },
          {
            name: 'Money In Transit',
            url: '/moneyInTransit/',
          },
        ],
      },
      {
        name: 'Uploads',
        icon: reporting,
        iconType: 'image',
        subitem: [
          {
            name: 'Water Bill',
            url: '/uploads/waterBill',
          },
          {
            name: 'Opening Balance',
            url: '/uploads/firstOpeningBalance',
          },
        ],
      },
      {
        name: 'Settings',
        icon: setting,
        iconType: 'image',
        subitem: [
          {
            name: 'Profile',
            url: `/management/view/${managementId}`,
          },
          {
            name: 'Users',
            url: '/mng-users',
          },
          {
            name: 'General',
            url: '/settings',
          },
          {
            name: 'Fee',
            url: '/charges',
          },
          {
            name: 'Fee Recurring',
            url: '/recurring-items',
          },
          {
            name: 'Initial Account Amount',
            url: '/firstTime/ledger',
          },
          {
            name: 'No Affect GL',
            url: '/firstTime/invoice',
          },
          // {
          //   'name': 'Accounting Periods',
          //   'url': '/accounting-periods'
          // },
          {
            name: 'T&C ',
            url: '/settings/invoice-tnc',
          },
          {
            name: 'Transaction Parameter',
            url: '/trxparameter',
          },
          {
            name: 'Payment Type Setup',
            url: '/paymenttypeparams',
          },
          // {
          //   'name': 'FPX Parameter',
          //   'url': '/fpxparameter'
          // },
        ],
      },
    ];
    let menuMngItem = [
      {
        name: 'Dashboard',
        url: '/dashboard',
        icon: dashboard,
        iconType: 'image',
      },
      {
        name: 'Building Info',
        icon: companyprofile,
        iconType: 'image',
        subitem: [
          {
            name: 'Floor Plan Upload',
            url: '/files',
          },
          {
            name: 'Property Units',
            url: '/propertyunitsmng',
          },
          {
            name: 'Apps Request',
            url: '/requests/index-mng',
            icon: 'user-add',
          },
          {
            name: 'Property News',
            url: '/ads',
          },
          {
            name: 'Pinboard',
            url: '/pinboard',
          },
        ],
      },
      {
        name: 'Management',
        icon: companyprofile,
        iconType: 'image',
        subitem: [
          {
            //   'name': 'Notices',
            //   'url': '/notices'
            // }, {
            name: 'eForms',
            url: '/eforms',
          },
          {
            name: 'eFacilities',
            url: '/facilities',
          },
          {
            //   'name': 'eFacilities Bookings',
            //   'url': '/facilityBookings'
            // }, {
            name: 'eFeedBack',
            url: '/complaints',
          },
          {
            name: 'eVisitor',
            url: '/passes',
          },
          {
            name: 'HELP',
            url: '/help',
          },

          {
            name: 'Document',
            url: '/document',
          },

          {
            name: 'Meter Reading',
            url: '/meterReadings',
          },
          {
            name: 'Guard Management',
            url: '/manageGuard',
          },
        ],
      },
      {
        name: 'Accounting',
        icon: accounting,
        iconType: 'image',
        subitem: [
          // {
          //   'name': 'Customer',
          //   'label': 'Y',
          // },
          {
            name: 'Maintenance',
            url: '/ledgers/debtors',
          },
          {
            name: 'Account Statement',
            url: '/statementofaccount',
          },
          {
            name: 'Deposit',
            url: '/deposit',
          },
          {
            name: 'Refund Listing',
            url: '/refunds/index-listing/',
          },
          {
            name: 'Invoices',
            url: '/invoices',
          },
          {
            name: 'Official Receipt',
            url: '/transactions',
          },
          {
            name: 'Prepayment',
            url: '/prepaymentbyProps',
          },
          {
            name: 'Waiver',
            url: '/waiverbyProps',
          },
          // {
          //    'name': 'Late Charge',
          //    'url': '/late-charge'
          //  },
          {
            name: 'Water Bills',
            url: '/waterbills',
          },
          {
            name: 'Debit Notes',
            url: '/debitnotes',
          },
          {
            name: 'Credit Notes',
            url: '/creditnotes',
          },
        ],
      },
      {
        name: 'Supplier',
        icon: accounting,
        iconType: 'image',
        subitem: [
          {
            name: 'Maintenance',
            url: '/suppliers',
          },
          {
            name: 'Invoices',
            url: '/receipts',
          },
          {
            name: 'Payment Voucher',
            url: '/paymentvoucher',
          },
          {
            name: 'Debit Notes',
            url: '/debitnotesSupplier',
          },
          {
            name: 'Credit Notes',
            url: '/creditnotesSupplier',
          },
        ],
      },
      {
        name: 'Cash/Bank',
        icon: accounting,
        iconType: 'image',
        subitem: [{ name: 'Transactions', url: '/cashAndbanktransactions' }],
      },
      {
        name: 'Reporting',
        icon: reporting,
        iconType: 'image',
        subitem: [
          {
            name: 'Journal Entry',
            url: '/ledgers/journalentries',
          },
          // {
          //   'name': 'Journal Entry',
          //   'url': '/ledgers/main-transactions'
          // },
          {
            name: 'General Ledger',
            url: '/ledgers/accounts',
          },
          {
            name: 'Bank Reconciliation',
            url: '/bankrecons/main',
          },
          {
            name: 'Receipt and Payment',
            url: '/reports/receipt-and-payment',
          },
          {
            name: 'Trial Balance',
            url: '/reports/trial-balance',
          },
          {
            name: 'Profit and Loss',
            url: '/reports/profit-and-loss',
          },
          {
            name: 'Balance Sheet',
            url: '/reports/balance-sheet',
          },
          {
            name: 'Chart Of Accounts',
            url: '/chartOfAccounts/view',
          },
          {
            name: 'Debtor Aging',
            url: '/debtorAging/view',
          },
          {
            name: 'Creditor Aging',
            url: '/creditorAging/view',
          },
          // , {
          //   'name': 'Interest Debit Note',
          //   'url': '/interestDebitNote/index'
          // }
          {
            name: 'Reminder/NOD Letter',
            url: '/reminderLetter/',
          },
          {
            name: 'Money In Transit',
            url: '/moneyInTransit/',
          },
        ],
      },
      {
        name: 'Uploads',
        icon: reporting,
        iconType: 'image',
        subitem: [
          {
            name: 'Water Bill',
            url: '/uploads/waterBill',
          },
          {
            name: 'Opening Balance',
            url: '/uploads/firstOpeningBalance',
          },
        ],
      },
      {
        name: 'Settings',
        icon: setting,
        iconType: 'image',
        subitem: [
          {
            name: 'Profile',
            url: `/management/view/${managementId}`,
          },
          {
            name: 'General',
            url: '/settings',
          },
          {
            name: 'Fee',
            url: '/charges',
          },
          {
            name: 'Fee Recurring',
            url: '/recurring-items',
          },
          {
            name: 'Initial Account Amount',
            url: '/firstTime/ledger',
          },
          {
            name: 'No Affect GL',
            url: '/firstTime/invoice',
          },
          // {
          //   'name': 'Accounting Periods',
          //   'url': '/accounting-periods'
          // },
          {
            name: 'T&C ',
            url: '/settings/invoice-tnc',
          },
          {
            name: 'Transaction Parameter',
            url: '/trxparameter',
          },
          {
            name: 'Payment Type Setup',
            url: '/paymenttypeparams',
          },
          // {
          //   'name': 'FPX Parameter',
          //   'url': '/fpxparameter'
          // },
        ],
      },
    ];

    let menuExecutiveItem = [
      {
        name: 'Dashboard',
        url: '/dashboard',
        icon: dashboard,
        iconType: 'image',
      },
      {
        name: 'Building Info',
        icon: companyprofile,
        iconType: 'image',
        subitem: [
          {
            name: 'Floor Plan Upload',
            url: '/files',
          },
          {
            name: 'Property Units',
            url: '/propertyunitsmng',
          },
          {
            name: 'Apps Request',
            url: '/requests/index-mng',
            icon: 'user-add',
          },
          {
            name: 'Property News',
            url: '/ads',
          },
          {
            name: 'Pinboard',
            url: '/pinboard',
          },
        ],
      },
      {
        name: 'Management',
        icon: companyprofile,
        iconType: 'image',
        subitem: [
          {
            name: 'eForms',
            url: '/eforms',
          },
          {
            name: 'eFacilities',
            url: '/facilities',
          },
          {
            name: 'eFeedBack',
            url: '/complaints',
          },
          {
            name: 'eVisitor',
            url: '/passes',
          },
          {
            name: 'HELP',
            url: '/help',
          },
          {
            name: 'Document',
            url: '/document',
          },

          {
            name: 'Meter Reading',
            url: '/meterReadings',
          },
          {
            name: 'Guard Management',
            url: '/manageGuard',
          },
        ],
      },
      {
        name: 'Settings',
        icon: setting,
        iconType: 'image',
        subitem: [
          {
            name: 'Profile',
            url: `/management/view/${managementId}`,
          },
          {
            name: 'T&C ',
            url: '/settings/invoice-tnc',
          },
        ],
      },
    ];

    let menuAdminItem = [
      {
        name: 'Dashboard',
        url: '/dashboard',
        icon: dashboard,
        iconType: 'image',
      },
      {
        name: 'Building Info',
        icon: companyprofile,
        iconType: 'image',
        subitem: [
          {
            name: 'Floor Plan Upload',
            url: '/files',
          },
          {
            name: 'Property Units',
            url: '/propertyunitsmng',
          },
          {
            name: 'Apps Request',
            url: '/requests/index-mng',
            icon: 'user-add',
          },
          {
            name: 'Property News',
            url: '/ads',
          },
          {
            name: 'Pinboard',
            url: '/pinboard',
          },
        ],
      },
      ,
      {
        name: 'Management',
        icon: companyprofile,
        iconType: 'image',
        subitem: [
          {
            //   'name': 'Notices',
            //   'url': '/notices'
            // }, {
            name: 'eForms',
            url: '/eforms',
          },
          {
            name: 'eFacilities',
            url: '/facilities',
          },
          {
            //   'name': 'eFacilities Bookings',
            //   'url': '/facilityBookings'
            // }, {
            name: 'eFeedBack',
            url: '/complaints',
          },
          {
            name: 'eVisitor',
            url: '/passes',
          },
          {
            name: 'HELP',
            url: '/help',
          },

          {
            name: 'Document',
            url: '/document',
          },

          {
            name: 'Meter Reading',
            url: '/meterReadings',
          },
          {
            name: 'Guard Management',
            url: '/manageGuard',
          },
        ],
      },

      {
        name: 'Accounting',
        icon: accounting,
        iconType: 'image',
        subitem: [
          {
            name: 'Maintenance',
            url: '/ledgers/debtors',
          },
          {
            name: 'Invoices',
            url: '/invoices',
          },
          {
            name: 'Official Receipt',
            url: '/transactions',
          },
        ],
      },
    ];

    let menuAccountItem = [
      {
        name: 'Dashboard',
        url: '/dashboard',
        icon: dashboard,
        iconType: 'image',
      },
      {
        name: 'Building Info',
        icon: companyprofile,
        iconType: 'image',
        subitem: [
          {
            name: 'Floor Plan Upload',
            url: '/files',
          },
          {
            name: 'Property Units',
            url: '/propertyunitsmng',
          },
          {
            name: 'Apps Request',
            url: '/requests/index-mng',
            icon: 'user-add',
          },
          {
            name: 'Property News',
            url: '/ads',
          },
          {
            name: 'Pinboard',
            url: '/pinboard',
          },
        ],
      },
      {
        name: 'Accounting',
        icon: accounting,
        iconType: 'image',
        subitem: [
          // {
          //   'name': 'Customer',
          //   'label': 'Y',
          // },
          {
            name: 'Maintenance',
            url: '/ledgers/debtors',
          },
          {
            name: 'Account Statement',
            url: '/statementofaccount',
          },
          {
            name: 'Deposit',
            url: '/deposit',
          },
          {
            name: 'Refund Listing',
            url: '/refunds/index-listing/',
          },
          {
            name: 'Invoices',
            url: '/invoices',
          },
          {
            name: 'Official Receipt',
            url: '/transactions',
          },
          {
            name: 'Prepayment',
            url: '/prepaymentbyProps',
          },
          {
            name: 'Waiver',
            url: '/waiverbyProps',
          },
          // {
          //    'name': 'Late Charge',
          //    'url': '/late-charge'
          //  },
          {
            name: 'Water Bills',
            url: '/waterbills',
          },
          {
            name: 'Debit Notes',
            url: '/debitnotes',
          },
          {
            name: 'Credit Notes',
            url: '/creditnotes',
          },
        ],
      },
      {
        name: 'Supplier',
        icon: accounting,
        iconType: 'image',
        subitem: [
          {
            name: 'Maintenance',
            url: '/suppliers',
          },
          {
            name: 'Account Statement',
            url: '/supplierStatementAccount',
          },
          {
            name: 'Invoices',
            url: '/receipts',
          },
          {
            name: 'Payment Voucher',
            url: '/paymentvoucher',
          },
          // {
          //   'name': 'Debit Notes',
          //   'url': '/debitnotesSupplier'
          // }, {
          //   'name': 'Credit Notes',
          //   'url': '/creditnotesSupplier'
          // }
        ],
      },

      {
        name: 'Cash/Bank',
        icon: accounting,
        iconType: 'image',
        subitem: [{ name: 'Transactions', url: '/cashAndbanktransactions' }],
      },
      {
        name: 'Reporting',
        icon: reporting,
        iconType: 'image',
        subitem: [
          {
            name: 'Journal Entry',
            url: '/ledgers/journalentries',
          },
          // {
          //   'name': 'Journal Entry',
          //   'url': '/ledgers/main-transactions'
          // },
          {
            name: 'General Ledger',
            url: '/ledgers/accounts',
          },
          {
            name: 'Bank Reconciliation',
            url: '/bankrecons/main',
          },
          {
            name: 'Receipt and Payment',
            url: '/reports/receipt-and-payment',
          },
          {
            name: 'Trial Balance',
            url: '/reports/trial-balance',
          },
          {
            name: 'Profit and Loss',
            url: '/reports/profit-and-loss',
          },
          {
            name: 'Balance Sheet',
            url: '/reports/balance-sheet',
          },
          {
            name: 'Chart Of Accounts',
            url: '/chartOfAccounts/view',
          },
          {
            name: 'Debtor Aging',
            url: '/debtorAging/view',
          },
          {
            name: 'Creditor Aging',
            url: '/creditorAging/view',
          },
          {
            name: 'Payment Log',
            url: '/paymentlog',
          },
          // , {
          //   'name': 'Interest Debit Note',
          //   'url': '/interestDebitNote/index'
          // }
          {
            name: 'Reminder/NOD Letter',
            url: '/reminderLetter/',
          },
          {
            name: 'Money In Transit',
            url: '/moneyInTransit/',
          },
        ],
      },

      {
        name: 'Uploads',
        icon: reporting,
        iconType: 'image',
        subitem: [
          {
            name: 'Water Bill',
            url: '/uploads/waterBill',
          },
          {
            name: 'Opening Balance',
            url: '/uploads/firstOpeningBalance',
          },
        ],
      },

      {
        name: 'Settings',
        icon: setting,
        iconType: 'image',
        subitem: [
          {
            name: 'Profile',
            url: `/management/view/${managementId}`,
          },
          {
            name: 'General',
            url: '/settings',
          },
          {
            name: 'Fee',
            url: '/charges',
          },
          {
            name: 'Fee Recurring',
            url: '/recurring-items',
          },
          {
            name: 'Initial Account Amount',
            url: '/firstTime/ledger',
          },
          {
            name: 'No Affect GL',
            url: '/firstTime/invoice',
          },
          // {
          //   'name': 'Accounting Periods',
          //   'url': '/accounting-periods'
          // },
          {
            name: 'T&C ',
            url: '/settings/invoice-tnc',
          },
          {
            name: 'Transaction Parameter',
            url: '/trxparameter',
          },
          {
            name: 'Payment Type Setup',
            url: '/paymenttypeparams',
          },
          // {
          //   'name': 'FPX Parameter',
          //   'url': '/fpxparameter'
          // },
        ],
      },
    ];

    let bryanMenuItem = {
      name: 'Bryan',
      icon: reporting,
      iconType: 'image',
      subitem: [
        {
          name: 'Ledger Transactions',
          url: '/reports/ledger-transactions',
        },
        {
          name: 'Late Charge',
          url: '/late-charge',
        },
      ],
    };

    menuItem = [];
    if (!_.isEmpty(this.props.user.info)) {
      if (this.props.user.info.user.role[0] === 'management') {
        menuItem = menuMngItem;
      } else if (this.props.user.info.user.role[0] === 'authoriser') {
        menuItem = menuAuthoriser;
      } else if (this.props.user.info.user.role[0] === 'executive') {
        menuItem = menuExecutiveItem;
      } else if (this.props.user.info.user.role[0] === 'admin') {
        menuItem = menuAdminItem;
      } else if (this.props.user.info.user.role[0] === 'account') {
        menuItem = menuAccountItem;
      } else if (this.props.user.info.user.role[0] === 'superadmin') {
        menuItem = menuSuperAdminItem;
      }
      if (this.props.user.info.user.email === 'bryan@kbms.my') {
        menuItem.push(bryanMenuItem);
      }
    }

    let menuKey = 1;
    let menuSubKey = 'a';
    let menuStruct = menuItem.map((menu, index) => {
      menuSubKey = String.fromCharCode(menuSubKey.charCodeAt(0) + 1);
      if (menu.subitem) {
        return (
          <SubMenu
            key={menuSubKey}
            title={
              <span>
                {this._renderIcon(menu.iconType, menu.icon)}
                <Tooltip placement='rightTop' title={menu.name}>
                  <span className='span3'>{menu.name}</span>
                </Tooltip>
              </span>
            }
          >
            {menu.subitem.map((submenu, subindex) => {
              menuKey++;
              if (submenu.label !== 'Y') {
                return (
                  <Menu.Item
                    key={menuSubKey + menuKey}
                    onClick={this.handleClick}
                    className='ant-menu-item'
                  >
                    <span className='span2'>
                      <Tooltip placement='rightTop' title={submenu.name}>
                        <Link to={submenu.url ? submenu.url : ''}>
                          {submenu.name}
                        </Link>
                      </Tooltip>
                    </span>
                  </Menu.Item>
                );
              } else {
                return (
                  <Menu.Item
                    key={menuSubKey + menuKey}
                    onClick={this.handleClick}
                    className='ant-menu-item2'
                  >
                    <span className='span2'>{submenu.name}</span>
                  </Menu.Item>
                );
              }
            })}
          </SubMenu>
        );
      } else {
        return (
          <Menu.Item
            key={index}
            onClick={this.handleClick}
            className='ant-menu-item'
          >
            {this._renderIcon(menu.iconType, menu.icon)}
            <span className='span3'>
              <Link to={menu.url}>{menu.name}</Link>
            </span>
          </Menu.Item>
        );
      }
    });

    return (
      <Layout
        style={{
          minHeight: '100vh',
        }}
      >
        <Sider
          collapsedWidth='0'
          collapsible='collapsible'
          collapsed={this.state.collapsed}
          onCollapse={this.onCollapse}
          style={{
            backgroundColor: '#002140',
          }}
        >
          <div className='logo' />
          <img style={{ width: '70%', margin: '5% 10%' }} src={kbmslogo} />
          <Menu
            theme='dark'
            mode='inline'
            defaultOpenKeys={[
              this.props.app.activeMenuKey
                ? this.props.app.activeMenuKey.toString().substring(0, 1)
                : 'a',
            ]}
            selectedKeys={[this.props.app.activeMenuKey]}
            style={{ backgroundColor: '#002140' }}
          >
            {menuStruct}
            <Menu.Item key={menuStruct.length + 1} onClick={this.handleLogout}>
              <Icon type='logout' />
              <span className='span3'>Logout</span>
            </Menu.Item>
          </Menu>
        </Sider>

        <Layout>
          <Header
            style={{
              backgroundColor: '#fff',
              padding: '0px',
            }}
          >
            <Row>
              <Col span={18}>
                <h6
                  style={{
                    margin: '20px',
                    // float: 'right'
                  }}
                >
                  {showProperty}
                </h6>
              </Col>

              <Col span={1}>
                <Badge
                  style={{ float: 'right' }}
                  dot={this.state.incomingNotification}
                >
                  <Icon
                    type='bell'
                    onClick={() => {
                      this.setState({ incomingNotification: false });
                    }}
                  />
                </Badge>
              </Col>
              <Col span={5}>{this.renderUserDetails()}</Col>
            </Row>
          </Header>

          <Content
            style={{
              margin: '0 16px',
            }}
          >
            <marquee
              style={{
                fontSize: '23px',
                color: 'black',
              }}
            >
              {this.state.runingNotice}
            </marquee>
            <Breadcrumb
              style={{
                margin: '16px 0',
              }}
            >
              <Breadcrumb.Item></Breadcrumb.Item>
            </Breadcrumb>
            <div
              style={{
                padding: 24,
                background: '#fff',
                minHeight: 360,
              }}
            >
              {this.props.children}
            </div>
          </Content>

          {/* <Footer style={{ textAlign: 'right' }}>
          © 2021 Mr Tech System Holdings Sdn Bhd (1370083-M)
        </Footer> */}
          <Footer style={{ textAlign: 'right' }}>
            © 2021 Mr Tech System Holdings Sdn Bhd (1370083-M)
          </Footer>
        </Layout>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    app: state.app,
    commons: state.commons,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logoutSuccessful: logoutSuccessful,
      updateActiveMenuKey: updateActiveMenuKey,
      updateActivePage: updateActivePage,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(layout));
