import React, { Component } from 'react';
import { Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Card, Select, Tabs, Badge, DatePicker } from 'antd';
import { Route, Link } from 'react-router-dom';
import client from '../../feathers';
import requireAuth from "../../components/requireAuth";
import Layout from "../../components/layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from 'moment';
import {
  fetchEforms,
  pushEforms,
  removeEforms,
  updateMode,
  updateActiveEforms,
  updateEforms
} from '../../actions/actions-eforms';
import axios from 'axios';

const _ = require('lodash')
const dateFormat = 'DD/MM/YYYY'
const FormItem = Form.Item;
const Option = Select.Option;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const text = 'Are you sure to delete this item?';

class EformsIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterStatus: '',
      totalPagination: 0,
      loading: false,
      CurrentPagination: 1,
      totalCard: 0,
      totalRenovation: 0,
      totalCommonArea: 0,
      formType: '',
      dateMoment: '',
      typingTimeout: '',
      selectedUnit: '',
      selectedMphType: '',
      selectedStatus: '',
      unitList: []

    }

  }

  componentDidUpdate() {
  }

  componentDidMount() {

  }

  componentWillMount() {

    this.setState({
      formType: 'CARD'
    }, () => {
      this.getData(0)
    })



    client.authenticate()
      .then((res) => {
        client.service('propertyunits').find({
          query: {
            propertyId: this.props.user.info.propertyId._id
          }
        }).then((result) => {
          this.setState({ unitList: result.data })
        })
      })



  }

  getData(skip) {


    const { formType, dateMoment, selectedMphType, selectedStatus, selectedUnit } = this.state
    let queryFormType, queryMphType, queryDateRange, queryStatus, queryUnit = ''

    if (!_.isEmpty(formType)) {
      queryFormType = { formType: formType }
    }
    if (!_.isEmpty(selectedMphType)) {
      queryMphType = { mphType: selectedMphType }
    }
    if (!_.isEmpty(selectedStatus)) {
      queryStatus = { status: selectedStatus }
    }
    if (!_.isEmpty(selectedUnit)) {
      queryUnit = { propertyunitId: selectedUnit }
    }
    if (!_.isEmpty(dateMoment)) {
      queryDateRange = {
        applyDate: {
          $gte: new Date(moment(new Date(this.state.dateMoment[0])).startOf('day')),
          $lte: new Date(moment(new Date(this.state.dateMoment[1])).endOf('day')),

        }
      }
    }
    client.authenticate()
      .then((res) => {

        axios.get(`${client.io.io.uri}getTotalFormsNumber`, {
          params: {
            propertyId: this.props.user.info.propertyId._id,
          },
          headers: {
            'Authorization': client.settings.storage.storage['feathers-jwt']
          }
        }).then((res) => {
          let data = []
          try { data = res.data.data } catch (err) { data = [] }

          for (let i = 0; i < data.length; i++) {
            let result = data[i]

            if (result._id === 'CARD') { this.setState({ totalCard: result.totalCount }) }
            if (result._id === 'RENOVATION') { this.setState({ totalRenovation: result.totalCount }) }
            if (result._id === 'MULTIPURPOSE HALL') { this.setState({ totalCommonArea: result.totalCount }) }


          }
        })


        return client.service('eforms').find({
          query: {
            ...queryStatus,
            ...queryFormType,
            ...queryDateRange,
            ...queryMphType,
            ...queryUnit,
            propertyId: this.props.user.info.propertyId._id,
            $populate: 'propertyunitId propertyId userId',
            $limit: 20,
            $sort: {
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {
        this.props.fetchEforms(res.data)
      })
      .catch((err) => {
        console.log(err);
      })
  }

  handleOnChangeSortBy = (e) => {

    this.setState({
      formType: e,
      // changeStatus: true
    },
      () => {

        this.getData(0)
        // this.setState({ changeStatus: false })
      })

  }




  handlePageChange = (page) => {
    ;
    this.setState({
      CurrentPagination: page,
    });
    this.props.form.validateFields((err, values) => {

      client.authenticate()
        .then((res) => {

          return client.service('eforms').find({
            query: {
              propertyId: this.props.user.info.propertyId._id,
              $populate: 'propertyunitId propertyId userId',
              $limit: 20,
              $sort: {
                status: -1
              },
              $skip: (page - 1) * 20,
            }
          })
        })
        .then((res) => {


          this.props.fetchEforms(res.data);
          this.setState({
            totalPagination: res.total,
          });
        })
        .catch((err) => {
          console.log(err);
        })
    });
  }
  onChangeCalendar = (value) => {



    if (value.length === 2) {
      this.setState({ dateMoment: value }, () => {
        this.getData(0)
      })

    }


  }
  onChange = (value) => {
    if (_.isEmpty(value)) {
      this.setState({ dateMoment: '' }, () => {
        this.getData(0)
      })
    }
  }

  downloadGuestList(data) {

    if (_.isObject(data)) {

      let a = document.createElement('a')
      a.href = data.url
      a.click();
      return message.success('Download Sucess !')


    }
    else {
      return message.error('No guest list upload from user.')

    }

  }


  render() {
    const { getFieldDecorator } = this.props.form;
    const loading = this.state.loading;
    const totalPagination = this.state.totalPagination;
    const dataSource = this.props.eforms.eforms
    let { formType, unitList } = this.state

    const columns = [
      {
        title: 'Applied Date',
        dataIndex: 'applyDate',
        key: 'applyDate',
        render: (values, record) => {
          return (
            moment(values).format('DD/MM/YYYY')
          )
        }
      },
      {
        title: 'Type',
        dataIndex: 'formType',
        key: 'formType',
      },
      {
        title: <Select style={{ width: '100%' }} defaultValue=''
          onChange={(e) => {
            this.setState({ selectedUnit: e }, () => {
              this.getData(0)
            })
          }}
        >
          <Option key='' value=''>Property Unit</Option>
          {unitList.map((v) =>
            <Option key={v._id}>{v.referenceId}</Option>
          )}



        </Select>,
        dataIndex: 'propertyunitId.referenceId',
        key: 'propertyunitId.referenceId',
      },
      {
        title: 'Apps User',
        dataIndex: 'userId.firstName',
        key: 'userId.firstName',
      },
      {
        title: 'Name',
        dataIndex: 'idName',
        key: 'idName',
      },

      {
        title: <Select style={{ width: '100%' }} defaultValue=''
          onChange={(e) => {
            this.setState({ selectedStatus: e }, () => {
              this.getData(0)
            })
          }}
        >
          <Option key='' value=''>Status</Option>
          <Option value='In progress'>In progress</Option>
          <Option value='Approved'>Approved</Option>
          <Option value='Rejected'>Rejected</Option>



        </Select>,
        dataIndex: 'status',
        key: 'status',
        render: (text, record) => {
          let colorStatus = ''
          if (text === 'Approved') {
            colorStatus = 'green'
          }
          else if (text === 'Rejected') {
            colorStatus = 'red'
          } else {
            colorStatus = '#fda855'
          }
          return (<span style={{ color: colorStatus }}>
            {text}
          </span>)

        }

      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            {/* <Divider type="vertical" /> */}
            {record.formType === 'CARD' ?
              <Button type='primary' size='small'><Link to={{ pathname: `/eforms/card/` + record._id }} >View</Link></Button> :
              <Button type='primary' size='small'><Link to={{ pathname: `/eforms/renovation/` + record._id }} >View</Link></Button>
            }
            {/* <Divider type="vertical" /> */}
          </span>
        ),
      }];

    let commonAreaCol = [
      {
        title: 'Applied Date',
        dataIndex: 'applyDate',
        key: 'applyDate',
        render: (values, record) => {
          return (
            moment(values).format('DD/MM/YYYY')
          )
        }
      },
      {
        title: 'Applied Session',
        dataIndex: 'session',
        render: (values) => {
          if (values === 'morning') {
            return <span>First Half</span>

          }
          else if (values === 'night') {
            return <span>Second Half</span>

          }
          else if (values === 'all') {
            return <span>Entire Day</span>

          }
          else {
            return <span>-</span>
          }
        }
      },
      {
        title: <Select style={{ width: '100%' }} defaultValue=''

          allowClear

          onChange={(e) => {

            this.setState({
              selectedMphType: e,

            }, () => {
              this.getData(0)
            })

          }}
        >
          <Option key='' value=''>Type</Option>
          <Option value='Multi Purpose Hall'>Multipurpose Hall</Option>
          <Option value='BBQ'>BBQ</Option>





        </Select>,
        dataIndex: 'mphType',
        key: 'mphType',
      },
      {
        title: <Select style={{ width: '100%' }} defaultValue=''
          onChange={(e) => {
            this.setState({ selectedUnit: e }, () => {
              this.getData(0)
            })
          }}
        >
          <Option key='' value=''>Property Unit</Option>
          {unitList.map((v) =>
            <Option key={v._id}>{v.referenceId}</Option>
          )}



        </Select>,
        dataIndex: 'propertyunitId.referenceId',
        key: 'propertyunitId.referenceId',
      },
      {
        title: 'Apps User',
        dataIndex: 'userId.firstName',
        key: 'userId.firstName',
      },
      {
        title: 'Name',
        dataIndex: 'idName',
        key: 'idName',
      },

      {
        title: <Select style={{ width: '100%' }} defaultValue=''
          onChange={(e) => {
            this.setState({ selectedStatus: e }, () => {
              this.getData(0)
            })
          }}
        >
          <Option key='' value=''>Status</Option>
          <Option value='In progress'>In progress</Option>
          <Option value='Approved'>Approved</Option>
          <Option value='Rejected'>Rejected</Option>



        </Select>,
        dataIndex: 'status',
        key: 'status',
        render: (text, record) => {
          let colorStatus = ''
          if (text === 'Approved') {
            colorStatus = 'green'
          }
          else if (text === 'Rejected') {
            colorStatus = 'red'
          } else {
            colorStatus = '#fda855'
          }
          return (<span style={{ color: colorStatus }}>
            {text}
          </span>)

        }
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => {
          let isDisable = false
          if (!record.filePath) {
            isDisable = true
          }
          return (
            <span>


              <Button type='primary' size='small' style={{ marginRight: '8px' }}><Link to={{ pathname: `/eforms/commonArea/` + record._id }} >View</Link></Button>
              <Button size='small' disabled={isDisable} onClick={() => { this.downloadGuestList(record.filePath) }}>Download</Button>


            </span>

          )

        }


      }




    ]
    let dataCol = []

    if (formType === 'MULTIPURPOSE HALL') {
      dataCol = commonAreaCol
    }
    else {
      dataCol = columns
    }

    return (
      <Layout>
        <b>E-Forms</b>
        <Card
          key='eforms'
          title={<Tabs defaultActiveKey="CARD" onChange={this.handleOnChangeSortBy} style={{ flex: 1 }}>
            {/* <TabPane tab='All' key=''></TabPane> */}

            <TabPane tab={(<div><span className='marginRight8px' >Card</span><Badge overflowCount={999999} style={{ marginLeft: '8px', backgroundColor: '#1DA57A' }} count={this.state.totalCard} /></div>)} key="CARD">
            </TabPane>

            <TabPane tab={(<div><span className='marginRight8px' >Renovation</span><Badge overflowCount={999999} style={{ marginLeft: '8px', backgroundColor: '#1DA57A' }} count={this.state.totalRenovation} /></div>)} key="RENOVATION">
            </TabPane>
            <TabPane tab={(<div><span className='marginRight8px' >Common Area</span><Badge overflowCount={999999} style={{ marginLeft: '8px', backgroundColor: '#1DA57A' }} count={this.state.totalCommonArea} /></div>)} key="MULTIPURPOSE HALL">
            </TabPane>

          </Tabs>}
          extra={
            <div>
              <RangePicker style={{ marginRight: '10px' }} on
                format={dateFormat}
                onCalendarChange={this.onChangeCalendar}
                onChange={this.onChange}
              />


              <Button key='btneforms' type='primary'><Link to={{ pathname: `/eforms/form` }} >New</Link></Button>
            </div>
          }
          loading={loading}
        >



          <Table
            dataSource={dataSource}
            columns={dataCol}
            rowKey='_id'
            size='small'
            pagination={{
              pageSize: 20,
              defaultCurrent: 1,
              total: totalPagination,
              onChange: this.handlePageChange,
            }}
          />


        </Card>

      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    eforms: state.eforms,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchEforms: fetchEforms,
    pushEforms: pushEforms,
    removeEforms: removeEforms,
    updateActiveEforms: updateActiveEforms,
    updateEforms: updateEforms,
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(EformsIndex)));
