import React, { Component } from 'react'
import { Popconfirm, Divider,  Table, Form, Input, Button,Card,Row,Col } from 'antd'
import { Link } from 'react-router-dom';

import client from '../../feathers';
import requireAuth  from "../../components/requireAuth";
import Layout  from "../../components/layout";
import UsersForm  from "../../components/users/form";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchUsers,
  pushUsers,
  removeUsers,
  updateMode,
  updateActiveUser,
  updateUsers } from '../../actions/users-actions';


const text = 'Are you sure to delete this item?';

class UsersIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter_email:'',
      filter_username:'',
    }

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidUpdate(){
  }

  componentDidMount(){
      client.service('users').on('created', (users) => {
        //this.props.pushUsers(users)
      })

      client.service('users').on('removed', (users) => {
        //this.props.removeUsers(users)
      })

      client.service('users').on('updated', (users) => {
        //this.props.updateUsers(users)
      })

      client.service('users').on('patched', (users) => {
        //this.props.updateUsers(users)
      })
  }

  componentWillMount(){
      client.authenticate()
      .then(()=>{
          return client.service('users').find({
            query: {
              role:'user',
              status: {$nin:['INACTIVE'] },
               $sort: {
                 createdAt: -1
               }
             }
          })
      })
      .then((res)=>{
        // console.log({res});
        this.props.fetchUsers(res.data)
      })
      .catch((err)=>{
        console.log({err});
      });
  }

  handleDelete(_id) {
     client.service('users').patch(_id,{status:'INACTIVE'})
     .then((res)=>{
     })
     .catch((err)=>{
       alert(err);
     })
  }

  handleEdit(_id) {
    this.props.updateActiveUser('edit',_id)
  }

  handleManage(_id) {
    this.props.updateActiveUser('',_id)
  }

  showModal = () => {
    this.props.updateMode('new')
  }

  handleEmail = (e) => {
    this.setState({
      filter_email:e.target.value,
      typingTimeout: setTimeout(()=>{
        this.handleFilter()
      }, 500)
    });
  }
  handleUsername = (e) => {
    this.setState({
      filter_username:e.target.value,
      typingTimeout: setTimeout(()=>{
        this.handleFilter()
      }, 500)
    });
  }

  handleFilter = () => {

    let email       = this.state.filter_email===''?'':{email:this.state.filter_email};
    let username       = this.state.filter_username===''?'':{username:this.state.filter_username};
    let collection  = {...email,...username};

    client.authenticate()
    .then((res)=>{

        return client.service('users').find({
          query: {
            role:'user',
            status: {$nin:['INACTIVE'] },
            // ...collection,
            orRegex:collection,
             $sort: {
               createdAt: -1
             }
           }
        })
    })
    .then((res)=>{

      this.props.fetchUsers(res.data);
    })
    .catch((err)=>{
        console.log(err);
    })
  }

  renderConfirmDeleteBtn(_id){
    return (
      <Popconfirm placement="topLeft" title={text} onConfirm={()=>this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
        <Button type="danger">Delete</Button>
      </Popconfirm>
    )
  }

  render() {

    const columns = [{
        title:
        (
          <Row gutter={8}>
           <Row><Col span={24}>Email</Col></Row>
          <Row><Col span={24}>
          <Input onChange={this.handleEmail} placeholder="Email" style={{'width':'200px'}}/>
           </Col></Row>
        </Row>
        ),
        dataIndex: 'email',
        key: 'email',
      },
      {
        title:
        (
          <Row gutter={8}>
           <Row><Col span={24}>Username</Col></Row>
          <Row><Col span={24}>
          <Input onChange={this.handleUsername} placeholder="Username" style={{'width':'200px'}}/>
           </Col></Row>
        </Row>
        ),
        dataIndex: 'username',
        key: 'username',
      },
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
      },
      {
        title: 'User ID',
        dataIndex: '_id',
        key: '_id',
      },
      {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
          <Divider type="vertical" />
          {/*<Button onClick={()=>this.handleEdit(record._id)}>Edit</Button>*/}
          <Link
              to={{
                pathname: `/users/view/${record._id}`
              }}
            >
            <Button>View Property</Button>
          </Link>
          <Divider type="vertical" />
          {/*this.renderConfirmDeleteBtn(record._id)*/}
        </span>
      ),
    }];

    return(
      <Layout>

        <Card
          title='User'
          extra={[
          //  <Input onChange={this.handleEmail} placeholder="Email" style={{'width':'200px'}}/>
          //  ,
          //  <Input onChange={this.handleUsername} placeholder="username" style={{'width':'200px'}} />
          //  ,
          //  <Button type='primary' onClick={this.handleFilter} >Search</Button>
                ]}
        >
          <Table dataSource={this.props.users.users} columns={columns} rowKey="_id" />
          <UsersForm />
        </Card>

      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    user: state.user,
    users: state.users
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchUsers: fetchUsers,
    pushUsers: pushUsers,
    removeUsers: removeUsers,
    updateActiveUser: updateActiveUser,
    updateUsers: updateUsers,
    updateMode: updateMode
    }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(UsersIndex)));
