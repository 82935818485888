import _ from 'lodash';
import {
    FETCH_HELP,
    PUSH_HELP,
    REMOVE_HELP,
    UPDATE_HELP,
    UPDATE_MODE,
    UPDATE_ACTIVE_ID
} from '../actions/actions-help';

const INITIAL_STATE = {
    help: [],
    mode: 'index',
    activeId: '',
    activeHelp: {}
}


export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_HELP:
            return {
                ...state,
                help: action.payload,
            }
        case PUSH_HELP:
            return {
                ...state,
                help: [action.payload, ...state.help],
            }
        case REMOVE_HELP:
            return {
                ...state,
                help: [...state.help.filter((item) => item._id !== action.payload._id)]
            }
        case UPDATE_HELP:
            let index = _.findIndex(state.help, { '_id': action.payload._id })
            state.help.splice(index, 1, action.payload)
            return {
                ...state,
                help: state.help
            }
        case UPDATE_MODE:
            return {
                ...state,
                mode: action.payload,
                activeId: ''
            }
        case UPDATE_ACTIVE_ID:
            return {
                ...state,
                mode: action.mode,
                activeId: action.id,
                activeHelp: _.pick(_.find(state.help, { '_id': action.id }), ['name', 'description', 'unit', 'categories', 'amount', 'minutePerSession', 'startTime', 'maxSessionPerday', '_id'])
            }
        default:
            return state

    }
}
