import _ from 'lodash';
import {
  FETCH_FPXPARAMETERS,
  PUSH_TRXPARAMETERS,
  REMOVE_TRXPARAMETERS,
  UPDATE_TRXPARAMETERS,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID } from '../actions/actions-fpxparameters';

const INITIAL_STATE = {
  fpxparameters: [],
  mode: 'index',
  activeId: '',
  activeFpxParameters:{}
}


export default function(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_FPXPARAMETERS:
      return {
        ...state,
        fpxparameters:action.payload,
      }
    case PUSH_TRXPARAMETERS:
      return {
        ...state,
        fpxparameters:[action.payload, ...state.fpxparameters],
      }
    case REMOVE_TRXPARAMETERS:
      return {
        ...state,
        fpxparameters : [...state.fpxparameters.filter( (item) => item._id !== action.payload._id)]
      }
    case UPDATE_TRXPARAMETERS:
      let index =  _.findIndex(state.fpxparameters, { '_id': action.payload._id })
      state.fpxparameters.splice(index, 1, action.payload)
      return {
        ...state,
        fpxparameters : state.fpxparameters
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode : action.payload,
        activeId:''
      }
    case UPDATE_ACTIVE_ID:
      return {
        ...state,
        mode : action.mode,
        activeId:action.id,
        activeFpxParameters: _.pick(_.find(state.fpxparameters, { '_id': action.id }), ['companyId','type', 'des','ledgerAccountId', '_id'])
      }
    default:
      return state

  }
}
