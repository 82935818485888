import React, { Component } from 'react'
import { Popconfirm, message, Divider,  Table, Form, Icon, Input, Button,Card } from 'antd'
import { Route, Link } from 'react-router-dom';

import client from '../../feathers'
import requireAuth  from "../../components/requireAuth"
import Layout  from "../../components/layout"
import AccountmainsForm  from "../../components/accountmains/form"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  fetchAccountmains,
  pushAccountmains,
  removeAccountmains,
  updateMode,
  updateActiveAccountmains,
  updateAccountmains } from '../../actions/actions-accountmains'

const FormItem = Form.Item;

const text = 'Are you sure to delete this item?';

class AccountmainsIndex extends Component {
  constructor(props) {
    super(props);

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidUpdate(){
  }

  componentDidMount(){
 
  }



  componentWillMount(){

      client.authenticate()
      .then((res)=>{

          return client.service('accountmains').find({
            query: {
               $sort: {
                 createdAt: -1
               }
             }
          })
      })
      .then((res)=>{
        this.props.fetchAccountmains(res.data)
      })
      .catch((err)=>{
          console.log(err);
      })
  }

  handleDelete(_id) {
     client.service('accountmains').remove(_id)
     .then((res)=>{
     })
     .catch((err)=>{
       alert(err);
     })
  }

  handleEdit(_id) {
    this.props.updateActiveAccountmains('edit',_id)
  }

  handleManage(_id) {
    this.props.updateActiveAccountmains('',_id)
  }

  showModal = () => {
    this.props.updateMode('new')
  }

  renderConfirmDeleteBtn(_id){
    return (
      <Popconfirm placement="topLeft" title={text} onConfirm={()=>this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
        <Button type="danger">Delete</Button>
      </Popconfirm>
    )
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title: 'Account Number',
        dataIndex: '_id',
        key: '_id',
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
      },
      {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
          <Divider type="vertical" />
          {/*<Button onClick={()=>this.handleEdit(record._id)}>Edit</Button>*/}
          <Divider type="vertical" />
          {/*this.renderConfirmDeleteBtn(record._id)*/}
        </span>
      ),
    }];

    return(
      <Layout>
        <Card
          title="Accounts"
          extra={<Button type="primary" onClick={this.showModal}>New Account</Button>}
        >
          <Table dataSource={this.props.accountmains.accountmains} columns={columns} rowKey="_id" />
          <AccountmainsForm />
        </Card>

      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    accountmains: state.accountmains,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchAccountmains: fetchAccountmains,
    pushAccountmains: pushAccountmains,
    removeAccountmains: removeAccountmains,
    updateActiveAccountmains: updateActiveAccountmains,
    updateAccountmains: updateAccountmains,
    updateMode: updateMode
    }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(AccountmainsIndex)));
