import _ from "lodash";
import client from "../../feathers";

export function getBulkData(collection, query, pageSize) {
  return client
    .service(collection)
    .find({
      query: {
        ...query,
        $limit: 0,
      },
    })
    .then((res) => {
      let promiseAll = [];
      let totalRecord = res.total;
      if (totalRecord > 0) {
        for (var i = 0; i < totalRecord; ) {
          promiseAll.push(
            client
              .service(collection)
              .find({
                query: {
                  ...query,
                  $limit: pageSize,
                  $skip: i,
                },
              })
              .then((res) => {
                return res.data;
              })
              .catch((err) => {
                console.log(err);
              })
          );
          i = i + pageSize;
        }

        return Promise.all(promiseAll).then((resArr) => {
          // /combine all array
          let dataList = [].concat.apply([], resArr);
          return dataList;
        });
      }
    });
}
