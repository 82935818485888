import { Button, Card, Popconfirm, Table, Row, Col } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import Layout from "../../components/layout";
import requireAuth from "../../components/requireAuth";
import { bindActionCreators } from "redux";
import client from "../../feathers";
import _ from "lodash";

const text = "Are you sure to delete this item?";
const formatCurr = function format1(n = 0, currency = null) {
  let oriAmt = n;
  n = n < 0 ? Math.abs(n) : n;
  let amt = currency
    ? currency
    : "" +
      n.toFixed(2).replace(/./g, function(c, i, a) {
        return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
      });

  return oriAmt < 0 ? "(" + amt + ")" : amt;
};
class PrepaymentsIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      loading: false,
      propertyunit: {},
      CurrentPagination: 1,
      totalPagination: 0,
      mode: "",
      totalAmount: 0,
    };
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentWillMount() {
    this.setState({ loading: true, mode: "" });
    let companyId = this.props.commons.selectedCompany._id;

    client
      .authenticate()
      .then((res) => {
        return client.service("prepayments").find({
          query: {
            propertyId: companyId,
            $populate: "propertyunitId",
            propertyunitId: this.props.match.params.id,
            $limit: 20,
            $sort: {
              paid: -1,
              createdAt: -1,
            },
          },
        });
      })
      .then((res) => {
        let amt = res.data.map((r) => {
          return r.amount;
        });
        amt = _.sum(amt);
        this.setState({
          dataSource: res.data,
          loading: false,
          totalPagination: res.total,
          totalAmount: amt,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });

    client
      .authenticate()
      .then((res) => {
        return client.service("propertyunits").get(this.props.match.params.id);
      })
      .then((res) => {
        this.setState({
          propertyunit: res,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handlePageChange = (page) => {
    this.setState({
      CurrentPagination: page,
    });
    this.props.form.validateFields((err, values) => {
      let companyId = this.props.commons.selectedCompany._id;

      client
        .authenticate()
        .then((res) => {
          return client.service("prepayments").find({
            query: {
              propertyId: companyId,
              $populate: "propertyunitId",
              propertyunitId: this.props.match.params.id,
              $sort: {
                paid: -1,
                createdAt: -1,
              },
              $limit: 20,
              $skip: (page - 1) * 20,
            },
          });
        })
        .then((res) => {
          this.setState({
            totalPagination: res.total,
            dataSource: res.data,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  renderConfirmDeleteBtn(_id) {
    return (
      <Popconfirm
        placement="topLeft"
        title={text}
        onConfirm={() => this.handleDelete(_id)}
        okText="Confirm"
        cancelText="Cancel"
      >
        <Button type="danger">Delete</Button>
      </Popconfirm>
    );
  }

  handleDelete(_id) {
    this.setState({ loading: true });
    client
      .service("prepayments")
      .remove(_id)
      .then((res) => {
        this.setState({ loading: false, mode: "refresh" });
      });
  }

  render() {
    const dataSource = this.state.dataSource;
    const loading = this.state.loading;
    const propertyunit = this.state.propertyunit;

    const columns = [
      {
        title: "Prepayment Date",
        dataIndex: "createdAt",
        align: "right",
        render: (text, record) => (
          <span>{text ? moment(text).format("DD-MM-YYYY") : ""}</span>
        ),
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        align: "right",
        render: (text, record) => (
          <span>{text ? parseFloat(text).toFixed(2) : "0.00"}</span>
        ),
      },
    ];

    return (
      <Layout>
        <Row gutter={10}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Card
              title={`Prepayments ${propertyunit.referenceId}`}
              loading={loading}
            >
              <Table
                dataSource={dataSource}
                columns={columns}
                rowKey="_id"
                size={"small"}
                pagination={{
                  pageSize: 20,
                  onChange: this.handlePageChange,
                }}
              />
              <span style={{ float: "right" }}>
                Total Amount:{formatCurr(this.state.totalAmount)}
              </span>
            </Card>
          </Col>
        </Row>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    propertyunits: state.propertyunits,
    commons: state.commons,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}
export default requireAuth(
  connect(mapStateToProps, mapDispatchToProps)(PrepaymentsIndex)
);
