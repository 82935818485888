import _ from 'lodash';
import {
  FETCH_SETTINGS,
  PUSH_SETTINGS,
  REMOVE_SETTINGS,
  UPDATE_SETTINGS,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID } from '../actions/actions-settings';

const INITIAL_STATE = {
  settings: [],
  mode: 'index',
  activeId: '',
  activeSettings:{}
}


export default function(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_SETTINGS:
      return {
        ...state,
        settings:action.payload,
      }
    case PUSH_SETTINGS:
      return {
        ...state,
        settings:[action.payload, ...state.settings],
      }
    case REMOVE_SETTINGS:
      return {
        ...state,
        settings : [...state.settings.filter( (item) => item._id !== action.payload._id)]
      }
    case UPDATE_SETTINGS:
      let index =  _.findIndex(state.settings, { '_id': action.payload._id })
      state.settings.splice(index, 1, action.payload)
      return {
        ...state,
        settings : state.settings
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode : action.payload,
        activeId:''
      }
    case UPDATE_ACTIVE_ID:
      return {
        ...state,
        mode : action.mode,
        activeId:action.id,
        activeSettings: _.pick(_.find(state.settings, { '_id': action.id }), ['invoiceGenMonth','recurringGenDate','recurringDate','dueDatePeriod','recurringPeriod', '_id'])
      }
    default:
      return state

  }
}

// recurringGenDate    :{ type: Number},
// recurringDate       :{ type: Number},
// recurringPeriod     : { type: Number},  //1,3,4,6
// invoiceGenMonth     : { type: String},  //CURRENT,NEXT
// dueDatePeriod       : { type: Number},