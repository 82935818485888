import React from "react";

const SvgDumbbell = props => (
  <svg height={512} viewBox="0 -96 512 512" width={512} {...props}>
    <path
      fill="#8D99AE"
      d="M482 110h-10V65c0-22.059-17.95-40-40-40a39.724 39.724 0 00-21.031 5.988C406.879 13.25 390.949 0 372 0h-.012c-22.047 0-40 17.941-40 40v70H180.012V40c0-22.059-17.953-40-40.012-40-18.95 0-34.879 13.25-38.969 30.988A39.724 39.724 0 0080 25c-22.05 0-40 17.941-40 40v45H30c-16.54 0-30 13.46-30 30v40c0 16.54 13.46 30 30 30h10v45c0 22.059 17.95 40 40 40 7.71 0 14.922-2.191 21.031-5.988C105.121 306.75 121.051 320 140 320h.012c22.047 0 40-17.941 40-40v-70h151.976v70c0 22.059 17.953 40 40.012 40 18.95 0 34.879-13.25 38.969-30.988A39.724 39.724 0 00432 295c22.05 0 40-17.941 40-40v-45h10c16.54 0 30-13.46 30-30v-40c0-16.54-13.46-30-30-30zM40 190H30c-5.512 0-10-4.488-10-10v-40c0-5.512 4.488-10 10-10h10zm60 65c0 5.52-2.238 10.52-5.86 14.14S85.52 275 80 275c-11.031 0-20-8.969-20-20V65c0-11.031 8.969-20 20-20s20 8.969 20 20zm60.012 25c0 11.031-8.98 20-20 20H140c-11.02 0-20-8.969-20-20V40c0-11.031 8.98-20 20.012-20 11.02 0 20 8.969 20 20zM331.93 150H262c-5.52 0-10 4.48-10 10s4.48 10 10 10h69.93v20H179.96v-60h151.97zM392 280c0 11.031-8.98 20-20.012 20-11.02 0-20-8.969-20-20V160.512a5.88 5.88 0 01-.058.648v-2.32c.03.21.05.43.058.648V40c0-11.031 8.98-20 20-20H372c11.02 0 20 8.969 20 20zm60-25c0 11.031-8.969 20-20 20s-20-8.969-20-20V65c0-11.031 8.969-20 20-20s20 8.969 20 20zm40-75c0 5.512-4.488 10-10 10h-10v-60h10c5.512 0 10 4.488 10 10zm0 0"
      data-original="#000000"
      className="dumbbell_svg__active-path"
      data-old_color="#000000"
    />
    <path
      d="M222.02 170c-5.524 0-10.004-4.477-10.004-10s4.472-10 9.996-10h.008c5.523 0 10 4.477 10 10s-4.477 10-10 10zm0 0"
      data-original="#000000"
      className="dumbbell_svg__active-path"
      data-old_color="#000000"
      fill="#8D99AE"
    />
  </svg>
);

export default SvgDumbbell;
