import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
  Select,
  Spin,
  Table,
  DatePicker,
} from 'antd';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  fetchTransactions,
  pushTransactions,
  removeTransactions,
  updateActiveTransactions,
  updateMode,
  updateTransactions,
} from '../../actions/actions-transactions';
import { updateActivePage } from '../../actions/app-actions';
import Layout from '../../components/layout';
import requireAuth from '../../components/requireAuth';
import TransactionsForm from '../../components/transactions/form';
import client from '../../feathers';

const Option = Select.Option;

const text = 'Are you sure to delete this item?';

class TransactionsIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalPagination: 1,
      dateFilter: [],
      dropDownPaymentType: [],
      searchReceiptNo: '',
      searchAmount: '',
      searchChannel: '',
      searchUnitNo: '',
      searchUnitNoId: '',
      selectedPaymentMode: '',
      loading: false,
      typingTimeout: 0,
      prepaymentList: '',
    };

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidUpdate() {
    if (this.props.transactions.mode === 'refresh') {
      this.componentWillMount();
    }
  }

  componentDidMount() {}

  componentWillMount() {
    this.getPaymentMode();
    this.props.updateMode('');
    this.setState({
      loading: true,
    });
    let companyId = this.props.commons.selectedCompany._id;
    const activePage = this.props.activePage.officialReceiptPage;

    if (activePage > 1) {
      this.handlePageChange(activePage);
    } else {
      client
        .authenticate()
        .then((res) => {
          client
            .service('prepayments')
            .find({
              query: {
                propertyId: companyId,
              },
            })
            .then((result) => {
              this.setState({ prepaymentList: result.data });
            });

          return client.service('transactions').find({
            query: {
              propertyId: companyId,
              $limit: 20,
              $populate: ['paymentTypeParamId', 'propertyunitId'],
              $sort: {
                createdAt: -1,
              },
            },
          });
        })
        .then((res) => {
          //
          this.setState({
            totalPagination: res.total,
            loading: false,
          });
          this.props.fetchTransactions(res.data);
        })
        .catch((err) => {
          this.setState({ loading: false });
          console.log(err);
        });
    }
  }

  getPaymentMode() {
    let companyId = this.props.commons.selectedCompany._id;

    client.authenticate().then(async (res) => {
      let patchTransactions = await axios.post(
        `${client.io.io.uri}patchMoneyInTransit`,
        {
          companyId,
        }
      );

      client
        .service('payment-type-params')
        .find({
          query: {
            propertyId: companyId,
            category: {
              $in: ['INVOICE', 'DEPOSIT'],
            },
          },
        })
        .then((res) => {
          this.setState({ dropDownPaymentType: res.data });
        });
    });
  }

  handleDelete(record) {
    this.setState({ loading: true });
    let companyId = this.props.commons.selectedCompany._id;

    axios
      .post(
        `${client.io.io.uri}removeTrx`,
        {
          _id: record._id,
          propertyunitId: record.propertyunitId._id,
          companyId: companyId,
          trxType: 'OR',
        },
        {
          headers: {
            Authorization: client.settings.storage.storage['feathers-jwt'],
          },
        }
      )
      .then((res) => {
        message.success('Deleted');

        this.setState({ loading: false });

        this.props.updateMode('refresh');
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleEdit(_id) {
    this.props.updateActiveTransactions('edit', _id);
  }

  handleManage(_id) {
    this.props.updateActiveTransactions('', _id);
  }

  showModal = () => {
    this.props.updateMode('new');
  };

  handlePageChange = (page) => {
    const {
      dateFilter,
      searchReceiptNo,
      searchAmount,
      searchChannel,
      searchUnitNo,
      searchUnitNoId,
    } = this.state;

    let receiptNo = searchReceiptNo === '' ? '' : { trxNo: searchReceiptNo };

    let unitNoId =
      searchUnitNo === '' ? '' : { propertyunitId: searchUnitNoId };

    let amount =
      searchAmount === '' ? '' : { appliedAmount: { $eq: searchAmount } };

    let channel = searchChannel === '' ? '' : { channel: searchChannel };
    let queryStringDate = '';

    if (_.isEmpty(dateFilter) === false) {
      queryStringDate = {
        paymentDate: {
          $gte: new Date(moment(dateFilter[0]).startOf('day')),
          $lte: new Date(moment(dateFilter[1]).endOf('day')),
        },
      };
    }
    this.props.updateActivePage({ officialReceiptPage: page });
    this.props.form.validateFields((err, values) => {
      let companyId = this.props.commons.selectedCompany._id;

      client
        .authenticate()
        .then((res) => {
          return client.service('transactions').find({
            query: {
              ...amount,
              ...channel,
              ...queryStringDate,
              propertyId: companyId,
              $limit: 20,
              $skip: (page - 1) * 20,
              $populate: 'paymentTypeParamId propertyunitId',
              $sort: {
                createdAt: -1,
              },
              ...unitNoId,
              orRegex: { ...receiptNo },
            },
          });
        })
        .then((res) => {
          this.setState({ totalPagination: res.total, loading: false });
          this.props.fetchTransactions(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };
  //-------------------------------------------------
  // Search
  //-------------------------------------------------
  searchDateChange = (e) => {
    this.setState({ dateFilter: e }, () => {
      this.handleSearch();
    });
  };

  searchReceiptNoChange = (e) => {
    this.state.searchReceiptNo = e.target.value;
    clearTimeout(this.state.typingTimeout);
    this.setState({
      typingTimeout: setTimeout(() => {
        this.handleSearch();
      }, 500),
    });
  };

  searchUnitNoChange = (e) => {
    const value = e.target.value;
    this.state.searchUnitNo = value;
    clearTimeout(this.state.typingTimeout);
    let companyId = this.props.commons.selectedCompany._id;

    this.setState({
      typingTimeout: setTimeout(() => {
        client
          .authenticate()
          .then((res) => {
            return client.service('propertyunits').find({
              query: {
                $limit: 30,
                // $populate: 'paymentTypeParamId propertyunitId',
                propertyId: companyId,
                $sort: {
                  referenceId: 1,
                },
                orRegex: { unitNo: value.trim() },
              },
            });
          })
          .then((res) => {
            console.log({ res });
            if (res.total > 0) {
              let result = res.data.map((i) => i._id);
              this.setState({ searchUnitNoId: result }, () => {
                this.handleSearch();
              });
            } else {
              this.setState({ searchUnitNoId: [] }, () => {
                this.handleSearch();
              });
            }
          })
          .catch((err) => {
            console.log({ err });
          });
      }, 500),
    });
  };

  searchAmountChange = (e) => {
    this.state.searchAmount = e.target.value;
    clearTimeout(this.state.typingTimeout);
    this.setState({
      typingTimeout: setTimeout(() => {
        this.handleSearch();
      }, 500),
    });
  };

  searchPaymentChange = (e) => {
    this.setState({ selectedPaymentMode: e }, () => {
      this.handleSearch();
    });
  };

  searchChannelChange = (e) => {
    this.setState({ searchChannel: e }, () => {
      this.handleSearch();
    });
  };

  handleSearch = (e) => {
    try {
      e.preventDefault();
    } catch (e) {}

    const {
      dateFilter,
      searchReceiptNo,
      searchAmount,
      searchChannel,
      searchUnitNo,
      searchUnitNoId,
      selectedPaymentMode,
    } = this.state;

    let receiptNo = searchReceiptNo === '' ? '' : { trxNo: searchReceiptNo };

    let unitNoId =
      searchUnitNo === '' ? '' : { propertyunitId: searchUnitNoId };

    let amount =
      searchAmount === '' ? '' : { appliedAmount: { $eq: searchAmount } };

    let channel = searchChannel === '' ? '' : { channel: searchChannel };
    let queryStringDate = '';
    let queryPaymentType = '';

    if (_.isEmpty(dateFilter) === false) {
      queryStringDate = {
        paymentDate: {
          $gte: new Date(moment(dateFilter[0]).startOf('day')),
          $lte: new Date(moment(dateFilter[1]).endOf('day')),
        },
      };
    }

    if (!_.isEmpty(selectedPaymentMode)) {
      queryPaymentType = {
        paymentTypeParamId: selectedPaymentMode,
      };
    }

    this.props.form.validateFields((err, values) => {
      let companyId = this.props.commons.selectedCompany._id;

      client
        .authenticate()
        .then((res) => {
          let query = {
            query: {
              ...amount,
              ...channel,
              ...queryStringDate,
              ...queryPaymentType,
              $limit: 20,
              $populate: 'paymentTypeParamId propertyunitId',
              propertyId: companyId,
              $sort: {
                createdAt: -1,
              },
              ...unitNoId,
              orRegex: { ...receiptNo },
            },
          };

          return client.service('transactions').find(query);
        })
        .then((res) => {
          this.setState({ totalPagination: res.total });
          this.props.fetchTransactions(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  renderConfirmDeleteBtn(record) {
    const { prepaymentList } = this.state;
    const userRole = this.props.commons.selectedRole;

    let searchResult = _.find(prepaymentList, function(a) {
      return a.transactionId === record._id;
    });

    if (userRole === 'admin') {
      return null;
    } else if (searchResult) {
      return (
        <>
          <Divider type='vertical' />
          <Popconfirm
            placement='topLeft'
            title={text}
            onConfirm={() => this.handleDelete(record)}
            okText='Confirm'
            cancelText='Cancel'
          >
            <Button type='dashed'>Delete</Button>
          </Popconfirm>
        </>
      );
    } else {
      return (
        <>
          <Divider type='vertical' />
          <Popconfirm
            placement='topLeft'
            title={text}
            onConfirm={() => this.handleDelete(record)}
            okText='Confirm'
            cancelText='Cancel'
          >
            <Button type='danger'>Delete</Button>
          </Popconfirm>
        </>
      );
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title: (
          <Row>
            <Row>
              <Col span={12}> Date</Col>
            </Row>
            <Row>
              <Col span={24}>
                <DatePicker.RangePicker
                  allowEmpty
                  type='Date'
                  onChange={this.searchDateChange}
                />
              </Col>
            </Row>
          </Row>
        ),
        dataIndex: 'paymentDate',
        key: 'paymentDate',
        render: (text, record) => moment(new Date(text)).format('YYYY-MM-DD'),
        width: '15%',
      },
      {
        title: (
          <Row gutter={8}>
            <Row>
              <Col span={24}>Receipt No</Col>
            </Row>
            <Row>
              <Col span={24}>
                <Input
                  value={this.state.searchReceiptNo}
                  onChange={this.searchReceiptNoChange}
                />
              </Col>
            </Row>
          </Row>
        ),
        dataIndex: 'trxNo',
        key: 'trxNo',
      },
      {
        title: (
          <Row gutter={8}>
            <Row>
              <Col span={24}>Unit Number</Col>
            </Row>
            <Row>
              <Col span={24}>
                <Input
                  value={this.state.searchUnitNo}
                  onChange={this.searchUnitNoChange}
                />
              </Col>
            </Row>
          </Row>
        ),
        dataIndex: 'customerType',
        key: 'customerType',
        render: (text, record) => {
          if (_.get(record, 'propertyunitId.referenceId') !== undefined) {
            return (
              <span>
                {record.customerType === 'PROPERTYUNIT'
                  ? record.propertyunitId.referenceId
                  : ''}
              </span>
            );
          }
        },
      },

      {
        title: (
          <Row gutter={8}>
            <Row>
              <Col span={24}>Amount (MYR)</Col>
            </Row>
            <Row>
              <Col span={24}>
                <Input
                  value={this.state.searchAmount}
                  onChange={this.searchAmountChange}
                />
              </Col>
            </Row>
          </Row>
        ),
        dataIndex: 'appliedAmount',
        key: 'appliedAmount',
        render: (text, record) => (
          <span style={{ float: 'left' }}>{parseFloat(text).toFixed(2)}</span>
        ),
      },
      {
        title: (
          <>
            <Row>
              <Col span={24}>Payment Mode</Col>
            </Row>
            <Row>
              <Col span={24}>
                <Select
                  style={{ width: '200px' }}
                  onChange={this.searchPaymentChange}
                  allowClear
                >
                  <Option key='' value=''></Option>
                  {this.state.dropDownPaymentType.map((v) => (
                    <Option key={v._id}>
                      {v.description + ' (' + v.category + ')'}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </>
        ),
        dataIndex: 'paymentTypeParamId.description',
      },
      {
        title: (
          <Row gutter={8}>
            <Row>
              <Col span={24}>Channel</Col>
            </Row>
            <Row>
              <Col span={24}>
                <Select
                  style={{ width: '80px' }}
                  defaultValue=''
                  onChange={this.searchChannelChange}
                  allowClear
                >
                  <Option key='WEB' value='WEB'>
                    WEB
                  </Option>
                  <Option key='APPS' value='APPS'>
                    APPS
                  </Option>
                </Select>
              </Col>
            </Row>
          </Row>
        ),
        dataIndex: 'channel',
        key: 'channel',
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            {/*
          <LedgerIndexTransactionsByReference transactionId={record._id} />
          <Divider type="vertical" />
          */}
            {/*<Button onClick={()=>this.handleEdit(record._id)}>Edit</Button>*/}
            <Link to={{ pathname: `/transactions/` + record._id }}>
              <Button>Print Receipts</Button>
            </Link>
            {this.renderConfirmDeleteBtn(record)}
          </span>
        ),
      },
    ];

    const activePage = this.props.activePage.officialReceiptPage;

    return (
      <Layout>
        <Spin key='spinning' spinning={this.state.loading}>
          <Card
            title='Official Receipt'
            extra={
              <Link to={{ pathname: `/propertyunitsmng/` }}>
                <Button type='primary'>New</Button>
              </Link>
            }
          >
            <Table
              dataSource={this.props.transactions.transactions}
              columns={columns}
              rowKey='_id'
              pagination={{
                defaultCurrent: this.props.activePage.officialReceiptPage,
                total: this.state.totalPagination,
                onChange: this.handlePageChange,
                pageSize: 20,
              }}
            />

            <TransactionsForm />
          </Card>
        </Spin>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    transactions: state.transactions,
    commons: state.commons,
    activePage: state.app.activePage,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchTransactions: fetchTransactions,
      pushTransactions: pushTransactions,
      removeTransactions: removeTransactions,
      updateActiveTransactions: updateActiveTransactions,
      updateTransactions: updateTransactions,
      updateMode: updateMode,
      updateActivePage,
    },
    dispatch
  );
}

export default requireAuth(
  connect(mapStateToProps, mapDispatchToProps)(Form.create()(TransactionsIndex))
);
