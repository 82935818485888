import React, { Component, useEffect } from 'react'
import { Row, Col, AutoComplete, Modal, Form, Input, Select, InputNumber, Spin, DatePicker } from 'antd'
import client from '../../feathers'
import requireAuth from "../../components/requireAuth"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateMode } from '../../actions/actions-debitnotes'
import moment from 'moment';
import _ from "lodash"


var FormData = require('form-data');
const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const formItemLayout2 = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};
//----------------------------------------------------------------------------
class DebitNotesForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',
      confirmDirty: false,
      autoCompleteResult: [],
      referenceformat: '',
      ledgerAccountList: [],
      formloading: false,
      dtAccountList: [],
      ctAccountList: [],
      invoices: [],
      receiptId: null,
      receiptTotalAmount: 0,
    }

  }
  //----------------------------------------------------------------------------
  componentDidUpdate() {

    if (this.props.debitnotes.activeId !== this.state._id) {
      this.setState({
        _id: this.props.debitnotes.activeId
      })

      if (this.props.debitnotes.activeDebitNotes) {

        this.props.form.setFieldsValue({
          ctledgerAccountId: this.props.debitnotes.activeDebitNotes.ctledgerAccountId._id,
          dtledgerAccountId: this.props.debitnotes.activeDebitNotes.dtledgerAccountId._id,
          debitAmount: this.props.debitnotes.activeDebitNotes.debitAmount,
          remark: this.props.debitnotes.activeDebitNotes.remark,
        })
      }
    }

  }

  //----------------------------------------------------------------------------
  handleSubmit = (e) => {

    this.setState({ formloading: true })
    if (this.props.debitnotes.mode === 'new') {

      this.props.form.validateFields((err, values) => {

        if (!err) {

          values.trxDate = new Date(values.trxDate)

          client.authenticate()
            .then((res) => {
              return client.service('debitnotes').create({
                companyId: this.props.commons.selectedCompany._id,
                ...values,
                divisionType: 'SUPPLIER',
                trancode: '1800',
                receiptId: this.state.receiptId,
                receiptTotalAmount: this.state.receiptTotalAmount
              })
            })
            .then((res) => {

              this.props.updateMode('refresh')
              this.props.form.resetFields()
              this.setState({
                formloading: false,
              });
            })
            .catch(err => { console.log(err) })
        }

      });


    }
    else {
      this.props.form.validateFields((err, values) => {

        if (!err) {

          this.setState({
            formloading: true,
          });

          values.trxDate = new Date(values.trxDate)

          client.authenticate()
            .then((res) => {
              return client.service('debitnotes').patch(this.props.debitnotes.activeId, {
                companyId: this.props.commons.selectedCompany._id,
                ...values,
              })
            })
            .then((res) => {

              this.props.updateMode('refresh')
              this.props.form.resetFields()
              this.setState({
                formloading: false,
              });
            })
            .catch(err => { console.log(err) })
        }

      });
    }
  }
  //----------------------------------------------------------------------------
  //----------------------------------------------------------------------------
  componentWillMount() {


    client.authenticate()
      .then((res) => {
        return client.service('ledger-accounts').find({
          query: {
            companyId: this.props.commons.selectedCompany._id,
            $sort: { accountName: 1 },
            accountNo: '4000',
          }
        })
      })
      .then((res) => {

        this.setState({
          dtAccountList: res.data
        });

        return client.service('payment-type-params').find({
          query: {
            propertyId: this.props.commons.selectedCompany._id,
            category: {
              $in: ['DEBITNOTES']
            },
          }
        })
      })
      .then((res) => {

        this.setState({
          ctAccountList: res.data
        });
      })


      // this.getExpenses()
      .catch((err) => {
        console.log(err);
      });
  }

  resetForm() {
    this.props.form.setFieldsValue({
      ctledgerAccountId: null,
      dtledgerAccountId: null,
      debitAmount: null,
      invoiceNo: null,
      invoiceDate: null,
      trxDate: null,
      remark: null,
    })
  }

  // getExpenses(_id) {
  //   let query =
  //   {
  //     companyId: this.props.commons.selectedCompany._id,
  //     $sort: { accountName: 1 },
  //     categories: 'EXPENSES'
  //   }

  //   if (_id) { query = { _id } }

  //   return client.service('ledger-accounts').find({ query })
  //     .then((res) => { this.setState({ ctAccountList: res.data }) })
  // }

  getSupplierInvoices(accountId) {
    return client.service('suppliers').find({
      query: { ledgerAccountId: accountId }
    })
      .then((res) => {
        if (res.total > 0) {
          return client.service('receipts').find({
            query: { supplierId: res.data[0]._id }
          })
        }
        return { "data": [] }
      })
      .then((res) => {
        this.setState({ invoices: res.data })
      })
  }

  updateAmount(e) {
    let result = _.find(this.state.invoices, { receiptNo: e })
    if (result) {
      // this.getExpenses(result.debitledgerAccountId)
      this.setState({ receiptId: result._id, receiptTotalAmount: result.totalAmount })
      this.props.form.setFieldsValue({ invoiceDate: moment(result.date) })
      // this.props.form.setFieldsValue({ debitAmount: result.totalAmount, invoiceDate: moment(result.date) })
    } else {
      // this.getExpenses()
      this.setState({ receiptId: null, receiptTotalAmount: 0 })
      // this.props.form.setFieldsValue({ debitAmount: null, invoiceDate: null })
    }
  }

  //----------------------------------------------------------------------------
  render() {
    const { visible, onCancel } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { referenceformat } = this.state;

    return (
      <Spin spinning={this.state.formloading}>
        <Modal
          centered
          visible={this.props.debitnotes.mode === 'new' || this.props.debitnotes.mode === 'edit' ? true : false}
          title={this.props.debitnotes.mode === 'new' ? 'Add New Supplier Debit Notes' : 'Edit Supplier Debit Notes'}
          okText={this.props.debitnotes.mode === 'new' ? 'Add' : 'Edit'}
          onCancel={() => { this.props.updateMode(''); this.props.form.resetFields() }}
          onOk={() => { this.handleSubmit() }}

        >
          <Form className="submit-form">

            <FormItem {...formItemLayout} label="Transaction Date">
              {getFieldDecorator('trxDate', {
                rules: [{
                  required: true, message: 'Please input Transaction Date!',
                }],
                initialValue: moment(),
              })(
                <DatePicker />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="Supplier">
              {getFieldDecorator('dtledgerAccountId', {
                rules: [{
                  required: true, message: 'Please select Debtor!',
                }],
              })(
                <Select onChange={(e) => {
                  this.getSupplierInvoices(e)
                  this.resetForm()
                }}>
                  {this.state.dtAccountList.map(dropdown => (
                    <Option key={dropdown._id} value={dropdown._id} description={dropdown.description} >
                      {dropdown.description}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="Credit to">
              {getFieldDecorator('ctledgerAccountId', {
                rules: [{
                  required: true, message: 'Please select Account Date!',
                }],
              })(
                <Select>
                  {this.state.ctAccountList.map(dropdown => (
                    <Option key={dropdown.ledgerAccountId} value={dropdown.ledgerAccountId} description={dropdown.description} >
                      {dropdown.description}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="Invoice No">
              {getFieldDecorator('invoiceNo', {
                rules: [{
                  required: false, message: 'Please select Invoice No!',
                }],
              })(
                <AutoComplete onBlur={(e) => this.updateAmount(e)} onSelect={(e) => this.updateAmount(e)} onChange={(e) => this.updateAmount(e)} style={{ width: 200 }} onSearch={this.handleSearch} placeholder="">
                  {this.state.invoices.map(d => (
                    <AutoComplete.Option key={d._id} value={d.receiptNo} description={d.receiptNo} >
                      {d.receiptNo}
                    </AutoComplete.Option>
                  ))}
                </AutoComplete>
              )}
            </FormItem>

            <Row>
              <Col span={16}>
                <FormItem {...formItemLayout2} label="Debit Amount">
                  {getFieldDecorator('debitAmount', {
                    rules: [{
                      required: true, message: 'Please select Debit Amount!',
                    }],
                  })(
                    <InputNumber style={{ width: '90%' }} />
                  )}
                </FormItem>
              </Col>
              <Col span={8}><p style={{ margin: '10px', fontColor: "lightgrey" }}>{this.state.receiptTotalAmount.toFixed(2)}</p></Col>
            </Row>

            <FormItem {...formItemLayout} label="Invoice Date">
              {getFieldDecorator('invoiceDate', {
                rules: [{
                  required: false, message: 'Please input Invoice Date!',
                }],
                initialValue: moment(),
              })(
                <DatePicker />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="Remark">
              {getFieldDecorator('remark', {
                rules: [{
                  required: false, message: 'Please select Remark!',
                }],
              })(
                <TextArea rows={4} />
              )}
            </FormItem>

          </Form>
        </Modal>
      </Spin>
    )
  }
}

//----------------------------------------------------------------------------
function mapStateToProps(state) {
  return {
    debitnotes: state.debitnotes,
    commons: state.commons
  }
}
//----------------------------------------------------------------------------
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
  }, dispatch);
}

//----------------------------------------------------------------------------

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(DebitNotesForm)));