import React, { Component } from 'react';
import { Popconfirm, message, Divider,  Table, Form, Icon, Input, Button,Card } from 'antd';
import { Route, Link } from 'react-router-dom';

import client from '../../feathers';
import requireAuth  from "../../components/requireAuth";
import Layout  from "../../components/layout";
import FpxParametersForm  from "../../components/fpxparameter/form";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchFpxParameters,
  pushTrxParameters,
  removeTrxParameters,
  updateMode,
  updateActiveTrxParameters,
  updateTrxParameters } from '../../actions/actions-fpxparameters';

const FormItem = Form.Item;

const text = 'Are you sure to delete this item?';

class FpxParametersIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      loading:false,
      fpxparameters:[],
    }

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidUpdate(){
  }

  componentDidMount(){
      client.service('fpxparameters').on('created', (fpxparameters) => {
        this.componentWillMount();
      })

      client.service('fpxparameters').on('removed', (fpxparameters) => {
        this.componentWillMount();
      })

      client.service('fpxparameters').on('updated', (fpxparameters) => {
        this.componentWillMount();
      })

      client.service('fpxparameters').on('patched', (fpxparameters) => {
        this.componentWillMount();
      })
  }

  componentWillMount(){

      client.authenticate()
      .then(()=>{
          return client.service('fpxparameters').find({
            query: {
               // $sort: {
               //   trancode: 1
               // }
               $populate: 'ledgerAccountId',
             }
          })
      })
      .then((res)=>{
        this.props.fetchFpxParameters(res.data)
      })
      .catch((err)=>{

      })
  }

  handleDelete(_id) {
     client.service('fpxparameters').remove(_id)
     .then((res)=>{
     })
     .catch((err)=>{
       alert(err);
     })
  }

  handleEdit(_id) {
    this.props.updateActiveTrxParameters('edit',_id)

  }

  handleManage(_id) {
    this.props.updateActiveTrxParameters('',_id)
  }

  showModal = () => {
    this.props.updateMode('new')
  }

  renderConfirmDeleteBtn(_id){
    return (
      <Popconfirm placement="topLeft" title={text} onConfirm={()=>this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
        <Button type="danger">Delete</Button>
      </Popconfirm>
    )
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const columns = [

      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        width: '10%',
      },
      {
        title: 'Description',
        dataIndex: 'des',
        key: 'des',
        width: '20%',
      },
      {
        title: 'Account Name',
        dataIndex: 'ledgerAccountId.accountName',
        key: 'ledgerAccountId',
        width: '20%',
      },
      {
      title: 'Action',
      key: 'action',
      width: '20%',
      render: (text, record) => (
        <span>
          <Divider type="vertical" />
          <Button onClick={()=>this.handleEdit(record._id)}>Edit</Button>
          <Divider type="vertical" />
          {this.renderConfirmDeleteBtn(record._id)}
        </span>
      ),
    }];

    return(
      <Layout>
          <Card
            title="Transasction Parameter"
            extra={<Button type="primary" onClick={this.showModal}>New</Button>}
          >
            <Table dataSource={this.props.fpxparameters.fpxparameters} columns={columns} rowKey="_id" />
            <FpxParametersForm />
          </Card>

      </Layout>
    )
  }
}


function mapStateToProps(state) {

  return {
    fpxparameters: state.fpxparameters,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchFpxParameters: fetchFpxParameters,
    pushTrxParameters: pushTrxParameters,
    removeTrxParameters: removeTrxParameters,
    updateActiveTrxParameters: updateActiveTrxParameters,
    updateTrxParameters: updateTrxParameters,
    updateMode: updateMode
    }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(FpxParametersIndex)));
