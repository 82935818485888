export const FETCH_USERS = 'FETCH_USERS';
export const PUSH_USERS = 'PUSH_USERS';
export const REMOVE_USERS = 'REMOVE_USERS';
export const UPDATE_USERS = 'UPDATE_USERS';
export const UPDATE_MODE = 'UPDATE_MODE';
export const UPDATE_ACTIVE_ID = 'UPDATE_ACTIVE_ID';

export function fetchUsers(data){
  return (dispatch) => {
    dispatch({
      type: FETCH_USERS,
      payload:data
    });
  }
}

export function pushUsers(data){
  return (dispatch) => {
    dispatch({
      type: PUSH_USERS,
      payload:data
    });
  }
}

export function removeUsers(data){
  return (dispatch) => {
    dispatch({
      type: REMOVE_USERS,
      payload:data
    });
  }
}

export function updateUsers(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_USERS,
      payload:data
    });
  }
}

export function updateMode(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE,
      payload:data
    });
  }
}

export function updateActiveUser(mode, id){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_ID,
      mode,
      id
    });
  }
}
