import _ from 'lodash';
import {
    FETCH_BANKRECONS,
    FETCH_BANKRECONS2,
    PUSH_BANKRECONS,
    REMOVE_BANKRECONS,


    UPDATE_ACTIVE_ID_BANKRECONS, UPDATE_BANKRECONS,
    UPDATE_MODE_BANKRECONS,

    UPDATE_MONTHBANK
} from '../actions/actions-bankrecons';

const INITIAL_STATE = {
  bankrecons: [],
  bankrecons2: [],
  mode: 'index',
  activeId: '',
  activeBankRecons: {},
  selectedMonth: '',
  selectedBank: '',
  closingBalance: 0,
}


export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_BANKRECONS:
      return {
        ...state,
        bankrecons: action.payload,
      }
    case FETCH_BANKRECONS2:
      return {
        ...state,
        bankrecons2: action.payload,
      }
    case PUSH_BANKRECONS:
      return {
        ...state,
        bankrecons: [action.payload, ...state.bankrecons],
      }
    case REMOVE_BANKRECONS:
      return {
        ...state,
        bankrecons: [...state.bankrecons.filter((item) => item._id !== action.payload._id)]
      }
    case UPDATE_BANKRECONS:
      let index = _.findIndex(state.bankrecons, { '_id': action.payload._id })
      state.bankrecons.splice(index, 1, action.payload)
      return {
        ...state,
        bankrecons: state.bankrecons
      }
    case UPDATE_MODE_BANKRECONS:
      return {
        ...state,
        mode: action.payload,
        activeId: ''
      }
    case UPDATE_ACTIVE_ID_BANKRECONS:
      return {
        ...state,
        mode: action.mode,
        activeId: action.id,
        activeBankRecons: _.pick(_.find(state.bankrecons, { '_id': action.id }), [
          'companyId',
          'status',
          'trxdate',
          'description',
          'category',
          'balance',
          'bankLedgerAccountId',
          'ledgerAccountId',
          'remark',
          'statementBalance',
          'reconMonth',
        ])
      }
    case UPDATE_MONTHBANK:
      return {
        selectedMonth: action.month,
        selectedBank: action.bank,
        closingBalance: action.closingBalance,
      }
    default:
      return state

  }
}
