import React, { Component, useEffect } from 'react';
import {
  Modal, Upload, Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Select, InputNumber, Card, Spin,
  Tabs, Row, Col
} from 'antd';
import client from '../../feathers';
import requireAuth from "../requireAuth";
import Layout from "../layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateMode } from '../../actions/actions-settings';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const TabPane = Tabs.TabPane;
const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

const text = 'Are you sure to delete this item?';

class SettingsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',
      confirmDirty: false,
      autoCompleteResult: [],
      setting: [],
      loading: false,
      text: '',
      textReceipt: '',
      textApplicationForm: '',
      textCreditNoteForm: '',
      textDebitNoteForm: '',
      textSoaForm: '',
      textReminderForm: '',
      textNOD11Name: '',
      textNOD11Designation: '',
      textNOD20Name: '',
      textNOD20Designation: '',
    }
    this._handleChangeSoaForm = this._handleChangeSoaForm.bind(this);
    this._handleChangeReceipt = this._handleChangeReceipt.bind(this);
    this._handleChangeApplicationForm = this._handleChangeApplicationForm.bind(this);
    this._handleChangeCreditNoteForm = this._handleChangeCreditNoteForm.bind(this);
    this._handleChangeDebitNoteForm = this._handleChangeDebitNoteForm.bind(this);
    this._handleChangeSoaForm = this._handleChangeSoaForm.bind(this);
    this._handleChangeReminderForm = this._handleChangeReminderForm.bind(this);
    this._handleChangeNOD11Name = this._handleChangeNOD11Name.bind(this);
    this._handleChangeNOD11Designation = this._handleChangeNOD11Designation.bind(this);
    this._handleChangeNOD20Name = this._handleChangeNOD20Name.bind(this);
    this._handleChangeNOD20Designation = this._handleChangeNOD20Designation.bind(this);

    this._handleChange = this._handleChange.bind(this);
    this._renderResult = this._renderResult.bind(this);
  }

  componentWillMount() {

    this.setState({ loading: true });
    client.authenticate()
      .then((res) => {

        return client.service('settings').find({
          query: {
            propertyId: this.props.commons.selectedCompany._id,
            $sort: {
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {


        if (res.total < 1) {
          message.error('No Record Found, Kindly contact System ADMIN');
        }
        this.setState({
          loading: false,
          setting: res.data[0],
          text: res.data[0].tnc.invoiceTnc ? res.data[0].tnc.invoiceTnc : '',
          textReceipt: res.data[0].tnc.receiptTnc ? res.data[0].tnc.receiptTnc : '',
          textApplicationForm: res.data[0].tnc.applicationFormTnc ? res.data[0].tnc.applicationFormTnc : '',
          textCreditNoteForm: res.data[0].tnc.creditNoteTnc ? res.data[0].tnc.creditNoteTnc : '',
          textDebitNoteForm: res.data[0].tnc.debitNoteTnc ? res.data[0].tnc.debitNoteTnc : '',
          textSoaForm: res.data[0].tnc.soaTnc ? res.data[0].tnc.soaTnc : '',
          textReminderForm: res.data[0].tnc.reminderTnc ? res.data[0].tnc.reminderTnc : '',
          textNOD11Name: res.data[0].nodForm11 ? res.data[0].nodForm11.name : '',
          textNOD11Designation: res.data[0].nodForm11 ? res.data[0].nodForm11.designation : '',
          textNOD20Name: res.data[0].nodForm20 ? res.data[0].nodForm20.name : '',
          textNOD20Designation: res.data[0].nodForm20 ? res.data[0].nodForm20.designation : '',

        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      })
  }

  _handleChange(value) {
    this.setState({ text: value })
  }

  _handleChangeReceipt(value) {
    this.setState({ textReceipt: value })
  }

  _handleChangeApplicationForm(value) {
    this.setState({ textApplicationForm: value })
  }

  _handleChangeCreditNoteForm(value) {
    this.setState({ textCreditNoteForm: value })
  }

  _handleChangeDebitNoteForm(value) {
    this.setState({ textDebitNoteForm: value })
  }

  _handleChangeSoaForm(value) {
    this.setState({ textSoaForm: value })
  }

  _handleChangeReminderForm(value) {

    this.setState({ textReminderForm: value })
  }

  _handleChangeNOD11Name = (e) => {

    this.setState({ textNOD11Name: e.target.value })
  }

  _handleChangeNOD11Designation = (e) => {
    this.setState({ textNOD11Designation: e.target.value })
  }

  _handleChangeNOD20Name = (e) => {
    this.setState({ textNOD20Name: e.target.value })
  }

  _handleChangeNOD20Designation = (e) => {
    this.setState({ textNOD20Designation: e.target.value })
  }

  _renderResult() {
    return { __html: this.state.text }
  }

  _handleSave = (e) => {


    client.service('settings').patch(this.state.setting._id,
      {
        tnc: {
          invoiceTnc: this.state.text,
          receiptTnc: this.state.textReceipt,
          applicationFormTnc: this.state.textApplicationForm,
          creditNoteTnc: this.state.textCreditNoteForm,
          debitNoteTnc: this.state.textDebitNoteForm,
          soaTnc: this.state.textSoaForm,
          reminderTnc: this.state.textReminderForm,
        },
        nodForm11:
        {
          name: this.state.textNOD11Name,
          designation: this.state.textNOD11Designation,
        },
        nodForm20:
        {
          name: this.state.textNOD20Name,
          designation: this.state.textNOD20Designation,
        }

      }
    )
      .then((res) => {

        this.setState({ loading: false });
        message.success(`Updated`);
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      })
  }

  render() {
    const { visible, onCancel } = this.props;

    const { getFieldDecorator } = this.props.form;


    return (
      <Layout>
        <Spin spinning={this.state.loading}>
          <Card title="Term & Condition" extra={<Button style={{ marginTop: 10 }} type="primary" onClick={this._handleSave}>Save</Button>}>
            {/*  <Button style={{marginTop:10}} type="primary" onClick={this._handleSave}>Save</Button> */}



            <Tabs defaultActiveKey="1">
              <TabPane tab="Invoice" key="1">
                <ReactQuill value={this.state.text} onChange={this._handleChange} />
              </TabPane>
              <TabPane tab="Payment Receipt" key="2">
                <ReactQuill value={this.state.textReceipt} onChange={this._handleChangeReceipt} />
              </TabPane>
              <TabPane tab="Application Form" key="3">
                <ReactQuill value={this.state.textApplicationForm} onChange={this._handleChangeApplicationForm} />
              </TabPane>
              <TabPane tab="Credit Note" key="4">
                <ReactQuill value={this.state.textCreditNoteForm} onChange={this._handleChangeCreditNoteForm} />
              </TabPane>
              <TabPane tab="Debit Note" key="5">
                <ReactQuill value={this.state.textDebitNoteForm} onChange={this._handleChangeDebitNoteForm} />
              </TabPane>
              <TabPane tab="SOA" key="6">
                <ReactQuill value={this.state.textSoaForm} onChange={this._handleChangeSoaForm} />
              </TabPane>
              <TabPane tab="Reminder" key="7">
                <ReactQuill value={this.state.textReminderForm} onChange={this._handleChangeReminderForm} />
              </TabPane>
              <TabPane tab="NOD(Form 11)" key="8">
                <Row>Name:</Row>
                <Row> <Col span={4}><Input value={this.state.textNOD11Name} onChange={this._handleChangeNOD11Name} /></Col></Row>
                <Row>Designation:</Row>
                <Row> <Col span={4}><Input value={this.state.textNOD11Designation} onChange={this._handleChangeNOD11Designation} /></Col></Row>
              </TabPane>
              <TabPane tab="NOD(Form 20)" key="9">
                <Row>Name:</Row>
                <Row> <Col span={4}><Input value={this.state.textNOD20Name} onChange={this._handleChangeNOD20Name} /></Col></Row>
                <Row>Designation:</Row>
                <Row> <Col span={4}><Input value={this.state.textNOD20Designation} onChange={this._handleChangeNOD20Designation} /></Col></Row>
              </TabPane>
            </Tabs>

          </Card>
        </Spin>
      </Layout>

    )
  }
}


function mapStateToProps(state) {
  return {
    settings: state.settings,
    commons: state.commons
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(SettingsForm)));
