import React from "react";

const SvgBasketball = props => (
  <svg height={512} viewBox="0 0 480 480" width={512} {...props}>
    <path
      fill="#8D99AE"
      d="M240 0C107.453 0 0 107.453 0 240s107.453 240 240 240 240-107.453 240-240C479.852 107.516 372.484.148 240 0zm214.078 174.047a298.679 298.679 0 00-82.75-7.527 156.95 156.95 0 0129.727-81.97 224.112 224.112 0 0153.023 89.497zm-64.652-100.75a174.536 174.536 0 00-33.84 94.062 538.716 538.716 0 00-76.32 11.395 405.453 405.453 0 00-62.524-161.547A226.7 226.7 0 01240 16a223.112 223.112 0 01149.426 57.297zM221.184 463.152a222.39 222.39 0 01-100.153-33.488c62.09-81.152 90.77-152.863 85.367-213.504 19.457-6.472 39.38-12.367 59.403-17.39 8.262 71.773-.488 160.175-44.617 264.382zm-17.246-263.664c-.563-2.793-1.075-5.601-1.786-8.343-16.062-61.778-67.824-97.88-111.488-117.922A223.411 223.411 0 01199.152 19.8a388.181 388.181 0 0164.442 162.597c-18.625 4.524-38.465 10.137-59.656 17.09zM78.336 85.23c41.512 18.016 93.176 51.524 108.367 109.938.8 3.113 1.434 6.305 2.024 9.496a1268.653 1268.653 0 00-169.2 74.45C6.941 208.796 28.81 136.706 78.336 85.23zM22.93 295.328a1299.116 1299.116 0 01167.804-73.8c3.457 55.921-24.343 122.745-82.949 199.136A224.604 224.604 0 0122.93 295.328zM240 464c-.625 0-1.238-.04-1.863-.047 43.765-105.723 52.16-195.695 43.406-268.937a526.608 526.608 0 0174.312-11.657c3.047 44 25.801 95.41 87.497 150.403C406.722 413.078 327.367 463.902 240 464zm209.922-145.879c-54.168-49.601-75.54-95.473-78.402-135.695A266.666 266.666 0 01458.762 192a223.396 223.396 0 01-8.801 126.152zm0 0"
      data-original="#000000"
      className="basketball_svg__active-path"
      data-old_color="#000000"
    />
    <path
      d="M50.969 213.602a8 8 0 008-8 124.504 124.504 0 0132-80.282 8.002 8.002 0 10-11.328-11.305 138.65 138.65 0 00-36.672 91.587 8 8 0 008 8zm0 0M111.105 385.488a7.997 7.997 0 007.883-6.164 7.996 7.996 0 00-4.3-9.035 81.3 81.3 0 01-38.696-54.562 8 8 0 00-15.688 3.137 96.87 96.87 0 0047.2 65.738 8.07 8.07 0 003.601.886zm0 0M190.977 412.855l-25.778-8.59a8.01 8.01 0 10-5.062 15.2L185.91 428a8.012 8.012 0 005.067-15.2zm0 0"
      data-original="#000000"
      className="basketball_svg__active-path"
      data-old_color="#000000"
      fill="#8D99AE"
    />
  </svg>
);

export default SvgBasketball;
