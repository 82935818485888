import React, {Component} from 'react'
import {
  Modal,
  Popconfirm,
  message,
  Divider,
  Table,
  Form,
  Icon,
  Input,
  Button,
  Card,
  Spin,
  DatePicker,
  Select,
  Row,
  Col,
  Radio,
} from 'antd'
import {Route, Link} from 'react-router-dom';

import client from '../../feathers'
import requireAuth from "../../components/requireAuth"
import Layout from "../../components/layout"
import BankReconsForm from "../../components/bankrecons/form"

import {connect} from "react-redux"
import {bindActionCreators} from "redux"
import {
  updateValueIDN,
} from '../../actions/actions-commons'
import moment from 'moment';
import params from '../../params/system'


const {MonthPicker, RangePicker, WeekPicker} = DatePicker;
const FormItem = Form.Item;
const Option = Select.Option;
const formItemLayout = {
  labelCol: {
    span: 4
  },
  wrapperCol: {
    span: 10
  }
};
const _ = require('lodash');

class BankReconsIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      propertyunit: [],
      searchAccount:'',
      fetching:false,
    }

  }

  componentDidUpdate() {}

  componentDidMount() {}

  componentWillMount() {


    client.authenticate().then((res) => {

      return client.service('propertyunits').find({
        query: {
          propertyId: sessionStorage.getItem('companyId'),
          $sort: {
            referenceId: 1
          }
        }
      })
    }).then((res) => {
      this.setState({propertyunit: res.data})
    }).catch((err) => {
      console.log(err);
      this.setState({propertyunit: []})
    })

  }

  goNextPage() {
              this.props.form.validateFields((err, values) => {

                if(!err)
                {
              let propertyunit= _.find(this.state.propertyunit, { '_id': values.debtorAcc })

              this.props.updateValueIDN("print",values.status,values.debtorAcc,propertyunit);
              this.props.history.push('/interestDebitNotePrint/');
            }
            
            })

  }

  handleSearchAccount = (value) => {

    this.setState({fetching:true});

    this.setState({
    typingTimeout: setTimeout(()=>{

    client.authenticate()
    .then((res)=>{
        return client.service('ledger-accounts').find({
          query: {
            companyId:sessionStorage.getItem('companyId'),
            accountNo:'3000',
            subAccountNo:{$ne:'000'},
             $sort: {
               description: 1
            },
            orRegex:{description:value},
           }
        })
    })
    .then((res)=>{

      this.setState({
        propertyunit:res.data,
        fetching:false,
      });
    })

  }, 500)
  })

  }

  render() {

    const {getFieldDecorator} = this.props.form;
    const {loading} = this.state;
    const {propertyunit} = this.state;

    return (<Layout>

      

      <Form className="submit-form">
        <Row gutter={8}>

        <FormItem {...formItemLayout} label="Status">
          {
            getFieldDecorator('status', {
              rules: [
                {
                  required: true,
                  message: 'Please Select Status!'
                }
              ]
            })(<Radio.Group key='status'>
              <Radio.Button key='CHARGED' value='CHARGED' description='CHARGED'>Charged</Radio.Button>
              <Radio.Button key='ALL' value='' description='All'>All</Radio.Button>
            </Radio.Group>)
          }
        </FormItem>

          <FormItem {...formItemLayout} label="Property Unit">
            {
              getFieldDecorator('debtorAcc', {
                rules: [
                  {
                    required: true,
                    message: 'Please select Debtor Account!'
                  }
                ]
              })(<Select
                showSearch
                placeholder="Select Account"
                notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
                filterOption={false}
                onSearch={this.handleSearchAccount}
                onChange={this._searchAccountChange}
                value={this.state.searchAccount}
                >
                {
                  this.state.propertyunit.map(dropdown => (<Option key={dropdown._id} value={dropdown._id} description={dropdown.referenceId}>
                    {dropdown.referenceId}
                  </Option>))
                }
              </Select>)
            }
          </FormItem>
          <Col span={4} offset={3}>


              <Button onClick ={()=> this.goNextPage()}  type='submit'>Next</Button>

          </Col>
        </Row>
      </Form>
      
    </Layout>)
  }
}

function mapStateToProps(state) {
  return {commons: state.commons}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({

    updateValueIDN: updateValueIDN,

  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(BankReconsIndex)));
