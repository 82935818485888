import React, { Component } from 'react'
import { Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Card, Spin, Tabs, Badge, Select } from 'antd'
import { Route, Link } from 'react-router-dom';
import axios from 'axios';
import client from '../../feathers'
import requireAuth from "../../components/requireAuth"
import Layout from "../../components/layout"
import ComplaintsForm from "../../components/complaints/form"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  fetchComplaints,
  pushComplaints,
  removeComplaints,
  updateMode,
  updateActiveComplaints,
  updateComplaints
} from '../../actions/actions-complaints'
import _ from 'lodash';


const FormItem = Form.Item;
const { TabPane } = Tabs;
const Option = Select.Option;
const text = 'Are you sure to delete this item?';
const complaintFeedback = [
  { status: 'Pending', total: 'totalPending' },
  { status: 'Rejected', total: 'totalRejected' },
  { status: 'Solved', total: 'totalSolved' },
  { status: 'In progress', total: 'totalProgress' }
]

class ComplaintsIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userRole: '',
      loading: false,
      statusType: '',
      totalPending: 0,
      totalProgress: 0,
      totalSolved: 0,
      totalRejected: 0,
      dropDownPropertyUnit: [],
      selectedPropertyUnit: '',
      selectedCategory: ''
    }

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidUpdate() {
    if (this.props.complaints.mode === 'refresh') {

      this.componentWillMount()
    }

  }

  componentWillMount() {
    this.props.updateMode('')
    this.setState({
      loading: true,
      statusType: 'Pending'
    }, () => {
      this.getData(0)
    });


    client.service('propertyunits').find({
      query: {
        propertyId: this.props.user.info.propertyId._id
      }
    }).then((res) => {
      this.setState({ dropDownPropertyUnit: res.data })
    })


  }
  getData(skip) {

    const { statusType, selectedPropertyUnit, selectedCategory } = this.state


    let queryStatusType, queryPropertyUnit, queryCategories = ''
    if (!_.isEmpty(statusType)) {

      queryStatusType = { status: statusType }
    }
    if (!_.isEmpty(selectedPropertyUnit)) {

      queryPropertyUnit = { propertyunitId: selectedPropertyUnit }
    }
    if (!_.isEmpty(selectedCategory)) {

      queryCategories = { categories: selectedCategory }
    }

    client.authenticate()
      .then((res) => {

        axios.get(`${client.io.io.uri}getTotalStatusNumber`, {
          params: {
            propertyId: this.props.user.info.propertyId._id,
            propertyunitId: selectedPropertyUnit,
            categories: selectedCategory

          },
          headers: {
            'Authorization': client.settings.storage.storage['feathers-jwt']
          }
        }).then((res) => {


          let data = []

          try { data = res.data.data } catch (err) { data = [] }

          for (let i = 0; i < data.length; i++) {

            let result = data[i]
            if (result._id === 'Pending') { this.setState({ totalPending: result.totalCount }) }
            if (result._id === 'In progress') { this.setState({ totalProgress: result.totalCount }) }
            if (result._id === 'Solved') { this.setState({ totalSolved: result.totalCount }) }
            if (result._id === 'Rejected') { this.setState({ totalRejected: result.totalCount }) }

          }

          if (data.length !== complaintFeedback.length) {
            let clone_complaints = _.cloneDeep(complaintFeedback)



            let uniqueResultTwo = clone_complaints.filter(function (obj) {
              return !data.some(function (obj2) {
                return obj.status === obj2._id

              })
            })

            if (!_.isEmpty(uniqueResultTwo)) {

              _.forEach(uniqueResultTwo, (a) => {
                let obj = {}
                obj[a.total] = 0
                this.setState(obj)

              })
            }
          }

        }).catch((err) => {
          console.log(err)
        })




        if (res.role == "management") {
          this.setState({ userRole: res.role });
        }

        return client.service('complaints').find({
          query: {
            ...queryStatusType,
            ...queryPropertyUnit,
            ...queryCategories,
            propertyId: res.propertyId,
            $populate: ['propertyunitId', 'createdBy'],
            $sort: {
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {
        this.props.fetchComplaints(res.data)
        this.setState({
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      })

  }

  handleDelete(_id) {
    client.service('complaints').remove(_id)
      .then((res) => {

        this.props.updateMode('refresh')
        message.success('New Complaints Deleted');
      })
      .catch((err) => {
        alert(err);
      })
  }

  handleEdit(_id) {
    this.props.updateActiveComplaints('edit', _id)
  }

  handleManage(_id) {
    this.props.updateActiveComplaints('', _id)
  }

  showModals = () => {
    this.props.updateMode('new')
  }

  renderConfirmDeleteBtn(_id) {
    return (
      <Popconfirm placement="topLeft" title={text} onConfirm={() => this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
        <Button type="danger">Delete</Button>
      </Popconfirm>
    )
  }
  handleChangeStatus = (e) => {
    this.setState({
      statusType: e
    }, () => {
      this.getData(0)
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    //const  userRole  = this.state.userRole;
    const userRole = sessionStorage.getItem('role');
    let renderComplaintsForm
    if (userRole !== 'management') {

      renderComplaintsForm = <Button type="primary" onClick={this.showModals}>New Complaints</Button>;
    }

    const columns = [

      {
        title:

          <Select defaultValue='' allowClear showSearch style={{ width: 90 }} optionFilterProp="children"
            // filterOption={(input, option) =>
            //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            // }
            onChange={(e) => {
              this.setState({
                selectedPropertyUnit: e
              }, () => { this.getData(0) })
            }}
          >
            <Option value=''>Unit</Option>
            {this.state.dropDownPropertyUnit.map(v => { return <Option key={v._id}>{v.referenceId}</Option> })}


          </Select>,
        dataIndex: 'propertyunitId.referenceId',
        key: 'propertyunitId.referenceId',
      },
      {
        title:
          <Select style={{ width: '100%' }} defaultValue='' allowClear
            onChange={(e) => {
              this.setState({
                selectedCategory: e
              }, () => { this.getData(0) })
            }}
          >
            <Option value=''>Category</Option>
            <Option value='SECURITY'>Security</Option>
            <Option value='FACILITY'>Facility</Option>
            <Option value='CARPARK'>Car Park</Option>
            <Option value='OTHERS'>Others</Option>



          </Select>
        ,
        dataIndex: 'categories',
        key: 'categories',
      },
      {
        title: 'Subject',
        dataIndex: 'subject',
        key: 'subject',
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: 'Created By',
        dataIndex: 'createdBy',
        key: 'createdBy',
        render: (v, r) => (
          <p>{v.lastName} {v.firstName}</p>
        )
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text, record) => {
          let colorStatus = ''
          if (record.status === 'Pending') {
            colorStatus = '#fda855'
          }
          else if (record.status === 'Rejected') {
            colorStatus = 'red'

          }
          else if (record.status === 'Solved') {
            colorStatus = 'green'

          }

          else {
            colorStatus = '#9FD5B5'
          }

          return (<span style={{ color: colorStatus }}>
            {record.status}
          </span>)

        }


      },
      // {
      //   title: 'Email',
      //   dataIndex: 'createdBy.email',
      //   key: 'createdBy.email',
      // },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <Button onClick={() => this.handleEdit(record._id)}>Edit</Button>
            <Divider type="vertical" />
            {this.renderConfirmDeleteBtn(record._id)}
          </span>
        ),
      }];

    return (
      <Layout>
        E-Feedback

        <Spin spinning={this.state.loading}>
          <Card title={
            <Tabs defaultActiveKey='Pending' onChange={this.handleChangeStatus} style={{ flex: 1 }}>

              <TabPane tab={(<div><span className='marginRight8px' >Pending</span><Badge overflowCount={999999} style={{ marginLeft: '8px', backgroundColor: '#fda855' }} count={this.state.totalPending} /></div>)} key="Pending">
              </TabPane>
              <TabPane tab={(<div><span className='marginRight8px' >In Progress</span><Badge overflowCount={999999} style={{ marginLeft: '8px', backgroundColor: '#9FD5B5' }} count={this.state.totalProgress} /></div>)} key="In progress">
              </TabPane>
              <TabPane tab={(<div><span className='marginRight8px' >Solved</span><Badge overflowCount={999999} style={{ marginLeft: '8px', backgroundColor: 'green' }} count={this.state.totalSolved} /></div>)} key="Solved">
              </TabPane>
              <TabPane tab={(<div><span className='marginRight8px' >Rejected</span><Badge overflowCount={999999} style={{ marginLeft: '8px', backgroundColor: 'red' }} count={this.state.totalRejected} /></div>)} key="Rejected">
              </TabPane>


            </Tabs>
          }>
            {/*  {<Button type="primary" onClick={this.showModals}>New Complaints</Button>} */}
            {/* {renderComplaintsForm} */}

            <Table dataSource={this.props.complaints.complaints} columns={columns} rowKey="_id" />

            <ComplaintsForm />
          </Card>
        </Spin>
      </Layout>
    )
  }
}




function mapStateToProps(state) {
  return {
    complaints: state.complaints,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchComplaints: fetchComplaints,
    pushComplaints: pushComplaints,
    removeComplaints: removeComplaints,
    updateActiveComplaints: updateActiveComplaints,
    updateComplaints: updateComplaints,
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(ComplaintsIndex)));
