import React, { Component, useEffect } from 'react';
import {
  Row,
  Col,
  Popover,
  Tag,
  Button,
  Modal,
  Form,
  Input,
  message
   } from 'antd';
import client from '../../feathers'
import { connect } from "react-redux"
import { bindActionCreators } from "redux";
import {
  refreshPage,
} from '../../actions/actions-commons'
const _ = require('lodash')

const ButtonGroup = Button.Group;

const FormItem = Form.Item;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset:4 },
};

class ChartAction extends Component{
  constructor(props) {
    super(props)
    this.state = {
      visible:false,
      modalVisible:false,
      submitMode:'',
      resultStatus:'',
      commons:''

    }

    this._showModal = this._showModal.bind(this)
  }

  _getChildrenId(obj){
    let strResult=[]
    if(obj.children){
      strResult = _.map(_.values(obj.children), '_id')
      _.forEach(obj.children, (v)=>{
        if(v.children){
          strResult = strResult.concat( this._getChildrenId(v))
        }
      })
    }
    return strResult
  }

  _hide = () => {
    this.setState({
      visible: false,
    });
  }

  _handleVisibleChange = (visible) => {
    this.setState({ visible });
  }

  _showModal = (mode) => {
    let modalVisible = true
    let resultStatus = ''
    if(mode === 'edit'){
      resultStatus = 'Changed'
      this.props.form.setFieldsValue({
        name:this.props.info.name,
        title:this.props.info.title,
        contact:this.props.info.contact
      })
    }else if(mode === 'delete'){
      resultStatus = 'Deleted'
      modalVisible = false
      client.authenticate()
      .then((res)=>{
        return client.service('organizational-charts').remove(null,{
          query:{
            _id:{$in:this._getChildrenId(this.props.info).concat([this.props.info._id])}
          }
        })
      })
      .catch((err)=>{
        this.setState({
          visible: false,
        });
        this.props.form.resetFields();
        console.log(err);
        this.props.refreshPage('refresh')
      })
    }else if(mode === 'Add'){
      resultStatus = 'Added'
    }else{
      this.props.form.resetFields()
    }

    this.setState({
      modalVisible,
      visible: false,
      submitMode:mode
    });
  }

  _handleModalCancel = () => {
    this.setState({
      modalVisible: false,
    });
  }

  _closePopover = () => {
    this.setState({
      visible: false,
    });
  }

  _handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        client.authenticate()
        .then((res)=>{
          if(this.state.submitMode === 'add'){


             let organizational = client.service('organizational-charts').find({
              propertyId:this.props.user.info.propertyId._id,
            }).then((res) => {
              if (res.total===0)
              {
                  values.parent = ''
              }
              else
              {
                  values.parent=this.props.parent
              }

              return client.service('organizational-charts').create({
                ...values,
                propertyId:this.props.user.info.propertyId._id,

              })
            }).then((res) => {
              this.props.refreshPage('refresh')
            })


          }

          else if(this.state.submitMode === 'edit'){
            return client.service('organizational-charts').patch(this.props.info._id, values)
          }
        })
        .then((res)=>{

          this.setState({
            modalVisible: false,
          });
          message.success(this.props.resultStatus);
          this.props.form.resetFields();
          this.props.refreshPage('refresh')
        })
        .catch((err)=>{
          this.setState({
            modalVisible: false,
          });
          this.props.form.resetFields();
          console.log(err);
        })
      }
    });

  }

  render(){
    const { getFieldDecorator } = this.props.form;

    return(
      <div>
        <Modal
          title="Details"
          visible={this.state.modalVisible}
          onOk={this._handleSubmit}
          textOk='Submit'
          onCancel={this._handleModalCancel}
        >
          <Form className="submit-form">
            <FormItem
              {...formItemLayout}
              label="Name"
              hasFeedback
            >
              {getFieldDecorator('name', {
                rules: [
                  { required: true, message: 'Please input Name!' },
                ],
              })(
                <Input />
              )}
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Title"
              hasFeedback
            >
              {getFieldDecorator('title', {
                rules: [
                  { required: true, message: 'Please input Title!' },
                ],
              })(
                <Input />
              )}
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Contact"
              hasFeedback
            >
              {getFieldDecorator('contact', {
                rules: [
                  { required: true, message: 'Please input Contact!' },
                ],
              })(
                <Input />
              )}
            </FormItem>

          </Form>
        </Modal>

        <Popover
          content={(
            <Row type="flex">
              <Col span={24} align="middle">
                <ButtonGroup>
                  <Button icon="plus"  type="primary" onClick={()=>this._showModal('add')} />
                  <Button icon="edit" type="info" onClick={()=>this._showModal('edit')} />
                  <Button icon="delete" type="danger" onClick={()=>this._showModal('delete')} />
                  <Button icon="close" type="warning" onClick={this._closePopover} />
                </ButtonGroup>
              </Col>
            </Row>
          )}
          title="Action"
          trigger="click"
          visible={this.state.visible}
          onVisibleChange={this._handleVisibleChange}
          >
        <Button type="primary">Manage</Button>
        </Popover>
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    user: state.user,
    commons:state.commons,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    refreshPage:refreshPage,
    }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(Form.create()(ChartAction))
