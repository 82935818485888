import { combineReducers } from 'redux';
import AppReducer from './app-reducer';
import UserReducer from './user-reducer';
import PropertiesReducer from './properties-reducer';
import UsersReducer from './users-reducer';
import ManagementsReducer from './managements-reducer';
import PropertyunitsReducer from './reducer-propertyunits';
import PropertyunitusersReducer from './reducer-propertyunitusers';
import AdsReducer from './ads-reducer';
import FacilitiesReducer from './reducer-facilities';
import FacilitybookingsReducer from './reducer-facilitybookings';
import MngpropertiesReducer from './reducer-mngproperties';
import UserpropertiesReducer from './reducer-userproperties';
import ComplaintsReducer from './reducer-complaints';
import InvoicesReducer from './reducer-invoices';
import InvoiceitemsReducer from './reducer-invoiceitems';
import VendorsReducer from './reducer-vendors';
import PassesReducer from './reducer-passes';
import ProfomasReducer from './reducer-profomas';
import ChargesReducer from './reducer-charges';
import NoticesReducer from './reducer-notices';
import SettingsReducer from './reducer-settings';
import AccountsReducer from './reducer-accounts';
import AccountmainsReducer from './reducer-accountmains';
import AccounttrxsReducer from './reducer-accounttrxs';
import ReceiptsReducer from './reducer-receipts';
import SuppliersReducer from './reducer-suppliers';
import LedgersReducer from './reducer-ledgers';
import FilesReducer from './reducer-files';
import RequestsReducer from './reducer-requests';
import EformsReducer from './reducer-eforms';
import JmbsReducer from './reducer-jmbs';
import TransactionsReducer from './reducer-transactions';
import RecurringitemsReducer from './reducer-recurringitems';
import AccountingperiodsReducer from './reducer-accountingperiods';
import GlobalParametersReducer from './reducer-globalparameters';
import BankReconsReducer from './reducer-bankrecons';
import PaymentVouchersReducer from './reducer-paymentvouchers';
import MonthlyStatementsReducer from './reducer-monthlystatements';
import TrxParametersReducer from './reducer-trxparameters';
import FpxParametersReducer from './reducer-fpxparameters';
import DocumentsReducer from './reducer-documents';
import ChartOfAccountsReducer from './reducer-chartOfAccounts';
import DebtorAgingReportsReducer from './reducer-debtorAgingReports';
import CreditorAgingReportsReducer from './reducer-creditorAgingReports';
import JournalEntriesReducer from './reducer-journalentries';
import PaymentsVoucherMastersReducer from './reducer-paymentsvouchermasters';
import CommonsReducer from './reducer-commons';
import PaymentTypeParamsReducer from './reducer-paymenttypeparams';
import MeterReadingsReducer from './reducer-meterreadings';
import RefundsReducer from './reducer-refunds';
import WaterBillsReducer from './reducer-waterbills';
import DebitnotesReducer from './reducer-debitnotes';
import CreditNotesReducer from './reducer-creditnotes';
import UploadsReducer from './reducer-uploads';
import LedgerAccountsReducer from './reducer-ledgerAccounts';
import PinboardReducer from './reducer-pinboard'
import HelpReducer from './help-reducer'
import BankTrxReducer from './reducer-banktrxs';
const reducers = {
  app: AppReducer,
  user: UserReducer,
  properties: PropertiesReducer,
  users: UsersReducer,
  managements: ManagementsReducer,
  propertyunits: PropertyunitsReducer,
  propertyunitusers: PropertyunitusersReducer,
  ads: AdsReducer,
  facilities: FacilitiesReducer,
  facilitybookings: FacilitybookingsReducer,
  mngproperties: MngpropertiesReducer,
  userproperties: UserpropertiesReducer,
  complaints: ComplaintsReducer,
  invoices: InvoicesReducer,
  invoiceitems: InvoiceitemsReducer,
  vendors: VendorsReducer,
  passes: PassesReducer,
  profomas: ProfomasReducer,
  charges: ChargesReducer,
  notices: NoticesReducer,
  settings: SettingsReducer,
  accounts: AccountsReducer,
  accountmains: AccountmainsReducer,
  accounttrxs: AccounttrxsReducer,
  receipts: ReceiptsReducer,
  suppliers: SuppliersReducer,
  ledgers: LedgersReducer,
  files: FilesReducer,
  requests: RequestsReducer,
  eforms: EformsReducer,
  jmbs: JmbsReducer,
  transactions: TransactionsReducer,
  recurringitems: RecurringitemsReducer,
  accountingperiods: AccountingperiodsReducer,
  globalparameters: GlobalParametersReducer,
  bankrecons: BankReconsReducer,
  paymentvouchers: PaymentVouchersReducer,
  monthlystatements: MonthlyStatementsReducer,
  trxparameters: TrxParametersReducer,
  fpxparameters: FpxParametersReducer,
  documents: DocumentsReducer,
  chartOfAccounts: ChartOfAccountsReducer,
  debtorAgingReports: DebtorAgingReportsReducer,
  creditorAgingReports: CreditorAgingReportsReducer,
  journalentries: JournalEntriesReducer,
  paymentsvouchermasters: PaymentsVoucherMastersReducer,
  commons: CommonsReducer,
  paymenttypeparams: PaymentTypeParamsReducer,
  meterreadings: MeterReadingsReducer,
  refunds: RefundsReducer,
  waterbills: WaterBillsReducer,
  debitnotes: DebitnotesReducer,
  creditnotes: CreditNotesReducer,
  uploads: UploadsReducer,
  ledgeraccounts: LedgerAccountsReducer,
  pinboard: PinboardReducer,
  help: HelpReducer,
  banktrxs:BankTrxReducer,
}

const rootReducer = combineReducers(reducers);

export default rootReducer;
