import React, { Component } from 'react';
import { Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Card, Modal, Select, Row, Col } from 'antd';


import client from '../../feathers';
import requireAuth from "../../components/requireAuth";
import Layout from "../../components/layout";
import RequestsForm from "../../components/requests/form";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchRequests,
  pushRequests,
  removeRequests,
  updateMode,
  updateActiveRequests,
  updateRequests
} from '../../actions/actions-requests';
import moment from "moment";


const Option = Select.Option;
const _ = require('lodash');
const text = 'Are you sure to delete this item?';

class RequestsIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      img: '',
      status: '',
      searchStatus: '',
    }

    this.handleDelete = this.handleDelete.bind(this);
    this._searchStatusChange = this._searchStatusChange.bind(this);
  }

  componentDidUpdate() {
  }

  componentDidMount() {

  }

  componentWillMount() {

    client.authenticate()
      .then((res) => {

        return client.service('requests').find({
          query: {
            status: 'PENDING',
            $populate: 'userId propertyId propertyunitId',
            $sort: {
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {
        this.props.fetchRequests(res.data)
      })
      .catch((err) => {
        console.log(err);
      })
  }

  handleDelete(_id) {
    client.service('requests').remove(_id)
      .then((res) => {
      })
      .catch((err) => {
        alert(err);
      })
  }

  handleEdit(_id) {
    this.props.updateActiveRequests('edit', _id)
  }

  handleManage(_id) {
    this.props.updateActiveRequests('', _id)
  }

  showModal = () => {
    this.props.updateMode('new')
  }

  handleFilter = () => {

    //let status        = this.state.status===''?'':{status:this.state.status};
    let status = this.state.searchStatus === '' ? '' : { status: this.state.searchStatus };
    let collection = { ...status };

    client.authenticate()
      .then((res) => {
        return client.service('requests').find({
          query: {
            ...collection,
            $populate: 'userId propertyId propertyunitId',
            $sort: {
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {

        this.props.fetchRequests(res.data)
      })
      .catch((err) => {
        console.log(err);
      })
  }

  handleImage(img = null) {
    this.setState({
      visible: true,
      img: img
    });
  }

  handleOk = (e) => {

    this.setState({
      visible: false,
    });
  }

  handleCancel = (e) => {

    this.setState({
      visible: false,
    });
  }

  handleStatus = (e) => {
    this.setState({
      status: e
    });
  }

  _searchStatusChange(e) {

    this.state.searchStatus = e;
    this.setState({
      typingTimeout: setTimeout(() => {
        this.handleFilter()
      }, 500)
    })
  }

  renderConfirmDeleteBtn(_id) {
    return (
      <Popconfirm placement="topLeft" title={text} onConfirm={() => this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
        <Button type="danger">Delete</Button>
      </Popconfirm>
    )
  }

  render() {


    const columns = [
      {
        title: 'Name',
        dataIndex: 'userId.firstName',
        key: 'userId.firstName',
      },
      {
        title: 'Property',
        dataIndex: 'propertyName',
        key: 'propertyName',
      },
      {
        title: 'Property Unit',
        dataIndex: 'propertyUnitName',
        key: 'propertyUnitName',
      },
      {
        title: (
          <Row gutter={8}>
            <Row><Col span={24}>Status</Col></Row>
            <Row><Col span={24}>
              <Select style={{ width: 200 }} placeholder="Please select Status" value={this.state.searchStatus} onChange={this._searchStatusChange}>
                <Option value="APPROVED">APPROVED</Option>
                <Option value="PENDING">PENDING</Option>
              </Select>
            </Col></Row>
          </Row>
        ),
        dataIndex: 'status',
        key: 'status',
      },
      {
        title: 'Created Time',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text, record) => (
          moment(new Date(text)).format("YYYY-MM-DD HH:mm")
        ),
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <Divider type="vertical" />
            {/*<Button onClick={()=>this.handleEdit(record._id)}>Edit</Button>*/}
            {record.file ? <Button onClick={() => this.handleImage(record.file)}>Image</Button> : ''}
            <Divider type="vertical" />
            {/*this.renderConfirmDeleteBtn(record._id)*/}
          </span>
        ),
      }];

    return (
      <Layout>
        <Card
          title="Requests"
          //extra={<Button type="primary" onClick={this.showModal}>New</Button>}
          extra={[
            //  <Select style={{ width: 200 }} placeholder="Please select Status" onChange={this.handleStatus}>
            //    <Option value="APPROVED">APPROVED</Option>
            //    <Option value="PENDING">PENDING</Option>
            //  </Select>
            //    ,
            //    <Button type='primary' onClick={this.handleFilter} >Search</Button>
          ]}
        >



          <Table dataSource={this.props.requests.requests} columns={columns} rowKey="_id" />


          <Modal
            title="Image"
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            okText="Done"
          >
            <img style={{ 'width': '100%' }} src={this.state.img} />
          </Modal>
        </Card>

      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    requests: state.requests,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchRequests: fetchRequests,
    pushRequests: pushRequests,
    removeRequests: removeRequests,
    updateActiveRequests: updateActiveRequests,
    updateRequests: updateRequests,
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(RequestsIndex)));
