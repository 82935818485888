export const FETCH_CREDITNOTES = 'FETCH_CREDITNOTES';
export const PUSH_CREDITNOTES = 'PUSH_CREDITNOTES';
export const REMOVE_CREDITNOTES = 'REMOVE_CREDITNOTES';
export const UPDATE_CREDITNOTES = 'UPDATE_CREDITNOTES';
export const UPDATE_MODE = 'UPDATE_MODE';
export const UPDATE_ACTIVE_ID = 'UPDATE_ACTIVE_ID';

export function fetchCreditNotes(data){
  return (dispatch) => {
    dispatch({
      type: FETCH_CREDITNOTES,
      payload:data
    });
  }
}

export function pushCreditNotes(data){
  return (dispatch) => {
    dispatch({
      type: PUSH_CREDITNOTES,
      payload:data
    });
  }
}

export function removeCreditNotes(data){
  return (dispatch) => {
    dispatch({
      type: REMOVE_CREDITNOTES,
      payload:data
    });
  }
}

export function updateCreditNotes(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_CREDITNOTES,
      payload:data
    });
  }
}

export function updateMode(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE,
      payload:data
    });
  }
}

export function updateActiveCreditNotes(mode, id){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_ID,
      mode,
      id
    });
  }
}
