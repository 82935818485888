import _ from 'lodash';
import {
  FETCH_GLOBALPARAMETERS,
  PUSH_GLOBALPARAMETERS,
  REMOVE_GLOBALPARAMETERS,
  UPDATE_GLOBALPARAMETERS,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID } from '../actions/actions-globalparameters';

const INITIAL_STATE = {
  GlobalParameters: [],
  mode: 'index',
  activeId: '',
  activeGlobalParameters:{}
}


export default function(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_GLOBALPARAMETERS:
      return {
        ...state,
        GlobalParameters:action.payload,
      }
    case PUSH_GLOBALPARAMETERS:
      return {
        ...state,
        GlobalParameters:[action.payload, ...state.GlobalParameters],
      }
    case REMOVE_GLOBALPARAMETERS:
      return {
        ...state,
        GlobalParameters : [...state.GlobalParameters.filter( (item) => item._id !== action.payload._id)]
      }
    case UPDATE_GLOBALPARAMETERS:
      let index =  _.findIndex(state.GlobalParameters, { '_id': action.payload._id })
      state.GlobalParameters.splice(index, 1, action.payload)
      return {
        ...state,
        GlobalParameters : state.GlobalParameters
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode : action.payload,
        activeId:''
      }
    case UPDATE_ACTIVE_ID:
      return {
        ...state,
        mode : action.mode,
        activeId:action.id,
        activeGlobalParameters: _.pick(_.find(state.GlobalParameters, { '_id': action.id }), ['parametername','variable','variableValue1', '_id'])
      }
    default:
      return state

  }
}
