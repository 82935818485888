import React, { Component, useEffect } from 'react'
import { Modal, Upload, Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Select } from 'antd'
import client from '../../feathers'
import requireAuth from "../../components/requireAuth"
import Layout from "../../components/layout"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateMode } from '../../actions/actions-vendors'
import axios from 'axios';
var FormData = require('form-data');

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};

const text = 'Are you sure to delete this item?';

class VendorsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',
      confirmDirty: false,
      autoCompleteResult: [],
      serviceDropdowns: [],
    }
  }
  componentDidUpdate() {
    if (this.props.vendors.activeId !== this.state._id) {
      this.setState({
        _id: this.props.vendors.activeId
      })

      if (this.props.vendors.activeVendors) {
        console.log(this.props.vendors.activeVendors)
        this.props.form.setFieldsValue(
          this.props.vendors.activeVendors
        )
      }
    }

  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }

  handleFileUpload = (event) => {

    let formData = new FormData();

    formData.append('image', event.target.files[0]);

    axios.post(`${client.io.io.uri}upload-image`,
      formData
      , {
        headers: {
          'Authorization': client.settings.storage.storage['feathers-jwt'],
          'Content-Type': 'multipart/form-data',
        }
      }
    ).then((res) => {

      this.props.form.setFieldsValue({
        file: res.data.imageUrl
      })
      message.success('Upload Successful');
    })
      .catch((err) => {
        console.log(err);
        message.error(err);
      })
  }


  handleSubmit = (e) => {
    e.preventDefault();
    if (this.props.vendors.mode === 'new') {

      this.props.form.validateFields((err, values) => {
        if (!err) {
          client.service('vendors').create(
            values
          )
            .then((res) => {
              this.props.updateMode('')
              message.success('New Ad added');
            })
            .catch((err) => {
              this.props.updateMode('')
              alert(err);
            })
        }
      });
    } else {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          client.service('vendors').update(this.props.vendors.activeId,
            values
          )
            .then((res) => {
              message.success(`Updated`);
              this.props.updateMode('')
            })
            .catch((err) => {

              this.props.updateMode('')
            })
          return;
        }

      });
    }
  }

  componentWillMount() {

    client.authenticate()
      .then((res) => {

        return client.service('parameters').find({
          query: {
            table: 'serviceType',
            $sort: {
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {

        this.setState({
          serviceDropdowns: res.data[0].items,
        })
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          serviceDropdowns: []
        })
      })

  }


  render() {
    const { visible, onCancel } = this.props;

    const { getFieldDecorator } = this.props.form;

    return (
      <Modal
        visible={
          this.props.vendors.mode === 'new' || this.props.vendors.mode === 'edit' ? true : false
        }
        title={this.props.vendors.mode === 'edit' ? 'Update Service' : 'Create Service'}
        okText={this.props.vendors.mode === 'edit' ? 'Update' : 'Create'}
        onCancel={() => { this.props.updateMode(''); this.props.form.resetFields() }}
        onOk={this.handleSubmit}
      >
        <Form className="submit-form">


          <FormItem
            {...formItemLayout}
            label="Upload"
          >
            <Input label='upload file' type='file' onChange={this.handleFileUpload} />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="File Name"
          >
            {getFieldDecorator('file', {
              rules: [{
                required: false, message: 'Please Upload File!',
              }],
            })(
              <Input disabled="true" prefix={<Icon type="bars" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="File Name" />
            )}
          </FormItem>


          <FormItem
            {...formItemLayout}
            label="Service Name"
          >
            {getFieldDecorator('name', {
              rules: [{
                required: false, message: 'Please input Name!',
              }],
            })(
              <Input prefix={<Icon type="bars" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="" />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Decription"
          >
            {getFieldDecorator('description', {
              rules: [{
                required: false, message: 'Please input description!',
              }],
            })(
              <TextArea rows={4} />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Contact"
          >
            {getFieldDecorator('mobile', {
              rules: [{
                required: false, message: 'Please input Contact!',
              }],
            })(
              <Input prefix={<Icon type="bars" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="012-3456789" />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Email"
          >
            {getFieldDecorator('email', {
              rules: [{
                required: false, message: 'Please input email!',
              }],
            })(
              <Input prefix={<Icon type="bars" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="a@a.com.my" />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Location"
          >
            {getFieldDecorator('location', {
              rules: [{
                required: false, message: 'Please input Location!',
              }],
            })(
              <TextArea rows={4} />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Select Status"
            hasFeedback
          >
            {getFieldDecorator('status', {
              rules: [
                { required: true, message: 'Please select Status!' },
              ],
            })(
              <Select placeholder="Please select Status">
                <Option value="active">Active</Option>
                <Option value="inactive">Inactive</Option>
              </Select>
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Select Type"
            hasFeedback
          >
            {getFieldDecorator('type', {
              rules: [
                { required: true, message: 'Please select type!' },
              ],
            })(
              <Select placeholder="Please select type">
                {this.state.serviceDropdowns.map(dropdown => (
                  <Option value={dropdown.value}>
                    {dropdown.description}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>


        </Form>
      </Modal>

    )
  }
}


function mapStateToProps(state) {
  return {
    vendors: state.vendors
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
  }, dispatch);
}




export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(VendorsForm)));
