import _ from 'lodash';
import {
  FETCH_MNGPROPERTIES,
  PUSH_MNGPROPERTIES,
  REMOVE_MNGPROPERTIES,
  UPDATE_MNGPROPERTIES,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID } from '../actions/actions-mngproperties';

const INITIAL_STATE = {
  mngproperties: [],
  mode: 'index',
  activeId: '',
  activeMngproperties:{}
}


export default function(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_MNGPROPERTIES:
      return {
        ...state,
        mngproperties:action.payload,
      }
    case PUSH_MNGPROPERTIES:
      return {
        ...state,
        mngproperties:[action.payload, ...state.mngproperties],
      }
    case REMOVE_MNGPROPERTIES:
      return {
        ...state,
        mngproperties : [...state.mngproperties.filter( (item) => item._id !== action.payload._id)]
      }
    case UPDATE_MNGPROPERTIES:
      let index =  _.findIndex(state.mngproperties, { '_id': action.payload._id })
      state.mngproperties.splice(index, 1, action.payload)
      return {
        ...state,
        mngproperties : state.mngproperties
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode : action.payload,
        activeId:''
      }
    case UPDATE_ACTIVE_ID:
      return {
        ...state,
        mode : action.mode,
        activeId:action.id,
        activeMngproperties: _.pick(_.find(state.mngproperties, { '_id': action.id }), ['name', 'description', 'unit', 'categories', '_id'])
      }
    default:
      return state

  }
}
