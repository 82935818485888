import React, { Component } from 'react';
import {
  Modal,
  Popconfirm,
  Popover,
  message,
  Divider,
  Table,
  Form,
  Icon,
  Input,
  Button,
  Select,
  Radio,
  Card,
  Row,
  Col,
  Spin,
  Tree,
} from 'antd';
import client from '../../feathers';
import requireAuth from '../requireAuth';
import Layout from '../layout';
import Info from '../common/info';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchCreditorAgingReports } from '../../actions/actions-creditorAgingReports';
import axios from 'axios';
import { CSVLink } from 'react-csv';

const { TreeNode } = Tree;
const _ = require('lodash');

const formatCurr = function format1(n = 0.0, currency = null) {
  let oriAmt = n;
  n = n < 0 ? Math.abs(n) : n;

  try {
    let amt = currency
      ? currency
      : '' +
        n.toFixed(2).replace(/./g, function(c, i, a) {
          return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c;
        });
    return oriAmt < 0 ? '(' + amt + ')' : amt;
  } catch (e) {
    return '0.00';
  }
};

class CreditorAgingPrint extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      loading: false,
      fromDate: moment()
        .startOf('year')
        .format('YYYY-MM-DD'),
      toDate: moment()
        .endOf('year')
        .format('YYYY-MM-DD'),
      suppliers: [],
      receipts: [],
      paymentsvouchermasters: [],
      finaldata: [],
      jmbsName: '',
      jmbsNo: '',
      totalBalance: 0,
      excelHeader: [
        {
          label: 'Creditor',
          key: 'name',
        },

        {
          label: '0-30 days',
          key: 'firstMonth',
        },

        {
          label: '31-90 days',
          key: 'secondMonth',
        },
        {
          label: '91-180 days',
          key: 'thirdMonth',
        },

        {
          label: '> 180 days',
          key: 'fourthMonth',
        },
        {
          label: 'Total Invoice Amount',
          key: 'totalAmount',
        },
        {
          label: 'Total Payment Amount',
          key: 'totalPaid',
        },
        {
          label: 'Balance',
          key: 'total',
        },
      ],
    };
  }

  componentDidUpdate() {
    if (this.props.fromDate !== this.state.fromDate) {
      this.setState({ fromDate: this.props.fromDate }, () => {
        this.componentWillMount();
      });
    }

    if (this.props.toDate !== this.state.toDate) {
      this.setState({ toDate: this.props.toDate }, () => {
        this.componentWillMount();
      });
    }
  }

  componentWillMount() {
    let companyId = this.props.commons.selectedCompany._id;
    document.title = `${
      this.props.commons.selectedCompany.name
    }_Creditor Aging_${moment(this.state.toDate).format('YYYYMMDD')}`;

    this.setState({ loading: true });
    let suppliers = [];
    let receipts = [];
    let paymentsvouchermasters = [];

    client
      .authenticate()
      .then(() => {
        return client.service('jmbs').find({
          query: {
            propertyId: companyId,
          },
        });
      })
      .then((res) => {
        this.setState({
          jmbsName: res.data[0].name,
          jmbsNo: res.data[0].registrationNo,
        });
        return client.service('suppliers').find({
          query: {
            propertyId: companyId,
            $sort: { name: 1 },
          },
        });
      })
      .then((res1) => {
        this.setState({ suppliers: res1.data });
        return axios.get(`${client.io.io.uri}AgingByMonth`, {
          params: {
            propertyId: companyId,
            toDate: this.state.toDate,
            creditor: true,
          },
          headers: {
            Authorization: client.settings.storage.storage['feathers-jwt'],
          },
        });
      })
      .then((res2) => {
        let toDate = this.state.toDate;
        let toDateInt = parseInt(toDate.slice(0, 6));

        let newCreditorAging = _.map(res2.data, function(a) {
          let totalDebits = _.sumBy(a.month, 'debitAmount');
          let totalCredits = _.sumBy(a.month, 'creditAmount');
          let fourthMonth = 0;
          _.map(a.month, function(b) {
            if (b.yearMonth === toDateInt) {
              a.firstMonth = b.balance;
            } else if (b.yearMonth === toDateInt - 1) {
              a.secondMonth = b.balance;
            } else if (b.yearMonth === toDateInt - 2) {
              a.thirdMonth = b.balance;
            } else {
              fourthMonth += b.balance;
            }

            return b;
          });
          a.fourthMonth = fourthMonth;
          a.totalAmount = totalCredits;
          a.totalPaid = totalDebits;

          return a;
        });
        var suppliers = _.map(this.state.suppliers, function(item) {
          item.firstMonth = _.get(item, ['firstMonth'])
            ? _.get(item, ['firstMonth'])
            : 0;
          item.secondMonth = _.get(item, ['secondMonth'])
            ? _.get(item, ['secondMonth'])
            : 0;
          item.thirdMonth = _.get(item, ['thirdMonth'])
            ? _.get(item, ['thirdMonth'])
            : 0;
          item.fourthMonth = _.get(item, ['fourthMonth'])
            ? _.get(item, ['fourthMonth'])
            : 0;
          item.totalAmount = item.totalAmount ? item.totalAmount : 0;
          item.totalPaid = item.totalPaid ? item.totalPaid : 0;
          item.total = item.totalAmount
            ? item.totalAmount
            : 0 - item.totalPaid
            ? item.totalPaid
            : 0;
          return _.merge(
            item,
            _.find(newCreditorAging, { ledgerAccountId: item.ledgerAccountId })
          );
        });

        let totalBalance = 0;

        var totalinvoice = _.sumBy(suppliers, 'totalAmount');
        var totalpay = _.sumBy(suppliers, 'totalPaid');

        totalBalance = parseFloat(
          (totalinvoice ? totalinvoice : 0) - (totalpay ? totalpay : 0)
        ).toFixed(2);

        this.setState({
          suppliers: suppliers,
          totalBalance: totalBalance,
          loading: false,
        });

        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    //render final data
    const { jmbsName, jmbsNo, totalBalance } = this.state;
    const { fromDate, toDate } = this.state;
    var { fromDateD, toDateD, excelHeader, suppliers } = this.state;

    fromDateD = moment(new Date(this.state.fromDate)).format('Do MMM YYYY');
    toDateD = moment(this.state.toDate).format('Do MMM YYYY');

    const columns = [
      {
        title: 'Creditor',
        dataIndex: 'name',
        key: 'name',
        width: '20%',
      },
      {
        title: '0-30 days',
        dataIndex: 'firstMonth',
        // key: 'Owners',
        width: '10%',
        align: 'right',
        render: (text, record) => {
          if (text) {
            return <span>{text.toFixed(2)}</span>;
          } else {
            return <span>0.00</span>;
          }
        },
      },
      {
        title: '31-90 days',
        dataIndex: 'secondMonth',
        // key: 'Owners',
        width: '10%',
        align: 'right',
        render: (text, record) => {
          if (text) {
            return <span>{text.toFixed(2)}</span>;
          } else {
            return <span>0.00</span>;
          }
        },
      },
      {
        title: '91-180 days',
        dataIndex: 'thirdMonth',
        // key: 'Owners',
        width: '10%',
        align: 'right',
        render: (text, record) => {
          if (text) {
            return <span>{text.toFixed(2)}</span>;
          } else {
            return <span>0.00</span>;
          }
        },
      },
      {
        title: '> 180 days',
        dataIndex: 'fourthMonth',
        // key: 'Owners',
        width: '10%',
        align: 'right',
        render: (text, record) => {
          if (text) {
            return <span>{text.toFixed(2)}</span>;
          } else {
            return <span>0.00</span>;
          }
        },
      },
      {
        title: <span style={{ float: 'right' }}>Total Invoice Amount</span>,
        dataIndex: 'totalAmount',
        key: 'totalinvoice',
        width: '10%',
        align: 'right',
        render: (text, record) => (
          <span style={{ float: 'right' }}>{formatCurr(text)}</span>
        ),
      },

      {
        title: <span style={{ float: 'right' }}>Total Payment Amount</span>,
        dataIndex: 'totalPaid',
        key: 'totalpay',
        align: 'right',
        width: '10%',
        render: (text, record) => (
          <span style={{ float: 'right' }}>{formatCurr(text)}</span>
        ),
      },

      {
        title: <span style={{ float: 'right' }}>Balance</span>,
        dataIndex: 'total',
        key: 'total',
        align: 'right',
        width: '10%',
        render: (text, record) => (
          <span style={{ float: 'right' }}>
            {formatCurr(
              (record.totalAmount ? record.totalAmount : 0) -
                (record.totalPaid ? record.totalPaid : 0)
            )}
          </span>
        ),
      },
    ];

    return (
      <Card
        title={
          <div>
            <span style={{ fontSize: 20 }}>{jmbsName}</span>
            <br></br>
            <span>({jmbsNo})</span>
            <br></br>
            <span>Creditor Aging</span>
            <span className='alignRight'>As at selected date {toDateD}</span>
          </div>
        }
        extra={[
          !_.isEmpty(suppliers) ? (
            <CSVLink
              headers={excelHeader}
              data={suppliers}
              filename={`${
                this.props.commons.selectedCompany.name
              }_Creditor Aging_${moment(this.state.toDate).format('YYYYMMDD')}`}
            >
              <Button type='primary'>Export</Button>
            </CSVLink>
          ) : null,
        ]}
      >
        <Spin spinning={this.state.loading}>
          <Table
            dataSource={this.state.suppliers}
            columns={columns}
            rowKey='_id'
            pagination={false}
            footer={() => (
              <div>
                <span>Total</span>
                <span style={{ float: 'right' }}>
                  {totalBalance ? totalBalance : 0}
                </span>
              </div>
            )}
          />
        </Spin>
      </Card>
    );
  }
}

function mapStateToProps(state) {
  return {
    creditorAgingReports: state.creditorAgingReports,
    commons: state.commons,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchCreditorAgingReports: fetchCreditorAgingReports,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(CreditorAgingPrint));
