import React, { Component, useEffect } from 'react';
import { Alert,Modal, Upload,Popconfirm, message, Divider,  Table, Form, Icon, Input, Button, Select, Card, Row, Col, Pagination,Drawer,Spin,DatePicker } from 'antd';
import client from '../../feathers';
import requireAuth  from "../requireAuth";
import Layout  from "../layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateMode } from '../../actions/actions-accounts';
import { Route, Link } from 'react-router-dom';
import FormSingle  from "../../components/ledgers/form-single";
import FormMulti  from "../../components/ledgers/form-multi";
import axios from 'axios';
import moment from 'moment';
import ReactToPrint from "react-to-print";
import { css, cx } from 'emotion'
//---------------------------------------
const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset:4 },
};

const _ = require('lodash');
const text = 'Are you sure to delete this item?';



class ComponentToPrint extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ledgerTrx:[],
      visibleSingle:false,
      totalPagination:1,
      CurrentPagination:1,
      newDrawerVisible:false, //add drawer function
      dbalance:0.00,
      cbalance:0.00,
      accountDropdowns:[],
      descriptionDropdown:[],
      searchGLReference:'',
      searchAccount:'',
      searchTrxDate:'',
      searchTrxDate2:'',
      loading:false,

    }
    this._searchDescription = this._searchDescription.bind(this);
  }

  componentDidMount(){
    client.service('ledger-transactions').on('created', (data) => {
      if(data.companyId===sessionStorage.getItem('companyId')){
        this.componentWillMount();
      }
    })

    client.service('ledger-transactions').on('removed', (data) => {
        this.componentWillMount();
    })

    client.service('ledger-transactions').on('updated', (data) => {
      if(data.companyId===sessionStorage.getItem('companyId')){
        this.componentWillMount();
      }
    })

    client.service('ledger-transactions').on('patched', (data) => {
      if(data.companyId===sessionStorage.getItem('companyId')){
        this.componentWillMount();
      }
    })
}

  componentWillMount(){


this.setState({loading:true})
    client.authenticate()
    .then((res)=>{
        return client.service('ledger-transactions').find({
          query: {
            $populate:'ledgerAccountId',
            $limit:20,
            companyId: sessionStorage.getItem('companyId'),
            companyType:{
              $in:['PROPERTY']
            },
            trancode:'2500',
            $sort: {
              referenceId: -1,
            }
           }
        })
    })
    .then((res)=>{

      this.setState({
        ledgerTrx:res.data,
        totalPagination:res.total,
        loading:false
      });
    })
    .catch((err)=>{
      this.setState({
        loading:false
      });
        console.log(err);
    });

  //  this.getAccountBalance();
    this.getAccountList();

  }

  // getAccountBalance(){
  //   client.authenticate()
  //   .then((res)=>{
  //       return axios.get(`${client.io.io.uri}ledger-account-trx-balance`,{
  //         params:{
  //           companyId:sessionStorage.getItem('companyId'),
  //           companyType:'PROPERTY',
  //         },
  //         headers: {
  //           'Authorization': client.settings.storage.storage['feathers-jwt']
  //         }
  //       })
  //   })
  //   .then((res)=>{
  //     this.setState({dbalance:this.formatAmt(res.data.data[0]?res.data.data[0].dbalance:0)});
  //     this.setState({cbalance:this.formatAmt(res.data.data[0]?res.data.data[0].cbalance:0)});
  //   })
  //   .catch((err)=>{
  //     console.log(err);
  //   })
  // }

  getAccountList(){
    client.authenticate()
    .then((res)=>{

        return client.service('ledger-accounts').find({
          query: {
            companyId:sessionStorage.getItem('companyId'),
            //accountNo: {$nin:['3000'] },
             $sort: {
               accountName: 1,
               createdAt: -1
             }
           }
        })
    })
    .then((res)=>{
      this.setState({
        accountDropdowns:res.data,
      })
    })
    .catch((err)=>{
        console.log(err);
        this.setState({
          accountDropdowns:[]
        })
    })
  }

  formatAmt(amt) {
    let integer = parseFloat(amt);
    return integer>0?integer.toFixed(2):0.00;
  }



//Search Description
  _searchDescription(e){
    this.setState({
        searchName:e.target.value,
        searchObj:_.merge(this.state.searchObj,e.target.value?{description:e.target.value}:{description:'.'})
      },
    ()=>{
      typingTimeout: setTimeout(()=>{
        this._getData(this.state.skip)
      }, 500)
    })
  }

  componentDidUpdate(){
  }

  handlePageChange = (page) => {
    this.setState({
      CurrentPagination: page,
    });
    this.props.form.validateFields((err, values) => {

    //  let sort = values.sorting==='Creation'?{referenceId:-1,createdAt:-1}:{referenceId:-1,trxDate:-1};
      let sort = {referenceId:-1};

      client.authenticate()
      .then((res)=>{

          return client.service('ledger-transactions').find({
            query: {
              ...values,
              companyId:sessionStorage.getItem('companyId'),
              companyType:{
                $in:['PROPERTY']
              },
              trancode:'2500',
              $limit:20,
              $skip:(page-1)*20,
              $populate:'ledgerAccountId',
              $sort: sort,

            }
          })
      })
      .then((res)=>{

        this.setState({
          totalPagination:res.total,
          ledgerTrx:res.data
        });
      })
      .catch((err)=>{
          console.log(err);
      })
    });
  }

  handleConfirmBlur = (e) => {

  }

  handleSubmit = (e) => {
     e.preventDefault();
  }

  showSingle = () => {
    this.setState({
      visibleSingle: true,
    });


  }

  convertDate(date){
    let tempDate = new Date(date);
    let d = tempDate.getDate();
    let m = tempDate.getMonth()+1
    let y = tempDate.getFullYear();
    return d+'/'+m+'/'+y;
  }


  //Drawer function
  showDrawer = () => {
  this.setState({
    visible: true,
  });
};

onClose = () => {
  this.setState({
    visible: false,
  });
};

//Handle Search
  handleSearch = (e) => {

    try{e.preventDefault();}
    catch(err){
      //console.log(err);
    }

    this.props.form.validateFields((err, values) => {


      var sort = values.sorting==='Creation'?{referenceId:-1,createdAt:-1}:{referenceId:-1,trxDate:-1};
      let searchGLReference        = this.state.searchGLReference===''?'':{referenceId:this.state.searchGLReference};
      let searchAccount        = this.state.searchAccount===''?'':{ledgerAccountId:this.state.searchAccount};
    //  let searchTrxDate        = this.state.searchTrxDate===''?'':{trxDate:{$gte:this.state.searchTrxDate}};
      let searchTrxDate

      if (this.state.searchTrxDate!=='')
      {
      //  searchTrxDate = {trxDate:{$gte:this.state.searchTrxDate}}
      //  searchTrxDate = {trxDate:{gte:moment(new Date(this.state.searchTrxDate)).format("YYYY-MM-DD")}}
            let date = parseInt(this.state.searchTrxDate.replace(/-/g,''));
          searchTrxDate = {trxDateInt:{$eq:date}}
      };

      // if (this.state.searchTrxDate2!=='')
      // {
      //   searchTrxDate = {trxDate:{$lte:moment(new Date(this.state.searchTrxDate2)).format("YYYY-MM-DD")}}
      // };
      //
      // if (this.state.searchTrxDate!=='' && this.state.searchTrxDate2!=='')
      // {
      //   searchTrxDate = {trxDate:{$gte:moment(new Date(this.state.searchTrxDate)).format("YYYY-MM-DD"),
      //                             $lte:moment(new Date(this.state.searchTrxDate2)).format("YYYY-MM-DD")
      //                   }}
      // };

      let collection              = {...searchGLReference,...searchAccount,...searchTrxDate};

this.setState({loading:true})
      client.authenticate()
      .then((res)=>{

          return client.service('ledger-transactions').find({
            query: {
               //...values,
               ...collection,
               $populate:'ledgerAccountId',
               $limit:20,
               companyId: sessionStorage.getItem('companyId'),
               companyType:{
                 $in:['PROPERTY']
               },
               trancode:'2500',
               $sort: sort,
               orRegex:{...searchGLReference},
             }
          })
      })
      .then((res)=>{

        this.setState({
          ledgerTrx:res.data,
          totalPagination:res.total,
          loading:false
        });
      })
      .catch((err)=>{
          console.log(err);
      });

    });
  }


//handleSearchAccount
handleSearchAccount = (value) => {

  this.setState({fetching:true});

  this.setState({
  typingTimeout: setTimeout(()=>{

  client.authenticate()
  .then((res)=>{
      return client.service('ledger-accounts').find({
        query: {
          companyId:sessionStorage.getItem('companyId'),
           $sort: {
             description: 1
          },
          orRegex:{description:value},
         }
      })
  })
  .then((res)=>{

    this.setState({
      accountDropdowns:res.data,
      fetching:false,
    });
  })
  .catch((err)=>{
    this.setState({fetching:false});
    console.log(err);
  });
}, 500)
})

}

//handle auto search Description
  handleSearchDescription = (value) => {

  this.setState({fetching:true});
  client.authenticate()
  .then((res)=>{
      return client.service('ledger-transactions').find({
        query: {
          companyId:sessionStorage.getItem('companyId'),
          orRegex:{
           description:value
         },
          trancode:'2500',
           $sort: {
             createdAt: -1
          },
         }
      })
  })
  .then((res)=>{

    this.setState({
      descriptionDropdown:res.data,
      fetching:false,
    });
  })
  .catch((err)=>{
    this.setState({fetching:false});
    console.log(err);
  });
}


// Filtering
 _searchGLReferenceChange = (e) => {
  this.state.searchGLReference = e.target.value;
  this.handleSearch();
}

_searchAccountChange = (e) => {
 this.state.searchAccount = e;
 this.handleSearch();
}

_searchTrxDateChange = (e) => {


 this.state.searchTrxDate = moment(new Date(e)).format("YYYY-MM-DD");
 this.state.searchTrxDate2 = e

 this.handleSearch();

}

_searchTrxDateChange2 = (e) => {
 this.state.searchTrxDate2 = e.target.value;
 this.handleSearch();
}
//RenderSearching
handleDelete(_id) {
   // client.service('ledger-transactions').remove(_id)
   // .then((res)=>{
   //   message.success('Deleted');
   // })
   // .catch((err)=>{
   //   alert(err);
   // })

   axios.post(`${client.io.io.uri}removeTrx`,
       {
         _id: _id,
         companyId  : sessionStorage.getItem('companyId'),
         trxType : 'JE',
       },
       { headers: {'Authorization': client.settings.storage.storage['feathers-jwt']}}
   )
   .then((res)=>{
      message.success('Deleted');
   })
   .catch((err)=>{
     console.log(err);
   })
}

renderConfirmDeleteBtn(_id){
  return (
    <Popconfirm placement="topLeft" title={text} onConfirm={()=>this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
      <Button type="danger">Delete</Button>
    </Popconfirm>
  )
}

  renderSearching(){
    const { getFieldDecorator } = this.props.form;
    return (
      <Form
        className="ant-advanced-search-form"
        onSubmit={this.handleSearch}
      >
        <Row gutter={24} style={{ display: 'block' }}>

        <Col span={6}>
          <Form.Item label='Description'>
            {getFieldDecorator(`description`, {
              rules: [{
                required: false,
                message: 'Select Description!',
              }],
            })(
              <Select
                showSearch
                // value={value}
                placeholder="Select Units"
                notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
                filterOption={false}
                onSearch={this.handleSearchDescription}
                // onChange={this.handleSearchPropertyunit}
                style={{ width: '100%' }}
              >
                {this.state.descriptionDropdown.map(d => <Option key={d.description}>{d.description}</Option>)}
              </Select>
            )}
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label='Transaction Date'>
            {getFieldDecorator(`trxDate`, {
              rules: [{
                required: false,
                message: 'Select Transaction Date!',
              }],
            })(
<Input type='Date'/>
            )}
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label='Account'>
            {getFieldDecorator(`ledgerAccountId`, {
              rules: [{
                required: false,
                message: 'Input Account!',
              }],
            })(

              <Select
                showSearch
                placeholder="Select Account"
                notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
                filterOption={false}
                onSearch={this.handleSearchAccount}
                style={{ width: '100%' }}
              >
                {this.state.accountDropdowns.map(d => <Option key={d._id}>{d.accountName}</Option>)}
              </Select>

            )}
          </Form.Item>
        </Col>



          <Col span={6}>
            <Form.Item label='Sort By'>
              {getFieldDecorator(`sorting`, {
                rules: [{
                  required: false,
                  message: 'Select Sorting!',
                }],
              })(
                <Select>
                  <Option value='Creation'>
                    Latest Creation
                  </Option>
                  <Option value='Transaction'>
                    Latest Transaction
                  </Option>
                </Select>
              )}
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label='GL Reference'>
              {getFieldDecorator(`referenceId`, {
                rules: [{
                  required: false,
                  message: 'Select Sorting!',
                }],
              })(

<Input/>

              )}
            </Form.Item>
          </Col>


          <Button key='searchdata' type="primary" htmlType="submit" style={{float:'right'}} >Search</Button>
        </Row>

      </Form>
    )
  }

  render() {
    const { visible, onCancel } = this.props;

    const { getFieldDecorator } = this.props.form;
    /*
    ledgerAccountId : { type: String, required: true,ref:'ledgerAccounts' },
    transactionId   : { type: String,ref:'transactions' },
    description     : { type: String, required: true },
    remark          :{ type: String },
    creditAmount    : { type: Number},
    debitAmount     : { type: Number},
    currency        : { type: String},
    trxDate         : { type: String, required: true }
    */

    const columns = [

      {
        title:
        (
          <Row gutter={8}>
           <Row>
           <Col span={24}>Transaction Date</Col></Row>
           <Row><Col span={24}>

           <DatePicker className ='no-print' value={this.state.searchTrxDate2} onChange={this._searchTrxDateChange} />
           </Col>
           {/*
           <Col span={11} offset={1}>
           <Input type='Date' value={this.state.searchTrxDate2} onChange={this._searchTrxDateChange2}/>
           </Col>
           */}
           </Row>

        </Row>

        ),
        dataIndex: 'trxDate',
        key: 'trxDate',
        width: '11%',
        render: (text, record) => (
          <span>{this.convertDate(record.trxDate)}</span>
        ),
      },
    {
      title: (
        <Row gutter={8}>
         <Row><Col span={24}>GL Reference</Col></Row>
         <Row><Col span={24}>
          <Input className ='no-print' value={this.state.searchGLReference}
          onChange={this._searchGLReferenceChange}/>
         </Col></Row>
      </Row>
      ),
      dataIndex: 'referenceId',
      key: 'referenceId',
      width: '15%',
    },

      {
        title:
        (
            <Row gutter={8}>
            <Row><Col span={24}>Account</Col></Row>
            <Row><Col span={24}>
              <Select
                className ='no-print'
                 showSearch
                 placeholder="Select Account"
                 notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
                 filterOption={false}
                 onSearch={this.handleSearchAccount}
                 onChange={this._searchAccountChange}
                 style={{ width: '100%' }}
                 value={this.state.searchAccount}
                >
          {this.state.accountDropdowns.map(d => <Option value ={d._id} key={d._id}>{d.accountName}</Option>)}
              </Select>
            </Col></Row>
            </Row>
        )
        ,
        dataIndex: 'ledgerAccountId.accountName',
        key: 'ledgerAccountId.accountName',
        width: '20%',
      },


      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        width: '15%',
      },
      {
        title: (
          <Row gutter={8}>
            <Row><Col span={24}>Remark</Col></Row>
            <Row><Col span={24} style={{color:'green'}} ></Col></Row>
          </Row>
        ),
        dataIndex: 'remark',
        key: 'remark',
        width: '21%',
      },
      {
        title: (
          <Row gutter={8}>
            <Row><Col span={24}>Debit Amt</Col></Row>
            {/* <Row><Col span={24} style={{color:'green'}} >{this.state.dbalance}</Col></Row> */}
          </Row>
        ),
        // title: 'Debit Amount',
        dataIndex: 'debitAmount',
        key: 'debitAmount',
        width: '9%',
        render: (text, record) => (
          <span style={{color:'green'}}>{record.debitAmount?record.debitAmount.toFixed(2):''}</span>
        ),
      },
      {
        title: (
          <Row gutter={8}>
            <Row><Col span={24}>Credit Amt</Col></Row>
          {/*  <Row><Col span={24} style={{color:'red'}} >{this.state.cbalance}</Col></Row> */}
          </Row>
        ),
        // title: 'Credit Amount',
        dataIndex: 'creditAmount',
        key: 'creditAmount',
        width: '9%',
        render: (text, record) => (
          <span style={{color:'red'}} >{record.creditAmount?record.creditAmount.toFixed(2):''}</span>
        ),
      },

      {
        title: (<div className ='no-print'>Action</div>),
        key: 'action',
        width: '6%',
        render: (text, record) => (
          <span className ='no-print'>
                    {this.renderConfirmDeleteBtn(record._id)}
          </span>
        ),
      }
    ];

    return(

        <Spin key='spinning' spinning={this.state.loading}>
          <Card
            key='journalentry'
            title='Journal Entry'
             // extra={[<FormMulti />]}
             extra={[<Button className ='no-print' key='newTrx' type="primary" onClick={this.showDrawer}>New</Button>]}
          >

            <Drawer
              title="Single / Multiple GL Entry"
              width={720}
              onClose={this.onClose}
              visible={this.state.visible}
              style={{
                overflow: 'auto',
                height: 'calc(100% - 108px)',
                paddingBottom: '108px',
              }}
            >

            <FormSingle/>
            </Drawer>

          <Row>

            <Col span={24}>
            {/*  {this.renderSearching()}   */}
              <Table

                dataSource={this.state.ledgerTrx}
                columns={columns}
                rowKey="_id"
                pagination={{
                  defaultCurrent:1,
                  total:this.state.totalPagination,
                  onChange:this.handlePageChange,
                  pageSize:20,
                  size:'small'
                }}
              />
            </Col>
            <Col span={1}>
            </Col>
            <Col span={7}>


            </Col>
          </Row>

          </Card>
          </Spin>



    )
  }
}

class LedgerIndexMainTrx extends React.Component {
  render() {
return(
  <Layout>
  <Row>
    <Col style={{ marginBottom: 10,float: 'right'}}>
    <ReactToPrint
        trigger={() => <Button type='primary'>PRINT</Button>}
        content={() => this.componentRef}
    />
    </Col>
    </Row>
    <ComponentToPrint ref={el => (this.componentRef = el)} form={this.props.form} id={this.props.match.params.id} mode='Edit' />
  </Layout>
    )
  }
}

function mapStateToProps(state) {
  return {
    //accounts: state.accounts
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode,
//    fetchProperties: fetchProperties
    }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(LedgerIndexMainTrx)));
