import React, { Component } from 'react';
import { DatePicker, Table, Input, Button, Popconfirm, Form, InputNumber, Select, Col, Row, Card, Spin } from 'antd';
import { Route, Link } from 'react-router-dom';

import client from '../../feathers';
import requireAuth from "../requireAuth";
import Layout from "../layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PaymentFormItems from "./form-payment-items";
import {
  fetchInvoiceitems,
  pushInvoiceitems,
  removeInvoiceitems,
  updateMode,
  updateActiveInvoiceitems,
  updateInvoiceitems
} from '../../actions/actions-invoiceitems';
import '../../App.css';
import moment from 'moment';

const _ = require('lodash');
const text = 'Are you sure to delete this item?';
const FormItem = Form.Item;
const EditableContext = React.createContext();
const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);
const EditableFormRow = Form.create()(EditableRow);
const Option = Select.Option;
const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const config = {
  initialValue: moment(),
  rules: [{
    type: 'object',
    required: true,
    message: 'Please select date!'
  }],
};

class EditableCell extends React.Component {
  state = {
    editing: false,
    charges: []
  }

  componentWillMount() {
    // console.log(this.props)
    let companyId = _.get(this.props, ['companyId'])

    client.authenticate()
      .then((res) => {

        return client.service('charges').find({
          query: {
            propertyId: companyId,
          }
        })
      })
      .then((res) => {

        this.setState({
          charges: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });

  }

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  }

  save = () => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {

      if (error) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  }

  getInput = () => {
    if (this.props.inputType === 'number') {
      return <InputNumber
        ref={node => (this.input = node)}
        onPressEnter={this.save}
        onBlur={this.save}
      />;
    } else if (this.props.inputType === 'month') {//monthNames:['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December','All']
      return <Select
        ref={node => (this.input = node)}
        onPressEnter={this.save}
        onBlur={this.save}
        placeholder="Please Month"
        style={{ width: '100%' }}
      >
        <Option value="1">1</Option>
        <Option value="2">2</Option>
        <Option value="3">3</Option>
        <Option value="4">4</Option>
        <Option value="5">5</Option>
        <Option value="6">6</Option>
        <Option value="7">7</Option>
        <Option value="8">8</Option>
        <Option value="9">9</Option>
        <Option value="10">10</Option>
        <Option value="11">11</Option>
        <Option value="12">12</Option>
      </Select>
    } else if (this.props.inputType === 'chargeId') {
      return <Select
        ref={node => (this.input = node)}
        onPressEnter={this.save}
        onBlur={this.save}
        placeholder="Select Fee"
        style={{ width: '100%' }}
      >
        {this.state.charges.map(dropdown => (
          <Option value={dropdown.code}>
            {dropdown.code}
          </Option>
        ))}
      </Select>
    } else {
      return <Input
        ref={node => (this.input = node)}
        onPressEnter={this.save}
        onBlur={this.save}
      />;
    }
  };

  render() {
    const { editing } = this.state;
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      ...restProps
    } = this.props;


    return (
      <td ref={node => (this.cell = node)} {...restProps}>
        {editable ? (
          <EditableContext.Consumer>
            {(form) => {
              this.form = form;
              return (
                editing ? (
                  <FormItem style={{ margin: 0 }}>
                    {form.getFieldDecorator(dataIndex, {
                      rules: [{
                        required: true,
                        message: `${title} is required.`,
                      }],
                      initialValue: record[dataIndex],
                    })(
                      this.getInput()
                      // <Input
                      //   ref={node => (this.input = node)}
                      //   onPressEnter={this.save}
                      //   onBlur={this.save}
                      // />
                    )}
                  </FormItem>
                ) : (
                  <div
                    className="editable-cell-value-wrap"
                    style={{ paddingRight: 24 }}
                    onClick={this.toggleEdit}
                  >
                    {restProps.children}
                  </div>
                )
              );
            }}
          </EditableContext.Consumer>
        ) : restProps.children}
      </td>
    );
  }
}


class IndexKnockOffByInvoice extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        title: 'Doc Id',
        dataIndex: 'docId',
        key: 'docId',
        width: '15%',
      },
      {
        title: 'Description',
        dataIndex: 'description',
        width: '15%',
        key: 'description',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        width: '10%',
        key: '',
      },
      {
        title: 'Due Date',
        dataIndex: 'dueDate',
        width: '15%',
        key: 'dueDate',
        render: (text, record) => (
          <span>
            {record.dueDate ? this.convertDate(record.dueDate) : ''}
          </span>
        ),
      },
      {
        title: 'Charge Amount',
        dataIndex: 'appliedAmount',
        width: '15%',
        key: 'appliedAmount',
        render: (text, record) => (
          <span style={{ float: 'right' }}>
            {this.formatAmt(record.appliedAmount)}
          </span>
        ),
      },
      {
        title: 'Amount Paid',
        dataIndex: 'knockOffBalance',
        width: '15%',
        key: 'knockOffBalance',
        //editable: true,
        render: (text, record) => (
          <span style={{ float: 'right' }}>
            {this.formatAmt(record.knockOffBalance)}
          </span>
        ),
      },
      {
        title: 'KnockOff Amount',
        dataIndex: 'knockOffAmount',
        width: '15%',
        key: 'knockOffAmount',
        editable: true,
        render: (text, record) => (
          <span style={{ float: 'right' }}>
            {this.formatAmt(record.knockOffAmount)}
          </span>
        ),
      }
    ];

    this.state = {
      prepaymentTotalAmount: 0,
      itemTotalAmount: 0,
      selectedAmount: 0,
      totalAmount: 0,
      propertyUnit: {},
      loading: false,
      dataSource: [],
      selectedRowKeys: [],
      count: 0,
      charges: [],
      selectedRows: [],
      paymentDate: moment(new Date()).format("YYYY-MM-DD"),
    }

  }

  componentDidUpdate() {

    if (this.state.totalAmount !== this.props.form.getFieldValue('payAmount')) {
      this.setState({ payAmount: this.state.totalAmount });
      this.props.form.setFieldsValue({ payAmount: this.state.totalAmount });
    }
  }

  componentWillMount() {


    this.setState({ loading: true });
    let companyId = this.props.commons.selectedCompany._id
    console.log(companyId)
    client.authenticate()
      .then((res) => {

        return client.service('invoice-items').find({
          query: {
            $populate: 'propertyId invoiceId',
            propertyId: companyId,
            invoiceId: this.props.match.params.id,
            status: { $in: ['ISSUED', 'PARTIAL'] },
            $sort: {
              effectiveDate: 1,

            }
          }
        })
      })
      .then((res) => {
        this.setState({ dataSource: res.data });
        this.checkedAllCheckedBox();
        // this.setState({loading:false});
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  }

  checkedAllCheckedBox() {

    let selectedRowKeys = [];
    let selectedRows = [];
    let newData = [];
    let totalSelectedAmt = 0;
    let totalKnockOffAmt = 0;
    for (let i = 0; i < this.state.dataSource.length; i++) {

      this.state.dataSource[i].knockOffAmount = this.state.dataSource[i].appliedAmount - this.state.dataSource[i].knockOffBalance;
      newData.push(this.state.dataSource[i]);
      selectedRows.push(this.state.dataSource[i]);
      selectedRowKeys.push(this.state.dataSource[i]._id);
      totalSelectedAmt += this.state.dataSource[i].knockOffAmount;
      totalKnockOffAmt += this.state.dataSource[i].knockOffAmount;

    }
    setTimeout(() => {
      this.setState({
        selectedRowKeys: selectedRowKeys,
        selectedRows: selectedRows,
        dataSource: newData,
        selectedAmount: totalSelectedAmt,
        itemTotalAmount: totalSelectedAmt,
        totalAmount: parseFloat(totalSelectedAmt).toFixed(2),
        loading: false,
      });
    }, 1000);

  }

  formatAmt(amt) {
    let integer = parseFloat(amt);
    return integer > 0 ? integer.toFixed(2) : 0.00;
  }

  convertDate(date) {
    let tempDate = new Date(date);
    let d = tempDate.getDate();
    let m = tempDate.getMonth() + 1;
    let y = tempDate.getFullYear();
    return d + '/' + m + '/' + y;
  }



  handleSave = (row) => {
    //dataSource
    const newData = [...this.state.dataSource];
    const index = newData.findIndex(item => row._id === item._id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });

    //dataSelectedrow
    const newData2 = [...this.state.selectedRows];
    const index2 = newData2.findIndex(item => row._id === item._id);
    const item2 = newData2[index2];
    newData2.splice(index2, 1, {
      ...item2,
      ...row,
    });

    let amt = 0;
    for (let i = 0; i < newData2.length; i++) {
      amt += parseFloat(newData2[i].knockOffAmount);
    }

    this.setState({
      dataSource: newData,
      selectedRows: newData2,
      totalAmount: amt
    });
  }


  getInputStyle = (dataType) => {
    if (dataType === 'amount') {
      return 'number';
    } else if (dataType === 'month') {
      return 'month';
    } else if (dataType === 'chargeId') {
      return 'chargeId';
    } else {
      return 'text';
    }
  }

  handleChangeTotalAmt = (e) => {
    this.setState({ totalAmount: e });

    let tempValue = 0;
    let tempAddOn = 0;
    let oriAmt = e;
    let dataSource = this.state.dataSource;
    let newData = [];
    let stopper = false;


    for (let i = 0; i < dataSource.length; i++) {

      let isChecked = _.includes(this.state.selectedRowKeys, dataSource[i]._id);
      if (isChecked) {

        if (stopper === true) {
          dataSource[i].knockOffAmount = 0.00;
        } else if (tempValue + (dataSource[i].appliedAmount - dataSource[i].knockOffBalance) <= e) {
          tempValue = dataSource[i].appliedAmount - dataSource[i].knockOffBalance;
          tempAddOn += dataSource[i].appliedAmount - dataSource[i].knockOffBalance;

          if (tempAddOn >= e) {
            dataSource[i].knockOffAmount = parseFloat(oriAmt);
            stopper = true;
          } else {
            dataSource[i].knockOffAmount = tempValue;
            oriAmt -= dataSource[i].appliedAmount - dataSource[i].knockOffBalance;
          }

        } else {
          dataSource[i].knockOffAmount = e - tempValue;
          stopper = true;
        }

        newData.push(dataSource[i]);

      } else {
        dataSource[i].knockOffAmount = 0.00;
        newData.push(dataSource[i]);
      }

    }

    this.setState({ dataSource: newData });
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  _paymentDateChange = (e) => {
    this.setState({
      paymentDate: moment(new Date(e)).format("YYYY-MM-DD")
    });
  }

  render() {
    const { dataSource, selectedRowKeys, loading, selectedRows } = this.state;
    const { getFieldDecorator } = this.props.form;
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };


    const rowSelection = {
      selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {


        let tempAmt = 0;
        let newData = [...this.state.dataSource];
        for (let i = 0; i < selectedRows.length; i++) {
          tempAmt += selectedRows[i].appliedAmount - selectedRows[i].knockOffBalance;
        }

        if (selectedRows.length > 0) {
          //start handle latest selected row
          selectedRows[selectedRows.length - 1].knockOffAmount = selectedRows[selectedRows.length - 1].appliedAmount - selectedRows[selectedRows.length - 1].knockOffBalance;
          const index = newData.findIndex(item => selectedRows[selectedRows.length - 1]._id === item._id);
          const item = newData[index];
          newData.splice(index, 1, {
            ...item,
            ...selectedRows[selectedRows.length - 1],
          });
          //end handle latest selected row
        }

        this.setState({
          selectedRows: selectedRows,
          selectedRowKeys: selectedRowKeys,
          selectedAmount: tempAmt,
          dataSource: newData,
          totalAmount: parseFloat(tempAmt).toFixed(2),
        });

      },
    };

    const hasSelected = selectedRows.length > 0;

    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          companyId: this.props.commons.selectedCompany._id,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });

    return (
      <Layout>
        <Spin spinning={this.state.loading}>
          <Card
            key='maintAction'
            title={'Official Receipt(Deposit)'}
          >

            <Row>
              <Col span='18'>
                {hasSelected ? `Selected ${selectedRows.length} items` : ''}
                <Table
                  rowSelection={rowSelection}
                  components={components}
                  rowClassName={() => 'editable-row'}
                  bordered
                  dataSource={dataSource}
                  columns={columns}
                  pagination={false}
                  scroll={{ y: 340 }}
                  rowKey="_id"
                  key='tables'
                />

              </Col>
              <Col span='6'>
                <Form.Item
                  {...formItemLayout}
                  label="Payment Date"
                >
                  {getFieldDecorator('paymentDate', config)(
                    <DatePicker
                      onChange={this._paymentDateChange}
                    />
                  )}
                </Form.Item>

                <FormItem
                  {...formItemLayout}
                  label="Payment Amt"
                >
                  {getFieldDecorator('payAmount', {
                    rules: [{
                      required: true, message: 'Please input Amount!',
                    }],
                  })(
                    <Input disabled />
                  )}
                </FormItem>
                <PaymentFormItems

                  paymentDate={this.state.paymentDate}
                  invoiceItems={this.state.selectedRows}
                  payAmount={this.state.totalAmount}
                  totalAmount={this.state.totalAmount}
                  propertyunitId={this.state.dataSource[0] ? this.state.dataSource[0].propertyunitId : ''}

                />
              </Col>
            </Row>
          </Card>
        </Spin>
      </Layout>
    )
  }


}


function mapStateToProps(state) {
  return {
    invoiceitems: state.invoiceitems,
    commons: state.commons
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchInvoiceitems: fetchInvoiceitems,
    pushInvoiceitems: pushInvoiceitems,
    removeInvoiceitems: removeInvoiceitems,
    updateActiveInvoiceitems: updateActiveInvoiceitems,
    updateInvoiceitems: updateInvoiceitems,
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(IndexKnockOffByInvoice)));
