export const FETCH_REQUESTS = 'FETCH_REQUESTS';
export const PUSH_REQUESTS = 'PUSH_REQUESTS';
export const REMOVE_REQUESTS = 'REMOVE_REQUESTS';
export const UPDATE_REQUESTS = 'UPDATE_REQUESTS';
export const UPDATE_MODE = 'UPDATE_MODE';
export const UPDATE_ACTIVE_ID = 'UPDATE_ACTIVE_ID';

export function fetchRequests(data){
  return (dispatch) => {
    dispatch({
      type: FETCH_REQUESTS,
      payload:data
    });
  }
}

export function pushRequests(data){
  return (dispatch) => {
    dispatch({
      type: PUSH_REQUESTS,
      payload:data
    });
  }
}

export function removeRequests(data){
  return (dispatch) => {
    dispatch({
      type: REMOVE_REQUESTS,
      payload:data
    });
  }
}

export function updateRequests(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_REQUESTS,
      payload:data
    });
  }
}

export function updateMode(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE,
      payload:data
    });
  }
}

export function updateActiveRequests(mode, id){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_ID,
      mode,
      id
    });
  }
}
