import _ from 'lodash';
import {
    FETCH_BANKTRXS,
    PUSH_BANKTRXS,
    REMOVE_BANKTRXS,


    UPDATE_ACTIVE_ID_BANKTRXS, UPDATE_BANKTRXS,


    UPDATE_BANKTRXS_TYPE, UPDATE_MODE_BANKTRXS
} from '../actions/actions-banktrxs';

const INITIAL_STATE = {
  banktrxs: [],
  mode: 'index',
  activeId: '',
  activeBankTrxs: {},
  bankTrxsType:''
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_BANKTRXS:
      return {
        ...state,
        banktrxs: action.payload,
      }
    case PUSH_BANKTRXS:
      return {
        ...state,
        banktrxs: [action.payload, ...state.banktrxs],
      }
    case REMOVE_BANKTRXS:
      return {
        ...state,
        banktrxs: [...state.banktrxs.filter((item) => item._id !== action.payload._id)]
      }
    case UPDATE_BANKTRXS:
      let index = _.findIndex(state.banktrxs, { '_id': action.payload._id })
      state.banktrxs.splice(index, 1, action.payload)
      return {
        ...state,
        banktrxs: state.banktrxs
      }
    case UPDATE_MODE_BANKTRXS:
      return {
        ...state,
        mode: action.payload,
        activeId: ''
      }
    case UPDATE_ACTIVE_ID_BANKTRXS:
      return {
        ...state,
        mode: action.mode,
        activeId: action.id,
        activeBankTrxs: _.pick(_.find(state.banktrxs, { '_id': action.id }), [
          '_id',
          'companyId',
          'referenceId',
          'trxDate',
          'description',
          'remark',
          'taxAmount',
          'subTotalAmount',
          'totalAmount',
          'totalPay',
          'file',
          'filePrevious',
          'bankAccountId',
          'otherAccountId',
          'updatedBy',
          'createdBy',
          'bankTrxType',
          'trxAmount',
        ])
      }
      case UPDATE_BANKTRXS_TYPE:
        return {
          ...state,
          bankTrxsType: action.bankTrxsType,
        }
    default:
      return state

  }
}
