import _ from 'lodash';
import {
  FETCH_TRXPARAMETERS,
  PUSH_TRXPARAMETERS,
  REMOVE_TRXPARAMETERS,
  UPDATE_TRXPARAMETERS,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID } from '../actions/actions-trxparameters';

const INITIAL_STATE = {
  trxparameters: [],
  mode: 'index',
  activeId: '',
  activeTrxParameters:{}
}


export default function(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_TRXPARAMETERS:
      return {
        ...state,
        trxparameters:action.payload,
      }
    case PUSH_TRXPARAMETERS:
      return {
        ...state,
        trxparameters:[action.payload, ...state.trxparameters],
      }
    case REMOVE_TRXPARAMETERS:
      return {
        ...state,
        trxparameters : [...state.trxparameters.filter( (item) => item._id !== action.payload._id)]
      }
    case UPDATE_TRXPARAMETERS:
      let index =  _.findIndex(state.trxparameters, { '_id': action.payload._id })
      state.trxparameters.splice(index, 1, action.payload)
      return {
        ...state,
        trxparameters : state.trxparameters
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode : action.payload,
        activeId:''
      }
    case UPDATE_ACTIVE_ID:
      return {
        ...state,
        mode : action.mode,
        activeId:action.id,
        activeTrxParameters: _.pick(_.find(state.trxparameters, { '_id': action.id }), ['companyId','trancode', 'tranDesc','referenceformat','referenceseperator','referencedateformat','referenceseperator2','referenceSeqlength','currentDaySeqno','currentMOnthSeqno','currentYearSeqno', '_id'])
      }
    default:
      return state

  }
}
