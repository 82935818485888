import _ from 'lodash';
import {
  UPDATE_VALUEIDN,
  UPDATE_VALUEREMINDER,
  UPDATE_VALUEPROPERTYUNITID,
  REFRESH_PAGE,
  SELECTED_COMPANY,
  SELECTED_ROLE
} from '../actions/actions-commons';

const INITIAL_STATE = {
  commons: ''
}


export default function (state = INITIAL_STATE, action) {
  let mode = '', value = ''

  switch (action.type) {
    case UPDATE_VALUEIDN:
      return {
        mode: action.mode,
        status: action.status,
        debtorAcc: action.debtorAcc,
        propertyunit: action.propertyunit
      }
    case UPDATE_VALUEREMINDER:
      return {
        mode: action.mode,
        filterAmount: action.filterAmount,
      }
    case UPDATE_VALUEPROPERTYUNITID:
      return {
        ...state,
        mode: action.mode,
        propertyUnitId: action.propertyUnitId,
      }
    case REFRESH_PAGE:
      return {
        mode: action.mode,
      }
    case SELECTED_COMPANY:
      return { ...state, selectedCompany: action.selectedCompany }
    case SELECTED_ROLE:
      return { ...state, selectedRole: action.selectedRole }
    default:
      return state

  }
}
