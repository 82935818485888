export const FETCH_FACILITYBOOKINGS = 'FETCH_FACILITYBOOKINGS';
export const PUSH_FACILITYBOOKINGS = 'PUSH_FACILITYBOOKINGS';
export const REMOVE_FACILITYBOOKINGS = 'REMOVE_FACILITYBOOKINGS';
export const UPDATE_FACILITYBOOKINGS = 'UPDATE_FACILITYBOOKINGS';
export const UPDATE_MODE = 'UPDATE_MODE';
export const UPDATE_ACTIVE_ID = 'UPDATE_ACTIVE_ID';

export function fetchFacilitybookings(data) {
  return (dispatch) => {
    dispatch({
      type: FETCH_FACILITYBOOKINGS,
      payload: data
    });
  }
}

export function pushFacilitybookings(data) {
  return (dispatch) => {
    dispatch({
      type: PUSH_FACILITYBOOKINGS,
      payload: data
    });
  }
}

export function removeFacilitybookings(data) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_FACILITYBOOKINGS,
      payload: data
    });
  }
}

export function updateFacilitybookings(data) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_FACILITYBOOKINGS,
      payload: data
    });
  }
}

export function updateMode(data) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE,
      payload: data
    });
  }
}

export function updateActiveFacilitybookings(mode, id, date) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_ID,
      mode,
      id,
      date
    });
  }
}
