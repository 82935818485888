import React, { Component, useEffect } from 'react'

import { Modal, Upload, Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Select, DatePicker, Row, Col, Tooltip, Checkbox, Spin, notification } from 'antd'

import client from '../../feathers'
import requireAuth from "../requireAuth"
import Layout from "../layout"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateMode } from '../../actions/actions-accounttrxs'

import { BUTTON_GREEN } from "../common/button";
import { BUTTON_RED } from "../common/button";
import '../../App.css';
import axios from 'axios';

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
var _ = require('lodash');

const formItemLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 16 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};

const text = 'Are you sure to delete this item?';

const openNotification = (value) => {
  notification.open({
    message: 'Record Added',
    description: 'Reference Id: ' + JSON.stringify(value),
    duration: 0,
  });
};

class FormSingle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,

      accountDropdowns: [],
      transactions: [{ debitAccount: "", debitAmount: 0, creditAccount: "", creditAmount: 0 }],
      counterTrx: 0,
      idx: 0,
      debitAccounts: [],
      creditAccounts: [],
      loading: false,
      loading2: false,
      trxCodeParameter: [],

    }
  }

  componentWillMount() {
    let companyId = this.props.commons.selectedCompany._id

    client.authenticate()
      .then((res) => {

        return client.service('ledger-accounts').find({
          query: {
            companyId: companyId,
            accountNo: { $nin: ['3000'] },
            $sort: {
              description: 1
              //createdAt: -1
            }
          }
        })
      })
      .then((res) => {
        this.setState({
          debitAccounts: res.data,
          creditAccounts: res.data,
        })
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          accountDropdowns: []
        })
      })


    client.authenticate()
      .then((res) => {

        return client.service('trxparameters').find({
          query: {
            companyId: res.propertyId,
            trancode: '2500',
            $sort: {
              tranDesc: 1
            }
          }
        })
      })
      .then((res) => {
        this.setState({
          trxCodeParameter: res.data,
        })
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          trxCodeParameter: []
        })
      })

  }

  componentDidUpdate() {

  }

  handleAddTransaction = (idx) => {

    this.setState({
      transactions: this.state.transactions.concat([{ debitAccount: "", debitAmount: 0 }])
    });
    this.setState({ counterTrx: this.state.counterTrx + 1 });
    //alert(client.io.io.uri);
  };

  handleRemoveDebitTransaction = idx => () => {

    this.setState({
      transactions: this.state.transactions.filter((s, sidx) => idx !== sidx)
    });
    this.setState({ counterTrx: this.state.counterTrx - 1 });

  };

  handleTransactionChange = idx => evt => {

    const newTransactions = this.state.Transactions.map((Transaction, sidx) => {
      if (idx !== sidx) return Transaction;
      return { ...Transaction, debitAccount: evt.target.value };
    });
  };

  handleChangeDebitAccount = (e) => {
    let companyId = this.props.commons.selectedCompany._id
    this.setState({ loading: true });
    if (e.target.checked === true) {
      client.authenticate()
        .then((res) => {

          return client.service('ledger-accounts').find({
            query: {
              companyId: companyId,
              accountNo: { $in: ['3000'] },
              $sort: {
                description: 1,
                // createdAt: -1
              }
            }
          })
        })
        .then((res) => {
          this.setState({
            debitAccounts: res.data,
            loading: false
          })
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            debitAccounts: [],
            loading: false
          })
        })
    } else {
      client.authenticate()
        .then((res) => {

          return client.service('ledger-accounts').find({
            query: {
              companyId: companyId,
              accountNo: { $nin: ['3000'] },
              $sort: {
                createdAt: -1
              }
            }
          })
        })
        .then((res) => {
          this.setState({
            debitAccounts: res.data,
            loading: false
          })
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            debitAccounts: [],
            loading: false
          })
        })
    }
  }

  handleChangeCreditAccount = (e) => {
    let companyId = this.props.commons.selectedCompany._id
    this.setState({ loading: true });
    if (e.target.checked === true) {
      client.authenticate()
        .then((res) => {

          return client.service('ledger-accounts').find({
            query: {
              companyId: companyId,
              accountNo: { $in: ['3000'] },
              $sort: {
                createdAt: -1
              }
            }
          })
        })
        .then((res) => {
          this.setState({
            creditAccounts: res.data,
            loading: false
          })
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            creditAccounts: [],
            loading: false
          })
        })
    } else {
      client.authenticate()
        .then((res) => {

          return client.service('ledger-accounts').find({
            query: {
              companyId: companyId,
              accountNo: { $nin: ['3000'] },
              $sort: {
                createdAt: -1
              }
            }
          })
        })
        .then((res) => {
          this.setState({
            creditAccounts: res.data,
            loading: false
          })
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            creditAccounts: [],
            loading: false
          })
        })
    }
  }

  handleSubmit = (e) => {
    let companyId = this.props.commons.selectedCompany._id
    let entriesObj = []
    this.setState({ loading2: true })
    this.props.form.validateFields((err, values) => {
      if (!err) {

        values.trxDate = new Date(values.trxDate)
        //loop to multiple Entry
        let debitAmtField = '', debitAccField = '', creditAmtField = '', creditAccField = '';
        let debitTotalAmt = 0, creditTotalAmt = 0, wkDebitTotalAmt = 0, wkCreditTotalAmt = 0;

        for (let counterTotal = 0; counterTotal <= this.state.counterTrx; counterTotal++) {
          debitAmtField = "debitAmount" + counterTotal;
          creditAmtField = "creditAmount" + counterTotal;
          wkDebitTotalAmt = parseFloat(values[debitAmtField]) || 0;
          wkCreditTotalAmt = parseFloat(values[creditAmtField]) || 0;
          debitTotalAmt = parseFloat(debitTotalAmt) + parseFloat(wkDebitTotalAmt);
          creditTotalAmt = parseFloat(creditTotalAmt) + parseFloat(wkCreditTotalAmt);

        }
        if (debitTotalAmt !== creditTotalAmt) {
          this.setState({ loading2: false })
          return message.warning('Debit Amount and Credit Amount not Tally.');
        }

        if ((debitTotalAmt <= 0) || (creditTotalAmt <= 0)) {
          this.setState({ loading2: false })
          return message.warning('Debit Amount or Credit Amount can\'t be lesser than 0.');
        }

        if ((typeof values.debitAccount0 === "undefined") || (typeof values.creditAccount0 === "undefined")) {
          this.setState({ loading2: false })
          return message.warning('Please select Debit Account Or Credit Account.');
        }

        //generate credit/debit entries
        for (let counter = 0; counter <= this.state.counterTrx; counter++) {
          debitAmtField = "debitAmount" + counter;
          debitAccField = "debitAccount" + counter;
          creditAmtField = "creditAmount" + counter;
          creditAccField = "creditAccount" + counter;
          // get account Categories
          let crAccCat = _.find(this.state.creditAccounts, { '_id': values[creditAccField] });
          let drAccCat = _.find(this.state.debitAccounts, { '_id': values[debitAccField] });

          //credit session
          if ((typeof values[creditAccField] !== "undefined" && typeof values[creditAmtField] !== "undefined") &&
            (values[creditAccField].length > 0 && values[creditAmtField].length > 0)) {

            entriesObj.push({
              companyId: companyId,
              companyType: 'PROPERTY',
              description: values.description,
              remark: values.remark,
              ledgerAccountId: values[creditAccField],
              ledgerAccountCat: crAccCat.categories,
              creditAmount: values[creditAmtField],
              debitAmount: 0,
              currency: 'MYR',
              trxDate: values.trxDate,
              creditDebit: 'CREDIT',
              eventCode: 'JOURNALENTRY'
            })

          }

          //debit session
          if ((typeof values[debitAccField] !== "undefined" && typeof values[debitAmtField] !== "undefined") &&
            (values[debitAccField].length > 0 && values[debitAmtField].length > 0)) {
            entriesObj.push({
              companyId: companyId,
              companyType: 'PROPERTY',
              description: values.description,
              remark: values.remark,
              ledgerAccountId: values[debitAccField],
              ledgerAccountCat: drAccCat.categories,
              debitAmount: values[debitAmtField],
              creditAmount: 0,
              currency: 'MYR',
              trxDate: values.trxDate,
              creditDebit: 'DEBIT',
              eventCode: 'JOURNALENTRY'
            })
          }
        }

        client.service('journalentries').create({
          trxDate: values.trxDate,
          remark: values.remark,
          companyId: companyId,
          description: values.description,
          amount: debitTotalAmt,
          referenceId: values.glreference,
        }).then((res) => {
          console.log(res, 'hiiiii')

          axios.post(`${client.io.io.uri}ledger-trx-blk-add`,
            {
              'entriesN': entriesObj,
              companyId: companyId,
              companyType: 'PROPERTY',
              trxType: values.trxType,
              glreference: values.glreference,
              journalEntryId: res._id
            },
            { headers: { 'Authorization': client.settings.storage.storage['feathers-jwt'] } }
          )
            .then((res1) => {



              { openNotification(res1.data.GeneratedRef) }

              this.setState({
                loading2: false
              });
              this.props.form.resetFields()
            })
            .catch((err) => {
              this.setState({
                loading2: false
              });
              console.log(err);
            })

        })


        //end debit ,credit session

        this.setState({
          visible: false,
        });
        // alert("Record Added ! ");
      }
      else {
        this.setState({ loading2: false })
      }

    });

  }//end handlesubmit

  render() {
    const { visible, onCancel } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { idx } = 0;

    return ([
      <Spin key='spinning' spinning={this.state.loading2}>
        <Form key='thisform' className="submit-form">


          <Row>
            <Col span={6}>
              <span>Date</span>
              <FormItem key='trxDate'>
                {getFieldDecorator('trxDate', {
                  rules: [{
                    required: true, message: 'Please Select date!',
                  }],
                })(
                  <DatePicker />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col>

              <span>Description</span>
              <FormItem key='description'>
                {getFieldDecorator('description', {
                  rules: [{
                    required: true, message: 'Please input Description!',
                  }],
                })(
                  <TextArea rows={4} />
                )}
              </FormItem>
            </Col>

            <span>Transaction Type</span>
            <FormItem key='trxType'>
              {getFieldDecorator('trxType', {
                rules: [{
                  required: true, message: 'Please select!',
                }],
              })(
                <Select placeholder="Select Type" id="trxTypeDropdown" >
                  {this.state.trxCodeParameter.map(dropdown => (
                    <Option key={dropdown._id} value={dropdown.trancode}>
                      {dropdown.tranDesc}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Row>


          <Row gutter={8}>
            {this.state.transactions.map((transactions, idx) => (
              <div key={'magic' + idx} >
                <Col span={12}>

                  <Checkbox onChange={this.handleChangeDebitAccount}>Show Debtors Acc.?</Checkbox>
                  <br />
                  <span>DEBIT</span>
                  <FormItem key='debitAccount'>
                    {getFieldDecorator('debitAccount' + idx, {
                      rules: [{
                        required: false, message: 'Please Select Debit Account!',
                      }],
                    })(
                      <Select placeholder="Debit Account" >
                        {this.state.debitAccounts.map(dropdown => (
                          <Option key={dropdown._id} value={dropdown._id}>
                            {dropdown.description}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>

                  <FormItem key='debitAmount'>
                    {getFieldDecorator('debitAmount' + idx, {
                      rules: [{
                        required: false, message: 'Please input Amount!',
                      }],
                    })(
                      <Input type="number" />
                    )}
                  </FormItem>
                </Col>

                <Col span={12}>
                  <Checkbox onChange={this.handleChangeCreditAccount}>Show Debtors Acc.?</Checkbox>
                  <br />
                  <span>CREDIT</span>
                  <FormItem key='creditAccount'>
                    {getFieldDecorator('creditAccount' + idx, {
                      rules: [{
                        required: false, message: 'Please Select Credit Account!',
                      }],
                    })(
                      <Select placeholder="creditAccount" >
                        {this.state.creditAccounts.map(dropdown => (
                          <Option key={dropdown._id} value={dropdown._id}>
                            {dropdown.description}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>

                  <FormItem key='creditAmount'>
                    {getFieldDecorator('creditAmount' + idx, {
                      rules: [{
                        required: false, message: 'Please input Credit Amount!',
                      }],
                    })(
                      <Input type="number" />
                    )}
                  </FormItem>

                </Col>

              </div>))}

          </Row>


          <Row style={{ paddingBottom: '10px' }}>

            <Col span={4} >
              <Tooltip key='addentrytt' title="Add Entry">
                <Button key='addentry' className="buttonGreen" onClick={this.handleAddTransaction}>Add Entry </Button>
              </Tooltip>
            </Col>
            <Col span={4}>
              <Tooltip key='minusentrytt' title="Minus Entry">
                <Button key='minusentry' className="buttonRed" type="primary" onClick={this.handleRemoveDebitTransaction(this.state.idx)}> Minus Entry</Button>
              </Tooltip>
            </Col>

          </Row>
          <span>GL Reference</span>

          <FormItem key='glreference'>
            {getFieldDecorator('glreference', {
              rules: [{
                required: true, message: 'Please Input glreference!',
              }],
            })(
              <Input />
            )}
          </FormItem>
          <span>Remark</span>

          <FormItem key='remark'>
            {getFieldDecorator('remark', {
              rules: [{
                required: false, message: 'Please Input Remark!',
              }],
            })(
              <TextArea rows={4} />
            )}
          </FormItem>
          <Button type="primary" onClick={this.handleSubmit} >Submit</Button>

        </Form>
      </Spin>
    ])
  }
}


function mapStateToProps(state) {
  return {
    //visibleSingle: state.visibleSingle
    commons: state.commons
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(FormSingle)));
