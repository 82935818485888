import React, { Component, useEffect } from 'react';
import { Modal, Upload, Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Select, Spin } from 'antd';
import client from '../../feathers';
import requireAuth from "../../components/requireAuth";
import Layout from "../../components/layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateMode } from '../../actions/actions-propertyunits';
import axios from 'axios';

var FormData = require('form-data');
const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};

const text = 'Are you sure to delete this item?';
const children = [];

class PropertyunitsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',
      confirmDirty: false,
      autoCompleteResult: [],
      loading: false
    }
  }

  componentDidUpdate() {
    if (this.props.propertyunits.activeId !== this.state._id) {
      this.setState({
        _id: this.props.propertyunits.activeId
      })

      if (this.props.propertyunits.activePropertyunits) {
        this.props.form.setFieldsValue(
          this.props.propertyunits.activePropertyunits
        )
      }
    }

    if (this.props.propertyunits.mode === 'refresh') {
      this.componentWillMount()
    }

  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }

  componentWillMount() {
  }


  handleSubmit = (e) => {
    e.preventDefault();

    if (this.props.propertyunits.mode === 'new') {

      this.props.form.validateFields((err, values) => {

        var arr = values.unitNo.split(',');

        if (this.props.propertyType === 'LANDED') {
          for (let a = 0; a < arr.length; a++) {
            var fieldValues = {
              propertyId: this.props.propertyId,
              street: values.street,
              unitNo: arr[a],
              size: values.size,
              extraSize: values.extraSize,
            };
            this.setState({ loading: true });
            if (!err) {
              client.service('propertyunits').create({
                ...fieldValues
              })
                .then((res) => {
                  this.props.updateMode('');
                  this.setState({ loading: false });
                })
                .catch((err) => {
                  this.props.updateMode('');
                  alert(err);
                })
            }
          };
        } else {

          let tempNum = 0;
          for (let b = parseInt(values.floor); b <= parseInt(values.toFloor); b++) {

            for (let a = 0; a < arr.length; a++) {
              var fieldValues = {
                propertyId: this.props.propertyId,
                block: values.block,
                floor: parseInt(values.floor) + tempNum,
                unitNo: arr[a],
                size: values.size,
              };
              this.setState({ loading: true });
              if (!err) {
                client.service('propertyunits').create({
                  ...fieldValues
                })
                  .then((res) => {
                    console.log({ res });
                    this.props.updateMode('');
                    this.setState({ loading: false });
                  })
                  .catch((err) => {
                    console.log({ err });
                    this.props.updateMode('');
                    // alert(err);
                    message.error(err.message);
                  })
              };
            };
            tempNum += 1;
            this.props.updateMode('refresh');
          };
        };
      });

    } else {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          client.service('propertyunits').patch(this.props.propertyunits.activeId,
            {
              ...values
            }
          )
            .then((res) => {
              message.success(`Updated`);
              this.props.updateMode('');
              this.setState({ loading: false });
            })
            .catch((err) => {

              this.props.updateMode('');
            })
          return;
        }

      });
    }
  }

  renderFields(type) {

    const { getFieldDecorator } = this.props.form;

    if (type !== 'LANDED') {
      return ([
        <FormItem
          {...formItemLayout}
          label="Block"
        >
          {getFieldDecorator('block', {
            rules: [{
              required: false, message: 'Please input block!',
            }],
          })(
            <Input disabled={this.props.propertyunits.mode === 'edit' ? 'true' : ''} placeholder="A" />
          )}
        </FormItem>
        ,
        <FormItem
          {...formItemLayout}
          label="From Floor"
        >
          {getFieldDecorator('floor', {
            rules: [{
              required: true, message: 'Please input Floor!',
            }],
          })(
            <Input disabled={this.props.propertyunits.mode === 'edit' ? 'true' : ''} placeholder="8" />
          )}
        </FormItem>
        ,
        <FormItem
          {...formItemLayout}
          label="To Floor"
        >
          {getFieldDecorator('toFloor', {
            rules: [{
              required: true, message: 'Please input Floor!',
            }],
          })(
            <Input disabled={this.props.propertyunits.mode === 'edit' ? 'true' : ''} placeholder="8" />
          )}
        </FormItem>
        ,
        <FormItem
          {...formItemLayout}
          label="Unit Number"
        >
          {getFieldDecorator('unitNo', {
            rules: [{
              required: true, message: 'Please input Unit Number!',
            }],
          })(
            <Input disabled={this.props.propertyunits.mode === 'edit' ? 'true' : ''} placeholder="1,2,3,4,5,6" />
          )}
        </FormItem>
        ,
        ,
        <FormItem
          {...formItemLayout}
          label="Size"
        >
          {getFieldDecorator('size', {
            rules: [{
              required: true, message: 'Please input SquareFeet!',
            }],
          })(
            <Input placeholder="Square Feet/Share Unit" />
          )}
        </FormItem>
      ])
    } else {
      return ([
        <FormItem
          {...formItemLayout}
          label="Unit Number"
        >
          {getFieldDecorator('unitNo', {
            rules: [{
              required: true, message: 'Please input Unit Number!',
            }],
          })(
            <Input disabled={this.props.propertyunits.mode === 'edit' ? 'true' : ''} placeholder="1,2,3,4,5,6" />
          )}
        </FormItem>
        ,
        <FormItem
          {...formItemLayout}
          label="Street"
        >
          {getFieldDecorator('street', {
            rules: [{
              required: true, message: 'Please input Street!',
            }],
          })(
            <Input disabled={this.props.propertyunits.mode === 'edit' ? 'true' : ''} />
          )}
        </FormItem>
        ,
        <FormItem
          {...formItemLayout}
          label="Size"
        >
          {getFieldDecorator('size', {
            rules: [{
              required: true, message: 'Please input SquareFeet!',
            }],
          })(
            <Input placeholder="Square Feet/Share Unit" />
          )}
        </FormItem>
        ,
        <FormItem
          {...formItemLayout}
          label="Extra Size"
        >
          {getFieldDecorator('extraSize', {
            rules: [{
              required: false, message: 'Please input Extra Size !',
            }],
          })(
            <Input placeholder="Square Feet/Share Unit" />
          )}
        </FormItem>
      ])
    }
  }


  render() {
    const { visible, onCancel } = this.props;
    const { getFieldDecorator } = this.props.form;


    return (
      <Spin spinning={this.state.loading}>
        <Modal
          visible={
            this.props.propertyunits.mode === 'new' || this.props.propertyunits.mode === 'edit' ? true : false
          }
          title={this.props.propertyunits.mode === 'edit' ? 'Update' : 'Create Unit'}
          okText={this.props.propertyunits.mode === 'edit' ? 'Update' : 'Create'}
          onCancel={() => { this.props.updateMode(''); this.props.form.resetFields() }}
          onOk={this.handleSubmit}
        >
          <Form className="submit-form">

            {this.renderFields(this.props.propertyType)}

          </Form>
        </Modal>
      </Spin>

    )
  }
}


function mapStateToProps(state) {
  return {
    propertyunits: state.propertyunits,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(PropertyunitsForm)));
