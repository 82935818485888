import _ from 'lodash';
import {
  FETCH_DOCUMENTS,
  PUSH_DOCUMENTS,
  REMOVE_DOCUMENTS,
  UPDATE_DOCUMENTS,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID } from '../actions/actions-documents';

const INITIAL_STATE = {
  documents: [],
  mode: 'index',
  activeId: '',
  activeDocuments:{}
}


export default function(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_DOCUMENTS:
      return {
        ...state,
        documents:action.payload,
      }
    case PUSH_DOCUMENTS:
      return {
        ...state,
        documents:[action.payload, ...state.documents],
      }
    case REMOVE_DOCUMENTS:
      return {
        ...state,
        documents : [...state.documents.filter( (item) => item._id !== action.payload._id)]
      }
    case UPDATE_DOCUMENTS:
      let index =  _.findIndex(state.documents, { '_id': action.payload._id })
      state.documents.splice(index, 1, action.payload)
      return {
        ...state,
        documents : state.documents
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode : action.payload,
        activeId:''
      }
    case UPDATE_ACTIVE_ID:
      return {
        ...state,
        mode : action.mode,
        activeId:action.id,
        activeDocuments: _.pick(_.find(state.documents, { '_id': action.id }), ['title', 'fileName','fileType', 'date','remark', '_id'])
      }
    default:
      return state

  }
}
