import { Button, Card, Form, Select, Spin, Table, Tabs } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Layout from "../../components/layout";
import requireAuth from "../../components/requireAuth";
import client from "../../feathers";

const FormItem = Form.Item;
const { TabPane } = Tabs;
const Option = Select.Option;
const text = "Are you sure to delete this item?";
const complaintFeedback = [
  { status: "Pending", total: "totalPending" },
  { status: "Rejected", total: "totalRejected" },
  { status: "Solved", total: "totalSolved" },
  { status: "In progress", total: "totalProgress" },
];

class PaymentLogIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userRole: "",
      loading: false,
      statusType: "",
      totalPending: 0,
      totalProgress: 0,
      totalSolved: 0,
      totalRejected: 0,
      dropDownPropertyUnit: [],
      dataSourcePaymentLog: [],
      selectedCategory: "",
    };
  }

  componentWillMount() {
    this.getPropertyUnits();
    this.getData();
  }

  getPropertyUnits() {
    client
      .service("propertyunits")
      .find({
        query: {
          propertyId: this.props.commons.selectedCompany,
        },
      })
      .then((res) => {
        this.setState({ dropDownPropertyUnit: res.data });
      });
  }

  getData(skip) {
    client.authenticate().then(() => {
      client
        .service("paymentlogs")
        .find({
          query: {
            propertyId: this.props.commons.selectedCompany,
            $populate: ["propertyunitId", "invoiceId", "userId"],
            $sort: {
              receivedDate: 1,
            },
          },
        })
        .then((res) => {
          const data = res.data.map((r) => {
            r.fullName =
              r.userId && r.userId.firstName + " " + r.userId.lastName;
            return r;
          });
          this.setState({ dataSourcePaymentLog: data });
        });
    });
  }

  addData() {
    client
      .service("paymentlogs")
      .create({
        transId: "T093738823521",
        amount: 409.2,
        receivedDate: moment("2021-12-06T17:27:06").toDate(),
        propertyId: "6051d0ccd4c161377c809f36",
        propertyunitId: "6051d0fbd4c161377c80a958",
        paymentMethod: "CIMB Clicks",
        userId:'6274a3460ba53d296f1305ec'
      })
      .then((res) => {
        this.getData();
      });
  }

  render() {
    const { dataSourcePaymentLog } = this.state;

    const columns = [
      {
        title: "Invoice Issue Date",
        dataIndex: "invoiceId.invoiceDate",
        key: "invoiceId.invoiceDate",
        render: (v, r) => {
          if (v) {
            return moment(v).format("DD/MM/YYYY");
          } else return "-";
        },
      },
      {
        title: "Invoice No",
        dataIndex: "invoiceId.invoiceNo",
        key: "invoiceId.No",
        render: (v, r) => {
          if (v) {
            return v;
          } else return "-";
        },
      },
      {
        title: "Unit No",
        dataIndex: "propertyunitId.referenceId",
        key: "propertyunitId.referenceId",
      },
      {
        title: "Owner",
        dataIndex: "propertyunitId.owner.name",
        key: "propertyunitId.owner.name",
      },
      {
        title: "Received Date",
        dataIndex: "receivedDate",
        render: (v, r) => {
          return moment(v).format("DD/MM/YYYY hh:mm:ss A");
        },
      },
      {
        title: "TransID",
        dataIndex: "transId",
        key: "transID",
      },
      {
        title: "User Name",
        dataIndex: "fullName",
        key: "userId.firstName ",
        render: (v, r) => {
          if (v) {
            return v;
          } else return "-";
        },
      },
      {
        title: "Payment Method",
        dataIndex: "paymentMethod",
        key: "paymentMethod",
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        // render: (text, record) => {
        //   let colorStatus = ''
        //   if (record.status === 'Pending') {
        //     colorStatus = '#fda855'
        //   }
        //   else if (record.status === 'Rejected') {
        //     colorStatus = 'red'

        //   }
        //   else if (record.status === 'Solved') {
        //     colorStatus = 'green'

        //   }

        //   else {
        //     colorStatus = '#9FD5B5'
        //   }

        //   return (<span style={{ color: colorStatus }}>
        //     {record.status}
        //   </span>)
        // }
      },
    ];

    return (
      <Layout>
        <Spin spinning={this.state.loading}>
          <Card title="Payment Log by Apps">
            <Table
              dataSource={dataSourcePaymentLog}
              columns={columns}
              rowKey="_id"
              size="small"
            />
          </Card>
          {/* <Button
            onClick={() => {
              this.addData();
            }}
          >
            Add
          </Button> */}
        </Spin>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    commons: state.commons,
    // complaints: state.complaints,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      // fetchComplaints: fetchComplaints,
      // pushComplaints: pushComplaints,
      // removeComplaints: removeComplaints,
      // updateActiveComplaints: updateActiveComplaints,
      // updateComplaints: updateComplaints,
      // updateMode: updateMode
    },
    dispatch
  );
}

export default requireAuth(
  connect(mapStateToProps, mapDispatchToProps)(Form.create()(PaymentLogIndex))
);
