import React, { Component } from "react";
import { Modal, Radio, message, Form, Icon, Input, Select } from "antd";
import client from "../../feathers";
import requireAuth from "../../components/requireAuth";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateMode } from "../../actions/actions-ledgers";

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

class AccountForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: "",
      confirmDirty: false,
      accountTypeDropdowns: [],
      searchCategories: "",
      accountTypeDropdownsValue: "",
      filerCategories: "",
    };
    this.renderAccoutType = this.renderAccoutType.bind(this);
  }
  componentDidUpdate() {
    if (this.props.ledgers.activeId !== this.state._id) {
      this.setState({
        _id: this.props.ledgers.activeId,
      });

      if (this.props.ledgers.activeLedgers) {
        this.props.form.setFieldsValue(this.props.ledgers.activeLedgers);
        this.getParams(this.props.ledgers.activeLedgers.categories);
        console.log("get", this.props.ledgers.activeLedgers);
      }
    }
  }

  getParams = (e) => {
    client
      .authenticate()
      .then(() => {
        return client.service("parameters").find({
          query: {
            table: e,
            $sort: {
              createdAt: -1,
            },
          },
        });
      })
      .then((res) => {
        this.setState({
          accountTypeDropdowns: res.data[0].items,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  handleDropdown = (e) => {
    // this.props.form.resetFields();

    this.state.accountTypeDropdowns = [];
    this.state.accountTypeDropdownsValue = "";
    console.log("gett", e);
    this.getParams(e);
    //dropdown account type
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.props.ledgers.mode === "edit") {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          client
            .service("ledger-accounts")
            .patch(this.props.ledgers.activeId, values)
            .then((res) => {
              message.success(`accounts Updated`);
              this.props.updateMode("");
              this.props.doneSubmit("done");
            })
            .catch((err) => {
              console.log(err);
              this.props.updateMode("");
            });
          return;
        }
      });
    }
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  renderAccoutType = () => {
    const { getFieldDecorator } = this.props.form;
    // if(this.props.ledgers.mode == 'edit' ){
    //   return (
    //     <FormItem
    //       {...formItemLayout}
    //       label="Account Type"
    //       hasFeedback

    //     >
    //       {getFieldDecorator('accountType', {
    //         rules: [
    //           { required: true, message: 'Select Type!' },
    //         ],
    //       })(
    //         <Input />
    //       )}
    //     </FormItem>
    //   )
    // }else{
    return (
      <FormItem {...formItemLayout} label="Account Type" hasFeedback>
        {getFieldDecorator("accountType", {
          rules: [{ required: true, message: "Select Type!" }],
        })(
          <Select
            placeholder="Select Type"
            value={this.state.accountTypeDropdownsValue}
          >
            {this.state.accountTypeDropdowns.map((dropdown) => (
              <Option value={dropdown.value}>{dropdown.description}</Option>
            ))}
          </Select>
        )}
      </FormItem>
    );
    // }
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Modal
        visible={
          this.props.ledgers.mode === "new" ||
          this.props.ledgers.mode === "edit"
            ? true
            : false
        }
        title={
          this.props.ledgers.mode === "edit"
            ? "Update accounts"
            : "Create accounts"
        }
        okText={this.props.ledgers.mode === "edit" ? "Update" : "Create"}
        onCancel={() => {
          this.props.updateMode("");
          this.props.form.resetFields();
        }}
        onOk={this.handleSubmit}
      >
        <Form className="submit-form">
          <FormItem {...formItemLayout} label="Categories" hasFeedback>
            {getFieldDecorator("categories", {
              rules: [{ required: true, message: "Select Categories!" }],
            })(
              <Select
                style={{ width: 200 }}
                placeholder="Please select Account Categories"
                onChange={this.handleDropdown}
              >
                <Option value="ASSETS">ASSETS</Option>
                <Option value="LIABILITIES">LIABILITIES</Option>
                <Option value="INCOMES">INCOMES</Option>
                <Option value="EXPENSES">EXPENSES</Option>
                <Option value="EQUITY">EQUITY</Option>
              </Select>
            )}
          </FormItem>

          {this.renderAccoutType()}

          <FormItem {...formItemLayout} label="Account Name">
            {getFieldDecorator("accountName", {
              rules: [
                {
                  required: true,
                  message: "Please input Accounts Description!",
                },
              ],
            })(<Input placeholder="Lim Motors" />)}
          </FormItem>

          <FormItem {...formItemLayout} label="Account No.">
            {getFieldDecorator("accountNo", {
              rules: [
                {
                  required: true,
                  message: "Please input Account Number!",
                },
              ],
            })(<Input placeholder="1000" />)}
          </FormItem>

          <FormItem {...formItemLayout} label="Sub Account No.">
            {getFieldDecorator("subAccountNo", {
              rules: [
                {
                  required: true,
                  message: "Please input Account Number!",
                },
              ],
            })(<Input placeholder="000" />)}
          </FormItem>

          {/*
            <FormItem
            {...formItemLayout}
            label="Currency"
          >
            {getFieldDecorator('accountCurrency', {
              rules: [{
                required: true, message: 'Please select Currency!',
              }],
            })(
              <Select placeholder="Please Select currency">
                <Option value="MYR">MYR - Malaysia Ringgit</Option>
              </Select>
            )}
          </FormItem>
          */}

          <FormItem {...formItemLayout} label="Description">
            {getFieldDecorator("description", {
              rules: [
                {
                  required: true,
                  message: "Please input description!",
                },
              ],
            })(<Input placeholder="" />)}
          </FormItem>

          <FormItem {...formItemLayout} label="Bank Account?">
            {getFieldDecorator("bankAccountFlag", {
              rules: [
                {
                  required: true,
                  message: "Please select!",
                },
              ],
            })(
              <Radio.Group>
                <Radio.Button value={true}>true</Radio.Button>
                <Radio.Button value={false}>false</Radio.Button>
              </Radio.Group>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Special Code">
            {getFieldDecorator("specialCode", {
              rules: [
                {
                  required: false,
                  message: "Please Input Specia Code",
                },
              ],
            })(<Input placeholder="" />)}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    ledgers: state.ledgers,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateMode: updateMode,
    },
    dispatch
  );
}

export default requireAuth(
  connect(mapStateToProps, mapDispatchToProps)(Form.create()(AccountForm))
);
