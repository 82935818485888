import React, { Component, useEffect } from 'react';
import { Modal, Upload, Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Select, InputNumber, Pagination, Spin, DatePicker, Row, Col, Card, Popover } from 'antd';
import client from '../../feathers';
import requireAuth from "../requireAuth";
import Layout from "../layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateMode } from '../../actions/actions-ledgers';
import moment from 'moment';


const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const text = 'Are you sure to delete this item?';
const informationText = (
  <div>
    <Row>
      Initial Balance for All Accounts
  </Row>
  </div>
)
const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
  getInput = () => {

    if (this.props.dataIndex === 'openCreditDebit') {
      return (
        <Select style={{ width: '100%' }} >
          <Option value='DEBIT'>DEBIT</Option>
          <Option value='CREDIT'>CREDIT</Option>
        </Select>
      );
    }

    if (this.props.dataIndex === 'openDate') {
      this.props.record.openDate = this.props.record.openDate ? moment(this.props.record.openDate) : moment()
      return (
        <DatePicker />
      );
    }

    if (this.props.dataIndex === 'creditAmount') {
      return <Row><Col style={{ float: 'right' }}><InputNumber /></Col></Row>;
    }

    if (this.props.dataIndex === 'debitAmount') {
      return <Row><Col style={{ float: 'right' }}><InputNumber /></Col></Row>;
    }

    if (this.props.inputType === 'number') {
      return <InputNumber />;
    }
    return <Input />;
  };

  render() {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      ...restProps
    } = this.props;
    return (
      <EditableContext.Consumer>
        {(form) => {
          const { getFieldDecorator } = form;
          return (
            <td {...restProps}>
              {editing ? (


                <FormItem style={{ margin: 0 }}>
                  {getFieldDecorator(dataIndex, {
                    rules: [{
                      required: false,
                      message: `Please Input ${title}!`,
                    }],
                    initialValue: record[dataIndex],
                  })(this.getInput())}
                </FormItem>,

                <FormItem style={{ margin: 0 }}>
                  {getFieldDecorator(dataIndex, {
                    rules: [{
                      required: false,
                      message: `Please Input ${title}!`,
                    }],
                    initialValue: record[dataIndex],
                  })(this.getInput())}
                </FormItem>


              ) : restProps.children}
            </td>
          );
        }}
      </EditableContext.Consumer>
    );
  }
}

class LedgersFormOpen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ledgerAccounts: [],
      editingKey: '',
      totalPagination: 1,
      CurrentPagination: 1,
      loading: false,
      recordUpdated: '',
      refresh: false,
    }

    this.columns = [
      {
        title: 'Full account No',
        dataIndex: 'fullAccountNo',
        width: '10%',
        editable: false,
      },
      {
        title: 'Categories',
        dataIndex: 'categories',
        width: '10%',
        editable: false,
      },
      {
        title: 'Description',
        dataIndex: 'description',
        width: '15%',
        editable: false,
      },
      // {
      //   title: 'Debit / Credit ',
      //   dataIndex: 'openCreditDebit',
      //   key:"test",
      //   width: '15%',
      //   editable: true,
      //   required: true,
      // },

      {
        title: 'Debit Amount',
        dataIndex: 'debitAmount',
        width: '10%',
        editable: true,
        // required: true,
        render: (text, record) => (
          <span style={{ float: 'right' }}>
            {record.openCreditDebit === 'DEBIT' ? parseFloat(record.openAmount).toFixed(2) : 0}
          </span>)
      },
      {
        title: 'Credit Amount',
        dataIndex: 'creditAmount',
        width: '10%',
        editable: true,
        // required: true,
        render: (text, record) => (
          <span style={{ float: 'right' }}>
            {record.openCreditDebit === 'CREDIT' ? parseFloat(record.openAmount).toFixed(2) : 0}
          </span>)
      },
      // {
      //   title: 'Amount',
      //   dataIndex: 'openAmount',
      //   width: '15%',
      //   editable: true,
      //   required: true,
      //   render: (text, record) => (
      //     <span style={{float:'right'}}>
      //         {parseFloat(text).toFixed(2)}
      //       </span>)
      // },
      {
        title: 'Initial Balance Date',
        dataIndex: 'openDate',
        width: '15%',
        editable: true,
        required: true,
        render: (text, record) => (
          <span>
            {text ? moment(text).format('DD-MM-YYYY') : ''}
          </span>)

      },
      {
        title: 'Operation',
        dataIndex: 'operation',
        width: '20%',
        render: (text, record) => {
          const editable = this.isEditing(record);
          return (
            <div>
              {editable ? (
                <span>
                  <EditableContext.Consumer>
                    {form => (
                      <a
                        href="javascript:;"
                        onClick={() => this.save(form, record)}
                        style={{ marginRight: 8 }}
                      >
                        Save
                      </a>
                    )}
                  </EditableContext.Consumer>

                  <a
                    href="javascript:;"
                    onClick={() => this.cancel(record._id)}
                  >Cancel</a>

                </span>
              ) : (
                <a onClick={() => this.edit(record._id)}>Edit</a>
              )}
            </div>
          );
        },
      },
    ];
  }

  componentDidUpdate() {

    if (this.state.refresh === true) {
      this.componentWillMount()
    }

  }

  componentWillMount() {

    this.setState({ loading: true, refresh: false });
    let companyId = this.props.commons.selectedCompany._id

    client.authenticate()
      .then((res) => {
        return client.service('ledger-accounts').find({
          query: {
            companyId: companyId,
            $sort: {
              accountNo: 1,
              subAccountNo: 1
            }
          }
        })
      })
      .then((res) => {
        this.setState({
          editingKey: '',
          ledgerAccounts: res.data,
          totalPagination: res.total,
          loading: false
        });
      })
      .catch((err) => {
        this.setState({ loading: true });
        console.log(err);
      })
  }

  handlePageChange = (page) => {

    this.setState({
      CurrentPagination: page,
      loading: true
    });
    this.props.form.validateFields((err, values) => {

      client.authenticate()
        .then((res) => {
          let companyId = this.props.commons.selectedCompany._id

          return client.service('ledger-accounts').find({
            query: {
              ...values,
              companyId: companyId,
              $limit: 20,
              $skip: (page - 1) * 20,
              $sort: {
                accountNo: 1,
                subAccountNo: 1
              }
            }
          })
        })
        .then((res) => {

          this.setState({
            totalPagination: res.total,
            ledgerAccounts: res.data,
            loading: false
          });
        })
        .catch((err) => {
          this.setState({ loading: false });
          console.log(err);
        })
    });
  }

  isEditing = record => record._id === this.state.editingKey;

  cancel = () => {
    this.setState({ editingKey: '' });
  };

  save(form, key) {

    this.setState({ loading: true })

    form.validateFields((error, row) => {
      if (error) {
        this.setState({ loading: false })
        return;
      }
      //------------------------
      let initialBalanceDate = new Date(row.initialBalanceDate)

      client.service('openbalances').find({
        query: {
          ledgerAccountsId: key._id,
        }
      })
        .then((res) => {
          let openAmount = 0
          let openCreditDebit = ''
          if (row.creditAmount > 0) {
            openCreditDebit = 'CREDIT'
            openAmount = row.creditAmount
          } else {
            openCreditDebit = 'DEBIT'
            openAmount = row.debitAmount
          }
          if (res.total > 0) {
            let openBalanceId = res.data[0]._id
            client.service('ledger-accounts').patch(key._id,
              {
                openCreditDebit: openCreditDebit,
                openAmount: openAmount,
                openDate: row.openDate
              }
            )
              .then((res) => {



                client.service('openbalances').patch(openBalanceId,
                  {
                    creditDebit: openCreditDebit,
                    Amount: openAmount,
                    initialBalanceDate: row.openDate,
                  },

                )
                message.success(`Record Updated`);
                this.setState({ refresh: true })
              })
              .catch((err) => {

                console.log(err);
              })

            const newData = [...this.state.ledgerAccounts];
            const index = newData.findIndex(item => key._id === item._id);

            if (index > -1) {

              const item = newData[index];
              newData.splice(index, 1, {
                ...item,
                ...row,
              });
              this.setState({ loading: false, ledgerAccounts: newData, editingKey: '', refresh: false });
            } else {

              newData.push(row);
              this.setState({ loading: false, ledgerAccounts: newData, editingKey: '', refresh: false });
            }

            //--------------------------------addtional logging

          }

          else {

            let openAmount = 0
            let openCreditDebit = ''
            if (row.creditAmount > 0) {

              openCreditDebit = 'CREDIT'
              openAmount = row.creditAmount
            }
            else {
              openCreditDebit = 'DEBIT'
              openAmount = row.debitAmount
            }

            const values2 = {
              companyId: this.props.commons.selectedCompany._id,
              ledgerAccountsId: key._id,
              creditDebit: openCreditDebit,
              Amount: openAmount,
              initialBalanceDate: row.openDate
            };

            client.service('ledger-accounts').patch(key._id,
              {
                openCreditDebit: openCreditDebit,
                openAmount: openAmount,
                openDate: row.openDate
              }
            )

            client.service('openbalances').create(values2)
              .then((res) => {

                message.success('Record Updated');
                this.setState({ loading: false, editingKey: '', refresh: true })
              })
            //--------------------------------addtional logging

          }
        });

    });

  }

  edit(key) {
    this.setState({ editingKey: key });
  }

  render() {
    const { visible, onCancel } = this.props;
    const { getFieldDecorator } = this.props.form;
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };

    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: col.dataIndex === 'age' ? 'number' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });

    return (
      <Layout>
        <Spin spinning={this.state.loading}>
          {/*<Table dataSource={this.state.ledgerAccounts} columns={columns} rowKey="_id" />*/}
          <Card title={

            <div > <Row>
              <Col>
                <span>Initial Amount Balance</span>
                <span style={{
                  marginLeft: "5px",
                  marginBottom: "10px"
                }}>
                  <Popover content={informationText}><Icon type="info-circle" theme="twoTone" /></Popover>

                </span>
              </Col>
            </Row>
            </div>}
          >

            <Table
              components={components}
              bordered
              dataSource={this.state.ledgerAccounts}
              columns={columns}
              rowClassName="editable-row"
              pagination={false}
            />


          </Card>
          {/* <Pagination defaultCurrent={1} total={this.state.totalPagination} onChange={this.handlePageChange} /> */}
        </Spin>
      </Layout>

    )
  }
}


function mapStateToProps(state) {
  return {
    ledgers: state.ledgers,
    commons: state.commons
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(LedgersFormOpen)));
