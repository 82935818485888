import React, { Component, useEffect } from 'react';
import {
  Modal,
  Upload,
  Popconfirm,
  message,
  Divider,
  Table,
  Form,
  Icon,
  Input,
  Button,
  Select,
  Card,
  Drawer,
  Row,
  Col,
  Radio,
  DatePicker,
  Spin,
} from 'antd';
import client from '../../feathers';
import requireAuth from '../requireAuth';

import axios from 'axios';
import Layout from '../layout';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateMode } from '../../actions/actions-accounts';
import { Route, Link } from 'react-router-dom';

import moment from 'moment';
import { updateActivePage } from '../../actions/app-actions';

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;
const text = 'Are you sure to delete this item?';

const formatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
});

class LedgerIndexDebtors extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ledgerAccounts: [],
      filerAccountName: '',
      totalPagination: 1,
      accountType: 'CURRENT ASSETS',
      accountTypeDropdowns: [],
      filerCategories: 'ASSETS',
      accountNo: '3000',
      mode: '',
      loading: false,
    };
  }

  componentWillMount() {
    this.setState({ loading: true });
    let companyId = this.props.commons.selectedCompany._id;
    const activePage = this.props.activePage.supplierStatementPage;
    if (activePage > 1) {
      this.handlePageChange(activePage);
    } else {
      client
        .authenticate()
        .then((res) => {
          return client.service('ledger-accounts').find({
            query: {
              companyId: companyId,
              accountNo: { $in: ['4000'] },
              eventCode: {
                $nin: [
                  'PREPAYMENT',
                  'PREPAYMENTUA',
                  'MONTHLYCLOSING',
                  'CLOSED-ACCOUNTS',
                ],
              },
              remark: { $ne: 'PREPAYMENT' },
              $sort: {
                accountNo: 1,
                subAccountNo: 1,
              },
            },
          });
        })
        .then((res) => {
          this.setState({
            loading: false,
            ledgerAccounts: res.data,
            totalPagination: res.total,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }

    //-----------------------------

    this.state.filerCategories = 'ASSETS';
    this.state.accountTypeDropdowns = [];
    this.state.accountTypeDropdownsValue = '';

    //dropdown account type
    this.setState({ accountTypeDropdownsValue: [] }, () => {
      client
        .authenticate()
        .then(() => {
          return client.service('parameters').find({
            query: {
              table: this.state.filerCategories,
              $sort: {
                createdAt: -1,
              },
            },
          });
        })
        .then((res) => {
          this.setState({
            accountTypeDropdowns: res.data[0].items,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  componentDidUpdate() {}

  handleConfirmBlur = (e) => {};

  handlePageChange = (page) => {
    this.setState({
      loading: true,
    });

    this.props.updateActivePage({
      supplierStatementPage: page,
    });

    let filerAccountName =
      this.state.filerAccountName === ''
        ? ''
        : { accountName: this.state.filerAccountName };
    let collection = { ...filerAccountName };

    this.props.form.validateFields((err, values) => {
      let companyId = this.props.commons.selectedCompany._id;

      client
        .authenticate()
        .then((res) => {
          return client.service('ledger-accounts').find({
            query: {
              //...values,
              ...collection,
              companyId: companyId,
              accountNo: { $in: ['4000'] },
              eventCode: {
                $nin: [
                  'PREPAYMENT',
                  'PREPAYMENTUA',
                  'MONTHLYCLOSING',
                  'CLOSED-ACCOUNTS',
                ],
              },
              remark: { $ne: 'PREPAYMENT' },

              $sort: {
                accountNo: 1,
                subAccountNo: 1,
              },
              $limit: 20,
              $skip: (page - 1) * 20,
              orRegex: { ...filerAccountName },
            },
          });
        })
        .then((res) => {
          this.setState({
            totalPagination: res.total,
            ledgerAccounts: res.data,
            loading: false,
          });
          //this.props.fetchTransactions(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  handleFilter = () => {
    let filerAccountName =
      this.state.filerAccountName === ''
        ? ''
        : { accountName: this.state.filerAccountName };
    let collection = { ...filerAccountName };

    client
      .authenticate()
      .then((res) => {
        return client.service('ledger-accounts').find({
          query: {
            companyId: res.propertyId,
            accountNo: { $in: ['4000'] },
            eventCode: {
              $nin: [
                'PREPAYMENT',
                'PREPAYMENTUA',
                'MONTHLYCLOSING',
                'CLOSED-ACCOUNTS',
              ],
            },
            ...collection,
            $sort: {
              accountNo: 1,
              subAccountNo: 1,
            },
            orRegex: { ...filerAccountName },
            $limit: 20,
          },
        });
      })
      .then((res) => {
        this.setState({
          ledgerAccounts: res.data,
          totalPagination: res.total,
        });
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };
  //---------------------------------------------------
  handleAccountName = (e) => {
    // this.setState({
    //   filerAccountName:e.target.value
    // });
    this.state.filerAccountName = e.target.value;

    this.setState({
      typingTimeout: setTimeout(() => {
        this.handleFilter();
      }, 500),
    });
  };

  //---------------------------------------------------
  showModal = () => {
    this.props.updateMode('show');
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };
  //---------------------------------------------------
  _Field_TotalBillAmount(record) {
    try {
      if (record.balance < 0 || typeof record.balance == 'undefined') {
        return (
          <span
            style={{
              color: record.balance < 0 ? 'red' : 'rgba(0, 0, 0, 0.65)',
              float: 'right',
            }}
          >
            {formatter.format(record.balance ? record.balance : 0)}
          </span>
        );
      } else {
        return (
          <span style={{ float: 'right' }}>
            {record.balance
              ? formatter.format(record.balance)
              : formatter.format(0.0)}{' '}
          </span>
        );
      }
    } catch (e) {
      return <span style={{ float: 'right' }}>{formatter.format(0.0)} </span>;
    }
  }

  //---------------------------------------------------

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        // let fromdate = moment(new Date(values.fromDate)).format('YYYYMMDD')
        // let todate = moment(new Date(values.toDate)).format('YYYYMMDD')

        let fromdate = moment(new Date(values.DateRange[0])).format('YYYYMMDD');
        let todate = moment(new Date(values.DateRange[1])).format('YYYYMMDD');

        this.props.history.push();
        let companyId = this.props.commons.selectedCompany._id;

        if (!values.email) {
          this.props.history.push({
            pathname: '/soaPrintAll/' + companyId,
            state: { fromdate: fromdate, todate: todate },
          });
        } else {
          axios.get(`${client.io.io.uri}SOA-printAll`, {
            params: {
              companyId: companyId,
              fromDate: moment(new Date(values.fromDate)).format('YYYYMMDD'),
              toDate: moment(new Date(values.toDate)).format('YYYYMMDD'),
              email: values.email,
            },
            headers: {
              Authorization: client.settings.storage.storage['feathers-jwt'],
            },
          });
          this.props.updateMode('hide');
          message.success('Email will be sent in a while');
        }
      } //end validate field
    });
  };

  //--------------------------------------------------

  render() {
    const { visible, onCancel } = this.props;

    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title: 'Account No.',
        dataIndex: 'accountNo',
        key: 'accountNo',
        width: '15%',
        render: (text, record) => <span>{record.fullAccountNo}</span>,
      },
      {
        title: (
          <Row gutter={8}>
            <Row>
              <Col span={24}>Account Name</Col>
            </Row>
            <Row>
              <Col span={24}>
                <Input
                  value={this.state.filerAccountName}
                  onChange={this.handleAccountName}
                  placeholder='Account Name'
                  style={{ width: '200px' }}
                />
              </Col>
            </Row>
          </Row>
        ),
        dataIndex: 'accountName',
        key: 'accountName',
        width: '15%',
        render: (text, record) => <span>{text}</span>,
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        width: '15%',
        render: (text, record) => <span>{text}</span>,
      },
      {
        title: 'Total Outstanding (MYR)',
        dataIndex: 'balance',
        width: '7%',
        render: (text, record) => (
          <span>{this._Field_TotalBillAmount(record)}</span>
        ),
      },

      {
        title: 'Action',
        key: 'action',
        width: '10%',
        render: (text, record) => (
          <span>
            <Link
              to={{
                pathname: `/reports/statement-of-account/${record._id}`,
              }}
            >
              <Button>View Statement</Button>
            </Link>
          </span>
        ),
      },
    ];

    return (
      <Layout>
        <Spin spinning={this.state.loading}>
          <Card
            title={
              <span>
                <Row>
                  <div>
                    <Col>Statement Of Accounts</Col>
                  </div>
                </Row>
              </span>
            }
            extra={
              <Button type='primary' onClick={this.showModal}>
                Print All Statement
              </Button>
            }
          >
            <Table
              dataSource={this.state.ledgerAccounts}
              columns={columns}
              pagination={{
                total: this.state.totalPagination,
                defaultCurrent: this.props.activePage.supplierStatementPage,
                onChange: this.handlePageChange,
                pageSize: 20,
              }}
              rowKey='_id'
            />
          </Card>

          <Modal
            visible={this.props.accounts.mode === 'show' ? true : false}
            title='Print All Statement'
            okText='Print'
            onCancel={() => {
              this.props.updateMode('');
              this.props.form.resetFields();
            }}
            onOk={this.handleSubmit}
          >
            <Form className='submit-form'>
              {/*
                  <FormItem {...formItemLayout} label="Print Start Date">
                     {getFieldDecorator('fromDate', {
                       rules: [{
                         required: true, message: 'Please input Print Start Date!',
                       }],
                      initialValue: moment().startOf('year'),
                     })(
                       <DatePicker />
                     )}
                  </FormItem>

                  <FormItem {...formItemLayout} label="Print End Date">
                     {getFieldDecorator('toDate', {
                       rules: [{
                         required: true, message: 'Please input print End Date!',
                       }],
                       initialValue: moment().endOf('year'),
                     })(
                       <DatePicker  />
                     )}
                  </FormItem>
*/}
              <FormItem {...formItemLayout} label='Date Range'>
                {getFieldDecorator('DateRange', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input print End Date!',
                    },
                  ],
                  // initialValue: moment().endOf('year'),
                })(<RangePicker />)}
              </FormItem>

              <FormItem {...formItemLayout} label='Email'>
                {getFieldDecorator('email', {
                  rules: [
                    {
                      type: 'email',
                      message: 'The input is not valid E-mail!',
                    },
                  ],
                })(<Input placeholder='Email' />)}
              </FormItem>
            </Form>
          </Modal>
        </Spin>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    accounts: state.accounts,
    commons: state.commons,
    activePage: state.app.activePage,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateMode: updateMode,
      updateActivePage,
    },
    dispatch
  );
}

export default requireAuth(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Form.create()(LedgerIndexDebtors))
);
