import React, { Component } from 'react';
import { Modal,Avatar, Row, Col, Card, Popconfirm, message, Divider,  Table, Form, Icon, Input, Button,Select,Radio, Spin } from 'antd';
import client from '../../feathers';
import requireAuth  from "../../components/requireAuth";
import Layout  from "../../components/layout";
import Info  from "../../components/common/info";
import RefundsPrint from "../../components/refund/refund-print";
import ReactToPrint from "react-to-print";


import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchRefunds,
  pushRefunds,
  removeRefunds,
  updateMode,
  updateActiveRefunds,
  updateRefunds } from '../../actions/actions-refunds';

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

class RefundsView extends Component {
  constructor(props) {
      super(props);

      this.state = {
        refunds: [],
        loading:false
      }
  }

  handleSubmit = (e) => {
    e.preventDefault();
 }


  render() {

    return(
      <Layout>
        <Spin spinning={this.state.loading}>
        <Row>
          <Col style={{ marginBottom: 10,float: 'right'}}>
          <ReactToPrint
            trigger={() => <Button type="primary">Print</Button>}
            content={() => this.componentRef}
          />
          </Col>
          </Row>

          <RefundsPrint refundsId={this.props.match.params.id} ref={el => (this.componentRef = el)} />

        </Spin>
      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    refunds: state.refunds
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchRefunds: fetchRefunds,
    pushRefunds: pushRefunds,
    removeRefunds: removeRefunds,
    updateActiveRefunds: updateActiveRefunds,
    updateRefunds: updateRefunds,
    updateMode: updateMode
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(RefundsView));
