import { Button, Card, Divider, Form, message, Popconfirm, Spin, Table } from 'antd';
import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchCharges,
  pushCharges,
  removeCharges, updateActiveCharges,
  updateCharges, updateMode
} from '../../actions/actions-charges';
import ChargesForm from "../../components/charges/form";
import Layout from "../../components/layout";
import requireAuth from "../../components/requireAuth";
import client from '../../feathers';

const FormItem = Form.Item;

const text = 'Are you sure to delete this item?';

class ChargesIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    }

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidUpdate() {

    if (this.props.charges.mode === 'refresh') { this.componentWillMount() }
  }

  componentWillMount() {
    let propertyValue = this.props.user.info.propertyId._id

    this.props.fetchCharges()
    this.setState({ loading: true });

    this.props.updateMode('');

    client.authenticate()
      .then((res) => {
        return client.service('charges').find({
          query: {
            propertyId: propertyValue,
            //status: {$nin:['INACTIVE'] },
            $sort: {
              code: 1
            }
          }
        })
      })
      .then((res) => {
        console.log({ res });
        this.props.fetchCharges(res.data)
        this.setState({
          loading: false
        })
      })
      .catch((err) => {
        console.log(err);
      })
  }

  handleDelete(_id) {
    client.service('charges').remove(_id)
      .then((res) => {
        message.success('Record Deleted.');
        this.props.updateMode('refresh');
      })
      .catch((err) => {
        alert(err);
      })
  }

  handleEdit(_id) {
    this.props.updateActiveCharges('edit', _id)
  }

  handleManage(_id) {
    this.props.updateActiveCharges('', _id)
  }

  showModal = () => {
    this.props.updateMode('new');
  }

  renderConfirmDeleteBtn(_id) {
    return (
      <Popconfirm placement="topLeft" title={text} onConfirm={() => this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
        <Button type="danger">Delete</Button>
      </Popconfirm>
    )
  }


  render() {
    const columns = [
      {
        title: 'Account',
        dataIndex: 'accountNo',
        key: 'accountNo',
      },
      {
        title: 'Code',
        dataIndex: 'code',
        key: 'code',
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: 'Charge Type',
        dataIndex: 'chargeType',
        key: 'chargeType',
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
      },
      {
        title: 'Percentage %',
        dataIndex: 'percentage',
        key: 'percentage',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <Button onClick={() => this.handleEdit(record._id)}>Edit</Button>
            <Divider type="vertical" />
            {this.renderConfirmDeleteBtn(record._id)}
          </span>
        ),
      }];

    return (
      <Spin spinning={this.state.loading}>
        <Layout>
          <Card
            title="Fee"
            extra={<Button type="primary" onClick={this.showModal}>New Fee</Button>}
          >
            <Table dataSource={this.props.charges.charges} columns={columns} rowKey="_id" />
            <ChargesForm />
          </Card>
        </Layout>
      </Spin>
    )
  }
}


function mapStateToProps(state) {
  return {
    charges: state.charges,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchCharges: fetchCharges,
    pushCharges: pushCharges,
    removeCharges: removeCharges,
    updateActiveCharges: updateActiveCharges,
    updateCharges: updateCharges,
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(ChargesIndex)));
