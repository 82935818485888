import React, { Component } from 'react';
import { Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Card, Badge, Row, Col, Select, Spin, Modal, InputNumber } from 'antd';
import { Route, Link } from 'react-router-dom';

import client from '../../feathers';
import requireAuth from "../../components/requireAuth";
import Layout from "../../components/layout";
import axios from 'axios';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchPropertyunits,
  updateActivePropertyunits,
} from '../../actions/actions-propertyunits';
import {
  updateValueReminder,
} from '../../actions/actions-commons'


const _ = require('lodash');
const FormItem = Form.Item;
const Option = Select.Option;
const formatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2
});

const PAGESIZE = 20;
const text = 'Are you sure to delete this item?';
const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

class ReminderLetterIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchUnitNumber: '',
      totalPagination: 0,
      loading: true,
      dataSource: [],
      filterAmtModal: false,
      filterAmtNOD11Modal: false,
      filterAmtNOD20Modal: false,

    }

    this.handleDelete = this.handleDelete.bind(this);
    this._searchUnitNumberChange = this._searchUnitNumberChange.bind(this);
  }

  componentDidUpdate() { }

  componentDidMount() { }

  componentWillMount() {

    this.setState({ loading: true });

    let companyId = this.props.commons.selectedCompany._id

    client.authenticate()
      .then((res) => {
        return client.service('propertyunits').find({
          query: {
            propertyId: companyId,

            $limit: 20,
            $populate: 'ledgerAccountId',
            $sort: {
              referenceId: 1
            }
          }
        })
      })
      .then((res) => {

        this.props.fetchPropertyunits(res.data)
        this.setState({ dataSource: res.data, totalPagination: res.total, });

        return axios.get(`${client.io.io.uri}remindeLetterInq`, {
          params: {
            companyId: companyId,

          },
          headers: {
            'Authorization': client.settings.storage.storage['feathers-jwt']
          }
        })
      }).then((res2) => {


        res2.data.result.forEach(function (item, index) {
          item.sinkingfund = parseFloat(item.sinkingfund).toFixed(2)
          item.othersDueAmt = parseFloat(item.othersDueAmt).toFixed(2)
        });

        var dataSource = _.map(this.state.dataSource, function (item) {

          return _.merge(item, _.find(res2.data.result, { 'propertyunitId': item._id }));
        });


        this.setState({
          overdueAmtList: res2.data.result,
          dataSource: dataSource,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      })

  }

  handleDelete(_id) {
    client.service('propertyunits').remove(_id)
      .then((res) => {
      })
      .catch((err) => {
        alert(err);
      })
  }

  handlePrint(_id) {

    this.props.updateActivePropertyunits('print', _id)
  }

  handleManage(_id) {
    this.props.updateActivePropertyunits('', _id)
  }

  printAllReminder = () => {

    this.setState({ filterAmtModal: true })

  }

  printAllReminder2 = () => {
    let companyId = this.props.commons.selectedCompany._id

    this.props.form.validateFields((err, values) => {

      if (values.email) {
        axios.get(`${client.io.io.uri}remindeLetterInqAll`, {
          params: {
            companyId: companyId,
            filterbyAmount: values.filterbyAmount ? values.filterbyAmount : 0,
            email: values.email,
            mode: "All"
          },
          headers: {
            'Authorization': client.settings.storage.storage['feathers-jwt']
          }
        })
        this.setState({ filterAmtModal: false });
        message.success('Email will be sent in a while');
      }
      else {
        this.props.match.params = { filterAmount: values.filterbyAmount }
        this.props.updateValueReminder("print", values.filterbyAmount);
        this.props.history.push('/reminderLetter/printAll/' + companyId);
      }
    })
  }

  printAllNODForm11 = () => {

    this.setState({ filterAmtNOD11Modal: true })
  }

  printAllNODForm11_2 = () => {
    let companyId = this.props.commons.selectedCompany._id

    this.props.form.validateFields((err, values) => {
      if (values.email) {
        axios.get(`${client.io.io.uri}remindeLetterInqAll`, {
          params: {
            companyId: companyId,
            filterbyAmount: values.filterbyAmount ? values.filterbyAmount : 0,
            email: values.email,
            mode: "form11"
          },
          headers: {
            'Authorization': client.settings.storage.storage['feathers-jwt']
          }
        })
        this.setState({ filterAmtNOD11Modal: false });
        message.success('Email will be sent in a while');
      } else {
        this.props.match.params = { filterAmount: values.filterbyAmount }
        this.props.updateValueReminder("print", values.filterbyAmount);

        this.props.history.push('/NODFORM11Letter/printAll/' + companyId);
      }
    })

  }

  printAllNODForm20 = () => {

    this.setState({ filterAmtNOD20Modal: true })

  }

  printAllNODForm20_2 = () => {
    let companyId = this.props.commons.selectedCompany._id


    this.props.form.validateFields((err, values) => {
      if (values.email) {
        axios.get(`${client.io.io.uri}remindeLetterInqAll`, {
          params: {
            companyId: companyId,
            filterbyAmount: values.filterbyAmount ? values.filterbyAmount : 0,
            email: values.email,
            mode: "form20"
          },
          headers: {
            'Authorization': client.settings.storage.storage['feathers-jwt']
          }
        })
        this.setState({ filterAmtNOD20Modal: false });
        message.success('Email will be sent in a while');
      } else {
        this.props.match.params = { filterAmount: values.filterbyAmount }
        this.props.updateValueReminder("print", values.filterbyAmount);
        this.props.history.push('/NODFORM20Letter/printAll/' + companyId);
      }
    })
  }

  renderConfirmDeleteBtn(_id) {
    return (
      <Popconfirm placement="topLeft" title={text} onConfirm={() => this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
        <Button type="danger">Delete</Button>
      </Popconfirm>


    )
  }

  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {

      let companyId = this.props.commons.selectedCompany._id
      client.authenticate()
        .then((res) => {
          //
          return client.service('propertyunits').find({
            query: {
              ...values,
              propertyId: companyId,
              $populate: 'ledgerAccountId',
              $sort: {
                referenceId: 1
              }
            }
          })
        })
        .then((res) => {
          this.props.fetchPropertyunits(res.data);
        })
        .catch((err) => {
          console.log(err);
        });

    });
  }

  handlePageChange = (page) => {
    let companyId = this.props.commons.selectedCompany._id

    this.setState({
      CurrentPagination: page,
      loading: true
    });

    this.props.form.validateFields((err, values) => {
      client.authenticate()
        .then((res) => {
          //
          return client.service('propertyunits').find({
            query: {
              propertyId: companyId,
              $limit: 20,
              $skip: (page - 1) * 20,
              $populate: 'ledgerAccountId',
              $sort: {
                referenceId: 1

              }
            }
          })
        })
        .then((res) => {
          this.props.fetchPropertyunits(res.data)
          var overdueAmtList = this.state.overdueAmtList

          var dataSource = _.map(res.data, function (item) {

            return _.merge(item, _.find(overdueAmtList, { 'propertyunitId': item._id }));
          });
          this.setState({ dataSource: '' })
          this.setState({ dataSource: dataSource, totalPagination: res.total, loading: false });

        })
        .catch((err) => {
          console.log(err);
        })
    });
  }

  renderSearching() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form
        className="ant-advanced-search-form"
        onSubmit={this.handleSearch}
      >
        <Row gutter={24} style={{ display: 'block' }}>
          <Col span={8}>
            <Form.Item label='Unit Number'>
              {getFieldDecorator(`unitNo`, {
                rules: [{
                  required: false,
                  message: 'Unit Number!',
                }],
              })(
                <Input placeholder="B-1-2" />
              )}
            </Form.Item>
          </Col>

          <Button type="primary" htmlType="submit" style={{ float: 'right' }} >Search</Button>
        </Row>

      </Form>
    )
  }

  _searchUnitNumberChange(e) {
    this.setState({
      searchUnitNumber: e.target.value,
      searchObj: _.merge(this.state.searchObj, e.target.value ? { referenceId: e.target.value } : { referenceId: '.' }),
      typingTimeout: setTimeout(() => {
        this._getData(this.state.skip)
      }, 500)
    })
  }

  _getData(skip) {
    let companyId = this.props.commons.selectedCompany._id
    client.authenticate()
      .then(() => {
        let searchObj = this.state.searchObj
        let query = {
          query: {
            propertyId: companyId,
            $populate: 'ledgerAccountId',
            $sort: {
              referenceId: 1
            },
            $limit: PAGESIZE,
            orRegex: this.state.searchObj,
          }
        }
        _.assign(query.query, searchObj)
        return client.service('propertyunits').find(query)
      })
      .then((res) => {
        this.props.fetchPropertyunits(res.data)
        var overdueAmtList = this.state.overdueAmtList

        var dataSource = _.map(res.data, function (item) {

          return _.merge(item, _.find(overdueAmtList, { 'propertyunitId': item._id }));
        });
        this.setState({ dataSource: '' })
        this.setState({ dataSource: dataSource, totalPagination: res.total, loading: false });

      })
      .catch((err) => {
        console.log(err);
      });
  }


  //---------------Render Part-----------------------------//
  render() {
    const { getFieldDecorator } = this.props.form;
    const { dataSource } = this.state
    const text = 'Print All Reminder?';

    const columns = [
      {
        title:
          (<Row gutter={8}>
            <Row><Col span={24}>Unit Number</Col></Row>
            <Row><Col span={24}><Input value={this.state.searchUnitNumber} onChange={this._searchUnitNumberChange} /></Col></Row>
          </Row>
          ),

        dataIndex: 'referenceId',
        key: 'referenceId',
        width: '20%',
      },
      {
        title: 'Total Outstanding (Exclude Sinking Fund)',
        dataIndex: 'othersDueAmt',
        key: 'othersDueAmt',
        width: '20%',
        render: (text, record) => (
          <div style={{ 'float': 'right' }}>
            {text ? parseFloat(text).toFixed(2) : '0.00'}
          </div>
        ),
      },
      {
        title: 'Outstanding (Sinking Fund only)',
        dataIndex: 'sinkingfund',
        key: 'sinkingfund',
        width: '20%',
        render: (text, record) => (
          <div style={{ 'float': 'right' }}>
            {text ? parseFloat(text).toFixed(2) : '0.00'}
          </div>
        ),
      },
      {
        title: 'Action',
        key: 'action',
        width: '20%',
        render: (text, record) => (
          <span>
            {record.totalDueAmt ? <div>
              <Divider type="vertical" />
              <Link
                to={{
                  pathname: `/reminderLetter/print/${record._id.propertyunitId}`
                }}
              >
                <Button onClick={() => this.handlePrint(record._id)}>Print Reminder Letter</Button>
              </Link>

              <Divider type="vertical" />

              <Link
                to={{
                  pathname: `/NODFORM11Letter/print/${record._id.propertyunitId}`
                }}
              >
                <Button onClick={() => this.handlePrint(record._id)}>Print NOD (Form 11)</Button>
              </Link>
              <Divider type="vertical" />
              <Link
                to={{
                  pathname: `/NODFORM20Letter/print/${record._id.propertyunitId}`
                }}
              >
                <Button onClick={() => this.handlePrint(record._id)}>Print NOD (Form 20)</Button>
              </Link>
            </div>
              : ''}

          </span>
        ),
      }];

    return (
      <Layout>
        <Spin spinning={this.state.loading}>
          <Card
            title='Reminder / NOD Letter'
            extra={
              <Row>
                <Popconfirm placement="topLeft" title={text}
                  onConfirm={() => this.printAllReminder()}
                  okText="Confirm" cancelText="Cancel">
                  <Button>Print All Reminder</Button>
                </Popconfirm>

                <Divider type="vertical" />
                <Popconfirm placement="topLeft" title={text}
                  onConfirm={() => this.printAllNODForm11()}
                  okText="Confirm" cancelText="Cancel">
                  <Button>Print All NOD(Form 11)</Button>
                </Popconfirm>
                <Divider type="vertical" />
                <Popconfirm placement="topLeft" title={text}
                  onConfirm={() => this.printAllNODForm20()}
                  okText="Confirm" cancelText="Cancel">
                  <Button>Print All NOD(Form 20)</Button>
                </Popconfirm>

              </Row>
            }
          >



            <Table
              dataSource={dataSource}
              columns={columns}
              rowKey='id'
              pagination={{
                defaultCurrent: 1,
                total: this.state.totalPagination,
                onChange: this.handlePageChange,
                pageSize: 20,

              }}
              size={'small'}
            />



            <Modal
              visible={this.state.filterAmtModal}
              title='Filter by Amount'
              okText='Proceed'
              onCancel={() => { this.setState({ filterAmtModal: false }); this.props.form.resetFields() }}
              onOk={() => { this.printAllReminder2() }}
            >
              <Form className="submit-form">
                <FormItem {...formItemLayout} label="Greater than" >
                  {getFieldDecorator('filterbyAmount', {
                    rules: [{
                      required: false, message: 'Please input Amount!',
                    }],
                  })(
                    <InputNumber />
                  )}
                </FormItem>
                <FormItem {...formItemLayout} label="Email">
                  {getFieldDecorator('email', {
                    rules: [{
                      type: 'email', message: 'The input is not valid E-mail!'
                    }],

                  })(
                    <Input placeholder="Email" />
                  )}
                </FormItem>
              </Form>
            </Modal>
            <Modal
              visible={this.state.filterAmtNOD11Modal}
              title='Filter by Amount'
              okText='Proceed'
              onCancel={() => { this.setState({ filterAmtNOD11Modal: false }); this.props.form.resetFields() }}
              onOk={() => { this.printAllNODForm11_2() }}
            >
              <Form className="submit-form">
                <FormItem {...formItemLayout} label="Greater than" >
                  {getFieldDecorator('filterbyAmount', {
                    rules: [{
                      required: false, message: 'Please input Amount!',
                    }],
                  })(
                    <InputNumber />
                  )}
                </FormItem>
                <FormItem {...formItemLayout} label="Email">
                  {getFieldDecorator('email', {
                    rules: [{
                      type: 'email', message: 'The input is not valid E-mail!'
                    }],

                  })(
                    <Input placeholder="Email" />
                  )}
                </FormItem>
              </Form>
            </Modal>
            <Modal
              visible={this.state.filterAmtNOD20Modal}
              title='Filter by Amount'
              okText='Proceed'
              onCancel={() => { this.setState({ filterAmtNOD20Modal: false }); this.props.form.resetFields() }}
              onOk={() => { this.printAllNODForm20_2() }}
            >
              <Form className="submit-form">
                <FormItem {...formItemLayout} label="Greater than" >
                  {getFieldDecorator('filterbyAmount', {
                    rules: [{
                      required: false, message: 'Please input Amount!',
                    }],
                  })(
                    <InputNumber />
                  )}
                </FormItem>
                <FormItem {...formItemLayout} label="Email">
                  {getFieldDecorator('email', {
                    rules: [{
                      type: 'email', message: 'The input is not valid E-mail!'
                    }],

                  })(
                    <Input placeholder="Email" />
                  )}
                </FormItem>
              </Form>
            </Modal>
          </Card>
        </Spin>
      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return { commons: state.commons }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchPropertyunits: fetchPropertyunits,
    updateActivePropertyunits: updateActivePropertyunits,
    updateValueReminder: updateValueReminder
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(ReminderLetterIndex)));
