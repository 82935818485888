import React, { Component } from "react";
import { Row, Col, Divider, Table, Form, Input } from "antd";
import client from "../../feathers";
import Info from "../common/info";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchTransactions,
  pushTransactions,
  removeTransactions,
  updateMode,
  updateActiveTransactions,
  updateTransactions,
} from "../../actions/actions-transactions";

const FormItem = Form.Item;
const { TextArea } = Input;

class TransactionsPrint extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      transactions: {},
      jmb: {},
      owner: {},
      byInvoice: false,
      customer: [],
      setting: [],
      prepayment: [],
    };
  }

  componentDidMount() {
    // client.service('transactions').on('updated', (transactions) => {
    //   this.props.updateTransactions(transactions)
    // })
  }

  componentWillMount() {
    client
      .authenticate()
      .then((res) => {
        return client.service("transactions").find({
          query: {
            $populate:
              "paymentTypeParamId propertyunitId invoiceItems.invoiceItemId prepayment.chargeId createdBy",
            _id: this.props.paymentId,
            $sort: {
              createdAt: -1,
            },
          },
        });
      })
      .then((res) => {
        return client
          .service("prepayments")
          .find({
            query: {
              transactionId: this.props.paymentId,
              $sort: {
                createdAt: -1,
              },
            },
          })
          .then((res2) => {
            let correctData = res.data[0].invoiceItems.filter(
              (i) =>
                i.invoiceItemId.status === "PAID" ||
                i.invoiceItemId.status === "PARTIAL"
            );
            correctData = correctData.filter((i) => i.knockOffAmount !== 0);
            this.setState(
              {
                transactions: res.data[0],
                dataSource: res.data[0].invoiceId ? res.data : correctData,
                owner: res.data[0].propertyunitId.owner,
                byInvoice: res.data[0].invoiceId ? true : false,
                customer: res.data[0].propertyunitId,
                prepayment: res2.data,
              },
              () => {}
            );
          });
      })
      .catch((err) => {
        console.log(err);
      });

    this.getJmbInfo();
    this.getTnc();
  }

  getTnc() {
    let companyId = this.props.commons.selectedCompany._id;
    client
      .authenticate()
      .then(() => {
        return client.service("settings").find({
          query: {
            propertyId: companyId,
          },
        });
      })
      .then((res) => {
        this.setState({
          setting: res.data[0].tnc,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getJmbInfo() {
    let companyId = this.props.commons.selectedCompany._id;
    client
      .authenticate()
      .then((res) => {
        return client.service("jmbs").find({
          query: {
            propertyId: companyId,
            //  status:'ACTIVE',
            $limit: 1,
            $sort: {
              createdAt: -1,
            },
          },
        });
      })
      .then((res) => {
        this.setState({
          jmb: res.data[0],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  convertDate(date) {
    let tempDate = new Date(date);
    let d = tempDate.getDate();
    let m = tempDate.getMonth() + 1;
    let y = tempDate.getFullYear();
    return d + "/" + m + "/" + y;
  }

  formatAmt(amt) {
    let integer = parseFloat(amt);
    return integer > 0 ? integer.toFixed(2) : 0.0;
  }

  formatAmount(amt) {
    let integer = parseFloat(amt);

    return integer < 0
      ? `(${Math.abs(amt).toFixed(2)})`
      : integer === 0
      ? 0.0
      : integer.toFixed(2);
  }

  renderExtrafield() {
    let paymentMode = this.state.transactions.paymentTypeParamId
      ? this.state.transactions.paymentTypeParamId.description
      : "";
    if (paymentMode === "CHEQUE") {
      return (
        <React.Fragment>
          <Row gutter={24}>
            <Col span={4}>
              <span>Transaction No. </span>
            </Col>
            <Col span={1}>
              <span style={{ float: "right" }}>:</span>
            </Col>
            <Col span={19}>
              <p>
                {this.state.transactions.chequeNo
                  ? this.state.transactions.chequeNo
                  : "N/A"}{" "}
              </p>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={4}>
              <span>Transaction Date </span>
            </Col>
            <Col span={1}>
              <span style={{ float: "right" }}>:</span>
            </Col>
            <Col span={19}>
              <p>
                {this.state.transactions.chequeDate
                  ? this.convertDate(this.state.transactions.chequeDate)
                  : "N/A"}{" "}
              </p>
            </Col>
          </Row>
        </React.Fragment>
      );
    } else if (paymentMode === "ONLINE BANKING") {
      return (
        <React.Fragment>
          <Row gutter={24}>
            <Col span={4}>
              <span>IBG/IBFT </span>
            </Col>
            <Col span={1}>
              <span style={{ float: "right" }}>:</span>
            </Col>
            <Col span={19}>
              <p>
                {this.state.transactions.ibgOrInstant
                  ? this.state.transactions.ibgOrInstant
                  : "N/A"}{" "}
              </p>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={4}>
              <span>Payment Bank</span>
            </Col>
            <Col span={1}>
              <span style={{ float: "right" }}>:</span>
            </Col>
            <Col span={19}>
              <p>
                {this.state.transactions.paymentBankName
                  ? this.state.transactions.paymentBankName
                  : "N/A"}{" "}
              </p>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={4}>
              <span>References</span>
            </Col>
            <Col span={1}>
              <span style={{ float: "right" }}>:</span>
            </Col>
            <Col span={19}>
              <p>
                {this.state.transactions.reference
                  ? this.state.transactions.reference
                  : "N/A"}{" "}
              </p>
            </Col>
          </Row>
        </React.Fragment>
      );
    } else if (paymentMode === "CREDIT CARD") {
      return (
        <React.Fragment>
          <Row gutter={24}>
            <Col span={4}>
              <span>Card Type</span>
            </Col>
            <Col span={1}>
              <span style={{ float: "right" }}>:</span>
            </Col>
            <Col span={19}>
              <p>
                {this.state.transactions.ccType
                  ? this.state.transactions.ccType
                  : "N/A"}{" "}
              </p>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={4}>
              <span>Card No.</span>
            </Col>
            <Col span={1}>
              <span style={{ float: "right" }}>:</span>
            </Col>
            <Col span={19}>
              <p>
                {this.state.transactions.ccNumber
                  ? this.state.transactions.ccNumber
                  : "N/A"}{" "}
              </p>
            </Col>
          </Row>
        </React.Fragment>
      );
    }
  }

  _renderDescription(v) {
    let data = v.split("<br />");
    data.map((i) => {});
    // return v
    return data.map((i) => (
      <span style={{}}>
        {i} <br />
      </span>
    ));
  }

  render() {
    //const { getFieldDecorator } = this.props.form;
    const { prepayment, dataSource } = this.state;

    prepayment.forEach(function(item, index) {
      let iteminfo = {
        "invoiceItemId.docId": item.referenceId,
        "invoiceItemId.description": item.prepaymentDesc,
        knockOffAmount: item.amount,
      };
      dataSource.push(iteminfo);
    });

    const columns = [
      {
        title: "Doc Id",
        dataIndex: "invoiceItemId.docId",
        key: "invoiceItemId.docId",
      },
      {
        title: "Item",
        dataIndex: "invoiceItemId.description",
        key: "invoiceItemId.description",
        render: (v) => this._renderDescription(v),
      },
      {
        title: "Amount",
        dataIndex: "knockOffAmount",
        key: "knockOffAmount",
        align: "right",
        render: (text, record) => {
          return <span>{this.formatAmount(record.knockOffAmount)}</span>;
        },
      },
    ];

    const columnsInvoice = [
      {
        title: "Amount",
        dataIndex: "appliedAmount",
        key: "appliedAmount",
        render: (text, record) => (
          <span>{this.formatAmt(record.appliedAmount)}</span>
        ),
      },
      {
        title: "Payment Type",
        dataIndex: "paymentTypeParamId.description",
        key: "paymentTypeParamId.description",
      },
      {
        title: "Channel",
        dataIndex: "channel",
        key: "channel",
      },
    ];

    // const columnsPrepayment = [
    //   {
    //     title: 'Charges',
    //     dataIndex: 'chargeId.code',
    //   },
    //   {
    //     title: 'Year',
    //     dataIndex: 'year',
    //   },
    //   {
    //     title: 'Month',
    //     dataIndex: 'month',
    //   },
    //   {
    //     title: 'Amount',
    //     dataIndex: 'amount',
    //     render: (text, record) => (
    //       <span>
    //         {this.formatAmt(record.amount)}
    //       </span>
    //     ),
    //   },
    // ];
    const { jmb, transactions } = this.state;
    const isApp = transactions && transactions.channel === "APPS";
    const createdUser = transactions && transactions.createdBy;
    const appFirstName =
      transactions.createdBy && transactions.createdBy.firstName;
    const appLastName =
      transactions.createdBy && transactions.createdBy.lastName;
    const fromApp = `${appFirstName} ${appLastName} - From App`;
    console.log("pic", this.state.jmb.headerFile);

    return (
      <div style={{ padding: "20px", paddingBottom: "0px" }}>
        <Row>
          <Col span={3}>
            <img
              width="100%"
              src={this.state.jmb.headerFile ? this.state.jmb.headerFile : ""}
            />
          </Col>
          <Col span={14} offset={1}>
            {/* <p>{this.state.jmb.headerLine1} ({this.state.jmb?this.state.jmb.registrationNo:''})</p>
              <p>{this.state.jmb.headerLine2}</p>
              <p>{this.state.jmb.headerLine3}</p>
              <p>{this.state.jmb.headerLine4}</p> */}
            <p style={{ fontWeight: "bolder", color: "#000" }}>
              {jmb.managementName} ({jmb ? jmb.registrationNo : ""})
            </p>
            <p style={{ marginTop: -15 }}>
              <span>{jmb ? jmb.headerLine1 : ""} </span>
            </p>
            <p style={{ marginTop: -15 }}>
              <span>{jmb ? jmb.headerLine2 : ""}</span>
            </p>
            <p style={{ marginTop: -15 }}>
              <span>{jmb ? jmb.headerLine3 : ""}</span>
            </p>
            <p style={{ marginTop: -15 }}>
              <span>{jmb ? jmb.headerLine4 : ""}</span>
            </p>
            <p style={{ marginTop: -15 }}>
              <span>{jmb ? "Mangement Contact: " + jmb.contactNo : ""}</span>
            </p>
            <p style={{ marginTop: -15 }}>
              <span>{jmb ? "Email address: " + jmb.email : ""}</span>
            </p>
          </Col>
          <Col span={6}>
            <h3>Receipt</h3>
            <Info
              label="No."
              labelSpan={6}
              value={this.state.transactions.trxNo}
              valueSpan={18}
            />

            {/*
                <Info label='Date' labelSpan={6}  value={this.convertDate(this.state.transactions.createdAt)} valueSpan={18} />
              */}
            <Info
              label="Date"
              labelSpan={6}
              value={this.convertDate(this.state.transactions.paymentDate)}
              valueSpan={18}
            />
            <Info
              label="Status"
              labelSpan={6}
              value={this.state.transactions.status}
              valueSpan={18}
            />
          </Col>
        </Row>
        <br />
        <Row gutter={24}>
          <Col span={3}>
            <span>Issued To </span>
          </Col>
          <Col span={1}>
            <span style={{ float: "right" }}>:</span>
          </Col>
          <Col span={15}>
            <p>{this.state.customer.referenceId} </p>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={3}>
            <span>Attn </span>
          </Col>
          <Col span={1}>
            <span style={{ float: "right" }}>:</span>
          </Col>
          <Col span={15}>
            <p>{this.state.owner ? this.state.owner.name : ""} </p>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={3}>
            <span>Mailing Address</span>
          </Col>
          <Col span={1}>
            <span style={{ float: "right" }}>:</span>
          </Col>
          <Col span={15}>
            <p>{this.state.owner ? this.state.owner.mailingAddress : ""} </p>
          </Col>
        </Row>

        {/* <Info label='Issue To' labelSpan={2}  value={this.state.owner?this.state.owner.name:''} valueSpan={22} /> */}
        <Divider dashed>Detail</Divider>
        <div style={{}}>
          <Table
            dataSource={this.state.dataSource}
            columns={this.state.byInvoice ? columnsInvoice : columns}
            rowKey="_id"
            pagination={false}
          />

          <Row gutter={24} style={{ textAlign: "right" }}>
            <Col span={24} style={{ textAlign: "right" }}>
              <p style={{ fontWeight: "bold" }}>
                TOTAL :{" "}
                {this.state.transactions.currency +
                  " " +
                  this.formatAmt(this.state.transactions.appliedAmount)}
              </p>
              {/* <Info label='Total' labelSpan={10} valuePos={'right'} value={this.state.transactions.currency + ' ' + this.formatAmt(this.state.transactions.appliedAmount)} valueSpan={12} /> */}
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={4}>
              <span>Payment Mode </span>
            </Col>
            <Col span={1}>
              <span style={{ float: "right" }}>:</span>
            </Col>
            <Col span={19}>
              <p>
                {this.state.transactions.paymentTypeParamId
                  ? this.state.transactions.paymentTypeParamId.description
                  : ""}{" "}
              </p>
            </Col>
          </Row>

          {this.renderExtrafield()}

          <Row gutter={24}>
            <Col span={4}>
              <span>Remark</span>
            </Col>
            <Col span={1}>
              <span style={{ float: "right" }}>:</span>
            </Col>
            <Col span={19}>
              <p>
                {this.state.transactions.remark
                  ? this.state.transactions.remark
                  : ""}{" "}
              </p>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={4}>
              <span>Issues By </span>
            </Col>
            <Col span={1}>
              <span style={{ float: "right" }}>:</span>
            </Col>
            <Col span={19}>
              <p style={{ textTransform: "capitalize" }}>
                {isApp
                  ? fromApp
                  : this.state.transactions.createdBy
                  ? createdUser.email.slice(0, createdUser.email.indexOf("@")) +
                    " - " +
                    createdUser.role[0]
                  : ""}
              </p>
            </Col>
          </Row>

          {/*
              <Divider dashed >Pre-Payment</Divider>
                <Table
                  dataSource={this.state.prepayment}
                  columns={columnsPrepayment}
                  pagination={false}
                />
            */}
          {/* <Row>
            <Col span={24}>
              <div className="receiptFooter">
                <div className="ql-display-container2">
                  <div className="ql-display-editor2" style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: this.state.setting ? this.state.setting.receiptTnc : '' }} />
                </div>
                <div align="right">© 2021 Mr Tech System Holdings Sdn Bhd (1370083-M)</div>
              </div>
            </Col>
          </Row> */}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    transactions: state.transactions,
    commons: state.commons,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchTransactions: fetchTransactions,
      pushTransactions: pushTransactions,
      removeTransactions: removeTransactions,
      updateActiveTransactions: updateActiveTransactions,
      updateTransactions: updateTransactions,
      updateMode: updateMode,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsPrint);
