export const UPDATE_ACTIVE_MENU_KEY = 'update_active_menu_key';
export const UPDATE_ACTIVE_PAGE = 'update_active_page';


export function updateActiveMenuKey(payload){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_MENU_KEY,
      payload
    });
  }
}


export function updateActivePage(payload){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_PAGE,
      payload
    });
  }
}