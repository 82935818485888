import { Button, Card, Spin, Table } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import { CSVLink } from 'react-csv';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchDebtorAgingReports } from '../../actions/actions-debtorAgingReports';
import client from '../../feathers';

const _ = require('lodash');
const formatCurr = function format1(n = 0.0, currency = null) {
  let oriAmt = n;
  n = n < 0 ? Math.abs(n) : n;

  try {
    let amt = currency
      ? currency
      : '' +
        n.toFixed(2).replace(/./g, function(c, i, a) {
          return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c;
        });
    console.log('--1', amt, oriAmt);
    if (amt === '0.00') {
      return '0.00';
    } else {
      return oriAmt < 0 ? '-' + amt : amt;
    }
  } catch (e) {
    return '0.00';
  }
};

class DebtorAgingPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      loading: true,
      debtors: [],
      invoices: [],
      transactions: [],
      prepayments: [],
      fromDate: moment()
        .startOf('year')
        .format('YYYY-MM-DD'),
      toDate: moment()
        .endOf('year')
        .format('YYYYMMDD'),
      jmbsName: '',
      jmbsNo: '',
      totalBalance: 0,
      excelHeader: [
        {
          label: 'Unit No - Customer Name',
          key: 'owner',
        },
        {
          label: '0-30 days',
          key: 'firstMonth',
        },

        {
          label: '31-90 days',
          key: 'secondMonth',
        },
        {
          label: '91-180 days',
          key: 'thirdMonth',
        },

        {
          label: '> 180 days',
          key: 'fourthMonth',
        },
        {
          label: 'Total',
          key: 'net',
        },
        {
          label: 'Prepayment',
          key: 'ua',
        },
        {
          label: 'Net Due',
          key: 'netDue',
        },
      ],
      debtorAgingData: [],
    };
  }

  _getData() {
    let companyId = this.props.commons.selectedCompany._id;
    client
      .authenticate()
      .then(() => {
        return client.service('jmbs').find({
          query: {
            propertyId: companyId,
          },
        });
      })
      .then((res) => {
        this.setState({
          jmbsName: res.data[0].name,
          jmbsNo: res.data[0].registrationNo,
        });

        return axios.get(`${client.io.io.uri}debtorAgingMonth`, {
          params: {
            propertyId: companyId,
            toDate: this.props.toDate,
            debtor: true,
          },
          headers: {
            Authorization: client.settings.storage.storage['feathers-jwt'],
          },
        });
      })
      .then((res) => {
        let data = res.data.result;

        data = data.map((i) => {
          i.firstMonth = formatCurr(i.firstMonth);
          i.secondMonth = formatCurr(i.secondMonth);
          i.thirdMonth = formatCurr(i.thirdMonth);
          i.fourthMonth = formatCurr(i.fourthMonth);
          i.netDue = formatCurr(i.ua - i.net);
          return i;
        });
        client
          .service('propertyunits')
          .find({
            query: {
              propertyId: companyId,
            },
          })
          .then((x) => {
            data.map((r) => {
              x.data.map((i) => {
                if (r.unit === i.referenceId) {
                  r.owner = i.referenceId + ' - ' + i.owner.name;
                }
                return x;
              });
              return r;
            });
            this.setState({
              debtorAgingData: data || [],
              grandTotal: res.data.grandTotal,
              loading: false,
            });
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.toDate !== this.props.toDate) {
      this.setState({ toDate: this.props.toDate });
    }
  }

  componentWillMount() {
    this._getData();
  }

  render() {
    const { jmbsName, jmbsNo, grandTotal } = this.state;
    var { fromDateD, toDateD, excelHeader, debtorAgingData } = this.state;
    let dataSource = _.cloneDeep(debtorAgingData);
    fromDateD = moment(new Date(this.state.fromDate)).format('Do MMM YYYY');
    toDateD = moment(this.state.toDate).format('Do MMM YYYY');
    dataSource.map((r) => {
      r.net = formatCurr(r.net);
      return r;
    });

    const columns = [
      {
        title: 'Unit No',
        dataIndex: 'unit',
        key: 'unit',
        width: '10%',
      },
      {
        title: '0-30 days',
        dataIndex: 'firstMonth',
        key: 'firstMonth',
        width: '10%',
        align: 'right',
        render: (text) => text,
      },
      {
        title: '31-90 days',
        dataIndex: 'secondMonth',
        key: 'secondMonth',
        width: '10%',
        align: 'right',
        render: (text) => text,
      },
      {
        title: '91-180 days',
        dataIndex: 'thirdMonth',
        key: 'thirdMonth',
        width: '10%',
        align: 'right',
        render: (text) => text,
      },
      {
        title: '> 180 days',
        dataIndex: 'fourthMonth',
        key: 'fourthMonth',
        width: '10%',
        align: 'right',
        render: (text) => text,
      },
      {
        title: <div style={{ float: 'right' }}>Total</div>,
        dataIndex: 'net',
        key: 'net',
        width: '10%',
        align: 'right',
        render: (text) => formatCurr(text),
      },
      {
        title: <div style={{ float: 'right' }}>Prepayment</div>,
        dataIndex: 'ua',
        key: 'ua',
        width: '10%',
        align: 'right',
        render: (text) => formatCurr(text),
      },
      {
        title: <div style={{ float: 'right' }}>Net Due</div>,
        dataIndex: 'Net',
        key: 'netDue',
        width: '10%',
        align: 'right',
        render: (v, r) => {
          if (r) {
            return <span>{r.netDue}</span>;
          }
        },
      },
    ];

    return (
      <Card
        title={
          <div>
            <span style={{ fontSize: 20 }}>{jmbsName}</span>
            <br></br>
            <span>({jmbsNo})</span>
            <br></br>
            <span>Debtor Aging</span>
            <span className='alignRight'>As at selected date {toDateD}</span>
          </div>
        }
        extra={[
          !_.isEmpty(this.state.debtorAgingData) ? (
            <CSVLink
              headers={excelHeader}
              data={dataSource}
              filename={`${
                this.props.commons.selectedCompany.name
              }_Debtor Aging_${moment(this.state.toDate).format('YYYYMMDD')}`}
            >
              <Button type='primary' style={{ marginBottom: 10 }}>
                Export
              </Button>
            </CSVLink>
          ) : null,
        ]}
      >
        <Spin spinning={this.state.loading}>
          <Table
            dataSource={this.state.debtorAgingData}
            columns={columns}
            rowKey='_id'
            pagination={false}
            footer={() => (
              <div>
                <span style={{ float: 'right' }}>
                  Total : RM {formatCurr(grandTotal)}
                </span>
              </div>
            )}
          />
        </Spin>
      </Card>
    );
  }
}

//----------------------------------------------------------------------------
function mapStateToProps(state) {
  return {
    debtorAgingReports: state.debtorAgingReports,
    commons: state.commons,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchDebtorAgingReports: fetchDebtorAgingReports,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(DebtorAgingPrint);
