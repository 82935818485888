import React, { Component } from 'react';
import { Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Card, Spin, Tabs, Badge, DatePicker, Modal } from 'antd';
import { Route, Link } from 'react-router-dom';

import client from '../../feathers';
import requireAuth from "../../components/requireAuth";
import Layout from "../../components/layout";
import FacilitiesForm from "../../components/facilities/form";
import FacilitybookingsIndex from "../../components/facilitybookings/index";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchFacilities,
  pushFacilities,
  removeFacilities,
  updateMode,
  updateActiveFacilities,
  updateFacilities
} from '../../actions/actions-facilities';
import {
  fetchFacilitybookings,
  pushFacilitybookings,
  removeFacilitybookings,
  updateActiveFacilitybookings,
  updateFacilitybookings
} from '../../actions/actions-facilitybookings'
import moment from 'moment';
import _ from 'lodash';


const FormItem = Form.Item;
const dateFormat = 'DD/MM/YYYY'
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;



const text = 'Are you sure to disable this facilities?';

class FacilitiesIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showDatePicker: false
    }
    this.handleDisable = this.handleDisable.bind(this);
  }

  componentDidUpdate() {


    if (this.props.facilities.mode === 'refresh') {
      this.componentWillMount()
    }
  }

  componentWillMount() {

    this.props.updateActiveFacilities('', '', '')

    this.setState({ loading: true });
    client.authenticate()
      .then((res) => {

        return client.service('facilities').find({
          query: {
            propertyId: res.propertyId,
            $sort: {
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {
        this.props.fetchFacilities(res.data)
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err);
      })
  }

  handleDisable(_id, dataStatus) {
    client.service('facilities').patch(_id, { status: dataStatus })
      .then((res) => {
        message.success(`Facilities ${dataStatus}`);
        this.componentWillMount();
      })
      .catch((err) => {
        alert(err);
      })
  }
  onChangeCalendar = (value) => {

    this.props.updateActiveFacilitybookings('dateSearch', '', value);

  }
  onChange = (value) => {
    if (_.isEmpty(value)) {///check when is clear
      this.props.updateActiveFacilitybookings('deleteSearch', '', '');
    }
  }

  handleEdit(_id) {
    this.props.updateActiveFacilities('edit', _id)
  }

  handleManage(_id) {
    this.props.updateActiveFacilities('', _id)
  }

  showModal = () => {
    this.props.updateMode('new')
  }



  renderConfirmDeleteBtn(record) {
    if (record.status === 'active') {
      return (
        <Popconfirm placement="topLeft" title={text} onConfirm={() => this.handleDisable(record._id, 'disable')} okText="Confirm" cancelText="Cancel">
          <Button type="danger">Disable</Button>
        </Popconfirm>
      )

    }
    else {
      return (
        <Button type='primary' onClick={() => { this.handleDisable(record._id, 'active') }}>Enable</Button>
      )
    }

  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
      },

      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <Divider type="vertical" />
            <Button onClick={() => this.handleEdit(record._id)}>Edit</Button>
            <Divider type="vertical" />
            {this.renderConfirmDeleteBtn(record)}
          </span>
        ),
      }];

    return (
      <Layout>

        <Spin spinning={this.state.loading}>
          <Card
            title='E-Facilities'
            extra={
              <div>
                <RangePicker style={{ marginRight: '10px' }} on
                  format={dateFormat}
                  onCalendarChange={this.onChangeCalendar}
                  onChange={this.onChange}
                />

                <Button type="primary" onClick={this.showModal}>New Facility</Button>
              </div>}


          >


            <Tabs defaultActiveKey="pending" onChange={this.handleOnChangeSortBy} style={{ flex: 1 }}>
              {/* <TabPane tab='All' key=''></TabPane> */}

              <TabPane tab={(<div><span className='marginRight8px' >Bookings</span><Badge overflowCount={999999} style={{ marginLeft: '8px', backgroundColor: '#1DA57A' }} count={this.state.totalDriveIn} /></div>)} key="DRIVE">

                <FacilitybookingsIndex />
              </TabPane>

              <TabPane tab={(<div><span className='marginRight8px' >Management</span><Badge overflowCount={999999} style={{ marginLeft: '8px', backgroundColor: '#1DA57A' }} count={this.state.totalWalkIn} /></div>)} key="WALK">
                <Table dataSource={this.props.facilities.facilities} columns={columns} rowKey="_id" />
              </TabPane>


            </Tabs>



            <FacilitiesForm />
          </Card>
        </Spin>
      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    facilities: state.facilities,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchFacilities: fetchFacilities,
    pushFacilities: pushFacilities,
    removeFacilities: removeFacilities,
    updateActiveFacilities: updateActiveFacilities,
    updateFacilities: updateFacilities,
    updateActiveFacilitybookings: updateActiveFacilitybookings,
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(FacilitiesIndex)));
