import React, { Component } from 'react';
import {
  Modal,
  message,
  Form,
  Icon,
  Input,
  Button,
  Select,
  Row,
  Card,
  Switch,
} from 'antd';
import client from '../../feathers';
import requireAuth from '../../components/requireAuth';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateMode } from '../../actions/users-actions';

const FormItem = Form.Item;
const Option = Select.Option;
const _ = require('lodash');

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

class UsersFormMngEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',
      confirmDirty: false,
      autoCompleteResult: [],
      managementdropdowns: [],
      propertydropdowns: [],
      ischangePassword: false,
    };

    this.onChangeSwitchPassword = this.onChangeSwitchPassword.bind(this);
  }
  componentDidUpdate() {
    if (this.props.users.activeId !== this.state._id) {
      this.setState({
        _id: this.props.users.activeId,
      });

      if (this.props.users.activeUser) {
        let newEmail = _.get(this.props.users.activeUser, ['email']);

        if (!_.isEmpty(newEmail)) {
          newEmail = newEmail.slice(0, newEmail.indexOf('@'));
        }
        this.props.form.setFieldsValue({
          managementId: this.props.users.activeUser.managementId,
          role: this.props.users.activeUser.role,
          email: newEmail,
        });
      }
    }
  }

  componentWillMount() {}

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      let managementId = this.props.user.info.managementId._id;
      //customize email value
      if (values.email.indexOf('@') === -1) {
        values.email = `${values.email}@kbms.my`;
      }
      values.username = values.email;

      if (this.props.users.mode === 'edit') {
        if (!err) {
          client
            .service('users')
            .patch(this.props.users.activeId, values)
            .then((res) => {
              message.success(`User updated`);
              this.setState({ ischangePassword: false });
              this.props.submitSuccess();
              this.props.updateMode('');
            })
            .catch((err) => {
              message.error(err.message);
              this.setState({ ischangePassword: false });

              // this.props.updateMode('')
            });
          return;
        }
      }
    });
  };

  handleChange = (value) => {
    client
      .service('managements')
      .find({
        query: {
          _id: value,
          $sort: {
            createdAt: -1,
          },
        },
      })
      .then((res) => {
        if (res.total == '1') {
          message.success('Management Found');
          this.props.form.setFieldsValue({
            propertyId: res.data[0].propertyId,
          });
        } else {
          message.error('Management not Found');
        }
      })
      .catch((err) => {
        console.log(err);
        message.error(err);
      });
  };

  handleSelectRole = (value) => {
    if (value != 'management') {
      this.props.form.setFieldsValue({
        managementId: '',
        propertyId: '',
        accountmainId: '',
      });
    }
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    let password = this.props.form.getFieldValue('password') || '';
    let confirm = this.props.form.getFieldValue('confirm') || '';
    var passw = /^(?=.*\d)(?=.*[A-Z]).{8,}/;

    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }

    if (!_.isEmpty(value)) {
      if (!passw.test(value)) {
        callback(
          'Password must be more than 8 characters at least 1 Uppercase, 1 lowercase and 1 digit'
        );
      }
      callback();
    }
    callback();
  };

  onChangeSwitchPassword(e) {
    this.setState({ ischangePassword: e });
  }

  render() {
    console.log(this.props.users.mode);
    // console.log(this.props.users)
    let checkIsEdit = false;
    try {
      if (!_.isEmpty(this.props.users.activeUser)) {
        checkIsEdit = true;
      }
    } catch (err) {
      checkIsEdit = false;
    }
    const { visible, onCancel } = this.props;

    const { getFieldDecorator } = this.props.form;

    return (
      <Modal
        visible={this.props.users.mode === 'edit' ? true : false}
        title={'Update User'}
        okText={'Update'}
        onCancel={() => {
          this.props.updateMode('');
          this.props.form.resetFields();
          this.setState({ ischangePassword: false });
        }}
        onOk={this.handleSubmit}
      >
        <Form className='submit-form'>
          <Row>
            <FormItem {...formItemLayout} label='Username'>
              {getFieldDecorator('email', {
                rules: [
                  {
                    type: 'string',
                    message: 'The input is not valid Username!',
                  },
                  {
                    required: true,
                    message: 'Please input your Username!',
                  },
                ],
              })(<Input disabled />)}
            </FormItem>

            <FormItem {...formItemLayout} label='Role'>
              {getFieldDecorator('role', {
                rules: [
                  {
                    required: true,
                    message: 'Please input your role!',
                  },
                ],
              })(
                <Select placeholder='Select Role'>
                  <Option value='management'>Management</Option>
                  <Option value='admin'>Admin</Option>
                  <Option value='account'>Account</Option>
                  <Option value='executive'>Executive</Option>
                </Select>
              )}
            </FormItem>
          </Row>
          <Row>
            <Card
              bordered={false}
              title={
                <div>
                  Change Password{' '}
                  <Switch
                    checkedChildren={'Yes'}
                    unCheckedChildren={'No'}
                    onChange={this.onChangeSwitchPassword}
                    checked={this.state.ischangePassword}
                  ></Switch>
                </div>
              }
            >
              {this.state.ischangePassword === true ? (
                <React.Fragment>
                  <FormItem {...formItemLayout} label='Password'>
                    {getFieldDecorator('password', {
                      rules: [
                        {
                          required: false,
                          message: 'Please input your password!',
                        },
                        {
                          validator: this.validateToNextPassword,
                        },
                      ],
                    })(
                      <Input
                        prefix={
                          <Icon
                            type='lock'
                            style={{ color: 'rgba(0,0,0,.25)' }}
                          />
                        }
                        placeholder='Password'
                        type='password'
                      />
                    )}
                  </FormItem>

                  <FormItem {...formItemLayout} label='Confirm Password'>
                    {getFieldDecorator('confirm', {
                      rules: [
                        {
                          required: false,
                          message: 'Please confirm your password!',
                        },
                        {
                          validator: this.compareToFirstPassword,
                        },
                      ],
                    })(
                      <Input
                        prefix={
                          <Icon
                            type='lock'
                            style={{ color: 'rgba(0,0,0,.25)' }}
                          />
                        }
                        placeholder='Confirm Password'
                        type='password'
                        onBlur={this.handleConfirmBlur}
                      />
                    )}
                  </FormItem>
                </React.Fragment>
              ) : (
                ''
              )}
            </Card>
          </Row>
        </Form>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.users,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateMode: updateMode,
    },
    dispatch
  );
}

export default requireAuth(
  connect(mapStateToProps, mapDispatchToProps)(Form.create()(UsersFormMngEdit))
);
