import React, { Component } from 'react';
import { Modal, Avatar, Row, Col, Card, Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Select, Radio, Switch, Spin } from 'antd';
import client from '../../feathers';
import requireAuth from "../requireAuth";
import Layout from "../layout";
import Info from "../common/info";
import moment from 'moment';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  updateValueIDN
} from '../../actions/actions-commons';
import ReactToPrint from 'react-to-print';

//--------------------------------------------------------------------
const toWords = require('to-words');
const { TextArea } = Input;
const _ = require("lodash");
//--------------------------------------------------------------------


class ComponentToPrint extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      visible: false,
      jmb: [],
      loading: false,
      totalAmount: 1,
      propertyunitId: '',
      dtledgerAccountId: '',
      ctledgerAccountId: '',
      supplierInfo: '',
      settings: '',
      dataInfo: '',
    }
  }
  //-------------------------------------------------------
  componentWillMount() {

    client.authenticate()
      .then((res) => {

        return client.service('receipt-items').find({
          query: {
            propertyId: this.props.companyId,
            $populate: 'supplierId creditledgerAccountId debitledgerAccountId',
            _id: this.props.props.match.params.id,

          }
        })
      })
      .then((res) => {
        let seqno = 0;
        res.data.forEach(function (item, index) {
          item.seqno = seqno + 1;
          seqno++;

        })

        this.setState({
          dataSource: res.data,
          dataInfo: res.data[0],
          // propertyunitId: res.data[0].propertyunitId,
          dtledgerAccountId: res.data[0].debitledgerAccountId,
          ctledgerAccountId: res.data[0].creditledgerAccountId,

        });

        // this.getSupplierInfo();

      })


    this.getJmbInfo();
    this.getSettingInfo();

  }

  //---------------------------------------------------------

  getJmbInfo() {
    client.authenticate()
      .then((res) => {
        return client.service('jmbs').find({
          query: {
            propertyId: this.props.companyId,
            status: 'ACTIVE',
            $limit: 1,
            $sort: {
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {

        this.setState({
          jmb: res.data[0]
        });
      })
      .catch((err) => {
        console.log(err);
      });

  }

  //---------------------------------------------------------
  getSettingInfo() {
    client.authenticate()
      .then((res) => {
        return client.service('settings').find({
          query: {
            propertyId: this.props.companyId,
          }
        })
      })
      .then((res) => {

        this.setState({
          settings: res.data[0]
        });
      })
      .catch((err) => {
        console.log(err);
      });

  }
  //-------------------------------------------------------
  getSupplierInfo() {

    client.authenticate()
      .then((res) => {
        return client.service('suppliers').find({
          query: {
            propertyId: this.props.companyId,
            ledgerAccountId: this.state.dtledgerAccountId._id
          }
        })
      })
      .then((res) => {

        this.setState({
          supplierInfo: res.data[0]
        });
      })
      .catch((err) => {
        console.log(err);
      });

  }
  //-------------------------------------------------------
  componentDidMount() { }

  _renderTable = () => {

    const { visible, onCancel } = this.props;
    const { loading, jmb, dataSource, dtledgerAccountId, ctledgerAccountId, propertyunitId } = this.state;


    let table = [];

    const columns = [
      {
        title: 'No',
        dataIndex: 'seqno',
        key: 'seqno',
        width: '5%',
        render: (text, record) => (
          <span>
            {text}
          </span>
        ),
      },
      {
        title: 'Expenses',
        dataIndex: "debitledgerAccountId.description",
        width: '20%'

      },
      {
        title: 'Remark',
        dataIndex: 'remark',
        key: 'remark',
        width: '20%',
      },

      {
        title: 'Invoice No',
        dataIndex: 'receiptId.receiptNo',
        key: 'receiptId.receiptNo',
        width: '10%',
      },
      {
        title: 'Invoice Date',
        dataIndex: 'invoiceDate',
        key: 'invoiceDate',
        width: '10%',
        render: (text, record) => (
          <span>
            {moment(text).format('DD-MM-YYYY')}
          </span>
        ),
      },

      {
        title: (<Row><Col style={{ 'float': 'right' }}>Amount</Col></Row>),
        dataIndex: 'creditAmount',
        key: 'creditAmount',
        align: 'right',
        width: '10%',
        render: (text, record) => (
          <span style={{ 'float': 'right' }}>
            {text ? text.toFixed(2) : '0.00'}
          </span>
        ),
      },


    ];

    const debtor =
      (<div>
        <Row gutter={24}>
          <Col span={4}><span>Address</span></Col>
          <Col span={1}><span>:</span></Col>
          {/* <Col span={18}><span style={{ 'padding-left': '0px' }}>{this.state.supplierInfo.addressA ? this.state.supplierInfo.addressA : ''} </span></Col> */}
        </Row>

        <Row gutter={24}>
          <Col span={4}><span></span></Col>
          <Col span={1}><span></span></Col>
          <Col span={18}><span>{this.state.supplierInfo.addressB ? this.state.supplierInfo.addressB : ''} </span></Col>
        </Row>

        <Row gutter={24}>
          <Col span={4}><span></span></Col>
          <Col span={1}><span></span></Col>
          <Col span={18}><span>
            {
              (this.state.supplierInfo.state ? this.state.supplierInfo.state + ' ' : '') +
              (this.state.supplierInfo.postCode ? this.state.supplierInfo.postCode + ' ' : '') +
              (this.state.supplierInfo.country ? this.state.supplierInfo.country + ' ' : '')
            } </span></Col>
        </Row>

        <br />
      </div>)


    table.push([
      <div key='main' >
        <Card>
          <Row>
            <Col span={14} align="left">
              <p><span>{jmb ? jmb.headerLine1 : ''}</span> ({jmb ? jmb.registrationNo : ''}) </p>
              <p><span>{jmb ? jmb.headerLine2 : ''}</span></p>
              <p><span>{jmb ? jmb.headerLine3 : ''}</span></p>
              <p><span>{jmb ? jmb.headerLine4 : ''}</span></p>
              <br />

            </Col>
          </Row>

          <Row gutter={8} >
            <Col span={24} align="left">
              <h2 style={{ 'color': 'rgb(169,169,169)', 'letterSpacing': '2px' }}>Credit Note</h2>
            </Col>

          </Row>
          <Divider></Divider>


          {debtor}

          <Divider dashed >Detail</Divider>

          <Table
            pagination={false}
            dataSource={dataSource}
            columns={columns}
            rowKey="_id" />

        </Card>

        <Row gutter={8}>
          <Col>
            <span style={{ fontWeight: 1000, fontSize: '2em', textDecoration: 'underline', marginLeft: '16px' }}>
              Notes:
            </span>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col>
            <div style={{ fontWeight: 500, fontSize: '1em', marginLeft: '16px' }} dangerouslySetInnerHTML={{ __html: this.state.settings ? this.state.settings.tnc.creditNoteTnc : '' }} />
          </Col>
        </Row>

      </div>,

      <div>
        <br />

      </div>
      ,
      <div key='pagebreak' className='pageBreak' />
    ])

    return table;
  }

  render() {
    const loading = this.state.loading;
    return (
      <Spin key='spinning' spinning={loading}>
        {this._renderTable()}
      </Spin>
    );
  }
}

class InterestCreditNote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    }

  }

  render() {
    return (<Layout>
      <Row>
        <Col align='right' style={{
          marginBottom: '5px'
        }}>
          <span>
            <ReactToPrint trigger={() => <Button type='primary'>PRINT</Button>} content={() => this.componentRef} />
          </span>
        </Col>
      </Row>
      <ComponentToPrint props={this.props} companyId={this.props.commons.selectedCompany._id} ref={el => (this.componentRef = el)} />
    </Layout>);
  }
}


function mapStateToProps(state) {
  return {
    commons: state.commons
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateValueIDN: updateValueIDN
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(InterestCreditNote));
