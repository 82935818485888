export const FETCH_TRANSACTIONS = 'FETCH_TRANSACTIONS';
export const PUSH_TRANSACTIONS = 'PUSH_TRANSACTIONS';
export const REMOVE_TRANSACTIONS = 'REMOVE_TRANSACTIONS';
export const UPDATE_TRANSACTIONS = 'UPDATE_TRANSACTIONS';
export const UPDATE_MODE = 'UPDATE_MODE';
export const UPDATE_ACTIVE_ID = 'UPDATE_ACTIVE_ID';

export function fetchTransactions(data){
  return (dispatch) => {
    dispatch({
      type: FETCH_TRANSACTIONS,
      payload:data
    });
  }
}

export function pushTransactions(data){
  return (dispatch) => {
    dispatch({
      type: PUSH_TRANSACTIONS,
      payload:data
    });
  }
}

export function removeTransactions(data){
  return (dispatch) => {
    dispatch({
      type: REMOVE_TRANSACTIONS,
      payload:data
    });
  }
}

export function updateTransactions(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_TRANSACTIONS,
      payload:data
    });
  }
}

export function updateMode(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE,
      payload:data
    });
  }
}

export function updateActiveTransactions(mode, id){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_ID,
      mode,
      id
    });
  }
}
