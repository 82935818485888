import React, { Component } from 'react';
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  InputNumber,
  Select,
  Col,
  Row,
  Card,
} from 'antd';
import { Route, Link } from 'react-router-dom';

import client from '../../feathers';
import requireAuth from '../requireAuth';
import Layout from '../layout';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import WaiveFormItems from './form-waiver';
import {
  fetchInvoiceitems,
  pushInvoiceitems,
  removeInvoiceitems,
  updateMode,
  updateActiveInvoiceitems,
  updateInvoiceitems,
} from '../../actions/actions-invoiceitems';

import '../../App.css';

const _ = require('lodash');
const text = 'Are you sure to delete this item?';
const FormItem = Form.Item;
const EditableContext = React.createContext();
const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);
const EditableFormRow = Form.create()(EditableRow);
const Option = Select.Option;

class EditableCell extends React.Component {
  state = {
    editing: false,
    waiver: [],
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = () => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  getInput = () => {
    if (this.props.inputType === 'number') {
      return (
        <InputNumber
          ref={(node) => (this.input = node)}
          onPressEnter={this.save}
          onBlur={this.save}
        />
      );
    } else if (this.props.inputType === 'month') {
      //monthNames:['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December','All']
      return (
        <Select
          ref={(node) => (this.input = node)}
          onPressEnter={this.save}
          onBlur={this.save}
          placeholder='Please Month'
          style={{ width: '100%' }}
        >
          <Option value='1'>1</Option>
          <Option value='2'>2</Option>
          <Option value='3'>3</Option>
          <Option value='4'>4</Option>
          <Option value='5'>5</Option>
          <Option value='6'>6</Option>
          <Option value='7'>7</Option>
          <Option value='8'>8</Option>
          <Option value='9'>9</Option>
          <Option value='10'>10</Option>
          <Option value='11'>11</Option>
          <Option value='12'>12</Option>
        </Select>
      );
    } else if (this.props.inputType === 'chargeId') {
      return (
        <Select
          ref={(node) => (this.input = node)}
          onPressEnter={this.save}
          onBlur={this.save}
          placeholder='Select Fee'
          style={{ width: '100%' }}
        >
          {this.state.waivers.map((dropdown) => (
            <Option value={dropdown.code}>{dropdown.code}</Option>
          ))}
        </Select>
      );
    } else {
      return (
        <Input
          ref={(node) => (this.input = node)}
          onPressEnter={this.save}
          onBlur={this.save}
        />
      );
    }
  };

  render() {
    const { editing } = this.state;
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      ...restProps
    } = this.props;
    return (
      <td ref={(node) => (this.cell = node)} {...restProps}>
        {editable ? (
          <EditableContext.Consumer>
            {(form) => {
              this.form = form;
              return editing ? (
                <FormItem style={{ margin: 0 }}>
                  {form.getFieldDecorator(dataIndex, {
                    rules: [
                      {
                        required: true,
                        message: `${title} is required.`,
                      },
                    ],
                    initialValue: record[dataIndex],
                  })(
                    this.getInput()
                    // <Input
                    //   ref={node => (this.input = node)}
                    //   onPressEnter={this.save}
                    //   onBlur={this.save}
                    // />
                  )}
                </FormItem>
              ) : (
                <div
                  className='editable-cell-value-wrap'
                  style={{ paddingRight: 24 }}
                  onClick={this.toggleEdit}
                >
                  {restProps.children}
                </div>
              );
            }}
          </EditableContext.Consumer>
        ) : (
          restProps.children
        )}
      </td>
    );
  }
}

class IndexKnockOffByPropertyunit extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        title: 'Doc Id',
        dataIndex: 'docId',
        key: 'docId',
        width: '25%',
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        width: '25%',
      },
      {
        title: 'Payment Date',
        dataIndex: 'effectiveDate',
        key: 'effectiveDate',
        width: '25%',
        render: (text, record) => (
          <span>
            {record.effectiveDate ? this.convertDate(record.effectiveDate) : ''}
          </span>
        ),
      },
      {
        title: 'Payment Amount',
        dataIndex: 'appliedAmount',
        key: 'appliedAmount',
        width: '25%',
        render: (text, record) => (
          <span style={{ float: 'right' }}>
            {this.formatAmt(record.appliedAmount)}
          </span>
        ),
      },
    ];
    //---------------------------------------------------------------
    this.columnsWaiver = [
      {
        title: 'Waive Item',
        dataIndex: 'invoiceItemId.description',
        key: 'invoiceItemId.description',
        width: '20%',
      },
      {
        title: 'Waive Date',
        dataIndex: 'waiveDate',
        key: 'waiveDate',
        width: '20%',
        render: (text, record) => (
          <span>{this.convertDate(record.waiveDate)}</span>
        ),
      },
      {
        title: 'Waive Amount',
        dataIndex: 'waiveAmt',
        key: 'waiveAmt',
        width: '20%',
        editable: false,
        render: (text, record) => (
          <span style={{ float: 'right' }}>
            {this.formatAmt(record.waiveAmt)}
          </span>
        ),
      },
      {
        title: 'Remark',
        dataIndex: 'remark',
        key: 'remark',
        width: '20%',
        editable: false,
      },
    ];

    this.state = {
      itemTotalAmount: 0,
      selectedAmount: 0,
      totalAmount: 0,
      propertyUnit: {},
      loading: false,
      dataSource: [],
      dataSourceRefund: [],
      selectedRowKeys: [],
      count: 0,
      refund: [],
      selectedRows: [],
      totalAmtWaived: '',
    };
  }

  componentDidUpdate() {}

  componentDidMount() {
    client.service('waives').on('created', (waives) => {
      if (
        sessionStorage.getItem('companyId') === waives.propertyId &&
        this.props.match.params.id === waives.propertyunitId
      ) {
        this.componentWillMount();
      }
    });

    client.service('waives').on('removed', (waives) => {
      if (
        sessionStorage.getItem('companyId') === waives.propertyId &&
        this.props.match.params.id === waives.propertyunitId
      ) {
        this.componentWillMount();
      }
    });

    client.service('waives').on('updated', (waives) => {
      if (
        sessionStorage.getItem('companyId') === waives.propertyId &&
        this.props.match.params.id === waives.propertyunitId
      ) {
        this.componentWillMount();
      }
    });

    client.service('waives').on('patched', (waives) => {
      if (
        sessionStorage.getItem('companyId') === waives.propertyId &&
        this.props.match.params.id === waives.propertyunitId
      ) {
        this.componentWillMount();
      }
    });

    client.service('invoice-items').on('patched', (invoiceItems) => {
      if (
        sessionStorage.getItem('companyId') === invoiceItems.propertyId &&
        this.props.match.params.id === invoiceItems.propertyunitId
      ) {
        this.componentWillMount();
      }
    });
  }

  componentWillMount() {
    this.setState({ loading: true });
    this.getData();
    this._getRefund();
  }

  getData() {
    client
      .authenticate()
      .then((res) => {
        return client.service('invoice-items').find({
          query: {
            $populate: 'propertyId invoiceId',
            propertyId: this.props.commons.selectedCompany._id,
            propertyunitId: this.props.match.params.id,
            //  description:{$in:['DEPOSIT']},
            status: { $nin: ['PAID', 'PAID-REFUND', 'ISSUE-WAIVED'] },
            $sort: {
              docId: 1,
            },
          },
        });
      })
      .then((res) => {
        this.setState({
          dataSource: res.data,
          loading: false,
        });
        this.checkedAllCheckedBox();
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  }

  _getRefund() {
    client
      .authenticate()
      .then((res) => {
        return client.service('waives').find({
          query: {
            propertyunitId: this.props.match.params.id,
            $populate: 'invoiceItemId',
          },
        });
      })
      .then((res) => {
        const { data } = res;
        let total = _.sumBy(data, 'waiveAmt');
        this.setState({
          dataSourceWaive: res.data,
          totalAmtWaived: total,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  checkedAllCheckedBox() {
    let selectedRowKeys = [];
    let selectedRows = [];
    let newData = [];
    let totalSelectedAmt = 0;
    let totalKnockOffAmt = 0;
    for (let i = 0; i < this.state.dataSource.length; i++) {
      this.state.dataSource[i].appliedAmount =
        this.state.dataSource[i].appliedAmount -
        this.state.dataSource[i].knockOffBalance;
      newData.push(this.state.dataSource[i]);
      selectedRows.push(this.state.dataSource[i]);
      selectedRowKeys.push(this.state.dataSource[i]._id);
      totalSelectedAmt += this.state.dataSource[i].appliedAmount;
    }
    setTimeout(() => {
      this.setState({
        selectedRowKeys: selectedRowKeys,
        selectedRows: selectedRows,
        dataSource: newData,
        selectedAmount: totalSelectedAmt,
        itemTotalAmount: totalSelectedAmt,
        totalAmount: parseFloat(totalSelectedAmt).toFixed(2),
      });
    }, 1000);
  }

  formatAmt(amt) {
    let integer = parseFloat(amt);
    return integer > 0 ? integer.toFixed(2) : 0.0;
  }

  convertDate(date) {
    let tempDate = new Date(date);
    let d = tempDate.getDate();
    let m = tempDate.getMonth() + 1;
    let y = tempDate.getFullYear();
    return d + '/' + m + '/' + y;
  }

  getInputStyle = (dataType) => {
    if (dataType === 'amount') {
      return 'number';
    } else if (dataType === 'month') {
      return 'month';
    } else if (dataType === 'chargeId') {
      return 'chargeId';
    } else {
      return 'text';
    }
  };

  handleChangeTotalAmt = (e) => {
    this.setState({ totalAmount: e });

    let tempValue = 0;
    let tempAddOn = 0;
    let oriAmt = e;
    let dataSource = this.state.dataSource;
    let newData = [];
    let stopper = false;

    for (let i = 0; i < dataSource.length; i++) {
      let isChecked = _.includes(this.state.selectedRowKeys, dataSource[i]._id);
      if (isChecked) {
        if (stopper === true) {
          dataSource[i].knockOffAmount = 0.0;
        } else if (
          tempValue +
            (dataSource[i].appliedAmount - dataSource[i].knockOffBalance) <=
          e
        ) {
          tempValue =
            dataSource[i].appliedAmount - dataSource[i].knockOffBalance;
          tempAddOn +=
            dataSource[i].appliedAmount - dataSource[i].knockOffBalance;

          if (tempAddOn >= e) {
            dataSource[i].knockOffAmount = parseFloat(oriAmt);
            stopper = true;
          } else {
            dataSource[i].knockOffAmount = tempValue;
            oriAmt -=
              dataSource[i].appliedAmount - dataSource[i].knockOffBalance;
          }
        } else {
          dataSource[i].knockOffAmount = e - tempValue;
          stopper = true;
        }

        newData.push(dataSource[i]);
      } else {
        dataSource[i].knockOffAmount = 0.0;
        newData.push(dataSource[i]);
      }
    }

    this.setState({ dataSource: newData });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  render() {
    const {
      dataSource,
      dataSourceWaive,
      selectedRowKeys,
      loading,
      selectedRows,
    } = this.state;
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };

    //when check or uncheck record, will run this function
    const rowSelection = {
      selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        let tempAmt = 0;
        let newData = [...this.state.dataSource];
        for (let i = 0; i < selectedRows.length; i++) {
          tempAmt += selectedRows[i].appliedAmount;
        }

        this.setState({
          selectedRows: selectedRows,
          selectedRowKeys: selectedRowKeys,
          selectedAmount: tempAmt,
          dataSource: newData,
          totalAmount: parseFloat(tempAmt).toFixed(2),
        });
      },
    };

    const hasSelected = selectedRows.length > 0;
    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });

    const columnsWaiver = this.columnsWaiver.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: this.getInputStyle(col.dataIndex), //col.dataIndex === 'amount' ? 'number' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
        }),
      };
    });

    return (
      <Layout>
        <Card title={'Waive'}>
          <Row>
            <Col span='16'>
              {hasSelected ? `Selected ${selectedRows.length} items` : ''}

              <Table
                rowSelection={rowSelection}
                components={components}
                rowClassName={() => 'editable-row'}
                bordered
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                scroll={{ y: 340 }}
                rowKey='_id'
              />

              <br />

              <Table
                components={components}
                rowClassName={() => 'editable-row'}
                bordered
                dataSource={dataSourceWaive}
                columns={columnsWaiver}
                pagination={false}
                rowKey='_id'
                footer={() => {
                  return (
                    <span style={{ fontWeight: 'bold' }}>
                      Total Amount : {this.formatAmt(this.state.totalAmtWaived)}
                    </span>
                  );
                }}
              />
            </Col>
            <br />
            <Col span='7' offset={1}>
              <WaiveFormItems
                // selectedRowKeys={this.state.selectedRowKeys}
                invoiceItems={this.state.selectedRows}
                totalAmount={this.state.totalAmount}
                propertyunitId={this.props.match.params.id}
                rowkey='_id'
                onRefresh={() => {
                  this.getData();
                  this._getRefund();
                }}
              />
            </Col>
          </Row>
        </Card>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    invoiceitems: state.invoiceitems,
    commons: state.commons,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchInvoiceitems: fetchInvoiceitems,
      pushInvoiceitems: pushInvoiceitems,
      removeInvoiceitems: removeInvoiceitems,
      updateActiveInvoiceitems: updateActiveInvoiceitems,
      updateInvoiceitems: updateInvoiceitems,
      updateMode: updateMode,
    },
    dispatch
  );
}

export default requireAuth(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Form.create()(IndexKnockOffByPropertyunit))
);
