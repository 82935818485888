import _ from 'lodash';
import {
  FETCH_EFORMS,
  PUSH_EFORMS,
  REMOVE_EFORMS,
  UPDATE_EFORMS,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID } from '../actions/actions-eforms';

const INITIAL_STATE = {
  eforms: [],
  mode: 'index',
  activeId: '',
  activeEforms:{}
}


export default function(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_EFORMS:
      return {
        ...state,
        eforms:action.payload,
      }
    case PUSH_EFORMS:
      return {
        ...state,
        eforms:[action.payload, ...state.eforms],
      }
    case REMOVE_EFORMS:
      return {
        ...state,
        eforms : [...state.eforms.filter( (item) => item._id !== action.payload._id)]
      }
    case UPDATE_EFORMS:
      let index =  _.findIndex(state.eforms, { '_id': action.payload._id })
      state.eforms.splice(index, 1, action.payload)
      return {
        ...state,
        eforms : state.eforms
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode : action.payload,
        activeId:''
      }
    case UPDATE_ACTIVE_ID:
      return {
        ...state,
        mode : action.mode,
        activeId:action.id,
        activeEforms: _.pick(_.find(state.eforms, { '_id': action.id }), ['name', 'description', 'unit', 'categories','amount','minutePerSession','startTime','maxSessionPerday', '_id'])
      }
    default:
      return state

  }
}
