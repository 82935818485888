import React from "react";
import {
  Table,
  Form,
  Input,
  Button,
  Select,
  Card,
  Spin,
  Row,
  Col,
  DatePicker,
  Icon,
} from "antd";
import client from "../../feathers";
import requireAuth from "../requireAuth";
import Layout from "../layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import { Route, Link } from 'react-router-dom';
import ReactToPrint from "react-to-print";
import axios from "axios";
import moment from "moment";
import "../../App.less";

//---------------------------------------------------------------
const _ = require("lodash");
const Option = Select.Option;

const style = {
  marginRight: 15,
};

const iconStyle = {
  fontSize: 16,
  verticalAlign: "middle",
  marginRight: 5,
  cursor: "pointer",
  color: "#999",
};

const ExpandIconWithBadge = ({ iconType, count, dot, onClick }) => (
  <Icon type={iconType} style={iconStyle} onClick={onClick} />
);

const ExpandIcon = ({ expanded, ...props }) => (
  <span style={style}>
    <ExpandIconWithBadge
      style={style}
      iconType={expanded ? "minus-square-o" : "plus-square-o"}
      dot={expanded}
      {...props}
    />
  </span>
);

const formatCurr = function format1(n = 0, currency = null) {
  let oriAmt = n;
  n = n < 0 ? Math.abs(n) : n;
  let amt = currency
    ? currency
    : "" +
      n.toFixed(2).replace(/./g, function(c, i, a) {
        return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
      });

  return oriAmt < 0 ? "(" + amt + ")" : amt;
};

const columnsDebtor = [
  {
    title: "Acct No.",
    dataIndex: "accountInfo.fullAccountNo",
    width: "20%",
    key: "fullAccountNo",
  },
  {
    title: "Account Name",
    dataIndex: "accountInfo.accountName",
    width: "20%",
    key: "accountName",
  },
  {
    title: <span style={{ float: "right" }}>Debit</span>,
    width: "20%",
    dataIndex: "debitAmount",
    key: "debitAmount",
    render: (text, record) => (
      <span style={{ float: "right" }}>{formatCurr(text)}</span>
    ),
  },
  {
    title: <span style={{ float: "right" }}>Credit</span>,
    width: "20%",
    key: "creditAmount",
    dataIndex: "creditAmount",
    render: (text, record) => (
      <span style={{ float: "right" }}>{formatCurr(text)}</span>
    ),
  },
];

class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      accountingPeriods: [],
      jmb: {},
      totalRecord: 0,
      CurrentPagination: 1,
      totalCredit: 0,
      totalDebit: 0,
      defaultValue: "",
      dataSourceASSETS: [],
      fromDate: moment(new Date("2020-05-27T14:26:42.195+08:00")).format(
        "YYYY-MM-DD"
      ),
      toDate: moment()
        .endOf("year")
        .format("YYYY-MM-DD"),
      jmbsName: "",
      jmbsNo: "",
      temptext: "",
    };
  }

  componentWillMount() {
    this.setState({ loading: true });

    client
      .authenticate()
      .then((res) => {
        // return axios.get(`${client.io.io.uri}trial-balance-by-date`,{
        return axios.get(`${client.io.io.uri}ledgerTrxSummary`, {
          params: {
            companyId: this.props.companyId._id,
            toDate: this.state.toDate,
            fromDate: this.state.fromDate,
          },
          headers: {
            Authorization: client.settings.storage.storage["feathers-jwt"],
          },
        });
      })
      .then((res) => {
        document.title = `${this.props.companyId.name}_Trial-Balance_${moment(
          this.state.fromDate
        ).format("YYYYMMDD")}-${moment(this.state.toDate).format("YYYYMMDD")}`;
        this.setState({
          dataSource: res.data[0],
          loading: false,
        });
      })
      //get JMB INFO
      .then((res) => {
        return client.service("jmbs").find({
          query: {
            $populate: "propertyId",
            propertyId: this.props.companyId,
            status: "ACTIVE",
            $sort: {
              createdAt: -1,
            },
            $limit: 1,
          },
        });
      })
      .then((res) => {
        this.setState({
          jmbsName: res.data[0] ? res.data[0].name : "NOT SET",
          jmbsNo: res.data[0] ? res.data[0].registrationNo : "NOT SET",
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
  }

  populatetoDebitCredit(record, debitOrCredit) {
    if (debitOrCredit === "DEBIT") {
      //initialize if undefined
      record.debitAmount = record.debitAmount || 0;
      record.creditAmount = record.creditAmount || 0;

      if (
        record.ledgerAccountCat === "ASSETS" ||
        record.ledgerAccountCat === "EXPENSES"
      ) {
        if (record.debitAmount - record.creditAmount >= 0) {
          return formatCurr(record.debitAmount - record.creditAmount);
        } else {
          return formatCurr(0);
        }
      } else {
        if (record.debitAmount - record.creditAmount >= 0) {
          return formatCurr(record.debitAmount - record.creditAmount);
        } else {
          return formatCurr(0);
        }
      }
    } else {
      if (
        record.ledgerAccountCat === "ASSETS" ||
        record.ledgerAccountCat === "EXPENSES"
      ) {
        if (record.creditAmount - record.debitAmount >= 0) {
          return formatCurr(record.creditAmount - record.debitAmount);
        } else {
          return formatCurr(0);
        }
      } else {
        if (record.creditAmount - record.debitAmount >= 0) {
          return formatCurr(record.creditAmount - record.debitAmount);
        } else {
          return formatCurr(0);
        }
      }
    }
  }

  componentDidUpdate() {
    if (this.props.fromDate !== this.state.fromDate) {
      this.setState({ fromDate: this.props.fromDate }, () => {
        this.componentWillMount();
      });
    }
    if (this.props.toDate !== this.state.toDate) {
      this.setState({ toDate: this.props.toDate }, () => {
        this.componentWillMount();
      });
    }
  }

  _expandRowRender = (record) => {
    return (
      <Table
        dataSource={record.debtorList}
        columns={columnsDebtor}
        pagination={false}
        rowKey={"_id"}
        size={"small"}
      />
    );
  };

  _getRowClassName = (record) => {
    let debtorcount = record.debtorList ? record.debtorList.length : 0;
    return debtorcount > 1 ? "" : "displayTableNone--hide-expand";
  };

  render() {
    const {
      dataSource,
      jmb,
      loading,
      accountingPeriods,
      defaultValue,
    } = this.state;
    const { jmbsName, jmbsNo } = this.state;
    var { fromDateD, toDateD } = this.state;

    fromDateD = moment(new Date(this.state.fromDate)).format("Do MMM YYYY");
    toDateD = moment(new Date(this.state.toDate)).format("Do MMM YYYY");

    let dataSourceASSETS = [];
    let dataSourceLIABILITIES = [];
    let dataSourceINCOMES = [];
    let dataSourceEXPENSES = [];
    let dataSourceEQUITY = [];
    let totalCredit = 0;
    let totalDebit = 0;
    let totalAssetCredit = 0;
    let totalAssetDebit = 0;
    let dataSourceDebtorAcc = [];
    let totalRecord = {};
    let newTotal = [];

    dataSource.forEach(function(item, index, object) {
      if (
        (formatCurr(item.debitAmount) === "0.00" &&
          formatCurr(item.creditAmount) === "0.00") ||
        formatCurr(item.debitAmount - item.creditAmount) === "0.00"
      ) {
      } else {
        if (item.ledgerAccountCat === "ASSETS") {
          if (item.ledgerAccountNo === "3000") {
            dataSourceDebtorAcc.push(item);
            if (item.debitAmount - item.creditAmount > 0) {
              totalAssetDebit += item.debitAmount - item.creditAmount;
            } else {
              totalAssetCredit += item.creditAmount - item.debitAmount;
            }
          } else {
            dataSourceASSETS.push(item);
            if (item.debitAmount - item.creditAmount > 0) {
              totalDebit += item.debitAmount - item.creditAmount;
            } else {
              totalCredit += item.creditAmount - item.debitAmount;
            }
          }
        } else if (item.ledgerAccountCat === "LIABILITIES") {
          dataSourceLIABILITIES.push(item);
          if (item.creditAmount - item.debitAmount > 0) {
            totalCredit += item.creditAmount - item.debitAmount;
          } else {
            totalDebit += item.debitAmount - item.creditAmount;
          }
        } else if (item.ledgerAccountCat === "INCOMES") {
          dataSourceINCOMES.push(item);
          if (item.creditAmount - item.debitAmount > 0) {
            totalCredit += item.creditAmount - item.debitAmount;
          } else {
            totalDebit += item.debitAmount - item.creditAmount;
          }
        } else if (item.ledgerAccountCat === "EXPENSES") {
          dataSourceEXPENSES.push(item);
          if (item.debitAmount - item.creditAmount > 0) {
            totalDebit += item.debitAmount - item.creditAmount;
          } else {
            totalCredit += item.creditAmount - item.debitAmount;
          }
        } else if (item.ledgerAccountCat === "EQUITY") {
          dataSourceEQUITY.push(item);
          if (item.creditAmount - item.debitAmount > 0) {
            totalCredit += item.creditAmount - item.debitAmount;
          } else {
            totalDebit += item.debitAmount - item.creditAmount;
          }
        }
      }
    });

    if (totalAssetCredit - totalAssetDebit > 0) {
      totalCredit += totalAssetCredit - totalAssetDebit;
    } else {
      totalDebit += totalAssetDebit - totalAssetCredit;
    }

    totalRecord.totalDebit = totalDebit;
    totalRecord.totalCredit = totalCredit;
    newTotal.push(totalRecord);

    var str1 = _(dataSourceDebtorAcc)
      .groupBy(null)
      .map((value, id) => ({
        creditAmount: _.sumBy(value, "creditAmount"),
        debitAmount: _.sumBy(value, "debitAmount"),
      }))
      .value();

    // console.log({str1});

    let str2 = {
      accountInfo: {
        categories: "ASSETS",
        accountName: "RECEIVABLE",
        fullAccountNo: "3000/000",
      },
    };
    let str3 = {
      debtorList: dataSourceDebtorAcc,
    };
    dataSourceASSETS.push({ ...str1[0], ...str2, ...str3 });
    dataSourceASSETS = _.orderBy(
      dataSourceASSETS,
      ["accountInfo.fullAccountNo"],
      ["asc"]
    );

    const columns = [
      {
        title: "Acct No.",
        dataIndex: "accountInfo.fullAccountNo",
        width: "30%",
        key: "1",
      },
      {
        title: "Account Name",
        dataIndex: "accountInfo.accountName",
        width: "30%",
        key: "2",
      },
      {
        title: <span>Debit</span>,
        width: "20%",
        dataIndex: "debitAmount",
        align: "right",
        key: "3",
        render: (text, record) => (
          <span>{this.populatetoDebitCredit(record, "DEBIT")}</span>
        ),
      },
      {
        title: <span>Credit</span>,
        width: "20%",
        key: "4",
        dataIndex: "creditAmount",
        align: "right",
        render: (text, record) => (
          <span>{this.populatetoDebitCredit(record, "CREDIT")}</span>
        ),
      },
    ];
    const newColumns = [
      {
        dataIndex: "accountInfo.fullAccountNo",
        width: "30%",
        key: "1",
      },
      {
        dataIndex: "accountInfo.accountName",
        width: "30%",
        key: "2",
      },
      {
        width: "20.5%",
        dataIndex: "totalDebit",
        align: "right",
        key: "3",
        render: (text, record) => (
          <span>
            <b> Total Debits : </b>
            {formatCurr(text)}
          </span>
        ),
      },
      {
        width: "22%",
        key: "4",
        dataIndex: "totalCredit",
        align: "right",
        render: (text, record) => (
          <span>
            <b>Total Credits : </b>
            {formatCurr(text)}
          </span>
        ),
      },
    ];

    return (
      <div key="MainTable">
        {/* {
         dataSource.map(item=>{
           if(item.creditAmount > 0 || item.debitAmount > 0){
             return <p>{item.ledgerAccountCat}, {item.ledgerAccountMainAcc}, {item.ledgerAccountSubAcc}, {item.creditAmount}, {item.debitAmount}</p>
           }
         }) 
        } */}
        <Card key="mainPage" loading={loading}>
          <span style={{ fontSize: 20 }}>{jmbsName}</span>
          <br />
          <span>({jmbsNo})</span>
          <br />
          <span style={{ fontSize: 20 }}>Trial Balance</span>
          <span className="alignRight">
            As at selected date : {fromDateD} ~ {toDateD}
          </span>
          <Row gutter={10}>
            <Table
              title={() => "ASSETS"}
              dataSource={dataSourceASSETS}
              columns={columns}
              pagination={false}
              rowKey={"_id"}
              size={"small"}
              // rowClassName={this._getRowClassName}
              // expandedRowRender={this._expandRowRender}
            />
            <br />
            <Table
              title={() => "LIABILITIES"}
              dataSource={dataSourceLIABILITIES}
              columns={columns}
              pagination={false}
              rowKey={"_id"}
              size={"small"}
            />
            <br />
            <Table
              title={() => "INCOMES"}
              dataSource={dataSourceINCOMES}
              columns={columns}
              pagination={false}
              rowKey={"_id"}
              size={"small"}
            />
            <br />
            <Table
              title={() => "EXPENSES"}
              dataSource={dataSourceEXPENSES}
              columns={columns}
              pagination={false}
              rowKey={"_id"}
              size={"small"}
            />
            <br />
            <Table
              title={() => "EQUITY"}
              dataSource={dataSourceEQUITY}
              columns={columns}
              pagination={false}
              rowKey={"_id"}
              size={"small"}
              footer={() => (
                <Table
                  size="small"
                  dataSource={newTotal}
                  columns={newColumns}
                  showHeader={false}
                  bordered={false}
                  pagination={false}
                />
              )}
            />
          </Row>
        </Card>
      </div>
    );
  }
}

class IndexTrialBalance extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fromDate: moment(new Date("2020-05-27T14:26:42.195+08:00")).format(
        "YYYY-MM-DD"
      ),
      toDate: moment()
        .endOf("year")
        .format("YYYY-MM-DD"),
    };
  }

  fromDateOnChange = (e) => {
    this.setState({
      fromDate: moment(new Date(e)).format("YYYY-MM-DD"),
    });
  };

  toDateOnChange = (e) => {
    this.setState({
      toDate: moment(new Date(e)).format("YYYY-MM-DD"),
    });
  };

  render() {
    return (
      <Layout>
        <Row gutter={24}>
          <Col span={3} offset={16}>
            From Date :{" "}
            <DatePicker
              defaultValue={moment("2020-05-27T", ["YYYY-MM-DD"])}
              onChange={this.fromDateOnChange}
            />
          </Col>
          <Col span={3}>
            To Date :{" "}
            <DatePicker
              defaultValue={moment().endOf("year")}
              onChange={this.toDateOnChange}
            />
          </Col>
          <Col span={2}>
            <br />
            <ReactToPrint
              trigger={() => <Button type="primary">PRINT</Button>}
              content={() => this.componentRef}
            />
          </Col>
        </Row>
        <br />

        <ComponentToPrint
          ref={(el) => (this.componentRef = el)}
          companyId={this.props.commons.selectedCompany}
          fromDate={this.state.fromDate}
          toDate={this.state.toDate}
        />
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    //accounts: state.accounts
    commons: state.commons,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      //updateMode: updateMode
    },
    dispatch
  );
}

export default requireAuth(
  connect(mapStateToProps, mapDispatchToProps)(Form.create()(IndexTrialBalance))
);
