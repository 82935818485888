import _ from 'lodash';
import {
  FETCH_RECEIPTS,
  PUSH_RECEIPTS,
  REMOVE_RECEIPTS,
  UPDATE_RECEIPTS,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID
} from '../actions/actions-receipts';

const INITIAL_STATE = {
  receipts: [],
  mode: 'index',
  activeId: '',
  activeReceipts: {}
}


export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_RECEIPTS:
      return {
        ...state,
        receipts: action.payload,
      }
    case PUSH_RECEIPTS:
      return {
        ...state,
        receipts: [action.payload, ...state.receipts],
      }
    case REMOVE_RECEIPTS:
      return {
        ...state,
        receipts: [...state.receipts.filter((item) => item._id !== action.payload._id)]
      }
    case UPDATE_RECEIPTS:
      let index = _.findIndex(state.receipts, { '_id': action.payload._id })
      state.receipts.splice(index, 1, action.payload)
      return {
        ...state,
        receipts: state.receipts
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode: action.payload,
        activeId: ''
      }
    case UPDATE_ACTIVE_ID:
      return {
        ...state,
        mode: action.mode,
        activeId: action.id,
        activeReceipts: _.pick(_.find(state.receipts, { '_id': action.id }), ['supplierId', 'date', 'receiptNo', 'currency', 'totalAmount', 'subTotalAmount', 'taxAmount', 'remark', 'file', 'debitledgerAccountId', 'creditledgerAccountId', 'status', '_id', 'amount'])
      }
    default:
      return state

  }
}