import React, { Component } from 'react';
import { Modal, Avatar, Row, Col, Card, Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Select, Radio, Switch, Spin } from 'antd';
import client from '../../feathers';
import requireAuth from "../requireAuth";
import Layout from "../layout";
import Info from "../common/info";
import moment from 'moment';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchRefunds,
  pushRefunds,
  removeRefunds,
  updateMode,
  updateActiveRefunds,
  updateRefunds
} from '../../actions/actions-refunds';

const toWords = require('to-words');
const { TextArea } = Input;

const formatCurr = function format1(n = 0, currency = null) {
  let oriAmt = n;
  n = n < 0 ? Math.abs(n) : n;
  n = n ? n : 0;
  let amt = currency ? currency : ' ' + n.toFixed(2).replace(/./g, function (c, i, a) {
    return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
  });

  return oriAmt < 0 ? '(' + amt + ')' : amt;
}
const thisStyle = {
  padding: '0px'
};

class RefundsPrint extends Component {
  constructor(props) {
    super(props);

    this.state = {
      refunds: [],
      dataSource: [],
      unit: '',
      visible: false,
      setting: [],
      selectedRowKeys: [], // Check here to configure the default column,
      jmb: [],
      customer: [],
      owner: [],
      hasError: false,
      mailingAddress: '',
      loading: false,
      creditledgerAccountNo: '',
      creditledgerAccountName: '',
      debitledgerAccountNo: '',
      debitledgerAccountName: '',
      supplierName: '',
      supplierCode: '',
      preparebyName: '',
      payeeCode: '',
      totalAmount: 1,
      refundNo: '',
      debtorInfo: '',
      propertyunitInfo: '',
    }
  }

  componentWillMount() {

    this.setState({ loading: true });
    client.authenticate()
      .then((res) => {

        this.setState({
          preparebyName: res.firstName ? res.firstName : '' + ' ' + res.lastName ? res.lastName : ''
        });

        return client.service('refunds').find({
          query: {
            _id: this.props.refundsId,
            $populate: 'propertyunitId invoiceItemId',
          }
        })
      })
      .then((res) => {

        let seq = 0;
        let totalAmount = 0
        res.data.forEach(function (item, index) {
          item.seq = seq + 1;
          seq++;
          totalAmount = + item.refundAmt

        })

        this.setState({
          dataSource: res.data,
          refundNo: res.data[0].referenceId,
          loading: false,
          totalAmount: totalAmount,
          debtorInfo: res.data[0].propertyunitId.owner ? res.data[0].propertyunitId.owner : '',
          propertyunitInfo: res.data[0].propertyunitId ? res.data[0].propertyunitId : '',
          chequeNo: res.data[0].chequeNo,
        });
      })
      .catch((err) => {
        console.log(err);
      })

    client.authenticate()
      .then((res) => {

        this.setState({

          // payeeCode  : res.data[0].supplierId?res.data[0].supplierId.name:'',
          // refundDate  : res.data[0].paymentDate,
          // totalAmount  : res.data[0].paymentamount,
          // refundNo  : res.data[0].referenceId,
          // chequeNo  : res.data[0].chequeNo,
        });

      })

    this.getJmbInfo();
    this.getTnc();
  }

  getJmbInfo() {
    client.authenticate()
      .then((res) => {
        return client.service('jmbs').find({
          query: {
            propertyId: this.props.commons.selectedCompany._id,
            status: 'ACTIVE',
            $limit: 1,
            $sort: {
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {

        this.setState({
          jmb: res.data[0]
        });
      })
      .catch((err) => {
        console.log(err);
      });

  }

  getTnc() {
    client.authenticate()
      .then(() => {
        return client.service('settings').find({
          query: {
            propertyId: this.props.commons.selectedCompany._id
          }
        })
      })
      .then((res) => {
        //
        this.setState({
          setting: res.data[0].tnc,
        });
      })
      .catch((err) => {
        console.log(err);
      })
  }

  componentDidMount() {


  }

  onSelectChange = (selectedRowKeys) => {

    this.setState({ selectedRowKeys });
  }

  formatAmt(amt) {
    let tempAmt = amt;

    return tempAmt > 0 ? tempAmt.toFixed(2) : 0.00;
  }

  convertDate(date = null) {
    let tempDate = new Date(date);
    let d = tempDate.getDate();
    let m = tempDate.getMonth() + 1
    let y = tempDate.getFullYear();
    return date ? d + '/' + m + '/' + y : '';
  }

  _renderTable = () => {

    const { visible, onCancel } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { loading, selectedRowKeys, jmb, dataSource } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    let table = [];


    const columns = [
      {
        title: 'No',
        dataIndex: 'seq',
        key: 'seq',
        width: '10%',
        render: (text, record) => (
          <span style={{ fontSize: 11, padding: '0px' }} >
            {text}
          </span>
        ),
      },
      {
        title: 'Invoice Reference',
        dataIndex: 'invoiceItemId.docId',
        key: 'invoiceItemId.docId',
        width: '10%',
        render: (text, record) => (
          <span style={{ fontSize: 11, padding: '0px' }} >
            {text}
          </span>
        ),
      },
      {
        title: 'Invoice Description',
        dataIndex: 'invoiceItemId.description',
        key: 'invoiceItemId.description',
        width: '10%',
        render: (text, record) => (
          <span style={{ fontSize: 11, padding: '0px' }} >
            {text}
          </span>
        ),
      },
      {
        title: 'Description',
        dataIndex: 'remark',
        key: 'remark',
        width: '50%',
        render: (text, record) => (
          <span style={{ fontSize: 11, padding: '0px' }} >
            {text}
          </span>
        ),
      },
      {
        title: (
          <span>
            Payment Amount
          </span>),
        dataIndex: 'refundAmt',
        key: 'refundAmt',
        width: '20%',
        align: 'right',
        render: (text, record) => (
          <span style={{ fontSize: 11, padding: '0px', float: 'right' }} >
            {text ? record.refundAmt.toFixed(2) : '0.00'}
          </span>
        ),
      }

    ];


    table.push([
      <div key='main' >
        <Card>
          <Row>
            <Col span={3}>
              <img width='100%' src={jmb ? jmb.headerFile : ''} />
            </Col>
            <Col span={18} offset={1}>
              <p style={{ fontWeight: 'bolder', color: "#000" }}>{jmb.managementName} ({jmb ? jmb.registrationNo : ''})</p>
              <p style={{ marginTop: -15 }}>{jmb ? jmb.headerLine1 : ''} ,{jmb ? jmb.headerLine2 : ''} </p>
              <p style={{ marginTop: -15 }}>{jmb ? jmb.headerLine3 : ''}{jmb ? jmb.headerLine4 : ''}</p>
              <p ><span>{jmb ? 'Tel: ' + jmb.contactNo : ''}       |       {jmb ? 'Email: ' + jmb.email : ''}</span></p>
              <br />

            </Col>


          </Row>

          <Row gutter={8} >
            <Col span={24} align="middle">
              <h1 style={{ 'color': 'rgb(169,169,169)', 'letterSpacing': '10px' }}>REFUND</h1>
            </Col>

          </Row>

          <Row gutter={8} >
            <Col span={19}>
              <Row>
                <span>Units : {this.state.propertyunitInfo.referenceId} </span>
              </Row>
              <Row>
                <span>Name  : {this.state.debtorInfo.name ? this.state.debtorInfo.name : ''} </span>
              </Row>
            </Col>
            <Col span={5}>
              <span>Date : {moment(this.state.refundDate).format("YYYY-MM-DD")}  </span><br />
              <span>No.  : {this.state.refundNo}  </span><br />
              <span>Cheque No : {this.state.chequeNo ? this.state.chequeNo : '-'}  </span>
            </Col>
          </Row>
          <Divider dashed >Detail</Divider>

          <Table
            pagination={false}
            dataSource={dataSource}
            columns={columns}
            rowKey="_id"
            footer={() => (
              <Row>
                <Col style={{ float: 'right' }}>
                  <span style={{ textAlign: 'center' }} > TOTAL:{formatCurr(this.state.totalAmount)}</span>
                </Col>
              </Row>
            )} />

          <div key='moneysum' style={{ 'fontSize': 15, 'fontWeight': 'bold', 'marginTop': '50px' }}>

            <Row>
              <Col align="left">
                <span>{'The Sum of Amount : **' + toWords(this.state.totalAmount) + ' Ringgit Only**'}</span>
              </Col>
            </Row>
            <br />
          </div>

          <Row className="noBorder">

            <div key='footerpv' className="refundFooter">
              <div key='displaycontain' className="ql-display-container">
                <Row gutter={8} >
                  <Col span={8}>
                    <Card headStyle={{ 'padding': '0 6', 'fontSize': '12px' }} style={{ minHeight: '145px' }}
                      bordered={true}
                      title={<span style={{ 'padding': '0 6' }}>Title</span>} >
                      <p style={{ 'lineHeight': 0.2, 'fontSize': '10px' }} >{this.state.preparebyName}</p>
                    </Card>
                  </Col>
                  <Col span={8} >
                    <Card headStyle={{ 'padding': '0 6', 'fontSize': '12px' }} style={{ minHeight: '145px' }} bordered={true} title="VERIFIED BY">
                      <div key='verifiedby' style={{ 'lineHeight': 0.2, 'fontSize': '10px' }} >
                        <p>Sign: </p>
                        <p>Name: </p>
                      </div>
                    </Card>
                  </Col>
                  <Col span={8} >
                    <Card headStyle={{ 'padding': '0 6', 'fontSize': '12px' }} style={{ minHeight: '145px' }} bordered={true} title="RECEIVED BY">
                      <div key='receiveby' style={{ 'lineHeight': 0.2, 'fontSize': '10px' }} >
                        <p>Sign: </p>
                        <p>Name: </p>
                        <p>IC No: </p>
                        <p>Date: </p>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </div>
              <div key='footer' align="right" >© 2021 Mr Tech System Holdings Sdn Bhd (1370083-M)</div>
            </div>
          </Row>

        </Card>
      </div>
      ,
      <div key='pagebreak' className='pageBreak' />
    ])

    return table;
  }

  render() {
    const loading = this.state.loading;
    return (
      <Spin key='spinning' spinning={loading}>
        {this._renderTable()}
      </Spin>
    );
  }
}



function mapStateToProps(state) {
  return {
    refunds: state.refunds,
    commons: state.commons
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchRefunds: fetchRefunds,
    pushRefunds: pushRefunds,
    removeRefunds: removeRefunds,
    updateActiveRefunds: updateActiveRefunds,
    updateRefunds: updateRefunds,
    updateMode: updateMode
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(RefundsPrint));
