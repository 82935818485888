import React, { Component } from 'react';
import { Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Card,Spin } from 'antd';
import { Route, Link } from 'react-router-dom';

import client from '../../feathers';
import requireAuth from "../../components/requireAuth";
import Layout from "../../components/layout";
import PinboardForm from "../../components/pinboard/form";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    fetchPinboard,
    pushPinboard,
    removePinboard,
    updatePinboardMode,
    updateActivePinboard,
    updatePinboard
} from '../../actions/actions-pinboard';
import userproperties from '../userproperties';
import moment from 'moment';
import _ from 'lodash';

const FormItem = Form.Item;

const text = 'Are you sure to delete this item?';

class PinboardIndex extends Component {
    constructor(props) {
        super(props);

        this.state={
            loading:true
        }

        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidUpdate() {
        if(this.props.pinboard.mode === 'refresh'){
            this.props.updateActivePinboard('','')
            this.componentWillMount();
        }

    }

    componentDidMount() {
        // client.service('ads').on('created', (ads) => {
        //   this.props.pushAds(ads)
        // })

        // client.service('ads').on('removed', (ads) => {
        //   this.props.removeAds(ads)
        // })

        // client.service('ads').on('updated', (ads) => {
        //   this.props.updateAd(ads)
        // })
    }

    componentWillMount() {
        this.setState({loading:true})

        let propertyValue=''

        try {
         propertyValue=this.props.user.info.propertyId._id

        }
        catch (err) {
          console.log(err)
        }


        client.authenticate()
            .then(() => {
                return client.service('pinboard').find({
                    query: {
                        propertyId:propertyValue,
                        $sort: {
                            createdAt: -1
                        }
                    }
                })
            })
            .then((res) => {
                this.props.fetchPinboard(res.data)
                this.setState({loading:false})

            })
            .catch((err) => {
                console.log(err);
            })
    }

    handleDelete(_id) {
        client.service('pinboard').remove(_id)
            .then((res) => {
            })
            .catch((err) => {
                alert(err);
            })
    }

    handleEdit(_id) {
        this.props.updateActivePinboard('edit', _id)
    }

    //   handleManage(_id) {
    //     this.props.updateActiveAd('', _id)
    //   }

    showModal = () => {
        this.props.updateActivePinboard('new', '')
    }

    renderConfirmDeleteBtn(_id) {
        return (
            <Popconfirm placement="topLeft" title={text} onConfirm={() => this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
                <Button type="danger">Delete</Button>
            </Popconfirm>
        )
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        let userRole = this.props.user.info.user.role[0]
        

        const columns = [
            {
                title: 'Thumbnail',
                key: 'imagesSrc',
                dataIndex: 'imagesSrc',
                width: '20%',
                render: (text, record) => {
                    if (!_.isEmpty(text)) {
                        return (
                            <div className='wrap-zoom-image'>
                                <img  alt='thumbnail'style={{ height: '150px', width: '200px' }} src={text} />
                            </div>

                        )

                    }
                    else{
                        return(
                            <span>
                                No Images found
                            </span>
                        )
                    }

                }
      
              
      
              
            ,
    },
        
        
    {
    title: 'Title',
        key: 'title',
            dataIndex: 'title',
                width: '20%',
    //   render: (text, record) => (

    //     <div className='wrap-zoom-image'>
    //       <img style={{ height: '150px' ,width:'200px' }} src={record.file} />
    //     </div>


    //   ),
    },
{
    title: 'Description',
        dataIndex: 'description',
            key: 'description',
      
    },

// {
//     title: 'Start Date',
//     dataIndex: 'startDate',
//     key: 'startDate',
//     width: '20%',
//     render:(data,record)=>{
//         return (
//             moment(data).format('DD/MM/YYYY')
//         )
//     }
// },

// {
//     title: 'End Date',
//     dataIndex: 'endDate',
//     key: 'endDate',
//     width: '20%',
//     render:(data,record)=>{
//         return (
//             moment(data).format('DD/MM/YYYY')
//         )
//     }
// },
{
    title: 'Action',
        key: 'action',
            width: '20%',
                render: (text, record) => (
                    <span>
                        <Divider type="vertical" />
                        <Button onClick={() => this.handleEdit(record._id)}>Edit</Button>
                        <Divider type="vertical" />
                        {this.renderConfirmDeleteBtn(record._id)}
                    </span>
                ),
    }];


return (
    <Layout>
        <Spin spinning={this.state.loading}>
        <Card
            title='Pinboard'

            extra={<Button type="primary" onClick={this.showModal}>{'Create Pinboard'}</Button>}
        >
            <Table dataSource={this.props.pinboard.pinboard} columns={columns} rowKey="_id" />
            <PinboardForm />
            
        </Card>
        </Spin>

    </Layout>
)
  }
}


function mapStateToProps(state) {
    return {

        pinboard: state.pinboard
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchPinboard: fetchPinboard,
        pushPinboard: pushPinboard,
        removePinboard: removePinboard,
        updateActivePinboard: updateActivePinboard,
        updatePinboard: updatePinboard,
        updatePinboardMode: updatePinboardMode
    }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(PinboardIndex)));
