import React, { Component } from 'react'
import { Avatar, Row, Col, Card, Popconfirm, message, Divider, Table, Form, Icon, Input, Button } from 'antd'
import client from '../../feathers'
import requireAuth from "../../components/requireAuth"
import Layout from "../../components/layout"
import Info from "../../components/common/info"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  fetchManagements,
  pushManagements,
  removeManagements,
  updateMode,
  updateActiveManagement,
  updateManagement
} from '../../actions/managements-actions'


const FormItem = Form.Item;

class ManagementView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      management: []
    }
  }

  componentWillMount() {
    console.log(' render here')
    client.authenticate()
      .then(() => {
        return client.service('managements').find({
          query: {
            _id: this.props.match.params.id,
            $populate: 'propertyId',
            $limit: 1
          }

        })
      })
      .then((res) => {
        console.log('been herre')
        this.setState({
          management: res.data[0]
        },
          () => { })

      })
      .catch((err) => {
        console.log(err);
      })
  }

  componentDidMount() {
    client.service('management').on('updated', (managements) => {
      this.props.updateManagement(managements)
    })
  }

  render() {
    let initial = this.state.management.email ? this.state.management.email.toString().split("@") : '';
    return (
      <Layout>


        <Row gutter={8}>
          <Col span={24}>
            <Card title="Company Profile">
              {/*<Avatar style={{ margin:'0 35% 10% 45% ', backgroundColor: '#87cefa', verticalAlign: 'middle' }} size={86}>
                {initial[0]}
              </Avatar>*/}
              <Info label="Company Name" labelSpan={6} value={this.state.management.name} valueSpan={18} />
              <Info label="SSM No" labelSpan={6} value={this.state.management.ssm} valueSpan={18} />

              <Divider dashed >Detail</Divider>
              <Info label="Property Name" labelSpan={6} value={this.state.management.propertyId ? this.state.management.propertyId.name : ''} valueSpan={18} />
              <Info label="Company Address" labelSpan={6} value={this.state.management.address1} valueSpan={18} />
              <Info label="" labelSpan={6} value={this.state.management.address2} valueSpan={18} />
              <Info label="City" labelSpan={6} value={this.state.management.city} valueSpan={18} />
              <Info label="State" labelSpan={6} value={this.state.management.state} valueSpan={18} />
              <Info label="PostCode" labelSpan={6} value={this.state.management.zipCode} valueSpan={18} />
              <Info label="Country" labelSpan={6} value={this.state.management.country} valueSpan={18} />
              <Info label="Contact Number" labelSpan={6} value={this.state.management.mobile} valueSpan={18} />
              <Info label="Fax Number" labelSpan={6} value={this.state.management.fax} valueSpan={18} />
              <Info label="Company Email" labelSpan={6} value={this.state.management.email} valueSpan={18} />
            </Card>
          </Col>

          {/*<Col span={16}>
            <Row gutter={8}>
              <Card title="Management">
              </Card>
            </Row>
            <Row gutter={8}>
              <Card title="Property">
              </Card>
            </Row>
          </Col>*/}
        </Row>

      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    management: state.management,
    user: state.user
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchManagements: fetchManagements,
    pushManagements: pushManagements,
    removeManagements: removeManagements,
    updateActiveManagement: updateActiveManagement,
    updateManagement: updateManagement,
    updateMode: updateMode
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagementView);
