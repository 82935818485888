import _ from 'lodash';
import {
  FETCH_ACCOUNTS,
  PUSH_ACCOUNTS,
  REMOVE_ACCOUNTS,
  UPDATE_ACCOUNTS,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID } from '../actions/actions-accounts';

const INITIAL_STATE = {
  accounts: [],
  mode: 'index',
  activeId: '',
  activeAccounts:{}
}


export default function(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_ACCOUNTS:
      return {
        ...state,
        accounts:action.payload,
      }
    case PUSH_ACCOUNTS:
      return {
        ...state,
        accounts:[action.payload, ...state.accounts],
      }
    case REMOVE_ACCOUNTS:
      return {
        ...state,
        accounts : [...state.accounts.filter( (item) => item._id !== action.payload._id)]
      }
    case UPDATE_ACCOUNTS:
      let index =  _.findIndex(state.accounts, { '_id': action.payload._id })
      state.accounts.splice(index, 1, action.payload)
      return {
        ...state,
        accounts : state.accounts
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode : action.payload,
        activeId:''
      }
    case UPDATE_ACTIVE_ID:
      return {
        ...state,
        mode : action.mode,
        activeId:action.id,
        activeAccounts: _.pick(_.find(state.accounts, { '_id': action.id }), ['role', 'roleId', 'categories', 'accountType','accountName','accountCurrency','accountId','description', '_id'])
      }
    default:
      return state

  }
}
