export const FETCH_PROPERTIES = 'FETCH_PROPERTIES';
export const PUSH_PROPERTIES = 'PUSH_PROPERTIES';
export const REMOVE_PROPERTIES = 'REMOVE_PROPERTIES';
export const UPDATE_PROPERTIES = 'UPDATE_PROPERTIES';
export const UPDATE_MODE = 'UPDATE_MODE';
export const UPDATE_ACTIVE_ID = 'UPDATE_ACTIVE_ID';

export function fetchProperties(data){
  return (dispatch) => {
    dispatch({
      type: FETCH_PROPERTIES,
      payload:data
    });
  }
}

export function pushProperties(data){
  return (dispatch) => {
    dispatch({
      type: PUSH_PROPERTIES,
      payload:data
    });
  }
}

export function removeProperties(data){
  return (dispatch) => {
    dispatch({
      type: REMOVE_PROPERTIES,
      payload:data
    });
  }
}

export function updateProperties(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_PROPERTIES,
      payload:data
    });
  }
}

export function updateMode(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE,
      payload:data
    });
  }
}

export function updateActiveProperty(mode, id){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_ID,
      mode,
      id
    });
  }
}
