import React, { Component } from 'react'
import { connect } from "react-redux"
//import client from '../feathers'

import { bindActionCreators } from "redux"
import { pushProperties, removeProperties } from '../actions/properties-actions'
import { Redirect } from 'react-router';

export default ChildComponent => {
  class ComposedComponent extends Component {
    constructor(props) {
      super(props);

      this.state={
        logout:false
      }
    }

    componentDidMount(){
      this.handleNavigation();
    }

    componentDidUpdate(){
      this.handleNavigation();
    }

    handleNavigation(){

      if( !window.sessionStorage.getItem("token") ){
        this.setState({
          logout:true
        })
      }
    }

    render() {
      if(this.state.logout === true){
        return <Redirect to='/' />
      }
      return <ChildComponent {...this.props} />;
    }
  }


  function mapStateToProps(state, ownProps) {
    return {
      user: state.user,
      ownProps: ownProps
    }
  }

  function mapDispatchToProps(dispatch) {
    return bindActionCreators({
      pushProperties: pushProperties,
      removeProperties: removeProperties,
      }, dispatch);
  }

  return connect(mapStateToProps, mapDispatchToProps)(ComposedComponent);
}
