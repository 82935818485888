import React, { Component } from "react";
import {
  DatePicker,
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  InputNumber,
  Select,
  Col,
  Row,
  Card,
} from "antd";
import { Route, Link } from "react-router-dom";

import client from "../../feathers";
import requireAuth from "../requireAuth";
import Layout from "../layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PaymentFormItems from "./form-payment-items";
import {
  fetchInvoiceitems,
  pushInvoiceitems,
  removeInvoiceitems,
  updateMode,
  updateActiveInvoiceitems,
  updateInvoiceitems,
} from "../../actions/actions-invoiceitems";
import "../../App.css";
import moment from "moment";

const _ = require("lodash");
const text = "Are you sure to delete this item?";
const FormItem = Form.Item;
const EditableContext = React.createContext();
const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);
const EditableFormRow = Form.create()(EditableRow);
const Option = Select.Option;
const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const config = {
  initialValue: moment(),
  rules: [
    {
      type: "object",
      required: true,
      message: "Please select date!",
    },
  ],
};

class EditableCell extends React.Component {
  state = {
    editing: false,
    charges: [],
  };

  componentWillMount() {
    // console.log('been here')
    // let companyId = this.props.commons.selectedCompany._id
    // client.authenticate()
    //   .then((res) => {
    //     return client.service('charges').find({
    //       query: {
    //         propertyId: companyId,
    //       }
    //     })
    //   })
    //   .then((res) => {
    //     this.setState({
    //       charges: res.data,
    //     });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = () => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  getInput = () => {
    if (this.props.inputType === "number") {
      return (
        <InputNumber
          ref={(node) => (this.input = node)}
          onPressEnter={this.save}
          onBlur={this.save}
        />
      );
    } else if (this.props.inputType === "month") {
      //monthNames:['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December','All']
      return (
        <Select
          ref={(node) => (this.input = node)}
          onPressEnter={this.save}
          onBlur={this.save}
          placeholder="Please Month"
          style={{ width: "100%" }}
        >
          <Option value="1">1</Option>
          <Option value="2">2</Option>
          <Option value="3">3</Option>
          <Option value="4">4</Option>
          <Option value="5">5</Option>
          <Option value="6">6</Option>
          <Option value="7">7</Option>
          <Option value="8">8</Option>
          <Option value="9">9</Option>
          <Option value="10">10</Option>
          <Option value="11">11</Option>
          <Option value="12">12</Option>
        </Select>
      );
    } else if (this.props.inputType === "chargeId") {
      return (
        <Select
          ref={(node) => (this.input = node)}
          onPressEnter={this.save}
          onBlur={this.save}
          placeholder="Select Fee"
          style={{ width: "100%" }}
        >
          {this.state.charges.map((dropdown) => (
            <Option value={dropdown.code}>{dropdown.code}</Option>
          ))}
        </Select>
      );
    } else {
      return (
        <Input
          ref={(node) => (this.input = node)}
          onPressEnter={this.save}
          onBlur={this.save}
        />
      );
    }
  };

  render() {
    const { editing } = this.state;
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      ...restProps
    } = this.props;

    return (
      <td ref={(node) => (this.cell = node)} {...restProps}>
        {editable ? (
          <EditableContext.Consumer>
            {(form) => {
              this.form = form;
              return editing ? (
                <FormItem style={{ margin: 0 }}>
                  {form.getFieldDecorator(dataIndex, {
                    rules: [
                      {
                        required: true,
                        message: `${title} is required.`,
                      },
                    ],
                    initialValue: record[dataIndex],
                  })(
                    this.getInput()
                    // <Input
                    //   ref={node => (this.input = node)}
                    //   onPressEnter={this.save}
                    //   onBlur={this.save}
                    // />
                  )}
                </FormItem>
              ) : (
                <div
                  className="editable-cell-value-wrap"
                  style={{ paddingRight: 24 }}
                  onClick={this.toggleEdit}
                >
                  {restProps.children}
                </div>
              );
            }}
          </EditableContext.Consumer>
        ) : (
          restProps.children
        )}
      </td>
    );
  }
}

class IndexKnockOffByInvoice extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        title: "Doc Id",
        dataIndex: "docId",
        key: "docId",
        width: "15%",
      },
      {
        title: "Description",
        dataIndex: "description",
        width: "15%",
        key: "description",
      },
      {
        title: "Status",
        dataIndex: "status",
        width: "10%",
        key: "",
      },
      {
        title: "Due Date",
        dataIndex: "dueDate",
        width: "15%",
        key: "dueDate",
        render: (text, record) => (
          <span>{record.dueDate ? this.convertDate(record.dueDate) : ""}</span>
        ),
      },
      {
        title: "Charge Amount",
        dataIndex: "appliedAmount",
        width: "15%",
        key: "appliedAmount",
        render: (text, record) => (
          <span style={{ float: "right" }}>
            {this.formatAmt(record.appliedAmount)}
          </span>
        ),
      },
      {
        title: "Amount Paid",
        dataIndex: "knockOffBalance",
        width: "15%",
        key: "knockOffBalance",
        //editable: true,
        render: (text, record) => (
          <span style={{ float: "right" }}>
            {this.formatAmt(record.knockOffBalance)}
          </span>
        ),
      },
      {
        title: "KnockOff Amount",
        dataIndex: "knockOffAmount",
        width: "15%",
        key: "knockOffAmount",
        editable: true,
        render: (text, record) => (
          <span style={{ float: "right" }}>
            {this.formatAmt(record.knockOffAmount)}
          </span>
        ),
      },
    ];

    this.columnsPrepayment = [
      {
        title: "Charges",
        dataIndex: "chargeId",
        width: "20%",
        key: "chargeId",
        editable: true,
      },
      {
        title: "Year",
        dataIndex: "year",
        width: "20%",
        key: "year",
        editable: true,
      },
      {
        title: "Next Process Month",
        dataIndex: "month",
        key: "month",
        width: "20%",
        editable: true,
      },
      {
        title: "Amount",
        dataIndex: "amount",
        width: "20%",
        editable: true,
        key: "amount",
      },
      {
        title: "operation",
        dataIndex: "operation",
        key: "operation",
        render: (text, record) =>
          this.state.dataSourcePrepayment.length >= 1 ? (
            <Popconfirm
              key="confirmation"
              title="Sure to delete?"
              onConfirm={() => this.handleDelete(record.key)}
            >
              <a href="javascript:;">Delete</a>
            </Popconfirm>
          ) : null,
      },
    ];

    this.state = {
      prepaymentTotalAmount: 0,
      itemTotalAmount: 0,
      selectedAmount: 0,
      totalAmount: 0,
      propertyUnit: {},
      loading: false,
      dataSource: [],
      dataSourcePrepayment: [],
      selectedRowKeys: [],
      count: 0,
      charges: [],
      selectedRows: [],
      paymentDate: moment(new Date()).format("YYYY-MM-DD"),
    };
  }

  componentDidUpdate() {
    if (this.state.totalAmount !== this.props.form.getFieldValue("payAmount")) {
      this.setState({ payAmount: this.state.totalAmount });
      this.props.form.setFieldsValue({ payAmount: this.state.totalAmount });
    }
  }

  componentDidMount() {
    client.service("invoiceitems").on("created", (invoiceitems) => {
      if (sessionStorage.getItem("companyId") === invoiceitems.propertyId) {
        this.componentWillMount();
      }
    });

    client.service("invoice-items").on("removed", (invoiceitems) => {
      if (sessionStorage.getItem("companyId") === invoiceitems.propertyId) {
        this.componentWillMount();
      }
    });

    client.service("invoice-items").on("updated", (invoiceitems) => {
      if (sessionStorage.getItem("companyId") === invoiceitems.propertyId) {
        this.componentWillMount();
      }
    });

    client.service("invoice-items").on("patched", (invoiceitems) => {
      if (sessionStorage.getItem("companyId") === invoiceitems.propertyId) {
        this.componentWillMount();
      }
    });
  }

  componentWillMount() {
    let companyId = this.props.commons.selectedCompany._id;

    this.setState({ loading: true });
    client
      .authenticate()
      .then((res) => {
        return client.service("invoice-items").find({
          query: {
            $populate: "propertyId invoiceId",
            propertyId: companyId,
            //propertyunitId:this.props.match.params.id,
            invoiceId: this.props.match.params.id,
            status: { $in: ["ISSUED", "PARTIAL"] },
            $sort: {
              effectiveDate: -1,
              docId: 1,
            },
          },
        });
      })
      .then((res) => {
        this.setState({
          dataSource: res.data,
          loading: false,
        });
        this.checkedAllCheckedBox();
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  }

  checkedAllCheckedBox() {
    let selectedRowKeys = [];
    let selectedRows = [];
    let newData = [];
    let totalSelectedAmt = 0;
    let totalKnockOffAmt = 0;
    for (let i = 0; i < this.state.dataSource.length; i++) {
      this.state.dataSource[i].knockOffAmount =
        this.state.dataSource[i].appliedAmount -
        this.state.dataSource[i].knockOffBalance;
      newData.push(this.state.dataSource[i]);
      selectedRows.push(this.state.dataSource[i]);
      selectedRowKeys.push(this.state.dataSource[i]._id);
      totalSelectedAmt += this.state.dataSource[i].knockOffAmount;
      totalKnockOffAmt += this.state.dataSource[i].knockOffAmount;
    }
    setTimeout(() => {
      this.setState({
        selectedRowKeys: selectedRowKeys,
        selectedRows: selectedRows,
        dataSource: newData,
        selectedAmount: totalSelectedAmt,
        itemTotalAmount: totalSelectedAmt,
        totalAmount: parseFloat(
          totalSelectedAmt + this.state.prepaymentTotalAmount
        ).toFixed(2),
      });
    }, 1000);
  }

  formatAmt(amt) {
    let integer = parseFloat(amt);
    return integer > 0 ? integer.toFixed(2) : 0.0;
  }

  convertDate(date) {
    let tempDate = new Date(date);
    let d = tempDate.getDate();
    let m = tempDate.getMonth() + 1;
    let y = tempDate.getFullYear();
    return d + "/" + m + "/" + y;
  }

  handleDelete = (key) => {
    const dataSourcePrepayment = [...this.state.dataSourcePrepayment];

    this.setState({
      dataSourcePrepayment: dataSourcePrepayment.filter(
        (item) => item.key !== key
      ),
    });

    setTimeout(() => {
      //recalculate
      let tempTotal = 0;
      for (let i = 0; i < this.state.dataSourcePrepayment.length; i++) {
        tempTotal += this.state.dataSourcePrepayment[i].amount;
      }
      this.setState({ prepaymentTotalAmount: tempTotal });
    }, 1000);
  };

  handleAdd = () => {
    const { count, dataSourcePrepayment } = this.state;
    const newData = {
      key: count,
      chargeId: `MNTFEE`,
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 2,
      amount: 0,
    };

    this.setState({
      dataSourcePrepayment: [...dataSourcePrepayment, newData],
      count: count + 1,
    });
  };

  handleSave = (row) => {
    //dataSource
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row._id === item._id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });

    //dataSelectedrow
    const newData2 = [...this.state.selectedRows];
    const index2 = newData2.findIndex((item) => row._id === item._id);
    const item2 = newData2[index2];
    newData2.splice(index2, 1, {
      ...item2,
      ...row,
    });

    let amt = 0;
    for (let i = 0; i < newData2.length; i++) {
      amt += parseFloat(newData2[i].knockOffAmount);
    }

    this.setState({
      dataSource: newData,
      selectedRows: newData2,
      totalAmount: amt,
    });
  };

  // handleSavePrepayment = (row) => {
  //   const newData = [...this.state.dataSourcePrepayment];
  //   const index = newData.findIndex((item) => row.key === item.key);
  //   const item = newData[index];
  //   newData.splice(index, 1, {
  //     ...item,
  //     ...row,
  //   });

  //   let tempTotal = 0;
  //   for (let i = 0; i < newData.length; i++) {
  //     tempTotal += newData[i].amount;
  //   }

  //   this.setState({
  //     dataSourcePrepayment: newData,
  //     prepaymentTotalAmount: tempTotal,
  //     totalAmount: tempTotal + this.state.itemTotalAmount,
  //   });
  // };

  getInputStyle = (dataType) => {
    if (dataType === "amount") {
      return "number";
    } else if (dataType === "month") {
      return "month";
    } else if (dataType === "chargeId") {
      return "chargeId";
    } else {
      return "text";
    }
  };

  handleChangeTotalAmt = (e) => {
    this.setState({ totalAmount: e });

    let tempValue = 0;
    let tempAddOn = 0;
    let oriAmt = e;
    let dataSource = this.state.dataSource;
    let newData = [];
    let stopper = false;

    for (let i = 0; i < dataSource.length; i++) {
      let isChecked = _.includes(this.state.selectedRowKeys, dataSource[i]._id);
      if (isChecked) {
        if (stopper === true) {
          dataSource[i].knockOffAmount = 0.0;
        } else if (
          tempValue +
            (dataSource[i].appliedAmount - dataSource[i].knockOffBalance) <=
          e
        ) {
          tempValue =
            dataSource[i].appliedAmount - dataSource[i].knockOffBalance;
          tempAddOn +=
            dataSource[i].appliedAmount - dataSource[i].knockOffBalance;

          if (tempAddOn >= e) {
            dataSource[i].knockOffAmount = parseFloat(oriAmt);
            stopper = true;
          } else {
            dataSource[i].knockOffAmount = tempValue;
            oriAmt -=
              dataSource[i].appliedAmount - dataSource[i].knockOffBalance;
          }
        } else {
          dataSource[i].knockOffAmount = e - tempValue;
          stopper = true;
        }

        newData.push(dataSource[i]);
      } else {
        dataSource[i].knockOffAmount = 0.0;
        newData.push(dataSource[i]);
      }
    }

    this.setState({ dataSource: newData });
  };

  renderFooter() {
    return [
      <Row>
        <Col offset={18} span={3}>
          Total Amount
          <Input
            style={{ color: "red", width: "100%" }}
            value={this.formatAmt(this.state.selectedAmount)}
            disabled={true}
          />
        </Col>
        <Col span={3}>
          Total KnockOff
          <InputNumber
            style={{ color: "green", width: "100%" }}
            onChange={this.handleChangeTotalAmt}
            value={this.state.totalAmount}
          />
        </Col>
      </Row>,
    ];
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };
  //------------------------------------------------------
  _paymentDateChange = (e) => {
    this.setState({
      paymentDate: moment(new Date(e)).format("YYYY-MM-DD"),
    });
  };
  //------------------------------------------------------
  render() {
    const {
      dataSource,
      dataSourcePrepayment,
      selectedRowKeys,
      loading,
      selectedRows,
    } = this.state;
    const { getFieldDecorator } = this.props.form;
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };

    // const rowSelection = {
    //   selectedRowKeys,
    //   onChange: this.onSelectChange,
    // };

    // rowSelection object indicates the need for row selection
    const rowSelection = {
      selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        let tempAmt = 0;
        let newData = [...this.state.dataSource];
        for (let i = 0; i < selectedRows.length; i++) {
          tempAmt +=
            selectedRows[i].appliedAmount - selectedRows[i].knockOffBalance;
        }

        if (selectedRows.length > 0) {
          //start handle latest selected row
          selectedRows[selectedRows.length - 1].knockOffAmount =
            selectedRows[selectedRows.length - 1].appliedAmount -
            selectedRows[selectedRows.length - 1].knockOffBalance;
          const index = newData.findIndex(
            (item) => selectedRows[selectedRows.length - 1]._id === item._id
          );
          const item = newData[index];
          newData.splice(index, 1, {
            ...item,
            ...selectedRows[selectedRows.length - 1],
          });
          //end handle latest selected row
        }

        this.setState({
          selectedRows: selectedRows,
          selectedRowKeys: selectedRowKeys,
          selectedAmount: tempAmt,
          dataSource: newData,
          totalAmount: parseFloat(tempAmt).toFixed(2),
        });
      },
    };

    const hasSelected = selectedRows.length > 0;
    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });

    const columnsPrepayment = this.columnsPrepayment.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: this.getInputStyle(col.dataIndex), //col.dataIndex === 'amount' ? 'number' : 'text',
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSavePrepayment,
        }),
      };
    });

    return (
      <Layout>
        <Card key="maintAction" title={"Official Receipt"}>
          {/* <Button onClick={this.handleAdd} type="primary" style={{ marginTop: 16 }}>
            Add a Prepayment
          </Button> */}
          <Row style={{ height: 700 }}>
            <Col span="18">
              {hasSelected ? `Selected ${selectedRows.length} items` : ""}

              <Table
                rowSelection={rowSelection}
                components={components}
                rowClassName={() => "editable-row"}
                bordered
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                scroll={{ y: 600 }}
                rowKey="_id"
                key="tables"
              />

              {/* <Table
                components={components}
                rowClassName={() => 'editable-row'}
                bordered
                dataSource={dataSourcePrepayment}
                columns={columnsPrepayment}
                pagination={false}
                footer={()=>this.renderFooter()}
              /> */}
            </Col>
            <Col span="6">
              <Form.Item {...formItemLayout} label="Payment Date">
                {getFieldDecorator(
                  "paymentDate",
                  config
                )(<DatePicker onChange={this._paymentDateChange} />)}
              </Form.Item>

              <FormItem {...formItemLayout} label="Payment Amt">
                {getFieldDecorator("payAmount", {
                  rules: [
                    {
                      required: true,
                      message: "Please input Amount!",
                    },
                  ],
                })(<Input disabled />)}
              </FormItem>

              <PaymentFormItems
                // selectedRowKeys={this.state.selectedRowKeys}
                paymentDate={this.state.paymentDate}
                invoiceItems={this.state.selectedRows}
                payAmount={this.state.totalAmount}
                totalAmount={this.state.totalAmount}
                propertyunitId={
                  this.state.dataSource[0]
                    ? this.state.dataSource[0].propertyunitId
                    : ""
                }
                prepayment={this.state.dataSourcePrepayment}
              />
            </Col>
          </Row>
        </Card>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    invoiceitems: state.invoiceitems,
    commons: state.commons,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchInvoiceitems: fetchInvoiceitems,
      pushInvoiceitems: pushInvoiceitems,
      removeInvoiceitems: removeInvoiceitems,
      updateActiveInvoiceitems: updateActiveInvoiceitems,
      updateInvoiceitems: updateInvoiceitems,
      updateMode: updateMode,
    },
    dispatch
  );
}

export default requireAuth(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Form.create()(IndexKnockOffByInvoice))
);
