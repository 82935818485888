import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Pagination,
  Popconfirm,
  Row,
  Spin,
  Table,
} from 'antd';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  fetchRefunds,
  pushRefunds,
  removeRefunds,
  updateActiveRefunds,
  updateMode,
  updateRefunds,
} from '../../actions/actions-refunds';
import Layout from '../../components/layout';
import requireAuth from '../../components/requireAuth';
import client from '../../feathers';
import { CSVLink } from 'react-csv';
import { updateActivePage } from '../../actions/app-actions';

const FormItem = Form.Item;
const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const { MonthPicker, RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';
const text = 'Are you sure to delete this item?';

class RefundsIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalPagination: 1,
      searchReceiptNo: '',
      searchAmount: '',
      loading: false,
      printModal: false,
      allData: [],
      excelHeader: [
        {
          label: 'Refund Date',
          key: 'date',
        },
        {
          label: 'Reference',
          key: 'referenceId',
        },
        {
          label: 'Payment Type',
          key: 'paymentType',
        },
        {
          label: 'Customer Name',
          key: 'customerName',
        },
        {
          label: 'Unit No',
          key: 'unitNo',
        },
        {
          label: 'Refund Amount',
          key: 'refundAmt',
        },
      ],
    };

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidUpdate() {
    if (this.props.refunds.mode === 'refresh') {
      this.componentWillMount();
    }

    if (this.props.refunds.activeId !== this.state._id) {
      this.setState({ _id: this.props.refunds.activeId });

      if (this.props.refunds.activerefunds) {
        console.log(this.props.refunds.activerefunds);
        this.props.form.setFieldsValue(this.props.refunds.activerefunds);
      }
    }
  }

  componentDidMount() {}

  componentWillMount() {
    this.props.updateMode('');
    this.setState({
      loading: true,
    });
    const activePage = this.props.activePage.refundPage;

    if (activePage > 1) {
      this.handlePageChange(activePage);
    } else {
      client
        .authenticate()
        .then((res) => {
          client
            .service('refunds')
            .find({
              query: {
                propertyId: this.props.commons.selectedCompany._id,
                $limit: 20,
                $populate: 'paymentTypeParamId propertyunitId invoiceItemId ',
                $sort: {
                  createdAt: -1,
                },
              },
            })
            .then((res) => {
              console.log('akadk', res.total);
              this.setState({
                totalPagination: res.total,
                loading: false,
              });
              this.props.fetchRefunds(res.data);
              this._getAllData();
            });
        })

        .catch((err) => {
          this.setState({ loading: false });
          console.log(err);
        });
    }
  }

  _getAllData() {
    client
      .authenticate()
      .then((res) => {
        client
          .service('refunds')
          .find({
            query: {
              propertyId: this.props.commons.selectedCompany._id,
              $populate: 'paymentTypeParamId propertyunitId invoiceItemId ',
              $sort: {
                createdAt: -1,
              },
            },
          })
          .then((res) => {
            this.setState({
              allData: res.data,
              loading: false,
            });
          });
      })

      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
  }

  handleDelete(record) {
    this.setState({
      loading: true,
    });

    axios
      .post(
        `${client.io.io.uri}removeTrx`,
        {
          _id: record._id,
          companyId: this.props.commons.selectedCompany._id,
          trxType: 'RF',
          invoiceItemId: record.invoiceItemId,
        },
        {
          headers: {
            Authorization: client.settings.storage.storage['feathers-jwt'],
          },
        }
      )
      .then((res) => {
        this.props.updateMode('refresh');
        this.setState({
          loading: false,
        });

        message.success('Record Deleted');
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleEdit(_id) {
    this.props.updateActiveRefunds('edit', _id);
    this.setState({ printModal: true });
  }

  handleManage(_id) {
    this.props.updateActiveRefunds('', _id);
  }

  showModal = () => {
    this.props.updateMode('new');
  };

  handlePageChange = (page) => {
    this.props.updateActivePage({
      refundPage: page,
    });
    this.props.form.validateFields((err, values) => {
      client
        .authenticate()
        .then((res) => {
          return client.service('refunds').find({
            query: {
              propertyId: this.props.commons.selectedCompany._id,
              $limit: 20,
              $skip: (page - 1) * 20,
              $populate: 'paymentTypeParamId propertyunitId',
              $sort: {
                createdAt: -1,
              },
            },
          });
        })
        .then((res) => {
          this.setState({
            totalPagination: res.total,
            loading: false,
          });
          this.props.fetchRefunds(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };
  //-------------------------------------------------
  // Search
  //-------------------------------------------------

  renderConfirmDeleteBtn(record) {
    return (
      <Popconfirm
        placement='topLeft'
        title={text}
        onConfirm={() => this.handleDelete(record)}
        okText='Confirm'
        cancelText='Cancel'
      >
        <Button type='danger'>Delete</Button>
      </Popconfirm>
    );
  }

  handleSubmit = (e) => {
    this.props.form.validateFields((err, values) => {
      client
        .service('refunds')
        .patch(this.props.refunds.activeId, {
          ...values,
        })
        .then((res) => {
          message.success('Refund Listing Updated');
        });
    });
    this.setState({ printModal: false });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title: 'Date',
        dataIndex: 'refundDate',
        key: 'refundDate',
        render: (text, record) => moment(new Date(text)).format('DD-MM-YYYY'),
      },
      {
        title: 'Refereces',
        dataIndex: 'referenceId',
        key: 'referenceId',
      },
      {
        title: 'Unit Number',
        dataIndex: 'propertyunitId',
        key: 'propertyunitId',
        render: (text, record) => {
          if (_.get(text, 'referenceId') !== undefined) {
            return <span>{text.referenceId}</span>;
          }
        },
      },
      {
        title: 'Payment Type',
        dataIndex: 'paymentTypeParamId.description',
        key: 'paymentTypeParamId.description',
      },
      {
        style: { float: 'right' },
        title: (
          <Row>
            <Col style={{ float: 'right' }}>Amount (MYR)</Col>
          </Row>
        ),
        dataIndex: 'refundAmt',
        key: 'refundAmt',
        align: 'right',
        render: (text, record) => <span>{parseFloat(text).toFixed(2)}</span>,
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <Divider type='vertical' />
            <Link
              to={{
                pathname: `/refundStatement/${record._id}`,
              }}
            >
              <Button type='primary'>Print</Button>
            </Link>
            <Divider type='vertical' />
            <Button
              onClick={() => {
                this.handleEdit(record._id);
              }}
            >
              Edit
            </Button>
            <Divider type='vertical' />
            {this.renderConfirmDeleteBtn(record)}
          </span>
        ),
      },
    ];

    var refundList = _.map(this.state.allData, function(item) {
      const unit = _.get(item, 'propertyunitId.unitNo').split('-');
      item.invoiceNo = _.get(item, 'invoiceNo');
      item.paymentType = _.get(item, 'paymentTypeParamId.description');
      item.unitNo = unit[0] + ' - ' + unit[1];
      item.customerName = _.get(item, 'propertyunitId.owner.name');
      item.date = item && moment(item.refundDate).format('DD-MM-YYYY');
      return item;
    });

    return (
      <Layout>
        <Spin key='spinning' spinning={this.state.loading}>
          <Card
            title='Refund Listing'
            extra={
              <Row>
                <Col>
                  <Divider type='vertical' />
                  <Link to={{ pathname: `/deposit/` }}>
                    <Button type='primary'>New</Button>
                  </Link>
                  <Divider type='vertical' />
                  {!_.isEmpty(refundList) ? (
                    <CSVLink
                      filename={`${this.props.commons.selectedCompany.name}_Refund_List.csv`}
                      data={refundList}
                      headers={this.state.excelHeader}
                    >
                      <Button type='primary'>Export to CSV</Button>
                    </CSVLink>
                  ) : null}
                </Col>
              </Row>
            }
          >
            <Table
              dataSource={this.props.refunds.refunds}
              columns={columns}
              rowKey='_id'
              pagination={{
                defaultCurrent: this.props.activePage.refundPage,
                total: this.state.totalPagination,
                onChange: this.handlePageChange,
                pageSize: 20,
              }}
            />
          </Card>

          <Modal
            visible={this.state.printModal}
            title='Edit Refund Listing'
            okText='Update'
            onCancel={() => {
              this.setState({
                printModal: false,
              });
            }}
            onOk={this.handleSubmit}
          >
            <FormItem {...formItemLayout} label='Cheque No'>
              {getFieldDecorator('chequeNo', {
                rules: [
                  {
                    required: false,
                    message: 'Please input Cheque No!',
                  },
                ],
              })(<Input />)}
            </FormItem>

            <FormItem {...formItemLayout} label='Remark'>
              {getFieldDecorator('remark', {
                rules: [
                  {
                    required: false,
                    message: 'Please input Remark!',
                  },
                ],
              })(<Input />)}
            </FormItem>
          </Modal>
        </Spin>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    refunds: state.refunds,
    commons: state.commons,
    activePage: state.app.activePage,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchRefunds: fetchRefunds,
      pushRefunds: pushRefunds,
      removeRefunds: removeRefunds,
      updateActiveRefunds: updateActiveRefunds,
      updateRefunds: updateRefunds,
      updateMode: updateMode,
      updateActivePage: updateActivePage,
    },
    dispatch
  );
}

export default requireAuth(
  connect(mapStateToProps, mapDispatchToProps)(Form.create()(RefundsIndex))
);
