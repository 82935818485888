import React, { Component, useEffect } from 'react'
import { Modal, Upload,Popconfirm, message, Divider,  Table, Form, Icon, Input, Button, Select, DatePicker,Row,Col } from 'antd'
import client from '../../feathers'
import requireAuth  from "../requireAuth"
import Layout  from "../layout"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateMode } from '../../actions/actions-accounttrxs'

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 16 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset:4 },
};

const text = 'Are you sure to delete this item?';

class FormMulti extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false
    }
  }

  componentWillMount(){

  }

  componentDidUpdate(){

  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  }

  handleOk = (e) => {
    
    this.setState({
      visible: false,
    });
  }

  handleCancel = (e) => {
    
    this.setState({
      visible: false,
    });
  }


  handleSubmit = (e) => {
     e.preventDefault();
  }

  render() {
    const { visible, onCancel } = this.props;

    const { getFieldDecorator } = this.props.form;


    return([
      <Button type="primary" onClick={this.showModal}>Multiple Entry</Button>,
      <Modal
        title="Multiple"
        visible={this.state.visible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
      >
        <Form className="submit-form">

         Description
          <FormItem>
             {getFieldDecorator('description', {
               rules: [{
                 required: true, message: 'Please input Description!',
               }],
             })(
              <TextArea rows={4} />
             )}
          </FormItem>

          <Row>
            <Col span={12}>
                DEBIT
                <FormItem>
                  {getFieldDecorator('accountId', {
                    rules: [{
                      required: true, message: 'Please Select Debit Account!',
                    }],
                  })(
                    <Input placeholder="100.00" />
                  )}
                </FormItem>

                <FormItem>
                  {getFieldDecorator('amount', {
                    rules: [{
                      required: true, message: 'Please input Amount!',
                    }],
                  })(
                      <Input placeholder="100.00" />
                  )}
                </FormItem>
            </Col>
            <Col span={12}>
              CREDIT
              <FormItem>
                {getFieldDecorator('category', {
                  rules: [{
                    required: true, message: 'Please Select Credit Account!',
                  }],
                })(
                  <Input placeholder="100.00" />
                )}
              </FormItem>

              <FormItem>
                {getFieldDecorator('amount', {
                  rules: [{
                    required: true, message: 'Please input Amount!',
                  }],
                })(
                    <Input placeholder="100.00" />
                )}
              </FormItem>
            </Col>
          </Row>

          Date
          <FormItem>
             {getFieldDecorator('trxDate', {
               rules: [{
                 required: true, message: 'Please Select date!',
               }],
             })(
                <Input type='date' />
             )}
          </FormItem>

          Remark
          <FormItem>
             {getFieldDecorator('remark', {
               rules: [{
                 required: true, message: 'Please Input Remark!',
               }],
             })(
                <TextArea rows={4} />
             )}
          </FormItem>

        </Form>
      </Modal>

    ])
  }
}


function mapStateToProps(state) {
  return {
    //visibleSingle: state.visibleSingle
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
    }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(FormMulti)));
