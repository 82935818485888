import React, { Component, useEffect } from 'react';
import { Modal, Upload, Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Select, Radio, InputNumber, Spin, Dialog, DatePicker } from 'antd';
import client from '../../feathers';
import requireAuth from "../requireAuth";
import Layout from "../layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateMode } from '../../actions/actions-receipts';
import axios from 'axios';
import { Link, withRouter, Route } from 'react-router-dom';

import moment from 'moment';
//----------------------------------------------------------------------------
const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
const _ = require('lodash');
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'MYR',
  minimumFractionDigits: 2
})

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};

const text = 'Are you sure to delete this item?';

const config = {
  initialValue: moment(),
  rules: [{
    type: 'object',
    required: true,
    message: 'Please select date!'
  }],
};
//----------------------------------------------------------------------------
class PaymentFormItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',
      confirmDirty: false,
      autoCompleteResult: [],
      mode: this.props.mode,
      visible: false,
      loading: false,
      paymentTypeParams: [],
      paymentType: '',    //CASH, CREDIT CARD, ONLINE BANKING, CHEQUE
      payAmount: 0,
      totalAmount: 0,
      receipts: [],
      bankledgerAccountList: [],
      supplierInvoices: [],
      supplierId: '',
      overpaid: false,
      paymentDate: moment(new Date()).format("YYYY-MM-DD"),
      paymentDatet: moment(new Date()).format("YYYY-MM-DD"),
      paymentTypeParams: [],
      remark: '',
    }
  }
  //----------------------------------------------------------------------------
  componentDidUpdate() {

    if (this.props.totalAmount !== this.props.form.getFieldValue('paymentamount')) {
      //      setTimeout(this.setState({totalAmount:this.props.totalAmount}), 5000);
      this.setState({ totalAmount: this.props.totalAmount });
      this.props.form.setFieldsValue({ paymentamount: this.props.totalAmount });
    }
    if (this.props.supplierInvoices !== this.state.supplierInvoices) {
      this.setState({ supplierInvoices: this.props.supplierInvoices });
    }
    if (this.props.supplierId !== this.state.supplierId) {
      this.setState({ supplierId: this.props.supplierId });
    }
    if (this.props.overpaid !== this.state.overpaid) {
      this.setState({ overpaid: this.props.overpaid });
    }

  }
  //----------------------------------------------------------------------------


  componentDidMount() {

    this.getPaymentTypeParams();

    // client.authenticate()
    // .then((res)=>{
    //
    //     return client.service('ledger-accounts').find({
    //       query: {
    //         companyId:this.props.commons.selectedCompany._id,
    //         categories:'ASSETS',
    //         $or:[
    //         {bankAccountFlag:true,},
    //         {fullAccountNo : "3120/000"}
    //       ],
    //         //orRegex:{description:'BANK'},
    //          $sort: {description: 1}
    //        }
    //     })
    // })
    // .then((res)=>{
    //   this.setState({
    //     bankledgerAccountList:res.data
    //   })
    // })
    // .catch((err)=>{
    //     console.log(err);
    //     this.setState({
    //       bankledgerAccountList:[]
    //     })
    // })




  }
  //----------------------------------------------------------------------------
  componentWillMount() {

  }
  //----------------------------------------------------------------------------
  getPaymentTypeParams() {
    client.authenticate()
      .then((res) => {
        return client.service('payment-type-params').find({
          query: {
            propertyId: this.props.commons.selectedCompany._id,
            category: {
              $in: ['SUPPLIERPAYMENT']
            },
          }
        })
      })
      .then((res) => {
        this.setState({
          paymentTypeParams: res.data
        });
      })
      .catch((err) => {
        console.log(err);
      });

  }
  //----------------------------------------------------------------------------
  handleSubmit = (e) => {

    this.setState({ loading: true })

    if (this.state.overpaid == false) {
      e.preventDefault();
      this.props.form.validateFields((err, values) => {

        if (!err) {
          console.log('asd', this.state.supplierInvoices)

          if (this.state.supplierInvoices.length <= 0) {
            console.log(this.state.supplierInvoices)
            this.setState({ loading: false })
            return message.error('Please Select Record');
          }

          let totalAmount = _.sumBy(this.state.supplierInvoices, 'knockOffAmount');

          if (totalAmount <= 0 || (!totalAmount)) {
            this.setState({ loading: false })
            return message.error('Total knockOff amount must greater than zeros !');
          }

          client.service('paymentsvouchermasters').create({
            supplierId: this.props.supplierId,
            checkDuplicate: true,
            ...values,
            supplierInvoices: this.state.supplierInvoices,
            propertyId: this.props.commons.selectedCompany._id,
          })
            .then((res) => {
              this.setState({ loading: false })
              this.props.updateMode('');
              message.success('New Record Added');
              this.props.history.push('/paymentvoucher');
            })
            .catch((err) => {
              this.setState({ loading: false })
              console.log(err);
              this.props.updateMode('');
              alert(err);
            })
        }
        else {
          this.setState({ loading: false })
        }
      });

    }
    else {
      this.setState({ loading: false })
      message.error('Amount Overpaid');
    }
  }
  //----------------------------------------------------------------------------
  showModal = () => {
    this.setState({
      visible: true,
    });
  }
  //----------------------------------------------------------------------------
  handleCancel = (e) => {

    this.setState({
      visible: false,
    });
  }
  //----------------------------------------------------------------------------
  formatAmt(amt) {
    let tempAmt = amt;

    return tempAmt > 0 ? tempAmt.toFixed(2) : 0.00;
  }
  //------------------------------------------------------
  _paymentDateChange = (e) => {

    this.setState({
      paymentDate: moment(new Date(e)).format("YYYY-MM-DD")
    });
  }

  //----------------------------------------------------------------------------
  render() {
    const { visible, onCancel } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { bankledgerAccountList } = this.state;


    //----------------------------------------------------------------------------
    return ([
      <Spin key='spinning' spinning={this.state.loading}>

        <Form className="submit-form" onSubmit={this.handleSubmi} >

          <Form.Item key='paymentDate'
            {...formItemLayout}
            label="Payment Date"
          >
            {getFieldDecorator('paymentDate', config)(
              <DatePicker
                onChange={this._paymentDateChange}
              />
            )}
          </Form.Item>

          <FormItem key='creditledgerAccountId'
            {...formItemLayout}
            label="Pay by"
          >
            {getFieldDecorator('creditledgerAccountId', {
              rules: [{
                required: true, message: 'Please select Payment Type!',
              }],
            })(
              <Select>
                {this.state.paymentTypeParams.map(dropdown => (
                  <Option key={dropdown.ledgerAccountId} value={dropdown.ledgerAccountId} description={dropdown.description} >
                    {dropdown.description}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>

          <FormItem key='paymentamount'
            {...formItemLayout}
            label="Amount"
          >
            {getFieldDecorator('paymentamount', {
              rules: [{
                required: true, message: 'Please input amount!',
              }],
            })(
              //  {/* <Input value ={this.state.totalAmount} disabled/> */}
              <InputNumber disabled value={this.state.totalAmount} />
            )}
          </FormItem>

          <FormItem key='chequeNo'
            {...formItemLayout}
            label="Cheque No."
          >
            {getFieldDecorator('chequeNo', {
              rules: [{
                required: false, message: 'Please input Cheque No.!',
              }],
            })(
              <Input />
            )}
          </FormItem>
          <FormItem key='refer'
            {...formItemLayout}
            label="Ref No."
          >
            {getFieldDecorator('referenceNo', {
              rules: [{
                required: true, message: 'Please input Reference No.!',
              }],
            })(
              <Input />
            )}
          </FormItem>


          <FormItem key='Description'
            {...formItemLayout}
            label="Description"
          >
            {getFieldDecorator('remark', {
              rules: [{
                required: true, message: 'Please input description!',
              }],
            })(
              <TextArea rows={4} />
            )}
          </FormItem>

          <Button type="primary" onClick={this.handleSubmit} htmlType="submit" style={{ float: 'right' }} >Pay</Button>
        </Form>
      </Spin>

    ])
  }
}

//----------------------------------------------------------------------------
function mapStateToProps(state) {
  return {
    receipts: state.receipts,
    commons: state.commons
  }
}
//----------------------------------------------------------------------------
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
  }, dispatch);
}
//----------------------------------------------------------------------------
export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(withRouter(Form.create()(PaymentFormItems))));
