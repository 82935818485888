import React, { Component } from 'react'
import { Popconfirm, message, Divider,  Table, Form, Icon, Input, Button } from 'antd'
import { Route, Link } from 'react-router-dom';

import client from '../../feathers'
import requireAuth  from "../../components/requireAuth"
import Layout  from "../../components/layout"
import UserpropertiesForm  from "../../components/userproperties/form"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  fetchUserproperties,
  pushUserproperties,
  removeUserproperties,
  updateMode,
  updateActiveUserproperties,
  updateUserproperties } from '../../actions/actions-userproperties'

const FormItem = Form.Item;

const text = 'Are you sure to delete this item?';

class UserpropertiesIndex extends Component {
  constructor(props) {
    super(props);

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidUpdate(){
  }

  componentDidMount(){
      client.service('userproperties').on('created', (userproperties) => {
        this.props.pushUserproperties(userproperties)
      })

      client.service('userproperties').on('removed', (userproperties) => {
        this.props.removeUserproperties(userproperties)
      })

      client.service('userproperties').on('updated', (userproperties) => {
        this.props.updateUserproperties(userproperties)
      })
  }

  componentWillMount(){
      client.authenticate()
      .then(()=>{
          return client.service('userproperties').find({
            query: {
              referenceId:this.props.match.params.id,
               $sort: {
                 createdAt: -1
               }
             }
          })
      })
      .then((res)=>{
        this.props.fetchUserproperties(res.data)
      })
      .catch((err)=>{
          console.log(err);
      })
  }

  handleDelete(_id) {
     client.service('userproperties').remove(_id)
     .then((res)=>{
     })
     .catch((err)=>{
       alert(err);
     })
  }

  handleEdit(_id) {
    this.props.updateActiveUserproperties('edit',_id)
  }

  handleManage(_id) {
    this.props.updateActiveUserproperties('',_id)
  }

  showModal = () => {
    this.props.updateMode('new')
  }

  renderConfirmDeleteBtn(_id){
    return (
      <Popconfirm placement="topLeft" title={text} onConfirm={()=>this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
        <Button type="danger">Delete</Button>
      </Popconfirm>
    )
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const columns = [{
        title: 'User',
        dataIndex: 'userId',
        key: 'userId',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Reference ID',
        dataIndex: 'referenceId',
        key: 'referenceId',
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
      },
      {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
          <Divider type="vertical" />
          <Button onClick={()=>this.handleEdit(record._id)}>Edit</Button>
          <Divider type="vertical" />
          {this.renderConfirmDeleteBtn(record._id)}
        </span>
      ),
    }];

    return(
      <Layout>
        <Button type="primary" onClick={this.showModal}>New Assignment</Button>

        <Table dataSource={this.props.userproperties.userproperties} columns={columns} rowKey="_id" />
        <UserpropertiesForm referenceId={this.props.match.params.id}/>

      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    userproperties: state.userproperties,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchUserproperties: fetchUserproperties,
    pushUserproperties: pushUserproperties,
    removeUserproperties: removeUserproperties,
    updateActiveUserproperties: updateActiveUserproperties,
    updateUserproperties: updateUserproperties,
    updateMode: updateMode
    }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(UserpropertiesIndex)));
