import { Button, Card, Col, DatePicker, Form, Row, Table, Tabs } from 'antd';
import axios from 'axios';
import { css } from 'emotion';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import ReactToPrint from 'react-to-print';
import { bindActionCreators } from 'redux';
import BSYearly from '../../components/reports/index-balance-sheet-yearly';
import client from '../../feathers';
import Layout from '../layout';
import requireAuth from '../requireAuth';
import { updateDate } from '../../actions/actions-ledgerAccounts';
import NumberFormat from 'react-number-format';
import TableToExcel from '@linways/table-to-excel';

const { RangePicker } = DatePicker;
const _ = require('lodash');
const TabPane = Tabs.TabPane;

const formatCurr = function format1(n = 0, currency = null) {
  let oriAmt = n;
  n = n < 0 ? Math.abs(n) : n;
  let amt = currency
    ? currency
    : '' +
      n.toFixed(2).replace(/./g, function(c, i, a) {
        return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c;
      });

  return oriAmt < 0 ? '(' + amt + ')' : amt;
};

class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      loading: true,
      jmb: this.props.jmbs,
      fromDate: this.props.fromDate,
      toDate: this.props.toDate,
      subAccountTable: false,
      subAccount: [],
      mainAccount: [],
      tableColumn: [],
    };
  }

  componentWillMount() {
    this.setState({
      tableColumn: [
        {
          title: 'Account No.',
          dataIndex: 'ledgerAccountNo',
          width: 250,
        },
        {
          title: 'Account Name',
          dataIndex: 'ledgerAccountName',
          width: 250,
        },
        {
          title: 'Amount',
          width: 250,
          dataIndex: 'balance',
          render: (v) => this._renderAmount(v),
        },
      ],
    });
    let companyId = this.props.companyId._id;
    client
      .authenticate()
      .then(() => {
        return axios.get(`${client.io.io.uri}ledgerTrxSummary`, {
          params: {
            companyId: companyId,
            toDate: this.state.toDate,
            fromDate: this.state.fromDate,
            balanceSheet: true,
          },
          headers: {
            Authorization: client.settings.storage.storage['feathers-jwt'],
          },
        });
      })
      .then((res) => {
        console.log({ res });
        return Promise.all([
          this.setState({
            dataSource: res.data[0],
            loading: false,
            mainAccount: res.data[1],
          }),

          axios.get(`${client.io.io.uri}ledgerTrxSummary`, {
            params: {
              companyId: companyId,
              toDate: this.state.toDate,
              fromDate: this.state.fromDate,
            },
            headers: {
              Authorization: client.settings.storage.storage['feathers-jwt'],
            },
          }),
        ]);
      })
      .then(([res1, res2]) => {
        document.title = `${this.props.companyId.name}_Balance Sheet_ ${moment(
          this.state.fromDate
        ).format('YYYYMMDD')}-${moment(this.state.toDate).format('YYYYMMDD')}`;

        let { dataSource } = this.state;
        // let subaccount = res2.data[0]

        // dataSource.forEach(function (item) {
        //   let subAccountlist = []
        //   subaccount.forEach(function (item2) {
        //     if (item.ledgerAccountMainAcc === item2.ledgerAccountMainAcc && item.ledgerAccountSubAcc !== '000') {
        //       subAccountlist.push(item2)
        //     }
        //   });
        //   item.subAccountlist = subAccountlist
        // });
        this.setState({ dataSource });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  }

  redirectTo(event, record) {
    let companyId = this.props.companyId;
    client
      .authenticate()
      .then((res) => {
        return client.service('ledger-accounts').find({
          query: {
            companyId: companyId,
            accountNo: record.ledgerAccountMainAcc,
          },
        });
      })
      .then((res) => {
        if (res.total > 0) {
          this.props.props.history.push(
            '/ledgers/accountList/' + record.ledgerAccountMainAcc
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  _renderAmount(v) {
    v = parseFloat(v.toFixed(2));
    return (
      <span style={{ float: 'right' }}>
        <NumberFormat
          prefix={v < 0 ? '(' : ''}
          suffix={v < 0 ? ')' : ''}
          thousandSeparator={true}
          displayType='text'
          fixedDecimalScale={true}
          decimalScale={2}
          value={v < 0 ? v * -1 : v}
        />
      </span>
    );
  }

  _renderAccountName(t, r) {
    const { mainAccount } = this.state;

    let name = _.find(mainAccount, { accountNo: r.ledgerAccountNo });
    return (
      <span>
        <p
          className='balancesheet-linktext'
          onClick={(e) => this.redirectTo(e, r)}
        >
          <a>{name ? name.description : t}</a>
        </p>
      </span>
    );
  }

  _renderExcelRows() {
    const { dataSource, tableColumn } = this.state;
    let dataSourceFixAssets = [];
    let dataSourceCurrentAssets = [];
    let dataSourceLiabilities = [];
    let dataSourceEquity = [];
    let totalAssets = 0;
    let totalFixedAssets = 0;
    let totalCurrentAssets = 0;
    let totalLiabilities = 0;
    let totalEquity = 0;
    let totalIncomes = 0;
    let totalExpenses = 0;
    let totalNet = 0;
    let totalRecord = {};
    let newTotal = [];

    function checkVal(k, i, n, underline, key) {
      let a = { 'data-b-b-s': false };
      if (i[k.dataIndex]) {
        if (i.titleMain) {
          a['data-f-sz'] = '18';
          a['data-f-bold'] = true;
        }
        if (i.titleRow) {
          a['data-f-sz'] = '13';
          a['data-f-bold'] = true;
        }
        if (n) {
          a['data-t'] = 'n';
          a['data-num-fmt'] = '#,##0.00;[RED](#,##0.00)';
        }
        return (
          <td {...a} key={key}>
            {i[k.dataIndex]}
          </td>
        );
      }
      return <td {...a} key={key}></td>;
    }

    dataSource.forEach(function(item, index) {
      if (item.ledgerAccountCat === 'LIABILITIES') {
        dataSourceLiabilities.push(item);
        totalLiabilities += item.balance;
      } else if (item.ledgerAccountCat === 'EQUITY') {
        dataSourceEquity.push(item);
        totalEquity += item.balance;
      } else if (item.ledgerAccountType === 'FIXED ASSETS') {
        if (item.ledgerAccountMainAcc != '2000') {
          dataSourceFixAssets.push(item);
          totalAssets += item.balance;
          totalFixedAssets += item.balance;
        }
      } else if (item.ledgerAccountType === 'CURRENT ASSETS') {
        dataSourceCurrentAssets.push(item);
        totalAssets += item.balance;
        totalCurrentAssets += item.balance;
      }

      if (item.ledgerAccountCat === 'INCOMES') {
        totalIncomes = parseFloat(
          parseFloat(totalIncomes) + parseFloat(item.balance)
        ).toFixed(2);
      } else if (item.ledgerAccountCat === 'EXPENSES') {
        totalExpenses = parseFloat(
          parseFloat(totalExpenses) + parseFloat(item.balance)
        ).toFixed(2);
      }
    });

    let retailEarning = {};
    retailEarning.ledgerAccountNo = '1100';
    retailEarning.ledgerAccountName = 'RETAINED SURPLUS/(DEFICIT)';
    retailEarning.balance = totalIncomes - totalExpenses;
    totalEquity = totalEquity + (totalIncomes - totalExpenses);
    dataSourceEquity.push(retailEarning);
    totalNet = totalAssets - totalLiabilities;

    function _renderTableInfo(
      catTitle,
      totalTitle,
      data,
      total,
      total2,
      catTitle2
    ) {
      let titleTotal = '';

      return (
        <>
          <tr key={'td-header-1'}>
            <td data-f-sz='18' data-f-bold='true'>
              {catTitle}
            </td>
          </tr>
          <tr>
            {tableColumn.map((i, index) => {
              let title = i.title;
              return (
                <td
                  key={'tr-title-td-' + index}
                  data-f-sz='16'
                  data-f-bold='true'
                  data-a-h='center'
                  data-b-b-s='medium'
                  data-b-t-s='medium'
                >
                  {title}
                </td>
              );
            })}
          </tr>
          {//rows
          data.map((i, indexi) => {
            return (
              <tr>
                {tableColumn.map((j, index) => {
                  return (
                    <>
                      {checkVal(
                        j,
                        i,
                        index > 1,
                        i.titleMain,
                        'td-amt-' + indexi + '-td-' + index
                      )}
                    </>
                  );
                })}
              </tr>
            );
          })}
          <tr key={'td-header-2'}>
            <td
              data-f-sz='13'
              colspan='3'
              align='right'
              data-a-rtl='true'
              data-f-bold='true'
            >
              {`Total ${totalTitle}: ${parseFloat(total)}`}
            </td>
          </tr>
          {total2 && (
            <tr key={'td-header-3'}>
              <td
                data-f-sz='13'
                colspan='3'
                align='right'
                data-a-rtl='true'
                data-f-bold='true'
              >{`${catTitle2}: ${parseFloat(total2)}`}</td>
            </tr>
          )}
        </>
      );
    }
    return (
      <>
        {_renderTableInfo(
          'FIXED ASSETS',
          'Fixed Assets',
          dataSourceFixAssets,
          totalFixedAssets.toFixed(2)
        )}
        <tr />
        {_renderTableInfo(
          'CURRENT ASSETS',
          'Current Assets',
          dataSourceCurrentAssets,
          totalCurrentAssets.toFixed(2),
          totalAssets.toFixed(2),
          'Total Assets'
        )}
        <tr />
        {_renderTableInfo(
          'LIABILITIES',
          'Liabilities',
          dataSourceLiabilities,
          totalLiabilities.toFixed(2),
          totalNet.toFixed(2),
          'Net Assets'
        )}
        <tr />
        {_renderTableInfo(
          'ENQUITY',
          'Enquity',
          dataSourceEquity,
          totalEquity.toFixed(2)
        )}
      </>
    );
  }

  render() {
    const { dataSource, jmb, loading } = this.state;
    const { fromDate, toDate } = this.state;
    let dataSourceFixAssets = [];
    let dataSourceCurrentAssets = [];
    let dataSourceLiabilities = [];
    let dataSourceEquity = [];
    let totalAssets = 0;
    let totalFixedAssets = 0;
    let totalCurrentAssets = 0;
    let totalLiabilities = 0;
    let totalEquity = 0;
    let totalIncomes = 0;
    let totalExpenses = 0;
    let totalRecord = {};
    let newTotal = [];

    dataSource.forEach(function(item, index) {
      if (item.ledgerAccountCat === 'LIABILITIES') {
        dataSourceLiabilities.push(item);
        totalLiabilities += item.balance;
      } else if (item.ledgerAccountCat === 'EQUITY') {
        dataSourceEquity.push(item);
        totalEquity += item.balance;
      } else if (item.ledgerAccountType === 'FIXED ASSETS') {
        if (item.ledgerAccountMainAcc != '2000') {
          dataSourceFixAssets.push(item);
          totalAssets += item.balance;
          totalFixedAssets += item.balance;
        }
      } else if (item.ledgerAccountType === 'CURRENT ASSETS') {
        dataSourceCurrentAssets.push(item);
        totalAssets += item.balance;
        totalCurrentAssets += item.balance;
      }

      if (item.ledgerAccountCat === 'INCOMES') {
        totalIncomes = parseFloat(
          parseFloat(totalIncomes) + parseFloat(item.balance)
        ).toFixed(2);
      } else if (item.ledgerAccountCat === 'EXPENSES') {
        totalExpenses = parseFloat(
          parseFloat(totalExpenses) + parseFloat(item.balance)
        ).toFixed(2);
      }
    });

    let retailEarning = {};
    retailEarning.ledgerAccountMainAcc = '1100';
    retailEarning.ledgerAccountDescription = 'RETAINED SURPLUS/(DEFICIT)';
    retailEarning.balance = totalIncomes - totalExpenses;
    totalEquity = totalEquity + (totalIncomes - totalExpenses);
    dataSourceEquity.push(retailEarning);

    const columns = [
      {
        title: '',
        dataIndex: 'ledgerAccountMainAcc',
        width: '20%',
        key: 'fullAccountNo',
        render: (text, record) => (
          <span>
            <p className='balancesheet-text'>
              <a>{text}</a>
            </p>
          </span>
        ),
      },
      {
        title: '',
        dataIndex: 'ledgerAccountDescription',
        width: '50%',
        key: 'description',
        render: (t, r) => this._renderAccountName(t, r),
      },
      {
        title: '',
        width: '20%',
        align: 'right',
        dataIndex: 'balance',
        key: 'balance',
        render: (text, record) => (
          <span className='' style={{ float: 'right' }}>
            {formatCurr(text)}
          </span>
        ),
      },
      {
        width: '10%',
        dataIndex: 'blank',
        key: 'blank',
      },
    ];

    const columnsTotal = [
      {
        width: '20%',
      },
      {
        width: '30%',
      },
      {
        dataIndex: 'title',
        align: 'right',
        width: '20%',
        render: (text, record) => {
          return <b>{text}</b>;
        },
      },
      {
        width: '20.5%',
        align: 'right',
        dataIndex: 'total',
        key: 'balance',
        render: (text, record) => {
          return <b>{formatCurr(text)}</b>;
        },
      },
      {
        width: '10%',
        dataIndex: 'blank',
        key: 'blank',
      },
    ];

    const body = css({
      '& thead > tr': {
        display: 'none',
      },
    });

    return (
      <div>
        <Card
          loading={loading}
          title={
            <div>
              <span style={{ fontSize: 20 }}>{jmb.name}</span>
              <br></br>
              <span>({jmb.registrationNo})</span>
              <br></br>
              <span>Balance Sheet</span>
              <span className='alignRight'>
                As at selected date till {toDate}
              </span>
            </div>
          }
        >
          <Row>
            <Row>
              <Col
                span={5}
                className={'table-title'}
                style={{ marginLeft: 13 }}
              >
                Acct No.
              </Col>
              <Col span={6} className={'table-title'}>
                Account Name
              </Col>
              <Col span={10} push={5} className={'table-title'}>
                AMOUNT (RM)
              </Col>
            </Row>
            <Col span={24}>
              <Table
                className={body}
                rowKey='_id'
                dataSource={dataSourceFixAssets}
                columns={columns}
                pagination={false}
                title={() => <span>FIXED ASSETS</span>}
                footer={() => {
                  let fixedAssetTotal = {};
                  let fixedArr = [];
                  fixedAssetTotal.total = totalFixedAssets;
                  fixedAssetTotal.title = 'Total Fixed Assets';
                  fixedArr.push(fixedAssetTotal);

                  return (
                    <Table
                      size='small'
                      dataSource={fixedArr}
                      columns={columnsTotal}
                      showHeader={false}
                      bordered={false}
                      pagination={false}
                    />
                  );
                }}
                size={'small'}
                // expandedRowRender={this._expandedRowRender}
              />
              <br />
              <Table
                className={body}
                rowKey='_id'
                dataSource={dataSourceCurrentAssets}
                columns={columns}
                pagination={false}
                title={() => <span>CURRENT ASSETS</span>}
                footer={() => {
                  let currentAssetTotal = {},
                    totalAsset = {};
                  let currentArr = [];
                  currentAssetTotal.total = totalCurrentAssets;
                  currentAssetTotal.title = 'Total Current Assets';

                  totalAsset.total = totalAssets;
                  totalAsset.title = 'Total Assets';

                  currentArr.push(currentAssetTotal);
                  currentArr.push(totalAsset);
                  return (
                    <Table
                      size='small'
                      dataSource={currentArr}
                      columns={columnsTotal}
                      showHeader={false}
                      bordered={false}
                      pagination={false}
                    />
                  );
                }}
                size={'small'}
                // expandedRowRender={this._expandedRowRender}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col span={24}>
              <Table
                className={body}
                rowKey='_id'
                dataSource={dataSourceLiabilities}
                columns={columns}
                pagination={false}
                title={() => <span>LIABILITIES</span>}
                footer={() => {
                  let liabilitiesTotal = {},
                    netAsset = {};
                  let liabilitiesArr = [];
                  liabilitiesTotal.total = totalLiabilities;
                  liabilitiesTotal.title = 'Total Liabilities';

                  netAsset.total = totalAssets - totalLiabilities;
                  netAsset.title = 'Net Assets';
                  liabilitiesArr.push(liabilitiesTotal);
                  liabilitiesArr.push(netAsset);
                  return (
                    <Table
                      size='small'
                      dataSource={liabilitiesArr}
                      columns={columnsTotal}
                      showHeader={false}
                      bordered={false}
                      pagination={false}
                    />
                  );
                }}
                size='small'
                // expandedRowRender={this._expandedRowRender}
              />
              <br />
              <Table
                className={body}
                rowKey='_id'
                dataSource={dataSourceEquity}
                columns={columns}
                pagination={false}
                title={() => <span>FINANCED BY</span>}
                footer={() => {
                  let equityTotal = {},
                    netAsset = {};
                  let equityArr = [];
                  equityTotal.total = totalEquity;
                  equityTotal.title = 'Total Equity';
                  equityArr.push(equityTotal);

                  return (
                    <Table
                      size='small'
                      dataSource={equityArr}
                      columns={columnsTotal}
                      showHeader={false}
                      bordered={false}
                      pagination={false}
                    />
                  );
                }}
                size='small'
                // expandedRowRender={this._expandedRowRender}
              />
              <table
                style={{ display: 'none' }}
                key={'printExcel-table'}
                data-cols-width={
                  '40' +
                  ',12'.repeat(
                    this.state.tableColumn.length > 0
                      ? this.state.tableColumn.length - 1
                      : 0
                  )
                }
                className='printExcel'
              >
                <tbody>{this._renderExcelRows()}</tbody>
              </table>
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

class IndexBalanceSheet extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fromDate: moment(new Date('2020-05-27T14:26:42.195+08:00')).format(
        'YYYY-MM-DD'
      ),
      toDate: moment()
        .endOf('year')
        .format('YYYY-MM-DD'),
    };
  }

  componentDidMount() {
    this.props.updateDate('');
  }

  fromDateOnChange = (e) => {
    this.setState({
      fromDate: moment(new Date('2020-05-27T14:26:42.195+08:00')).format(
        'YYYY-MM-DD'
      ),
      toDate: moment(new Date(e)).format('YYYY-MM-DD'),
    });
    this.props.updateDate(moment(new Date(e)).format('YYYY-MM-DD'));
  };

  render() {
    const exportToCSV = () => {
      TableToExcel.convert(document.querySelector('table.printExcel'), {
        name: `${this.props.jmbs.jmbs[0].name}_BALANCE SHEET STATEMENT_${this.state.fromDate} to ${this.state.toDate}.xlsx`,
        sheet: {
          name: 'Sheet 1',
        },
      });
    };

    return (
      <Layout>
        <Tabs defaultActiveKey='1'>
          <TabPane tab='Summary' key='1'>
            <Row gutter={24}>
              <Col span={6} offset={13}>
                Date : <DatePicker onChange={this.fromDateOnChange} />
              </Col>
              <Col span={2}>
                <ReactToPrint
                  trigger={() => <Button type='primary'>PRINT</Button>}
                  content={() => this.componentRef}
                />
              </Col>
              <Col span={2}>
                <Button onClick={(e) => exportToCSV()} type='primary'>
                  Export
                </Button>
              </Col>
            </Row>
            <br />
            <ComponentToPrint
              key={this.state.fromDate + this.state.toDate}
              jmbs={this.props.jmbs.jmbs[0]}
              companyId={this.props.commons.selectedCompany}
              ref={(el) => (this.componentRef = el)}
              props={this.props}
              fromDate={this.state.fromDate}
              toDate={this.state.toDate}
            />
          </TabPane>

          <TabPane tab='Detail' key='2'>
            <BSYearly />
          </TabPane>
        </Tabs>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    jmbs: state.jmbs,
    commons: state.commons,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateDate: updateDate,
    },
    dispatch
  );
}

export default requireAuth(
  connect(mapStateToProps, mapDispatchToProps)(Form.create()(IndexBalanceSheet))
);
