import React, { Component, useEffect } from 'react';
import { Modal, Upload, Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Select, InputNumber, Pagination, Spin, Row, Col, Card } from 'antd';
import client from '../../feathers';
import requireAuth from "../requireAuth";
import Layout from "../layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateMode } from '../../actions/actions-recurringitems';
import RecurringFormSingle from "../../components/recurringitems/form";
import RecurringFormMultiple from "../../components/recurringitems/form-multiple";
import axios from 'axios';


const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const text = 'Are you sure to delete this item?';
const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);
const PAGESIZE = 20

class EditableCell extends React.Component {
  state = {
    editing: false,
  }

  componentDidMount() {
    if (this.props.editable) {
      document.addEventListener('click', this.handleClickOutside, true);
    }
  }

  componentWillUnmount() {
    if (this.props.editable) {
      document.removeEventListener('click', this.handleClickOutside, true);
    }
  }

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  }

  handleClickOutside = (e) => {
    const { editing } = this.state;
    if (editing && this.cell !== e.target && !this.cell.contains(e.target)) {
      this.save();
    }
  }

  save = () => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  }

  render() {
    const { editing } = this.state;
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      ...restProps
    } = this.props;
    return (
      <td ref={node => (this.cell = node)} {...restProps}>
        {editable ? (
          <EditableContext.Consumer>
            {(form) => {
              this.form = form;
              return (
                editing ? (
                  <FormItem style={{ margin: 0 }}>
                    {form.getFieldDecorator(dataIndex, {
                      rules: [{
                        required: true,
                        message: `${title} is required.`,
                      }],
                      initialValue: record[dataIndex],
                    })(
                      <Input
                        ref={node => (this.input = node)}
                        onPressEnter={this.save}
                      />
                    )}
                  </FormItem>
                ) : (
                  <div
                    className="editable-cell-value-wrap"
                    style={{ paddingRight: 24 }}
                    onClick={this.toggleEdit}
                  >
                    {restProps.children}
                  </div>
                )
              );
            }}
          </EditableContext.Consumer>
        ) : restProps.children}
      </td>
    );
  }
}

class RecurringItemsIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      editingKey: '',
      totalPagination: 1,
      loading: true,
      propertyunitsDropdowns: [],
      refresh: false,
    }

  }
  componentDidUpdate() {

    if (this.state.refresh === true) {
      this.setState({ refresh: false })
      this.componentWillMount()
    }

  }
  componentDidMount() {

  }
  componentWillMount() {
    let companyId = this.props.commons.selectedCompany._id

    client.authenticate()
      .then((res) => {
        return client.service('recurring-items').find({
          query: {
            $limit: 20,
            $populate: 'propertyunitId',
            propertyId: companyId,
            $sort: {
              propertyunitId: 1
            }
          }
        })
      })
      .then((res) => {
        console.log(res)
        this.setState({
          dataSource: res.data,
          totalPagination: res.total,
          loading: false
        });
      })
      .catch((err) => {
        this.setState({ loading: true });
        console.log(err);
      });
    this.getPropertyunits();
  }

  getPropertyunits() {
    let companyId = this.props.commons.selectedCompany._id
    //generate property unit dropdown
    client.authenticate()
      .then((res) => {
        return client.service('propertyunits').find({
          query: {
            propertyId: companyId,
            $sort: {
              referenceId: 1
              //createdAt: -1
            },
            $limit: 20,
          }
        })
      })
      .then((res) => {

        this.setState({ propertyunitsDropdowns: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handlePageChange = (page) => {
    let companyId = this.props.commons.selectedCompany._id
    console.log({ page })
    // this.setState({ loading: true });
    this.props.form.validateFields((err, values) => {
      client.authenticate()
        .then((res) => {
          return client.service('recurring-items').find({
            query: {
              ...values,
              $populate: 'propertyunitId',
              propertyId: companyId,
              $limit: PAGESIZE,
              $skip: page.current * PAGESIZE,
              $sort: {
                propertyunitId: 1,
              }
            }
          })
        })
        .then((res) => {
          console.log('done')
          this.setState({
            totalPagination: res.total,
            dataSource: res.data,
            loading: false
          });
        })
        .catch((err) => {
          this.setState({ loading: false });
          console.log(err);
        })
    })
  }

  handleDelete = (_id) => {

    client.service('recurring-items').remove(_id)
      .then((res) => {
        message.success('Deleted');
      })
      .catch((err) => {
        alert(err);
      })

    const dataSource = [...this.state.dataSource];
    this.setState({ dataSource: dataSource.filter(item => item._id !== _id) });
  }

  handleAdd = () => {
    const { count, dataSource } = this.state;
    const newData = {
      key: count,
      name: `Edward King ${count}`,
      age: 32,
      address: `London, Park Lane no. ${count}`,
    };
    this.setState({
      dataSource: [...dataSource, newData],
      count: count + 1,
    });
  }

  handleSave = (row) => {

    client.service('recurring-items').patch(row._id,
      {
        amount: row.amount,
        status: row.status
      }
    )
      .then((res) => {

        message.success(`Record Updated`);
      })
      .catch((err) => {
        console.log(err);
      })

    const newData = [...this.state.dataSource];
    const index = newData.findIndex(item => row._id === item._id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ dataSource: newData });
  }

  formatAmt(amt) {
    let integer = parseFloat(amt);
    return integer > 0 ? integer.toFixed(2) : 0.00;
  }

  handleSearch = (e) => {
    let companyId = this.props.commons.selectedCompany._id

    try {
      e.preventDefault();
    }
    catch (err) { };

    if (e == "ShowAll") {
      e = '';
    }
    let filerAccountName = e === '' ? '' : { propertyunitId: e };
    let collection = { ...filerAccountName };

    this.props.form.validateFields((err, values) => {
      client.authenticate()
        .then((res) => {

          return client.service('recurring-items').find({
            query: {
              //  ...values,
              //propertyunitId:e,
              ...collection,
              $populate: 'propertyunitId',
              propertyId: companyId,
              $sort: {
                propertyunitId: 1
              },
            }
          })
        })
        .then((res) => {
          this.setState({
            dataSource: res.data,
            totalPagination: res.total,
          });
        })
        .catch((err) => {
          console.log(err);
        });

    });
  }

  handleSearchPropertyunit = (value) => {
    let companyId = this.props.commons.selectedCompany._id
    //generate charges dropdown


    this.setState({ fetching: true });
    client.authenticate()
      .then((res) => {
        return client.service('propertyunits').find({
          query: {
            propertyId: companyId,
            orRegex: {
              referenceId: value
            },
            $sort: {
              referenceId: 1
            }

          }
        })
      })
      .then((res) => {

        this.setState({
          propertyunitsDropdowns: res.data,
          fetching: false,
        });
      })
      .catch((err) => {
        this.setState({ fetching: false });
        console.log(err);
      });
  }

  handleRecalculate = () => {
    let companyId = this.props.commons.selectedCompany._id

    client.authenticate()
      .then((res) => {
        return axios.get(`${client.io.io.uri}recalculate-recurring-items`, {
          params: {
            propertyId: companyId,
          },
          headers: {
            'Authorization': client.settings.storage.storage['feathers-jwt']
          }
        })
      })
      .then((res) => {
        this.setState({
          refresh: true,
        });
        message.success('Recalculating in progress.It\'s might take few minute to complete.')
        // console.log(res)
      })
      .catch((err) => {
        this.setState({
          refresh: true,
        });
        console.log(err);
      });
  }
  renderSearching() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form
        className="ant-advanced-search-form"
        onSubmit={this.handleSearch}
      >
        <Row gutter={24} style={{ display: 'block' }}>
          <Col span={8}>
            <Form.Item label='Unit Number'>
              {getFieldDecorator(`propertyunitId`, {
                rules: [{
                  required: false,
                  message: 'Unit Number!',
                }],
              })(
                <Select
                  showSearch
                  // value={value}
                  placeholder="Select Units"
                  notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
                  filterOption={false}
                  onSearch={this.handleSearchPropertyunit}
                  // onChange={this.handleSearchPropertyunit}
                  style={{ width: '100%' }}
                >
                  {this.state.propertyunitsDropdowns.map(d => <Option key={d._id}>{d.referenceId}</Option>)}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Button type="primary" htmlType="submit" style={{ float: 'right' }} >Search</Button>
        </Row>
      </Form>
    )
  }

  render() {
    const { visible, onCancel } = this.props;
    const { getFieldDecorator } = this.props.form;
    const dataSource = this.state.dataSource;
    const loading = this.state.loading;
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };

    const columns = [
      {
        title: (
          <Row gutter={8}>
            <Row><Col span={24}>Property Unit No.</Col></Row>
            <Row><Col span={24}>
              <Select
                showSearch
                placeholder="Select Units"
                notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
                filterOption={false}
                showArrow={false}
                onSearch={this.handleSearchPropertyunit}
                onChange={this.handleSearch}
                style={{ width: '100%' }}
                notFoundContent={null}
              >
                <Option key='ShowAll'>All</Option>
                {this.state.propertyunitsDropdowns.map(d => <Option key={d._id}>{d.referenceId}</Option>)}
              </Select>
            </Col></Row>
          </Row>
        ),
        dataIndex: 'propertyunitId.referenceId',
        width: '20%',
        editable: false,
      },
      {
        title: 'Size',
        dataIndex: 'propertyunitId.size',
        width: '10%',
        editable: false,
      },
      {
        title: 'Extra Size',
        dataIndex: 'propertyunitId.extraSize',
        width: '10%',
        editable: false,
      },
      {
        title: 'Description',
        dataIndex: 'description',
        width: '30%',
        editable: false,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        width: '10%',
        editable: true,
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        width: '10%',
        editable: true,
        align: 'right',
        render: (text, record) => (
          <span>{this.formatAmt(record.amount)}</span>
        ),
      }, {
        title: 'operation',
        dataIndex: 'operation',
        render: (text, record) => (
          this.state.dataSource.length >= 1
            ? (
              <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record._id)}>
                <a href="javascript:;">Delete</a>
              </Popconfirm>
            ) : null
        ),
      }
    ];

    const columns2 = columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });

    return (
      <Layout>
        <Card
          loading={loading}
          title={'RECURRING ITEMS'}
          extra={[
            <Row gutter={16}>
              <Col span={8}>
                <RecurringFormSingle />
              </Col>
              <Col span={8}>
                <RecurringFormMultiple />
              </Col>
              <Col span={8}>
                <Button style={{ "marginRight": "10px" }} type="primary" onClick={this.handleRecalculate} >Re-Calculate</Button>
              </Col>
            </Row>
          ]}
        >
          {/*  {this.renderSearching()} */}
          {/* <RecurringFormMultiple /> */}
          {/*<Table dataSource={this.state.ledgerAccounts} columns={columns} rowKey="_id" />*/}
          <Table
            components={components}
            rowClassName={() => 'editable-row'}
            rowKey="_id"
            bordered
            dataSource={dataSource}
            columns={columns2}
            size={'small'}
            pagination={{
              // defaultCurrent: '1',
              total: this.state.totalPagination,
              pageSize: 20,
            }}
            onChange={this.handlePageChange}
          />
        </Card>
      </Layout>

    )
  }
}


function mapStateToProps(state) {
  return {
    recurringitems: state.recurringitems,
    commons: state.commons
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(RecurringItemsIndex)));
