import _ from 'lodash';
import {
  FETCH_INVOICES,
  PUSH_INVOICES,
  REMOVE_INVOICES,
  UPDATE_INVOICES,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID,
  UPDATE_INVOICE_COPY
 } from '../actions/actions-invoices';

const INITIAL_STATE = {
  invoices: [],
  mode: 'index',
  activeId: '',
  activeInvoices:{},
  copiedInvoice:{}
}


export default function(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_INVOICES:
      return {
        ...state,
        invoices:action.payload,
      }
    case PUSH_INVOICES:
      return {
        ...state,
        invoices:_.orderBy( _.uniqBy([action.payload, ...state.invoices], '_id'), ['invoiceNo'], ['desc'] ),
      }
    case REMOVE_INVOICES:
      return {
        ...state,
        invoices : [...state.invoices.filter( (item) => item._id !== action.payload._id)]
      }
    case UPDATE_INVOICES:
      let index =  _.findIndex(state.invoices, { '_id': action.payload._id })
      state.invoices.splice(index, 1, action.payload)
      return {
        ...state,
        invoices : state.invoices
      }
    case UPDATE_INVOICE_COPY:
      return {
        ...state,
        copiedInvoice : action.payload
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode : action.payload,
        activeId:''
      }
    case UPDATE_ACTIVE_ID:
      return {
        ...state,
        mode : action.mode,
        activeId:action.id,
        activeInvoices: _.pick(_.find(state.invoices, { '_id': action.id }), ['invoiceId','referenceId','propertyunitId', 'propertyId', 'status', 'subtotal', 'taxPercentage', 'taxAmt', 'total', 'remark', 'paymentAmt', 'paymentType', 'paymentDate', '_id'])
      }
    default:
      return state

  }
}
