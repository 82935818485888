import React, { Component } from 'react'
import { Modal, message, Form, Input, Select } from 'antd'
import client from '../../feathers'
import requireAuth  from "../requireAuth"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateMode } from '../../actions/actions-accounts'

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

class AccountsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id:'',
      confirmDirty: false,
      autoCompleteResult: [],
      accountTypeDropdowns : [],
    }
  }

  componentWillMount(){
    //generate mng dropdown

    client.authenticate()
    .then(()=>{
        return client.service('parameters').find({
          query: {
            table: this.props.categories,
             $sort: {
               createdAt: -1
             }
           }
        })
    })
    .then((res)=>{
      this.setState({
        accountTypeDropdowns:res.data[0].items
      })
    })
    .catch((err)=>{
        console.log(err);
    });
  }

  componentDidUpdate(){
      if(this.props.accounts.activeId !== this.state._id){
        this.setState({
          _id:this.props.accounts.activeId
        })

        if(this.props.accounts.activeAccounts){
          this.props.form.setFieldsValue(
            this.props.accounts.activeAccounts
          )
        }
      }
  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }


  handleSubmit = (e) => {
     e.preventDefault();
     if(this.props.accounts.mode === 'new'){

       this.props.form.validateFields((err, values) => {
         if (!err) {
           client.service('accounts').create({
            accountmainId:sessionStorage.getItem('accountmainId'),
            categories:this.props.categories,
            accountType:values.accountType,
            accountName:values.accountName,
            accountId:values.accountId,
            subAccountId:values.subAccountId,
            accountCurrency:values.accountCurrency,
            description:values.description,
           })
           .then((res)=>{

             this.props.updateMode('')
             message.success('New Accounts Added');
           })
           .catch((err)=>{
            console.log(err);
             this.props.updateMode('')
             alert(err);
           })
         }
       });
     }else{
       this.props.form.validateFields((err, values) => {
         if (!err) {
           client.service('accounts').patch(this.props.accounts.activeId,
             values
           )
           .then((res)=>{

             message.success(`Accounts Updated`);
             this.props.updateMode('')
           })
           .catch((err)=>{
            console.log(err);
             
             this.props.updateMode('')
           })
           return;
         }
         
       });
     }
  }

  render() {

    const { getFieldDecorator } = this.props.form;


    return(
      <Modal
         visible={
           this.props.accounts.mode==='new'||this.props.accounts.mode==='edit'?true:false
         }
         title={ this.props.accounts.mode === 'edit' ? 'Update Accounts':'Create Accounts' }
         okText={ this.props.accounts.mode === 'edit' ? 'Update':'Create' }
         onCancel={()=>{this.props.updateMode(''); this.props.form.resetFields()} }
         onOk={this.handleSubmit}

      >
        <Form className="submit-form">

          <FormItem
            {...formItemLayout}
            label="Account Type"
            hasFeedback
          >
            {getFieldDecorator('accountType', {
              rules: [
                { required: true, message: 'Select Type!' },
              ],
            })(
              <Select placeholder="Select Type">
                {this.state.accountTypeDropdowns.map(dropdown => (
                  <Option value={dropdown.value}>
                    {dropdown.description}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>

          <FormItem
             {...formItemLayout}
             label="Account Description"
           >
             {getFieldDecorator('accountName', {
               rules: [{
                 required: true, message: 'Please input Accounts Description!',
               }],
             })(
               <Input placeholder="Lim Motors" />
             )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Account No."
          >
            {getFieldDecorator('accountId', {
              rules: [{
                required: true, message: 'Please input Account Number!',
              }],
            })(
              <Input placeholder="1000" />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Sub Account No."
          >
              {getFieldDecorator('subAccountId', {
              rules: [{
                required: true, message: 'Please input Account Number!',
              }],
            })(
              <Input placeholder="000" />
            )}
          </FormItem>

          <FormItem
             {...formItemLayout}
             label="Currency"
           >
             {getFieldDecorator('accountCurrency', {
               rules: [{
                 required: true, message: 'Please select Currency!',
               }],
             })(
              <Select placeholder="Please Select currency">
                <Option value="MYR">MYR - Malaysia Ringgit</Option>
              </Select>
             )}
          </FormItem>

          <FormItem
             {...formItemLayout}
             label="Description"
           >
             {getFieldDecorator('description', {
               rules: [{
                 required: true, message: 'Please input description!',
               }],
             })(
               <Input placeholder=""  />
             )}
          </FormItem>

        </Form>
      </Modal>

    )
  }
}


function mapStateToProps(state) {
  return {
    accounts: state.accounts
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
    }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(AccountsForm)));
