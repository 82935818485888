import React, { Component, useEffect } from 'react';
import { Modal, Upload,Popconfirm, message, Divider,  Table, Form, Icon, Input, Button, Select } from 'antd';
import client from '../../feathers';
import requireAuth  from "../requireAuth";
import Layout  from "../layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateMode } from '../../actions/actions-ledgers';

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset:4 },
};

const text = 'Are you sure to delete this item?';

class LedgersForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id:'',
      confirmDirty: false,
      autoCompleteResult: [],
    }
  }
  componentDidUpdate(){
      if(this.props.ledgers.activeId !== this.state._id){
        this.setState({
          _id:this.props.ledgers.activeId
        })

        if(this.props.ledgers.activeLedgers){
          this.props.form.setFieldsValue(
            this.props.ledgers.activeLedgers
          )
        }
      }
  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }


  handleSubmit = (e) => {
     e.preventDefault();
     if(this.props.ledgers.mode === 'new'){

       this.props.form.validateFields((err, values) => {
         if (!err) {
           client.service('ledgers').create(values)
           .then((res)=>{

             this.props.updateMode('')
             message.success('New Ledgers Added');
           })
           .catch((err)=>{
            console.log(err);
             this.props.updateMode('')
             alert(err);
           })
         }
       });
     }else{
       this.props.form.validateFields((err, values) => {
         if (!err) {
           client.service('ledgers').patch(this.props.ledgers.activeId,
             values
           )
           .then((res)=>{

             message.success(`Ledgers Updated`);
             this.props.updateMode('')
           })
           .catch((err)=>{
            console.log(err);
             
             this.props.updateMode('')
           })
           return;
         }
         
       });
     }
  }

  render() {
    const { visible, onCancel } = this.props;

    const { getFieldDecorator } = this.props.form;

    return(
      <Layout>
        <Form className="submit-form">


          <FormItem
             {...formItemLayout}
             label="Name"
           >
             {getFieldDecorator('name', {
               rules: [{
                 required: true, message: 'Please input Ledgers Name!',
               }],
             })(
               <Input prefix={<Icon type="idcard" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="SUPERHERO" />
             )}
          </FormItem>

          <FormItem
             {...formItemLayout}
             label="Description"
           >
             {getFieldDecorator('description', {
               rules: [{
                 required: false, message: 'Please input DEscription!',
               }],
             })(
              <TextArea rows={4} />
             )}
          </FormItem>

          <FormItem
             {...formItemLayout}
             label="Unit"
           >
             {getFieldDecorator('unit', {
               rules: [{
                 required: true, message: 'Please input Unit!',
               }],
             })(
               <Input prefix={<Icon type="tag" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="1" />
             )}
          </FormItem>

          <FormItem
             {...formItemLayout}
             label="Charges Amount"
           >
             {getFieldDecorator('amount', {
               rules: [{
                 required: false, message: 'Please input amount!',
               }],
             })(
               <Input placeholder="10.00" />
             )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Select Categories"
            hasFeedback
          >
            {getFieldDecorator('categories', {
              rules: [
                { required: true, message: 'Please select your categories!' },
              ],
            })(
              <Select placeholder="Please select a categories">
                <Option value="GYM">GYM</Option>
                <Option value="OTHERS">Others</Option>
              </Select>
            )}
          </FormItem>

        </Form>
      </Layout>

    )
  }
}


function mapStateToProps(state) {
  return {
    ledgers: state.ledgers
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
    }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(LedgersForm)));
