import React, { Component } from 'react';
import { Avatar, Row, Col, Card, Popconfirm, message, Divider,  Table, Form, Icon, Input, Button, Spin } from 'antd';
import client from '../../feathers';
import requireAuth  from "../../components/requireAuth";
import Layout  from "../../components/layout";
import Info  from "../../components/common/info";
import TransactionsPrint  from "../../components/transactions/print";
import ReactToPrint from "react-to-print";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchTransactions,
  pushTransactions,
  removeTransactions,
  updateMode,
  updateActiveTransactions,
  updateTransactions } from '../../actions/actions-transactions';

const FormItem = Form.Item;

class TransactionsView extends Component {
  constructor(props) {
      super(props);
      this.state = {
        loading:false
      }
  }

  componentDidMount(){
      client.service('transactions').on('updated', (transactions) => {
        this.props.updateTransactions(transactions)
      })
  }

  componentWillMount(){


    this.setState({loading:true});
    client.authenticate()
    .then((res)=>{

        return client.service('transactions').find({
          query: {
            _id:this.props.match.params.id,
             $sort: {
               createdAt: -1
             }
           }
        })
    })
    .then((res)=>{
      this.props.fetchTransactions(res.data);
      this.setState({loading:false});
    })
    .catch((err)=>{
        console.log(err);
        this.setState({loading:false});
    })
  }

  render() {
    //const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title: 'Invoice No',
        dataIndex: 'invoiceNo',
        key: 'invoiceNo',
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
      },
      {
        title: 'Payment Type',
        dataIndex: 'paymentType',
        key: 'paymentType',
      },
      {
        title: 'Channel',
        dataIndex: 'channel',
        key: 'channel',
      },
      {
        title: 'Date',
        dataIndex: 'createdAt',
        key: 'createdAt',
      }];

    return(
      <Layout>
        <Spin spinning={this.state.loading}>
        <Row>
          <Col style={{ marginBottom: 10,float: 'right'}}>
          <ReactToPrint
            trigger={() => <Button type="primary">Print</Button>}
            content={() => this.componentRef}
          />
          </Col>
          </Row>
          
          <TransactionsPrint paymentId={this.props.match.params.id} ref={el => (this.componentRef = el)} />
        </Spin>
      </Layout>
    )
  }
}

function mapStateToProps(state) {
  return {
    transactions: state.transactions
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchTransactions: fetchTransactions,
    pushTransactions: pushTransactions,
    removeTransactions: removeTransactions,
    updateActiveTransactions: updateActiveTransactions,
    updateTransactions: updateTransactions,
    updateMode: updateMode
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsView);
