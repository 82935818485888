import React, { Component } from 'react';
import { Modal, message, Form, Icon, Input, Button, Select } from 'antd';
import client from '../../feathers';
import requireAuth from '../../components/requireAuth';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateMode } from '../../actions/users-actions';

const FormItem = Form.Item;
const Option = Select.Option;
const _ = require('lodash');

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

class UsersFormMng extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',
      confirmDirty: false,
      autoCompleteResult: [],
      managementdropdowns: [],
      propertydropdowns: [],
    };
  }
  componentDidUpdate() {
    if (this.props.users.activeId !== this.state._id) {
      this.setState({
        _id: this.props.users.activeId,
      });

      if (this.props.users.activeUser) {
        let newEmail = _.get(this.props.users.activeUser, ['email']);

        if (!_.isEmpty(newEmail)) {
          newEmail = newEmail.slice(0, newEmail.indexOf('@'));
        }
        this.props.form.setFieldsValue({
          managementId: this.props.users.activeUser.managementId,
          role: this.props.users.activeUser.role,
          email: newEmail,
        });
      }
    }
  }

  componentWillMount() {}

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    // if(this.props.users.mode === 'new'){

    this.props.form.validateFields((err, values) => {
      let managementId = this.props.user.info.managementId._id;
      //customize email value
      if (values.email.indexOf('@') === -1) {
        values.email = `${values.email}@kbms.my`;
      }
      values.username = values.email;

      if (this.props.users.mode === 'new') {
        if (!err) {
          const fieldValues = { ...values, managementId: managementId };
          client
            .authenticate()
            .then((res) => {
              return client.service('users').create(fieldValues);
            })
            .then((res) => {
              this.props.updateMode('');
              this.props.submitSuccess();
              message.success('New User added');
            })
            .catch((err) => {
              // this.props.updateMode('')
              message.error(err.message);
              // alert(err);
            });
        }
      }
    });
  };

  handleChange = (value) => {
    client
      .service('managements')
      .find({
        query: {
          _id: value,
          $sort: {
            createdAt: -1,
          },
        },
      })
      .then((res) => {
        if (res.total == '1') {
          message.success('Management Found');
          this.props.form.setFieldsValue({
            propertyId: res.data[0].propertyId,
          });
        } else {
          message.error('Management not Found');
        }
      })
      .catch((err) => {
        console.log(err);
        message.error(err);
      });
  };

  handleSelectRole = (value) => {
    if (value != 'management') {
      this.props.form.setFieldsValue({
        managementId: '',
        propertyId: '',
        accountmainId: '',
      });
    }
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    let password = this.props.form.getFieldValue('password') || '';
    let confirm = this.props.form.getFieldValue('confirm') || '';
    var passw = /^(?=.*\d)(?=.*[A-Z]).{8,}/;

    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }

    if (!_.isEmpty(value)) {
      if (!passw.test(value)) {
        callback(
          'Password must be more than 8 characters at least 1 Uppercase, 1 lowercase and 1 digit'
        );
      }
      callback();
    }
    callback();
  };

  render() {
    // console.log(this.props.users)
    let checkIsEdit = false;
    try {
      if (!_.isEmpty(this.props.users.activeUser)) {
        checkIsEdit = true;
      }
    } catch (err) {
      checkIsEdit = false;
    }
    const { visible, onCancel } = this.props;

    const { getFieldDecorator } = this.props.form;

    return (
      <Modal
        visible={this.props.users.mode === 'new' ? true : false}
        title='Create User'
        okText='Create'
        onCancel={() => {
          this.props.updateMode('');
          this.props.form.resetFields();
        }}
        onOk={this.handleSubmit}
      >
        <Form className='submit-form'>
          <FormItem {...formItemLayout} label='Username'>
            {getFieldDecorator('email', {
              rules: [
                {
                  type: 'string',
                  message: 'The input is not valid Username!',
                },
                {
                  required: true,
                  message: 'Please input your Username!',
                },
              ],
            })(<Input />)}
          </FormItem>

          <FormItem {...formItemLayout} label='Password'>
            {getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  message: 'Please input your password!',
                },
                {
                  validator: this.validateToNextPassword,
                },
              ],
            })(
              <Input
                prefix={
                  <Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                placeholder='Password'
                type='password'
              />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label='Confirm Password'>
            {getFieldDecorator('confirm', {
              rules: [
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                {
                  validator: this.compareToFirstPassword,
                },
              ],
            })(
              <Input
                prefix={
                  <Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                placeholder='Confirm Password'
                type='password'
                onBlur={this.handleConfirmBlur}
              />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label='Role'>
            {getFieldDecorator('role', {
              rules: [
                {
                  required: true,
                  message: 'Please input your role!',
                },
              ],
            })(
              <Select placeholder='Select Role'>
                <Option value='management'>Management</Option>
                <Option value='admin'>Admin</Option>
                <Option value='executive'>Executive</Option>
                <Option value='account'>Account</Option>
              </Select>
            )}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.users,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateMode: updateMode,
    },
    dispatch
  );
}

export default requireAuth(
  connect(mapStateToProps, mapDispatchToProps)(Form.create()(UsersFormMng))
);
