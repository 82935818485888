import React, { Component } from 'react'
import { Modal, message, Form, Input, Select, Spin } from 'antd'
import client from '../../feathers'
import requireAuth from "../../components/requireAuth"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateMode } from '../../actions/actions-notices'
import {
  fetchPropertyunits,
} from '../../actions/actions-propertyunits';
import axios from 'axios';

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const _ = require('lodash');
const PAGESIZE = 20;

class NoticesForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',
      confirmDirty: false,
      autoCompleteResult: [],
      priorityDropdowns: [],
      searchUnitNumber: '',
      propertyunitsList: [],
      loading:false
    }

    this._searchUnitNumberChange = this._searchUnitNumberChange.bind(this);
  }
  componentDidUpdate() {
    if (this.props.notices.activeId !== this.state._id) {
      this.setState({
        _id: this.props.notices.activeId
      })

      if (this.props.notices.activeNotices) {
        this.props.form.setFieldsValue(
          this.props.notices.activeNotices
        )
      }
    }

  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }


  handleSubmit = (e) => {
    e.preventDefault();
    if (this.props.notices.mode === 'new') {

      this.props.form.validateFields((err, values) => {
        if (!err) {
          this.setState({ loading: true });

          client.service('notices').create({

            ...values,
            propertyId: sessionStorage.getItem('companyId'),

          })
            .then((res) => {
              message.success('New Notices Added');
              this.props.updateMode('refresh')
              this.setState({ loading: false });
            })
            .catch((err) => {
              console.log(err);
              this.props.updateMode('refresh')
              alert(err);
            })
        }
      });
    } else {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          client.service('notices').patch(this.props.notices.activeId,
            values
          )
            .then((res) => {
              message.success(`Notices Updated`);
              this.props.updateMode('refresh')
              this.setState({ loading: false });
            })
            .catch((err) => {
              console.log(err);

              this.props.updateMode('refresh')
            })
          return;
        }

      });
    }
  }

  componentWillMount() {

    client.authenticate()
      .then((res) => {

        return client.service('parameters').find({
          query: {
            table: "priority",
            $sort: {
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {

        this.setState({
          priorityDropdowns: res.data[0].items
        })
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          priorityDropdowns: []
        })
      })

  }
  //-----------------------------------------------------
  handleFileUpload = (event) => {

    this.setState({ loading: true });
    let formData = new FormData();

    formData.append('image', event.target.files[0]);

    axios.post(`${client.io.io.uri}upload-notice-image`,
      formData
      , {
        headers: {
          'Authorization': client.settings.storage.storage['feathers-jwt'],
          'Content-Type': 'multipart/form-data',
        }
      }
    ).then((res) => {

      this.props.form.setFieldsValue({
        file: res.data.imageUrl
      });
      this.setState({ loading: false });
      message.success('Upload Successful');
    })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
        message.error(err);
      })
  }
  //-----------------------------------------------------
  _searchUnitNumberChange(e) {
    this.setState({ fetching: true });
    this.setState({
      searchUnitNumber: e,
      searchObj: _.merge(this.state.searchObj, e ? { referenceId: e } : { referenceId: '.' }),
      typingTimeout: setTimeout(() => {
        this._getData(this.state.skip)
      }, 300)
    })
  }

  //-----------------------------------------------------
  _getData(skip) {
    client.authenticate()
      .then(() => {
        let searchObj = this.state.searchObj
        let query = {
          query: {
            propertyId: sessionStorage.getItem('companyId'),
            $limit: 20,
            $populate: 'ledgerAccountId',
            $sort: {
              referenceId: 1
            },
            $limit: PAGESIZE,
            orRegex: this.state.searchObj,
          }
        }
        _.assign(query.query, searchObj)
        return client.service('propertyunits').find(query)
      })
      .then((res) => {
        this.setState({
          propertyunitsList: res.data,
          fetching: false,
        });
        //this.props.fetchPropertyunits(res.data)
      })
      .catch((err) => {
        console.log(err);
        this.setState({ fetching: false });
      });
  }
  //-----------------------------------------------------
  render() {
    const { getFieldDecorator } = this.props.form;


    return (

      <Modal
        visible={
          this.props.notices.mode === 'new' || this.props.notices.mode === 'edit' ? true : false
        }
        title={this.props.notices.mode === 'edit' ? 'Update Notices' : 'Create Notices'}
        okText={this.props.notices.mode === 'edit' ? 'Update' : 'Create'}
        onCancel={() => { this.props.updateMode(''); this.props.form.resetFields() }}
        onOk={this.handleSubmit}
      >

        {/* propertyId    : {type:String,ref:'properties', required: true },
        propertyunitId: {type:String,ref:'propertyunits', required: true },
        chargeId      : {type:String,ref:'charges', required: true },
        description   : {type:String},
        amount        : {type:Number},
        status        : {type:String, required: true },   //ACTIVE, INACTIVE */}
        <Spin spinning={this.state.loading}>
          <Form className="submit-form">

            {/*}  <FormItem
             {...formItemLayout}
             label="Property Unit No."
           >
             {getFieldDecorator('propertyunitId', {
               rules: [{
                 required: true, message: 'Please input!',
               }],
             })(
               <Select
                  showSearch
                  showArrow={false}
                  filterOption={false}
                  notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
                  style={{ width: '100%' }}
                  placeholder="Please Input to Search"
                  value={this.state.searchUnitNumber}
                  onSearch={this._searchUnitNumberChange}

                  >
                  {this.state.propertyunitsList.map(d => <Option key={d._id}>{d.referenceId}</Option>)}
                  </Select>
             )}
          </FormItem>   */}

            <FormItem
              {...formItemLayout}
              label="Notices Title"
            >
              {getFieldDecorator('subject', {
                rules: [{
                  required: true, message: 'Please input Subject!',
                }],
              })(
                <Input />
              )}
            </FormItem>

            {/*}
          <FormItem
             {...formItemLayout}
             label="Charges"
           >
             {getFieldDecorator('chargeId', {
               rules: [{
                 required: false, message: 'Please select Charges!',
               }],
             })(
              <TextArea rows={4} />
             )}
          </FormItem>
          */}

            <FormItem
              {...formItemLayout}
              label="Target"
            >
              {getFieldDecorator('target', {
                initialValue: 'ALL',
                rules: [{
                  required: true, message: 'Please input Target!',
                }],
              })(
                <Input disabled={true} />
              )}
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Description"
            >
              {getFieldDecorator('description', {
                rules: [{
                  required: true, message: 'Please input Description!',
                }],
              })(
                <TextArea rows={4} />
              )}
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Upload"
            >
              <Input label='upload file' type='file' onChange={this.handleFileUpload} />
            </FormItem>,

            <FormItem
              {...formItemLayout}
              label="File Name"
              style={{ 'display': 'none' }}
            >
              {getFieldDecorator('file', {
                rules: [{
                  required: false, message: 'Please Upload File!',
                }],
              })(
                <Input style={{ 'display': 'none' }} disabled="true" placeholder="File Name" />
              )}
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Status"
            >
              {getFieldDecorator('status', {
                rules: [{
                  required: true, message: 'Please Select Status!',
                }],
              })(
                <Select placeholder="Please Select Status">
                  <Option value="ACTIVE">ACTIVE</Option>
                  <Option value="INACTIVE">INACTIVE</Option>
                </Select>
              )}
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Select Priority"
              hasFeedback
            >
              {getFieldDecorator('priority', {
                rules: [
                  { required: true, message: 'Please select priority!' },
                ],
              })(
                <Select placeholder="Please Selct priority">
                  {this.state.priorityDropdowns.map(dropdown => (
                    <Option value={dropdown.value}>
                      {dropdown.description}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>

          </Form>
        </Spin>
      </Modal>

    )
  }
}


function mapStateToProps(state) {
  return {
    notices: state.notices
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchPropertyunits: fetchPropertyunits,
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(NoticesForm)));
