import React, { Component } from 'react';
import { Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Card, Badge, Row, Col, Select, Spin } from 'antd';
import { Route, Link } from 'react-router-dom';

import client from '../../feathers';
import requireAuth from "../../components/requireAuth";
import Layout from "../../components/layout";
import PropertyunitsForm from "../../components/propertyunits/form-mng";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchPropertyunits,
  pushPropertyunits,
  removePropertyunits,
  updateMode,
  updateActivePropertyunits,
  updatePropertyunits
} from '../../actions/actions-propertyunits';


const _ = require('lodash');
const FormItem = Form.Item;
const Option = Select.Option;


const PAGESIZE = 20;
const text = 'Are you sure to delete this item?';

class PropertyunitsIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchUnitNumber: '',
      totalPagination: 0,
      loading: true,
    }

    this.handleDelete = this.handleDelete.bind(this);
    this._searchUnitNumberChange = this._searchUnitNumberChange.bind(this);
  }

  componentDidUpdate() {

    if (this.props.propertyunits.mode === 'refresh') {

      this.componentWillMount()
    }

  }

  componentDidMount() {

  }



  componentWillMount() {
    this.props.updateMode('');
    this.setState({ loading: true });

    client.authenticate()
      .then((res) => {
        return client.service('propertyunits').find({
          query: {
            propertyId: this.props.commons.selectedCompany._id,
            //$limit: 20,
            //$populate:'propertyId',
            //propertyId:{_id:res.propertyId},
            //propertyId:res.propertyId,
            $limit: 20,
            $populate: 'ledgerAccountId',
            $sort: {
              referenceId: 1
              //createdAt: -1
            }
          }
        })
      })
      .then((res) => {

        this.setState({ totalPagination: res.total, loading: false });
        this.props.fetchPropertyunits(res.data)


      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      })
  }

  handleDelete(_id) {
    client.service('propertyunits').remove(_id)
      .then((res) => {
      })
      .catch((err) => {
        alert(err);
      })
  }

  handleEdit(_id) {
    this.props.updateActivePropertyunits('edit', _id)

  }

  handleManage(_id) {
    this.props.updateActivePropertyunits('', _id)
  }

  showModal = () => {
    this.props.updateMode('new')
  }

  formatAmt(amt) {
    let integer = parseFloat(amt);
    return integer > 0 ? integer.toFixed(2) : 0.00;
  }

  renderConfirmDeleteBtn(_id) {
    return (
      <Popconfirm placement="topLeft" title={text} onConfirm={() => this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
        <Button type="danger">Delete</Button>
      </Popconfirm>
    )
  }

  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      client.authenticate()
        .then((res) => {
          //
          return client.service('propertyunits').find({
            query: {
              ...values,
              propertyId: this.props.commons.selectedCompany._id,
              $populate: 'ledgerAccountId',
              $sort: {
                referenceId: 1
                //createdAt: -1
              }
            }
          })
        })
        .then((res) => {
          this.props.fetchPropertyunits(res.data);
        })
        .catch((err) => {
          console.log(err);
        });

    });
  }

  handlePageChange = (page) => {

    this.setState({
      CurrentPagination: page,
      loading: true,
    });
    this.props.form.validateFields((err, values) => {
      client.authenticate()
        .then((res) => {
          //
          return client.service('propertyunits').find({
            query: {
              propertyId: this.props.commons.selectedCompany._id,
              $limit: 20,
              $skip: (page - 1) * 20,
              $populate: 'ledgerAccountId',
              $sort: {
                referenceId: 1
                //createdAt: -1
              }
            }
          })
        })
        .then((res) => {
          //
          this.setState({ totalPagination: res.total, loading: false });
          this.props.fetchPropertyunits(res.data)
        })
        .catch((err) => {
          console.log(err);
        })
    });
  }

  renderSearching() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form
        className="ant-advanced-search-form"
        onSubmit={this.handleSearch}
      >
        <Row gutter={24} style={{ display: 'block' }}>
          <Col span={8}>
            <Form.Item label='Unit Number'>
              {getFieldDecorator(`unitNo`, {
                rules: [{
                  required: false,
                  message: 'Unit Number!',
                }],
              })(
                <Input placeholder="B-1-2" />
              )}
            </Form.Item>
          </Col>

          <Button type="primary" htmlType="submit" style={{ float: 'right' }} >Search</Button>
        </Row>

      </Form>
    )
  }



  _searchUnitNumberChange(e) {
    this.setState({
      loading: true,
      searchUnitNumber: e.target.value,
      searchObj: _.merge(this.state.searchObj, e.target.value ? { referenceId: e.target.value } : { referenceId: '.' }),
      typingTimeout: setTimeout(() => {
        this._getData(this.state.skip)
      }, 500)
    })
  }

  _getData(skip) {


    client.authenticate()
      .then(() => {
        let searchObj = this.state.searchObj
        let query = {
          query: {
            propertyId: this.props.commons.selectedCompany._id,
            $limit: 20,
            $populate: 'ledgerAccountId',
            $sort: {
              referenceId: 1
            },
            $limit: PAGESIZE,
            orRegex: this.state.searchObj,
          }
        }
        _.assign(query.query, searchObj)
        return client.service('propertyunits').find(query)
      })
      .then((res) => {
        this.setState({
          loading: false,
        });

        this.props.fetchPropertyunits(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }


  //---------------Render Part-----------------------------//
  render() {
    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title:
          (<Row gutter={8}>
            <Row><Col span={24}>Unit Number</Col></Row>
            <Row><Col span={24}><Input value={this.state.searchUnitNumber} onChange={this._searchUnitNumberChange} /></Col></Row>
          </Row>
          ),

        dataIndex: 'referenceId',
        key: 'referenceId',
        width: '15%',
      },

      {
        title: 'Action',
        key: 'action',
        width: '55%',
        render: (text, record) => (
          <span>
            <Divider type="vertical" />
            <Link
              to={{
                pathname: `/firstTime/invoice/open/${record._id}`
              }}
            >
              <Button>Add Invoice</Button>
            </Link>
            <Divider type="vertical" />
            <Link
              to={{
                pathname: `/firstTime/prepayment/open/${record._id}`
              }}
            >
              <Button>Add Prepayment</Button>
            </Link>
          </span>
        ),
      }];

    return (
      <Layout>
        <Spin spinning={this.state.loading}>
          <Card
            title='Property Unit Info'
          >



            <Table
              dataSource={this.props.propertyunits.propertyunits}
              columns={columns}
              rowKey='_id'
              pagination={{
                defaultCurrent: 1,
                total: this.state.totalPagination,
                onChange: this.handlePageChange,
                pageSize: 20,
                size: 'small'
              }}
            />



            <PropertyunitsForm />
          </Card>
        </Spin>
      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    propertyunits: state.propertyunits,
    commons: state.commons
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchPropertyunits: fetchPropertyunits,
    pushPropertyunits: pushPropertyunits,
    removePropertyunits: removePropertyunits,
    updateActivePropertyunits: updateActivePropertyunits,
    updatePropertyunits: updatePropertyunits,
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(PropertyunitsIndex)));
