import React, { Component } from 'react';
import {
  Popconfirm,
  message,
  Divider,
  Table,
  Form,
  Icon,
  Input,
  Button,
  Card,
  Spin,
} from 'antd';
import { Route, Link, StaticRouter } from 'react-router-dom';

import client from '../../feathers';
import requireAuth from '../../components/requireAuth';
import Layout from '../../components/layout';
import SuppliersForm from '../../components/suppliers/form';
import PaymentBySupplier from '../../components/paymentvoucher/form-paymentvoucher-new';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  fetchSuppliers,
  pushSuppliers,
  removeSuppliers,
  updateMode,
  updateActiveSuppliers,
  updateSuppliers,
} from '../../actions/actions-suppliers';
import { updateDate } from '../../actions/actions-ledgerAccounts';

const FormItem = Form.Item;

const text = 'Are you sure to delete this item?';
const formatCurr = function format1(n = 0, currency = null) {
  let oriAmt = n;
  n = n < 0 ? Math.abs(n) : n;
  let amt = currency
    ? currency
    : '' +
      n.toFixed(2).replace(/./g, function(c, i, a) {
        return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c;
      });

  return oriAmt < 0 ? '(' + amt + ')' : amt;
};

class SuppliersIndex extends Component {
  constructor(props) {
    super(props);

    this.handleDelete = this.handleDelete.bind(this);
  }

  state = {
    loading: false,
  };

  componentDidUpdate() {}

  componentDidMount() {
    client.service('suppliers').on('created', (suppliers) => {
      if (suppliers.propertyId === sessionStorage.getItem('companyId')) {
        this.componentWillMount();
      }
    });

    client.service('suppliers').on('removed', (suppliers) => {
      if (suppliers.propertyId === sessionStorage.getItem('companyId')) {
        this.componentWillMount();
      }
    });

    client.service('suppliers').on('updated', (suppliers) => {
      if (suppliers.propertyId === sessionStorage.getItem('companyId')) {
        this.componentWillMount();
      }
    });

    client.service('suppliers').on('patched', (suppliers) => {
      if (suppliers.propertyId === sessionStorage.getItem('companyId')) {
        this.componentWillMount();
      }
    });
  }

  componentWillMount() {
    this.setState({ loading: true });

    client
      .authenticate()
      .then((res) => {
        return client.service('suppliers').find({
          query: {
            propertyId: this.props.commons.selectedCompany._id,
            $populate: 'ledgerAccountId',
            $sort: {
              createdAt: -1,
            },
          },
        });
      })
      .then((res) => {
        this.props.fetchSuppliers(res.data);
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleDelete(_id) {
    client
      .service('suppliers')
      .remove(_id)
      .then((res) => {})
      .catch((err) => {
        alert(err);
      });
  }

  handleEdit(_id) {
    this.props.updateActiveSuppliers('edit', _id);
  }

  handleManage(_id) {
    this.props.updateActiveSuppliers('', _id);
  }

  showModal = () => {
    this.props.updateMode('new');
  };
  showPayBySupplier = (_id) => {
    this.props.updateActiveSuppliers('pay', _id);
  };

  renderConfirmDeleteBtn(_id) {
    return (
      <Popconfirm
        placement='topLeft'
        title={text}
        onConfirm={() => this.handleDelete(_id)}
        okText='Confirm'
        cancelText='Cancel'
      >
        <Button type='danger'>Delete</Button>
      </Popconfirm>
    );
  }

  renderEditButton(record) {
    if (this.props.location.state !== 'PV') {
      return (
        <span>
          <Divider type='vertical' />
          <Button onClick={() => this.handleEdit(record._id)}>Edit</Button>
        </span>
      );
    }
  }

  renderNewSupplier() {
    if (this.props.location.state !== 'PV') {
      return (
        <Button type='primary' onClick={this.showModal}>
          New Suppliers
        </Button>
      );
    }
  }

  renderPayButton(record) {
    if (this.props.location.state == 'PV') {
      // return (
      //   <Link
      //     to={{
      //       pathname: `/paymentvoucher/pay-supplier/${record._id}`
      //     }}>
      //     <Button type='primary'>Pay</Button>
      //   </Link>
      // )

      return (
        <Button
          type='primary'
          onClick={() => {
            this.showPayBySupplier(record._id);
          }}
        >
          Pay
        </Button>
      );
    }
  }

  renderTitle() {
    if (this.props.location.state !== 'PV') {
      return 'Supplier';
    }
    return 'Payment Voucher';
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title: 'Code',
        dataIndex: 'supplierCode',
        key: 'supplierCode',
      },
      {
        title: 'Full Account No',
        dataIndex: 'ledgerAccountId.fullAccountNo',
        key: 'fullAccountNo',
      },

      {
        title: 'Account Name',
        dataIndex: 'ledgerAccountId.accountName',
        key: 'description',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Contact Number',
        dataIndex: 'contactNo',
        key: 'contactNo',
      },

      {
        title: 'Outstanding Ammount',
        dataIndex: 'ledgerAccountId.balance',
        key: 'balance',
        align: 'right',
        render: (text, record) => {
          return formatCurr(text ? text : 0);
        },
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            {this.renderEditButton(record)}
            <Divider type='vertical' />
            <Link
              target='blank'
              to={{
                pathname: `/ledgers/transactions/${record.ledgerAccountId._id}`,
              }}
            >
              <Button
                onClick={() => {
                  this.props.updateDate('');
                }}
              >
                Transactions
              </Button>
            </Link>
            {/*
          <Button onClick={()=>this.handleEdit(record._id)}>Edit</Button>
          */}

            {/*
          <Divider type="vertical" />
          {this.renderConfirmDeleteBtn(record._id)}
*/}

            {/* {this.renderPayButton(record)} */}

            {/* <Divider type="vertical" /> */}
            {/* <Link
              to={{
                pathname: `/paymentvoucher/paymentvoucher-multipay/${record._id}`
              }}
            >
              <Button>Pay</Button>
            </Link> */}
          </span>
        ),
      },
    ];

    return (
      <Layout>
        <Spin key='spinning' spinning={this.state.loading}>
          <Card
            //title={'Suppliers'}
            title={this.renderTitle()}
            extra={this.renderNewSupplier()}
          >
            <Table
              dataSource={this.props.suppliers.suppliers}
              columns={columns}
              rowKey='_id'
            />

            <SuppliersForm />
            <PaymentBySupplier />
          </Card>
        </Spin>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    suppliers: state.suppliers,
    commons: state.commons,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchSuppliers: fetchSuppliers,
      pushSuppliers: pushSuppliers,
      removeSuppliers: removeSuppliers,
      updateActiveSuppliers: updateActiveSuppliers,
      updateSuppliers: updateSuppliers,
      updateMode: updateMode,
      updateDate: updateDate,
    },
    dispatch
  );
}

export default requireAuth(
  connect(mapStateToProps, mapDispatchToProps)(Form.create()(SuppliersIndex))
);
