import React, { Component } from 'react'
import { Modal, message, Form, Input, Select,Row,Col } from 'antd'
import client from '../../feathers'
import requireAuth  from "../requireAuth"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateMode } from '../../actions/actions-accounttrxs'

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

class AccounttrxsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id:'',
      confirmDirty: false,
      autoCompleteResult: [],
      accountAssestsDropdowns :[],
      accountExpensesDropdowns :[],
    }
  }

  componentWillMount(){

    client.authenticate()
    .then((res)=>{

        return client.service('accounts').find({
          query: {
            $limit: 99,
            accountmainId:sessionStorage.getItem('accountmainId'),
            //categories:'assests',
             $sort: {
               createdAt: -1
             }
           }
        })
    })
    .then((res)=>{
      this.setState({
        accountAssestsDropdowns:res.data,
      })
    })
    .catch((err)=>{
        console.log(err);
        this.setState({
          accountAssestsDropdowns:[]
        })
    })

    client.authenticate()
    .then((res)=>{

        return client.service('accounts').find({
          query: {
            $limit: 99,
            accountmainId:sessionStorage.getItem('accountmainId'),
            //categories:'expenses',
             $sort: {
               createdAt: -1
             }
           }
        })
    })
    .then((res)=>{
      this.setState({
        accountExpensesDropdowns:res.data,
      })
    })
    .catch((err)=>{
        console.log(err);
        this.setState({
          accountExpensesDropdowns:[]
        })
    })

  }

  componentDidUpdate(){
      if(this.props.accounttrxs.activeId !== this.state._id){
        this.setState({
          _id:this.props.accounttrxs.activeId
        })

        if(this.props.accounttrxs.activeAccounttrxs){
          this.props.form.setFieldsValue(
            this.props.accounttrxs.activeAccounttrxs
          )
        }
      }
  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }


  handleSubmit = (e) => {
     e.preventDefault();
     if(this.props.accounttrxs.mode === 'new-journal'){

       this.props.form.validateFields((err, fieldsValue) => {
         if (!err) {

          const values = {
            ...fieldsValue,
            'accountmainId': sessionStorage.getItem('accountmainId'),
            'creditDebit': 'DEBIT',
            'trxType': 'JOURNAL',
          };

           client.service('accounttrxs').create(values)
           .then((res)=>{

             this.props.updateMode('');
             message.success('New Journal Added');
           })
           .catch((err)=>{
            console.log(err);
             this.props.updateMode('')
             alert(err);
           })
         }
       });
     }else{
       this.props.form.validateFields((err, fieldsValue) => {

        const values = {
          ...fieldsValue,
          'accountmainId': sessionStorage.getItem('accountmainId'),
        };

         if (!err) {
           client.service('accounttrxs').patch(this.props.accounttrxs.activeId,
             values
           )
           .then((res)=>{

             message.success(`Account Updated`);
             this.props.updateMode('')
           })
           .catch((err)=>{
            console.log(err);
             
             this.props.updateMode('')
           })
           return;
         }
         
       });
     }
  }

  render() {

    const { getFieldDecorator } = this.props.form;


    return(
      <Modal
         visible={
           this.props.accounttrxs.mode==='new-journal'||this.props.accounttrxs.mode==='edit-journal'?true:false
         }
         title={ this.props.accounttrxs.mode === 'edit' ? 'Update Journal':'Create Journal' }
         okText={ this.props.accounttrxs.mode === 'edit' ? 'Update':'Create' }
         onCancel={()=>{this.props.updateMode(''); this.props.form.resetFields()} }
         onOk={this.handleSubmit}

      >
        <Form className="submit-form">

         Description
          <FormItem>
             {getFieldDecorator('description', {
               rules: [{
                 required: true, message: 'Please input Description!',
               }],
             })(
              <TextArea rows={4} />
             )}
          </FormItem>

          <Row>
            <Col span={12}>
                DEBIT
                <FormItem>
                  {getFieldDecorator('accountId', {
                    rules: [{
                      required: true, message: 'Please Select Debit Account!',
                    }],
                  })(
                    <Select placeholder="Debit Account">
                      {this.state.accountAssestsDropdowns.map(dropdown => (
                        <Option value={dropdown._id}>
                          {dropdown.description}
                        </Option>
                      ))}
                    </Select>
                  )}
                </FormItem>

                <FormItem>
                  {getFieldDecorator('amount', {
                    rules: [{
                      required: true, message: 'Please input Amount!',
                    }],
                  })(
                      <Input placeholder="100.00" />
                  )}
                </FormItem>
            </Col>
            <Col span={12}>
              CREDIT
              <FormItem>
                {getFieldDecorator('category', {
                  rules: [{
                    required: true, message: 'Please Select Credit Account!',
                  }],
                })(
                  <Select placeholder="Credit Account">
                    {this.state.accountExpensesDropdowns.map(dropdown => (
                      <Option value={dropdown._id}>
                        {dropdown.description}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>

              <FormItem>
                {getFieldDecorator('amount', {
                  rules: [{
                    required: true, message: 'Please input Amount!',
                  }],
                })(
                    <Input placeholder="100.00" />
                )}
              </FormItem>
            </Col>
          </Row>

          Date
          <FormItem>
             {getFieldDecorator('trxDate', {
               rules: [{
                 required: true, message: 'Please Select date!',
               }],
             })(
                <Input type='date' />
             )}
          </FormItem>

          Remark
          <FormItem>
             {getFieldDecorator('remark', {
               rules: [{
                 required: false, message: 'Please Input Remark!',
               }],
             })(
                <TextArea rows={4} />
             )}
          </FormItem>

        </Form>
      </Modal>

    )
  }
}


function mapStateToProps(state) {
  return {
    accounttrxs: state.accounttrxs
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
    }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(AccounttrxsForm)));
