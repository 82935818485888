export const FETCH_VENDORS = 'FETCH_VENDORS';
export const PUSH_VENDORS = 'PUSH_VENDORS';
export const REMOVE_VENDORS = 'REMOVE_VENDORS';
export const UPDATE_VENDORS = 'UPDATE_VENDORS';
export const UPDATE_MODE = 'UPDATE_MODE';
export const UPDATE_ACTIVE_ID = 'UPDATE_ACTIVE_ID';

export function fetchVendors(data){
  return (dispatch) => {
    dispatch({
      type: FETCH_VENDORS,
      payload:data
    });
  }
}

export function pushVendors(data){
  return (dispatch) => {
    dispatch({
      type: PUSH_VENDORS,
      payload:data
    });
  }
}

export function removeVendors(data){
  return (dispatch) => {
    dispatch({
      type: REMOVE_VENDORS,
      payload:data
    });
  }
}

export function updateVendors(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_VENDORS,
      payload:data
    });
  }
}

export function updateMode(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE,
      payload:data
    });
  }
}

export function updateActiveVendors(mode, id){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_ID,
      mode,
      id
    });
  }
}
