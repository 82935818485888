import React, { Component, useEffect, useRef } from 'react';
import { Modal, message, Table, Form, Input, Button, Select, Card, Row, Col } from 'antd';
import client from '../../feathers';
import requireAuth from "../requireAuth";
import Layout from "../layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Route, Link } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import axios from 'axios';


const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};

const text = 'Are you sure to delete this item?';


class IndexAppLogs extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      totalPagination: 0,
      CurrentPagination: 1,
      loading: false,
      visible: false,
      id: '',
    }
  }

  componentWillMount() {

    this.setState({ loading: true });
    client.authenticate()
      .then(() => {
        return client.service('applogs').find({
          query: {
            propertyId: this.props.user.info.propertyId._id,
            $populate: 'items.invoiceId',
            $limit: 20,
            $sort: {
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {
        this.setState({
          dataSource: res.data,
          totalPagination: res.total,
          loading: false
        });
      })
      .catch((err) => {
        console.log(err);
      })
  }

  convertDate(date = null) {
    let tempDate = new Date(date);
    let d = tempDate.getDate();
    let m = tempDate.getMonth() + 1
    let y = tempDate.getFullYear();
    return date ? d + '/' + m + '/' + y : '';
  }

  showModal = (e, id) => {
    this.setState({
      id: id,
      visible: true,
    });
  };

  handleCancel = e => {
    this.setState({
      visible: false,
    });
  };

  handleOk = e => {

    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        axios.get(`${client.io.io.uri}applogs-invoices`, {
          params: {
            id: this.state.id,
            email: values.email,
            companyId: this.props.user.info.propertyId._id
          },
          headers: {
            'Authorization': client.settings.storage.storage['feathers-jwt']
          }
        });
        this.setState({
          visible: false,
        });
        message.success('Email will be sent in a while.');
      }
    })
  };

  handlePageChange = (page) => {
    console.log(page);
    this.setState({
      CurrentPagination: page,
      loading: true
    });

    client.authenticate()
      .then((res) => {

        return client.service('applogs').find({
          query: {
            propertyId: this.props.user.info.propertyId._id,
            $populate: 'items.invoiceId',
            $limit: 20,
            $skip: (page - 1) * 20,
            $sort: {
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {

        this.setState({
          dataSource: res.data,
          totalPagination: res.total,
          loading: false
        });
      })
      .catch((err) => {
        console.log(err);
      });

  }

  render() {
    const dataSource = this.state.dataSource;
    const totalPagination = this.state.totalPagination;
    const loading = this.state.loading;
    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
      },
      {
        title: 'Remark',
        dataIndex: 'remark',
        key: 'remark',
      },
      {
        title: 'Total Invoices',
        dataIndex: 'invoiceCount',
        align: 'right',
      },
      {
        title: 'Statement Date',
        dataIndex: 'items[0].invoiceId.invoiceDate',
        render: (text, record) => (
          <span>
            {this.convertDate(text)}
          </span>
        ),
      },
      {
        title: 'Created At',
        dataIndex: 'createdAt',
        render: (text, record) => (
          <span>
            {this.convertDate(text)}
          </span>
        ),
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <Row gutter={20}>
            <Col span={6}><Button type='primary'><Link to={{ pathname: `/applogs/form/${record._id}` }} >View</Link></Button></Col>
            <Col span={6}><Button type='primary' onClick={(event) => this.showModal(event, record._id)}>Send To Email</Button></Col>
          </Row>
        ),
      }
    ];

    return (
      <Layout>
        <Card
          loading={loading}
          title={'Recurring Invoice History'}
        >
          <Modal
            title="Send To Email"
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
          >
            <Form className="submit-form">

              <FormItem {...formItemLayout} label="Email">
                {getFieldDecorator('email', {
                  rules: [{
                    type: 'email', message: 'The input is not valid E-mail!'
                  }, { required: true, message: 'Please input E-mail!' }],

                })(
                  <Input placeholder="Email" />
                )}
              </FormItem>

            </Form>
          </Modal>




          <Table
            dataSource={dataSource}
            columns={columns}
            size={'small'}
            pagination={{
              defaultCurrent: 1,
              total: totalPagination,
              onChange: this.handlePageChange,
              pageSize: 20,
            }}
            rowKey='_id'
          />


        </Card>
      </Layout>
    );
  }
}


function mapStateToProps(state) {
  return {
    //accounts: state.accounts
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    //updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(IndexAppLogs)));
