
import firebase from 'firebase/app';
import 'firebase/messaging';
import localforage from 'localforage';

const PUBLIC_VAPID_KEY = "BAexeu3MIOZLD2gKft2ajAmsZ87ggBwSt7FfZfac4eD6-OZeDOZVYyqKErd5Fn_ip9-lJ6B-b1OI0LjHpc4okVA";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAS1FjgVoeWVfG7BpUWDQ7UZOWihRvweP4",
    authDomain: "kbms-ptl.firebaseapp.com",
    projectId: "kbms-ptl",
    storageBucket: "kbms-ptl.appspot.com",
    messagingSenderId: "1063146762147",
    appId: "1:1063146762147:web:c348e3c7264417a663b416",
    measurementId: "G-1ZH1Y1GGF2"
};


if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

export const tokenInlocalforage = async () => {
    return localforage.getItem('fcm_token');
}


export const initFirebaseToken = async () => {
    try {
        const messaging = firebase.messaging();
        // const tokenInLocalForage = await tokenInlocalforage();
        //if FCM token is already there just return the token
        // if (tokenInLocalForage !== null) {
        //     return tokenInLocalForage;
        // }
        //requesting notification permission from browser
        const status = await Notification.requestPermission();
        // console.log(status)
        if (status && status === 'granted') {
            // console.log('been here')
            //getting token from FCM
            const fcm_token = await messaging.getToken({ vapidKey: PUBLIC_VAPID_KEY });


            if (fcm_token) {
                //setting FCM token in indexed db using localforage
                // localforage.setItem('fcm_token', fcm_token);
                //return the FCM token after saving it
                return fcm_token;
            }
        }
    } catch (error) {
        console.error(error);
        return null;
    }
}

export const onMessageListener = () => {

    const messaging = firebase.messaging();
    return new Promise((resolve) => {
        messaging.onMessage((payload) => {
            // console.log('get message');
            // console.log(payload);
            resolve(payload);
        });
    });
}
