import React from "react";

const SvgPark = props => (
  <svg viewBox="0 0 463 463" width={512} height={512} {...props}>
    <path
      fill="#8D99AE"
      d="M455.5 384H407v-65.687c18.205-3.337 32-18.571 32-36.813 0-9.229-3.692-32.216-9.657-53.258C420.787 198.058 411.305 184 399.5 184s-21.287 14.058-29.843 44.242C363.692 249.284 360 272.271 360 281.5c0 18.243 13.795 33.477 32 36.813V384h-49V197.766c9.29-3.138 16-11.93 16-22.266v-32a7.5 7.5 0 00-7.5-7.5H343v-9h16.5c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-48c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5H328v9h-8.5a7.5 7.5 0 00-7.5 7.5v32c0 10.336 6.71 19.128 16 22.266V384h-26.222l-5.096-25h6.817c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5H287v-17h8.5c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5H287v-9h8.5c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-144c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h8.5v9h-8.5c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h8.5v17h-16.5c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h6.817l-5.096 25H87v-73.458c31.49-3.724 56-30.567 56-63.042 0-56.341-54.382-174.169-56.697-179.157a7.499 7.499 0 00-13.606 0C70.382 73.331 16 191.159 16 247.5c0 32.475 24.51 59.318 56 63.042V384H7.5c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h448c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5zM375 281.5c0-6.688 2.938-26.702 8.452-46.881 8.021-29.353 14.763-34.74 16.048-35.526 1.285.786 8.026 6.173 16.048 35.526 5.515 20.18 8.452 40.193 8.452 46.881 0 10.005-7.149 18.501-17 21.417V287.5c0-4.142-3.357-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v15.417c-9.851-2.916-17-11.412-17-21.417zM327 151h17v24.5c0 4.687-3.813 8.5-8.5 8.5s-8.5-3.813-8.5-8.5V151zM31 247.5c0-41.627 33.719-123.632 48.5-157.579C94.281 123.868 128 205.873 128 247.5c0 24.193-17.806 44.302-41 47.918v-23.913V250.607l21.804-21.804a7.5 7.5 0 00-10.607-10.606L87 229.393V215.5c0-4.142-3.357-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v37.893l-11.196-11.196a7.5 7.5 0 00-10.607 10.606L72 274.607v20.811c-23.194-3.616-41-23.725-41-47.918zM175 303h97v9h-97v-9zm0 24h97v17h-97v-17zm-9.375 32h115.75l5.095 25H160.53l5.095-25z"
      data-original="#000000"
      className="park_svg__active-path"
      data-old_color="#000000"
    />
  </svg>
);

export default SvgPark;
