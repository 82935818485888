import React, { Component } from 'react'
import { Row, Col, Layout } from 'antd';
import header from '../assets/images/header.png';
const { Content, Footer, Header } = Layout;

class layout extends Component {
  state = {
    collapsed: false,
  };
  onCollapse = (collapsed) => {

    this.setState({ collapsed });
  }

  render() {
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Layout>

          <Content style={{ margin: '100px 16px', }}>
            <Row>
              <Col xl={{ span: 6, offset: 9 }} lg={{ span: 12, offset: 6 }} md={{ span: 12, offset: 6 }} sm={{ span: 14, offset: 5 }} >
                <div className='box-shadow border-top-primary' style={{ padding: 24, minHeight: 360, background: '#fff', borderColor: 'black' }}>
                  {this.props.children}
                </div>
              </Col>
            </Row>
          </Content>
          <Footer style={{ textAlign: 'right' }}>
            © 2021 Mr Tech System Holdings Sdn Bhd (1370083-M)
          </Footer>
        </Layout>
      </Layout>
    )
  }
}

export default layout;
