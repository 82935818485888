import React, { Component } from 'react';
import { Modal, Avatar, Row, Col, Card, Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Select, Radio, Switch, Spin } from 'antd';
import client from '../../feathers';
import requireAuth from "../requireAuth";
import Layout from "../layout";
import Info from "../common/info";
import moment from 'moment';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchPaymentVouchers,
  pushPaymentVouchers,
  removePaymentVouchers,
  updateMode,
  updateActivePaymentVouchers,
  updatePaymentVouchers
} from '../../actions/actions-paymentvouchers';


const toWords = require('to-words');
const { TextArea } = Input;

const formatCurr = function format1(n = 0, currency = null) {
  let oriAmt = n;
  n = n < 0 ? Math.abs(n) : n;
  n = n ? n : 0;
  let amt = currency ? currency : ' ' + n.toFixed(2).replace(/./g, function (c, i, a) {
    return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
  });

  return oriAmt < 0 ? '(' + amt + ')' : amt;
}
const thisStyle = {
  padding: '0px'
};

class PaymentVouchersPrint extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentvouchers: [],
      dataSource: [],
      unit: '',
      visible: false,
      setting: [],
      selectedRowKeys: [], // Check here to configure the default column,
      jmb: [],
      customer: [],
      owner: [],
      hasError: false,
      mailingAddress: '',
      loading: false,
      creditledgerAccountNo: '',
      creditledgerAccountName: '',
      debitledgerAccountNo: '',
      debitledgerAccountName: '',
      supplierName: '',
      supplierCode: '',
      preparebyName: '',
      payeeCode: '',
      totalAmount: 1,
      voucherNo: '',
      masterRemark: ''
    }
  }

  componentWillMount() {

    //  this.setState({loading:true});
    client.authenticate()
      .then((res) => {

        this.setState({
          preparebyName: res.firstName ? res.firstName : '' + ' ' + res.lastName ? res.lastName : ''
        });

        return client.service('paymentvouchers').find({
          query: {
            paymentvouchermasterId: this.props.paymentsvouchermastersId,
            //_id:this.props.receiptId,
            $populate: 'receiptId creditledgerAccountId debitledgerAccountId',
          }
        })
      })
      .then((res) => {
        console.log(res)
        let seq = 0;
        res.data.forEach(function (item, index) {
          item.seq = seq + 1;
          seq++;
        })
        this.setState({
          dataSource: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      })

    client.authenticate()
      .then((res) => {

        this.setState({
          preparebyName: res.firstName ? res.firstName : '' + ' ' + res.lastName ? res.lastName : ''
        });
        return client.service('paymentsvouchermasters').find({
          query: {
            _id: this.props.paymentsvouchermastersId,
            $populate: 'supplierId',
          }
        })
      })
      .then((res) => {

        this.setState({
          payeeCode: res.data[0].supplierId ? res.data[0].supplierId.name : '',
          paymentvoucherDate: res.data[0].paymentDate,
          totalAmount: res.data[0].paymentamount,
          voucherNo: res.data[0].referenceNo,
          chequeNo: res.data[0].chequeNo,
          masterRemark: res.data[0].remark,
        });
      })


    this.getJmbInfo();
    this.getTnc();
  }

  getJmbInfo() {
    client.authenticate()
      .then((res) => {
        return client.service('jmbs').find({
          query: {
            propertyId: this.props.commons.selectedCompany._id,
            status: 'ACTIVE',
            $limit: 1,
            $sort: {
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {

        this.setState({
          jmb: res.data[0]
        });
      })
      .catch((err) => {
        console.log(err);
      });

  }

  getTnc() {
    client.authenticate()
      .then(() => {
        return client.service('settings').find({
          query: {
            propertyId: this.props.commons.selectedCompany._id
          }
        })
      })
      .then((res) => {
        //
        this.setState({
          setting: res.data[0].tnc,
        });
      })
      .catch((err) => {
        console.log(err);
      })
  }

  componentDidMount() {


  }

  onSelectChange = (selectedRowKeys) => {

    this.setState({ selectedRowKeys });
  }

  formatAmt(amt) {
    let tempAmt = amt;

    return tempAmt > 0 ? tempAmt.toFixed(2) : 0.00;
  }

  convertDate(date = null) {
    let tempDate = new Date(date);
    let d = tempDate.getDate();
    let m = tempDate.getMonth() + 1
    let y = tempDate.getFullYear();
    return date ? d + '/' + m + '/' + y : '';
  }

  _renderTable = () => {

    const { visible, onCancel } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { loading, selectedRowKeys, jmb, dataSource } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    let table = [];


    const columns = [
      {
        title: 'No',
        dataIndex: 'seq',
        key: 'seq',
        width: '5%',
        render: (text, record) => (
          <span style={{ fontSize: 11, padding: '0px' }} >
            {text}
          </span>
        ),
      },
      {
        title: 'Receipt No',
        dataIndex: 'receiptId.receiptNo',
        key: 'receiptNo',
        width: '15%',
        render: (text, record) => (
          <span style={{ fontSize: 11, padding: '0px' }} >
            {text}
          </span>
        ),
      },
      // {
      //   title: 'Account Name',
      //   dataIndex: 'debitledgerAccountId.description',
      //   width: '20%'
      // },
      {
        title: 'Description',
        dataIndex: 'remark',
        key: 'remark',
        width: '40%',
        render: (text, record) => (
          <span style={{ fontSize: 11, padding: '0px' }} >
            {text}
          </span>
        ),
      },
      {
        title: (
          <span style={{ float: 'right' }}>
            Payment Amount
          </span>),
        dataIndex: 'paymentamount',
        key: 'paymentamount',
        align: 'right',
        width: '15%',
        render: (text, record) => (
          <span style={{ fontSize: 11, padding: '0px', float: 'right' }} >
            {record.paymentamount.toFixed(2)}
          </span>
        ),
      }

    ];


    table.push([
      <div key='main' >
        <Card>
          <Row>
            <Col span={3}>

              <img width='100%' src={jmb ? jmb.headerFile : ''} />

            </Col>
            <Col span={18} offset={1}>
              <p style={{ fontWeight: 'bolder', color: "#000" }}>{jmb.managementName} ({jmb ? jmb.registrationNo : ''})</p>
              <p style={{ marginTop: -15 }}>{jmb ? jmb.headerLine1 : ''} ,{jmb ? jmb.headerLine2 : ''} </p>
              <p style={{ marginTop: -15 }}>{jmb ? jmb.headerLine3 : ''}{jmb ? jmb.headerLine4 : ''}</p>
              <p ><span>{jmb ? 'Tel: ' + jmb.contactNo : ''}       |       {jmb ? 'Email: ' + jmb.email : ''}</span></p>
              <br />

            </Col>


          </Row>

          <Row gutter={8} >
            <Col span={24} align="middle">
              <h1 style={{ 'color': 'rgb(169,169,169)', 'letterSpacing': '20px' }}>PAYMENT VOUCHER</h1>
            </Col>

          </Row>

          <Row gutter={8} >
            <Col span={19}>
              <span>Payee       : {this.state.payeeCode} </span>

            </Col>
            <Col span={5}>
              <span>Date      : {moment(this.state.paymentvoucherDate).format("YYYY-MM-DD")}  </span><br />
              <span>No.       : {this.state.voucherNo}  </span><br />

              {
                this.state.chequeNo ?
                  <span>Cheque No : {this.state.chequeNo}  </span> :
                  ''
              }
            </Col>
          </Row>
          <Row><span>Description : {this.state.masterRemark}</span></Row>
          <Divider dashed >Detail</Divider>
          {/*
            <div style={{height:'auto', marginBottom:'20px'}}>
            <Info label='Date' labelSpan={5}  value={this.convertDate(this.state.receipts.date)} valueSpan={5} /><br/>
            <Info label='Receipt No' labelSpan={5}  value={this.state.receipts.receiptNo} valueSpan={5} /><br/>
            <Info label='Supplier' labelSpan={5}  value={this.state.supplierCode+ ' - '+this.state.supplierName} valueSpan={5} /><br/>
            <Info label='Sub Total Amount' labelSpan={5}  value={formatCurr(this.state.receipts.subTotalAmount)} valueSpan={10} /><br/>
            <Info label='Tax Amount' labelSpan={5}  value={formatCurr(this.state.receipts.taxAmount)} valueSpan={5} /><br/>
            <Info label='Total Amount' labelSpan={5}  value={formatCurr(this.state.receipts.totalAmount)} valueSpan={5} /><br/>
            <Info label='Category' labelSpan={5}  value={this.state.debitledgerAccountNo+' - '+this.state.debitledgerAccountName} valueSpan={10} /><br/>
            <Info label='Payment Account' labelSpan={5}  value={this.state.creditledgerAccountNo+' - '+this.state.creditledgerAccountName} valueSpan={10} /><br/>
            <Info label='Remark' labelSpan={5}  value={this.state.receipts.remark} valueSpan={250} /><br/>

            </div>
            */}
          <Table
            pagination={false}
            dataSource={dataSource}
            columns={columns}
            rowKey="_id"
            footer={() => (
              <Row>
                <Col style={{ float: 'right' }}>
                  <span style={{ textAlign: 'center' }} > TOTAL:{formatCurr(this.state.totalAmount)}</span>
                </Col>
              </Row>
            )} />

          <div key='moneysum' style={{ 'fontSize': 15, 'fontWeight': 'bold', 'marginTop': '50px' }}>

            <Row>
              <Col align="left">
                <span>{'The Sum of Amount : **' + toWords(this.state.totalAmount) + ' Ringgit Only**'}</span>
              </Col>
            </Row>
            <br />
          </div>

          <Row className="noBorder">

            <div key='footerpv' className="paymentvoucherFooter">
              <div key='displaycontain' className="ql-display-container">
                <Row gutter={8} >
                  <Col span={8}>
                    <Card headStyle={{ 'padding': '0 6', 'fontSize': '12px' }} style={{ minHeight: '145px', width: '250px' }}
                      bordered={true}
                      title={<span style={{ 'padding': '0 6' }}>Title</span>} >
                      <p style={{ 'lineHeight': 0.2, 'fontSize': '10px' }} >{this.state.preparebyName}</p>
                    </Card>
                  </Col>
                  <Col span={8} >
                    <Card headStyle={{ 'padding': '0 6', 'fontSize': '12px' }} style={{ minHeight: '145px', width: '250px' }} bordered={true} title="VERIFIED BY">
                      <div key='verifiedby' style={{ 'lineHeight': 0.2, 'fontSize': '10px' }} >
                        <p>Sign: </p>
                        <p>Name: </p>
                      </div>
                    </Card>
                  </Col>
                  <Col span={8} >
                    <Card headStyle={{ 'padding': '0 6', 'fontSize': '12px' }} style={{ minHeight: '145px', width: '250px' }} bordered={true} title="RECEIVED BY">
                      <div key='receiveby' style={{ 'lineHeight': 0.2, 'fontSize': '10px' }} >
                        <p>Sign: </p>
                        <p>Name: </p>
                        <p>IC No: </p>
                        <p>Date: </p>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </Row>

        </Card>
      </div>
      ,
      <div key='pagebreak' className='pageBreak' />
    ])

    return table;
  }

  render() {
    const loading = this.state.loading;
    return (


      <Spin key='spinning' spinning={loading}>
        {this._renderTable()}
      </Spin>

    );
  }
}



function mapStateToProps(state) {
  return {
    paymentvouchers: state.paymentvouchers,
    commons: state.commons
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchPaymentVouchers: fetchPaymentVouchers,
    pushPaymentVouchers: pushPaymentVouchers,
    removePaymentVouchers: removePaymentVouchers,
    updateActivePaymentVouchers: updateActivePaymentVouchers,
    updatePaymentVouchers: updatePaymentVouchers,
    updateMode: updateMode
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(PaymentVouchersPrint));
