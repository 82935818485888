import React, { Component } from "react";
import {
  Popconfirm,
  message,
  Divider,
  Table,
  Form,
  Icon,
  Input,
  Button,
  Card,
  Spin,
  Tooltip,
  Popover,
  Row,
  Col,
  Select,
} from "antd";
import { Route, Link } from "react-router-dom";

import client from "../../feathers";
import requireAuth from "../../components/requireAuth";
import Layout from "../../components/layout";
import MeterReadingsForm from "../../components/meterReadings/form";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchMeterReadings,
  pushMeterReadings,
  removeMeterReadings,
  updateMode,
  updateActiveMeterReadings,
  updateMeterReadings,
} from "../../actions/actions-meterreadings";
import moment from "moment";
const _ = require("lodash");

const FormItem = Form.Item;
const Option = Select.Option;

const text = "Are you sure to delete this item?";

class MeterReadingsIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      loading: false,
      meterreadings: [],
      loading: false,
      search: "",
      searchName: "",
      searchType: "",
      searchDate: "",
    };

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidUpdate() {
    if (this.props.meterreadings.mode === "refresh") {
      this.componentWillMount();
    }
  }

  componentDidMount() {}

  componentWillMount() {
    this.getData();
  }

  getData() {
    this.props.updateMode("");
    this.setState({ loading: true });
    let readDate = "";

    if (this.state.searchDate) {
      readDate = {
        readDate: {
          $gte: moment(this.state.searchDate).startOf("day"),
          $lte: moment(this.state.searchDate).endOf("day"),
        },
      };
    }
    client
      .authenticate()
      .then(() => {
        return client.service("meterreadings").find({
          query: {
            companyId: this.props.commons.selectedCompany._id,
            orRegex: this.state.searchObj,
            ...readDate,
            $sort: {
              readDate: -1,
            },
          },
        });
      })
      .then((res) => {
        this.setState({ loading: false });
        this.props.fetchMeterReadings(res.data);
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  }
  handleDelete(_id) {
    client
      .service("meterreadings")
      .remove(_id)
      .then((res) => {
        this.props.updateMode("refresh");
      })
      .catch((err) => {
        alert(err);
      });
  }

  handleEdit(_id) {
    this.props.updateActiveMeterReadings("edit", _id);
  }

  handleManage(_id) {
    this.props.updateActiveMeterReadings("", _id);
  }

  showModal = () => {
    this.props.updateMode("new");
  };

  renderConfirmDeleteBtn(_id) {
    return (
      <Popconfirm
        placement="topLeft"
        title={text}
        onConfirm={() => this.handleDelete(_id)}
        okText="Confirm"
        cancelText="Cancel"
      >
        <Button type="danger">Delete</Button>
      </Popconfirm>
    );
  }

  handleFilter = (e) => {
    this.setState(
      {
        search: e.target.value,
      },
      () => {
        this.getData();
      }
    );
  };

  _searchName = (e) => {
    this.setState(
      {
        searchName: e.target.value,
        searchObj: _.merge(
          this.state.searchObj,
          e
            ? {
                meterName: e.target.value,
              }
            : { meterName: "" }
        ),
      },
      () => {
        this.getData();
      }
    );
  };

  _searchDate = (e) => {
    this.setState(
      {
        searchDate: e.target.value,
      },
      () => {
        this.getData();
      }
    );
  };

  _searchType = (e) => {
    this.setState(
      {
        searchType: e,
        searchObj: _.merge(
          this.state.searchObj,
          e
            ? {
                meterType: e,
              }
            : { meterType: "" }
        ),
      },
      () => {
        this.getData();
      }
    );
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title: (
          <Row gutter={8}>
            <Row>
              <Col span={24}>Name.</Col>
            </Row>
            <Row>
              <Col span={24}>
                <Input
                  onChange={this._searchName}
                  value={this.state.searchName}
                />
              </Col>
            </Row>
          </Row>
        ),
        dataIndex: "meterName",
        key: "meterName",
        width: "10%",
      },
      {
        title: (
          <Row gutter={8}>
            <Row>
              <Col span={24}>Read Date</Col>
            </Row>
            <Row>
              <Col span={24}>
                <Input
                  type="Date"
                  value={this.state.searchDate}
                  onChange={this._searchDate}
                />
              </Col>
            </Row>
          </Row>
        ),
        dataIndex: "readDate",
        key: "readDate",
        width: "10%",
        render: (text, record) => (
          <span>{moment(text).format("DD-MM-YYYY")}</span>
        ),
      },
      {
        title: (
          <Row gutter={8}>
            <Row>
              <Col span={24}>Type</Col>
            </Row>
            <Row>
              <Col span={24}>
                <Select
                  style={{ width: 100 }}
                  allowClear={true}
                  value={this.state.searchType}
                  onChange={this._searchType}
                  placeholder="Type"
                >
                  <Option value="water">WATER</Option>
                  <Option value="electricity">ELECTRICITY</Option>
                </Select>
              </Col>
            </Row>
          </Row>
        ),
        dataIndex: "meterType",
        key: "meterType",
        width: "10%",
        render: (text, record) => <span>{text.toUpperCase()}</span>,
      },
      {
        title: "UOM",
        dataIndex: "uom",
        key: "uom",
        width: "10%",
      },

      {
        title: "Previous",
        dataIndex: "previousNum",
        key: "previousNum",
        width: "10%",
      },
      {
        title: "Current",
        dataIndex: "currentNum",
        key: "currentNum",
        width: "10%",
      },
      {
        title: "Usage",
        dataIndex: "usageNum",
        key: "usageNum",
        width: "10%",
      },

      {
        title: "Notes",
        dataIndex: "notes",
        key: "notes",
        width: "10%",
        render: (text, record) => (
          <span>
            {text ? (
              <Popover content={text}>
                <Icon type="message" />
              </Popover>
            ) : (
              ""
            )}
          </span>
        ),
      },

      {
        title: "Action",
        key: "action",
        width: "20%",
        render: (text, record) => (
          <span>
            <Divider type="vertical" />
            <Button onClick={() => this.handleEdit(record._id)}>Edit</Button>
            <Divider type="vertical" />
            {this.renderConfirmDeleteBtn(record._id)}
          </span>
        ),
      },
    ];

    return (
      <Layout>
        <Spin key="spinning" spinning={this.state.loading}>
          <Card
            title="Meter Readings"
            extra={
              <Button type="primary" onClick={this.showModal}>
                New
              </Button>
            }
          >
            <Table
              dataSource={this.props.meterreadings.meterreadings}
              columns={columns}
              rowKey="_id"
            />

            <MeterReadingsForm />
          </Card>
        </Spin>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    meterreadings: state.meterreadings,
    commons: state.commons,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchMeterReadings: fetchMeterReadings,
      pushMeterReadings: pushMeterReadings,
      removeMeterReadings: removeMeterReadings,
      updateActiveMeterReadings: updateActiveMeterReadings,
      updateMeterReadings: updateMeterReadings,
      updateMode: updateMode,
    },
    dispatch
  );
}

export default requireAuth(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Form.create()(MeterReadingsIndex))
);
