import React, { Component } from "react";
import {
  Popconfirm,
  message,
  Divider,
  Table,
  Form,
  Icon,
  Input,
  Row,
  Button,
  Card,
  Spin,
} from "antd";
import { Route, Link } from "react-router-dom";

import client from "../../feathers";
import requireAuth from "../../components/requireAuth";
import Layout from "../../components/layout";
import DocumentsForm from "../../components/document/form";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchDocuments,
  pushDocuments,
  removeDocuments,
  updateMode,
  updateActiveDocuments,
  updateDocuments,
} from "../../actions/actions-documents";

const _ = require("lodash");

const FormItem = Form.Item;

const text = "Are you sure to delete this item?";

var AWS = require("aws-sdk");
var s3 = new AWS.S3();

class DocumentsIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      loading: false,
      selected: 0,
      allDoc: [],
    };

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidUpdate() {
    if (this.props.documents.mode === "refresh") {
      this.componentWillMount();
    }
  }

  componentDidMount() { }

  componentWillMount() {
    this.setState({
      loading: true,
    });
    this.props.updateMode("");

    client
      .authenticate()
      .then(() => {
        return client.service("systems").find({
          query: {
            $limit: 1,
          },
        });
      })
      .then((systemResp) => {
        let systemRes = systemResp.data[0];
        AWS.config.update({
          region: systemRes.region,
          secretAccessKey: systemRes.secretAccessKey,
          accessKeyId: systemRes.accessKeyId,
        });
        s3 = new AWS.S3();
        return client.service("documents").find({
          query: {
            companyId: this.props.commons.selectedCompany._id,
          },
        });
      })
      .then((res) => {
        let data = _.groupBy(res.data, "fileType");
        this.setState({ allDoc: data });
        console.log({ data });
        this.props.fetchDocuments(res.data);
        this.setState({
          loading: false,
        });
      })
      .catch((err) => { });
  }

  handleDelete(_id) {
    this.setState({
      loading: true,
    });

    client
      .service("documents")
      .remove(_id)
      .then((res) => {
        this.setState({
          loading: false,
        });
        this.props.updateMode("refresh");
      })
      .catch((err) => {
        alert(err);
      });
  }

  handleEdit(_id) {
    this.props.updateActiveDocuments("edit", _id);
  }

  downloadNow(record) {
    s3.getSignedUrl(
      "getObject",
      {
        Bucket: record.bucket,
        ResponseContentDisposition:
          'attachetment; filename="' + record.originalname + '"',
        Key: record.key,
      },
      (err, url) => {
        window.location.assign(url);
      }
    );
  }

  downloadDocument(record) {
    return (
      <Button type="link" onClick={() => this.downloadNow(record)}>
        Download
      </Button>
    );
  }

  handleManage(_id) {
    this.props.updateActiveDocuments("", _id);
  }

  showModal = () => {
    this.props.updateMode("upload");
  };

  renderConfirmDeleteBtn(_id) {
    return (
      <Popconfirm
        placement="topLeft"
        title={text}
        onConfirm={() => this.handleDelete(_id)}
        okText="Confirm"
        cancelText="Cancel"
      >
        <Button>Delete</Button>
      </Popconfirm>
    );
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    let getTitle = Object.keys(this.state.allDoc);
    const columns = [
      {
        title: "Title",
        dataIndex: "title",
        key: "title",
        width: "18%",
      },
      // {
      //   title: "File Type",
      //   dataIndex: "fileType",
      //   key: "fileType",
      //   width: "10%",
      // },
      {
        title: "File Name",
        dataIndex: "originalname",
        key: "originalname",
        width: "18%",
      },
      {
        title: "Role",
        dataIndex: "role",
        key: "role",
        width: "15%",
        render: (text, record) => {
          switch (text) {
            case 0:
              return 'Management'
              break;
            case 1:
              return 'Management & JMB Committee'
              break;
            case 2:
              return 'All'
              break;
          }
        }
      },
      {
        title: "Remark",
        dataIndex: "remark",
        key: "remark",
        width: "10%",
      },
      {
        title: "Action",
        key: "action",
        width: "15%",
        render: (text, record) => (
          <span>
            <Divider type="vertical" /> {this.downloadDocument(record)}
            <Divider type="vertical" />
            <Button onClick={() => this.handleEdit(record._id)}>Edit</Button>
            <Divider type="vertical" />{" "}
            {this.renderConfirmDeleteBtn(record._id)}
          </span>
        ),
      },
    ];

    return (
      <Layout>
        <Card
          title="Document"
          extra={
            <Button type="primary" onClick={this.showModal}>
              {" "}
              Upload
            </Button>
          }
        >
          <Spin spinning={this.state.loading}>
            {getTitle.map((i) => {
              const selectedData = this.state.allDoc;
              return (
                <>
                  <Row
                    style={{ margin: 20, fontWeight: "bold", marginLeft: 5 }}
                  >
                    {i}
                  </Row>
                  <Table
                    dataSource={selectedData[i]}
                    columns={columns}
                    pagination={false}
                    rowKey="_id"
                  />
                </>
              );
            })}
          </Spin>

          <DocumentsForm />
        </Card>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    documents: state.documents,
    commons: state.commons,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchDocuments: fetchDocuments,
      pushDocuments: pushDocuments,
      removeDocuments: removeDocuments,
      updateActiveDocuments: updateActiveDocuments,
      updateDocuments: updateDocuments,
      updateMode: updateMode,
    },
    dispatch
  );
}

export default requireAuth(
  connect(mapStateToProps, mapDispatchToProps)(Form.create()(DocumentsIndex))
);
