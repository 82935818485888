import React, { Component, useEffect } from "react";
import {
  Modal,
  Upload,
  Popconfirm,
  message,
  Divider,
  Table,
  Form,
  Checkbox,
  Icon,
  Input,
  Button,
  Select,
  Card,
  Drawer,
  Row,
  Col,
  Radio,
  Spin,
} from "antd";
import client from "../../feathers";
import requireAuth from "../requireAuth";
import Layout from "../layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchLedgerAccounts,
  updateActiveLedgerAccounts,
  updateMode,
  updateDate
} from "../../actions/actions-ledgerAccounts";
import { Route, Link } from "react-router-dom";
import DebtorForm from "../../components/ledgers/form-debtor";

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};

const text = "Are you sure to delete this item?";
const _ = require("lodash");

class LedgerIndexDebtors extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ledgeraccounts: [],
      filerAccountName: "",
      totalPagination: 1,
      CurrentPagination: 1,
      accountType: "CURRENT ASSETS",
      accountTypeDropdowns: [],
      filerCategories: "ASSETS",
      accountNo: "3000",
      propertyunitlist: [],
      loading: false,
    };
  }

  componentWillMount() {
    this.props.updateMode("");
    this.setState({ loading: true });

    client
      .authenticate()
      .then((res) => {
        return client.service("ledger-accounts").find({
          query: {
            companyId: this.props.commons.selectedCompany._id,
            accountNo: { $in: ["3000"] },
            subAccountNo: { $nin: ["000"] },
            $sort: {
              accountNo: 1,
              subAccountNo: 1,
            },
          },
        });
      })
      .then((res) => {
        let ledgerAccountList = _.map(res.data, function(o) {
          return o._id;
        });

        let propertyunitlist = client
          .service("propertyunits")
          .find({
            query: {
              propertyId: this.props.commons.selectedCompany._id,
              ledgerAccountId: { $in: ledgerAccountList },
            },
          })
          .then((res1) => {
            this.setState({
              propertyunitlist: res1.data,
              ledgerAccounts: res.data,
              totalPagination: res.total,
              loading: false,
            });
            this.props.fetchLedgerAccounts(res.data);
          });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });

    //-----------------------------

    this.state.filerCategories = "ASSETS";
    this.state.accountTypeDropdowns = [];
    this.state.accountTypeDropdownsValue = "";

    //dropdown account type
    this.setState({ accountTypeDropdownsValue: [] }, () => {
      client
        .authenticate()
        .then(() => {
          return client.service("parameters").find({
            query: {
              table: this.state.filerCategories,
              $sort: {
                createdAt: -1,
              },
            },
          });
        })
        .then((res) => {
          this.setState({
            accountTypeDropdowns: res.data[0].items,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  componentDidUpdate() {
    if (this.props.ledgeraccounts.mode === "refresh") this.componentWillMount();
  }

  handleConfirmBlur = (e) => {};

  handlePageChange = (page) => {
    this.setState({
      CurrentPagination: page,
    });

    let filerAccountName =
      this.state.filerAccountName === ""
        ? ""
        : { accountName: this.state.filerAccountName };
    let collection = { ...filerAccountName };

    this.props.form.validateFields((err, values) => {
      client
        .authenticate()
        .then((res) => {
          return client.service("ledger-accounts").find({
            query: {
              //...values,
              ...collection,
              companyId: this.props.commons.selectedCompany._id,
              accountNo: { $in: ["3000"] },
              subAccountNo: { $nin: ["000"] },
              $sort: {
                accountNo: 1,
                subAccountNo: 1,
              },
              $limit: 20,
              $skip: (page - 1) * 20,
              orRegex: { ...filerAccountName },
            },
          });
        })
        .then((res) => {
          this.setState({
            totalPagination: res.total,
            ledgerAccounts: res.data,
          });
          //this.props.fetchTransactions(res.data);
          this.props.fetchAccounts(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  handleFilter = () => {
    let filerAccountName =
      this.state.filerAccountName === ""
        ? ""
        : { accountName: this.state.filerAccountName };
    let collection = { ...filerAccountName };

    client
      .authenticate()
      .then((res) => {
        return client.service("ledger-accounts").find({
          query: {
            companyId: res.propertyId,
            accountNo: { $in: ["3000"] },
            subAccountNo: { $nin: ["000"] },
            ...collection,
            $sort: {
              accountNo: 1,
              subAccountNo: 1,
            },
            orRegex: { ...filerAccountName },
          },
        });
      })
      .then((res) => {
        this.setState({
          ledgerAccounts: res.data,
          totalPagination: res.total,
        });
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };
  //---------------------------------------------------
  handleAccountName = (e) => {
    // this.setState({
    //   filerAccountName:e.target.value
    // });
    this.state.filerAccountName = e.target.value;

    this.setState({
      typingTimeout: setTimeout(() => {
        this.handleFilter();
      }, 500),
    });
  };

  //---------------------------------------------------
  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (values.specialCode) {
          values.specialCode = "RENODEPOSIT";
        } else {
          values.specialCode = "";
        }

        client
          .service("ledger-accounts")
          .create({
            companyId: this.props.commons.selectedCompany._id,
            companyType: "propertyId",
            categories: this.state.filerCategories,
            accountType: values.accountType,
            accountName: values.accountName,
            accountNo: values.accountNo,
            subAccountNo: values.subAccountNo,
            // accountCurrency  :values.accountCurrency,
            accountCurrency: "MYR",
            description: values.description,
            bankAccountFlag: false,
            specialCode: values.specialCode,
          })
          .then((res) => {
            this.setState({
              visible: false,
            });
            this.componentWillMount();
            message.success("New Accounts Added");
          })
          .catch((err) => {
            console.log(err);
            this.setState({
              visible: false,
            });
            // alert(err);
            console.log(err);
            message.error(
              err.message ? err.message : "Failed to create Account."
            );
          });
      }
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });

    this.props.form.setFieldsValue({ accountType: this.state.accountType });
    this.props.form.setFieldsValue({ categories: this.state.filerCategories });
    this.props.form.setFieldsValue({ accountNo: this.state.accountNo });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };
  //---------------------------------------------------
  populateOwnerName = (record) => {
    let owner = "";

    owner = this.state.propertyunitlist.filter((obj) => {
      if (record._id == obj.ledgerAccountId) {
        return obj;
      }
    });

    if (owner[0]) {
      return owner[0].owner ? owner[0].owner.name : "";
    }
  };

  //---------------------------------------------------
  handleDropdown = (e) => {
    //this.props.form.resetFields();
  };
  //---------------------------------------------------
  handleEdit(_id) {
    this.props.updateActiveLedgerAccounts("edit", _id);
  }

  //--------------------------------------------------
  handleDelete(_id) {
    client
      .service("ledger-accounts")
      .remove(_id)
      .then((res) => {
        this.props.updateMode("refresh");
      })
      .catch((err) => {
        this.props.updateMode("refresh");
        alert(err);
      });
  }
  //--------------------------------------------------
  renderConfirmDeleteBtn(_id) {
    return (
      <Popconfirm
        placement="topLeft"
        title={text}
        onConfirm={() => this.handleDelete(_id)}
        okText="Confirm"
        cancelText="Cancel"
      >
        <Button>Delete</Button>
      </Popconfirm>
    );
  }

  //--------------------------------------------------

  render() {
    const { visible, onCancel } = this.props;
    const userRole = this.props.commons.selectedRole;

    const { getFieldDecorator } = this.props.form;
    const { propertyunitlist } = this.state;

    const columns = [
      {
        title: "Account No.",
        dataIndex: "accountNo",
        key: "accountNo",
        render: (text, record) => <span>{record.fullAccountNo}</span>,
      },
      {
        title: (
          <Row gutter={8}>
            <Row>
              <Col span={24}>Account Name</Col>
            </Row>
            <Row>
              <Col span={24}>
                <Input
                  value={this.state.filerAccountName}
                  onChange={this.handleAccountName}
                  placeholder="Account Name"
                  style={{ width: "200px" }}
                />
              </Col>
            </Row>
          </Row>
        ),
        dataIndex: "accountName",
        key: "accountName",
      },
      {
        title: "Owner Name",
        dataIndex: "Ownername",
        key: "Ownername",
        render: (text, record) => <span>{this.populateOwnerName(record)}</span>,
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
      },
      {
        /*
      //
      // {
      //   title: 'Account Type',
      //   dataIndex: 'accountType',
      //   key: 'accountType',
      // },
      */
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <span>
            {userRole === "admin" ? null : (
              <Button onClick={() => this.handleEdit(record._id)}>Edit</Button>
            )}

            {userRole === "admin" ? null : <Divider type="vertical" />}
            {userRole === "admin"
              ? null
              : this.renderConfirmDeleteBtn(record._id)}

            {userRole === "admin" ? null : <Divider type="vertical" />}
            <Link
              target="blank"
              to={{
                pathname: `/ledgers/transactions/${record._id}`,
              }}
            >
              <Button
                onClick={() => {
                  this.props.updateDate("");
                }}
              >
                Transactions
              </Button>
            </Link>
          </span>
        ),
      },
    ];

    return (
      <Layout>
        <Spin key="spinning" spinning={this.state.loading}>
          <Card
            //title='Debtor Control'
            title={
              <span>
                <Row>
                  <div>
                    <Col>
                      Customer Maintenance
                      <Button
                        style={{ float: "right" }}
                        type="primary"
                        onClick={this.showModal}
                      >
                        New
                      </Button>
                    </Col>
                  </div>
                </Row>
              </span>
            }
            // extra={[<Input onChange={this.handleAccountName}
            //   placeholder="Account Name"
            //   style={{'width':'200px'}} />,
            //   <Button type='primary' onClick={this.handleFilter} >Search</Button>]}
          >
            <Table
              dataSource={this.state.ledgerAccounts}
              columns={columns}
              pagination={{
                total: this.state.totalPagination,
                defaultCurrent: 1,
                onChange: this.handlePageChange,
                pageSize: 20,
              }}
              rowKey="_id"
            />
          </Card>
        </Spin>

        {/*-----------FORM-------------------------*/}

        <Modal
          title="Details"
          visible={this.state.visible}
          onOk={this.handleSubmit}
          okText={"Submit"}
          onCancel={this.handleCancel}
        >
          <Form className="submit-form">
            <FormItem {...formItemLayout} label="Categories" hasFeedback>
              {getFieldDecorator("categories", {
                rules: [{ required: true, message: "Select Categories!" }],
              })(
                <Input disabled style={{ width: 200 }} placeholder="ASSETS" />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="Account Type" hasFeedback>
              {getFieldDecorator("accountType", {
                rules: [{ required: true, message: "Select Type!" }],
              })(<Input disabled />)}
            </FormItem>

            <FormItem {...formItemLayout} label="Account Name">
              {getFieldDecorator("accountName", {
                rules: [
                  {
                    required: true,
                    message: "Please input Accounts Description!",
                  },
                ],
              })(<Input placeholder="Lim Motors" />)}
            </FormItem>

            <FormItem {...formItemLayout} label="Account No.">
              {getFieldDecorator("accountNo", {
                rules: [
                  {
                    required: true,
                    message: "Please input Account Number!",
                  },
                ],
              })(<Input disabled />)}
            </FormItem>

            <FormItem {...formItemLayout} label="Sub Account No ">
              {getFieldDecorator("subAccountNo", {
                rules: [
                  {
                    required: true,
                    message: "Please input Account Number!",
                  },
                ],
              })(<Input placeholder="000" />)}
            </FormItem>

            {/*
              // <FormItem
              //   {...formItemLayout}
              //   label="Currency"
              // >
              //   {getFieldDecorator('accountCurrency', {
              //     rules: [{
              //       required: true, message: 'Please select Currency!',
              //     }],
              //   })(
              //     <Select placeholder="Please Select currency">
              //       <Option value="MYR">MYR - Malaysia Ringgit</Option>
              //     </Select>
              //   )}
              // </FormItem>
*/}

            <FormItem {...formItemLayout} label="Description">
              {getFieldDecorator("description", {
                rules: [
                  {
                    required: true,
                    message: "Please input description!",
                  },
                ],
              })(<Input placeholder="" />)}
            </FormItem>
            {/*
              <FormItem
                {...formItemLayout}
                label="Bank Account?"
              >
                {getFieldDecorator('bankAccountFlag', {
                  rules: [{
                    required: true, message: 'Please select!',
                  }],
                })(
                  <Radio.Group>
                    <Radio.Button value="true">true</Radio.Button>
                    <Radio.Button value="false">false</Radio.Button>
                  </Radio.Group>
                )}
              </FormItem>
*/}
            <FormItem {...formItemLayout} label="Special Code">
              {getFieldDecorator("specialCode", {
                rules: [
                  {
                    required: false,
                    message: "Please input Special Code!",
                  },
                ],
              })(<Checkbox>Reno Deposit</Checkbox>)}
            </FormItem>
          </Form>
        </Modal>
        <DebtorForm />
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    ledgeraccounts: state.ledgeraccounts,
    commons: state.commons,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateMode: updateMode,
      updateDate:updateDate,
      updateActiveLedgerAccounts: updateActiveLedgerAccounts,
      fetchLedgerAccounts: fetchLedgerAccounts,
    },
    dispatch
  );
}

export default requireAuth(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Form.create()(LedgerIndexDebtors))
);
