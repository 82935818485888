import React, { Component } from 'react';
import { Form, Icon, Input, Button, Row, Col, Spin, message } from 'antd';
import client from '../feathers';
import Logo from './common/logo.js';
import LayoutLogin from './layout-login';
import { selectedCompany, selectedRole } from '../actions/actions-commons'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { loginSuccessful } from "../actions/user-actions";
import { fetchJmbs } from '../actions/actions-jmbs';
import _ from 'lodash';

const FormItem = Form.Item;

const formItemLayout = {
  wrapperCol: {
    lg: {
      span: 20, offset: 2
    },
    sm: {
      span: 20, offset: 2
    },
    xs: {
      span: 8
    }
  }
};

class LoginForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false
    }

  }

  componentDidMount() {
    //check if user props has value
    if (!_.isEmpty(this.props.user.info)) {
      client.authenticate()
        .then((res) => {
          this._afterLogin(res)
          this.props.history.push('/dashboard')
        })
        .catch((err) => {
          this.setState({ loading: false })
          // console.log({err});
        })
    }
  }

  _afterLogin(res) {
    localStorage.setItem('avatar', res.avatar);
    sessionStorage.setItem('role', res.role);
    if (res.role[0] === 'management' || res.role[0] === 'authoriser') {
      sessionStorage.setItem('companyId', res.propertyId._id);
      this.props.selectedCompany(res.propertyId)
      this.props.selectedRole(res.role[0])

    }
    sessionStorage.setItem('token', res.accessToken);
  }

  handleSubmit = (e) => {
    this.setState({ loading: true })
    e.preventDefault();
    this.props.form.validateFields((err, values) => {

      if (values.username.indexOf('@') === -1) {
        values.username = `${values.username}@kbms.my`
      }
      if (!err) {
        client.authenticate({
          strategy: 'local',
          username: values.username,
          password: values.password
        })
          .then((res) => {
            // console.log(res)

            // console.log('login', { res });
            this.setState({ loading: false })
            localStorage.setItem('avatar', res.user.avatar);
            sessionStorage.setItem('role', res.user.role);
            let userRole = res.user.role[0]

            if (userRole === 'management' || userRole === 'authoriser' || userRole === 'admin' || userRole === 'account' || userRole === 'executive') {
              sessionStorage.setItem('companyId', res.propertyId);
              this.props.selectedCompany(res.propertyId)
              this.props.selectedRole(res.user.role[0])
            }
            sessionStorage.setItem('token', res.accessToken);
            this.props.loginSuccessful(res);
            this.props.history.push('/dashboard');

            //get JMB info
            if (res.propertyId) {
              return client.service('jmbs').find({
                query: {
                  propertyId: res.propertyId._id
                }
              })
            } else {
              return { data: [] }
            }
          })
          .then((res) => {
            //store in state
            this.props.fetchJmbs(res.data)
          })
          .catch((err) => {
            this.setState({ loading: false })
            message.error(err.message)
          })
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <LayoutLogin>
        <Spin spinning={this.state.loading}>
          <Form onSubmit={this.handleSubmit} className="submit-form">

            <Row>
              <Col span={12} offset={6}>
                <Logo />
                <br />
              </Col>
            </Row>

            <FormItem
              {...formItemLayout}
            //label=" "

            >
              {getFieldDecorator('username', {
                rules: [{ required: true, message: 'Please input your username/email!' }],
              })(
                <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Username/Email" />
              )}
            </FormItem>
            <FormItem
              {...formItemLayout}
            //label=" "
            >
              {getFieldDecorator('password', {
                rules: [{ required: true, message: 'Please input your Password!' }],
              })(
                <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Password" />
              )}
            </FormItem>

            <FormItem {...formItemLayout}>
              {/*}{getFieldDecorator('remember', {
              valuePropName: 'checked',
              initialValue: true,
            })(
              <Checkbox>Remember me</Checkbox>
            )}
            <a className="login-form-forgot" href="">Forgot password</a>
            <br/>*/}
              <Button type="primary" htmlType="submit" className="login-form-button" style={{ 'width': '100%' }}>
                Log in
              </Button>
              {/*}<br/>
            Or <a href="">register now!</a>*/}
            </FormItem>

          </Form>
        </Spin>
      </LayoutLogin>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    commons: state.commons
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    loginSuccessful: loginSuccessful,
    fetchJmbs: fetchJmbs,
    selectedCompany: selectedCompany,
    selectedRole: selectedRole
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(LoginForm));
