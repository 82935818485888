import React, { Component } from 'react';
import { Modal, Popconfirm, Popover, message, Divider, Table, Form, Icon, Input, Button, Select, Radio, Card, Row, Col, Spin, Tree } from 'antd';
import { Route, Link } from 'react-router-dom';

import client from '../../feathers';
import requireAuth from "../../components/requireAuth";
import Layout from "../../components/layout";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchledgeraccounts } from '../../actions/actions-chartOfAccounts';
// var align = require('align-text');


const FormItem = Form.Item;
const Option = Select.Option;
const DirectoryTree = Tree.DirectoryTree;
const { TreeNode } = Tree;
const _ = require("lodash");
const text = 'Are you sure to delete this item?';

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

class ChartOfAccountsIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      loading: true,
      ledgeraccounts: [],
      ledgeraccountsChild: [],
      accountTypeDropdowns: [],
      searchCategories: '',
      accountTypeDropdownsValue: '',
    }

  }

  onSelect = (selectedKeys, info) => {

  };

  parentsLedger(type, ac_type) {
    // return(
    // this.state.ledgeraccounts.map(dropdown => (
    //   <TreeNode title={dropdown.accountName} key={dropdown._id}>
    //     {this.childLedger(dropdown.accountNo)}
    //   </TreeNode>
    // ))
    // )

    let a = _.map(this.state.ledgeraccounts, function (o) {

      if (o.accountType === type) {
        return o;
      }
    });
    a = _.without(a, undefined)
    a = _.orderBy(a, ['accountNo', 'subAccountNo'], ['asc', 'asc'])

    if (a.length > 0) {
      return (
        a.map(dropdown => (
          <TreeNode title=
            {<div className='wrap-span-tree'>{dropdown.accountNo} / {dropdown.subAccountNo}
              <Popover content={dropdown.accountName} trigger="hover">
                <span className='spanMiddleName'>{dropdown.accountName}</span>
              </Popover>
              <span className='spanMiddleType'>{ac_type}</span></div>}
            key={dropdown._id}>
            {this.childLedger(dropdown.accountNo, ac_type)}
          </TreeNode>
        ))
      )
    }
  }

  childLedger(e, ac_type) {

    let a = _.map(this.state.ledgeraccountsChild, function (o) {
      if (o.accountNo === e) {
        return o;
      }
    });
    a = _.without(a, undefined)
    a = _.orderBy(a, ['accountNo', 'subAccountNo'], ['asc', 'asc'])

    if (a.length > 0) {
      return (
        a.map(dropdown => (
          <TreeNode title=
            {<div>{dropdown.accountNo} / {dropdown.subAccountNo}
              <Popover content={dropdown.accountName} trigger="hover">
                <span className='spanMiddleName'>{dropdown.accountName}</span>
              </Popover>
              <span className='spanMiddleType'>{ac_type}</span></div>}
            key={dropdown._id} />
          // <TreeNode title={<td>{dropdown.accountNo +'/'+ dropdown.subAccountNo +
          //               '  '+dropdown.accountName +' '+ ac_type} key={dropdown._id}}</td>}/>

          //   <TreeNode title="leaf" key="0-0-0-0" />
          //   <TreeNode title="leaf" key="0-0-0-1" />
          //   <TreeNode title="leaf" key="0-0-0-2" />
          // </TreeNode>
        ))
      )
    }
  }

  componentDidUpdate() {
  }

  componentDidMount() {
  }

  componentWillMount() {

    client.authenticate()
      .then(() => {
        return client.service('ledger-accounts').find({
          query: {
            $sort: {
              accountName: 1,
            },
            companyId: this.props.user.info.propertyId._id,
            subAccountNo: "000",
          }
        })
      })
      .then((res) => {
        this.props.fetchledgeraccounts(res.data);

        this.setState({
          ledgeraccounts: res.data,
        });
      })
      .catch((err) => {

      });

    //child
    client.authenticate()
      .then(() => {
        return client.service('ledger-accounts').find({
          query: {
            // $sort: {
            //   accountName : 1,
            // },
            companyId: this.props.user.info.propertyId._id,
            subAccountNo: { $ne: "000" },
          }
        })
      })
      .then((res) => {

        this.props.fetchledgeraccounts(res.data);

        this.setState({
          ledgeraccountsChild: res.data,
          loading: false,
        });
      })
      .catch((err) => {

      })
  }

  handleEdit(_id) {
    this.props.updateActiveDocuments('edit', _id)

  }

  handleManage(_id) {
    this.props.updateActiveDocuments('', _id)
  }
  showModal = () => {
    this.setState({
      visible: true,
    });
  }

  handleCancel = (e) => {

    this.setState({
      visible: false,
    });
  }

  handleDropdown = (e) => {

    this.props.form.resetFields();

    this.state.filerCategories = e;
    this.state.accountTypeDropdowns = [];
    this.state.accountTypeDropdownsValue = '';

    //dropdown account type
    this.setState({ accountTypeDropdownsValue: [] },
      () => {
        client.authenticate()
          .then(() => {
            return client.service('parameters').find({
              query: {
                table: this.state.filerCategories,
                $sort: {
                  createdAt: -1
                }
              }
            })
          })
          .then((res) => {
            this.setState({
              accountTypeDropdowns: res.data[0].items
            })
          })
          .catch((err) => {
            console.log(err);
          });
      });
  }

  handleSubmit = (e) => {
    try {
      e.preventDefault();
    } catch (err) { };
    this.props.form.validateFields((err, values) => {
      if (!err) {
        client.service('ledger-accounts').create({
          companyId: this.props.user.info.propertyId._id,
          companyType: 'PROPERTY',
          categories: this.state.filerCategories,
          accountType: values.accountType,
          accountName: values.accountName,
          accountNo: values.accountNo,
          subAccountNo: values.subAccountNo,
          accountCurrency: values.accountCurrency,
          description: values.description,
          bankAccountFlag: values.bankAccountFlag
        })
          .then((res) => {
            this.setState({
              visible: false,
            });
            this.componentWillMount();
            message.success('New Accounts Added');
          })
          .catch((err) => {
            console.log(err);
            this.setState({
              visible: false,
            });
            alert(err);
          })
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;


    return (
      <Layout>

        <Card
          title={
            <Row>
              <Col span={24}>
                <h2 align='middle'>Chart of Accounts</h2>
              </Col>
            </Row>
          }
          extra={[
            <Row gutter={24}>
              <Col span={12}>
                <Button type='primary' key='buttonkey01' onClick={this.showModal} >New</Button>
              </Col>
              <Col span={12}>
                <Link to={{ pathname: `/chartOfAccounts/view` }} ><Button type='primary' key='buttonkey02'>View Print</Button></Link>
              </Col>
            </Row>
          ]}
        >

          <Modal
            title="Details"
            visible={this.state.visible}
            onOk={this.handleSubmit}
            okText={'Submit'}
            onCancel={this.handleCancel}
          >
            <Form className="submit-form">

              <FormItem
                {...formItemLayout}
                label="Categories"
                hasFeedback
              >
                {getFieldDecorator('categories', {
                  rules: [
                    { required: true, message: 'Select Categories!' },
                  ],
                })(
                  <Select value={this.state.filerCategories} style={{ width: 200 }} placeholder="Please select Account Categories" onChange={this.handleDropdown}>
                    <Option key='ASSETS' value="ASSETS">ASSETS</Option>
                    <Option key='LIABILITIES' value="LIABILITIES">LIABILITIES</Option>
                    <Option key='INCOMES' value="INCOMES">INCOMES</Option>
                    <Option key='EXPENSES' value="EXPENSES">EXPENSES</Option>
                    <Option key='EQUITY' value="EQUITY">EQUITY</Option>
                  </Select>
                )}
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="Account Type"
                hasFeedback

              >
                {getFieldDecorator('accountType', {
                  rules: [
                    { required: true, message: 'Select Type!' },
                  ],
                })(
                  <Select placeholder="Select Type" value={this.state.accountTypeDropdownsValue}>
                    {this.state.accountTypeDropdowns.map(dropdown => (
                      <Option key={dropdown.value} value={dropdown.value}>
                        {dropdown.description}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="Account Name"
              >
                {getFieldDecorator('accountName', {
                  rules: [{
                    required: true, message: 'Please input Accounts Description!',
                  }],
                })(
                  <Input placeholder="Lim Motors" />
                )}
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="Account No."
              >
                {getFieldDecorator('accountNo', {
                  rules: [{
                    required: true, message: 'Please input Account Number!',
                  }],
                })(
                  <Input placeholder="1000" />
                )}
              </FormItem>

              <FormItem
                {...formItemLayout}
                label=" "
              >
                {getFieldDecorator('subAccountNo', {
                  rules: [{
                    required: true, message: 'Please input Account Number!',
                  }],
                })(
                  <Input placeholder="000" />
                )}
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="Currency"
              >
                {getFieldDecorator('accountCurrency', {
                  rules: [{
                    required: true, message: 'Please select Currency!',
                  }],
                })(
                  <Select placeholder="Please Select currency">
                    <Option value="MYR">MYR - Malaysia Ringgit</Option>
                  </Select>
                )}
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="Description"
              >
                {getFieldDecorator('description', {
                  rules: [{
                    required: true, message: 'Please input description!',
                  }],
                })(
                  <Input placeholder="" />
                )}
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="Bank Account?"
              >
                {getFieldDecorator('bankAccountFlag', {
                  rules: [{
                    required: true, message: 'Please select!',
                  }],
                })(
                  <Radio.Group>
                    <Radio.Button value="true">true</Radio.Button>
                    <Radio.Button value="false">false</Radio.Button>
                  </Radio.Group>
                )}
              </FormItem>

            </Form>
          </Modal>


          <Spin spinning={this.state.loading}>

            <table className="chartTable">
              <tbody>
                <tr>
                  <th>Acc Code</th>
                  <th>Description</th>
                  <th>A/C Type</th>
                </tr>
              </tbody>
            </table>


            <Tree showLine onSelect={this.onSelect}>
              <TreeNode title="CAPITAL" key="0">
                {this.parentsLedger('CAPITAL', 'CP')}
              </TreeNode>
              <TreeNode title="CURRENT LIABILITIES" key="1">
                {this.parentsLedger('CURRENT LIABILITIES', 'CL')}
              </TreeNode>
              <TreeNode title="FIXED ASSETS" key="2">
                {this.parentsLedger('FIXED ASSETS', 'FA')}
              </TreeNode>
              <TreeNode title="CURRENT ASSETS" key="3">
                {this.parentsLedger('CURRENT ASSETS', 'CA')}
              </TreeNode>
              <TreeNode title="SALES" key="4">
                {this.parentsLedger('SALES', 'SL')}
              </TreeNode>
              <TreeNode title="FIXED EXPENSES" key="5">
                {this.parentsLedger('FIXED EXPENSES', 'FE')}
              </TreeNode>
              <TreeNode title="VARIABLE EXPENSES" key="6">
                {this.parentsLedger('VARIABLE EXPENSES', 'VE')}
              </TreeNode>
            </Tree>

            {/*}
 <Tree showLine defaultExpandAll onSelect={this.onSelect}>
  <TreeNode title="parent 1" key="0-0">
    <TreeNode title="parent 1-0" key="0-0-0">
      <TreeNode title="leaf" key="0-0-0-0" />
      <TreeNode title="leaf" key="1-0-0-0" />
      <TreeNode title="leaf" key="2-0-0-0" />
    </TreeNode>
  </TreeNode>

    <TreeNode title="parent 1-1" key="0-1">
      <TreeNode title="leaf" key="0-1-0">
        <TreeNode title="leaf" key="0-1-0-0" />
        <TreeNode title="leaf" key="0-2-0-0" />
        <TreeNode title="leaf" key="0-3-0-0" />
      </TreeNode>
    </TreeNode>

    <TreeNode title="parent 1-2" key="0-2">
      <TreeNode title="leaf" key="0-2-0">
        <TreeNode title="leaf" key="0-0-1-0" />
        <TreeNode title="leaf" key="0-0-2-0" />
        <TreeNode title="leaf" key="0-0-3-0" />
      </TreeNode>
    </TreeNode>

</Tree>
*/}


          </Spin>

        </Card>

      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    chartOfAccounts: state.chartOfAccounts,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchledgeraccounts: fetchledgeraccounts
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(ChartOfAccountsIndex)));
