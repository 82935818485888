import React, { Component } from 'react'
import { Modal, message, Form, Icon, Input, Select, Popover, Spin } from 'antd'
import client from '../../feathers'
import requireAuth from "../../components/requireAuth"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateMode } from '../../actions/ads-actions'
import axios from 'axios';
import Resizer from 'react-image-file-resizer';
var FormData = require('form-data');

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const resizeFile = (file) => new Promise(resolve => {
  Resizer.imageFileResizer(file, 2048, 1363, 'JPEG', 100, 0,
    uri => {
      resolve(uri);
    },
    'base64'
  );
});


const resizeFilePNG = (file) => new Promise(resolve => {
  Resizer.imageFileResizer(file, 2048, 1363, 'PNG', 100, 0,
    uri => {
      resolve(uri);
    },
    'base64'
  );
});


class AdsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',
      confirmDirty: false,
      autoCompleteResult: [],
      loading: false
    }
  }
  componentDidUpdate() {

    if (this.props.ads.activeId !== this.state._id) {
      this.setState({
        _id: this.props.ads.activeId
      })

      if (this.props.ads.activeAd) {
        this.props.form.setFieldsValue(this.props.ads.activeAd)
        //this.props.form.setFieldsValue({})
      }
    }

  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }

  handleFileUpload = async (event) => {
    this.setState({ loading: true })

    let resizeImage = ''
    if (event.target.files[0].type === 'image/png') {
      resizeImage = await resizeFilePNG(event.target.files[0])
    }
    else {
      resizeImage = await resizeFile(event.target.files[0])

    }

    axios.post(`${client.io.io.uri}compressImages`,
      { data: resizeImage }
      , {
        headers: {
          'Authorization': client.settings.storage.storage['feathers-jwt'],

        }
      }
    ).then((res) => {
      this.setState({ loading: false })
      this.props.form.setFieldsValue({
        file: res.data
      })
      message.success('Upload Successful');
    })
      .catch((err) => {
        this.setState({ loading: false })
        console.log(err);
        message.error(err);
      })
  }


  handleSubmit = (e) => {
    this.setState({ loading: true })
    e.preventDefault();
    if (this.props.ads.mode === 'new') {

      this.props.form.validateFields((err, values) => {

        if (!err) {
          client.service('ads').create(
            {
              'propertyId': this.props.commons.selectedCompany._id,
              'file': values.file,
              'title': values.title,
              'content': values.content,
              'type': values.type,
              'location': values.location,
            }
          )
            .then((res) => {
              this.setState({ loading: false })
              this.props.updateMode('refresh')
              this.props.form.resetFields();
              message.success('New Ad added');
            })
            .catch((err) => {
              this.setState({ loading: false })
              this.props.updateMode('refresh')
              alert(err);
            })
        }
      });
    } else {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          values.propertyId = this.props.commons.selectedCompany._id
          client.service('ads').update(this.props.ads.activeId,
            values
          )
            .then((res) => {
              console.log(this.props.ads.activeAd)
              message.success(`Ad ${this.props.ads.activeAd.title} updated`);
              this.props.updateMode('refresh')
              this.props.form.resetFields()
              this.setState({ loading: false })
            })
            .catch((err) => {
              this.setState({ loading: false })

              this.props.updateMode('refresh')
            })
          return;
        }

      });
    }
  }
  renderOptionNews() {
    const { getFieldDecorator } = this.props.form;
    return (
      <FormItem
        {...formItemLayout}
        label="Select Type"
        hasFeedback
      >
        {getFieldDecorator('type', {
          rules: [
            { required: true, message: 'Please select type!' },
          ],
        })(
          <Select placeholder="Please select a Advertisment type">
            <Option value="n">Happenings</Option>
            <Option value="p">Promo</Option>
            <Option value='np'>Property News</Option>
          </Select>
        )}
      </FormItem>

    )

  }


  render() {
    // const { visible, onCancel } = this.props;

    const { getFieldDecorator } = this.props.form;


    // const uploadButton = (
    //   <div>
    //     <Icon type={this.state.loading ? 'loading' : 'plus'} />
    //     <div className="ant-upload-text">Upload</div>
    //   </div>
    // );

    return (

      <Modal
        visible={
          this.props.ads.mode === 'new' || this.props.ads.mode === 'edit' ? true : false
        }
        title={this.props.ads.mode === 'edit' ? 'Update News' : 'Create News'}
        okText={this.props.ads.mode === 'edit' ? 'Update' : 'Create'}
        onCancel={() => { this.props.updateMode(''); this.props.form.resetFields() }}
        onOk={this.handleSubmit}
      >
        <Spin spinning={this.state.loading}>
          <Form className="submit-form">


            <FormItem
              {...formItemLayout}
              label={
                <span>
                  Upload
                  <Popover content={<span>Size Recommended (2048px x 1363px)</span>}>
                    <Icon className='news-icon-placement' type="info-circle" />
                  </Popover>
                </span>
              }
            >{getFieldDecorator('fileName', {
              rules: [{
                required: false, message: 'Please Upload File!',
              }],

            })(<Input label='upload file' type='file' onChange={this.handleFileUpload} />)}

            </FormItem>

            <FormItem
              {...formItemLayout}
              label="File Name"
            >
              {getFieldDecorator('file', {
                rules: [{
                  required: false, message: 'Please Upload File!',
                }],
              })(
                <Input disabled={true} placeholder="File Name" />
              )}
            </FormItem>


            <FormItem
              {...formItemLayout}
              label="Title"
            >
              {getFieldDecorator('title', {
                rules: [{
                  required: false, message: 'Please input Title!',
                }],
              })(
                <Input placeholder="Management" />
              )}
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Content"
            >
              {getFieldDecorator('content', {
                rules: [{
                  required: false, message: 'Please input Content!',
                }],
              })(
                <TextArea rows={4} />
              )}
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Location"
            >
              {getFieldDecorator('location', {
                rules: [{
                  required: false, message: 'Please input Location!',
                }],
              })(
                <TextArea rows={4} />
              )}
            </FormItem>


            {this.renderOptionNews()}




          </Form>
        </Spin>
      </Modal>

    )
  }
}


function mapStateToProps(state) {
  return {
    ads: state.ads,
    commons: state.commons
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
  }, dispatch);
}

// function getBase64(img, callback) {
//   const reader = new FileReader();
//   reader.addEventListener('load', () => callback(reader.result));
//   reader.readAsDataURL(img);
// }

// function beforeUpload(file) {
//   const isJPG = file.type === 'image/jpeg';
//   if (!isJPG) {
//     message.error('You can only upload JPG file!');
//   }
//   const isLt2M = file.size / 1024 / 1024 < 2;
//   if (!isLt2M) {
//     message.error('Image must smaller than 2MB!');
//   }
//   return isJPG && isLt2M;
// }



export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(AdsForm)));
