export const FETCH_ACCOUNTMAINS = 'FETCH_ACCOUNTMAINS';
export const PUSH_ACCOUNTMAINS = 'PUSH_ACCOUNTMAINS';
export const REMOVE_ACCOUNTMAINS = 'REMOVE_ACCOUNTMAINS';
export const UPDATE_ACCOUNTMAINS = 'UPDATE_ACCOUNTMAINS';
export const UPDATE_MODE = 'UPDATE_MODE';
export const UPDATE_ACTIVE_ID = 'UPDATE_ACTIVE_ID';

export function fetchAccountmains(data){
  return (dispatch) => {
    dispatch({
      type: FETCH_ACCOUNTMAINS,
      payload:data
    });
  }
}

export function pushAccountmains(data){
  return (dispatch) => {
    dispatch({
      type: PUSH_ACCOUNTMAINS,
      payload:data
    });
  }
}

export function removeAccountmains(data){
  return (dispatch) => {
    dispatch({
      type: REMOVE_ACCOUNTMAINS,
      payload:data
    });
  }
}

export function updateAccountmains(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACCOUNTMAINS,
      payload:data
    });
  }
}

export function updateMode(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE,
      payload:data
    });
  }
}

export function updateActiveAccountmains(mode, id){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_ID,
      mode,
      id
    });
  }
}
