export const FETCH_SETTINGS = 'FETCH_SETTINGS';
export const PUSH_SETTINGS = 'PUSH_SETTINGS';
export const REMOVE_SETTINGS = 'REMOVE_SETTINGS';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const UPDATE_MODE = 'UPDATE_MODE';
export const UPDATE_ACTIVE_ID = 'UPDATE_ACTIVE_ID';

export function fetchSettings(data){
  return (dispatch) => {
    dispatch({
      type: FETCH_SETTINGS,
      payload:data
    });
  }
}

export function pushSettings(data){
  return (dispatch) => {
    dispatch({
      type: PUSH_SETTINGS,
      payload:data
    });
  }
}

export function removeSettings(data){
  return (dispatch) => {
    dispatch({
      type: REMOVE_SETTINGS,
      payload:data
    });
  }
}

export function updateSettings(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_SETTINGS,
      payload:data
    });
  }
}

export function updateMode(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE,
      payload:data
    });
  }
}

export function updateActiveSettings(mode, id){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_ID,
      mode,
      id
    });
  }
}
