import _ from 'lodash';
import {
  FETCH_PROPERTIES,
  PUSH_PROPERTIES,
  REMOVE_PROPERTIES,
  UPDATE_PROPERTIES,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID } from '../actions/properties-actions';

const INITIAL_STATE = {
  properties: [],
  mode: 'index',
  activeId: '',
  activeProperty:{}
}


export default function(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_PROPERTIES:
      return {
        ...state,
        properties:action.payload,
      }
    case PUSH_PROPERTIES:
      return {
        ...state,
        properties:[action.payload, ...state.properties],
      }
    case REMOVE_PROPERTIES:
      return {
        ...state,
        properties : [...state.properties.filter( (item) => item._id !== action.payload._id)]
      }
    case UPDATE_PROPERTIES:
      let index =  _.findIndex(state.properties, { '_id': action.payload._id })
      state.properties.splice(index, 1, action.payload)
      return {
        ...state,
        properties : state.properties
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode : action.payload,
        activeId:''
      }
    case UPDATE_ACTIVE_ID:
      return {
        ...state,
        mode : action.mode,
        activeId:action.id,
        activeProperty: _.pick(_.find(state.properties, { '_id': action.id }), ['name', 'type','code','accountmainId','addressLineA','addressLineB','addressLineC','postCode','state','country', '_id'])
      }
    default:
      return state

  }
}
