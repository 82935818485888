export const FETCH_RECURRINGITEMS = 'FETCH_RECURRINGITEMS';
export const PUSH_RECURRINGITEMS = 'PUSH_RECURRINGITEMS';
export const REMOVE_RECURRINGITEMS = 'REMOVE_RECURRINGITEMS';
export const UPDATE_RECURRINGITEMS = 'UPDATE_RECURRINGITEMS';
export const UPDATE_MODE = 'UPDATE_MODE';
export const UPDATE_ACTIVE_ID = 'UPDATE_ACTIVE_ID';

export function fetchRecurringitems(data){
  return (dispatch) => {
    dispatch({
      type: FETCH_RECURRINGITEMS,
      payload:data
    });
  }
}

export function pushRecurringitems(data){
  return (dispatch) => {
    dispatch({
      type: PUSH_RECURRINGITEMS,
      payload:data
    });
  }
}

export function removeRecurringitems(data){
  return (dispatch) => {
    dispatch({
      type: REMOVE_RECURRINGITEMS,
      payload:data
    });
  }
}

export function updateRecurringitems(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_RECURRINGITEMS,
      payload:data
    });
  }
}

export function updateMode(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE,
      payload:data
    });
  }
}

export function updateActiveRecurringitems(mode, id){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_ID,
      mode,
      id
    });
  }
}
