export const FETCH_FPXPARAMETERS = 'FETCH_FPXPARAMETERS';
export const PUSH_TRXPARAMETERS = 'PUSH_TRXPARAMETERS';
export const REMOVE_TRXPARAMETERS = 'REMOVE_TRXPARAMETERS';
export const UPDATE_TRXPARAMETERS = 'UPDATE_TRXPARAMETERS';
export const UPDATE_MODE = 'UPDATE_MODE';
export const UPDATE_ACTIVE_ID = 'UPDATE_ACTIVE_ID';

export function fetchFpxParameters(data){
  return (dispatch) => {
    dispatch({
      type: FETCH_FPXPARAMETERS,
      payload:data
    });
  }
}

export function pushTrxParameters(data){
  return (dispatch) => {
    dispatch({
      type: PUSH_TRXPARAMETERS,
      payload:data
    });
  }
}

export function removeTrxParameters(data){
  return (dispatch) => {
    dispatch({
      type: REMOVE_TRXPARAMETERS,
      payload:data
    });
  }
}

export function updateTrxParameters(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_TRXPARAMETERS,
      payload:data
    });
  }
}

export function updateMode(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE,
      payload:data
    });
  }
}

export function updateActiveTrxParameters(mode, id){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_ID,
      mode,
      id
    });
  }
}
