import { Button, Card, Col, DatePicker, Row, Spin, Table } from "antd";
import axios from "axios";
import moment from 'moment';
import React from "react";
import NumberFormat from 'react-number-format';
import { connect } from "react-redux";
import ReactToPrint from "react-to-print";
import client from "../../feathers";
import requireAuth from "../requireAuth";
import TableToExcel from "@linways/table-to-excel";

const _ = require("lodash");
const { MonthPicker } = DatePicker;

class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      loading: true,
      jmb: this.props.jmb,
      tableColumn: [],
      reportData: []
    };
  }

  _constructTable(data, data2) {
    let { fromMonth, toMonth } = this.props
    let monthCount = moment([toMonth.substring(0, 4), parseInt(toMonth.substring(4)) - 1]).diff(moment([fromMonth.substring(0, 4), parseInt(fromMonth.substring(4)) - 1]), 'months', true)
    let yearMonth = []
    let incomesData = []
    let expensesData = []
    let receiptTotal = 0
    let colTotal = 0

    for (let x = -1; x <= monthCount; x++) {
      let varA = x < 0 ? 'desc' : moment([fromMonth.substring(0, 4), parseInt(fromMonth.substring(4)) - 1]).add(x, 'M').format('YYYYMM')
      yearMonth.push(x < 0 ? 'total' : varA)
    }

    let result = _.chain(data)
      .groupBy("ledgerAccountName")
      .map((value, key) => ({ desc: key, month: value }))
      .value()

    result.map((i, index) => {
      incomesData.push({ 'desc': i.desc, 'total': 0.00 })
      i.month.map((j) => {
        if (yearMonth.indexOf(j.yearmonth.toString()) !== -1) {
          incomesData[index][j.yearmonth] = j.balance || 0
          incomesData[index]['total'] += j.balance || 0
          receiptTotal += j.balance
        }
      })
    })

    //calc total row
    incomesData.push({ 'desc': 'Total Incomes', 'total': receiptTotal, totalRow: true, titleTotal: true, })
    yearMonth.map((i, index) => {
      let total = 0
      if (index > 0) {
        let value = _.sumBy(incomesData, i) || 0
        total += value
        incomesData[incomesData.length - 1][i] = value
      }
    })

    incomesData.splice(0, 0, { desc: 'INCOMES', titleMain: true })

    let result2 = _.chain(data2)
      .groupBy("ledgerAccountName")
      .map((value, key) => ({ desc: key, month: value }))
      .value()


    result2.map((i, index) => {
      expensesData.push({ 'desc': i.desc, accountType: i.month[0].ledgerAccountType, 'total': 0.00 })
      i.month.map((j) => {
        if (yearMonth.indexOf(j.yearmonth.toString()) !== -1) {
          expensesData[index][j.yearmonth] = j.balance || 0
          expensesData[index]['total'] += j.balance || 0
          colTotal += j.balance
        }
      })
    })

    expensesData = _.sortBy(expensesData, ['accountType', 'desc'])

    //calc total row
    expensesData.push({ 'desc': 'Total Expenses', 'total': colTotal, totalRow: true, titleTotal: true })
    yearMonth.map((i, index) => {
      let total = 0
      if (index > 0) {
        let value = _.sumBy(expensesData, i) || 0
        total += value
        expensesData[expensesData.length - 1][i] = value
      }
    })

    // add category
    let accountType = ''
    expensesData.map((i, index) => {
      if (accountType !== i.accountType) {
        accountType = i.accountType
        expensesData.splice(index, 0, { desc: i.accountType, titleRow: true, titleMain: true })
      }
    })

    expensesData.splice(0, 0, { desc: 'EXPENSES', titleMain: true })

    //show monthly income
    expensesData.push({ 'desc': 'Surplus/(Deficit)', titleTotal: true, 'total': receiptTotal - colTotal })

    yearMonth.map((i, index) => {
      if (index > 0) {
        let income = incomesData[incomesData.length - 1][i] - expensesData[expensesData.length - 2][i]
        expensesData[expensesData.length - 1][i] = income
      }
    })

    expensesData.push({ 'desc': 'Retained Surplus/(Deficit)BF', titleTotal: true, })
    expensesData.push({ 'desc': 'Retained Surplus/(Deficit)CF', titleTotal: true, })

    yearMonth.map((i, index) => {
      if (index > 0) {
        let yearMonthInt = parseFloat(i)
        let suplursBF = 0

        if (expensesData[expensesData.length - 3][yearMonthInt - 1] && index === 1) {
          suplursBF = expensesData[expensesData.length - 3][yearMonthInt - 1]
        }
        else if (index > 1) {
          suplursBF = expensesData[expensesData.length - 1][yearMonthInt - 1]
        }

        let suplursCF = suplursBF + expensesData[expensesData.length - 3][i]

        expensesData[expensesData.length - 2][i] = suplursBF
        expensesData[expensesData.length - 1][i] = suplursCF
      }
    })
    this.setState({ reportData: [...incomesData, ...expensesData], })
  }

  _renderCellDesc(v, r) {
    let styleTitle = { fontWeight: 'normal', color: '#000', marginLeft: 10, fontSize: '12px' }
    if (r.titleTotal) {
      styleTitle.fontWeight = 'bolder'
      styleTitle.float = 'right'
      styleTitle.textAlign = 'right'

    }
    if (r.titleRow) {
      styleTitle.textAlign = 'right'
      styleTitle.fontWeight = 'bolder'
      styleTitle.marginLeft = 0
    }
    if (r.titleMain) {
      styleTitle.fontWeight = 'bolder'
      styleTitle.fontSize = '13px'
      styleTitle.marginLeft = 0
    }

    if (v) { return (<span style={styleTitle}> {v}</span>) }
  }

  _renderCellAmount(v, r) {
    if (v && r.titleTotal) {
      return (
        <div style={{ textAlign: 'right', }}><span className="reportAmt">
          {typeof (v) === 'string' ? v : <NumberFormat prefix={v < 0 ? '(' : ''} suffix={v < 0 ? ')' : ''} style={{ fontWeight: 'bolder', }} thousandSeparator={true} displayType="text" fixedDecimalScale={true} decimalScale={2} value={(v < 0) ? (v * -1) : (v || 0)} />}
        </span></div>
      )
    }
    if (v) {
      return (
        <div style={{ textAlign: 'right' }}><span className="reportAmt">
          {typeof (v) === 'string' ? v : <NumberFormat prefix={v < 0 ? '(' : ''} suffix={v < 0 ? ')' : ''} thousandSeparator={true} displayType="text" fixedDecimalScale={true} decimalScale={2} value={(v < 0) ? (v * -1) : (v || 0)} />}
        </span></div>
      )
    }
  }

  _renderCellTotal(v, r) {
    if (v) {
      return (
        <div style={{ textAlign: 'right' }}><span className="reportAmt">
          <NumberFormat
            prefix={v < 0 ? '(' : ''}
            suffix={v < 0 ? ')' : ''}
            thousandSeparator={true} displayType="text" fixedDecimalScale={true} decimalScale={2} value={(v < 0) ? (v * -1) : (v || 0)} />
        </span></div>
      )
    }
  }

  componentWillMount() {
    //----- construct table columns -----
    //construct column
    let { fromMonth, toMonth, companyId } = this.props

    let monthCount = moment([toMonth.substring(0, 4), parseInt(toMonth.substring(4)) - 1]).diff(moment([fromMonth.substring(0, 4), parseInt(fromMonth.substring(4)) - 1]), 'months', true)
    let tableColumn = []
    let yearMonth = []

    // categories column
    tableColumn.push(
      {
        title: (
          <Row><Col span={24}></Col></Row>
        ),
        className: 'title-desc',
        width: 250,
        fixed: 'left',
        dataIndex: 'desc',
        key: 'rnp-report-desc',
        render: (v, r) => this._renderCellDesc(v, r),
      },
    )

    // values column
    for (let x = 0; x <= monthCount; x++) {
      let varA = moment([fromMonth.substring(0, 4), parseInt(fromMonth.substring(4)) - 1]).add(x, 'M').format('YYYYMM')
      yearMonth.push(x < 0 ? 'total' : varA)
      tableColumn.push(
        {
          title: (
            <Row><Col span={24}>{varA}</Col></Row>
          ),
          className: 'td-padding-0 reportAmt',
          width: 'auto',
          align: 'right',
          dataIndex: varA,
          key: 'rnp-report-' + x,
          render: (v, r) => this._renderCellAmount(v, r, x),
        },
      )
    }

    //total column 
    tableColumn.push(
      {
        title: (
          <Row><Col span={24}>Total</Col></Row>
        ),
        className: 'td-padding-0 reportAmt',
        width: 'auto',
        fixed: 'right',
        align: 'right',
        dataIndex: 'total',
        key: monthCount,
        render: (v, r) => this._renderCellAmount(v, r),
      },
    )
    //----- construct table columns -----

    client.authenticate().then((res) => {
      return axios.get(`${client.io.io.uri}profitAndLostYearlyLedgerTrx`, {
        params: {
          companyId,
          toDate: toMonth,
          fromDate: fromMonth
        },
        headers: { 'Authorization': client.settings.storage.storage['feathers-jwt'] }
      })
    })
      .then((res) => {
        this.setState({ tableColumn, loading: false })
        let incomeData = _.filter(res.data, { ledgerAccountCat: 'INCOMES' }) || []
        let expensesData = _.filter(res.data, { ledgerAccountCat: 'EXPENSES' }) || []

        this._constructTable(incomeData, expensesData)

        document.title = 'Profit and Loss (' + moment(this.state.fromMonthVal).format('YYYYMM') + ' to ' + moment(this.state.toMonthVal).format('YYYYMM') + ')'
      })
  }

  _renderExcelRows() {
    const { jmb } = this.props
    const { tableColumn, reportData } = this.state

    let tableCol = tableColumn.length

    function checkVal(k, i, n, underline, key) {
      let a = { 'data-b-b-s': underline ? false : 'thin' }
      if (i[k.dataIndex]) {
        if (i.titleMain) {
          a['data-f-sz'] = "18"
          a['data-f-bold'] = true
        }
        if (i.titleRow) {
          a['data-f-sz'] = "13"
          a['data-f-bold'] = true
        }
        if (n) {
          a['data-t'] = "n"
          a['data-num-fmt'] = '#,##0.00;[RED](#,##0.00)'
        }
        return <td {...a} key={key}>{i[k.dataIndex]}</td>
      }
      return <td {...a} key={key}></td>
    }

    return (<>
      {/* print header */}
      {jmb.managementName ? (<tr key={'td-header-1'}><td data-f-bold={true} data-a-h='center' colSpan={tableCol}>{jmb.managementName}</td></tr>) : null}
      {jmb.headerLine1 ? (<tr key={'td-header-2'}><td data-a-h='center' colSpan={tableCol}>{jmb.headerLine1}</td></tr>) : null}
      {jmb.headerLine2 ? (<tr key={'td-header-3'}><td data-a-h='center' colSpan={tableCol}>{jmb.headerLine2}</td></tr>) : null}
      {jmb.headerLine3 ? (<tr key={'td-header-4'}><td data-a-h='center' colSpan={tableCol}>{jmb.headerLine3}</td></tr>) : null}
      {jmb.headerLine4 ? (<tr key={'td-header-5'}><td data-a-h='center' colSpan={tableCol}>{jmb.headerLine4}</td></tr>) : null}
      {(<tr key={'td-header-6'}><td data-a-h='center' data-f-sz="18" colSpan={tableCol}>Profit & Loss Statement</td></tr>)}

      <tr>{//column title
        tableColumn.map((i, index) => {
          let title = moment(i.dataIndex, 'YYYYMM').isValid() ? moment(i.dataIndex, 'YYYYMM').format('MMM-YYYY') : i.title
          return <td key={'tr-title-td-' + index} data-f-sz="16" data-f-bold="true" data-a-h='center' data-b-b-s='medium' data-b-t-s='medium' >{title}</td>
        })
      }</tr>

      {//rows
        reportData.map((i, indexi) => {
          return (<tr>{
            tableColumn.map((j, index) => {
              return (<>{checkVal(j, i, index > 1, i.titleMain, 'td-amt-' + indexi + '-td-' + index)}</>)
            })
          }
          </tr>)
        })
      }
    </>)
  }

  render() {
    const { jmb, fromMonth, toMonth } = this.props;
    const { loading } = this.state;

    return (<div>
      <Spin spinning={loading}>
        <Card>
          <span style={{ fontSize: 20 }}>{jmb.name || ''}</span><br />
          <span>({jmb.registrationNo})</span><br />
          <span style={{ fontSize: 20 }} >PROFIT & LOSS STATEMENT</span>
          <span className="alignRight" >As at selected date : {moment(fromMonth, 'YYYYMM').format('MM-YYYY')} ~ {moment(toMonth, 'YYYYMM').format('MM-YYYY')}</span><br></br>
          <Row>
            <Col span={5}>
              <img width="25%" src={jmb.headerFile} />
            </Col>
            <Col span={19}>
              <p><span>{jmb.headerLine1}</span></p>
              <p><span>{jmb.headerLine2}</span></p>
              <p><span>{jmb.headerLine3}</span></p>
              <p><span>{jmb.headerLine4}</span></p>
            </Col>
          </Row>

          <Table
            className="printTable"
            rowClassName={(record) => {
              if (record.titleMain) { return 'title-category title-main' }
              if (record.titleRow) { return 'title-category' }
            }}
            scroll={{ x: 1250 }}
            dataSource={this.state.reportData}
            columns={this.state.tableColumn}
            pagination={false}
            size="small"
          />

          <table style={{ display: 'none' }} key={'printExcel-table'} data-cols-width={"40" + ",12".repeat(this.state.tableColumn.length > 0 ? this.state.tableColumn.length - 1 : 0)} className="printExcel">
            <tbody>
              {this._renderExcelRows()}
            </tbody>
          </table>

        </Card>
      </Spin>
    </div>);
  }
}

class PNLYearly extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      fromMonth: moment().startOf('year'),
      toMonth: moment().endOf('month'),
    }
  }

  render() {
    const exportToCSV = () => {
      TableToExcel.convert(document.querySelector('table.printExcel'), {
        name: `${this.props.commons.selectedCompany.name}_P&L_${moment(this.state.fromMonth).format('YYYYMM')}-${moment(this.state.toMonth).format('YYYYMM')}.xlsx`,
        sheet: {
          name: "Sheet 1"
        }
      });
    };

    return (
      <div>
        <Row gutter={20}>
          <Col span={3} offset={16}>
            From Date : <MonthPicker defaultValue={this.state.fromMonth} format={'YYYYMM'} onChange={e => this.setState({ fromMonth: moment(new Date(e)).format("YYYYMM") })} />
          </Col>
          <Col span={3}>
            To Date : <MonthPicker defaultValue={this.state.toMonth} format={'YYYYMM'} onChange={e => this.setState({ toMonth: moment(new Date(e)).format("YYYYMM") })} />
          </Col>
          <Col span={2}>
            <br />
            <Button onClick={(e) => exportToCSV()} type="primary">Export</Button>
            {/* <ReactToPrint trigger={() => <Button type="primary">PRINT</Button>} content={() => this.componentRef} fromMonth={this.state.fromMonth} toMonth={this.state.toMonth} /> */}
          </Col>
        </Row>
        <br />
        <ComponentToPrint key={this.state.fromMonth + this.state.toMonth} jmb={this.props.jmbs.jmbs[0]} companyId={this.props.commons.selectedCompany._id} ref={el => (this.componentRef = el)}
          fromMonth={moment(this.state.fromMonth).startOf('day').format('YYYYMM')}
          toMonth={moment(this.state.toMonth).startOf('day').format('YYYYMM')}
          fromMonthDate={this.state.fromMonth}
          toMonthDate={this.state.toMonth} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    jmbs: state.jmbs,
    commons: state.commons
  };
}

export default requireAuth(connect(mapStateToProps)(PNLYearly));