import React, { Component, useEffect } from 'react';
import { Modal, Upload, Popconfirm, AutoComplete, message, Divider, Table, Form, Icon, Input, Button, Select, Radio, InputNumber, Spin, Dialog, DatePicker, Row, Col } from 'antd';
import client from '../../feathers';
import requireAuth from "../requireAuth";
import Layout from "../layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateMode } from '../../actions/actions-receipts';
import axios from 'axios';
import { Link, withRouter, Route } from 'react-router-dom';

import moment from 'moment';
//----------------------------------------------------------------------------
const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
const _ = require('lodash');
const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'MYR',
    minimumFractionDigits: 2
})

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const amountLayout = {
    labelCol: { span: 14 },
    wrapperCol: { span: 10 },

}
const formTailLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 8, offset: 4 },
};

const text = 'Are you sure to delete this item?';

const config = {
    initialValue: moment(),
    rules: [{
        type: 'object',
        required: true,
        message: 'Please select date!'
    }],
};
//----------------------------------------------------------------------------
class PaymentFormItems extends Component {
    constructor(props) {
        super(props);

        this.state = {
            _id: '',
            confirmDirty: false,
            autoCompleteResult: [],
            mode: this.props.mode,
            visible: false,
            loading: false,
            paymentTypeParams: [],
            paymentType: '',    //CASH, CREDIT CARD, ONLINE BANKING, CHEQUE
            payAmount: 0,
            totalAmount: 0,
            receipts: [],
            bankledgerAccountList: [],
            supplierInvoices: [],
            supplierId: '',
            overpaid: false,
            paymentDate: moment(new Date()).format("YYYY-MM-DD"),
            paymentDatet: moment(new Date()).format("YYYY-MM-DD"),
            paymentTypeParams: [],
            invoices: [],
            remark: '',
            receiptTotalAmount: 0,
            receiptItems: []
        }
    }
    //----------------------------------------------------------------------------
    componentDidUpdate() {
        // console.log(this.state.overpaid)

        if (this.props.suppliers.activeId !== this.state._id) {
            this.setState({
                _id: this.props.suppliers.activeId
            })


            if (this.props.suppliers.activeSuppliers) {
                client.service('receipts').find({
                    query: {
                        supplierId: this.props.suppliers.activeId,
                        status: { $in: ['OPEN'] }
                    }
                }).then((res) => {
                    this.setState({ invoices: res.data })

                })

                client.service('receipt-items').find({
                    query: {
                        supplierId: this.props.suppliers.activeId,
                        status: { $in: ['OPEN'] }

                    }
                }).then((res2) => {
                    console.log(res2)
                    this.setState({ receiptItems: res2.data })
                })

                //   this.props.form.setFieldsValue({
                //     ctledgerAccountId: this.props.debitnotes.activeDebitNotes.ctledgerAccountId._id,
                //     dtledgerAccountId: this.props.debitnotes.activeDebitNotes.dtledgerAccountId._id,
                //     debitAmount: this.props.debitnotes.activeDebitNotes.debitAmount,
                //     remark: this.props.debitnotes.activeDebitNotes.remark,
                //   })
            }
        }

        // if (this.props.totalAmount !== this.props.form.getFieldValue('paymentamount')) {
        //     //      setTimeout(this.setState({totalAmount:this.props.totalAmount}), 5000);
        //     this.setState({ totalAmount: this.props.totalAmount });
        //     this.props.form.setFieldsValue({ paymentamount: this.props.totalAmount });
        // }
        // if (this.props.supplierInvoices !== this.state.supplierInvoices) {
        //     this.setState({ supplierInvoices: this.props.supplierInvoices });
        // }
        // if (this.props.supplierId !== this.state.supplierId) {
        //     this.setState({ supplierId: this.props.supplierId });
        // }
        // if (this.props.overpaid !== this.state.overpaid) {
        //     this.setState({ overpaid: this.props.overpaid });
        // }
        // if (this.props.remark !== this.state.remark) {
        //     this.setState({ remark: this.props.remark });
        //     this.props.form.setFieldsValue({ remark: this.props.remark });
        // }
    }
    //----------------------------------------------------------------------------


    componentDidMount() {


        this.getPaymentTypeParams();


        // client.authenticate()
        // .then((res)=>{
        //
        //     return client.service('ledger-accounts').find({
        //       query: {
        //         companyId:this.props.commons.selectedCompany._id,
        //         categories:'ASSETS',
        //         $or:[
        //         {bankAccountFlag:true,},
        //         {fullAccountNo : "3120/000"}
        //       ],
        //         //orRegex:{description:'BANK'},
        //          $sort: {description: 1}
        //        }
        //     })
        // })
        // .then((res)=>{
        //   this.setState({
        //     bankledgerAccountList:res.data
        //   })
        // })
        // .catch((err)=>{
        //     console.log(err);
        //     this.setState({
        //       bankledgerAccountList:[]
        //     })
        // })




    }
    //----------------------------------------------------------------------------
    componentWillMount() {

        // client.service('receipts').find({
        //     query: {
        //         supplierId:
        //     }
        // })


    }
    //----------------------------------------------------------------------------
    getPaymentTypeParams() {
        client.authenticate()
            .then((res) => {
                return client.service('payment-type-params').find({
                    query: {
                        propertyId: this.props.commons.selectedCompany._id,
                        category: {
                            $in: ['SUPPLIERPAYMENT']
                        },
                    }
                })
            })
            .then((res) => {
                this.setState({
                    paymentTypeParams: res.data
                });
            })
            .catch((err) => {
                console.log(err);
            });

    }
    //----------------------------------------------------------------------------
    handleSubmit = (e) => {

        this.setState({ loading: true })

        if (this.state.overpaid == false) {
            console.log('been here')
            e.preventDefault();
            this.props.form.validateFields((err, values) => {

                if (!err) {
                    console.log('asd', this.state.supplierInvoices)

                    if (this.state.supplierInvoices.length <= 0) {
                        console.log(this.state.supplierInvoices)
                        this.setState({ loading: false })
                        return message.error('Please Select Record');
                    }

                    let totalAmount = _.sumBy(this.state.supplierInvoices, 'knockOffAmount');
                    console.log(values)

                    // if (totalAmount <= 0 || (!totalAmount)) {
                    //     this.setState({ loading: false })
                    //     return message.error('Total knockOff amount must greater than zeros !');
                    // }

                    client.service('paymentsvouchermasters').create({
                        supplierId: this.props.supplierId,
                        ...values,
                        supplierInvoices: this.state.supplierInvoices,
                        propertyId: this.props.commons.selectedCompany._id,
                    })
                        .then((res) => {
                            this.setState({ loading: false })
                            this.props.updateMode('');
                            message.success('New Record Added');
                            this.props.history.push('/paymentvoucher');
                        })
                        .catch((err) => {
                            this.setState({ loading: false })
                            console.log(err);
                            this.props.updateMode('');
                            alert(err);
                        })
                }
                else {
                    this.setState({ loading: false })
                }
            });

        }
        else {
            this.setState({ loading: false })
            message.error('Amount Overpaid');
        }
    }
    //----------------------------------------------------------------------------
    showModal = () => {
        this.setState({
            visible: true,
        });
    }
    //----------------------------------------------------------------------------
    handleCancel = (e) => {

        this.setState({
            visible: false,
        });
    }
    //----------------------------------------------------------------------------
    formatAmt(amt) {
        let tempAmt = amt;

        return tempAmt > 0 ? tempAmt.toFixed(2) : 0.00;
    }
    //------------------------------------------------------
    _paymentDateChange = (e) => {

        this.setState({
            paymentDate: moment(new Date(e)).format("YYYY-MM-DD")
        });
    }
    updateAmount(e) {
        let result = _.find(this.state.invoices, { receiptNo: e })
        if (result) {
            let tempArr = []
            tempArr.push(result)
            this.setState({ receiptId: result._id, receiptTotalAmount: result.totalAmount, supplierInvoices: tempArr })
            this.props.form.setFieldsValue({ invoiceDate: moment(result.date) })
            // this.props.form.setFieldsValue({ debitAmount: result.totalAmount, invoiceDate: moment(result.date) })
        } else {
            // this.getExpenses()
            this.setState({ receiptId: null, receiptTotalAmount: 0 })
            // this.props.form.setFieldsValue({ debitAmount: null, invoiceDate: null })
        }
    }
    // handelAddKnockOffAmount(e) {
    //     let { supplierInvoices } = this.state
    //     _.forEach(supplierInvoices, function (a) {
    //         a.knockOffAmount = this.state.totalAmount
    //     })
    //     console.log(this.state.supplierInvoices)

    // }

    //----------------------------------------------------------------------------
    render() {
        const { visible, onCancel } = this.props;
        const { getFieldDecorator } = this.props.form;
        const { bankledgerAccountList } = this.state;


        //----------------------------------------------------------------------------
        return ([
            <Modal
                centered
                visible={this.props.suppliers.mode === 'pay' ? true : false}
                title='Make payment'
                okText='Pay'
                onCancel={() => { this.props.updateMode(''); this.props.form.resetFields() }}
                onOk={this.handleSubmit}

            >
                <Spin key='spinning' spinning={this.state.loading}>


                    <Form className="submit-form" onSubmit={this.handleSubmi} >

                        <FormItem {...formItemLayout} label="Invoice No">
                            {getFieldDecorator('invoiceNo', {
                                rules: [{
                                    required: false, message: 'Please select Invoice No!',
                                }],
                            })(
                                <AutoComplete onBlur={(e) => this.updateAmount(e)} onSelect={(e) => this.updateAmount(e)} onChange={(e) => this.updateAmount(e)} style={{ width: 200 }} onSearch={this.handleSearch} placeholder="">
                                    {this.state.invoices.map(d => (
                                        <AutoComplete.Option key={d._id} value={d.receiptNo} description={d.receiptNo} >
                                            {d.receiptNo}
                                        </AutoComplete.Option>
                                    ))}
                                </AutoComplete>
                            )}
                        </FormItem>

                        <Form.Item key='paymentDate'
                            {...formItemLayout}
                            label="Payment Date"
                        >
                            {getFieldDecorator('paymentDate', config)(
                                <DatePicker
                                    onChange={this._paymentDateChange}
                                />
                            )}
                        </Form.Item>

                        <FormItem key='creditledgerAccountId'
                            {...formItemLayout}
                            label="Pay by"
                        >
                            {getFieldDecorator('creditledgerAccountId', {
                                rules: [{
                                    required: true, message: 'Please select Payment Type!',
                                }],
                            })(
                                <Select>
                                    {this.state.paymentTypeParams.map(dropdown => (
                                        <Option key={dropdown.ledgerAccountId} value={dropdown.ledgerAccountId} description={dropdown.description} >
                                            {dropdown.description}
                                        </Option>
                                    ))}
                                </Select>
                            )}
                        </FormItem>


                        {/* <Row> */}
                        {/* <Col span={14}> */}
                        <FormItem key='paymentamount'
                            {...formItemLayout}
                            label="Amount"
                        >
                            {getFieldDecorator('paymentamount', {
                                rules: [{
                                    required: true, message: 'Please input amount!',
                                }],
                            })(
                                //  {/* <Input value ={this.state.totalAmount} disabled/> */}
                                <InputNumber style={{ width: '90%' }} value={this.state.totalAmount} />
                            )}
                        </FormItem>
                        {/* </Col> */}
                        {/* <Col span={8}><p style={{ margin: '10px', fontColor: "lightgrey" }}>{this.state.receiptTotalAmount.toFixed(2)}</p></Col> */}
                        {/* </Row> */}

                        <FormItem key='chequeNo'
                            {...formItemLayout}
                            label="Cheque No."
                        >
                            {getFieldDecorator('chequeNo', {
                                rules: [{
                                    required: false, message: 'Please input Cheque No.!',
                                }],
                            })(
                                <Input />
                            )}
                        </FormItem>



                        <FormItem key='Description'
                            {...formItemLayout}
                            label="Description"
                        >
                            {getFieldDecorator('remark', {
                                rules: [{
                                    required: true, message: 'Please input description!',
                                }],
                            })(
                                <TextArea rows={4} />
                            )}
                        </FormItem>

                    </Form>
                </Spin>
            </Modal >

        ])
    }
}

//----------------------------------------------------------------------------
function mapStateToProps(state) {
    return {
        suppliers: state.suppliers,
        receipts: state.receipts,
        commons: state.commons
    }
}
//----------------------------------------------------------------------------
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateMode: updateMode
    }, dispatch);
}
//----------------------------------------------------------------------------
export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(withRouter(Form.create()(PaymentFormItems))));
