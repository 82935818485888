import React, {Component} from 'react';
import {
  Popconfirm,
  message,
  Divider,
  Row,
  Col,
  Table,
  Form,
  Icon,
  Input,
  Button,
  Card,
  Spin
} from 'antd';
import {Route, Link} from 'react-router-dom';

import client from '../../feathers';
import requireAuth from "../requireAuth";
import Layout from "../layout";
import PropertyunitusersForm from "./form";
import PropertyunitusersView from "./view";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
  fetchPropertyunitusers,
  pushPropertyunitusers,
  removePropertyunitusers,
  updateMode,
  updateActivePropertyunitusers,
  updatePropertyunitusers
} from '../../actions/actions-propertyunitusers';


const FormItem = Form.Item;

const text = 'Are you sure to delete this item?';

class PropertyunitusersIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      primary: [],
      secondary: [],
      loading: true
    }

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidUpdate() {}

  componentDidMount() {}

  componentWillMount() {

    client.authenticate().then((res) => {
      return client.service('propertyunits').find({
        query: {
          _id: this.props.match.params.id,
          $sort: {
            createdAt: -1
          },
          $populate:'users.userId',
          // $populate: {
          //   path: 'users',
          //   populate: [
          //     {
          //       path: 'userId'
          //     }
          //   ]
          // }
        }

      })
    }).then((res) => {

      let users = res.data[0].users;
      let primary = users.filter(user => user.userType === 'primary');
      let secondary = users.filter(user => user.userType === 'secondary');

      this.setState({primary: primary, secondary: secondary, loading: false})
      //this.props.fetchPropertyunitusers(res.data[0].users)
    }).catch((err) => {
      console.log(err);
    })
  }

  handleDelete(userId) {

    client.service('propertyunits').patch(this.props.match.params.id, {
      '$pull': {
        'users': {
          'userId': userId
        }
      }
    }).then((res) => {

      this.props.fetchPropertyunitusers(res.users)
    }).catch((err) => {
      alert(err);
    })
  }

  handleEdit(_id) {
    this.props.updateActivePropertyunitusers('edit', _id)
  }

  handleManage(_id) {
    this.props.updateActivePropertyunitusers('', _id)
  }

  showModal = () => {
    this.props.updateMode('new')
  }

  renderConfirmDeleteBtn(_id) {
    return (<Popconfirm placement="topLeft" title={text} onConfirm={() => this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
      <Button type="danger">Delete</Button>
    </Popconfirm>)
  }

  render() {
    const {getFieldDecorator} = this.props.form;

    const columns = [
      // {
      //   title: 'User ID',
      //   dataIndex: 'userId.username',
      //   key: 'userId'
      // }, 
      {
        title: 'Email',
        dataIndex: 'userId.email',
        key: 'email'
      }, {
        title: 'Type',
        dataIndex: 'userType',
        key: 'userType'
      }, {/*
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            {this.renderConfirmDeleteBtn(record.userId)}
          </span>
        ),
      */
      }
    ];

    return (<Layout>
      <Spin spinning={this.state.loading}>
        <Row gutter={8}>
          <Col span={8}>

          
            <PropertyunitusersView propertyunitsId={this.props.match.params.id}/>
            
          </Col>

          <Col span={16}>
            <Row gutter={8}>
              <Card title="Primary User">

              
                <Table dataSource={this.state.primary} columns={columns} rowKey="userId"/>
                
              </Card>
              <Card title="Secondary User">

              
                <Table dataSource={this.state.secondary} columns={columns} rowKey="userId"/>
                
              </Card>
            </Row>
          </Col>

        </Row>
      </Spin>

    </Layout>)
  }
}

function mapStateToProps(state) {
  return {propertyunitusers: state.propertyunitusers}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchPropertyunitusers: fetchPropertyunitusers,
    pushPropertyunitusers: pushPropertyunitusers,
    removePropertyunitusers: removePropertyunitusers,
    updateActivePropertyunitusers: updateActivePropertyunitusers,
    updatePropertyunitusers: updatePropertyunitusers,
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(PropertyunitusersIndex)));
