import React, { Component } from 'react'
import { MDBAnimation } from "mdbreact";
import "@fortawesome/fontawesome-free/css/all.min.css";
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import logoImage from '../../assets/images/logo.png';
import { Layout } from 'antd';

class logo extends Component{

  render(){
    return(
      <Layout style={{background:'#fff'}}>
        <MDBAnimation type="slideInUp" duration="2s" >
          <img alt="logo" style={{'width':'100%', }} src={logoImage}/>
        </MDBAnimation>
      </Layout>
    )
  }
}

export default logo;
