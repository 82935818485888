import React from "react";

const SvgBathrobe = props => (
  <svg height={512} viewBox="0 0 480.005 480.005" width={512} {...props}>
    <path
      d="M5.806 391.693l56 16a8.001 8.001 0 0010.031-6.062l43.156-206.9 5.008 61.631v215.64a8 8 0 008 8h224a8 8 0 008-8v-215.64l5.008-61.633 43.156 206.9a8 8 0 0010.031 6.064l56-16a8 8 0 005.7-9.007l-45.918-275.332a75.45 75.45 0 00-9.672-26.411 75.702 75.702 0 00-57.188-36.261l-42.324-4.241L302.86 3.885a8.194 8.194 0 00-6.859-3.831V.002h-112v.049a7.894 7.894 0 00-6.859 3.834l-21.934 36.556-42.307 4.241c-33.902 3.332-61.334 29.031-66.867 62.644L.11 382.686a7.998 7.998 0 005.696 9.007zm11.257-13.421l2.809-16.838 41.517 11.9-3.466 16.612zm230.938-114.27v16h-112v-16zm96 0v16h-48v-16zm-48 32h16v112h-16zm-16 120v16h-16v-168h16zm-144-120h40v168h-40zm56 168v-168h24v168zm152 0h-112v-168h16v144a8 8 0 008 8h32a8 8 0 008-8v-16h24a8 8 0 008-8v-120h16zm78.078-74.059l-3.466-16.612 41.517-11.9 2.809 16.838zM391.431 69.58a58.704 58.704 0 0119.148 19.578 59.536 59.536 0 017.61 20.8l39.291 235.589-42.145 12.077-45.982-220.376a8 8 0 00-15.8.984l-8.927 109.77h-108.12l88.371-191.471 40.663 4.071a57.747 57.747 0 0125.891 8.978zm-80.469-21.09l-92.078 199.512h-22.492L297.001 25.221zm-38.189-8.488H207.18l-10.8-24h87.235zm-7.226 16l-25.625 56.741-25.54-56.741zM182.985 25.24l48.147 106.967-13.511 29.918-48.659-113.514zM61.821 109.932c4.354-26.472 25.958-46.711 52.657-49.33l40.483-4.056 53.73 125.356-29.853 66.1h-43.462l-8.922-109.77a8 8 0 00-15.805-.984L64.667 357.625l-42.145-12.077z"
      data-original="#000000"
      className="bathrobe_svg__active-path"
      data-old_color="#000000"
      fill="#8D99AE"
    />
  </svg>
);

export default SvgBathrobe;
