import _ from 'lodash';
import {
  FETCH_PINBOARD,
  PUSH_PINBOARD,
  REMOVE_PINBOARD,
  UPDATE_PINBOARD,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID } from '../actions/actions-pinboard';

const INITIAL_STATE = {
  pinboard: [],
  mode: 'index',
  activeId: '',
  activePinboard:{}
}


export default function(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_PINBOARD:
      return {
        ...state,
        pinboard:action.payload,
      }
    case PUSH_PINBOARD:
      return {
        ...state,
        pinboard:[action.payload, ...state.pinboard],
      }
    case REMOVE_PINBOARD:
      return {
        ...state,
        pinboard : [...state.pinboard.filter( (item) => item._id !== action.payload._id)]
      }
    case UPDATE_PINBOARD:
      let index =  _.findIndex(state.pinboard, { '_id': action.payload._id })
      state.pinboard.splice(index, 1, action.payload)
      return {
        ...state,
        pinboard : state.pinboard
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode : action.payload,
        activeId:''
      }
    case UPDATE_ACTIVE_ID:
      return {
        ...state,
        mode : action.mode,
        activeId:action.id,
        activePinboard: _.pick(_.find(state.pinboard, { '_id': action.id }), [
            'title',
            'description', 
            'startDate',
            'endDate',
            '_id',
            'imagesSrc',
            'propertyId',
            'filePath'
    ])
      }
    default:
      return state

  }
}
