import React, { Component } from 'react'
import { Modal, message, DatePicker, TimePicker, Form, Icon, Input, Select } from 'antd'
import client from '../../feathers'
import requireAuth from "../../components/requireAuth"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateMode } from '../../actions/actions-passes'

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};


class PassesForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',
      confirmDirty: false,
      autoCompleteResult: [],
    }
  }
  componentDidUpdate() {
    if (this.props.passes.activeId !== this.state._id) {
      this.setState({
        _id: this.props.passes.activeId
      })

      if (this.props.passes.activePasses) {
        this.props.form.setFieldsValue(
          this.props.passes.activePasses
        )
      }
    }
  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.props.passes.mode === 'new') {

      this.props.form.validateFields((err, fieldsValue) => {

        const values = {
          ...fieldsValue,
          'visitDate': fieldsValue['visitDate'].format('YYYY-MM-DD'),
          'visitTime': fieldsValue['visitTime'].format('HH:mm:ss'),
        };

        if (!err) {
          client.service('passes').create({
            ...values,
            propertyId: this.props.user.info.propertyId._id,
          })
            .then((res) => {

              this.props.updateMode('')
              message.success('New Passes Added');
            })
            .catch((err) => {
              console.log(err);
              this.props.updateMode('')
              alert(err);
            })
        }
      });
    } else {
      this.props.form.validateFields((err, values) => {

        if (!err) {
          client.service('passes').update(this.props.passes.activeId,
            values
          )
            .then((res) => {

              message.success(`Passes Updated`);
              this.props.updateMode('')
            })
            .catch((err) => {
              console.log(err);

              this.props.updateMode('')
            })
          return;
        }

      });
    }
  }

  compareToFirstPassesword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('passesword')) {
      callback('Two passeswords that you enter is inconsistent!');
    } else {
      callback();
    }
  }

  validateToNextPassesword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  }

  render() {

    const { getFieldDecorator } = this.props.form;

    return (
      <Modal
        visible={
          this.props.passes.mode === 'new' || this.props.passes.mode === 'edit' ? true : false
        }
        title={this.props.passes.mode === 'edit' ? 'Update Passes' : 'Create Passes'}
        okText={this.props.passes.mode === 'edit' ? 'Update' : 'Create'}
        onCancel={() => { this.props.updateMode(''); this.props.form.resetFields() }}
        onOk={this.handleSubmit}

      >
        <Form className="submit-form">

          <FormItem
            {...formItemLayout}
            label="Property ID"
          >
            {getFieldDecorator('propertyId', {
              rules: [{
                required: true, message: 'Please input propertyId!',
              }],
            })(
              <Input prefix={<Icon type="idcard" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Spiderman" />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="referenceId"
          >
            {getFieldDecorator('referenceId', {
              rules: [{
                required: true, message: 'Please input referenceId!',
              }],
            })(
              <Input prefix={<Icon type="idcard" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Spiderman" />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="IC/Passesport Name"
          >
            {getFieldDecorator('visitorName', {
              rules: [{
                required: true, message: 'Please input name!',
              }],
            })(
              <Input prefix={<Icon type="idcard" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Spiderman" />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Gender"
          >
            {getFieldDecorator('gender', {
              rules: [{
                required: true, message: 'Please select gender!',
              }],
            })(
              <Select placeholder="Please select gender">
                <Option value="male">male</Option>
                <Option value="female">female</Option>
              </Select>
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Contact No."
          >
            {getFieldDecorator('mobile', {
              rules: [{
                required: true, message: 'Please input contact number!',
              }],
            })(
              <Input prefix={<Icon type="idcard" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="012-345678" />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Remark"
          >
            {getFieldDecorator('remark', {
              rules: [{
                required: false, message: 'Please input remark!',
              }],
            })(
              <TextArea rows={4} />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Visit Date"
          >
            {getFieldDecorator('visitDate', {
              rules: [{
                required: true, message: 'Please input Email!',
              }],
            })(
              <DatePicker />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Visit Time"
          >
            {getFieldDecorator('visitTime', {
              rules: [{
                required: true, message: 'Please input Email!',
              }],
            })(
              <TimePicker />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Visit Type"
          >
            {getFieldDecorator('type', {
              rules: [{
                required: true, message: 'Please select visit Type!',
              }],
            })(
              <Select placeholder="Please select Type">
                <Option value="vehicle">vehicle</Option>
                <Option value="walkin">Walk in</Option>
              </Select>
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Vehicle Plate No."
          >
            {getFieldDecorator('vehicleNo', {
              rules: [{
                required: false, message: 'Please input car plate!',
              }],
            })(
              <Input prefix={<Icon type="idcard" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="www8888" />
            )}
          </FormItem>

        </Form>
      </Modal>

    )
  }
}


function mapStateToProps(state) {
  return {
    passes: state.passes
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(PassesForm)));
