import { Button, Card, Col, DatePicker, Form, Icon, Input, message, Popconfirm, Row, Select, Spin, Table } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateActiveReceipts, updateMode } from '../../actions/actions-receipts';
import client from '../../feathers';
import Layout from "../layout";
import requireAuth from "../requireAuth";
//------------------------------------------------------------
const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
const _ = require('lodash');
const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
//------------------------------------------------------------
class ReceiptsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',
      confirmDirty: false,
      autoCompleteResult: [],
      accountAssetsDropdowns: [],
      accountExpensesDropdowns: [],
      suppliers: [],
      newMerchant: '',
      loading: false,
      amount: 0,
      totalAmount: 0,
      creditledgerAccountId: '',
      creditledgerAccountName: '',
      selectedCategory: '',
      count: 0,
      receiptItems: [],
      expenses: [],
    }
  }

  componentWillMount() {
      client.authenticate()
      .then(() => {
        return client.service('receipt-items').find({
          query: {
            receiptId: this.props.receipts.activeId,
            $populate: 'debitledgerAccountId',
            $sort: {
              sortOrder: 1
            }
          }
        })
      })
      .then((res) => {

        if (!_.isEmpty(res.data)) {

          let newStateArr = []
          newStateArr = _.map(res.data, function (a, i) {
            a.key = i
            a.debitAccountName = a.debitledgerAccountId.description
            a.debitledgerAccountId = a.debitledgerAccountId._id
            a._id = a._id

            return a
          })

          this.setState({
            receiptItems: newStateArr,
            count: newStateArr.length,
          })
        }
        
        //generate dropdown
        return client.service('ledger-accounts').find({
          query: {
            companyId: this.props.commons.selectedCompany._id,
            categories: 'ASSETS',
            $or: [
              { accountNo: { $in: ['3130', '3120', '3110'] } },
              { bankAccountFlag: true }
            ],
            $sort: { description: 1 }
          }
        })
      })
      .then((res) => {
        this.setState({ accountAssetsDropdowns: res.data })
        //generate dropdown category
        return client.service('ledger-accounts').find({
          query: {
            companyId: this.props.commons.selectedCompany._id,
            //categories:{$in:['EXPENSES','ASSETS']},
            accountNo: { $nin: ['3000', '4000', '3110', '3150', '3130'] },
            $sort: {
              description: 1
            }
          }
        })

      })
      .then((res) => {
        this.setState({ accountExpensesDropdowns: res.data })
      })
      .catch((err) => {
        this.setState({
          accountAssetsDropdowns: []
        })
      })

    this.handleGetSupplierDropdown();
  }

  //------------------------------------------------------------
  async componentDidUpdate() {

    if (this.props.receipts.activeId !== this.state._id) {
      this.setState({ _id: this.props.receipts.activeId })


      if (!_.isEmpty(this.props.receipts.activeReceipts)) {
        this.setState({
          totalAmount: this.props.receipts.activeReceipts.totalAmount,
          expenses: {
            remark: this.props.receipts.activeReceipts.remark,
            amount: this.props.receipts.activeReceipts.amount,
          }
        })
        this.props.form.setFieldsValue(
          {
            date: moment(this.props.receipts.activeReceipts.date),
            receiptNo: this.props.receipts.activeReceipts.receiptNo,
            amount: this.props.receipts.activeReceipts.amount,
            totalAmount: this.props.receipts.activeReceipts.totalAmount,
            remark: this.props.receipts.activeReceipts.remark,
            supplierId: _.get(this.props.receipts.activeReceipts.supplierId, ['_id']),
          }
        )

      }
    }
  }
  //------------------------------------------------------------
  handleSubmit = (e) => {
    e.preventDefault();

    let { receiptItems } = this.state
    let newTotal = 0

    for (let i = 0; i < receiptItems.length; i++) {
      if(receiptItems[i].updatedAmount){
        receiptItems[i].amount = receiptItems[i].updatedAmount
      }

      newTotal += parseFloat(receiptItems[i].amount)
    }

    if (this.state.receiptItems.length === 0) {
      return message.error('No item is selected');
    }

    let creditledgerAccountId = this.props.receipts.activeReceipts.creditledgerAccountId 
    let receiptId = this.props.receipts.activeReceipts._id 
    console.log({creditledgerAccountId})

    this.props.form.validateFields((err, values) => {
      if (!err) {
        values.totalAmount = newTotal
        values.date = moment(values.date).format("YYYY-MM-DD")

        const fieldValues = { ...values, receiptId, creditledgerAccountId, receiptType:"2", status: 'OPEN', propertyId: this.props.commons.selectedCompany._id };
        client.service('receipts').create(fieldValues, {
          query: {
            $client: {
              receiptItems
            }
          }
        })
        .then(() => {
          this.props.history.push('/receipts')
          message.success('New Receipts Added');
          this.props.form.resetFields();
          this.props.updateActiveReceipts('refresh', '')
        })
        .catch((err) => {
          message.error(err.message)
        })
      }
    });
  }
  //------------------------------------------------------------
  handleFileUpload = (event) => {

    this.setState({ loading: true });

    let formData = new FormData();

    formData.append('image', event.target.files[0]);

    axios.post(`${client.io.io.uri}upload-receipts`,
      formData
      , {
        headers: {
          'Authorization': client.settings.storage.storage['feathers-jwt'],
          'Content-Type': 'multipart/form-data',
        }
      }
    ).then((res) => {

      this.props.form.setFieldsValue({
        file: res.data.imageUrl
      });
      this.setState({ loading: false });
      message.success('Upload Successful');
    })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
        message.error(err);
      })
  }
  //------------------------------------------------------------
  handleGetSupplierDropdown = () => {
    //generate suppliers dropdown
    client.authenticate()
      .then((res) => {

        return client.service('suppliers').find({
          query: {
            propertyId: this.props.commons.selectedCompany._id,
            $populate: 'ledgerAccountId',
            $sort: {
              supplierCode: 1
            }
          }
        })
      })
      .then((res) => {
        // console.log({res});
        this.setState({
          suppliers: res.data
        });
      })
      .catch((err) => {
        console.log(err);
      })
  }
  //-------------------------------------------------------------------
  // handleChange = (value) => {

  //   var result = this.state.suppliers.filter(obj => {
  //     return obj._id === value
  //   })
  //   this.setState({
  //     creditledgerAccountId: result[0].ledgerAccountId._id,
  //     creditledgerAccountName: result[0].ledgerAccountId.accountName,
  //   });

  //   this.props.form.setFieldsValue({ creditledgerAccountId: result[0].ledgerAccountId.accountName });

  // }
  //-------------------------------------------------------------------
  handleSearch = (value) => {
    this.setState({
      newMerchant: value
    });
  }
  //-------------------------------------------------------------------
  handleChangeSubTotalAmt = (e) => {
    let tempId = this.props.form.getFieldValue('debitledgerAccountId')
    let tempName = _.find(this.state.accountExpensesDropdowns, function (a) {
      return a._id === tempId
    })
    this.setState({

      expenses: {
        debitledgerAccountId: this.props.form.getFieldValue('debitledgerAccountId'),
        name: _.get(tempName, ['description']),
        remark: this.props.form.getFieldValue('remark'),
        amount: e.target.value
      },
      amount: e.target.value
    });
    typingTimeout: setTimeout(() => {
      this.handleChangeTotalAmt()
    }, 500)
  }

  handleChangeTotalAmt = () => {
    if (this.state.count === 0) {
      this.setState({
        totalAmount: parseFloat(this.state.amount)
      });
      this.props.form.setFieldsValue({ totalAmount: this.state.totalAmount });
    }
  }

  handleAddItem = () => {
    if (this.state.count !== 0) {
      this.setState({
        totalAmount: this.state.totalAmount + parseFloat(this.state.expenses.amount)
      }, () => {
        this.props.form.setFieldsValue({ totalAmount: this.state.totalAmount });
      })
    }
    const newData = {
      key: this.state.count,
      debitledgerAccountId: this.state.expenses.debitledgerAccountId,
      debitAccountName: this.state.expenses.name,
      remark: this.state.expenses.remark,
      amount: this.state.expenses.amount,
      date: new Date(this.props.form.getFieldValue('date'))

    }
    this.setState({
      receiptItems: [...this.state.receiptItems, newData],
      count: this.state.count + 1,
    })
  }
  
  handleDeleteItem = (record) => {
    const dataSource = [...this.state.receiptItems];
    this.setState({ receiptItems: dataSource.filter(item => item.key !== record.key) });
    this.setState({ totalAmount: parseFloat(this.state.totalAmount) - parseFloat(record.amount) }, () => {
      this.props.form.setFieldsValue({ totalAmount: this.state.totalAmount })
    })
  }

  handleAmtChange = (v, k) =>{
    let { receiptItems } = this.state
    let index = _.findIndex(receiptItems, ['_id', k])
    receiptItems[index]['updatedAmount'] = parseFloat(v)
    this.setState({receiptItems},
      ()=>{
      console.log(this.state.receiptItems)
    })
  }

  //----------------------------------------------------------
  render() {
    const { getFieldDecorator } = this.props.form;
    const { accountExpensesDropdowns } = this.state;
    const imageUrl = this.props.form.getFieldValue('file');

    const columns = [{
      title: 'Expenses Category',
      dataIndex: 'debitAccountName',
      key: 'chargeCode',
      width:'30%'
    }, 
    {
      title: 'Description',
      dataIndex: 'remark',
      key: 'description',
      width:'30%'
    }, 
    {
      title: 'Original Amount',
      dataIndex: 'amount',
      key: 'oriAmount',
      align:'right',
      width:'15%'
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      align:'right',
      width:'15%',
      render: (t, r) => {
        return (<Input placeholder={t} key={r._id} style={{textAlign:'right'}} type="text" onChange={(e)=>this.handleAmtChange(e.target.value, r._id)}/>)
      }
    },
    {
      title: 'Action',
      key: 'action',
      width:'10%',
      align:'center',
      render: (text, record) => (
        this.state.receiptItems.length >= 1
          ? (
            <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDeleteItem(record)}>
              <Icon type="delete" />
            </Popconfirm>
          ) : null
      ),
    }
    ];

    return (
      <Layout>
        <Spin spinning={this.state.loading}>
          <Card
            title="Supplier Invoices (DN)"
            extra={
              <Button type="danger" onClick={this.handleSubmit}>
                Create
              </Button>}
          >
            <Form className="submit-form">

              <Row>
                <Col span={12}>
                  <FormItem
                    {...formItemLayout}
                    label="Upload"
                  >
                    <Input label='upload file' type='file' onChange={this.handleFileUpload} />
                  </FormItem>

                  <FormItem
                    {...formItemLayout}
                    label="File Name"
                    style={{ 'display': 'none' }}
                  >
                    {getFieldDecorator('file', {
                      rules: [{
                        required: false, message: 'Please Upload File!',
                      }],
                    })(
                      <Input style={{ 'display': 'none' }} disabled={true} placeholder="File Name" />
                    )}
                  </FormItem>

                  <FormItem {...formItemLayout} label="Date" >
                    {getFieldDecorator('date', {
                      rules: [{
                        required: true, message: 'Please input date!',
                      }],
                    })(
                      <DatePicker />
                    )}
                  </FormItem>

                  <FormItem {...formItemLayout} label="Supplier" >
                    {getFieldDecorator('supplierId', {
                      rules: [{
                        required: true, message: 'Please select Supplier!',
                      }],
                    })(
                      <Select
                        showSearch
                        placeholder="Select a Supplier"
                        defaultActiveFirstOption={false}
                        onChange={this.handleChange}
                        onSearch={this.handleSearch}
                        showArrow={false}
                      >
                        {this.state.suppliers.map(dropdown => (
                          <Option key={dropdown._id} value={dropdown._id}>
                            {dropdown.supplierCode + '-' + dropdown.name}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>

                  <FormItem {...formItemLayout} label="Bill / Invoice No." >
                    {getFieldDecorator('receiptNo', {
                      rules: [{
                        required: true, message: 'Please input Invoice No.!',
                      }],
                    })(
                      <Input placeholder="INV0001" />
                    )}
                  </FormItem>

                </Col>
                <Col span={12}>

                  <FormItem {...formItemLayout} label="Amount" >
                    {getFieldDecorator('amount', {
                      // rules: [{
                      //   required: true, message: 'Please input SubTotal!',
                      // }],
                    })(
                      <Input placeholder="9.00" onChange={this.handleChangeSubTotalAmt} />
                    )}
                  </FormItem>

                  <FormItem key='totalAmount' {...formItemLayout} label="Invoice Amount" >
                    {getFieldDecorator('totalAmount', {
                      // rules: [{
                      //   required: true, message: 'Please input Amount!',
                      // }],
                    })(
                      <Input disabled />
                    )}
                  </FormItem>

                  <FormItem {...formItemLayout} label="Category" >
                    {getFieldDecorator('debitledgerAccountId', {
                      // rules: [{
                      //   required: true, message: 'Please input category!',
                      // }],
                    })(
                      <Select placeholder="Please select category"
                        showSearch
                        dropdownMatchSelectWidth={false}
                        allowClear
                        optionFilterProp='children'
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        onChange={(e) => {
                          let tempName = _.find(this.state.accountExpensesDropdowns, function (a) {
                            return a._id === e
                          })

                          this.setState({
                            expenses: {
                              debitledgerAccountId: e,
                              remark: this.props.form.getFieldValue('remark'),
                              name: tempName.description,
                              amount: this.props.form.getFieldValue('amount')
                            }
                          })
                        }}

                      >
                        {accountExpensesDropdowns.map(dropdown => (
                          <Option key={dropdown._id} value={dropdown._id}>
                            {dropdown.description}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>


                  <FormItem
                    {...formItemLayout}
                    label="Description"
                  >
                    {getFieldDecorator('remark', {
                      // rules: [{
                      //   required: true, message: 'Please input Description!',
                      // }],
                    })(
                      <TextArea rows={4} onChange={(e) => {
                        let tempId = this.props.form.getFieldValue('debitledgerAccountId')
                        let tempName = _.find(this.state.accountExpensesDropdowns, function (a) {
                          return a._id === tempId
                        })

                        this.setState({
                          expenses: {
                            debitledgerAccountId: this.props.form.getFieldValue('debitledgerAccountId'),
                            // name: tempName.description,
                            remark: e.target.value,
                            amount: this.props.form.getFieldValue('amount')
                          }
                        })
                      }} />
                    )}
                  </FormItem>
                  <Button type="primary" onClick={this.handleAddItem}>Add</Button>

                </Col>
              </Row>

            </Form>

            <Table dataSource={this.state.receiptItems} columns={columns} />
          </Card>
        </Spin>
      </Layout>

    )
  }
}


function mapStateToProps(state) {
  return {
    receipts: state.receipts,
    commons: state.commons
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode,
    updateActiveReceipts: updateActiveReceipts
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(ReceiptsForm)));
