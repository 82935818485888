import _ from 'lodash';
import { UPDATE_ACTIVE_MENU_KEY,UPDATE_ACTIVE_PAGE } from '../actions/app-actions';

const INITIAL_STATE = {
  activeMenuKey: 0,
  activePage:{}
}


export default function(state = INITIAL_STATE, action){
  switch(action.type){
    case UPDATE_ACTIVE_MENU_KEY:
      return {
        ...state,
        activeMenuKey : action.payload,
      }
    case UPDATE_ACTIVE_PAGE:
      return {
        ...state,
        activePage : action.payload,
      }
    default:
      return state

  }
}
