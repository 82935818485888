export const FETCH_LEDGERACCOUNTS = 'FETCH_LEDGERACCOUNTS';
export const PUSH_LEDGERACCOUNTS = 'PUSH_LEDGERACCOUNTS';
export const REMOVE_LEDGERACCOUNTS = 'REMOVE_LEDGERACCOUNTS';
export const UPDATE_LEDGERACCOUNTS = 'UPDATE_LEDGERACCOUNTS';
export const UPDATE_MODE = 'UPDATE_MODE';
export const UPDATE_ACTIVE_ID = 'UPDATE_ACTIVE_ID';
export const UPDATE_DATE = 'UPDATE_DATE';


export function fetchLedgerAccounts(data){
  return (dispatch) => {
    dispatch({
      type: FETCH_LEDGERACCOUNTS,
      payload:data
    });
  }
}

export function pushLedgerAccounts(data){
  return (dispatch) => {
    dispatch({
      type: PUSH_LEDGERACCOUNTS,
      payload:data
    });
  }
}

export function removeLedgerAccounts(data){
  return (dispatch) => {
    dispatch({
      type: REMOVE_LEDGERACCOUNTS,
      payload:data
    });
  }
}

export function updateLedgerAccounts(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_LEDGERACCOUNTS,
      payload:data
    });
  }
}

export function updateMode(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE,
      payload:data
    });
  }
}

export function updateActiveLedgerAccounts(mode, id){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_ID,
      mode,
      id
    });
  }
}


export function updateDate(data){
  console.log({data})
  return (dispatch) => {
    dispatch({
      type: UPDATE_DATE,
      payload:data

    });
  }
}
