export const FETCH_JOURNALENTRIES = 'FETCH_JOURNALENTRIES';
export const PUSH_JOURNALENTRIES = 'PUSH_JOURNALENTRIES';
export const REMOVE_JOURNALENTRIES = 'REMOVE_JOURNALENTRIES';
export const UPDATE_JOURNALENTRIES = 'UPDATE_JOURNALENTRIES';
export const UPDATE_MODE = 'UPDATE_MODE';
export const UPDATE_ACTIVE_ID = 'UPDATE_ACTIVE_ID';

export function fetchJournalEntries(data){

  return (dispatch) => {
    dispatch({
      type: FETCH_JOURNALENTRIES,
      payload:data
    });
  }
}

export function pushJournalEntries(data){
  return (dispatch) => {
    dispatch({
      type: PUSH_JOURNALENTRIES,
      payload:data
    });
  }
}

export function removeJournalEntries(data){
  return (dispatch) => {
    dispatch({
      type: REMOVE_JOURNALENTRIES,
      payload:data
    });
  }
}

export function updateJournalEntries(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_JOURNALENTRIES,
      payload:data
    });
  }
}

export function updateMode(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE,
      payload:data
    });
  }
}

export function updateActiveJournalEntries(mode, id){
 
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_ID,
      mode,
      id
    });
  }
}
