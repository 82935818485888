import { Button, Card, Col, DatePicker, Form, Row, Spin, Table } from "antd";
import axios from "axios";
import moment from "moment";
import React from "react";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import ReactToPrint from "react-to-print";
import { bindActionCreators } from "redux";
import "../../App.less";
import client from "../../feathers";
import requireAuth from "../requireAuth";
import TableToExcel from "@linways/table-to-excel";
import { Route, Link } from "react-router-dom";
import { updateDate } from "../../actions/actions-ledgerAccounts";

const _ = require("lodash");
const { MonthPicker } = DatePicker;

class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      loading: true,
      tableColumn: [],
      reportData: [],
      multiDataSet: [],
    };
  }

  _constructTable(dataReceipt, dataPayment) {
    let { fromMonth, toMonth } = this.props;
    let tableData = [];
    let paymentData = [];
    let receiptTotal = 0;
    let monthCount = moment([
      toMonth.substring(0, 4),
      parseInt(toMonth.substring(4)) - 1,
    ]).diff(
      moment([fromMonth.substring(0, 4), parseInt(fromMonth.substring(4)) - 1]),
      "months",
      true
    );
    let yearMonth = [];

    for (let x = -1; x <= monthCount; x++) {
      let varA =
        x < 0
          ? "desc"
          : moment([
              fromMonth.substring(0, 4),
              parseInt(fromMonth.substring(4)) - 1,
            ])
              .add(x, "M")
              .format("YYYYMM");
      yearMonth.push(x < 0 ? "total" : varA);
    }

    //---- RECEIPTS -----
    result = _.chain(dataReceipt)
      .groupBy("ledgerAccountDescription")
      .map((value, key) => ({ desc: key, month: value }))
      .value();

    result.map((i, index) => {
      //add row for category, only for payment
      tableData.push({ desc: i.desc, total: 0.0 });
      i.month.map((j) => {
        if (yearMonth.indexOf(j.yearmonth.toString()) !== -1) {
          tableData[index][j.yearmonth] = j.amount || 0;
          tableData[index]["total"] += j.amount || 0;
          tableData[index]["id"] = j.accountInfo._id;
          receiptTotal += j.amount;
        }
      });
    });

    //calc total row
    tableData.push({
      desc: "Total Receipts ",
      total: receiptTotal,
      totalRow: true,
      titleTotal: true,
    });
    yearMonth.map((i, index) => {
      let total = 0;
      if (index > 0) {
        let value = _.sumBy(tableData, i) || 0;
        total += value;
        tableData[tableData.length - 1][i] = value;
      }
    });

    tableData.splice(0, 0, { desc: "Receipts", titleMain: true });

    //---- RECEIPTS -----

    //---- PAYMENTS -----
    let paymentTotal = 0;
    let result = _.chain(dataPayment)
      .groupBy("ledgerAccountName")
      .map((value, key) => ({ desc: key, month: value }))
      .value();

    result.map((i, index) => {
      //add row for category, only for payment
      paymentData.push({
        desc: i.desc,
        accountType: i.month[0].ledgerAccountType,
        total: 0.0,
      });
      i.month.map((j) => {
        if (yearMonth.indexOf(j.yearmonth.toString()) !== -1) {
          paymentData[index][j.yearmonth] = j.amount || 0;
          paymentData[index]["total"] += j.amount || 0;
          paymentData[index]["id"] = j._id;
          paymentTotal += j.amount;
        }
      });
    });

    paymentData = _.sortBy(paymentData, ["accountType", "desc"]);

    //calc total row
    paymentData.push({
      desc: "Total Payments ",
      total: paymentTotal,
      titleTotal: true,
      totalRow: true,
    });
    yearMonth.map((i, index) => {
      let total = 0;
      if (index > 0) {
        let value = _.sumBy(paymentData, i) || 0;
        total += value;
        paymentData[paymentData.length - 1][i] = value;
      }
    });

    // add category
    let accountType = "";
    paymentData.map((i, index) => {
      if (accountType !== i.accountType) {
        accountType = i.accountType;
        paymentData.splice(index, 0, {
          desc: i.accountType,
          titleRow: true,
          titleMain: true,
        });
      }
    });
    paymentData.splice(0, 0, { desc: "Payments", titleMain: true });

    //show monthly income
    paymentData.push({
      desc: "Surplus/(Deficit)",
      titleRow: true,
      total: receiptTotal - paymentTotal,
      titleTotal: true,
    });
    yearMonth.map((i, index) => {
      if (index > 0) {
        let income =
          tableData[tableData.length - 1][i] -
          paymentData[paymentData.length - 2][i];
        paymentData[paymentData.length - 1][i] = income;
      }
    });
    paymentData.push({ desc: "Balance B/F", titleRow: true, titleTotal: true });
    paymentData.push({ desc: "Balance C/F", titleRow: true, titleTotal: true });

    yearMonth.map((i, index) => {
      let yearMonthInt = parseFloat(i);
      let bf = 0;
      let cf = 0;

      if (index === 1 && paymentData[paymentData.length - 3][1]) {
        cf = paymentData[paymentData.length - 3][i];
      } else if (index >= 1 && paymentData[paymentData.length - 3][i]) {
        bf = paymentData[paymentData.length - 1][yearMonthInt - 1]
          ? paymentData[paymentData.length - 1][yearMonthInt - 1]
          : 0;
        cf = bf + paymentData[paymentData.length - 3][i];
      }

      paymentData[paymentData.length - 2][i] = bf;
      paymentData[paymentData.length - 1][i] = cf;
    });
    //---- PAYMENTS -----
    this.setState({ reportData: [...tableData, ...paymentData] });
  }
  redirectTo(event, record) {
    // this.props.props.history.push('/ledgers/accountList/' + record);
    console.log(this.props.props);
  }

  _renderCellDesc(v, r) {
    let styleTitle = {
      fontWeight: "normal",
      color: "#00000",
      marginLeft: 10,
      fontSize: "12px",
    };

    if (r.titleTotal) {
      styleTitle.fontWeight = "bolder";
      styleTitle.float = "right";
      styleTitle.textAlign = "right";
    }
    if (r.titleRow) {
      styleTitle.textAlign = "right";
      styleTitle.fontWeight = "bolder";
      styleTitle.marginLeft = 0;
    }
    if (r.titleMain) {
      styleTitle.fontWeight = "bolder";
      styleTitle.fontSize = "13px";
    }

    if (r.id) {
      this.props.props.updateDate("");
      return (
        <span style={styleTitle}>
          <Link to={{ pathname: `/ledgers/transactions/${r.id}` }}>{v}</Link>
        </span>
      );
    }
    if (v) {
      return <span style={styleTitle}> {v}</span>;
    }
  }

  _renderCellAmount(v, r) {
    if (v && r.titleTotal) {
      return (
        <div style={{ textAlign: "right" }}>
          <span className="reportAmt">
            {typeof v === "string" ? (
              v
            ) : (
              <NumberFormat
                prefix={v < 0 ? "(" : ""}
                suffix={v < 0 ? ")" : ""}
                style={{ fontWeight: "bolder" }}
                thousandSeparator={true}
                displayType="text"
                fixedDecimalScale={true}
                decimalScale={2}
                value={v < 0 ? v * -1 : v || 0}
              />
            )}
          </span>
        </div>
      );
    }
    if (v) {
      return (
        <div style={{ textAlign: "right" }}>
          <span className="reportAmt">
            {typeof v === "string" ? (
              v
            ) : (
              <NumberFormat
                prefix={v < 0 ? "(" : ""}
                suffix={v < 0 ? ")" : ""}
                thousandSeparator={true}
                displayType="text"
                fixedDecimalScale={true}
                decimalScale={2}
                value={v < 0 ? v * -1 : v || 0}
              />
            )}
          </span>
        </div>
      );
    }
  }

  _renderCellTotal(v, r) {
    if (v) {
      return (
        <div style={{ textAlign: "right" }}>
          <span className="reportAmt">
            <NumberFormat
              prefix={v < 0 ? "(" : ""}
              suffix={v < 0 ? ")" : ""}
              thousandSeparator={true}
              displayType="text"
              fixedDecimalScale={true}
              decimalScale={2}
              value={v < 0 ? v * -1 : v || 0}
            />
          </span>
        </div>
      );
    }
  }

  componentWillMount() {
    const { fromMonth, toMonth, fromMonthDate, toMonthDate } = this.props;
    let companyId = this.props.companyId._id;
    //----- construct table columns -----
    let monthCount = moment([
      toMonth.substring(0, 4),
      parseInt(toMonth.substring(4)) - 1,
    ]).diff(
      moment([fromMonth.substring(0, 4), parseInt(fromMonth.substring(4)) - 1]),
      "months",
      true
    );
    let tableColumn = [];
    let yearMonth = [];

    // categories column
    tableColumn.push({
      title: (
        <Row>
          <Col span={24}></Col>
        </Row>
      ),
      className: "title-desc",
      width: 250,
      fixed: "left",
      dataIndex: "desc",
      key: "rnp-report-desc",
      render: (v, r) => this._renderCellDesc(v, r),
    });

    // values column
    for (let x = 0; x <= monthCount; x++) {
      let varA = moment([
        fromMonth.substring(0, 4),
        parseInt(fromMonth.substring(4)) - 1,
      ])
        .add(x, "M")
        .format("YYYYMM");
      yearMonth.push(x < 0 ? "total" : varA);
      tableColumn.push({
        title: (
          <Row>
            <Col span={24}>{varA}</Col>
          </Row>
        ),
        className: "td-padding-0 reportAmt",
        width: "auto",
        align: "right",
        dataIndex: varA,
        key: "rnp-report-" + x,
        render: (v, r) => this._renderCellAmount(v, r, x),
      });
    }

    //total column
    tableColumn.push({
      title: (
        <Row>
          <Col span={24}>Total</Col>
        </Row>
      ),
      className: "td-padding-0 reportAmt",
      width: "auto",
      fixed: "right",
      align: "right",
      dataIndex: "total",
      key: monthCount,
      render: (v, r) => this._renderCellAmount(v, r),
    });

    this.setState({ tableColumn });
    //----- construct table columns -----

    client
      .authenticate()
      .then(() => {
        return Promise.all([
          axios.get(`${client.io.io.uri}ledgerTrxSummarybyReceipt`, {
            params: {
              companyId: companyId,
              toDate: new Date(toMonthDate),
              fromDate: new Date(fromMonthDate),
            },
            headers: {
              Authorization: client.settings.storage.storage["feathers-jwt"],
            },
          }),
          axios.get(`${client.io.io.uri}ledgerTrxSummarybyPayment`, {
            params: {
              companyId: companyId,
              toDate: new Date(toMonthDate),
              fromDate: new Date(fromMonthDate),
            },
            headers: {
              Authorization: client.settings.storage.storage["feathers-jwt"],
            },
          }),
        ]);
      })
      .then(([res1, res2]) => {
        this.setState({ tableColumn, loading: false });
        this._constructTable(res1.data, res2.data);
        document.title = `${this.props.companyId.name}_R&P_ ${moment(
          this.state.fromDate
        ).format("YYYYMMDD")}-${moment(this.state.toDate).format("YYYYMMDD")}`;
      });
  }

  _renderExcelRows() {
    const { jmbs } = this.props;
    const { tableColumn, reportData } = this.state;

    let tableCol = tableColumn.length;

    function checkVal(k, i, n, underline, key) {
      let a = { "data-b-b-s": underline ? false : "thin" };
      if (i[k.dataIndex]) {
        if (i.titleMain) {
          a["data-f-sz"] = "18";
          a["data-f-bold"] = true;
        }
        if (i.titleRow) {
          a["data-f-sz"] = "13";
          a["data-f-bold"] = true;
        }
        if (n) {
          a["data-t"] = "n";
          a["data-num-fmt"] = "#,##0.00;[RED](#,##0.00)";
        }
        return (
          <td {...a} key={key}>
            {i[k.dataIndex]}
          </td>
        );
      }
      return <td {...a} key={key}></td>;
    }

    return (
      <>
        {/* print header */}
        {jmbs.managementName ? (
          <tr key={"td-header-1"}>
            <td data-f-bold={true} data-a-h="center" colSpan={tableCol}>
              {jmbs.managementName}
            </td>
          </tr>
        ) : null}
        {jmbs.headerLine1 ? (
          <tr key={"td-header-2"}>
            <td data-a-h="center" colSpan={tableCol}>
              {jmbs.headerLine1}
            </td>
          </tr>
        ) : null}
        {jmbs.headerLine2 ? (
          <tr key={"td-header-3"}>
            <td data-a-h="center" colSpan={tableCol}>
              {jmbs.headerLine2}
            </td>
          </tr>
        ) : null}
        {jmbs.headerLine3 ? (
          <tr key={"td-header-4"}>
            <td data-a-h="center" colSpan={tableCol}>
              {jmbs.headerLine3}
            </td>
          </tr>
        ) : null}
        {jmbs.headerLine4 ? (
          <tr key={"td-header-5"}>
            <td data-a-h="center" colSpan={tableCol}>
              {jmbs.headerLine4}
            </td>
          </tr>
        ) : null}
        {
          <tr key={"td-header-6"}>
            <td data-a-h="center" data-f-sz="18" colSpan={tableCol}>
              Receipts & Payments Statement
            </td>
          </tr>
        }

        <tr>
          {//column title
          tableColumn.map((i, index) => {
            let title = moment(i.dataIndex, "YYYYMM").isValid()
              ? moment(i.dataIndex, "YYYYMM").format("MMM-YYYY")
              : i.title;
            return (
              <td
                key={"tr-title-td-" + index}
                data-f-sz="16"
                data-f-bold="true"
                data-a-h="center"
                data-b-b-s="medium"
                data-b-t-s="medium"
              >
                {title}
              </td>
            );
          })}
        </tr>

        {//rows
        reportData.map((i, indexi) => {
          return (
            <tr>
              {tableColumn.map((j, index) => {
                return (
                  <>
                    {checkVal(
                      j,
                      i,
                      index > 1,
                      i.titleMain,
                      "td-amt-" + indexi + "-td-" + index
                    )}
                  </>
                );
              })}
            </tr>
          );
        })}
      </>
    );
  }

  render() {
    const { jmbs, fromMonth, toMonth } = this.props;
    const { loading } = this.state;

    return (
      <div>
        <Spin spinning={loading}>
          <Card>
            <span style={{ fontSize: 20 }}>{jmbs.name}</span>
            <br />
            <span>({jmbs.registrationNo})</span>
            <br />
            <span style={{ fontSize: 20 }}>Receipt And Payment</span>
            <span className="alignRight">
              As at selected date :{" "}
              {moment(fromMonth, "YYYYMM").format("MM-YYYY")} ~{" "}
              {moment(toMonth, "YYYYMM").format("MM-YYYY")}
            </span>
            <br></br>
            <Row>
              <Col span={5}>
                <img width="25%" src={jmbs.headerFile} />
              </Col>
              <Col span={19}>
                <p>
                  <span>{jmbs.headerLine1}</span>
                </p>
                <p>
                  <span>{jmbs.headerLine2}</span>
                </p>
                <p>
                  <span>{jmbs.headerLine3}</span>
                </p>
                <p>
                  <span>{jmbs.headerLine4}</span>
                </p>
              </Col>
            </Row>

            <Table
              className="printTable"
              rowClassName={(record) => {
                if (record.titleMain) {
                  return "title-category title-main";
                }
                if (record.titleRow) {
                  return "title-category";
                }
              }}
              scroll={{ x: 1250 }}
              dataSource={this.state.reportData}
              columns={this.state.tableColumn}
              pagination={false}
              size="small"
            />

            <table
              style={{ display: "none" }}
              key={"printExcel-table"}
              data-cols-width={
                "40" +
                ",12".repeat(
                  this.state.tableColumn.length > 0
                    ? this.state.tableColumn.length - 1
                    : 0
                )
              }
              className="printExcel"
            >
              <tbody>{this._renderExcelRows()}</tbody>
            </table>
          </Card>
        </Spin>
      </div>
    );
  }
}

class ReceiptandPaymentYearly extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fromMonth: moment().startOf("year"),
      toMonth: moment().endOf("year"),
    };
  }

  render() {
    const exportToCSV = () => {
      TableToExcel.convert(document.querySelector("table.printExcel"), {
        name: `${this.props.commons.selectedCompany.name}_R&P Detail_${moment(
          this.state.fromMonth
        ).format("YYYYMM")}-${moment(this.state.toMonth).format(
          "YYYYMM"
        )}.xlsx`,
        sheet: {
          name: "Sheet 1",
        },
      });
    };

    return (
      <div>
        <Row gutter={24}>
          <Col span={3} offset={16}>
            From Date :{" "}
            <MonthPicker
              defaultValue={this.state.fromMonth}
              onChange={(e) =>
                this.setState({ fromMonth: moment(new Date(e)).startOf("day") })
              }
            />
          </Col>
          <Col span={3}>
            To Date :{" "}
            <MonthPicker
              defaultValue={this.state.toMonth}
              onChange={(e, s) => {
                let dayString = moment(e)
                  .daysInMonth()
                  .toString();
                let dateString = s + "-" + dayString;
                this.setState({ toMonth: moment(dateString) });
              }}
            />
          </Col>

          <Col span={2}>
            <Button onClick={(e) => exportToCSV()} type="primary">
              Export
            </Button>
            {/* <ReactToPrint trigger={() => <Button type="primary">PRINT</Button>} content={() => this.componentRef} /> */}
          </Col>
        </Row>
        <br />

        <ComponentToPrint
          key={this.state.fromMonth + this.state.toMonth}
          jmbs={this.props.jmbs.jmbs[0]}
          companyId={this.props.commons.selectedCompany}
          ref={(el) => (this.componentRef = el)}
          fromMonth={moment(this.state.fromMonth)
            .startOf("day")
            .format("YYYYMM")}
          toMonth={moment(this.state.toMonth)
            .endOf("day")
            .format("YYYYMM")}
          fromMonthDate={this.state.fromMonth}
          toMonthDate={this.state.toMonth}
          props={this.props}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    jmbs: state.jmbs,
    commons: state.commons,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateDate: updateDate,
    },
    dispatch
  );
}

export default requireAuth(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Form.create()(ReceiptandPaymentYearly))
);
