import React, { Component, useEffect } from 'react'
import { Modal, Upload, Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Select, InputNumber, Spin, DatePicker } from 'antd'
import client from '../../feathers'
import requireAuth from "../../components/requireAuth"
import Layout from "../../components/layout"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateMode } from '../../actions/actions-creditnotes'
import axios from 'axios';
import params from '../../params/system'
import moment from 'moment';


var FormData = require('form-data');
const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};
const text = 'Are you sure to delete this item?';
//----------------------------------------------------------------------------
class CreditNotesForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',
      confirmDirty: false,
      autoCompleteResult: [],
      ledgerAccountList: [],
      formloading: false,
      dtAccountList: [],
      ctAccountList: [],
    }

  }

  componentDidUpdate() {

    if (this.props.creditnotes.activeId !== this.state._id) {
      this.setState({
        _id: this.props.creditnotes.activeId
      })

      if (this.props.creditnotes.activeCreditNotes) {
        this.props.form.setFieldsValue({
          ctledgerAccountId: this.props.creditnotes.activeCreditNotes.ctledgerAccountId._id,
          dtledgerAccountId: this.props.creditnotes.activeCreditNotes.dtledgerAccountId._id,
          creditAmount: this.props.creditnotes.activeCreditNotes.creditAmount,
          invoiceNo: this.props.creditnotes.activeCreditNotes.invoiceNo,
          invoiceDate: moment(this.props.creditnotes.activeCreditNotes.invoiceDate),
          trxDate: moment(this.props.creditnotes.activeCreditNotes.trxDate),
          remark: this.props.creditnotes.activeCreditNotes.remark,
        })
      }
    }

  }

  handleSubmit = (e) => {

    this.setState({ formloading: true })
    if (this.props.creditnotes.mode === 'new') {

      this.props.form.validateFields((err, values) => {

        if (!err) {

          values.trxDate = new Date(values.trxDate)
          values.invoiceDate = new Date(values.invoiceDate)

          client.authenticate()
            .then((res) => {
              return client.service('creditnotes').create({
                companyId: this.props.user.info.propertyId._id,
                ...values,
                divisionType: 'DEBTOR',
                trancode: '1600',
              })
            })
            .then((res) => {

              this.props.updateMode('refresh')
              this.props.form.resetFields()
              this.setState({
                formloading: false,
              });
            })
            .catch(err => { console.log(err) })
        }
        else {
          this.setState({
            formloading: false,
          });
        }

      });


    }
    else {
      this.props.form.validateFields((err, values) => {

        if (!err) {

          this.setState({
            formloading: true,
          });

          values.trxDate = new Date(values.trxDate)
          values.invoiceDate = new Date(values.invoiceDate)

          client.authenticate()
            .then((res) => {
              return client.service('creditnotes').patch(this.props.creditnotes.activeId, {
                companyId: this.props.user.info.propertyId._id,
                ...values,
              })
            })
            .then((res) => {

              this.props.updateMode('refresh')
              this.props.form.resetFields()
              this.setState({
                formloading: false,
              });
            })
            .catch(err => { console.log(err) })
        }
        else {
          this.setState({
            formloading: false,
          });
        }

      });
    }
  }

  componentWillMount() {
    client.authenticate()
      .then((res) => {
        return client.service('ledger-accounts').find({
          query: {
            companyId: this.props.user.info.propertyId._id,
            $sort: { accountName: 1 },
            accountNo: '3000',
            subAccountNo: { $ne: '000' },
          }
        })
      })
      .then((res) => {
        this.setState({
          dtAccountList: res.data
        });
        return client.service('payment-type-params').find({
          query: {
            $populate: 'ledgerAccountId',
            propertyId: this.props.user.info.propertyId._id,
            category: {
              $in: ['CREDITNOTES']
              // $in:['INVOICE']
            },
          }
        })

      })
      .then((res) => {
        // console.log({res});
        this.setState({
          ctAccountList: res.data
        });
      })
      .catch((err) => {
        console.log(err);
      });

  }

  render() {
    const { visible, onCancel } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Modal
        centered
        visible={this.props.creditnotes.mode === 'new' || this.props.creditnotes.mode === 'edit' ? true : false}
        title={this.props.creditnotes.mode === 'new' ? 'Add New Credit Notes' : 'Edit New Credit Notes'}
        okText={this.props.creditnotes.mode === 'new' ? 'Add' : 'Edit'}
        onCancel={() => { this.props.updateMode(''); this.props.form.resetFields() }}
        onOk={() => { setTimeout(() => { this.handleSubmit() }, 5000) }}
      >
        <Spin spinning={this.state.formloading}>
          <Form className="submit-form">

            <FormItem {...formItemLayout} label="Transaction Date">
              {getFieldDecorator('trxDate', {
                rules: [{
                  required: true, message: 'Please input Transaction Date!',
                }],
                initialValue: moment(),
              })(
                <DatePicker />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="Debtor">
              {getFieldDecorator('dtledgerAccountId', {
                rules: [{
                  required: true, message: 'Please select Debtor!',
                }],
              })(
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.state.dtAccountList.map(dropdown => (
                    <Option key={dropdown._id} value={dropdown._id} description={dropdown.description} >
                      {dropdown.description}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="Credit by">
              {getFieldDecorator('ctledgerAccountId', {
                rules: [{
                  required: true, message: 'Please select Credit by!',
                }],
              })(
                <Select>
                  {this.state.ctAccountList.map(dropdown => (
                    <Option key={dropdown.ledgerAccountId._id}  >
                      {dropdown.description} ({dropdown.ledgerAccountId.fullAccountNo})
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="Invoice No">
              {getFieldDecorator('invoiceNo', {
                rules: [{
                  required: false, message: 'Please select Invoice No!',
                }],
              })(
                <Input />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="Invoice Date">
              {getFieldDecorator('invoiceDate', {
                rules: [{
                  required: false, message: 'Please input Invoice Date!',
                }],
                initialValue: moment(),
              })(
                <DatePicker />
              )}
            </FormItem>


            <FormItem {...formItemLayout} label="Credit Amount">
              {getFieldDecorator('creditAmount', {
                rules: [{
                  required: true, message: 'Please select Credit Amount!',
                }],
              })(
                <InputNumber />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="Remark">
              {getFieldDecorator('remark', {
                rules: [{
                  required: false, message: 'Please select Remark!',
                }],
              })(
                <TextArea rows={4} />
              )}
            </FormItem>

          </Form>
        </Spin>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    creditnotes: state.creditnotes
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
  }, dispatch);
}

//----------------------------------------------------------------------------

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(CreditNotesForm)));
