import React, { Component } from 'react';
import { Modal, message, Form, Input, Button, Select, InputNumber, Radio } from 'antd';
import client from '../../feathers';
import requireAuth from "../requireAuth";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateMode } from '../../actions/actions-recurringitems';
import axios from 'axios';
const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};


class RecurringitemsFormMultiple extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',
      confirmDirty: false,
      autoCompleteResult: [],
      chargesDropdowns: [],
      visible: false
    }
  }
  componentDidUpdate() {
    if (this.props.recurringitems.activeId !== this.state._id) {
      this.setState({
        _id: this.props.recurringitems.activeId
      })

      if (this.props.recurringitems.activeRecurringitems) {
        this.props.form.setFieldsValue(
          this.props.recurringitems.activeRecurringitems
        )
      }
    }

  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }


  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        // client.service('recurringitems').create({
        //   ...values,
        //   propertyId:sessionStorage.getItem('companyId'),
        client.authenticate()
          .then((res) => {
            return axios.post(`${client.io.io.uri}updateallfee`, {
              ...values,
              propertyId: this.props.commons.selectedCompany._id,
            }, {
              headers: {
                'Authorization': client.settings.storage.storage['feathers-jwt']
              }
            })
          })
          .then((res) => {

            message.success('Updated');
            this.setState({ loading: false });
          })
          .catch((err) => {
            console.log(err);
            this.setState({ loading: false });
          });
      }
    });
  }

  componentWillMount() {

    //generate charges dropdown
    client.authenticate()
      .then((res) => {
        return client.service('charges').find({
          query: {
            propertyId: this.props.commons.selectedCompany._id,
            $sort: {
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {
        this.setState({ chargesDropdowns: res.data });
      })
      .catch((err) => {
        console.log(err);
      });

  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  }

  handleOk = (e) => {

    this.setState({
      visible: false,
    });
  }

  handleCancel = (e) => {

    this.setState({
      visible: false,
    });
  }


  render() {

    const { getFieldDecorator } = this.props.form;

    // propertyId    : {type:String,ref:'properties', required: true },
    // propertyunitId: {type:String,ref:'propertyunits', required: true },
    // chargeId      : {type:String,ref:'charges', required: true },
    // description   : {type:String},
    // amount        : {type:Number},
    // status        : {type:String, required: true },   //ACTIVE, INACTIVE

    return ([
      <Button type="primary" onClick={this.showModal}>
        Edit All
      </Button>,
      <Modal
        title="Apply to All Unit"
        visible={this.state.visible}
        okText={'Submit'}
        onOk={this.handleSubmit}
        onCancel={this.handleCancel}
      >
        <Form className="submit-form">

          <FormItem
            {...formItemLayout}
            label="Charges"
          >
            {getFieldDecorator('chargeId', {
              rules: [{
                required: true, message: 'Please select Charges!',
              }],
            })(
              <Select placeholder="Select Type" style={{ 'width': '200px' }} onChange={this.handleChange}>
                {this.state.chargesDropdowns.map(dropdown => (
                  <Option value={dropdown._id}>
                    {dropdown.description}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>

          {/*  // <FormItem
          //    {...formItemLayout}
          //    label="Description"
          //  >
          //    {getFieldDecorator('description', {
          //      rules: [{
          //        required: false, message: 'Please input Description!',
          //      }],
          //    })(
          //     <TextArea rows={4} />
          //    )}
          // </FormItem>   */}


          <FormItem
            {...formItemLayout}
            label="Amount"
          >
            {getFieldDecorator('amount', {
              rules: [{
                required: true, message: 'Please input recurring!',
              }],
            })(
              <InputNumber />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Status"
          >
            {getFieldDecorator('status', {
              rules: [{
                required: true, message: 'Please Select Status!',
              }],
            })(
              <Radio.Group>
                <Radio.Button value="ACTIVE">ACTIVE</Radio.Button>
                <Radio.Button value="INACTIVE">INACTIVE</Radio.Button>
              </Radio.Group>
            )}
          </FormItem>

        </Form>
      </Modal>

    ])
  }
}


function mapStateToProps(state) {
  return {
    recurringitems: state.recurringitems,
    commons: state.commons
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(RecurringitemsFormMultiple)));
