export const FETCH_RECEIPTS= 'FETCH_RECEIPTS';
export const PUSH_RECEIPTS= 'PUSH_RECEIPTS';
export const REMOVE_RECEIPTS= 'REMOVE_RECEIPTS';
export const UPDATE_RECEIPTS= 'UPDATE_RECEIPTS';
export const UPDATE_MODE = 'UPDATE_MODE';
export const UPDATE_ACTIVE_ID = 'UPDATE_ACTIVE_ID';

export function fetchReceipts(data){
  return (dispatch) => {
    dispatch({
      type: FETCH_RECEIPTS,
      payload:data
    });
  }
}

export function pushReceipts(data){
  return (dispatch) => {
    dispatch({
      type: PUSH_RECEIPTS,
      payload:data
    });
  }
}

export function removeReceipts(data){
  return (dispatch) => {
    dispatch({
      type: REMOVE_RECEIPTS,
      payload:data
    });
  }
}

export function updateReceipts(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_RECEIPTS,
      payload:data
    });
  }
}

export function updateMode(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE,
      payload:data
    });
  }
}

export function updateActiveReceipts(mode, id){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_ID,
      mode,
      id
    });
  }
}
