import React, { Component, useEffect } from 'react';
import { Modal, Upload, Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Select, TimePicker, Tabs } from 'antd';
import client from '../../feathers';
import requireAuth from "../../components/requireAuth";
import Layout from "../../components/layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateMode } from '../../actions/actions-eforms';
import moment from 'moment';
import EformsCardForm from "./form-card";
import EformsMoveForm from "./form-move";
import EformsRenovationForm from "./form-renovation";

const TabPane = Tabs.TabPane;
const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
const format = 'HH:mm';

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};

const text = 'Are you sure to delete this item?';

class EformsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  componentDidUpdate() {
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.props.eforms.mode === 'new') {

      this.props.form.validateFields((err, values) => {
        if (!err) {
          client.service('eforms').create(values)
            .then((res) => {

              this.props.updateMode('')
              message.success('New Facility Added');
            })
            .catch((err) => {
              console.log(err);
              this.props.updateMode('')
              alert(err);
            })
        }
      });
    } else {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          client.service('eforms').patch(this.props.eforms.activeId,
            values
          )
            .then((res) => {

              message.success(`Eforms Updated`);
              this.props.updateMode('')
            })
            .catch((err) => {
              console.log(err);

              this.props.updateMode('')
            })
          return;
        }

      });
    }
  }

  render() {
    const { visible, onCancel } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Layout>
        <Tabs defaultActiveKey="1" onChange={callback}>
          <TabPane tab="Card Application" key="1"><EformsCardForm mode='New' /></TabPane>
          <TabPane tab="Renovation" key="2"><EformsRenovationForm mode='New' /></TabPane>
          <TabPane tab="Common Area" key="3"><EformsMoveForm mode='New' /></TabPane>
        </Tabs>
      </Layout>

    )
  }
}

function callback(key) {

}

function mapStateToProps(state) {
  return {
    eforms: state.eforms
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(EformsForm)));
