import React, { Component } from 'react';
import { Row, Col, Card, Popconfirm, message, Divider,  Table, Form, Icon, Input, Button } from 'antd';
import client from '../../feathers';
import requireAuth  from "../requireAuth";
import Layout  from "../layout";
import Info  from "../common/info";
//import Test  from "../../components/property-units/test"
import { updateMode } from '../../actions/actions-propertyunits';
import PropertyunitusersForm  from "./form";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchPropertyunits,
  pushPropertyunits,
  removePropertyunits,
  updateActivePropertyunits,
  updatePropertyunits } from '../../actions/actions-propertyunits';


const FormItem = Form.Item;

class PropertyView extends Component {
  constructor(props) {
      super(props);

      this.state = {
        propertyunits: []
      }

      this.handlePropertyUnitAdd = this.handlePropertyUnitAdd.bind(this);
  }


  componentWillMount(){

      client.authenticate()
      .then((res)=>{
          return client.service('propertyunits').find({
            query: {
              _id:this.props.propertyunitsId,
               $sort: {
                 createdAt: -1
               }
             }
          })
      })
      .then((res)=>{

        this.setState({
          propertyunits:res.data[0]
        })

      })
      .catch((err)=>{
        console.log(err);
      })
  }

  componentDidMount(){
      client.service('propertyunits').on('updated', (propertyunits) => {
        this.props.updatePropertyunits(propertyunits)
      })
  }

  handlePropertyUnitAdd(){
    this.props.updateMode('new')
  }

  handleEdit(_id) {
    this.props.updateActivePropertyunits('edit',_id)
  }


  render() {
    return(
      <div>
        <Card title="Property Unit Info">
          <Info label="Unit No." labelSpan={6} value={this.state.propertyunits.referenceId} valueSpan={18} />
        </Card>
        <PropertyunitusersForm/>
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    propertyunits: state.propertyunits
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchPropertyunits: fetchPropertyunits,
    pushPropertyunits: pushPropertyunits,
    removePropertyunits: removePropertyunits,
    updateActivePropertyunits: updateActivePropertyunits,
    updatePropertyunits: updatePropertyunits,

    updateMode:updateMode
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyView);
