import { Button, Card, Col, Form, Row, Spin, Table } from 'antd';
import axios from "axios";
import _ from "lodash";
import moment from 'moment';

import React from 'react';
import { connect } from "react-redux";
import ReactToPrint from 'react-to-print';
import { bindActionCreators } from "redux";
import {
  fetchBankRecons,
  pushBankRecons,
  removeBankRecons,

  updateActiveBankRecons,
  updateBankRecons, updateModeBankRecons
} from '../../actions/actions-bankrecons';
import client from '../../feathers';
import Layout from "../layout";
import requireAuth from "../requireAuth";


// -----------------------------------------------------------------------------------
const formatCurr = function format1(n = 0, currency = null) {
  let oriAmt = n;
  n = n < 0
    ? Math.abs(n)
    : n;
  let amt = currency
    ? currency
    : '' + n.toFixed(2).replace(/./g, function (c, i, a) {
      return i > 0 && c !== "." && (a.length - i) % 3 === 0
        ? "," + c
        : c;
    });

  return oriAmt < 0
    ? '(' + amt + ')'
    : amt;
}


// -----------------------------------------------------------------------------------
class ComponentToPrint extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      dataSource1: [],
      dataSource2: [],
      dataSource3: [],

      loading: false,
      totalRecord: 0,
      CurrentPagination: 1,
      totalCredit: 0,
      totalDebit: 0,
      bankLedgerAccountList: [],
      currentBalance: 0,
      bankLedgerAccountId: '',
      thisyearmonth: '',
      monthlystatements: '',
    }
  }
  // -----------------------------------------------------------------------------------
  componentWillMount() {


    this.setState({ dataSource1: [], dataSource2: [], dataSource3: [], loading: true });
    // let thisMonth = this.props.props.bankrecons.selectedMonth.substring(0, 4) + '-' + this.props.props.bankrecons.selectedMonth.substring(4, 6)
    let thisMonth = this.props.props.bankrecons.selectedMonth
    let thisMonthStart = moment(thisMonth).startOf('month')
    let thisMonthEnd = moment(thisMonth).endOf('month')

    let yearmonthFrom = moment(thisMonth).startOf('month')
    let yearmonthTo = moment(thisMonth).endOf('month')
    let thisyearmonth = moment(yearmonthTo).format('YYYY-MM-DD')
    let ledgerAccountId = this.props.props.bankrecons.selectedBank

    client.authenticate().then((res) => {

      axios.get(`${client.io.io.uri}getAccountBalanceByDate`, {
        params: {
          companyId: this.props.props.commons.selectedCompany._id,
          ledgerAccountId: ledgerAccountId,
          trxDateStart: new Date(thisMonthStart),
          trxDateTo: new Date(thisMonthEnd),
        },
        headers: {
          'Authorization': client.settings.accessToken
        }
      }).then((res) => {

        if (res.data) {
          if (_.isEmpty(res.data) === false) {

            let currentBalance = res.data[0].currentBalance
            this.setState({ systemBalance: currentBalance, })
          }
          else {
            this.setState({ systemBalance: 0, })
          }
        }
      })
        .catch((err) => {
          console.log(err);
        })

      this.setState({ thisyearmonth: thisyearmonth });

      client.service('ledger-transactions').find({
        query: {
          ledgerAccountId: ledgerAccountId,
          companyId: this.props.props.commons.companyId,
          reconDate: { $not: { $lte: yearmonthTo } },
          trxDate: {
            // $gte: yearmonthFrom,
            $lte: yearmonthTo
          },
          $sort: {
            trxDate: 1
          }
        }
      }).then((res) => {
        this.setState({ dataSource: res.data, loading: false });
      })
      document.title = `${this.props.props.commons.selectedCompany.name}_Bank Reconciliation Statement_${moment(yearmonthTo).format('YYYYMMDD')}`

    })
  }

  // -----------------------------------------------------------------------------------
  render() {

    const { dataSource, systemBalance, } = this.state;

    let { companyInfo, } = this.props.props.commons
    let closingBalance = this.props.props.bankrecons.closingBalance ? this.props.props.bankrecons.closingBalance : 0
    let differences = closingBalance - systemBalance


    if (differences < 0) {
      differences = differences * -1
    }

    let totalUC = 0;
    // let totalUR = 0;
    let totalLNC = 0;
    let dataSource1 = [];
    let dataSource3 = [];

    // /* --------------------------------------------------------------- */
    dataSource.forEach(function (item, index) {
      try {
        if (item.creditDebit === 'DEBIT') {
          dataSource1.push(item);
          //totalLiabilities += item.output.balance;
        } else if (item.creditDebit === 'CREDIT') {
          dataSource3.push(item);
        }
      } catch (e) { }
    });


    // /* --------------------------------------------------------------- */

    for (let i = 0; i <= dataSource1.length; i++) {


      totalUC += dataSource1[i]
        ? dataSource1[i].debitAmount
        : 0;
    }

    // for (let i = 0; i <= dataSource2.length; i++) {
    //   totalUR += dataSource2[i]
    //     ? dataSource2[i].creditAmount
    //     : 0;
    // }

    for (let i = 0; i <= dataSource3.length; i++) {

      totalLNC += dataSource3[i]
        ? dataSource3[i].creditAmount
        : 0;
    }

    let perCashBook = closingBalance + totalUC - totalLNC




    // {/* --------------------------------------------------------------- */ }
    const columns = [
      {
        title: 'Date',
        dataIndex: 'trxDate',
        width: '10%',
        render: (text, record) => (<span>
          {moment(text).format('DD-MM-YYYY')}
        </span>)
      }, {
        title: 'Description',
        dataIndex: 'description',
        width: '40%'
      }, {
        title: (<span style={{
          float: 'right'
        }}>
          Balance
        </span>),
        width: '20%',
        dataIndex: 'balance',
        render: (text, record) => (<span style={{
          float: 'right'
        }}>
          {
            formatCurr(
              record.creditAmount
                ? record.creditAmount
                : record.debitAmount)
          }
        </span>)
      }
    ];

    return (<div>
      <Spin spinning={this.state.loading}>
        <Card title={<div > <Row>
          <span>Bank Reconciliation Statement </span>

        </Row>
          <Row>
            <span style={{ fontSize: 12 }}>as Per {moment(new Date(this.state.thisyearmonth)).format("Do MMM YYYY")}</span>
          </Row>
        </div>}>
          <Col span={24}>
            <Row><span>{companyInfo ? companyInfo.name ? companyInfo.name : '' : ''}</span>{companyInfo ? companyInfo.registrationNo ? ' (' + companyInfo.registrationNo + ')' : '' : ''}</Row>
            <Row><span>{companyInfo ? companyInfo.address ? companyInfo.address : '' : ''}</span></Row>
            <Row>
              <span>{companyInfo ? companyInfo.postCode ? companyInfo.postCode : '' : ''}</span>
              <span>{companyInfo ? companyInfo.area ? ' , ' + companyInfo.area : '' : ''}</span>
              <span>{companyInfo ? companyInfo.state ? ' , ' + companyInfo.state : '' : ''}</span>
            </Row>
          </Col>

          <Row>
            <Row>



              <Row>
                <Col span={12}><br /><span><h5>BALANCE AS PER ACCOUNT</h5></span></Col>
                <Col span={12}><br /><span style={{ float: 'right' }}><h5>{formatCurr(closingBalance)}</h5></span></Col>
              </Row>

              <br />
              <span>
                <h5>UNIDENTIFIED CHEQUE/RECEIPTS</h5>
              </span>
              <Table dataSource={dataSource1} columns={columns} pagination={false} rowKey={'_id'} footer={() => <div>
                <Row>

                  <Col>
                    <span style={{
                      float: 'right'
                    }}>Total :{formatCurr(totalUC)}</span>
                  </Col>
                </Row>
              </div>} size={'small'} />
            </Row>

            <Row>
              <br />
              <span><h5>LESS : UNPRESENTED CHEUQE</h5></span>
              <br />
              <Table dataSource={dataSource3} columns={columns} pagination={false} rowKey={'_id'} footer={() => <div>
                <Row>
                  <Col><span style={{ float: 'right' }}>Total :{formatCurr(totalLNC)}</span></Col>
                </Row>
              </div>} size={'small'} />
            </Row>

            <Row>
              <Col span={12}><br /><span><h5>BALANCE AS PER CASH BOOK</h5></span></Col>
              <Col span={12}><br /><span style={{ float: 'right' }}><h5>{formatCurr(perCashBook)}</h5></span></Col>
            </Row>

            {/* <Row>
              <Col span={12}><br /><span><h5>DIFFERENCES</h5></span></Col>
              <Col span={12}><br /><span style={{ float: 'right' }}><h5>{formatCurr(differences)}</h5></span></Col>
            </Row> */}


          </Row>

        </Card>
      </Spin>
    </div>);
  }
}

class BankReconStatement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      dataSource1: [],
      dataSource2: [],
      dataSource3: [],

      loading: false,
      totalRecord: 0,
      CurrentPagination: 1,
      totalCredit: 0,
      totalDebit: 0,
      bankLedgerAccountList: [],
      currentBalance: 0,
      bankLedgerAccountId: this.props.match.params.id
    }

  }

  render() {
    return (<Layout>
      <Row>
        <Col align='right' style={{
          marginBottom: '5px'
        }}>
          {/* <span>
           <Button style={{marginRight:'6px'}} type='primary'>Save</Button> 
        </span> */}
          <span>
            <ReactToPrint trigger={() => <Button type='primary'>PRINT</Button>} content={() => this.componentRef} />
          </span>

        </Col>
      </Row>

      <>
        <ComponentToPrint user={this.props.user} props={this.props} ref={el => (this.componentRef = el)} />
      </>

    </Layout>);
  }
}

function mapStateToProps(state) {

  return {
    user: state.user,
    bankrecons: state.bankrecons,
    commons: state.commons,
  }

}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchBankRecons: fetchBankRecons,
    pushBankRecons: pushBankRecons,
    removeBankRecons: removeBankRecons,
    updateActiveBankRecons: updateActiveBankRecons,
    updateBankRecons: updateBankRecons,
    updateModeBankRecons: updateModeBankRecons
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(BankReconStatement)));