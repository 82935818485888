import React, { Component } from 'react'
import { Modal, message, Form, Icon, Input, Select } from 'antd'
import client from '../../feathers'
import requireAuth  from "../../components/requireAuth"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateMode } from '../../actions/actions-invoices'

const FormItem = Form.Item;
const Option = Select.Option;


const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};


class InvoicesForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id:'',
      confirmDirty: false,
      autoCompleteResult: [],
    }
  }
  componentDidUpdate(){
      if(this.props.invoices.activeId !== this.state._id){
        this.setState({
          _id:this.props.invoices.activeId
        })

        if(this.props.invoices.activeInvoices){
          this.props.form.setFieldsValue(
            this.props.invoices.activeInvoices
          )
        }
      }
  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }


  handleSubmit = (e) => {
     e.preventDefault();
     if(this.props.invoices.mode === 'new'){

       this.props.form.validateFields((err, values) => {
         if (!err) {

           client.service('invoices').create(values)
           .then((res)=>{

             this.props.updateMode('')
             message.success('New Invoices Added');
           })
           .catch((err)=>{
            console.log(err);
             this.props.updateMode('')
             alert(err);
           })

         }
       });

     }else{
       this.props.form.validateFields((err, values) => {
         if (!err) {
           client.service('invoices').patch(this.props.invoices.activeId,
             values
           )
           .then((res)=>{

             message.success(`Invoices Updated`);
             this.props.updateMode('')
           })
           .catch((err)=>{
            console.log(err);
             this.props.updateMode('')
           })
           return;
         }

       });
     }
  }


  handleBlur = (event) => {
    client.service('propertyunits').find({
      query: {
        referenceId:event.target.value,
         $sort: {
           createdAt: -1
         }
       }
    })
    .then((res)=>{
      if(res.total>0){
        message.success('Record Found');
        this.props.form.setFieldsValue({
          propertyunitId:res.data[0]._id
        })
      }else{
        message.error('Not Found');
      }

    })
    .catch((err)=>{
      console.log(err);
      //message.error(err);
    })
  }

  render() {

    const { getFieldDecorator } = this.props.form;


    return(
      <Modal
         visible={
           this.props.invoices.mode==='new'||this.props.invoices.mode==='edit'?true:false
         }
         title={ this.props.invoices.mode === 'edit' ? 'Update Invoices':'Create Invoices' }
         okText={ this.props.invoices.mode === 'edit' ? 'Update':'Create' }
         onCancel={()=>{this.props.updateMode(''); this.props.form.resetFields()} }
         onOk={this.handleSubmit}
      >
        <Form className="submit-form">

          <FormItem
             {...formItemLayout}
             label="Reference ID"
           >
             {getFieldDecorator('referenceId', {
               rules: [{
                 required: true, message: 'Please input Reference ID!',
               }],
             })(
               <Input onBlur={this.handleBlur} prefix={<Icon type="tag" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="PROA-b-2-3" />
             )}
          </FormItem>

          <FormItem
             {...formItemLayout}
             label="Unit ID"
           >
             {getFieldDecorator('propertyunitId', {
               rules: [{
                 required: true, message: 'Please input unit ID!',
               }],
             })(
               <Input disabled placeholder="n3n3hb4b3hj" />
             )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Status"
            hasFeedback
          >
            {getFieldDecorator('status', {
              rules: [
                { required: true, message: 'Please select your Status!' },
              ],
            })(
              <Select placeholder="Please select a status">
                <Option value="PENDING">PENDING</Option>
                <Option value="CLOSED">CLOSED</Option>
              </Select>
            )}
          </FormItem>

        </Form>
      </Modal>

    )
  }
}


function mapStateToProps(state) {
  return {
    invoices: state.invoices
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
    }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(InvoicesForm)));
