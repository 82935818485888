import React from "react";
import { Table, Form, Button, Select, Card, Spin, Row, Col } from "antd";
import client from "../../feathers";
import requireAuth from "../requireAuth";
import Layout from "../layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { CSVLink } from "react-csv";

import ReactToPrint from "react-to-print";

import moment from "moment";
import {
  fetchTransactions,
  pushTransactions,
  removeTransactions,
  updateMode,
  updateActiveTransactions,
  updateTransactions,
} from "../../actions/actions-transactions";
import XLSX from "sheetjs-style";

// -----------------------------------------------------------------------------------
const formatCurr = function format1(n = 0, currency = null) {
  let oriAmt = n;
  n = n < 0 ? Math.abs(n) : n;
  let amt = currency
    ? currency
    : "" +
    n.toFixed(2).replace(/./g, function (c, i, a) {
      return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
    });

  return oriAmt < 0 ? "(" + amt + ")" : amt;
};
const text = "";

// -----------------------------------------------------------------------------------
class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      jmb: {},
      loading: false,
      totalRecord: 0,
      CurrentPagination: 1,
      totalCredit: 0,
      totalDebit: 0,
      transactions: "",
      totalCash: 0,
      totalBank: 0,
      totalAtm: 0,
      excelHeader: [
        {
          label: "Seq",
          key: "seqNo",
        },
        {
          label: "PaymentDate",
          key: "paymentDate",
        },
        {
          label: "Receipt No",
          key: "trxNo",
        },
        {
          label: "Unit No - Customer Name",
          key: "unitNo",
        },
        {
          label: "Payment Method",
          key: "paymentTypeParamId.description",
        },
        {
          label: "Reference",
          key: "reference",
        },
        {
          label: "Amount",
          key: "payAmount",
        },
      ],
    };
  }
  // -----------------------------------------------------------------------------------
  componentWillMount() {
    this.setState({ dataSource1: [], loading: true });

    client
      .authenticate()
      .then((res) => {
        let companyId = this.props.companyId._id;

        return client.service("jmbs").find({
          query: {
            propertyId: companyId,
            status: "ACTIVE",
          },
        });
      })
      .then((res) => {
        this.setState({ jmb: res.data[0] });
        return res;
      })
      .then((res) => {
        let companyId = this.props.companyId._id;

        document.title = `${this.props.companyId.name}_Money In Transit`;

        this.setState({ dataSource: res.data, loading: false });
        return client.service("transactions").find({
          query: {
            MITstatus: true,
            propertyId: companyId,
            $populate: "propertyunitId paymentTypeParamId",
          },
        });
      })
      .then((res2) => {
        let seqno = 0;
        res2.data.forEach(function (item, index) {
          item.seqNo = seqno + 1;
          seqno++;
        });

        this.setState({
          dataSource: res2.data,
        });
      });
  }

  // -----------------------------------------------------------------------------------
  render() {
    const { dataSource, jmb, loading } = this.state;
    var totalCash = 0;
    var totalInternetBanking = 0;
    var totalCheque = 0;
    var totalAtm = 0;
    var totalBank = 0;
    // -----------------------------------------------------------------------------------

    dataSource.forEach(function (item, index) {
      if (item.paymentTypeParamId) {
        if (
          item.paymentTypeParamId.description === "CASH"
            ? item.payAmount
            : 0 > 0
        ) {
          totalCash += item.payAmount;
        } else if (
          item.paymentTypeParamId.description === "ONLINE BANKING"
            ? item.payAmount
            : 0 > 0
        ) {
          totalInternetBanking += item.payAmount;
        } else if (
          item.paymentTypeParamId.description === "CHEQUE"
            ? item.payAmount
            : 0 > 0
        ) {
          totalCheque += item.payAmount;
        } else if (
          item.paymentTypeParamId.description === "ATM" ? item.payAmount : 0 > 0
        ) {
          totalAtm += item.payAmount;
        } else if (
          item.paymentTypeParamId.description === "BANK"
            ? item.payAmount
            : 0 > 0
        ) {
          totalBank += item.payAmount;
        }
      }
    });

    {
      /* --------------------------------------------------------------- */
    }
    const columns = [
      {
        title: "Seq.",
        dataIndex: "seqNo",
        key: "seqNo",
        width: "5%",
      },
      {
        title: "Payment Date",
        dataIndex: "paymentDate",
        key: "paymentDate",
        width: "7%",
        render: (text, record) => (
          <span>{text ? moment(text).format("DD-MM-YYYY") : ""}</span>
        ),
      },
      {
        title: "Receipt No",
        dataIndex: "trxNo",
        key: "trxNo",
        width: "8%",
        rowClassName: "italic",
      },

      {
        title: "Unit No",
        dataIndex: "propertyunitId.referenceId",
        key: "propertyunitId.referenceId",
        width: "9%",
      },
      {
        title: "Payment Method",
        dataIndex: "paymentTypeParamId.description",
        key: "paymentTypeParamId.description",
        width: "7%",
      },

      {
        title: "Reference",
        dataIndex: "reference",
        key: "reference",
        width: "10%",
      },

      {
        title: "Amount",
        dataIndex: "payAmount",
        key: "payAmount",
        width: "8%",
        align: "center",
        render: (text, record) => (
          <span>{text ? parseFloat(text).toFixed(2) : "0.00"}</span>
        ),
      },
      {
        title: "Remark",
        dataIndex: "remark",
        key: "remark",
        width: "20%",
        render: (text, record) => <div>{text}</div>,
      },
    ];

    var statementList=_.map(dataSource,function(item){
      const unit = _.get(item, "propertyunitId.unitNo");
      const ownerName = _.get(item, "propertyunitId.owner.name");
      item.unitNo = unit + " - " + ownerName;
      return item
    })


    return (
      <div>
        <Row>
          <Col
            align="right"
            style={{
              marginBottom: "5px",
            }}
          >
            <span style={{ marginLeft: 10 }}>
              {!_.isEmpty(dataSource) ? (
                <CSVLink
                  filename={`${this.props.companyId.name}_Money In Transit.csv`}
                  data={statementList}
                  headers={this.state.excelHeader}
                >
                  <Button type="primary">Export to CSV</Button>
                </CSVLink>
              ) : null}
            </span>
          </Col>
        </Row>
        <Spin spinning={this.state.loading}>
          <Card
            title={
              <div>
                {" "}
                <Row>
                  <span>Money in Transit</span>
                </Row>
              </div>
            }
          >
            <Row>
              <Col span={5}>
                <img width="100%" src={jmb.headerFile} />
              </Col>
              <Col span={19}>
                <p>
                  <span>{jmb.headerLine1}</span>
                </p>
                <p>
                  <span>{jmb.headerLine2}</span>
                </p>
                <p>
                  <span>{jmb.headerLine3}</span>
                </p>
                <p>
                  <span>{jmb.headerLine4}</span>
                </p>
              </Col>
            </Row>

            <Row>
              <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                rowKey={"_id"}
                footer={() => <div></div>}
                size={"small"}
              />
            </Row>
          </Card>

          <Row className="noBorder">
            <div key="footerMIT" className="moneyInTransitStatement">
              <div key="displaycontain" className="ql-display-container">
                <Row gutter={8}>
                  <Col span={12}>
                    <Card
                      headStyle={{ padding: "0 6", fontSize: "12px" }}
                      style={{ minHeight: "145px", width: "100 px" }}
                      bordered={true}
                    >
                      <div key="verifiedby">
                        <Row>
                          <Col span={8}>
                            {" "}
                            <span>Cheque</span>{" "}
                          </Col>
                          <Col span={8}>
                            {" "}
                            <span>:</span>{" "}
                          </Col>
                          <Col span={8}>
                            {" "}
                            <span style={{ float: "right" }}>
                              {" "}
                              {totalCheque ? formatCurr(totalCheque) : "0.00"}
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={8}>
                            {" "}
                            <span>Cash</span>{" "}
                          </Col>
                          <Col span={8}>
                            {" "}
                            <span>:</span>{" "}
                          </Col>
                          <Col span={8}>
                            {" "}
                            <span style={{ float: "right" }}>
                              {" "}
                              {totalCash ? formatCurr(totalCash) : "0.00"}
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={8}>
                            {" "}
                            <span>Online Banking</span>{" "}
                          </Col>
                          <Col span={8}>
                            {" "}
                            <span>:</span>{" "}
                          </Col>
                          <Col span={8}>
                            {" "}
                            <span style={{ float: "right" }}>
                              {" "}
                              {totalInternetBanking
                                ? formatCurr(totalInternetBanking)
                                : "0.00"}
                            </span>
                          </Col>
                        </Row>

                        <Row>
                          <Col span={8}>
                            {" "}
                            <span>Bank</span>{" "}
                          </Col>
                          <Col span={8}>
                            {" "}
                            <span>:</span>{" "}
                          </Col>
                          <Col span={8}>
                            {" "}
                            <span style={{ float: "right" }}>
                              {" "}
                              {totalBank ? formatCurr(totalBank) : "0.00"}
                            </span>
                          </Col>
                        </Row>

                        <Row>
                          <Col span={8}>
                            {" "}
                            <span>ATM</span>{" "}
                          </Col>
                          <Col span={8}>
                            {" "}
                            <span>:</span>{" "}
                          </Col>
                          <Col span={8}>
                            {" "}
                            <span style={{ float: "right" }}>
                              {" "}
                              {totalAtm ? formatCurr(totalAtm) : "0.00"}
                            </span>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card
                      headStyle={{ padding: "0 6", fontSize: "12px" }}
                      style={{ minHeight: "145px" }}
                      bordered={true}
                    >
                      <div key="verifiedby">
                        <Row>
                          <Col span={24}>
                            {" "}
                            <span
                              style={{
                                fontWeight: "bold",
                                textDecoration: "underline",
                              }}
                            >
                              For Office Use
                            </span>{" "}
                          </Col>
                        </Row>
                        <Row>
                          <Col span={10}>
                            {" "}
                            <span>Bank In Slip</span>{" "}
                          </Col>
                          <Col span={8}>
                            {" "}
                            <span>:</span>{" "}
                          </Col>
                        </Row>
                        <Row>
                          <Col span={10}>
                            {" "}
                            <span>Received By</span>{" "}
                          </Col>
                          <Col span={8}>
                            {" "}
                            <span>:</span>{" "}
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </Row>
        </Spin>
      </div>
    );
  }
}
class PrintExcell extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      doneLoading: true,
      moneyInTransitList: [],
    };
  }
  componentWillMount() {
    this.setState({ doneLoading: true });

    client
      .service("transactions")
      .find({
        query: {
          MITstatus: true,
          propertyId: this.props.companyId._id,
          $populate: "propertyunitId paymentTypeParamId",
        },
      })
      .then((res) => {
        let no = 0;
        res.data.forEach(function (item, index) {
          item.seqNo = no + 1;
          no++;
        });

        this.setState({ moneyInTransitList: res.data, doneLoading: false });
      });
  }
  handlePrintExcell = (e) => {
    const { moneyInTransitList } = this.state;

    var wb = XLSX.utils.book_new();

    let ws = XLSX.utils.aoa_to_sheet([]);
    let cell = XLSX.utils.sheet_add_aoa;

    cell(ws, [["Money In Transit"]], { origin: "A1" });
    cell(
      ws,
      [
        [
          "Seq",
          "Payment Date",
          "Receipt No",
          "Unit No",
          "Payment Method",
          "Reference",
          "Amount",
          "Remark",
        ],
      ],
      { origin: "A2" }
    );

    // ws["A2"].s = {									// set the style for target cell
    //   font: {
    //     sz: 24,
    //     bold: true,
    //   },
    // };
    // console.log(moneyInTransitList)

    moneyInTransitList.map((v) => {
      XLSX.utils.sheet_add_aoa(
        ws,
        [
          [
            v.seqNo,
            moment(v.paymentDate).format("DD-MM-YYYY"),
            v.trxNo,
            v.propertyunitId.referenceId,
            v.paymentTypeParamId.description,
            v.reference,
            v.payAmount,
            v.remark,
          ],
        ],
        { origin: -1 }
      );
    });

    XLSX.utils.book_append_sheet(wb, ws, "Money In Transit");
    XLSX.writeFile(wb, "Money In Transit.xlsx", { compression: true });
  };
  render() {
    let { doneLoading } = this.state;

    return (
      <Button
        type="primary"
        loading={doneLoading}
        onClick={this.handlePrintExcell}
      >
        Print Excel
      </Button>
    );
  }
}

class transactionstatement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gotData: 0
    }
  }
  componentDidMount() {
    client.service("transactions").find({
      query: {
        MITstatus: true,
        propertyId: this.props.commons.selectedCompany,
        $populate: "propertyunitId paymentTypeParamId",
      },
    }).then((res) => {
        this.setState({ gotData: res.total })
    })
  }
  render() {
    return (
      <Layout>
        <Row>
          {this.state.gotData > 0 &&
          <Col align='right' style={{
            marginBottom: '5px'
          }}>
            <ReactToPrint
              trigger={() => <Button type="primary">PRINT</Button>}
              content={() => this.componentRef}
            />
          </Col>}
          <ComponentToPrint
            props={this.props}
            companyId={this.props.commons.selectedCompany}
            ref={el => (this.componentRef = el)}
          />
        </Row>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    transactions: state.transactions,
    commons: state.commons,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchTransactions: fetchTransactions,
      pushTransactions: pushTransactions,
      removeTransactions: removeTransactions,
      updateActivetransactions: updateActiveTransactions,
      updateTransactions: updateTransactions,
      updateMode: updateMode,
    },
    dispatch
  );
}

export default requireAuth(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Form.create()(transactionstatement))
);
