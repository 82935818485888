import client from '../feathers';
import _ from "lodash"
export function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
  try {
    const negativeSign = amount < 0 ? "-" : "";
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    // const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();



    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {

  }
};

export function installmentAmount(amount) {
  try {

    //formula ===  princial multiply 3% , for 7 years, then divide by month (7x12= 84 month)
    let year = 9, interest = 3, month = year * 12

    let interestAmount = (amount * interest / 100) * year
    let totalLoanAmount = amount + interestAmount
    let installmentAmount = totalLoanAmount / month

    return installmentAmount
  } catch (e) {

  }
};


export function getBulkData(collection, query, pageSize) {

  return client.service(collection).find({
    query: {
      ...query,
      $limit: 0
    }
  })
    .then((res) => {

      let promiseAll = []
      let totalRecord = res.total
      if (totalRecord > 0) {
        for (var i = 0; i < totalRecord;) {
          promiseAll.push(
            client.service(collection).find({
              query: {
                ...query,
                $limit: pageSize,
                $skip: i
              }
            }).then((res) => {
              return res.data
            })
          )
          i = i + pageSize
        }

        return Promise.all(promiseAll)
          .then((resArr) => {
            // /combine all array
            let dataList = [].concat.apply([], resArr);
            return dataList

          })

      }
      else {
        return []
      }
    })


}



export function difference(object, base) {
  return _.transform(object, (result, value, key) => {
    if (!_.isEqual(value, base[key])) {
      result[key] = (_.isObject(value) && _.isObject(base[key])) ? difference(value, base[key]) : value;
    }
  });
}
