export const FETCH_WATERBILLS = 'FETCH_WATERBILLS';
export const PUSH_WATERBILLS = 'PUSH_WATERBILLS';
export const REMOVE_WATERBILLS = 'REMOVE_WATERBILLS';
export const UPDATE_WATERBILLS = 'UPDATE_WATERBILLS';
export const UPDATE_WATERBILLS_MODE = 'UPDATE_WATERBILLS_MODE';
export const UPDATE_WATERBILLS_ACTIVE_ID = 'UPDATE_WATERBILLS_ACTIVE_ID';

export function fetchWaterBills(data){
  return (dispatch) => {
    dispatch({
      type: FETCH_WATERBILLS,
      payload:data
    });
  }
}

export function pushWaterBills(data){
  return (dispatch) => {
    dispatch({
      type: PUSH_WATERBILLS,
      payload:data
    });
  }
}

export function removeWaterBills(data){
  return (dispatch) => {
    dispatch({
      type: REMOVE_WATERBILLS,
      payload:data
    });
  }
}

export function updateWaterBills(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_WATERBILLS,
      payload:data
    });
  }
}

export function updateMode(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_WATERBILLS_MODE,
      payload:data
    });
  }
}

export function updateActiveWaterBills(mode, id){
  return (dispatch) => {
    dispatch({
      type: UPDATE_WATERBILLS_ACTIVE_ID,
      mode,
      id
    });
  }
}
