export const FETCH_MONTHLYSTATEMENTS = 'FETCH_MONTHLYSTATEMENTS';
export const PUSH_MONTHLYSTATEMENTS = 'PUSH_MONTHLYSTATEMENTS';
export const REMOVE_MONTHLYSTATEMENTS = 'REMOVE_MONTHLYSTATEMENTS';
export const UPDATE_MONTHLYSTATEMENTS = 'UPDATE_MONTHLYSTATEMENTS';
export const UPDATE_MODE = 'UPDATE_MODE';
export const UPDATE_ACTIVE_ID = 'UPDATE_ACTIVE_ID';
export const UPDATE_MONTHBANK = 'UPDATE_MONTHBANK';

export function fetchMonthlyStatements(data){
  return (dispatch) => {
    dispatch({
      type: FETCH_MONTHLYSTATEMENTS,
      payload:data
    });
  }
}

export function pushMonthlyStatements(data){
  return (dispatch) => {
    dispatch({
      type: PUSH_MONTHLYSTATEMENTS,
      payload:data
    });
  }
}

export function removeMonthlyStatements(data){
  return (dispatch) => {
    dispatch({
      type: REMOVE_MONTHLYSTATEMENTS,
      payload:data
    });
  }
}

export function updateMonthlyStatements(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MONTHLYSTATEMENTS,
      payload:data
    });
  }
}

export function updateMode(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE,
      payload:data
    });
  }
}

export function updateActiveMonthlyStatements(mode, id){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_ID,
      mode,
      id
    });
  }
}

export function updateMonthBank(month,bank){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MONTHBANK,
      month,
      bank
    });
  }
}
