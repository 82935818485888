import React, { Component, useEffect } from 'react';
import {
  Modal,
  Upload,
  Popconfirm,
  message,
  Divider,
  Table,
  Form,
  Icon,
  Input,
  Button,
  Select,
  InputNumber,
  Radio,
  Spin,
  DatePicker,
} from 'antd';
import client from '../../feathers';
import requireAuth from '../../components/requireAuth';
import Layout from '../../components/layout';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  updateActiveDocuments,
  updateMode,
} from '../../actions/actions-documents';
import axios from 'axios';
import params from '../../params/system';
import moment from 'moment';

var FormData = require('form-data');

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};
const text = 'Are you sure to delete this item?';
//----------------------------------------------------------------------------
class DocumentsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',
      confirmDirty: false,
      autoCompleteResult: [],
      documentList: [],
      bucket: '',
      key: '',
      originalname: '',
      loading: false,
    };
  }
  //----------------------------------------------------------------------------
  componentDidUpdate() {
    if (this.props.documents.activeId !== this.state._id) {
      this.setState({
        _id: this.props.documents.activeId,
      });

      if (this.props.documents.activeDocuments) {
        this.props.documents.activeDocuments.date = this.props.documents
          .activeDocuments.date
          ? moment(this.props.documents.activeDocuments.date)
          : '';
        this.props.form.setFieldsValue(this.props.documents.activeDocuments);
      }
    }
  }
  //----------------------------------------------------------------------------
  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };
  //----------------------------------------------------------------------------
  //files
  handleFileUpload = (event) => {
    if (event.target.files[0]) {
      this.setState({ loading: true });

      let formData = new FormData();

      formData.append('avatar', event.target.files[0]);

      axios
        .post(`${client.io.io.uri}upload-document`, formData, {
          headers: {
            Authorization: client.settings.storage.storage['feathers-jwt'],
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          this.props.form.setFieldsValue({
            fileName: res.data.pdfUrl,
          });

          this.setState({
            loading: false,
            key: res.data.key,
            bucket: res.data.bucket,
            originalname: res.data.originalname,
          });
          message.success('Upload Successful');
        })
        .catch((err) => {
          this.setState({ loading: false });
          message.error(err);
        });
    }
  };
  //----------------------------------------------------------------------------
  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({ loading: true });
    if (this.props.documents.mode === 'upload') {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          values.bucket =
            this.state.bucket !== '' ? this.state.bucket : values.bucket;
          values.originalname =
            this.state.originalname !== ''
              ? this.state.originalname
              : values.originalname;
          values.key = this.state.key !== '' ? this.state.key : values.key;
          console.log({ values });

          client
            .service('documents')
            .create({
              ...values,
              companyId: this.props.commons.selectedCompany._id,
            })
            .then((res) => {
              this.props.updateMode('refresh');
              message.success('New Record added');
              this.setState({ loading: false });
            })
            .catch((err) => {
              this.props.updateMode('refresh');
              this.setState({ loading: false });
              alert(err);
            });
        }
      });
    } else {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          if (this.state.bucket) {
            values.bucket = this.state.bucket;
          }
          if (this.state.originalname) {
            values.originalname = this.state.originalname;
          }
          if (this.state.key) {
            values.key = this.state.key;
          }

          client
            .service('documents')
            .patch(this.props.documents.activeId, values)
            .then((res) => {
              message.success(`Updated`);
              this.props.updateMode('refresh');
              this.setState({ loading: false });
            })
            .catch((err) => {
              this.props.updateMode('refresh');
              this.setState({ loading: false });
            });
          return;
        }
      });
    }
  };

  //----------------------------------------------------------------------------
  componentWillMount() {
    client
      .authenticate()
      .then((res) => {
        return client.service('documents').find({
          query: {
            //orRegex:{description:'BANK'},
            // $sort: {description: 1}
          },
        });
      })
      .then((res) => {
        this.setState({
          documentList: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          documentList: [],
        });
      });
  }

  renderFormValid() {
    const { getFieldDecorator } = this.props.form;
    if (this.props.documents.mode === 'upload') {
      return (
        <FormItem {...formItemLayout} label='Upload File'>
          {getFieldDecorator('pdfUrl', {
            rules: [
              {
                required: true,
                message: 'Please input File Path!',
              },
            ],
          })(<Input type='file' onChange={this.handleFileUpload} />)}
        </FormItem>
      );
    } else {
      return (
        <FormItem {...formItemLayout} label='Upload File'>
          {getFieldDecorator('pdfUrl', {
            rules: [
              {
                required: false,
                message: 'Please input File Path!',
              },
            ],
          })(<Input type='file' onChange={this.handleFileUpload} />)}
        </FormItem>
      );
    }
  }

  //----------------------------------------------------------------------------
  render() {
    const { visible, onCancel } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { documentList } = this.state;
    const fileList = [
      'Unaudited Financial Report',
      'Agreement / Contracts',
      'Monthly Service Report',
      'Important Document',
      'Meeting Minutes',
      'AGM / EGM',
      'Fire Insurance',
      'Audited Report',
      'Announcement / Newsletter',
    ];

    return (
      <Modal
        visible={
          this.props.documents.mode === 'upload' ||
          this.props.documents.mode === 'edit'
            ? true
            : false
        }
        title={
          this.props.documents.mode === 'edit'
            ? 'Update Parameter'
            : 'Create Parameter'
        }
        okText={this.props.documents.mode === 'edit' ? 'Update' : 'Create'}
        onCancel={() => {
          this.props.updateActiveDocuments('', '');
          this.props.form.resetFields();
        }}
        onOk={this.handleSubmit}
      >
        <Spin spinning={this.state.loading}>
          <Form className='submit-form'>
            {this.renderFormValid()}

            {/*
        <FormItem
           {...formItemLayout}
           label="Upload File"
         >
           {getFieldDecorator('pdfUrl', {
             rules: [{
               required: true, message: 'Please input File Path!',
             }],
           })(
             <Input type='file' onChange={this.handleFileUpload} />
           )}
        </FormItem>
        */}

            <FormItem {...formItemLayout} label='Title'>
              {getFieldDecorator('title', {
                rules: [
                  {
                    required: true,
                    message: 'Please input Title!',
                  },
                ],
              })(<Input />)}
            </FormItem>

            <FormItem {...formItemLayout} label='File Name'>
              {getFieldDecorator('fileName', {
                rules: [
                  {
                    required: false,
                    message: 'Please input File Name!',
                  },
                ],
              })(<Input placeholder='File Name' disabled />)}
            </FormItem>

            <FormItem {...formItemLayout} label='File Category'>
              {getFieldDecorator('fileType', {
                rules: [
                  {
                    required: true,
                    message: 'Please input File Category!',
                  },
                ],
              })(
                <Select
                  id='fileType'
                  onChange={this.fileType}
                  placeholder='Select Type'
                >
                  {fileList.map((i) => {
                    return <Option value={i}>{i}</Option>;
                  })}
                </Select>
              )}
            </FormItem>

            <FormItem {...formItemLayout} label='Date'>
              {getFieldDecorator('date', {
                rules: [
                  {
                    required: true,
                    message: 'Please input Date!',
                  },
                ],
              })(<DatePicker />)}
            </FormItem>

            <FormItem {...formItemLayout} label='Role'>
              {getFieldDecorator('role', {
                rules: [
                  {
                    required: true,
                    message: 'Please input Role!',
                  },
                ],
              })(
                <Select
                  id='role'
                  onChange={this.role}
                  placeholder='Select Role'
                >
                  <Option value={0}>Management</Option>
                  <Option value={1}>Management & JMB Committee</Option>
                  <Option value={2}>All</Option>
                </Select>
              )}
            </FormItem>

            <FormItem {...formItemLayout} label='Remark'>
              {getFieldDecorator('remark', {
                rules: [{}],
              })(<TextArea rows={4} />)}
            </FormItem>
          </Form>
        </Spin>
      </Modal>
    );
  }
}

//----------------------------------------------------------------------------
function mapStateToProps(state) {
  return {
    documents: state.documents,
    commons: state.commons,
  };
}
//----------------------------------------------------------------------------
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateMode: updateMode,
      updateActiveDocuments: updateActiveDocuments,
    },
    dispatch
  );
}

//----------------------------------------------------------------------------

export default requireAuth(
  connect(mapStateToProps, mapDispatchToProps)(Form.create()(DocumentsForm))
);
