export const FETCH_PASSES = 'FETCH_PASSES';
export const PUSH_PASSES = 'PUSH_PASSES';
export const REMOVE_PASSES = 'REMOVE_PASSES';
export const UPDATE_PASSES = 'UPDATE_PASSES';
export const UPDATE_MODE = 'UPDATE_MODE';
export const UPDATE_ACTIVE_ID = 'UPDATE_ACTIVE_ID';

export function fetchPasses(data){
  return (dispatch) => {
    dispatch({
      type: FETCH_PASSES,
      payload:data
    });
  }
}

export function pushPasses(data){
  return (dispatch) => {
    dispatch({
      type: PUSH_PASSES,
      payload:data
    });
  }
}

export function removePasses(data){
  return (dispatch) => {
    dispatch({
      type: REMOVE_PASSES,
      payload:data
    });
  }
}

export function updatePasses(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_PASSES,
      payload:data
    });
  }
}

export function updateMode(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE,
      payload:data
    });
  }
}

export function updateActivePasses(mode, id){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_ID,
      mode,
      id
    });
  }
}
