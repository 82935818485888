import React, { Component, useEffect, useRef } from 'react';
import { Modal, Upload, Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Select, Card, Radio, Spin, Row, Col } from 'antd';
import client from '../../feathers';
import requireAuth from "../requireAuth";
import Layout from "../layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Route, Link } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import axios from 'axios';
import Info from "../common/info";
import moment from 'moment';


//--------------------------------------------------------------------
const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
const _ = require('lodash');
const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};

const tablebody = {
  padding: "4px 4px",
};

const formatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2
})

const todaydate = moment().format('DD-MMM-YYYY')
var balanceAmount = 0
var ledgerId = ''
var StatementTotal = [];

//-------------------------------------------------------------------------------
class ComponentToPrint extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      jmb: {},
      loading: false,
      invoiceAndItems: [],
      propertyunit: [],
      setting: [],
      CurrentPagination: 1,
      totalRecord: 0,
      balanceAmount: 0,
    }
  }

  componentWillMount() {

    this.setState({ loading: true });

    //get JMB INFO
    client.authenticate()
      .then((res) => {
        return client.service('jmbs').find({
          query: {
            $populate: 'propertyId',
            propertyId: this.props.companyId,
            status: 'ACTIVE',
            $sort: {
              createdAt: -1
            },
            $limit: 1
          }
        })
      })
      .then((res) => {
        this.setState({ jmb: res.data[0] });

        client.service('settings').find({
          query: {
            propertyId: this.props.companyId
          }
        })
          .then((res) => {
            this.setState({
              setting: res.data[0].tnc,
            });
          })

      })
      .then((res) => {

        return axios.get(`${client.io.io.uri}remindeLetterInqAll`, {
          params: {
            companyId: this.props.companyId,
            filterbyAmount: this.props.filterAmount ? this.props.filterAmount : 0
          },
          headers: {
            'Authorization': client.settings.storage.storage['feathers-jwt']
          }
        })
      })
      .then((res) => {
        if (res.data.result.length <= 0) {
          message.error("No record found.")
        }

        res.data.result = _.orderBy(res.data.result, ['referenceId'], ['asc'])

        let seqno = 0;
        res.data.result.forEach(function (item, index) {
          item.seqno = seqno + 1;
          seqno++;

        })

        this.setState({
          dataSource: res.data,
          loading: false
        });
      })
      .catch((err) => {
        console.log(err);
      });




  }

  //----------------------------------------------------------------------
  componentDidUpdate() {

  }

  //----------------------------------------------------------------------
  _renderTable = () => {

    const dataSource = this.state.dataSource;
    const loading = this.state.loading;
    const jmb = this.state.jmb;
    let table = [];
    var balanceAmount = 0;
    var ledgerId = '';

    const columns = [
      {
        title: 'Seq No.',
        dataIndex: 'seqno',
        key: 'seqno',
        width: '5%',
      },
      {
        title: 'Unit Number',
        dataIndex: 'referenceId',
        key: 'referenceId',
        width: '20%',
      },
      {
        title: 'Name',
        dataIndex: 'owner.name',
        key: 'owner.name',
        width: '20%',
        render: (text, record) => (
          <div>
            {text}
          </div>
        ),
      },
      {
        title: (<span style={{ float: 'right' }}>Total Due Amount</span>),
        dataIndex: 'totalDueAmt',
        key: 'totalDueAmt',
        width: '20%',
        render: (text, record) => (
          <span style={{ float: 'right' }}>
            {parseFloat(text).toFixed(2)}
          </span>
        ),
      },


    ];

    // Outer loop to create parent
    let recordCount = dataSource.result ? dataSource.result.length : 0

    if (recordCount > 0) {
      for (let i = 0; i < dataSource.result.length; i++) {

        { balanceAmount = 0; }

        table.push([
          <Card loading={loading}>
            <Row>
              <Col span={19}>
                <p style={{ margin: '5px' }}>
                  <span >{this.state.jmb ? this.state.jmb.headerLine1 : ''}</span> ({this.state.jmb ? this.state.jmb.registrationNo : ''})</p>
                <p style={{ margin: '5px' }}>
                  <span>{this.state.jmb ? this.state.jmb.headerLine2 : ''}</span>
                </p>
                <p style={{ margin: '5px' }}>
                  <span>{this.state.jmb ? this.state.jmb.headerLine3 : ''}</span>
                </p>
                <p style={{ margin: '5px' }}>
                  <span>{this.state.jmb ? this.state.jmb.headerLine4 : ''}</span>
                </p>
              </Col>
            </Row>

            <br />
            <Row gutter={8} >
              <Col span={24} align="middle">
                <h2 style={{ 'color': 'rgb(169,169,169)', 'letterSpacing': '10px' }}>REMINDER</h2>
              </Col>

            </Row>
            <Row gutter={24}>
              <Col span={4}><span>Generated on</span></Col>
              <Col span={1}><span>:</span></Col>
              <Col span={18}><span>{todaydate}</span></Col>
            </Row>

            <Row gutter={24}>
              <Col span={4}><span>Unit</span></Col>
              <Col span={1}><span>:</span></Col>
              <Col span={18}><span>{dataSource.result[i].referenceId}</span></Col>
            </Row>

            <Row gutter={24}>
              <Col span={4}><span>Name</span></Col>
              <Col span={1}><span>:</span></Col>
              <Col span={18}><span>{dataSource.result[i].owner ? dataSource.result[i].owner.name : ''}</span></Col>
            </Row>

            <Row gutter={24}>
              <Col span={4}><span>Mailing Address</span></Col>
              <Col span={1}><span>:</span></Col>
              <Col span={18}><span><TextArea readOnly style={{ fontWeight: 650, fontSize: '1em', resize: 'none', padding: 0, width: '300px', height: '10 0px', border: 'none' }} value={dataSource.result[i].owner ? dataSource.result[i].owner.mailingAddress : ''} rows={4} /></span></Col>
            </Row>

            <br />
            <br />
            <Row gutter={24}>
              <p>Dear Sir/Madam,</p>
              <br />
              <p className='borderlineBottom'>RE: Overdue Bills</p>
              <br />
              <p>We would like to inform you that as of {todaydate}, our records shows that your account is overdue by total of <span className='customBold'>RM {parseFloat(dataSource.result[i].totalDueAmt).toFixed(2)}</span> </p>
              <br />
              <p>We hope this is merely an oversight on your part and would appreciate if you can settle the abovementioned sum within <span className='customBold'>FOURTEEN (14)</span> days of this notice.</p>
              <br />
              <p>If full payment is not received by the end of the 14 days, legal and/or tribunal proceedings will commence without any further reference to you, and all costs incurred will be charged to you.</p>
              <br />
              <p>Please email us your transaction slip or bank-in slip if you have already made payment so that we can update your account.</p>
              <p>Thank you.</p>
              <br />
              <p className='italic'>This is a computer generated documents.</p>
              <p className='italic'>No signature is required.</p>
            </Row>

            <br />
            <Row>
              <p className='borderlineBottom'></p>
              <div className="reminderFooter">
                <div className="ql-display-container2">
                  <div className="ql-display-editor2" dangerouslySetInnerHTML={{ __html: this.state.setting ? this.state.setting.reminderTnc : '' }} />
                </div>
              </div>
            </Row>

          </Card>
          ,
          <div className='pageBreak' />
        ]);
        // } //for loop total page
      }
      table.push([
        <div>
          <Card>
            <Row>
              <Col span={19}>
                <p style={{ margin: '5px' }}>
                  <span >{this.state.jmb ? this.state.jmb.headerLine1 : ''}</span> ({this.state.jmb ? this.state.jmb.registrationNo : ''})</p>
                <p style={{ margin: '5px' }}>
                  <span>{this.state.jmb ? this.state.jmb.headerLine2 : ''}</span>
                </p>
                <p style={{ margin: '5px' }}>
                  <span>{this.state.jmb ? this.state.jmb.headerLine3 : ''}</span>
                </p>
                <p style={{ margin: '5px' }}>
                  <span>{this.state.jmb ? this.state.jmb.headerLine4 : ''}</span>
                </p>
              </Col>
            </Row>
            <br />
            <br />
            <Row gutter={8} >
              <Col span={24} align="middle">
                <h2 style={{ 'color': 'rgb(169,169,169)', 'letterSpacing': '10px' }}>LISTING for REMINDER LETTER GENERATED at {todaydate}</h2>
              </Col>

            </Row>
            <p className='borderlineBottom'></p>
            <br />
            <Table
              dataSource={dataSource.result}
              columns={columns}
              rowKey='_id'
              size={'small'}
              pagination={false}
            />
          </Card>
        </div>
      ])

      return table;
    }
  }

  render() {
    const loading = this.state.loading;
    return (
      <Spin spinning={loading}>
        {this._renderTable()}
      </Spin>
    );
  }
}

class IndexTrialBalance extends React.Component {

  constructor(props) {
    super(props);
  }

  componentWillMount() {

  }

  render() {
    return (
      <Layout>
        <ReactToPrint
          trigger={() =>
            <Row><Col style={{ float: 'right', marginBottom: '5px' }}>
              <Button type="primary">Print</Button></Col></Row>}
          content={() => this.componentRef}
        />


        <ComponentToPrint ref={el => (this.componentRef = el)}
          id={this.props.match.params.id}
          companyId={this.props.commons.selectedCompany._id}
          filterAmount={this.props.commons.filterAmount}
        />

      </Layout>
    );
  }
}


function mapStateToProps(state) {
  return { commons: state.commons }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    //updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(IndexTrialBalance)));
