import React, { Component, useEffect } from 'react'
import { Modal, Upload, Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Select, InputNumber, DatePicker, Radio, Row, Col } from 'antd'
import client from '../../feathers'
import requireAuth from "../../components/requireAuth"
import Layout from "../../components/layout"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateMode } from '../../actions/actions-waterbills'
import axios from 'axios';
import params from '../../params/system'
import moment from "moment";

var FormData = require('form-data');
const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};

const formItemStyle = {
  style: { width: '300px' }
};

const text = 'Are you sure to delete this item?';

const _ = require('lodash');
//----------------------------------------------------------------------------
class WaterBillsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',
      currentNum: 0,
      previousNum: 0,
      usageNum: 0,
      propertyunits: [],
      waterCharge: [],
      mode: '',
    }

  }

  componentDidUpdate() {
    if (this.props.waterbills.activeId) {
      if (this.props.waterbills.activeId !== this.state._id) {
        this.setState({
          _id: this.props.waterbills.activeId
        })

        if (this.props.waterbills.activeWaterBills) {
          this.props.waterbills.activeWaterBills.readDate = moment(new Date(this.props.waterbills.activeWaterBills.readDate))
          this.props.form.resetFields()
          this.props.form.setFieldsValue(this.props.waterbills.activeWaterBills)
        }
      }
    }

    if (this.props.form.getFieldValue('currentNum') !== this.state.currentNum) {
      let usageNum = parseFloat((this.props.form.getFieldValue('currentNum') - this.state.previousNum))
      let billAmount = parseFloat((this.props.form.getFieldValue('currentNum') - this.state.previousNum) * this.state.waterCharge.amount)
      this.setState({ usageNum: parseFloat(usageNum).toFixed(2), billAmount: parseFloat(billAmount).toFixed(2), currentNum: this.props.form.getFieldValue('currentNum') })
      this.props.form.setFieldsValue({ usageNum: parseFloat(usageNum).toFixed(2), billAmount: parseFloat(billAmount).toFixed(2) })

    }

    if (this.props.form.getFieldValue('previousNum') !== this.state.previousNum) {
      let usageNum = parseFloat((this.state.currentNum - this.props.form.getFieldValue('previousNum')))
      let billAmount = parseFloat((this.state.currentNum - this.props.form.getFieldValue('previousNum')) * this.state.waterCharge.amount)
      this.setState({ usageNum: parseFloat(usageNum).toFixed(2), billAmount: parseFloat(billAmount).toFixed(2), previousNum: this.props.form.getFieldValue('previousNum') })
      this.props.form.setFieldsValue({ usageNum: parseFloat(usageNum).toFixed(2), billAmount: parseFloat(billAmount).toFixed(2) })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.props.waterbills.mode === 'new') {

      this.props.form.validateFields((err, values) => {

        if (values.previousNum) {
          values.usagesNum = parseFloat(values.currentNum - values.previousNum)
        }
        values.readDate = new Date(values.readDate)
        let companyId = this.props.commons.selectedCompany._id

        if (!err) {
          client.service('waterbills').create({
            ...values,
            chargeId: this.state.waterCharge._id,
            companyId: companyId,
          })
            .then((res) => {
              this.props.updateMode('refresh')
              this.setState({ mode: 'refresh' });
              message.success('New Record added');
            })
            .catch((err) => {
              this.props.updateMode('')
              this.setState({ mode: '' });
              alert(err);
            })
        }
      });
    } else {
      this.props.form.validateFields((err, values) => {
        if (!err) {

          client.service('waterbills').patch(this.props.waterbills.activeId, values)
            .then((res) => {
              message.success(`Updated`);
              this.props.updateMode('refresh')
              this.setState({ mode: 'refresh' });
            })
            .catch((err) => {
              this.props.updateMode('')
              this.setState({ mode: '' })
            })
          return;
        }
      });
    }
  }


  componentWillMount() {
    let companyId = this.props.commons.selectedCompany._id

    client.authenticate()
      .then((res) => {
        return client.service('propertyunits').find({
          query: {
            propertyId: companyId,
            $sort: {
              referenceId: 1
            }
          }
        })
      })
      .then((res) => {
        this.setState({
          propertyunits: res.data
        });

        return client.service('charges').find({
          query: {
            propertyId: companyId,
            code: 'WATERBILL'
          }
        })
      })
      .then((res) => {

        this.setState({
          waterCharge: res.data[0] ? res.data[0] : ''
        });
      })
  }

  handleChangePropertyunit = (e) => {

    var selectedProperty = _.find(this.state.propertyunits, function (o) { return o._id == e; });

    if (selectedProperty.owner) {

      let ownerName = selectedProperty.owner.name ? selectedProperty.owner.name : ''
      let ownerContact = selectedProperty.owner.contactNumber ? selectedProperty.owner.contactNumber : ''
      this.props.form.setFieldsValue(
        {
          ownerName: ownerName,
          ownerContact: ownerContact
        }
      )
    } else {
      this.props.form.setFieldsValue(
        {
          ownerName: '',
          ownerContact: ''
        }
      )
    }
  }

  renderPropertyUnitField() {

    const { getFieldDecorator } = this.props.form;
    if (this.props.waterbills.mode === 'edit') {

      let propertyInfo = this.props.waterbills.activeWaterBills.propertyunitId

      return ([

        <FormItem key='propertyunitdisplay' {...formItemLayout} label="Property Unit">
          <Input disabled value={propertyInfo.referenceId} />
        </FormItem>,
        <FormItem key='propertyunitdisplay1' {...formItemLayout} label="Owner">
          <Input disabled value={propertyInfo.owner ? propertyInfo.owner.name : ''} />
        </FormItem>,
        <FormItem key='propertyunitdisplay2' {...formItemLayout} label="Contact No.">
          <Input disabled value={propertyInfo.owner ? propertyInfo.owner.contactNumber : ''} />
        </FormItem>

      ])
    }
    else {

      if (this.props.waterbills.mode !== this.state.mode) {
        this.props.form.resetFields()
        this.state.mode = this.props.waterbills.mode
        this.setState({
          mode: this.props.waterbills.mode,
          usageNum: 0,
          billAmount: 0,
        });

      }
      return ([
        <FormItem key='propertyunitId'
          {...formItemLayout}
          label="Property Unit"
        >
          {getFieldDecorator('propertyunitId', {
            rules: [{
              required: true, message: 'Please Input Property Unit!',
            }],
          })(
            <Select
              {...formItemStyle}
              onChange={this.handleChangePropertyunit}
              showSearch
              placeholder="Select a unit"
              optionFilterProp="children"
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              values=''
            >
              {this.state.propertyunits.map(dropdown => (
                <Option key={dropdown._id} val={dropdown._id}>
                  {dropdown.referenceId}
                </Option>
              ))}
            </Select>

          )}
        </FormItem>,
        <FormItem key='ownerName'
          {...formItemLayout}
          label="Owner Name"
        >
          {getFieldDecorator('ownerName', {
            rules: [{
              required: false, message: 'Please Input ownerName!',
            }],
          })(
            <Input disabled />
          )}
        </FormItem>,

        <FormItem key='ownerContact'
          {...formItemLayout}
          label="Owner Contact"
        >
          {getFieldDecorator('ownerContact', {
            rules: [{
              required: false, message: 'Please Input ownerContact!',
            }],
          })(
            <Input disabled />
          )}
        </FormItem>
      ])
    }

  }

  render() {
    const { visible, onCancel } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { usageNum, mode } = this.state;

    return (
      <Modal
        visible={this.props.waterbills.mode === 'new' || this.props.waterbills.mode === 'edit' ? true : false}
        title={this.props.waterbills.mode === 'edit' ? 'Update Reading' : 'Create Reading'}
        okText={this.props.waterbills.mode === 'edit' ? 'Update' : 'Create'}
        onCancel={() => {
          this.props.updateMode('')
          this.setState({ mode: '', })
          this.props.form.resetFields()
        }}
        onOk={this.handleSubmit}
      >
        <Form className="submit-form">
          {this.renderPropertyUnitField()}

          <FormItem {...formItemLayout} label="Reading Date">
            {getFieldDecorator('readDate', {
              rules: [{ required: true, message: 'Please input Date!', }],
            })(
              <DatePicker />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Current Reading">
            {getFieldDecorator('currentNum', {
              rules: [{ required: true, message: 'Please input Current!', }],
              initialValue: 0,
            })(
              <InputNumber value={this.state.currentNum} />
            )}
          </FormItem>

          <Row gutter={8}>
            <Col offset={8}>
              <span style={{ 'fontSize': '0.75em', 'fontStyle': 'italic' }}>If below leave blank, system will auto derive from previous record</span>
            </Col>
          </Row>

          <FormItem {...formItemLayout} label="Previous Reading">
            {getFieldDecorator('previousNum', {
              rules: [{ required: false, message: 'Please input Previous!', }],
              initialValue: 0,
            })(
              <InputNumber value={this.state.previousNum} />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Usage">
            {getFieldDecorator('usageNum', {
              rules: [{ required: false, message: 'Please input Usage!', }],
            })(
              <Input value={this.state.usageNum} disabled />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Bill Amount">
            {getFieldDecorator('billAmount', {
              rules: [{ required: false, message: 'Please input Usage!', }],
            })(
              <Input value={this.state.billAmount} disabled />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Remark">
            {getFieldDecorator('notes', {
              rules: [{ required: false, message: 'Please input Notes!', }],
            })(
              <TextArea rows={4} />
            )}
          </FormItem>
        </Form>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    waterbills: state.waterbills,
    commons: state.commons
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(WaterBillsForm)));
