
import React, { Component } from 'react'
import { Modal, message, Form, Icon, Input, Select } from 'antd'
import client from '../../feathers'
import requireAuth from "../../components/requireAuth"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateMode } from '../../actions/actions-userproperties'

const FormItem = Form.Item;
const Option = Select.Option;


const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

class UserpropertiesForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',
      confirmDirty: false,
      autoCompleteResult: [],
    }
  }
  componentDidUpdate() {
    if (this.props.userproperties.activeId !== this.state._id) {
      this.setState({
        _id: this.props.userproperties.activeId
      })

      if (this.props.userproperties.activeUserproperties) {
        this.props.form.setFieldsValue(
          this.props.userproperties.activeUserproperties
        )
      }
    }

  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }


  handleSubmit = (e) => {
    e.preventDefault();
    if (this.props.userproperties.mode === 'new') {

      this.props.form.validateFields((err, fieldsValue) => {
        if (!err) {

          const values = {
            ...fieldsValue,
            'referenceId': this.props.referenceId,
          };

          client.service('userproperties').create(values)
            .then((res) => {
              this.props.updateMode('')
              message.success('New Assignment Added');
            })
            .catch((err) => {
              this.props.updateMode('')
              alert(err);
            })
        }
      });
    } else {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          client.service('userproperties').update(this.props.userproperties.activeId,
            values
          )
            .then((res) => {
              message.success(`Assignment Updated`);
              this.props.updateMode('')
            })
            .catch((err) => {

              this.props.updateMode('')
            })
          return;
        }

      });
    }
  }



  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  }

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  }

  handleBlurProperty = (e) => {

    client.service('propertyunits').find({
      query: {
        referenceId: e.target.value,
        $sort: {
          createdAt: -1
        }
      }
    })
      .then((res) => {
        if (res.total > 0) {
          message.success('Record Found');
          this.props.form.setFieldsValue({
            propertyId: res.data[0].propertyId
          })
        } else {
          message.error('Not Found');
        }
      })
      .catch((err) => {
        console.log(err);
        message.error(err);
      })
  }

  handleChange = (e) => {

    client.service('users').find({
      query: {
        email: e.target.value,
        $sort: {
          createdAt: -1
        }
      }
    })
      .then((res) => {
        console.log({ res })
        if (res.total > 0) {
          message.success('User Found');
          this.props.form.setFieldsValue({
            userId: res.data[0]._id
          })
        } else {
          message.error('User not Found');
        }

      })
      .catch((err) => {

        message.error(err);
      })
  }

  render() {

    const { getFieldDecorator } = this.props.form;

    return (
      <Modal
        visible={
          this.props.userproperties.mode === 'new' || this.props.userproperties.mode === 'edit' ? true : false
        }
        title={this.props.userproperties.mode === 'edit' ? 'Update User Account' : 'Create User Account'}
        okText={this.props.userproperties.mode === 'edit' ? 'Update' : 'Create'}
        onCancel={() => { this.props.updateMode(''); this.props.form.resetFields() }}
        onOk={this.handleSubmit}

      >
        <Form className="submit-form">

          <FormItem
            {...formItemLayout}
            label="User Email"
          >
            {getFieldDecorator('email', {
              rules: [{
                required: true, message: 'Please input Email Address!',
              }],
            })(
              <Input onBlur={this.handleChange} prefix={<Icon type="idcard" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="SUPERHERO@gmail.com" />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="User"
          >
            {getFieldDecorator('userId', {
              rules: [{
                required: true, message: 'Please input Userproperties Name!',
              }],
            })(
              <Input prefix={<Icon type="idcard" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="SUPERHERO" />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Type"
            hasFeedback
          >
            {getFieldDecorator('type', {
              rules: [
                { required: true, message: 'Please select Type!' },
              ],
            })(
              <Select placeholder="Please select type">
                <Option value="primary">Primary</Option>
                <Option value="secondary">Secondary</Option>
              </Select>
            )}
          </FormItem>


        </Form>
      </Modal>

    )
  }
}


function mapStateToProps(state) {
  return {
    userproperties: state.userproperties
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(UserpropertiesForm)));
