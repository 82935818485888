import _ from 'lodash';
import {
  FETCH_SUPPLIERS,
  PUSH_SUPPLIERS,
  REMOVE_SUPPLIERS,
  UPDATE_SUPPLIERS,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID
} from '../actions/actions-suppliers';

const INITIAL_STATE = {
  suppliers: [],
  mode: 'index',
  activeId: '',
  activeSuppliers: {}
}


export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_SUPPLIERS:
      return {
        ...state,
        suppliers: action.payload,
      }
    case PUSH_SUPPLIERS:
      return {
        ...state,
        suppliers: [action.payload, ...state.suppliers],
      }
    case REMOVE_SUPPLIERS:
      return {
        ...state,
        suppliers: [...state.suppliers.filter((item) => item._id !== action.payload._id)]
      }
    case UPDATE_SUPPLIERS:
      let index = _.findIndex(state.suppliers, { '_id': action.payload._id })
      state.suppliers.splice(index, 1, action.payload)
      return {
        ...state,
        suppliers: state.suppliers
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode: action.payload,
        activeId: ''
      }
    case UPDATE_ACTIVE_ID:
      return {
        ...state,
        mode: action.mode,
        activeId: action.id,
        activeSuppliers: _.pick(_.find(state.suppliers, { '_id': action.id }), ['name', 'email', 'contactNo', 'country', 'addressA', 'addressB', 'state', 'supplierCode', 'postCode', 'remark', '_id', 'ssmNo'])
      }
    default:
      return state

  }
}
