import _ from 'lodash';
import {
  FETCH_FACILITIES,
  PUSH_FACILITIES,
  REMOVE_FACILITIES,
  UPDATE_FACILITIES,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID
} from '../actions/actions-facilities';

const INITIAL_STATE = {
  facilities: [],
  mode: 'index',
  activeId: '',
  activeFacilities: {}
}


export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_FACILITIES:
      return {
        ...state,
        facilities: action.payload,
      }
    case PUSH_FACILITIES:
      return {
        ...state,
        facilities: [action.payload, ...state.facilities],
      }
    case REMOVE_FACILITIES:
      return {
        ...state,
        facilities: [...state.facilities.filter((item) => item._id !== action.payload._id)]
      }
    case UPDATE_FACILITIES:
      let index = _.findIndex(state.facilities, { '_id': action.payload._id })
      state.facilities.splice(index, 1, action.payload)
      return {
        ...state,
        facilities: state.facilities
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode: action.payload,
        activeId: ''
      }
    case UPDATE_ACTIVE_ID:
      return {
        ...state,
        mode: action.mode,
        activeId: action.id,
        activeFacilities: _.pick(_.find(state.facilities, { '_id': action.id }), ['name', 'icon', 'description', 'unit', 'categories', 'amount', 'minutePerSession', 'startTime', 'maxSessionPerday', '_id', 'status'])
      }
    default:
      return state

  }
}
