import React, { Component, useEffect } from 'react'
import { Modal, Upload, Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Select, InputNumber, DatePicker, Radio, Row, Col, Spin } from 'antd'
import client from '../../feathers'
import requireAuth from "../../components/requireAuth"
import Layout from "../../components/layout"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateMode } from '../../actions/actions-uploads'
import axios from 'axios';
import params from '../../params/system'
import moment from "moment";

var FormData = require('form-data');
const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};

const formItemStyle = {
  style: { width: '300px' }
};

const text = 'Are you sure to delete this item?';

const _ = require('lodash');
//----------------------------------------------------------------------------
class UploadsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',
      currentNum: 0,
      previousNum: 0,
      usageNum: 0,
      propertyunits: [],
      waterCharge: [],
      mode: '',
      bucket: '',
      key: '',
      loading: false,
    }

  }
  //----------------------------------------------------------------------------
  componentDidUpdate() {
    if (this.props.uploads.activeId !== this.state._id) {
      this.setState({
        _id: this.props.uploads.activeId
      })

      if (this.props.uploads.activeUploads) {

        this.props.uploads.activeUploads.readDate = moment(new Date(this.props.uploads.activeUploads.readDate))
        this.props.form.resetFields()
        this.props.form.setFieldsValue(this.props.uploads.activeUploads)
      }

    }

  }

  //----------------------------------------------------------------------------
  handleSubmit = (e) => {
    e.preventDefault();

    if (this.props.uploads.mode === 'new') {

      if (this.state.bucket === '') {
        message.error('Uploading File')
      } else {
        this.props.form.validateFields((err, values) => {

          values.uploadDate = new Date()
          values.processStatus = "Uploaded"
          values.bucket = this.state.bucket
          values.key = this.state.key

          if (!err) {
            client.service('uploads').create({
              ...values,
              companyId: this.props.commons.selectedCompany._id,
            })
              .then((res) => {

                this.props.updateMode('refresh')
                this.setState({
                  mode: 'refresh',
                });
                message.success('New Record added');
                this.props.form.resetFields()
              })
              .catch((err) => {
                this.props.updateMode('')
                this.setState({
                  mode: '',
                });
                alert(err);
              })
          }
        });
      }
    }
  }

  //----------------------------------------------------------------------------
  _handleConfirmUpload() {
    let formData = new FormData();

    formData.append('image', this.state.file);

    axios.post(`${client.io.io.uri}uploadExcelFiles`,
      formData
      , {
        headers: {
          'Authorization': client.settings.storage.storage['feathers-jwt'],
          'Content-Type': 'multipart/form-data',
        }
      }
    ).then((res) => {

      this.props.form.setFieldsValue({
        file: res.data.imageUrl
      })

      message.success('Upload Successful');
      this.props.updateMode('')
      this.setState({ submitting: false })
    })
      .catch((err) => {
        this.setState({
          showUploadDone: false,
          submitting: false
        })
        console.log(err);
        message.error(err);
      })

  }
  //----------------------------------------------------------------------------
  handleFileUpload = (event) => {
    this.setState({ loading: true });
    let formData = new FormData();

    formData.append('avatar', event.target.files[0]);

    axios.post(`${client.io.io.uri}uploadExcelFiles`,
      formData
      , {
        headers: {
          'Authorization': client.settings.storage.storage['feathers-jwt'],
          'Content-Type': 'multipart/form-data',
        }
      }
    )
      .then((res) => {
        // console.log('handleFileUpload')
        // console.log({res});

        this.props.form.setFieldsValue({
          documentPath: res.data.documentPath
        });
        this.setState({
          bucket: res.data.bucket,
          key: res.data.key
        });
        //return client.service('uploads').patch(this.props.uploads.activeId,{documentPath:res.data.imageUrl})

        this.setState({ loading: false });
        message.success('Upload Successful');
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      })

  }
  //-------)}---------------------------------------------------------------------
  componentWillMount() { }
  //----------------------------------------------------------------------------
  render() {
    const { visible, onCancel } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { usageNum, mode } = this.state;

    return (
      <Spin spinning={this.state.loading}>
        <Modal
          visible={
            this.props.uploads.mode === 'new' || this.props.uploads.mode === 'edit' ? true : false
          }
          title={this.props.uploads.mode === 'edit' ? 'Update Reading' : 'Create Uploads'}
          okText={this.props.uploads.mode === 'edit' ? 'Update' : 'Create'}
          onCancel={() => { this.props.updateMode(''); this.setState({ mode: '', }); this.props.form.resetFields() }}
          onOk={this.handleSubmit}
        >
          <Form className="submit-form">

            <FormItem {...formItemLayout} label="Function" >
              {getFieldDecorator('function', {
                rules: [{
                  required: false, message: 'Please input Function!',
                }],
              })(


                <Select
                  id='function'
                  placeholder="Select Type">
                  <Option value="NonDebtorOpenBal">Non Debtor Opening Balance</Option>
                  <Option value="debtorOpenBal">Debtor Opening Balance</Option>
                </Select>
              )}
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Upload File"
            >
              {getFieldDecorator('excelPath', {
                rules: [{
                  required: true, message: 'Please input File Path!',
                }],
              })(
                <Input type='file' onChange={this.handleFileUpload} />
              )}
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="File Name"
            >
              {getFieldDecorator('documentPath', {
                rules: [{
                  required: false, message: 'Please input File Name!',
                }],
              })(
                <Input placeholder="File Name" disabled />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="Remark">
              {getFieldDecorator('remark', {
                rules: [{ required: false, message: 'Please input remark!', }],
              })(
                <TextArea rows={4} />
              )}
            </FormItem>


          </Form>

        </Modal>
      </Spin>
    )
  }
}

//----------------------------------------------------------------------------
function mapStateToProps(state) {
  return {
    uploads: state.uploads,
    commons: state.commons
  }
}
//----------------------------------------------------------------------------
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
  }, dispatch);
}

//----------------------------------------------------------------------------

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(UploadsForm)));
