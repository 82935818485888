import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Row,
  Spin,
  Table,
  DatePicker,
  Input,
  Popconfirm,
} from 'antd';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  fetchBankRecons,
  pushBankRecons,
  removeBankRecons,
  updateActiveBankRecons,
  updateBankRecons,
  updateModeBankRecons,
} from '../../actions/actions-bankrecons';
import { updateActivePage } from '../../actions/app-actions';
import Layout from '../../components/layout';
import requireAuth from '../../components/requireAuth';
import client from '../../feathers';
import { formatMoney } from '../../functionContent/index';

var showHide = false;
const textHere =
  'This transaction has been ticked and reconciled. Confirm to untick?';
const textHere2 =
  'There are some transactions has been ticked and reconciled. Confirm to untick?';

class BankReconsIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      monthlystatementsId: '',
      loading: false,
      loading2: false,
      wksysbalance: 0,
      wkbankbalance: 0,
      totalPagination: 1,
      totalPagination2: 1,
      CurrentPagination: 1,
      visibleBankRecon: false,
      systemBalance: 0,
      dateFilter: '',
      ref: '',
      desc: '',
      notLatestDate: false,
      doubleCheck: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.bankrecons.mode === 'refresh') {
      this.componentWillMount();
    }
  }
  componentDidMount() {}

  componentWillMount() {
    this.getData(0);
    this.checkDate();
  }

  checkDate = () => {
    let ledgerAccountId = this.props.bankrecons.selectedBank;
    let thisMonth = this.props.bankrecons.selectedMonth;
    let thisMonthEnd = moment(thisMonth).endOf('month');

    client
      .service('bankrecons')
      .find({
        query: {
          companyId: this.props.commons.selectedCompany._id,
          // $limit: 20,
          // $skip: (this.CurrentPagination - 1) * 20,
          $sort: {
            reconMonth: -1,
          },
        },
      })
      .then((res) => {
        let data = res.data;
        if (data) {
          if (moment(data[0].reconMonth) > thisMonthEnd) {
            this.setState({ notLatestDate: true });
          } else {
            this.setState({ notLatestDate: false });
          }
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  getData = (skip) => {
    if (this.props.bankrecons.mode === 'view') {
      showHide = true;
    }
    let thisMonth = this.props.bankrecons.selectedMonth;
    let thisMonthStart = moment(thisMonth).startOf('month');
    let thisMonthEnd = moment(thisMonth).endOf('month');
    let ledgerAccountId = this.props.bankrecons.selectedBank;
    let showInBankRecon =
      showHide === true ? '' : { showInBankRecon: showHide };
    let { ref, desc, dateFilter } = this.state;
    let orRegexFilter = [],
      queryDate = '';
    if (!_.isEmpty(dateFilter)) {
      queryDate = {
        trxDate: {
          $gte: new Date(moment(dateFilter).startOf('day')),
          $lte: new Date(moment(dateFilter).endOf('day')),
        },
      };
    } else {
      queryDate = {
        trxDate: {
          $lte: thisMonthEnd,
          // $gte:thisMonthStart
        },
      };
    }

    if (!_.isEmpty(ref)) {
      orRegexFilter.push({ referenceId: ref });
    }
    if (!_.isEmpty(desc)) {
      orRegexFilter.push({ description: desc });
    }
    if (!_.isEmpty(orRegexFilter)) {
      orRegexFilter = { orRegexOr: orRegexFilter };
    }

    this.setState({ loading: true, loading2: true });

    client.authenticate().then((res) => {
      //  get Account Balance()

      axios
        .get(`${client.io.io.uri}getAccountBalanceByDate`, {
          params: {
            companyId: this.props.commons.selectedCompany._id,
            ledgerAccountId: ledgerAccountId,
            trxDateStart: new Date(thisMonthStart),
            trxDateTo: new Date(thisMonthEnd),
          },
          headers: {
            Authorization: client.settings.accessToken,
          },
        })
        .then((res) => {
          if (res.data) {
            if (_.isEmpty(res.data) === false) {
              let currentBalance = res.data[0].currentBalance;
              this.setState({ systemBalance: currentBalance });
            } else {
              this.setState({ systemBalance: 0 });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });

      client
        .service('ledger-transactions')
        .find({
          query: {
            companyId: this.props.commons.selectedCompany._id,
            ledgerAccountId: ledgerAccountId,
            $populate: 'ledgerAccountId transactionId',
            ...showInBankRecon,
            ...orRegexFilter,
            ...queryDate,
            eventCode: { $ne: 'POS-SALES' },
            $limit: 20,
            $skip: (this.props.activePage.bankReconPage - 1) * 20,
            $sort: {
              ledgerAccountId: 1,
              trxDate: -1,
            },
          },
        })
        .then((res) => {
          let data = res.data;

          if (data) {
            let filterData = data.filter(
              (i) =>
                (moment(i.trxDate) <= thisMonthStart && !i.showInBankRecon) ||
                (moment(i.trxDate) <= thisMonthEnd &&
                  moment(i.trxDate) >= thisMonthStart)
            );
            let check = filterData.filter((i) => i.showInBankRecon);
            if (check.length > 0) {
              this.setState({ doubleCheck: true });
            } else {
              this.setState({ doubleCheck: false });
            }
            this.props.fetchBankRecons(filterData);
            this.setState({
              totalPagination: res.total,
              loading: false,
            });
          }
        });
    });
  };
  searchInputFun = (e, type) => {
    let searchType = type;

    this.setState({ [searchType]: e.target.value }, () => {
      this.getData(0);
    });
  };

  searchDate = (e) => {
    this.setState({ dateFilter: e }, () => {
      this.getData(0);
    });
  };

  // ---------------------------------------------------------------
  handlePageChange = (page) => {
    this.setState({ CurrentPagination: page.current });

    // this.props.updateModeBankRecons('');

    client.authenticate().then((res) => {
      // let thisMonth = this.props.bankrecons.selectedMonth.substring(0, 4) + '-' + this.props.bankrecons.selectedMonth.substring(4, 6)
      let thisMonth = this.props.bankrecons.selectedMonth;
      let thisMonthStart = moment(thisMonth).startOf('month');
      let thisMonthEnd = moment(thisMonth).endOf('month');

      // eslint-disable-next-line
      let yearmonthFrom = thisMonthStart;
      let yearmonthTo = thisMonthEnd;
      let ledgerAccountId = this.props.bankrecons.selectedBank;
      // let wksysbalance = res.data[0].balance ? res.data[0].balance : 0
      // let wkbankbalance = res.data[0].bankStatementBalance ? res.data[0].bankStatementBalance : 0
      // let wksysbalance = 0
      // let wkbankbalance = 0

      let showInBankRecon =
        showHide === true ? '' : { showInBankRecon: showHide };

      return client
        .service('ledger-transactions')
        .find({
          query: {
            companyId: this.props.commons.selectedCompany._id,
            ledgerAccountId: ledgerAccountId,
            $populate: 'ledgerAccountId transactionId',
            ...showInBankRecon,
            eventCode: { $ne: 'POS-SALES' },
            trxDate: {
              // $gte: yearmonthFrom,
              $lte: yearmonthTo,
            },
            $limit: 20,
            $skip: (page.current - 1) * 20,
            $sort: {
              ledgerAccountId: 1,
              trxDate: -1,
            },
          },
        })
        .then((res) => {
          let data = res.data;
          if (data) {
            let filterData = data.filter(
              (i) =>
                (moment(i.trxDate) <= thisMonthStart && !i.showInBankRecon) ||
                (moment(i.trxDate) <= thisMonthEnd &&
                  moment(i.trxDate) >= thisMonthStart)
            );
            let check = filterData.filter((i) => i.showInBankRecon);
            if (check.length > 0) {
              this.setState({ doubleCheck: true });
            } else {
              this.setState({ doubleCheck: false });
            }
            this.props.fetchBankRecons(filterData);
            // this.setState({ monthlystatementsId: this.props.match.params.id, wksysbalance: wksysbalance, wkbankbalance: wkbankbalance, totalPagination: res.total, loading: false });
            this.setState({ loading: false });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  // ---------------------------------------------------------------

  handleHideinRecon(e, _id) {
    if (!showHide) {
      this.setState({ loading: true });
    }
    axios
      .post(`${client.io.io.uri}bankReconProcess`, {
        params: {
          patchId: _id,
          showInBankRecon: e === false ? false : e.target.checked,
          bankreconId: this.props.bankrecons.activeId,
          selectedMonth: this.props.bankrecons.selectedMonth,
        },
        headers: {
          Authorization: client.settings.accessToken,
        },
      })

      .then((res) => {
        if (!showHide) {
          this.props.fetchBankRecons('');
          this.setState({ loading: false });
          this.componentWillMount();
        } else {
          // this.setState({ loading: false });
          const data = this.props.bankrecons.bankrecons.map((i) => {
            if (i._id === _id) {
              i.showInBankRecon = !i.showInBankRecon;
            }
            return i;
          });
          this.props.fetchBankRecons(data);
          let check = data.filter((i) => i.showInBankRecon);
          if (check.length > 0) {
            this.setState({ doubleCheck: true });
          } else {
            this.setState({ doubleCheck: false });
          }
        }
      })
      .catch((err) => {
        alert(err);
        this.setState({ loading: false });
      });
  }

  hideAllRecon = () => {
    for (let r = 0; r < this.props.bankrecons.bankrecons.length; r++) {
      axios
        .post(`${client.io.io.uri}bankReconProcess`, {
          params: {
            patchId: this.props.bankrecons.bankrecons[r]._id,
            showInBankRecon: showHide
              ? !this.props.bankrecons.bankrecons[r].showInBankRecon
              : true,
            bankreconId: this.props.bankrecons.activeId,
            selectedMonth: this.props.bankrecons.selectedMonth,
          },
          headers: {
            Authorization: client.settings.accessToken,
          },
        })

        .then((res) => {
          if (!showHide) {
            this.props.fetchBankRecons('');
            this.setState({ loading: false });
            this.componentWillMount();
          } else {
            // this.setState({ loading: false });
            const data = this.props.bankrecons.bankrecons.map((i) => {
              if (i._id === this.props.bankrecons.bankrecons[r]._id) {
                i.showInBankRecon = !i.showInBankRecon;
              }
              return i;
            });
            this.props.fetchBankRecons(data);
            let check = data.filter((i) => i.showInBankRecon);
            if (check.length > 0) {
              this.setState({ doubleCheck: true });
            } else {
              this.setState({ doubleCheck: false });
            }
          }
        })
        .catch((err) => {
          alert(err);
          this.setState({ loading: false });
        });
    }
  };

  showModal = () => {
    this.props.updateModeBankRecons('');

    this.props.updateActivePage({
      bankReconPage: this.state.CurrentPagination,
    });
  };

  renderShowAll = () => {
    if (showHide === false) {
      return (
        <Button size='small' type='primary' onClick={this.showAllRecord}>
          Show All Record
        </Button>
      );
    }
    if (showHide === true) {
      return (
        <Button size='small' type='primary' onClick={this.showAllRecord}>
          Hide Tick Record
        </Button>
      );
    }
  };

  showAllRecord = () => {
    switch (showHide) {
      case true:
        showHide = false;
        break;
      case false:
        showHide = true;
        break;
      default:
        showHide = true;
    }
    this.setState({ loading: true, CurrentPagination: 1 });

    let showInBankRecon =
      showHide === true ? '' : { showInBankRecon: showHide };

    client.authenticate().then((res) => {
      // let thisMonth = this.props.bankrecons.selectedMonth.substring(0, 4) + '-' + this.props.bankrecons.selectedMonth.substring(4, 6)
      let thisMonth = this.props.bankrecons.selectedMonth;
      // eslint-disable-next-line
      let thisMonthStart = moment(thisMonth).startOf('month');
      // eslint-disable-next-line
      let thisMonthEnd = moment(thisMonth).endOf('month');
      let ledgerAccountId = this.props.bankrecons.selectedBank;

      return client
        .service('ledger-transactions')
        .find({
          query: {
            companyId: this.props.commons.selectedCompany._id,
            ledgerAccountId: ledgerAccountId,
            $populate: 'ledgerAccountId',
            ...showInBankRecon,
            trxDate: {
              // $gte: thisMonthStart,
              $lte: thisMonthEnd,
            },
            $limit: 20,
            $skip: (this.state.CurrentPagination - 1) * 20,
            $sort: {
              ledgerAccountId: 1,
              trxDate: -1,
            },
          },
        })
        .then((res) => {
          let data = res.data;
          if (data) {
            let filterData = data.filter(
              (i) =>
                (moment(i.trxDate) <= thisMonthStart && !i.showInBankRecon) ||
                (moment(i.trxDate) <= thisMonthEnd &&
                  moment(i.trxDate) >= thisMonthStart)
            );
            this.props.fetchBankRecons(filterData);
            let check = filterData.filter((i) => i.showInBankRecon);
            if (check.length > 0) {
              this.setState({ doubleCheck: true });
            } else {
              this.setState({ doubleCheck: false });
            }
            this.setState({
              totalPagination: res.total,
              monthlystatementsId: this.props.match.params.id,
              loading: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });
    });
  };
  //---------------------------------------------------------------------------------------
  render() {
    let closingBalance = this.props.bankrecons.closingBalance
      ? this.props.bankrecons.closingBalance
      : 0;
    let systemBalance = this.state.systemBalance ? this.state.systemBalance : 0;
    const columns = [
      {
        title: (
          <div>
            <Row>
              <Col span={24}>Date</Col>
            </Row>
            <Row>
              <Col span={24}>
                <DatePicker allowEmpty type='Date' onChange={this.searchDate} />
              </Col>
            </Row>
          </div>
        ),
        dataIndex: 'trxDate',
        key: 'trxDate',
        width: '10%',
        render: (text, record) => (
          <span>{moment(text).format('DD-MM-YYYY')}</span>
        ),
      },
      {
        title: (
          <div>
            <Row>
              <Col span={24}>Reference</Col>
            </Row>
            <Row>
              <Col span={24}>
                <Input
                  onChange={(e) => {
                    this.searchInputFun(e, 'ref');
                  }}
                />
              </Col>
            </Row>
          </div>
        ),
        dataIndex: 'referenceId',
        key: 'referenceId',
        width: '15%',
      },
      {
        title: (
          <div>
            <Row>
              <Col span={24}>Reference 2</Col>
            </Row>
          </div>
        ),
        dataIndex: 'transactionId.reference',
        key: 'reference2',
        width: '15%',
      },
      {
        title: (
          <div>
            <Row>
              <Col span={24}>Description</Col>
            </Row>
            <Row>
              <Col span={24}>
                <Input
                  onChange={(e) => {
                    this.searchInputFun(e, 'desc');
                  }}
                />
              </Col>
            </Row>
          </div>
        ),
        dataIndex: 'description',
        key: 'description',
        width: '15%',
        render: (text, record) => {
          let txt = text.split('-');
          let check = txt[0] === 'OR';
          txt = check ? txt[3] : text;
          return <span>{txt}</span>;
        },
      },
      {
        title: <span style={{ float: 'right' }}>Debit Amount</span>,
        dataIndex: 'debitAmount',
        key: 'debitAmount',
        width: '10%',
        render: (text, record) => (
          <span style={{ float: 'right' }}>
            {formatMoney(record.debitAmount ? record.debitAmount : 0)}
          </span>
        ),
      },
      {
        title: <span style={{ float: 'right' }}>Credit Amount</span>,
        dataIndex: 'creditAmount',
        key: 'creditAmount',
        width: '10%',
        render: (text, record) => (
          <span style={{ float: 'right' }}>
            {formatMoney(record.creditAmount ? record.creditAmount : 0)}
          </span>
        ),
      },
      {
        title: (
          <div>
            <Row>
              <Col span={24}>Recon Date</Col>
            </Row>
          </div>
        ),
        dataIndex: 'reconDate',
        key: 'reconDate',
        width: '10%',
        render: (text, record) => {
          let endofMonth = moment(text).endOf('month');
          return <span>{text && moment(endofMonth).format('DD-MM-YYYY')}</span>;
        },
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => {
          if (this.state.notLatestDate && record.showInBankRecon) {
            return (
              <span>
                <Popconfirm
                  placement='topLeft'
                  title={textHere}
                  onConfirm={(e) => this.handleHideinRecon(false, record._id)}
                  okText='Confirm'
                  cancelText='Cancel'
                  disabled={this.props.bankrecons.mode === 'view'}
                >
                  <Checkbox
                    checked={record.showInBankRecon}
                    disabled={this.props.bankrecons.mode === 'view'}
                  ></Checkbox>
                </Popconfirm>
              </span>
            );
          } else {
            return (
              <Checkbox
                disabled={this.props.bankrecons.mode === 'view'}
                checked={record.showInBankRecon}
                onChange={(e) => this.handleHideinRecon(e, record._id)}
              ></Checkbox>
            );
          }
        },
      },
    ];

    return (
      <Layout>
        <Spin key='spinning' spinning={this.state.loading}>
          <Card
            title={
              <React.Fragment>
                <Row>
                  <Col span={9}>Bank Reconciliation</Col>
                  <Col span={15} style={{ float: 'right' }}>
                    <Row>
                      <Col style={{ textAlign: 'right' }} span={10}>
                        <Row>
                          <Col span={12}>
                            <span style={{ fontSize: '12px' }}>
                              Bank Statement Balance
                            </span>
                          </Col>
                          <Col span={1}>:</Col>
                          <Col span={11}>
                            <span style={{ fontSize: '12px' }}>
                              {formatMoney(closingBalance)}
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={12}>
                            <span style={{ fontSize: '12px' }}>
                              System Balance
                            </span>
                          </Col>
                          <Col span={1}>:</Col>
                          <Col span={11}>
                            <span style={{ fontSize: '12px' }}>
                              {formatMoney(systemBalance)}
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={12}>
                            <span style={{ fontSize: '12px' }}>
                              Differences
                            </span>
                          </Col>
                          <Col span={1}>:</Col>
                          <Col
                            style={{
                              borderTop: 'double',
                              borderBottom: 'double',
                            }}
                            span={11}
                          >
                            <span style={{ fontSize: '12px' }}>
                              {formatMoney(closingBalance - systemBalance)}
                            </span>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        style={{
                          float: 'right',
                          textAlign: 'right',
                        }}
                        span={14}
                      >
                        {this.props.bankrecons.mode !== 'view' ? (
                          <>
                            {this.state.notLatestDate &&
                            this.state.doubleCheck ? (
                              <Popconfirm
                                placement='topLeft'
                                title={textHere2}
                                onConfirm={() => this.hideAllRecon()}
                                okText='Confirm'
                                cancelText='Cancel'
                              >
                                <Button
                                  size='small'
                                  style={{ marginRight: '8px' }}
                                  type='primary'
                                >
                                  {showHide ? 'Untick/Tick All' : 'Tick All'}
                                </Button>
                              </Popconfirm>
                            ) : (
                              <Button
                                size='small'
                                style={{ marginRight: '8px' }}
                                type='primary'
                                onClick={this.hideAllRecon}
                              >
                                {showHide ? 'Untick/Tick All' : 'Tick All'}
                              </Button>
                            )}
                            {this.renderShowAll()}
                          </>
                        ) : null}
                        <Link
                          to={{
                            pathname: `/bankrecons-statement/${this.props.match.params.id}`,
                          }}
                        >
                          <Button
                            size='small'
                            style={{ marginLeft: '8px' }}
                            type='primary'
                            onClick={this.showModal}
                          >
                            View Statement
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </React.Fragment>
            }
          >
            <>
              <Table
                dataSource={this.props.bankrecons.bankrecons}
                columns={columns}
                rowKey='_id'
                onChange={(e) => this.handlePageChange(e)}
                pagination={{
                  total: this.state.totalPagination,
                  defaultCurrent: this.props.activePage.bankReconPage,
                  current: this.props.activePage.bankReconPage,
                  // onChange: this.handlePageChange,
                  pageSize: 20,
                }}
              />
            </>
          </Card>
        </Spin>
      </Layout>
    );
  }
}
function mapStateToProps(state) {
  return {
    bankrecons: state.bankrecons,
    commons: state.commons,
    activePage: state.app.activePage,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchBankRecons: fetchBankRecons,
      pushBankRecons: pushBankRecons,
      removeBankRecons: removeBankRecons,
      updateActiveBankRecons: updateActiveBankRecons,
      updateBankRecons: updateBankRecons,
      updateModeBankRecons: updateModeBankRecons,
      updateActivePage,
    },
    dispatch
  );
}

export default requireAuth(
  connect(mapStateToProps, mapDispatchToProps)(Form.create()(BankReconsIndex))
);
