import React from "react";

const SvgSpeech = props => (
  <svg height={512} viewBox="0 0 64 64" width={512} {...props}>
    <g data-name="Speech">
      <path
        fill="#8D99AE"
        d="M39 42h-6a1 1 0 000 2h6a1 1 0 000-2zM43 42h-1a1 1 0 000 2h1a1 1 0 000-2z"
        data-original="#000000"
        className="speech_svg__active-path"
        data-old_color="#000000"
      />
      <path
        d="M58 46H47V36h1a1 1 0 001-1v-3a1 1 0 00-1-1h-3v-5c0-.015-.008-.027-.008-.042a3.046 3.046 0 00.543-.422 3 3 0 000-4.243l-2.828-2.829a3.074 3.074 0 00-4.242 0 3 3 0 000 4.243l2.828 2.829a2.97 2.97 0 001.707.838V31H33v-2a.9.9 0 00-.035-.173A3 3 0 0035 26v-4a3 3 0 00-6 0v4a3 3 0 002.035 2.827A.9.9 0 0031 29v2H21v-4.626a2.97 2.97 0 001.707-.838l2.828-2.829a3 3 0 000-4.243 3.074 3.074 0 00-4.242 0l-2.828 2.829a3 3 0 000 4.243 3.046 3.046 0 00.543.422c0 .015-.008.027-.008.042v5h-3a1 1 0 00-1 1v3a1 1 0 001 1h1v10H2a1 1 0 000 2h56a1 1 0 000-2zM39.879 21.293a1 1 0 010-1.415 1 1 0 011.414 0l2.828 2.829a1 1 0 010 1.415 1.025 1.025 0 01-1.414 0zM31 22a1 1 0 012 0v4a1 1 0 01-2 0zm-11.121.707l2.828-2.828a1 1 0 111.414 1.414l-2.828 2.828a1.022 1.022 0 01-1.414 0 1 1 0 010-1.414zM17 33h30v1H17zm2 13V36h26v10zM62 46h-1a1 1 0 000 2h1a1 1 0 000-2z"
        data-original="#000000"
        className="speech_svg__active-path"
        data-old_color="#000000"
        fill="#8D99AE"
      />
    </g>
  </svg>
);

export default SvgSpeech;
