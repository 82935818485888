export const FETCH_CHARGES = 'FETCH_CHARGES';
export const PUSH_CHARGES = 'PUSH_CHARGES';
export const REMOVE_CHARGES = 'REMOVE_CHARGES';
export const UPDATE_CHARGES = 'UPDATE_CHARGES';
export const UPDATE_MODE = 'UPDATE_MODE';
export const UPDATE_ACTIVE_ID = 'UPDATE_ACTIVE_ID';

export function fetchCharges(data){
  return (dispatch) => {
    dispatch({
      type: FETCH_CHARGES,
      payload:data
    });
  }
}

export function pushCharges(data){
  return (dispatch) => {
    dispatch({
      type: PUSH_CHARGES,
      payload:data
    });
  }
}

export function removeCharges(data){
  return (dispatch) => {
    dispatch({
      type: REMOVE_CHARGES,
      payload:data
    });
  }
}

export function updateCharges(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_CHARGES,
      payload:data
    });
  }
}

export function updateMode(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE,
      payload:data
    });
  }
}

export function updateActiveCharges(mode, id){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_ID,
      mode,
      id
    });
  }
}
