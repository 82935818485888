import _ from 'lodash';
import {
  FETCH_PAYMENTTYPEPARAMS,
  PUSH_PAYMENTTYPEPARAMS,
  REMOVE_PAYMENTTYPEPARAMS,
  UPDATE_PAYMENTTYPEPARAMS,
  UPDATE_MODE,
  UPDATE_ACTIVE_ID } from '../actions/actions-paymenttypeparams';

const INITIAL_STATE = {
  paymenttypeparams: [],
  mode: 'index',
  activeId: '',
  activePaymentTypeParams:{}
}


export default function(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_PAYMENTTYPEPARAMS:
      return {
        ...state,
        paymenttypeparams:action.payload,
      }
    case PUSH_PAYMENTTYPEPARAMS:
      return {
        ...state,
        paymenttypeparams:[action.payload, ...state.paymenttypeparams],
      }
    case REMOVE_PAYMENTTYPEPARAMS:
      return {
        ...state,
        paymenttypeparams : [...state.paymenttypeparams.filter( (item) => item._id !== action.payload._id)]
      }
    case UPDATE_PAYMENTTYPEPARAMS:
      let index =  _.findIndex(state.paymenttypeparams, { '_id': action.payload._id })
      state.paymenttypeparams.splice(index, 1, action.payload)
      return {
        ...state,
        paymenttypeparams : state.paymenttypeparams
      }
    case UPDATE_MODE:
      return {
        ...state,
        mode : action.payload,
        activeId:''
      }
    case UPDATE_ACTIVE_ID:
      return {
        ...state,
        mode : action.mode,
        activeId:action.id,
        activePaymentTypeParams: _.pick(_.find(state.paymenttypeparams, { '_id': action.id }), ['propertyId','description', 'ledgerAccountId','category','_id'])
      }
    default:
      return state

  }
}
