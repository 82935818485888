export const FETCH_INVOICEITEMS = 'FETCH_INVOICEITEMS';
export const PUSH_INVOICEITEMS = 'PUSH_INVOICEITEMS';
export const REMOVE_INVOICEITEMS = 'REMOVE_INVOICEITEMS';
export const UPDATE_INVOICEITEMS = 'UPDATE_INVOICEITEMS';
export const UPDATE_MODE = 'UPDATE_MODE';
export const UPDATE_ACTIVE_ID = 'UPDATE_ACTIVE_ID';

export function fetchInvoiceitems(data){
  return (dispatch) => {
    dispatch({
      type: FETCH_INVOICEITEMS,
      payload:data
    });
  }
}

export function pushInvoiceitems(data){
  return (dispatch) => {
    dispatch({
      type: PUSH_INVOICEITEMS,
      payload:data
    });
  }
}

export function removeInvoiceitems(data){
  return (dispatch) => {
    dispatch({
      type: REMOVE_INVOICEITEMS,
      payload:data
    });
  }
}

export function updateInvoiceitems(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_INVOICEITEMS,
      payload:data
    });
  }
}

export function updateMode(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE,
      payload:data
    });
  }
}

export function updateActiveInvoiceitems(mode, id){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_ID,
      mode,
      id
    });
  }
}
