import React, { Component } from 'react';
import { Modal, message, Form, Input, Button, Select, Radio, InputNumber, Spin } from 'antd'
import client from '../../feathers';
import requireAuth from "../requireAuth";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateMode } from '../../actions/actions-invoices';
import axios from 'axios';

const FormItem = Form.Item;

const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};


class PaymentForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',
      confirmDirty: false,
      autoCompleteResult: [],
      mode: this.props.mode,
      visible: false,
      loading: false,
      paymentTypeParams: [],
      paymentType: '',    //CASH, CREDIT CARD, ONLINE BANKING, CHEQUE
      invoiceItems: [],
    }
  }

  componentDidUpdate() {
    if (this.props.totalAmount !== this.props.form.getFieldValue('appliedAmount')) {
      this.props.form.setFieldsValue({ appliedAmount: this.props.totalAmount });
    }

    if (this.props.invoiceItems !== this.state.invoiceItems) {
      this.setState({ invoiceItems: this.props.invoiceItems });
    }

  }

  componentDidMount() {
  }

  componentWillMount() {

    this.getPaymentTypeParams();

  }

  getPaymentTypeParams() {
    let companyId = this.props.commons.selectedCompany._id

    client.authenticate()

      .then((res) => {
        return client.service('payment-type-params').find({
          query: {
            propertyId: companyId,
            category: {
              $in: ['INVOICE']
            },
          }
        })
      })
      .then((res) => {
        this.setState({
          paymentTypeParams: res.data
        });
      })
      .catch((err) => {
        console.log(err);
      });

  }

  handleConfirmBlur = (e) => {

  }

  handleChange = (value) => {

    let change = value - this.props.form.getFieldValue('appliedAmount');

    if (change < 0) {
      this.props.form.setFieldsValue({
        change: change,
      });
    } else {
      this.props.form.setFieldsValue({
        change: this.formatAmt(change),
      });
    }

  }

  handleChangePaymentType = (e) => {
    this.setState({ paymentType: e.target.description });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      let companyId = this.props.commons.selectedCompany._id
      if (values.payAmount < values.appliedAmount) {
        return alert('Payment Amount cannot lesser then Invoice Amount');
      }

      if (!err) {

        let custInfo = {
          propertyunitId: this.props.propertyunitId,
          customerType: 'PROPERTYUNIT',
          invoiceItems: this.props.invoiceItems
        };


        client.service('transactions').create({
          ...values,
          ...custInfo,
          currency: 'MYR',
          status: 'SUCCESS',                            //PENDING,SUCCESS, FAILED
          propertyId: companyId,
          channel: 'WEB',                                //APPS, WEB
        })
          .then((res) => {

            message.success('Paid');
            this.setState({ visible: false });
            this.props.history.push('/transactions/' + res._id);
          })
          .catch((err) => {
            console.log(err);
            alert(err);
          })
      }
    });
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  }

  handleCancel = (e) => {

    this.setState({
      visible: false,
    });
  }

  formatAmt(amt) {
    let tempAmt = amt;

    return tempAmt > 0 ? tempAmt.toFixed(2) : 0.00;
  }

  handleFileUpload = (event) => {

    this.setState({ loading: true });
    let formData = new FormData();

    formData.append('image', event.target.files[0]);

    axios.post(`${client.io.io.uri}upload-transactions`,
      formData
      , {
        headers: {
          'Authorization': client.settings.storage.storage['feathers-jwt'],
          'Content-Type': 'multipart/form-data',
        }
      }
    ).then((res) => {

      this.props.form.setFieldsValue({
        file: res.data.imageUrl
      });
      this.setState({ loading: false });
      message.success('Upload Successful');
    })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
        message.error(err);
      })
  }

  renderPaymentField() {
    const { getFieldDecorator } = this.props.form;

    if (this.state.paymentType === 'CREDIT CARD') {
      return ([
        <FormItem
          {...formItemLayout}
          label="Select VISA/MASTER"
        >
          {getFieldDecorator('ccType', {
            rules: [
              { required: false, message: 'Please select VISA/MASTER!' },
            ],
          })(
            <Radio.Group>
              <Radio.Button value="VISA">VISA</Radio.Button>
              <Radio.Button value="MASTER">MASTER</Radio.Button>
            </Radio.Group>
          )}
        </FormItem>
        ,
        <FormItem
          {...formItemLayout}
          label="Credit Card No."
        >
          {getFieldDecorator('ccNumber', {
            rules: [{
              required: false, message: 'Please input Credit Card Number!',
            }],
          })(
            <Input placeholder="542323893838" />
          )}
        </FormItem>
      ]);
    } else if (this.state.paymentType === 'CASH') {

      return ([
        <FormItem
          {...formItemLayout}
          label="Change"
        >
          {getFieldDecorator('change', {
            rules: [{
              required: false, message: 'Please input change!',
            }],
          })(
            <Input disabled />
          )}
        </FormItem>
      ]);
    } else if (this.state.paymentType === 'ONLINE BANKING' || this.state.paymentType === 'CHEQUE') {
      return ([
        <FormItem
          {...formItemLayout}
          label="Upload"
        >
          <Input label='upload file' type='file' onChange={this.handleFileUpload} />
        </FormItem>,

        <FormItem
          {...formItemLayout}
          label="File Name"
          style={{ 'display': 'none' }}
        >
          {getFieldDecorator('file', {
            rules: [{
              required: false, message: 'Please Upload File!',
            }],
          })(
            <Input style={{ 'display': 'none' }} disabled="true" placeholder="File Name" />
          )}
        </FormItem>
      ]);
    }

  }

  render() {

    const { getFieldDecorator } = this.props.form;

    return ([
      <Button style={{ margin: '10px' }} type="danger" onClick={this.showModal}>Pay</Button>,
      <Modal
        visible={this.state.visible}
        title={'PAYMENT'}
        onOk={this.handleSubmit}
        okText='submit'
        onCancel={this.handleCancel}
        width="900px"
      >
        <Spin spinning={this.state.loading}>
          <Form className="submit-form">

            <FormItem
              {...formItemLayout}
              label="Payment Type"
            >
              {getFieldDecorator('paymentTypeParamId', {
                rules: [{
                  required: true, message: 'Please select Payment Type!',
                }],
              })(
                <Radio.Group onChange={this.handleChangePaymentType} >
                  {this.state.paymentTypeParams.map(dropdown => (
                    <Radio.Button value={dropdown._id} description={dropdown.description} >
                      {dropdown.description}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              )}
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Invoice Amount"
            >
              {getFieldDecorator('appliedAmount', {
                rules: [{
                  required: true, message: 'Please input Amount!',
                }],
              })(
                <Input disabled />
              )}
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Pay Amount"
            >
              {getFieldDecorator('payAmount', {
                rules: [{
                  required: true, message: 'Please input Amount!',
                }],
              })(
                <InputNumber onChange={this.handleChange} />
              )}
            </FormItem>

            {this.renderPaymentField()}

            <FormItem
              {...formItemLayout}
              label="Remark"
            >
              {getFieldDecorator('remark', {
                rules: [{
                  required: false, message: 'Please input remark!',
                }],
              })(
                <TextArea rows={4} />
              )}
            </FormItem>

          </Form>
        </Spin>
      </Modal>

    ])
  }
}


function mapStateToProps(state) {
  return {
    invoices: state.invoices,
    commons: state.commons
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(PaymentForm)));
