import React, { Component } from 'react';
import { Modal, message, Form, Input, Button, Select, InputNumber, Radio, Spin } from 'antd';
import client from '../../feathers';
import requireAuth from "../../components/requireAuth";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateMode } from '../../actions/actions-recurringitems';
import axios from 'axios';
import _ from 'lodash'

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};


class RecurringitemsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: '',
      confirmDirty: false,
      autoCompleteResult: [],
      chargesDropdowns: [],
      propertyunitsDropdowns: [],
      visible: false,
      fetching: false,
      loading: false
    }
  }
  componentDidUpdate() {
    if (this.props.recurringitems.activeId !== this.state._id) {
      this.setState({
        _id: this.props.recurringitems.activeId
      })

      if (this.props.recurringitems.activeRecurringitems) {
        this.props.form.setFieldsValue(
          this.props.recurringitems.activeRecurringitems
        )
      }
    }

  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }


  handleSubmit = (e) => {

    var CreateAll = '';

    e.preventDefault();
    this.props.form.validateFields((err, values) => {

      CreateAll = ''

      for (let i = 0; i < values.propertyunitId.length; i++) {
        if (values.propertyunitId[i].key === 'CreateAll') { CreateAll = 'Y'; }
      }

      if (!err) {
        //------------Create All------------------------
        if (CreateAll === 'Y') {
          let desc = _.map(this.state.chargesDropdowns, function (a) {
            if (a._id === values.cahrgeId) {
              return a.description
            }
          })

          let companyId = this.props.commons.selectedCompany._id

          client.authenticate()
            .then((res) => {
              return axios.post(`${client.io.io.uri}createallfee`, {
                //...values,
                chargeId: values.chargeId,
                description: values.description,
                amount: values.amount,
                status: values.status,
                propertyId: companyId,
              }, {
                headers: {
                  'Authorization': client.settings.storage.storage['feathers-jwt']
                }
              })
            })
            .then((res) => {

              message.success('Created');
              this.setState({ loading: false, visible: false });
            })
            .catch((err) => {
              console.log(err);
              this.setState({ loading: false });
            });

        }

        //------------Create single/multiple------------------------
        else {
          let companyId = this.props.commons.selectedCompany._id

          for (let i = 0; i < values.propertyunitId.length; i++) {
            this.setState({ loading: true });
            client.service('recurring-items').create({
              ...values,
              propertyunitId: values.propertyunitId[i].key,
              propertyId: companyId,
            })
              .then((res) => {

                message.success('New Recurring items Added');
                this.setState({
                  loading: false,
                  visible: false,
                });
              })
              .catch((err) => {
                console.log(err);
                alert(err);
                this.setState({ loading: false });
              })
          }
        }
      }

    });
  }

  componentWillMount() {
    let companyId = this.props.commons.selectedCompany._id

    //generate charges dropdown
    client.authenticate()
      .then((res) => {
        return client.service('charges').find({
          query: {
            propertyId: companyId,
            $sort: {
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {
        this.setState({ chargesDropdowns: res.data });
      })
      .catch((err) => {
        console.log(err);
      });

    //generate property unit dropdown
    client.authenticate()
      .then((res) => {
        return client.service('propertyunits').find({
          query: {
            propertyId: companyId,
            $sort: {
              createdAt: -1
            },
            $limit: 10,
          }
        })
      })
      .then((res) => {
        this.setState({ propertyunitsDropdowns: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleSearchPropertyunit = (value) => {
    let companyId = this.props.commons.selectedCompany._id
    //generate charges dropdown

    this.setState({ fetching: true });
    client.authenticate()
      .then((res) => {
        return client.service('propertyunits').find({
          query: {
            propertyId: companyId,
            $sort: {
              createdAt: -1
            },
            orRegex: {
              referenceId: value
            },
          }
        })
      })
      .then((res) => {

        this.setState({
          propertyunitsDropdowns: res.data,
          fetching: false
        });
      })
      .catch((err) => {
        this.setState({ fetching: false });
        console.log(err);
      });
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  }

  handleOk = (e) => {

    this.setState({
      visible: false,
    });
  }

  handleCancel = (e) => {

    this.setState({
      visible: false,
    });
  }


  render() {
    const { getFieldDecorator } = this.props.form;

    // propertyId    : {type:String,ref:'properties', required: true },
    // propertyunitId: {type:String,ref:'propertyunits', required: true },
    // chargeId      : {type:String,ref:'charges', required: true },
    // description   : {type:String},
    // amount        : {type:Number},
    // status        : {type:String, required: true },   //ACTIVE, INACTIVE

    return ([
      <Button type="primary" onClick={this.showModal}>
        New
      </Button>,
      <Spin spinning={this.state.loading} >
        <Modal
          title="Apply to One Account"
          visible={this.state.visible}
          okText={'Submit'}
          onOk={this.handleSubmit}
          onCancel={this.handleCancel}
        >
          <Form className="submit-form">

            <FormItem
              {...formItemLayout}
              label="Unit No."
            >
              {getFieldDecorator('propertyunitId', {
                rules: [{
                  required: true, message: 'Please Select Unit No.!',
                }],
              })(
                <Select
                  mode="multiple"
                  labelInValue
                  // value={value}
                  placeholder="Select users"
                  notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
                  filterOption={false}
                  onSearch={this.handleSearchPropertyunit}
                  // onChange={this.handleSearchPropertyunit}
                  style={{ width: '100%' }}
                >
                  <Option key='CreateAll'>All</Option>
                  {this.state.propertyunitsDropdowns.map(d => <Option key={d._id}>{d.referenceId}</Option>)}
                </Select>
              )}
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Charges"
            >
              {getFieldDecorator('chargeId', {
                rules: [{
                  required: true, message: 'Please select Charges!',
                }],
              })(
                <Select placeholder="Select Type" style={{ 'width': '200px' }} onChange={this.handleChange}>
                  {this.state.chargesDropdowns.map(dropdown => (
                    <Option value={dropdown._id}>
                      {dropdown.description}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Description"
            >
              {getFieldDecorator('description', {
                rules: [{
                  required: false, message: 'Please input Description!',
                }],
              })(
                <TextArea rows={4} />
              )}
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Amount"
            >
              {getFieldDecorator('amount', {
                rules: [{
                  required: true, message: 'Please input recurring!',
                }],
              })(
                <InputNumber />
              )}
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Status"
            >
              {getFieldDecorator('status', {
                rules: [{
                  required: true, message: 'Please Select Status!',
                }],
              })(
                <Radio.Group>
                  <Radio.Button value="ACTIVE">ACTIVE</Radio.Button>
                  <Radio.Button value="INACTIVE">INACTIVE</Radio.Button>
                </Radio.Group>
              )}
            </FormItem>

          </Form>
        </Modal>
      </Spin>

    ])
  }
}


function mapStateToProps(state) {
  return {
    recurringitems: state.recurringitems,
    commons: state.commons
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(RecurringitemsForm)));
