import React, { Component } from 'react';
import { Avatar, Row, Col, Card, Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Spin, Select, Radio } from 'antd';
import client from '../../feathers';
import requireAuth from "../requireAuth";
import Layout from "../layout";
import Info from "../common/info";
import ReactToPrint from "react-to-print";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  fetchEforms,
  pushEforms,
  removeEforms,
  updateMode,
  updateActiveEforms,
  updateEforms
} from '../../actions/actions-eforms';


const _ = require('lodash');
const { TextArea } = Input;
const FormItem = Form.Item;
const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
};
const Option = Select.Option;
const EditableContext = React.createContext();
const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
  state = {
    editing: false,
  }

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  }

  save = (e) => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  }

  getInput = () => {
    if (this.props.inputtype === 'date') {
      return <Input
        type='date'
        ref={node => (this.input = node)}
        onPressEnter={this.save}
        onBlur={this.save}
      />;
    } else if (this.props.inputtype === 'dropdownCard') {
      return <Select ref={node => (this.input = node)}
        onPressEnter={this.save}
        onBlur={this.save}>
        <Option key='Building Access' value='Building Access'>Building Access</Option>
        <Option key='Car Park & Building Access' value='Car Park & Building Access'>Car Park & Building Access</Option>
      </Select>
    }
    else if (this.props.inputtype === 'dropdownStatus') {
      return <Select ref={node => (this.input = node)}
        onPressEnter={this.save}
        onBlur={this.save}>
        <Option key='Active' value='Active'>Active</Option>
        <Option key='Inactive' value='Inactive'>Inactive</Option>
      </Select>
    }
    else if (this.props.inputtype === 'dropdownCardReplace') {
      return <Select ref={node => (this.input = node)}
        onPressEnter={this.save}
        onBlur={this.save}>
        <Option key='' value=''>(None)</Option>
        <Option key='Defect' value='Defect'>Defect</Option>
        <Option key='Lost Card' value='Lost Card'>Lost Card</Option>
      </Select>

    }
    else {
      return <Input
        ref={node => (this.input = node)}
        onPressEnter={this.save}
        onBlur={this.save}
      />;
    }
  };

  render() {
    const { editing } = this.state;
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>
            {(form) => {
              this.form = form;
              return (
                editing ? (
                  <FormItem style={{ margin: 0 }}>
                    {form.getFieldDecorator(dataIndex, {
                      rules: [{
                        required: dataIndex !== 'cardReplacement' ? true : false,
                        message: `${title} is required.`,
                      }],
                      initialValue: record[dataIndex],
                    })(
                      this.getInput()
                      // <Input
                      //   ref={node => (this.input = node)}
                      //   onPressEnter={this.save}
                      //   onBlur={this.save}
                      // />
                    )}
                  </FormItem>
                ) : (
                  <div
                    className="editable-cell-value-wrap"
                    style={{ paddingRight: 24 }}
                    onClick={this.toggleEdit}
                  >
                    {restProps.children}
                  </div>
                )
              );
            }}
          </EditableContext.Consumer>
        ) : restProps.children}
      </td>
    );
  }
}

class ComponentToPrint extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      count: 0,
      setting: {},
      jmb: {},
      mode: 'New',
      propertyunitsDropdowns: [],
      fetching: false,
      loading: false,
      numberCar: 0,
      numberBuilding: 0,
    }

    this.columns = [{
      title: 'Card Type',
      dataIndex: 'cardType',
      width: '15%',
      editable: true,
    }, {
      title: 'Card No.',
      dataIndex: 'cardNo',
      width: '25%',
      editable: true,
    }, {
      title: 'Date Issued',
      dataIndex: 'dateIssue',
      width: '15%',
      editable: true,
    }, {
      title: 'Activation Date',
      dataIndex: 'activateDate',
      width: '15%',
      editable: true,
    }, {
      title: 'Card Replacement',
      dataIndex: 'cardReplacement',
      width: '15%',
      editable: true,
    }, {
      title: 'Status',
      dataIndex: 'status',
      width: '15%',
      editable: true,
    },
    {
      title: <Icon type="plus" onClick={this.handleAdd} />,
      dataIndex: 'operation',
      render: (text, record) => (

        this.state.dataSource.length >= 1
          ? (<span>
            {/* <Icon type='edit' onClick={this.handleEdit(record.key)} /> */}
            <Popconfirm title="Sure to delete?" onConfirm={() => {
              this.handleDelete(record.key)
            }}>
              <Icon type='delete' />
            </Popconfirm>
          </span>
          ) : null
      ),
    }



    ];
    // }, {
    //   title: 'operation',
    //   dataIndex: 'operation',
    //   render: (text, record) => (
    //     this.state.dataSource.length >= 1
    //       ? (
    //         <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record.key)}>
    //           <a href="javascript:;">Delete</a>
    //         </Popconfirm>
    //       ) : null
    //   ),
    // }];

  }

  componentDidUpdate() {
    const { count, dataSource } = this.state;

    if (this.props.mode !== this.state.mode) {
      this.setState({
        mode: this.props.mode
      });
    }
  }

  componentDidMount() {

  }

  _getPropertyUnit(id) {
    client.authenticate()
      .then(() => {
        return client.service('propertyunits').get(id)
      })
      .then(res => {
        this.setState({ propertyunitsDropdowns: [res] });
      })
  }

  componentWillMount() {


    if (this.props.id) {
      this.setState({ loading: true });
      //get data
      client.authenticate()
        .then(() => {
          return client.service('eforms').find({
            query: {
              _id: this.props.id,
              $populate: ['propertyunitId', 'userId'],
            }
          })
        })
        .then((res) => {
          res = res.data[0]

          for (let i = 0; i < res.card.length; i++) {
            res.card[i].key = i

          }

          client.service('propertyunits').find({
            query: {
              _id: res.propertyunitId._id
            }

          }).then((result) => {

            try {

              this.setState({
                numberCar: result.data[0].numberCarParkCardAllowed,
                numberBuilding: result.data[0].numberBuildingCardAllowed,
                totalCard: result.data[0].numberCarParkCardAllowed + result.data[0].numberBuildingCardAllowed
              })
            }
            catch (err) {

              this.setState({
                numberCar: 0,
                numberBuilding: 0
              })
            }
          })


          this.setState({
            dataSource: res.card,
            loading: false,
            count: res.card.length,
            createdAt: ' ' + moment(res.createdAt).format('DD/MM/YYYY')
          });
          this._getPropertyUnit(res.propertyunitId._id)
          this.props.form.setFieldsValue({
            firstName: res.userId.firstName,
            lastName: res.userId.lastName,
            applyDate: res.applyDate,
            idNo: res.idNo,
            idName: res.idName,
            status: res.status,
            propertyunitId: res.propertyunitId._id,
            contactNo: res.contactNo,
            owner: res.propertyunitId.owner,
            remark: res.remark,
          });
          // this.props.form.setFieldsValue(res);

          // res.applyDate = new Date(res.applyDate)
          // res.applyDate = new Date(moment(res.applyDate).format('yyyy-MM-dd'))

          res.applyDate = new Date(res.applyDate)

          res.applyDate = moment(res.applyDate).format('YYYY-MM-DD')

          this.props.form.setFieldsValue({
            applyDate: res.applyDate
          });
        })
        .catch((err) => {
          this.setState({ loading: false });
          console.log(err);
        });

    }




    client.authenticate()
      //get footer info
      .then(() => {
        return client.service('settings').find({
          query: {
            propertyId: this.props.companyId
          }
        })
      })
      .then((res) => {
        this.setState({ setting: res.data[0].tnc })
        //get header info
        return client.service('jmbs').find({
          query: {
            propertyId: this.props.companyId,
            status: 'ACTIVE',
            $limit: 1,
            $sort: {
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {
        this.setState({ jmb: res.data[0] });
        // if(this.state.mode )
        //generate property unit dropdown
        return client.service('propertyunits').find({
          query: {
            propertyId: this.props.companyId,
            $sort: {
              referenceId: -1
            },

          }
        })
      })
      .then((res) => {
        this.setState({ propertyunitsDropdowns: [...res.data, ...this.state.propertyunitsDropdowns] });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleSearchPropertyunit = (value) => {
    //generate charges dropdown
    this.setState({ fetching: true });
    client.authenticate()
      .then((res) => {
        return client.service('propertyunits').find({
          query: {
            propertyId: this.props.companyId,
            orRegex: {
              referenceId: value
            },
          }
        })
      })
      .then((res) => {
        this.setState({
          propertyunitsDropdowns: res.data,
          fetching: false,
        });
      })
      .catch((err) => {
        this.setState({ fetching: false });
        console.log(err);
      });
  }


  convertDate(date = null) {
    let tempDate = new Date(date);
    let d = tempDate.getDate();
    let m = tempDate.getMonth() + 1;
    let y = tempDate.getFullYear();
    return date ? y + '-' + m + '-' + d : '';
  }

  handleAdd = () => {

    const { count, dataSource } = this.state;
    let { totalCard } = this.state
    let isDefectOrLost = 0

    _.forEach(dataSource, function (a) {
      if (a.cardReplacement === 'Defect' || a.cardReplacement === 'Lost Card') {
        isDefectOrLost += 1
      }

    })
    totalCard = totalCard + isDefectOrLost

    if (dataSource.length > totalCard) {
      this.handleDelete(dataSource.length - 1)
      return;
    }

    else if (dataSource.length === totalCard) {
      return message.error(`Maximum can only add ${totalCard} row for Card Forms`)
    }
    const newData = {
      key: count,
      cardType: ``,
      cardNo: '',
      dateIssue: '',
      activateDate: '',
      cardReplacement: '',
      status: '',
    };
    this.setState({
      dataSource: [...dataSource, newData],
      count: count + 1,
    });
  }

  handleSave = (row) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ dataSource: newData });
  }

  handleDelete = (key) => {

    const dataSource = [...this.state.dataSource];

    this.setState({ dataSource: dataSource.filter(item => item.key !== key) });




  }

  handleChangePropertyunit = (e) => {

    const { propertyunitsDropdowns } = this.state;

    let row = propertyunitsDropdowns.filter(item => item._id === e);
    if (row[0].owner) {
      this.props.form.setFieldsValue({
        owner: row[0].owner
      });
    }
    else {
      return message.warn('Owner details is not save in database ! Please fill in manually')
    }
  }

  getInputStyle = (dataType) => {
    if (dataType === 'activateDate' || dataType === 'dateIssue') {
      return 'date';
    }
    else if (dataType === 'cardType') {
      return 'dropdownCard'
    }
    else if (dataType === 'status') {
      return 'dropdownStatus'
    }
    else if (dataType === 'cardReplacement') {
      return 'dropdownCardReplace'

    }


    else {
      return 'text';
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();


    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        return;
      }
      const dataSource = this.state.dataSource;
      const mode = this.state.mode;
      const { numberCar, numberBuilding } = this.state

      let cardCar = 0, cardBuilding = 0

      _.forEach(dataSource, function (i) {
        console.log(i)
        if (i.cardType === 'Car Park & Building Access') {
          cardCar += 1
        }
        else if (i.cardType === 'Building Access') {
          cardBuilding += 1
        }
      })
      console.log(numberCar, numberBuilding)
      console.log(cardBuilding)

      if (cardCar > numberCar) {
        return message.error(`Car Park & Building Access card should be ${numberCar} only.`)
      }
      else if (cardBuilding > numberBuilding) {
        return message.error(`Building Access card should be ${numberBuilding} only.`)
      }





      if (mode === 'New') {
        console.log(fieldsValue)

        client.service('eforms').create({
          ...fieldsValue,
          propertyId: this.props.companyId,
          formType: 'CARD',
          card: dataSource
        })
          .then((res) => {

            message.success('Record Created');
          })
          .catch((err) => {
            console.log(err);
          });

      } else if (mode === 'Edit') {
        console.log(dataSource)

        client.service('eforms').patch(this.props.id, {
          ...fieldsValue,
          card: dataSource
        })
          .then((res) => {

            message.success('Record Updated');
          })
          .catch((err) => {
            console.log(err);
          });
      }

    });
  }

  render() {
    const setting = this.state.setting;
    const loading = this.state.loading;
    const jmb = this.state.jmb;
    const dataSource = this.state.dataSource;
    const mode = this.props.mode

    let isDisable
    if (mode === 'Edit') {
      isDisable = true
    }
    else {
      isDisable = false
    }
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };
    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputtype: this.getInputStyle(col.dataIndex), //col.dataIndex === 'amount' ? 'number' : 'text',
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });

    const { getFieldDecorator } = this.props.form;

    return (
      <Card
        loading={loading}
      >
        <Row>
          <Col span={4}>
            <img width='100%' src={jmb ? jmb.headerFile : ''} />
          </Col>
          <Col span={16} style={{ textAlign: 'center' }} >
            <p style={{ fontSize: 'large' }}><span>Application Form</span></p>
            <h3 style={{}}><span>Cards</span></h3>
          </Col>
          <Col span={4}>
            <p><span>{jmb ? jmb.headerLine1 : ''}</span></p>
            <p><span>{jmb ? jmb.headerLine2 : ''}</span></p>
            <p><span>{jmb ? jmb.headerLine3 : ''}</span></p>
            <p><span>{jmb ? jmb.headerLine4 : ''}</span></p>
          </Col>
        </Row>
        <Form className="submit-form" onSubmit={this.handleSubmit}>
          <Row>
            <Col span={12}>

              <FormItem
                label="Unit No."
              >
                {getFieldDecorator('propertyunitId', {
                  rules: [{
                    required: true, message: 'Please input!',
                  }],
                })(
                  <Select disabled={isDisable}
                    showSearch
                    style={{ width: '75%' }}
                    // value={value}
                    notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
                    filterOption={false}
                    onSearch={this.handleSearchPropertyunit}
                    onChange={this.handleChangePropertyunit}
                  >
                    {this.state.propertyunitsDropdowns.map(d => <Option key={d._id}>{d.referenceId}</Option>)}
                  </Select>
                )}
              </FormItem>


              <FormItem
                label='Mobile Number'
              >
                {getFieldDecorator('owner.contactNumber', {
                  rules: [{
                    required: true, message: 'Please input!',
                  }],
                })(
                  <Input style={{ width: '75%' }} disabled={isDisable} />
                )}
              </FormItem>

              <FormItem
                label="Status"
              >
                {getFieldDecorator('status', {
                  rules: [{
                    required: true, message: 'Please select Status!',
                  }],
                })(
                  <Radio.Group>
                    <Radio.Button value="In progress">In Progress</Radio.Button>
                    <Radio.Button value="Approved">Approve</Radio.Button>
                    <Radio.Button value="Rejected">Reject</Radio.Button>
                  </Radio.Group>
                )}
              </FormItem>


            </Col>
            <Col span={12}>
              <FormItem
                label="Owner Name"
              >
                {getFieldDecorator('owner.name', {
                  rules: [{
                    required: true, message: 'Please input!',
                  }],
                })(
                  <Input style={{ width: '75%' }} disabled={isDisable} />
                )}
              </FormItem>

              <FormItem
                label="IC No."
              >
                {getFieldDecorator('owner.idNo', {
                  rules: [{
                    required: true, message: 'Please input!',
                  }],
                })(
                  <Input style={{ width: '75%' }} disabled={isDisable} />
                )}
              </FormItem>



              <span><b>Created Date:</b></span>
              <span>{this.state.createdAt}</span>




            </Col>
          </Row>

          <Row>
            {/* <Button className='print-hide' onClick={this.handleAdd} type="primary" style={{ marginBottom: 16 }} >
              Add a row
            </Button> */}
            <Table
              bordered
              components={components}
              dataSource={dataSource}
              columns={columns}
              rowKey='_id'
              // size={'small'}
              pagination={false}
            />
          </Row>

          <Row>
            <Col span={7} >
              <span>Signature:</span>
              <br /><br />
              <br /><br />
              <Divider dashed />
              <span>Name:</span>
              <br /><br />
              <span>Date:</span>
            </Col>
            <Col span={15} offset={1}>
              <FormItem
                label="Remark"
              >
                {getFieldDecorator('remark', {
                  rules: [{
                    required: false, message: 'Please input!',
                  }],
                })(
                  <TextArea rows={4} />
                )}
              </FormItem>
            </Col>
          </Row>

          <Button type="primary" htmlType="submit" style={{ 'float': 'right' }} className='print-hide' >Submit</Button>
        </Form>

        <div className="invoiceFooter">
          <div className="ql-display-container">
            <div className="ql-display-editor" dangerouslySetInnerHTML={{ __html: setting ? setting.applicationFormTnc : '' }} />
          </div>

          <div style={{ 'textAlign': 'right' }}>© 2021 Mr Tech System Holdings Sdn Bhd (1370083-M)</div>
        </div>
      </Card>
    );
  }
}

class FormEformCard extends React.Component {
  render() {

    if (this.props.mode === 'New') {

      return (
        <div>
          <Row>
            <Col style={{ marginBottom: 10, float: 'right' }}>
              <ReactToPrint
                trigger={() => <Button type='primary'>PRINT</Button>}
                content={() => this.componentRef}
              />
            </Col>
          </Row>
          <ComponentToPrint ref={el => (this.componentRef = el)} form={this.props.form} mode='New' companyId={this.props.commons.selectedCompany._id} />
        </div>
      );

    } else {

      return (
        <Layout>
          <Row>
            <Col style={{ marginBottom: 10, float: 'right' }}>
              <ReactToPrint
                trigger={() => <Button type='primary'>PRINT</Button>}
                content={() => this.componentRef}
              />
            </Col>
          </Row>
          <ComponentToPrint ref={el => (this.componentRef = el)} form={this.props.form} companyId={this.props.commons.selectedCompany._id} id={this.props.match.params.id} mode='Edit' />
        </Layout>
      );

    }
  }
}


function mapStateToProps(state) {
  return {
    eform: state.eform,
    commons: state.commons
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchEforms,
    pushEforms,
    removeEforms,
    updateActiveEforms,
    updateEforms,
    updateMode
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(FormEformCard));
