import React, { Component } from 'react';
import { Modal, Avatar, Row, Col, Card, Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Select, Radio, Switch, Spin } from 'antd';
import client from '../../feathers';
import requireAuth from "../requireAuth";
import Layout from "../layout";
import Info from "../common/info";
import moment from 'moment';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  updateValueIDN
} from '../../actions/actions-commons';
import ReactToPrint from 'react-to-print';
import axios from 'axios';


//--------------------------------------------------------------------
const todaydate = moment().format('DD-MMM-YYYY')
const toWords = require('to-words');
const { TextArea } = Input;
const _ = require("lodash");
//--------------------------------------------------------------------


class ComponentToPrint extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      visible: false,
      setting: [],
      jmb: [],
      loading: false,
      totalAmount: 1,
      settings: [],
      dueAmount: 0,
      sinkingFundAmt: 0,
    }
  }
  //-------------------------------------------------------
  componentWillMount() {


    client.authenticate()
      .then((res) => {
        document.title = `${this.props.commons.name}_Reminder Letter_${this.props.props.propertyunits.activePropertyunits.referenceId}`

        return axios.get(`${client.io.io.uri}remindeLetterInq`, {
          params: {
            companyId: this.props.commons._id,
            propertyunitId: this.props.props.match.params.id
          },
          headers: {
            'Authorization': client.settings.storage.storage['feathers-jwt']
          }
        })
      }).then((res) => {

        this.setState({ dueAmount: res.data.result[0].totalDueAmt })
      })

    this.getJmbInfo();
    this.getTnc()
  }

  //---------------------------------------------------------

  getJmbInfo() {
    client.authenticate()
      .then((res) => {
        return client.service('jmbs').find({
          query: {
            propertyId: this.props.commons._id,
            status: 'ACTIVE',
            $limit: 1,
            $sort: {
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {

        this.setState({
          jmb: res.data[0]
        });
      })
      .catch((err) => {
        console.log(err);
      });

  }

  //---------------------------------------------------------
  getTnc() {
    client.authenticate()
      .then(() => {
        return client.service('settings').find({
          query: {
            propertyId: this.props.commons._id
          }
        })
      })
      .then((res) => {
        //
        this.setState({
          setting: res.data[0].tnc,
        });
      })
      .catch((err) => {
        console.log(err);
      })
  }
  //-------------------------------------------------------
  componentDidMount() { }

  _renderTable = () => {

    const { visible, onCancel } = this.props;
    const { loading, selectedRowKeys, jmb, dataSource, totalAmount } = this.state;

    let table = [];

    const columns = [
      {
        title: 'No',
        dataIndex: 'seq',
        key: 'seq',
        width: '5%',
        render: (text, record) => (
          <span style={{ fontSize: 9, padding: '0px' }} >
            {text}
          </span>
        ),
      },
      {
        title: 'Invoice No.',
        dataIndex: 'invoiceItemId.docId',
        key: 'invoiceItemId.docId',
        width: '15%',
        render: (text, record) => (
          <span style={{ fontSize: 9, padding: '0px' }} >
            {text}
          </span>
        ),
      },
      {
        title: 'Invoice Item',
        dataIndex: 'invoiceItemId.description',
        key: 'invoiceItemId.description',
        width: '15%',
        render: (text, record) => (
          <span style={{ fontSize: 9, padding: '0px' }} >
            {text}
          </span>
        ),
      },
      {
        title: 'Invoice Date',
        dataIndex: 'invoiceItemId.effectiveDate',
        key: 'invoiceItemId.effectiveDate',
        width: '10%',
        render: (text, record) => (
          <span style={{ fontSize: 9, padding: '0px' }} >
            {moment(text).format('DD-MM-YYYY')}
          </span>
        ),
      },

      {
        title: 'Invoice Amount',
        dataIndex: 'invoiceAmt',
        key: 'invoiceAmt',
        width: '10%',
        render: (text, record) => (
          <span style={{ fontSize: 9, padding: '0px' }} >
            {text.toFixed(2)}
          </span>
        ),
      },
      {
        title: 'Accrue Interest Start Date',
        dataIndex: 'accrueIntStartDate',
        key: 'accrueIntStartDate',
        width: '10%',
        render: (text, record) => (
          <span style={{ fontSize: 9, padding: '0px' }} >
            {moment(text).format('DD-MM-YYYY')}
          </span>
        ),
      },
      {
        title: 'Due Days',
        dataIndex: 'dueDateDays',
        key: 'dueDateDays',
        width: '5%',
        render: (text, record) => (
          <span style={{ fontSize: 9, padding: '0px' }} >
            {text}
          </span>
        ),
      },
      {
        title: 'Charge Date',
        dataIndex: 'chargeDate',
        key: 'chargeDate',
        width: '10%',
        render: (text, record) => (
          <span style={{ fontSize: 9, padding: '0px' }} >
            {moment(text).format('DD-MM-YYYY')}
          </span>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: '10%',
        render: (text, record) => (
          <span style={{ fontSize: 9, padding: '0px' }} >
            {text}
          </span>
        ),
      },
      {
        title: (
          <span style={{ float: 'right' }}> Charge Amount </span>),
        dataIndex: 'runninglateInterest',
        key: 'runninglateInterest',
        width: '10%',
        render: (text, record) => (
          <span style={{ fontSize: 9, padding: '0px', float: 'right' }} >
            {text.toFixed(2)}
          </span>
        ),
      }

    ];

    table.push([
      <div key='main' >
        <Card>
          <Row>
            <Col span={19} align="left">
              <p><span>{jmb ? jmb.headerLine1 : ''}</span> ({jmb ? jmb.registrationNo : ''}) </p>
              <p><span>{jmb ? jmb.headerLine2 : ''}</span></p>
              <p><span>{jmb ? jmb.headerLine3 : ''}</span></p>
              <p><span>{jmb ? jmb.headerLine4 : ''}</span></p>
              <br />

            </Col>
          </Row>

          <Row gutter={8} >
            <Col span={24} align="middle">
              <h2 style={{ 'color': 'rgb(169,169,169)', 'letterSpacing': '10px' }}>REMINDER</h2>
            </Col>

          </Row>

          <Row gutter={24}>
            <Col span={4}><span>Date</span></Col>
            <Col span={1}><span>:</span></Col>
            <Col span={18}><span style={{ 'paddingleft': '0px' }}>{moment().format('DD-MMM-YYYY')} </span></Col>
          </Row>

          <Row gutter={24}>
            <Col span={4}><span>Unit</span></Col>
            <Col span={1}><span>:</span></Col>
            <Col span={18}><span style={{ 'paddingleft': '0px' }}>{this.props.props.propertyunits.activePropertyunits.referenceId ? this.props.props.propertyunits.activePropertyunits.referenceId : ''} </span></Col>
          </Row>

          <Row gutter={24}>
            <Col span={4}><span>Name</span></Col>
            <Col span={1}><span>:</span></Col>
            <Col span={18}><span>{this.props.props.propertyunits.activePropertyunits.owner ? this.props.props.propertyunits.activePropertyunits.owner.name : ''} </span></Col>
          </Row>

          <Row gutter={24}>
            <Col span={4}><span>Mailing Address</span></Col>
            <Col span={1}><span>:</span></Col>
            <TextArea readOnly style={{ fontWeight: 650, fontSize: '1em', resize: 'none', padding: 0, width: '300px', height: '10 0px', border: 'none' }} value={this.props.props.propertyunits.activePropertyunits.owner ? this.props.props.propertyunits.activePropertyunits.owner.mailingAddress : ''} rows={4} />
          </Row>

          <br />
          <br />
          <Row gutter={24}>
            <p>Dear Sir/Madam,</p>
            <br />
            <p className='borderlineBottom'>RE: Overdue Bills</p>
            <br />
            <p>We would like to inform you that as of {todaydate}, our records shows that your account is overdue by total of <span className='customBold'>RM {parseFloat(this.state.dueAmount).toFixed(2)}</span> </p>
            <br />
            <p>We hope this is merely an oversight on your part and would appreciate if you can settle the abovementioned sum within <span className='customBold'>FOURTEEN (14)</span> days of this notice.</p>
            <br />
            <p>If full payment is not received by the end of the 14 days, legal and/or tribunal proceedings will commence without any further reference to you, and all costs incurred will be charged to you.</p>
            <br />
            <p>Please email us your transaction slip or bank-in slip if you have already made payment so that we can update your account.</p>
            <p>Thank you.</p>
            <br />
            <p className='italic'>This is a computer generated documents.</p>
            <p className='italic'>No signature is required.</p>
          </Row>
          <p className='borderlineBottom'></p>
          <div className="reminderFooter">
            <div className="ql-display-container2">
              <div className="ql-display-editor2" dangerouslySetInnerHTML={{ __html: this.state.setting ? this.state.setting.reminderTnc : '' }} />
            </div>
          </div>
        </Card>
      </div>
    ])

    return table;
  }

  render() {
    const loading = this.state.loading;
    return (
      <Spin key='spinning' spinning={loading}>
        {this._renderTable()}
      </Spin>
    );
  }
}

class InterestDebitNote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    }

  }

  render() {
    return (<Layout>
      <Row>
        <Col align='right' style={{
          marginBottom: '5px'
        }}>
          <span>
            <ReactToPrint trigger={() => <Button type='primary'>PRINT</Button>} content={() => this.componentRef} />
          </span>
        </Col>
      </Row>


      <ComponentToPrint props={this.props} ref={el => (this.componentRef = el)} commons={this.props.commons.selectedCompany} />

    </Layout>);
  }
}


function mapStateToProps(state) {
  return {
    commons: state.commons,
    propertyunits: state.propertyunits,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateValueIDN: updateValueIDN
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(InterestDebitNote));
