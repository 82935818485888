export const FETCH_BANKTRXS= 'FETCH_BANKTRXS';
export const PUSH_BANKTRXS= 'PUSH_BANKTRXS';
export const REMOVE_BANKTRXS= 'REMOVE_BANKTRXS';
export const UPDATE_BANKTRXS= 'UPDATE_BANKTRXS';
export const UPDATE_MODE_BANKTRXS = 'UPDATE_MODE_BANKTRXS';
export const UPDATE_ACTIVE_ID_BANKTRXS = 'UPDATE_ACTIVE_ID_BANKTRXS';
export const UPDATE_BANKTRXS_TYPE = 'UPDATE_BANKTRXS_TYPE';

export function fetchBankTrxs(data){
  return (dispatch) => {
    dispatch({
      type: FETCH_BANKTRXS,
      payload:data
    });
  }
}

export function pushBankTrxs(data){
  return (dispatch) => {
    dispatch({
      type: PUSH_BANKTRXS,
      payload:data
    });
  }
}

export function removeBankTrxs(data){
  return (dispatch) => {
    dispatch({
      type: REMOVE_BANKTRXS,
      payload:data
    });
  }
}

export function updateBankTrxs(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_BANKTRXS,
      payload:data
    });
  }
}

export function updateModeBankTrxs(data){
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE_BANKTRXS,
      payload:data
    });
  }
}

export function updateActiveBankTrxs(mode, id){
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACTIVE_ID_BANKTRXS,
      mode,
      id
    });
  }
}


export function updateBankTrxsType(bankTrxsType){
  return (dispatch) => {
    dispatch({
      type: UPDATE_BANKTRXS_TYPE,
      bankTrxsType
    });
  }
}