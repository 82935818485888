import React, { Component } from "react";
import {
  Popconfirm,
  Divider,
  Table,
  Form,
  Input,
  Button,
  Card,
  Badge,
  Row,
  Col,
  Select,
  Spin,
} from "antd";
import { Link } from "react-router-dom";

import client from "../../feathers";
import requireAuth from "../../components/requireAuth";
import Layout from "../../components/layout";
import PropertyunitsForm from "../../components/propertyunits/form-mng";
import axios from "axios";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchPropertyunits,
  pushPropertyunits,
  removePropertyunits,
  updateMode,
  updateActivePropertyunits,
  updatePropertyunits,
} from "../../actions/actions-propertyunits";
import NumberFormat from "react-number-format";

const _ = require("lodash");
// const formatter = new Intl.NumberFormat('en-US', {
//   //style: 'currency',
//   //currency: 'MYR',
//   minimumFractionDigits: 2
// });

const PAGESIZE = 20;
const text = "Are you sure to delete this item?";

class PropertyunitsIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchUnitNumber: "",
      totalPagination: 0,
      accountBalanceList: [],
      loading: true,
    };

    this.handleDelete = this.handleDelete.bind(this);
    this._searchUnitNumberChange = this._searchUnitNumberChange.bind(this);
  }

  componentDidUpdate() {
    if (this.props.propertyunits.mode === "refresh") {
      this.componentWillMount();
    }
  }

  componentDidMount() {}

  componentWillMount() {
    this.props.updateMode("");

    this.setState({ loading: true });

    this._getData(0);
  }

  handleDelete(_id) {
    client
      .service("propertyunits")
      .remove(_id)
      .then((res) => {})
      .catch((err) => {
        alert(err);
      });
  }

  handleEdit(_id) {
    this.props.updateActivePropertyunits("edit", _id);
  }

  handleManage(_id) {
    this.props.updateActivePropertyunits("", _id);
  }

  showModal = () => {
    this.props.updateMode("new");
  };

  formatAmt(amt) {
    let integer = parseFloat(amt);
    return integer > 0 ? integer.toFixed(2) : 0.0;
  }

  renderConfirmDeleteBtn(_id) {
    return (
      <Popconfirm
        placement="topLeft"
        title={text}
        onConfirm={() => this.handleDelete(_id)}
        okText="Confirm"
        cancelText="Cancel"
      >
        <Button type="danger">Delete</Button>
      </Popconfirm>
    );
  }

  handlePageChange = (pagination, filters, sorter) => {
    this._getData(pagination.current * PAGESIZE - PAGESIZE);
  };

  _totalOutstanding(r) {
    let info = _.find(this.state.accountBalanceList, (i) => {
      return i._id.accountId === r.ledgerAccountId._id;
    });

    let balance = (info ? info.balance : 0).toFixed(2);

    return (
      <NumberFormat
        thousandSeparator={true}
        displayType="text"
        fixedDecimalScale={true}
        decimalScale={2}
        value={balance}
      />
    );
  }

  _searchUnitNumberChange(e) {
    this.setState({
      loading: true,
      searchUnitNumber: e.target.value,
      searchObj: _.merge(
        this.state.searchObj,
        e.target.value ? { referenceId: e.target.value } : { referenceId: "." }
      ),
      typingTimeout: setTimeout(() => {
        this._getData(this.state.skip);
      }, 500),
    });
  }

  _getData(skip) {
    let companyId = this.props.commons.selectedCompany._id;

    client
      .authenticate()
      .then(() => {
        let searchObj = this.state.searchObj;
        let query = {
          query: {
            propertyId: companyId,
            isTesting: {
              $ne: true,
            },
            $skip: skip,
            $populate: "ledgerAccountId",
            $sort: {
              referenceId: 1,
            },
            $limit: PAGESIZE,
            orRegex: this.state.searchObj,
          },
        };
        _.assign(query.query, searchObj);
        return client.service("propertyunits").find(query);
      })
      .then((res) => {
        let accountList = res.data.map((i) => {
          return i.ledgerAccountId._id;
        });
        this.setState({ totalPagination: res.total, loading: false });
        this.props.fetchPropertyunits(res.data);

        // get total outstanding
        return axios.get(`${client.io.io.uri}ledgerTrxSummarybyAccounts`, {
          params: {
            companyId: companyId,
            accountList,
          },
          headers: {
            Authorization: client.settings.storage.storage["feathers-jwt"],
          },
        });
      })
      .then((res) => {
        this.setState({ accountBalanceList: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    const columns = [
      {
        title: (
          <Row gutter={8}>
            <Row>
              <Col span={24}>Unit Number</Col>
            </Row>
            <Row>
              <Col span={24}>
                <Input
                  value={this.state.searchUnitNumber}
                  onChange={this._searchUnitNumberChange}
                />
              </Col>
            </Row>
          </Row>
        ),
        dataIndex: "referenceId",
        key: "referenceId",
        width: "15%",
      },
      {
        title: "Size",
        dataIndex: "size",
        key: "size",
        width: "5%",
        align: "right",
      },
      {
        title: "Extra Size",
        dataIndex: "extraSize",
        key: "extraSize",
        width: "6%",
        align: "right",
      },
      {
        title: "Total Outstanding (MYR)",
        dataIndex: "balance",
        width: "7%",
        align: "right",
        render: (v, r) => <div>{this._totalOutstanding(r)}</div>,
      },
      {
        title: "Action",
        key: "action",
        width: "55%",
        render: (text, record) => (
          <span>
            <Link
              to={{
                pathname: `/propertyunits/user/${record._id}`,
              }}
            >
              <Button>Apps Users</Button>
            </Link>
            <Divider type="vertical" />
            <Button onClick={() => this.handleEdit(record._id)}>
              Edit Size(ft)
            </Button>
            {/*<Link
              to={{
                pathname: `/profomas/${record._id}`
              }}
            >
            <Button>Recurring Invoice</Button>
            </Link>*/}
            <Divider type="vertical" />
            {/*<Badge count={record.requestPending}>*/}
            <Link
              to={{
                pathname: `/propertyunits/approval/${record._id}`,
              }}
            >
              <Button>Residential Info</Button>
            </Link>
            {/*  </Badge>*/}

            <Divider type="vertical" />
            <Link
              to={{
                pathname: `/invoices/index-knockoff-by-propertyunit/${record._id}`,
              }}
            >
              <Button>Pay</Button>
            </Link>

            {/*
          <Divider type="vertical" />
          <Link
              to={{
                pathname: `/refunds/index-refund/${record._id}`
              }}
            >
                <Button>Refund</Button>
          </Link>
          */}
            {/*
          <Divider type="vertical" />
          <Link
              to={{
                pathname: `/prepayments/${record._id}`
              }}
            >
                <Button>Prepayment List</Button>
          </Link>
          */}
            {/*
          <Divider type="vertical" />
          <Link
              to={{
                pathname: `/waiver/${record._id}`
              }}
            >
                <Button>Waiver</Button>
          </Link>
          */}
          </span>
        ),
      },
    ];

    return (
      <Layout>
        <Spin spinning={this.state.loading}>
          <Card title="Property Unit Info">
            <Table
              dataSource={this.props.propertyunits.propertyunits}
              columns={columns}
              rowKey="_id"
              onChange={(e) => this.handlePageChange(e)}
              pagination={{
                defaultCurrent: 1,
                total: this.state.totalPagination,
                pageSize: PAGESIZE,
                size: "small",
              }}
            />
            <PropertyunitsForm />
          </Card>
        </Spin>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    propertyunits: state.propertyunits,
    commons: state.commons,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchPropertyunits: fetchPropertyunits,
      pushPropertyunits: pushPropertyunits,
      removePropertyunits: removePropertyunits,
      updateActivePropertyunits: updateActivePropertyunits,
      updatePropertyunits: updatePropertyunits,
      updateMode: updateMode,
    },
    dispatch
  );
}

export default requireAuth(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Form.create()(PropertyunitsIndex))
);
