import React, { Component } from 'react';
import { Modal, Avatar, Row, Col, Card, Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Select, Radio, Switch, Spin } from 'antd';
import client from '../../feathers';
import requireAuth from "../requireAuth";
import Layout from "../layout";
import Info from "../common/info";
import moment from 'moment';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  updateValueIDN
} from '../../actions/actions-commons';
import ReactToPrint from 'react-to-print';
import axios from 'axios';


//--------------------------------------------------------------------
const toWords = require('to-words');
const { TextArea } = Input;
const _ = require("lodash");
var today8 = moment().format('DD MMM YYYY')
//--------------------------------------------------------------------


class ComponentToPrint extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      visible: false,
      setting: [],
      jmb: [],
      loading: false,
      totalAmount: 1,
      totalDueAmt: 0,
      sinkingFundAmt: 0,
      unitAddress: '',
      othersDueAmt: 0,
      sinkingfund: 0,

    }
  }
  //-------------------------------------------------------
  componentWillMount() {



    client.authenticate()
      .then((res) => {
        document.title = `${this.props.props.commons.selectedCompany.name}_NOD FORM 11_${this.props.props.propertyunits.activePropertyunits.referenceId}`


        return axios.get(`${client.io.io.uri}remindeLetterInq`, {
          params: {
            companyId: this.props.props.commons.selectedCompany._id,
            propertyunitId: this.props.props.match.params.id
          },
          headers: {
            'Authorization': client.settings.storage.storage['feathers-jwt']
          }
        })
      }).then((res) => {

        this.setState({
          totalDueAmt: parseFloat(res.data.result[0].totalDueAmt).toFixed(2),
          othersDueAmt: parseFloat(res.data.result[0].othersDueAmt).toFixed(2),
          sinkingfund: parseFloat(res.data.result[0].sinkingfund).toFixed(2),

        })
      })

    this.getJmbInfo();
    this.getTnc()
    this.getProperties()
  }

  //---------------------------------------------------------

  getJmbInfo() {
    client.authenticate()
      .then((res) => {
        return client.service('jmbs').find({
          query: {
            propertyId: this.props.props.commons.selectedCompany._id,
            status: 'ACTIVE',
            $limit: 1,
            $sort: {
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {

        this.setState({
          jmb: res.data[0],
          jmbAddress: (res.data[0].headerLine1 ? res.data[0].headerLine1.toUpperCase() + ',' : '') + ' ' +
            (res.data[0].headerLine2 ? res.data[0].headerLine2.toUpperCase() + ',' : '') + ' ' +
            (res.data[0].headerLine3 ? res.data[0].headerLine3.toUpperCase() + ',' : '') + ' ' +
            (res.data[0].headerLine4 ? res.data[0].headerLine4.toUpperCase() : '')

        });
      })
      .catch((err) => {
        console.log(err);
      });

  }

  //---------------------------------------------------------
  getTnc() {
    client.authenticate()
      .then(() => {
        return client.service('settings').find({
          query: {
            propertyId: this.props.props.commons.selectedCompany._id
          }
        })
      })
      .then((res) => {

        this.setState({
          setting: res.data[0],
        });
      })
      .catch((err) => {
        console.log(err);
      })
  }
  //---------------------------------------------------------
  getProperties() {
    client.authenticate()
      .then(() => {
        return client.service('properties').find({
          query: {
            _id: this.props.props.commons.selectedCompany._id
          }
        })
      })
      .then((res) => {

        this.setState({
          unitAddress: (res.data[0].addressLineA ? res.data[0].addressLineA.toUpperCase() + ',' : '') + ' ' +
            (res.data[0].addressLineB ? res.data[0].addressLineB.toUpperCase() + ',' : '') + ' ' +
            (res.data[0].addressLineC ? res.data[0].addressLineC.toUpperCase() + ',' : '') + ' ' +
            (res.data[0].postCode ? res.data[0].postCode + ',' : '') + ' ' +
            (res.data[0].country ? res.data[0].country.toUpperCase() : '') + ' '
        });
      })
      .catch((err) => {
        console.log(err);
      })
  }

  //-------------------------------------------------------
  componentDidMount() { }

  _renderTable = () => {

    const { visible, onCancel } = this.props;
    const { loading, selectedRowKeys, jmb, dataSource, totalAmount, totalDueAmt, } = this.state;

    let table = [];

    const columns = [
      {
        title: 'No',
        dataIndex: 'seq',
        key: 'seq',
        width: '5%',
        render: (text, record) => (
          <span style={{ fontSize: 9, padding: '0px' }} >
            {text}
          </span>
        ),
      },
      {
        title: 'Invoice No.',
        dataIndex: 'invoiceItemId.docId',
        key: 'invoiceItemId.docId',
        width: '15%',
        render: (text, record) => (
          <span style={{ fontSize: 9, padding: '0px' }} >
            {text}
          </span>
        ),
      },
      {
        title: 'Invoice Item',
        dataIndex: 'invoiceItemId.description',
        key: 'invoiceItemId.description',
        width: '15%',
        render: (text, record) => (
          <span style={{ fontSize: 9, padding: '0px' }} >
            {text}
          </span>
        ),
      },
      {
        title: 'Invoice Date',
        dataIndex: 'invoiceItemId.effectiveDate',
        key: 'invoiceItemId.effectiveDate',
        width: '10%',
        render: (text, record) => (
          <span style={{ fontSize: 9, padding: '0px' }} >
            {moment(text).format('DD-MM-YYYY')}
          </span>
        ),
      },

      {
        title: 'Invoice Amount',
        dataIndex: 'invoiceAmt',
        key: 'invoiceAmt',
        width: '10%',
        render: (text, record) => (
          <span style={{ fontSize: 9, padding: '0px' }} >
            {text.toFixed(2)}
          </span>
        ),
      },
      {
        title: 'Accrue Interest Start Date',
        dataIndex: 'accrueIntStartDate',
        key: 'accrueIntStartDate',
        width: '10%',
        render: (text, record) => (
          <span style={{ fontSize: 9, padding: '0px' }} >
            {moment(text).format('DD-MM-YYYY')}
          </span>
        ),
      },
      {
        title: 'Due Days',
        dataIndex: 'dueDateDays',
        key: 'dueDateDays',
        width: '5%',
        render: (text, record) => (
          <span style={{ fontSize: 9, padding: '0px' }} >
            {text}
          </span>
        ),
      },
      {
        title: 'Charge Date',
        dataIndex: 'chargeDate',
        key: 'chargeDate',
        width: '10%',
        render: (text, record) => (
          <span style={{ fontSize: 9, padding: '0px' }} >
            {moment(text).format('DD-MM-YYYY')}
          </span>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: '10%',
        render: (text, record) => (
          <span style={{ fontSize: 9, padding: '0px' }} >
            {text}
          </span>
        ),
      },
      {
        title: (
          <span style={{ float: 'right' }}> Charge Amount </span>),
        dataIndex: 'runninglateInterest',
        key: 'runninglateInterest',
        width: '10%',
        render: (text, record) => (
          <span style={{ fontSize: 9, padding: '0px', float: 'right' }} >
            {text.toFixed(2)}
          </span>
        ),
      }

    ];



    table.push([
      <div key='main' >

        <Row>
          <Col span={24} align="right">
            <p><span className='customBold'>P.U. [A] 107</span></p>

          </Col>
        </Row>
        <Row>
          <Col span={24} align="middle">
            <p><span className='customBold'>STRATA MANAGEMENT ACT 2013</span></p>
            <br />
            <p><span className='customBold'>STRATA MANAGEMENT [MAINTENANCE AND MANAGEMENT]</span></p>
            <p><span className='customBold'>REGULATIONS 2015</span></p>
            <br />
            <p><span className='customBold'>FORM 11</span></p>
            <p><span>(Regulation) 20</span></p>
            <p><span className='customBold'>NOTICE TO DEMAND PAYMENT OF SUM DUE BY</span></p>
            <p><span className='customBold'>PURCHASER OR PARCEL OWNER </span></p>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col offset={1} span={4}><span>TO</span></Col>
          <Col span={1}><span>:</span></Col>
          <Col span={18}>
            <span>
              {this.props.props.propertyunits.activePropertyunits.owner ? this.props.props.propertyunits.activePropertyunits.owner.name : ''}
            </span>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col offset={6} span={18}>
            <TextArea readOnly style={{ fontWeight: 650, fontSize: '1em', resize: 'none', padding: 0, width: '300px', height: '10 0px', border: 'none' }} value={this.props.props.propertyunits.activePropertyunits.owner ? this.props.props.propertyunits.activePropertyunits.owner.mailingAddress : ''} rows={4} />
          </Col>
        </Row>


        <Row gutter={24}>
          <Col offset={1} span={4}><span>PARCEL/UNIT</span></Col>
          <Col span={1}><span>:</span></Col>
          <Col span={18}><span style={{ 'paddingleft': '0px' }}>{this.props.props.propertyunits.activePropertyunits.referenceId ? this.props.props.propertyunits.activePropertyunits.referenceId + ',' + this.state.unitAddress : ''} </span></Col>
        </Row>

        <Row gutter={24}>
          <Col offset={1} span={4}><span>Name of development area</span></Col>
          <Col span={1}><span>:</span></Col>
          <Col span={18}><span style={{ 'paddingleft': '0px' }}>{this.state.jmbAddress ? this.state.jmbAddress : ''} </span></Col>
        </Row>

        <br />
        <Row gutter={24}>
          <Col offset={1}>
            <p><span className='customBold2'>WHEREAS</span> you have committed a breach of the provisions of the Strata Management Act 2013 (“the Act”) by failing to pay to the joint management body under subsection 25(6) of the Act:</p>
            <p>*(a) Charges in the sum of <span className='customBold2'>RM {this.state.othersDueAmt}</span></p>
            <p>*(b) contribution to the sinking fund in the sum of <span className='customBold2'>RM {this.state.sinkingfund}</span></p>
            <br />
            <p>*failing to fully discharge your liability in respect of the amount of money lawfully incurred by the joint management body under subsection 21(4) of the Act and/or subsection 33(1) of the Act which is guaranteed by you as a parcel owner, in the sum of <span className='customBold2'>RM {this.state.totalDueAmt}</span>;</p>
            <p><span className='customBold2'>AND WHEREAS</span> the said sum of <span className='customBold2'>RM {this.state.totalDueAmt}</span> has become recoverable from you by virtue of the provisions of the Act mentioned above;</p>
            <br />
            <p>We as joint management body, by virtue of the powers conferred by subsection 34(1) of the Act here by demand payment of the sum due within the period of <span className='customBold2'>14 days(s)</span>(Note1)from the date of service of this notice, failing which we may file a summons or claim in a court of competent jurisdiction or in the Strata Management Tribunal for recovery of the said sum, or as an alternative, resort to recovery under section 35 of the Act for attachment of movable property.</p>
            <br />
            <p><span className='customBold2'>AND TAKE FURTHER NOTICE</span> that any purchaser or parcel owner who, without reasonable excuse, fails to comply with this notice commits an offence under subsection 34(3) of the Act and shall on conviction, be liable to a fine not exceeding five thousand ringgit or to imprisonment for a term not exceeding three years or to both, and in the case of a continuing offence, to a further fine not exceeding fifty ringgit for every day or part thereof during which the offence continues after conviction.</p>
            <br />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col offset={1}>
            <p>Dated: <span className='customBold2'>{today8}</span></p>
            <br /><br /><br />
          </Col>
        </Row>

        <Row gutter={24}>
          <Col offset={1} span={6}><p className='borderlineBottom'></p></Col>
        </Row>

        <Row gutter={24} >
          <Col offset={1}>
            <p>Signature of authorized signatory of joint management body</p>
            <p>Name : {this.state.setting.nodForm11 ? this.state.setting.nodForm11.name : ''}</p>
            <p>Designation : {this.state.setting.nodForm11 ? this.state.setting.nodForm11.designation : ''}</p>
            <p>Note:</p>
            <p>(1) Not less than two weeks from the date of service of this notice.</p>
          </Col>
        </Row>


      </div>
    ])

    return table;
  }

  render() {
    const loading = this.state.loading;
    return (
      <Spin key='spinning' spinning={loading}>
        {this._renderTable()}
      </Spin>
    );
  }
}

class InterestDebitNote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    }

  }

  render() {
    return (<Layout>
      <Row>
        <Col align='right' style={{
          marginBottom: '5px'
        }}>
          <span>
            <ReactToPrint trigger={() => <Button type='primary'>PRINT</Button>} content={() => this.componentRef} />
          </span>
        </Col>
      </Row>


      <ComponentToPrint props={this.props} ref={el => (this.componentRef = el)} />

    </Layout>);
  }
}


function mapStateToProps(state) {
  return {
    commons: state.commons,
    propertyunits: state.propertyunits,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateValueIDN: updateValueIDN
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(InterestDebitNote));
