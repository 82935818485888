import React, { Component } from 'react';
import { DatePicker, Table, Input, Button, Popconfirm, Form, InputNumber, Select, Col, Row, Card, message, Spin, Popover, Icon } from 'antd';
import { Route, Link } from 'react-router-dom';

import client from '../../feathers';
import requireAuth from "../requireAuth";
import Layout from "../layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PaymentFormItems from "./form-paymentvoucher-multipay";
import {
  fetchReceipts,
  pushReceipts,
  removeReceipts,
  updateMode,
  updateActiveReceipts,
  updateReceipts
} from '../../actions/actions-receipts';
import copy from 'copy-to-clipboard';
import '../../App.css';
import moment from 'moment';

const _ = require('lodash');
const text = 'Are you sure to delete this item?';
const FormItem = Form.Item;
const EditableContext = React.createContext();
const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const d = new Date();
const monthdesc = monthNames[d.getMonth()];
const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const formatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2
})
const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);
const EditableFormRow = Form.create()(EditableRow);
const Option = Select.Option;


class EditableCell extends React.Component {

  state = {
    editing: false,
    charges: [],
    loading: false,
  }

  componentWillMount() {

  }

  componentDidUpdate() {
    if (this.props.charges !== this.state.charges) {
      this.setState({
        charges: this.props.charges
      });
    }
  }

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  }



  save = () => {
    const { record, handleSave } = this.props;
    let newRecord = {};
    this.form.validateFields((error, values) => {

      if (values.chargeId) {

        const newData = [...this.props.recurringItems];
        const index = newData.findIndex(item => values.chargeId === item.chargeId._id);
        const item = newData[index];
        // newData.splice(index, 1, {
        //   ...item,
        //   ...row,
        // });
        newRecord = { ...record, actualAmount: item.amount };
      } else {
        newRecord = record;
      }

      if (error) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...newRecord, ...values });
    });
  }

  getInput = () => {
    if (this.props.inputType === 'number') {
      return <InputNumber
        ref={node => (this.input = node)}
        onPressEnter={this.save}
        onBlur={this.save}
      />;
    } else {
      return <Input
        ref={node => (this.input = node)}
        onPressEnter={this.save}
        onBlur={this.save}
      />;
    }
  };

  render() {
    const { editing } = this.state;
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      ...restProps
    } = this.props;
    return (
      <td ref={node => (this.cell = node)} {...restProps}>
        {editable ? (
          <EditableContext.Consumer>
            {(form) => {
              this.form = form;
              return (
                editing ? (
                  <FormItem style={{ margin: 0 }}>
                    {form.getFieldDecorator(dataIndex, {
                      rules: [{
                        required: true,
                        message: `${title} is required.`,
                      }],
                      initialValue: record[dataIndex],
                    })(
                      this.getInput()
                      // <Input
                      //   ref={node => (this.input = node)}
                      //   onPressEnter={this.save}
                      //   onBlur={this.save}
                      // />
                    )}
                  </FormItem>
                ) : (
                  <div
                    className="editable-cell-value-wrap"
                    style={{ paddingRight: 24, border: '1px solid darkgray', backgroundColor: 'white' }}
                    onClick={this.toggleEdit}
                  >
                    {restProps.children}
                  </div>
                )
              );
            }}
          </EditableContext.Consumer>
        ) : restProps.children}
      </td>
    );
  }
}


class PaymentVoucherMultipayIndex extends Component {
  constructor(props) {
    super(props);




    this.columns = [
      {
        title: 'Date',
        dataIndex: 'date',
        width: '10%',
        render: (text, record) => (
          <span>
            {record.date ? this.convertDate(record.date) : ''}
          </span>
        ),
      },
      {
        title: 'Category Expenses',
        dataIndex: 'debitledgerAccountId.accountName',
        width: '20%',
      },
      {
        title: 'Description',
        dataIndex: 'remark',
        width: '10%',
        render: (text, record) => (
          <div style={{ textAlign: 'center' }}>
            {text ?
              <div>
                <Popover content={text}><a href="javascript:;"><Icon onClick={() => this.copyDescription(text)} type="message" /></a></Popover>
              </div>
              :
              ''}
          </div>
        ),
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        width: '10%',
        render: (text, record) => (
          <span style={{ float: 'right' }}>
            {this.formatAmt(record.amount)}
          </span>
        ),
      }
      , {
        title: 'Total Paid',
        dataIndex: 'totalPay',
        width: '10%',
        render: (text, record) => (
          <span style={{ float: 'right' }}>
            {this.formatAmt(record.totalPay)}
          </span>
        ),
      }
      , {
        title: 'KnockOff Amount',
        dataIndex: 'knockOffAmount',
        width: '15%',
        editable: true,
        render: (number, record) => (
          <span >
            {this.formatAmt(record.knockOffAmount)}
          </span>
        ),
      }, {
        title: 'Remaining Balance',
        dataIndex: 'balance',
        width: '15%',
        //editable: true,
        render: (text, record) => (
          <span style={{ float: 'right' }}>
            {this.formatAmt(_.get(record, ['amount'], 0) - _.get(record, ['totalPay'], 0) - _.get(record, ['knockOffAmount'], 0) - _.get(record, ['creditAmount'], 0))}
          </span>
        ),
      }];


    this.state = {
      selectedAmount: 0,
      totalAmount: 0,
      payAmount: 0,
      propertyUnit: {},
      loading: false,
      dataSource: [],
      selectedRowKeys: [],
      count: 0,
      charges: [],
      recurringItems: [],
      selectedRows: [],
      paymentDate: null,
      selectedRowsTick: [],
      overpaid: false,
      creditNoteList: []
    }

  }

  copyDescription(text) {
    copy(text)
    message.success('Description Copied')
  }

  componentDidUpdate() {

    if (this.state.selectedRows.length > 0) {

      let tempAmt = 0;
      for (let i = 0; i < this.state.selectedRows.length; i++) {
        if (parseFloat(this.state.selectedRows[i].knockOffAmount) >
          (parseFloat(this.state.selectedRows[i].appliedAmount) - parseFloat(this.state.selectedRows[i].knockOffBalance))) {
          alert("more than remaining charges!");

        }

        tempAmt += parseFloat(this.state.selectedRows[i].knockOffAmount);

      }

      // this.setState({
      //   totalAmount: tempAmt,
      // });
    }

  }

  componentDidMount() {

  }

  componentWillMount() {

    this.setState({ loading: true });
    client.authenticate()
      .then((res) => {
        client.service('receipt-items').find({
          query: {
            $populate: 'debitledgerAccountId',
            propertyId: this.props.commons.selectedCompany._id,
            receiptType: { $in: ["1"] },
            $sort: {
              sortOrder: 1
            }
          }
        }).then((res2) => {
          this.setState({ creditNoteList: res2.data })
        })

        client.service('receipt-items').find({
          query: {
            $populate: 'debitledgerAccountId',
            propertyId: this.props.commons.selectedCompany._id,
            status: { $in: ['OPEN'] },
            receiptType: { $nin: ["1", "2"] },
            $sort: {
              sortOrder: 1
            }
          }
        }).then((result) => {

          this.setState({
            dataSource: result.data,
            loading: false,
          }, () => {
            this.checkedAllCheckedBox();
          })

        })
          .catch((err) => {
            console.log(err);
            this.setState({ loading: false });
          });
      })


  }
  calculateCreditAmount(id) {
    const CnReceiptItems = this.state.creditNoteList

    let creditAmount = 0

    _.forEach(CnReceiptItems, function (item) {

      if (item.receiptItemId === id) {
        creditAmount += _.get(item, ["amount"], 0)
      }
    })
    return creditAmount

  }


  checkedAllCheckedBox() {

    let selectedRowKeys = [];
    let selectedRows = [];
    let newData = [];
    let totalSelectedAmt = 0;
    let totalKnockOffAmt = 0;
    for (let i = 0; i < this.state.dataSource.length; i++) {

      let creditAmount = 0
      creditAmount = this.calculateCreditAmount(this.state.dataSource[i]._id)

      this.state.dataSource[i].knockOffAmount = Number(this.state.dataSource[i].amount - this.state.dataSource[i].totalPay - creditAmount - this.state.dataSource[i].knockOffAmount);
      this.state.dataSource[i].creditAmount = creditAmount
      newData.push(this.state.dataSource[i]);
      selectedRows.push(this.state.dataSource[i]);
      selectedRowKeys.push(this.state.dataSource[i]._id);
      totalSelectedAmt += this.state.dataSource[i].knockOffAmount;
      totalKnockOffAmt += this.state.dataSource[i].knockOffAmount;
    }

    setTimeout(() => {
      this.setState({
        selectedRowKeys: selectedRowKeys,
        selectedRows: selectedRows,
        dataSource: newData,
        selectedAmount: totalSelectedAmt,
        totalAmount: totalKnockOffAmt
      });
    }, 1000);

  }

  formatAmt(amt) {

    let integer = parseFloat(amt);
    return integer > 0 ? integer.toFixed(2) : 0.00;
  }


  convertDate(date) {
    let tempDate = new Date(date);
    let d = tempDate.getDate();
    let m = tempDate.getMonth() + 1;
    let y = tempDate.getFullYear();
    return d + '/' + m + '/' + y;
  }

  handleSave = (row) => {


    //dataSource
    const newData = [...this.state.dataSource];
    const index = newData.findIndex(item => row._id === item._id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });

    //dataSelectedrow
    const newData2 = [...this.state.selectedRowsTick];

    const index2 = newData2.findIndex(item => row._id === item._id);
    const item2 = newData2[index2];
    if (index2 > -1) {
      newData2.splice(index2, 1, {
        ...item2,
        ...row,
      });
    }

    let tempAmt = 0;
    let overpaid = false;
    for (let i = 0; i < newData2.length; i++) {

      overpaid = false;

      let knockoffAmount = newData2[i].knockOffAmount ? parseFloat(newData2[i].knockOffAmount) : 0;
      let totalAmount = newData2[i].totalAmount
      let totalPay = newData2[i].totalPay ? parseFloat(newData2[i].totalPay) : 0;
      // let remainingAmount = totalAmount - totalPay - knockoffAmount
      let remainingAmount = totalAmount - totalPay
      if (knockoffAmount > remainingAmount) {
        overpaid = true;
      }


      tempAmt += newData2[i].knockOffAmount ? parseFloat(newData2[i].knockOffAmount) : 0;
    }

    // if(this.state.selectedRowsTick.length>0){
    //   var tempAmt = 0;
    //   for(let i=0;i<this.state.selectedRowsTick.length;i++){
    //     tempAmt += parseFloat(this.state.selectedRowsTick[i].knockOffAmount);
    //   }
    // }

    this.setState({
      dataSource: newData,
      selectedRows: newData2,
      selectedRowsTick: newData2,
      totalAmount: tempAmt,
      overpaid: overpaid,
    });

  }

  getInputStyle = (dataType) => {
    if (dataType === 'amount') {
      return 'number';
    } else {
      return 'text';
    }
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  _paymentDateChange(date, datestring) {
    this.setState({
      paymentDate: date
    });
  }

  render() {
    const { dataSource, selectedRowKeys, loading, selectedRows, creditNoteList } = this.state;
    const { getFieldDecorator } = this.props.form;
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };

    const creditColumns = [
      {
        title: 'Transaction Date',
        dataIndex: 'date'
      },
      {
        title: 'Category Expenses',
        dataIndex: 'debitledgerAccountId.accountName'
      }
      ,
      {
        title: 'Description',
        dataIndex: 'remark',
      },
      {
        title: 'Credit Amount',
        dataIndex: 'amount',
        render: (row, record) => {
          return row.toFixed(2)
        }
      },
      {
        title: 'Status',
        dataIndex: 'status',

      },

    ]


    // rowSelection object indicates the need for row selection
    const rowSelection = {
      selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {

        let tempAmt = 0;
        let newData = [...this.state.dataSource];

        for (let i = 0; i < selectedRows.length; i++) {

          tempAmt += selectedRows[i].knockOffAmount ? parseFloat(selectedRows[i].knockOffAmount) : 0

        }

        if (selectedRows.length > 0) {
          //start handle latest selected row
          //selectedRows[selectedRows.length-1].knockOffAmount = selectedRows[selectedRows.length-1].appliedAmount-selectedRows[selectedRows.length-1].knockOffBalance;
          const index = newData.findIndex(item => selectedRows[selectedRows.length - 1]._id === item._id);
          const item = newData[index];
          newData.splice(index, 1, {
            ...item,
            ...selectedRows[selectedRows.length - 1],
          });
          //end handle latest selected row

        }
        this.setState({
          selectedRowsTick: selectedRows,
          selectedRowKeys,
          totalAmount: tempAmt
        });

        // this.setState({
        //   selectedRows:selectedRows,
        //   selectedRowKeys:selectedRowKeys,
        //   // selectedAmount:tempAmt,
        //   // dataSource:newData,
        //   // totalAmount:tempAmt
        // });
      },
    };


    const hasSelected = selectedRows.length > 0;
    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });


    const dateFormat = 'YYYY/MM/DD';

    const config = {
      initialValue: moment(),
      rules: [{
        type: 'object',
        required: true,
        message: 'Please select date!'
      }],
    };

    return (
      <Layout>
        <Spin key='spinning' spinning={this.state.loading}>
          <Card
            title={'Payment Voucher'}
          >
            <Row>
              <Col span='18'>
                {hasSelected ? `Selected ${selectedRows.length} items` : ''}
                <Table
                  rowSelection={rowSelection}
                  components={components}
                  rowClassName={() => 'editable-row'}
                  bordered
                  dataSource={dataSource}
                  columns={columns}
                  pagination={false}
                  size='small'
                  scroll={{ y: 340 }}
                  rowKey="_id"
                />
                <br />

                <Card title='Credit Notes'>
                  <Table columns={creditColumns} dataSource={creditNoteList} rowKey='_id' />
                </Card>
              </Col>
              <Col span='6'>
                {/*
              <Form.Item
                {...formItemLayout}
                label="Payment Date"
              >
                {getFieldDecorator('paymentDate', config)(
                  <DatePicker
                    onChange={()=>this._paymentDateChange}
                  />
                )}
              </Form.Item>
              */}

                <PaymentFormItems
                  supplierId={this.props.match.params.id}
                  supplierInvoices={this.state.selectedRows}
                  totalAmount={this.state.totalAmount}
                  overpaid={this.state.overpaid}
                />
              </Col>
            </Row>
          </Card>
        </Spin>
      </Layout>
    )
  }


}


function mapStateToProps(state) {
  return { invoiceitems: state.invoiceitems, commons: state.commons }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchReceipts: fetchReceipts,
    pushReceipts: pushReceipts,
    removeReceipts: removeReceipts,
    updateActiveReceipts: updateActiveReceipts,
    updateReceipts: updateReceipts,
    updateMode: updateMode
  }, dispatch);
}


export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(PaymentVoucherMultipayIndex)));
