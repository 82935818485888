import React, { Component, useEffect } from 'react';
import { Modal, Upload,Popconfirm, message, Divider,  Table, Form, Icon, Input, Button, Select, Card, Pagination,Col } from 'antd';
import client from '../../feathers';
import requireAuth  from "../requireAuth";
import Layout  from "../layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateMode } from '../../actions/actions-accounts';
import { Route, Link } from 'react-router-dom';

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset:4 },
};

const text = 'Are you sure to delete this item?';

class LedgerIndexTransactionsByReference extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      receiptId:'',
      invoiceId:'',
      transactionId:'',
      // ledgerAccount:'',
      ledgerTransactions:[],
      totalPagination:1,
      CurrentPagination:1
    }
  }

  componentWillMount(){
    //get ledger account transaction info
    client.authenticate()
    .then((res)=>{

        return client.service('ledger-transactions').find({
          query: {
            //ledgerAccountId:this.props.match.params.id,
            $populate:'ledgerAccountId',
            receiptId:this.state.receiptId,
            invoiceId:this.state.invoiceId,
            transactionId :this.state.transactionId,
            $limit:10,
            //$populate:'ledgerAccountsId ledgerMainTrxId',
            $sort:{
              trxDate:-1,
              createdAt:-1
            }
           }
        })
    })
    .then((res)=>{

      this.setState({
        ledgerTransactions:res.data,
        totalPagination:res.total
      });
    })
    .catch((err)=>{
        alert(err);
    });
  }

  componentDidUpdate(){
    if(this.props.receiptId!==this.state.receiptId){
      this.setState({receiptId:this.props.receiptId});
      this.componentWillMount();
    }

    if(this.props.invoiceId!==this.state.invoiceId){
      this.setState({invoiceId:this.props.invoiceId});
      this.componentWillMount();
    }

    if(this.props.transactionId!==this.state.transactionId){
      this.setState({transactionId:this.props.transactionId});
      this.componentWillMount();
    }
  }

  handleConfirmBlur = (e) => {

  }


  handleSubmit = (e) => {
     e.preventDefault();
  }

  handlePageChange = (page) => {

    this.setState({
      CurrentPagination: page,
    });
    this.props.form.validateFields((err, values) => {

      client.authenticate()
      .then((res)=>{

          return client.service('ledger-transactions').find({
            query: {
              ...values,
              ledgerAccountId:this.props.match.params.id,
              $limit:10,
              $skip:(page-1)*10,
              $sort: {
                trxDate:-1,
                createdAt:-1
              }
            }
          })
      })
      .then((res)=>{

        this.setState({
          ledgerTransactions:res.data,
          totalPagination:res.total
        });
      })
      .catch((err)=>{
          console.log(err);
      })
    });
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  }

  handleOk = (e) => {

    this.setState({
      visible: false,
    });
  }

  handleCancel = (e) => {

    this.setState({
      visible: false,
    });
  }

  convertDate(date){
    let tempDate = new Date(date);
    let d = tempDate.getDate();
    let m = tempDate.getMonth()+1
    let y = tempDate.getFullYear();
    return d+'/'+m+'/'+y;
  }

  formatAmt(amt) {
    let tempAmt = amt;

    return tempAmt>0?tempAmt.toFixed(2):0.00;
  }

  render() {
    const { visible, onCancel } = this.props;

    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title: 'Reference',
        dataIndex: 'referenceId',
        key: 'referenceId',
      },
      {
        title: 'Account Name',
        dataIndex: 'ledgerAccountId.accountName',
        key: 'ledgerAccountId.accountName',
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: 'Date',
        dataIndex: 'trxDate',
        key: 'trxDate',
        render: (text, record) => (
          <span>{this.convertDate(record.trxDate)}</span>
        ),
      },
      {
        title: 'Debit',
        dataIndex: 'debitAmount',
        key: 'debitAmount',
        render: (text, record) => (
          <span>{this.formatAmt(record.debitAmount)}</span>
        ),
      },
      {
        title: 'Credit',
        dataIndex: 'creditAmount',
        key: 'creditAmount',
        render: (text, record) => (
          <span>{this.formatAmt(record.creditAmount)}</span>
        ),
      },
    ];

    return([
 
        <Button key='viewtrx' type="primary" onClick={this.showModal}>
          View Trx
        </Button>

        ,
        <Modal
          title="Accounting Posting"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width="900px"
          key='modal'
          footer={[
            <Button key="back" onClick={this.handleCancel}>Cancel</Button>,
          ]}
        >
          <Table dataSource={this.state.ledgerTransactions}  columns={columns} rowKey="_id" pagination={false} />
          <Pagination  defaultCurrent={1} total={this.state.totalPagination} onChange={this.handlePageChange} />
        </Modal>
    ])
  }
}


function mapStateToProps(state) {
  return {
    accounts: state.accounts
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
    }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(LedgerIndexTransactionsByReference)));
