import React, { Component } from 'react';
import { Popconfirm, message, Divider, Table, Form, Icon, Input, Button, Card, Row, Col, Modal, Spin } from 'antd';
import { Route, Link } from 'react-router-dom';

import client from '../../feathers';
import requireAuth from "../requireAuth";
import Layout from "../layout";
import PropertyunitusersForm from "./form";


import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchRequests,
  pushRequests,
  removeRequests,
  updateMode,
  updateActiveRequests,
  updateRequests
} from '../../actions/actions-requests';
import axios from 'axios';

const FormItem = Form.Item;
const { TextArea } = Input;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const text = 'Are you sure to delete this item?';

class RequestsIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      propertyunit: [],
      visible: false,
      img: '',
      loading: false,
      refresh: false,
    }

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidUpdate() {

    if (this.state.refresh === true) {
      this.componentWillMount()
    }

  }

  componentDidMount() {

  }



  componentWillMount() {

    this.setState({ loading: true, refresh: false });

    client.authenticate()
      .then((res) => {
        return client.service('propertyunits').find({
          query: {
            _id: this.props.match.params.id,

            $sort: {
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {
        //

        this.setState({
          propertyunit: res.data[0],
          loading: false
        });

        this.props.form.setFieldsValue(
          res.data[0].owner
        );
        //this.props.fetchPropertyunitusers(res.data[0].users)
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      })
    //query request service
    client.authenticate()
      .then((res) => {
        return client.service('requests').find({
          query: {
            $populate: 'userId',
            propertyunitId: this.props.match.params.id,

            $sort: {
              createdAt: -1
            }
          }
        })
      })
      .then((res) => {
        this.props.fetchRequests(res.data)
      })
      .catch((err) => {
        console.log(err);
      })
  }

  handleDelete(_id) {
    client.service('propertyunitusers').remove(_id)
      .then((res) => {
      })
      .catch((err) => {
        alert(err);
      })
  }

  handleApprove(_id) {
    client.service('requests').patch(_id, {
      'status': 'APPROVED'
    })
      .then((res) => {
        //
        message.success('Record Updated');
      })
      .catch((err) => {
        message.error('Updated Failed');
        console.log(err);
        alert(err);
      })
  }

  handleReject(_id) {
    client.service('requests').patch(_id, {
      'status': 'REJECTED'
    })
      .then((res) => {
        //
        message.success('Record Updated');
      })
      .catch((err) => {
        message.error('Updated Failed');
        console.log(err);
        alert(err);
      })
  }

  handleFileUpload = (event) => {

    this.setState({ loading: true });

    let formData = new FormData();

    formData.append('image', event.target.files[0]);

    axios.post(`${client.io.io.uri}upload-propertyunit`,
      formData
      , {
        headers: {
          'Authorization': client.settings.storage.storage['feathers-jwt'],
          'Content-Type': 'multipart/form-data',
        }
      }
    ).then((res) => {
      this.props.form.setFieldsValue({
        file: res.data.imageUrl
      });
      this.setState({ loading: false });

      message.success('Upload Successful');
    })
      .catch((err) => {
        console.log(err);
        message.error(err);
        this.setState({ loading: false });
      })
  }

  handleEdit(_id) {
    this.props.updateActivePropertyunitusers('edit', _id)
  }

  handleManage(_id) {
    this.props.updateActivePropertyunitusers('', _id)
  }

  renderConfirmDeleteBtn(_id) {
    return (
      <Popconfirm placement="topLeft" title={text} onConfirm={() => this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
        <Button type="danger">Delete</Button>
      </Popconfirm>
    )
  }

  renderConfirmApproveBtn(_id) {
    return (
      <Popconfirm placement="topLeft" title='Are you sure to approve?' onConfirm={() => this.handleApprove(_id)} okText="Confirm" cancelText="Cancel">
        <Button type="success">Approve</Button>
      </Popconfirm>
    )
  }

  renderConfirmRejectBtn(_id) {
    return (
      <Popconfirm placement="topLeft" title='Are you sure to reject?' onConfirm={() => this.handleReject(_id)} okText="Confirm" cancelText="Cancel">
        <Button type="danger">Reject</Button>
      </Popconfirm>
    )
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {

      if (!err) {

        client.service('propertyunits').patch(this.props.match.params.id, {
          'owner': {
            ...values,

          }
        })
          .then((res) => {


            this.props.form.setFieldsValue(
              res.owner
            );
            this.setState({
              refresh: true,
            });
            message.success('Record Updated');
          })
          .catch((err) => {
            message.error('Updated Failed');
            console.log(err);
            alert(err);
          })

      }

    });
  }

  showModal = (file) => {

    this.setState({
      visible: true,
      img: file
    });
  }

  handleOk = (e) => {
    this.setState({
      visible: false,
      img: ''
    });
  }

  handleCancel = (e) => {
    this.setState({
      visible: false,
      img: ''
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title: 'User ID',
        dataIndex: 'userId.firstName',
        key: 'userId.firstName',
      },
      {
        title: 'Remark',
        dataIndex: 'remark',
        key: 'remark',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => ([
          <span key='imgBtn'>
            <Button type="info" onClick={() => this.showModal(record.file)}>View Image</Button>
            {record.status === 'PENDING' ? this.renderConfirmApproveBtn(record._id) : ''}
            {record.status === 'PENDING' ? this.renderConfirmRejectBtn(record._id) : ''}
          </span>
        ]),
      }];

    return (

      <Layout>
        <Row>
          <Col span={8}>
            <Spin spinning={this.state.loading} >
              <Card title={'Owner Info ' + this.state.propertyunit.referenceId} >
                
                <Form onSubmit={this.handleSubmit}>
                  <Form.Item
                    {...formItemLayout}
                    label="Name"
                  >
                    {getFieldDecorator('name', {
                      rules: [{
                        required: false, message: 'Please input your Name!',
                      }],
                    })(
                      <Input />
                    )}
                  </Form.Item>

                  <Form.Item
                    {...formItemLayout}
                    label="Identification No"
                  >
                    {getFieldDecorator('idNo', {
                      rules: [{
                        required: false, message: 'Please input your Name!',
                      }],
                    })(
                      <Input onChange={(e)=>{
                        console.log(e.target.value)
                      }} />
                    )}
                  </Form.Item>

                  <Form.Item
                    {...formItemLayout}
                    label="Contact No."
                  >
                    {getFieldDecorator('contactNumber', {
                      rules: [{
                        required: false, message: 'Please input Contact Number!',
                      }],
                    })(
                      <Input />
                    )}
                  </Form.Item>

                  <Form.Item
                    {...formItemLayout}
                    label="E-mail"
                  >
                    {getFieldDecorator('email', {
                      rules: [{
                        required: false, message: 'Please input E-mail!',
                      }],
                    })(
                      <Input />
                    )}
                  </Form.Item>

                  <Form.Item
                    {...formItemLayout}
                    label="Mailing Address Line"
                  >
                    {getFieldDecorator('mailingAddress', {
                      rules: [{
                        required: false, message: 'Please input mailingAddress!',
                      }],
                    })(
                      //  <Input />
                      <TextArea rows={4} />
                    )}
                  </Form.Item>

                  {/*          <Form.Item
                      {...formItemLayout}
                      label="Mailing Address Line 2"
                    >
                      {getFieldDecorator('mailingAddress2', {
                              rules: [{
                                required: false, message: 'Please input mailingAddress!',
                              }],
                            })(
                              <Input />
                            )}
                          </Form.Item>

                          <Form.Item
                            {...formItemLayout}
                            label="Mailing Address Line 3"
                          >
                            {getFieldDecorator('mailingAddress3', {
                              rules: [{
                                required: false, message: 'Please input mailingAddress!',
                              }],
                            })(
                              <Input />
                            )}
                          </Form.Item>
                */}
                  <Form.Item
                    {...formItemLayout}
                    label="Remark"
                  >
                    {getFieldDecorator('remark', {
                      rules: [{
                        required: false, message: 'Please input remark!',
                      }],
                    })(
                      <TextArea rows={4} />
                    )}
                  </Form.Item>

                  <Form.Item
                    {...formItemLayout}
                    label="Vehicle Details"
                  >
                    {getFieldDecorator('vehicleDetails', {
                      rules: [{
                        required: false, message: 'Please input Vehicle Details!',
                      }],
                    })(
                      <Input />
                    )}
                  </Form.Item>

                  <Form.Item
                    {...formItemLayout}
                    label="Parking No."
                  >
                    {getFieldDecorator('parkingNo', {
                      rules: [{
                        required: false, message: 'Please input Parking No.!',
                      }],
                    })(
                      <Input />
                    )}
                  </Form.Item>

                  <Form.Item
                    {...formItemLayout}
                    label="Upload"
                  >
                    <Input label='upload file' type='file' onChange={this.handleFileUpload} />
                  </Form.Item>

                  <Form.Item
                    {...formItemLayout}
                    label="Upload File"
                    style={{ 'display': 'none' }}
                  >
                    {getFieldDecorator('file', {
                      rules: [{
                        required: false, message: 'Please upload File!',
                      }],
                    })(
                      <Input style={{ 'display': 'none' }} />
                    )}
                  </Form.Item>

                  {this.props.form.getFieldValue('file') ?
                    <img src={this.props.form.getFieldValue('file')} style={{ 'width': '200px', 'marginLeft': '35%' }} alt="Document Info" />
                    : ''}


                  <Form.Item {...formItemLayout}>
                    <Button type="primary" htmlType="submit">Update</Button>
                  </Form.Item>
                </Form>


              </Card>
            </Spin>
          </Col>
          <Col span={16}>
            <Card title='Request Info'>



              <Table dataSource={this.props.requests.requests} columns={columns} rowKey="_id" />


            </Card>
          </Col>
        </Row>

        <Modal
          title="Document Info"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <img src={this.state.img} style={{ 'width': '200px', 'marginleft': '30%' }} alt="Document Info" />
        </Modal>

      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    requests: state.requests,
    file: state.file,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchRequests: fetchRequests,
    pushRequests: pushRequests,
    removeRequests: removeRequests,
    updateActiveRequests: updateActiveRequests,
    updateRequests: updateRequests,
    updateMode: updateMode
  }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(RequestsIndex)));
