import React, { Component } from 'react'
import { Modal, message, Form, Icon, Input, Select,Alert,Row,Col } from 'antd'
import client from '../../feathers'
import requireAuth  from "../../components/requireAuth"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateMode } from '../../actions/users-actions'

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
//prompt error messages config .
message.config({
  top: 125,
  duration: 3,
});

class UsersForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id:'',
      confirmDirty: false,
      autoCompleteResult: [],
      managementdropdowns :[],
      propertydropdowns :[]
    }

  }
  componentDidUpdate(){
      if(this.props.users.activeId !== this.state._id){
        this.setState({
          _id:this.props.users.activeId
        })

        if(this.props.users.activeUser){
          this.props.form.setFieldsValue(
            this.props.users.activeUser
          )
        }
      }

  }

  componentWillMount(){
    //generate mng dropdown
    client.authenticate()
    .then(()=>{
        return client.service('managements').find({
          query: {
             $sort: {
               createdAt: -1
             }
           }
        })
    })
    .then((res)=>{
      this.setState({
        managementdropdowns:res.data
      })
    })
    .catch((err)=>{
        console.log(err);
    });

    //generate property dropdown
    client.authenticate()
    .then(()=>{
        return client.service('properties').find({
          query: {
             $sort: {
               createdAt: -1
             }
           }
        })
    })
    .then((res)=>{
      this.setState({
        propertydropdowns:res.data
      })
    })
    .catch((err)=>{
        console.log(err);
    });

  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }


  handleSubmit = (e) => {
    e.preventDefault();
    // if(this.props.users.mode === 'new'){

    this.props.form.validateFields((err, values) => {
        //customize email value
        if( values.email.indexOf('@') === -1 ){
          values.email = `${values.email}@kbms.my`
        }

        if(this.props.users.mode === 'new'){
            if (!err) {
              const fieldValues = {...values,role:'management'};
              client.authenticate()
              .then((res)=>{
                return client.service('users').create(fieldValues)
              })
              .then((res)=>{
                this.props.updateMode('')
                message.success('New User added');

              })
              .catch((err)=>{
                this.props.updateMode('')
                console.log({err});
                message.error(err.message);

                // alert(err);
              })
            }
        }else{
            if (!err) {
              client.service('users').patch(this.props.users.activeId,
                values
              )
              .then((res)=>{
                message.success(`User updated`);
                this.props.updateMode('')
              })
              .catch((err)=>{
                this.props.updateMode('')
                message.error(err.message);
              })
              return;
            }
        }

    });
  }

  handleChange = (value) => {

    client.service('managements').find({
      query: {
        _id:value,
         $sort: {
           createdAt: -1
         }
       }
    })
    .then((res)=>{
      if(res.total=='1'){
        message.success('Management Found');
        this.props.form.setFieldsValue({
          propertyId:res.data[0].propertyId
        })
      }else{
        message.error('Management not Found');
      }

    })
    .catch((err)=>{
      console.log(err);
      message.error(err);
    })
  }

  handleSelectRole = (value) => {


    if(value!= 'management'){
      this.props.form.setFieldsValue({
        managementId:'',
        propertyId:'',
        accountmainId:'',
      });
    }

  }


compareToFirstPassword = (rule, value, callback) => {
  const form = this.props.form;
  if (value && value !== form.getFieldValue('password')) {
    callback('Two passwords that you enter is inconsistent!');
  } else {
    callback();
  }
}

validateToNextPassword = (rule, value, callback) => {
  const form = this.props.form;
  if (value && this.state.confirmDirty) {
    form.validateFields(['confirm'], { force: true });
  }
  callback();
}


  render() {
    const { getFieldDecorator } = this.props.form;


    return(
      <Modal
         visible={
           this.props.users.mode==='new'||this.props.users.mode==='edit'?true:false
         }
         title={ this.props.users.mode === 'edit' ? "Update User":"Create User" }
         okText={ this.props.users.mode === 'edit' ? 'Update':'Create' }
         onCancel={()=>{this.props.updateMode(''); this.props.form.resetFields()} }
         onOk={this.handleSubmit}

      >
        <Form className="submit-form">
          <FormItem
             {...formItemLayout}
             label="Username"
           >
             {getFieldDecorator('email', {
               rules: [{
                 type: 'string', message: 'The input is not valid Username!',
               }, {
                 required: true, message: 'Please input your Username!',
               }],
             })(
              <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Username" />
             )}
           </FormItem>

          <FormItem
          {...formItemLayout}
          label="Password"
        >
          {getFieldDecorator('password', {
            rules: [{
              required: true, message: 'Please input your password!',
            }, {
              validator: this.validateToNextPassword,
            }],
          })(

            <Row gutter={8}>
            <Row><Col span={24}><Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Password" type="password" /></Col></Row>
            <Row style={{ lineHeight: '10px',fontSize: 12,fontStyle: 'italic',color:'#a9b3ad'}} ><Col span={24}>Password length must more than 8.</Col></Row>
            <Row style={{ lineHeight: '10px',fontSize: 12,fontStyle: 'italic',color:'#a9b3ad'}}><Col span={24}>Password must contain Uppercase.</Col></Row>
            <Row style={{ lineHeight: '10px',fontSize: 12,fontStyle: 'italic',color:'#a9b3ad'}}><Col span={24}>Password must contain digits.</Col></Row>
            </Row>
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Confirm Password"
        >
          {getFieldDecorator('confirm', {
            rules: [{
              required: true, message: 'Please confirm your password!',
            }, {
              validator: this.compareToFirstPassword,
            }],
          })(
            <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Confirm Password" type="password" onBlur={this.handleConfirmBlur} />
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="First Name"
        >
          {getFieldDecorator('firstName', {
            rules: [{
              required: false, message: 'Please confirm your First Name!',
            }],
          })(
            <Input />
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Last Name"
        >
          {getFieldDecorator('lastName', {
            rules: [{
              required: false, message: 'Please confirm your Last Name!',
            }],
          })(
            <Input />
          )}
        </FormItem>

          <FormItem
            {...formItemLayout}
            label="Select Management"
            hasFeedback
          >
            {getFieldDecorator('managementId', {
              rules: [
                { required: false, message: 'Please select Management!' },
              ],
            })(
              <Select placeholder="Please select Management" onChange={this.handleChange}>
                {this.state.managementdropdowns.map(managementdropdown => (
                  <Option value={managementdropdown._id}>
                    {managementdropdown.name}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>

        </Form>
      </Modal>

    )
  }
}


function mapStateToProps(state) {
  return {
    users: state.users
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
    }, dispatch);
}


export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(UsersForm)));
