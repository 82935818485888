import React, { Component, useEffect, useRef } from "react";
import {
  Modal,
  Upload,
  Popconfirm,
  message,
  Divider,
  Table,
  Form,
  Icon,
  Input,
  Button,
  Select,
  Card,
  Radio,
  Spin,
  Row,
  Col,
  Tabs,
  DatePicker,
} from "antd";
import client from "../../feathers";
import requireAuth from "../requireAuth";
import Layout from "../layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Route, Link } from "react-router-dom";
import ReactToPrint from "react-to-print";
import axios from "axios";
import moment from "moment";
import NumberFormat from "react-number-format";
//----------------------------------------------------------------------------------------
const _ = require("lodash");
const TabPane = Tabs.TabPane;
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;

class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      loading: false,
      jmb: this.props.jmbs,
      defaultValue: "",
      fromMonth: moment(new Date("2020-05-27T14:26:42.195+08:00")).format(
        "YYYYMM"
      ),
      toMonth: moment()
        .endOf("year")
        .format("YYYYMM"),
      columns: [],
      fixedAssetData: [],
      fixedAssetTotal: 0,
      currentAssetData: [],
      currentAssetTotal: 0,
      liabilitiesData: [],
      liabilitiesTotal: 0,
      equityData: [],
      equityTotal: 0,
    };
  }

  componentWillMount() {
    this.setState({ loading: true });

    client
      .authenticate()
      .then(() => {
        return axios.get(
          `${client.io.io.uri}ledgerTrxSummaryBalanceSheetDetail`,
          {
            params: {
              companyId: this.props.companyId,
              toDate: this.state.toMonth,
              fromDate: this.state.fromMonth,
              balanceSheetDetail: true,
            },
            headers: {
              Authorization: client.settings.storage.storage["feathers-jwt"],
            },
          }
        );
      })
      .then((res1) => {
        let data = res1.data;
        // console.log({data});
        let currentAssetData =
          _.filter(data, { ledgerAccountType: "CURRENT ASSETS" }) || [];
        let fixedAssetData =
          _.filter(data, { ledgerAccountType: "FIXED ASSETS" }) || [];
        let currentLiabiliesData =
          _.filter(data, { ledgerAccountType: "CURRENT LIABILITIES" }) || [];
        let equityData = _.filter(data, { ledgerAccountCat: "EQUITY" }) || [];

        this._constructTable(fixedAssetData, "fixedAsset");
        this._constructTable(currentAssetData, "currentAsset");
        this._constructTable(currentLiabiliesData, "liabilities");
        this._constructTable(equityData, "equity");

        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  _constructTable(data, target) {
    let { fromMonth, toMonth, receiptTotal, paymentTotal } = this.state;
    let result = _.chain(data)
      .groupBy("ledgerAccountDescription")
      .map((value, key) => ({ desc: key, month: value }))
      .value();

    //construct column
    let monthCount = moment([
      toMonth.substring(0, 4),
      parseInt(toMonth.substring(4)) - 1,
    ]).diff(
      moment([fromMonth.substring(0, 4), parseInt(fromMonth.substring(4)) - 1]),
      "months",
      true
    );
    let columns = [];
    let yearMonth = [];
    this.setState({ columns });
    for (let x = -1; x <= monthCount; x++) {
      let varA =
        x < 0
          ? "desc"
          : moment([
              fromMonth.substring(0, 4),
              parseInt(fromMonth.substring(4)) - 1,
            ])
              .add(x, "M")
              .format("YYYYMM");
      yearMonth.push(x < 0 ? "total" : varA);
      columns.push({
        title: (
          <Row>
            <Col span={24}>
              {x > -1
                ? x < 0
                  ? varA.substring(0, 4)
                  : varA.substring(0, 4) + "-" + varA.substring(4)
                : "Account Name"}
            </Col>
          </Row>
        ),
        className: x > -1 ? "td-padding-0 reportAmt" : "title-desc",
        width: x > -1 ? "auto" : 150,
        align: x > -1 ? "right" : "left",
        dataIndex: varA,
        key: target + x,
        render: (v) => (
          <div style={{ textAlign: x > -1 ? "right" : "left" }}>
            <span>
              {typeof v === "string" ? (
                v
              ) : (
                <NumberFormat
                  prefix={v < 0 ? "(" : ""}
                  suffix={v < 0 ? ")" : ""}
                  thousandSeparator={true}
                  displayType="text"
                  fixedDecimalScale={true}
                  decimalScale={2}
                  value={parseFloat(v < 0 ? v * -1 : v || 0).toFixed(2)}
                />
              )}
            </span>
          </div>
        ),
      });
    }

    //total column
    columns.push({
      title: (
        <Row>
          <Col span={24}>Total</Col>
        </Row>
      ),
      className: "td-padding-0 reportAmt",
      width: 100,
      align: "right",
      dataIndex: "total",
      key: monthCount,
      render: (v, r) => (
        <div style={{ textAlign: "right" }}>
          <span>
            <NumberFormat
              prefix={v < 0 ? "(" : ""}
              suffix={v < 0 ? ")" : ""}
              style={{
                fontWeight: r.grandTotal ? "bolder" : "bold",
                fontSize: r.grandTotal ? 13 : 12,
                borderTop: r.grandTotal ? "double" : "none",
                borderBottom: r.grandTotal ? "double" : "none",
              }}
              thousandSeparator={true}
              displayType="text"
              fixedDecimalScale={true}
              decimalScale={2}
              value={parseFloat(v < 0 ? v * -1 : v || 0).toFixed(2)}
            />
          </span>
        </div>
      ),
    });

    let tableData = [];
    let colTotal = 0.0;
    result.map((i, index) => {
      tableData.push({ desc: i.desc, total: 0.0 });
      i.month.map((j) => {
        if (yearMonth.indexOf(j.yearmonth.toString()) !== -1) {
          tableData[index][j.yearmonth] = parseFloat(j.balance) || 0;
          tableData[index]["total"] += parseFloat(j.balance) || 0;
          colTotal += parseFloat(j.balance);
        }
      });
    });

    //calc total row
    tableData.push({ desc: "Total", total: colTotal, grandTotal: true });
    yearMonth.map((i, index) => {
      let total = 0;
      if (index > 0) {
        let value = _.sumBy(tableData, i) || 0;
        total += value;
        tableData[tableData.length - 1][i] = value;
      }
    });

    if (target === "currentAsset") {
      this.setState({
        currentAssetData: tableData,
        columns,
        currentAssetTotal: colTotal,
      });
      //show monthly income
      let grandTotal = 0.0;
      tableData.push({
        desc: "TOTAL ASSETS ",
        total: this.state.receiptTotal + colTotal,
        grandTotal: true,
      });
      yearMonth.map((i, index) => {
        if (index > 0) {
          let total =
            this.state.fixedAssetData[this.state.fixedAssetData.length - 1][i] +
            tableData[tableData.length - 2][i];
          tableData[tableData.length - 1][i] = total;
          grandTotal += total;
        }
      });
      tableData[tableData.length - 1]["total"] = grandTotal;
    }
    if (target === "fixedAsset") {
      this.setState({
        fixedAssetData: tableData,
        columns,
        fixedAssetTotal: colTotal,
      });
    }
    if (target === "liabilities") {
      this.setState({
        liabilitiesData: tableData,
        columns,
        liabilitiesTotal: colTotal,
      });
    }
    if (target === "equity") {
      this.setState({ equityData: tableData, columns, equityTotal: colTotal });
      //show monthly income
      let grandTotal = 0.0;
      tableData.push({
        desc: "TOTAL LIABILITIES and EQUITY ",
        total: this.state.receiptTotal + colTotal,
        grandTotal: true,
      });
      yearMonth.map((i, index) => {
        if (index > 0) {
          let total =
            this.state.liabilitiesData[this.state.liabilitiesData.length - 1][
              i
            ] + tableData[tableData.length - 2][i];
          tableData[tableData.length - 1][i] = total;
          grandTotal += total;
        }
      });
      tableData[tableData.length - 1]["total"] = grandTotal;
    }
  }

  componentDidUpdate() {
    if (this.props.toMonth !== this.state.toMonth) {
      this.setState({ toMonth: this.props.toMonth }, () => {
        this.componentWillMount();
      });
    }
  }

  // -------------------------------
  render() {
    const {
      fixedAssetData,
      currentAssetData,
      liabilitiesData,
      equityData,
      columns,
      jmb,
      loading,
      fromMonth,
      toMonth,
    } = this.state;

    return (
      <div>
        <Spin key="spinning" spinning={this.state.loading}>
          <Card
            title={
              <div>
                <span style={{ fontSize: 20 }}>{jmb.name}</span>
                <br></br>
                <span>({jmb.registrationNo})</span>
                <br></br>
                <span>Balance Sheet</span>
                <span className="alignRight">
                  As at selected date till {moment(toMonth).format("YYYY-MM")}
                </span>
                <br></br>
              </div>
            }
          >
            <Row>
              <Col span={5}>
                <img width="25%" src={this.state.jmb.headerFile} />
              </Col>
              <Col span={19}>
                <p>
                  <span>{jmb.headerLine1}</span>
                </p>
                <p>
                  <span>{jmb.headerLine2}</span>
                </p>
                <p>
                  <span>{jmb.headerLine3}</span>
                </p>
                <p>
                  <span>{jmb.headerLine4}</span>
                </p>
              </Col>
            </Row>

            <Table
              className="printTable"
              bordered
              scroll={{ x: 950 }}
              title={() => "Fixed Assets"}
              dataSource={fixedAssetData}
              columns={columns}
              pagination={false}
              size="small"
            />
            <br />
            <Table
              className="printTable"
              bordered
              scroll={{ x: 950 }}
              title={() => "Current Assets"}
              dataSource={currentAssetData}
              columns={columns}
              pagination={false}
              size="small"
            />
            <br />
            <Table
              className="printTable"
              bordered
              scroll={{ x: 950 }}
              title={() => "Liabilities"}
              dataSource={liabilitiesData}
              columns={columns}
              pagination={false}
              size="small"
            />
            <br />
            <Table
              className="printTable"
              bordered
              scroll={{ x: 950 }}
              title={() => "Equity"}
              dataSource={equityData}
              columns={columns}
              pagination={false}
              size="small"
            />
            <br />
          </Card>
        </Spin>
      </div>
    );
  }
}

class BSYearly extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fromMonth: moment(new Date("2020-05-27T14:26:42.195+08:00")).format(
        "YYYYMM"
      ),
      toMonth: moment()
        .endOf("year")
        .format("YYYYMM"),
    };
  }

  fromMonthOnChange = (e) => {
    this.setState({
      fromMonth: moment(new Date("2020-05-27T14:26:42.195+08:00")).format(
        "YYYYMM"
      ),
    });
  };

  toMonthOnChange = (e) => {
    this.setState({
      toMonth: moment(new Date(e)).format("YYYYMM"),
    });
  };

  render() {
    return (
      <div>
        <Row gutter={24}>
          {/* <Col span={3} offset={15}>
            From Date :{" "}
            <MonthPicker
              defaultValue={moment().startOf("year")}
              onChange={this.fromMonthOnChange}
            />
          </Col> */}
          <Col span={6}  offset={15}> 
            Date :{" "}
            <MonthPicker
              // defaultValue={moment().startOf("month")}
              onChange={this.toMonthOnChange}
            />
          </Col>
          <Col span={2}>
            {/* <br /> */}
            <ReactToPrint
              trigger={() => <Button type="primary">PRINT</Button>}
              content={() => this.componentRef}
            />
          </Col>
        </Row>
        <br />
        <ComponentToPrint
          jmbs={this.props.jmbs.jmbs[0]}
          companyId={this.props.commons.selectedCompany._id}
          ref={(el) => (this.componentRef = el)}
          fromMonth={this.state.fromMonth}
          toMonth={this.state.toMonth}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    jmbs: state.jmbs,
    commons: state.commons,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      //updateMode: updateMode
    },
    dispatch
  );
}

export default requireAuth(
  connect(mapStateToProps, mapDispatchToProps)(Form.create()(BSYearly))
);
