import React, { Component } from 'react';
import { Popconfirm, message, Divider,  Table, Form, Icon, Input, Button,Card } from 'antd';
import { Route, Link } from 'react-router-dom';

import client from '../../feathers';
import requireAuth  from "../../components/requireAuth";
import Layout  from "../../components/layout";
import ProfomasForm  from "../../components/profomas/form";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchProfomas,
  pushProfomas,
  removeProfomas,
  updateMode,
  updateActiveProfomas,
  updateProfomas } from '../../actions/actions-profomas';

const FormItem = Form.Item;

const text = 'Are you sure to delete this item?';

class ProfomasIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      propertyunit : [],
    }

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidUpdate(){
  }

  componentDidMount(){
      client.service('profomas').on('created', (profomas) => {
        this.props.pushProfomas(profomas)
      });

      client.service('profomas').on('removed', (profomas) => {
        this.props.removeProfomas(profomas)
      });

      client.service('profomas').on('updated', (profomas) => {
        this.props.updateProfomas(profomas)
      });
  }

  

  componentWillMount(){

      client.authenticate()
      .then((res)=>{
        
          return client.service('propertyunits').find({
            query: {
              _id:this.props.match.params.id,
               $sort: {
                 createdAt: -1
               }
             }
          })
      })
      .then((res)=>{
        
        this.props.fetchProfomas(res.data[0].recurringInvoice)
      })
      .catch((err)=>{
          console.log(err);
      })
  }

  handleDelete(_id) {

    client.service('propertyunits').patch(this.props.match.params.id,{
      '$pull':{'recurringInvoice':{
        '_id':_id,
      } }
    })
    .then((res)=>{
      
      message.success('Deleted');
      this.props.fetchProfomas(res.recurringInvoice);
    })
    .catch((err)=>{
      alert(err);
    })
  }

  handleEdit(_id) {
    this.props.updateActiveProfomas('edit',_id)
  }

  handleManage(_id) {
    this.props.updateActiveProfomas('',_id)
  }

  showModal = () => {
    this.props.updateMode('new')
  }

  renderConfirmDeleteBtn(_id){
    return (
      <Popconfirm placement="topLeft" title={text} onConfirm={()=>this.handleDelete(_id)} okText="Confirm" cancelText="Cancel">
        <Button type="danger">Delete</Button>
      </Popconfirm>
    )
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title: 'Charges',
        dataIndex: 'chargesCode',
        key: 'chargesCode',
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
      },
      {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
          <Divider type="vertical" />
          {/*<Button onClick={()=>this.handleEdit(record._id)}>Edit</Button>*/}
          {this.renderConfirmDeleteBtn(record._id)}
          <Divider type="vertical" />
        </span>
      ),
    }];

    return(
      <Layout>
        <Card
          title={this.state.propertyunit.referenceId}
          extra={<Button type="primary" onClick={this.showModal}>New Item</Button>}
        >
      
          <Table dataSource={this.props.profomas.profomas} columns={columns} rowKey="_id" />
          <ProfomasForm propertyunitId={this.props.match.params.id} />
        </Card>
      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    profomas: state.profomas,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchProfomas: fetchProfomas,
    pushProfomas: pushProfomas,
    removeProfomas: removeProfomas,
    updateActiveProfomas: updateActiveProfomas,
    updateProfomas: updateProfomas,
    updateMode: updateMode
    }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(ProfomasIndex)));
