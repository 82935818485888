import React from "react";

const SvgCocktail1 = props => (
  <svg height={512} viewBox="0 0 64 64" width={512} {...props}>
    <path
      d="M42 5a12.009 12.009 0 00-11.949 11h-4.637l-5.5-5.5 6.793-6.793A1 1 0 0026 2H11a1 1 0 00-1 1v15a1 1 0 00.617.924A.987.987 0 0011 19a1 1 0 00.707-.293l6.793-6.793L22.586 16H21a1 1 0 00-.843 1.537c.035.057 3.583 5.8 1.859 15.284-1.793 9.864 4.8 13.817 6.984 14.84v1.915a3.438 3.438 0 000 4.848v1.926l-8.406 3.736A1 1 0 0021 62h22a1 1 0 00.406-1.914L35 56.35v-1.926a3.438 3.438 0 000-4.848v-1.915c2.188-1.023 8.777-4.976 6.984-14.84-1.451-7.982.828-13.3 1.607-14.821H54v-1A12.013 12.013 0 0042 5zm-25.726 6.312l-3.038-6.076 6.076 3.038zM20.8 6.783L15.236 4h8.35zm-8.8.453l2.783 5.564L12 15.586zm11.984 25.943c.073-.4.133-.791.189-1.179h15.654c.056.388.116.779.189 1.179A14.392 14.392 0 0140.134 38H23.866a14.392 14.392 0 01.118-4.821zM39.6 30H24.4a27.31 27.31 0 00-.492-8H40.1a27.31 27.31 0 00-.5 8zm-6.269 20.743a1.515 1.515 0 010 2.514A1 1 0 0033 54v2h-2v-2a1 1 0 00-.331-.743 1.515 1.515 0 010-2.514A1 1 0 0031 50v-2h2v2a1 1 0 00.331.743zM25.712 60l4.5-2h3.576l4.5 2zm8.112-14h-3.647a10.365 10.365 0 01-5.8-6h15.251a10.369 10.369 0 01-5.804 6zm6.805-26H23.371a20.218 20.218 0 00-.742-2h18.742a20.218 20.218 0 00-.742 2zM42 15.2l-2.438-3.656a5.764 5.764 0 014.876 0zm-4.1-2.55L40.131 16h-4.048a5.984 5.984 0 011.817-3.35zM43.869 16l2.231-3.35A5.984 5.984 0 0147.917 16zm6.062 0a7.993 7.993 0 00-15.862 0H32.05a10 10 0 0119.9 0z"
      data-original="#000000"
      className="cocktail_(1)_svg__active-path"
      data-old_color="#000000"
      fill="#8D99AE"
    />
    <path
      d="M25 10h2v2h-2zM28 6h2v2h-2zM35 34h2v2h-2zM29 42h2v2h-2z"
      data-original="#000000"
      className="cocktail_(1)_svg__active-path"
      data-old_color="#000000"
      fill="#8D99AE"
    />
  </svg>
);

export default SvgCocktail1;
