import React, { Component, useEffect } from 'react';
import { Modal, Upload,Popconfirm, message, Divider,  Table, Form, Icon, Input, Button, Select,Card,Row,Col,Spin ,InputNumber,Radio,DatePicker} from 'antd'
import client from '../../feathers';
import requireAuth  from "../requireAuth";
import Layout  from "../layout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateMode } from '../../actions/actions-invoices';
import Info  from "../common/info";
import moment from 'moment';
const _ = require('lodash');
const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },

};
const formItemStyle = {
  style: {width:'300px'}
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset:4 },
};

const text = 'Are you sure to delete this item?';

class InvoicesForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id:'',
      confirmDirty: false,
      autoCompleteResult: [],
      chargesDropdowns : [],
      charges:[],
      dataSource:[],
      mode:'new',
      propertyunits:[],
      loading:false,
      count:0
    }

  }

  componentWillMount(){

    setTimeout(() => {    //set default date
      this.props.form.setFieldsValue({invoiceDate:moment()});
    }, 1000)

    //generate charges dropdown
    // client.authenticate()
    // .then((res)=>{
    //     return client.service('charges').find({
    //       query: {
    //         propertyId: sessionStorage.getItem('companyId'),
    //         code:{$nin:'DEPOSIT'},
    //          $sort: {
    //            createdAt: -1
    //          }
    //        }
    //     })
    // })
    // .then((res)=>{
    //   this.setState({chargesDropdowns:res.data});
    // })
    // .catch((err)=>{
    //     console.log(err);
    // });
    //get all propertyunits
    client.authenticate()
    .then((res)=>{
      // console.log(sessionStorage.getItem('companyId'))
      client.service('charges').find({
        query: {
          // propertyId: sessionStorage.getItem('companyId'),
          propertyId: this.props.commons.selectedCompany._id,
          code:{$nin:'DEPOSIT'},
           $sort: {
             createdAt: -1
           }
         }

        })
        .then((res)=>{
          console.log({res})
          this.setState({chargesDropdowns:res.data});
          })

        return client.service('propertyunits').get(this.props.match.params.id)

    })
    .then((res)=>{

      this.setState({
        propertyunits:res
      });

      let ownerName=''
      let ownerContact=''
      this.props.form.setFieldsValue({
        ownerName:res.owner.name?res.owner.name:'',
        ownerContact:res.owner.contactNumber?res.owner.contactNumber:'',
        propertyunitIdName:res.referenceId?res.referenceId:'',
      });


    })
    .catch((err)=>{
        console.log(err);
    });

  }

  componentDidUpdate(){

      if(this.props.invoices.activeId !== this.state._id){
        this.setState({
          _id:this.props.invoices.activeId
        })

        if(this.props.invoices.activeInvoices){
          this.props.form.setFieldsValue(
            this.props.invoices.activeInvoices
          )
        }
      }
  }

  handleChange = (e) => {

    this.setState({
      loading:true
    });


    client.authenticate()
    .then((res)=>{
        return client.service('charges').get(e)
    })
    .then((res)=>{
      this.setState({
        charges:res,
        loading:false
      });
      this.props.form.setFieldsValue(
        res
      );
    })
    .catch((err)=>{
      this.setState({
        loading:false
      });
      console.log(err);
    });
  }

  handleDeleteItem = (key) => {

    const dataSource = [...this.state.dataSource];
    this.setState({ dataSource: dataSource.filter(item => item.key !== key) });
  }

  handleClick = () => {

    if(!this.state.charges._id)
    {
      message.error("Please Select Charges")
      return;
    }

    if(!this.state.charges.amount||this.state.charges.amount<0)
    {
      message.error("Invalid Amount")
      return;
    }

    let effectiveDate = this.props.form.getFieldValue('invoiceDate')

    if(!effectiveDate)
    {
      message.error("Invalid Invoice Date")
      return;
    }

    let dueDate = this.props.form.getFieldValue('dueDate')

    if(!dueDate)
    {
      message.error("Invalid Due Date")
      return;
    }


    const newData = {
      key             : this.state.count,
      chargeId        : this.state.charges._id,
      chargeCode      : this.state.charges.code,
      description     : this.state.charges.description,
      amount          : this.state.charges.amount,
      effectiveDateDisplay   : moment(new Date(this.props.form.getFieldValue('invoiceDate'))).format('DD-MM-YYYY'),
      effectiveDate   : new Date(this.props.form.getFieldValue('invoiceDate')),
      dueDateDisplay  : moment(new Date(this.props.form.getFieldValue('dueDate'))).format('DD-MM-YYYY'),
      dueDate         : new Date(this.props.form.getFieldValue('dueDate')),

    };

    this.setState({
      dataSource: [...this.state.dataSource, newData],
      count: this.state.count + 1,
    });
}

 handleChangeAmount = (e) => {

  this.setState({
    charges:{
      _id           :this.state.charges._id,
      code          :this.state.charges.code,
      description   :this.state.charges.description,
      amount        :e
    }
  })
 }

  handleSubmit = (e) => {
     e.preventDefault();
    // console.log('---',this.state.dataSource)
    // return 

     if(this.state.dataSource.length===0){
       return message.error('No item is selected');
     }

     this.props.form.validateFields((err, values) => {
      if (!err) {

        values.propertyunitId = this.props.match.params.id

        client.service('invoices').create({
          invoiceDate       : values.invoiceDate,
          invoiceType       : 'OTHERS',
          propertyId        : this.props.commons.selectedCompany._id,
          customerId        : values.customerId,
          propertyunitId    : values.propertyunitId,
          customerType      : 'PROPERTYUNIT',//values.customerType,
          reference         : '',
          status            : 'ISSUED',
          subtotal          : 0,
          taxPercentage     : 0,
          taxAmt            : 0,
          totalAmt          : 0,
          remark            : values.remark,
          eventCode         : "INITIALINVOICE",
          invoiceNo         : values.invoiceNo,
        },
        {
          query:{
            $client:{
              invoiceItems:this.state.dataSource
            }
          }
        }
        )
        .then((res)=>{
          this.props.history.push('/invoices/view/'+res._id);
          message.success('Invoice Created');
        })
        .catch((err)=>{
         console.log(err);
          alert(err);
        })

      }
    });
  }

  render() {
    const { visible, onCancel } = this.props;
    const { getFieldDecorator } = this.props.form;

    const columns = [{
      title: 'Fee Code',
      dataIndex: 'chargeCode',
      key: 'chargeCode',
    }, {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Effective Date',
      dataIndex: 'effectiveDateDisplay',
      key: 'effectiveDate',
    },
    , {
      title: 'Due Date',
      dataIndex: 'dueDateDisplay',
      key: 'dueDate',
    }, {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Action',
      key: 'action',
       render: (text, record) => (
        this.state.dataSource.length >= 1
          ? (

            <Button  onClick={() => this.handleDeleteItem(record.key)}> Delete </Button>
          ) : null
      ),
    }
    ];

    return(
      <Layout>
        <Spin spinning={this.state.loading}>
          <Card
          title='Tax Invoice (Not Affecting GL)'
          extra={<Button type="danger" onClick={this.handleSubmit}>Create</Button>}
          >
            <Form className="submit-form">

              <Row>
                <Col span={6}>

                  <FormItem key='invoiceDate'
                    {...formItemLayout}
                    label="Invoice Date"
                  >
                    {getFieldDecorator('invoiceDate', {
                      rules: [{
                        required: true, message: 'Please Select date!',
                      }],
                    })(
                      <DatePicker/>
                    )}
                  </FormItem>

                  <FormItem key='dueDate'
                    {...formItemLayout}
                    label="Due Date"
                  >
                    {getFieldDecorator('dueDate', {
                      rules: [{
                        required: true, message: 'Please Select date!',
                      }],
                    })(
                      <DatePicker/>
                    )}
                  </FormItem>

                  <FormItem key='propertyunitId'
                    {...formItemLayout}
                    label="Property Unit"
                  >
                    {getFieldDecorator('propertyunitIdName', {
                      rules: [{
                        required: false, message: 'Please Input Property Unit!',
                      }],
                    })(
                        <Input disabled />
                    )}
                  </FormItem>

                  <FormItem key='ownerName'
                    {...formItemLayout}
                    label="Owner Name"
                  >
                    {getFieldDecorator('ownerName', {
                      rules: [{
                        required: false, message: 'Please Input ownerName!',
                      }],
                    })(
                      <Input disabled />
                    )}
                  </FormItem>

                  <FormItem key='ownerContact'
                    {...formItemLayout}
                    label="Owner Contact"
                  >
                    {getFieldDecorator('ownerContact', {
                      rules: [{
                        required: false, message: 'Please Input ownerContact!',
                      }],
                    })(
                      <Input disabled />
                    )}
                  </FormItem>


                  <FormItem key='invoiceNo'
                    {...formItemLayout}
                    label="Invoice No."
                  >
                    {getFieldDecorator('invoiceNo', {
                      rules: [{
                        required: true, message: 'Please Input Invoice No!',
                      }],
                    })(
                      <Input/>
                    )}
                  </FormItem>

                  <FormItem key='remark'
                    {...formItemLayout}
                    label="Remark"
                  >
                    {getFieldDecorator('remark', {
                      rules: [{
                        required: false, message: 'Please Input Remark!',
                      }],
                    })(
                      <TextArea rows={3} />
                    )}
                  </FormItem>

                </Col>
                <Col span={6} offset={12}>

                  <FormItem key='chargeId'
                    {...formItemLayout}
                    label="Charges"
                  >
                    {getFieldDecorator('chargeId', {
                      rules: [{
                        required: true, message: 'Please Select Charges!',
                      }],
                    })(
                      <Select placeholder="Select Type"  style={{'width':'200px'}} onChange={this.handleChange}>
                        {this.state.chargesDropdowns.map(dropdown => (
                          <Option key={dropdown._id} value={dropdown._id}>
                            {dropdown.description}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>

                  <FormItem key='code'
                    {...formItemLayout}
                    label="Charge Code"
                  >
                    {getFieldDecorator('code', {
                      rules: [{
                        required: true, message: 'Please select charges!',
                      }],
                    })(
                      <Input disabled/>
                    )}
                  </FormItem>

                  <FormItem key='amount'
                    {...formItemLayout}
                    label="Amount"
                  >
                    {getFieldDecorator('amount', {
                      rules: [{
                        required: true, message: 'Please Input amount!',
                      }],
                    })(
                      <InputNumber onChange={this.handleChangeAmount} />
                    )}
                  </FormItem>


                  <Button type="primary" onClick={this.handleClick} disabled={this.state.mode==='edit'?true:false} >Add</Button>

                </Col>
              </Row>

            </Form>

            <Table dataSource={this.state.dataSource} columns={columns} rowKey='code' />

          </Card>
        </Spin>
      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    invoices: state.invoices,
    commons: state.commons
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateMode: updateMode
    }, dispatch);
}

export default requireAuth(connect(mapStateToProps, mapDispatchToProps)(Form.create()(InvoicesForm)));
